import React from 'react';

function DividerNoLabel(props) {
  const { title } = props;

  return (
    <div className="divider-no-label">
      <span>{title}</span>
    </div>
  );
}

export default DividerNoLabel;
