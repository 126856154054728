import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import API from '@utils/API';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import BookingReviseForm from '@components/booking/BookingReviseForm';
import BookingSummary from '../../components/booking/BookingSummary';
import { filter, map } from 'lodash';
import ConfirmationModal from '@components/modal/ConfirmationModal';

function BookingRevise() {
  const history = useHistory();
  const { id } = useParams();
  const { generatePermission } = userPermission();
  const api = new API('v2');

  const [data, setData] = useState({});
  const [dataSummary, setDataSummary] = useState({});
  const [ppn, setPPN] = useState(0);
  const [pph, setPPH] = useState(0);
  const [dpp, setDPP] = useState(0);
  const [rounding, setRounding] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [amount, setAmount] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [hasNotif, setHasNotif] = useState(true);
  const [notification, setNotification] = useState({});
  const [isFleetMode, setIsFleetMode] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [hasConfirmationModal, setHasConfirmationModal] = useState(false);

  const allowPermissionRevise = generatePermission('booking', 'revise');
  const breadcrumb = [
    {
      id: 1,
      label: 'Booking / Order',
      active: false,
      url: '/bookings',
    },
    {
      id: 2,
      label: `#${data?.booking_code ?? '-'}`,
      active: false,
      url: `/bookings/${data?.booking_code ?? ''}`,
    },
    {
      id: 3,
      label: 'Revise Booking',
      active: true,
      url: '',
    },
  ];

  const getPermissionRevise = () => {
    if (allowPermissionRevise) {
      loadBookingDetail();
    } else {
      history.push(`/bookings`);
    }
  };
  async function loadBookingDetail() {
    setIsFleetMode(false);
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/bookings/${id}/booking-revise/?view_draft=y`);
      if (response?.data?.data) {
        setData(response?.data?.data);
        setIsFleetMode(response?.data?.data?.booking_source?.slug == 'fleet' ? true : false);
        handleCanReviseOrder(response?.data?.data);
      } else {
        setData({});
        history.push(`/bookings`);
      }
      setLoading(false);
    } catch (error) {
      setData({});
      setLoading(false);
      history.push(`/bookings`);
    }
  }

  const handleCanReviseOrder = (data) => {
    if (
      data?.customer?.customer_type == 'b2b' &&
      data?.booking_source?.slug !== 'fleet' &&
      (data?.booking_status?.slug == 'payment' ||
        data?.booking_status?.slug == 'waiting' ||
        data?.booking_status?.slug == 'confirm' ||
        data?.booking_status?.slug == 'on-process' ||
        data?.booking_status?.slug == 'service-done' ||
        data?.booking_status?.slug == 'finish' ||
        data?.booking_status?.slug == 'complain')
    ) {
      //pass
    } else if (
      data?.customer?.customer_type == 'b2b' &&
      data?.booking_source?.slug == 'fleet' &&
      (data?.booking_status?.slug == 'complain' ||
        data?.booking_status?.slug == 'waiting' ||
        data?.booking_status?.slug == 'confirm' ||
        data?.booking_status?.slug == 'on-process')
    ) {
      //pass
    } else {
      history.push(`/bookings/${data?.booking_code}`);
    }
  };

  const handleChangePKP = (value) => {
    setDPP(value?.dpp);
    setPPH(value?.pph);
    setPPN(value?.ppn);
    setAmount(value?.total_amount);
  };

  const toggleConfirmationModal = () => {
    setHasConfirmationModal(!hasConfirmationModal);
  };

  const disabledForm = () => {
    let isDisabled = false;
    if (
      data?.booking_type == 'b2b' &&
      (data?.booking_status?.slug == 'service-done' || data?.booking_status?.slug == 'finish')
    ) {
      isDisabled = true;
    }

    return isDisabled;
  };

  const handleChangeForm = (dataForm) => {
    let newDataSummary = { ...data };
    newDataSummary.data_form = dataForm;
    setDataSummary(newDataSummary);
  };

  const handleSaveAsDraft = () => {
    setSaveAsDraft(true);
    handleSubmit();
  };

  const handleSave = () => {
    setSaveAsDraft(false);
    handleSubmit();
  };

  const handleCancelSave = () => {
    setHasConfirmationModal(true);
  };

  const handleSubmit = () => {
    setDisabled(true);
    setIsSubmit(false);
    setTimeout(() => {
      setIsSubmit(true);
    }, 300);
  };

  const handleCancel = () => {
    toggleConfirmationModal();
    setTimeout(() => {
      history.push(`/bookings/${data?.booking_code}`);
    }, 100);
  };

  const handleSubmitRevise = (status, payload) => {
    if (status) {
      if (!saveAsDraft) {
        api
          .put(`v2/intools/bookings/${id}/booking-revise/`, payload)
          .then(() => {
            setDisabled(false);
            resultNotification(true, 'success', 'Berhasil Revise Booking ');
            setTimeout(() => {
              history.push(`/bookings/${data?.booking_code}`);
            }, 1000);
          })
          .catch((e) => {
            const message = `API Error : ${e?.response?.data?.error?.message}`;
            setDisabled(false);
            resultNotification(true, 'fail', 'Gagal Revise Booking ' + message);
          });
      } else {
        api
          .post(`v2/intools/bookings/${id}/revise/draft/`, payload)
          .then(() => {
            setDisabled(false);
            resultNotification(true, 'success', 'Berhasil Revise Booking Draft ');
            setTimeout(() => {
              history.push(`/bookings/${data?.booking_code}`);
            }, 1000);
          })
          .catch((e) => {
            const message = `API Error : ${e?.response?.data?.error?.message}`;
            setDisabled(false);
            resultNotification(true, 'fail', 'Gagal Revise Booking Draft ' + message);
          });
      }
    } else {
      setDisabled(false);
    }
  };

  const resultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    handleResultNotification(notification);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const handleProcess = (status) => {
    setDisabled(status);
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const remapItemToSummary = (dt) => {
    let paket = [];
    let produk = [];
    let jasa = [];

    map(dt, (item) => {
      const tempItem = {
        ...item,
        promoData: { voucher_code: item?.voucher_code },
      };
      if (!item?.deleted && item?.service_type == 'package') {
        paket.push(tempItem);
      }
      if (
        (!item?.deleted && item?.service_type == 'product') ||
        (!item?.deleted && item?.service_type == 'custom' && item?.type == 'product')
      )
        produk.push(tempItem);
      if (
        (!item?.deleted && item?.service_type == 'service') ||
        (!item?.deleted && item?.service_type == 'custom' && item?.type == 'service' && !item?.deleted)
      )
        jasa.push(tempItem);
    });

    return {
      packages: paket,
      products: produk,
      services: jasa,
    };
  };

  useEffect(() => {
    getPermissionRevise();
  }, []);

  const spk = filter(dataSummary?.documents, (x) => x.document_type == 'spk');

  return (
    <>
      {loading && <LoadingWrapper />}
      {!loading && (
        <>
          <ConfirmationModal
            modal={hasConfirmationModal}
            isDisabled={false}
            toggle={toggleConfirmationModal}
            header={`Yakin ingin membatalkan perubahan booking "${id}" ?`}
            onConfirm={() => {
              handleCancel();
            }}
            toggleClose={toggleConfirmationModal}
          />
          <Wrapper
            title="Revise Booking"
            breadcrumbData={breadcrumb}
            showNotification={hasNotif}
            notification={notification}
            onCloseNotification={handleCloseNotification}
          >
            <Row>
              <Col sm={12} md={6} style={{ zIndex: '3' }}>
                <BookingReviseForm
                  bookingCode={id}
                  data={data}
                  disabled={disabled}
                  isSubmit={isSubmit}
                  onChangeForm={handleChangeForm}
                  onSubmitRevise={handleSubmitRevise}
                  pkp={{
                    tax: ppn,
                    service_tax: pph,
                    price_after_tax: amount,
                    price_before_tax: dpp,
                  }}
                  onResultNotification={handleResultNotification}
                  isFleetMode={isFleetMode}
                  saveAsDraft={saveAsDraft}
                />
              </Col>
              <Col sm={12} md={6}>
                <BookingSummary
                  fleetProps={{ isFleetMode: false, detailFleet: false }}
                  datas={{
                    is_b2b: dataSummary?.booking_type == 'b2b',
                    booking_date_time: dataSummary?.booking_datetime,
                    users: dataSummary?.customer,
                    items: {
                      package: remapItemToSummary(dataSummary?.data_form).packages,
                      product: remapItemToSummary(dataSummary?.data_form).products,
                      service: remapItemToSummary(dataSummary?.data_form).services,
                    },
                    workshops: dataSummary?.workshop,
                    cars: dataSummary?.user_car,
                    otopoints: dataSummary?.otopoints,
                    promo: { promo: dataSummary?.discount?.promo, amount: dataSummary?.discount?.amount },
                    spk: {
                      file:
                        spk?.length > 0
                          ? {
                              name: spk[0]?.filename,
                              url: spk[0]?.url,
                              label: spk[0]?.label,
                            }
                          : null,
                      number: dataSummary?.spk_number ?? '',
                    },
                  }}
                  setAmount={setAmount}
                  setPPN={setPPN}
                  setPPH={setPPH}
                  setDPP={setDPP}
                  setRounding={setRounding}
                  setGrandTotal={setGrandTotal}
                  source="revise"
                />
              </Col>
              <Col sm={12} md={12} className="text-right mb-2 section-action">
                {allowPermissionRevise && (
                  <>
                    {isFleetMode && (
                      <>
                        <Button className="button-action save" disabled={disabled} onClick={handleSave}>
                          {'Simpan'}
                        </Button>
                        <Button className="button-action save-draft" disabled={disabled} onClick={handleSaveAsDraft}>
                          {'Simpan Draft'}
                        </Button>
                      </>
                    )}
                    {!isFleetMode && (
                      <Button className="button-action save" disabled={disabled} onClick={handleSave}>
                        {'Simpan'}
                      </Button>
                    )}
                  </>
                )}
                <Button className="button-action cancel" disabled={disabled} onClick={handleCancelSave}>
                  {'Batal'}
                </Button>
              </Col>
            </Row>
          </Wrapper>
        </>
      )}
    </>
  );
}

export default BookingRevise;
