import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Label, Button } from 'reactstrap';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import FsLightbox from 'fslightbox-react';
import userPermission from '@utils/userPermission';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import UploadImage from '@components/uploads/UploadImage';
import ActionSwitch from '@components/field/ActionSwitch';
import ConfirmationModal from '@components/modal/ConfirmationModal';

const OtoklixInventorySectionDetail = (props) => {
  const { detailInventory, onResultNotification, onSubmit } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const [imageKey, setImageKey] = useState();
  const [formData, setFormData] = useState({});
  const [modalType, setModalType] = useState('');
  const [errorImage, setErrorImage] = useState('');
  const [imagePreview, setImagePreview] = useState();
  const [disableForm, setDisableForm] = useState(true);
  const [showLightbox, setShowLightbox] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [loadingSumbit, setLoadingSubmit] = useState(false);
  const [loadingUploadImage, setLoadingUploadImage] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('master', menu) || generatePermission('master', 'all'));
  };

  const allowEdit =
    allowPermission('otoklixinventoryall') ||
    allowPermission('otoklixinventorydetailall') ||
    allowPermission('otoklixinventorydetailedit');

  const optionsType = [
    {
      value: 'part',
      label: 'Produk',
    },
    {
      value: 'work',
      label: 'Jasa',
    },
  ];

  async function fetchCategoryOptions() {
    setLoadingFetchOptCategory(true);
    try {
      const response = await api.get(`v2/intools/products/categories/`);
      const categoryOptions = map(response?.data?.data?.categories, (item) => {
        return { value: item?.id, label: item?.name ?? '-' };
      });
      setOptionsCategory(categoryOptions);
      setLoadingFetchOptCategory(false);
    } catch (error) {
      setOptionsCategory([]);
      setLoadingFetchOptCategory(false);
    }
  }

  const setDefaultData = () => {
    let selectedCars = [];

    const selectedType =
      detailInventory?.product?.type == 'part'
        ? { value: 'part', label: 'Produk' }
        : detailInventory?.product?.type == 'work'
        ? { value: 'work', label: 'Jasa' }
        : null;
    const selectedBrand = detailInventory?.product?.brand_product?.id
      ? {
          value: detailInventory?.product?.brand_product?.id ?? null,
          label: detailInventory?.product?.brand_product?.name ?? '',
        }
      : null;
    const selectedComponent = detailInventory?.product?.product_component?.id
      ? {
          value: detailInventory?.product?.product_component?.id ?? null,
          label: detailInventory?.product?.product_component?.name ?? '',
        }
      : null;
    const selectedCategory = detailInventory?.product?.product_category?.id
      ? {
          value: detailInventory?.product?.product_category?.id ?? null,
          label: detailInventory?.product?.product_category?.name ?? '',
        }
      : null;
    detailInventory?.product?.product_car_compatibility?.length &&
      detailInventory?.product?.product_car_compatibility.forEach((item) => {
        const car_model = item?.car_model ?? '';
        const car_variant = item?.car_variant ?? '';
        const car_brand = item?.car_brand ?? '';
        const objSelectedCars = {
          value: car_brand + item?.car_id,
          label: car_model + ' - ' + car_variant,
          id: item?.car_id,
        };
        selectedCars.push(objSelectedCars);
      });

    const dataForm = {
      id: detailInventory?.id ?? null,
      product_id: detailInventory?.product?.id ?? null,
      sku: detailInventory?.product?.sku ?? '',
      cars: selectedCars,
      type: selectedType,
      price: Helper.formatMoneyOnField('' + detailInventory?.price, 'Rp '),
      brand: selectedBrand,
      name: detailInventory?.product?.name ?? '',
      display: detailInventory?.display_name ?? '',
      desc: detailInventory?.description ?? '',
      category: selectedCategory,
      component: selectedComponent,
      is_active: detailInventory?.is_active ? true : false,
      error_price: '',
      error_display: '',
      error_desc: '',
    };

    setFormData(dataForm);
    setImageKey(null);
    setImagePreview(detailInventory?.image_link ?? '');
  };

  const setChangeValueForm = (key, value, errorKey, errorMsg) => {
    formData[key] = value;
    if (errorKey) formData[errorKey] = errorMsg;
    setFormData({ ...formData });
  };

  const handleChangeDisplayName = (e) => {
    validateDisplayName(e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : formData?.display);
  };

  const handleChangeProductPrice = (e) => {
    validateProductPrice(Helper.formatMoneyOnField(e?.target?.value, 'Rp '));
  };

  const handleChangeDescription = (e) => {
    validateDescription(e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : formData?.desc);
  };

  const handleChangeStatus = () => {
    setChangeValueForm('is_active', !formData?.is_active);
  };

  const validateDisplayName = (value) => {
    let errorMsg = '';
    if (value.length == 0) {
      errorMsg = 'Display Name tidak boleh kosong';
    } else if (value.length < 5 || value.length > 100) {
      errorMsg = 'Format Display Name minimal 5, maksimal 100 karakter';
    }
    setChangeValueForm('display', value, 'error_display', errorMsg);
    return errorMsg ? false : true;
  };

  const validateProductPrice = (value) => {
    setChangeValueForm(
      'price',
      value,
      'error_price',
      value?.formatMoney > 99999999 ? 'Sell Price maksimal Rp 99.999.999' : ''
    );
    return value?.formatMoney > 99999999 ? false : true;
  };

  const validateDescription = (value) => {
    let errorMsg = '';
    if (value.length !== 0 && (value.length < 5 || value.length > 500)) {
      errorMsg = 'Format Deskripsi minimal 5, maksimal 500 karakter';
    }

    setChangeValueForm('desc', value, 'error_desc', errorMsg);
    return errorMsg ? false : true;
  };

  const handleLoadingUploadImage = (status) => {
    setLoadingUploadImage(status);
  };

  const handleErrorUploadImage = (msg) => {
    setErrorImage(msg);
  };

  const handlePreviewImage = () => {
    if (imagePreview) {
      setShowLightbox(!showLightbox);
    }
  };

  const handleDeleteImage = () => {
    setImageKey(null);
    setImagePreview('');
  };

  const handleUploadImage = (image) => {
    setImageKey(image.key);
    setImagePreview(image.preview);
  };

  const editForm = () => {
    setDisableForm(false);
    setLoadingUploadImage(false);
  };

  const cancelEditForm = () => {
    setModalType('Batalkan');
    setConfirmModal(true);
  };

  const handleSubmit = () => {
    const passValidateDisplayName = validateDisplayName(formData?.display);
    const passValidateProductPrice = validateProductPrice(formData?.price);
    const passValidateDescription = validateDescription(formData?.desc);
    const isValid = passValidateDisplayName && passValidateProductPrice && passValidateDescription;

    if (isValid) {
      setModalType('Simpan');
      setConfirmModal(true);
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
  };

  const handleResultNotification = (status, type, message) => {
    onResultNotification({ status: status, type: type, message: message });
  };

  const getPayload = () => {
    const payload = {
      display_name: formData?.display ?? null,
      price: formData?.price?.formatMoney ?? 0,
      description: formData?.desc,
      image_link: imageKey ? imageKey : imagePreview ? detailInventory?.image_link : null,
      is_active: formData?.is_active ? true : false,
    };

    return payload;
  };

  async function updateDetailInventory() {
    setConfirmModal(false);
    setLoadingSubmit(true);

    try {
      const response = await api.put(`v2/intools/master/otoklix-inventories/${formData?.id}`, getPayload());
      onSubmit(true);
      setDisableForm(true);
      setLoadingSubmit(false);
    } catch (error) {
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoadingSubmit(false);
    }
  }

  const restoreDetailInventory = () => {
    setDefaultData();
    setDisableForm(true);
    setConfirmModal(false);
  };

  useEffect(() => {
    setDefaultData();
  }, [detailInventory]);

  return (
    <React.Fragment>
      <FsLightbox toggler={showLightbox} sources={[imagePreview]} type="image" />
      <Card>
        <CardHeader className="bg-primary text-white mt-0">Product Detail</CardHeader>
        <CardBody>
          <Col lg={12}>
            <FormGroup row>
              <Label lg={3}>ID</Label>
              <InputField colWidth={9} inputType={'text'} disabled={true} placeholder="ID" value={formData?.id ?? ''} />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Product ID</Label>
              <InputField
                colWidth={9}
                inputType={'text'}
                disabled={true}
                placeholder="Product ID"
                value={formData?.product_id ?? ''}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Part Number</Label>
              <InputField
                colWidth={9}
                inputType={'text'}
                disabled={true}
                placeholder="Part Number"
                value={formData?.sku ?? ''}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Brand
              </Label>
              <SelectField
                colWidth={9}
                name="brand"
                placeholder="Pilih Brand"
                closeMenuOnSelect={true}
                value={formData?.brand}
                options={[]}
                disabled={true}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Nama Produk
              </Label>
              <InputField
                colWidth={9}
                inputType={'text'}
                disabled={true}
                placeholder="Nama Produk"
                value={formData?.name ?? ''}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Display Name
              </Label>
              <InputField
                colWidth={9}
                inputType={'text'}
                disabled={disableForm}
                placeholder="Display Name"
                value={formData?.display ?? ''}
                invalid={formData?.error_display ? true : false}
                errorMessage={formData?.error_display ?? ''}
                onChange={handleChangeDisplayName}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Komponen
              </Label>
              <SelectField
                colWidth={9}
                name="component"
                placeholder="Pilih Komponen"
                closeMenuOnSelect={true}
                value={formData?.component}
                options={[]}
                disabled={true}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Kategori
              </Label>
              <SelectField
                colWidth={9}
                name="category"
                placeholder="Pilih Kategori"
                closeMenuOnSelect={true}
                value={formData?.category}
                options={[]}
                disabled={true}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Tipe</Label>
              <SelectField
                colWidth={9}
                name="type"
                placeholder="Pilih Tipe"
                closeMenuOnSelect={true}
                value={formData?.type}
                options={[]}
                disabled={true}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>B2C Sell Price</Label>
              <InputField
                colWidth={9}
                inputType={'text'}
                disabled={disableForm}
                placeholder="Harga Produk"
                value={formData?.price?.formatMoneyString ?? ''}
                invalid={formData?.error_price ? true : false}
                errorMessage={formData?.error_price ?? ''}
                onChange={handleChangeProductPrice}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Car Compability</Label>
              <SelectField
                colWidth={9}
                isMulti={true}
                name="cars"
                placeholder="Pilih Car Compability"
                closeMenuOnSelect={false}
                isSelectAll={true}
                value={formData?.cars}
                options={[]}
                disabled={true}
                titlePreview={'Car Compability'}
                placeholderPreview={'Tipe Mobil'}
                labelDataPreview={'Tipe Mobil'}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Deskripsi</Label>
              <InputField
                colWidth={9}
                inputType={'textarea'}
                disabled={disableForm}
                placeholder="Deskripsi"
                value={formData?.desc ?? ''}
                invalid={formData?.error_desc ? true : false}
                errorMessage={formData?.error_desc ?? ''}
                onChange={handleChangeDescription}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Gambar Produk</Label>
              <Col lg={9}>
                <UploadImage
                  isView={disableForm}
                  image={imagePreview}
                  error={errorImage}
                  maxSize={3000}
                  maxSizeString={'3MB'}
                  nameSpace={'master_otoklix_inventory_image'}
                  identifier={formData?.id}
                  onLoading={handleLoadingUploadImage}
                  onError={handleErrorUploadImage}
                  onDelete={handleDeleteImage}
                  onPreview={handlePreviewImage}
                  onUpload={handleUploadImage}
                ></UploadImage>
                <div className="invalid-document">{errorImage}</div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Status</Label>
              <Col className="p-2">
                <ActionSwitch
                  inputName={'is_active'}
                  color="secondary"
                  isChecked={formData?.is_active}
                  customClass="mb-0"
                  editableForm={disableForm}
                  onChange={handleChangeStatus}
                />
              </Col>
            </FormGroup>
          </Col>
          <Row className="mt-3">
            {allowEdit && (
              <Col sm={12} md={12} className="section-action">
                <Button
                  className="button-action save"
                  type="button"
                  disabled={loadingSumbit || loadingUploadImage}
                  onClick={() => {
                    disableForm ? editForm() : handleSubmit();
                  }}
                >
                  {disableForm ? 'Edit' : 'Simpan'}
                </Button>
                {!disableForm && (
                  <Button
                    className="button-action cancel"
                    disabled={loadingSumbit || loadingUploadImage}
                    onClick={cancelEditForm}
                  >
                    Batalkan
                  </Button>
                )}
              </Col>
            )}
          </Row>
        </CardBody>
        <ConfirmationModal
          modal={confirmModal}
          toggle={() => setConfirmModal(!confirmModal)}
          header={`Anda yakin ingin ${modalType === 'Simpan' ? 'menyimpan ' : 'membatalkan '} ?`}
          subHeader={`Aksi ini akan mengakibatkan hasil edit master otoklix inventory ${
            detailInventory?.product?.name
          } ${modalType === 'Simpan' ? ' tersimpan' : ' tidak tersimpan'}`}
          onConfirm={() => (modalType === 'Simpan' ? updateDetailInventory() : restoreDetailInventory())}
          toggleClose={() => setConfirmModal(!confirmModal)}
        ></ConfirmationModal>
      </Card>
    </React.Fragment>
  );
};

export default OtoklixInventorySectionDetail;
