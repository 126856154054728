import React from 'react';
import { useHistory } from 'react-router-dom';
import Wrapper from '@components/wrapper/Wrapper';
import PushNotificationCreateForm from '@components/push-notifications/PushNotificationCreateForm';
import userPermission from '@utils/userPermission';
import { Row } from 'reactstrap';

function PushNotificationCreate() {
  const history = useHistory();
  const { generatePermission } = userPermission();

  if (!generatePermission('pushnotification', 'submit')) {
    history.push('/push-notifications');
  }

  const breadcrumb = [
    {
      id: 1,
      label: 'Push Notifications List',
      active: false,
      url: '/push-notifications',
    },
    {
      id: 2,
      label: 'Create Push Notification',
      active: true,
      url: '',
    },
  ];

  return (
    <React.Fragment>
      <Wrapper title="Create Push Notifications" breadcrumbData={breadcrumb}>
        <Row>
          <PushNotificationCreateForm />
        </Row>
      </Wrapper>
    </React.Fragment>
  );
}

export default PushNotificationCreate;
