import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Label, Row, Button } from 'reactstrap';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import userPermission from '@utils/userPermission';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import SelectField from '@components/field/SelectField';

const TakeRate = (props) => {
  const { id, onChangeAction, isValidationForm, onResultValidationForm, onResultNotification, onLoading } = props;
  const { generatePermission } = userPermission();
  const history = useHistory();
  const api = new API('v2');
  const [dataAgreement, setDataAgreement] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [disableForm, setDisableForm] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'otoklixservicefeeall'));
  };

  const allowShow = allowPermission('otoklixservicefeeshow');
  const allowList = allowPermission('otoklixservicefeelist');
  const allowEditDetail =
    allowPermission('otoklixservicefeedetailall') || allowPermission('otoklixservicefeedetailedit');
  const allowShowDetail =
    allowPermission('otoklixservicefeedetailall') || allowPermission('otoklixservicefeedetailshow');
  const productTakeRateOptions = Helper.generatePercentage(0, 100);
  const jasaTakeRateOptions = Helper.generatePercentage(0, 100);

  async function getAgreement() {
    setLoading(true);
    setDisableForm(true);

    try {
      const response = await api.get(`v2/intools/workshops/${id}/agreement/`);
      if (response?.data?.data) {
        setDataAgreement(response?.data?.data ?? {});
        setDisableForm(false);
      } else {
        setDataAgreement({});
        setDisableForm(true);
      }
      setLoading(false);
    } catch (error) {
      setDataAgreement({});
      setLoading(false);
      setDisableForm(true);
    }
  }

  const defaultDataForm = () => {
    const product_take_rate = find(productTakeRateOptions, (val) => {
      return val.value === dataAgreement?.product_take_rate;
    });

    const jasa_take_rate = find(jasaTakeRateOptions, (val) => {
      return val.value === dataAgreement?.service_take_rate;
    });

    const dataForm = {
      id: dataAgreement?.workshop_id,
      take_rate_product: product_take_rate,
      take_rate_jasa: jasa_take_rate,
      error_take_rate_product: '',
      error_take_rate_jasa: '',
    };

    setFormData(dataForm);
  };

  const setChangeValueForm = (key, value, errorKey, errorMsg) => {
    formData[key] = value;
    if (errorKey) formData[errorKey] = errorMsg;
    setFormData({ ...formData });
  };

  const handleChangeProductTakeRate = (e) => {
    validateProductTakeRate(e?.value >= 0 ? e : null);
  };

  const handleChangeJasaTakeRate = (e) => {
    validateJasaTakeRate(e?.value >= 0 ? e : null);
  };

  const validateProductTakeRate = (value) => {
    setChangeValueForm(
      'take_rate_product',
      value,
      'error_take_rate_product',
      value?.value >= 0 ? '' : 'Pilih Take Rate Produk terlebih dahulu'
    );
    return value?.value >= 0 ? true : false;
  };

  const validateJasaTakeRate = (value) => {
    setChangeValueForm(
      'take_rate_jasa',
      value,
      'error_take_rate_jasa',
      value?.value >= 0 ? '' : 'Pilih Take Rate Jasa terlebih dahulu'
    );
    return value?.value >= 0 ? true : false;
  };

  const handleAction = (type) => {
    if (isEdit) {
      if (type == 'cancel') {
        defaultDataForm();
        setIsEdit(false);
        onChangeAction(false, 'agreement');
        handleResultNotification(false);
      } else {
        handleSubmitForm('submit');
      }
    } else {
      setIsEdit(true);
      onChangeAction(true, 'agreement');
    }
  };

  const handleSubmitForm = (type) => {
    const isValid = validateForm();
    if (isValid) {
      setDisableForm(true);
      submitFormAgreement(type);
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
  };

  const handleValidateForm = () => {
    const isValid = validateForm();
    if (isValid) {
      handleSubmitForm();
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
    onResultValidationForm(isValid);
  };

  const validateForm = () => {
    const passValidateProduct = validateProductTakeRate(formData?.take_rate_product);
    const passValidateJasa = validateJasaTakeRate(formData?.take_rate_jasa);

    return passValidateProduct && passValidateJasa;
  };

  const handleGetPayload = () => {
    const payload = {
      product_take_rate: formData?.take_rate_product?.value ?? null,
      service_take_rate: formData?.take_rate_jasa?.value ?? null,
    };

    return payload;
  };

  async function submitFormAgreement(type) {
    const payload = handleGetPayload();
    try {
      const response = await api.put(`v2/intools/workshops/${id}/agreement/`, payload);
      handleResultNotification(true, 'success', 'Berhasil Update Agreement');
      setIsEdit(false);
      setDisableForm(false);
      setDataAgreement(response?.data?.data ?? {});
      if (type == 'submit') {
        onChangeAction(false, 'agreement');
      }
    } catch (error) {
      setDisableForm(false);
      handleResultNotification(true, 'fail', 'Gagal Update Agreement');
    }
  }

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const handleLoading = (e) => {
    setLoading(e);
  };

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (isValidationForm) {
      handleValidateForm();
    }
  }, [isValidationForm]);

  useEffect(() => {
    if (dataAgreement?.workshop_id) {
      defaultDataForm();
    }
  }, [dataAgreement]);

  useEffect(() => {
    if (!allowShow || (!allowShowDetail && !allowList)) {
      history.push('/404');
    } else if (allowShowDetail) {
      getAgreement();
    }
  }, []);

  return (
    <React.Fragment>
      <div className="mt-3" />
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <Card className="card-custom">
          <CardBody className="p-0">
            {allowShowDetail && (
              <>
                <Row className="mb-4">
                  <Col sm={12} md={4}>
                    <Row>
                      <Label lg={5} className="text-left label-required">
                        Take Rate Produk
                      </Label>
                      {!isEdit && (
                        <Label lg={7} className="text-left text-bold">
                          : <span className="ml-1">{formData?.take_rate_product?.label ?? '-'}</span>
                        </Label>
                      )}
                      {isEdit && (
                        <SelectField
                          colWidth={7}
                          name="take_rate_product"
                          placeholder="Pilih Take Rate Produk"
                          closeMenuOnSelect={true}
                          value={formData?.take_rate_product}
                          options={productTakeRateOptions}
                          disabled={disableForm}
                          errorMessage={formData?.error_take_rate_product}
                          onChange={handleChangeProductTakeRate}
                        />
                      )}
                    </Row>
                  </Col>
                  <Col sm={12} md={4}>
                    <Row>
                      <Label lg={5} className="text-left label-required">
                        Take Rate Jasa
                      </Label>
                      {!isEdit && (
                        <Label lg={7} className="text-left text-bold">
                          : <span className="ml-1">{formData?.take_rate_jasa?.label ?? '-'}</span>
                        </Label>
                      )}
                      {isEdit && (
                        <SelectField
                          colWidth={7}
                          name="take_rate_jasa"
                          placeholder="Pilih Take Rate Jasa"
                          closeMenuOnSelect={true}
                          value={formData?.take_rate_jasa}
                          options={jasaTakeRateOptions}
                          disabled={disableForm}
                          errorMessage={formData?.error_take_rate_jasa}
                          onChange={handleChangeJasaTakeRate}
                        />
                      )}
                    </Row>
                  </Col>
                  {allowEditDetail && (
                    <Col sm={12} md={4} className="section-action fx-start">
                      <Button
                        disabled={disableForm}
                        className="button-action save"
                        onClick={() => {
                          if (!disableForm) {
                            handleAction('save');
                          }
                        }}
                      >
                        {isEdit ? 'Simpan' : 'Edit Data'}
                      </Button>
                      {isEdit && (
                        <Button
                          disabled={disableForm}
                          className="button-action cancel"
                          onClick={() => {
                            if (!disableForm) {
                              handleAction('cancel');
                            }
                          }}
                        >
                          {'Batal'}
                        </Button>
                      )}
                    </Col>
                  )}
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default TakeRate;
