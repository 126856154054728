import React, { useState, useEffect } from 'react';
import { Label, FormGroup, Col, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import API from '@utils/API';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import SelectAsyncField from '@components/field/SelectAsyncField';
import Helper from '@helpers/Helper';
import SoldOut from '@assets/images/sold-out.png';
import PackageLineItems from '@components/booking/BookingPackageLineItems';
import { debounce } from 'lodash';
import newTabIcon from '@assets/icons/new-tab.svg';

function BookingReviseFormPackage(props) {
  const {
    data,
    index,
    isFleetMode,
    disabled,
    categoryOptions,
    onChangeItem,
    onChangeRemarks,
    onChangeComponent,
    onChangePromoCode,
    selectedWorkshop,
    tab,
    selectedCar,
    accountType,
    calculatePromo,
  } = props;
  const api = new API('v2');

  const [selectedPackageDetails, setSelectedPackageDetails] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [errorCategory, setErrorCategory] = useState('');
  const [selectedPackage, setSelectedPackage] = useState();
  const [loadingPackage, setLoadingPackage] = useState(false);
  const [errorPackage, setErrorPackage] = useState('');
  const [packageOptions, setPackageOptions] = useState([]);
  const [packageQuery, setPackageQuery] = useState('');
  const [sellPrice, setSellPrice] = useState({
    formatIsMoney: false,
    formatMoney: 0,
    formatMoneyString: '0',
  });
  const [packageDetails, setPackageDetails] = useState([]);
  const [loadingPackageDetails, setLoadingPackageDetails] = useState(false);
  const [packageId, setPackageId] = useState();
  const [b2bPackagePrice, setB2bPackagePrice] = useState('0');
  const [packageNetPrice, setPackageNetPrice] = useState('0');
  const [promoData, setPromoData] = useState({});
  const [promoCode, setPromoCode] = useState('');

  const fetchPackage = async (selectedCategories, query = '', source = '', callback) => {
    const payload = {
      variant_car_id: selectedCar?.car_details?.id,
      service_category_id: selectedCategories,
      query,
      customer_type: accountType,
    };
    let data = [];

    if (source === 'category-filter') setLoadingPackage(true);

    await api
      .post(`/v2/intools/workshops/${selectedWorkshop?.id}/packages/`, payload)
      .then((res) => {
        const transformedData = map(res.data.data, (item) => {
          return {
            value: item.id,
            label: item.name,
            ...item,
          };
        });

        data = transformedData;
        callback(data);
        setPackageOptions(data);
        if (source === 'category-filter') setLoadingPackage(false);
      })
      .catch((err) => {
        setPackageOptions(data);
        if (source === 'category-filter') setLoadingPackage(false);
      });

    return data;
  };

  const getPackage = (query, callback) => {
    setPackageQuery(query);

    const res = fetchPackage(selectedCategory?.value, query, 'search-filter', callback);
    return res;
  };

  const getPackageWithCategory = (category) => {
    fetchPackage(category, '', 'category-filter');
  };

  const getPackageDetail = async (value) => {
    setLoadingPackageDetails(true);
    await api
      .get(
        `v2/intools/workshops/packages/${value?.id}?variant_car_id=${selectedCar?.car_details?.id}&service_category_id=${selectedCategory?.value}&customer_type=${accountType}`
      )
      .then((res) => {
        const data = res.data.data;
        setPackageId(+data.package_id);
        const transformedData = map(data.package_details, (item) => {
          let productValue;
          if (item.products.length < 2) productValue = item.products[0];
          return {
            value: productValue && productValue?.is_sellable ? productValue : undefined,
            ...item,
          };
        });
        const calculationData = map(data.package_details, (item) => {
          let productValue;
          if (item.products.length < 2) productValue = item.products[0];
          return {
            package_detail_id: item?.products[0]?.package_details_id,
            package_id: +data.package_id,
          };
        });
        getItemCalculation(calculationData);
        setPackageDetails(transformedData);
        setSelectedPackage(value);
        setSellPrice({
          moneyValue: `IDR ${Helper.numberWithCommas(data.total_price ?? 0)}`,
          value: data.total_price ?? 0,
        });
        const tempItem = {
          ...value,
          is_new_data: true,
          service_type: 'package',
          package_details: [...transformedData],
        };
        onChangeItem(tempItem, index);
        setLoadingPackageDetails(false);
      })
      .catch((err) => {
        console.log('failed get package detail => ', err.response);
        setLoadingPackageDetails(false);
      });
  };

  const getItemCalculation = async (itemCalculation) => {
    const payloadItemCalculation = {
      booking_type: accountType,
      items: itemCalculation,
    };
    await api
      .post(`v2/intools/bookings/item_calculation/`, payloadItemCalculation)
      .then((res) => {
        const data = res.data.data;
        setB2bPackagePrice(data[0].total_price);
        setPackageNetPrice(data[0].total_nett_price);
      })
      .catch((err) => {
        console.log('failed get Item Calculation => ', err.response);
      });
  };

  const handleChangeCategories = (value) => {
    setSelectedCategory(value);
    getPackageWithCategory(value.value);

    setSelectedPackage();
    setSellPrice({
      formatIsMoney: false,
      formatMoney: 0,
      formatMoneyString: '0',
    });
    setPackageDetails([]);

    if (!value) {
      setErrorCategory('Kategori harus diisi!');
    } else {
      setErrorCategory('');
    }
  };

  const handleChangePackage = (value) => {
    setSelectedPackage(value);

    if (!value) {
      setErrorPackage('Paket harus diisi!');
    } else {
      setErrorPackage('');
    }

    const categoriesIndex = findIndex(categoryOptions, (val) => {
      return val?.value === value?.category_id;
    });

    if (categoriesIndex !== -1) {
      setSelectedCategory(categoryOptions[categoriesIndex]);
      const tempValue = {
        ...value,
        category: categoryOptions[categoriesIndex],
      };
      getPackageDetail(tempValue);
    }

    handleChangeSellPrice(value.price);
  };

  const handleChangePackageDetail = (value, subIndex) => {
    const tempPackageDetail = packageDetails;
    tempPackageDetail[subIndex].value = value;
    const itemCalculation = [];
    for (let x in tempPackageDetail) {
      let objt = { package_id: packageId, package_detail_id: tempPackageDetail[x]?.value?.package_details_id ?? null };
      itemCalculation.push(objt);
    }
    getItemCalculation(itemCalculation);
    setPackageDetails(tempPackageDetail);
    const tempItem = {
      ...selectedPackage,
      is_new_data: true,
      category: selectedCategory,
      service_type: 'package',
      package_details: [...tempPackageDetail],
    };
    onChangeItem(tempItem, index);
  };

  const handleChangeSellPrice = (value, hasQtyModifier) => {
    const pricePerItem = hasQtyModifier?.is_applied ? value / hasQtyModifier?.qty : value;

    try {
      const sellprice = Helper.formatMoneyOnField(pricePerItem);

      setSellPrice(sellprice);
    } catch {
      setSellPrice({
        formatIsMoney: false,
        formatMoney: pricePerItem,
        formatMoneyString: Helper.formatMoney(pricePerItem),
      });
    }
  };

  const handleDefaultLoadPackage = () => {
    if (tab === 'package' && selectedCar) {
      fetchPackage('', '', 'search-filter');
    } else {
      setPackageOptions([]);
    }
  };

  const onPromoCodeChange = (value) => {
    setPromoCode(value);
  };

  const checkPromoCode = async () => {
    let package_details = [];
    map(data?.package_details, (item) => {
      package_details.push(Number(item?.package_detail_id));
    });

    const packages = {
      index: index,
      promo_code: promoCode,
      package_id: data?.package_id,
      package_details: package_details,
      source: 'revise',
    };
    try {
      const checkPromo = await calculatePromo(packages, 'package');
      if (checkPromo) onChangePromoCode(promoCode, index);
    } catch (error) {
      console.error(error);
    }
    getPromoDetail();
  };

  const getPromoDetail = () => {
    api
      .get(`v2/intools/campaigns/voucher-campaign/${promoCode}/`)
      .then((response) => {
        const data = response?.data?.data ?? {};
        const promo = {
          campaign_id: data?.campaign_id,
          campaign_name: data?.campaign_name,
          voucher_code: promoCode,
          status: 'success',
        };
        setPromoData(promo);
      })
      .catch((err) => {
        const errMessage = err?.response?.data?.error?.message ?? 'promo code may not be blank';
        const promo = {
          status: 'danger',
          message: errMessage,
        };
        setPromoData(promo);
      });
  };

  const resetField = () => {};

  useEffect(() => {
    resetField();
  }, [selectedWorkshop, tab]);

  useEffect(() => {
    handleDefaultLoadPackage();
  }, [selectedCar]);

  const onComponentChange = (value, indexComponent) => {
    onChangeComponent(value, index, indexComponent);
  };

  const onResetItem = () => {
    setSelectedCategory('');
    setSelectedPackageDetails('');
    setPackageDetails([]);
    setB2bPackagePrice('');
    setPackageNetPrice('');
  };

  const onRemarksChange = (value) => {
    onChangeRemarks(value, index);
  };

  useEffect(() => {
    if (data?.voucher_code) setPromoCode(data?.voucher_code);
  }, [data?.voucher_code]);

  useEffect(() => {
    if (data?.promo_data) setPromoData(data?.promo_data);
  }, [data?.promo_data]);

  useEffect(() => {
    if (promoData?.status === 'success') onChangePromoCode(promoCode, index);
    if (promoData?.status === 'danger') onChangePromoCode('', index);
  }, [promoData]);

  return (
    <React.Fragment>
      <div className="service-tab">
        {isFleetMode && data?.is_approve !== undefined && data?.is_approve !== null && (
          <FormGroup row>
            <Label lg={3}>Status</Label>
            <Label
              lg={9}
              className={
                data?.is_approve ? 'text-left font-weight-bold color-green' : 'text-left font-weight-bold color-red'
              }
            >
              <div>{data?.is_approve ? 'APPROVED' : 'REJECTED'}</div>
            </Label>
          </FormGroup>
        )}
        {isFleetMode && data?.is_draft_change !== undefined && data?.is_draft_change !== null && data?.is_draft_change && (
          <FormGroup row>
            <Label lg={3}>Label</Label>
            <span className="ml-2 badge-custom badge-custom-draft">{'Draft'}</span>
          </FormGroup>
        )}
        {data?.is_new_data && (
          <Row>
            <Col>
              <Label className="cursor-pointer float-right mb-3" onClick={() => onResetItem(index)}>
                <u>Reset</u>
              </Label>
            </Col>
          </Row>
        )}
        <FormGroup row>
          <Label lg={3} className="label-required">
            Kategori
          </Label>
          <SelectField
            colWidth={9}
            title="Kategori"
            placeholder={'Pilih Kategori'}
            disabled={data?.disable_category || disabled}
            closeMenuOnSelect={true}
            options={categoryOptions}
            errorMessage={data?.error_category ?? ''}
            value={data?.is_new_data ? selectedCategory : data?.category}
            onChange={(e) => {
              if (!data?.disable_category && !disabled) {
                handleChangeCategories(e);
                setSelectedPackage();
                setSelectedPackageDetails('');
                setPackageOptions([]);
              }
            }}
          />
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="label-required">
            Paket
          </Label>
          {data?.is_new_data ? (
            <>
              <SelectAsyncField
                id="package"
                colWidth={9}
                isDisabled={!selectedCar || loadingPackage}
                value={selectedPackage || ''}
                defaultOptions={packageOptions}
                getOptionLabel={(e) => (
                  <span>
                    {e.is_fulfilled_by_otoklix == 1 ? (
                      <span className="dropdown-tag">FBO</span>
                    ) : e.price_origin ? (
                      <span className="dropdown-tag">{e.price_origin}</span>
                    ) : null}
                    {e.label}
                    {!e.is_compatible && <PackageLineItems items={e} />}
                    {!e?.is_sellable && <img id="availability" src={SoldOut} width={15} className="ml-1 mt-0" />}
                  </span>
                )}
                getOptionValue={(e) => e.value}
                loadOptions={debounce(getPackage, 600)}
                needInputChange={true} //adjust note
                onInputChange={() => {
                  //adjust note
                }}
                onChange={handleChangePackage}
                invalid={!selectedPackage}
                errorMessage={errorPackage}
                placeholder="Pilih Paket..."
                cacheOptions={false}
                loading={loadingPackage}
                isOptionDisabled={(e) => !e?.is_sellable}
              />
            </>
          ) : (
            <>
              <SelectField
                colWidth={9}
                title="Paket"
                placeholder={'Pilih Paket'}
                disabled={data?.disable_package || disabled}
                closeMenuOnSelect={true}
                options={data?.package_options ?? []}
                errorMessage={data?.error_package ?? ''}
                value={data?.package}
              />
            </>
          )}
        </FormGroup>
        {loadingPackageDetails ? (
          <FormGroup row>
            <Label lg={9}>Loading package details ...</Label>
          </FormGroup>
        ) : packageDetails?.length > 0 ? (
          map(packageDetails, (item, index) => (
            <FormGroup row>
              <Label lg={3} className="label-required">
                {item?.component_label}
              </Label>
              <SelectField
                id={`package-detail-${index}`}
                colWidth={9}
                options={item?.products}
                getOptionLabel={(e) => (
                  <span>
                    {e.is_fbo && <span className="dropdown-tag">FBO</span>}
                    {e.name}
                    {!e.is_compatible && <PackageLineItems items={e} />}
                    {!e?.is_sellable && <img id="availability" src={SoldOut} width={15} className="ml-1 mt-0" />}
                  </span>
                )}
                getOptionValue={(e) => e.product_id}
                onChange={(e) => handleChangePackageDetail(e, index)}
                value={item?.value}
                placeholder={item?.prompt}
                isOptionDisabled={(e) => !e?.is_sellable}
              />
            </FormGroup>
          ))
        ) : null}
        <FormGroup row>
          <Label lg={3}>Keterangan</Label>
          <InputField
            colWidth={9}
            disabled={!data?.is_new_data}
            placeholder="Keterangan"
            value={data?.note}
            onChange={(e) => onRemarksChange(e)}
          />
        </FormGroup>
        {data?.is_new_data ? (
          <>
            <FormGroup row>
              <Label lg={3}>B2B Package Price</Label>
              <InputField
                colWidth={9}
                placeholder="B2B Package Price"
                value={`IDR ${Helper.numberWithCommas(b2bPackagePrice ?? 0)}`}
                disabled
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Package Nett Price</Label>
              <InputField
                colWidth={9}
                placeholder="Package Nett Price"
                value={`IDR ${Helper.numberWithCommas(packageNetPrice ?? 0)}`}
                disabled
              />
            </FormGroup>
          </>
        ) : (
          <></>
        )}

        {data?.components?.length > 0 &&
          !data?.is_new_data &&
          map(data?.components, (item, indexComponent) => {
            return (
              <FormGroup row>
                <Label lg={3} className="label-required">
                  {item?.label}
                </Label>
                <SelectField
                  colWidth={9}
                  placeholder={item?.prompt ?? ''}
                  disabled={item?.is_disabled || disabled}
                  closeMenuOnSelect={true}
                  options={item?.product_options ?? []}
                  errorMessage={item?.error_component ?? ''}
                  value={item?.product}
                  onChange={(e) => {
                    if (!item?.is_disabled && !disabled) {
                      onComponentChange(e, indexComponent);
                    }
                  }}
                />
              </FormGroup>
            );
          })}
        <FormGroup row>
          <Label lg={3}>Kode Promo</Label>
          <InputField
            colWidth={6}
            placeholder={'Masukkan Kode Promo'}
            value={promoCode}
            valid={data?.promo_status?.color === 'success'}
            invalid={data?.promo_status?.color === 'danger' && data?.voucher_code !== '' ? true : false}
            errorMessage={
              data?.promo_status?.color === 'danger' && data?.voucher_code !== '' ? data?.promo_status?.message : ''
            }
            onChange={(e) => {
              if (!data?.disable_promo_code && !disabled) {
                setPromoData({ ...promoData, message: '' });
                onPromoCodeChange(e?.target?.value.toUpperCase());
              }
            }}
          />
          <Col lg={3}>
            <Button id="create-booking" color="purple" className="w-100" onClick={checkPromoCode}>
              Pakai
            </Button>
          </Col>
          <Col lg={3} />
          <Col lg={9} className="mt-2">
            {data?.promo_status?.message && (
              <>
                {data?.promo_status.color === 'success' && <span>{data?.promo_status?.message}. </span>}
                {promoData?.campaign_id && promoData?.campaign_name && (
                  <span>
                    Detail campaign <span className="promo-name">{promoData?.campaign_name ?? '-'}</span> dapat diakses
                    disini{' '}
                    <span>
                      <Link to={`/campaigns/campaign/detail/${promoData?.campaign_id ?? ''}`} target="_blank">
                        <img src={newTabIcon} height={18} className="icon-new-tab pb-1 ml-1" />
                      </Link>
                    </span>
                  </span>
                )}
              </>
            )}
          </Col>
        </FormGroup>
        {isFleetMode && data?.note !== undefined && (
          <FormGroup row>
            <Label lg={3}>Catatan</Label>
            <Label lg={9} className="text-left">
              <div>{!data?.note ? '-' : data?.note}</div>
            </Label>
          </FormGroup>
        )}
      </div>
    </React.Fragment>
  );
}

export default BookingReviseFormPackage;
