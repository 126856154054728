import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import Wrapper from '@components/wrapper/Wrapper';
import API from '@utils/API';
import CustomerForms from '@components/customer/CustomerForms';
import { assign, find, map } from 'lodash';
import { flagTypeOption, userTypeOption } from '../../utils/Constants';
import Helper from '@helpers/Helper';
import userPermission from '@utils/userPermission';
import B2BCustomerForms from '@components/customer/B2BCustomerForms';
import { getCustomerGroupOptions } from '@store/customer';

function CustomerDetail() {
  const { id } = useParams();
  const api = new API('v2');
  const dispatch = useDispatch();
  const { generatePermission } = userPermission();

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [customerType, setCustomerType] = useState('b2c');
  const [userType, setUserType] = useState(undefined);
  const [flagType, setFlagType] = useState(undefined);
  const [loyaltyPoints, setLoyaltyPoints] = useState(undefined);
  const [origin, setOrigin] = useState('detail');
  const [notification, setNotification] = useState({});
  const [hasNotification, setHasNotification] = useState(false);
  const [userCars, setUserCars] = useState([]);
  const [userLocations, setUserLocations] = useState();
  const [wsList, setWSList] = useState([]);
  const [loadingLoadWorkshop, setLoadingLoadWorkshop] = useState(false);
  const [loadingLoadPIC, setLoadingLoadPIC] = useState(false);
  const [listPIC, setListPIC] = useState([]);
  const [listCustomerGroup, setListCustomerGroup] = useState([]);
  const [allCustomerGroup, setAllCustomerGroup] = useState([]);
  const [loadingCustomerGroup, setLoadingCustomerGroup] = useState(false);

  const breadcrumb = [
    {
      id: 1,
      label: 'List Customer',
      active: false,
      url: '/customer',
    },
    {
      id: 2,
      label: `${origin === 'detail' ? 'Detail' : 'Edit'} Customer`,
      active: false,
      url: '',
    },
    {
      id: 3,
      label: initialValues?.customer_name ?? '',
      active: true,
      url: '',
    },
  ];

  const loadListWorkshop = async (search) => {
    setLoadingLoadWorkshop(true);
    if (search) {
      return await api
        .get(`v2/intools/customers/workshops/?limit=100&search=${search}`)
        .then((result) => {
          setLoadingLoadWorkshop(false);
          return result.data.data;
        })
        .catch(() => {
          setLoadingLoadWorkshop(false);
        });
    }
  };

  const loadListPIC = async (search = '') => {
    setLoadingLoadPIC(true);
    return await api
      .get(`v2/intools/customers/b2b_pic/?limit=100&search=${search}`)
      .then((result) => {
        setLoadingLoadPIC(false);
        setListPIC(result.data.data);
        return result.data.data;
      })
      .catch(() => {
        setLoadingLoadPIC(false);
      });
  };

  const loadUserDetail = async () => {
    setLoading(true);
    const response = await api.get(`v2/intools/customers/details/?customer_id=${id}`);
    if (response.data.data) {
      const data = response?.data?.data;
      const {
        profile_picture,
        username,
        phone_no,
        email,
        user_type,
        flag_status,
        nickname,
        pic_name,
        pic_phone,
        pic_email,
        status,
        product_markup,
        product_discount,
        service_markup,
        service_discount,
        internal_notes,
        blacklisted_workshops,
        config_documents,
        company_address,
        billing_address,
        b2b_pic,
        customer_group,
        warranty_facility,
        terms_of_payment,
        virtual_account
      } = data;

      const flag = find(flagTypeOption, { value: flag_status });
      const userType = find(userTypeOption, { value: user_type });
      const customer_type = data?.b2b_customer ? 'b2b' : 'b2c';

      let userDetail = {
        photo: profile_picture || '',
        customer_name: username,
        phone_number: phone_no?.toLowerCase() === 'none' ? '' : phone_no,
        email,
        user_type: userType,
        flag,
      };

      let wsBlacklist = [];
      map(blacklisted_workshops, (item) => {
        wsBlacklist.push({ ...item, label: item?.name, value: item?.id });
      });

      if (customer_type === 'b2b') {
        assign(userDetail, {
          nickname,
          pic_name,
          pic_phone,
          pic_email: pic_email ? Helper.seperateEmail(pic_email) : '',
          b2b_customer_status: status,
          product_markup: { value: product_markup ?? 0, label: `${product_markup ?? 0}%` },
          service_markup: { value: service_markup ?? 0, label: `${service_markup ?? 0}%` },
          product_discount: { value: product_discount ?? 0, label: `${product_discount ?? 0}%` },
          service_discount: { value: service_discount ?? 0, label: `${service_discount ?? 0}%` },
          internal_notes,
          workshop_blacklist: wsBlacklist,
          config_documents,
          company_address,
          billing_address,
          b2b_pic,
          customer_group,
          warranty_facility,
          terms_of_payment,
          virtual_account
        });
      }

      setInitialValues(userDetail);
      setCustomerType(customer_type);
      setUserType(data?.user_type || '');
      setFlagType(data?.flag_status || '');
      setLoyaltyPoints(data?.loyalty_points || '');
    }
    setLoading(false);
  };

  const loadUserLocation = async () => {
    const response = await api.get(`v2/intools/customers/${id}/locations/`);

    setUserLocations(response?.data?.data);
  };

  const loadUserCar = async () => {
    const response = await api.get(`v2/intools/customers/customer-car/${id}/`);

    setUserCars(response?.data?.data ?? []);
  };

  const loadAllCustomerGroup = async () => {
    setLoadingCustomerGroup(true);
    dispatch(getCustomerGroupOptions())
      .then((response) => {
        setAllCustomerGroup(response.payload || []);
        setListCustomerGroup(response.payload || []);
      })
      .catch((err) => console.error('Failed to fetch customer group options', err))
      .finally(() => setLoadingCustomerGroup(false));
  };

  const loadCustomerGroupOptions = async (search = '') => {
    setLoadingCustomerGroup(true);
    if (search) {
      const result = allCustomerGroup?.filter((item) => {
        return item.name.toLowerCase().includes(search.toLowerCase());
      });

      setListCustomerGroup(result);
      setLoadingCustomerGroup(false);
      return result;
    }

    setListCustomerGroup(allCustomerGroup);
    setLoadingCustomerGroup(false);
    return allCustomerGroup;
  };

  const handleChangeOrigin = (origin) => {
    setOrigin(origin);
  };

  const handleEditForm = (status) => {
    setCustomerType(status);
  };

  const handleResultNotification = (notification) => {
    const { status, type, message } = notification;
    if (status) {
      setNotification({
        type: type,
        message: message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotification(status);
    setTimeout(() => {
      setHasNotification(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotification(false);
    setNotification({});
  };

  const allowPermission = (menu, type = 'page') => {
    const allowAccess = !!(generatePermission('customer', menu) || generatePermission('customer', 'all'));
    if (!allowAccess && type !== 'element') return history.push('/404');
    return allowAccess;
  };

  const allowListCar = allowPermission('usercarlist', 'element') || allowPermission('usercarall', 'element');

  useEffect(() => {
    if (id) {
      loadUserDetail();
      if (allowPermission('location', 'element')) loadUserLocation();
      if (allowListCar) loadUserCar();
    }
  }, [id]);

  useEffect(() => {
    allowPermission(origin);
  }, [origin]);

  useEffect(() => {
    loadListPIC();
    loadAllCustomerGroup();
  }, []);

  // render main content page
  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <React.Fragment>
        <Wrapper
          title="Detail Pelanggan"
          breadcrumbData={breadcrumb}
          showNotification={hasNotification}
          notification={notification}
          onCloseNotification={handleCloseNotification}
        >
          {customerType == 'b2c' ? (
            <CustomerForms
              id={id}
              initialValues={initialValues}
              origin={origin}
              handleChangeOrigin={handleChangeOrigin}
              handleEditForm={handleEditForm}
              activeCustomerType={customerType}
              loadUserDetail={loadUserDetail}
              loadUserCar={loadUserCar}
              loadListWorkshop={loadListWorkshop}
              userLocations={userLocations}
              userCars={userCars}
              handleResultNotification={handleResultNotification}
              wsList={wsList}
              loadingLoadWorkshop={loadingLoadWorkshop}
              loyaltyPoints={loyaltyPoints}
              loadListPIC={loadListPIC}
              loadingLoadPIC={loadingLoadPIC}
              listPIC={listPIC}
            />
          ) : (
            <B2BCustomerForms
              id={id}
              initialValues={initialValues}
              origin={origin}
              handleChangeOrigin={handleChangeOrigin}
              handleEditForm={handleEditForm}
              activeCustomerType={customerType}
              loadUserDetail={loadUserDetail}
              loadUserCar={loadUserCar}
              loadListWorkshop={loadListWorkshop}
              userLocations={userLocations}
              userCars={userCars}
              handleResultNotification={handleResultNotification}
              wsList={wsList}
              loadingLoadWorkshop={loadingLoadWorkshop}
              loyaltyPoints={loyaltyPoints}
              loadListPIC={loadListPIC}
              loadingLoadPIC={loadingLoadPIC}
              listPIC={listPIC}
              listCustomerGroup={listCustomerGroup}
              loadCustomerGroupOptions={loadCustomerGroupOptions}
              loadingCustomerGroup={loadingCustomerGroup}
            />
          )}
        </Wrapper>
      </React.Fragment>
    );
  };

  return renderContent();
}

export default CustomerDetail;
