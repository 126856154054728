import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardHeader, CardBody, Col, Badge } from 'reactstrap';
import moment from 'moment';
import API from '@utils/API';
import Loading from '@components/loading/Loading';
import userPermission from '@utils/userPermission';
import DataTable from 'react-data-table-component';
import CustomPagination from '@components/pagination/CustomPagination';

const ProductSectionHistory = (props) => {
  const { idProduct } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const allowPermission = (menu) => {
    return !!(generatePermission('master', menu) || generatePermission('master', 'all'));
  };

  const allowList = allowPermission('productall') || allowPermission('productlog');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        name: 'Timestamp',
        selector: (row) =>
          moment(row?.timestamp).isValid() ? moment(row?.timestamp).format('MMM DD YYYY , HH:mm') : '-',
        minWidth: '200px',
        sortable: true,
      },
      {
        name: 'Agent',
        selector: (row) => row?.agent ?? '-',
        minWidth: '200px',
        sortable: true,
      },
      {
        name: 'Type Change',
        minWidth: '210px',
        selector: (row) => <Badge className="p-2 badge-dark">{row?.type_change ?? '-'}</Badge>,
        sortable: true,
        wrap: true,
      },
    ],
    []
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchLogHistory() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/master/products/${idProduct}/logs/`, { params });
      setData(response?.data?.data?.logs ?? []);
      setTotalRows(response?.data?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
    }
  }

  const getParamData = () => {
    let params = {
      id: 1,
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.data?.pagination?.total_page) {
      setPage(response?.data?.data?.pagination?.total_page);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  useEffect(() => {
    if (page && pageLimit) {
      fetchLogHistory();
    }
  }, [page, pageLimit]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-primary text-white mt-0">Log History</CardHeader>
        <CardBody>
          <Col sm={12} md={12} className="p-0">
            <DataTable
              persistTableHead
              highlightOnHover
              noHeader={true}
              progressPending={loading}
              data={data}
              className="table dt-responsive table-custom"
              noDataComponent={noDataComponent}
              progressComponent={<Loading />}
              customStyles={customStylesHeaderTable}
              columns={allowList ? columns : []}
            />
          </Col>
          <Col sm={12} md={12} className="p-0 mt-2">
            {data?.length >= 1 && !loading && allowList && (
              <CustomPagination
                page={page}
                pageLimit={pageLimit}
                short={true}
                totalRows={data?.length ?? 0}
                totalAllRows={totalRows}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                handleChangeRowPerPage={handleChangeRowPerPage}
              ></CustomPagination>
            )}
          </Col>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ProductSectionHistory;
