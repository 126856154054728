import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Helper from '@helpers/Helper';
import { map } from 'lodash';
import moment from 'moment';
import { BASE64_OTOKLIX_LOGO } from '@utils/Constants';

export default class PhotoServicesPdf {
  static print(content, bookingCode) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'potrait',
      content: content,
      header: function (currentPage, pageCount) {
        return [
          {
            image: 'logo',
            width: 100,
            style: {
              alignment: 'left',
              fontSize: 8,
            },
            margin: [40, 30, 0, 0],
          },
        ];
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Halaman ' + currentPage.toString() + ' dari ' + pageCount,
            alignment: 'center',
            bold: true,
            color: '#d6d8e7',
            fontSize: 10,
          },
        ];
      },
      pageMargins: [40, 70, 40, 40],
      images: {
        logo: BASE64_OTOKLIX_LOGO,
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 30, 0, 0],
          alignment: 'center',
        },
        columnTop: {
          color: 'red',
        },
        paddingLeftRightSectionTop: {
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5], //left, top, right, bottom
        },
        tableContent: {
          margin: [0, 0, 0, 0],
        },
        tableDash: {
          margin: [-50, 5, 0, -50],
        },
        tableSpace: {
          margin: [0, -5, 0, 0],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
        tableStyle: {
          border: '',
          fontSize: 10,
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download(`Foto Bukti Servis ${bookingCode ?? ''}`);
  }

  static create(data) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const summary = map(data?.document, (item, index) => {
      const photosOdd = map(item?.photo, (dt, idx) => {
        if (idx % 2 === 1) {
          return {
            image: dt ?? BASE64_OTOKLIX_LOGO,
            margin: [5, 5, 0, 5],
            width: 180,
            alignment: 'left',
          };
        }
      });

      const photosEven = map(item?.photo, (dt, idx) => {
        if (idx % 2 === 0) {
          return {
            image: dt ?? BASE64_OTOKLIX_LOGO,
            margin: [5, 5, 0, 5],
            width: 180,
            alignment: 'left',
          };
        }
      });

      return [
        { text: item?.label, margin: [0, 10, 0, 0] },
        {
          columns: [
            {
              stack: photosEven?.filter((obj) => obj?.image),
            },
            {
              stack: photosOdd?.filter((obj) => obj?.image),
            },
          ],
          columnGap: 10,
        },
      ];
    });

    const content = [
      {
        text: 'DOKUMENTASI PENGERJAAN KENDARAAN',
        style: 'tableContent',
        alignment: 'center',
        bold: true,
      },
      {
        text: [{ text: 'Tipe Mobil : ', bold: true }, data?.car_type ?? '-'],
        margin: [0, 20, 0, 3],
      },
      {
        text: [{ text: 'Plat Nomor : ', bold: true }, data?.license_plate ?? '-'],
        margin: [0, 0, 0, 3],
      },
      {
        text: [{ text: 'Tanggal : ', bold: true }, data?.order_complete_date ?? '-'],
        margin: [0, 0, 0, 3],
      },
      {
        text: [{ text: 'Kode Booking : ', bold: true }, data?.booking_code ?? '-'],
        margin: [0, 0, 0, 15],
      },
      {
        table: {
          headerRows: 1,
          dontBreakRows: false,
          keepWithHeaderRows: true,
          widths: [80, '*'],
          body: [
            [
              { text: 'Nama', bold: true },
              { text: 'Foto', bold: true },
            ],
            ...summary,
          ],
        },
        margin: [0, 20, 0, 10],
      },
    ];

    return content;
  }
}
