import React, { useEffect, useState } from 'react';
import { Col, Label, Row } from 'reactstrap';
import UploadImagePo from '@components/uploads/UploadImagePo';

const InventoryUploadImage = (props) => {
  const { onResultNotification, onSelectImage } = props;

  const [listImage, setListImage] = useState([]);
  const [errorImage, setErrorImage] = useState('');

  const handleDeleteImage = (index) => {
    const filteredImage = listImage.filter((item, i) => +i !== +index);
    setListImage(filteredImage);
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: 'fail',
      message: message,
    };
    onResultNotification(notification);
  };

  const handleErrorUploadImage = (msg) => {
    msg !== '' && handleResultNotification(true, 'fail', msg);
    setErrorImage(msg);
  };

  const handleSelectImage = (file) => {
    const tempDataImage = { isEmpty: false, file };
    const tempListImage = [...listImage, tempDataImage];
    setListImage(tempListImage);
  };

  useEffect(() => {
    onSelectImage(listImage);
  }, [listImage]);

  return (
    <React.Fragment>
      <Row className="d-flex justify-content-between">
        <Col md={3} lg={3} sm={12} className="text-right">
          <Label>Attachments</Label>
        </Col>
        <Col md={9} lg={9} sm={12}>
          {listImage.map((item, index) => (
            <UploadImagePo
              key={index}
              isEmpty={item?.isEmpty}
              imageFile={item}
              error={errorImage}
              nameSpace={'campaign_banner'}
              maxSize={5072}
              maxSizeString={`5MB`}
              onError={handleErrorUploadImage}
              onDelete={() => handleDeleteImage(index)}
              onSelectImage={handleSelectImage}
              useProps={false}
            />
          ))}
          {+listImage.length < 5 && (
            <UploadImagePo
              error={errorImage}
              nameSpace={'campaign_banner'}
              maxSize={5072}
              maxSizeString={`5MB`}
              onError={handleErrorUploadImage}
              onSelectImage={handleSelectImage}
              isButton
            />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InventoryUploadImage;
