import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import WarningIcon from '@assets/icons/warning.svg';
import { Row, Col, Card, CardBody, CardHeader, FormGroup, Label } from 'reactstrap';
import ApprovalNote from '@components/modal/ApprovalNote';
import { filter } from 'lodash';

function BookingDetailFleetService(props) {
  const { detailData, title, customer_note } = props;

  const [itemNote, setItemNote] = useState('');
  const [hasModalApprovalNote, setHasModalApprovalNote] = useState(false);

  const handleClickNote = (note) => {
    setItemNote(note);
    setHasModalApprovalNote(true);
  };

  const PackageItems = detailData?.items?.packages;
  const products = detailData?.items?.products?.product_items ?? [];
  const services = detailData?.items?.services?.service_items ?? [];
  const Items = [...products, ...services];
  const InventoryItems = filter(Items, (x) => !x?.is_custom);
  const InventoryProducts = filter(InventoryItems, (x) => x?.product_type == 'part');
  const InventoryServices = filter(InventoryItems, (x) => x?.product_type == 'work');
  const CustomItems = filter(Items, (x) => x?.is_custom);
  const CustomProducts = filter(CustomItems, (x) => x?.product_type == 'part');
  const CustomServices = filter(CustomItems, (x) => x?.product_type == 'work');

  const parsingDetailItemService = (detailItem) => {
    return {
      name: detailItem?.name ?? '',
      qty: detailItem?.qty ?? 0,
      is_approve: detailItem?.is_approve == false ? false : true,
      note: detailItem?.note ?? '',
    };
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-primary text-white mt-0">{title}</CardHeader>
        <CardBody className="pb-0">
          <FormGroup row className="mb-0">
            <Col lg={12} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: detailData?.service_demand?.replace(/\n/g, '<br />') ?? '-',
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </FormGroup>

          {false ? (
            <FormGroup row className="mb-3">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group">Paket</span>
                  </Col>
                </Row>
              </Col>
              {map(PackageItems, (item) => {
                item = parsingDetailItemService(item);
                return (
                  <Col lg={12} sm={12}>
                    <>
                      <Row>
                        <Col lg={12} sm={12} className="col-summary">
                          <span className="detail-group-service">ITEM NAME</span>
                        </Col>
                      </Row>

                      <>
                        <Row>
                          <Col lg={12} sm={12} className="col-summary">
                            <>
                              <span
                                className={
                                  item?.is_approve ? 'detail-group-item-name' : 'detail-group-item-name text-danger'
                                }
                              >
                                {`1 x DETAIL NAME`}
                                {item?.note && (
                                  <img
                                    src={WarningIcon}
                                    width={14}
                                    className="ml-1 pointer-cursor"
                                    onClick={() => handleClickNote(item?.note)}
                                  />
                                )}
                              </span>
                            </>
                          </Col>
                        </Row>
                      </>
                    </>
                  </Col>
                );
              })}
            </FormGroup>
          ) : null}

          {false ? (
            <FormGroup row className="mb-3">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group">Items</span>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} sm={12}>
                {InventoryProducts?.length > 0 ? (
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="detail-group-service">Produk</span>
                    </Col>
                  </Row>
                ) : null}
                {map(InventoryProducts, (item) => {
                  item = parsingDetailItemService(item);
                  return (
                    <>
                      <Row>
                        <Col lg={12} sm={12} className="col-summary">
                          <span
                            className={
                              item?.is_approve ? 'detail-group-item-name' : 'detail-group-item-name text-danger'
                            }
                          >
                            {`${item?.qty} x ${item?.name}`}
                            {item?.note && (
                              <img
                                src={WarningIcon}
                                width={14}
                                className="ml-1 pointer-cursor"
                                onClick={() => handleClickNote(item?.note)}
                              />
                            )}
                          </span>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>

              <Col lg={12} sm={12}>
                {InventoryServices?.length > 0 ? (
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="detail-group-service">Jasa</span>
                    </Col>
                  </Row>
                ) : null}
                {map(InventoryServices, (item) => {
                  item = parsingDetailItemService(item);
                  return (
                    <>
                      <Row>
                        <Col lg={12} sm={12} className="col-summary">
                          <span
                            className={
                              item?.is_approve ? 'detail-group-item-name' : 'detail-group-item-name text-danger'
                            }
                          >
                            {`${item?.qty} x ${item?.name}`}
                            {item?.note && (
                              <img
                                src={WarningIcon}
                                width={14}
                                className="ml-1 pointer-cursor"
                                onClick={() => handleClickNote(item?.note)}
                              />
                            )}
                          </span>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>
            </FormGroup>
          ) : null}

          {false ? (
            <FormGroup row className="mb-3">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group">Custom</span>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} sm={12}>
                {CustomProducts?.length > 0 ? (
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="detail-group-service">Produk</span>
                    </Col>
                  </Row>
                ) : null}
                {map(CustomProducts, (item) => {
                  item = parsingDetailItemService(item);
                  return (
                    <>
                      <Row>
                        <Col lg={12} sm={12} className="col-summary">
                          <span
                            className={
                              item?.is_approve ? 'detail-group-item-name' : 'detail-group-item-name text-danger'
                            }
                          >
                            {`${item?.qty} x ${item?.name}`}
                            {item?.note && (
                              <img
                                src={WarningIcon}
                                width={14}
                                className="ml-1 pointer-cursor"
                                onClick={() => handleClickNote(item?.note)}
                              />
                            )}
                          </span>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>

              <Col lg={12} sm={12}>
                {CustomServices?.length > 0 ? (
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="detail-group-service">Jasa</span>
                    </Col>
                  </Row>
                ) : null}
                {map(CustomServices, (item) => {
                  item = parsingDetailItemService(item);
                  return (
                    <>
                      <Row>
                        <Col lg={12} sm={12} className="col-summary">
                          <span
                            className={
                              item?.is_approve ? 'detail-group-item-name' : 'detail-group-item-name text-danger'
                            }
                          >
                            {`${item?.qty} x ${item?.name}`}
                            {item?.note && (
                              <img
                                src={WarningIcon}
                                width={14}
                                className="ml-1 pointer-cursor"
                                onClick={() => handleClickNote(item?.note)}
                              />
                            )}
                          </span>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>
            </FormGroup>
          ) : null}

          {/* {map(detailData.groups, (group, indexGroup) => {
            if (detailData?.groups[indexGroup]?.group_items?.length > 0) {
              return (
                <FormGroup row className="mb-3">
                  <Col lg={12} sm={12}>
                    <Row>
                      <Col lg={12} sm={12} className="col-summary">
                        <span className="detail-group">{group?.name}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={12} sm={12}>
                    {map(group.group_items, (item) => {
                      return (
                        <>
                          {item?.name && (
                            <Row>
                              <Col lg={12} sm={12} className="col-summary">
                                <span className="detail-group-service">{item?.name}</span>
                              </Col>
                            </Row>
                          )}
                          {map(item.group_item_details, (detail) => {
                            return (
                              <>
                                <Row>
                                  <Col lg={12} sm={12} className="col-summary">
                                    <>
                                      <span
                                        className={
                                          detail?.is_approve
                                            ? 'detail-group-item-name'
                                            : 'detail-group-item-name text-danger'
                                        }
                                      >
                                        {`${detail?.qty} x ${detail?.name}`}
                                        {detail?.note && (
                                          <img
                                            src={WarningIcon}
                                            width={14}
                                            className="ml-1 pointer-cursor"
                                            onClick={() => handleClickNote(detail?.note)}
                                          />
                                        )}
                                      </span>
                                    </>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  </Col>
                </FormGroup>
              );
            }
          })} */}
          {/* {detailData?.groups[0]?.group_items?.length < 1 &&
            detailData?.groups[1]?.group_items?.length < 1 &&
            detailData?.groups[2]?.group_items?.length < 1 &&
            detailData?.groups[3]?.group_items?.length < 1 && (
              <div className="no-record my-3 text-center">{'Belum Ada Hasil Pemesanan untuk Ditampilkan'}</div>
            )} */}
          {/* <FormGroup row className="mb-0">
            <Col lg={12} sm={12} className="col-summary">
              <div className="divider divider-no-mt"></div>
            </Col>
          </FormGroup> */}
          <FormGroup row>
            <Col lg={12} sm={12} className="col-summary">
              <Row>
                <Label lg={12} className="text-sm-left">
                  {/* <span className="group">Catatan</span>
                  <span className="ml-3">{!customer_note ? '-' : customer_note}</span> */}
                </Label>
              </Row>
            </Col>
          </FormGroup>
        </CardBody>
      </Card>
      <ApprovalNote
        modal={hasModalApprovalNote}
        note={itemNote}
        toggle={() => setHasModalApprovalNote(!hasModalApprovalNote)}
      />
    </React.Fragment>
  );
}

export default BookingDetailFleetService;
