import React from 'react';
import { Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const CustomBreadcrumb = (props) => {
  const { breadcrumbData, onClick } = props;

  return (
    <Col sm={12} md={6} className="p-0 custom-breadcrumb-header">
      <div className="page-title-box">
        {breadcrumbData && (
          <Breadcrumb>
            {breadcrumbData.map((item) => (
              <BreadcrumbItem key={item.id} active={item.active}>
                <a
                  href={() => false}
                  onClick={() => {
                    onClick(item);
                  }}
                  className="custom-breadcrumb-item"
                >
                  {item.label}
                </a>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        )}
      </div>
    </Col>
  );
};

export default CustomBreadcrumb;
