import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Helper from '@helpers/Helper';
import { map } from 'lodash';
import { BASE64_OTOKLIX_LOGO } from '@utils/Constants';

export default class CollectionLetterPdf {
  static print(content, bookingCode) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'potrait',
      content: content,
      header: function (currentPage, pageCount) {
        return [
          {
            image: 'logo',
            width: 180,
            style: {
              alignment: 'center',
              fontSize: 8,
            },
            margin: [0, 30, 0, 0],
          },
          {
            margin: [40, 0, 40, 0],
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 10,
                x2: 515,
                y2: 10,
                lineWidth: 1.7,
                lineColor: '#cc4200',
              },
            ],
          },
          {
            text: 'PT. OTO KLIX INDONESIA',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 10, 40, 0],
            bold: true,
          },
          {
            text: 'Jl. Terusan Sinabung No.1',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 2, 40, 0],
          },
          {
            text: 'Grogol Selatan, Kebayoran Baru',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 2, 40, 0],
            bold: false,
          },
          {
            text: 'Kota Jakarta Selatan, DKI Jakarta 12220. Indonesia',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 2, 40, 0],
            bold: false,
          },
        ];
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Halaman ' + currentPage.toString() + ' dari ' + pageCount,
            alignment: 'center',
            bold: true,
            color: '#d6d8e7',
            fontSize: 10,
          },
        ];
      },
      pageMargins: [40, 170, 40, 40],
      images: {
        logo: BASE64_OTOKLIX_LOGO,
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 30, 0, 0],
          alignment: 'center',
        },
        columnTop: {
          color: 'red',
        },
        paddingLeftRightSectionTop: {
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5], //left, top, right, bottom
        },
        tableContent: {
          margin: [0, 0, 0, 0],
        },
        tableDash: {
          margin: [-50, 5, 0, -50],
        },
        tableSpace: {
          margin: [0, -5, 0, 0],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
        tableStyle: {
          border: '',
          fontSize: 10,
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download(`Permohonan Pembayaran Tagihan ${bookingCode ?? ''}`);
  }

  static create(data) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const content = [
      {
        alignment: 'justify',
        columns: [
          {
            table: {
              body: [
                ['No', ':', data?.letter_number ?? '-'],
                ['Lampiran', ':', '-'],
                ['Hal', ':', 'Permohonan Pembayaran Tagihan'],
              ],
            },
            style: 'tableContent',
            fontSize: 12,
            layout: 'noBorders',
          },
          {
            text: ['Jakarta, ', data?.date ?? '-'],
            alignment: 'right',
            style: 'tableContent',
          },
        ],
      },
      {
        text: 'Kepada Yth.',
        margin: [0, 20, 0, 0],
      },
      {
        text: data?.company_name ?? '-',
        margin: [0, 2, 0, 0],
      },
      {
        text: data?.address ?? '-',
        margin: [0, 2, 0, 0],
      },
      {
        text: 'Dengan Hormat,',
        margin: [0, 20, 0, 0],
      },
      {
        text: [
          'Bersamaan surat ini kami dari PT Oto Klix Indonesia bermaksud untuk menagihkan pembayaran untuk servis yang telah di lakukan pada kendaraan ',
          { text: data?.car, bold: true },
          ' dengan nomor receipt ',
          { text: data?.receipt_number, bold: true },
          ' dengan rincian sebagai berikut:',
        ],
        margin: [0, 20, 0, 20],
      },
      {
        alignment: 'justify',
        columns: [
          {
            table: {
              body: [
                ['DPP', ':', `Rp ${Helper.formatMoney(data?.bills?.total_price)}` ?? '-'],
                ['PPn 11%', ':', `Rp ${Helper.formatMoney(data?.bills?.ppn)}` ?? '-'],
                ['PPh 2%', ':', `Rp ${Helper.formatMoney(data?.bills?.pph)}` ?? '-'],
                ['Total Tagihan', ':', `Rp ${Helper.formatMoney(data?.bills?.total_bill)}` ?? '-'],
              ],
            },
            style: 'tableContent',
            layout: {
              hLineWidth: function (i, node) {
                return i === 3 ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return 0;
              },
              hLineColor: function (i, node) {
                return i === 3 ? 'black' : null;
              },
            },
          },
        ],
      },
      {
        text: 'Pembayaran dapat dilakukan melalui Transfer ke rekening berikut ini :',
        margin: [0, 20, 0, 0],
      },
      {
        table: {
          body: [
            ['Bank', ':', 'BCA'],
            ['Nomer Rekening', ':', '0352-988-918'],
            ['Atas Nama', ':', 'PT. Oto Klix Indonesia'],
          ],
        },
        layout: 'noBorders',
      },
      {
        text: 'Demikian permohonan ini kami sampaikan, atas perhatiannya kami ucapkan terima kasih.',
        colSpan: 2,
        margin: [0, 20, 0, 40],
      },
      {
        text: 'Hormat Kami,',
        margin: [0, 80, 0, 0],
      },
      {
        text: { text: 'PT Oto Klix Indonesia', bold: true },
      },
    ];

    return content;
  }
}
