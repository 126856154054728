import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Alert, Row } from 'reactstrap';
import API from '@utils/API';

const ChangePhotoModal = (props) => {
  const { modal, title, toggle, resultUploadFile, isValidation, sizeValidation } = props;
  const [uploadFile, setUploadFile] = useState(undefined);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState(null);
  const [hasMessage, setHasMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const submitForm = () => {
    setDisable(true);

    if (uploadFile) {
      const result = { file: uploadFile[0], preview: previewImage };
      resultUploadFile(result);
      setPreviewImage('');
      setDisable(true);
    } else {
      setIsError(true);
      setMessage('Please choose an image to upload');
      setHasMessage(true);
      setDisable(false);
    }
  };

  const toggleClose = () => {
    setUploadFile(undefined);
    setPreviewImage('');
    toggle();
  };

  const passValidation = (e) => {
    const imgUrl = URL.createObjectURL(e.target.files[0]);
    setUploadFile(e.target.files);
    setPreviewImage(imgUrl);
    setIsError(false);
    setMessage(null);
    setHasMessage(false);
  };

  const handleAttachImage = (e) => {
    if (isValidation) {
      const fileSize = Math.round(e.target.files[0].size / 1024);
      if (fileSize > sizeValidation) {
        e.target.value = '';
        setUploadFile(undefined);
        setPreviewImage('');
        setMessage('Please choose an image that the size is less than 5MB');
        setIsError(true);
        setHasMessage(true);
        setDisable(false);
      } else {
        passValidation(e);
      }
    } else {
      passValidation(e);
    }
  };

  const reset = () => {
    setUploadFile(undefined);
    setDisable(false);
    setMessage(null);
    setHasMessage(false);
    setIsError(false);
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggleClose} onClosed={() => reset()}>
      <ModalHeader>Tambah Foto</ModalHeader>
      <ModalBody>
        <p>{title}</p>
        {previewImage && (
          <div className="my-3">
            <img src={previewImage} className="attached-profpict" alt="profile picture" />
          </div>
        )}
        <FormGroup className="mb-0">
          <label className="custom-file-upload">
            <Input
              type="file"
              name="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => handleAttachImage(e)}
            />
          </label>
        </FormGroup>

        {hasMessage && (
          <Alert className="mt-3 mb-0" color={isError ? 'danger' : 'primary'}>
            {message}
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="danger" outline size="lg" onClick={() => toggleClose()}>
          Kembali
        </Button>{' '}
        <Button color="primary" size="lg" disabled={disable} onClick={() => submitForm()}>
          Konfirmasi
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChangePhotoModal;
