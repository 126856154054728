import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import API from '@utils/API';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import OtoklixServiceSectionDetail from '@components/masters/otoklix-service/OtoklixServiceSectionDetail';
import OtoklixServiceSectionHistory from '@components/masters/otoklix-service/OtoklixServiceSectionHistory';

const OtoklixServiceDetail = (props) => {
  const { idService, activeSection } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const history = useHistory();
  const [hasNotif, setHasNotif] = useState();
  const [loading, setLoading] = useState(false);
  const [fisrtLoad, setFirstLoad] = useState(true);
  const [notification, setNotification] = useState({});
  const [mainLoading, setMainLoading] = useState(true);
  const [dataDetailService, setDataDetailService] = useState();

  const allowPermission = (menu) => {
    return !!(generatePermission('master', menu) || generatePermission('master', 'all'));
  };

  const allowShow = allowPermission('otoklixservicefeeall') || allowPermission('otoklixservicefeeshow');
  const allowProductDetail =
    allowPermission('otoklixservicefeeall') ||
    allowPermission('otoklixservicefeedetailall') ||
    allowPermission('otoklixservicefeedetailshow');
  const allowProductHistory = allowPermission('otoklixservicefeeall') || allowPermission('otoklixservicefeelog');

  const breadcrumb = [
    {
      id: 1,
      label: 'List Master',
      active: false,
      url: activeSection?.url,
    },
    {
      id: 2,
      label: activeSection?.label ?? '',
      active: false,
      url: '',
    },
    {
      id: 3,
      label: dataDetailService?.product?.name ?? '',
      active: true,
      url: '',
    },
  ];

  async function fetchDetailService() {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/master/otoklix-service-fees/${idService}`);
      setDataDetailService(response?.data?.data ?? {});
      handleGetServiceDetail(response?.data?.data ?? {});
      setLoading(false);
      setFirstLoad(false);
    } catch (error) {
      const errorMsg = error?.response?.data?.error?.message ?? '';
      setDataDetailService({});
      handleResultNotification({
        status: true,
        type: 'fail',
        message: 'Gagal mendapatkan detail data master otoklix service ' + errorMsg,
      });
      setLoading(false);
      setFirstLoad(false);
    }
  }

  const handleGetServiceDetail = (data) => {
    if (!data?.id) {
      handleResultNotification({
        status: true,
        type: 'fail',
        message: 'Detail data master otoklix service tidak ditemukan',
      });
    }
  };

  const handleSubmitEditServiceDetail = (status, error) => {
    const errorMsg = error ? 'Gagal - Service Otoklix Gagal Diubah, ' + error : 'Gagal - Service Otoklix Gagal Diubah';
    if (status) {
      fetchDetailService();
    }
    handleResultNotification({
      status: true,
      type: status ? 'success' : 'fail',
      message: status ? 'Berhasil - Service Otoklix Berhasil Diubah' : errorMsg,
    });
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  useEffect(() => {
    setMainLoading(loading && fisrtLoad ? true : false);
  }, [loading]);

  useEffect(() => {
    if (!allowShow || (!allowProductDetail && !allowProductHistory)) {
      history.push('/404');
    } else if (allowProductDetail) {
      fetchDetailService();
    }
  }, []);

  return (
    <React.Fragment>
      {mainLoading && (
        <Wrapper className="wrapperContent">
          <LoadingSpinner></LoadingSpinner>
        </Wrapper>
      )}
      {!mainLoading && (
        <Wrapper
          title={activeSection?.label}
          className="wrapperContent"
          showNotification={hasNotif}
          notification={notification}
          breadcrumbData={breadcrumb}
          onCloseNotification={handleCloseNotification}
        >
          {loading && <LoadingSpinner></LoadingSpinner>}
          {!loading && (
            <>
              <Row>
                {dataDetailService?.id && allowProductDetail && (
                  <Col sm={12} md={6}>
                    <OtoklixServiceSectionDetail
                      detailService={dataDetailService}
                      onResultNotification={handleResultNotification}
                      onSubmit={handleSubmitEditServiceDetail}
                    />
                  </Col>
                )}
                {allowProductHistory && (
                  <Col sm={12} md={6}>
                    <OtoklixServiceSectionHistory idService={idService} />
                  </Col>
                )}
              </Row>
            </>
          )}
        </Wrapper>
      )}
    </React.Fragment>
  );
};

export default OtoklixServiceDetail;
