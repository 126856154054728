import React, { useState } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import { PACKAGE_ALLOWED } from '@utils/Constants';
import { Button, ListGroup, ListGroupItem, Row, Col, Label, FormGroup, Input } from 'reactstrap';
import filter from 'lodash/filter';
import Helper from '@helpers/Helper';
import ChevronUp from '@assets/icons/chevron-up.svg';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import ChevronDown from '@assets/icons/chevron-down.svg';
import CircleClose from '@assets/icons/circle-close.svg';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import ActionSwitch from '@components/field/ActionSwitch';
import ConfirmationModal from '@components/modal/ConfirmationModal';

const PackageDataDetailProduct = (props) => {
  const {
    watch,
    control,
    setValue,
    getValues,
    setError,
    disableForm,
    loadingSumbit,
    componentIndex,
    errorSectionForm,
    onNotifications,
    productGroup,
    percentageDiscountB2C,
    percentageDiscountB2B,
    allowedCustomerType,
  } = props;

  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `components[${componentIndex}].products`,
  });

  const watchForm = (name) => {
    return watch(name, false);
  };

  const addProduct = () => {
    append({
      product: null,
      product_label: '',
      original_price: {
        formatIsMoney: true,
        formatMoney: 0,
        formatMoneyString: 'Rp 0',
      },
      original_price_b2b: {
        formatIsMoney: true,
        formatMoney: 0,
        formatMoneyString: 'Rp 0',
      },
      product_price: {
        formatIsMoney: true,
        formatMoney: 0,
        formatMoneyString: 'Rp 0',
      },
      product_price_b2b: {
        formatIsMoney: true,
        formatMoney: 0,
        formatMoneyString: 'Rp 0',
      },
      nett_price: {
        formatIsMoney: true,
        formatMoney: 0,
        formatMoneyString: 'Rp 0',
      },
      apps_name: '',
      is_show: true,
      is_active: true,
      is_deleted: false,
    });
  };

  const setErrorMessage = (nameFiled, type, message) => {
    setError(nameFiled, { type: type, message: message }, { shouldFocus: true });
  };

  const showHideProduct = (index) => {
    const is_show = getValues(`components[${componentIndex}].products[${index}].is_show`);
    setValue(`components[${componentIndex}].products[${index}].is_show`, !is_show);
  };

  const deleteProduct = (index) => {
    setErrorMessage(`components[${componentIndex}].products[${index}].product`, 'manual', '');
    if (allowedCustomerType == PACKAGE_ALLOWED.B2C) {
      setErrorMessage(`components[${componentIndex}].products[${index}].apps_name`, 'manual', '');
      setErrorMessage(`components[${componentIndex}].products[${index}].original_price`, 'manual', '');
      setErrorMessage(`components[${componentIndex}].products[${index}].product_price`, 'manual', '');
    }
    if (allowedCustomerType == PACKAGE_ALLOWED.BOTH) {
      setErrorMessage(`components[${componentIndex}].products[${index}].apps_name`, 'manual', '');
      setErrorMessage(`components[${componentIndex}].products[${index}].product_price`, 'manual', '');
    }
    if (allowedCustomerType == PACKAGE_ALLOWED.BOTH || allowedCustomerType == PACKAGE_ALLOWED.B2B) {
      setErrorMessage(`components[${componentIndex}].products[${index}].product_price_b2b`, 'manual', '');
      setErrorMessage(`components[${componentIndex}].products[${index}].nett_price`, 'manual', '');
    }

    setValue(`components[${componentIndex}].products[${index}].is_deleted`, true);
    setConfirmModal(false);
  };

  const handleDeleteProduct = (index) => {
    if (!loadingSumbit) {
      const products = filter(getValues(`components[${componentIndex}].products`), (value) => {
        return !value.is_deleted;
      });
      const checkActiveProduct =
        getValues(`components[${componentIndex}].products[${index}].product.value`) == productGroup?.id;

      if (products?.length == 1) {
        onNotifications({ status: false, msg: 'Component Produk minimal 1' });
      } else if (checkActiveProduct && productGroup?.id) {
        onNotifications({ status: false, msg: 'Product aktif sebagai Product Group' });
      } else {
        setSelectedIndex(index);
        setConfirmModal(true);
      }
    }
  };

  const getClassSectionError = (param) => {
    const errorProduct = errorSectionForm.filter((val) => val == param);
    if (errorProduct?.length !== 0) {
      return 'out-group-item-invalid';
    } else {
      return 'out-group-item';
    }
  };

  const validationAppsName = (value) => {
    let validation = {
      valid: value?.length == 0 || value?.length > 100 ? false : true,
      errorMsg:
        value?.length > 100
          ? 'Show in apps as max 100 karakter'
          : value?.length == 0
          ? 'Show in apps as harus diisi'
          : '',
    };
    return validation;
  };

  const validationPrice = (value, label) => {
    const msgError = label + ' max Rp 99.999.999';
    let validation = {
      valid: value?.formatMoney > 99999999 ? false : true,
      errorMsg: value?.formatMoney > 99999999 ? msgError : '',
    };
    return validation;
  };

  const convertPrice = (value) => {
    return Helper.formatMoneyOnField('' + value ?? 0, 'Rp ');
  };

  const getPriceAfterDiscount = (value, type) => {
    const price = value ?? 0;
    const percentage = type == PACKAGE_ALLOWED.B2C ? percentageDiscountB2C : percentageDiscountB2B;
    const price_disc = (price * percentage) / 100;
    const price_after_disc = price - price_disc;

    return Math.round(price_after_disc);
  };

  return (
    <React.Fragment>
      {fields.map(({ id }, index) => {
        return (
          <ListGroup key={id}>
            {!watchForm(`components[${componentIndex}].products[${index}].is_deleted`) && (
              <ListGroupItem
                key={id}
                className={getClassSectionError(`components[${componentIndex}]products[${index}]`)}
              >
                <div className="fly-title" onClick={() => showHideProduct(index)}>
                  Product: <span>{watchForm(`components[${componentIndex}].products[${index}].product_label`)}</span>
                  <img
                    src={
                      watchForm(`components[${componentIndex}].products[${index}].is_show`) ? ChevronUp : ChevronDown
                    }
                    alt=""
                  />
                </div>
                {!disableForm && (
                  <img src={CircleClose} className="fly-close" onClick={() => handleDeleteProduct(index)} />
                )}
                {watchForm(`components[${componentIndex}].products[${index}].is_show`) && (
                  <div className="in-group-item">
                    <Controller
                      control={control}
                      name={`components[${componentIndex}].products[${index}].product`}
                      rules={{
                        validate: () => {
                          const valid = !getValues(`components[${componentIndex}].products[${index}].product.value`)
                            ? false
                            : true;
                          return valid || 'Product harus diisi';
                        },
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return (
                          <Row className="mb-3">
                            <Label lg={3} className="label-required">
                              Product
                            </Label>
                            <SelectField
                              colWidth={9}
                              placeholder={'Pilih Product'}
                              value={value}
                              disabled={disableForm || loadingSumbit}
                              closeMenuOnSelect={true}
                              options={getValues(`components[${componentIndex}].product_options`) ?? []}
                              isLoading={getValues(`components[${componentIndex}].loading_product_options`) ?? false}
                              errorMessage={error?.message ?? ''}
                              onChange={(e) => {
                                const originalPrice = convertPrice(e?.sell_price);
                                const productPrice = convertPrice(
                                  getPriceAfterDiscount(e?.sell_price, PACKAGE_ALLOWED.B2C)
                                );
                                const originalPriceB2B = convertPrice(e?.base_price);
                                const productPriceB2B = convertPrice(
                                  getPriceAfterDiscount(e?.base_price, PACKAGE_ALLOWED.B2B)
                                );
                                const nettPrice = convertPrice(e?.nett_price);

                                setValue(
                                  `components[${componentIndex}].products[${index}].product_label`,
                                  e?.label ?? ''
                                );
                                setValue(`components[${componentIndex}].products[${index}].apps_name`, e?.label ?? '');
                                setValue(
                                  `components[${componentIndex}].products[${index}].original_price`,
                                  originalPrice
                                );
                                setValue(
                                  `components[${componentIndex}].products[${index}].product_price`,
                                  productPrice
                                );
                                setValue(
                                  `components[${componentIndex}].products[${index}].original_price_b2b`,
                                  originalPriceB2B
                                );
                                setValue(
                                  `components[${componentIndex}].products[${index}].product_price_b2b`,
                                  productPriceB2B
                                );
                                setValue(`components[${componentIndex}].products[${index}].nett_price`, nettPrice);

                                if (allowedCustomerType == PACKAGE_ALLOWED.B2C) {
                                  setErrorMessage(
                                    `components[${componentIndex}].products[${index}].apps_name`,
                                    'manual',
                                    ''
                                  );
                                  setErrorMessage(
                                    `components[${componentIndex}].products[${index}].original_price`,
                                    'manual',
                                    ''
                                  );
                                  setErrorMessage(
                                    `components[${componentIndex}].products[${index}].product_price`,
                                    'manual',
                                    ''
                                  );
                                }
                                if (allowedCustomerType == PACKAGE_ALLOWED.BOTH) {
                                  setErrorMessage(
                                    `components[${componentIndex}].products[${index}].apps_name`,
                                    'manual',
                                    ''
                                  );
                                  setErrorMessage(
                                    `components[${componentIndex}].products[${index}].product_price`,
                                    'manual',
                                    ''
                                  );
                                }
                                if (
                                  allowedCustomerType == PACKAGE_ALLOWED.BOTH ||
                                  allowedCustomerType == PACKAGE_ALLOWED.B2B
                                ) {
                                  setErrorMessage(
                                    `components[${componentIndex}].products[${index}].product_price_b2b`,
                                    'manual',
                                    ''
                                  );
                                  setErrorMessage(
                                    `components[${componentIndex}].products[${index}].nett_price`,
                                    'manual',
                                    ''
                                  );
                                }

                                onChange(e);
                              }}
                            />
                          </Row>
                        );
                      }}
                    />
                    {allowedCustomerType !== PACKAGE_ALLOWED.B2B ? (
                      <>
                        <Controller
                          name={`components[${componentIndex}].products[${index}].apps_name`}
                          control={control}
                          rules={{
                            validate: () => {
                              const validation = validationAppsName(
                                getValues(`components[${componentIndex}].products[${index}].apps_name`)
                              );
                              return validation?.valid || validation?.errorMsg;
                            },
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Row className="mb-3">
                              <Label lg={3} className="label-required">
                                Show in apps as
                              </Label>
                              <InputField
                                colWidth={9}
                                inputType={'text'}
                                disabled={disableForm || loadingSumbit}
                                value={value}
                                invalid={error?.message ? true : false}
                                errorMessage={error?.message ?? ''}
                                placeholder={'Show in apps as'}
                                onChange={(e) => {
                                  const val =
                                    e.target.value !== ' '
                                      ? e.target.value.replace(/  +/g, ' ')
                                      : getValues(`components[${componentIndex}].products[${index}].apps_name`);
                                  const validation = validationAppsName(val);
                                  setErrorMessage(
                                    `components[${componentIndex}].products[${index}].apps_name`,
                                    'manual',
                                    validation?.errorMsg
                                  );
                                  setValue(`components[${componentIndex}].products[${index}].apps_name`, val);
                                  onChange(val);
                                }}
                              ></InputField>
                            </Row>
                          )}
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    {allowedCustomerType === PACKAGE_ALLOWED.B2C ? (
                      <>
                        <Controller
                          control={control}
                          name={`components[${componentIndex}].products[${index}].original_price`}
                          rules={{
                            validate: () => {
                              const validation = validationPrice(
                                getValues(`components[${componentIndex}].products[${index}].original_price`),
                                'Original Price'
                              );
                              return validation?.valid || validation?.errorMsg;
                            },
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Row className="mb-3">
                              <Label lg={3} className="label-required">
                                Original Price
                              </Label>
                              <InputField
                                colWidth={9}
                                inputType={'text'}
                                disabled={disableForm || loadingSumbit}
                                value={value?.formatMoneyString ?? ''}
                                invalid={error?.message ? true : false}
                                errorMessage={error?.message ?? ''}
                                placeholder={'Original Price'}
                                onChange={(e) => {
                                  let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                                  const validation = validationPrice(convertMoney, 'Original Price');
                                  setErrorMessage(
                                    `components[${componentIndex}].products[${index}].original_price`,
                                    'manual',
                                    validation?.errorMsg
                                  );
                                  setValue(
                                    `components[${componentIndex}].products[${index}].original_price`,
                                    convertMoney
                                  );
                                  onChange(convertMoney);
                                }}
                              ></InputField>
                            </Row>
                          )}
                        />
                        <Controller
                          control={control}
                          name={`components[${componentIndex}].products[${index}].product_price`}
                          rules={{
                            validate: () => {
                              const validation = validationPrice(
                                getValues(`components[${componentIndex}].products[${index}].product_price`),
                                'Price'
                              );
                              return validation?.valid || validation?.errorMsg;
                            },
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Row className="mb-3">
                              <Label lg={3} className="label-required">
                                Price
                              </Label>
                              <InputField
                                colWidth={9}
                                inputType={'text'}
                                disabled={disableForm || loadingSumbit}
                                value={value?.formatMoneyString ?? ''}
                                invalid={error?.message ? true : false}
                                errorMessage={error?.message ?? ''}
                                placeholder={'Price'}
                                onChange={(e) => {
                                  let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                                  const validation = validationPrice(convertMoney, 'Price');
                                  setErrorMessage(
                                    `components[${componentIndex}].products[${index}].product_price`,
                                    'manual',
                                    validation?.errorMsg
                                  );
                                  setValue(
                                    `components[${componentIndex}].products[${index}].product_price`,
                                    convertMoney
                                  );
                                  onChange(convertMoney);
                                }}
                              ></InputField>
                            </Row>
                          )}
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    {allowedCustomerType === PACKAGE_ALLOWED.BOTH ? (
                      <>
                        <Controller
                          control={control}
                          name={`components[${componentIndex}].products[${index}].product_price`}
                          rules={{
                            validate: () => {
                              const validation = validationPrice(
                                getValues(`components[${componentIndex}].products[${index}].product_price`),
                                'B2C Price'
                              );
                              return validation?.valid || validation?.errorMsg;
                            },
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Row className="mb-3">
                              <Label lg={3} className="label-required">
                                B2C Price
                              </Label>
                              <InputField
                                colWidth={9}
                                inputType={'text'}
                                disabled
                                value={value?.formatMoneyString ?? ''}
                                invalid={error?.message ? true : false}
                                errorMessage={error?.message ?? ''}
                                placeholder={'Price'}
                                onChange={(e) => {
                                  let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                                  const validation = validationPrice(convertMoney, 'B2C Price');
                                  setErrorMessage(
                                    `components[${componentIndex}].products[${index}].product_price`,
                                    'manual',
                                    validation?.errorMsg
                                  );
                                  setValue(
                                    `components[${componentIndex}].products[${index}].product_price`,
                                    convertMoney
                                  );
                                  onChange(convertMoney);
                                }}
                              ></InputField>
                            </Row>
                          )}
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    {allowedCustomerType === PACKAGE_ALLOWED.B2B || allowedCustomerType === PACKAGE_ALLOWED.BOTH ? (
                      <>
                        <Controller
                          control={control}
                          name={`components[${componentIndex}].products[${index}].product_price_b2b`}
                          rules={{
                            validate: () => {
                              const validation = validationPrice(
                                getValues(`components[${componentIndex}].products[${index}].product_price_b2b`),
                                'B2B Price'
                              );
                              return validation?.valid || validation?.errorMsg;
                            },
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Row className="mb-3">
                              <Label lg={3} className="label-required">
                                B2B Price
                              </Label>
                              <InputField
                                colWidth={9}
                                inputType={'text'}
                                disabled
                                value={value?.formatMoneyString ?? ''}
                                invalid={error?.message ? true : false}
                                errorMessage={error?.message ?? ''}
                                placeholder={'Price'}
                                onChange={(e) => {
                                  let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                                  const validation = validationPrice(convertMoney, 'B2B Price');
                                  setErrorMessage(
                                    `components[${componentIndex}].products[${index}].product_price_b2b`,
                                    'manual',
                                    validation?.errorMsg
                                  );
                                  setValue(
                                    `components[${componentIndex}].products[${index}].product_price_b2b`,
                                    convertMoney
                                  );
                                  onChange(convertMoney);
                                }}
                              ></InputField>
                            </Row>
                          )}
                        />
                        <Controller
                          control={control}
                          name={`components[${componentIndex}].products[${index}].nett_price`}
                          rules={{
                            validate: () => {
                              const validation = validationPrice(
                                getValues(`components[${componentIndex}].products[${index}].nett_price`),
                                'Nett Price'
                              );
                              return validation?.valid || validation?.errorMsg;
                            },
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Row className="mb-3">
                              <Label lg={3} className="label-required">
                                Nett Price
                              </Label>
                              <InputField
                                colWidth={9}
                                inputType={'text'}
                                disabled={disableForm || loadingSumbit}
                                value={value?.formatMoneyString ?? ''}
                                invalid={error?.message ? true : false}
                                errorMessage={error?.message ?? ''}
                                placeholder={'Price'}
                                onChange={(e) => {
                                  let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                                  const validation = validationPrice(convertMoney, 'Nett Price');
                                  setErrorMessage(
                                    `components[${componentIndex}].products[${index}].nett_price`,
                                    'manual',
                                    validation?.errorMsg
                                  );
                                  setValue(`components[${componentIndex}].products[${index}].nett_price`, convertMoney);
                                  onChange(convertMoney);
                                }}
                              ></InputField>
                            </Row>
                          )}
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    <Controller
                      control={control}
                      name={`components[${componentIndex}].products[${index}].is_active`}
                      render={({ field: { ref, value, name, ...rest } }) => {
                        return (
                          <FormGroup row>
                            <Label lg={3}>Aktif</Label>
                            <Col className="p-2">
                              <ActionSwitch
                                {...rest}
                                inputName={name}
                                isChecked={value}
                                customClass="mb-0"
                                editableForm={disableForm || loadingSumbit}
                              />
                            </Col>
                          </FormGroup>
                        );
                      }}
                    />
                  </div>
                )}
              </ListGroupItem>
            )}
          </ListGroup>
        );
      })}
      <Row>
        <Col className="text-left">
          {!disableForm && (
            <Button
              color="primary"
              size="lg"
              className="btn-add-component-product"
              disabled={loadingSumbit}
              onClick={addProduct}
            >
              <img src={PlusFill} className="mr-2" />
              Add Product
            </Button>
          )}
        </Col>
      </Row>
      <ConfirmationModal
        modal={confirmModal}
        toggle={() => setConfirmModal(!confirmModal)}
        header={`Anda yakin ingin menghapus data Product ?`}
        onConfirm={() => {
          deleteProduct(selectedIndex);
        }}
        toggleClose={() => setConfirmModal(!confirmModal)}
      ></ConfirmationModal>
    </React.Fragment>
  );
};

export default PackageDataDetailProduct;
