import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import API from '@utils/API';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import BookingDraftForm from '@components/booking/BookingDraftForm';
import BookingDraftSummary from '@components/booking/BookingDraftSummary';
import ConfirmationModal from '@components/modal/ConfirmationModal';

function BookingDraft() {
  const history = useHistory();
  const { id } = useParams();
  const { generatePermission } = userPermission();
  const api = new API('v2');
  const [data, setData] = useState({});
  const [dataSummary, setDataSummary] = useState({});
  const [dataSummaryEdit, setDataSummaryEdit] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [hasNotif, setHasNotif] = useState(true);
  const [notification, setNotification] = useState({});
  const [isFleetMode, setIsFleetMode] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [hasConfirmationModal, setHasConfirmationModal] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  const allowPermissionRevise = generatePermission('booking', 'revise');
  const breadcrumb = [
    {
      id: 1,
      label: 'Booking / Order',
      active: false,
      url: '/bookings',
    },
    {
      id: 2,
      label: `#${data?.booking_code ?? '-'}`,
      active: false,
      url: `/bookings/${data?.booking_code ?? ''}`,
    },
    {
      id: 3,
      label: 'Draft Booking',
      active: true,
      url: '',
    },
  ];

  const getPermissionRevise = () => {
    if (allowPermissionRevise) {
      loadBookingDetail();
    } else {
      history.push(`/bookings`);
    }
  };
  async function loadBookingDetail() {
    setIsFleetMode(false);
    setLoading(true);
    try {
      const response = await api.get(`v2/otopilot/booking/uid/${id}`);
      if (response?.data?.data) {
        setData(response?.data?.data);
        setIsFleetMode(response?.data?.data?.booking_source?.slug == 'fleet' ? true : false);
        handleCanReviseOrder(response?.data?.data);
      } else {
        setData({});
        history.push(`/bookings`);
      }
      setLoading(false);
    } catch (error) {
      setData({});
      setLoading(false);
      history.push(`/bookings`);
    }
  }

  const handleCanReviseOrder = (data) => {
    if (
      data?.customer?.customer_type == 'b2b' &&
      data?.booking_source?.slug !== 'fleet' &&
      (data?.booking_status?.slug == 'payment' ||
        data?.booking_status?.slug == 'waiting' ||
        data?.booking_status?.slug == 'confirm' ||
        data?.booking_status?.slug == 'on-process' ||
        data?.booking_status?.slug == 'service-done' ||
        data?.booking_status?.slug == 'finish')
    ) {
      //pass
    } else if (
      data?.customer?.customer_type == 'b2b' &&
      data?.booking_source?.slug == 'fleet' &&
      data?.booking_status?.slug == 'complain'
    ) {
      //pass
    }
  };

  const toggleConfirmationModal = () => {
    setHasConfirmationModal(!hasConfirmationModal);
  };

  const disabledForm = () => {
    let isDisabled = false;
    if (
      data?.booking_type == 'b2b' &&
      (data?.booking_status?.slug == 'service-done' || data?.booking_status?.slug == 'finish')
    ) {
      isDisabled = false;
    }

    return isDisabled;
  };

  const handleChangeForm = (dataForm) => {
    let newDataSummary = { ...data };
    newDataSummary.data_form = dataForm;
    setDataSummary(newDataSummary);
  };

  const handleSaveAsDraft = () => {
    setSaveAsDraft(true);
    handleSubmit();
  };

  const handleSave = () => {
    setSaveAsDraft(false);
    handleSubmit();
  };

  const handleCancelSave = () => {
    setHasConfirmationModal(true);
  };

  const handleSubmit = () => {
    setDisabled(true);
    setIsSubmit(false);
    setTimeout(() => {
      setIsSubmit(true);
    }, 300);
  };

  const handleCancel = () => {
    toggleConfirmationModal();
    setTimeout(() => {
      history.push(`/bookings/${data?.booking_code}/draft`);
    }, 100);
  };

  // const onChangeDataItem = (status, payload) => {
  //   const tempData = { ...payload };
  //   setDataSummaryEdit(payload);
  // };

  const handleSubmitRevise = (status, payload) => {
    const newPayload = {
      items: payload?.items,
      spk_number: dataSummaryEdit?.spk_number ?? '',
      freshdesk_ticket_link: dataSummaryEdit?.freshdesk_ticket_link ?? '',
      mileage: dataSummaryEdit?.mileage ?? '',
      schedule: dataSummaryEdit?.schedule ?? '',
      note: dataSummaryEdit?.note ?? '',
      customer_settlement_price: totalAmount,
    };
    if (status) {
      if (!saveAsDraft) {
        api
          .post(`v2/otopilot/booking/uid/${id}`, newPayload)
          .then((res) => {
            setDisabled(false);
            resultNotification(true, 'success', 'Berhasil Membuat Booking ');
            setTimeout(() => {
              history.push(`/bookings/${res?.data?.data?.booking_code}`);
            }, 1000);
          })
          .catch((e) => {
            const message = `API Error : ${e?.response?.data?.message}`;
            setDisabled(false);
            resultNotification(true, 'fail', 'Gagal Membuat Booking ' + message);
          });
      } else {
        api
          .post(`v2/otopilot/booking/uid/${id}`, newPayload)
          .then((res) => {
            setDisabled(false);
            resultNotification(true, 'success', 'Berhasil Membuat Booking ');
            setTimeout(() => {
              history.push(`/bookings/${res?.data?.data?.booking_code}`);
            }, 1000);
          })
          .catch((e) => {
            const message = `API Error : ${e?.response?.data?.message}`;
            setDisabled(false);
            resultNotification(true, 'fail', 'Gagal Membuat Booking ' + message);
          });
      }
    } else {
      setDisabled(false);
    }
  };

  const resultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    handleResultNotification(notification);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const handleProcess = (status) => {
    setDisabled(status);
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleDataSummary = (newData) => {
    const tempData = { ...dataSummary, newData };
    setDataSummaryEdit(newData);
  };

  const addSummaryPrice = (price) => {
    setTotalAmount(price);
  };

  useEffect(() => {
    getPermissionRevise();
  }, []);

  return (
    <React.Fragment>
      {loading && <LoadingWrapper />}
      {!loading && (
        <>
          <ConfirmationModal
            modal={hasConfirmationModal}
            isDisabled={false}
            toggle={toggleConfirmationModal}
            header={`Yakin ingin membatalkan perubahan booking "${id}" ?`}
            onConfirm={() => {
              handleCancel();
            }}
            toggleClose={toggleConfirmationModal}
          />
          <Wrapper
            title="Draft Booking"
            breadcrumbData={breadcrumb}
            showNotification={hasNotif}
            notification={notification}
            onCloseNotification={handleCloseNotification}
          >
            <Row>
              <Col sm={12} md={6}>
                <BookingDraftForm
                  data={data}
                  bookingCode={id}
                  isFleetMode={isFleetMode}
                  disabled={disabled || disabledForm()}
                  saveAsDraft={saveAsDraft}
                  isSubmit={isSubmit}
                  onChangeForm={handleChangeForm}
                  onSubmitRevise={handleSubmitRevise}
                  // onChangeDataItem={onChangeDataItem}
                  onResultNotification={handleResultNotification}
                ></BookingDraftForm>
              </Col>
              <Col sm={12} md={6}>
                <BookingDraftSummary
                  dataSummary={dataSummary}
                  onSubmitData={(data) => handleDataSummary(data)}
                  onSummaryPrice={(price) => addSummaryPrice(price)}
                />
              </Col>
              <Col sm={12} md={12} className="text-right mb-2 section-action">
                {allowPermissionRevise && (
                  <Button className="button-action save" disabled={disabled} onClick={handleSave}>
                    {'Simpan'}
                  </Button>
                )}
                <Button className="button-action cancel" disabled={disabled} onClick={handleCancelSave}>
                  {'Batal'}
                </Button>
              </Col>
            </Row>
          </Wrapper>
        </>
      )}
    </React.Fragment>
  );
}

export default BookingDraft;
