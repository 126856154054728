import React, { useState, useEffect, useMemo } from 'react';
import { Col, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { assign, find, map, isEmpty } from 'lodash';
import API from '@utils/API';
import qs from 'query-string';
import Helper from '@helpers/Helper';
import Loading from '@components/loading/Loading';
import userPermission from '@utils/userPermission';
import DataTable from 'react-data-table-component';
import BrandAdd from '@components/masters/brand/BrandAdd';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';

const BrandList = (props) => {
  const { onResultNotification } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const history = useHistory();
  const qp = qs.parse(history?.location?.search);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [dataExport, setDataExport] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [disabledExport, setDisabledExport] = useState(true);
  const [hasModalAddBrand, setHasModalAddBrand] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('master', menu) || generatePermission('master', 'all'));
  };

  const allowList = allowPermission('productbrandall') || allowPermission('productbrandlist');
  const allowAdd = allowPermission('productbrandall') || allowPermission('productbrandcreate');
  const allowShow = allowPermission('productbrandall') || allowPermission('productbrandshow');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        name: 'Brand ID',
        selector: (row) => row?.brand_id ?? '-',
        minWidth: '120px',
        sortable: true,
        sortField: 'id',
      },
      {
        name: 'Brand Name',
        minWidth: '455px',
        selector: (row) => row?.brand_name ?? '-',
        sortable: true,
        sortField: 'name',
        wrap: true,
      },
    ],
    [resetSorting]
  );

  const dataFilter = [
    {
      id: 'filter_text_search',
      type: 'text',
      name: 'search',
      name_field: 'search',
      value: '',
      placeholder: 'Cari Nama Brand',
    },
  ];

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchBrand() {
    setLoading(true);
    setDisabledExport(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/master/product-brands/`, { params });
      setData(response?.data?.data?.brands ?? []);
      handleDataExport(response?.data?.data?.brands ?? []);
      setTotalRows(response?.data?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      let msg = error?.response?.data?.error?.message ?? '';
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleDataExport([]);
      handleResultNotification(true, 'fail', 'Gagal mendapatakan list master brand ' + msg);
    }
  }

  const getParamData = () => {
    let params = {
      search: filtered?.search ?? '',
      page: page,
      limit: pageLimit,
    };

    if (sortingData) {
      params.sort = sortingData?.sort;
      params.order = sortingData?.order;
    }

    return params;
  };

  const handleDataExport = (datBrand) => {
    let arrDataExport = [];

    datBrand.forEach((item) => {
      const objDataExport = {
        brand_id: item?.brand_id ?? '-',
        brand_name: item?.brand_name ?? '-',
      };

      arrDataExport.push(objDataExport);
    });

    setDataExport(arrDataExport);
    setDisabledExport(false);
  };

  const handleDownloadCSV = () => {
    const keys = ['brand_id', 'brand_name'];
    const headers = ['Brand ID', 'Brand Name'];
    Helper.downloadCSV(dataExport, headers, keys);
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };
  const handleChangeSorting = (column, sortDirection) => {
    setSortingData({
      sort: column?.sortField ?? '',
      order: sortDirection ?? '',
    });
  };

  const handleApplyFilters = async (params) => {
    await setFiltered(params);
    if (!isEmpty(qp)) {
      const payload = {
        ...qp,
        search: params?.search,
      };

      history.push({ search: qs.stringify(payload) });
    }
  };

  const handleResetFilters = () => {
    setFilters(dataFilter);
    setFiltered({});
    delete qp.search;
    history.push({ search: qs.stringify(qp) });
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.data?.pagination?.total_page) {
      setPage(response?.data?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleAddBrand = () => {
    setHasModalAddBrand(true);
  };

  const handleDetailBrand = (row) => {
    if (allowShow) {
      history.push(`/masters/brand/detail/${row?.brand_id}`);
    }
  };

  const handleSubmitAddBrand = (status, error) => {
    const errorMsg = error ? 'Gagal Tambah Data Master Brand, ' + error : 'Gagal Tambah Data Master Brand';
    if (status) {
      handleMethodFetchBrand();
    }
    setHasModalAddBrand(false);
    handleResultNotification(
      true,
      status ? 'success' : 'fail',
      status ? 'Berhasil Tambah Data Master Brand' : errorMsg
    );
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodFetchBrand();
  };

  const handleMethodFetchBrand = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchBrand();
    }
  };

  const resetBrand = () => {
    setResetSorting(true);
    setSortingData({});
  };

  async function setDefaultFilters() {
    const filteredSearch = qp?.search ?? '';

    let paramsFiltered = {};
    if (qp?.search) await assign(paramsFiltered, { search: filteredSearch });
    let filterData = dataFilter;
    filterData[0].value = filteredSearch;

    setFilters(filterData);
    setFiltered(paramsFiltered);
  }

  const toogleAddBrand = () => setHasModalAddBrand(!hasModalAddBrand);

  useEffect(() => {
    if (page && pageLimit && !firstLoad) {
      fetchBrand();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    if (firstLoad && filtered) {
      fetchBrand();
    } else {
      resetBrand();
    }
  }, [filtered]);

  useEffect(() => {
    setDefaultFilters();
  }, []);

  return (
    <React.Fragment>
      <BrandAdd modal={hasModalAddBrand} onSubmit={handleSubmitAddBrand} toggle={toogleAddBrand} />
      <Col sm={12} md={12} className="p-0 mt-0 section-action">
        {allowAdd && (
          <Button disabled={loading} className="button-action add" onClick={handleAddBrand}>
            {'Tambah'}
          </Button>
        )}
        {allowList && (
          <Button disabled={disabledExport} className="button-action primary" onClick={handleDownloadCSV}>
            {'Export CSV'}
          </Button>
        )}
      </Col>
      {filters?.length !== 0 && allowList && (
        <Col sm={12} md={12} className="p-0 mt-4">
          <ActionFilterCustom
            filters={filters}
            loading={loading}
            onApplyFilters={handleApplyFilters}
            onResetFilters={handleResetFilters}
          ></ActionFilterCustom>
        </Col>
      )}

      <Col sm={12} md={12} className="p-0 mt-4">
        <DataTable
          persistTableHead
          highlightOnHover
          sortServer
          noHeader={true}
          progressPending={loading}
          data={data}
          className="table dt-responsive table-custom"
          noDataComponent={noDataComponent}
          progressComponent={<Loading />}
          customStyles={customStylesHeaderTable}
          onSort={handleChangeSorting}
          onRowClicked={(row) => handleDetailBrand(row)}
          columns={allowList ? columns : []}
        />
      </Col>
      <Col sm={12} md={12} className="p-0 mt-2">
        {data?.length >= 1 && !loading && allowList && (
          <CustomPagination
            page={page}
            pageLimit={pageLimit}
            totalRows={data?.length ?? 0}
            totalAllRows={totalRows}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
            handleChangeRowPerPage={handleChangeRowPerPage}
          ></CustomPagination>
        )}
      </Col>
    </React.Fragment>
  );
};

export default BrandList;
