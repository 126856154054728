import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import API from '@utils/API';
import Wrapper from '@components/wrapper/Wrapper';
import InventoryViewDetail from '@components/workshops/inventory/InventoryDetail';

const InventoryDetail = () => {
  const api = new API('v2');
  const { workshop_id, id } = useParams();

  const [hasNotif, setHasNotif] = useState(false);
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataInventory, setDataInventory] = useState({});
  const [dataWorkshop, setDataWorkshop] = useState({});

  const breadcrumbItem = [
    {
      id: 1,
      label: 'List Workshop',
      active: false,
      url: '/workshops',
    },
    {
      id: 2,
      label: 'Detail Workshop',
      active: false,
      url: `/workshops/${dataWorkshop?.id}`,
    },
    {
      id: 3,
      label: `${dataWorkshop?.name ?? '-'}`,
      active: false,
      url: `/workshops/${dataWorkshop?.id}?activetab=inventory`,
    },
    {
      id: 4,
      label: `${dataInventory?.product_name ?? '-'}`,
      active: true,
      url: '',
    },
  ];

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const getInventoryData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/workshops/${workshop_id}/inventories/${id}/`);
      setDataInventory(response?.data?.data ?? {});
      setLoading(false);
    } catch (error) {
      const errorMsg = error?.response?.data?.error?.message ?? '';
      setDataInventory({});
      setLoading(false);
      handleResultNotification({ status: true, type: 'fail', message: 'Fail get data workshop ' + errorMsg });
    }
  };

  const getWorkshopData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/workshops/${workshop_id}/general-info/`);
      setDataWorkshop(response?.data?.data ?? {});
      setLoading(false);
    } catch (error) {
      const errorMsg = error?.response?.data?.error?.message ?? '';
      setDataWorkshop({});
      setLoading(false);
      handleResultNotification({ status: true, type: 'fail', message: 'Fail get data workshop ' + errorMsg });
    }
  };

  useEffect(() => {
    getWorkshopData();
    getInventoryData();
  }, []);

  return (
    <React.Fragment>
      <Wrapper
        title={dataInventory?.product_name}
        breadcrumbData={breadcrumbItem}
        showNotification={hasNotif}
        notification={notification}
        onCloseNotification={() => setHasNotif(false)}
      >
        <InventoryViewDetail
          dataInventory={dataInventory}
          onResultNotification={handleResultNotification}
          workshopId={workshop_id}
        />
      </Wrapper>
    </React.Fragment>
  );
};

export default InventoryDetail;
