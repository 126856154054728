import React, { useState } from 'react';
import { Button, FormGroup, Row, Col, Label, Spinner } from 'reactstrap';
import API from '@utils/API';
import InputField from '@components/field/InputField';
import CustomModal from '@components/modal/CustomModal';

const RefreshDataModal = (props) => {
  const { isOpen, toggle, handleResultNotification } = props;
  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [loadingRefreshData, setLoadingRefreshData] = useState(false);
  const [isSpecific, setIsSpecific] = useState(false);
  const [allTransaction, setAllTransaction] = useState(false);
  const [loadOrder, setLoadOrder] = useState(false);
  const [loadDelivery, setLoadDelivery] = useState(false);
  const [noPo, setNoPo] = useState('');

  const handleResultRefreshData = (res) => {
    setLoading(false);
    setLoadingRefreshData(false);
    if (+res?.status === 200) {
      handleResultNotification(true, 'success', 'Refresh Data Success');
      toggle();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  const handleErrorRefreshData = (e) => {
    handleResultNotification(true, 'fail', `${e}`);
    setLoadingRefreshData(false);
    setLoading(false);
    console.errpr('err: ', e);
  };

  const handleRefreshData = async (type) => {
    setLoading(true);
    setLoadingRefreshData(true);
    if (isSpecific) {
      try {
        if (allTransaction) {
          const res = await api.post(`v2/intools/warehouse-transfers/sync/`);
          handleResultRefreshData(res);
        } else {
          const res = await api.get(`v2/intools/warehouse-transfers/sync/${noPo}`);
          handleResultRefreshData(res);
        }
      } catch (e) {
        handleErrorRefreshData(e);
      }
    } else {
      if (type === 'Order') {
        setLoadOrder(true);
        try {
          const res = await api.post('v2/intools/purchase-orders/sync/');
          handleResultRefreshData(res);
          setLoadOrder(false);
        } catch (e) {
          setLoadOrder(false);
          handleErrorRefreshData(e);
        }
      }
      if (type === 'Delivery') {
        setLoadDelivery(true);
        try {
          const res = await api.post('v2/intools/purchase-deliveries/sync/');
          handleResultRefreshData(res);
          setLoadDelivery(false);
        } catch (e) {
          setLoadDelivery(false);
          handleErrorRefreshData(e);
        }
      }
    }
  };
  return (
    <CustomModal
      className={!isSpecific ? 'modal-sync-inventory' : 'modal-confirm-inventory'}
      modalWidth={'auto'}
      modal={isOpen}
      toggle={toggle}
      modalHeader="Apakah Anda akan melakukan Refresh Data?"
      modalFooter={
        <>
          {isSpecific ? (
            <>
              <Button
                color="secondary"
                size="md"
                onClick={() => setIsSpecific(false)}
                disabled={loading || loadingRefreshData}
              >
                Batal
              </Button>
              <Button
                color="primary"
                size="md"
                onClick={() => handleRefreshData('Specific')}
                disabled={loading || loadingRefreshData || (allTransaction ? false : noPo === '')}
              >
                {!loadingRefreshData ? (
                  'Back Date Transaction'
                ) : (
                  <Spinner size="sm" color="dark">
                    Loading...
                  </Spinner>
                )}
              </Button>
            </>
          ) : (
            <>
              <Button color="secondary" size="md" onClick={() => handleRefreshData('Order')} disabled={loading}>
                {!loadOrder ? (
                  'Purchase Order'
                ) : (
                  <Spinner size="sm" color="dark">
                    Loading...
                  </Spinner>
                )}
              </Button>
              <Button color="secondary" size="md" onClick={() => handleRefreshData('Delivery')} disabled={loading}>
                {!loadDelivery ? (
                  'Purchase Delivery'
                ) : (
                  <Spinner size="sm" color="dark">
                    Loading...
                  </Spinner>
                )}
              </Button>
              <Button color="secondary" size="md" onClick={() => setIsSpecific(true)} disabled={loading}>
                Warehouse Transfer
              </Button>
            </>
          )}
        </>
      }
    >
      {isSpecific && (
        <Row>
          <Col sm={12} md={12}>
            <FormGroup row>
              <Label lg={4} className={allTransaction ? '' : 'label-required'}>
                Masukan Nomor Transaksi Transfer
              </Label>
              <InputField
                colWidth={8}
                name="no_transaction_transfer"
                placeholder="Input No Transaksi Transfer"
                closeMenuOnSelect={true}
                value={noPo}
                disabled={loading || loadingRefreshData || allTransaction}
                errorMessage={allTransaction ? '' : noPo === '' ? 'No Transaksi Transfer is Required' : ''}
                invalid={allTransaction ? false : noPo === '' ? true : false}
                onChange={(e) => setNoPo(e.target.value)}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={4}>All Transactions</Label>
              <Col lg={8}>
                <div className="d-flex align-items-center h-100">
                  <input
                    id="allTransaction"
                    type="checkbox"
                    onClick={(e) => {
                      setAllTransaction(e.target.checked);
                      if (e.target.checked) setNoPo('');
                    }}
                    value={allTransaction}
                    checked={allTransaction}
                    disabled={loading || loadingRefreshData}
                  />
                </div>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      )}
    </CustomModal>
  );
};

export default RefreshDataModal;
