import React, { useEffect, useState } from 'react';
import Wrapper from '@components/wrapper/Wrapper';
import CarCompabilityForms from '@components/cars-compability/CarCompabilityForms';
import CarCompabilityLog from '@components/cars-compability/CarCompabilityLog';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import API from '@utils/API';
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { map } from 'lodash';

const DetailCarsCompability = () => {
  const { id } = useParams();
  const api = new API('v2');

  const [origin, setOrigin] = useState('detail');
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({});
  const [hasNotification, setHasNotification] = useState(false);

  const generateBreadcrumb = () => {
    return [
      {
        id: 1,
        label: 'Cars',
        active: false,
        url: '/cars',
      },
      {
        id: 2,
        label: 'List Car Compability',
        active: false,
        url: '/cars',
      },
      {
        id: 3,
        label: `${origin === 'detail' ? 'Detail' : 'Edit'} Group Car Compability`,
        active: true,
        url: `/compability/${id}`,
      },
    ];
  };

  const getCarGroupDetails = async () => {
    setIsLoading(true);
    const response = await api.get(`v2/intools/user_car/compatibility_groups/${id}/`);
    const data = response.data.data;
    let payload = {};
    if (data) {
      const { car_group_id, car_model, car_group_name, year_range } = data.car_group;
      let carModel = [];
      map(car_model, (item) => {
        carModel.push({
          value: item.car_details_id,
          label: `${item.brand} ${item.model_name} ${item.variant}`,
        });
      });
      const generateYear = year_range ? year_range.split(' - ') : ['', ''];
      const yearStart = generateYear[0];
      const yearEnd = generateYear[1];

      payload = {
        car_group_id,
        car_group_name,
        car_model: carModel,
        year_start: { value: yearStart, label: yearStart },
        year_end: { value: yearEnd, label: yearEnd },
      };
    }

    setData(payload);
    setIsLoading(false);
  };

  const handleChangeOrigin = (origin) => {
    setOrigin(origin);
  };

  const handleResultNotification = async (notification) => {
    const { status, type, message } = notification;
    if (status) {
      setNotification({
        type: type,
        message: message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    setHasNotification(status);
    setTimeout(() => {
      setHasNotification(false);
      setNotification({});
      return status;
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotification(false);
    setNotification({});
  };

  useEffect(() => {
    getCarGroupDetails();
  }, []);

  return (
    <>
      <Wrapper
        title={`${origin === 'detail' ? 'Detail' : 'Edit'} Car Group Compability`}
        className="wrapperContent"
        breadcrumbData={generateBreadcrumb()}
        showNotification={hasNotification}
        notification={notification}
        onCloseNotification={handleCloseNotification}
      >
        {isLoading ? <LoadingSpinner /> : null}
        {!isLoading ? (
          <Row>
            <Col lg={6}>
              <CarCompabilityForms
                origin={origin}
                handleChangeOrigin={handleChangeOrigin}
                initialValues={data}
                loading={isLoading}
                getCarGroupDetails={getCarGroupDetails}
                handleResultNotification={handleResultNotification}
              />
            </Col>
            {origin === 'detail' ? (
              <Col lg={6}>
                <CarCompabilityLog id={id} />
              </Col>
            ) : null}
          </Row>
        ) : null}
      </Wrapper>
    </>
  );
};

export default DetailCarsCompability;
