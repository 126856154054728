import React from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import Helper from '@helpers/Helper';
import AlertIcon from '@assets/icons/red-alert.svg';
import DetailField from '@components/field/DetailField';

function BookingDetailUser(props) {
  const { detailData } = props;

  const customerType = detailData?.customer?.customer_type;
  const frontName = detailData?.customer?.name ?? '';
  const lastName = detailData?.customer?.nickname ?? '';
  let corporateName = frontName;
  corporateName += lastName == '' ? '' : ' - ' + lastName;
  const customerName = customerType == 'b2b' ? corporateName : frontName ?? '-';

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-primary text-white mt-0">Customer</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <DetailField title="ID" value={detailData?.customer?.id ?? '-'} />
              <DetailField title={customerType == 'b2b' ? 'Nama Perusahaan' : 'Nama'} value={customerName} />
              <DetailField title="Tipe" value={customerType ? customerType.toUpperCase() : '-'} />
              {customerType == 'b2b' && <DetailField title="Status" value={detailData?.customer?.status || '-'} />}
              <DetailField title="Email" value={detailData?.customer?.email || '-'} />
              <DetailField title="No Telepon" value={detailData?.customer?.phone_no || '-'} />
              {customerType == 'b2b' && (
                <>
                  <DetailField title="Nama PIC" value={detailData?.customer?.pic_name || '-'} />
                  <DetailField title="Nomor Telepon PIC" value={detailData?.customer?.pic_phone || '-'} />
                </>
              )}
              <DetailField title="Lokasi" value={detailData?.customer?.address || '-'} />
              <DetailField title="Tipe User" value={Helper.getCommonLabel(detailData?.customer?.user_type) || '-'} />
              {customerType == 'b2b' && (
                <DetailField title="Catatan Internal" value={detailData?.customer?.internal_notes || '-'} />
              )}
              {detailData?.customer?.flag_status == 'suspect abuser' && (
                <div>
                  <img src={AlertIcon} className="large-abuser-notif" />
                  <div className="abuser-notif">
                    <img src={AlertIcon} />
                    <p>Suspect Abuser</p>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default BookingDetailUser;
