import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, CardHeader, Input } from 'reactstrap';
import map from 'lodash/map';
import moment from 'moment';
import API from '@utils/API';
import DataTable from 'react-data-table-component';
import Loading from '@components/loading/Loading';
import UploadIcon from '@assets/icons/upload-file.svg';
import UploadFile from '@components/modal/UploadFile';
import UploadImage from '@components/uploads/UploadImage';
import ConfirmationModal from '@components/modal/ConfirmationModal';

const BookingDetailFleetFileOtoklix = (props) => {
  const api = new API('v2');
  const { detailData, onResultNotification } = props;
  const [loading, setLoading] = useState(false);
  const [dataFile, setDataFile] = useState([]);
  const [activeFile, setActiveFile] = useState({});
  const [subTitle, setSubTitle] = useState('');
  const [hasModalUpload, setHasModalUpload] = useState(false);

  const [errorImage, setErrorImage] = useState('');
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [showModal, setShowModal] = useState(false);

  async function fetchFileOtoklix() {
    setLoading(true);

    try {
      const response = await api.get(`v2/intools/bookings/${detailData?.booking_code}/documents/?option=otoklix`);
      if (response?.data?.data) {
        const fileData = map(response?.data?.data, (item) => {
          const isRequired =
            item?.document_type == 'arrival_report' ||
            item?.document_type == 'mileage_before' ||
            item?.document_type == 'mileage_after' ||
            item?.document_type == 'car_handover'
              ? true
              : false;
          const nameFile = item?.filename
            ? item.filename.length > 25
              ? item.filename.substr(0, 11) + '...' + item.filename.substr(item.filename.length - 11)
              : item.filename
            : '';
          return {
            id: item?.id,
            time: item?.created_at,
            type_file: item?.document_type,
            label_file: item?.label ?? '',
            required: isRequired,
            document: {
              name: nameFile,
              url: item?.url ?? null,
            },
          };
        });
        setDataFile(fileData);
      } else {
        setDataFile([]);
      }
      setLoading(false);
    } catch (error) {
      setDataFile([]);
      setLoading(false);
      handleResultNotification(true, 'fail', 'Gagal mendapatkan lampiran file otoklix');
    }
  }

  const handleResultNotification = (status = false, type = '', message = '', without_reload = false) => {
    const notification = {
      status: status,
      type: type,
      message: message,
      without_reload: without_reload,
    };

    onResultNotification(notification);
  };

  const handleResultUploadFile = (result) => {
    setLoading(true);
    const file = result?.file;

    if (file?.name) {
      const payloadUploadFile = new FormData();
      payloadUploadFile.append('namespace', 'attachment_otoklix');
      payloadUploadFile.append('identifier', detailData?.booking_code);
      payloadUploadFile.append('image', file);
      handleUploadImage(payloadUploadFile, file?.name);
    } else {
      handleResultNotification(true, 'fail', 'Gagal upload file ' + activeFile?.label_file + ' file tidak diketahui');
      setLoading(false);
    }

    setHasModalUpload(!hasModalUpload);
  };

  async function handleUploadImage(payload, fileName) {
    api
      .post(`v2/intools/image-upload/`, payload)
      .then((response) => {
        const keyFile = response?.data?.data?.key ?? '';
        if (keyFile !== '') {
          handleSaveFile(keyFile, fileName);
        } else {
          handleResultNotification(true, 'fail', 'Gagal upload file ' + activeFile?.label_file);
          setLoading(false);
        }
      })
      .catch((e) => {
        const message = `API Error : ${e?.response?.data?.error?.message}`;
        handleResultNotification(true, 'fail', 'Gagal upload file ' + activeFile?.label_file + ' ' + message);
        setLoading(false);
      });
  }

  async function handleSaveFile(keyFile, fileName, file, is_deleted = false) {
    setShowModal(false);
    setLoading(true);
    const payload = {
      document_type: file?.type_file,
      filename: fileName,
      key: keyFile,
      is_deleted,
      id: file?.id ?? null,
    };

    api
      .post(`v2/intools/bookings/${detailData?.booking_code}/documents/`, payload)
      .then((response) => {
        handleResultNotification(
          true,
          'success',
          `Berhasil ${is_deleted ? 'menghapus' : 'menyimpan'} file ` + file?.label_file,
          true
        );
        fetchFileOtoklix();
      })
      .catch((e) => {
        const message = `API Error : ${e?.response?.data?.error?.message}`;
        handleResultNotification(
          true,
          'fail',
          `Gagal ${is_deleted ? 'menhapus' : 'menyimpan'} file ` + file?.label_file + ' ' + message
        );
        setLoading(false);
      });
  }

  async function handleConfirmationModal() {
    setShowModal(false);
    setLoading(true);
    const payload = {
      document_type: activeFile?.type_file,
    };

    api
      .put(`v2/intools/bookings/${detailData?.booking_code}/documents/delete`, payload)
      .then((response) => {
        handleResultNotification(true, 'success', 'Berhasil menghapus file ' + activeFile?.label_file, true);
        fetchFileOtoklix();
      })
      .catch((e) => {
        const message = `API Error : ${e?.response?.data?.error?.message}`;
        handleResultNotification(true, 'fail', 'Gagal menghapus file ' + activeFile?.label_file + ' ' + message);
        setLoading(false);
      });
  }

  const downloadFile = (url_file) => {
    if (url_file) {
      const link = document.createElement('a');
      link.setAttribute('href', url_file);
      link.setAttribute('download', '');
      link.click();
    }
  };

  const handleUploadFile = (files, data) => {
    setActiveFile(data);
    handleSaveFile(files?.key, files?.file?.name, data);
  };

  const handleErrorUploadFile = (msg, data) => {
    setErrorImage(msg);
    if (msg) {
      handleResultNotification(true, 'fail', 'Gagal upload file ' + data?.label_file + ' ' + msg);
    }
  };

  const handleDeleteFile = (e, data) => {
    setShowModal(true);
    setActiveFile(data);
  };

  const handleLoadingUploadFile = (status) => {
    setLoadingUploadFile(status);
  };

  const columns = useMemo(
    () => [
      {
        name: 'Waktu',
        selector: (row) => row?.time,
        cell: (row) => (moment(row?.time).isValid() ? moment(row?.time).format('MMM DD YYYY , HH:mm:ss') : '-'),
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'File Name',
        selector: (row) => row?.label_file,
        cell: (row) => (
          <div className={row?.required ? 'text-bold text-purple-2 label-required' : 'text-bold text-purple-2'}>
            {row?.label_file ?? '-'}
          </div>
        ),
        wrap: true,
        minWidth: '210px',
      },
      {
        name: 'Upload',
        omit: true,
        cell: (row) => (
          <div className="text-bold text-purple-2" style={{ paddingTop: '10px' }}>
            <>
              <Input name="formId" type="file" id="formId" className="display-none" hidden />
              <label htmlFor="">
                <img src={UploadIcon} className="mt-0 mb-0 cursor-pointer" onClick={() => handleModalUpload(row)} />
              </label>
            </>
          </div>
        ),
        wrap: true,
        minWidth: '50px',
      },
      {
        name: 'File',
        selector: (row) => row?.document?.name,
        cell: (row) => (
          <UploadImage
            uploadTitle=""
            isView={loading}
            image={''}
            textMode={true}
            textPreview={row?.document?.name}
            previewURL={row?.document?.url}
            error={errorImage}
            nameSpace={'attachment_otoklix'}
            identifier={detailData?.booking_code}
            maxSize={5120}
            maxSizeString={'5 MB'}
            allowTypesString={'jpeg, jpg, png, xlsx, xls, pdf'}
            allowTypes={['jpeg', 'jpg', 'png', 'xlsx', 'xls', 'pdf']}
            onLoading={handleLoadingUploadFile}
            onError={(e) => handleErrorUploadFile(e, row)}
            onDelete={(e) => handleDeleteFile(e, row)}
            onUpload={(e) => handleUploadFile(e, row)}
          />
        ),
        wrap: true,
        minWidth: '300px',
      },
    ],
    []
  );

  const handleModalUpload = (file) => {
    const labelFile = file?.label_file ?? '';
    setHasModalUpload(true);
    setActiveFile(file);
    setSubTitle('Unggah ' + labelFile + ' dengan maksimal size 5 MB');
  };

  useEffect(() => {
    fetchFileOtoklix();
  }, [detailData]);

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Lampiran File Otoklix</CardHeader>
      <CardBody className="pb-0">
        <DataTable
          persistTableHead
          highlightOnHover
          data={dataFile}
          noHeader={true}
          fixedHeader
          progressPending={loading}
          progressComponent={<Loading />}
          fixedHeaderScrollHeight="340px"
          columns={columns}
          className="table table-bordered dt-responsive"
        />
      </CardBody>
      <UploadFile
        isValidation={true}
        title={'Unggah File'}
        subTitle={subTitle}
        sizeString={'5 MB'}
        sizeValidation={5120}
        modal={hasModalUpload}
        resultUploadFile={handleResultUploadFile}
        toggle={() => setHasModalUpload(!hasModalUpload)}
      />
      <ConfirmationModal
        modal={showModal}
        isDisabled={false}
        toggle={() => setShowModal(!showModal)}
        header={`Yakin ingin menghapus file "${activeFile?.label_file}" ?`}
        onConfirm={() => handleSaveFile(null, null, activeFile, true)}
        toggleClose={() => setShowModal(!showModal)}
      />
    </Card>
  );
};

export default BookingDetailFleetFileOtoklix;
