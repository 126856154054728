import React from 'react';
import { Card, CardHeader, CardBody, Col, Row, FormGroup } from 'reactstrap';

const BookingWorkshopDetail = (props) => {
  const { dataWorkshop } = props;
  return (
    <Card style={{ minHeight: 205 }}>
      <CardHeader className="text-white mt-0 d-flex justify-content-between">Rincian Bengkel</CardHeader>
      <CardBody>
        <FormGroup row className="mb-4">
          <Col lg={2} sm={2} className="pr-4">
            <Row>
              <Col lg={12} sm={12} className="text-sm-right">
                <span className="label">Bengkel</span>
              </Col>
            </Row>
          </Col>
          <Col lg={10} sm={10} className="pr-4">
            <Row>
              <Col lg={12} sm={12} className="col-summary">
                {dataWorkshop ? (
                  <>
                    <span className="font-weight-bold">{dataWorkshop?.name}</span>
                    <span>{dataWorkshop?.location?.street_address}</span>
                  </>
                ) : (
                  <span>-</span>
                )}
              </Col>
            </Row>
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );
};

export default BookingWorkshopDetail;
