import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SearchItemService from '@services/searchItem.service';
import customerService from '@services/customer.service';

export const getCustomerGroupOptions = createAsyncThunk(
    'customer/getCustomerGroupOptions',
    async (_, { rejectWithValue }) => {
        try {
            const response = await customerService.getCustomerGroupOptions();
            return response.data?.data || [];
        } catch (error) {
            return rejectWithValue(error?.data || 'Failed to fetch customer group options');
        }
    }
)