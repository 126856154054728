import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import API from '@utils/API';
import Wrapper from '@components/wrapper/Wrapper';
import InventoryAddNew from '@components/workshops/inventory/InventoryAddNew';

const InventoryAdd = () => {
  const api = new API('v2');
  const { workshop_id } = useParams();
  const queryParams = useLocation().search;
  const productId = new URLSearchParams(queryParams).get('id');
  const warehouseId = new URLSearchParams(queryParams).get('wid');
  const isConsignment = new URLSearchParams(queryParams).get('consignment');

  const [hasNotif, setHasNotif] = useState(false);
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataWorkshop, setDataWorkshop] = useState({});

  const breadcrumbItem = [
    {
      id: 1,
      label: 'List Workshop',
      active: false,
      url: '/workshops',
    },
    {
      id: 2,
      label: 'Detail Workshop',
      active: false,
      url: `/workshops/${dataWorkshop?.id}`,
    },
    {
      id: 3,
      label: `${dataWorkshop?.name ?? '-'}`,
      active: false,
      url: `/workshops/${dataWorkshop?.id}?activetab=inventory`,
    },
    {
      id: 4,
      label: `Tambah Stok`,
      active: true,
      url: '',
    },
  ];

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const getWorkshopData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/workshops/${workshop_id}/general-info/`);
      setDataWorkshop(response?.data?.data ?? {});
      setLoading(false);
    } catch (error) {
      const errorMsg = error?.response?.data?.error?.message ?? '';
      setDataWorkshop({});
      setLoading(false);
      handleResultNotification({ status: true, type: 'fail', message: 'Fail get data workshop ' + errorMsg });
    }
  };

  useEffect(() => {
    getWorkshopData();
  }, []);

  useEffect(() => {
    const confirmExit = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', confirmExit);

    return () => {
      window.removeEventListener('beforeunload', confirmExit);
    };
  }, []);

  return (
    <React.Fragment>
      <Wrapper
        title="Tambah Stok Inventory"
        breadcrumbData={breadcrumbItem}
        showNotification={hasNotif}
        notification={notification}
        onCloseNotification={() => setHasNotif(false)}
      >
        <InventoryAddNew
          onResultNotification={handleResultNotification}
          workshopId={workshop_id}
          workshopSlug={dataWorkshop?.slug}
          consignment={isConsignment}
          warehouseId={warehouseId}
          productId={productId}
        />
      </Wrapper>
    </React.Fragment>
  );
};

export default InventoryAdd;
