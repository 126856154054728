import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import EditableInputField from '@components/field/EditableInputField';

const AddNewRoleModal = (props) => {
  const { modal, toggle, onChange, error, value, onConfirm } = props;

  return (
    <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggle}>
      <ModalHeader>Add New Role</ModalHeader>
      <ModalBody>
        <EditableInputField
          title="Role Name"
          labelWidth={12}
          textLeft
          required
          inputType="text"
          inputName="role"
          onChange={onChange}
          invalid={error ? true : false}
          errorMessage={error}
          value={value}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="m-auto px-4 rounded-pill" size="lg" onClick={onConfirm} disabled={!value}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddNewRoleModal;
