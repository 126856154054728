import React from 'react';
import map from 'lodash/map';
import Helper from '@helpers/Helper';
import { Row, Col, Card, CardBody, CardHeader, FormGroup } from 'reactstrap';
import OverridePriceIcon from '@assets/icons/override-price.svg';
import { Tooltip } from 'react-tooltip';

function BookingDetailSettlementOtoklix(props) {
  const { data, detailData, title } = props;

  const customerType = data?.customer?.customer_type;
  const packages = detailData?.items?.packages;
  const products = detailData?.items?.products;
  const services = detailData?.items?.services;

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-primary text-white mt-0">{title}</CardHeader>
        <CardBody className="pb-0">
          <FormGroup row className="mb-0">
            <Col lg={12} sm={12}>
              <Row>
                <Col lg={4} sm={12} className="col-summary">
                  <span className="detail-group"></span>
                </Col>
                <Col lg={3} sm={12} className="col-summary">
                  <span className="detail-group price">{'Customer'}</span>
                </Col>
                <Col lg={2} sm={12} className="col-summary">
                  <span className="detail-group price">{'Workshop'}</span>
                </Col>
                <Col lg={3} sm={12} className="col-summary">
                  <span className="detail-group price">{'Margin'}</span>
                </Col>
              </Row>
            </Col>
          </FormGroup>
          {packages?.package_items?.length > 0 ? (
            <FormGroup row className="mb-0">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={4} sm={12} className="col-summary">
                    <span className="detail-group">Paket</span>
                  </Col>
                  <Col lg={3} sm={12} className="col-summary">
                    <span></span>
                  </Col>
                  <Col lg={2} sm={12} className="col-summary">
                    <span></span>
                  </Col>
                  <Col lg={3} sm={12} className="col-summary">
                    <span></span>
                  </Col>
                </Row>
              </Col>

              {map(packages?.package_items, (item) => {
                return (
                  <>
                    <Col lg={12} sm={12}>
                      <Row>
                        <Col lg={4} sm={12} className="col-summary">
                          <span className="detail-group">{item?.name}</span>
                        </Col>
                        <Col lg={3} sm={12} className="col-summary">
                          <span></span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span></span>
                        </Col>
                        <Col lg={3} sm={12} className="col-summary">
                          <span className="detail-group price">{`Rp ${Helper.formatMoney(item?.price ?? 0)}`}</span>
                        </Col>
                      </Row>
                    </Col>
                    {map(item?.item_details, (subitem) => {
                      return (
                        <Col lg={12} sm={12}>
                          <Row>
                            <Col lg={5} sm={5} className="col-summary">
                              <>
                                <span className="detail-group-item-name">
                                  {subitem?.name}
                                  {subitem?.is_fbo && <span className="fbo-badge">FBO</span>}
                                  {subitem?.is_custom && <span className="custom-badge">Custom</span>}
                                </span>
                              </>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name price">
                                {`Rp ${Helper.formatMoney(subitem?.price ?? 0)}`}
                              </span>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name price">
                                {`Rp ${Helper.formatMoney(subitem?.nett_price ?? 0)}`}
                              </span>
                            </Col>
                            <Col lg={3} sm={12} className="col-summary">
                              <span className="price detail-group-item-name">
                                {'IDR ' + Helper.formatMoney(subitem?.margin ?? 0)}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                    {item?.item_details[0]?.note && (
                      <Col className="col-summary">
                        <span className="remarks-item mb-0">{'Keterangan :'}</span>
                        <span className="remarks-item mt-0">
                          {!item?.item_details[0]?.note ? '-' : item?.item_details[0]?.note.replace(/-/g, '‑')}
                        </span>
                      </Col>
                    )}
                  </>
                );
              })}
            </FormGroup>
          ) : null}

          {products?.product_items?.length > 0 ? (
            <FormGroup row className="mb-0">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={4} sm={12} className="col-summary">
                    <span className="detail-group">Produk</span>
                  </Col>
                  <Col lg={3} sm={12} className="col-summary">
                    <span></span>
                  </Col>
                  <Col lg={2} sm={12} className="col-summary">
                    <span></span>
                  </Col>
                  <Col lg={3} sm={12} className="col-summary">
                    <span></span>
                  </Col>
                </Row>
              </Col>

              {map(products?.product_items, (item) => {
                return (
                  <Col lg={12} sm={12}>
                    <Row>
                      <Col lg={5} sm={5} className="col-summary">
                        <>
                          <span className="detail-group-item-name">
                            {item?.name}
                            {item?.is_fbo && <span className="fbo-badge">FBO</span>}
                            {item?.is_custom && <span className="custom-badge">Custom</span>}
                          </span>
                          {item?.remark && (
                            <>
                              <span className="remarks-item mb-0">{'Keterangan :'}</span>
                              <span className="remarks-item mt-0">
                                {!item?.remark ? '-' : item?.remark.replace(/-/g, '‑')}
                              </span>
                            </>
                          )}
                        </>
                      </Col>
                      <Col lg={2} sm={12} className="col-summary">
                        <span className="detail-group-item-name price">
                          {`Rp ${Helper.formatMoney(item?.price ?? 0)}`}
                        </span>
                      </Col>
                      <Col lg={2} sm={12} className="col-summary">
                        <span className="detail-group-item-name price">
                          {`Rp ${Helper.formatMoney(item?.nett_price ?? 0)}`}
                        </span>
                      </Col>
                      <Col lg={3} sm={12} className="col-summary">
                        <span className="price detail-group-item-name">
                          {'IDR ' + Helper.formatMoney(item?.margin ?? 0)}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                );
              })}

              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={6} sm={12} className="col-summary">
                    <span className="detail-group">Subtotal Produk</span>
                  </Col>
                  <Col lg={6} sm={12} className="col-summary">
                    <span className="price detail-group">{`IDR ${Helper.formatMoney(
                      products?.subtotal_product ?? 0
                    )}`}</span>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          ) : null}

          {services?.service_items?.length > 0 ? (
            <FormGroup row className="mb-0">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={4} sm={12} className="col-summary">
                    <span className="detail-group">Jasa</span>
                  </Col>
                  <Col lg={3} sm={12} className="col-summary">
                    <span></span>
                  </Col>
                  <Col lg={2} sm={12} className="col-summary">
                    <span></span>
                  </Col>
                  <Col lg={3} sm={12} className="col-summary">
                    <span></span>
                  </Col>
                </Row>
              </Col>

              {map(services?.service_items, (item) => {
                return (
                  <Col lg={12} sm={12}>
                    <Row>
                      <Col lg={5} sm={5} className="col-summary">
                        <>
                          <span className="detail-group-item-name">
                            {item?.name}
                            {item?.is_fbo && <span className="fbo-badge">FBO</span>}
                            {item?.is_custom && <span className="custom-badge">Custom</span>}
                          </span>
                          {item?.remark && (
                            <>
                              <span className="remarks-item mb-0">{'Keterangan :'}</span>
                              <span className="remarks-item mt-0">
                                {!item?.remark ? '-' : item?.remark.replace(/-/g, '‑')}
                              </span>
                            </>
                          )}
                        </>
                      </Col>
                      <Col lg={2} sm={12} className="col-summary">
                        <span className="detail-group-item-name price">
                          {`Rp ${Helper.formatMoney(item?.price ?? 0)}`}
                        </span>
                      </Col>
                      <Col lg={2} sm={12} className="col-summary">
                        <span className="detail-group-item-name price">
                          {`Rp ${Helper.formatMoney(item?.nett_price ?? 0)}`}
                        </span>
                      </Col>
                      <Col lg={3} sm={12} className="col-summary">
                        <span className="price detail-group-item-name">
                          {'IDR ' + Helper.formatMoney(item?.margin ?? 0)}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                );
              })}

              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={6} sm={12} className="col-summary">
                    <span className="detail-group">Subtotal Jasa</span>
                  </Col>
                  <Col lg={6} sm={12} className="col-summary">
                    <span className="price detail-group">{`IDR ${Helper.formatMoney(
                      services?.subtotal_service ?? 0
                    )}`}</span>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          ) : null}

          <FormGroup row className="mb-0">
            <Col lg={12} sm={12} className="col-summary">
              <div className="divider"></div>
            </Col>
          </FormGroup>
          <FormGroup row className={customerType == 'b2b' ? 'mb-0' : ''}>
            <Col lg={4} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-left col-summary">
                  <span className="group">{'Subtotal'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={8} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span className="price detail-group">{`IDR ${Helper.formatMoney(detailData?.subtotal ?? 0)}`}</span>
                </Col>
              </Row>
            </Col>
          </FormGroup>
          {customerType !== 'b2b' && (
            <>
              <FormGroup row>
                <Col lg={4} sm={12} className="pr-3">
                  <Row>
                    <Col lg={12} sm={12} className="text-sm-left col-summary">
                      <span className="group">{'Promo Whole Cart'}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={8} sm={12}>
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="price discount-otopoints">
                        {`- IDR ${Helper.formatMoney(data?.discount?.amount ?? 0)}`}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
              <FormGroup row className="">
                <Col lg={4} sm={12} className="pr-3">
                  <Row>
                    <Col lg={12} sm={12} className="text-sm-left col-summary">
                      <span className="group">{'Otopoints'}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={8} sm={12}>
                  <Row>
                    <Col lg={12} sm={12} className="col-summary">
                      <span className="price discount-otopoints">
                        {(data?.otopoints?.point_spent < 0 ? 'IDR ' : '- IDR ') +
                          Helper.formatMoney(data?.otopoints?.point_spent ?? 0)}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            </>
          )}
          <FormGroup row className="mb-0">
            <Col lg={12} sm={12} className="col-summary">
              <div className="divider"></div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col lg={4} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-left col-summary">
                  <span className="group">{'Gross Profit'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={8} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span
                    className={
                      detailData?.gross_profit < 0 ? 'price detail-group discount-otopoints' : 'price detail-group'
                    }
                  >
                    {Helper.generateFinalMoney(detailData?.gross_profit ?? 0)}
                  </span>
                </Col>
              </Row>
            </Col>
          </FormGroup>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default BookingDetailSettlementOtoklix;
