import React, { useState } from 'react';
import Wrapper from '@components/wrapper/Wrapper';
import CarCompabilityForms from '@components/cars-compability/CarCompabilityForms';
import { Col, Row } from 'reactstrap';

const CreateCarsCompability = () => {
  const [notification, setNotification] = useState({});
  const [hasNotification, setHasNotification] = useState(false);

  const generateBreadcrumb = () => {
    return [
      {
        id: 1,
        label: 'Cars',
        active: false,
        url: '/cars',
      },
      {
        id: 2,
        label: 'List Car Compability',
        active: false,
        url: '/cars',
      },
      {
        id: 3,
        label: 'Create Group Car Compability',
        active: true,
        url: '/compability/create',
      },
    ];
  };

  const handleResultNotification = async (notification) => {
    const { status, type, message } = notification;
    if (status) {
      setNotification({
        type: type,
        message: message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    setHasNotification(status);
    setTimeout(() => {
      setHasNotification(false);
      setNotification({});
      return status;
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotification(false);
    setNotification({});
  };

  return (
    <>
      <Wrapper
        title="Create Car Group Compability"
        className="wrapperContent"
        breadcrumbData={generateBreadcrumb()}
        showNotification={hasNotification}
        notification={notification}
        onCloseNotification={handleCloseNotification}
      >
        <Row>
          <Col lg="6">
            <CarCompabilityForms origin="create" handleResultNotification={handleResultNotification} />
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

export default CreateCarsCompability;
