import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SearchItemService from '@services/searchItem.service';
import bookingService from '../../services/booking.service';

export const setIsDisableCreateBookingButton = createAsyncThunk(
  'booking/setIsDisableCreateBookingButton',
  async (isDisableCreateBookingButton) => {
    return isDisableCreateBookingButton;
  }
);

export const getPackages = createAsyncThunk('booking/getPackages', async (payload, { rejectWithValue }) => {
  try {
    const response = await SearchItemService.getPackages(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error?.data || 'Failed to fetch packages');
  }
});

export const getProducts = createAsyncThunk('booking/getProducts', async (payload, { rejectWithValue }) => {
  try {
    const response = await SearchItemService.getProducts(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error?.data || 'Failed to fetch products');
  }
});

export const getCalculatePrice = createAsyncThunk('booking/getCalculatePrice', async (payload, { rejectWithValue }) => {
  try {
    const response = await bookingService.getCalculatePrice(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error?.data || 'Failed to fetch calculate price');
  }
});

export const createBookingB2BDraft = createAsyncThunk(
  'booking/createBookingB2BDraft',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await bookingService.createBookingB2BDraft(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error || 'Failed to create booking');
    }
  }
);

export const updateBookingB2BDraft = createAsyncThunk(
  'booking/updateBookingB2BDraft',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await bookingService.updateBookingB2BDraft(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error || 'Failed to create booking');
    }
  }
);

export const bookingSlice = createSlice({
  name: 'booking',
  initialState: {
    isDisableCreateBookingButton: true,
    selectedWorkshopCreateBooking: [],
    temporaryBookingData: null,
    packages: [],
    packageLoading: false,
    packageError: null,
    products: [],
    productLoading: false,
    productError: null,
    selectedTemporaryCart: [],
    calculatePrice: {},
    calculatePriceLoading: false,
    calculatePriceError: null,
    bookingB2BDraft: null,
  },
  reducers: {
    addWorkshop: (state, action) => {
      if (!Array.isArray(state.selectedWorkshopCreateBooking)) {
        state.selectedWorkshopCreateBooking = [];
      }
      if (!state.selectedWorkshopCreateBooking.includes(action.payload)) {
        state.selectedWorkshopCreateBooking.push(action.payload);
      }
    },
    addMultipleWorkshop: (state, action) => {
      state.selectedWorkshopCreateBooking = action.payload;
    },
    removeWorkshop: (state, action) => {
      if (!Array.isArray(state.selectedWorkshopCreateBooking)) {
        state.selectedWorkshopCreateBooking = [];
      }

      // Assuming action.payload is the workshop object you want to remove
      const workshopToRemove = action.payload;

      state.selectedWorkshopCreateBooking = state.selectedWorkshopCreateBooking.filter(
        (workshop) => workshop.id !== workshopToRemove
      );
    },
    removeAllWorkshop: (state, _) => {
      state.selectedWorkshopCreateBooking = [];
    },
    addTemporaryCart: (state, action) => {
      if (!Array.isArray(state.selectedTemporaryCart)) {
        state.selectedTemporaryCart = [];
      }
      if (!state.selectedTemporaryCart.includes(action.payload)) {
        state.selectedTemporaryCart.push(action.payload);
      }
    },
    removeTemporaryCart: (state, action) => {
      state.selectedTemporaryCart = [];
    },
    overwriteSelectedTemporaryCart: (state, action) => {
      if (action.payload && typeof action.payload === 'object') {
        state.selectedTemporaryCart = action.payload;
      } else {
        console.error('Invalid payload for overwriting cart data');
      }
    },
    addTemporaryBookingData: (state, action) => {
      state.temporaryBookingData = action.payload;
    },
    removeTemporaryBookingData: (state, action) => {
      state.temporaryBookingData = {};
    },
    addCalculatePrice: (state, action) => {
      state.calculatePrice = action.payload;
    },
    reset: (state) => {
      state.isDisableCreateBookingButton = true;
      state.selectedWorkshopCreateBooking = [];
      state.temporaryBookingData = null;
      state.packages = [];
      state.packageLoading = false;
      state.packageError = null;
      state.products = [];
      state.productLoading = false;
      state.productError = null;
      state.selectedTemporaryCart = [];
      state.calculatePrice = {};
      state.calculatePriceLoading = false;
      state.calculatePriceError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setIsDisableCreateBookingButton.fulfilled, (state, action) => {
      state.isDisableCreateBookingButton = action.payload;
    });
    builder
      .addCase(getPackages.pending, (state) => {
        state.packageLoading = true;
        state.packageError = null;
      })
      .addCase(getPackages.fulfilled, (state, action) => {
        state.packageLoading = false;
        state.packages = action.payload;
      })
      .addCase(getPackages.rejected, (state, action) => {
        state.packageLoading = false;
        state.packageError = action.payload;
      });
    builder
      .addCase(getProducts.pending, (state) => {
        state.packageLoading = true;
        state.packageError = null;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.packageLoading = false;
        state.packages = action.payload;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.packageLoading = false;
        state.packageError = action.payload;
      });
    builder
      .addCase(getCalculatePrice.pending, (state) => {
        state.calculatePriceLoading = true;
        state.calculatePriceError = null;
      })
      .addCase(getCalculatePrice.fulfilled, (state, action) => {
        state.calculatePriceLoading = false;
        state.calculatePrice = action.payload;
      })
      .addCase(getCalculatePrice.rejected, (state, action) => {
        state.calculatePriceLoading = false;
        state.calculatePriceError = action.payload;
      });
    builder.addCase(createBookingB2BDraft.fulfilled, (state, action) => {
      state.bookingB2BDraft = action.payload;
    });
    builder.addCase(updateBookingB2BDraft.fulfilled, (state, action) => {
      state.bookingB2BDraft = action.payload;
    });
  },
});

export const {
  addWorkshop,
  removeWorkshop,
  removeAllWorkshop,
  addMultipleWorkshop,
  addTemporaryCart,
  removeTemporaryCart,
  overwriteSelectedTemporaryCart,
  addTemporaryBookingData,
  removeTemporaryBookingData,
  addCalculatePrice,
  saveCalculatePrice,
  reset,
} = bookingSlice.actions;

export default bookingSlice.reducer;
