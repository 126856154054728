import React, { useState, useCallback, useMemo } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row,
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import EditableInputField from '../field/EditableInputField';
import SelectAsyncField from '../field/SelectAsyncField';
import RadioInput from '../field/RadioInput';
import InputField from '../field/InputField';
import { statusOptions, customerTypeOptions } from '@utils/Constants';
import ConfirmationModal from '@components/modal/ConfirmationModal';

const B2BCustomerGeneralInformationForms = (props) => {
  const {
    isB2B,
    formValues,
    customerType,
    handleChangeCustomerType,
    handleChangeCustomerName,
    handleChangeNickname,
    handleChangePICName,
    handleChangePhoneNumber,
    handleChangeEmail,
    handleChangePIC,
    handleChangeCustomerGroup,
    handleChangeStatus,
    handleChangeCompanyAddress,
    origin,
    loadPIC,
    loadingLoadPIC,
    listPIC,
    listCustomerGroup,
    loadCustomerGroupOptions,
    loadingCustomerGroup,
    loadingSubmit,
    allowEdit,
    handleSubmit,
  } = props;

  const [disableForm, setDisableForm] = useState(true);
  const [hasModalConfirmation, setHasModalConfirmation] = useState(false);
  const [hasModalCreateGroup, setHasModalCreateGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [onEdit, setOnEdit] = useState(false);

  const handleClickEdit = useCallback(() => {
    setDisableForm(false);
    setOnEdit(true);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setDisableForm(true);
    setOnEdit(false);
  }, []);

  const handleSuccessSubmit = useCallback((res) => {
    const newData = res?.data?.data ?? {};
    console.log(newData.customer_group);
    console.log(res);
    handleChangeCustomerGroup(newData.customer_group ?? null);
    handleChangeCustomerName({ target: { value: newData.username ?? '' } });
    handleChangeNickname({ target: { value: newData.nickname ?? '' } });
    handleChangePhoneNumber({ target: { value: newData.phone_number ?? '' } });
    handleChangeEmail(newData.email ?? '');
    handleChangeCompanyAddress({ target: { value: newData.company_address ?? '' } });
    handleChangePICName({ target: { value: newData.pic_name ?? '' } });
    handleChangePhoneNumber({ target: { value: newData.pic_phone ?? '' } });
    handleChangePIC(newData.b2b_pic ?? null);
    handleChangeStatus({ value: newData.status ?? '' });

    handleCancelEdit();
  }, []);

  const toggleModalConfirmation = useCallback(() => setHasModalConfirmation((prev) => !prev), []);
  const toggleModalCreateGroup = useCallback(() => setHasModalCreateGroup((prev) => !prev), []);

  const b2bCustomerStatus = useMemo(() => {
    return statusOptions.find((status) => status.value === formValues?.b2b_customer_status) ?? [{}];
  }, [formValues?.b2b_customer_status]);

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Informasi Umum</CardHeader>
      <CardBody>
        <Row>
          <Col md={12}>
            <RadioInput
              required
              labelWidth={3}
              colWidth={9}
              label="Tipe Pelanggan"
              options={customerTypeOptions}
              checked={customerType}
              onClick={handleChangeCustomerType}
              disabled={origin !== 'create'}
            />

            <FormGroup row>
              <Label lg={3} className="label-required">
                Grup Perusahaan
              </Label>
              <SelectAsyncField
                colWidth={9}
                isDisabled={disableForm}
                value={formValues?.customer_group ?? {}}
                defaultOptions={listCustomerGroup}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e}
                onChange={handleChangeCustomerGroup}
                invalid={!!formValues?.error_customer_group}
                errorMessage={formValues?.error_customer_group}
                placeholder="Pilih Grup..."
                loading={loadingCustomerGroup}
                onInputChange={(e) => {
                  loadCustomerGroupOptions();
                }}
                needInputChange={true}
                isCreate={true}
                textLabelCreate="+ Tambah Grup"
                onClickCreate={toggleModalCreateGroup}
              />
            </FormGroup>

            <FormGroup row>
              <Label lg={3} className="label-required">
                {isB2B ? 'Nama Perusahaan' : 'Nama'}
              </Label>
              <InputField
                colWidth={9}
                placeholder="Ex: Takaaki"
                value={formValues?.customer_name}
                invalid={!!formValues?.error_customer_name}
                errorMessage={formValues?.error_customer_name}
                disabled={disableForm}
                onChange={handleChangeCustomerName}
              />
            </FormGroup>

            <FormGroup row>
              <Label lg={3}>Nickname Perusahaan</Label>
              <InputField
                colWidth={9}
                placeholder="Ex: Nakagawa"
                value={formValues?.nickname}
                invalid={!!formValues?.error_nickname}
                errorMessage={formValues?.error_nickname}
                disabled={disableForm}
                onChange={handleChangeNickname}
              />
            </FormGroup>

            <FormGroup row>
              <Label lg={3}>No Telp Perusahaan</Label>
              <InputField
                colWidth={9}
                placeholder="Ex: 08123456789"
                inputName="phone_number"
                value={formValues?.phone_number}
                invalid={!!formValues?.error_phone_number}
                errorMessage={formValues?.error_phone_number}
                disabled={disableForm}
                onChange={handleChangePhoneNumber}
              />
            </FormGroup>

            <FormGroup row>
              <Label lg={3} className="label-required">
                Email Perusahaan
              </Label>
              <InputField
                colWidth={9}
                placeholder="Ex: people@otoklix.com"
                inputName="email"
                value={formValues?.email}
                invalid={!!formValues?.error_email}
                errorMessage={formValues?.error_email}
                disabled={disableForm}
                onChange={handleChangeEmail}
              />
            </FormGroup>

            <EditableInputField
              colWidth={9}
              labelWidth={3}
              inputType="textarea"
              title="Alamat Perusahaan"
              placeholder="Ex: Jl. Raya Kebayoran Lama No. 12"
              inputName="company_address"
              value={formValues?.company_address}
              invalid={!!formValues?.error_company_address}
              errorMessage={formValues?.error_company_address}
              disabled={disableForm}
              onChange={handleChangeCompanyAddress}
            />

            <FormGroup row>
              <Label lg={3} className="label-required">
                Nama PIC Utama Perusahaan
              </Label>
              <InputField
                colWidth={9}
                placeholder="Ex: Takaaki"
                value={formValues?.pic_name}
                invalid={!!formValues?.error_pic_name}
                errorMessage={formValues?.error_pic_name}
                disabled={disableForm}
                onChange={handleChangePICName}
              />
            </FormGroup>

            <FormGroup row>
              <Label lg={3} className="label-required">
                No Telp PIC Utama Perusahaan
              </Label>
              <InputField
                colWidth={9}
                placeholder="Ex: 08123456789"
                inputName="pic_phone"
                value={formValues?.pic_phone}
                invalid={!!formValues?.error_pic_phone}
                errorMessage={formValues?.error_pic_phone}
                disabled={disableForm}
                onChange={handleChangePhoneNumber}
              />
            </FormGroup>

            <FormGroup row>
              <Label lg={3} className="label-required">
                B2B PIC
              </Label>
              <SelectAsyncField
                colWidth={9}
                isDisabled={disableForm}
                value={formValues?.b2b_pic ?? {}}
                defaultOptions={listPIC}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                loadOptions={loadPIC}
                onChange={handleChangePIC}
                invalid={!!formValues?.error_b2b_pic}
                errorMessage={formValues?.error_b2b_pic}
                loading={loadingLoadPIC}
              />
            </FormGroup>

            <FormGroup row>
              <Label lg={3} className="label-required">
                Status
              </Label>
              <SelectAsyncField
                colWidth={9}
                isDisabled={disableForm}
                value={b2bCustomerStatus}
                defaultOptions={statusOptions}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                loadOptions={loadPIC}
                onChange={handleChangeStatus}
                invalid={!!formValues?.error_b2b_customer_status}
                errorMessage={formValues?.error_b2b_customer_status}
                loading={loadingLoadPIC}
              />
            </FormGroup>
          </Col>
          <Col className="section-action mb-2 mt-4">
            {allowEdit && onEdit ? (
              <>
                <Button className="button-action save" onClick={toggleModalConfirmation} disabled={loadingSubmit}>
                  {loadingSubmit ? <Spinner color="light" size="sm" /> : 'Simpan'}
                </Button>
                <Button className="button-action cancel" onClick={handleCancelEdit} disabled={loadingSubmit}>
                  Batal
                </Button>
              </>
            ) : (
              allowEdit && (
                <Button color="purple" className="button-action" onClick={handleClickEdit} disabled={loadingSubmit}>
                  Edit
                </Button>
              )
            )}
          </Col>
        </Row>
      </CardBody>

      <ConfirmationModal
        toggle={toggleModalConfirmation}
        toggleClose={toggleModalConfirmation}
        onConfirm={() => {
          setDisableForm(true);
          toggleModalConfirmation();
          handleSubmit(handleSuccessSubmit, handleClickEdit);
        }}
        modal={hasModalConfirmation}
        header="Simpan Perubahan Informasi Umum?"
        subHeader={`Simpan Perubahan Informasi Umum atas nama ${formValues?.customer_name}?`}
      />

      <Modal isOpen={hasModalCreateGroup} toggle={toggleModalCreateGroup} centered className="modal-add-group">
        <ModalHeader toggle={toggleModalCreateGroup}>Tambah Grup</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup row>
                <Label lg={3} className="label-required">
                  Nama Grup
                </Label>
                <InputField value={newGroupName} onChange={(e) => setNewGroupName(e.target.value)} />
              </FormGroup>
              <div className="my-3 text-center">
                <Button color="purple" outline onClick={toggleModalCreateGroup} className="mx-2">
                  Batal
                </Button>
                <Button
                  color="purple"
                  onClick={() => {
                    const value = { name: newGroupName, id: null };
                    handleChangeCustomerGroup(value);
                    listCustomerGroup.push(value);
                    toggleModalCreateGroup();
                  }}
                  className="mx-2"
                  disabled={newGroupName.trim() === ''}
                >
                  Simpan
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Card>
  );
};

export default B2BCustomerGeneralInformationForms;
