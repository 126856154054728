import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge } from 'reactstrap';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import ExportButton from '@components/export/ExportButton';
import ActionFilterService from '@components/filters/ActionFilterService';
import Loading from '@components/loading/Loading';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import Helper from '@helpers/Helper';

function Services() {
  const history = useHistory();
  const api = new API('v2');
  const { generatePermission } = userPermission();

  const [data, setData] = useState([]);
  const [serviceName, setServiceName] = useState('');
  const [sku, setSku] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadServices(1);
  }, []);

  async function loadServices(page) {
    setLoading(true);
    const response = await api.get(
      `v2/intools/service/get-filtered-services/?name=${serviceName}&sku=${sku}&page=${page}&limit=${perPage}`
    );

    setData(response.data.data);
    setTotalRows(response.data.pagination.total_rows);
    setLoading(false);
  }

  const handlePageChange = (page) => {
    loadServices(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await api.get(
      `v2/intools/service/get-filtered-services/?name=${serviceName}&sku=${sku}&page=${page}&limit=${newPerPage}`
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const openServiceDetail = (row) => {
    if (generatePermission('services', 'detail')) {
      history.push(`/services/${row.id}`);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: 'name',
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'SKU',
        selector: 'sku',
        width: '90px',
      },
      {
        name: 'Regular Price',
        cell: (row) => `Rp. ${Helper.formatMoney(row.regular_price)}`,
        minWidth: '120px',
      },
      {
        name: 'Discount Price',
        cell: (row) => `Rp. ${Helper.formatMoney(row.discount_price)}`,
        minWidth: '120px',
      },
      {
        name: 'Status',
        cell: (row) =>
          row.is_active === 0 ? (
            <Badge className="p-2 badge-warning">Not Active</Badge>
          ) : (
            <Badge className="p-2 badge-success">Active</Badge>
          ),
        width: '90px',
      },
    ],
    []
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleSearch = () => {
      loadServices(1);
    };

    return (
      <ActionFilterService
        serviceName={serviceName}
        onChangeServiceName={(e) => setServiceName(e.target.value)}
        sku={sku}
        onChangeSku={(e) => setSku(e.target.value)}
        onSearch={handleSearch}
      />
    );
  }, [serviceName, sku]);

  return (
    <Wrapper title="Services" className="wrapperContent">
      <DataTable
        persistTableHead
        highlightOnHover
        pagination
        subHeader
        progressPending={loading}
        data={data}
        columns={generatePermission('services', 'list') ? columns : []}
        className="table table-bordered dt-responsive"
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        progressComponent={generatePermission('services', 'list') ? <Loading /> : <></>}
        actions={
          <ExportButton
            hasActionExport={generatePermission('services', 'export')}
            data={data}
            title="Export CSV"
            keys={['name', 'sku', 'regular_price', 'discount_price', 'is_active']}
            header={['name', 'sku', 'regular price', 'discount', 'status']}
          />
        }
        subHeaderComponent={generatePermission('services', 'search') ? subHeaderComponentMemo : ''}
        onRowClicked={(row) => openServiceDetail(row)}
      />
    </Wrapper>
  );
}

export default Services;
