import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, Col, Input, Row, Spinner } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import API from '@utils/API';
import CheckboxTree from 'react-checkbox-tree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faChevronDown, faChevronRight, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import {
  faFile,
  faFolder,
  faFolderOpen,
  faPlusSquare,
  faSquare,
  faSquareCheck,
} from '@fortawesome/free-regular-svg-icons';

const ACLForm = () => {
  const api = new API('v2');
  const history = useHistory();
  const { id } = useParams();

  const [hasEditRoleName, setHasEditRoleName] = useState(false);
  const [roleName, setRoleName] = useState('');
  const [permissionList, setPermissionList] = useState([]);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [hasMessage, setHasMessage] = useState();
  const [isError, setIsError] = useState(false);

  const fetchACLData = async () => {
    setIsLoading(true);

    api
      .get(`v2/intools/roles/${id}/`)
      .then((result) => {
        const data = result?.data?.data;

        setRoleName(data?.name ?? '');
        setPermissionList(data?.permissions);
        setChecked(data?.permission_values);
        setExpanded(data?.permission_expanded);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setHasMessage('There is an error occured. Please try reloading the page.');
        setIsLoading(false);
      });
  };

  const handleChangeRoleName = (e) => {
    setRoleName(e.target.value);
  };

  const handleSubmit = async () => {
    let tempPermissionList = [];
    checked.map((number) => {
      tempPermissionList.push(Number(number));
    });

    const params = {
      name: roleName,
      permissions: tempPermissionList,
    };

    setLoadingSubmit(true);

    api
      .put(`v2/intools/roles/${id}/`, params)
      .then(() => {
        setLoadingSubmit(false);
        setHasMessage('Success');
        setIsError(false);
        setTimeout(() => {
          history.push('/acl?activetab=role');
        }, 2000);
      })
      .catch((err) => {
        setHasMessage(err?.response?.data?.error?.message);
        setIsError(true);
        setLoadingSubmit(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchACLData();
    }
  }, [id]);

  const icons = {
    check: <FontAwesomeIcon icon={faCheckSquare} />,
    uncheck: <FontAwesomeIcon icon={faSquare} />,
    halfCheck: <FontAwesomeIcon icon={faSquareCheck} />,
    expandClose: <FontAwesomeIcon icon={faChevronRight} />,
    expandOpen: <FontAwesomeIcon icon={faChevronDown} />,
    expandAll: <FontAwesomeIcon icon={faPlusSquare} />,
    collapseAll: <FontAwesomeIcon icon={faMinusSquare} />,
    parentClose: <FontAwesomeIcon icon={faFolder} />,
    parentOpen: <FontAwesomeIcon icon={faFolderOpen} />,
    leaf: <FontAwesomeIcon icon={faFile} />,
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <Input type="text" value={roleName} onChange={handleChangeRoleName} disabled={!hasEditRoleName} />
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              color="primary"
              className="px-4 rounded-pill"
              onClick={() => setHasEditRoleName(!hasEditRoleName)}
              disabled={isLoading}
            >
              {`${hasEditRoleName ? 'Disable ' : ''}Edit Role Name`}
            </Button>
          </Col>
        </Row>

        <hr />

        <Row className="my-3">
          <Col>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner color="primary">Loading...</Spinner>
              </div>
            ) : (
              permissionList?.length > 0 && (
                <CheckboxTree
                  nodes={permissionList}
                  checked={checked}
                  expanded={expanded}
                  onCheck={(checkedValue) => setChecked(checkedValue)}
                  onExpand={(expandedValue) => setExpanded(expandedValue)}
                  icons={icons}
                  showExpandAll={false}
                  showNodeIcon={false}
                  checkModel="all"
                />
              )
            )}
          </Col>
        </Row>

        {hasMessage && (
          <Alert className="mt-3 mb-0" color={isError ? 'danger' : 'primary'}>
            {hasMessage}
          </Alert>
        )}

        <Row>
          <Col>
            <div className="d-flex justify-content-center mt-3">
              <Button
                color="secondary"
                className="px-4 rounded-pill"
                onClick={handleSubmit}
                loading={isLoading || loadingSubmit}
                disabled={isLoading || loadingSubmit}
              >
                {(isLoading || loadingSubmit) && (
                  <>
                    <Spinner color="light" size="sm">
                      Loading...
                    </Spinner>{' '}
                    &nbsp;
                  </>
                )}
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ACLForm;
