import React, { useState, useEffect } from 'react';
import { DISCOUNT_OPTIONS } from '@utils/Constants';
import { Row, Col, Card, CardBody, CardHeader, Form, Button, ListGroup, ListGroupItem } from 'reactstrap';
import { map, findIndex } from 'lodash';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import DeleteIcon from '@assets/icons/delete.svg';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import BookingReviseFormPackage from '@components/booking/BookingReviseFormPackage';
import BookingReviseFormProduct from '@components/booking/BookingReviseFormProduct';
import BookingReviseFormService from '@components/booking/BookingReviseFormService';
import BookingReviseFormItem from '@components/booking/BookingReviseFormItem';
import BookingReviseFormCustom from '@components/booking/BookingReviseFormCustom';
import ConfirmationModal from '@components/modal/ConfirmationModal';

function BookingDraftForm(props) {
  const {
    data,
    bookingCode,
    isSubmit,
    isFleetMode,
    saveAsDraft,
    disabled,
    onChangeForm,
    onSubmitRevise,
    onResultNotification,
  } = props;

  const api = new API('v2');
  const apiSearch = new API('search');

  const [dataForm, setDataForm] = useState([]);
  const [activeItems, setActiveItems] = useState(0);
  const [markUpProduct, setMarkUpProduct] = useState(0);
  const [markUpService, setMarkUpService] = useState(0);
  const [discProduct, setDiscProduct] = useState(0);
  const [discService, setDiscService] = useState(0);
  const [takeRateProduct, setTakeRateProduct] = useState(0);
  const [takeRateService, setTakeRateService] = useState(0);
  const [defaultOptionsItem, setDefaultOptionsItem] = useState([]);
  const [categoryPackageOptions, setCategoryPackageOptions] = useState([]);
  const [categoryItemOptions, setCategoryItemOptions] = useState([]);
  const [categoryCustomOptions, setCategoryCustomOptions] = useState([]);
  const [disableButtonModal, setDisableButtonModal] = useState(false);
  const [hasConfirmationModal, setHasConfirmationModal] = useState(false);

  const initDefaultDataForm = () => {
    let defaultDataForm = [];
    data?.packages?.items?.forEach((item) => {
      if (item?.package_details !== undefined) {
        defaultDataForm.push(parsingDataPackage(item));
      } else if (item?.products !== undefined) {
        defaultDataForm.push(parsingDataProductAndService(item));
      } else if (item?.custom_products !== undefined) {
        defaultDataForm.push(parsingDataCustom(item));
      }
    });

    setDataForm(defaultDataForm);
  };

  const parsingDataPackage = (item) => {
    const dataPackage = {
      service_type: 'package',
      title: 'Paket',
      deleted: false,
      id: item?.booking_package_id ?? null, //booking_package_id
      category: {
        value: item?.category?.id ?? null,
        label: item?.category?.name ?? null,
      },
      package: {
        value: item?.booking_package_id, //temp //package_id
        label: item?.name ?? '',
      },
      package_options: [],
      components: parsingDataComponents(item?.package_details ?? []),
      error_category: '',
      error_package: '',
      disable_category: true,
      disable_package: true,
    };

    return dataPackage;
  };

  const parsingDataProductAndService = (item) => {
    const product = item?.products?.length && item?.products?.length > 0 ? item?.products[0] : {};
    const isProduct = Helper.readIsProduct(product?.product_type ?? '');
    const isService = Helper.readIsService(product?.product_type ?? '');
    let dataProduct = {
      service_type: isProduct ? 'product' : isService ? 'service' : 'item',
      title: isProduct ? 'Produk' : isService ? 'Jasa' : 'Item',
      deleted: false,
      id: item?.booking_product_id ?? null, //booking_product_id
      booking_product_id: product?.booking_product_id ?? null,
      booking_package_id: product?.booking_package_id ?? null,
      booking_detail_id: product?.booking_details_id ?? null,
      is_fbo: product?.is_fbo ? true : false,
      category: {
        value: product?.product_category?.id ?? null,
        label: product?.product_category?.name ?? null,
      },
      sub_category_id: product?.product_subcategory_id ?? null,
      sub_category: product?.product_subcategory_name ?? '',
      qty: Helper.formatMoneyOnField(`${product?.qty ?? 0}`),
      is_override: product?.is_overide_sell_price ? true : false,
      nett_price: product?.nett_price ?? 0,
      sell_price: Helper.formatMoneyOnField(`${product?.sell_price ?? 0}`, 'IDR '),
      take_rate: product?.take_rate_otoklix,
      workshop_price: product?.workshop_price,
      error_category: '',
      error_qty: '',
      error_sell_price: '',
      disable_category: true,
      disable_qty: product?.is_qty_modifier ? true : false,
      disable_sell_price: product?.is_overide_sell_price == true ? false : true,
      is_approve: product?.is_approve,
      is_draft_change: product?.is_draft_change,
      note: product?.note ?? '',
      loading_hit_default_price: false,
      hit_default_price: false,
      default_price: null,
    };

    if (isProduct || isService) {
      dataProduct.item = {
        value: product?.product_id ? `${product?.product_id}||0` : null, //product_id
        label: product?.name ?? '',
        price: product?.sell_price ?? 0,
        is_fbo: product?.is_fbo ? true : false,
      };
      dataProduct.item_options = [];
      dataProduct.error_item = '';
      dataProduct.disable_item = true;
    }

    return dataProduct;
  };

  const parsingDataCustom = (item) => {
    const discountOptions = [{ value: 0, label: '0%' }];
    const product = item?.custom_products?.length && item?.custom_products?.length > 0 ? item?.custom_products[0] : {};
    const isProduct = Helper.readIsProduct(product?.product_type ?? '');
    const isService = Helper.readIsService(product?.product_type ?? '');
    discountOptions.push(DISCOUNT_OPTIONS);

    const dataCustom = {
      service_type: 'custom',
      title: 'Custom',
      deleted: false,
      id: product?.product_id ?? null,
      booking_product_id: product?.booking_product_id ?? null,
      booking_package_id: product?.booking_package_id ?? null,
      booking_detail_id: product?.booking_details_id ?? null,
      is_fbo: product?.is_fbo ? true : false,
      type: isProduct ? 'product' : isService ? 'service' : '',
      name: product?.name ?? '',
      category: {
        value: product?.product_category?.id ?? null,
        label: product?.product_category?.name ?? null,
      },
      qty: Helper.formatMoneyOnField(`${product?.qty ?? 0}`),
      workshop_price: Helper.formatMoneyOnField(`${product?.workshop_price ?? 0}`, 'IDR '),
      markup:
        product?.markup_percentage >= 0
          ? {
              value: product?.markup_percentage,
              label: `${product?.markup_percentage}%`,
            }
          : null,
      markup_options: Helper.generatePercentage(0, 100),
      customer_price: Helper.formatMoneyOnField(`${product?.sell_price ?? 0}`, 'IDR '),
      discount:
        product?.discount_percentage >= 0
          ? {
              value: product?.discount_percentage,
              label: `${product?.discount_percentage}%`,
            }
          : null,
      is_approve: product?.is_approve,
      is_draft_change: product?.is_draft_change,
      note: product?.note ?? '',
      discount_options: discountOptions,
      error_type: '',
      error_name: '',
      error_category: '',
      error_qty: '',
      error_workshop_price: '',
      error_customer_price: '',
      error_discount: '',
      error_markup: '',
      disable_type: false,
      disable_category: false,
      disable_customer_price: product?.markup_percentage > 0 ? true : false,
    };

    return dataCustom;
  };

  const parsingDataComponents = (items) => {
    let dataComponent = [];
    if (items?.length > 0) {
      items?.forEach((item) => {
        let componentData = {
          value: item?.component_id ?? null, //component_id
          label: item?.component_name ?? 'Component',
          prompt: item?.component_prompt ?? '',
          is_disabled: false,
          error_component: '',
          product_options: [
            {
              value: item?.product_id ?? null, //product_id
              label: item?.name ?? '',
              price: item?.sell_price ?? 0,
              qty: item?.qty ?? 0,
              is_fbo: item?.is_fbo ? true : false,
              nett_price: item?.nett_price ?? 0,
              category: item?.product_category?.id ?? null,
              product_type: item?.product_type ?? '',
              booking_product_id: item?.booking_product_id ?? null,
              booking_package_id: item?.booking_package_id ?? null,
              booking_detail_id: item?.booking_details_id ?? null,
            },
          ],
          product: {
            value: item?.product_id ?? null, //product_id
            label: item?.name ?? '',
            price: item?.sell_price ?? 0,
            qty: item?.qty ?? 0,
            is_fbo: item?.is_fbo ? true : false,
            nett_price: item?.nett_price ?? 0,
            category: item?.product_category?.id ?? null,
            product_type: item?.product_type ?? '',
            booking_product_id: item?.booking_product_id ?? null,
            booking_package_id: item?.booking_package_id ?? null,
            booking_detail_id: item?.booking_details_id ?? null,
          },
        };
        dataComponent.push(componentData);
      });
    }

    return dataComponent;
  };

  async function fetchCustomerConfig() {
    try {
      const response = await api.get(`v2/intools/customers/details/?customer_id=${data?.customer?.id}`);
      setMarkUpProduct(response?.data?.data?.product_markup ?? 0);
      setMarkUpService(response?.data?.data?.service_markup ?? 0);
      setDiscProduct(response?.data?.data?.product_discount ?? 0);
      setDiscService(response?.data?.data?.service_discount ?? 0);
    } catch (error) {
      setMarkUpProduct(0);
      setMarkUpService(0);
      setDiscProduct(0);
      setDiscService(0);
    }
  }

  async function fetchWorkshopTakeRate() {
    try {
      const response = await api.get(`v2/intools/workshops/${data?.workshop?.id}/agreement/`);
      setTakeRateProduct(response?.data?.data?.product_take_rate ?? 0);
      setTakeRateService(response?.data?.data?.service_take_rate ?? 0);
    } catch (error) {
      setTakeRateProduct(0);
      setTakeRateService(0);
    }
  }

  async function fetchCategoryPackage() {
    let listCategory = [];
    try {
      const response = await api.get(
        `v2/intools/categories/inventory/?type=package&workshop_id=${data?.workshop?.id ?? ``}`
      );
      if (response?.data?.data) {
        listCategory = map(response?.data?.data, (item) => {
          return {
            value: item?.category_id,
            label: item?.name,
          };
        });
        setCategoryPackageOptions(listCategory);
      }
    } catch (error) {
      setCategoryPackageOptions(listCategory);
    }
  }

  async function fetchPackage(idCategory, index) {
    let listPackage = [];
    try {
      const response = await api.get(`v2/intools/products/categories/`);
      const optionsPackage = [];
      if (optionsPackage) {
        listPackage = map(optionsPackage, (item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        dataForm[index].package_options = listPackage;

        setDataForm([...dataForm]);
      }
    } catch (error) {
      dataForm[index].package_options = listPackage;
      setDataForm([...dataForm]);
    }
  }

  async function fetchComponent(idPackage, index) {
    let listComponent = [];
    try {
      const response = await api.get(`v2/intools/products/categories/`);
      const optionsPackageDetails = [];
      if (optionsPackageDetails?.components?.length > 0) {
        map(optionsPackageDetails?.components, (component) => {
          let listProduct = [];
          if (component?.products?.length > 0) {
            map(component?.products, (product) => {
              listProduct.push({
                value: product?.id,
                label: product?.name ?? '',
                price: product?.price,
                qty: product?.qty ?? 0,
                product_type: product?.product_type ?? '',
              });
            });
          }
          listComponent.push({
            value: component?.id,
            label: component?.name,
            prompt: component?.prompt,
            is_disabled: false,
            error_component: '',
            product_options: listProduct,
            product: listProduct?.length == 1 ? listProduct[0] : null,
          });
        });
        dataForm[index].components = listComponent;

        setDataForm([...dataForm]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCategoryItem() {
    let listCategory = [];
    try {
      const response = await api.get(
        `v2/intools/categories/inventory/?type=items&workshop_id=${data?.workshop?.id ?? ``}`
      );
      if (response?.data?.data?.length > 0) {
        listCategory = map(response?.data?.data, (item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setCategoryItemOptions(listCategory);
      }
    } catch (error) {
      setCategoryItemOptions(listCategory);
    }
  }

  async function fetchCategoryCustom() {
    let listCategory = [];
    try {
      const response = await api.get(`v2/intools/products/categories/`);
      if (response?.data?.data?.categories?.length > 0) {
        map(response?.data?.data?.categories, (item) => {
          if (!isNaN(+item?.id)) {
            listCategory.push({ value: item?.id, label: item?.name });
          }
        });
        setCategoryCustomOptions(listCategory);
      }
    } catch (error) {
      setCategoryCustomOptions(listCategory);
    }
  }

  async function fetchItem(idCategory, query, isTriggerDefault = false, index) {
    let listItem = [];
    let params = {
      category_id: idCategory,
      customer_id: data?.customer?.id,
      is_ecommerce: data?.booking_source?.source_type == 'ecommerce' ? true : false,
      is_enterprise_customer: true,
      q: query,
    };

    if (data?.user_car?.car_details?.id) {
      params.variant_car_id = data?.user_car?.car_details?.id;
    }
    try {
      const url = data?.workshop?.slug
        ? `v2/intools/products/items/workshop/${data?.workshop?.slug}`
        : `v2/intools/products/items/master/`;
      const response = await apiSearch.get(url, { params });
      if (response?.data?.data?.length > 0) {
        listItem = map(response?.data?.data, (item, idx) => {
          return {
            value: item?.product_id ? `${item?.product_id}||${idx}` : null,
            label: item?.display_name,
            ...item,
          };
        });
      }
      if (isTriggerDefault) {
        setDefaultOptionsItem(listItem);
      } else {
        dataForm[index].item_options = listItem;
        setDataForm([...dataForm]);
      }
    } catch (error) {
      if (isTriggerDefault) {
        setDefaultOptionsItem(listItem);
      } else {
        dataForm[index].item_options = listItem;
        setDataForm([...dataForm]);
      }
    }
  }

  async function fetchUpdatedItem(index) {
    const payload = {
      category_id: dataForm[index]?.category?.value,
      customer_id: data?.customer?.id,
      is_ecommerce: false,
      is_enterprise_customer: true,
      products: [
        {
          is_fbo: dataForm[index]?.is_fbo ?? false,
          product_id: generateProductId(dataForm[index]?.item?.value),
        },
      ],
      type: generateProductType(dataForm[index]?.service_type),
      variant_car_id: data?.user_car?.car_details?.id ?? '',
    };
    await api
      .post(`v2/intools/products/workshop/${data?.workshop?.slug}`, payload)
      .then((response) => {
        let defaultPrice = 0;
        if (response?.data?.data?.length && response?.data?.data?.length !== 0) {
          defaultPrice = response?.data?.data[0]?.price ?? 0;
          dataForm[index].hit_default_price = true;
          dataForm[index].default_price = defaultPrice;
        } else {
          dataForm[index].hit_default_price = false;
          dataForm[index].default_price = null;
        }

        dataForm[index].sell_price = Helper.formatMoneyOnField(Helper.formatMoney(defaultPrice ?? 0), 'IDR ');
        dataForm[index].disable_sell_price = true;
        dataForm[index].loading_hit_default_price = false;
        setDataForm([...dataForm]);
      })
      .catch((e) => {
        dataForm[index].hit_default_price = false;
        dataForm[index].default_price = null;
        dataForm[index].sell_price = Helper.formatMoneyOnField(Helper.formatMoney(0), 'IDR ');
        dataForm[index].disable_sell_price = true;
        dataForm[index].loading_hit_default_price = false;
        setDataForm([...dataForm]);
      });
  }

  const handleChangeCategoryPackage = (value, index) => {
    dataForm[index].category = value;
    dataForm[index].error_category = validateCategoryPackage(value?.value);
    dataForm[index].package = null;
    dataForm[index].disable_package = false;
    dataForm[index].components = [];

    setDataForm([...dataForm]);
    fetchPackage(value?.value, index);
  };

  const handleChangePackagePackage = (value, index) => {
    dataForm[index].package = value;
    dataForm[index].error_package = validatePackage(value?.value);
    dataForm[index].components = [];

    setDataForm([...dataForm]);
    fetchComponent(value?.value, index);
  };

  const handleChangeComponentPackage = (value, index, indexComponent) => {
    dataForm[index].components[indexComponent].product = value;
    dataForm[index].components[indexComponent].error_component = validateComponent(
      value?.value,
      dataForm[index]?.components[indexComponent]?.label ?? ''
    );

    setDataForm([...dataForm]);
  };

  const handleChangeListItem = (value, index) => {
    dataForm[index].item_options = value;
    setDataForm([...dataForm]);
  };

  const handleChangeCategoryItem = (value, index) => {
    dataForm[index].category = value;
    dataForm[index].error_category = false;
    dataForm[index].item = null;
    dataForm[index].error_item = '';
    dataForm[index].qty = Helper.formatMoneyOnField('0');
    dataForm[index].error_qty = '';
    dataForm[index].is_override = false;
    dataForm[index].sell_price = Helper.formatMoneyOnField('0', 'IDR ');
    dataForm[index].error_sell_price = '';
    dataForm[index].disable_sell_price = true;

    setDataForm([...dataForm]);
    fetchItem(value?.value, 'oli', false, index);
  };

  const handleChangeItem = (value, index) => {
    const isProduct = Helper.readIsProduct(value?.type ?? '');
    const isService = Helper.readIsService(value?.type ?? '');
    const serviceType = isProduct ? 'product' : isService ? 'service' : 'item';

    const categoriesIndex = findIndex(categoryPackageOptions, (val) => {
      return val?.value === value?.product_category_id;
    });

    if (categoriesIndex !== -1) {
      dataForm[index].category = categoryPackageOptions[categoriesIndex];
    }
    dataForm[index].sub_category_id = value?.product_subcategory_id;
    dataForm[index].sub_category = value?.product_subcategory_name;
    dataForm[index].service_type = serviceType;
    dataForm[index].title = isProduct ? 'Produk' : isService ? 'Jasa' : 'Item';
    dataForm[index].item = value;
    dataForm[index].error_item = validateItem(value?.value, serviceType);
    dataForm[index].qty = Helper.formatMoneyOnField(`${value?.qty_modifier?.qty ?? 0}`);
    dataForm[index].error_qty = '';
    dataForm[index].disable_qty = value?.qty_modifier?.is_applied ? true : false;
    dataForm[index].is_override = false;
    dataForm[index].sell_price = Helper.formatMoneyOnField(Helper.formatMoney(value?.price ?? 0), 'IDR ');
    dataForm[index].error_sell_price = '';
    dataForm[index].disable_sell_price = dataForm[index].is_override ? false : true;

    setDataForm([...dataForm]);
  };

  const handleChangeQtyItem = (value, index) => {
    const qty = Helper.formatMoneyOnField(value?.target?.value ?? '0');
    dataForm[index].qty = qty;
    dataForm[index].error_qty = validateQtyItem(qty);

    setDataForm([...dataForm]);
  };

  const handleChangeOverrideItem = (index) => {
    const is_override = !dataForm[index].is_override;
    dataForm[index].is_override = is_override;
    dataForm[index].error_sell_price = '';

    if (is_override) {
      dataForm[index].disable_sell_price = false;
      setDataForm([...dataForm]);
    } else {
      if (dataForm[index].is_new_data) {
        dataForm[index].sell_price = Helper.formatMoneyOnField(
          Helper.formatMoney(dataForm[index].item?.price ?? 0),
          'IDR '
        );
        dataForm[index].disable_sell_price = true;
        setDataForm([...dataForm]);
      } else if (!dataForm[index].is_new_data && dataForm[index].hit_default_price) {
        dataForm[index].sell_price = Helper.formatMoneyOnField(
          Helper.formatMoney(dataForm[index].default_price ?? 0),
          'IDR '
        );
        dataForm[index].disable_sell_price = true;
        setDataForm([...dataForm]);
      } else if (!dataForm[index].is_new_data && !dataForm[index].hit_default_price) {
        dataForm[index].loading_hit_default_price = true;
        setDataForm([...dataForm]);
        fetchUpdatedItem(index);
      }
    }
  };

  const handleChangeSellPriceItem = (value, index) => {
    const is_override = dataForm[index].is_override;
    const sell_price = Helper.formatMoneyOnField(value?.target?.value ?? '0', 'IDR ');

    dataForm[index].sell_price = sell_price;
    dataForm[index].error_sell_price = validateSellPriceItem(sell_price, is_override);

    setDataForm([...dataForm]);
  };

  const handleChangeTypeCustom = (value, index) => {
    dataForm[index].type = value;
    dataForm[index].name = '';
    dataForm[index].category = null;
    dataForm[index].error_type = validateTypeCustom(value);
    dataForm[index].error_name = '';
    dataForm[index].error_category = '';

    setDataForm([...dataForm]);
  };

  const handleChangeNameCustom = (value, index) => {
    const valueName = value?.target?.value !== ' ' ? value.target.value.replace(/  +/g, ' ') : dataForm[index].name;
    const labelName =
      dataForm[index].type == 'product' ? 'Product Name' : dataForm[index].type == 'service' ? 'Service Name' : 'Name';
    dataForm[index].name = valueName;
    dataForm[index].error_name = validateNameCustom(valueName, labelName);

    setDataForm([...dataForm]);
  };

  const handleChangeCategoryCustom = (value, index) => {
    dataForm[index].category = value;
    dataForm[index].error_category = validateCategoryCustom(value?.value);

    setDataForm([...dataForm]);
  };

  const handleChangeQtyCustom = (value, index) => {
    const qty = Helper.formatMoneyOnField(value?.target?.value ?? '0');
    dataForm[index].qty = qty;
    dataForm[index].error_qty = validateQtyCustom(qty);

    setDataForm([...dataForm]);
  };

  const handleChangeWorkshopPriceCustom = (value, index) => {
    const workshop_price = Helper.formatMoneyOnField(value?.target?.value ?? '0', 'IDR ');

    dataForm[index].workshop_price = workshop_price;
    dataForm[index].error_workshop_price = validateWorkshopPriceCustom(workshop_price);

    handleCalculateCustomerPrice(index, dataForm[index].markup?.value ?? 0, workshop_price?.formatMoney ?? 0);
    setDataForm([...dataForm]);
  };

  const handleChangeMarkUpCustom = (value, index) => {
    dataForm[index].markup = value;
    dataForm[index].error_markup = validateMarkUpCustom(value?.value);

    handleCalculateCustomerPrice(index, value?.value ?? 0, dataForm[index].workshop_price?.formatMoney ?? 0);
    setDataForm([...dataForm]);
  };

  const handleCalculateCustomerPrice = (index, markup, workshoPrice) => {
    let customerPrice = Helper.formatMoneyOnField('0', 'IDR ');
    if (markup > 0) {
      customerPrice = Helper.formatMoneyOnField(
        Helper.formatMoney((Helper.countDiscount(markup, workshoPrice, 1) ?? 0) + workshoPrice),
        'IDR '
      );
      dataForm[index].disable_customer_price = true;
    } else {
      customerPrice = dataForm[index].workshop_price;
      dataForm[index].disable_customer_price = false;
    }

    dataForm[index].error_customer_price = '';
    dataForm[index].customer_price = customerPrice;
  };

  const handleChangeCustomerPriceCustom = (value, index) => {
    const customer_price = Helper.formatMoneyOnField(value?.target?.value ?? '0', 'IDR ');

    dataForm[index].customer_price = customer_price;
    dataForm[index].error_customer_price = validateCustomerPriceCustom(customer_price);

    setDataForm([...dataForm]);
  };

  const handleChangeDiscountCustom = (value, index) => {
    dataForm[index].discount = value;
    dataForm[index].error_discount = validateDiscountCustom(value?.value);

    setDataForm([...dataForm]);
  };

  const validateCategoryPackage = (value) => {
    const errorMsg = !value ? 'Pilih Kategori terlebih dahulu' : '';

    return errorMsg;
  };

  const validatePackage = (value) => {
    const errorMsg = !value ? 'Pilih Paket terlebih dahulu' : '';

    return errorMsg;
  };

  const validateComponent = (value, label) => {
    const errorMsg = !value ? 'Pilih ' + label + ' terlebih dahulu' : '';

    return errorMsg;
  };

  const validateItem = (value, serviceType) => {
    const errorMsg = !value ? `Pilih item terlebih dahulu` : serviceType == 'item' ? 'Tipe item tidak diketahui' : '';

    return errorMsg;
  };

  const validateQtyItem = (value) => {
    const errorMsg =
      value?.formatMoney == 0
        ? 'Qty Item harus diisi'
        : value?.formatMoney < 1
        ? 'Minimal Qty Item 1'
        : value?.formatMoney > 999
        ? 'Maksimal Qty Item 999'
        : '';

    return errorMsg;
  };

  const validateSellPriceItem = (value, is_override) => {
    const errorMsg = value?.formatMoney > 999999999 && is_override ? 'Maksimal Sell Price Item IDR 999.999.999' : '';

    return errorMsg;
  };

  const validateTypeCustom = (value) => {
    const errorMsg = value !== 'product' && value !== 'service' ? 'Pilih Tipe Flagging terlebih dahulu' : '';

    return errorMsg;
  };

  const validateNameCustom = (value, label) => {
    const errorMsg =
      value.length > 100
        ? 'Format ' + label + ' maksimal 100 karakter'
        : value.length == 0
        ? label + ' harus diisi'
        : '';

    return errorMsg;
  };

  const validateQtyCustom = (value) => {
    const errorMsg =
      value?.formatMoney == 0
        ? 'Jumlah harus diisi'
        : value?.formatMoney < 1
        ? 'Minimal Jumlah 1'
        : value?.formatMoney > 999
        ? 'Maksimal Jumlah 999'
        : '';

    return errorMsg;
  };

  const validateCategoryCustom = (value) => {
    const errorMsg = !value ? 'Pilih Kategori terlebih dahulu' : '';

    return errorMsg;
  };

  const validateWorkshopPriceCustom = (value) => {
    const errorMsg = value?.formatMoney > 999999999 ? 'Maksimal Harga Jual Bengkel IDR 999.999.999' : '';

    return errorMsg;
  };

  const validateMarkUpCustom = (value) => {
    const errorMsg = value >= 0 ? '' : 'Pilih Mark Up terlebih dahulu';

    return errorMsg;
  };

  const validateCustomerPriceCustom = (value) => {
    const errorMsg = value?.formatMoney > 999999999 ? 'Maksimal Harga Jual Customer IDR 999.999.999' : '';

    return errorMsg;
  };

  const validateDiscountCustom = (value) => {
    const errorMsg = value >= 0 ? '' : 'Pilih Diskon terlebih dahulu';

    return errorMsg;
  };

  const calculateActiveItems = () => {
    const itemActive = dataForm.filter((obj) => !obj.deleted);

    setActiveItems(itemActive?.length ?? 0);
  };

  const handleDeleteItem = (index) => {
    if (!disabled) {
      if (activeItems == 1) {
        handleResultNotification(true, 'fail', 'Minimal 1 item Paket/Produk/Jasa/Custom');
      } else {
        if (dataForm[index]?.is_new_data) {
          dataForm?.splice(index, 1);
        } else {
          dataForm[index].deleted = true;
        }
        setDataForm([...dataForm]);
      }
    }
  };

  const handleAddItem = (type) => {
    if (!disabled) {
      if (activeItems > 49) {
        handleResultNotification(true, 'fail', 'Item sudah melebihi batas, maksimal 50 item');
      } else {
        const item =
          type == 'package'
            ? handleDataPackage()
            : type == 'item'
            ? handleDataItem()
            : type == 'custom'
            ? handleDataCustom()
            : {};
        if (item?.service_type !== undefined) {
          setDataForm([...dataForm, item]);
        }
      }
    }
  };

  const handleDataPackage = () => {
    const itemPackage = {
      service_type: 'package',
      title: 'Paket',
      deleted: false,
      id: null,
      category: null,
      package: null,
      package_options: [],
      components: [],
      error_category: '',
      error_package: '',
      disable_category: false,
      disable_package: true,
      is_new_data: true,
    };

    return itemPackage;
  };

  const handleDataItem = () => {
    const itemProduct = {
      service_type: 'item',
      title: 'Item',
      deleted: false,
      id: null,
      category: null,
      sub_category_id: null,
      sub_category: '',
      item: null,
      item_options: defaultOptionsItem,
      qty: Helper.formatMoneyOnField('0'),
      is_override: false,
      sell_price: Helper.formatMoneyOnField('0'),
      error_category: '',
      error_item: '',
      error_qty: '',
      error_sell_price: '',
      disable_qty: false,
      disable_category: false,
      disable_item: false,
      disable_sell_price: true,
      is_new_data: true,
    };

    return itemProduct;
  };

  const handleDataCustom = () => {
    const discountOptions = [{ value: 0, label: '0%' }];
    discountOptions.push(DISCOUNT_OPTIONS);

    const itemCustom = {
      service_type: 'custom',
      title: 'Custom',
      deleted: false,
      id: null,
      type: '',
      name: '',
      category: null,
      qty: Helper.formatMoneyOnField('0'),
      workshop_price: Helper.formatMoneyOnField('0', 'IDR '),
      markup: null,
      markup_options: Helper.generatePercentage(0, 100),
      customer_price: Helper.formatMoneyOnField('0', 'IDR '),
      discount: null,
      discount_options: discountOptions,
      error_type: '',
      error_name: '',
      error_category: '',
      error_qty: '',
      error_workshop_price: '',
      error_customer_price: '',
      error_discount: '',
      error_markup: '',
      disable_type: false,
      disable_category: false,
      is_new_data: true,
    };

    return itemCustom;
  };

  const validateFormPackage = (item, index, passValidate) => {
    let passValidatePackage = passValidate;

    dataForm[index].error_category = validateCategoryPackage(item?.category?.value);
    dataForm[index].error_package = validatePackage(item?.package?.value);

    passValidatePackage =
      validateCategoryPackage(item?.category?.value) !== '' || validatePackage(item?.package?.value) !== ''
        ? false
        : passValidatePackage;

    item.components.forEach((itemComponent, indexComponent) => {
      dataForm[index].components[indexComponent].error_component = validateComponent(
        itemComponent?.product?.value,
        itemComponent?.label
      );

      passValidatePackage =
        validateComponent(itemComponent?.product?.value, itemComponent?.label) !== '' ? false : passValidatePackage;
    });

    return passValidatePackage;
  };

  const validateFormItem = (item, index, passValidate) => {
    let passValidateItem = passValidate;

    const errorItem = validateItem(item?.item?.value, item?.service_type);
    const errorQtyItem = validateQtyItem(item?.qty);
    const errorSellPriceItem = validateSellPriceItem(item?.sell_price, item.is_override);

    dataForm[index].error_item = errorItem;
    dataForm[index].error_qty = errorQtyItem;
    dataForm[index].error_sell_price = errorSellPriceItem;

    passValidateItem = errorItem || errorQtyItem || errorSellPriceItem ? false : passValidateItem;

    return passValidateItem;
  };

  const validateFormCustom = (item, index, passValidate) => {
    let passValidateCustom = passValidate;
    const labelName = item?.type == 'product' ? 'Product Name' : item?.type == 'service' ? 'Service Name' : 'Name';

    dataForm[index].error_type = validateTypeCustom(item?.type);
    dataForm[index].error_name = validateNameCustom(item?.name, labelName);
    dataForm[index].error_category = validateCategoryCustom(item?.category?.value);
    dataForm[index].error_qty = validateQtyCustom(item?.qty);
    dataForm[index].error_workshop_price = validateWorkshopPriceCustom(item?.workshop_price);
    dataForm[index].error_customer_price = validateCustomerPriceCustom(item?.customer_price);
    dataForm[index].error_discount = validateDiscountCustom(item?.discount?.value);
    dataForm[index].error_markup = validateMarkUpCustom(item?.markup?.value);

    passValidateCustom =
      validateTypeCustom(item?.type) !== '' ||
      validateNameCustom(item?.name, labelName) !== '' ||
      validateCategoryCustom(item?.category?.value) !== '' ||
      validateQtyCustom(item?.qty) !== '' ||
      validateWorkshopPriceCustom(item?.workshop_price) !== '' ||
      validateCustomerPriceCustom(item?.customer_price) !== '' ||
      validateDiscountCustom(item?.discount?.value) !== '' ||
      validateMarkUpCustom(item?.markup?.value) !== ''
        ? false
        : passValidateCustom;

    return passValidateCustom;
  };

  const validateProcess = () => {
    let passValidate = true;

    dataForm.forEach((item, index) => {
      if (item?.service_type == 'package' && !item?.deleted) {
        passValidate = validateFormPackage(item, index, passValidate);
      } else if (
        (item?.service_type == 'product' || item?.service_type == 'service' || item?.service_type == 'item') &&
        !item?.deleted
      ) {
        passValidate = validateFormItem(item, index, passValidate);
      } else if (item?.service_type == 'custom' && !item?.deleted) {
        passValidate = validateFormCustom(item, index, passValidate);
      }
    });

    return passValidate;
  };

  const validationForm = () => {
    if (isSubmit) {
      if (!validateProcess()) {
        handleResultNotification(true, 'fail', 'Mohon lengkapi form');
        onSubmitRevise(false);
      } else {
        setDisableButtonModal(false);
        setHasConfirmationModal(true);
      }
    }
  };

  const handleConfirmationModal = () => {
    setDisableButtonModal(true);
    setHasConfirmationModal(false);
    const payload = handleGetPayload();
    onSubmitRevise(true, payload);
  };

  const toggleConfirmationModal = () => {
    setHasConfirmationModal(!hasConfirmationModal);
    if (onSubmitRevise) onSubmitRevise(false);
  };

  const generateProductType = (productType) => {
    const resultProductType =
      productType == 'part' || productType == 'product' || productType == 'Produk'
        ? 'part'
        : productType == 'work' || productType == 'service' || productType == 'Jasa'
        ? 'work'
        : '';

    return resultProductType;
  };

  const generateTakeRate = (productType) => {
    const typeProduct = generateProductType(productType);

    return typeProduct == 'part' ? takeRateProduct : typeProduct == 'work' ? takeRateService : 0;
  };

  const generateMarkUp = (productType) => {
    const typeProduct = generateProductType(productType);

    return typeProduct == 'part' ? markUpProduct : typeProduct == 'work' ? markUpService : 0;
  };

  const generateDiscount = (productType) => {
    const typeProduct = generateProductType(productType);

    return typeProduct == 'part' ? discProduct : typeProduct == 'work' ? discService : 0;
  };

  const generateProductId = (productValue) => {
    const value = productValue ?? '';
    const splitArray = value.split('||');

    return !isNaN(splitArray[0]) ? Number(splitArray[0]) : null;
  };

  const handleGetPayload = () => {
    let payload = {
      items: [],
    };

    dataForm.forEach((item) => {
      if (item?.service_type == 'package') {
        item?.components.forEach((itemComponent) => {
          payload.items.push({
            booking_product_id: itemComponent?.product?.booking_product_id ?? null,
            booking_package_id: itemComponent?.product?.booking_package_id ?? null,
            booking_detail_id: itemComponent?.product?.booking_detail_id ?? null,
            product_id: itemComponent?.product?.value ?? null,
            name: itemComponent?.product?.label ?? null,
            product_type: generateProductType(itemComponent?.product?.product_type),
            product_category: itemComponent?.product?.category ?? null,
            is_fbo: itemComponent?.product?.is_fbo ? true : false,
            is_deleted: item?.deleted ? true : false,
            is_overide_sell_price: false,
            qty: itemComponent?.product?.qty ?? 0,
            nett_price: itemComponent?.product?.nett_price ?? 0,
            sell_price: itemComponent?.product?.price ?? 0,
            take_rate_percentage: generateTakeRate(itemComponent?.product?.product_type),
            markup_percentage: generateMarkUp(itemComponent?.product?.product_type),
            discount_percentage: generateDiscount(itemComponent?.product?.product_type),
          });
        });
      } else if (item?.service_type == 'product' || item?.service_type == 'service') {
        let objItems = {
          booking_product_id: item?.booking_product_id ?? null,
          booking_package_id: item?.booking_package_id ?? null,
          booking_detail_id: item?.booking_detail_id ?? null,
          product_id: item?.item?.value ? generateProductId(item?.item?.value) : null,
          name: item?.item?.label ?? '',
          product_type: generateProductType(item?.service_type),
          product_category: item?.category?.value ?? null,
          product_subcategory_id: item?.sub_category_id ?? null,
          is_fbo: item?.item?.is_fbo ? true : false,
          is_deleted: item?.deleted ? true : false,
          is_overide_sell_price: item?.is_override ? true : false,
          qty: item?.qty?.formatMoney ?? 0,
          nett_price: item?.nett_price ?? 0,
          sell_price: item?.sell_price?.formatMoney ?? 0,
          take_rate_percentage: item?.take_rate ?? null,
          markup_percentage: generateMarkUp(item?.service_type),
          discount_percentage: generateDiscount(item?.service_type),
        };
        if (item?.workshop_price !== undefined) {
          objItems.workshop_price = item?.workshop_price ?? 0;
        }
        payload.items.push(objItems);
      } else if (item?.service_type == 'custom') {
        payload.items.push({
          booking_product_id: item?.booking_product_id ?? null,
          booking_package_id: item?.booking_package_id ?? null,
          booking_detail_id: item?.booking_detail_id ?? null,
          product_id: item?.id ?? null,
          name: item?.name ?? '',
          product_type: generateProductType(item?.type),
          product_category: item?.category?.value ?? null,
          is_fbo: item?.is_fbo ? true : false,
          is_deleted: item?.deleted ? true : false,
          is_overide_sell_price: false,
          qty: item?.qty?.formatMoney ?? 0,
          nett_price: Helper.readNettPriceCustom(generateTakeRate(item?.type), item?.workshop_price?.formatMoney),
          workshop_price: item?.workshop_price?.formatMoney ?? 0,
          sell_price: item?.customer_price?.formatMoney ?? 0,
          take_rate_percentage: generateTakeRate(item?.type),
          markup_percentage: item?.markup?.value ?? 0,
          discount_percentage: item?.discount?.value ?? 0,
        });
      }
    });

    return payload;
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  useEffect(() => {
    calculateActiveItems();
    onChangeForm(dataForm);
  }, [dataForm]);

  useEffect(() => {
    validationForm();
  }, [isSubmit]);

  useEffect(() => {
    initDefaultDataForm();
    if (data?.id) {
      fetchWorkshopTakeRate();
      fetchCustomerConfig();
      fetchCategoryPackage();
      fetchCategoryItem();
      fetchCategoryCustom();
      fetchItem('', 'oli', true);
    }
  }, [data?.id]);

  return (
    <React.Fragment>
      <ConfirmationModal
        modal={hasConfirmationModal}
        isDisabled={disableButtonModal}
        toggle={toggleConfirmationModal}
        header={`Yakin ingin menyimpan ${saveAsDraft ? `sebagai draft ` : ``} perubahan booking "${bookingCode}" ?`}
        onConfirm={() => {
          handleConfirmationModal();
        }}
        toggleClose={toggleConfirmationModal}
      />
      <Card>
        <CardHeader className="text-white mt-0">Pengisian Data</CardHeader>
        <CardBody>
          <Row className="mt-3">
            <Col>
              <Form>
                {map(dataForm, (item, index) => {
                  if (!item?.deleted) {
                    return (
                      <ListGroup key={index}>
                        <ListGroupItem
                          key={index}
                          className={
                            isFleetMode && item?.is_approve !== undefined && item?.is_approve !== null
                              ? item?.is_approve == false
                                ? 'container-item-revise border-revise-rejected'
                                : 'container-item-revise border-revise-approved'
                              : 'container-item-revise'
                          }
                        >
                          <div className="title-item">{item?.title}</div>
                          <div className="close-wrapper" onClick={() => handleDeleteItem(index)}>
                            <img className="icon" src={DeleteIcon} />
                          </div>
                          {item?.service_type == 'package' && (
                            <BookingReviseFormPackage
                              data={item}
                              index={index}
                              disabled={false}
                              categoryOptions={categoryPackageOptions}
                              onChangeCategory={handleChangeCategoryPackage}
                              onChangePackage={handleChangePackagePackage}
                              onChangeComponent={handleChangeComponentPackage}
                            ></BookingReviseFormPackage>
                          )}
                          {(item?.service_type == 'service' ||
                            item?.service_type == 'product' ||
                            item?.service_type == 'item') && (
                            <BookingReviseFormItem
                              headerData={data}
                              data={item}
                              index={index}
                              disabled={disabled}
                              categoryOptions={categoryItemOptions}
                              isFleetMode={isFleetMode}
                              onChangeCategory={handleChangeCategoryItem}
                              onChangeItem={handleChangeItem}
                              onChangeQty={handleChangeQtyItem}
                              onChangeOverride={handleChangeOverrideItem}
                              onChangeSellPrice={handleChangeSellPriceItem}
                              onChangeListItem={handleChangeListItem}
                            ></BookingReviseFormItem>
                          )}
                          {item?.service_type == 'custom' && (
                            <BookingReviseFormCustom
                              data={item}
                              index={index}
                              takeRateProduct={takeRateProduct}
                              takeRateService={takeRateService}
                              disabled={false}
                              categoryOptions={categoryCustomOptions}
                              isFleetMode={isFleetMode}
                              onChangeType={handleChangeTypeCustom}
                              onChangeName={handleChangeNameCustom}
                              onChangeCategory={handleChangeCategoryCustom}
                              onChangeQty={handleChangeQtyCustom}
                              onChangeWorkshopPrice={handleChangeWorkshopPriceCustom}
                              onChangeMarkUp={handleChangeMarkUpCustom}
                              onChangeCustomerPrice={handleChangeCustomerPriceCustom}
                              onChangeDiscount={handleChangeDiscountCustom}
                            ></BookingReviseFormCustom>
                          )}
                        </ListGroupItem>
                      </ListGroup>
                    );
                  }
                })}

                <Row style={{ marginTop: '10px' }}>
                  <Col className="text-right">
                    {/* <Button
                      color="primary"
                      size="lg"
                      className="btn-add-item-package"
                      disabled={disabled}
                      style={{ marginRight: '10px' }}
                      onClick={() => handleAddItem('package')}
                    >
                      <img src={PlusFill} className="mr-2" />
                      Paket
                    </Button> */}
                    <Button
                      color="primary"
                      size="lg"
                      className="btn-add-item-package mr-2"
                      disabled={disabled}
                      onClick={() => handleAddItem('item')}
                    >
                      <img src={PlusFill} className="mr-2" />
                      Item
                    </Button>
                    <Button
                      color="primary"
                      size="lg"
                      className="btn-add-item-package"
                      disabled={disabled}
                      onClick={() => handleAddItem('custom')}
                    >
                      <img src={PlusFill} className="mr-2" />
                      Custom
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default BookingDraftForm;
