import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import map from 'lodash/map';
import moment from 'moment';
import API from '@utils/API';
import DataTable from 'react-data-table-component';
import Loading from '@components/loading/Loading';

const BookingDetailFleetFileUser = (props) => {
  const api = new API('v2');
  const { detailData, onResultNotification } = props;
  const [loading, setLoading] = useState(false);
  const [dataFile, setDataFile] = useState([]);

  async function fetchFileUser() {
    setLoading(true);

    try {
      const response = await api.get(`v2/intools/bookings/${detailData?.booking_code}/documents/?option=user`);
      if (response?.data?.data) {
        const fileData = map(response?.data?.data, (item) => {
          const nameFile = item?.filename
            ? item.filename.length > 25
              ? item.filename.substr(0, 11) + '...' + item.filename.substr(item.filename.length - 11)
              : item.filename
            : '';
          return {
            id: item?.id,
            time: item?.created_at,
            type_file: item?.document_type,
            label_file: item?.label ?? '',
            document: {
              name: nameFile,
              url: item?.url ?? null,
            },
          };
        });
        setDataFile(fileData);
      } else {
        setDataFile([]);
      }
      setLoading(false);
    } catch (error) {
      setDataFile([]);
      setLoading(false);
      handleResultNotification(true, 'fail', 'Gagal mendapatkan lampiran file user');
    }
  }

  const handleResultNotification = (status = false, type = '', message = '', without_reload = false) => {
    const notification = {
      status: status,
      type: type,
      message: message,
      without_reload: without_reload,
    };

    onResultNotification(notification);
  };

  const downloadFile = (url_file) => {
    if (url_file) {
      const link = document.createElement('a');
      link.setAttribute('href', url_file);
      link.setAttribute('download', '');
      link.click();
    }
  };

  const columns = useMemo(
    () => [
      {
        name: 'Waktu',
        selector: (row) => row?.time,
        cell: (row) => (moment(row?.time).isValid() ? moment(row?.time).format('MMM DD YYYY , HH:mm:ss') : '-'),
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'File Name',
        selector: (row) => row?.label_file,
        cell: (row) => <div className="text-bold text-purple-2">{row?.label_file ?? '-'}</div>,
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'File',
        selector: (row) => row?.document?.name,
        cell: (row) => (
          <div className="text-bold text-purple-2" style={{ paddingTop: '10px' }}>
            <span onClick={() => downloadFile(row?.document?.url)}>{row?.document?.name ?? ''}</span>
          </div>
        ),
        wrap: true,
        minWidth: '250px',
      },
    ],
    []
  );

  useEffect(() => {
    fetchFileUser();
  }, [detailData]);

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Lampiran File User</CardHeader>
      <CardBody className="pb-0">
        <DataTable
          persistTableHead
          highlightOnHover
          data={dataFile}
          noHeader={true}
          fixedHeader
          progressPending={loading}
          progressComponent={<Loading />}
          fixedHeaderScrollHeight="340px"
          columns={columns}
          className="table table-bordered dt-responsive"
        />
      </CardBody>
    </Card>
  );
};

export default BookingDetailFleetFileUser;
