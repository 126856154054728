import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';

const ConfirmationModal = (props) => {
  const {
    modal,
    modalWidth,
    buttonClassName,
    toggle,
    header,
    subHeader,
    toggleClose,
    onConfirm,
    isDisabled,
    buttonClose = 'Tidak',
    buttonConfirm = 'Ya',
  } = props;

  return (
    <Modal
      className={
        modalWidth
          ? `modal-dialog-centered modal-confirmation w-${modalWidth}`
          : `modal-dialog-centered modal-confirmation w-374`
      }
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalBody className="body">
        <div className="header">{header}</div>
        {subHeader && <div className="sub-header">{subHeader}</div>}
        <div className="action">
          <Button
            className={buttonClassName ? `button-action-modal ${buttonClassName}` : 'button-action-modal primary'}
            onClick={toggleClose}
            disabled={isDisabled}
          >
            {buttonClose}
          </Button>
          <Button
            className={buttonClassName ? `button-action-modal ${buttonClassName}` : 'button-action-modal primary'}
            onClick={onConfirm}
            disabled={isDisabled}
          >
            {buttonConfirm}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
