import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, CardBody, Col, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { TAX_STATUS_OPTIONS } from '@utils/Constants';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import TrashIcon from '@assets/icons/trash.svg';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import APIS3 from '@utils/APIS3';
import userPermission from '@utils/userPermission';

const Tax = (props) => {
  const { id, handleResultNotification } = props;
  const api = new API('v2');
  const apiS3 = new APIS3();
  const { generatePermission } = userPermission();

  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({});
  const [forms, setForms] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingUploadImage, setLoadingUploadImage] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'all'));
  };

  const allowShow = allowPermission('taxshow') || allowPermission('taxall');
  const allowEdit = allowPermission('taxedit') || allowPermission('taxall');

  const fetchNPWPDetail = async () => {
    setLoading(true);
    await api
      .get(`v2/intools/workshops/${id}/general-info/tax/`)
      .then((res) => {
        const dt = res?.data?.data;
        setData(dt);
        setDefaultForms(dt);
        setLoading(false);
      })
      .catch(() => {
        setDefaultForms();
        setLoading(false);
      });
  };

  const setDefaultForms = (dt = {}) => {
    const isPKP = dt?.is_pkp ? TAX_STATUS_OPTIONS[0] : TAX_STATUS_OPTIONS[1];
    let dataForm = {
      name: dt?.ws_npwp_name ?? '',
      image: {
        url: dt?.npwp_image ?? '',
        name: dt?.npwp_image ? Helper.removeExtension(Helper.renameNPWPFileName(dt?.npwp_image)) : '',
      },
      status: isPKP ?? null,
      number: dt?.ws_npwp_number !== '0' ? Helper.formatNpwp(dt?.ws_npwp_number) : null,
      address: dt?.ws_npwp_address ?? '',
      error_name: '',
      error_image: '',
      error_status: '',
      error_number: '',
      error_address: '',
    };

    setForms(dataForm);
  };

  const toggleEdit = (status) => setIsEditing(status);

  const handleChangeStatus = (value) => {
    forms.status = value;
    setForms({ ...forms });
  };

  const handleChangeName = (e) => {
    const { value } = e.target;
    forms.name = value;
    validateName(value);
    setForms({ ...forms });
  };

  const validateName = (value) => {
    let status = true;
    if (value?.length > 40) {
      forms.error_name = 'Maksimal karakter adalah 40';
      status = false;
    } else {
      forms.error_name = '';
      status = true;
    }

    return status;
  };

  const handleChangeNPWP = (e) => {
    const { value } = e.target;
    if (value?.length < 21) forms.number = Helper.formatNpwp(value);
    validateNPWP(value);
    setForms({ ...forms });
  };

  const validateNPWP = (value) => {
    let status = true;
    if (value?.length > 0 && value?.length < 20) {
      forms.error_number = 'Nomor NPWP minimal 15 digit';
      status = false;
    } else if (value?.length > 0 && value?.length > 21) {
      forms.error_number = 'Nomor NPWP maksimal 16 digit';
      status = false;
    } else {
      forms.error_number = '';
      status = true;
    }

    return status;
  };

  const handleChangeAddress = (e) => {
    const { value } = e.target;
    forms.address = value;
    validateAddress(value);
    setForms({ ...forms });
  };

  const validateAddress = (value) => {
    let status = false;
    if (value?.length > 100) {
      forms.error_address = 'Maksimal karakter adalah 100';
      status = false;
    } else {
      forms.error_address = '';
      status = true;
    }

    return status;
  };

  const handleChangeImage = (file) => {
    validateFile(file).then((status) => {
      if (status) {
        const url = URL.createObjectURL(file);
        handlePreSignedUploadFile(file, url);
      }
    });
  };

  const handlePreSignedUploadFile = (file, url) => {
    setLoadingUploadImage(true);
    const paramsPresignedUpload = {
      namespace: 'npwp',
      identifier: id,
      file_name: file?.name,
    };

    api.post('v2/intools/presigned-upload/', paramsPresignedUpload).then((res) => {
      const urlUpload = res?.data?.data?.presigned_url ?? '';
      const keyFile = res?.data?.data?.key;
      if (urlUpload !== '') {
        uploadDocument(file, url, urlUpload, keyFile);
      } else {
        handleResultNotification({
          status: true,
          type: 'failed',
          message: `Gagal, fail get url upload file`,
        });
        console.log('failed get url of presigned document');
        setLoadingUploadImage(false);
      }
    });
  };

  const { getRootProps } = useDropzone({
    multiple: false,
    disabled: !isEditing,
    onDrop: (files) => {
      handleChangeImage(files[0]);
      setIsDragOver(false);
    },
    onDragOver: () => {
      setForms({ ...forms, error_image: '' });
      setIsDragOver(true);
    },
    onDragLeave: () => {
      setIsDragOver(false);
    },
    onFileDialogOpen: () => {
      setForms({ ...forms, error_image: '' });
    },
  });

  const handleDeleteImage = () => {
    if (isEditing) {
      setForms({
        ...forms,
        image: {
          name: '',
          url: '',
        },
      });
    }
  };

  const handleDownloadImage = () => {
    const link = document.createElement('a');
    link.setAttribute('href', forms?.image?.url);
    link.setAttribute('download', forms?.image?.path ?? forms?.image?.name ?? '');
    link.click();
  };

  const validateFile = async (file) => {
    const availableFormat = ['jpg', 'png', 'pdf', 'jpeg'];
    let status = true;
    if (file) {
      const fileSizeError = file?.size > 5000000;
      const fileType = file?.type?.split('/');
      const fileExtentionError = !availableFormat.includes(fileType[1]);
      let msg = '';

      if (fileSizeError) {
        msg = 'Ukuran maksimal file adalah 5MB';
        forms.error_image = msg;
        status = false;
      } else if (fileExtentionError) {
        msg = 'Ekstensi file tidak sesuai. ekstensi file yang diperbolehkan adalah jpg/jpeg/png/pdf';
        forms.error_image = msg;
        status = false;
      } else {
        forms.error_image = '';
        status = true;
      }

      setForms({ ...forms });
    }

    return status;
  };

  const validate = () => {
    let status = true;
    if (!validateName(forms?.name)) status = false;
    if (!validateNPWP(forms?.number?.string)) status = false;
    if (!validateAddress(forms?.address)) status = false;
    if (forms?.error_image) status = false;

    return status;
  };

  const uploadDocument = async (file, fileUrl, urlUpload, keyFile) => {
    await apiS3
      .put(urlUpload, file)
      .then(() => {
        setForms({
          ...forms,
          image: { name: Helper.removeExtension(file?.name), ...file, url: fileUrl },
          key: keyFile,
        });
        setLoadingUploadImage(false);
      })
      .catch((e) => {
        console.log('failed to upload document');
        handleResultNotification({
          status: true,
          type: 'failed',
          message: 'Gagal, mengunggah dokumen. coba sesaat lagi',
        });
        setLoadingUploadImage(false);
      });
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    if (!allowEdit) {
      handleResultNotification({
        status: true,
        type: 'fail',
        message: 'Tidak ada akses untuk edit',
      });
      setLoadingSubmit(false);

      return;
    }

    if (!validate()) {
      handleResultNotification({
        status: true,
        type: 'fail',
        message: 'Gagal, cek kembali field pada form',
      });
      setLoadingSubmit(false);

      return;
    }

    const isPKP = forms?.status?.value === 'pkp' ? true : false;
    const image = forms?.key
      ? forms?.key
      : forms?.image?.url && forms?.image?.url !== ''
      ? Helper.removeDomain(forms?.image?.url)
      : '';
    const payload = {
      is_pkp: isPKP,
      npwp_image: image,
      ws_npwp_address: forms?.address ?? '',
      ws_npwp_name: forms?.name ?? '',
      ws_npwp_number: forms?.number?.int ?? null,
    };

    await api
      .put(`v2/intools/workshops/${id}/general-info/tax/`, payload)
      .then((res) => {
        const dt = res?.data?.data;
        setData(dt);
        setDefaultForms(dt);
        setLoadingSubmit(false);
        toggleEdit(!isEditing);
        handleResultNotification({
          status: true,
          type: 'success',
          message: `Sukses update NPWP`,
        });
      })
      .catch((err) => {
        const msg = err?.response?.message ?? '';
        setLoadingSubmit(false);
        handleResultNotification({
          status: true,
          type: 'failed',
          message: `Gagal update NPWP${msg ? `: ${msg}` : ''}`,
        });
      });
  };

  const handleCancel = () => {
    toggleEdit(!isEditing);
    setDefaultForms(data);
  };

  useEffect(() => {
    if (allowShow) fetchNPWPDetail();
  }, []);

  const buttonDisabled = loadingUploadImage || loading || loadingSubmit;

  if (!allowShow) return null;

  if (loading)
    return (
      <div className="mt-3">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );

  return (
    <div className="mt-3">
      <Card className="card-custom">
        <CardBody className="p-0">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Row className="mb-4 font-weight-bold font-18">
                <span>Perpajakan</span>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <FormGroup row>
                    <Label className="text-left" lg={4}>
                      Status Pajak
                    </Label>
                    <Col lg={8} className={isEditing ? '' : 'text-left text-bold'}>
                      {isEditing ? (
                        <SelectField
                          value={forms?.status}
                          options={TAX_STATUS_OPTIONS}
                          closeMenuOnSelect={true}
                          placeholder="Pilih Status"
                          name="status"
                          onChange={handleChangeStatus}
                        />
                      ) : (
                        <div className="npwp_form-values">
                          : <span className="ml-1">{forms?.status?.label ?? '-'}</span>
                        </div>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label className="text-left" lg={4}>
                      Nomor NPWP
                    </Label>
                    <Col lg={8} className={isEditing ? '' : 'text-left text-bold'}>
                      {isEditing ? (
                        <InputField
                          value={forms?.number?.string}
                          name="number"
                          placeholder="Ex: 11.111.111.1-111.111"
                          onChange={handleChangeNPWP}
                          invalid={!!forms?.error_number}
                          errorMessage={forms?.error_number}
                        />
                      ) : (
                        <div className="npwp_form-values">
                          : <span className="ml-1">{forms?.number?.string ?? '-'}</span>
                        </div>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label className="text-left" lg={4}>
                      Nama
                    </Label>
                    <Col lg={8} className={isEditing ? '' : 'text-left text-bold'}>
                      {isEditing ? (
                        <InputField
                          value={forms.name}
                          name="name"
                          placeholder="Ex: Oness"
                          onChange={handleChangeName}
                          invalid={!!forms?.error_name}
                          errorMessage={forms?.error_name}
                        />
                      ) : (
                        <div className="npwp_form-values">
                          : <span className="ml-1">{Helper.emptyStringRenderer(forms?.name)}</span>
                        </div>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label className="text-left" lg={4}>
                      Alamat NPWP
                    </Label>
                    <Col
                      lg={8}
                      className={isEditing ? '' : 'text-left text-bold d-flex'}
                      style={!isEditing ? { overflowWrap: 'anywhere' } : {}}
                    >
                      {isEditing ? (
                        <InputField
                          inputType="textarea"
                          value={forms.address}
                          onChange={handleChangeAddress}
                          placeholder="Ex: Jl kemana aja no 3"
                          invalid={!!forms?.error_address}
                          errorMessage={forms?.error_address}
                        />
                      ) : (
                        <div className="npwp_form-values d-flex" style={!isEditing ? { overflowWrap: 'anywhere' } : {}}>
                          : <span className="ml-1">{Helper.emptyStringRenderer(forms?.address)}</span>
                        </div>
                      )}
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <FormGroup row>
                    <Label className="text-left" lg={4}>
                      Foto NPWP
                    </Label>
                    <Col lg={8}>
                      {forms?.image?.name ? (
                        <div className="document-box-workshop">
                          <span className="file" onClick={handleDownloadImage}>
                            {forms?.image?.name}
                          </span>
                          {isEditing ? (
                            <img className="icon-trash-workshop" src={TrashIcon} onClick={handleDeleteImage} />
                          ) : null}
                        </div>
                      ) : (
                        <div style={!isEditing ? { pointerEvents: 'none' } : {}}>
                          {isDragOver && (
                            <div {...getRootProps({ className: 'document-box-workshop drag-over cursor-pointer' })}>
                              <span className="choose-file">Release File</span>
                            </div>
                          )}
                          {!isDragOver && (
                            <div {...getRootProps({ className: 'document-box-workshop cursor-pointer' })}>
                              <span className="choose-file">
                                {isEditing ? 'Drag and Drop or Choose File' : 'Upload File'}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {isEditing ? (
                        <>
                          <div className="invalid-document">{forms?.error_image}</div>
                          <div className="document-guide">Max upload 5MB</div>
                          <div className="document-guide">jpeg, jpg, png, pdf</div>
                        </>
                      ) : null}
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12} className="section-action px-0">
              {allowEdit ? (
                <Button
                  className="button-action save"
                  onClick={isEditing ? () => handleSubmit() : () => toggleEdit(!isEditing)}
                  disabled={buttonDisabled}
                >
                  {loadingSubmit ? <Spinner color="white" className="mr-1" size="sm"></Spinner> : null}
                  {isEditing ? 'Simpan' : 'Edit'}
                </Button>
              ) : null}
              {isEditing ? (
                <Button className="button-action cancel" onClick={handleCancel} disabled={buttonDisabled}>
                  Batal
                </Button>
              ) : null}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default Tax;
