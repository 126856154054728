import React, { useState } from 'react';
import { Button, Col, FormGroup, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import InputField from '@components/field/InputField';
import TrashIcon from '@assets/icons/trash.svg';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import FilePurpleFill from '@assets/icons/file-purple.svg';
import API from '@utils/API';
import APIS3 from '@utils/APIS3';
import Helper from '@helpers/Helper';

const UploadSPKModal = (props) => {
  const { isOpen, toggle, callbackSubmit, preData, handleResultNotification } = props;
  const api = new API('v2');
  const apiS3 = new APIS3();

  const [file, setFile] = useState();
  const [key, setKey] = useState();
  const [url, setUrl] = useState('');
  const [bookingID, setBookingID] = useState();
  const [errorFile, setErrorFile] = useState('');
  const [no, setNo] = useState('');
  const [errorNo, setErrorNo] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChangeSPKNumber = (e) => {
    const { value } = e.target;

    if (value.length > 100) {
      setErrorNo('Maksimal karakter 100');
    } else if (!value && file?.name) {
      setNo(value);
      setErrorNo('Nomor SPK harus diisi');
    } else if (value && !file?.name) {
      setNo(value);
      setErrorFile('Dokumen SPK harus diisi');
      // } else if (file && value !== Helper.removeFileNameExtention(file?.name)) {
      //   setNo(value);
      //   setErrorNo('No. SPK harus sesuai dengan nama file');
    } else {
      setNo(value);
      setErrorNo('');
    }
  };

  const handleDeleteSPKFile = () => {
    setFile();
    if (no) {
      setErrorFile('Dokumen SPK harus diisi');
    } else {
      setErrorFile('');
    }
    if (!no && file?.name) setErrorNo('');
  };

  const handleClickUploadSPK = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (e) => handleChangeSPKFile(e);
    input.click();
  };

  const handleUploadSPK = (bookingId, spkfile) => {
    setLoading(true);

    if (spkfile) {
      validateSPKFile(spkfile)
        .then((status) => {
          if (status && spkfile) {
            const paramsPresignedUpload = {
              namespace: 'booking',
              identifier: bookingId,
              file_name: spkfile?.name,
              booking_id: bookingId,
            };
            api
              .post(`v2/intools/presigned-upload/`, paramsPresignedUpload)
              .then((response) => {
                const urlUpload = response?.data?.data?.presigned_url ?? '';
                const keyFile = response?.data?.data?.key ?? '';
                if (urlUpload !== '') {
                  uploadDocument(urlUpload, spkfile, spkfile?.name, keyFile, bookingId);
                } else {
                  console.log('failed get url of presigned document');
                  setLoading(false);
                }
              })
              .catch((e) => {
                console.log('failed upload presigned document');
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const uploadDocument = (url, spkfile, fileName, keyFile, bookingId) => {
    apiS3
      .put(url, spkfile)
      .then(() => {
        handleUpdateSPKFile(keyFile, bookingId, spkfile, fileName, url);
        setLoading(false);
      })
      .catch((e) => {
        console.log('failed to upload document');
        setLoading(false);
      });
  };

  const handleUpdateSPKFile = async (keyFile, bookingId, spkfile, fileName, url) => {
    setFile(spkfile);
    setKey(keyFile);
    setUrl(url);
    setBookingID(bookingId);
  };

  const handleChangeSPKFile = (e) => {
    const files = e.target.files[0];

    handleUploadSPK(preData?.booking_id, files);
    if (files) validateSPKFile(files);
  };

  const validateSPKFile = async (files) => {
    const availableFormat = ['jpg', 'png', 'pdf', 'jpeg'];
    const fileSizeError = files?.size > 10000000;
    const fileType = files?.type?.split('/');
    const fileExtentionError = !availableFormat.includes(fileType[1]);
    let status = true;

    if (fileSizeError) {
      setErrorFile('Ukuran maksimal file adalah 10MB');
      status = false;
    } else if (fileExtentionError) {
      setErrorFile('Ekstensi file tidak sesuai. ekstensi file yang diperbolehkan adalah jpg/jpeg/png/pdf');
      status = false;
    } else if (!no && files?.name) {
      setErrorNo('Nomor SPK harus diisi');
    } else if (no && !files?.name) {
      setErrorFile('Dokumen SPK harus diisi');
      // } else if (no && no !== Helper.removeFileNameExtention(files?.name)) {
      //   setErrorNo('No. SPK harus sesuai dengan nama file');
    } else {
      setErrorFile('');
      status = true;
    }

    return status;
  };

  const validate = () => {
    let status = true;

    if (file) {
      validateSPKFile(file).then((fileStatus) => {
        if (!fileStatus) status = false;
      });
    } else {
      setErrorFile('Dokumen SPK harus diisi');
      status = false;
    }

    if (!no) {
      setErrorNo('No. SPK harus diisi');
    } else if (no.length > 100) {
      setErrorNo('Maksimal karakter 100');
      status = false;
    } else if (!no && file?.name) {
      setErrorNo('Nomor SPK harus diisi');
      status = false;
    } else if (no && !file?.name) {
      setErrorFile('Dokumen SPK harus diisi');
      status = false;
    }

    return status;
  };

  const handleSubmit = async () => {
    if (!validate()) {
      handleResultNotification({
        status: true,
        type: 'fail',
        message: 'Gagal, cek kembali isian form',
      });
      return;
    }

    const payload = {
      booking_id: bookingID,
      spk_number: no,
      key: key,
    };

    await api
      .post('v2/intools/invoices/spk/', payload)
      .then(() => {
        callbackSubmit();
        handleResultNotification({
          status: true,
          type: 'success',
          message: 'Sukses update file invoice',
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log('failed update spk file => ', err.response);
        handleResultNotification({
          status: true,
          type: 'fail',
          message: 'Gagal, update file invoice. coba ulangi beberapa saat lagi',
        });
        setLoading(false);
      });
  };

  const resetField = () => {
    setFile();
    setErrorFile('');
    setNo('');
    setErrorNo('');
  };

  const btnSubmitDisabled = errorFile || errorNo || !no || !file;

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="modal-custom" onClosed={resetField}>
      <ModalBody>
        <h4 className="mt-0 text-dark">Upload SPK</h4>

        <FormGroup row>
          <Label lg={3}>No. SPK</Label>
          <InputField
            colWidth={9}
            onChange={handleChangeSPKNumber}
            value={no}
            placeholder="Ex: SPK/123.123..."
            disabled={loading}
            invalid={!!errorNo}
            errorMessage={errorNo}
          />
        </FormGroup>

        <FormGroup row>
          <Label lg={3}>SPK Document</Label>
          <Col lg={9} className="d-flex justify-content-between">
            {file ? (
              <div>
                <img src={FilePurpleFill} alt="" width={24} height={24} />
                <span className="mx-2">{Helper.decreaseSPKName(file?.name)}</span>
                <img
                  src={TrashIcon}
                  alt=""
                  style={{ padding: 8, border: '1px solid #D6D8E7', borderRadius: 4, height: 'fit-content' }}
                  onClick={handleDeleteSPKFile}
                />
              </div>
            ) : null}
            <Button
              color="primary"
              size="lg"
              className="btn-add-item"
              style={{ marginBottom: '10px' }}
              onClick={() => handleClickUploadSPK(preData)}
              disabled={loading}
            >
              <img src={PlusFill} className="mr-2" />
              Browse
            </Button>
          </Col>
          <Row className="justify-content-end w-100">
            <Col lg={9} style={{ paddingLeft: 20 }}>
              <span className="text-danger">{errorFile}</span>
            </Col>
          </Row>
        </FormGroup>

        <div className="mt-3 d-flex justify-content-center">
          <Button color="danger" onClick={toggle} className="mr-2" disabled={loading} outline size="lg">
            Batal
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={loading || btnSubmitDisabled}
            size="lg"
            className="d-flex align-items-center"
          >
            {loading ? <Spinner color="light" size="sm" className="mr-1" /> : null}
            Upload
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UploadSPKModal;
