import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import API from '@utils/API';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import Divider from '@components/divider/Divider';
import DetailCampaignForm from './DetailCampaignForm';
import CustomerEligibilityForm from './CustomerEligibilityForm';
import SetupVoucherForm from './SetupVoucherForm';
import SetupDiscountValueForm from './SetupDiscountValueForm';
import PublishmentScenarioForm from './PublishmentScenarioForm';
import SetupValidationRulesForm from './SetupValidationRulesForm';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import map from 'lodash/map';
import moment from 'moment';

const CampaignDetailCreate = (props) => {
  const { id, dataCampaign, groupOptions, bannerTypeOptions, allowEdit, allowCreate, onResultNotification } = props;

  const api = new API('v2');

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState();
  const [disableForm, setDisableForm] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const [showDetailCampaign, setShowDetailCampaign] = useState(true);
  const [showCustomerEligibility, setShowCustomerEligibility] = useState(false);
  const [showSetupVoucher, setShowSetupVoucher] = useState(false);
  const [showSetupDiscountValue, setShowSetupDiscountValue] = useState(false);
  const [showSetupValidation, setShowSetupValidation] = useState(false);
  const [showPublishmentScenario, setShowPublishmentScenario] = useState(false);

  const discountForOptions = [
    { value: 'whole-cart', label: 'Whole Cart' },
    { value: 'per-item', label: 'Discount Per Baris' },
    { value: 'selected-packages', label: 'Selected Packages' },
  ];

  useEffect(() => {
    setDataDetail(dataCampaign);
  }, [dataCampaign]);

  useEffect(() => {
    if (id) setDisableForm(!allowEdit);
  }, [allowEdit]);

  useEffect(() => {
    if (allowCreate) setDisableForm(!allowCreate);
  }, [allowCreate]);

  const handleActionNext = (step, passValidate, newDataDetail) => {
    if (step == 'detail') {
      if (passValidate) {
        setShowDetailCampaign(false);
        setShowCustomerEligibility(true);
        setDataDetail({ ...newDataDetail });
      } else {
        handleResultNotification(true, 'fail', 'Mohon lengkapi form');
      }
    } else if (step == 'eligibility') {
      if (passValidate) {
        setShowCustomerEligibility(false);
        setShowSetupVoucher(true);
        setDataDetail({ ...newDataDetail });
      } else {
        handleResultNotification(true, 'fail', 'Mohon lengkapi form');
      }
    } else if (step == 'voucher') {
      if (passValidate) {
        setShowSetupVoucher(false);
        setShowSetupDiscountValue(true);
        setDataDetail({ ...newDataDetail });
      } else {
        handleResultNotification(true, 'fail', 'Mohon lengkapi form');
      }
    } else if (step == 'discount') {
      if (passValidate) {
        setShowSetupDiscountValue(false);
        setShowSetupValidation(true);
        setDataDetail({ ...newDataDetail });
      } else {
        handleResultNotification(true, 'fail', 'Mohon lengkapi form');
      }
    } else if (step == 'validation') {
      if (passValidate) {
        setShowSetupValidation(false);
        setShowPublishmentScenario(true);
        setDataDetail({ ...newDataDetail });
      } else {
        handleResultNotification(true, 'fail', 'Mohon lengkapi form');
      }
    } else if (step == 'publishment') {
      if (passValidate) {
        setDataDetail({ ...newDataDetail });
        setConfirmModal(true);
      } else {
        handleResultNotification(true, 'fail', 'Mohon lengkapi form');
      }
    }
  };

  const handleActionBack = (step) => {
    if (step == 'eligibility') {
      setShowCustomerEligibility(false);
      setShowDetailCampaign(true);
    } else if (step == 'voucher') {
      setShowSetupVoucher(false);
      setShowCustomerEligibility(true);
    } else if (step == 'discount') {
      setShowSetupDiscountValue(false);
      setShowSetupVoucher(true);
    } else if (step == 'validation') {
      setShowSetupValidation(false);
      setShowSetupDiscountValue(true);
    } else if (step == 'publishment') {
      setShowPublishmentScenario(false);
      setShowSetupValidation(true);
    }
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const generateValidationRules = (data) => {
    const transformedData = map(data, (item) => {
      return ' ' + item?.value;
    });

    return data ? transformedData.toString().substring(1) : '';
  };

  const generateValidationRulesForNonItemPromo = (data, params) => {
    const transformedData = map(data, (item) => {
      if (item?.reference === params) {
        return ' ' + item?.value;
      }
    });
    return data ? transformedData.toString().substring(1) : '';
  };

  const generatePayloadValidationRules = () => {
    let validationRules = [
      {
        type: 'service',
        values: generateValidationRules(dataDetail?.services),
      },
      {
        type: 'product',
        values: generateValidationRules(dataDetail?.products),
      },
      {
        type: 'workshop',
        values: dataDetail?.workshops == 'all' ? 'all' : generateValidationRules(dataDetail?.workshops),
      },
      {
        type: 'brand',
        values: generateValidationRules(dataDetail?.brands),
      },
      {
        type: 'package',
        values: generateValidationRules(dataDetail?.packages),
      },
      {
        type: 'booking_origin',
        values:
          dataDetail?.booking_origin?.length > 1
            ? dataDetail?.booking_origin?.join(', ')
            : dataDetail?.booking_origin?.[0],
      },
      {
        type: 'payment_method',
        values: dataDetail?.payment_type ?? '',
      },
      {
        type: 'prohibition_combine',
        values: generateValidationRules(dataDetail?.cannotBeCombined),
      },
      {
        type: 'exclusion',
        values: {
          packages: generateValidationRulesForNonItemPromo(dataDetail?.exclusion, 'package'),
          products: generateValidationRulesForNonItemPromo(dataDetail?.exclusion, 'product'),
        },
      },
    ];

    if (+dataDetail?.customer_b2b?.length > 0) {
      const listCustomerB2B = dataDetail?.customer_b2b.map((item) => ({ id: item?.value, value: item?.value }));
      const rulesCustomer = {
        type: 'customer',
        values: generateValidationRules(listCustomerB2B),
      };

      return [...validationRules, rulesCustomer];
    }

    return validationRules;
  };

  const getPayloadCreate = () => {
    const payload = {
      name: dataDetail?.name ?? null,
      category: dataDetail?.category?.value ?? null,
      start_date: moment(dataDetail?.start_date).format(),
      end_date: moment(dataDetail?.end_date).format(),
      is_active: dataDetail?.is_active ?? false,
      is_membership: dataDetail?.is_subscription ?? false,
      slug: dataDetail?.campaign_slug?.length > 0 ? dataDetail?.campaign_slug : null,
      pic_name: dataDetail?.pic_campaign ?? null,
      deck_link: dataDetail?.deck_campaign ?? null,
      customer_eligibility: dataDetail?.customer_eligibility ?? '',
      customer_segment: dataDetail?.customer_segment?.value ?? '',
      membership_campaign_id: dataDetail?.campaign?.value ?? null,
      customer_id: dataDetail?.customer?.value ?? null,
      user_car_id: dataDetail?.plate?.id ?? null,
      voucher_code: dataDetail?.voucher_code ?? null,
      voucher_type: dataDetail?.campaign_type ?? null,
      voucher_count: dataDetail?.total_voucher?.formatMoney ?? null,
      voucher_redeem_per_user: dataDetail?.max_redeem_per_user?.formatMoney ?? null,
      voucher_quota: dataDetail?.max_quota?.formatMoney ?? null,
      discount_for: dataDetail?.discount_for?.value ?? null,
      discount_type: dataDetail?.discount_type == 'amount' ? 'amount' : 'percent',
      discount_amount: dataDetail?.discount_value?.formatMoney ?? null,
      max_discount: dataDetail?.max_discount?.formatMoney ?? 0,
      is_public: dataDetail?.is_public ?? false,
      is_show_list: dataDetail?.is_show_list ?? false,
      is_featured: dataDetail?.is_featured ?? false,
      is_fbo: 'all',
      banner_text: dataDetail?.banner_text ?? '',
      seo_title: dataDetail?.seo_title ?? '',
      seo_description: dataDetail?.seo_description ?? '',
      description: dataDetail?.description ?? '',
      image_link: dataDetail?.image_key ?? '',
      image_desktop_link: dataDetail?.image_desktop_key ?? '',
      tnc: dataDetail?.terms_and_condition ?? '',
      validation_rules: generatePayloadValidationRules(),
      min_transaction: dataDetail?.min_transaction?.formatMoney ?? 0,
    };

    if (dataDetail?.is_public) {
      payload.banner_type = dataDetail?.banner_type?.value ?? '';
    }

    if (dataDetail?.discount_type == 'percentage') {
      payload.max_discount = dataDetail?.max_discount?.formatMoney ?? 0;
    }

    if (dataDetail?.banner_type?.value == 'deeplink') {
      payload.banner_link = dataDetail?.banner_link ?? '';
    }

    if (dataDetail?.banner_type?.value == 'landing-page') {
      payload.seo_title = dataDetail?.seo_title ?? '';
      payload.seo_description = dataDetail?.seo_description ?? '';
    }

    return payload;
  };

  const getPayloadUpdate = () => {
    const payload = {
      name: dataDetail?.name ?? null,
      category: dataDetail?.category?.value ?? null,
      start_date: moment(dataDetail?.start_date).format(),
      end_date: moment(dataDetail?.end_date).format(),
      is_active: dataDetail?.is_active ?? false,
      is_membership: dataDetail?.is_subscription ?? false,
      slug: dataDetail?.campaign_slug?.length > 0 ? dataDetail?.campaign_slug : null,
      pic_name: dataDetail?.pic_campaign ?? null,
      deck_link: dataDetail?.deck_campaign ?? null,
      customer_eligibility: dataDetail?.customer_eligibility ?? '',
      customer_segment: dataDetail?.customer_segment?.value ?? '',
      membership_campaign_id: dataDetail?.campaign?.value ?? null,
      customer_id: dataDetail?.customer?.value ?? null,
      user_car_id: dataDetail?.plate?.id ?? null,
      voucher_code: dataDetail?.voucher_code ?? null,
      voucher_type: dataDetail?.campaign_type ?? null,
      voucher_count: dataDetail?.total_voucher?.formatMoney ?? null,
      voucher_redeem_per_user: dataDetail?.max_redeem_per_user?.formatMoney ?? null,
      voucher_quota: dataDetail?.max_quota?.formatMoney ?? null,
      discount_for: dataDetail?.discount_for?.value ?? null,
      discount_type: dataDetail?.discount_type == 'amount' ? 'amount' : 'percent',
      discount_amount: dataDetail?.discount_value?.formatMoney ?? null,
      discount_value: dataDetail?.discount_value?.formatMoney ?? null,
      is_public: dataDetail?.is_public ?? false,
      is_show_list: dataDetail?.is_show_list ?? false,
      is_featured: dataDetail?.is_featured ?? false,
      is_fbo: 'all',
      banner_text: dataDetail?.banner_text ?? '',
      description: dataDetail?.description ?? '',
      image_link: dataDetail?.image_key
        ? dataDetail?.image_key
        : dataDetail?.image_link
        ? dataCampaign?.image_link
        : '',
      image_desktop_link: dataDetail?.image_desktop_key
        ? dataDetail?.image_desktop_key
        : dataDetail?.image_desktop_link
        ? dataCampaign?.image_desktop_link
        : '',
      validation_rules: generatePayloadValidationRules(),
      min_transaction: dataDetail?.min_transaction?.formatMoney ?? 0,
    };

    if (dataDetail?.is_public) {
      payload.banner_type = dataDetail?.banner_type?.value ?? null;
    }

    if (dataDetail?.discount_type == 'percent') {
      payload.max_discount = dataDetail?.max_discount?.formatMoney ?? 0;
    }

    if (dataDetail?.banner_type?.value == 'deeplink') {
      payload.banner_link = dataDetail?.banner_link ?? '';
    }

    if (dataDetail?.banner_type?.value == 'landing-page') {
      payload.tnc = dataDetail?.terms_and_condition ?? '';
      payload.seo_title = dataDetail?.seo_title ?? '';
      payload.seo_description = dataDetail?.seo_description ?? '';
    }

    return payload;
  };

  const updateCampaign = async () => {
    const payload = getPayloadUpdate();
    // return;
    setDisableForm(true);
    try {
      const response = await api.post(`v2/intools/campaigns/${id}/`, payload);
      setConfirmModal(false);
      setTimeout(() => {
        handleResultNotification(true, 'success', 'Berhasil Mengubah Campaign');
      }, 300);
      setTimeout(() => {
        setDisableForm(false);
        history.push(`/campaigns/campaign/detail/${id}`);
      }, 2000);
    } catch (e) {
      let defaultMsg = 'Gagal Mengubah Campaign';
      let errMsg = e?.response?.data?.error?.message
        ? defaultMsg + ', ' + e?.response?.data?.error?.message
        : defaultMsg;
      setConfirmModal(false);
      setTimeout(() => {
        handleResultNotification(true, 'fail', errMsg);
        setDisableForm(false);
      }, 300);
    }
  };

  const createCampaign = async () => {
    const payload = getPayloadCreate();
    setDisableForm(true);
    try {
      const response = await api.post(`v2/intools/campaigns/`, payload);
      setConfirmModal(false);
      setTimeout(() => {
        handleResultNotification(true, 'success', 'Berhasil Menambahkan Campaign');
      }, 300);
      setTimeout(() => {
        setDisableForm(false);
        history.push(`/campaigns`);
      }, 2000);
    } catch (e) {
      let defaultMsg = 'Gagal Menambahkan Campaign';
      let errMsg = e?.response?.data?.error?.message
        ? defaultMsg + ', ' + e?.response?.data?.error?.message
        : defaultMsg;
      setConfirmModal(false);
      setTimeout(() => {
        handleResultNotification(true, 'fail', errMsg);
        setDisableForm(false);
      }, 300);
    }
  };

  return (
    <React.Fragment>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <Card className="card-custom">
          <CardBody className="p-0">
            <Row className="align-items-center">
              <Col>
                <div className="center-line">
                  <span
                    className={
                      showDetailCampaign ||
                      showCustomerEligibility ||
                      showSetupVoucher ||
                      showSetupDiscountValue ||
                      showSetupValidation ||
                      showPublishmentScenario
                        ? 'active'
                        : ''
                    }
                  >
                    Detail Campaign
                  </span>
                  <span>{''}</span>
                </div>
              </Col>
              <Col>
                <div className="center-line">
                  <span
                    className={
                      showCustomerEligibility ||
                      showSetupVoucher ||
                      showSetupDiscountValue ||
                      showSetupValidation ||
                      showPublishmentScenario
                        ? 'active'
                        : ''
                    }
                  >
                    Customer Eligibility
                  </span>
                  <span>{''}</span>
                </div>
              </Col>
              <Col>
                <div className="center-line">
                  <span
                    className={
                      showSetupVoucher || showSetupDiscountValue || showSetupValidation || showPublishmentScenario
                        ? 'active'
                        : ''
                    }
                  >
                    Setup Voucher
                  </span>
                  <span>{''}</span>
                </div>
              </Col>
              <Col>
                <div className="center-line">
                  <span
                    className={showSetupDiscountValue || showSetupValidation || showPublishmentScenario ? 'active' : ''}
                  >
                    Setup Discount Value
                  </span>
                  <span>{''}</span>
                </div>
              </Col>
              <Col>
                <div className="center-line">
                  <span className={showSetupValidation || showPublishmentScenario ? 'active' : ''}>
                    Setup Validation Rules
                  </span>
                  <span>{''}</span>
                </div>
              </Col>
              <Col>
                <div className="text-line">
                  <span className={showPublishmentScenario ? 'active' : ''}>Publishment Scenario</span>
                </div>
              </Col>
            </Row>

            {showDetailCampaign && (
              <>
                <Divider title="Detail Campaign" />
                <DetailCampaignForm
                  dataDetail={dataDetail}
                  dataCampaign={dataCampaign}
                  groupOptions={groupOptions}
                  allowEdit={allowEdit}
                  handleNext={(e) => handleActionNext('detail', e?.status, e?.data)}
                  disableButton={disableButton}
                  disableForm={disableForm}
                />
              </>
            )}

            {showCustomerEligibility && (
              <>
                <Divider title="Customer Eligibility" />
                <CustomerEligibilityForm
                  dataDetail={dataDetail}
                  dataCampaign={dataCampaign}
                  allowEdit={allowEdit}
                  handleNext={(e) => handleActionNext('eligibility', e?.status, e?.data)}
                  handleBack={() => handleActionBack('eligibility')}
                  disableButton={disableButton}
                  disableForm={disableForm}
                />
              </>
            )}

            {showSetupVoucher && (
              <>
                <Divider title="Voucher" />
                <SetupVoucherForm
                  dataDetail={dataDetail}
                  dataCampaign={dataCampaign}
                  allowEdit={allowEdit}
                  handleNext={(e) => handleActionNext('voucher', e?.status, e?.data)}
                  handleBack={() => handleActionBack('voucher')}
                  disableButton={disableButton}
                  disableForm={disableForm || id ? true : false}
                />
              </>
            )}

            {showSetupDiscountValue && (
              <>
                <Divider title="Discount Value" />
                <SetupDiscountValueForm
                  dataDetail={dataDetail}
                  discountForOptions={discountForOptions}
                  allowEdit={allowEdit}
                  handleNext={(e) => handleActionNext('discount', e?.status, e?.data)}
                  handleBack={() => handleActionBack('discount')}
                  disableButton={disableButton}
                  disableForm={disableForm}
                />
              </>
            )}

            {showSetupValidation && (
              <>
                <Divider title="Validation Rules" />
                <SetupValidationRulesForm
                  dataDetail={dataDetail}
                  allowEdit={allowEdit}
                  handleNext={(e) => handleActionNext('validation', e?.status, e?.data)}
                  handleBack={() => handleActionBack('validation')}
                  disableButton={disableButton}
                  disableForm={disableForm}
                />
              </>
            )}

            {showPublishmentScenario && (
              <>
                <Divider title="Publishment Scenario" />
                <PublishmentScenarioForm
                  dataDetail={dataDetail}
                  id={id}
                  bannerOptions={bannerTypeOptions}
                  handleNext={(e) => handleActionNext('publishment', e?.status, e?.data)}
                  handleBack={() => handleActionBack('publishment')}
                  disableButton={disableButton}
                  disableForm={disableForm}
                />
              </>
            )}
          </CardBody>
          <ConfirmationModal
            modal={confirmModal}
            toggle={() => setConfirmModal(!confirmModal)}
            header={`Anda yakin ingin menyimpan ?`}
            subHeader={`Aksi ini akan mengakibatkan ${
              id ? 'hasil edit campaign ' + dataDetail?.name : 'data campaign baru'
            } tersimpan`}
            isDisabled={disableForm}
            onConfirm={() => (id ? updateCampaign() : createCampaign())}
            toggleClose={() => setConfirmModal(!confirmModal)}
          ></ConfirmationModal>
        </Card>
      )}
    </React.Fragment>
  );
};

export default CampaignDetailCreate;
