import React, { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { FormGroup, Col, Input, Row, Button, Label } from 'reactstrap';
import moment from 'moment';
import userPermission from '@utils/userPermission';
import Divider from '@components/divider/Divider';
import EditableInputField from '@components/field/EditableInputField';
import EditableSelectField from '@components/field/EditableSelectField';
import EditableSelectAsyncField from '@components/field/EditableSelectAsyncField';
import EditModal from '@components/modal/EditModal';
import API from '@utils/API';
import { regexOnlyAllowNumber } from '@utils/Constants';
import Helper from '@helpers/Helper';
import { map, find } from 'lodash';

const DetailCarHealth = (props) => {
  const {
    usercarID,
    bookingCode,
    isUpdated,
    isDisabledHeaderForm,
    originOptions,
    inspectionList,
    scoreList,
    defaultValueCarHealth,
    onUpdateCarHealth,
  } = props;
  const {
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    validateCriteriaMode: 'all',
  });

  const { generatePermission } = userPermission();
  const api = new API('v2');
  const [dataForm, setDataForm] = useState({});
  const [modalType, setModalType] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [disabledHeaderForm, setDisabledHeaderForm] = useState(false);
  const [disabledForm, setDisabledForm] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [defaultBookingIdOptions, setDefaultBookingIdOptions] = useState([]);
  const [selectedBookingCode, setSelectedBookingCode] = useState(null);
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [allowDetail, setAllowDetail] = useState(false);

  const { fields } = useFieldArray({
    control,
    name: 'car_health.inspections',
  });

  const watchBooking = watch(`car_health.booking`);

  const setDefaultForm = (dataForm) => {
    reset({ car_health: dataForm });
  };

  const resetInspections = () => {
    const inspectionDate = getValues(`car_health.booking.booking_datetime`);
    const inspectionDateString = moment(inspectionDate).isValid()
      ? moment(inspectionDate).format('DD/MM/YYYY , HH:mm:ss')
      : '-';
    setValue(`car_health.inspection_date`, inspectionDate);
    setValue(`car_health.inspection_date_string`, inspectionDateString);
    setValue(`car_health.inspections`, inspectionList);
    setValue(`car_health.inspection_location`, '');
    clearErrors();
  };

  const getDescriptionScore = (score) => {
    let description = '';

    scoreList.forEach((item) => {
      if (item.value == score) {
        description = item?.description ?? '';
      }
    });

    return description;
  };

  const handleBookingChange = (e) => {
    setSelectedBookingCode(e);
    setValue(`car_health.booking.code`, e?.value);
    setValue(`car_health.booking.booking_datetime`, e?.datetime);
    setValue(`car_health.booking.booking_id`, e?.booking_id);
    const inspectionDateString = moment(e?.datetime).isValid()
      ? moment(e?.datetime).format('DD/MM/YYYY , HH:mm:ss')
      : '-';
    setValue(`car_health.inspection_date_string`, inspectionDateString);
    setValue(`car_health.inspection_location`, e?.location ?? 'null');
    setDisabledHeaderForm(false);
    if (!getValues('car_health.origin.value')) {
      setIsUpdate(true);
      setDisabledForm(false);
    }
    setOriginByInspectionLocation();
  };

  const setOriginByInspectionLocation = () => {
    const inspectionLocation = getValues('car_health.inspection_location');
    if (!!inspectionLocation) {
      const selectedLocation = find(originOptions, { label: inspectionLocation });

      handleChangeOrigin(selectedLocation);
    }
  };

  const handleChangeOrigin = (e) => {
    setValue(`car_health.origin.label`, e?.label);
    setValue(`car_health.origin.value`, e?.value);
    setValue('car_health.inspection_location', e?.label);
    setSelectedOrigin(e);
    if (!getValues('car_health.booking.code')) {
      setIsUpdate(true);
      setDisabledForm(false);
    }
  };

  async function loadBookingOptions(booking_code) {
    const params = {
      user_car_id: usercarID,
      booking_code: booking_code ?? null,
      page: 1,
      limit: 30,
    };

    try {
      const response = await api.get(`v2/intools/user-cars/${usercarID}/bookings/`, { params });
      const bookings = response?.data?.data?.bookings ?? [];
      let listBookingCode = map(bookings, (item, idx) => {
        return {
          id: idx,
          value: item?.booking_code ?? '',
          label: item?.booking_code ?? '',
          datetime: item?.booking_datetime ?? '',
          booking_id: item?.id ?? '',
          location: item?.inspection_location ?? '',
        };
      });
      return listBookingCode;
    } catch (error) {
      return [];
    }
  }

  async function getDefaultBookingIdOptions() {
    const bookings = await loadBookingOptions();
    if (bookingCode && bookingCode !== '') {
      handleAutoSelectBooking(bookings);
    } else {
      setDefaultBookingIdOptions(bookings);
    }
  }

  async function handleAutoSelectBooking(bookings) {
    if (bookingCode && bookingCode != '') {
      let bookingOptions = bookings;
      let bookingOptionsSelected = await loadBookingOptions(bookingCode);
      if (bookingOptionsSelected.length !== 0) {
        bookingOptionsSelected[0].id = bookingOptions.length;
        const selected = find(bookingOptions, (data) => {
          return bookingOptionsSelected[0].value === data.value;
        });

        if (!selected) {
          bookingOptions.push(bookingOptionsSelected[0]);
        }

        setDefaultBookingIdOptions(bookingOptions);
        handleBookingChange(bookingOptionsSelected[0]);
      } else {
        setIsUpdate(false);
      }
    }
  }

  const submitForm = (data) => {
    setDataForm(data);
    setConfirmModal(true);
    setModalType('Simpan');
  };

  const onError = () => {
    showAlert('danger', `Mohon Lengkapi Form!`);
  };

  async function updateCarHealth() {
    let payloadInspectionsPoints = [];

    await dataForm?.car_health?.inspections?.map((item, index) => {
      item?.conditions;
      if (item?.conditions) {
        let inspectionObj = {};
        inspectionObj.inspection_point_id = inspectionList[index]?.id ?? index + 1;
        inspectionObj.value = item?.conditions ?? null;
        inspectionObj.note = item?.note ?? '';
        payloadInspectionsPoints.push(inspectionObj);
      }
    });

    const getWsId = find(originOptions, dataForm?.car_health?.origin) ?? null;

    let payload = {
      origin: dataForm?.car_health?.origin?.value ?? null,
      booking_id: dataForm?.car_health?.booking?.booking_id ?? null,
      inspection_date: dataForm?.car_health?.booking?.booking_datetime ?? null,
      inspection_location: getWsId?.id ?? null,
      mileage: dataForm?.car_health?.mileage?.value ?? null,
      summary: dataForm?.car_health?.summary ?? '',
      inspection_points: payloadInspectionsPoints,
      event_location: dataForm?.car_health?.event_location ?? null,
      event_name: dataForm?.car_health?.event_name ?? null,
      workshop_project: dataForm?.car_health?.workshop_project ?? null,
      workshop_langganan: dataForm?.car_health?.workshop_langganan ?? null,
    };

    if (payloadInspectionsPoints?.length < 1) {
      updateFail('Data Inspeksi harus diisi, minimal 1 data');
      return;
    }

    try {
      const response = await api.post(`v2/intools/user-cars/${usercarID}/inspections/`, payload);
      if (response?.data?.data?.inspection?.id) {
        updateSuccess();
      } else {
        updateFail();
      }
    } catch (error) {
      updateFail(error?.response?.data?.error?.message ?? '');
    }
  }

  const updateSuccess = () => {
    setIsUpdate(false);
    setDataForm({});
    setDisabledHeaderForm(true);
    setDisabledForm(true);
    setConfirmModal(false);
    showAlert('success', 'Berhasil update inspeksi.');
    setTimeout(() => {
      onUpdateCarHealth();
    }, 500);
  };

  const updateFail = (message) => {
    setConfirmModal(false);
    showAlert('danger', message ? message : 'Gagal update inspeksi.');
  };

  const showAlert = (color, message) => {
    setSubmitStatus({
      color: color,
      message: message,
    });

    setTimeout(() => {
      setSubmitStatus(null);
    }, 3000);
  };

  useEffect(() => {
    if (watchBooking?.id && isUpdate) {
      setDisabledForm(false);
      resetInspections();
    }
  }, [watchBooking]);

  useEffect(() => {
    setDefaultForm(defaultValueCarHealth);
  }, [defaultValueCarHealth]);

  useEffect(() => {
    setDisabledHeaderForm(isDisabledHeaderForm);
  }, [isDisabledHeaderForm]);

  useEffect(() => {
    if (isUpdated && originOptions) {
      getDefaultBookingIdOptions();
    }
    setIsUpdate(isUpdated);
    allowUpdateCarHealth();
    allowDetailCarHealth();
  }, [isUpdated, originOptions]);

  const allowUpdateCarHealth = () => {
    if (
      generatePermission('customer', 'carinspectionsubmit') ||
      generatePermission('customer', 'carinspectionall') ||
      generatePermission('customer', 'carall')
    ) {
      setAllowUpdate(true);
    } else {
      setAllowUpdate(false);
    }
  };

  const allowDetailCarHealth = () => {
    if (!isUpdated) {
      if (
        generatePermission('customer', 'carinspectiondetail') ||
        generatePermission('customer', 'carinspectionall') ||
        generatePermission('customer', 'carall')
      ) {
        setAllowDetail(true);
      } else {
        setAllowDetail(false);
      }
    } else {
      setAllowDetail(true);
    }
  };

  const bookingCodeValue = getValues('car_health.booking.code');
  const originValue = getValues('car_health.origin.value');

  useEffect(() => {
    if (!!originValue || !!bookingCodeValue) {
      setDisabledForm(false);

      return;
    }

    setDisabledForm(true);
    if (!!!originValue || !!!bookingCodeValue) {
      setValue('car_health.inspection_location', '');
      setValue('car_health.inspection_date_string', '');
    }

    if (!!!bookingCodeValue) {
      setValue('car_health.origin', null);
      setSelectedOrigin(null);
    }
  }, [bookingCodeValue, originValue]);

  return (
    <React.Fragment>
      {allowDetail && (
        <>
          <Row>
            <Col xs={12} md={6} lg={6}>
              {isUpdate && (
                <Controller
                  control={control}
                  name={`car_health.booking.code`}
                  render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                    return (
                      <EditableSelectAsyncField
                        {...rest}
                        colWidth={9}
                        labelWidth={3}
                        value={selectedBookingCode}
                        isDisabled={disabledHeaderForm}
                        defaultOptions={defaultBookingIdOptions}
                        title="Booking Code"
                        placeholder="Pilih..."
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadBookingOptions}
                        onChange={handleBookingChange}
                        errorMessage={error?.message ?? ''}
                        isClearable
                      />
                    );
                  }}
                />
              )}
              {!isUpdate && (
                <Controller
                  name={`car_health.booking.booking_code`}
                  control={control}
                  render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                    <EditableInputField
                      labelWidth={3}
                      colWidth={9}
                      title="Booking Code"
                      disabled={true}
                      invalid={error ? true : false}
                      errorMessage={error?.message ?? ''}
                      {...rest}
                    />
                  )}
                />
              )}
              {isUpdate && (
                <Controller
                  control={control}
                  name={`car_health.origin`}
                  rules={{
                    validate: () => {
                      const validOrigin = !!getValues(`car_health.origin.value`);
                      const validBookingCode = !!getValues(`car_health.booking.code`);

                      if (validBookingCode) {
                        return;
                      }

                      return validOrigin || 'Origin harus diisi';
                    },
                  }}
                  render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                    return (
                      <EditableSelectField
                        {...rest}
                        required
                        colWidth={9}
                        labelWidth={3}
                        disabled={disabledHeaderForm}
                        title="Origin"
                        placeholder="Pilih..."
                        value={selectedOrigin}
                        closeMenuOnSelect={true}
                        options={originOptions}
                        onChange={handleChangeOrigin}
                        errorMessage={error?.message ?? ''}
                        isClearable
                      />
                    );
                  }}
                />
              )}
              {!isUpdate && (
                <Controller
                  name={`car_health.origin.label`}
                  control={control}
                  render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                    <EditableInputField
                      labelWidth={3}
                      colWidth={9}
                      title="Origin"
                      disabled={true}
                      invalid={error ? true : false}
                      errorMessage={error?.message ?? ''}
                      {...rest}
                    />
                  )}
                />
              )}
              {isUpdate && (
                <Controller
                  control={control}
                  name={`car_health.mileage.string`}
                  render={({ field: { ref, onChange, ...rest }, fieldState: { error } }) => {
                    return (
                      <EditableInputField
                        colWidth={9}
                        labelWidth={3}
                        disabled={disabledForm}
                        title="Kilometer"
                        invalid={error ? true : false}
                        errorMessage={error?.message ?? ''}
                        onChange={(e) => {
                          let mile = Helper.formatMoneyOnField(e.target.value);
                          setValue(`car_health.mileage.value`, mile.formatMoney);
                          setValue(`car_health.mileage.string`, mile.formatMoneyString);
                          const regexNumb = new RegExp(regexOnlyAllowNumber);
                          if (mile.formatMoney && mile.formatMoney <= 9999999 && regexNumb.test(mile.formatMoney)) {
                            clearErrors(`car_health.mileage.string`);
                          } else if (mile.formatMoney > 9999999) {
                            setError(`car_health.mileage.string`, {
                              message: 'Format Mileage max 7 characters',
                            });
                          }
                        }}
                        ref={register}
                        {...rest}
                      />
                    );
                  }}
                />
              )}
              {!isUpdate && (
                <Controller
                  name={`car_health.mileage.string`}
                  control={control}
                  render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                    <EditableInputField
                      labelWidth={3}
                      colWidth={9}
                      title="Kilometer"
                      disabled={true}
                      invalid={error ? true : false}
                      errorMessage={error?.message ?? ''}
                      {...rest}
                    />
                  )}
                />
              )}
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Controller
                name={`car_health.inspection_date_string`}
                control={control}
                render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                  <EditableInputField
                    labelWidth={3}
                    colWidth={9}
                    title="Tanggal Inspeksi"
                    disabled={true}
                    invalid={error ? true : false}
                    errorMessage={error?.message ?? ''}
                    {...rest}
                  />
                )}
              />
              <Controller
                name={`car_health.inspection_location`}
                control={control}
                render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                  <EditableInputField
                    labelWidth={3}
                    colWidth={9}
                    title="Lokasi Inspeksi"
                    disabled={true}
                    invalid={error ? true : false}
                    errorMessage={error?.message ?? ''}
                    {...rest}
                  />
                )}
              />
              {isUpdate && (
                <Controller
                  name={`car_health.event_location`}
                  control={control}
                  rules={{
                    validate: () => {
                      const event = getValues(`car_health.event_location`);
                      if (event) {
                        const valid = event.length > 100 ? false : true;
                        return valid || 'Format Event Location max 100 characters';
                      }
                      return true;
                    },
                  }}
                  render={({ field: { ref, onChange, ...rest }, fieldState: { error } }) => (
                    <EditableInputField
                      labelWidth={3}
                      colWidth={9}
                      title="Lokasi Event"
                      disabled={disabledForm}
                      invalid={error ? true : false}
                      errorMessage={error?.message ?? ''}
                      onChange={(e) => {
                        setValue(`car_health.event_location`, e.target.value);
                        if (e.target.value.length <= 100) {
                          clearErrors(`car_health.event_location`);
                        }
                        if (e.target.value.length > 100) {
                          setError(`car_health.event_location`, {
                            message: 'Format Event Location max 100 characters',
                          });
                        }
                      }}
                      {...rest}
                    />
                  )}
                />
              )}
              {!isUpdate && (
                <Controller
                  name={`car_health.event_location`}
                  control={control}
                  render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                    <EditableInputField
                      labelWidth={3}
                      colWidth={9}
                      title="Lokasi Event"
                      disabled={true}
                      invalid={error ? true : false}
                      errorMessage={error?.message ?? ''}
                      {...rest}
                    />
                  )}
                />
              )}
              {isUpdate && (
                <Controller
                  name={`car_health.event_name`}
                  control={control}
                  rules={{
                    validate: () => {
                      const event = getValues(`car_health.event_name`);
                      if (event) {
                        const valid = event.length > 100 ? false : true;
                        return valid || 'Format Event Name max 100 characters';
                      }
                      return true;
                    },
                  }}
                  render={({ field: { ref, onChange, ...rest }, fieldState: { error } }) => (
                    <EditableInputField
                      labelWidth={3}
                      colWidth={9}
                      title="Nama Event"
                      disabled={disabledForm}
                      invalid={error ? true : false}
                      errorMessage={error?.message ?? ''}
                      onChange={(e) => {
                        setValue(`car_health.event_name`, e.target.value);
                        if (e.target.value.length <= 100) {
                          clearErrors(`car_health.event_name`);
                        }
                        if (e.target.value.length > 100) {
                          setError(`car_health.event_name`, {
                            message: 'Format Event Name max 100 characters',
                          });
                        }
                      }}
                      {...rest}
                    />
                  )}
                />
              )}
              {!isUpdate && (
                <Controller
                  name={`car_health.event_name`}
                  control={control}
                  render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                    <EditableInputField
                      labelWidth={3}
                      colWidth={9}
                      title="Nama Event"
                      disabled={true}
                      invalid={error ? true : false}
                      errorMessage={error?.message ?? ''}
                      {...rest}
                    />
                  )}
                />
              )}
              {isUpdate && (
                <Controller
                  name={`car_health.workshop_project`}
                  control={control}
                  rules={{
                    validate: () => {
                      const event = getValues(`car_health.workshop_project`);
                      if (event) {
                        const valid = event.length > 100 ? false : true;
                        return valid || 'Format Project from Workshop max 100 characters';
                      }
                      return true;
                    },
                  }}
                  render={({ field: { ref, onChange, ...rest }, fieldState: { error } }) => (
                    <EditableInputField
                      labelWidth={3}
                      colWidth={9}
                      title="Project dari Workshop"
                      disabled={disabledForm}
                      invalid={error ? true : false}
                      errorMessage={error?.message ?? ''}
                      onChange={(e) => {
                        setValue(`car_health.workshop_project`, e.target.value);
                        if (e.target.value.length <= 100) {
                          clearErrors(`car_health.workshop_project`);
                        }
                        if (e.target.value.length > 100) {
                          setError(`car_health.workshop_project`, {
                            message: 'Format Project from Workshop max 100 characters',
                          });
                        }
                      }}
                      {...rest}
                    />
                  )}
                />
              )}
              {!isUpdate && (
                <Controller
                  name={`car_health.workshop_project`}
                  control={control}
                  render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                    <EditableInputField
                      labelWidth={3}
                      colWidth={9}
                      title="Project dari Workshop"
                      disabled={true}
                      invalid={error ? true : false}
                      errorMessage={error?.message ?? ''}
                      {...rest}
                    />
                  )}
                />
              )}
              {isUpdate && (
                <Controller
                  name={`car_health.workshop_langganan`}
                  control={control}
                  rules={{
                    validate: () => {
                      const event = getValues(`car_health.workshop_langganan`);
                      if (event) {
                        const valid = event.length > 100 ? false : true;
                        return valid || 'Format Regural Customer Workshop Name max 100 characters';
                      }
                      return true;
                    },
                  }}
                  render={({ field: { ref, onChange, ...rest }, fieldState: { error } }) => (
                    <EditableInputField
                      labelWidth={3}
                      colWidth={9}
                      title="Nama Bengkel Langganan Customer"
                      disabled={disabledForm}
                      invalid={error ? true : false}
                      errorMessage={error?.message ?? ''}
                      onChange={(e) => {
                        setValue(`car_health.workshop_langganan`, e.target.value);
                        if (e.target.value.length <= 100) {
                          clearErrors(`car_health.workshop_langganan`);
                        }
                        if (e.target.value.length > 100) {
                          setError(`car_health.workshop_langganan`, {
                            message: 'Format Regural Customer Workshop Name max 100 characters',
                          });
                        }
                      }}
                      {...rest}
                    />
                  )}
                />
              )}
              {!isUpdate && (
                <Controller
                  name={`car_health.workshop_langganan`}
                  control={control}
                  render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                    <EditableInputField
                      labelWidth={3}
                      colWidth={9}
                      title="Nama Bengkel Langganan Customer"
                      disabled={true}
                      invalid={error ? true : false}
                      errorMessage={error?.message ?? ''}
                      {...rest}
                    />
                  )}
                />
              )}
            </Col>
          </Row>
          <Divider title="Hasil Inspeksi Kesehatan Mobil" />
          {getValues(`car_health.inspections`)?.length > 0 && (
            <div>
              <Row>
                <Col xs={12} md={8} lg={8}>
                  {fields.map((inspection, index) => {
                    return (
                      <div key={index}>
                        <Controller
                          name={`car_health.inspections[${index}].conditions`}
                          control={control}
                          render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                            return (
                              <FormGroup row key={index}>
                                <Label lg={4} sm={12} className="label-inspections-car-health">
                                  {index + 1 + '. ' + inspection?.label}
                                </Label>
                                <Col lg={8} sm={6}>
                                  <Row>
                                    {scoreList.map((score, idx) => (
                                      <Col className="col-radio-car-health" key={index + '-' + idx}>
                                        {isUpdate && (
                                          <Input
                                            {...rest}
                                            type="radio"
                                            id={'radio' + index + 'index' + idx}
                                            className={
                                              disabledForm ? 'radio-car-health' : 'radio-car-health cursor-pointer'
                                            }
                                            value={score.value}
                                            disabled={disabledForm}
                                            checked={
                                              getValues(`car_health.inspections[${index}].conditions`) == score.value
                                                ? true
                                                : null
                                            }
                                          />
                                        )}
                                        {!isUpdate && (
                                          <Input
                                            {...rest}
                                            type="radio"
                                            id={'radio' + index + 'index' + idx}
                                            className="radio-car-health"
                                            value={score.value}
                                            disabled
                                            checked={
                                              getValues(`car_health.inspections[${index}].conditions`) == score.value
                                                ? true
                                                : null
                                            }
                                          />
                                        )}
                                        <span className="check mt-2">{score.label}</span>
                                      </Col>
                                    ))}
                                  </Row>
                                </Col>
                                <Col lg={4}></Col>
                                <Label lg={8} className="label-describe-scor-car-health">
                                  {error?.message && <span className="text-danger">{error?.message}</span>}
                                  {!error?.message &&
                                    getDescriptionScore(getValues(`car_health.inspections[${index}].conditions`))}
                                </Label>
                                <Col lg={12}>
                                  {isUpdate && (
                                    <Controller
                                      name={`car_health.inspections[${index}].note`}
                                      control={control}
                                      rules={{
                                        validate: () => {
                                          const notes = getValues(`car_health.inspections[${index}].note`);
                                          if (notes) {
                                            const valid = notes?.length <= 200 ? true : false;
                                            return valid || 'Format Note max 200 characters';
                                          }
                                          return true;
                                        },
                                      }}
                                      render={({ field: { ref, onChange, ...rest }, fieldState: { error } }) => (
                                        <EditableInputField
                                          labelWidth={4}
                                          colWidth={8}
                                          onFocus={"e.inputType = 'Textarea'"}
                                          placeholder={'Catatan'}
                                          invalid={error ? true : false}
                                          disabled={disabledForm}
                                          errorMessage={error?.message ?? ''}
                                          onChange={(e) => {
                                            setValue(`car_health.inspections[${index}].note`, e.target.value);
                                            if (e.target.value.length > 200) {
                                              setError(`car_health.inspections[${index}].note`, {
                                                message: 'Format Note max 200 characters',
                                              });
                                            }
                                            if (e.target.value.length <= 200) {
                                              clearErrors(`car_health.inspections[${index}].note`);
                                            }
                                          }}
                                          ref={register}
                                          {...rest}
                                        />
                                      )}
                                    />
                                  )}
                                  {!isUpdate && (
                                    <Controller
                                      name={`car_health.inspections[${index}].note`}
                                      control={control}
                                      rules={{
                                        validate: () => {
                                          const notes = getValues(`car_health.inspections[${index}].note`);
                                          if (notes) {
                                            const valid = notes?.length <= 200 ? true : false;
                                            return valid || 'Format Note max 200 characters';
                                          }
                                          return true;
                                        },
                                      }}
                                      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                                        <EditableInputField
                                          labelWidth={4}
                                          colWidth={8}
                                          placeholder={'Tidak ada catatan'}
                                          disabled={true}
                                          invalid={error ? true : false}
                                          errorMessage={error?.message ?? ''}
                                          {...rest}
                                        />
                                      )}
                                    />
                                  )}
                                </Col>
                              </FormGroup>
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </Col>
                <Col lg={8}>
                  {isUpdate && (
                    <Controller
                      name={`car_health.summary`}
                      control={control}
                      rules={{
                        validate: () => {
                          const summary = getValues('car_health.summary');
                          if (summary) {
                            const valid = summary?.length <= 1000 ? true : false;
                            return valid || 'Format Summary max 1000 characters';
                          }
                          return true;
                        },
                      }}
                      render={({ field: { ref, onChange, ...rest }, fieldState: { error } }) => (
                        <EditableInputField
                          labelWidth={2}
                          colWidth={10}
                          title={'Summary'}
                          titleLeft={true}
                          inputType={'textarea'}
                          disabled={disabledForm}
                          invalid={error ? true : false}
                          errorMessage={error?.message ?? ''}
                          disabledFormText={false}
                          onChange={(e) => {
                            setValue('car_health.summary', e.target.value);
                            if (e.target.value.length > 1000) {
                              setError(`car_health.summary`, {
                                message: 'Format Summary max 1000 characters',
                              });
                            }
                            if (e.target.value.length <= 1000) {
                              clearErrors(`car_health.summary`);
                            }
                          }}
                          formTextLeft={
                            'Silahkan isi rangkuman hasil inspeksi Anda di kolom ini. Jika ada kondisi tambahan selain 14 titik inspeksi, silahkan ditulis juga.'
                          }
                          ref={register}
                          {...rest}
                        />
                      )}
                    />
                  )}
                  {!isUpdate && (
                    <Controller
                      name={`car_health.summary`}
                      control={control}
                      rules={{
                        validate: () => {
                          const summary = getValues('car_health.summary');
                          if (summary) {
                            const valid = summary?.length <= 1000 ? true : false;
                            return valid || 'Format Summary max 1000 characters';
                          }
                          return true;
                        },
                      }}
                      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                        <EditableInputField
                          className="label-inspections-car-health"
                          labelWidth={2}
                          colWidth={10}
                          title={'Summary'}
                          titleLeft
                          inputType={'textarea'}
                          placeholder={'Tidak ada summary'}
                          disabled={true}
                          invalid={error ? true : false}
                          errorMessage={error?.message ?? ''}
                          {...rest}
                        />
                      )}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="text-center">
                  {isUpdate && allowUpdate && (
                    <Button
                      color="primary"
                      className="w-25"
                      onClick={handleSubmit(submitForm, onError)}
                      disabled={disabledForm}
                    >
                      Simpan
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
      {(getValues(`car_health.inspections`)?.length == 0 || !isUpdate || !allowUpdate) && (
        <div>
          <Row className="mt-3">
            <Col className="text-center">
              <Button color="success" className="w-25" onClick={onUpdateCarHealth}>
                Back
              </Button>
            </Col>
          </Row>
        </div>
      )}
      <Row className="mt-3">
        <Col>{submitStatus && <div className={`alert alert-${submitStatus?.color}`}>{submitStatus?.message}</div>}</Col>
      </Row>
      <EditModal
        modal={confirmModal}
        isCancel={false}
        headerTitle={`${modalType} Hasil Inspeksi?`}
        toggle={() => setConfirmModal(!confirmModal)}
        onConfirm={() => updateCarHealth()}
      >
        {modalType} hasil inspeksi <b>Booking Code {watchBooking?.code}</b>?
      </EditModal>
    </React.Fragment>
  );
};

export default DetailCarHealth;
