import React, { useState } from 'react';
import API from '@utils/API';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Button, FormGroup, Label, Input } from 'reactstrap';

const CreateCarBrandModal = (props) => {
  const api = new API('v2');
  const { modal, onSuccess, toggle } = props;
  const [brandName, setBrandName] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = () => {
    setDisabledButton(true);
    const params = {
      brand_name: brandName,
    };
    api
      .post(`v2/intools/brands/`, params)
      .then((response) => {
        setDisabledButton(false);
        setBrandName('');
        setErrorMessage('');
        onSuccess(response?.data?.data);
      })
      .catch((error) => {
        setErrorMessage('Gagal create brand mobil baru.');
        setDisabledButton(false);
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
      });
  };

  const toggleModal = () => {
    setBrandName('');
    toggle('close');
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={modal} toggle={() => toggleModal()}>
      <ModalHeader>Tambah Brand Mobil</ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Label sm={4} className="required">
            Nama brand
          </Label>
          <Col sm={8}>
            <Input type="text" value={brandName} onChange={(e) => setBrandName(e.target.value)} required />
            {brandName == '' && <div className="pt-1 text-danger">Nama brand tidak boleh kosong</div>}
          </Col>
        </FormGroup>

        {errorMessage && <div className={`alert alert-danger`}>{errorMessage}</div>}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" size="lg" disable={disabledButton} onClick={() => toggleModal()}>
          Tutup
        </Button>{' '}
        <Button color="primary" size="lg" disabled={brandName == '' || disabledButton} onClick={handleSubmit}>
          Tambah
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateCarBrandModal;
