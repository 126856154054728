import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import Loading from '@components/loading/Loading';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import { Col, Button, Card, CardBody } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import UploadCSVOtoprotectWarrantyModal from '@components/modal/UploadCSVOtoprotectWarrantyModal';
import { map } from 'lodash';

function Customer() {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [dataExport, setDataExport] = useState([]);
  const [disabledExport, setDisabledExport] = useState(true);
  const [toggleImportCSV, setToggleImportCSV] = useState(false);
  const [loadingUploadCSV, setLoadingUploadCSV] = useState(false);
  const [notification, setNotification] = useState({});
  const [hasNotification, setHasNotification] = useState(false);
  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);
  const keys = ['query'];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });

  const allowPermission = (menu, submenu) => {
    return !!(generatePermission(menu, submenu) || generatePermission(menu, 'all'));
  };

  const allowList = allowPermission('customer', 'all') || allowPermission('customer', 'list');
  const allowFilter = allowPermission('customer', 'all') || allowPermission('customer', 'search');
  const allowShow = allowPermission('customer', 'all') || allowPermission('customer', 'detail');
  const allowCreate = allowPermission('customer', 'all') || allowPermission('customer', 'create');
  const allowExport = allowPermission('customer', 'all') || allowPermission('customer', 'export');
  const allowWarrantyImport = allowPermission('warranty', 'all') || allowPermission('waranty', 'import');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  let dataFilter = [
    {
      id: 'filter_text_query',
      type: 'text',
      name: 'query',
      name_field: 'query',
      value: tempFiltered?.query ?? '',
      placeholder: 'Phone Number / Name / Email',
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'Phone Number',
        selector: 'user.username',
        wrap: true,
        minWidth: '150px',
      },
      {
        name: 'Name',
        selector: 'name',
        wrap: true,
        minWidth: '150px',
      },
      {
        name: 'Email',
        selector: 'email',
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'Current Otopoints',
        cell: (row) => `Rp. ${Helper.formatMoney(row?.total_point?.total_point)}`,
        minWidth: '200px',
      },
    ],
    []
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchCustomers() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/customers/get-customer-by-filter/`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
      handleDataExport(response?.data?.data ?? []);
    } catch (error) {
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleDataExport([]);
    }
  }

  const getParamData = () => {
    let params = {
      query: filtered?.query ?? '',
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const handleDataExport = (data) => {
    let arrDataExport = [];

    data.forEach((item) => {
      const objDataExport = {
        phone: item?.user?.username ?? '-',
        customer: item?.name ?? '-',
        email: item?.email,
      };

      arrDataExport.push(objDataExport);
    });

    setDataExport(arrDataExport);
    setDisabledExport(false);
  };

  const handleDownloadCSV = () => {
    const keys = ['phone', 'customer', 'email'];
    const headers = ['phone', 'customer name', 'email'];

    Helper.downloadCSV(dataExport, headers, keys);
  };

  const handleToggleImportCSV = () => setToggleImportCSV(!toggleImportCSV);

  const handleSubmitOtoprotectWarrantyCSVUpload = async (formData) => {
    setLoadingUploadCSV(true);

    const dataForm = new FormData();
    dataForm.append('file', formData?.document_file);
    dataForm.append('preview', true);

    await api
      .post('v2/intools/warranty/import', dataForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setLoadingUploadCSV(false);
        const data = res.data.data;

        if (data.is_valid_data) handleToggleImportCSV();

        let errDesc = '';
        let message = '';
        const contentRes = data?.content;

        map(contentRes?.descriptions, (x) => {
          errDesc += `Error Row ${x?.row}: ${x?.note}<br/>`;
        });
        if (data.is_valid_data) {
          message = `<span>${data.is_valid_data ? 'Successfully' : 'Failed to'} upload CSV<br/><br/>Failed Row: ${
            contentRes?.fail
          }, Success Row: ${contentRes?.success}.<br/><br/>Description: <br/>${errDesc}</span>`;
        } else {
          message = `<span>Failed to upload CSV<br/>Please re-check the CSV files.</span>`;
        }

        handleResultNotification({
          status: true,
          type: data.is_valid_data ? 'success' : 'fail',
          message,
        });
      })
      .catch((err) => {
        setLoadingUploadCSV(false);

        handleResultNotification({
          status: true,
          type: 'fail',
          message: `Gagal Upload CSV`,
        });
      });
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleResetFilters = () => {
    tempFiltered = {};
    resetDataFilter();
  };

  const resetDataFilter = () => {
    const updatedDataFilter = dataFilter.map((filter) => ({
      ...filter,
      value: typeof filter?.value !== '' ? '' : filter?.value,
    }));
    setFilters(updatedDataFilter);
    setFiltered({});
    tempFiltered = {};
  };

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
      query: e?.query ?? '',
    };
    console.log(`setfiltered`, e);
    setFiltered(tempVal);
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodfetchCustomers();
  };

  const handleMethodfetchCustomers = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchCustomers();
    }
  };

  const resetFilter = () => {
    setResetSorting(true);
    setSortingData({});
  };

  const createCustomer = () => {
    if (allowCreate) history.push(`/customer/create`);
  };

  const openCustomerDetail = (row) => {
    if (allowShow) {
      history.push(`/customer/${row.id}`);
    }
  };

  const handleResultNotification = async (notification) => {
    const { status, type, message } = notification;
    if (status) {
      setNotification({
        type: type,
        message: message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    setHasNotification(status);
    setTimeout(() => {
      setHasNotification(false);
      setNotification({});
      return status;
    }, 10000);
  };

  const handleCloseNotification = () => {
    setHasNotification(false);
    setNotification({});
  };

  useEffect(() => {
    setFiltered({ ...tempFiltered });
  }, Object.values(tempFiltered));

  useEffect(() => {
    if (filtered) {
      let tempSetFiltered = { ...filtered };
      tempSetFiltered = {
        ...filtered,
      };
      const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
      history.push(`/customer/?${urlParam}`);
      fetchCustomers();
    }
    //resetBookings();
  }, [filtered]);

  useEffect(() => {
    if (page && pageLimit) {
      if (allowList) fetchCustomers();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    resetFilter();
  }, [filtered]);

  useEffect(() => {
    setFilters(dataFilter);
  }, []);

  return (
    <Wrapper
      title="List Customer"
      className="wrapperContent"
      showNotification={hasNotification}
      notification={notification}
      onCloseNotification={handleCloseNotification}
    >
      <Card className="p-3">
        <CardBody className="p-0">
          <Col sm={12} md={12} className="p-0 mt-0 section-action">
            {allowCreate && (
              <Button className="button-action add w-170" onClick={createCustomer}>
                {'Create Customer'}
              </Button>
            )}
            {allowList && allowExport && (
              <Button
                disabled={disabledExport || loading}
                className="button-action primary"
                onClick={handleDownloadCSV}
              >
                {'Export CSV'}
              </Button>
            )}
            {allowWarrantyImport && (
              <>
                <Button
                  disabled={loading}
                  className="button-action primary"
                  onClick={handleToggleImportCSV}
                  data-tooltip-id="upload_csv_otoprotect"
                  data-tooltip-content="Fitur ini digunakan untuk mendaftarkan customer OtoProtect."
                >
                  Import CSV
                </Button>
                <Tooltip id="upload_csv_otoprotect" />
              </>
            )}
          </Col>
          {filters?.length !== 0 && allowFilter && (
            <Col sm={12} md={12} className="p-0 mt-2">
              <ActionFilterCustom
                filters={filters}
                loading={loading}
                onApplyFilters={(e) => handleSetFiltered(e)}
                onResetFilters={handleResetFilters}
              ></ActionFilterCustom>
            </Col>
          )}
          <Col sm={12} md={12} className="p-0 mt-2">
            <DataTable
              persistTableHead
              highlightOnHover
              noHeader={true}
              progressPending={loading}
              data={data}
              columns={allowList ? columns : []}
              className="table dt-responsive table-custom"
              noDataComponent={noDataComponent}
              progressComponent={<Loading />}
              customStyles={customStylesHeaderTable}
              onRowClicked={(row) => openCustomerDetail(row)}
            />
          </Col>
          <Col sm={12} md={12} className="p-0 mt-2">
            {data?.length >= 1 && !loading && (
              <CustomPagination
                page={page}
                pageLimit={pageLimit}
                totalRows={data?.length ?? 0}
                totalAllRows={totalRows}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                handleChangeRowPerPage={handleChangeRowPerPage}
              ></CustomPagination>
            )}
          </Col>
        </CardBody>
      </Card>

      <UploadCSVOtoprotectWarrantyModal
        isOpen={toggleImportCSV}
        toggle={handleToggleImportCSV}
        onSubmit={handleSubmitOtoprotectWarrantyCSVUpload}
        loading={loadingUploadCSV}
      />
    </Wrapper>
  );
}

export default Customer;
