import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Label } from 'reactstrap';
import API from '@utils/API';
import map from 'lodash/map';
import Helper from '@helpers/Helper';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import { optionsType } from '@utils/Constants';
import InputTagField from '@components/field/InputTagField';

const ProductAdd = (props) => {
  const { modal, toggle, onSubmit } = props;

  const api = new API('v2');
  const [brand, setBrand] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState('');
  const [flagshipCategory, setFlagshipCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [component, setComponent] = useState();
  const [errorBrand, setErrorBrand] = useState('');
  const [productName, setProductName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [alias, setAlias] = useState('');
  const [productType, setProductType] = useState();
  const [productPrice, setProductPrice] = useState();
  const [brandOptions, setBrandOptions] = useState([]);
  const [errorCategory, setErrorCategory] = useState('');
  const [errorSubCategory, setErrorSubCategory] = useState('');
  const [disableBrand, setDisableBrand] = useState(false);
  const [errorComponent, setErrorComponent] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [flagshipCategoryOptions, setFlagshipCategoryOptions] = useState([]);
  const [componentOptions, setComponentOptions] = useState([]);
  const [errorProductName, setErrorProductName] = useState('');
  const [errorDisplayName, setErrorDisplayName] = useState('');
  const [errorProductType, setErrorProductType] = useState('');
  const [errorProductPrice, setErrorProductPrice] = useState('');
  const [loadingFetchBrand, setLoadingFetchBrand] = useState(false);
  const [loadingFetchCategory, setLoadingFetchCategory] = useState(false);
  const [loadingFetchSubCategory, setLoadingFetchSubCategory] = useState(false);
  const [loadingFetchFlagshipCategory, setLoadingFetchFlagshipCategory] = useState(false);
  const [loadingFetchComponent, setLoadingFetchComponent] = useState(false);

  async function fetchBrandOptions() {
    setLoadingFetchBrand(true);
    try {
      const response = await api.get(`v2/intools/master/product-brands/all/?sort=name&order=asc`);
      const optionsBrand = map(response?.data?.data, (item) => {
        return { value: item?.brand_id, label: item?.brand_name ?? '-' };
      });
      setBrandOptions(optionsBrand);
      setLoadingFetchBrand(false);
    } catch (error) {
      setBrandOptions([]);
      setLoadingFetchBrand(false);
    }
  }

  async function fetchComponentOptions() {
    setLoadingFetchComponent(true);
    try {
      const response = await api.get(`v2/intools/packages/components`);
      const optionsComponent = map(response?.data?.data?.components, (item) => {
        return { value: item?.id, label: item?.name ?? '-' };
      });
      setComponentOptions(optionsComponent);
      setLoadingFetchComponent(false);
    } catch (error) {
      setComponentOptions([]);
      setLoadingFetchComponent(false);
    }
  }

  async function fetchCategoryOptions() {
    setLoadingFetchCategory(true);
    try {
      const response = await api.get(`v2/intools/products/categories/`);
      const data = response?.data?.data?.categories ?? [];
      let optionsCategory = [];
      data?.length > 0 &&
        map(data, (item) => {
          if (item.id !== '-') optionsCategory.push({ value: item?.id, label: item?.name ?? '-' });
        });

      setCategoryOptions(optionsCategory);
      setLoadingFetchCategory(false);
    } catch (error) {
      setCategoryOptions([]);
      setLoadingFetchCategory(false);
    }
  }

  async function fetchFlagshipCategoryOptions() {
    setLoadingFetchFlagshipCategory(true);
    try {
      const response = await api.get(`v2/intools/master/flagship-report-categories/`);
      const data = response?.data?.data ?? [];
      let optionsCategory = [];
      data?.length > 0 &&
        map(data, (item) => {
          if (item.id !== '-') optionsCategory.push({ value: item?.id, label: item?.name ?? '-' });
        });

      setFlagshipCategoryOptions(optionsCategory);
      setLoadingFetchFlagshipCategory(false);
    } catch (error) {
      setFlagshipCategoryOptions([]);
      setLoadingFetchFlagshipCategory(false);
    }
  }

  async function fetchSubCategoryOptions(categoryId) {
    setLoadingFetchSubCategory(true);
    try {
      const response = await api.get(`v2/intools/master/product-sub-categories/?product_category_id=${categoryId}`);
      const data = response?.data?.data ?? [];
      let optionsCategory = [];
      data?.length > 0 &&
        map(data, (item) => {
          if (item.id !== '-') optionsCategory.push({ value: item?.id, label: item?.name ?? '-' });
        });

      setSubCategoryOptions(optionsCategory);
      setLoadingFetchSubCategory(false);
    } catch (error) {
      setSubCategoryOptions([]);
      setLoadingFetchSubCategory(false);
    }
  }

  const handleChangeAlias = (e) => {
    setAlias(e);
  };

  const handleChangeBrand = (e) => {
    const valueBrand = e?.value ? e : null;
    validateBrand(valueBrand);
  };

  const handleChangeComponent = (e) => {
    const valueComponent = e?.value ? e : null;
    validateComponent(valueComponent);
  };

  const handleChangeCategory = (e) => {
    const valueCategory = e?.value ? e : null;
    validateCategory(valueCategory);
  };

  const handleChangeSubCategory = (e) => {
    const valueSubCategory = e?.value ? e : null;
    validateSubCategory(valueSubCategory);
  };

  const handleChangeFlagshipCategory = (e) => {
    const value = e?.value ? e : null;
    setFlagshipCategory(value);
  };

  const handleChangeProductType = (e) => {
    const otoklixBrand = {
      value: 62,
      label: 'Otoklix',
    };
    const valueProductType = e?.value ? e : null;
    setDisableBrand(e?.value == 'work' ? true : false);
    handleChangeBrand(e?.value == 'work' ? otoklixBrand : null);
    validateProductType(valueProductType);
  };

  const handleChangeProductName = (e) => {
    const valueProductName = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : productName;
    validateProductName(valueProductName);
  };

  const handleChangeDisplayName = (e) => {
    const valueDisplayName = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : displayName;
    validateDisplayName(valueDisplayName);
  };

  const handleChangeProductPrice = (e) => {
    const valueProductPrice = Helper.formatMoneyOnField(e.target.value, 'Rp ');
    validateProductPrice(valueProductPrice);
  };

  const validateBrand = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorBrand('Pilih Brand terlebih dahulu');
    } else {
      setErrorBrand('');
    }

    setBrand(value);
    return passValidate;
  };

  const validateComponent = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorComponent('Pilih Komponen terlebih dahulu');
    } else {
      setErrorComponent('');
    }

    setComponent(value);
    return passValidate;
  };

  const validateCategory = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorCategory('Pilih Kategori terlebih dahulu');
    } else {
      setErrorCategory('');
    }

    setCategory(value);
    return passValidate;
  };

  const validateSubCategory = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorSubCategory('Pilih Sub Kategori terlebih dahulu');
    } else {
      setErrorSubCategory('');
    }

    setSubCategory(value);
    return passValidate;
  };

  const validateProductType = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorProductType('Pilih Tipe terlebih dahulu');
    } else {
      setErrorProductType('');
    }

    setProductType(value);
    return passValidate;
  };

  const validateProductName = (value) => {
    let passValidate = true;
    if (value.length == 0) {
      passValidate = false;
      setErrorProductName('Nama Produk tidak boleh kosong');
    } else if (value.length < 4 || value.length > 100) {
      passValidate = false;
      setErrorProductName('Format Nama Produk minimal 4, maksimal 100 karakter');
    } else {
      setErrorProductName('');
    }

    setProductName(value);
    return passValidate;
  };

  const validateDisplayName = (value) => {
    let passValidate = true;
    if (value.length == 0) {
      passValidate = false;
      setErrorDisplayName('Display Name tidak boleh kosong');
    } else if (value.length < 4 || value.length > 100) {
      passValidate = false;
      setErrorDisplayName('Format Display Name minimal 4, maksimal 100 karakter');
    } else {
      setErrorDisplayName('');
    }

    setDisplayName(value);
    return passValidate;
  };

  const validateProductPrice = (value) => {
    let passValidate = true;
    if (value?.formatMoney > 99999999) {
      passValidate = false;
      setErrorProductPrice('Harga Produk maksimal Rp 99.999.999');
    } else {
      setErrorProductPrice('');
    }

    setProductPrice(value);
    return passValidate;
  };

  const handleSubmit = () => {
    const passValidateBrand = validateBrand(brand);
    const passValidateCategory = validateCategory(category);
    const passValidateSubCategory = validateSubCategory(subCategory);
    const passValidateComponent = validateComponent(component);
    const passValidateProductName = validateProductName(productName);
    const passValidateDisplayName = validateDisplayName(displayName);
    const passValidateProductType = validateProductType(productType);
    const passValidateProductPrice = validateProductPrice(productPrice);

    if (
      passValidateBrand &&
      passValidateCategory &&
      passValidateSubCategory &&
      passValidateComponent &&
      passValidateProductName &&
      passValidateDisplayName &&
      passValidateProductType &&
      passValidateProductPrice
    ) {
      submitForm();
    }
  };

  async function submitForm() {
    toggle();
    setLoading(true);

    try {
      const payload = {
        product_brand_id: brand?.value ? `${brand?.value}` : null,
        product_category_id: category?.value ? `${category?.value}` : null,
        product_subcategory_id: subCategory?.value ? `${subCategory?.value}` : null,
        flagship_report_category_id: flagshipCategory?.value ? `${flagshipCategory?.value}` : null,
        product_component_id: component?.value ? `${component?.value}` : null,
        product_name: productName ?? null,
        product_display_name: displayName ?? null,
        product_type: productType?.value ?? null,
        product_price: productPrice?.formatMoney ?? 0,
        alias: alias ?? null,
      };
      await api.post(`v2/intools/master/products/`, payload);
      onSubmit(true);
      setLoading(false);
    } catch (error) {
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  const resetData = () => {
    setBrand();
    setCategory();
    setSubCategory();
    setFlagshipCategory();
    setComponent();
    setProductName('');
    setDisplayName('');
    setAlias([]);
    setProductType();
    setProductPrice();
    setErrorBrand('');
    setErrorCategory('');
    setErrorSubCategory('');
    setErrorComponent('');
    setErrorProductName('');
    setErrorDisplayName('');
    setErrorProductType('');
    setErrorProductPrice('');
    setBrandOptions([]);
    setCategoryOptions([]);
    setSubCategoryOptions([]);
    setFlagshipCategoryOptions([]);
    setComponentOptions([]);
  };

  const cancel = () => {
    toggle();
  };

  useEffect(() => {
    resetData();
    if (modal) {
      fetchBrandOptions();
      fetchCategoryOptions();
      fetchComponentOptions();
      fetchFlagshipCategoryOptions();
      setProductPrice(Helper.formatMoneyOnField('' + 0, 'Rp '));
    }
  }, [modal]);

  useEffect(() => {
    if (category?.value) fetchSubCategoryOptions(category?.value);
  }, [category]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Tambah Data Produk</div>
        <div className="content">
          <FormGroup row>
            <Label lg={4} className="label-required">
              Tipe
            </Label>
            <SelectField
              colWidth={8}
              name="type"
              placeholder="Pilih Tipe"
              closeMenuOnSelect={true}
              value={productType}
              options={optionsType}
              disabled={loading}
              errorMessage={errorProductType}
              onChange={handleChangeProductType}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4} className="label-required">
              Brand
            </Label>
            <SelectField
              colWidth={8}
              name="brand"
              placeholder="Pilih Brand"
              closeMenuOnSelect={true}
              value={brand}
              options={brandOptions}
              disabled={loading || disableBrand}
              isLoading={loadingFetchBrand}
              errorMessage={errorBrand}
              onChange={handleChangeBrand}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4} className="label-required">
              Nama Produk
            </Label>
            <InputField
              colWidth={8}
              inputType={'text'}
              disabled={loading}
              placeholder="Nama Produk"
              value={productName ?? ''}
              invalid={errorProductName ? true : false}
              errorMessage={errorProductName ?? ''}
              onChange={handleChangeProductName}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4} className="label-required">
              Display Name
            </Label>
            <InputField
              colWidth={8}
              inputType={'text'}
              disabled={loading}
              placeholder="Display name"
              value={displayName ?? ''}
              invalid={errorDisplayName ? true : false}
              errorMessage={errorDisplayName ?? ''}
              onChange={handleChangeDisplayName}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4}>Nama Alternatif (Alias)</Label>
            <InputTagField
              colWidth={8}
              name="alias"
              placeholder="Masukkan kata kunci lalu klik `Enter`"
              value={alias ?? []}
              allowDuplication={true}
              disabled={loading}
              onChange={handleChangeAlias}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4} className="label-required">
              Komponen
            </Label>
            <SelectField
              colWidth={8}
              name="component"
              placeholder="Pilih Komponen"
              closeMenuOnSelect={true}
              value={component}
              options={componentOptions}
              disabled={loading}
              isLoading={loadingFetchComponent}
              errorMessage={errorComponent}
              onChange={handleChangeComponent}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4} className="label-required">
              Kategori
            </Label>
            <SelectField
              colWidth={8}
              name="category"
              placeholder="Pilih Kategori"
              closeMenuOnSelect={true}
              value={category}
              options={categoryOptions}
              disabled={loading}
              isLoading={loadingFetchCategory}
              errorMessage={errorCategory}
              onChange={handleChangeCategory}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4} className="label-required">
              Sub Kategori
            </Label>
            <SelectField
              colWidth={8}
              name="subcategory"
              placeholder="Pilih Sub Kategori"
              closeMenuOnSelect={true}
              value={subCategory}
              options={subCategoryOptions}
              disabled={loading}
              isLoading={loadingFetchSubCategory}
              errorMessage={errorSubCategory}
              onChange={handleChangeSubCategory}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4}>Flagship Report Category</Label>
            <SelectField
              colWidth={8}
              name="flagshipcategory"
              placeholder="Pilih Flagship Report Category"
              closeMenuOnSelect={true}
              value={flagshipCategory}
              options={flagshipCategoryOptions}
              disabled={loading}
              isLoading={loadingFetchFlagshipCategory}
              onChange={handleChangeFlagshipCategory}
              isClearable
            />
          </FormGroup>
          {/* <FormGroup row className="mb-0">
            <Label lg={4}>Harga Produk</Label>
            <InputField
              colWidth={8}
              inputType={'text'}
              disabled={loading}
              placeholder="Sell Price"
              value={productPrice?.formatMoneyString ?? ''}
              invalid={errorProductPrice ? true : false}
              errorMessage={errorProductPrice ?? ''}
              onChange={handleChangeProductPrice}
            />
          </FormGroup> */}
        </div>

        <div className="action mb-3 mt-3">
          <Button className="button-cancel" onClick={cancel} disabled={loading}>
            Batal
          </Button>
          <Button className="button-action" onClick={handleSubmit} disabled={loading}>
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ProductAdd;
