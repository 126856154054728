import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Spinner } from 'reactstrap';
import API from '@utils/API';
import EditableInputField from '@components/field/EditableInputField';
import { map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import moment from 'moment';

function Notifications() {
  const [recipient, setRecipient] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const api = new API('v2');
  const history = useHistory();

  useEffect(() => {
    loadListNotifications();
  }, []);

  async function loadListNotifications() {
    setIsLoading(true);

    const url = `v2/pub/notifications`;
    const params = {
      params: {
        recipient: recipient,
      },
    };
    try {
      const response = await api.get(url, params);
      setData(response?.data?.data ?? []);
      setIsLoading(false);
    } catch (error) {
      setData([]);
      setIsLoading(false);
    }
  }

  const handleLoadNotifications = () => {
    loadListNotifications();
  };

  // const handleGoToContentPage = (id) => {
  //   // history.push(`/pub/notifications/${id}`);
  //   window.open(`/pub/notifications/${id}`, '_blank');
  // };

  return (
    <Container fluid={true}>
      <React.Fragment>
        <Row className="ml-1 mt-5">
          <EditableInputField
            md={3}
            placeholder={'Input Recipient'}
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
          ></EditableInputField>
          <Button className="ml-2 mb-4" md={3} onClick={() => handleLoadNotifications()}>
            Search
          </Button>
        </Row>

        <Row>
          <Col md={12}>
            <Card>
              <CardHeader className="bg-primary text-white mt-0">{'Notifications List'}</CardHeader>
              <div className="text-center">
                {isLoading && (
                  <Spinner size="lg" className="notif-loader">
                    Loading...
                  </Spinner>
                )}
              </div>
              {!isLoading && (
                <CardBody>
                  {/* <Row md={12}>
                    <Col className="border" md={1}>
                      <h4 className="text-bold text-center">ID</h4>
                    </Col>
                    <Col className="border" md={6}>
                      <h4 className="text-bold text-center">HEADER</h4>
                    </Col>
                    <Col className="border" md={5}>
                      <h4 className="text-bold text-center">CREATED AT</h4>
                    </Col>
                  </Row> */}
                  {map(data, (item, idx) => {
                    return (
                      <Link to={{ pathname: `/pub/notifications/${item?.id}` }}>
                        <Row className="notif-list cursor-pointer pt-0">
                          <Col className="border" md={1}>
                            <h6>{item?.id ?? ''}</h6>
                          </Col>

                          <Col className="border" md={6}>
                            <h6>{item?.header ?? '-'}</h6>
                          </Col>
                          <Col className="border" md={5}>
                            <h6>
                              {moment(item?.created_at).isValid()
                                ? moment(item?.created_at).format('MMM-D-YYYY HH:mm:ss')
                                : '-'}
                            </h6>
                          </Col>
                        </Row>
                      </Link>
                    );
                  })}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </Container>
  );
}

export default Notifications;
