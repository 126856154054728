import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import API from '@utils/API';
import APIS3 from '@utils/APIS3';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import CreateBasic from '@components/workshops/create/CreateBasic';
import CreateAdvance from '@components/workshops/create/CreateAdvance';

function WorkshopCreate() {
  const api = new API('v2');
  const apiS3 = new APIS3();
  const history = useHistory();
  const { generatePermission } = userPermission();
  const [hasNotif, setHasNotif] = useState(false);
  const [notification, setNotification] = useState({});
  const [disableForm, setDisableForm] = useState(false);
  const [formBasic, setFormBasic] = useState();
  const [formAdvance, setFormAdvance] = useState();
  const [passValidateBasic, setPassValidateBasic] = useState();
  const [passValidateAdvance, setPassValidateAdvance] = useState();
  const [isSave, setIsSave] = useState(false);
  const breadcrumb = [
    {
      id: 1,
      label: 'List Workshop',
      active: false,
      url: '/workshops',
    },
    {
      id: 2,
      label: 'Create Workshop',
      active: true,
      url: '',
    },
  ];

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'all'));
  };

  const allowCreate = allowPermission('all') || allowPermission('create');

  const handleAllowPermissionCreate = () => {
    if (!allowCreate) {
      history.push('/workshops');
    }
  };

  const handleShowNotification = (status, error) => {
    const errorMsg = error ? 'Gagal Create Data Workshop, ' + error : 'Gagal Create Data Workshop';
    handleResultNotification({
      status: true,
      type: status ? 'success' : 'fail',
      message: status ? 'Berhasil Create Data Workshop' : errorMsg,
    });
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const handleAction = (type) => {
    if (type == 'save') {
      setPassValidateBasic();
      setPassValidateAdvance();
      setIsSave(true);
      setTimeout(() => {
        setIsSave(false);
      }, 10);
    } else {
      history.push('/workshops');
    }
  };

  const handleResultValidateBasic = (params) => {
    setPassValidateBasic(params?.is_pass);
    setFormBasic(params?.data_form);
  };

  const handleResultValidateAdvance = (params) => {
    setPassValidateAdvance(params?.is_pass);
    setFormAdvance(params?.data_form);
  };

  const handlePassValidation = () => {
    if (passValidateBasic && passValidateAdvance) {
      setDisableForm(true);
      if (formAdvance?.document_file && formAdvance?.document_name) {
        handlePresignedUploadFile();
      } else {
        handleGetPayload();
      }
    }
    if (passValidateBasic == false || passValidateAdvance == false) {
      handleResultNotification({
        status: true,
        type: 'fail',
        message: 'Mohon lengkapi form',
      });
    }
  };

  const handlePresignedUploadFile = () => {
    const paramsPresignedUpload = {
      namespace: 'workshop',
      identifier: moment().format('HHmmss'),
      file_name: formAdvance?.document_name,
    };
    api
      .post(`v2/intools/presigned-upload/`, paramsPresignedUpload)
      .then((response) => {
        const urlUpload = response?.data?.data?.presigned_url ?? '';
        const keyFile = response?.data?.data?.key ?? '';
        if (urlUpload !== '') {
          handleUploadFile(urlUpload, keyFile);
        } else {
          setDisableForm(false);
          handleShowNotification(false, 'Fail get url upload file');
        }
      })
      .catch((e) => {
        const message = `API Error : ${e?.response?.data?.error?.message}`;
        setDisableForm(false);
        handleShowNotification(false, message);
      });
  };

  const handleUploadFile = (url, keyFile) => {
    apiS3
      .put(url, formAdvance?.document_file)
      .then((response) => {
        handleGetPayload(keyFile);
      })
      .catch((e) => {
        setDisableForm(false);
        handleShowNotification(false, 'Fail upload file');
      });
  };

  const handleGetPayload = (keyFile) => {
    let facilities_selected = [];
    let capabilities_selected = [];
    const specialities_selected = formAdvance?.speciality?.value
      ? [{ speciality_id: formAdvance?.speciality?.value }]
      : [];

    formAdvance?.facilities.map((item) => {
      if (item?.is_available) {
        facilities_selected.push({ facility_id: item.value });
      }
    });

    formAdvance?.capabilities.map((item) => {
      if (item?.is_available) {
        capabilities_selected.push({ capability_id: item.value });
      }
    });

    const stakeholders = [
      {
        name: formBasic?.owner_name ?? '',
        mobile_no: formBasic?.owner_phone ?? '',
        email: formAdvance?.owner_email ?? null,
        role: 1,
      },
      {
        name: formAdvance?.pic_name ?? '',
        mobile_no: formAdvance?.pic_phone ?? '',
        email: null,
        role: 2,
      },
    ];

    let payload = {
      name: formBasic?.name ?? '',
      phone: formBasic?.telp ?? '',
      postal_code: formBasic?.post_code ?? '',
      province_id: formBasic?.province?.value ?? null,
      city_id: formBasic?.city?.value ?? null,
      district_id: formBasic?.districts?.value ?? null,
      subdistricts: formBasic?.sub_districts ?? '',
      gmaps_link: formBasic?.gmaps_link ?? '',
      latitude: formBasic?.latitude ?? '',
      longitude: formBasic?.longitude ?? '',
      street_address: formBasic?.address ?? '',
      is_otoklix_pickup: formBasic?.is_otoklix_pickup ? true : false,
      workshop_type: formBasic?.type ?? '',
      email: formAdvance?.workshop_email ?? '',
      wa_group_link: formAdvance?.wa_group ?? '',
      specialities: specialities_selected,
      service_warranty: formAdvance?.is_service_warranty,
      is_fbo: formAdvance?.is_fbo,
      is_franchise: formAdvance?.is_franchise,
      status_workshop: formAdvance?.status ?? null,
      tier: formAdvance?.tier?.value ?? null,
      status_mou: formAdvance?.status_mou?.value ?? null,
      facilities: facilities_selected,
      capabilities: capabilities_selected,
      description: formAdvance?.description ?? '',
      otoklix_pic: formAdvance?.pic_otoklix ?? '',
      stakeholders: stakeholders,
    };

    if (keyFile) {
      payload.document_key = keyFile;
    }

    submitNewWorkshop(payload);
  };

  async function submitNewWorkshop(payload) {
    try {
      const response = await api.post(`v2/intools/workshops/`, payload);
      handleShowNotification(true);
      setTimeout(() => {
        if (response?.data?.data?.id) {
          const idWorkshop = response?.data?.data?.id;
          history.push(`/workshops/${idWorkshop}`);
        } else {
          history.push(`/workshops`);
        }
      }, 1500);
    } catch (error) {
      setDisableForm(false);
      handleShowNotification(false, error?.response?.data?.error?.message ?? '');
    }
  }

  useEffect(() => {
    handlePassValidation();
  }, [passValidateBasic, passValidateAdvance]);

  useEffect(() => {
    handleAllowPermissionCreate();
  }, []);

  return (
    <React.Fragment>
      {!allowCreate && <Wrapper title="Create Workshop" className="wrapperContent"></Wrapper>}
      {allowCreate && (
        <Wrapper
          title="Create Workshop"
          className="wrapperContent"
          showNotification={hasNotif}
          notification={notification}
          breadcrumbData={breadcrumb}
          onCloseNotification={handleCloseNotification}
        >
          <CreateBasic
            isSave={isSave}
            disableForm={disableForm}
            onResultValidate={handleResultValidateBasic}
          ></CreateBasic>
          <CreateAdvance
            isSave={isSave}
            disableForm={disableForm}
            onResultValidate={handleResultValidateAdvance}
          ></CreateAdvance>
          <Row>
            <Col sm={12} md={12} className="section-action">
              <Button
                disabled={disableForm}
                className="button-action save"
                onClick={() => {
                  if (!disableForm) {
                    handleAction('save');
                  }
                }}
              >
                {'Simpan'}
              </Button>
              <Button
                disabled={disableForm}
                className="button-action cancel"
                onClick={() => {
                  if (!disableForm) {
                    handleAction('cancel');
                  }
                }}
              >
                {'Batal'}
              </Button>
            </Col>
          </Row>
        </Wrapper>
      )}
    </React.Fragment>
  );
}

export default WorkshopCreate;
