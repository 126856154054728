import React, { useState } from 'react';
import { FormGroup, Label, Row } from 'reactstrap';
import FolderIcon from '@assets/icons/folder.svg';
import Dropzone from 'react-dropzone';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import TrashIcon from '@assets/icons/trash.svg';
import API from '@utils/API';

const PromoBanner = (props) => {
  const api = new API('v2');
  const { disableBrowse, disableGlobal, promoCode, image, onUpload, onDelete, setErrorUpload, errorUpload } = props;
  const [showLightbox, setShowLightbox] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [processUpload, setProcessUpload] = useState(false);
  const [disabledUplaod, setDisabledUplaod] = useState(false);

  const uploadImage = (data) => {
    const file = data[0];
    const maxSize = 10240;
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const nameFileSplit = file?.name ? file?.name.split('.') : [];
    const type = nameFileSplit[nameFileSplit.length - 1].toLowerCase();

    if (nameFileSplit.length == 0) {
      setErrorUpload('Format file tidak diketahui');
    } else {
      if (type == 'jpeg' || type == 'jpg' || type == 'png') {
        if (fileSize == null) {
          setErrorUpload('Ukuran file tidak diketahui');
        } else if (fileSize > maxSize) {
          setErrorUpload('Ukuran file maksimal 10mb');
        } else {
          handleUploadImage(data);
        }
      } else {
        setErrorUpload('File tidak sesuai dengan format');
      }
    }
  };

  const handleUploadImage = (file) => {
    setDisabledUplaod(true);
    setProcessUpload(true);

    if (file[0]?.name) {
      const paramsImagedUpload = new FormData();
      paramsImagedUpload.append('namespace', 'promo');
      paramsImagedUpload.append('identifier', promoCode);
      paramsImagedUpload.append('image', file[0]);

      // return;
      api
        .post(`v2/intools/image-upload/`, paramsImagedUpload)
        .then((response) => {
          const keyFile = response?.data?.data?.key ?? '';
          if (keyFile !== '') {
            onUpload(file, keyFile);
            showPreviewImage(file);
          } else {
            setErrorUpload('Gagal upload image');
          }
          setDisabledUplaod(false);
          setProcessUpload(false);
        })
        .catch((e) => {
          const message = `API Error : ${e?.response?.data?.error?.message}`;
          setDisabledUplaod(false);
          setProcessUpload(false);
          setErrorUpload(message);
        });
    } else {
      setDisabledUplaod(false);
      setProcessUpload(false);
      setErrorUpload('Gagal upload image, file tidak diketahui');
    }
  };

  const handleDeleteImage = () => {
    onDelete();
    showPreviewImage();
  };

  const showPreviewImage = (image) => {
    if (image) {
      const bannerPreview = document.getElementById('bannerPromoPreview');
      bannerPreview.src = URL.createObjectURL(image[0]);
      bannerPreview.onload = function () {
        URL.revokeObjectURL(bannerPreview.src);
      };
    } else {
      const bannerPreview = document.getElementById('bannerPromoPreview');
      bannerPreview.src = '';
    }
  };

  return (
    <div>
      {showLightbox && <Lightbox mainSrc={image} onCloseRequest={() => setShowLightbox(false)} />}
      <FormGroup row>
        <Label lg={2} className="text-left mr-4">
          Promo Banner
        </Label>
        {!image && disableBrowse && (
          <Label lg={8} className="text-left text-bold ml-1">
            : <span className="ml-1">{'-'}</span>
          </Label>
        )}
        <Row className="d-flex flex-column ml-1">
          {!image && !disableBrowse && (
            <Dropzone
              onDrop={(file) => {
                uploadImage(file);
                setIsDragOver(false);
              }}
              onDragOver={() => setIsDragOver(true)}
              onDragLeave={() => setIsDragOver(false)}
              disabled={disabledUplaod || disableGlobal}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="gallery-container-workshop ml-2">
                  <section>
                    {!processUpload && (
                      <div>
                        {isDragOver && (
                          <div {...getRootProps({ className: 'box-upload drag-over p-3' })}>
                            <img src={FolderIcon}></img>
                            <div>
                              <div className="title mt-3">Release File</div>
                              <div className="detail mt-2">Maximum file size 10MB.</div>
                              <div className="detail">jpeg, jpg, png</div>
                            </div>
                          </div>
                        )}
                        {!isDragOver && (
                          <div {...getRootProps({ className: 'box-upload p-3' })}>
                            <img src={FolderIcon}></img>
                            <div>
                              <div className="title mt-3">Click or drag file to this area to upload</div>
                              <div className="detail mt-2">Maximum file size 10MB.</div>
                              <div className="detail">jpeg, jpg, png</div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {processUpload && (
                      <div {...getRootProps({ className: 'box-upload p-3' })}>
                        <img src={FolderIcon}></img>
                        <div>
                          <div className="title mt-3">Please wait...</div>
                          <div className="detail mt-2">Maximum file size 10MB.</div>
                          <div className="detail">jpeg, jpg, png</div>
                        </div>
                      </div>
                    )}
                  </section>
                  <div className="invalid-hour">{errorUpload}</div>
                </div>
              )}
            </Dropzone>
          )}
        </Row>

        {image && (
          <div className="box">
            {!disableBrowse && <div className="icon-delete" src={TrashIcon} onClick={() => handleDeleteImage()}></div>}
            <img
              id="bannerPromoPreview"
              src={image}
              height={200}
              className="ml-4"
              onClick={() => setShowLightbox(disableBrowse ?? true)}
            />
          </div>
        )}
      </FormGroup>
    </div>
  );
};

export default PromoBanner;
