import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, FormGroup, Button, Form, Label, Input, FormFeedback, Alert } from 'reactstrap';
import { validate } from 'react-email-validator';
import Logo from '@assets/images/logo.svg';
import API from '@utils/API';
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

function ForgotPassword() {
  const api = new API('v2');

  const [isDisabled, setIsDisabled] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [email, setEmail] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [isEmailFormatInvalid, setIsEmailFormatInvalid] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);

  const handleEmailChange = (event) => {
    const email = event.target.value;
    let emailCheck = validate(email);
    if (emailCheck) {
      setEmailErrorMessage();
      setIsEmailFormatInvalid(false);
      setIsEmailInvalid(false);
    } else if (!email) {
      setEmailErrorMessage('Email cannot be empty');
      setIsEmailFormatInvalid(false);
      setIsEmailInvalid(true);
    } else if (!emailCheck) {
      setEmailErrorMessage('Email is invalid format');
      setIsEmailFormatInvalid(true);
      setIsEmailInvalid(true);
    }

    setEmail(email);
  };

  const validation = () => {
    let status = true;

    if (isEmailFormatInvalid) {
      setEmailErrorMessage('Email is invalid format');
      setIsEmailInvalid(true);
      status = false;
    }
    if (!email) {
      setEmailErrorMessage('Email cannot be empty');
      setIsEmailInvalid(true);
      status = false;
    }

    return status;
  };

  const handleRecover = (e) => {
    e.preventDefault();

    setIsSucceed(false);

    if (!validation()) {
      return;
    }

    setIsDisabled(true);

    const params = {
      email: email,
    };

    if (validateCaptcha(captcha) === true) {
      api
        .post(`v2/intools/users/recovery/`, params)
        .then((res) => {
          setAlertMessage(res?.data?.data);
          setIsSucceed(true);
          setIsDisabled(false);
          setHasError(false);
          setEmail('');
          setCaptcha('');
        })
        .catch((e) => {
          setIsSucceed(false);
          setHasError(true);
          setIsDisabled(false);
          setErrorMessage(e?.response?.data?.error?.message ?? '');
        });
      loadCaptchaEnginge(5);
      setCaptcha('');
    } else {
      setHasError(true);
      setErrorMessage('Captcha does not match');
      setIsDisabled(false);
      setCaptcha('');
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(5);
  }, []);

  return (
    <Container fluid={true}>
      <Row className="vh-100">
        <Col md={8} className="p-0 h-100vh d-flex justify-content-center auth-bg">
          <div className="accountbg d-flex align-items-center">
            <div className="account-title text-center">
              <h4 className="mt-3">{`Let's Rock`}</h4>
              <h1>
                Otoklix <span className="text-warning">Intools</span>{' '}
              </h1>
              <div className="border w-25 mx-auto border-warning" />
            </div>
          </div>
        </Col>
        <Col md={4} className="pr-0">
          <div className="auth-page">
            <Card body className="border-0 mb-0 h-100">
              <div className="mb-5 logo-rv">
                <a href="/" className="logo logo-admin">
                  <span>
                    <img src={Logo} height={40} alt="logo" className="my-3" />
                  </span>
                </a>
              </div>
              <h2 className="font-weight-semibold font-22 mb-2">
                Welcome to <span className="text-primary">InTools</span>.
              </h2>

              {isSucceed && <Alert color="warning">{alertMessage}</Alert>}

              <Form className="form-horizontal auth-form my-4">
                <FormGroup>
                  <Label>Email</Label>
                  <Input value={email} placeholder="Email" invalid={isEmailInvalid} onChange={handleEmailChange} />
                  <FormFeedback>{emailErrorMessage}</FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label>Enter Code</Label>
                  <Input value={captcha} onChange={(e) => setCaptcha(e.target.value)} className="mb-3"></Input>
                  <LoadCanvasTemplateNoReload />
                </FormGroup>

                {hasError && <div className="alert alert-danger">{errorMessage}</div>}
                <FormGroup>
                  <Button block outline size="md" color="primary" disabled={isDisabled} onClick={handleRecover}>
                    Recover
                  </Button>
                </FormGroup>
              </Form>
            </Card>
          </div>
          <div className="footer-auth">
            <hr className="hr-dashed mb-0" />
            <div className="p-4">
              <span className="text-muted d-sm-inline-block float-right">
                Crafted with <i className="mdi mdi-heart text-danger" /> by Otoklix
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
