import React, { useEffect, useState, useMemo } from 'react';
import { Button, Card, CardHeader, CardBody, Row, Col, Label, FormGroup, Spinner, Input, Tooltip } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import Loading from '@components/loading/Loading';
import DataTable from 'react-data-table-component';
import CustomPagination from '@components/pagination/CustomPagination';
import InputField from '@components/field/InputField';
import ActionSwitch from '@components/field/ActionSwitch';
import Helper from '@helpers/Helper';
import NoData from '@components/other/NoData';
import { customStylesHeaderTable } from '@utils/Constants';
import CustomModal from '@components/modal/CustomModal';
import moment from 'moment';
import InfoIcon from '@assets/icons/info.svg';

const InventoryViewDetail = (props) => {
  const { dataInventory, onResultNotification, workshopId } = props;
  const { generatePermission } = userPermission();
  const api = new API('v2');
  const history = useHistory();

  const [dataPayload, setDataPayload] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [resetSorting, setResetSorting] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [editable, setEditable] = useState(false);
  const [takeRate, setTakeRate] = useState(0);
  const [useTakeRate, setUseTakeRate] = useState(false);
  const [tooltipBookedQuantity, setTooltipBookedQuantity] = useState(false);
  const [tooltipPendingQuantity, setTooltipPendingQuantity] = useState(false);
  const toggleBookedQuantity = () => setTooltipBookedQuantity(!tooltipBookedQuantity);
  const togglePendingQuantity = () => setTooltipPendingQuantity(!tooltipPendingQuantity);

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'inventoryedit'));
  };

  const allowPermissionCogs = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'inventorycogsall'));
  };

  const allowPermissionQty = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'inventoryqtyall'));
  };

  const allowPermissionB2b = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'inventoryb2ball'));
  };

  const allowList = allowPermission('inventorylist');
  const allowCogs = allowPermissionCogs('inventorycogsshow');
  const allowEditQty = allowPermissionQty('inventoryqtyedit');
  const allowB2b = allowPermissionB2b('inventoryb2bshow');

  const calculateNettPrice = (value) => {
    const percentageTakeRate = (100 - +takeRate) / 100;
    return Math.round(percentageTakeRate * (+value ?? 0));
  };

  const handleChangeDataPayload = (value, type) => {
    let updatedData = { ...dataPayload };

    if (type === 'sellPrice') {
      if (useTakeRate && +takeRate > 0) {
        const valNettPrice = calculateNettPrice(value?.formatMoney);
        updatedData = {
          ...dataPayload,
          nettPrice: Helper.formatMoneyOnField('' + valNettPrice, 'IDR '),
          sellPrice: value,
        };
      } else {
        updatedData = { ...dataPayload, sellPrice: value };
      }
    }
    if (type === 'nettPrice') updatedData = { ...dataPayload, nettPrice: value };
    if (type === 'basePrice') updatedData = { ...dataPayload, basePrice: value };
    if (type === 'minimumQty') updatedData = { ...dataPayload, minimumQty: Helper.inputNumber(value, 9999) };
    setDataPayload(updatedData);
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };
    onResultNotification(notification);
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const columns = useMemo(
    () => [
      {
        name: 'Timestamp',
        selector: (row) => row?.created_at,
        cell: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm:ss'),
        sortable: true,
        sortField: 'timestamp',
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'Qty',
        selector: (row) => row?.qty,
        cell: (row) => row?.qty,
        sortable: true,
        sortField: 'quantity',
        wrap: true,
        minWidth: '80px',
      },
      {
        name: allowCogs ? 'COGS' : '',
        selector: (row) => row?.cogs,
        cell: (row) => <span className="product-price">{allowCogs ? `Rp. ${Helper.formatMoney(row?.cogs)}` : ''}</span>,
        sortable: true,
        sortField: 'cogs',
        minWidth: allowCogs ? '200px' : '0px',
      },
    ],
    [resetSorting]
  );

  const fetchLogHistory = async () => {
    setLoading(true);
    await api
      .get(
        `v2/intools/workshop-inventory-logs/?workshop_inventory_id=${dataInventory?.inventory_id}&page=${page}&limit=${pageLimit}`
      )
      .then((res) => {
        if (+res?.status === 200) {
          const data = res?.data;
          setDataTable(data?.data);
          setTotalRows(data?.pagination?.total_rows);
          setTotalPages(data?.pagination?.total_page);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
      });
  };

  const handleAddNewProduct = () => {
    const warehouseId = dataInventory?.default_warehouse?.id;
    const url =
      warehouseId !== null && warehouseId !== undefined
        ? `/workshops/${workshopId}/add/inventory?id=${dataInventory?.product_id}&wid=${warehouseId}&consignment=${dataInventory?.is_consignment}`
        : `/workshops/${workshopId}/add/inventory?id=${dataInventory?.product_id}&consignment=${dataInventory?.is_consignment}`;

    history.push(url);
  };

  const handleEditInventory = async () => {
    setLoadingEdit(true);
    setEditable(false);
    const payload = {
      sell_price: dataPayload?.sellPrice?.formatMoney ?? 0,
      nett_price: dataPayload?.nettPrice?.formatMoney ?? 0,
      base_price: dataPayload?.basePrice?.formatMoney ?? 0,
      minimum_qty: dataPayload?.minimumQty,
      use_take_rate: useTakeRate,
    };
    await api
      .put(`v2/intools/workshops/${workshopId}/inventories/${dataInventory?.inventory_id}/`, payload)
      .then((res) => {
        if (+res?.status === 200) {
          handleResultNotification(true, 'success', 'Success Edit Data');
          setLoadingEdit(false);
          setModalConfirm(false);
        }
      })
      .catch((err) => {
        console.log('err', err);
        handleResultNotification(true, 'fail', 'Failed Edit Data !');
        setLoadingEdit(false);
        setModalConfirm(false);
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }, 1000);
      });
  };

  const confirmEditInventory = () => {
    setModalConfirm(true);
  };

  const setDataInventory = () => {
    const defaultDataPayload = {
      sellPrice: Helper.formatMoneyOnField('' + dataInventory?.sell_price, 'IDR '),
      nettPrice: Helper.formatMoneyOnField('' + dataInventory?.nett_price, 'IDR '),
      basePrice: Helper.formatMoneyOnField('' + dataInventory?.base_price, 'IDR '),
      minimumQty: dataInventory?.minimum_qty,
      takeRate: dataInventory?.take_rate,
    };
    setDataPayload(defaultDataPayload);
    setTakeRate(dataInventory?.take_rate);
    setUseTakeRate(dataInventory?.use_take_rate);
  };

  const handleCancelEdit = () => {
    setEditable(false);
    setDataInventory();
  };

  useEffect(() => {
    if (useTakeRate) {
      const valNettPrice = calculateNettPrice(dataPayload?.sellPrice?.formatMoney);
      const tempDataPayload = { ...dataPayload, nettPrice: Helper.formatMoneyOnField('' + valNettPrice, 'IDR ') };
      setDataPayload(tempDataPayload);
    } else {
      const tempDataPayload = { ...dataPayload, nettPrice: Helper.formatMoneyOnField('' + 0, 'IDR ') };
      setDataPayload(tempDataPayload);
    }
  }, [useTakeRate]);

  useEffect(() => {
    if (dataInventory !== undefined) setDataInventory();
  }, [dataInventory]);

  useEffect(() => {
    if (dataInventory?.inventory_id !== undefined) fetchLogHistory();
  }, [dataInventory, page, pageLimit]);

  return (
    <>
      <Row>
        <Col md={6}>
          <Card>
            <CardHeader className="bg-primary text-white mt-0">Product Detail</CardHeader>
            <CardBody className="pb-0">
              <FormGroup row>
                <Label lg={4}>Category</Label>
                <InputField colWidth={8} inputType={'text'} disabled value={dataInventory?.category ?? '-'} />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>Product Name</Label>
                <InputField colWidth={8} inputType={'text'} disabled value={dataInventory?.product_name ?? '-'} />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>Product Type</Label>
                <InputField colWidth={8} inputType={'text'} disabled value={dataInventory?.product_type ?? '-'} />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>B2C Sell Price</Label>
                <InputField
                  colWidth={8}
                  inputType={'text'}
                  disabled={!editable}
                  onChange={(e) =>
                    handleChangeDataPayload(Helper.formatMoneyOnField(e.target.value, 'IDR '), 'sellPrice')
                  }
                  value={dataPayload?.sellPrice?.formatMoneyString ?? 'IDR 0'}
                />
              </FormGroup>
              <ActionSwitch
                labelWidth={4}
                colWidth={8}
                title={`Take Rate (${takeRate}%)`}
                inputName="is_active"
                isChecked={useTakeRate}
                customClass="mt-0"
                customClassLabel="text-right"
                editableForm={!editable}
                onChange={() => setUseTakeRate(!useTakeRate)}
                color={'secondary'}
              />
              <FormGroup row>
                <Label lg={4}>Nett Price</Label>
                <InputField
                  colWidth={8}
                  inputType={'text'}
                  disabled={!editable || useTakeRate}
                  onChange={(e) =>
                    handleChangeDataPayload(Helper.formatMoneyOnField(e.target.value, 'IDR '), 'nettPrice')
                  }
                  value={dataPayload?.nettPrice?.formatMoneyString ?? 'IDR 0'}
                />
              </FormGroup>
              {allowB2b && (
                <FormGroup row>
                  <Label lg={4}>B2B Sell Price</Label>
                  <InputField
                    colWidth={8}
                    inputType={'text'}
                    disabled={!editable}
                    onChange={(e) =>
                      handleChangeDataPayload(Helper.formatMoneyOnField(e.target.value, 'IDR '), 'basePrice')
                    }
                    value={dataPayload?.basePrice?.formatMoneyString ?? 'IDR 0'}
                  />
                </FormGroup>
              )}
              <FormGroup row>
                <Label lg={4}>Consignment</Label>
                <Input
                  lg={8}
                  type="checkbox"
                  name="isConsignment"
                  checked={dataInventory?.is_consignment}
                  disabled={true}
                  style={{
                    position: 'unset',
                    marginTop: 'unset',
                    marginLeft: '10px',
                  }}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>Minimum Quantity</Label>
                <InputField
                  colWidth={8}
                  disabled={!editable || allowEditQty}
                  onChange={(e) => handleChangeDataPayload(e.target.value, 'minimumQty')}
                  value={dataPayload?.minimumQty}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>Total Product Quantity</Label>
                <InputField colWidth={8} inputType={'text'} disabled value={dataInventory?.total_qty ?? 0} />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>Sellable Quantity</Label>
                <InputField
                  colWidth={8}
                  inputType={'text'}
                  disabled
                  value={dataInventory?.sellable_qty ?? 0}
                  isInvalid={true}
                  errorMessage={
                    +dataInventory?.sellable_qty < dataInventory?.minimum_qty
                      ? 'Sellable quantity dibawah minimum quantity'
                      : ''
                  }
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>Non-Sellable Quantity</Label>
                <InputField
                  colWidth={8}
                  inputType={'text'}
                  disabled
                  value={dataInventory?.non_sellable_qty ?? 0}
                  isInvalid={true}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>
                  Booked Quantity
                  <img src={InfoIcon} width={15} className="ml-1" id="tooltipBookedQuantity" />
                </Label>
                <InputField
                  colWidth={8}
                  inputType={'text'}
                  disabled
                  value={dataInventory?.non_sellable_detail?.booked_quantity ?? 0}
                  isInvalid={true}
                />
                <Tooltip
                  placement="top"
                  isOpen={tooltipBookedQuantity}
                  target="tooltipBookedQuantity"
                  toggle={toggleBookedQuantity}
                >
                  Jumlah barang yang telah dipesan oleh customer
                </Tooltip>
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>
                  Pending Quantity
                  <img src={InfoIcon} width={15} className="ml-1" id="tooltipPendingQuantity" />
                </Label>
                <InputField
                  colWidth={8}
                  inputType={'text'}
                  disabled
                  value={dataInventory?.non_sellable_detail?.pending_purchase ?? 0}
                  isInvalid={true}
                />
                <Tooltip
                  placement="top"
                  isOpen={tooltipPendingQuantity}
                  target="tooltipPendingQuantity"
                  toggle={togglePendingQuantity}
                >
                  Jumlah barang yang sedang dalam proses pengiriman
                </Tooltip>
              </FormGroup>
              {editable ? (
                <Row>
                  <Col className="mb-3 justify-content-end d-flex col-button-inventory">
                    <Button className="btn btn-primary" color="primary" onClick={() => handleCancelEdit()}>
                      Batal
                    </Button>
                    <Button className="btn btn-secondary ml-3" color="secondary" onClick={() => confirmEditInventory()}>
                      Simpan
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col className="mb-3 justify-content-end d-flex col-button-inventory">
                    <Button className="btn btn-primary" color="primary" onClick={() => setEditable(true)}>
                      Edit
                    </Button>
                    <Button className="btn btn-secondary ml-3" color="secondary" onClick={() => handleAddNewProduct()}>
                      Tambah Stok Produk
                    </Button>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <CardHeader className="bg-primary text-white mt-0">Log History Stock Inventory</CardHeader>
            <CardBody className="pb-0">
              <Col sm={12} md={12} className="p-0 mt-4">
                <DataTable
                  clearSelectedRows={loading}
                  persistTableHead
                  highlightOnHover
                  sortServer
                  noHeader={true}
                  progressPending={loading}
                  data={dataTable}
                  className="table dt-responsive table-custom"
                  noDataComponent={<NoData />}
                  progressComponent={<Loading />}
                  customStyles={customStylesHeaderTable}
                  columns={allowList ? columns : []}
                />
              </Col>
              <Col sm={12} md={12} className="p-0 mt-2">
                {dataTable?.length >= 1 && !loading && allowList && (
                  <CustomPagination
                    page={page}
                    pageLimit={pageLimit}
                    totalRows={dataTable?.length ?? 0}
                    totalAllRows={totalRows}
                    totalPages={totalPages}
                    handleChangePage={handleChangePage}
                    handleChangeRowPerPage={handleChangeRowPerPage}
                    short
                  />
                )}
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CustomModal
        className="modal-confirm-inventory"
        modalWidth={500}
        modal={modalConfirm}
        toggle={() => setModalConfirm(false)}
        modalHeader="Edit Produk"
        modalFooter={
          <>
            <Button color="danger" size="md" outline onClick={() => setModalConfirm(false)}>
              Kembali
            </Button>
            <Button color="primary" size="md" onClick={() => handleEditInventory()}>
              {!loadingEdit ? (
                'Ya, Prosess'
              ) : (
                <Spinner size="sm" color="dark">
                  Loading...
                </Spinner>
              )}
            </Button>
          </>
        }
      >
        Apakah kamu yakin ingin memproses perubahan data ini?
      </CustomModal>
    </>
  );
};

export default InventoryViewDetail;
