import React, { useState, useEffect, useMemo } from 'react';
import { find } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { Modal, ModalBody, Button, Col, Row, Tooltip } from 'reactstrap';
import moment from 'moment';
import Papa from 'papaparse';
import API from '@utils/API';
import map from 'lodash/map';
import TrashIcon from '@assets/icons/trash.svg';
import DataTable from 'react-data-table-component';
import QuestGuide from '@assets/icons/question.svg';

const ProductImport = (props) => {
  const { modal, toggle, onSubmit } = props;

  const api = new API('v2');
  const [loading, setLoading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [resultMode, setResultMode] = useState(false);
  const [successImport, setSuccessImport] = useState(false);
  const [processParsing, setProcessParsing] = useState(false);
  const [showTooltipProductID, setShowTooltipProductID] = useState(false);
  const [formData, setFormData] = useState({});
  const [headerData, setHeaderData] = useState({});
  const [previewData, setPreviewData] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [resultTotalData, setResultTotalData] = useState(0);
  const [invalidPreview, setInvalidPreview] = useState(true);
  const [errorMsgPreview, setErrorMsgPreview] = useState('');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        backgroundColor: '#F9F9F9',
        border: '0.7px solid #E0E7ED',
      },
    },
  };

  const conditionCellStylesPreview = (field) => {
    return [
      {
        when: (row) => row?.[field] == undefined || row?.[field] == '',
        style: {
          backgroundColor: '#F8D7DA',
        },
      },
    ];
  };

  const conditionCellStylesResult = (field) => {
    return [
      {
        when: (row) => row?.[field],
        style: {
          backgroundColor: '#F8D7DA',
        },
      },
    ];
  };

  const columnsPreview = useMemo(
    () => [
      {
        name: <span className={headerData?.product_id ? '' : 'header-danger'}>Produk ID</span>,
        selector: (row) => row?.product_id ?? '',
        minWidth: '130px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('product_id'),
      },
      {
        name: 'SKU',
        selector: (row) => row?.sku ?? '',
        minWidth: '130px',
        sortable: false,
      },
      {
        name: <span className={headerData?.brand_id ? '' : 'header-danger'}>Brand ID</span>,
        selector: (row) => row?.brand_id ?? '',
        minWidth: '130px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('brand_id'),
      },
      {
        name: <span className={headerData?.product_name ? '' : 'header-danger'}>Nama Produk</span>,
        minWidth: '350px',
        selector: (row) => row?.product_name ?? '',
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesPreview('product_name'),
      },
      {
        name: <span className={headerData?.display_name ? '' : 'header-danger'}>Display Name</span>,
        minWidth: '350px',
        selector: (row) => row?.display_name ?? '',
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesPreview('display_name'),
      },
      {
        name: 'Nama Alternatif (Alias)',
        minWidth: '500px',
        selector: (row) => row?.alias ?? '',
        cell: (row) => (
          <>
            <div className="overflow-field">{row?.alias ?? ''}</div>
          </>
        ),
        sortable: false,
        // wrap: true,
        conditionalCellStyles: conditionCellStylesResult('error_alias'),
      },
      {
        name: <span className={headerData?.component ? '' : 'header-danger'}>Komponen</span>,
        selector: (row) => row?.component ?? '',
        minWidth: '200px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('component'),
      },
      {
        name: <span className={headerData?.category ? '' : 'header-danger'}>Kategori</span>,
        selector: (row) => row?.category ?? '',
        minWidth: '200px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('category'),
      },
      {
        name: <span className={headerData?.type ? '' : 'header-danger'}>Tipe</span>,
        selector: (row) => row?.type ?? '',
        minWidth: '200px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('type'),
      },
      {
        name: <span className={headerData?.product_price ? '' : 'header-danger'}>Harga Produk</span>,
        selector: (row) => row?.product_price ?? '',
        minWidth: '200px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('product_price'),
      },
      {
        name: <span className={headerData?.car_compability ? '' : 'header-danger'}>Car Compatibility ID</span>,
        minWidth: '500px',
        selector: (row) => row?.car_compability ?? '',
        cell: (row) => (
          <>
            <div className="overflow-field">{row?.car_compability ?? ''}</div>
          </>
        ),
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesPreview('car_compability'),
      },
      {
        name: 'Deskripsi',
        minWidth: '950px',
        selector: (row) => row?.description ?? '',
        sortable: false,
        wrap: true,
      },
    ],
    [previewData, headerData]
  );

  const columnsResult = useMemo(
    () => [
      {
        name: '1',
        selector: (row) => row?.row_number ?? '',
        minWidth: '50px',
        sortable: false,
      },
      {
        name: 'Produk ID',
        selector: (row) => (
          <>
            {row?.product_id ?? ''}
            {row?.error_product_id && <span className="header-danger">{row?.error_product_id}</span>}
          </>
        ),
        minWidth: '350px',
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesResult('error_product_id'),
      },
      {
        name: 'SKU',
        selector: (row) => (
          <>
            {row?.sku ?? ''}
            {row?.error_sku && <span className="error-result-danger">{row?.error_sku}</span>}
          </>
        ),
        minWidth: '350px',
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesResult('error_sku'),
      },
      {
        name: 'Brand ID',
        selector: (row) => (
          <>
            {row?.brand_id ?? ''}
            {row?.error_brand_id && <span className="error-result-danger">{row?.error_brand_id}</span>}
          </>
        ),
        minWidth: '350px',
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesResult('error_brand_id'),
      },
      {
        name: 'Nama Produk',
        minWidth: '350px',
        selector: (row) => (
          <>
            {row?.product_name ?? ''}
            {row?.error_product_name && <span className="error-result-danger">{row?.error_product_name}</span>}
          </>
        ),
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesResult('error_product_name'),
      },
      {
        name: 'Display Name',
        minWidth: '350px',
        selector: (row) => (
          <>
            {row?.display_name ?? ''}
            {row?.error_display_name && <span className="error-result-danger">{row?.error_display_name}</span>}
          </>
        ),
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesResult('error_display_name'),
      },
      {
        name: 'Nama Alternatif (Alias)',
        minWidth: '500px',
        selector: (row) => (
          <>
            {row?.alias ?? ''}
            {row?.error_alias && <span className="error-result-danger">{row?.alias}</span>}
          </>
        ),
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesResult('error_alias'),
      },
      {
        name: 'Komponen',
        selector: (row) => (
          <>
            {row?.component ?? ''}
            {row?.error_component && <span className="error-result-danger">{row?.error_component}</span>}
          </>
        ),
        minWidth: '350px',
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesResult('error_component'),
      },
      {
        name: 'Kategori',
        selector: (row) => (
          <>
            {row?.category ?? ''}
            {row?.error_category && <span className="error-result-danger">{row?.error_category}</span>}
          </>
        ),
        minWidth: '350px',
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesResult('error_category'),
      },
      {
        name: 'Tipe',
        selector: (row) => (
          <>
            {row?.type ?? ''}
            {row?.error_type && <span className="error-result-danger">{row?.error_type}</span>}
          </>
        ),
        minWidth: '350px',
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesResult('error_type'),
      },
      {
        name: 'Harga Produk',
        selector: (row) => (
          <>
            {row?.product_price ?? ''}
            {row?.error_product_price && <span className="error-result-danger">{row?.error_product_price}</span>}
          </>
        ),
        minWidth: '350px',
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesResult('error_product_price'),
      },
      {
        name: 'Car Compability',
        minWidth: '350px',
        selector: (row) => (
          <>
            {row?.car_compability ?? ''}
            {row?.error_car_compability && <span className="error-result-danger">{row?.error_car_compability}</span>}
          </>
        ),
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesResult('error_car_compability'),
      },
      {
        name: 'Deskripsi',
        minWidth: '500px',
        selector: (row) => (
          <>
            {row?.description ?? ''}
            {row?.error_description && <span className="error-result-danger">{row?.error_description}</span>}
          </>
        ),
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesResult('error_description'),
      },
    ],
    [resultData]
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Data'}</div>;
  }, []);

  const { getRootProps } = useDropzone({
    multiple: false,
    disabled: loading,
    onDrop: (files) => {
      handleDropFile(files[0]);
      setIsDragOver(false);
    },
    onDragOver: () => {
      removeErrorDocument();
      setIsDragOver(true);
    },
    onDragLeave: () => {
      setIsDragOver(false);
    },
    onFileDialogOpen: () => {
      removeErrorDocument();
    },
  });

  const handleDropFile = (data) => {
    const file = data;
    const maxSize = 5048;
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const allowTypes = ['csv'];
    const nameFileSplit = file?.name ? file?.name.split('.') : [];

    if (nameFileSplit.length == 0) {
      formData.error_document = 'Format file tidak diketahui';
      setFormData({ ...formData });
    } else {
      const selected = find(allowTypes, (item) => {
        return nameFileSplit[nameFileSplit.length - 1].toLowerCase() === item;
      });
      if (!selected) {
        formData.error_document = 'File tidak sesuai dengan format';
        setFormData({ ...formData });
      } else {
        if (fileSize == null) {
          formData.error_document = 'Ukuran file tidak diketahui';
          setFormData({ ...formData });
        } else if (fileSize > maxSize) {
          formData.error_document = 'Ukuran file maksimal 5MB';
          setFormData({ ...formData });
        } else {
          const fileUrl = URL.createObjectURL(file);
          formData.error_document = '';
          formData.document_name = file?.name ?? '';
          formData.document_url = fileUrl ?? '';
          formData.document_file = file ?? '';
          setFormData({ ...formData });
          handleReadHeader(file);
        }
      }
    }
  };

  const handleCheckFieldHeader = (results, key) => {
    const data = results?.data?.length && results?.data?.length !== 0 ? results?.data[0] : [];
    const available = find(data, (item) => {
      return item === key;
    });

    return !available ? false : true;
  };

  const handleReadHeader = (file) => {
    setProcessParsing(true);
    Papa.parse(file, {
      header: false,
      complete: (results) => {
        let invalidHeader = true;
        const dataHeader = {
          product_id: handleCheckFieldHeader(results, 'Produk ID'),
          brand_id: handleCheckFieldHeader(results, 'Brand ID'),
          product_name: handleCheckFieldHeader(results, 'Nama Produk'),
          display_name: handleCheckFieldHeader(results, 'Display Name'),
          alias: handleCheckFieldHeader(results, 'Nama Alternatif (Alias)'),
          component: handleCheckFieldHeader(results, 'Komponen'),
          category: handleCheckFieldHeader(results, 'Kategori'),
          type: handleCheckFieldHeader(results, 'Tipe'),
          product_price: handleCheckFieldHeader(results, 'Harga Produk'),
          car_compability: handleCheckFieldHeader(results, 'Car Compatibility ID'),
        };

        if (
          !dataHeader.product_id ||
          !dataHeader.brand_id ||
          !dataHeader.product_name ||
          !dataHeader.display_name ||
          !dataHeader.component ||
          !dataHeader.category ||
          !dataHeader.type ||
          !dataHeader.product_price ||
          !dataHeader.car_compability
        ) {
          invalidHeader = true;
          setErrorMsgPreview('Data CSV tidak sesuai dengan format');
        } else {
          invalidHeader = false;
          setErrorMsgPreview('');
        }

        setHeaderData(dataHeader);
        handleReadContentPreview(file, invalidHeader);
      },
    });
  };

  const handleReadContentPreview = (file, invalidHeader) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const dummyData = results?.data?.length !== 0 ? results?.data.slice(0, 5) : [];
        const dataPreview = map(dummyData, (item) => {
          return {
            product_id: item['Produk ID'] ?? '',
            sku: item['SKU'] ?? '',
            brand_id: item['Brand ID'] ?? '',
            product_name: item['Nama Produk'] ?? '',
            display_name: item['Display Name'] ?? '',
            alias: item['Nama Alternatif (Alias)'] ?? '',
            component: item['Komponen'] ?? '',
            category: item['Kategori'] ?? '',
            type: item['Tipe'] ?? '',
            product_price: item['Harga Produk'] ?? '',
            car_compability: item['Car Compatibility ID'] ?? '',
            description: item['Deskripsi'] ?? '',
          };
        });
        setInvalidPreview(dataPreview?.length !== 0 && !invalidHeader ? false : true);
        setPreviewData(dataPreview);
      },
    });
    setProcessParsing(false);
  };

  const getErrorMsg = (item, key) => {
    return item?.errors?.[key]?.length && item?.errors?.[key]?.length !== 0 ? item?.errors?.[key].toString() : '';
  };

  const hanndleReadContentResult = (data) => {
    let arrData = [];

    data.forEach((item, index) => {
      if (item?.errors) {
        arrData.push({
          row_number: Number(index + 2),
          product_id: item?.id,
          sku: item?.sku,
          brand_id: item?.brand_product_id,
          product_name: item?.name,
          display_name: item?.display_name,
          alias: item?.alias,
          component: item?.component_name,
          category: item?.product_category_name,
          type: item?.product_type,
          product_price: item?.product_price,
          car_compability: item?.car_compatibility_group_ids,
          description: item?.description,
          error_product_id: getErrorMsg(item, 'id'),
          error_sku: getErrorMsg(item, 'sku'),
          error_brand_id: getErrorMsg(item, 'brand_product_id'),
          error_product_name: getErrorMsg(item, 'name'),
          error_display_name: getErrorMsg(item, 'display_name'),
          error_alias: getErrorMsg(item, 'alias'),
          error_component: getErrorMsg(item, 'component_name'),
          error_category: getErrorMsg(item, 'product_category_name'),
          error_type: getErrorMsg(item, 'product_type'),
          error_product_price: getErrorMsg(item, 'product_price'),
          error_car_compability: getErrorMsg(item, 'car_compatibility_group_ids'),
          error_description: getErrorMsg(item, 'description'),
        });
      }
    });

    setResultData(arrData);
  };

  async function handleDownloadTemplate() {
    try {
      const response = await api.get(`v2/intools/master/products/import/template`, {
        responseType: 'blob',
      });
      if (response?.data) {
        const file = new Blob([response?.data], { type: 'text/csv' });
        const fileURL = URL.createObjectURL(file);
        let link = document.createElement('a');
        link.href = fileURL;
        link.download = `template_import_product_${moment().format('HHmmss')}.csv`;
        link.click();
      }
    } catch (error) {
      let msg = error?.response?.data?.error?.message ?? '';
    }
  }

  const defaultDataForm = () => {
    const dataForm = {
      document_name: '',
      document_url: '',
      document_file: '',
      document_key: null,
      error_document: '',
    };

    setPreviewMode(false);
    setResultMode(false);
    setSuccessImport(false);
    setInvalidPreview(true);
    setProcessParsing(false);
    setResultData([]);
    setPreviewData([]);
    setHeaderData({});
    setResultTotalData(0);
    setFormData(dataForm);
    setErrorMsgPreview('Data CSV tidak sesuai dengan format');
  };

  const handleDeleteDocument = () => {
    if (!loading) {
      defaultDataForm();
    }
  };

  const handleDownloadDocument = () => {
    if (!loading) {
      const link = document.createElement('a');
      link.setAttribute('href', formData.document_url);
      link.setAttribute('download', formData.document_name ?? '');
      link.click();
    }
  };

  const removeErrorDocument = () => {
    if (formData.error_document !== '') {
      formData.error_document = '';
      setFormData({ ...formData });
    }
  };

  const handleSubmit = () => {
    if (!previewMode) {
      setPreviewMode(true);
    } else {
      submitForm();
    }
  };

  const handleCancel = () => {
    if (previewMode) {
      setPreviewMode(false);
      setResultMode(false);
    } else if (resultMode && !successImport) {
      setPreviewMode(true);
      setResultMode(false);
    } else {
      toggle(successImport);
    }
  };

  async function submitForm() {
    setResultData([]);
    setResultTotalData(0);
    setLoading(true);

    try {
      const dataForm = new FormData();
      dataForm.append('file', formData?.document_file);
      dataForm.append('preview', true);

      const response = await api.post(`v2/intools/master/products/import`, dataForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setResultTotalData(response?.data?.data?.content?.length ?? 0);

      if (response?.data?.data?.is_valid_data && response?.data?.data?.execute_path) {
        executeSubmitForm(response?.data?.data?.execute_path);
      } else {
        setPreviewMode(false);
        setResultMode(true);
        hanndleReadContentResult(response?.data?.data?.content ?? []);
        setSuccessImport(false);
        setLoading(false);
      }
    } catch (error) {
      toggle();
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  async function executeSubmitForm(executePath) {
    try {
      const response = await api.post(executePath);

      if (response?.data?.data) {
        setPreviewMode(false);
        setResultMode(true);
        setSuccessImport(true);
      } else {
        toggle();
        onSubmit(false, '');
      }

      setLoading(false);
    } catch (error) {
      toggle();
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  useEffect(() => {
    defaultDataForm();
  }, [modal]);

  return (
    <Modal
      className={
        previewMode
          ? 'modal-dialog-centered modal-preview-csv'
          : resultMode && !successImport
          ? 'modal-dialog-centered modal-preview-csv'
          : 'modal-dialog-centered modal-form'
      }
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalBody className="body mt-3">
        <div className="header">
          Task Import <br />
          <span className="h4">
            {!previewMode && !resultMode
              ? 'Langkah 1/3: Upload File'
              : previewMode
              ? 'Langkah 2/3: Pengecekan Header'
              : resultMode
              ? 'Langkah 3/3: Finalisasi'
              : ''}
          </span>
          <br />
          {resultMode && successImport && (
            <span className="badge-custom badge-custom-success">{`Berhasil - ${resultTotalData} baris data berhasil diimport`}</span>
          )}
          {resultMode && !successImport && (
            <span className="badge-custom badge-custom-danger">{`Gagal - ${resultData?.length} dari ${resultTotalData} baris data bermasalah, silahkan cek kembali file Anda`}</span>
          )}
        </div>
        <div className="content">
          {!previewMode && !resultMode && (
            <>
              <Tooltip
                isOpen={showTooltipProductID}
                target="tooltip_product_id"
                toggle={() => setShowTooltipProductID(!showTooltipProductID)}
              >
                <p>Value 0 = create New ID</p>
              </Tooltip>
              <Row className="p-2">
                Kamu bisa import data dengan mengupload CSV data. Data tersebut akan secara otomatis terbaca oleh sistem
                kami.
              </Row>
              <Row className="p-2">
                Silakan upload data dan link yang telah di-import CSV sesuai dengan contoh{' '}
                <span className="document-workshop font-weight-bold" onClick={handleDownloadTemplate}>
                  ini
                </span>
                .
              </Row>
              <Row className="p-2 pb-3">Berikut ini adalah hal-hal yang harus disediakan:</Row>
              <Row className="m-0">
                <Col lg={6} md={6} sm={6} className="pl-0">
                  <Row className="pl-2 pb-2 label-required">
                    <img id="tooltip_product_id" src={QuestGuide} width="15" className="mr-1" />
                    Produk ID
                  </Row>
                  <Row className="pl-2 pb-2">SKU</Row>
                  <Row className="pl-2 pb-2 label-required">Brand ID</Row>
                  <Row className="pl-2 pb-2 label-required">Nama Produk</Row>
                  <Row className="pl-2 pb-2 label-required">Display Name</Row>
                  <Row className="pl-2 pb-2">Nama Alternatif (Alias)</Row>
                </Col>
                <Col lg={6} md={6} sm={6} className="pl-0">
                  <Row className="pl-2 pb-2 label-required">Komponen</Row>
                  <Row className="pl-2 pb-2 label-required">Kategori</Row>
                  <Row className="pl-2 pb-2 label-required">Tipe</Row>
                  <Row className="pl-2 pb-2 label-required">Harga Produk</Row>
                  <Row className="pl-2 pb-2 label-required">Car Compatibility ID</Row>
                  <Row className="pl-2 pb-2">Deskripsi</Row>
                </Col>
              </Row>
              <Row className="p-2 pt-3">
                Upload Data CSV kamu disini{' '}
                <span className="file" onClick={handleDownloadDocument}>
                  {' Sample'}
                </span>
              </Row>
              <Row className="mb-3">
                <Col lg={12} className="text-left text-bold">
                  {formData?.document_name && (
                    <div className="document-box-workshop">
                      <span className="file" onClick={handleDownloadDocument}>
                        {formData?.document_name}
                      </span>
                      <img className="icon-trash-workshop" src={TrashIcon} onClick={handleDeleteDocument} />
                    </div>
                  )}
                  {!formData?.document_name && (
                    <div>
                      {isDragOver && (
                        <div {...getRootProps({ className: 'document-box-workshop drag-over cursor-pointer' })}>
                          <span className="choose-file">Release File</span>
                        </div>
                      )}
                      {!isDragOver && (
                        <div {...getRootProps({ className: 'document-box-workshop cursor-pointer' })}>
                          <span className="choose-file">Drag and Drop or Choose File</span>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="invalid-document">{formData?.error_document}</div>
                  <div className="document-guide">Hanya mendukung file CSV (max. 5 MB)</div>
                </Col>
              </Row>
            </>
          )}
          {previewMode && (
            <>
              <Col sm={12} md={12} className="p-0 mt-4">
                <DataTable
                  persistTableHead
                  highlightOnHover
                  noHeader={true}
                  data={previewData}
                  className="table dt-responsive table-custom mb-0"
                  noDataComponent={noDataComponent}
                  customStyles={customStylesHeaderTable}
                  columns={columnsPreview}
                />
                <div className="invalid-document">{errorMsgPreview ?? ''}</div>
              </Col>
            </>
          )}
          {resultMode && !successImport && (
            <>
              <Col sm={12} md={12} className="p-0 mt-4">
                <DataTable
                  persistTableHead
                  highlightOnHover
                  noHeader={true}
                  data={resultData}
                  className="table dt-responsive table-custom table-result-import mb-0"
                  noDataComponent={noDataComponent}
                  customStyles={customStylesHeaderTable}
                  columns={columnsResult}
                />
                <div className="invalid-document">{errorMsgPreview ?? ''}</div>
              </Col>
            </>
          )}
        </div>
        <div className="action mb-3 mt-3">
          <Button
            className="button-cancel"
            onClick={() => {
              if (!loading) {
                handleCancel();
              }
            }}
            disabled={loading}
          >
            {previewMode ? 'Kembali' : resultMode ? (successImport ? 'Tutup' : 'Kembali') : 'Batal'}
          </Button>
          {!resultMode && (
            <Button
              className="button-action"
              onClick={() => {
                const validDocument = previewMode ? (previewMode && invalidPreview ? false : true) : true;
                if (!loading && !processParsing && validDocument) {
                  handleSubmit();
                }
              }}
              disabled={loading || processParsing || (previewMode && invalidPreview)}
            >
              {previewMode ? 'Simpan' : 'Lanjut'}
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ProductImport;
