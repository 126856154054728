import React, { useEffect, useState } from 'react';
import Wrapper from '@components/wrapper/Wrapper';
import CustomerForms from '@components/customer/CustomerForms';
import { useHistory } from 'react-router-dom';
import userPermission from '@utils/userPermission';
import API from '@utils/API';
import map from 'lodash/map';

const CustomerCreate = () => {
  const history = useHistory();
  const api = new API('v2');
  const { generatePermission } = userPermission();

  const [notification, setNotification] = useState({});
  const [hasNotification, setHasNotification] = useState(false);
  const [loadingLoadWorkshop, setLoadingLoadWorkshop] = useState(false);
  const [loadingLoadPIC, setLoadingLoadPIC] = useState(false);
  const [listPIC, setListPIC] = useState([]);
  const [configDoc, setConfigDoc] = useState([]);

  const breadcrumb = [
    {
      id: 1,
      label: 'List Customer',
      active: false,
      url: '/customer',
    },
    {
      id: 2,
      label: 'Create Customer',
      active: false,
      url: '',
    },
  ];

  const allowPermission = (menu) => {
    const allowAccess = !!(generatePermission('customer', menu) || generatePermission('customer', 'all'));
    if (!allowAccess) return history.push('/404');
    return allowAccess;
  };

  const loadListWorkshop = async (search) => {
    setLoadingLoadWorkshop(true);
    if (search) {
      return await api
        .get(`v2/intools/customers/workshops/?limit=100&search=${search}`)
        .then((result) => {
          setLoadingLoadWorkshop(false);
          return result.data.data;
        })
        .catch(() => {
          setLoadingLoadWorkshop(false);
        });
    }
  };

  const loadListPIC = async (search = '') => {
    setLoadingLoadPIC(true);
    if (true) {
      return await api
        .get(`v2/intools/customers/b2b_pic/?limit=100&search=${search}`)
        .then((result) => {
          setLoadingLoadPIC(false);
          setListPIC(result.data.data);
          return result.data.data;
        })
        .catch(() => {
          setLoadingLoadPIC(false);
        });
    }
  };

  const loadConfigDocuments = async () => {
    return await api
      .get(`v2/intools/customers/master_document_config/`)
      .then((result) => {
        const transformedOptions = map(result.data.data, (item) => {
          return { id: item.id, name: item.name, slug: item.slug, checked: true };
        });
        setConfigDoc(result.data.data);
      })
      .catch(() => {
        setConfigDoc([]);
      });
  };

  const handleResultNotification = async (notification) => {
    const { status, type, message } = notification;
    if (status) {
      setNotification({
        type: type,
        message: message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    setHasNotification(status);
    setTimeout(() => {
      setHasNotification(false);
      setNotification({});
      return status;
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotification(false);
    setNotification({});
  };

  useEffect(() => {
    allowPermission('create');
  }, []);

  useEffect(() => {
    loadListPIC();
    loadConfigDocuments();
  }, []);

  const renderContent = () => {
    return (
      <>
        <Wrapper
          title="Create Customer"
          breadcrumbData={breadcrumb}
          showNotification={hasNotification}
          notification={notification}
          onCloseNotification={handleCloseNotification}
        >
          <CustomerForms
            origin="create"
            handleResultNotification={handleResultNotification}
            loadListWorkshop={loadListWorkshop}
            loadingLoadWorkshop={loadingLoadWorkshop}
            loadListPIC={loadListPIC}
            loadingLoadPIC={loadingLoadPIC}
            listPIC={listPIC}
            configDoc={configDoc}
          />
        </Wrapper>
      </>
    );
  };

  return renderContent();
};

export default CustomerCreate;
