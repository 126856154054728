import React from 'react';
import { Spinner } from 'reactstrap';

function LoadingSpinner() {
  return (
    <div className="text-center">
      <Spinner size="lg" className="loader-spinner">
        Loading...
      </Spinner>
    </div>
  );
}

export default LoadingSpinner;
