import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Card, CardBody, Col, Label, Row, Button, Tooltip, FormGroup, Input } from 'reactstrap';
import API from '@utils/API';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import Divider from '@components/divider/Divider';
import userPermission from '@utils/userPermission';
import SelectField from '@components/field/SelectField';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import NoImage from '@assets/images/no-image.jpeg';
import moment from 'moment';
import Quest from '@assets/icons/question.svg';
import Helper from '@helpers/Helper';
import map from 'lodash/map';
import newTabIcon from '@assets/icons/new-tab.svg';

const DetailCampaignTab = (props) => {
  const { id, onLoading } = props;

  const api = new API('v2');

  const history = useHistory();
  const { generatePermission } = userPermission();
  const [dataCampaign, setdataCampaign] = useState({});
  const [loading, setLoading] = useState(false);
  const [disableForm, setDisableForm] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);
  const [showLightboxDesktop, setShowLightboxDesktop] = useState(false);
  const [publicTooltip, setPublicTooltip] = useState(false);
  const [showlistTooltip, setShowlistTooltip] = useState(false);
  const [featuredTooltip, setFeaturedTooltip] = useState(false);
  const [PICTooltip, setPICTooltip] = useState(false);
  const [isCampaignB2B, setIsCampaignB2B] = useState(false);

  async function getCampaign() {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/campaigns/${id}/`);
      if (response?.data?.data) {
        setdataCampaign(response?.data?.data);
      } else {
        setdataCampaign({});
      }
      setLoading(false);
    } catch (error) {
      setdataCampaign({});
      setLoading(false);
    }
  }

  const color = (value) => {
    if (value) {
      return 'green';
    } else {
      return 'red';
    }
  };

  const customStyles = {
    color: color(),
  };

  const generateValidationRules = (data) => {
    const transformedData = map(data, (item) => {
      return { value: item.id, label: item.name ?? '-' };
    });

    return data ? transformedData : [];
  };

  const generateValidationRulesCannotBeCombine = (data) => {
    const transformedData = map(data, (item) => {
      return { value: item.id, label: item.slug ?? '-' };
    });

    return data ? transformedData : [];
  };

  const generateBookingOrigin = (data) => {
    const result = map(data, (item) => {
      if (item == 'intools') return 'Intools';
      else if (item == 'mobile') return 'Mobile App';
      else if (item == 'web') return 'Web App';
      else if (item == 'gms') return 'GMS';
      else return item.charAt(0).toUpperCase() + item.slice(1);
    }).join(', ');

    return result?.length > 0 ? result : '-';
  };

  const goToLink = (url) => {
    var data = document.createElement('a');
    data.href = url?.length > 0 ? url : '-';
    data.target = '_blank';
    if (url?.length > 0) data.click();
  };

  const handleEditCampaign = () => {
    history.push(`/campaigns/campaign/edit/${id}`);
  };

  useEffect(() => {
    if (dataCampaign) {
      console.log('dataCampaign', dataCampaign);
      const checkCampaignB2B =
        dataCampaign?.rules?.customer_eligibility === 'all_customer_b2b' ||
        dataCampaign?.rules?.customer_segment?.id === 'customer-b2b';
      setIsCampaignB2B(checkCampaignB2B);
      console.log(checkCampaignB2B);
    }
  }, [dataCampaign]);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    getCampaign();
  }, []);

  return (
    <React.Fragment>
      {showLightbox && <Lightbox mainSrc={dataCampaign?.image_link} onCloseRequest={() => setShowLightbox(false)} />}
      {showLightboxDesktop && (
        <Lightbox mainSrc={dataCampaign?.image_desktop_link} onCloseRequest={() => setShowLightboxDesktop(false)} />
      )}
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <Card className="card-custom">
          <CardBody className="p-0">
            <Divider title="Detail Campaign" />
            <Row>
              <Col sm={12} md={6}>
                <Row>
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={4} className="text-left">
                        Status
                      </Label>
                      <Label lg={8} className="text-left text-bold">
                        :{' '}
                        <span className="ml-1" style={{ color: color(dataCampaign?.is_active) }}>
                          {dataCampaign?.is_active ? 'Aktif' : 'Tidak Aktif'}
                        </span>
                      </Label>
                    </Row>
                  </Col>
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={4} className="text-left">
                        Nama Campaign
                      </Label>
                      <Label lg={8} className="text-left text-bold">
                        : <span className="ml-1">{dataCampaign?.name ? dataCampaign?.name : '-'}</span>
                      </Label>
                    </Row>
                  </Col>
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={4} className="text-left">
                        Subscription
                      </Label>
                      <Label lg={8} className="text-left text-bold">
                        :{' '}
                        <span className="ml-1" style={{ color: color(dataCampaign?.is_membership) }}>
                          {dataCampaign?.is_membership ? 'Ya' : 'Tidak'}
                        </span>
                      </Label>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row>
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={4} className="text-left">
                        Kelompok Campaign
                      </Label>
                      <Label lg={8} className="text-left text-bold">
                        : <span className="ml-1">{dataCampaign?.category ? dataCampaign?.category?.name : '-'}</span>
                      </Label>
                    </Row>
                  </Col>
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={4} className="text-left">
                        Periode Campaign
                      </Label>
                      <Label lg={8} className="text-left text-bold">
                        :{' '}
                        <span className="ml-1">
                          {(dataCampaign?.start_date
                            ? moment(dataCampaign?.start_date).local().format('DD MMMM YYYY, HH:mm:ss')
                            : '-') +
                            ' s/d ' +
                            (dataCampaign?.end_date
                              ? moment(dataCampaign?.end_date).format('DD MMMM YYYY, HH:mm:ss')
                              : '-')}
                        </span>
                      </Label>
                    </Row>
                  </Col>
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={4} className="text-left">
                        Slug Campaign
                      </Label>
                      <Label lg={8} className="text-left text-bold">
                        : <span className="ml-1">{dataCampaign?.slug ? dataCampaign?.slug : '-'}</span>
                      </Label>
                    </Row>
                  </Col>
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={4} className="text-left">
                        <div className="ml-3">
                          <img className="icon-quest" id="PICTooltip" src={Quest} width="15" />
                          PIC Campaign
                        </div>
                      </Label>
                      <Label lg={8} className="text-left text-bold">
                        : <span className="ml-1">{dataCampaign?.pic_name ? dataCampaign?.pic_name : '-'}</span>
                      </Label>
                    </Row>
                  </Col>
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={4} className="text-left">
                        Deck Campaign
                      </Label>
                      <Label lg={8} className="text-left text-bold d-flex">
                        :{' '}
                        <span className={`deck-span ${dataCampaign?.deck_link?.length > 40 ? 'w-261' : ''}`}>
                          {dataCampaign?.deck_link ? dataCampaign?.deck_link : '-'}
                        </span>
                        {dataCampaign?.deck_link?.length > 0 && (
                          <img
                            src={newTabIcon}
                            height={23}
                            className="icon-new-tab pb-1"
                            onClick={() => goToLink(dataCampaign?.deck_link)}
                          />
                        )}
                      </Label>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Divider title="Customer Eligibility" />
            <Row>
              <Col sm={12} md={12}>
                <Row className="mb-3">
                  <Label lg={2} className="text-left">
                    Customer Eligibility
                  </Label>
                  <Label lg={10} className="text-left text-bold">
                    :
                    <span className="ml-1">
                      {dataCampaign?.rules?.customer_eligibility == 'customer_segment'
                        ? 'Selected Customer Segments'
                        : dataCampaign?.rules?.customer_eligibility == 'all'
                        ? 'All'
                        : dataCampaign?.rules?.customer_eligibility == 'all_customer_b2b'
                        ? 'All Customer B2B'
                        : '-'}
                      {dataCampaign?.rules?.customer_eligibility == 'customer_segment'
                        ? ` - ${dataCampaign?.rules?.customer_segment?.name}`
                        : ''}
                    </span>
                  </Label>
                </Row>
              </Col>
              {isCampaignB2B && dataCampaign?.rules?.customer_segment?.id === 'customer-b2b' && (
                <Col sm={12} md={12}>
                  <Row className="mb-3">
                    <Label lg={2} className="text-left">
                      Customer B2B
                    </Label>
                    <SelectField
                      colWidth={10}
                      isMulti={true}
                      name="Customers B2B"
                      placeholder="Customers B2B"
                      value={generateValidationRules(dataCampaign?.rules?.customer)}
                      disabled={disableForm}
                      titlePreview={'List Customers B2B'}
                      placeholderPreview={'Customers B2B'}
                      labelDataPreview={'Customers B2B'}
                    />
                  </Row>
                </Col>
              )}
              {dataCampaign?.rules?.customer_segment?.id == 'membership' && (
                <>
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={2} className="text-left">
                        Subscription Campaign
                      </Label>
                      <Label lg={10} className="text-left text-bold">
                        :<span className="ml-1">{dataCampaign?.membership_campaign?.campaign_name ?? '-'}</span>
                      </Label>
                    </Row>
                  </Col>
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={2} className="text-left">
                        Customer Membership
                      </Label>
                      <Label lg={10} className="text-left text-bold">
                        :
                        <span className="ml-1">
                          {dataCampaign?.customer_membership?.name ??
                            dataCampaign?.customer_membership?.phone_number ??
                            '-'}
                        </span>
                      </Label>
                    </Row>
                  </Col>
                  {dataCampaign?.user_car && (
                    <Col sm={12} md={12}>
                      <Row className="mb-3">
                        <Label lg={2} className="text-left">
                          License Plate Membership
                        </Label>
                        <Label lg={10} className="text-left text-bold">
                          :<span className="ml-1">{dataCampaign?.user_car?.license_plate ?? '-'}</span>
                        </Label>
                      </Row>
                    </Col>
                  )}
                </>
              )}
            </Row>

            <Divider title="Voucher" />
            <Row>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Tipe Voucher
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    :
                    <span className="ml-1">
                      {dataCampaign?.campaign_type == 'single'
                        ? 'Single Voucher'
                        : dataCampaign?.campaign_type == 'bulk'
                        ? 'Bulk Vouchers'
                        : '-'}
                    </span>
                  </Label>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Kode Vocher
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <span className="ml-1">{dataCampaign?.voucher_code ?? '-'}</span>
                  </Label>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Jumlah Voucher
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <span className="ml-1">{dataCampaign?.total_voucher ?? '-'}</span>
                  </Label>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Max Redeem/User
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <span className="ml-1">{dataCampaign?.max_redeem_per_user ?? '-'}</span>
                  </Label>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Total Quota Voucher
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <span className="ml-1">{dataCampaign?.max_quota ?? '-'}</span>
                  </Label>
                </Row>
              </Col>
            </Row>

            <Divider title="Discount Value" />
            <Row>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Tipe Discount
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    :
                    <span className="ml-1">
                      {dataCampaign?.discount_type == 'amount'
                        ? 'Amount Type'
                        : dataCampaign?.discount_type == 'percent'
                        ? 'Percentage Type'
                        : '-'}
                    </span>
                  </Label>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Discount Untuk
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <span className="ml-1">{dataCampaign?.discount_for?.name ?? '-'}</span>
                  </Label>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Besaran Discount
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    :{' '}
                    <span className="ml-1">
                      {dataCampaign?.discount_value
                        ? dataCampaign?.discount_type == 'amount'
                          ? 'Rp ' + Helper.numberWithCommas(dataCampaign?.discount_value)
                          : dataCampaign?.discount_type == 'percent'
                          ? Helper.numberWithCommas(dataCampaign?.discount_value) + ' %'
                          : Helper.numberWithCommas(dataCampaign?.discount_value)
                        : '-'}
                    </span>
                  </Label>
                </Row>
              </Col>
              {dataCampaign?.discount_type == 'percent' && (
                <Col sm={12} md={6}>
                  <Row className="mb-3">
                    <Label lg={4} className="text-left">
                      Maximal Discount
                    </Label>
                    <Label lg={8} className="text-left text-bold">
                      :{' '}
                      <span className="ml-1">
                        {dataCampaign?.max_discount ? 'Rp ' + Helper.numberWithCommas(dataCampaign?.max_discount) : '-'}
                      </span>
                    </Label>
                  </Row>
                </Col>
              )}
            </Row>

            <Divider title="Validation Rules" />
            <Row>
              <Col sm={12} md={12}>
                <Row className="mb-3">
                  <Label lg={2} className="text-left">
                    Minimal Transaction
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    :{' '}
                    <span className="ml-1">
                      {dataCampaign?.min_transaction >= 0
                        ? 'Rp ' + Helper.numberWithCommas(dataCampaign?.min_transaction)
                        : '-'}
                    </span>
                  </Label>
                </Row>
              </Col>
              <Col sm={12} md={12}>
                <Row className="mb-3">
                  <Label lg={2} className="text-left">
                    Booking Origin
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <span className="ml-1"> {generateBookingOrigin(dataCampaign?.rules?.booking_origin ?? [])} </span>
                  </Label>
                </Row>
              </Col>
              <Col sm={12} md={12}>
                <Row className="mb-3">
                  <Label lg={2} className="text-left">
                    Workshop
                  </Label>
                  <SelectField
                    colWidth={8}
                    isMulti={true}
                    name="workshop"
                    placeholder="Workshop"
                    value={generateValidationRules(
                      dataCampaign?.rules?.workshop?.length > 0
                        ? dataCampaign?.rules?.workshop[0]?.id == 'all'
                          ? []
                          : dataCampaign?.rules?.workshop
                        : dataCampaign?.rules?.workshop
                    )}
                    disabled={disableForm}
                    titlePreview={'List Workshop'}
                    placeholderPreview={'Workshop'}
                    labelDataPreview={'Workshop'}
                  />
                  <Col md={2}>
                    <Label className="ml-4 mt-2 mb-0">
                      <Input
                        type="checkbox"
                        checked={
                          dataCampaign?.rules?.workshop?.length > 0
                            ? dataCampaign?.rules?.workshop[0]?.id == 'all'
                              ? true
                              : false
                            : false
                        }
                      />
                      Semua Workshop
                    </Label>
                  </Col>
                </Row>
              </Col>
              {dataCampaign?.discount_for?.slug !== 'whole-cart' && (
                <>
                  <Col sm={12} md={6}>
                    <Row className="mb-3">
                      <Label lg={4} className="text-left">
                        Servis
                      </Label>
                      <SelectField
                        colWidth={8}
                        isMulti={true}
                        name="servis"
                        placeholder="Servis"
                        value={generateValidationRules(dataCampaign?.rules?.service)}
                        disabled={disableForm}
                        titlePreview={'List Servis'}
                        placeholderPreview={'Servis'}
                        labelDataPreview={'Servis'}
                      />
                    </Row>
                  </Col>
                  <Col sm={12} md={6}>
                    <Row className="mb-3">
                      <Label lg={4} className="text-left">
                        Brands
                      </Label>
                      <SelectField
                        colWidth={8}
                        isMulti={true}
                        name="brands"
                        placeholder="Brands"
                        value={generateValidationRules(dataCampaign?.rules?.brand)}
                        disabled={disableForm}
                        titlePreview={'List Brand'}
                        placeholderPreview={'Brand'}
                        labelDataPreview={'Brand'}
                      />
                    </Row>
                  </Col>
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={2} className="text-left">
                        Packages
                      </Label>
                      <SelectField
                        colWidth={10}
                        isMulti={true}
                        name="packages"
                        placeholder="Packages"
                        value={generateValidationRules(dataCampaign?.rules?.package)}
                        disabled={disableForm}
                        titlePreview={'List Packages'}
                        placeholderPreview={'Packages'}
                        labelDataPreview={'Packages'}
                      />
                    </Row>
                  </Col>
                  {isCampaignB2B && (
                    <Col sm={12} md={12}>
                      <Row className="mb-3">
                        <Label lg={2} className="text-left">
                          Items
                        </Label>
                        <SelectField
                          colWidth={10}
                          isMulti={true}
                          name="items"
                          placeholder="Items"
                          value={generateValidationRules(dataCampaign?.rules?.product)}
                          disabled={disableForm}
                          titlePreview={'List Items'}
                          placeholderPreview={'Items'}
                          labelDataPreview={'Items'}
                        />
                      </Row>
                    </Col>
                  )}
                </>
              )}
              {dataCampaign?.discount_for?.slug === `whole-cart` && (
                <Col sm={12} md={12}>
                  <Row className="mb-3">
                    <Label lg={2} className="text-left">
                      Non Promo Item
                    </Label>
                    <SelectField
                      colWidth={10}
                      isMulti={true}
                      name="Cari nama product/paket/jasa"
                      placeholder="Non Promo Item"
                      value={generateValidationRules(dataCampaign?.rules?.exclusion ?? [])}
                      disabled={disableForm}
                      titlePreview={'Non Promo Item'}
                      placeholderPreview={'Non Promo Item'}
                      labelDataPreview={'Non Promo Item'}
                    />
                  </Row>
                  <Row className="mb-3">
                    <Label lg={2} className="text-left">
                      Pembayaran
                    </Label>
                    <Label lg={8} className="text-left text-bold">
                      :{' '}
                      <span className="ml-1">
                        {dataCampaign?.rules?.payment_method ? dataCampaign?.rules?.payment_method : '-'}
                      </span>
                    </Label>
                  </Row>
                  <Row className="mb-3">
                    <Label lg={2} className="text-left">
                      Tidak Dapat Digabung dengan
                    </Label>
                    <SelectField
                      colWidth={10}
                      isMulti={true}
                      name="Cari slug campaign"
                      placeholder="Tidak Dapat Digabung dengan"
                      value={generateValidationRulesCannotBeCombine(dataCampaign?.rules?.prohibition_combine ?? [])}
                      disabled={disableForm}
                      titlePreview={'Tidak Dapat Digabung dengan'}
                      placeholderPreview={'Tidak Dapat Digabung dengan'}
                      labelDataPreview={' Tidak Dapat Digabung dengan'}
                    />
                  </Row>
                </Col>
              )}
              {dataCampaign?.discount_for?.slug !== 'selected-packages' ||
                (dataCampaign?.discount_for?.slug !== 'whole-cart' && (
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={2} className="text-left">
                        Produk
                      </Label>
                      <SelectField
                        colWidth={10}
                        isMulti={true}
                        name="produk"
                        placeholder="Produk"
                        value={generateValidationRules(dataCampaign?.rules?.product)}
                        disabled={disableForm}
                        titlePreview={'List Produk'}
                        placeholderPreview={'Produk'}
                        labelDataPreview={'Produk'}
                      />
                    </Row>
                  </Col>
                ))}
            </Row>

            <Divider title="Publishment Scenario" />
            <Row>
              <Col sm={12} md={12}>
                <div>
                  <Tooltip
                    isOpen={publicTooltip}
                    target="publicTooltip"
                    toggle={() => setPublicTooltip(!publicTooltip)}
                  >
                    <p>
                      1. Jika aktif, maka customer dapat klik pada banner untuk menggunakan code voucher dan dapat
                      diketik juga
                    </p>
                    <p>2. Jika non-aktif, customer wajib mengetik promo code untuk mengaktifkan banner </p>
                  </Tooltip>
                  <Tooltip
                    isOpen={showlistTooltip}
                    target="showlistTooltip"
                    toggle={() => setShowlistTooltip(!showlistTooltip)}
                  >
                    <p>Jika aktif, maka customer akan melihatnya di promo list di checkout page</p>
                  </Tooltip>
                  <Tooltip
                    isOpen={featuredTooltip}
                    target="featuredTooltip"
                    toggle={() => setFeaturedTooltip(!featuredTooltip)}
                  >
                    <p>Jika aktif, maka customer akan melihatnya di home page</p>
                  </Tooltip>
                  <Tooltip isOpen={PICTooltip} target="PICTooltip" toggle={() => setPICTooltip(!PICTooltip)}>
                    <p>
                      PIC yang akan menjawab setiap pertanyaan stakeholder terkait ketentuan Campaign yang sedang
                      berjalan
                    </p>
                  </Tooltip>

                  <Row className="ml-2">
                    <Col sm={3} md={3}>
                      <FormGroup row className="mb-0">
                        <Label lg={4} className="text-left">
                          <img className="icon-quest" id="publicTooltip" src={Quest} width="15" />
                          Is Public
                        </Label>
                        <Col lg={8}>
                          <div className="custom-control custom-switch switch-secondary mt-1">
                            <Input
                              type="checkbox"
                              className="custom-control-input"
                              id="is_public"
                              name="is_public"
                              checked={dataCampaign?.is_public}
                              disabled={disableForm}
                            />
                            <Label className="custom-control-label" htmlFor="is_public">
                              {/* {dataCampaign?.is_public ? labelChecked : labelUnchecked} */}
                            </Label>
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm={3} md={3}>
                      <FormGroup row className="mb-0">
                        <Label lg={5} className="text-left">
                          <img className="icon-quest" id="showlistTooltip" src={Quest} width="15" />
                          Is Show List
                        </Label>
                        <Col lg={7}>
                          <div className="custom-control custom-switch switch-secondary mt-1">
                            <Input
                              type="checkbox"
                              className="custom-control-input"
                              id="is_show_list"
                              name="is_show_list"
                              checked={dataCampaign?.is_show_list}
                              disabled={disableForm}
                            />
                            <Label className="custom-control-label" htmlFor="is_show_list">
                              {/* {dataCampaign?.is_show_list ? labelChecked : labelUnchecked} */}
                            </Label>
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>

                    <Col sm={3} md={3}>
                      <FormGroup row className="mb-0">
                        <Label lg={4} className="text-left">
                          <img className="icon-quest" id="featuredTooltip" src={Quest} width="15" />
                          Is Featured
                        </Label>
                        <Col lg={8}>
                          <div className="custom-control custom-switch switch-secondary mt-1">
                            <Input
                              type="checkbox"
                              className="custom-control-input"
                              id="is_featured"
                              name="is_featured"
                              checked={dataCampaign?.is_featured}
                              disabled={disableForm}
                            />
                            <Label className="custom-control-label" htmlFor="is_featured">
                              {/* {dataCampaign?.is_featured ? labelChecked : labelUnchecked} */}
                            </Label>
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>

                    <Col sm={3} md={3}>
                      {dataCampaign?.is_public == true && (
                        <Row className="mb-0">
                          <Label lg={4} className="text-left">
                            Entry Point
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{dataCampaign?.banner_type?.name ?? '-'}</span>
                          </Label>
                        </Row>
                      )}
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col sm={6}>
                      {dataCampaign?.is_public == true && dataCampaign?.banner_type?.slug == 'deeplink' && (
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Link Promo
                          </Label>
                          <Label lg={8} className="text-left text-bold d-flex">
                            :{' '}
                            <span
                              className={`ml-2 text-overflow ${dataCampaign?.banner_link?.length > 30 ? 'w-230' : ''}`}
                            >
                              {dataCampaign?.banner_link?.length > 0 ? dataCampaign?.banner_link : '-'}
                            </span>
                            {dataCampaign?.banner_link?.length > 0 && (
                              <span>
                                <img
                                  src={newTabIcon}
                                  height={23}
                                  className="icon-new-tab ml-1 pb-1"
                                  onClick={() => goToLink(dataCampaign?.banner_link)}
                                />
                              </span>
                            )}
                          </Label>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* {dataCampaign?.is_public == true && dataCampaign?.banner_type?.slug == 'landing-page' && ( */}
              <Col sm={12} md={12}>
                <Row className="mb-3">
                  <Label lg={2} className="text-left">
                    Term and Condition
                  </Label>
                  <Label lg={10} className="text-left d-flex ">
                    :{' '}
                    <div
                      className={dataCampaign?.terms_and_condition?.includes('ol') ? '' : 'ml-2'}
                      dangerouslySetInnerHTML={{
                        __html: dataCampaign?.terms_and_condition?.length > 0 ? dataCampaign?.terms_and_condition : '-',
                      }}
                    />
                  </Label>
                </Row>
              </Col>
              {/* )} */}

              <Col sm={12} md={12}>
                <Row className="mb-3">
                  <Label lg={2} className="text-left">
                    Deskripsi
                  </Label>
                  <Label lg={10} className="text-left d-flex">
                    :<span className="ml-2">{dataCampaign?.description ? dataCampaign?.description : '-'}</span>
                  </Label>
                </Row>
              </Col>

              <Col sm={12} md={12}>
                <Row className="mb-3">
                  <Label lg={2} className="text-left">
                    Campaign Banner
                  </Label>
                  <Label lg={10} className="text-left d-flex">
                    <Row>
                      <Col>
                        {dataCampaign?.image_link && (
                          <div className="gallery-container-workshop mb-0 mt-0">
                            <div className="box">
                              <span>Mobile</span>
                              <img
                                id="bannerPreview"
                                src={dataCampaign?.image_link}
                                height={150}
                                onClick={() => setShowLightbox(true)}
                              />
                            </div>
                          </div>
                        )}
                        {!dataCampaign?.image_link && (
                          <div className="gallery-container-workshop mb-0 mt-0">
                            <div className="box">
                              <span>Mobile</span>
                              <img id="bannerPromoPreview" src={NoImage} height={150} />
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col>
                        {dataCampaign?.image_desktop_link && (
                          <div className="gallery-container-workshop mb-0 mt-0">
                            <div className="box">
                              <span>Web</span>
                              <img
                                id="bannerPreview"
                                src={dataCampaign?.image_desktop_link}
                                height={150}
                                onClick={() => setShowLightboxDesktop(true)}
                              />
                            </div>
                          </div>
                        )}
                        {!dataCampaign?.image_desktop_link && (
                          <div className="gallery-container-workshop mb-0 mt-0">
                            <div className="box">
                              <span>Web</span>
                              <img id="bannerPromoPreview" src={NoImage} height={150} />
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Label>
                </Row>
              </Col>
            </Row>

            {dataCampaign?.is_public == true && dataCampaign?.banner_type?.slug == 'landing-page' && (
              <div>
                <Divider title="SEO" />
                <Row>
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={2} className="text-left">
                        Meta Title
                      </Label>
                      <Label lg={10} className="text-left text-bold">
                        : <span className="ml-1">{dataCampaign?.seo_title ? dataCampaign?.seo_title : '-'}</span>
                      </Label>
                    </Row>
                  </Col>
                  <Col sm={12} md={12}>
                    <Row className="mb-3">
                      <Label lg={2} className="text-left">
                        Meta Description
                      </Label>
                      <Label lg={10} className="text-left d-flex">
                        :{' '}
                        <span className="ml-2">
                          {dataCampaign?.seo_description ? dataCampaign?.seo_description : '-'}
                        </span>
                      </Label>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}

            {generatePermission('campaign', 'detailedit') && (
              <Row>
                <Col sm={12} md={12} className="section-action">
                  <Button disabled={disableButton} className="button-action save" onClick={() => handleEditCampaign()}>
                    {'Edit Data'}
                  </Button>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default DetailCampaignTab;
