import API from '@utils/API';

const api = new API('v2');
class JiraService {
  constructor(jiraTicket = {}) {
    this.jiraTicket = jiraTicket;
  }
  async syncJira(bookingCode) {
    try {
      const response = await api.patch(`v2/intools/bookings/${bookingCode}/jira-ticket`);
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export default new JiraService();
