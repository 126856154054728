import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Row, Col, Card, CardBody, CardHeader, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import EditableInputField from '@components/field/EditableInputField';
import ActionSwitch from '@components/field/ActionSwitch';
import EditActionButton from '@components/field/EditActionButton';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import Wrapper from '@components/wrapper/Wrapper';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import userPermission from '@utils/userPermission';

function ServiceDetail() {
  const { id } = useParams();
  const { generatePermission } = userPermission();

  const [loading, setLoading] = useState(false);
  const [editableForm, setEditableForm] = useState(true);
  const [btnCancelDisable, setBtnCancelDisable] = useState(true);
  const [hasInfo, setHasInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [infoColor, setInfoColor] = useState('');
  const [serviceIsActive, setServiceIsActive] = useState(false);

  const [values, setValues] = useState({
    name: '',
    sku: '',
    description: '',
    variance_value: '',
    regular_price: 0,
    variance_type: '',
    discount_price: 0,
    image_link: '',
    is_active: null,
  });

  // used for error form message
  const [invalidValues, setInvalidValues] = useState({
    name: null,
    sku: null,
    variance_value: null,
    regular_price: null,
  });

  useEffect(() => {
    loadServiceDetail();
  }, []);

  async function fetchServiceDetail() {
    const api = new API('v2');
    const response = await api.get(`v2/intools/service/get-product-by-id/?product_id=${id}`);
    const data = response.data.data[0];

    setValues({
      ...values,
      ['product_id']: data.id,
      ['name']: data.name,
      ['sku']: data.sku,
      ['description']: data.description,
      ['variance_value']: data.variance_value,
      ['regular_price']: data.regular_price,
      ['variance_type']: data.variance_type,
      ['discount_price']: data.discount_price,
      ['image_link']: data.image_link,
      ['is_active']: data.is_active,
    });

    data.is_active === 1 ? setServiceIsActive(true) : setServiceIsActive(false);
  }

  async function loadServiceDetail() {
    setLoading(true);
    fetchServiceDetail();
    setLoading(false);
  }

  const resetEditableForm = () => {
    setEditableForm(!editableForm);
    setBtnCancelDisable(!btnCancelDisable);
  };

  async function handleSubmit() {
    resetEditableForm();

    if (!editableForm) {
      const { name, sku, variance_value, regular_price } = values;

      if (!name) return setInvalidValues({ ...invalidValues, ['name']: true });
      if (!sku) return setInvalidValues({ ...invalidValues, ['sku']: true });
      if (!variance_value) return setInvalidValues({ ...invalidValues, ['variance_value']: true });
      if (Helper.isEmptyField(regular_price)) return setInvalidValues({ ...invalidValues, ['regular_price']: true });

      const payload = {
        ...values,
        regular_price: parseInt(regular_price),
      };
      const api = new API();

      await api
        .put(`api/backoffice_services/edit-product/`, payload)
        .then(() => {
          setHasInfo(true);
          setInfoMessage('Produk berhasil di update');
          setInfoColor('info');

          resetEditableForm();
        })
        .catch(() => {
          setHasInfo(true);
          setInfoMessage('Produk gagal di update, tolong coba lagi');
          setInfoColor('danger');

          resetEditableForm();
        });
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'activeSwitch') {
      const activeService = !serviceIsActive ? 1 : 0;

      setServiceIsActive(!serviceIsActive);
      setValues({ ...values, ['is_active']: activeService });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleCancelEdit = () => {
    fetchServiceDetail();
    resetEditableForm();
  };

  // render main content page
  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <React.Fragment>
        <Wrapper title="Service Detail">
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-primary text-white mt-0">Service Details</CardHeader>
                <CardBody>
                  <Form>
                    <ActionSwitch
                      title=""
                      onChange={handleInputChange}
                      colWidth={3}
                      inputName="activeSwitch"
                      isChecked={serviceIsActive}
                      labelWidth={2}
                      labelChecked="Active"
                      editableForm={editableForm}
                      labelUnchecked="Inactive"
                    />

                    <EditableInputField
                      labelWidth={2}
                      colWidth={3}
                      title="Name"
                      inputName="name"
                      value={values.name}
                      placeholder="Name"
                      onChange={handleInputChange}
                      disabled={editableForm}
                      invalid={invalidValues.name}
                      errorMessage="Name is required"
                    />

                    <EditableInputField
                      labelWidth={2}
                      colWidth={3}
                      title="Part Number"
                      inputName="sku"
                      value={values.sku}
                      placeholder="Part Number"
                      onChange={handleInputChange}
                      disabled={editableForm}
                      invalid={invalidValues.sku}
                      errorMessage="SKU is required"
                    />

                    <FormGroup row>
                      <Label lg={2}>Description</Label>
                      <Col lg={3}>
                        <Input
                          name="description"
                          type="textarea"
                          onChange={handleInputChange}
                          value={values.description}
                          disabled={editableForm}
                        />
                      </Col>
                    </FormGroup>

                    <EditableInputField
                      labelWidth={2}
                      colWidth={3}
                      title="Variance Value"
                      inputName="variance_value"
                      value={values.variance_value}
                      placeholder="Variance Value"
                      onChange={handleInputChange}
                      disabled={editableForm}
                      invalid={invalidValues.variance_value}
                      errorMessage="Variance value is required"
                    />

                    <FormGroup row>
                      <Label lg={2}>Regular Price</Label>
                      <Col lg={3}>
                        <Input
                          name="regular_price"
                          type="number"
                          placeholder="Regular Price"
                          value={values.regular_price}
                          onChange={handleInputChange}
                          invalid={invalidValues.regular_price}
                          disabled={editableForm}
                          pattern="[0-9]{10}"
                        />
                        <FormFeedback>Regular Price is required</FormFeedback>
                      </Col>
                    </FormGroup>

                    {generatePermission('services', 'edit') && (
                      <EditActionButton
                        labelWidth={2}
                        colWidth={3}
                        hasInfo={hasInfo}
                        infoColor={infoColor}
                        infoMessage={infoMessage}
                        btnCancelDisable={btnCancelDisable}
                        handleCancelEdit={handleCancelEdit}
                        handleSubmit={handleSubmit}
                      />
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Wrapper>
      </React.Fragment>
    );
  };

  return renderContent();
}

export default ServiceDetail;
