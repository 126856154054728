import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardBody, Col, FormGroup, FormFeedback, Input, Label, Row, Button } from 'reactstrap';
import Loading from '@components/loading/Loading';
import CustomPagination from '@components/pagination/CustomPagination';
import DataTable from 'react-data-table-component';
import API from '@utils/API';
import moment from 'moment';

const History = (props) => {
  const api = new API('v2');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [sortingData, setSortingData] = useState({});
  const [firstLoad, setFirstLoad] = useState(true);

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        name: 'Waktu',
        selector: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.created_at).isValid() ? moment(row?.created_at).format('D MMM, YYYY') : '-'}
            </span>
            <span className="time">
              {moment(row?.created_at).isValid() ? moment(row?.created_at).format('HH:MM:SS') : '-'}
            </span>
          </div>
        ),
        width: '150px',
        left: true,
        sortable: true,
        sortField: 'created_at',
      },
      {
        name: 'PIC',
        selector: (row) => <span className="subscriptions_list_customer">{row?.customer_name ?? '-'}</span>,
        minWidth: '250px',
        sortable: true,
        sortField: 'name',
      },
      {
        name: 'Tipe Ubahan',
        minWidth: '300px',
        selector: (row) => <span className="subscriptions_list_package">{'Perubahan identitas bengkel'}</span>,
        sortable: false,
        sortField: 'subscription',
        wrap: true,
      },
      {
        name: 'Tipe Ubahan',
        minWidth: '300px',
        selector: (row) => <span className="subscriptions_list_package">{'Perubahan identitas bengkel'}</span>,
        sortable: false,
        sortField: 'subscription',
        wrap: true,
      },
    ],
    []
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchInventory() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/customer-subscriptions/`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
    }
  }

  const getParamData = () => {
    let params = {
      page: page,
      limit: pageLimit,
    };

    if (sortingData) {
      params.sort = sortingData?.sort;
      params.order = sortingData?.order;
    }

    return params;
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleChangeSorting = (column, sortDirection) => {
    setSortingData({
      sort: column?.sortField ?? '',
      order: sortDirection ?? '',
    });
    handleMethodFetchInventory();
  };

  const handleMethodFetchInventory = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchInventory();
    }
  };

  useEffect(() => {
    if (page && pageLimit) {
      fetchInventory();
    }
  }, [page, pageLimit]);

  return (
    <React.Fragment>
      <Card className="card-custom">
        <CardBody className="p-0">
          <Row>
            <Col sm={12} md={12} className="p-0">
              <DataTable
                persistTableHead
                highlightOnHover
                sortServer
                noHeader={true}
                progressPending={loading}
                data={data}
                columns={columns}
                className="table dt-responsive table-custom"
                noDataComponent={noDataComponent}
                progressComponent={<Loading />}
                customStyles={customStylesHeaderTable}
                onSort={handleChangeSorting}
              />
            </Col>
            <Col sm={12} md={12} className="p-0 mt-2">
              {data?.length >= 1 && !loading && (
                <CustomPagination
                  page={page}
                  pageLimit={pageLimit}
                  totalRows={data?.length ?? 0}
                  totalAllRows={totalRows}
                  totalPages={totalPages}
                  handleChangePage={handleChangePage}
                  handleChangeRowPerPage={handleChangeRowPerPage}
                ></CustomPagination>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* )} */}
    </React.Fragment>
  );
};

export default History;
