import classnames from 'classnames';
import Wrapper from '@components/wrapper/Wrapper';
import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody, Row, Col } from 'reactstrap';
import User from '@components/access-control-level/User';
import Role from '@components/access-control-level/Role';
import API from '@utils/API';
import { useHistory, useLocation } from 'react-router-dom';
import Permission from '@components/access-control-level/Permission';
import System from '@components/access-control-level/System';

const AccessControlLevel = () => {
  const api = new API('v2');
  const history = useHistory();
  const queryParams = useLocation().search;
  const activetab = new URLSearchParams(queryParams).get('activetab');

  const [activeTab, setActiveTab] = useState('user');
  const [userData, setUserData] = useState();
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [roleData, setRoleData] = useState();
  const [roleDataLoading, setRoleDataLoading] = useState(false);
  const [permissionData, setPermissionData] = useState();
  const [permissionDataLoading, setPermissionDataLoading] = useState(false);
  const [systemConfigData, setSystemConfigData] = useState();
  const [systemConfigDataLoading, setSystemConfigDataLoading] = useState(false);

  const generateBreadcrumb = () => {
    return [
      {
        id: 1,
        label: 'Access Control Level',
        active: false,
        url: '/acl',
      },
    ];
  };

  const toggleActiveTab = (tab) => {
    const params = new URLSearchParams();

    setActiveTab(tab);
    params.append('activetab', tab);
    history.push({ search: params.toString() });
  };

  const fetchUserData = async () => {
    setUserDataLoading(true);

    api
      .get('v2/intools/users/')
      .then((result) => {
        setUserData(result?.data?.data);
        setUserDataLoading(false);
      })
      .catch(() => {
        setUserDataLoading(false);
      });
  };

  const fetchRoleData = async () => {
    setRoleDataLoading(true);

    api
      .get('v2/intools/roles/')
      .then((result) => {
        setRoleData(result?.data?.data);
        setRoleDataLoading(false);
      })
      .catch(() => {
        setRoleDataLoading(false);
      });
  };

  const fetchPermissionData = async () => {
    setPermissionDataLoading(true);

    api
      .get('v2/intools/permissions/')
      .then((result) => {
        setPermissionData(result?.data?.data);
        setPermissionDataLoading(false);
      })
      .catch(() => {
        setPermissionDataLoading(false);
      });
  };

  const fetchSystemConfigData = async () => {
    setSystemConfigDataLoading(true);

    api
      .get('v2/intools/configs/')
      .then((result) => {
        setSystemConfigData(result?.data?.data);
        setSystemConfigDataLoading(false);
      })
      .catch(() => {
        setSystemConfigDataLoading(false);
      });
  };

  const handleClickUserChangeStatus = async (data) => {
    const params = { is_active: !data?.is_active };

    await api.put(`v2/intools/users/${data?.id}/status/`, params);
  };

  const handleClickUserRow = (id) => {
    history.push(`acl/edit-user/${id}`);
  };

  useEffect(() => {
    if (activeTab === 'user') {
      fetchUserData();
    } else if (activetab === 'role') {
      fetchRoleData();
    } else if (activeTab === 'system') {
      fetchSystemConfigData();
    } else {
      fetchPermissionData();
    }

    if (activetab) {
      setActiveTab(activetab);
    } else {
      setActiveTab('user');
    }
  }, [activeTab]);

  return (
    <Wrapper title="Access Control Level" className="wrapperContent">
      <Nav tabs className="user-role-tabs">
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'user' })} onClick={() => toggleActiveTab('user')}>
            User
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'role' })} onClick={() => toggleActiveTab('role')}>
            Role
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'permission' })}
            onClick={() => toggleActiveTab('permission')}
          >
            Permission
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'system' })} onClick={() => toggleActiveTab('system')}>
            System Configuration
          </NavLink>
        </NavItem>
      </Nav>

      <Card className="card-custom none-border-radius-top card-custom-p-15-15">
        <CardBody className="p-0">
          <Row>
            <Col sm={12} md={12}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="user">
                  <User
                    data={userData}
                    loading={userDataLoading}
                    onChangeStatus={handleClickUserChangeStatus}
                    handleClickUserRow={handleClickUserRow}
                  />
                </TabPane>
                <TabPane tabId="role">
                  <Role data={roleData} loading={roleDataLoading} setRoleData={setRoleData} />
                </TabPane>
                <TabPane tabId="permission">
                  <Permission
                    data={permissionData}
                    loading={permissionDataLoading}
                    setPermissionData={setPermissionData}
                  />
                </TabPane>
                <TabPane tabId="system">
                  <System
                    data={systemConfigData}
                    loading={systemConfigDataLoading}
                    setPermissionData={setSystemConfigData}
                  />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Wrapper>
  );
};

export default AccessControlLevel;
