import React, { useEffect, useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { LeftArrow, RightArrow } from '@components/tabs/TabsScrollArrow';
import map from 'lodash/map';
import { isEmpty } from 'lodash';

const onWheel = (apiObj, ev) => {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
};

function TabsScroll(props) {
  const { onChangeActiveTab, tabs, loading, initialActiveTabs } = props;

  const [tabsData, setTabsData] = useState([]);
  const [activeTab, setActiveTab] = useState({});

  const handleClick = (tab) => {
    if (!loading) {
      if (tab?.id !== activeTab?.id) {
        handleChangeTabs(tab);
      }
    }
  };

  const handleChangeTabs = (tab) => {
    const newTabsData = map(tabsData, (item) => {
      return {
        id: item?.id,
        name: item?.name,
        active: item?.id === tab?.id ? true : false,
      };
    });

    setTabsData(newTabsData);
    setActiveTab(tab);
    onChangeActiveTab(tab, true);
  };

  useEffect(() => {
    setTabsData(tabs);
    if (isEmpty(initialActiveTabs)) {
      setActiveTab(tabs[0]);
    } else {
      setActiveTab(initialActiveTabs);
    }
  }, [initialActiveTabs]);

  return (
    <>
      <div className="wrapper-slider-tabs">
        <ul className="slider-tabs">
          <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel}>
            {tabsData.map((item, index) => {
              let nameClass = item?.id === activeTab?.id ? 'tab-items-slider active' : 'tab-items-slider';
              nameClass = loading ? nameClass + ' disabled' : nameClass;
              nameClass +=
                index === 0
                  ? ' tab-item-slider-fisrt'
                  : index == tabsData?.length - 1
                  ? ' tab-item-slider-last'
                  : ' tab-item-slider';
              return (
                <li
                  className={nameClass}
                  key={item?.id}
                  onClick={() => {
                    handleClick(item);
                  }}
                >
                  {item?.name}
                </li>
              );
            })}
          </ScrollMenu>
        </ul>
      </div>
    </>
  );
}
export default TabsScroll;
