import React, { useState, useEffect } from 'react';
import { Col, Modal, ModalBody, Button, FormGroup, Label, Spinner } from 'reactstrap';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import InputField from '@components/field/InputField';

const AgreementEdit = (props) => {
  const { modal, allowEditDetail, toggle, idWorkshop, idAgreement, onSubmit } = props;

  const api = new API('v2');
  const [component, setComponent] = useState();
  const [serviceName, setServiceName] = useState();
  const [serviceFee, setServiceFee] = useState();
  const [agreedServiceFee, setAgreedServiceFee] = useState();
  const [basePrice, setBasePrice] = useState();
  const [sellPrice, setSellPrice] = useState();
  const [productType, setProductType] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingFetchData, setLoadingFetchData] = useState(false);

  async function fetchDetailAgreement() {
    setLoadingFetchData(true);

    try {
      const response = await api.get(`v2/intools/workshops/${idWorkshop}/otoklix-service-fees/${idAgreement}`);
      defaultData(response?.data?.data ?? {});
      setLoadingFetchData(false);
    } catch (error) {
      defaultData({});
      setLoadingFetchData(false);
    }
  }

  const handleDefaultAgreedServiceFee = () => {
    const agreedFee = Helper.formatMoneyOnField('' + serviceFee ?? 0, 'Rp ');
    setAgreedServiceFee(agreedFee);
  };

  const defaultData = (data) => {
    const agreedFee = Helper.formatMoneyOnField('' + data?.agreed_service_fee ?? 0, 'Rp ');
    setComponent(data?.service?.product_component?.name ?? '');
    setServiceName(data?.service?.service_name ?? '');
    setServiceFee(data?.service?.service_fee ?? 0);
    setAgreedServiceFee(agreedFee);
    setProductType(data?.service?.product_type);
    const SellPrice = Helper.formatMoneyOnField('' + data?.sell_price ?? 0, 'Rp ');
    setSellPrice(SellPrice);
    const BasePrice = Helper.formatMoneyOnField('' + data?.base_price ?? 0, 'Rp ');
    setBasePrice(BasePrice);
  };

  async function submitForm() {
    setLoading(true);

    try {
      const payload = {
        agreed_service_fee: agreedServiceFee?.formatMoney ?? 0,
        base_price: basePrice?.formatMoney ?? 0,
        sell_price: sellPrice?.formatMoney ?? 0,
      };
      await api.put(`v2/intools/workshops/${idWorkshop}/otoklix-service-fees/${idAgreement}`, payload);
      onSubmit(true);
      setLoading(false);
    } catch (error) {
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  const resetData = () => {
    setComponent();
    setServiceName();
    setServiceFee();
    setAgreedServiceFee();
  };

  const cancel = () => {
    toggle();
  };

  useEffect(() => {
    resetData();
    if (modal) {
      fetchDetailAgreement();
    }
  }, [modal]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Edit FBO</div>
        {!loadingFetchData && (
          <div className="content">
            <FormGroup row>
              <Label lg={4}>Component</Label>
              <InputField
                colWidth={8}
                inputName={'component'}
                inputType={'text'}
                disabled={true}
                placeholder="Component"
                value={component}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={4}>Product/Service Name</Label>
              <InputField
                colWidth={8}
                inputName={'service_name'}
                inputType={'text'}
                disabled={true}
                placeholder="Service Name"
                value={serviceName}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={4}>B2C Sell Price</Label>
              <InputField
                colWidth={8}
                inputName={'sell_price'}
                placeholder=""
                disabled={loading}
                value={sellPrice?.formatMoneyString ?? 'Rp 0'}
                onChange={(e) => {
                  let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                  setSellPrice(convertMoney?.formatMoney > 99999999 ? sellPrice : convertMoney);
                }}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={4}>B2B Sell Price</Label>
              <InputField
                colWidth={8}
                inputName={'base_price'}
                placeholder=""
                disabled={loading}
                value={basePrice?.formatMoneyString ?? 'Rp 0'}
                onChange={(e) => {
                  let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                  setBasePrice(convertMoney?.formatMoney > 99999999 ? basePrice : convertMoney);
                }}
              />
            </FormGroup>
            {productType === 'work' && (
              <>
                <FormGroup row>
                  <Label lg={4}>Service Fee</Label>
                  <InputField
                    colWidth={8}
                    inputName={'service_fee'}
                    inputType={'text'}
                    disabled={true}
                    placeholder="Service Fee"
                    value={`Rp. ${Helper.formatMoney(serviceFee)}`}
                  />
                </FormGroup>
                <FormGroup row className="mb-0">
                  <Label lg={4}>Agreed Service Fee</Label>
                  <InputField
                    colWidth={6}
                    inputName={'agreed_service_fee'}
                    inputType={'text'}
                    disabled={loading}
                    placeholder="Agreed Service Fee"
                    value={agreedServiceFee?.formatMoneyString ?? ''}
                    onChange={(e) => {
                      let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                      setAgreedServiceFee(convertMoney?.formatMoney > 99999999 ? agreedServiceFee : convertMoney);
                    }}
                  />

                  <Col lg={2}>
                    <Button className="default-button" onClick={handleDefaultAgreedServiceFee}>
                      Default
                    </Button>
                  </Col>
                </FormGroup>
              </>
            )}
          </div>
        )}

        <div className="action mb-3 mt-3">
          <Button className="button-cancel" onClick={cancel} disabled={loading || loadingFetchData}>
            Batal
          </Button>
          {allowEditDetail && (
            <Button className="button-action" onClick={submitForm} disabled={loading || loadingFetchData}>
              {loading ? <Spinner size="sm" /> : 'Simpan'}
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AgreementEdit;
