import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Label, Row, Col } from 'reactstrap';
import API from '@utils/API';
import InputField from '@components/field/InputField';
import UploadImage from '@components/uploads/UploadImage';

const GroupCampaignAdd = (props) => {
  const { modal, toggle, onSubmit } = props;

  const api = new API('v2');
  const [loading, setLoading] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [errorGroup, setErrorGroup] = useState('');
  const [imageKey, setImageKey] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [loadingUploadImage, setLoadingUploadImage] = useState(false);
  const [errorImage, setErrorImage] = useState('');

  const handleChangeGroup = (e) => {
    const valueGroup = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : groupName;
    validateGroupName(valueGroup);
  };

  const validateGroupName = (value) => {
    let passValidate = true;

    if (value.length == 0) {
      passValidate = false;
      setErrorGroup('Nama Grup tidak boleh kosong');
    } else if (value.length > 100) {
      passValidate = false;
      setErrorGroup('Format Nama Grup maksimal 100 karakter');
    } else {
      setErrorGroup('');
    }

    setGroupName(value);
    return passValidate;
  };

  const validateGroupBanner = (value) => {
    let passValidate = true;

    if (!value || value?.length < 1) {
      passValidate = false;
      setErrorImage('Banner Grup tidak boleh kosong');
    } else {
      setErrorImage('');
    }

    return passValidate;
  };

  const handleLoadingUploadImage = (status) => {
    setLoadingUploadImage(status);
  };

  const handleErrorUploadImage = (msg) => {
    setErrorImage(msg);
  };

  const handleDeleteImage = () => {
    setImageKey(null);
    setImagePreview('');
  };

  const handleUploadImage = (image) => {
    setImageKey(image.key);
    setImagePreview(image.preview);
    setErrorImage('');
  };

  const handleSubmit = () => {
    const passValidateGroupName = validateGroupName(groupName);
    const passValidateGroupBanner = validateGroupBanner(imagePreview);

    if (passValidateGroupName && passValidateGroupBanner) {
      submitForm();
    }
  };

  async function submitForm() {
    toggle();
    setLoading(true);
    try {
      const payload = {
        group_name: groupName ?? '',
        group_image: imageKey ?? '',
      };
      await api.post(`v2/intools/campaigns/groups/`, payload);
      onSubmit(true);
      setLoading(false);
    } catch (error) {
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  const resetData = () => {
    setGroupName('');
    setErrorGroup('');
    setImagePreview('');
    setErrorImage('');
  };

  const cancel = () => {
    toggle();
  };

  useEffect(() => {
    resetData();
  }, [modal]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Tambah Grup Campaign</div>
        <div className="content">
          <Row>
            <Col sm={12} md={12}>
              <FormGroup row className="mb-3">
                <Label lg={4} className="label-required">
                  Nama Grup
                </Label>
                <InputField
                  colWidth={8}
                  inputType={'text'}
                  disabled={loading}
                  placeholder="Masukkan nama grup campaign"
                  value={groupName ?? ''}
                  invalid={errorGroup ? true : false}
                  errorMessage={errorGroup ?? ''}
                  onChange={handleChangeGroup}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={12}>
              <Row className="mb-0">
                <Label lg={4} className="label-required">
                  Banner Grup
                </Label>
                <Col lg={8}>
                  <UploadImage
                    isView={loading}
                    image={imagePreview}
                    error={errorImage}
                    nameSpace={'group_banner'}
                    identifier={'promo-group'}
                    maxSize={3072}
                    maxSizeString={'3MB'}
                    onLoading={handleLoadingUploadImage}
                    onError={handleErrorUploadImage}
                    onDelete={handleDeleteImage}
                    onUpload={handleUploadImage}
                  />
                  <div className="invalid-document">{errorImage}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className="action mb-3 mt-3">
          <Button className="button-cancel" onClick={cancel} disabled={loading}>
            Batal
          </Button>
          <Button className="button-action" onClick={handleSubmit} disabled={loading}>
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default GroupCampaignAdd;
