import React, { useState, useEffect, useMemo } from 'react';
import { Col, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { assign, find, map, isEmpty } from 'lodash';
import qs from 'query-string';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import Loading from '@components/loading/Loading';
import userPermission from '@utils/userPermission';
import DataTable from 'react-data-table-component';
import OtoklixServiceAdd from '@components/masters/otoklix-service/OtoklixServiceAdd';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';

const OtoklixServiceList = (props) => {
  const { onResultNotification } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const history = useHistory();
  const qp = qs.parse(history?.location?.search);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [dataExport, setDataExport] = useState([]);
  const [sortingData, setSortingData] = useState({});
  const [brandOptions, setBrandOptions] = useState([]);
  const [resetSorting, setResetSorting] = useState(false);
  const [disabledExport, setDisabledExport] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [hasModalAddService, setHasModalAddService] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('master', menu) || generatePermission('master', 'all'));
  };

  const allowList = allowPermission('otoklixservicefeeall') || allowPermission('otoklixservicefeelist');
  const allowAdd = allowPermission('otoklixservicefeeall') || allowPermission('otoklixservicefeecreate');
  const allowShow = allowPermission('otoklixservicefeeall') || allowPermission('otoklixservicefeeshow');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        name: 'ID',
        selector: (row) => row?.id ?? '-',
        minWidth: '80px',
        sortable: true,
        sortField: 'id',
      },
      {
        name: 'Product ID',
        selector: (row) => row?.product?.id ?? '-',
        minWidth: '130px',
        sortable: true,
        sortField: 'product_id',
      },
      // {
      //   name: 'Product Brand',
      //   selector: (row) => row?.product?.brand_product?.name ?? '-',
      //   minWidth: '200px',
      //   sortable: true,
      //   sortField: 'brand',
      // },
      {
        name: 'Service Name',
        minWidth: '350px',
        selector: (row) => row?.product?.name ?? '-',
        sortable: true,
        sortField: 'display_name',
        wrap: true,
      },
      {
        name: 'Display Name',
        minWidth: '350px',
        selector: (row) => row?.display_name ?? '-',
        sortable: true,
        sortField: 'display_name',
        wrap: true,
      },
      {
        name: 'Category',
        selector: (row) => row?.product?.product_category?.name ?? '-',
        minWidth: '200px',
        sortable: true,
        sortField: 'category',
      },
      {
        name: 'B2C Sell Price',
        selector: (row) => <span className="product-price">{`Rp. ${Helper.formatMoney(row?.price)}`}</span>,
        minWidth: '200px',
        sortable: true,
        sortField: 'price',
      },
      {
        name: 'Service Fee',
        selector: (row) => <span className="product-price">{`Rp. ${Helper.formatMoney(row?.service_fee)}`}</span>,
        minWidth: '200px',
        sortable: true,
        sortField: 'fee',
      },
      {
        name: 'Status',
        selector: (row) =>
          row.is_active ? (
            <span className="badge-custom badge-custom-success">{'Active'}</span>
          ) : (
            <span className="badge-custom badge-custom-danger">{'Not Active'}</span>
          ),
        minWidth: '120px',
        center: true,
        sortable: true,
        sortField: 'status',
      },
    ],
    [resetSorting]
  );

  const statusOptions = [
    { value: '', label: 'Semua' },
    { value: true, label: 'Aktif' },
    { value: false, label: 'Tidak Aktif' },
  ];

  const dataFilter = [
    {
      id: 'filter_text_search',
      type: 'text',
      name: 'search',
      name_field: 'search',
      value: '',
      placeholder: 'Cari Nama Service',
    },
    {
      id: 'filter_text_display_search',
      type: 'text',
      name: 'display_search',
      name_field: 'display_search',
      value: '',
      placeholder: 'Cari Display Name Service',
    },
    {
      id: 'filter_select_category',
      type: 'select',
      name: 'category',
      name_field: 'category',
      options: categoryOptions,
      value: '',
      placeholder: 'Category',
    },
    {
      id: 'filter_select_status',
      type: 'select',
      name: 'status',
      name_field: 'status',
      options: statusOptions,
      value: '',
      placeholder: 'Status',
    },
  ];

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchService() {
    setLoading(true);
    setDisabledExport(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/master/otoklix-service-fees`, { params });
      setData(response?.data?.data ?? []);
      handleDataExport(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      let msg = error?.response?.data?.error?.message ?? '';
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleDataExport([]);
      handleResultNotification(true, 'fail', 'Gagal mendapatakan list master otoklix service, ' + msg);
    }
  }

  async function fetchBrandOptions() {
    let listBrand = [{ value: '', label: 'Any' }];

    try {
      const response = await api.get(`v2/intools/master/product-brands/all/?sort=name&order=asc`);
      if (response?.data?.data) {
        let resultListBrand = map(response?.data?.data, (item) => {
          return { value: item?.brand_id, label: item?.brand_name ?? '-' };
        });
        resultListBrand.splice(0, 0, { value: '', label: 'Any' });
        listBrand = resultListBrand;
      }

      setBrandOptions(listBrand);
    } catch (error) {
      setBrandOptions(listBrand);
    }
  }

  async function fetchCategories() {
    let listCategory = [{ value: '', label: 'Any' }];

    try {
      const response = await api.get(`v2/intools/products/categories/`);
      if (response?.data?.data?.categories) {
        let resultListCategory = map(response?.data?.data?.categories, (item) => {
          return { value: item?.id, label: item?.name ?? '-' };
        });
        resultListCategory.splice(0, 0, { value: '', label: 'Any' });
        listCategory = resultListCategory;
      }

      setCategoryOptions(listCategory);
    } catch (error) {
      setCategoryOptions(listCategory);
    }
  }

  const getParamData = () => {
    let params = {
      search: filtered?.search ?? '',
      display_search: filtered?.display_search ?? '',
      brand: filtered?.brand?.value ?? '',
      category: filtered?.category?.value ?? '',
      active: filtered?.status?.value ?? '',
      page: page,
      limit: pageLimit,
    };

    if (sortingData) {
      params.sort = sortingData?.sort;
      params.order = sortingData?.order;
    }

    return params;
  };

  const handleDataExport = (dataService) => {
    let arrDataExport = [];

    dataService.forEach((item) => {
      const objDataExport = {
        id: item?.id ?? '-',
        product_id: item?.product?.id ?? '-',
        brand_name: item?.product?.brand_product?.name ?? '-',
        product_name: item?.product?.name ?? '-',
        display_name: item?.display_name ?? '-',
        category: item?.product?.product_category?.name ?? '-',
        sell_price: `Rp. ${Helper.formatMoney(item?.price)}`,
        service_fee: `Rp. ${Helper.formatMoney(item?.service_fee)}`,
        status: item?.is_active ? 'Active' : 'Not Active',
      };

      arrDataExport.push(objDataExport);
    });

    setDataExport(arrDataExport);
    setDisabledExport(false);
  };

  const handleDownloadCSV = () => {
    const keys = [
      'id',
      'product_id',
      'brand_name',
      'product_name',
      'display_name',
      'category',
      'sell_price',
      'service_fee',
      'status',
    ];
    const headers = [
      'ID',
      'Product ID',
      'Brand Name',
      'Service Name',
      'Display Name',
      'Category',
      'Sell Price',
      'Service Fee',
      'Status',
    ];

    Helper.downloadCSV(dataExport, headers, keys);
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const handleChangeSorting = (column, sortDirection) => {
    setSortingData({
      sort: column?.sortField ?? '',
      order: sortDirection ?? '',
    });
  };

  const handleApplyFilters = async (params) => {
    await setFiltered(params);
    if (!isEmpty(qp)) {
      const payload = {
        ...qp,
        search: params?.search,
        display_search: params?.display_search,
        status: params?.status?.value,
        brand: params?.brand?.value,
        category: params?.category?.value,
      };

      history.push({ search: qs.stringify(payload) });
    }
  };

  const handleResetFilters = () => {
    setFilters(dataFilter);
    setFiltered({});
    delete qp.search;
    delete qp.display_search;
    delete qp.status;
    delete qp.brand;
    delete qp.category;
    history.push({ search: qs.stringify(qp) });
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleAddService = () => {
    setHasModalAddService(true);
  };

  const handleDetailService = (row) => {
    if (allowShow) {
      history.push(`/masters/otoklix-service/detail/${row?.id}`);
    }
  };

  const handleSubmitAddService = (idService, status, error) => {
    const errorMsg = error
      ? 'Gagal - Service Otoklix Gagal Ditambahkan, ' + error
      : 'Gagal - Service Otoklix Gagal Ditambahkan';
    setHasModalAddService(false);
    handleResultNotification(
      true,
      status ? 'success' : 'fail',
      status ? 'Berhasil - Service Otoklix Berhasil Ditambahkan' : errorMsg
    );
    if (status) {
      setTimeout(() => {
        history.push(`/masters/otoklix-service/detail/${idService}`);
      }, 1000);
    }
  };

  const handleChangeFetchDropdown = () => {
    if (filters.length >= 3) {
      filters[2].options = categoryOptions;
      setFilters([...filters]);
    }

    setDefaultFilters();
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodfetchService();
  };

  const handleMethodfetchService = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchService();
    }
  };

  const resetService = () => {
    setResetSorting(true);
    setSortingData({});
  };

  async function setDefaultFilters() {
    const withParams =
      qp?.brand || qp?.category || qp?.search || qp?.display_search || qp?.brand == '' || qp?.category == ''
        ? true
        : false;
    const valueStatus =
      qp?.status == 'true' ? true : qp?.status == 'false' ? false : qp?.status == '' ? '' : withParams ? null : true;
    const valueCategory = qp?.category
      ? qp?.category == '-'
        ? qp?.category
        : Number(qp?.category) ?? undefined
      : qp?.category == ''
      ? ''
      : undefined;
    const filteredSearch = qp?.search ?? '';
    const filteredDisplaySearch = qp?.display_search ?? '';
    const filteredStatus = find(statusOptions, { value: valueStatus });
    const filteredCategory = find(categoryOptions, { value: valueCategory ?? {} });

    let paramsFiltered = {};
    if (qp?.search) await assign(paramsFiltered, { search: filteredSearch });
    if (qp?.display_search) await assign(paramsFiltered, { display_search: filteredDisplaySearch });
    if (qp?.status || filteredStatus?.value) await assign(paramsFiltered, { status: filteredStatus });
    if (qp?.category) await assign(paramsFiltered, { category: filteredCategory });

    filters[0].value = filteredSearch;
    filters[1].value = filteredDisplaySearch;
    filters[2].value = filteredCategory;
    filters[3].value = filteredStatus;

    setFilters([...filters]);
    setFiltered(paramsFiltered);
  }

  const toogleAddService = () => setHasModalAddService(!hasModalAddService);

  useEffect(() => {
    if (page && pageLimit && !firstLoad) {
      fetchService();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    if (firstLoad && filtered) {
      fetchService();
    } else {
      resetService();
    }
  }, [filtered]);

  useEffect(() => {
    if (categoryOptions?.length) {
      handleChangeFetchDropdown();
    }
  }, [categoryOptions]);

  useEffect(() => {
    // fetchBrandOptions();
    fetchCategories();
    setFilters(dataFilter);
  }, []);

  return (
    <React.Fragment>
      <OtoklixServiceAdd modal={hasModalAddService} onSubmit={handleSubmitAddService} toggle={toogleAddService} />
      <Col sm={12} md={12} className="p-0 mt-0 section-action">
        {allowAdd && (
          <Button disabled={loading} className="button-action add" onClick={handleAddService}>
            {'Tambah'}
          </Button>
        )}
        {allowList && (
          <Button disabled={disabledExport} className="button-action primary" onClick={handleDownloadCSV}>
            {'Export CSV'}
          </Button>
        )}
      </Col>
      {filters?.length !== 0 && allowList && (
        <Col sm={12} md={12} className="p-0 mt-4">
          <ActionFilterCustom
            filters={filters}
            loading={loading}
            onApplyFilters={handleApplyFilters}
            onResetFilters={handleResetFilters}
          ></ActionFilterCustom>
        </Col>
      )}

      <Col sm={12} md={12} className="p-0 mt-4">
        <DataTable
          persistTableHead
          highlightOnHover
          sortServer
          noHeader={true}
          progressPending={loading}
          data={data}
          className="table dt-responsive table-custom"
          noDataComponent={noDataComponent}
          progressComponent={<Loading />}
          customStyles={customStylesHeaderTable}
          onSort={handleChangeSorting}
          onRowClicked={(row) => handleDetailService(row)}
          columns={allowList ? columns : []}
        />
      </Col>
      <Col sm={12} md={12} className="p-0 mt-2">
        {data?.length >= 1 && !loading && allowList && (
          <CustomPagination
            page={page}
            pageLimit={pageLimit}
            totalRows={data?.length ?? 0}
            totalAllRows={totalRows}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
            handleChangeRowPerPage={handleChangeRowPerPage}
          ></CustomPagination>
        )}
      </Col>
    </React.Fragment>
  );
};

export default OtoklixServiceList;
