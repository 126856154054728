import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

const BookingConfirmationModal = (props) => {
  const { title, status, modal, toggle, handleOnClick } = props;

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <div className="flex-column">
          <div>
            Yakin ingin mengubah status menjadi <strong>{status}</strong>?
          </div>
          <div className="mt-3 d-flex">
            <Button block size="sm" color="danger" className="mr-2" onClick={toggle}>
              Kembali
            </Button>
            <Button block size="sm" color="primary" className="mt-0" onClick={handleOnClick}>
              Konfirmasi
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BookingConfirmationModal;
