import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import GMap from '@components/maps/Gmap';
import Geocode from 'react-geocode';
import Helper from '@helpers/Helper';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { GMAPS_API_KEY } from '@utils/Constants';

const GMapsModal = (props) => {
  const { isOpen, toggle, lat, lng, handleMap, labelInput } = props;

  const [mapValues, setMapValues] = useState();

  const handleChangeCoordinate = (coord, streetAddress, label, source) => {
    const mapValue = {
      latitude: coord?.lat,
      longitude: coord?.lng,
      street_address: streetAddress,
      error_street_address: '',
      label: label,
      source,
    };

    setMapValues(mapValue);
  };

  const handleAutoComplete = async (value) => {
    const response = await Geocode.fromAddress(value?.label);
    const newCoord = Helper.getCoordByAddress(response);

    Geocode.fromLatLng(newCoord.lat, newCoord.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        handleChangeCoordinate(newCoord, address, value, 'search');
      },
      (error) => {
        handleChangeCoordinate(newCoord, '');
      }
    );
  };

  const getCity = (addressArray) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  const handleChangeMap = (e) => {
    const newCoord = {
      lat: e.lat,
      lng: e.lng,
    };

    Geocode.fromLatLng(e.lat, e.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const addressArray = response.results[0].address_components;
        const city = getCity(addressArray);

        handleChangeCoordinate(newCoord, address, city, 'pin-point');
      },
      (error) => {
        handleChangeCoordinate(newCoord, '', '');
      }
    );
  };

  const onOK = () => {
    if (lat !== mapValues?.latitude) handleMap(mapValues);
    toggle();
  };

  const onCancel = () => {
    toggle();
  };

  useEffect(() => {
    Geocode.setApiKey(GMAPS_API_KEY);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setMapValues({
        latitude: lat,
        longitude: lng,
        label: labelInput,
      });
    }, 300);
  }, [lat, labelInput]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
      <ModalBody>
        <h4 className="text-center">Pin Location</h4>
        <FormGroup row>
          <Label lg={1}>Lokasi</Label>
          <Col lg={11}>
            <GooglePlacesAutocomplete
              apiKey={GMAPS_API_KEY}
              selectProps={{
                inputId: 'gmaps-address-modal',
                value: mapValues?.label,
                onChange: (value) => handleAutoComplete(value, mapValues),
                placeholder: 'cari lokasi . . .',
              }}
              minLengthAutocomplete={5}
            />
          </Col>
        </FormGroup>
        <GMap
          labelWidth={0}
          colWidth={12}
          latitude={mapValues?.latitude}
          longitude={mapValues?.longitude}
          withAutoComplete={false}
          handleClickMap={handleChangeMap}
        />
        <Row className="section-action justify-content-center">
          <Button id="gmaps-address-modal-save" className="ml-2 button-action save" onClick={onOK}>
            Simpan
          </Button>
          <Button id="gmaps-address-modal-cancel" className="button-action cancel" onClick={onCancel}>
            Batal
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default GMapsModal;
