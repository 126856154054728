import React, { useEffect, useState } from 'react';
import userPermission from '@utils/userPermission';
import { Card, Col, FormGroup, Label, Row, Input, Button, Spinner, Tooltip } from 'reactstrap';
import API from '@utils/API';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import SelectField from '@components/field/SelectField';
import InputField from '@components/field/InputField';
import CreatableSelect from 'react-select/creatable';
import CopyToClipboard from 'react-copy-to-clipboard';
import PlusFill from '@assets/icons/plus-fill.svg';
import IconCopy from '@assets/icons/copy.svg';
import CustomModal from '@components/modal/CustomModal';
import ConfirmAddInventoryModal from '@components/modal/ConfirmAddInventoryModal';
import { ppnOptions, typeOptions, defaultProductData } from '@utils/Constants';
import Helper from '@helpers/Helper';
import InventoryUploadImage from './InventoryUploadImage';
import { isEmpty } from 'lodash';
import Info from '@assets/icons/info.svg';

const InventoryAddNew = (props) => {
  const { workshopId, workshopSlug, productId, warehouseId, onResultNotification, consignment } = props;
  const api = new API('v2');
  const history = useHistory();
  const { generatePermission } = userPermission();

  const todayFormatted = moment().format('YYYY-MM-DD');

  const customStyles = (isInvalid) => {
    return {
      control: (provided) => ({
        ...provided,
        border: isInvalid ? '1px solid red' : '1px solid #CED4DA',
      }),
    };
  };

  const defaultDataPayload = {
    noPo: '',
    warehouse: '',
    supplierName: '',
    transactionDate: todayFormatted,
    dueDate: '',
  };

  const defaultErrorDataPayload = {
    noPo: false,
    warehouse: false,
    supplierName: false,
    transactionDate: false,
    dueDate: false,
  };

  const [loading, setLoading] = useState(false);
  const [dataPayload, setDataPayload] = useState(defaultDataPayload);
  const [errorDataPayload, setErrorDataPayload] = useState(defaultErrorDataPayload);
  const [productInputList, setProductInputList] = useState([defaultProductData]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [componentOptions, setComponentOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalNewProduct, setModalNewProduct] = useState(false);
  const [modalFailUploadImage, setModalFailUploadImage] = useState(false);
  const [failedCreatePOMessage, setFailedCreatePOMessage] = useState(false);
  const [modalExit, setModalExit] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [typeDiscount, setTypeDiscount] = useState('Percentage');
  const [discountPl, setDiscountPl] = useState(0);
  const [discount, setDiscount] = useState();
  const [ppn, setPpn] = useState(0);
  const [total, setTotal] = useState(0);
  const [memo, setMemo] = useState('');
  const [disableBrand, setDisableBrand] = useState(false);
  const [validateProductList, setValidateProductList] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [isConsignment, setIsConsignment] = useState(consignment == 'true');
  const [marginOptions, setMarginOptions] = useState([]);
  const [infoTooltip, setInfoTooltip] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'inventoryall'));
  };

  const allowList = allowPermission('inventorylist');

  const fetchMarginOptions = async () => {
    await api
      .get('v2/intools/master/margin-options')
      .then((res) => {
        setMarginOptions(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddProduct = () => {
    if (!loading) setProductInputList([...productInputList, defaultProductData]);
  };

  const handleRemoveProduct = (id) => {
    const list = [...productInputList];
    list.splice(id, 1);
    setProductInputList(list);
  };

  const uploadImage = async (transactionId) => {
    const paramsImagedUpload = new FormData();
    paramsImagedUpload.append('transaction_no', transactionId);
    imageList.map((item) => {
      if (item?.file) paramsImagedUpload.append('attachments', item?.file);
    });

    await api
      .post(`v2/intools/purchase-orders/attachments/`, paramsImagedUpload)
      .then((response) => {
        setLoading(false);
        handleResultNotification(true, 'success', 'Success Create PO');
        setTimeout(() => {
          history.push(`/workshops/${workshopId}?activetab=inventory`);
        }, 2000);
      })
      .catch((e) => {
        console.log('error', e);
        const message = `Error : ${e?.response?.data?.error?.message}`;
        setFailedCreatePOMessage(message);
        setModalFailUploadImage(true);
        setLoading(false);
      });
  };

  const handleSubmit = async () => {
    setLoading(true);

    const mappingDataProducts = productInputList.map((item) => ({
      product_id: item?.isNew ? null : item?.productName?.id,
      sku: item?.productCode,
      sku: item?.productCode,
      name: item?.productName?.label,
      type: item?.productType?.value,
      brand_product_id: item?.brand?.value,
      product_category_id: item?.category?.value,
      component_id: item?.component?.value,
      qty: +item?.productQuantity,
      discount: item?.discount ? +item?.discount : 0,
      minimum_qty: +item?.minimumQuantity,
      cogs: Helper.formatDecimal(item?.cogs?.formatMoney),
      sell_price: Helper.formatDecimal(item?.sellPrice?.formatMoney),
      sell_price_ppn: Helper.formatDecimal(item?.sellPricePlusTax?.formatMoney),
      ongkir: Helper.formatDecimal(item?.ongkir?.formatMoney),
      include_ppn: item?.ppn?.value,
      display_name: item?.displayName,
    }));

    const payload = {
      po_number: dataPayload?.noPo,
      warehouse_id: dataPayload?.warehouse?.id,
      workshop_id: workshopId,
      supplier_name: dataPayload?.supplierName,
      transaction_date: dataPayload?.transactionDate + 'T23:00:00',
      due_date: dataPayload?.dueDate + 'T12:00:00',
      total: Helper.formatDecimal(total),
      sub_total: Helper.formatDecimal(subTotal),
      discount_per_lines: Helper.formatDecimal(discountPl),
      discount_type: typeDiscount === 'Percentage' ? 'Percent' : 'Value',
      discount: discount ? Helper.removeMoneyFormat(discount) : 0,
      products: [...mappingDataProducts],
      tax: Helper.formatDecimal(ppn),
      is_consignment: dataPayload?.isConsignment || isConsignment,
      memo: memo,
    };

    await api
      .post('v2/intools/purchase-orders/', payload)
      .then((res) => {
        if (+res?.status === 200) {
          setLoading(false);
          setModalConfirm(false);
          const transactionId = res?.data?.data?.id;
          const checkImage = imageList.filter((item) => item.isEmpty === false);
          uploadImage(dataPayload?.noPo);
        }
      })
      .catch((err) => {
        console.error('Error:', err);

        const message = err?.response?.data?.error?.message
          ? err?.response?.data?.error?.message
          : `Error: ${' '}${err?.message}`;
        handleResultNotification(true, 'fail', message);
        setModalConfirm(false);
        setLoading(false);
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }, 1000);
      });
  };

  const clearValidateData = () => {
    setErrorDataPayload(defaultErrorDataPayload);
    setValidateProductList(false);
  };

  const validateDataEmpty = (value) => {
    if (validateProductList && (value === '' || value === undefined)) {
      return true;
    } else {
      return false;
    }
  };

  const validateData = () => {
    setValidateProductList(true);
    let tempDataError;

    for (const key in dataPayload) {
      if (dataPayload.hasOwnProperty(key) && (dataPayload[key] === '' || dataPayload[key] === undefined)) {
        errorDataPayload[key] = true;
        tempDataError = { ...errorDataPayload };
        setErrorDataPayload(tempDataError);
        Helper.scrollToTop();
      }
    }

    const propsToCheck = [
      'productName',
      'productType',
      'displayName',
      'productQuantity',
      'minimumQuantity',
      'cogs',
      'ppn',
    ];
    const emptyFields = productInputList.filter((item) =>
      propsToCheck.some((prop) => item[prop] === '' || item[prop] === undefined)
    );
    if (emptyFields.length > 0) {
      const emptyProps = emptyFields.map((item) =>
        propsToCheck.find((prop) => item[prop] === '' || item[prop] === undefined)
      );
      tempDataError = { ...errorDataPayload, emptyProps: true };
      setErrorDataPayload(tempDataError);
      handleResultNotification(true, 'fail', 'Please fill in all required fields.');
      Helper.scrollToTop();
    }

    const checkNewProduct = productInputList.filter((item) => {
      return item.isNew;
    });

    if (tempDataError === undefined) {
      Helper.scrollToTop();
      if (+checkNewProduct.length > 0) {
        setModalNewProduct(true);
      } else {
        setModalConfirm(true);
      }
    }
  };

  const handleCheckForm = async () => {
    validateData();
  };

  const handleChangeDataPayload = (value, type) => {
    if (type === 'noPo') {
      const tempData = { ...dataPayload, noPo: value };
      if (value !== '') {
        const tempErrorData = { ...errorDataPayload, noPo: false };
        setErrorDataPayload(tempErrorData);
      } else {
        const tempErrorData = { ...errorDataPayload, noPo: true };
        setErrorDataPayload(tempErrorData);
      }
      setDataPayload(tempData);
    }
    if (type === 'supplierName') {
      const tempData = { ...dataPayload, supplierName: value };
      if (value !== '') {
        const tempErrorData = { ...errorDataPayload, supplierName: false };
        setErrorDataPayload(tempErrorData);
      } else {
        const tempErrorData = { ...errorDataPayload, supplierName: true };
        setErrorDataPayload(tempErrorData);
      }
      setDataPayload(tempData);
    }
  };

  const handleChangeWarehouse = (value) => {
    if (value !== '') {
      const tempErrorData = { ...errorDataPayload, warehouse: false };
      setErrorDataPayload(tempErrorData);
    } else {
      const tempErrorData = { ...errorDataPayload, warehouse: true };
      setErrorDataPayload(tempErrorData);
    }
    const tempData = { ...dataPayload, warehouse: value };
    setDataPayload(tempData);
  };

  const handleChangeDueDate = (value) => {
    if (value !== '') {
      const tempErrorData = { ...errorDataPayload, dueDate: false };
      setErrorDataPayload(tempErrorData);
    } else {
      const tempErrorData = { ...errorDataPayload, dueDate: true };
      setErrorDataPayload(tempErrorData);
    }
    const tempData = { ...dataPayload, dueDate: value };
    setDataPayload(tempData);
  };

  const handleChangeConsignment = () => {
    const tempData = { ...dataPayload, isConsignment: !isConsignment };
    setIsConsignment(!isConsignment);
    setDataPayload(tempData);
  };

  const handleAutoFill = (data, value, index, claerField = true) => {
    let updatedData = {};
    const tempProductInput = [...productInputList];
    const filteredBrand = brandOptions.filter((item) => {
      return +item.value === +data.product_brand_id;
    });
    const filteredProductType = typeOptions.filter((item) => {
      return item.value === data.product_type;
    });
    const filteredCategory = categoryOptions.filter((item) => {
      return +item.value === +data.product_category_id;
    });
    const filteredComponent = componentOptions.filter((item) => {
      return +item.value === +data.product_component.id;
    });

    const formattedSellPrice = Helper.formatMoneyOnField('' + Math.floor(+data?.inventory?.sell_price), 'IDR ');
    const hasInvetory = data?.inventory;

    if (claerField) {
      updatedData = {
        ...productInputList[index],
        productName: value,
        displayName: data?.product_display_name,
        productCode: data?.product_sku,
        brand: filteredBrand[0],
        category: filteredCategory[0],
        component: filteredComponent[0],
        productType: filteredProductType[0],
        productQuantity: '',
        minimumQuantity: +data?.inventory?.mimimum_qty !== NaN ? +data?.inventory?.mimimum_qty : '',
        sellPrice: +data?.inventory?.sell_price > 0 ? formattedSellPrice : 0,
        sellPricePlusTax: +data?.inventory?.sell_price > 0 ? formattedSellPrice : 0,
        cogs: 0,
        discount: '',
        ppn: '',
        isNew: false,
        isDisabledMinQty: data?.inventory?.mimimum_qty !== undefined ? true : false,
        isSellPrice: data?.inventory?.sell_price !== undefined ? true : false,
      };
    } else {
      let dataMinQty;
      let dataSellPrice;
      if (hasInvetory === null) {
        dataMinQty = productInputList[index]?.minimumQuantity;
        dataSellPrice = productInputList[index]?.sellPrice;
      } else {
        dataMinQty = +data?.inventory?.mimimum_qty !== NaN ? +data?.inventory?.mimimum_qty : '';
        dataSellPrice = +data?.inventory?.sell_price > 0 ? formattedSellPrice : 0;
      }
      updatedData = {
        ...productInputList[index],
        productName: value,
        displayName: data?.product_display_name,
        productCode: data?.product_sku,
        brand: filteredBrand[0],
        category: filteredCategory[0],
        component: filteredComponent[0],
        productType: filteredProductType[0],
        productQuantity: productInputList[index]?.productQuantity ?? '',
        minimumQuantity: dataMinQty,
        sellPrice: dataSellPrice,
        sellPricePlusTax: dataSellPrice,
        cogs: productInputList[index]?.cogs ?? '',
        discount: productInputList[index]?.discount ?? '',
        ppn: productInputList[index]?.ppn ?? '',
        isNew: false,
        isDisabledMinQty: data?.inventory?.mimimum_qty !== undefined ? true : false,
        isSellPrice: data?.inventory?.sell_price !== undefined ? true : false,
      };
    }

    tempProductInput[index] = updatedData;
    setProductInputList(tempProductInput);
  };

  const getDetailProduct = async (value, index, warehouse_id, claerField = true) => {
    const url =
      warehouse_id !== undefined
        ? `v2/intools/master/products/${value.id}/?warehouse_id=${warehouse_id}`
        : `v2/intools/master/products/${value.id}/`;
    await api
      .get(url)
      .then(async (res) => {
        let dt = res?.data?.data;
        handleAutoFill(dt, value, index, claerField);
      })
      .catch((err) => {
        console.log(err);
        handleResultNotification(true, 'fail', `Error: ${' '}${err?.message}`);
      });
  };

  const getProductList = async () => {
    await api
      .get('v2/intools/products/all?origin=create-otoklix-inventory')
      .then(async (res) => {
        let dt = res?.data?.data?.products;
        const transformedData = [];
        dt.map((item) => {
          if (item.id !== '-') {
            transformedData.push({
              id: item.id,
              value: item.id,
              label: item.name,
              categoryId: item.product_category_id,
              type: item.type,
            });
          }
        });
        setProductOptions(transformedData);
      })
      .catch((err) => {
        console.log(err);
        handleResultNotification(true, 'fail', `Error: ${' '}${err?.message}`);
      });
  };

  const getCategoryList = async () => {
    await api
      .get('v2/intools/products/categories/')
      .then(async (res) => {
        let dt = res?.data?.data?.categories;
        const transformedData = [];
        dt.map((item) => {
          if (item.id !== '-') {
            transformedData.push({
              value: item.category_id ?? item.id,
              label: item.name,
            });
          }
        });
        setCategoryOptions(transformedData);
      })
      .catch((err) => {
        console.log(err);
        handleResultNotification(true, 'fail', `Error: ${' '}${err?.message}`);
      });
  };

  const getBrandList = async () => {
    await api
      .get('v2/intools/master/product-brands/all/?sort=name&order=asc')
      .then(async (res) => {
        let dt = res?.data?.data;
        const transformedData = [];
        dt.map((item) => {
          if (item.brand_id !== '-') {
            transformedData.push({
              value: item.brand_id,
              label: item.brand_name,
            });
          }
        });
        setBrandOptions(transformedData);
      })
      .catch((err) => {
        console.log(err);
        handleResultNotification(true, 'fail', `Error: ${' '}${err?.message}`);
      });
  };

  const getComponentList = async () => {
    await api
      .get('v2/intools/packages/components')
      .then(async (res) => {
        let dt = res?.data?.data?.components;
        const transformedData = [];
        dt.map((item) => {
          if (item.id !== '-') {
            transformedData.push({
              value: item.id,
              label: item.name,
            });
          }
        });
        setComponentOptions(transformedData);
      })
      .catch((err) => {
        console.log(err);
        handleResultNotification(true, 'fail', `Error: ${' '}${err?.message}`);
      });
  };

  const getWarehouseList = async () => {
    await api
      .get('v2/intools/warehouses')
      .then(async (res) => {
        let dt = res?.data?.data;
        const transformedData = [];
        dt.map((item) => {
          if (item.id !== '-') {
            transformedData.push({
              id: item?.id,
              value: item.id,
              label: item.name,
              slug: item.slug,
            });
          }
        });
        setWarehouseOptions(transformedData);
      })
      .catch((err) => {
        console.log(err);
        handleResultNotification(true, 'fail', `Error: ${' '}${err?.message}`);
      });
  };

  const handleChangeSelectInventory = (value, index, type) => {
    const tempProductInput = [...productInputList];
    let updatedData = { ...productInputList[index] };
    if (type === 'brand') updatedData = { ...productInputList[index], brand: value };
    if (type === 'category') updatedData = { ...productInputList[index], category: value };
    if (type === 'component') updatedData = { ...productInputList[index], component: value };
    if (type === 'margin') {
      const sellPrice = Helper.PriceGenerator(
        updatedData?.cogs?.formatMoney,
        value,
        productInputList[index]?.ongkir?.formatMoney
      ).totalWithoutPPN;
      const sellPricePlusTax = Helper.PriceGenerator(
        updatedData?.cogs?.formatMoney,
        value,
        productInputList[index]?.ongkir?.formatMoney
      ).totalWithPPN;
      let valueSellPrice = {};
      let valueSellPricePlusTax = {};

      try {
        valueSellPricePlusTax = Helper.formatMoneyOnField(sellPricePlusTax, 'IDR');
        valueSellPrice = Helper.formatMoneyOnField(sellPrice, 'IDR');
      } catch (error) {
        valueSellPricePlusTax = {
          formatMoney: parseInt(sellPricePlusTax),
          formatIsMoney: true,
          formatMoneyString: `IDR ${Helper.formatMoney(sellPricePlusTax)}`,
        };
        valueSellPrice = {
          formatMoney: parseInt(sellPrice),
          formatIsMoney: true,
          formatMoneyString: `IDR ${Helper.formatMoney(sellPrice)}`,
        };
      }

      updatedData = {
        ...productInputList[index],
        sellPricePlusTax: valueSellPricePlusTax,
        sellPrice: valueSellPrice,
        margin: value,
      };
    }
    if (type === 'ppn') updatedData = { ...productInputList[index], ppn: value };
    if (type === 'productType') {
      if (value?.value === 'work') {
        const otoklixBrand = {
          value: 62,
          label: 'Otoklix',
        };
        setDisableBrand(true);
        updatedData = { ...productInputList[index], productType: value, brand: otoklixBrand };
      } else {
        setDisableBrand(false);
        updatedData = { ...productInputList[index], productType: value, brand: {} };
      }
    }
    tempProductInput[index] = updatedData;
    setProductInputList(tempProductInput);
  };

  const inputPercent = (value) => {
    let tempVal = 0;
    if (+value > 0 && +value < 101) {
      return (tempVal = +value);
    } else if (+value > 100) {
      return (tempVal = 100);
    } else {
      return (tempVal = 0);
    }
  };

  const handleChangeInputInventory = (value, index, type) => {
    const tempProductInput = [...productInputList];
    const checkSellPrice = productInputList[index].isSellPrice;
    let updatedData = { ...tempProductInput[index] };

    if (type === 'cogs' || type === 'ongkir') {
      if (checkSellPrice) {
        updatedData = {
          ...productInputList[index],
          [type]: value,
        };
      } else {
        const sellPrice = Helper.PriceGenerator(value, updatedData?.margin).totalWithoutPPN;
        const sellPricePlusTax = Helper.PriceGenerator(value, updatedData?.margin).totalWithPPN;
        let valueSellPrice = {};
        let valueSellPricePlusTax = {};

        try {
          valueSellPricePlusTax = Helper.formatMoneyOnField(sellPricePlusTax, 'IDR');
          valueSellPrice = Helper.formatMoneyOnField(sellPrice, 'IDR');
        } catch (error) {
          valueSellPricePlusTax = {
            formatMoney: parseInt(sellPricePlusTax),
            formatIsMoney: true,
            formatMoneyString: `IDR ${Helper.formatMoney(sellPricePlusTax)}`,
          };
          valueSellPrice = {
            formatMoney: parseInt(sellPrice),
            formatIsMoney: true,
            formatMoneyString: `IDR ${Helper.formatMoney(sellPrice)}`,
          };
        }
        updatedData = {
          ...productInputList[index],
          [type]: value,
          sellPricePlusTax: valueSellPricePlusTax,
          sellPrice: valueSellPrice,
          margin: {},
        };
      }
    }

    if (type === 'productQuantity')
      updatedData = { ...productInputList[index], productQuantity: Helper.inputNumber(value, 9999) };
    if (type === 'minimumQuantity')
      updatedData = { ...productInputList[index], minimumQuantity: Helper.inputNumber(value, 9999) };
    if (type === 'productCode') updatedData = { ...productInputList[index], productCode: value };
    if (type === 'discount') updatedData = { ...productInputList[index], discount: inputPercent(value) };
    if (type === 'displayName') updatedData = { ...productInputList[index], displayName: value };
    tempProductInput[index] = updatedData;

    setProductInputList([...tempProductInput]);
  };

  const handleChangeProductName = (value, index) => {
    if (value?.label) {
      const filteredProduct = productOptions.filter((item) => {
        return +item.value === +value.value;
      });
      if (+filteredProduct.length > 0) {
        const tempProductInput = [...productInputList];
        const updatedData = { ...productInputList[index], productName: value, isNew: false };
        tempProductInput[index] = updatedData;
        setProductInputList(tempProductInput);
        getDetailProduct(value, index, dataPayload?.warehouse?.id);
      }
    }
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };
    onResultNotification(notification);
  };

  const handleNewInventory = (e, index) => {
    const tempDataProduct = {
      label: e,
      value: e,
    };
    const tempProductInput = [...productInputList];
    const updatedData = { ...defaultProductData, productName: tempDataProduct, isNew: true, cogs: '' };
    tempProductInput[index] = updatedData;
    setProductInputList(tempProductInput);
  };

  const handleChangeTypeDiscount = () => {
    if (typeDiscount === 'Percentage') setTypeDiscount('Nominal');
    if (typeDiscount === 'Nominal') setTypeDiscount('Percentage');
  };

  const calculateTotal = () => {
    let tempDiscountPerLine = 0;
    let tempGeneralDiscount = 0;
    let tempTotalDiscountPerLine = 0;
    let tempTax = 0;
    let tempSubTotal = productInputList.reduce((acc, product) => {
      const { cogs, productQuantity, discount: productDiscount } = product;
      const cogsProductQuantity = cogs.formatMoney * productQuantity;
      tempTotalDiscountPerLine += (cogsProductQuantity * productDiscount) / 100;
      return acc + cogsProductQuantity;
    }, 0);
    let subTotalWithDiscountPerLine = tempSubTotal - tempTotalDiscountPerLine;

    productInputList.map((item) => {
      const { ppn, cogs, discount: productDiscount, productQuantity } = item;
      let amountPerLine = cogs.formatMoney * productQuantity;
      let discountPerLine = getDiscountPerLine(productDiscount, amountPerLine) || 0;
      let discountGeneralPerLine =
        getGeneralDiscountPerLine(
          typeDiscount,
          discount,
          subTotalWithDiscountPerLine,
          amountPerLine,
          discountPerLine
        ) || 0;
      let priceAfterDiscount = amountPerLine - discountPerLine - discountGeneralPerLine;
      let taxPerLine = getTaxPerLine(priceAfterDiscount, ppn?.value);

      tempDiscountPerLine += discountPerLine;
      tempGeneralDiscount += discountGeneralPerLine;
      tempTax += taxPerLine;
    });

    let tempTotal = tempSubTotal - tempDiscountPerLine - tempGeneralDiscount + tempTax;

    setSubTotal(tempSubTotal);
    setDiscountPl(tempDiscountPerLine);
    setPpn(tempTax);
    setTotal(tempTotal);
  };

  const getTaxPerLine = (priceAfterDiscount, ppn) => {
    if (ppn) {
      return (priceAfterDiscount * 11) / 100;
    }
    return 0;
  };

  const getDiscountPerLine = (discountPerLine, amountPerLine) => {
    return (amountPerLine * discountPerLine) / 100;
  };

  const getGeneralDiscountPerLine = (discountType, discountValue, subTotal, amountPerLine, discountPerLine) => {
    if (discountType == 'Percentage') {
      return ((amountPerLine - discountPerLine) * discountValue) / 100;
    } else {
      let portion = (amountPerLine - discountPerLine) / subTotal;
      return discountValue * portion;
    }
  };

  const handleModalNewProduct = () => {
    setModalNewProduct(false);
    setModalConfirm(true);
    Helper.scrollToTop();
  };

  const handleChangeDiscount = (e) => {
    if (typeDiscount === 'Percentage') {
      setDiscount(inputPercent(e));
    } else {
      const tempVal = Helper.formatMoneyOnField(e, '');
      setDiscount(tempVal.formatMoney);
    }
  };

  const handleCopyClipBoard = () => {
    handleResultNotification(true, 'success', 'No PO berhasil disalin', true);
  };

  useEffect(() => {
    if (productId !== undefined && productId !== null && +productOptions.length > 0) {
      const filteredProduct = productOptions.filter((item) => {
        return +item.id === +productId;
      });
      handleChangeProductName(filteredProduct[0], 0);
      getDetailProduct(filteredProduct[0], 0, dataPayload?.warehouse?.id);
    }
  }, [productId, productOptions]);

  useEffect(() => {
    if (warehouseId !== undefined && warehouseId !== null && +warehouseOptions?.length > 0) {
      const filteredWarehouse = warehouseOptions.filter((item) => {
        return +item.id === +warehouseId;
      });
      const dataWarehouse = filteredWarehouse[0];
      handleChangeWarehouse(dataWarehouse);
    }
    if (+warehouseOptions?.length > 0 && workshopSlug !== undefined && workshopSlug !== null) {
      const filteredWarehouse = warehouseOptions.filter((item) => {
        return item?.slug === workshopSlug;
      });
      const dataWarehouse = filteredWarehouse[0];
      handleChangeWarehouse(dataWarehouse);
    }
  }, [warehouseId, workshopSlug, warehouseOptions]);

  useEffect(() => {
    if (dataPayload?.warehouse?.id !== undefined) {
      if (+productInputList.length > 0) {
        productInputList.map((item, index) => {
          if (!item?.isNew && item?.productName?.id !== undefined) {
            getDetailProduct(item?.productName, index, dataPayload?.warehouse?.id, false);
          }
        });
      }
    }
  }, [dataPayload]);

  useEffect(() => {
    calculateTotal();
    clearValidateData();
  }, [productInputList, discount, typeDiscount]);

  useEffect(() => {
    if (!modalConfirm) setLoading(false);
  }, [modalConfirm]);

  useEffect(() => {
    getWarehouseList();
    getBrandList();
    getComponentList();
    getProductList();
    getCategoryList();
    fetchMarginOptions();
  }, []);

  return (
    <React.Fragment>
      <Card style={{ padding: 30 }}>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <FormGroup row>
              <Label lg={4} className="label-required">
                No PO
              </Label>
              <InputField
                colWidth={8}
                name="no_po"
                placeholder="Input No"
                closeMenuOnSelect={true}
                value={dataPayload?.noPo}
                disabled={loading}
                errorMessage={errorDataPayload?.noPo ? 'No PO is Required' : ''}
                invalid={errorDataPayload?.noPo}
                onChange={(e) => handleChangeDataPayload(e.target.value, 'noPo')}
              />
            </FormGroup>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <FormGroup row>
              <Label lg={4} className="label-required">
                Warehouse
              </Label>
              <SelectField
                colWidth={8}
                name="warehouse"
                placeholder="Select Warehouse"
                closeMenuOnSelect={true}
                value={dataPayload?.warehouse}
                options={warehouseOptions}
                disabled
                invalid={errorDataPayload?.warehouse}
                errorMessage={errorDataPayload?.warehouse ? 'Warehouse is Required' : ''}
                onChange={handleChangeWarehouse}
              />
            </FormGroup>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <FormGroup row>
              <Label lg={4} className="label-required">
                Supplier Name
              </Label>
              <InputField
                colWidth={8}
                name="supplier_name"
                placeholder="Input Supplier Name"
                closeMenuOnSelect={true}
                value={dataPayload?.supplierName}
                disabled={loading}
                errorMessage={errorDataPayload?.supplierName ? 'Supplier Name is Required' : ''}
                invalid={errorDataPayload?.supplierName}
                onChange={(e) => handleChangeDataPayload(e.target.value, 'supplierName')}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <FormGroup row>
              <Label lg={4} className="label-required">
                Transaction Date
              </Label>
              <Col lg={8}>
                <Input type="date" disabled value={todayFormatted} />
              </Col>
            </FormGroup>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <FormGroup row>
              <Label lg={4} className="label-required">
                Due Date
              </Label>
              <Col lg={8}>
                <Input
                  type="date"
                  placeholder="Due Date"
                  disabled={loading}
                  min={todayFormatted}
                  onChange={(e) => handleChangeDueDate(e.target.value)}
                  invalid={errorDataPayload?.dueDate}
                />
                {errorDataPayload?.dueDate && <div className="pt-1 text-danger">Due Date is Required</div>}
              </Col>
            </FormGroup>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <FormGroup row>
              <Label lg={4}>Consignment</Label>
              <Input
                lg={8}
                type="checkbox"
                name="isConsignment"
                disabled={consignment == null ? false : true}
                checked={isConsignment}
                style={{
                  position: 'unset',
                  marginTop: 'unset',
                  marginLeft: '10px',
                }}
                onChange={handleChangeConsignment}
              />
            </FormGroup>
          </Col>
        </Row>
        {productInputList.map((item, i) => {
          return (
            <Card key={i} className="card-product-list">
              <Row>
                <Col lg={12} className="d-flex justify-content-between">
                  <h4 className="m-0">Produk {i + 1}</h4>
                  {+productInputList.length > 1 && (
                    <Button
                      className="btn btn-warning btn btn-secondary"
                      onClick={() => handleRemoveProduct(i)}
                      disabled={loading}
                    >
                      Hapus Data
                    </Button>
                  )}
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5} className="label-required">
                      Product Name
                    </Label>
                    <Col lg={7}>
                      <CreatableSelect
                        required
                        options={productOptions}
                        title="Product Name"
                        placeholder="Select Product"
                        isDisabled={loading}
                        value={item.productName}
                        getOptionLabel={(e) => e?.label}
                        getOptionValue={(e) => e?.value}
                        onChange={(e) => handleChangeProductName(e, i)}
                        onCreateOption={(e) => handleNewInventory(e, i)}
                        formatCreateLabel={(e) => {
                          return (
                            <div className="dropdown-create-product">
                              <img src={PlusFill} />
                              <div className="ml-3">{`Add New '${e}'`}</div>
                            </div>
                          );
                        }}
                        styles={customStyles(validateDataEmpty(item.productName))}
                      />
                      {validateDataEmpty(item.productName) && (
                        <div className="pt-1 text-danger">Product Name is Required</div>
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5} className="label-required">
                      Component
                    </Label>
                    <SelectField
                      colWidth={7}
                      name="component"
                      placeholder="Select Component"
                      options={componentOptions}
                      value={item.component}
                      onChange={(e) => handleChangeSelectInventory(e, i, 'component')}
                      closeMenuOnSelect={true}
                      disabled={loading || !item?.isNew}
                      errorMessage={validateDataEmpty(item.component) ? 'Component is Required' : ''}
                      invalid={validateDataEmpty(item.component)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5}>Ongkir</Label>
                    <InputField
                      colWidth={7}
                      name="ongkir"
                      placeholder="Input Ongkir"
                      disabled={loading}
                      value={item?.ongkir?.formatMoneyString ?? ''}
                      onChange={(e) =>
                        handleChangeInputInventory(Helper.formatMoneyOnField(e.target.value, 'IDR '), i, 'ongkir')
                      }
                      errorMessage={validateDataEmpty(item.ongkir) ? 'Ongkir is Required' : ''}
                      invalid={validateDataEmpty(item.ongkir)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5}>
                      <div className="ml-3">
                        <img className="icon-quest" id="InfoTooltip" src={Info} width="14" />
                        Part Number
                      </div>
                    </Label>
                    <InputField
                      colWidth={7}
                      name="product_code"
                      placeholder="Input Part Number"
                      value={item?.productCode}
                      onChange={(e) => handleChangeInputInventory(e.target.value, i, 'productCode')}
                      disabled={loading || !item?.isNew}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5} className="label-required">
                      Category
                    </Label>
                    <SelectField
                      colWidth={7}
                      name="category"
                      placeholder="Select Category"
                      closeMenuOnSelect={true}
                      disabled={loading || !item?.isNew}
                      options={categoryOptions}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      onChange={(e) => handleChangeSelectInventory(e, i, 'category')}
                      value={item?.category}
                      errorMessage={validateDataEmpty(item.category) ? 'Category is Required' : ''}
                      invalid={validateDataEmpty(item.category)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5} className="label-required">
                      COGS
                    </Label>
                    <InputField
                      colWidth={7}
                      name="cogs"
                      placeholder="Input COGS"
                      disabled={loading}
                      value={item?.cogs?.formatMoneyString ?? ''}
                      onChange={(e) =>
                        handleChangeInputInventory(Helper.formatMoneyOnField(e.target.value, 'IDR '), i, 'cogs')
                      }
                      errorMessage={validateDataEmpty(item.cogs) ? 'COGS is Required' : ''}
                      invalid={validateDataEmpty(item.cogs)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5} className="label-required">
                      Product Type
                    </Label>
                    <SelectField
                      colWidth={7}
                      name="product_type"
                      placeholder="Input Product Type"
                      options={typeOptions}
                      onChange={(e) => handleChangeSelectInventory(e, i, 'productType')}
                      value={item?.productType}
                      disabled={loading || !item?.isNew}
                      errorMessage={validateDataEmpty(item.productType) ? 'Product Type is Required' : ''}
                      invalid={validateDataEmpty(item.productType)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5} className="label-required">
                      Product Quantity
                    </Label>
                    <InputField
                      colWidth={7}
                      name="product_quantity"
                      placeholder="Input Product Quantity"
                      value={item?.productQuantity}
                      onChange={(e) => handleChangeInputInventory(e.target.value, i, 'productQuantity')}
                      disabled={loading}
                      errorMessage={validateDataEmpty(item.productQuantity) ? 'Product Quantity is Required' : ''}
                      invalid={validateDataEmpty(item.productQuantity)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5} className="label-required">
                      Margin
                    </Label>
                    <SelectField
                      colWidth={7}
                      name="margin"
                      placeholder="Select Margin"
                      options={marginOptions}
                      getOptionLabel={(e) => e.margin_name}
                      getOptionValue={(e) => e.margin_value}
                      value={!isEmpty(item?.margin) ? item?.margin : ''}
                      onChange={(e) => handleChangeSelectInventory(e, i, 'margin')}
                      closeMenuOnSelect={true}
                      disabled={loading}
                      errorMessage={validateDataEmpty(item?.margin) ? 'Margin is Required' : ''}
                      invalid={validateDataEmpty(item?.margin)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5} className="label-required">
                      Brand
                    </Label>
                    <SelectField
                      colWidth={7}
                      name="brand"
                      placeholder="Select Brand"
                      closeMenuOnSelect={true}
                      options={brandOptions}
                      value={item?.brand}
                      onChange={(e) => handleChangeSelectInventory(e, i, 'brand')}
                      disabled={loading || !item?.isNew || disableBrand}
                      errorMessage={validateDataEmpty(item.brand) ? 'Brand is Required' : ''}
                      invalid={validateDataEmpty(item.brand)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5} className="label-required">
                      Minimum Quantity
                    </Label>
                    <InputField
                      colWidth={7}
                      name="minimum_quantity"
                      placeholder="Input Minimum Quantity"
                      closeMenuOnSelect={true}
                      disabled={loading || item?.isDisabledMinQty}
                      value={isNaN(item?.minimumQuantity) ? '' : item?.minimumQuantity}
                      onChange={(e) => handleChangeInputInventory(e.target.value, i, 'minimumQuantity')}
                      errorMessage={validateDataEmpty(item.minimumQuantity) ? 'Minimum is Required' : ''}
                      invalid={validateDataEmpty(item.minimumQuantity)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5} className="">
                      B2C Sell Price
                    </Label>
                    <InputField
                      colWidth={7}
                      name="sell_price"
                      placeholder="Input B2C Sell Price"
                      value={item?.sellPricePlusTax?.formatMoneyString ?? ''}
                      disabled={true}
                      errorMessage={validateDataEmpty(item.sellPrice) ? 'B2C Sell Price is Required' : ''}
                      invalid={validateDataEmpty(item.sellPrice)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5} className="label-required">
                      Display Name
                    </Label>
                    <InputField
                      colWidth={7}
                      name="display_name"
                      value={item?.displayName}
                      onChange={(e) => handleChangeInputInventory(e.target.value, i, 'displayName')}
                      placeholder="Input Display Name"
                      disabled={loading || !item?.isNew}
                      errorMessage={validateDataEmpty(item.displayName) ? 'Display Name is Required' : ''}
                      invalid={validateDataEmpty(item.displayName)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5} className="">
                      Discount
                    </Label>
                    <InputField
                      colWidth={7}
                      name="discount"
                      placeholder="Input Discount"
                      closeMenuOnSelect={true}
                      disabled={loading}
                      value={item?.discount}
                      onChange={(e) => handleChangeInputInventory(e.target.value, i, 'discount')}
                      prepend={
                        <Button disabled color="dark">
                          %
                        </Button>
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FormGroup row>
                    <Label lg={5} className="label-required">
                      PPN
                    </Label>
                    <SelectField
                      colWidth={7}
                      name="ppn"
                      placeholder="Select PPN Type"
                      disabled={loading}
                      options={ppnOptions}
                      onChange={(e) => handleChangeSelectInventory(e, i, 'ppn')}
                      errorMessage={validateDataEmpty(item.ppn) ? 'PPN is Required' : ''}
                      invalid={validateDataEmpty(item.ppn)}
                      value={item?.ppn}
                      isClearable
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Card>
          );
        })}
        <Card className="card-add-inventory cursor-pointer" onClick={() => handleAddProduct()}>
          <span className="text-center py-2">+ Tambah Produk Lain</span>
        </Card>
        <Row>
          {!isConsignment ? (
            <Col>
              <Card className="card-total-inventory" style={{ minHeight: 243 }}>
                <Row className="d-flex justify-content-between">
                  <Col>
                    <FormGroup row>
                      <Label lg={3}>Memo</Label>
                      <InputField
                        colWidth={9}
                        inputType="textarea"
                        name="memo"
                        value={memo}
                        onChange={(e) => setMemo(e.target.value)}
                        disabled={loading}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <InventoryUploadImage
                  onSelectImage={(file) => setImageList(file)}
                  onResultNotification={onResultNotification}
                />
              </Card>
            </Col>
          ) : null}
          <Col>
            <Card className="card-total-inventory">
              <Row className="d-flex justify-content-between">
                <Col>
                  <span>Sub Total</span>
                </Col>
                <Col className="d-flex justify-content-end">
                  <span>IDR {Helper.formatMoney(subTotal, 2, ',')}</span>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between">
                <Col>
                  <span>Discount per Lines</span>
                </Col>
                <Col className="d-flex justify-content-end">
                  <span>IDR {Helper.formatMoney(discountPl, 2, ',')}</span>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between">
                <Col lg={6} md={6} sm={12}>
                  <span>Discount</span>
                </Col>
                <Col lg={6} md={6} sm={12} className="d-flex justify-content-end pr-0">
                  <InputField
                    colWidth={10}
                    name="discount"
                    disabled={loading}
                    value={discount}
                    onChange={(e) => {
                      handleChangeDiscount(e.target.value);
                    }}
                    prepend={
                      <Button disabled style={{ minWidth: 50 }}>
                        {typeDiscount === 'Percentage' ? '%' : 'Rp'}
                      </Button>
                    }
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-between">
                <Col md={4} />
                <Col md={8} className="d-flex justify-content-end  cursor-pointer" onClick={handleChangeTypeDiscount}>
                  <div className="custom-control custom-switch switch-secondary">
                    <Label style={{ paddingTop: 2, position: 'absolute', left: -75 }}>Percentage</Label>
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      id="typeDiscount"
                      name="typeDiscount"
                      onChange={handleChangeTypeDiscount}
                      checked={typeDiscount === 'Percentage' ? false : true}
                    />
                    <Label className="custom-control-label">Nominal</Label>
                  </div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between">
                <Col>
                  <span>PPN</span>
                </Col>
                <Col className="d-flex justify-content-end">
                  <span>IDR {Helper.formatMoney(ppn, 2, ',')}</span>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between">
                <Col>
                  <span>Total</span>
                </Col>
                <Col className="d-flex justify-content-end">
                  <span>IDR {Helper.formatMoney(total, 2, ',')}</span>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Col lg={3} md={3} sm={12} />
          <Col lg={3} md={3} sm={12} />
          <Col lg={3} md={3} sm={12} className="d-flex justify-content-end  mb-2">
            <Button
              className="btn btn-danger"
              style={{ minWidth: '100%' }}
              onClick={() => setModalExit(true)}
              disabled={loading}
            >
              Batalkan
            </Button>
          </Col>
          <Col lg={3} md={3} sm={12} className="d-flex justify-content-end  mb-2">
            <Button
              className="btn btn-purple"
              style={{ minWidth: '100%', marginLeft: 20 }}
              onClick={() => handleCheckForm()}
              disabled={loading}
            >
              {!loading ? (
                'Simpan'
              ) : (
                <Spinner size="sm" color="dark">
                  Loading...
                </Spinner>
              )}
            </Button>
          </Col>
        </Row>
      </Card>
      <ConfirmAddInventoryModal
        toggle={() => {
          setModalConfirm(!modalConfirm);
          Helper.scrollToTop();
        }}
        isOpen={modalConfirm}
        isLoading={loading}
        onProccess={() => handleSubmit()}
      />
      <CustomModal
        className="modal-confirm-inventory"
        modalWidth={'auto'}
        modal={modalNewProduct}
        toggle={() => setModalNewProduct(false)}
        modalHeader="Produk Baru Belum Terdaftar"
        modalFooter={
          <>
            <Button color="danger" size="md" outline onClick={() => setModalNewProduct(false)}>
              Kembali
            </Button>
            <Button color="primary" size="md" onClick={() => handleModalNewProduct()}>
              Tambah Produk Baru
            </Button>
          </>
        }
      >
        Terdapat produk baru yang belum terdaftar. Apakah kamu yakin ingin menambahkan produk baru di Product Master
        Data?
      </CustomModal>
      <CustomModal
        className="modal-confirm-inventory"
        modalWidth={'auto'}
        modal={modalExit}
        toggle={() => setModalExit(false)}
        modalHeader="Yakin ingin keluar ?"
        modalFooter={
          <>
            <Button
              color="danger"
              size="md"
              outline
              onClick={() => history.push(`/workshops/${workshopId}?activetab=inventory`)}
            >
              Batalkan tambah stok
            </Button>
            <Button color="primary" size="md" onClick={() => setModalExit(false)}>
              Tetap dihalaman ini
            </Button>
          </>
        }
      >
        Jika kamu keluar dari halaman, maka seluruh data yang diisi akan hilang. Apakah kamu ingin melanjutkan?
      </CustomModal>
      <CustomModal
        className="modal-confirm-inventory"
        modalWidth={'auto'}
        modal={modalFailUploadImage}
        toggle={() => setModalFailUploadImage(false)}
        modalHeader="Gagal membuat PO"
        onClosed={() => history.push(`/workshops/${workshopId}?activetab=inventory`)}
      >
        <p>
          {dataPayload?.noPo} Gagal dibuat dengan error :
          <br /> {failedCreatePOMessage}
        </p>
        <CopyToClipboard onCopy={handleCopyClipBoard} text={dataPayload?.noPo}>
          <Button color="purple" block className="mt-4 w-50" style={{ margin: '0 auto' }}>
            <img src={IconCopy} className="mr-2 mb-1" />
            <span>Copy No PO</span>
          </Button>
        </CopyToClipboard>
      </CustomModal>
      <Tooltip isOpen={infoTooltip} target="InfoTooltip" toggle={() => setInfoTooltip(!infoTooltip)}>
        <p>Part Number tidak perlu diisi (dikosongkan) jika tidak ada.</p>
      </Tooltip>
    </React.Fragment>
  );
};

export default InventoryAddNew;
