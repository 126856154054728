import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Label } from 'reactstrap';
import API from '@utils/API';
import map from 'lodash/map';
import Helper from '@helpers/Helper';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';

const OtoklixServiceAdd = (props) => {
  const { modal, toggle, onSubmit } = props;

  const api = new API('v2');
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(false);
  const [sellPrice, setSellPrice] = useState();
  const [productPrice, setProductPrice] = useState();
  const [productOptions, setProductOptions] = useState([]);
  const [errorProduct, setErrorProduct] = useState('');
  const [errorSellPrice, setErrorSellPrice] = useState('');
  const [errorProductPrice, setErrorProductPrice] = useState('');
  const [loadingFetchProduct, setLoadingFetchProduct] = useState(false);

  async function fetchProductOptions() {
    setLoadingFetchProduct(true);
    try {
      const response = await api.get(`v2/intools/products/all?origin=create-otoklix-service-fee`);
      const optionsProduct = map(response?.data?.data?.products, (item) => {
        return { value: item?.id, label: item?.name, regular_price: item?.regular_price ?? 0 };
      });
      setProductOptions(optionsProduct);
      setLoadingFetchProduct(false);
    } catch (error) {
      setProductOptions([]);
      setLoadingFetchProduct(false);
    }
  }

  const handleChangeProduct = (e) => {
    const valueProduct = e?.value ? e : null;
    setProductPrice(Helper.formatMoneyOnField(Helper.formatMoney(e?.regular_price ?? 0), 'Rp '));
    validateProduct(valueProduct);
  };

  const handleChangeSellPrice = (e) => {
    const valueSellPrice = Helper.formatMoneyOnField(e.target.value, 'Rp ');
    validateSellPrice(valueSellPrice);
  };

  const handleChangeProductPrice = (e) => {
    const valueProductPrice = Helper.formatMoneyOnField(e.target.value, 'Rp ');
    validateProductPrice(valueProductPrice);
  };

  const validateProduct = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorProduct('Pilih Service Name terlebih dahulu');
    } else {
      setErrorProduct('');
    }

    setProduct(value);
    return passValidate;
  };

  const validateSellPrice = (value) => {
    let passValidate = true;
    if (value?.formatMoney > 99999999) {
      passValidate = false;
      setErrorSellPrice('Sell Price maksimal Rp 99.999.999');
    } else {
      setErrorSellPrice('');
    }

    setSellPrice(value);
    return passValidate;
  };

  const validateProductPrice = (value) => {
    let passValidate = true;
    if (value?.formatMoney > 99999999) {
      passValidate = false;
      setErrorProductPrice('Service Fee maksimal Rp 99.999.999');
    } else {
      setErrorProductPrice('');
    }

    setProductPrice(value);
    return passValidate;
  };

  const handleSubmit = () => {
    const passValidateProduct = validateProduct(product);
    const passValidateSellPrice = validateSellPrice(sellPrice);
    const passValidateProductPrice = validateProductPrice(productPrice);

    if (passValidateProduct && passValidateSellPrice && passValidateProductPrice) {
      submitForm();
    }
  };

  async function submitForm() {
    setLoading(true);
    try {
      const payload = {
        product_id: product?.value ?? null,
        display_name: null,
        price: sellPrice?.formatMoney ?? 0,
        service_fee: productPrice?.formatMoney ?? 0,
        is_active: false,
      };
      const response = await api.post(`v2/intools/master/otoklix-service-fees`, payload);
      onSubmit(response?.data?.data?.id ?? null, true);
      setLoading(false);
    } catch (error) {
      onSubmit(null, false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  const resetData = () => {
    setProduct();
    setSellPrice();
    setProductPrice();
    setErrorProduct('');
    setErrorSellPrice('');
    setErrorProductPrice('');
    setProductOptions([]);
  };

  const cancel = () => {
    toggle();
  };

  useEffect(() => {
    resetData();
    if (modal) {
      fetchProductOptions();
      setSellPrice(Helper.formatMoneyOnField('' + 0, 'Rp '));
      setProductPrice(Helper.formatMoneyOnField('' + 0, 'Rp '));
    }
  }, [modal]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Add Service Fee</div>
        <div className="content">
          <FormGroup row>
            <Label lg={4} className="label-required">
              Service Name
            </Label>
            <SelectField
              colWidth={8}
              name="service_name"
              placeholder="Pilih Service Name"
              closeMenuOnSelect={true}
              value={product}
              options={productOptions}
              disabled={loading}
              isLoading={loadingFetchProduct}
              errorMessage={errorProduct}
              onChange={handleChangeProduct}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4}>B2C Sell Price</Label>
            <InputField
              colWidth={8}
              inputType={'text'}
              disabled={loading}
              placeholder="B2C Sell Price"
              value={sellPrice?.formatMoneyString ?? ''}
              invalid={errorSellPrice ? true : false}
              errorMessage={errorSellPrice ?? ''}
              onChange={handleChangeSellPrice}
            />
          </FormGroup>
          <FormGroup row className="mb-0">
            <Label lg={4}>Service Fee</Label>
            <InputField
              colWidth={8}
              inputType={'text'}
              disabled={loading}
              placeholder="Service Fee"
              value={productPrice?.formatMoneyString ?? ''}
              invalid={errorProductPrice ? true : false}
              errorMessage={errorProductPrice ?? ''}
              onChange={handleChangeProductPrice}
            />
          </FormGroup>
        </div>

        <div className="action mb-3 mt-3">
          <Button className="button-cancel" onClick={cancel} disabled={loading}>
            Batal
          </Button>
          <Button className="button-action" onClick={handleSubmit} disabled={loading}>
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default OtoklixServiceAdd;
