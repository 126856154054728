import React from 'react';
import { Badge } from 'reactstrap';

const StnkBadge = ({ status }) => {
  switch (status) {
    case 'unverified':
      return <Badge className="p-2 badge-danger">Unverified</Badge>;
    case 'verified':
      return <Badge className="p-2 badge-green">Verified</Badge>;
    case 'checking':
      return <Badge className="p-2 badge-info">Menunggu Verifikasi</Badge>;
    default:
      return <Badge className="p-2 badge-danger">No Status</Badge>;
  }
};

export default StnkBadge;
