import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, CardHeader, Button, Row, Col } from 'reactstrap';
import map from 'lodash/map';
import moment from 'moment';
import API from '@utils/API';
import DataTable from 'react-data-table-component';
import Loading from '@components/loading/Loading';
import UploadImage from '@components/uploads/UploadImage';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import DeleteIcon from '@assets/icons/delete.svg';
import UploadFileConfig from '@components/modal/UploadFileConfig';
import EditIcon from '@assets/icons/edit.svg';
import { assign } from 'lodash';
import PreviewDownload from '@components/modal/PreviewDownload';
import Helper from '@helpers/Helper';

const BookingDetailFile = (props) => {
  const api = new API('v2');
  const {
    detailData,
    isFleetMode,
    onResultNotification,
    setIsSPK,
    setIsCustomDoc,
    setIsInvoice,
    loadLogHistory,
  } = props;
  const [loading, setLoading] = useState(false);
  const [dataFile, setDataFile] = useState([]);
  const [activeFile, setActiveFile] = useState({});

  const [errorImage, setErrorImage] = useState('');
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hasModalUpload, setHasModalUpload] = useState(false);
  const [uploadFile, setUploadFile] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  async function fetchFileOtoklix() {
    setLoading(true);

    try {
      const response = await api.get(
        `v2/intools/bookings/${detailData?.booking_code}/documents/?option=${isFleetMode ? 'fleet' : 'config'}`
      );
      if (response?.data?.data) {
        let customDocCount = 0;
        let customDocFile = 0;

        const fileData = map(response?.data?.data, (item) => {
          const isRequired = false;
          const nameFile = item?.filename
            ? item.filename.length > 25
              ? item.filename.substr(0, 11) + '...' + item.filename.substr(item.filename.length - 11)
              : item.filename
            : '';
          if (item?.document_type == 'spk' && !!item?.filename) {
            setIsSPK(true);
          } else if (item?.document_type == 'spk' && !item?.filename) {
            setIsSPK(false);
          }
          if (item?.document_type == 'invoice' && !!item?.filename) {
            setIsInvoice(true);
          } else if (item?.document_type == 'invoice' && !item?.filename) {
            setIsInvoice(false);
          }
          if (item?.slug == 'custom') {
            if (!!item?.filename) customDocFile += 1;
            customDocCount += 1;
          }
          customDocCount == customDocFile ? setIsCustomDoc(true) : setIsCustomDoc(false);

          return {
            id: item?.id,
            doc_id: item?.master_document_config_id,
            slug: item?.slug,
            time: item?.created_at,
            type_file: item?.document_type,
            label_file: item?.label ?? '',
            required: isRequired,
            spk_number: item?.spk_number,
            base64: item?.base64,
            document: {
              name: nameFile,
              url: item?.url ?? null,
              key: item?.key,
            },
          };
        });
        setDataFile(fileData);
      } else {
        setDataFile([]);
      }
      setLoading(false);
    } catch (error) {
      setDataFile([]);
      setLoading(false);
      handleResultNotification(true, 'fail', 'Gagal mendapatkan lampiran file otoklix');
    }
  }

  const handleResultNotification = (status = false, type = '', message = '', without_reload = false) => {
    const notification = {
      status: status,
      type: type,
      message: message,
      without_reload: without_reload,
    };

    onResultNotification(notification);
  };

  async function handleSaveFile(keyFile, fileName, file, spkNumber, is_deleted = false) {
    setShowModal(false);
    setLoading(true);
    const payload = {
      document_type: activeFile?.type_file,
      filename: fileName,
      key: keyFile?.length > 0 ? keyFile : activeFile?.document?.key,
      is_deleted,
      id: activeFile?.id ?? null,
      master_document_config_id: activeFile?.doc_id,
      slug: activeFile?.slug,
    };

    if (activeFile?.type_file == 'spk') assign(payload, { spk_number: spkNumber ?? activeFile?.spk_number });

    api
      .post(`v2/intools/bookings/${detailData?.booking_code}/documents/`, payload)
      .then((response) => {
        handleResultNotification(
          true,
          'success',
          `Berhasil ${is_deleted ? 'menghapus' : 'menyimpan'}  file ` + activeFile?.label_file,
          true
        );
        fetchFileOtoklix();
        loadLogHistory();
      })
      .catch((e) => {
        const message = `API Error : ${e?.response?.data?.error?.message}`;
        handleResultNotification(
          true,
          'fail',
          `Gagal ${is_deleted ? 'menghapus' : 'menyimpan'} file ` + activeFile?.label_file + ' ' + message
        );
        setLoading(false);
      });
  }

  const handleUploadFile = (files, data) => {
    setActiveFile(data);
    handleSaveFile(files?.key, files?.file?.name, data);
  };

  const handleErrorUploadFile = (msg, data = 'none') => {
    setErrorImage(msg);
    if (msg) {
      handleResultNotification(true, 'fail', 'Gagal upload file ' + data?.label_file + ' ' + msg);
    }
  };

  const handleDeleteFile = (data) => {
    setShowModal(true);
    setActiveFile(data);
  };

  const handleLoadingUploadFile = (status) => {
    setLoadingUploadFile(status);
  };

  const handleModalUpload = (file, isEdit) => {
    setHasModalUpload(true);
    setActiveFile(file);
    setUploadFile({ ...file, date: new Date().getTime() });
    setIsEdit(isEdit);
  };

  const previewPdf = (base64) => {
    const contentType = 'application/pdf';
    var blob = Helper.base64StringToBlob(base64, contentType);
    const dataUrl = URL.createObjectURL(blob);

    const iframe = document.createElement('iframe');
    iframe.style.position = 'fixed';
    iframe.style.right = '0';
    iframe.style.bottom = '0';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = '0';
    iframe.src = dataUrl;
    document.body.appendChild(iframe);

    iframe.contentWindow.print();
  };

  const columns = useMemo(
    () => [
      {
        name: 'Waktu',
        selector: (row) => row?.time,
        cell: (row) => (moment(row?.time).isValid() ? moment(row?.time).format('MMM DD YYYY , HH:mm:ss') : '-'),
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'File Name',
        selector: (row) => row?.label_file,
        cell: (row) => (
          <div
            className={
              (row?.type_file == 'invoice' && detailData?.booking_type == 'b2b') ||
              (row?.type_file !== 'invoice' &&
                row?.type_file !== 'stnk' &&
                row?.type_file !== 'inspection_result' &&
                row?.type_file !== 'approval_evidence')
                ? 'text-bold text-purple-2 label-required'
                : 'text-bold text-purple-2'
            }
          >
            {row?.label_file ?? '-'}
          </div>
        ),
        wrap: true,
        minWidth: '210px',
      },
      {
        name: 'File',
        selector: (row) => row?.document?.name,
        cell: (row) =>
          row?.document?.name ? (
            <>
              <>
                <Col lg={9}>
                  {row?.type_file == 'spk' && <div className="mb-0">{row?.spk_number ?? '-'}</div>}
                  {row?.document?.name?.split('.')[row?.document?.name?.split('.')?.length - 1] == 'pdf' ? (
                    <div className="text-bold text-purple-2" onClick={() => previewPdf(row?.base64)}>
                      {row?.document?.name}
                    </div>
                  ) : (
                    <div className="mb-2">
                      <PreviewDownload previewURL={row?.document?.url} previewName={row?.document?.name} />
                    </div>
                  )}
                </Col>
                <Col lg={3} className="">
                  {row?.type_file == 'spk' ? (
                    <>
                      <img
                        className="ml-0 cursor-pointer"
                        src={EditIcon}
                        height={15}
                        onClick={() => handleModalUpload(row, true)}
                      />
                      <img
                        className="ml-2 cursor-pointer"
                        src={DeleteIcon}
                        height={15}
                        onClick={() => handleDeleteFile(row)}
                      />
                    </>
                  ) : (
                    <img
                      className="ml-0 cursor-pointer"
                      src={DeleteIcon}
                      height={15}
                      onClick={() => handleDeleteFile(row)}
                    />
                  )}
                </Col>
              </>
            </>
          ) : (
            <Button className="cursor-pointer" color="light" onClick={(e) => handleModalUpload(row, false)}>
              Klik untuk mengisi data
            </Button>
          ),
        // wrap: true,
        minWidth: '350px',
      },
    ],
    []
  );

  const conditionalRowStyles = [
    {
      when: (row) => row?.type_file == 'spk',
      style: {
        minHeight: '80px',
      },
    },
  ];

  useEffect(() => {
    fetchFileOtoklix();
  }, [detailData]);

  return (
    <Card>
      <UploadFileConfig
        id={detailData?.booking_code}
        title={`${isEdit ? 'Edit' : 'Isi'} Data`}
        subTitle={``}
        modal={hasModalUpload}
        toggle={() => setHasModalUpload(!hasModalUpload)}
        onError={(e) => handleErrorUploadFile(e)}
        file={uploadFile}
        onConfirm={(e) => handleSaveFile(e?.key, e?.fileName, e?.file, e?.spkNumber)}
      />
      <CardHeader className="bg-primary text-white mt-0">Lampiran File</CardHeader>
      <CardBody className="pb-0">
        <DataTable
          persistTableHead
          highlightOnHover
          data={dataFile}
          noHeader={true}
          fixedHeader
          progressPending={loading}
          progressComponent={<Loading />}
          fixedHeaderScrollHeight="340px"
          columns={columns}
          conditionalRowStyles={conditionalRowStyles}
          className="table table-bordered dt-responsive"
        />
      </CardBody>
      <ConfirmationModal
        modal={showModal}
        isDisabled={false}
        toggle={() => setShowModal(!showModal)}
        header={`Yakin ingin menghapus file "${activeFile?.label_file}" ?`}
        onConfirm={() => handleSaveFile(null, null, activeFile, null, true)}
        toggleClose={() => setShowModal(!showModal)}
      />
    </Card>
  );
};

export default BookingDetailFile;
