import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Helper from '@helpers/Helper';
import { map } from 'lodash';

export default class InvoicePdf {
  static print(content) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'potrait',
      content: content,
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Halaman ' + currentPage.toString() + ' dari ' + pageCount,
            alignment: 'center',
            bold: true,
            color: '#d6d8e7',
            fontSize: 10,
          },
        ];
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        columnTop: {
          color: 'red',
        },
        paddingLeftRightSectionTop: {
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5], //left, top, right, bottom
        },
        tableContent: {
          margin: [0, 10, 0, 0],
        },
        tableDash: {
          margin: [-50, 5, 0, -50],
        },
        tableSpace: {
          margin: [0, -5, 0, 0],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download('Invoice');
  }

  static generateRowGroup(label) {
    return [
      {
        text: label,
        fillColor: '#f7f7fd',
        margin: [20, 0, 0, 0],
        border: [false, false, false, false],
        bold: true,
      },
      { text: '', fillColor: '#f7f7fd', border: [false, false, false, false] },
    ];
  }
  static generateRowItem(label, value) {
    return [
      {
        text: label,
        fillColor: '#f7f7fd',
        margin: [20, 0, 0, 0],
        border: [false, false, false, false],
        bold: true,
      },
      {
        text: value !== null ? 'Rp ' + Helper.formatMoney(value ?? 0) : null,
        fillColor: '#f7f7fd',
        margin: [0, 0, 20, 0],
        border: [false, false, false, false],
        alignment: 'right',
        // bold: true,
        // color: '#fe6902',
      },
    ];
  }
  static generateRowSubItem(label, value, sectionUp) {
    return [
      {
        text: label,
        fillColor: '#f7f7fd',
        margin: [20, 0, 0, 0],
        border: [false, false, false, false],
        fontSize: 9,
      },
      {
        text:
          value !== null
            ? sectionUp
              ? 'Rp ' + Helper.formatMoney(value ?? 0)
              : '- Rp ' + Helper.formatMoney(value ?? 0)
            : null,
        fillColor: '#f7f7fd',
        margin: [0, 0, 20, 0],
        border: [false, false, false, false],
        alignment: 'right',
        fontSize: 12,
        // bold: true,
        color: sectionUp ? '' : 'red',
      },
    ];
  }

  static getContentBody(dataSummary, userType) {
    let contentItem = [
      [
        { text: '\n', fillColor: '#f7f7fd', border: [false, false, false, false] },
        { text: '\n', fillColor: '#f7f7fd', border: [false, false, false, false] },
      ],
    ];

    const packages = dataSummary?.items?.packages;
    const products = dataSummary?.items?.products;
    const services = dataSummary?.items?.services;

    if (packages?.package_items?.length > 0) {
      contentItem.push(this.generateRowGroup('Paket'));
      map(packages?.package_items, (item) => {
        contentItem.push(this.generateRowItem(item?.name ?? '-', item?.price ?? 0));
        map(item?.item_details, (subitem) => {
          if (userType == 'b2b') {
            contentItem.push(this.generateRowSubItem(subitem?.name ?? '-', subitem?.price ?? 0, true));
            contentItem.push(
              this.generateRowSubItem(
                `${subitem?.qty} x Rp ${Helper.formatMoney(subitem?.unit_price ?? 0)}`,
                userType == 'b2b' ? subitem?.discount_amount ?? 0 : subitem?.voucher_amount ?? 0
              )
            );
          }
          if (userType == 'b2c') {
            contentItem.push(this.generateRowSubItem(`${subitem?.qty ?? 0} x ${subitem?.name ?? '-'}`, null));
          }
        });
        if (userType == 'b2c') contentItem.push(this.generateRowSubItem('Diskon', item?.total_voucher ?? 0, false));
        // contentItem.push(this.generateRowItem('Subtotal Paket', packages?.subtotal_package ?? 0));
      });
    }

    if (products?.product_items?.length > 0) {
      contentItem.push(this.generateRowGroup('Produk'));
      map(products?.product_items, (item) => {
        contentItem.push(this.generateRowItem(item?.name ?? '-', null));
        contentItem.push(
          this.generateRowSubItem(
            `${item?.qty} x Rp ${Helper.formatMoney(item?.unit_price ?? 0)}`,
            item?.price ?? 0,
            true
          )
        );
        contentItem.push(this.generateRowSubItem(`Diskon`, item?.discount_amount ?? 0, false));
      });
      // contentItem.push(this.generateRowItem('Subtotal Produk', products?.subtotal_product ?? 0));
    }

    if (services?.service_items?.length > 0) {
      contentItem.push(this.generateRowGroup('Jasa'));
      map(services?.service_items, (item) => {
        contentItem.push(this.generateRowItem(item?.name ?? '-', null));
        contentItem.push(
          this.generateRowSubItem(
            `${item?.qty} x Rp ${Helper.formatMoney(item?.unit_price ?? 0)}`,
            item?.price ?? 0,
            true
          )
        );
        contentItem.push(this.generateRowSubItem(`Diskon`, item?.discount_amount ?? 0, false));
      });
      // contentItem.push(this.generateRowItem('Subtotal Jasa', services?.subtotal_service ?? 0));
    }

    return {
      content_body: contentItem,
    };
  }

  static create(dataBooking, dataSummary) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const splitTime = dataBooking?.booking_time ? dataBooking?.booking_time.split('.') : [];
    const bookingTime = splitTime?.length == 2 ? `${splitTime[0]}:${splitTime[1]}` : '';
    let bookingSchedule = dataBooking?.booking_date ?? '';
    bookingSchedule += bookingTime ? ' - ' + bookingTime : '';
    let carType = dataBooking?.user_car?.car_details?.car_model?.brand?.name
      ? dataBooking?.user_car?.car_details?.car_model?.brand?.name + ' '
      : '';
    carType += dataBooking?.user_car?.car_details?.car_model?.model_name
      ? dataBooking?.user_car?.car_details?.car_model?.model_name + ' '
      : '';
    carType += dataBooking?.user_car?.car_details?.variant ? dataBooking?.user_car?.car_details?.variant : '';
    const dataCalculate = this.getContentBody(dataSummary, dataBooking?.customer?.customer_type);
    let bodySummary = [];
    if (dataBooking?.customer?.customer_type == 'b2c') {
      bodySummary.push(
        [
          {
            text: 'Sub Total',
            fillColor: '#f7f7fd',
            margin: [20, 0, 0, 0],
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: 'Rp ' + Helper.formatMoney(dataSummary?.subtotal ?? 0),
            fillColor: '#f7f7fd',
            margin: [0, 0, 20, 0],
            border: [false, false, false, false],
            alignment: 'right',
            bold: true,
            color: '#fe6902',
          },
        ],
        [
          {
            text: dataBooking?.customer?.customer_type == 'b2b' ? 'Discount Transaksi' : 'Kode Promo',
            fillColor: '#f7f7fd',
            margin: [20, 10, 0, 0],
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: '- Rp ' + Helper.formatMoney(dataBooking?.discount?.amount ?? 0),
            fillColor: '#f7f7fd',
            margin: [0, 10, 20, 0],
            border: [false, false, false, false],
            alignment: 'right',
            bold: true,
            color: 'red',
          },
        ],
        [
          {
            text: 'OtoPoints',
            fillColor: '#f7f7fd',
            margin: [20, 10, 0, 0],
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: '- Rp ' + Helper.formatMoney(dataBooking?.otopoints?.point_spent ?? 0),
            fillColor: '#f7f7fd',
            margin: [0, 10, 20, 0],
            border: [false, false, false, false],
            alignment: 'right',
            bold: true,
            color: 'red',
          },
        ],
        [
          { text: '', fillColor: '#f7f7fd', border: [false, false, false, true] },
          { text: '', fillColor: '#f7f7fd', border: [false, false, false, true] },
        ],
        [
          {
            text: 'Total',
            fillColor: '#f7f7fd',
            margin: [20, 10, 0, 0],
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: 'Rp ' + Helper.formatMoney(dataSummary?.amount ?? 0),
            fillColor: '#f7f7fd',
            margin: [0, 10, 20, 0],
            border: [false, false, false, false],
            alignment: 'right',
            bold: true,
            color: '#fe6902',
          },
        ],
        [
          { text: '', fillColor: '#f7f7fd', margin: [100, 0, 0, 0], border: [false, false, false, true] },
          { text: '', fillColor: '#f7f7fd', margin: [0, 0, 100, 0], border: [false, false, false, true] },
        ]
      );
    } else {
      bodySummary.push([
        {
          text: 'Total Bayar',
          fillColor: '#f7f7fd',
          margin: [20, 0, 0, 10],
          border: [false, false, false, false],
          bold: true,
        },
        {
          text: 'Rp ' + Helper.formatMoney(dataSummary?.amount ?? 0),
          fillColor: '#f7f7fd',
          margin: [0, 0, 20, 10],
          border: [false, false, false, false],
          alignment: 'right',
          bold: true,
          color: '#fe6902',
        },
      ]);
    }

    let content = [
      {
        image: Helper.logoBase64(),
        alignment: 'center',
        width: 100,
        margin: [0, 0, 0, 20],
      },
      { text: 'Otoklix Booking Receipt', style: 'header', alignment: 'center', bold: true, fontSize: 22 },
      {
        style: 'tableContent',
        table: {
          widths: [120, 5, 362],
          body: [
            [
              {
                text: 'Kode Booking',
                fillColor: '#f7f7fd',
                margin: [20, 10, 0, 10],
                border: [false, false, false, false],
              },
              { text: ':', fillColor: '#f7f7fd', margin: [0, 10, 0, 10], border: [false, false, false, false] },
              {
                text: dataBooking?.booking_code ?? '-',
                fillColor: '#f7f7fd',
                margin: [0, 10, 20, 10],
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Jadwal Booking',
                fillColor: '#f7f7fd',
                margin: [20, 0, 0, 10],
                border: [false, false, false, false],
              },
              { text: ':', fillColor: '#f7f7fd', margin: [0, 0, 0, 10], border: [false, false, false, false] },
              {
                text: !bookingSchedule ? '-' : bookingSchedule,
                fillColor: '#f7f7fd',
                margin: [0, 0, 20, 10],
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Pilihan Lokasi',
                fillColor: '#f7f7fd',
                margin: [20, 0, 0, 10],
                border: [false, false, false, false],
              },
              { text: ':', fillColor: '#f7f7fd', margin: [0, 0, 0, 10], border: [false, false, false, false] },
              {
                text: dataBooking?.workshop?.name ?? '-',
                fillColor: '#f7f7fd',
                margin: [0, 0, 20, 10],
                bold: true,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        style: 'tableContent',
        table: {
          widths: [120, 5, 362],
          body: [
            [
              {
                text: 'Jenis Mobil',
                fillColor: '#f7f7fd',
                margin: [20, 10, 0, 10],
                border: [false, false, false, false],
              },
              { text: ':', fillColor: '#f7f7fd', margin: [0, 10, 0, 10], border: [false, false, false, false] },
              {
                text: !carType ? '-' : carType,
                fillColor: '#f7f7fd',
                margin: [0, 10, 20, 10],
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Nomor Plat',
                fillColor: '#f7f7fd',
                margin: [20, 0, 0, 10],
                border: [false, false, false, false],
              },
              { text: ':', fillColor: '#f7f7fd', margin: [0, 0, 0, 10], border: [false, false, false, false] },
              {
                text: dataBooking?.user_car?.license_plate ?? '-',
                fillColor: '#f7f7fd',
                margin: [0, 0, 20, 10],
                bold: true,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        style: 'tableContent',
        table: {
          widths: [120, 5, 362],
          body: [
            [
              {
                text: 'Nama Customer',
                fillColor: '#f7f7fd',
                margin: [20, 10, 0, 10],
                border: [false, false, false, false],
              },
              { text: ':', fillColor: '#f7f7fd', margin: [0, 10, 0, 10], border: [false, false, false, false] },
              {
                text: dataBooking?.customer?.name ?? '-',
                fillColor: '#f7f7fd',
                margin: [0, 10, 20, 10],
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Nomor HP',
                fillColor: '#f7f7fd',
                margin: [20, 0, 0, 10],
                border: [false, false, false, false],
              },
              { text: ':', fillColor: '#f7f7fd', margin: [0, 0, 0, 10], border: [false, false, false, false] },
              {
                text: dataBooking?.customer?.phone_no ?? '-',
                fillColor: '#f7f7fd',
                margin: [0, 0, 20, 10],
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Metode Bayar',
                fillColor: '#f7f7fd',
                margin: [20, 0, 0, 10],
                border: [false, false, false, false],
              },
              { text: ':', fillColor: '#f7f7fd', margin: [0, 0, 0, 10], border: [false, false, false, false] },
              {
                text: dataBooking?.payment_details?.booking_pay_method ?? '-',
                fillColor: '#f7f7fd',
                margin: [0, 0, 20, 10],
                bold: true,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        style: 'tableContent',
        table: {
          widths: [360, 136],
          body: dataCalculate.content_body,
        },
      },
      {
        style: 'tableSpace',
        table: {
          widths: [360, 136],
          body: [
            [
              { text: '\n', fillColor: '#f7f7fd', border: [false, false, false, false] },
              { text: '\n', fillColor: '#f7f7fd', border: [false, false, false, false] },
            ],
          ],
        },
      },
      {
        style: 'tableSpace',
        table: {
          widths: [505],
          body: [
            [{ text: '', fillColor: '#f7f7fd', border: [false, false, false, true] }],
            [{ text: '', fillColor: '#f7f7fd', border: [false, false, false, true] }],
            [{ text: '', fillColor: '#f7f7fd', border: [false, false, false, false] }],
            [{ text: '', fillColor: '#f7f7fd', border: [false, false, false, false] }],
            [{ text: '', fillColor: '#f7f7fd', border: [false, false, false, false] }],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 1 ? 2 : 3;
          },
          hLineColor: function (i, node) {
            return '#d6d8e7';
          },
          hLineStyle: function (i, node) {
            if (i === 1) {
              return null;
            }
            return { dash: { length: 5, space: 5 }, color: 'red' };
          },
        },
      },
      {
        style: 'tableSpace',
        table: {
          widths: [360, 136],
          body: bodySummary,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 1 ? 2 : 3;
          },
          hLineColor: function (i, node) {
            return '#d6d8e7';
          },
          hLineStyle: function (i, node) {
            if (i === 1) {
              return null;
            }
            return { dash: { length: 5, space: 5 }, color: 'red' };
          },
        },
      },
    ];

    if (dataBooking?.customer?.customer_type == 'b2c') {
      content.push({
        style: 'tableSpace',
        table: {
          widths: [16, 455, 16],
          body: [
            [
              { text: '', fillColor: '#f7f7fd', margin: [0, 10, 0, 0], border: [false, false, false, false] },
              {
                text: 'Total OtoPoints akan didapatkan',
                fillColor: '#FFFFFF',
                margin: [10, 10, 0, 0],
                border: [false, false, false, false],
                fontSize: 9,
              },
              {
                text: '',
                fillColor: '#f7f7fd',
                margin: [0, 10, 0, 0],
                border: [false, false, false, false],
                bold: true,
              },
            ],
            [
              { text: '', fillColor: '#f7f7fd', margin: [0, 0, 0, 10], border: [false, false, false, false] },
              {
                text: '+' + dataBooking?.otopoints?.point_get ?? 0 + 'Points',
                fillColor: '#FFFFFF',
                margin: [10, 0, 0, 10],
                border: [false, false, false, false],
                bold: true,
                color: '#002fb4',
                fontSize: 14,
              },
              {
                text: '',
                fillColor: '#f7f7fd',
                margin: [0, 0, 0, 10],
                border: [false, false, false, false],
                bold: true,
              },
            ],
          ],
        },
      });
      content.push({
        style: 'tableSpace',
        table: {
          widths: [360, 136],
          body: [
            [
              { text: '\n', fillColor: '#f7f7fd', border: [false, false, false, false] },
              { text: '\n', fillColor: '#f7f7fd', border: [false, false, false, false] },
            ],
          ],
        },
      });
    }
    this.print(content);
  }
}
