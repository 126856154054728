import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Col } from 'reactstrap';
import API from '@utils/API';
import AddPermissionModal from '../modal/AddPermissionModal';
import { toast, ToastContainer } from 'react-toastify';
import deleteIcon from '@assets/icons/trash.svg';
import editIcon from '@assets/icons/edit.svg';
import userPermission from '@utils/userPermission';
import CustomModal from '@components/modal/CustomModal';
import ActionFilterACL from '@components/filters/ActionFilterACL';
import Loading from '@components/loading/Loading';

const Permission = (props) => {
  const { data, loading, setPermissionData } = props;
  const api = new API('v2');
  const { generatePermission } = userPermission();

  const [hasCreatePermissionModal, setHasCreatePermissionModal] = useState(false);
  const [editedData, setEditedData] = useState();
  const [isAPIFetch, setIsAPIFetch] = useState(false);
  const [hasModalConfirmation, setHasModalConfirmation] = useState(false);
  const [filterText, setFilterText] = useState('');

  const allowPermission = (menu) => {
    return !!(generatePermission('acl', menu) || generatePermission('acl', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowFilter = allowPermission('all') || allowPermission('search');
  const allowCreate = allowPermission('all') || allowPermission('add');

  const createPermissionModalToggle = () => setHasCreatePermissionModal(!hasCreatePermissionModal);

  const handleClickPermissionRow = (id) => {
    setIsAPIFetch(true);

    api
      .get(`v2/intools/permissions/${id}/`)
      .then((result) => {
        setEditedData(result?.data?.data);
        createPermissionModalToggle();
        setIsAPIFetch(false);
      })
      .catch((err) => {
        console.log(err?.response);
        setIsAPIFetch(false);
        toast.error(err?.response?.data?.error?.message, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };

  const handleDeletePermission = () => {
    setIsAPIFetch(true);

    api
      .delete(`v2/intools/permissions/${editedData?.id}/`)
      .then((result) => {
        setPermissionData(result?.data?.data);
        setHasModalConfirmation(false);
        setIsAPIFetch(false);
      })
      .catch((err) => {
        console.log(err?.response);
        setIsAPIFetch(false);
        toast.error(err?.response?.data?.error?.message, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };

  const handleSubmit = async (params) => {
    setIsAPIFetch(true);

    if (editedData) {
      api
        .put(`v2/intools/permissions/${editedData?.id}/`, params)
        .then((result) => {
          setPermissionData(result?.data?.data);
          createPermissionModalToggle();
          setIsAPIFetch(false);
          setEditedData();
        })
        .catch((err) => {
          console.log(err?.response);
          setIsAPIFetch(false);
          toast.error(err?.response?.data?.error?.message, {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        });

      return;
    }

    api
      .post('v2/intools/permissions/', params)
      .then((result) => {
        setPermissionData(result?.data?.data);
        createPermissionModalToggle();
        setIsAPIFetch(false);
      })
      .catch((err) => {
        console.log(err?.response);
        setIsAPIFetch(false);
        toast.error(err?.response?.data?.error?.message, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };

  const permissionData = () => {
    const list =
      data?.length > 0
        ? data
            ?.filter(
              (dt) =>
                dt?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
                dt?.method?.toLowerCase().includes(filterText.toLowerCase()) ||
                dt?.path?.toLowerCase().includes(filterText.toLowerCase())
            )
            .map((item) => {
              item = {
                ...item,
              };
              return item;
            })
        : [];

    return list;
  };

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row) => row?.name,
        cell: (row) => row?.name,
        minWidth: '200px',
        width: '250px',
      },
      {
        name: 'Method',
        selector: (row) => row?.method,
        cell: (row) => row?.method ?? '-',
        minWidth: '100px',
        width: '100px',
      },
      {
        name: 'Path',
        selector: (row) => row?.path,
        cell: (row) => row?.path ?? '-',
        minWidth: '200px',
      },
      {
        name: 'Actions',
        cell: (row) => (
          <>
            <img src={editIcon} height={16} alt="" className="mr-3" onClick={() => handleClickPermissionRow(row?.id)} />
            <img
              src={deleteIcon}
              height={16}
              alt=""
              onClick={() => {
                setHasModalConfirmation(true);
                setEditedData(row);
              }}
            />
          </>
        ),
        right: true,
      },
    ],
    []
  );

  const createPermission = () => {
    if (allowCreate) setHasCreatePermissionModal(!hasCreatePermissionModal);
  };

  const subHeaderComponentMemo = useMemo(() => {
    return <ActionFilterACL value={filterText} handleChangeValue={setFilterText} />;
  }, [filterText, setFilterText]);

  return (
    <React.Fragment>
      <ToastContainer />
      <Col sm={12} md={12} className="p-0 mt-0 section-action">
        {allowCreate && (
          <Button className="button-action add w-170" onClick={createPermission}>
            {'Create New Permission'}
          </Button>
        )}
      </Col>
      {allowFilter && (
        <Col sm={12} md={12} className="p-0 mt-4 mb-0">
          <ActionFilterACL value={filterText} handleChangeValue={setFilterText} />
        </Col>
      )}
      <Col sm={12} md={12} className="p-0 mt-1">
        <DataTable
          persistTableHead
          highlightOnHover
          noHeader={true}
          progressPending={loading}
          data={permissionData()}
          fixedHeaderScrollHeight="239px"
          columns={allowList ? columns : []}
          className="table dt-responsive table-custom"
          progressComponent={allowList ? <Loading /> : <></>}
        />
      </Col>

      <AddPermissionModal
        isOpen={hasCreatePermissionModal}
        toggle={createPermissionModalToggle}
        onSubmit={handleSubmit}
        initialValue={editedData}
        onClosed={() => setEditedData()}
        loading={isAPIFetch}
      />

      <CustomModal
        modal={hasModalConfirmation}
        toggle={() => setHasModalConfirmation(false)}
        modalHeader="Delete Permission Data"
        modalFooter={
          <>
            <Button
              color="danger"
              outline
              className="m-auto px-4 rounded-pill"
              size="lg"
              onClick={handleDeletePermission}
            >
              Delete
            </Button>
            <Button
              color="primary"
              className="m-auto px-4 rounded-pill"
              size="lg"
              onClick={() => setHasModalConfirmation(false)}
            >
              Cancel
            </Button>
          </>
        }
      >
        Are you sure you want to delete this data?
      </CustomModal>
    </React.Fragment>
  );
};

export default Permission;
