import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import ExportButton from '@components/export/ExportButton';
import Loading from '@components/loading/Loading';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import { NavLink, Nav, NavItem, TabContent, TabPane, Card, CardBody, Row, Col, Button } from 'reactstrap';
import classnames from 'classnames';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import CustomPagination from '@components/pagination/CustomPagination';
import ItemsModal from '@components/modal/ItemsModal';
import { map } from 'lodash';
import qs from 'query-string';
import Helper from '@helpers/Helper';

function Cars() {
  const history = useHistory();
  const api = new API('v2');
  const { generatePermission } = userPermission();
  const queryParams = useLocation().search;
  const activetab = new URLSearchParams(queryParams).get('activetab') ?? 'master';

  const [activeTab, setActiveTab] = useState(activetab);
  const [dataMaster, setDataMaster] = useState([]);
  const [dataCompability, setDataCompability] = useState([]);
  const [dataCarExport, setDataCarExport] = useState([]);
  const [disabledCarExport, setDisabledCarExport] = useState(true);
  const [dataCompabilityExport, setDataCompabilityExport] = useState([]);
  const [disabledCompabilityExport, setDisabledCompabilityExport] = useState(true);
  const [carName, setCarName] = useState('');
  const [searchBy, setSearchBy] = useState({ value: 'Variant', label: 'Variant' });
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [resetSorting, setResetSorting] = useState(false);
  const [sortingData, setSortingData] = useState({});
  const [showModalItems, setShowModalItems] = useState(false);
  const [modalData, setModalData] = useState({});
  const getParams = new URLSearchParams(queryParams);
  const keys = ['search'];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });
  const allowPermission = (menu) => {
    const allowAccess = !!(generatePermission('car', menu) || generatePermission('car', 'all'));
    if (!allowAccess) return history.push('/404');
    return allowAccess;
  };

  async function loadCars() {
    setLoading(true);
    setDisabledCarExport(true);

    const params = getParamData();
    const response = await api.get(`v2/intools/user_car/get-all-cars-with-filter/`, { params });

    try {
      setDataMaster(response?.data?.data);
      handleDataExportCar(response?.data?.data);
      setTotalRows(response?.data?.pagination?.total_rows);
      setTotalPages(response?.data?.pagination?.total_page);
      validateCountPage(response);
      setLoading(false);
    } catch (error) {
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleDataExportCar([]);
    }
  }

  const getParamData = () => {
    let params = {
      query: filtered?.search ?? '',
      search_by: filtered?.searchBy?.value ?? '',
      page: page,
      limit: pageLimit,
    };

    if (sortingData) {
      params.sort = sortingData?.sort;
      params.order = sortingData?.order;
    }

    return params;
  };

  const loadCarGroup = async () => {
    setLoading(true);

    const params = getParamDataGroupCar();
    if (!disabledCompabilityExport) setDisabledCompabilityExport(true);
    const response = await api.get(`v2/intools/user_car/compatibility_groups/`, { params });

    try {
      setDataCompability(response?.data?.data);
      handleDataExport(response?.data?.data);
      setTotalRows(response?.data?.pagination?.total_rows);
      setTotalPages(response?.data?.pagination?.total_page);
      validateCountPage(response);
      setLoading(false);
    } catch (error) {
      setPage(1);
      setDataCompability([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleDataExport([]);
    }
  };

  const getParamDataGroupCar = () => {
    let params = {
      page: page,
      limit: pageLimit,
    };

    if (sortingData) {
      params.sort = sortingData?.sort ?? 'id';
      params.order = sortingData?.order ?? 'asc';
    }

    return params;
  };

  const handlePageChange = (page) => {
    if (activeTab === 'master') loadCars(page);
    if (activeTab === 'compability') loadCarGroup(page);
  };

  const handlePerRowsChange = async (newpageLimit, page) => {
    setLoading(true);
    const response = await api.get(
      `v2/intools/user_car/get-all-cars-with-filter/?query=${carName}&search_by=${searchBy.value}&page=${page}&limit=${newpageLimit}`
    );

    setData(response.data.data);
    setPageLimit(newpageLimit);
    setLoading(false);
  };

  const openCarDetail = (row) => {
    if (generatePermission('car', 'detail')) {
      history.push(`/cars/${row.id}`);
    }
  };

  const openCarGroupDetail = (row) => {
    if (allowPermission('groupshow')) {
      history.push(`/cars/compability/${row.car_group_id}`);
    }
  };

  const handleClickCarModel = (data) => {
    setShowModalItems(true);
    let datas = [];
    map(data, (item) => {
      datas.push({ label: item });
    });
    setModalData(datas);
  };

  const masterColumns = useMemo(
    () => [
      {
        name: 'Car Name',
        cell: (row) => (
          <p onClick={() => openCarDetail(row)}>
            {row.car_model.brand.name} / {row.car_model.model_name} / {row.variant}
          </p>
        ),
        minWidth: '450px',
      },
      {
        name: 'Oil Type',
        selector: 'oil_type',
        wrap: true,
      },
      {
        name: 'Rim size',
        selector: 'rim_size',
        wrap: true,
      },
    ],
    []
  );

  const compabilityColumns = useMemo(
    () => [
      {
        name: 'Car Group Name',
        selector: 'car_group_name',
        minWidth: '450px',
        sortable: true,
        sortField: 'name',
      },
      {
        name: 'Car Model',
        cell: (row) => (
          <p
            className="my-1"
            onClick={() => {
              handleClickCarModel(row.car_details);
            }}
          >
            {map(row.car_details, (item, index) => {
              const itemLength = row.car_details.length - 1;
              const itemToShow = 3;

              return (
                <>
                  <span>{index < itemToShow ? item : null}</span>
                  {itemLength !== index && index < itemToShow ? <br /> : null}
                </>
              );
            })}
            <a style={{ color: '#0D6EFD', textDecoration: 'underline' }}>
              {row.car_details.length > 3 ? `+${row.car_details.length - 3} Lainnya` : null}
            </a>
          </p>
        ),
        sortable: true,
        wrap: true,
        sortField: 'car_model',
      },
      // {
      //   name: 'Year Range',
      //   cell: (row) => (row.year_range ? row.year_range.replace(',', ' - ') : row.year_range) ?? '-',
      //   sortable: true,
      //   wrap: true,
      // },
    ],
    []
  );

  const handleDataExport = (data) => {
    let arrDataExport = [];

    data.forEach((item) => {
      const transformCarModelArray =
        item?.car_details?.length > 0 ? item?.car_details.toString().replaceAll(',', '; ') : item?.car_details ?? '-';

      const objDataExport = {
        car_group_id: item?.car_group_id,
        car_group_name: item?.car_group_name,
        car_details: transformCarModelArray,
        // year_range: item?.year_range ? item?.year_range.replace(',', ' - ') : '-',
      };

      arrDataExport.push(objDataExport);
    });

    setDataCompabilityExport(arrDataExport);
    setDisabledCompabilityExport(false);
  };

  const handleDataExportCar = (data) => {
    let arrDataExport = [];

    data.forEach((item) => {
      const generatedName = item?.car_model?.brand?.name + ' / ' + item?.car_model?.model_name + ' / ' + item?.variant;

      const objDataExport = {
        name: generatedName,
        oil_type: item?.oil_type,
        rim_size: item?.rim_size,
      };

      arrDataExport.push(objDataExport);
    });

    setDataCarExport(arrDataExport);
    setDisabledCarExport(false);
  };

  const handleDownloadCSVCar = () => {
    const keys = ['name', 'oil_type', 'rim_size'];
    const headers = ['name', 'oil', 'rim size'];
    Helper.downloadCSV(dataCarExport, headers, keys);
  };

  const handleDownloadCSV = () => {
    const keys = ['car_group_id', 'car_group_name', 'car_details'];
    const headers = ['ID', 'Car Group Name', 'Car Model'];
    Helper.downloadCSV(dataCompabilityExport, headers, keys);
  };

  const handleSort = async (column, sortDirection) => {
    setSortingData({
      sort: column?.sortField ?? '',
      order: sortDirection ?? '',
    });
  };

  const optionSearchBy = [
    { value: 'Variant', label: 'Variant' },
    { value: 'Brand', label: 'Brand' },
    { value: 'Model', label: 'Model' },
  ];

  const dataFilter = [
    {
      id: 'filter_text_search',
      type: 'text',
      name: 'search',
      name_field: 'search',
      value: tempFiltered?.search ?? '',
      placeholder: 'Cari',
    },
    {
      id: 'filter_select_searchBy',
      type: 'select',
      name: 'searchBy',
      name_field: 'searchBy',
      options: optionSearchBy,
      value: '',
      placeholder: 'Search by',
    },
  ];

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodFetchCar();
  };

  const handleMethodFetchCar = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      if (activeTab === 'master') {
        loadCars();
      } else if (activeTab === 'compability') {
        loadCarGroup();
      }
    }
  };

  const resetFilter = () => {
    setResetSorting(true);
    setSortingData({});
  };

  useEffect(() => {
    if (page && pageLimit && !firstLoad) {
      if (activeTab === 'master') {
        loadCars();
      } else if (activeTab === 'compability') {
        loadCarGroup();
      }
    }
  }, [page, pageLimit]);

  const handleSearchFilter = (e) => {
    setFiltered(e);
  };

  const setDefaultFilters = async () => {
    dataFilter[0].value = '';
    dataFilter[1].value = { value: 'Variant', label: 'Variant' };
    setFilters([...dataFilter]);

    setFiltered({
      searchBy: { value: 'Variant', label: 'Variant' },
    });
  };

  const handleResetFilters = () => {
    setFiltered({});
    setDefaultFilters();
    tempFiltered = {};
  };

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
    };
    console.log(`setfiltered`, e);
    setFiltered(tempVal);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row?.car_details?.length > 3,
      style: {
        minHeight: '100px',
      },
    },
    {
      when: (row) => row?.car_details?.length <= 3,
      style: {
        minHeight: '70px',
      },
    },
  ];

  const toggleActiveTab = (tab) => {
    let params = qs.parse(history?.location?.search);
    params = {
      ...params,
      activetab: tab,
    };

    setActiveTab(tab);
    history.push({ search: qs.stringify(params) });
  };

  const createCar = () => {
    if (generatePermission('car', 'create')) history.push('/cars/create');
  };

  const createGroupCar = () => {
    if (generatePermission('car', 'create')) history.push('/cars/compability/create');
  };

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowpageLimit = (e) => {
    setPageLimit(e);
  };

  useEffect(() => {
    setFiltered({ ...tempFiltered });
  }, Object.values(tempFiltered));

  useEffect(() => {
    if (filtered) {
      let tempSetFiltered = { ...filtered };
      tempSetFiltered = {
        ...filtered,
      };
      const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
      history.push(`/cars/?${urlParam}`);
      //handleMethodFetchCar();
      console.log('usefectfiltered', filtered);
    }
    //resetBookings();
  }, [filtered]);

  useEffect(() => {
    if (firstLoad && filtered) {
      if (activeTab === 'master') {
        loadCars();
      } else if (activeTab === 'compability') {
        loadCarGroup();
      }
    } else {
      resetFilter();
    }
  }, [activeTab, filtered]);

  useEffect(() => {
    setPageLimit(10);
    handleResetFilters();
  }, [activeTab]);

  useEffect(() => {
    setDefaultFilters();
  }, []);

  return (
    <Wrapper title="List Car" className="wrapperContent">
      <Nav tabs className="car-tabs">
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'master' })} onClick={() => toggleActiveTab('master')}>
            Master
          </NavLink>
        </NavItem>
        {allowPermission('groupall') ? (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'compability' })}
              onClick={() => toggleActiveTab('compability')}
            >
              Compability
            </NavLink>
          </NavItem>
        ) : null}
      </Nav>

      <Card className="card-custom none-border-radius-top card-custom-p-15-15">
        <CardBody className="p-0">
          <Row>
            <Col sm={12} md={12}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="master">
                  <Col sm={12} md={12} className="p-0 mt-0 section-action">
                    {generatePermission('car', 'create') && (
                      <Button className="button-action add w-170" onClick={createCar}>
                        {'Create New Car'}
                      </Button>
                    )}
                    {generatePermission('car', 'export') && (
                      <Button
                        disabled={disabledCarExport}
                        className="button-action primary"
                        onClick={handleDownloadCSVCar}
                      >
                        {'Export CSV'}
                      </Button>
                    )}
                  </Col>
                  {generatePermission('car', 'search') && (
                    <Col sm={12} md={12} className="p-0 mt-4 mb-0 filter-car">
                      <ActionFilterCustom
                        filters={filters}
                        loading={loading}
                        onApplyFilters={(e) => handleSetFiltered(e)}
                        onResetFilters={handleResetFilters}
                      ></ActionFilterCustom>
                    </Col>
                  )}
                  <Col sm={12} md={12} className="p-0 mt-4">
                    <DataTable
                      persistTableHead
                      highlightOnHover
                      noHeader
                      progressPending={loading}
                      data={dataMaster}
                      noDataComponent={noDataComponent}
                      columns={generatePermission('car', 'list') ? masterColumns : []}
                      className="table dt-responsive table-custom"
                      progressComponent={generatePermission('car', 'list') ? <Loading /> : <></>}
                      onRowClicked={(row) => openCarDetail(row)}
                    />
                  </Col>
                  <Col sm={12} md={12} className="p-0 mt-2">
                    {dataMaster?.length >= 1 && !loading && generatePermission('car', 'list') && (
                      <CustomPagination
                        page={page}
                        pageLimit={pageLimit}
                        totalRows={dataMaster?.length ?? 0}
                        totalAllRows={totalRows}
                        totalPages={totalPages}
                        handleChangePage={handleChangePage}
                        handleChangeRowPerPage={handleChangeRowpageLimit}
                      ></CustomPagination>
                    )}
                  </Col>
                </TabPane>
                <TabPane tabId="compability">
                  <Col sm={12} md={12} className="p-0 mt-0 section-action">
                    {generatePermission('car', 'groupcreate') && (
                      <Button className="button-action add w-170" onClick={createGroupCar}>
                        {'Create New Group'}
                      </Button>
                    )}
                    {generatePermission('car', 'grouplist') && (
                      <Button
                        disabled={disabledCompabilityExport}
                        className="button-action primary"
                        onClick={handleDownloadCSV}
                      >
                        {'Export CSV'}
                      </Button>
                    )}
                  </Col>
                  <Col sm={12} md={12} className="p-0 mt-4">
                    <DataTable
                      persistTableHead
                      highlightOnHover
                      noHeader
                      conditionalRowStyles={conditionalRowStyles}
                      progressPending={loading}
                      data={dataCompability}
                      columns={generatePermission('car', 'grouplist') ? compabilityColumns : []}
                      className="table dt-responsive table-custom"
                      noDataComponent={noDataComponent}
                      progressComponent={generatePermission('car', 'grouplist') ? <Loading /> : <></>}
                      onRowClicked={(row) => openCarGroupDetail(row)}
                      onSort={handleSort}
                      sortServer
                    />
                  </Col>
                  <Col sm={12} md={12} className="p-0 mt-2">
                    {dataCompability?.length >= 1 && !loading && generatePermission('car', 'grouplist') && (
                      <CustomPagination
                        page={page}
                        pageLimit={pageLimit}
                        totalRows={dataCompability?.length ?? 0}
                        totalAllRows={totalRows}
                        totalPages={totalPages}
                        handleChangePage={handleChangePage}
                        handleChangeRowPerPage={handleChangeRowpageLimit}
                      ></CustomPagination>
                    )}
                  </Col>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <ItemsModal
        data={modalData}
        modal={showModalItems}
        toggle={() => {
          setShowModalItems(!showModalItems);
        }}
      />
    </Wrapper>
  );
}

export default Cars;
