import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import userPermission from '@utils/userPermission';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import Loading from '@components/loading/Loading';
import DataTable from 'react-data-table-component';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import AccountAdd from '@components/workshops/account/AccountAdd';
import AccountEdit from '@components/workshops/account/AccountEdit';
import CustomPagination from '@components/pagination/CustomPagination';

const AccountList = (props) => {
  const { id, onLoading, handleResultNotification } = props;
  const api = new API('v2');
  const { generatePermission } = userPermission();

  const [data, setData] = useState([]);
  const [idAccount, setIdAccount] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [hasModalAddAccount, setHasModalAddAccount] = useState(false);
  const [hasModalEditAccount, setHasModalEditAccount] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'accountall'));
  };

  const allowList = allowPermission('accountlist');
  const allowCreate = allowPermission('accountcreate');
  const allowDetail = allowPermission('accountshow');
  const allowEdit = allowPermission('accountedit');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  let dataFilter = [];

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row) => Helper.shortenByDash(row?.name),
        wrap: true,
        sortable: true,
        minWidth: '275px',
      },
      {
        name: 'Status',
        minWidth: '70px',
        selector: (row) =>
          row.status === 'active' ? (
            <span className="badge-custom badge-custom-success">Active</span>
          ) : (
            <span className="badge-custom badge-custom-danger">Deactive</span>
          ),
      },
      {
        name: 'Email',
        selector: (row) => row?.email ?? '-',
        wrap: true,
        minWidth: '275px',
      },
      {
        name: 'Role',
        selector: (row) => row?.role?.name ?? '-',
        wrap: true,
        minWidth: '100px',
      },
    ],
    []
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchListAccount() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/workshops/${id}/gms-account/`, { params });
      setData(response?.data?.data?.workshops ?? []);
      setTotalRows(response?.data?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
      setLoading(false);
      onLoading(false);
    } catch (error) {
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      onLoading(false);
    }
  }

  const getParamData = () => {
    let params = {
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.data?.pagination?.total_page) {
      setPage(response?.data?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleDetailAccount = (row) => {
    setIdAccount(row?.id);
    if (allowDetail) {
      handleEditAccount();
    }
  };

  const handleResetFilters = () => {
    setFilters(dataFilter);
    setFiltered({});
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodfetchListAccount();
  };

  const handleMethodfetchListAccount = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchListAccount();
    }
  };

  const resetFilter = () => {
    setResetSorting(true);
    setSortingData({});
  };

  const handleAddAccount = () => setHasModalAddAccount(true);
  const toogleAddAccount = () => setHasModalAddAccount(!hasModalAddAccount);
  const handleEditAccount = () => setHasModalEditAccount(true);
  const toogleEditAccount = () => setHasModalEditAccount(!hasModalEditAccount);

  const handleSubmitAddAccount = (status, error) => {
    const errorMsg = error ? 'Gagal Add New Account, ' + error : 'Gagal Add New Account';
    if (status) {
      fetchListAccount();
    }
    setHasModalAddAccount(false);
    handleResultNotification(true, status ? 'success' : 'fail', status ? 'Berhasil Add New Account' : errorMsg);
  };

  const handleSubmitEditAccount = (status, error) => {
    const errorMsg = error ? 'Gagal Edit Account, ' + error : 'Gagal Edit Account';
    if (status) {
      fetchListAccount();
    }
    setHasModalEditAccount(false);
    handleResultNotification(true, status ? 'success' : 'fail', status ? 'Berhasil Edit Account' : errorMsg);
  };

  useEffect(() => {
    if (page && pageLimit) {
      if (allowList) fetchListAccount();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    resetFilter();
  }, [filtered]);

  useEffect(() => {
    setFilters(dataFilter);
  }, []);

  return (
    <React.Fragment>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <>
          <Card className="card-custom">
            <CardBody className="p-0">
              <Row>
                <Col sm={12} md={12}>
                  <AccountAdd
                    modal={hasModalAddAccount}
                    idWorkshop={id}
                    toggle={toogleAddAccount}
                    onSubmit={handleSubmitAddAccount}
                  />
                  <AccountEdit
                    idAccount={idAccount}
                    modal={hasModalEditAccount}
                    idWorkshop={id}
                    allowEdit={allowEdit}
                    toggle={toogleEditAccount}
                    onSubmit={handleSubmitEditAccount}
                  />
                  <Col sm={12} md={12} className="p-0 mt-0">
                    <Row className="ml-2 font-weight-bold">
                      <h3>Akun GMS</h3>
                    </Row>
                  </Col>
                  <Col sm={12} md={12} className="p-0 mt-0 section-action">
                    {allowCreate && (
                      <Button disabled={loading} className="button-action add w-170" onClick={handleAddAccount}>
                        {'Create New Account'}
                      </Button>
                    )}
                  </Col>
                  <Col sm={12} md={12} className="p-0 mt-4">
                    <DataTable
                      persistTableHead
                      highlightOnHover
                      noHeader={true}
                      progressPending={loading}
                      data={data}
                      className="table dt-responsive table-custom"
                      noDataComponent={noDataComponent}
                      progressComponent={<Loading />}
                      customStyles={customStylesHeaderTable}
                      onRowClicked={(row) => handleDetailAccount(row)}
                      columns={allowList ? columns : []}
                    />
                  </Col>
                  <Col sm={12} md={12} className="p-0 mt-2">
                    {data?.length >= 1 && !loading && allowList && (
                      <CustomPagination
                        page={page}
                        pageLimit={pageLimit}
                        totalRows={data?.length ?? 0}
                        totalAllRows={totalRows}
                        totalPages={totalPages}
                        handleChangePage={handleChangePage}
                        handleChangeRowPerPage={handleChangeRowPerPage}
                      ></CustomPagination>
                    )}
                  </Col>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      )}
    </React.Fragment>
  );
};

export default AccountList;
