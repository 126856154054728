import React from 'react';
import { FormGroup, Input, Label, Col } from 'reactstrap';

const ActionSwitch = (props) => {
  const {
    title,
    onChange,
    colWidth,
    inputName,
    isChecked,
    labelWidth,
    labelChecked,
    editableForm,
    labelUnchecked,
    customClass = '',
    customClassLabel = '',
    color,
  } = props;

  const handleChangeColor = (color) => {
    if (color == 'secondary') {
      return 'mt-2 custom-control custom-switch switch-secondary';
    } else if (color == 'primary') {
      return 'mt-2 custom-control custom-switch switch-primary';
    } else if (color == 'warning') {
      return 'mt-2 custom-control custom-switch switch-warning';
    } else if (color == 'danger') {
      return 'mt-2 custom-control custom-switch switch-danger';
    } else if (color) {
      return `mt-2 custom-control custom-switch ${color}`;
    } else {
      return 'mt-2 custom-control custom-switch switch-success';
    }
  };

  return (
    <FormGroup row className={customClass}>
      <Label lg={labelWidth} className={customClassLabel}>
        {title}
      </Label>
      <Col lg={colWidth}>
        <div className={handleChangeColor(color)}>
          <Input
            type="checkbox"
            className="custom-control-input"
            id={inputName}
            name={inputName}
            onChange={onChange}
            checked={isChecked}
            disabled={editableForm}
          />
          <Label className="custom-control-label" htmlFor={inputName}>
            {isChecked ? labelChecked : labelUnchecked}
          </Label>
        </div>
      </Col>
    </FormGroup>
  );
};

export default ActionSwitch;
