import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Helper from '@helpers/Helper';
import moment from 'moment';
import { map } from 'lodash';

export default class SPKPdf {
  static print(content) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'potrait',
      content: content,
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Halaman ' + currentPage.toString() + ' dari ' + pageCount,
            alignment: 'center',
            bold: true,
            color: '#d6d8e7',
            fontSize: 10,
          },
        ];
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        columnTop: {
          color: 'red',
        },
        paddingLeftRightSectionTop: {
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5], //left, top, right, bottom
        },
        tableContent: {
          margin: [0, 10, 0, 0],
        },
        tableDash: {
          margin: [-50, 5, 0, -50],
        },
        tableSpace: {
          margin: [0, -5, 0, 0],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download('SPK');
  }

  static generateRowGroup(label) {
    return [
      {
        text: label,
        fillColor: '#f7f7fd',
        margin: [20, 0, 0, 0],
        border: [false, false, false, false],
        bold: true,
      },
      { text: '', fillColor: '#f7f7fd', border: [false, false, false, false] },
    ];
  }

  static generateRowItem(label, value) {
    return [
      {
        text: label,
        fillColor: '#f7f7fd',
        margin: [20, 0, 0, 0],
        border: [false, false, false, false],
        bold: true,
      },
      {
        text: 'Rp ' + Helper.formatMoney(value ?? 0),
        fillColor: '#f7f7fd',
        margin: [0, 0, 20, 0],
        border: [false, false, false, false],
        alignment: 'right',
        bold: true,
        color: '#fe6902',
      },
    ];
  }

  static generateRowSubItem(label, value, sectionUp) {
    return [
      {
        text: label,
        fillColor: '#f7f7fd',
        margin: [20, 0, 0, 0],
        border: [false, false, false, false],
        fontSize: sectionUp ? 12 : 9,
      },
      {
        text: value == null ? '' : 'Rp ' + Helper.formatMoney(value ?? 0),
        fillColor: '#f7f7fd',
        margin: [0, 0, 20, 0],
        border: [false, false, false, false],
        alignment: 'right',
        // bold: true,
        fontSize: 12,
      },
    ];
  }

  static generateRowSubTitle(label, value, sectionUp) {
    return [
      {
        text: label,
        fillColor: '#f7f7fd',
        margin: [20, 0, 0, 10],
        border: [false, false, false, false],
        bold: true,
        fontSize: sectionUp ? 12 : 9,
      },
      {
        text: value == null ? '' : 'Rp ' + Helper.formatMoney(value ?? 0),
        fillColor: '#f7f7fd',
        margin: [0, 0, 20, 10],
        border: [false, false, false, false],
        alignment: 'right',
        bold: true,
        fontSize: 12,
        color: '#fe6902',
      },
    ];
  }

  static getContentBody(dataBooking, dataSummary) {
    let contentItem = [
      [
        { text: '\n', fillColor: '#f7f7fd', border: [false, false, false, false] },
        { text: '\n', fillColor: '#f7f7fd', border: [false, false, false, false] },
      ],
    ];

    const packages = dataSummary?.items?.packages;
    const products = dataSummary?.items?.products;
    const services = dataSummary?.items?.services;

    if (packages?.package_items?.length > 0) {
      contentItem.push(this.generateRowGroup('Paket'));
      if (dataBooking?.booking_type === 'b2c') {
        map(packages?.package_items, (item) => {
          contentItem.push(this.generateRowItem(item?.name ?? '-', item?.price ?? 0));
          map(item?.item_details, (subitem) => {
            contentItem.push(this.generateRowSubItem(subitem?.name ?? '-', subitem?.price ?? 0, true));
            contentItem.push(
              this.generateRowSubItem(
                `${subitem?.qty} x Rp ${Helper.formatMoney(subitem?.marked_up_unit_price ?? 0)}`,
                subitem?.discount_amount ?? 0
              )
            );
          });
        });
      } else {
        map(packages?.package_items, (item) => {
          contentItem.push(this.generateRowItem(item?.name ?? '-', item?.price ?? 0));
          map(item?.item_details, (subitem) => {
            contentItem.push(
              this.generateRowSubItem(`${subitem?.qty} x ${subitem?.name}`, subitem?.total_workshop_price)
            );
          });
          const notes = 'Keterangan : ' + item?.item_details[0]?.note ?? '-';
          contentItem.push(this.generateRowSubItem(notes, null));
        });
      }
      contentItem.push(this.generateRowSubTitle('Subtotal Paket', packages?.subtotal_package ?? 0, true));
    }

    if (products?.product_items?.length > 0) {
      contentItem.push(this.generateRowGroup('Produk'));
      map(products?.product_items, (item) => {
        contentItem.push(this.generateRowSubItem(item?.name ?? '-', item?.total_workshop_price ?? 0, true));
        contentItem.push(
          this.generateRowSubItem(`${item?.qty} x Rp ${Helper.formatMoney(item?.unit_price ?? 0)}`, null)
        );
        const remarks = 'Keterangan : ' + item?.remark ?? '';
        item?.remark ? contentItem.push(this.generateRowSubItem(remarks, null)) : null;
      });
      contentItem.push(this.generateRowSubTitle('Subtotal Produk' ?? '-', products?.subtotal_product_pdf ?? 0, true));
    }

    if (services?.service_items?.length > 0) {
      contentItem.push(this.generateRowGroup('Jasa'));
      map(services?.service_items, (item) => {
        contentItem.push(this.generateRowSubItem(item?.name ?? '-', item?.total_workshop_price ?? 0, true));
        contentItem.push(
          this.generateRowSubItem(`${item?.qty} x Rp ${Helper.formatMoney(item?.unit_price ?? 0)}`, null)
        );
        const remarks = 'Keterangan : ' + item?.remark ?? '';
        item?.remark ? contentItem.push(this.generateRowSubItem(remarks, null)) : null;
      });
      contentItem.push(this.generateRowSubTitle('Subtotal Jasa' ?? '-', services?.subtotal_service_pdf ?? 0, true));
    }

    return {
      content_body: contentItem,
    };
  }

  static create(dataBooking, dataSummary) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const dateNow = moment().format('DD MMMM YYYY');
    const timeNow = moment().format('HH:mm:ss');
    const splitTime = dataBooking?.booking_time ? dataBooking?.booking_time.split('.') : [];
    const bookingTime = splitTime?.length == 2 ? `${splitTime[0]}:${splitTime[1]}` : '';
    let bookingSchedule = dataBooking?.booking_date ?? '';
    bookingSchedule += bookingTime ? ' - ' + bookingTime : '';
    let carType = dataBooking?.user_car?.car_details?.car_model?.brand?.name
      ? dataBooking?.user_car?.car_details?.car_model?.brand?.name + ' '
      : '';
    carType += dataBooking?.user_car?.car_details?.car_model?.model_name
      ? dataBooking?.user_car?.car_details?.car_model?.model_name + ' '
      : '';
    carType += dataBooking?.user_car?.car_details?.variant ? dataBooking?.user_car?.car_details?.variant : '';
    const dataCalculate = this.getContentBody(dataBooking, dataSummary);
    const isFlagship = dataBooking?.workshop?.tier?.value?.includes('flagship') ? true : false;

    let summaryPKP = [
      [
        {
          text: 'Sub Total',
          fillColor: '#f7f7fd',
          margin: [20, 16, 0, 0],
          border: [false, false, false, false],
          bold: true,
        },
        {
          text: 'Rp ' + Helper.formatMoney(dataSummary?.dpp_spk ?? 0),
          fillColor: '#f7f7fd',
          margin: [0, 16, 20, 0],
          border: [false, false, false, false],
          alignment: 'right',
          bold: true,
          color: isFlagship ? '' : '#fe6902',
        },
      ],
      [
        {
          text: 'Otoklix Take Rate',
          fillColor: '#f7f7fd',
          margin: [20, 10, 0, 16],
          border: [false, false, false, false],
          bold: true,
        },
        {
          text: `- ${Helper.generateFinalMoney(dataSummary?.take_rate_otoklix ?? 0)}`,
          fillColor: '#f7f7fd',
          margin: [0, 10, 20, 16],
          border: [false, false, false, false],
          alignment: 'right',
          bold: true,
          color: 'red',
        },
      ],
    ];

    if (dataBooking?.workshop?.is_pkp) {
      summaryPKP?.splice(1, 0, [
        {
          text: 'PPN 11%',
          fillColor: '#f7f7fd',
          margin: [20, 16, 0, 0],
          border: [false, false, false, false],
          bold: true,
        },
        {
          text: 'Rp ' + Helper.formatMoney(dataSummary.ppn_spk ?? 0),
          fillColor: '#f7f7fd',
          margin: [0, 16, 20, 0],
          border: [false, false, false, false],
          alignment: 'right',
          bold: true,
          color: isFlagship ? '' : '#fe6902',
        },
      ]);
    }

    let totalNonFlagship = [
      [
        {
          text: 'Total',
          fillColor: '#f7f7fd',
          margin: [20, 16, 0, 10],
          border: [false, false, false, false],
          bold: true,
        },
        {
          text: 'Rp ' + Helper.formatMoney(dataSummary.amount_spk ?? 0),
          fillColor: '#f7f7fd',
          margin: [0, 16, 20, 10],
          border: [false, false, false, false],
          alignment: 'right',
          bold: true,
        },
      ],
    ];

    let totalFlagship = [
      [
        {
          text: 'Total',
          fillColor: '#f7f7fd',
          margin: [20, 16, 0, 16],
          border: [false, false, false, false],
          bold: true,
        },
        {
          text: 'Rp ' + Helper.formatMoney(dataSummary.amount_spk ?? 0),
          fillColor: '#f7f7fd',
          margin: [0, 16, 20, 16],
          border: [false, false, false, false],
          alignment: 'right',
          bold: true,
          color: 'brown',
        },
      ],
    ];

    let content = [
      {
        image: Helper.logoBase64(),
        alignment: 'center',
        width: 100,
        margin: [0, 0, 0, 20],
      },
      { text: 'Surat Perintah Kerja / Purchase Order', style: 'header', alignment: 'center', bold: true, fontSize: 22 },
      {
        style: 'tableContent',
        table: {
          widths: [120, 5, 362],
          body: [
            [
              {
                text: 'Kode Booking',
                fillColor: '#f7f7fd',
                margin: [20, 10, 0, 10],
                border: [false, false, false, false],
              },
              { text: ':', fillColor: '#f7f7fd', margin: [0, 10, 0, 10], border: [false, false, false, false] },
              {
                text: dataBooking?.booking_code ?? '-',
                fillColor: '#f7f7fd',
                margin: [0, 10, 20, 10],
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Jadwal Booking',
                fillColor: '#f7f7fd',
                margin: [20, 0, 0, 10],
                border: [false, false, false, false],
              },
              { text: ':', fillColor: '#f7f7fd', margin: [0, 0, 0, 10], border: [false, false, false, false] },
              {
                text: !bookingSchedule ? '-' : bookingSchedule,
                fillColor: '#f7f7fd',
                margin: [0, 0, 20, 10],
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Pilihan Lokasi',
                fillColor: '#f7f7fd',
                margin: [20, 0, 0, 10],
                border: [false, false, false, false],
              },
              { text: ':', fillColor: '#f7f7fd', margin: [0, 0, 0, 10], border: [false, false, false, false] },
              {
                text: dataBooking?.workshop?.name ?? '-',
                fillColor: '#f7f7fd',
                margin: [0, 0, 20, 10],
                bold: true,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        style: 'tableContent',
        table: {
          widths: [120, 5, 362],
          body: [
            [
              {
                text: 'Jenis Mobil',
                fillColor: '#f7f7fd',
                margin: [20, 10, 0, 10],
                border: [false, false, false, false],
              },
              { text: ':', fillColor: '#f7f7fd', margin: [0, 10, 0, 10], border: [false, false, false, false] },
              {
                text: !carType ? '-' : carType,
                fillColor: '#f7f7fd',
                margin: [0, 10, 20, 10],
                bold: true,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'Nomor Plat',
                fillColor: '#f7f7fd',
                margin: [20, 0, 0, 10],
                border: [false, false, false, false],
              },
              { text: ':', fillColor: '#f7f7fd', margin: [0, 0, 0, 10], border: [false, false, false, false] },
              {
                text: dataBooking?.user_car?.license_plate ?? '-',
                fillColor: '#f7f7fd',
                margin: [0, 0, 20, 10],
                bold: true,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        style: 'tableContent',
        table: {
          widths: [360, 136],
          body: dataCalculate.content_body,
        },
      },
      {
        style: 'tableSpace',
        table: {
          widths: [360, 136],
          body: [
            [
              { text: '\n', fillColor: '#f7f7fd', border: [false, false, false, false] },
              { text: '\n', fillColor: '#f7f7fd', border: [false, false, false, false] },
            ],
          ],
        },
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 16,
            y1: 0,
            x2: 500,
            y2: 0,
            lineWidth: 1,
          },
        ],
      },
      {
        style: 'tableSpace',
        table: {
          widths: [360, 136],
          body: summaryPKP,
        },
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 16,
            y1: 0,
            x2: 500,
            y2: 0,
            lineWidth: 1,
          },
        ],
      },
      {
        style: 'tableSpace',
        table: {
          widths: [360, 136],
          body: isFlagship ? totalFlagship : totalNonFlagship,
        },
      },
      // isFlagship
      //   ? {
      //       canvas: [
      //         {
      //           type: 'line',
      //           x1: 16,
      //           y1: 0,
      //           x2: 500,
      //           y2: 0,
      //           lineWidth: 1,
      //         },
      //       ],
      //     }
      //   : {},
      {
        text: 'Jakarta, ' + dateNow + ' (' + timeNow + ')',
        alignment: 'left',
        bold: true,
        margin: [0, 0, 0, 0],
        pageBreak: 'before',
      },
      {
        style: 'tableContent',
        table: {
          widths: [200],
          body: [[{ text: '\n', margin: [20, 50, 0, 10], border: [false, false, false, true] }]],
        },
        layout: {
          hLineWidth: function (i, node) {
            return 2;
          },
          hLineColor: function (i, node) {
            return '#d6d8e7';
          },
        },
      },
      {
        text: 'Bengkel Wajib Memberikan Foto Proses Pengerjaan Sebagai Bagian dari Dokumen Penagihan.',
        alignment: 'left',
        bold: true,
        margin: [0, 40, 0, 10],
      },
      {
        text: 'Panduan Bengkel Partner Otoklix terdapat di link berikut ini:',
        alignment: 'left',
        bold: true,
        margin: [0, 10, 0, 0],
      },
      {
        text: 'https://link.otoklix.com/panduanbengkel ',
        alignment: 'left',
        bold: true,
        margin: [0, 0, 0, 10],
        color: '#0360c0',
        decoration: 'underline',
      },
      { text: 'Syarat & Ketentuan', alignment: 'left', bold: true, margin: [0, 40, 0, 10] },
      {
        ol: [
          {
            text:
              'Otoklix tidak bertanggung jawab atas pengerjaan penggantian sparepart atau servis diluar permintaan pada Surat Perintah Kerja Otoklix.',
            bold: true,
            margin: [10, 0, 0, 5],
          },
          {
            text:
              'Pembayaran kepada Bengkel akan dilakukan selambat-lambatnya 3 (tiga) hari kerja setelah Final Invoice Bengkel diterima oleh Otoklix.',
            bold: true,
            margin: [10, 0, 0, 5],
          },
          {
            text:
              'Surat Perintah Kerja Otoklix bersifat rahasia dan Bengkel tidak diperbolehkan untuk menyebarkan dokumen tersebut kepada pihak manapun.',
            bold: true,
            margin: [10, 0, 0, 5],
          },
          {
            text:
              'Nota Penagihan Bengkel hanya dapat diberikan kepada pihak Otoklix dan tidak diperkenankan untuk diberikan kepada pihak lain.',
            bold: true,
            margin: [10, 0, 0, 5],
          },
          {
            text:
              'Surat Perintah Kerja ini sah dan diproses oleh komputer. Silakan hubungi Otoklix Customer Service apabila kamu membutuhkan bantuan.',
            bold: true,
            margin: [10, 0, 0, 5],
          },
        ],
      },
    ];

    this.print(content);
  }
}
