import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Input, Button } from 'reactstrap';
import Select from 'react-select';
import API from '@utils/API';
import map from 'lodash/map';

const ActionFilterPackage = (props) => {
  const {
    packageName,
    workshopName,
    status,
    category,
    onChangePackageName,
    onChangeWorkshopName,
    onChangeStatus,
    onChangeCategory,
    onSearch,
  } = props;

  const api = new API('v2');
  const statusOptions = [
    { value: '', label: 'Any' },
    { value: '0', label: 'Non Active' },
    { value: '1', label: 'Active' },
  ];
  const customStyles = {
    option: (provided) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
      minWidth: 220,
      minHeight: 35,
      height: 35,
      border: '1px solid #9ba7ca',
      borderRadius: 0,
      backgroundColor: '#fff',
      boxShadow: 'none',
    }),
  };

  const [categoryOptions, setCategoryOptions] = useState([{ value: '', label: 'Any' }]);

  async function loadCategories() {
    let listCategory = [{ value: '', label: 'Any' }];

    try {
      const response = await api.get(`v2/intools/categories/list-categories-all/`);
      if (response?.data?.data) {
        let resultListCategory = map(response?.data?.data, (item) => {
          return { value: item?.slug, label: item?.name };
        });
        resultListCategory.splice(0, 0, { value: '', label: 'Any' });
        listCategory = resultListCategory;
      }
      setCategoryOptions(listCategory);
    } catch (error) {
      setCategoryOptions(listCategory);
    }
  }

  useEffect(() => {
    loadCategories();
  }, []);

  return (
    <Form inline>
      <FormGroup className="mb-2 mb-sm-0">
        <Input
          placeholder="Package Name"
          className="br-0 fixed-fc-height"
          value={packageName}
          onChange={onChangePackageName}
        />
      </FormGroup>
      <FormGroup className="mb-2 mb-sm-0">
        <Input
          placeholder="Workshop Name"
          className="br-0 fixed-fc-height"
          value={workshopName}
          onChange={onChangeWorkshopName}
        />
      </FormGroup>
      <FormGroup className="mb-2 mb-sm-0">
        <Select
          placeholder="Category"
          isSearchable={false}
          styles={customStyles}
          onChange={onChangeCategory}
          value={category}
          options={categoryOptions}
        />
      </FormGroup>
      <FormGroup className="mb-2 mb-sm-0">
        <Select
          placeholder="Status"
          isSearchable={false}
          styles={customStyles}
          onChange={onChangeStatus}
          value={status}
          options={statusOptions}
        />
      </FormGroup>
      <Button className="btn-info btn-square" onClick={onSearch}>
        Search
      </Button>
    </Form>
  );
};

export default ActionFilterPackage;
