import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, Col, FormGroup, FormFeedback, Input, Label, Row, Button } from 'reactstrap';
import { useParams } from 'react-router';
import Wrapper from '@components/wrapper/Wrapper';

function SubscriptionsCreate() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});

  const breadcrumb = [
    {
      id: 1,
      label: 'Langganan',
      active: false,
      url: '/subscriptions',
    },
    {
      id: 2,
      label: 'Buat Langganan',
      active: true,
      url: '',
    },
  ];

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  return (
    <Wrapper
      title="Buat Langganan Baru"
      customContentClassName="wrapper-content"
      customTitleClassName="wrapper-title"
      breadcrumbData={breadcrumb}
      showNotification={hasNotif}
      notification={notification}
      onCloseNotification={handleCloseNotification}
    >
      <Row>
        <Col sm={12}>
          <Card className="card-custom">
            <CardBody className="p-0"></CardBody>
          </Card>
        </Col>
      </Row>
    </Wrapper>
  );
}

export default SubscriptionsCreate;
