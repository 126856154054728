import React, { useState, useEffect, useMemo } from 'react';
import { find } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { Modal, ModalBody, Button, Col, Row, Spinner } from 'reactstrap';
import Papa from 'papaparse';
import API from '@utils/API';
import map from 'lodash/map';
import TrashIcon from '@assets/icons/trash.svg';
import DataTable from 'react-data-table-component';

const WorkshopPackageImport = (props) => {
  const { modal, toggle, onSubmit } = props;

  const api = new API('v2');
  const [loading, setLoading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [resultMode, setResultMode] = useState(false);
  const [successImport, setSuccessImport] = useState(false);
  const [processParsing, setProcessParsing] = useState(false);
  const [formData, setFormData] = useState({});
  const [headerData, setHeaderData] = useState({});
  const [previewData, setPreviewData] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [resultTotalData, setResultTotalData] = useState(0);
  const [invalidPreview, setInvalidPreview] = useState(true);
  const [errorMsgPreview, setErrorMsgPreview] = useState('');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        backgroundColor: '#F9F9F9',
        border: '0.7px solid #E0E7ED',
      },
    },
  };

  const conditionCellStylesPreview = (field) => {
    return [
      {
        when: (row) => row?.[field] == undefined || row?.[field] == '',
        style: {
          backgroundColor: '#F8D7DA',
        },
      },
    ];
  };

  const columnsPreview = useMemo(
    () => [
      {
        name: <span className={headerData?.package_detail_id ? '' : 'header-danger'}>Paket Detail ID</span>,
        selector: (row) => row?.package_detail_id ?? '',
        minWidth: '100px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('package_detail_id'),
      },
      {
        name: 'Workshop',
        selector: (row) => row?.workshop_name ?? '',
        minWidth: '100px',
        sortable: false,
      },
      {
        name: <span className={headerData?.package_name ? '' : 'header-danger'}>Nama Paket</span>,
        selector: (row) => row?.package_name ?? '',
        minWidth: '100px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('package_name'),
      },
      {
        name: <span className={headerData?.component_name ? '' : 'header-danger'}>Komponen</span>,
        minWidth: '100px',
        selector: (row) => row?.component_name ?? '',
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesPreview('component_name'),
      },
      {
        name: <span className={headerData?.product_name ? '' : 'header-danger'}>Nama Produk</span>,
        minWidth: '100px',
        selector: (row) => row?.product_name ?? '',
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesPreview('product_name'),
      },
      {
        name: <span className={headerData?.price ? '' : 'header-danger'}>Price</span>,
        selector: (row) => row?.price ?? '',
        minWidth: '100px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('price'),
      },
      {
        name: <span className={headerData?.original_price ? '' : 'header-danger'}>Original Price (Harga coret)</span>,
        selector: (row) => row?.original_price ?? '',
        minWidth: '100px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('original_price'),
      },
    ],
    [previewData, headerData]
  );

  const columnsResult = useMemo(
    () => [
      {
        name: <span className={headerData?.package_detail_id ? '' : 'header-danger'}>Paket Detail ID</span>,
        selector: (row) => row?.package_detail_id ?? '',
        minWidth: '100px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('package_detail_id'),
      },
      {
        name: 'Workshop',
        selector: (row) => row?.workshop_name ?? '',
        minWidth: '100px',
        sortable: false,
      },
      {
        name: <span className={headerData?.package_name ? '' : 'header-danger'}>Nama Paket</span>,
        selector: (row) => row?.package_name ?? '',
        minWidth: '100px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('package_name'),
      },
      {
        name: <span className={headerData?.component_name ? '' : 'header-danger'}>Komponen</span>,
        minWidth: '100px',
        selector: (row) => row?.component_name ?? '',
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesPreview('component_name'),
      },
      {
        name: <span className={headerData?.product_name ? '' : 'header-danger'}>Nama Produk</span>,
        minWidth: '100px',
        selector: (row) => row?.product_name ?? '',
        sortable: false,
        wrap: true,
        conditionalCellStyles: conditionCellStylesPreview('product_name'),
      },
      {
        name: <span className={headerData?.price ? '' : 'header-danger'}>Price</span>,
        selector: (row) => row?.price ?? '',
        minWidth: '100px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('price'),
      },
      {
        name: <span className={headerData?.original_price ? '' : 'header-danger'}>Original Price</span>,
        selector: (row) => row?.original_price ?? '',
        minWidth: '100px',
        sortable: false,
        conditionalCellStyles: conditionCellStylesPreview('original_price'),
      },
    ],
    [resultData]
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Data'}</div>;
  }, []);

  const { getRootProps } = useDropzone({
    multiple: false,
    disabled: loading,
    onDrop: (files) => {
      handleDropFile(files[0]);
      setIsDragOver(false);
    },
    onDragOver: () => {
      removeErrorDocument();
      setIsDragOver(true);
    },
    onDragLeave: () => {
      setIsDragOver(false);
    },
    onFileDialogOpen: () => {
      removeErrorDocument();
    },
  });

  const handleDropFile = (data) => {
    const file = data;
    const maxSize = 20480;
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const allowTypes = ['csv'];
    const nameFileSplit = file?.name ? file?.name.split('.') : [];

    if (nameFileSplit.length == 0) {
      formData.error_document = 'Format file tidak diketahui';
      setFormData({ ...formData });
    } else {
      const selected = find(allowTypes, (item) => {
        return nameFileSplit[nameFileSplit.length - 1].toLowerCase() === item;
      });
      if (!selected) {
        formData.error_document = 'File tidak sesuai dengan format';
        setFormData({ ...formData });
      } else {
        if (fileSize == null) {
          formData.error_document = 'Ukuran file tidak diketahui';
          setFormData({ ...formData });
        } else if (fileSize > maxSize) {
          formData.error_document = 'Ukuran file maksimal 20MB';
          setFormData({ ...formData });
        } else {
          const fileUrl = URL.createObjectURL(file);
          formData.error_document = '';
          formData.document_name = file?.name ?? '';
          formData.document_url = fileUrl ?? '';
          formData.document_file = file ?? '';
          setFormData({ ...formData });
          handleReadHeader(file);
        }
      }
    }
  };

  const handleCheckFieldHeader = (results, key) => {
    const data = results?.data?.length && results?.data?.length !== 0 ? results?.data[0] : [];
    const available = find(data, (item) => {
      return item === key;
    });

    return !available ? false : true;
  };

  const handleReadHeader = (file) => {
    setProcessParsing(true);
    Papa.parse(file, {
      header: false,
      complete: (results) => {
        let invalidHeader = true;
        const dataHeader = {
          package_detail_id: handleCheckFieldHeader(results, 'package_detail_id'),
          package_name: handleCheckFieldHeader(results, 'package_name'),
          workshop_name: handleCheckFieldHeader(results, 'workshop_name'),
          component_name: handleCheckFieldHeader(results, 'component_name'),
          product_name: handleCheckFieldHeader(results, 'product_name'),
          original_price: handleCheckFieldHeader(results, 'original_price'),
          price: handleCheckFieldHeader(results, 'price'),
        };
        if (
          !dataHeader.package_detail_id ||
          !dataHeader.package_name ||
          !dataHeader.workshop_name ||
          !dataHeader.component_name ||
          !dataHeader.product_name ||
          !dataHeader.original_price ||
          !dataHeader.price
        ) {
          invalidHeader = true;
          setErrorMsgPreview('Data CSV tidak sesuai dengan format');
        } else {
          invalidHeader = false;
          setErrorMsgPreview('');
        }

        setHeaderData(dataHeader);
        handleReadContentPreview(file, invalidHeader);
      },
    });
  };

  const handleReadContentPreview = (file, invalidHeader) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const dummyData = results?.data?.length !== 0 ? results?.data.slice(0, 500) : [];
        const dataPreview = map(dummyData, (item) => {
          return {
            package_detail_id: item['package_detail_id'] ?? '',
            package_name: item['package_name'] ?? '',
            workshop_name: item['workshop_name'] ?? '',
            component_name: item['component_name'] ?? '',
            product_name: item['product_name'] ?? '',
            original_price: item['original_price'] ?? '',
            price: item['price'] ?? '',
          };
        });
        setInvalidPreview(dataPreview?.length !== 0 && !invalidHeader ? false : true);
        setPreviewData(dataPreview);
        setResultTotalData(results?.data?.length);
      },
    });
    setProcessParsing(false);
  };

  const defaultDataForm = () => {
    const dataForm = {
      document_name: '',
      document_url: '',
      document_file: '',
      document_key: null,
      error_document: '',
    };

    setPreviewMode(false);
    setResultMode(false);
    setSuccessImport(false);
    setInvalidPreview(true);
    setProcessParsing(false);
    setResultData([]);
    setPreviewData([]);
    setHeaderData({});
    setResultTotalData(0);
    setFormData(dataForm);
    setErrorMsgPreview('Data CSV tidak sesuai dengan format');
  };

  const handleDeleteDocument = () => {
    if (!loading) {
      defaultDataForm();
    }
  };

  const handleDownloadDocument = () => {
    if (!loading) {
      const link = document.createElement('a');
      link.setAttribute('href', formData.document_url);
      link.setAttribute('download', formData.document_name ?? '');
      link.click();
    }
  };

  const removeErrorDocument = () => {
    if (formData.error_document !== '') {
      formData.error_document = '';
      setFormData({ ...formData });
    }
  };

  const handleSubmit = () => {
    if (!previewMode) {
      setPreviewMode(true);
    } else {
      submitForm();
    }
  };

  const handleCancel = () => {
    if (previewMode) {
      setPreviewMode(false);
      setResultMode(false);
    } else if (resultMode && !successImport) {
      setPreviewMode(true);
      setResultMode(false);
    } else {
      toggle(successImport);
    }
  };

  async function submitForm() {
    setResultData([]);
    setLoading(true);

    try {
      const dataForm = new FormData();
      dataForm.append('files', formData?.document_file);
      dataForm.append('preview', true);

      const response = await api.post(`v2/intools/packages/pricing/import/`, dataForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (+response?.status === 200) {
        setPreviewMode(false);
        setResultMode(true);
        setSuccessImport(true);
        setLoading(false);
      }
    } catch (error) {
      toggle();
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  useEffect(() => {
    defaultDataForm();
  }, [modal]);

  return (
    <>
      <Modal
        className={
          previewMode
            ? 'modal-dialog-centered modal-preview-csv'
            : resultMode && !successImport
            ? 'modal-dialog-centered modal-preview-csv'
            : 'modal-dialog-centered modal-form'
        }
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
      >
        <ModalBody className="body mt-3">
          <div className="header">
            Import Harga Paket <br />
            <span className="h4">
              {!previewMode && !resultMode
                ? 'Langkah 1/3: Upload File'
                : previewMode
                ? 'Langkah 2/3: Pengecekan Header'
                : resultMode
                ? 'Langkah 3/3: Finalisasi'
                : ''}
            </span>
            <br />
            {resultMode && successImport && (
              <span className="badge-custom badge-custom-success">{`Berhasil - ${resultTotalData} baris data berhasil diimport`}</span>
            )}
            {resultMode && !successImport && (
              <span className="badge-custom badge-custom-danger">{`Gagal - ${resultData?.length} dari ${resultTotalData} baris data bermasalah, silahkan cek kembali file Anda`}</span>
            )}
          </div>
          <div className="content">
            {!previewMode && !resultMode && (
              <>
                <Row className="p-2">
                  Dengan fitur Import Data Harga Paket, Anda dapat mengunggah seluruh data Anda sekaligus ke dalam
                  sistem kami dengan cepat dan mudah. Mohon menggunakan template pada fitur export.
                </Row>
                <Row className="p-2 pb-3">Berikut ini adalah hal-hal yang harus disediakan:</Row>
                <Row className="m-0">
                  <Col lg={6} md={6} sm={6} className="pl-0">
                    <Row className="pl-2 pb-2 label-required">ID Paket</Row>
                    <Row className="pl-2 pb-2 label-required">Nama Workshop</Row>
                    <Row className="pl-2 pb-2 label-required">Nama Paket</Row>
                  </Col>
                  <Col lg={6} md={6} sm={6} className="pl-0">
                    <Row className="pl-2 pb-2 label-required">Komponen Paket</Row>
                    <Row className="pl-2 pb-2 label-required">Nama Produk</Row>
                    <Row className="pl-2 pb-2 label-required">Harga Paket</Row>
                    <Row className="pl-2 pb-2">Harga Original (digunakan untuk harga coret di tampilan aplikasi)</Row>
                  </Col>
                </Row>
                <Row className="p-2 pt-3">Upload Data CSV kamu disini</Row>
                <Row className="mb-3">
                  <Col lg={12} className="text-left text-bold">
                    {formData?.document_name && (
                      <div className="document-box-workshop">
                        <span className="file" onClick={handleDownloadDocument}>
                          {formData?.document_name}
                        </span>
                        <img className="icon-trash-workshop" src={TrashIcon} onClick={handleDeleteDocument} />
                      </div>
                    )}
                    {!formData?.document_name && (
                      <div>
                        {isDragOver && (
                          <div {...getRootProps({ className: 'document-box-workshop drag-over cursor-pointer' })}>
                            <span className="choose-file">Release File</span>
                          </div>
                        )}
                        {!isDragOver && (
                          <div {...getRootProps({ className: 'document-box-workshop cursor-pointer' })}>
                            <span className="choose-file">Drag and Drop or Choose File</span>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="invalid-document">{formData?.error_document}</div>
                    <div className="document-guide">Hanya mendukung file CSV (max. 20 MB)</div>
                  </Col>
                </Row>
              </>
            )}
            {previewMode && (
              <>
                <Col sm={12} md={12} className="p-0 mt-4 overflow-auto" style={{ height: '50vh' }}>
                  <DataTable
                    persistTableHead
                    highlightOnHover
                    noHeader={true}
                    data={previewData}
                    className="table dt-responsive table-custom mb-0"
                    noDataComponent={noDataComponent}
                    customStyles={customStylesHeaderTable}
                    columns={columnsPreview}
                  />
                  <div className="invalid-document">{errorMsgPreview ?? ''}</div>
                </Col>
              </>
            )}
            {resultMode && !successImport && (
              <>
                <Col sm={12} md={12} className="p-0 mt-4">
                  <DataTable
                    persistTableHead
                    highlightOnHover
                    noHeader={true}
                    data={resultData}
                    className="table dt-responsive table-custom table-result-import mb-0"
                    noDataComponent={noDataComponent}
                    customStyles={customStylesHeaderTable}
                    columns={columnsResult}
                  />
                  <div className="invalid-document">{errorMsgPreview ?? ''}</div>
                </Col>
              </>
            )}
          </div>
          <div className="action mb-3 mt-3">
            <Button
              className="button-cancel"
              onClick={() => {
                if (!loading) {
                  handleCancel();
                }
              }}
              disabled={loading}
            >
              {previewMode ? 'Kembali' : resultMode ? (successImport ? 'Tutup' : 'Kembali') : 'Batal'}
            </Button>
            {!resultMode && (
              <Button
                className="button-action"
                onClick={() => {
                  const validDocument = previewMode ? (previewMode && invalidPreview ? false : true) : true;
                  if (!loading && !processParsing && validDocument) {
                    handleSubmit();
                  }
                }}
                disabled={loading || processParsing || (previewMode && invalidPreview)}
              >
                {loading ? <Spinner size="sm" /> : <>{previewMode ? 'Simpan' : 'Lanjut'}</>}
              </Button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default WorkshopPackageImport;
