import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { FormGroup, Button, Card, CardBody, CardHeader, Row, Col, Label } from 'reactstrap';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import FsLightbox from 'fslightbox-react';
import userPermission from '@utils/userPermission';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import ActionSwitch from '@components/field/ActionSwitch';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import Dropzone from 'react-dropzone';
import FolderIcon from '@assets/icons/folder.svg';
import TrashIcon from '@assets/icons/trash.svg';
import InfoIcon from '@assets/icons/info.svg';
import NoImage from '@assets/images/no-image.jpeg';
import { map, find, replace } from 'lodash';
import {
  PACKAGE_BOOKING_MAXIMUM_DAY,
  PAYMENT_METHOD_TYPE,
  optionsAllowCustomerType,
  PACKAGE_ALLOWED,
} from '@utils/Constants';
import { Tooltip } from 'react-tooltip';

const PackageDataHeader = (props) => {
  const {
    isEditDetail,
    defaultValuesHeader,
    packageDetail,
    packageCategoryOptions,
    carCompatibilityOptions,
    productGroupOptions,
    detailPrice,
    loadingPackageHeader,
    onSubmit,
    onEditHeader,
    onChangeDiscountB2C,
    onChangeDiscountB2B,
    onCancelPackageHeader,
    onUpdateAllowedCustomerType,
  } = props;
  const {
    control,
    reset,
    setValue,
    setError,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [images, setImages] = useState([]);
  const [imgPrev, setImgPrev] = useState('');
  const [modalType, setModalType] = useState('');
  const [payloadForm, setPayloadForm] = useState();
  const [disableForm, setDisableForm] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [showLightbox, setShowLightbox] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmChangeModal, setConfirmChangeModal] = useState(false);
  const [loadingSumbit, setLoadingSubmit] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [processUpload, setProcessUpload] = useState(false);
  const [disabledUplaod, setDisabledUplaod] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [image, setImage] = useState();
  const [imageKey, setImageKey] = useState();
  const [allowCustomerType, setAllowCustomerType] = useState('');
  const [tempAllowCustType, setTempAllowCustType] = useState('');
  const [isEditHeader, setIsEditHeader] = useState(false);

  const api = new API('v2');
  const { generatePermission } = userPermission();

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'packageall'));
  };

  const allowEdit = allowPermission('packageheaderedit') || allowPermission('packageheaderall');

  const setDefaultForm = () => {
    reset({
      header: {
        ...defaultValuesHeader,
        b2c_price: defaultValuesHeader?.price,
        b2b_price: defaultValuesHeader?.price_b2b,
        b2c_package_price: defaultValuesHeader?.price_after_disc,
        b2c_package_discount: Math.round(defaultValuesHeader?.price_disc_percentage),
        b2b_package_price: defaultValuesHeader?.price_b2b_after_disc,
        b2b_package_discount: Math.round(defaultValuesHeader?.price_b2b_disc_percentage),
        nett_price: defaultValuesHeader?.nett_price,
      },
    });
  };

  const setImagesPreview = (image_link) => {
    const imagePreview = image_link ?? '';
    const imagesPreview = image_link ? [image_link] : [];

    setImgPrev(imagePreview);
    setImages(imagesPreview);
    setImage(imagePreview);
  };

  const previewImage = () => {
    if (images?.length && disableForm) {
      setShowLightbox(!showLightbox);
    }
  };

  const restoreFormPackageHeader = () => {
    setDefaultForm();
    setImagesPreview(defaultValuesHeader?.image_link);
    setDisableForm(true);
    setConfirmModal(false);
    setErrorUpload();
    setIsEditHeader(false);
    onCancelPackageHeader(true);
    if (defaultValuesHeader?.allowed_customer_type) {
      setAllowCustomerType(
        optionsAllowCustomerType.filter((option) => option?.value === defaultValuesHeader?.allowed_customer_type)[0]
      );
    }
  };

  const editForm = () => {
    if (isEditDetail) {
      onSubmit(false, `Mohon selesaikan edit package detail`);
    } else {
      setDisableForm(false);
      setIsEditHeader(true);
    }
  };

  const cancelEditForm = () => {
    setModalType('Batalkan');
    setConfirmModal(true);
  };

  const submitForm = (data) => {
    const isValidPackageDetails = validatePackageDetails();
    if (isValidPackageDetails) {
      const isValidAddOn = validateAddOn();
      if (isValidAddOn) {
        const isValid = validateHeaderPrice();
        if (isValid.valid) {
          setModalType('Simpan');
          setConfirmModal(true);
          setPayloadForm(data);
        } else {
          onSubmit(false, isValid.msgError);
        }
      } else {
        onSubmit(false, `Is Add On package detail can't be active`);
      }
    } else {
      onSubmit(false, 'There is no active package detail');
    }
  };

  const errorForm = (e) => {
    onSubmit(false, 'Mohon lengkapi form');
  };

  const validateHeaderPrice = () => {
    let isValid = {
      valid: true,
      msgError: '',
    };
    const b2c_price = getValues('header.b2c_price') ?? 0;
    const b2b_price = getValues('header.b2b_price') ?? 0;
    const b2c_package_price = getValues('header.b2c_package_price') ?? 0;
    const b2b_package_price = getValues('header.b2b_package_price') ?? 0;

    if (allowCustomerType?.value == PACKAGE_ALLOWED.B2B) {
      // if (b2b_price < b2b_package_price) {
      //   isValid.valid = false;
      //   isValid.msgError = 'B2B Package Price tidak boleh lebih dari Total B2B Price';
      // }
    } else if (allowCustomerType?.value == PACKAGE_ALLOWED.BOTH) {
      let msgError = '';
      // if (b2b_price < b2b_package_price) {
      //   isValid.valid = false;
      //   msgError = 'B2B Package Price tidak boleh lebih dari Total B2B Price';
      // }
      // if (b2c_price < b2c_package_price) {
      //   isValid.valid = false;
      //   msgError += msgError !== '' ? ', ' : '';
      //   msgError += 'B2C Package Price tidak boleh lebih dari Total B2C Price';
      // }
      if (!isValid.valid) {
        isValid.msgError = msgError;
      }
    }

    return isValid;
  };
  const validatePackageDetails = () => {
    let isValid = true;

    if (getValues('header.is_active')) {
      if (packageDetail) {
        let activeComponents = 0;
        packageDetail.map((component) => {
          if (component.is_single && component.is_active) {
            activeComponents++;
          }
          if (!component.is_single) {
            let activeProducts = 0;
            component.products.map((product) => {
              if (product?.is_active) {
                activeProducts++;
              }
            });
            activeComponents += activeProducts;
          }
        });
        isValid = activeComponents > 0 ? true : false;
      }

      if (packageDetail?.length < 1) {
        isValid = false;
      }
    }

    return isValid;
  };

  const validateAddOn = () => {
    let isValidAddOn = true;
    if (getValues('header.is_active') && packageDetail) {
      packageDetail.map((component) => {
        if (!component?.is_single && packageDetail?.length == 1 && component?.is_removable) {
          isValidAddOn = false;
        }
      });
    }

    return isValidAddOn;
  };

  const updateHeaderPackage = async () => {
    setConfirmModal(false);
    setLoadingSubmit(true);
    const idProduct = payloadForm?.header?.id;
    const bookingDay = replace(payloadForm?.header.booking_day?.value, 'H - ', '');
    const carModel = map(defaultValuesHeader.car_compatibility, (data) => {
      const check = find(payloadForm.header.car_compatibility, (item) => {
        return data.id === item.id;
      });
      return { id: data?.id, car_details_id: data?.carID, is_deleted: check ? false : true };
    });

    payloadForm?.header?.car_compatibility &&
      payloadForm.header.car_compatibility.map((data) => {
        const list = find(defaultValuesHeader.car_compatibility, (item) => {
          return data.id === item.id;
        });
        if (!list) {
          carModel.push({ id: null, car_details_id: data?.carID, is_deleted: false });
        }
      });

    const newCarModel = map(payloadForm.header.car_compatibility, (data) => {
      return { id: null, car_details_id: data?.carID, is_deleted: false };
    });

    let payload = {
      package_name: payloadForm?.header?.name,
      category_id: payloadForm?.header?.category?.id,
      description: payloadForm?.header?.description,
      image_link: imageKey ? imageKey : payloadForm?.header?.image_link,
      is_active: payloadForm?.header?.is_active,
      is_fbo: payloadForm?.header?.is_fbo,
      product_group: payloadForm?.header?.product_group?.id,
      car_compatibility:
        defaultValuesHeader.car_compatibility.length > 0
          ? carModel
          : payloadForm.header.car_compatibility.length > 0
          ? newCarModel
          : [],
      day_of_booking: bookingDay,
      allowed_payment_method: payloadForm?.header?.payment_method_type?.value ?? PAYMENT_METHOD_TYPE[0]?.value,
      allowed_customer_type: allowCustomerType?.value,
    };
    if (allowCustomerType?.value == PACKAGE_ALLOWED.B2B) {
      payload.description = null;
      payload.b2c_package_price = 0;
      payload.b2b_package_price = payloadForm?.header?.b2b_package_price ?? 0;
    } else if (allowCustomerType?.value == PACKAGE_ALLOWED.BOTH) {
      payload.b2c_package_price = payloadForm?.header?.b2c_package_price ?? 0;
      payload.b2b_package_price = payloadForm?.header?.b2b_package_price ?? 0;
    }

    try {
      const response = await api.put(`v2/intools/packages/${idProduct}/header/`, payload);
      setImageKey();
      setTimeout(() => {
        setLoadingSubmit(false);
        setDisableForm(true);
        onSubmit(true);
        setIsEditHeader(false);
      }, 200);
    } catch (e) {
      let defaultMsg = 'Gagal update package header';
      let errMsg = e?.response?.data?.error?.message
        ? defaultMsg + ', ' + e?.response?.data?.error?.message
        : defaultMsg;
      setTimeout(() => {
        setLoadingSubmit(false);
        onSubmit(false, errMsg);
      }, 1500);
    }
  };

  const setErrorMessage = (nameFiled, type, message) => {
    setError(nameFiled, { type: type, message: message }, { shouldFocus: true });
  };

  const validationPackageName = (value) => {
    let validation = {
      valid: value?.length == 0 || value?.length > 100 || value?.length < 5 ? false : true,
      errorMsg:
        value?.length == 0
          ? 'Package Name harus diisi'
          : value?.length > 100
          ? 'Package Name max 100 karakter'
          : value?.length < 5
          ? 'Package Name min 5 karakter'
          : '',
    };

    return validation;
  };

  const validationDescription = (value) => {
    let validation = {
      valid: value?.length > 1000 ? false : true,
      errorMsg: value?.length > 1000 ? 'Description max 1000 karakter' : '',
    };

    return validation;
  };

  const handleChangePackagePrice = (val, type) => {
    const valPrice = getValues(`header.b2c_price`) ?? 0;
    const valPriceB2b = getValues(`header.b2b_price`) ?? 0;
    const price = type === PACKAGE_ALLOWED.B2C ? valPrice : valPriceB2b;
    const calcDiscount = price > 0 ? 100 - (+val / +price) * 100 : 0;
    const calcDiscountRound = Math.round(calcDiscount);
    setValue(`header.${type}_package_price`, val);
    setValue(`header.${type}_package_discount`, calcDiscountRound);
    handleChangeDiscount(calcDiscount, val, type);
  };

  const handleChangeDiscount = (disc, package_price, type) => {
    if (isEditHeader) {
      if (type == PACKAGE_ALLOWED.B2C) onChangeDiscountB2C(disc, package_price);
      if (type == PACKAGE_ALLOWED.B2B) onChangeDiscountB2B(disc, package_price);
    }
  };

  const handleUpdateAllowedCustomerType = () => {
    onUpdateAllowedCustomerType(true);
    reset({
      header: {
        ...defaultValuesHeader,
        b2c_price: 0,
        b2b_price: 0,
        b2c_package_price: 0,
        b2c_package_discount: 0,
        b2b_package_price: 0,
        b2b_package_discount: 0,
        nett_price: 0,
      },
    });
  };

  const uploadImage = (data) => {
    const file = data[0];
    const maxSize = 3000; //3000
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const nameFileSplit = file?.name ? file?.name.split('.') : [];
    const type = nameFileSplit[nameFileSplit.length - 1].toLowerCase();

    if (nameFileSplit.length == 0) {
      setErrorUpload('Format file tidak diketahui');
    } else {
      if (type == 'jpeg' || type == 'jpg' || type == 'png') {
        if (fileSize == null) {
          setErrorUpload('Ukuran file tidak diketahui');
        } else if (fileSize > maxSize) {
          setErrorUpload('Ukuran file maksimal 3MB');
        } else {
          handleUploadImage(data);
        }
      } else {
        setErrorUpload('File tidak sesuai dengan format');
      }
    }
  };

  const handleUploadImage = (file) => {
    setDisabledUplaod(true);
    setProcessUpload(true);

    if (file[0]?.name) {
      const paramsImagedUpload = new FormData();
      paramsImagedUpload.append('namespace', 'package');
      paramsImagedUpload.append('identifier', getValues(`header.id`));
      paramsImagedUpload.append('image', file[0]);

      api
        .post(`v2/intools/image-upload/`, paramsImagedUpload)
        .then((response) => {
          const keyFile = response?.data?.data?.key ?? '';
          if (keyFile !== '') {
            handleChangeImage(file, keyFile);
            showPreviewImage(file);
          } else {
            setErrorUpload('Gagal upload image');
          }
          setDisabledUplaod(false);
          setProcessUpload(false);
        })
        .catch((error) => {
          const message = `API Error : ${error?.response?.data?.error?.message}`;
          setDisabledUplaod(false);
          setProcessUpload(false);
          setErrorUpload(message);
        });
    } else {
      setDisabledUplaod(false);
      setProcessUpload(false);
      setErrorUpload('Gagal upload image, file tidak diketahui');
    }
  };

  const handleChangeImage = (data, key) => {
    setImage(data[0]);
    setImageKey(key);
  };

  const showPreviewImage = (image) => {
    if (image) {
      const bannerPreview = document.getElementById('bannerPromoPreview');
      bannerPreview.src = URL.createObjectURL(image[0]);
      bannerPreview.onload = function () {
        URL.revokeObjectURL(bannerPreview.src);
      };
    } else {
      const bannerPreview = document.getElementById('bannerPromoPreview');
      bannerPreview.src = '';
    }
  };

  const handleDeleteImage = () => {
    setImage(null);
    showPreviewImage();
    setImageKey();
    setValue(`header.image_link`, null);
  };

  useEffect(() => {
    setDefaultForm();
    setImagesPreview(defaultValuesHeader?.image_link);
    if (defaultValuesHeader?.allowed_customer_type) {
      setAllowCustomerType(
        optionsAllowCustomerType.filter((option) => option?.value === defaultValuesHeader?.allowed_customer_type)[0]
      );
    }
  }, [defaultValuesHeader]);

  useEffect(() => {
    onEditHeader(isEditHeader);
  }, [isEditHeader]);

  useEffect(() => {
    if (!isEditDetail) setDefaultForm();
  }, [isEditDetail]);

  useEffect(() => {
    if (isEditDetail) {
      if (+detailPrice?.price?.formatMoney >= 0) {
        setValue(`header.b2c_price`, detailPrice?.price?.formatMoney);
      }
      if (+detailPrice?.price_b2b?.formatMoney >= 0) {
        setValue(`header.b2b_price`, detailPrice?.price_b2b?.formatMoney);
      }
      if (+detailPrice?.package_price?.formatMoney >= 0) {
        setValue(`header.b2c_package_price`, detailPrice?.package_price?.formatMoney);
      }
      if (+detailPrice?.package_price_b2b?.formatMoney >= 0) {
        setValue(`header.b2b_package_price`, detailPrice?.package_price_b2b?.formatMoney);
      }
      if (+detailPrice?.nett_price?.formatMoney >= 0) {
        setValue(`header.nett_price`, detailPrice?.nett_price?.formatMoney);
      }
    }
  }, [detailPrice]);

  return (
    <React.Fragment>
      {loadingPackageHeader && <LoadingSpinner></LoadingSpinner>}
      {!loadingPackageHeader && (
        <>
          <ToastContainer />

          <FsLightbox toggler={showLightbox} sources={images} type="image" />

          <Card>
            <CardHeader className="bg-primary text-white mt-0">Package Header</CardHeader>
            <CardBody>
              <Controller
                name={`header.workshop.name`}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <Row className="mb-3">
                    <Label lg={3}>Workshop</Label>
                    <InputField colWidth={9} inputType={'text'} disabled={true} {...rest}></InputField>
                  </Row>
                )}
              />

              <Controller
                name={`header.name`}
                control={control}
                rules={{
                  validate: () => {
                    const validation = validationPackageName(getValues(`header.name`));
                    return validation?.valid || validation?.errorMsg;
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Row className="mb-3">
                    <Label lg={3} className="label-required">
                      Package Name
                    </Label>
                    <InputField
                      colWidth={9}
                      inputType={'text'}
                      disabled={disableForm || loadingSumbit}
                      value={value}
                      invalid={error?.message ? true : false}
                      errorMessage={error?.message ?? ''}
                      placeholder={'Package Name'}
                      onChange={(e) => {
                        const val =
                          e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : getValues(`header.name`);
                        const validation = validationPackageName(val);
                        setErrorMessage(`header.name`, 'manual', validation?.errorMsg);
                        setValue(`header.name`, val);
                        onChange(val);
                      }}
                    ></InputField>
                  </Row>
                )}
              />

              <Controller
                control={control}
                name={`header.category`}
                rules={{
                  validate: () => {
                    const valid = !getValues(`header.category.value`) ? false : true;
                    return valid || 'Category harus diisi';
                  },
                }}
                render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                  return (
                    <Row className="mb-3">
                      <Label lg={3} className="label-required">
                        Category
                      </Label>
                      <SelectField
                        {...rest}
                        colWidth={9}
                        placeholder={'Pilih Category'}
                        disabled={disableForm || loadingSumbit}
                        closeMenuOnSelect={true}
                        options={packageCategoryOptions}
                        errorMessage={error?.message ?? ''}
                      />
                    </Row>
                  );
                }}
              />

              <Controller
                control={control}
                name={`header.car_compatibility`}
                render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                  return (
                    <Row className="mb-3">
                      <Label lg={3}>Car Compatibility</Label>
                      <SelectField
                        {...rest}
                        colWidth={9}
                        placeholder={'Car Model'}
                        isMulti={true}
                        disabled={disableForm || loadingSumbit}
                        closeMenuOnSelect={false}
                        isSelectAll={true}
                        options={carCompatibilityOptions}
                        errorMessage={error?.message ?? ''}
                        titlePreview={'Car Compability'}
                        placeholderPreview={'Tipe Mobil'}
                        labelDataPreview={'Tipe Mobil'}
                      />
                    </Row>
                  );
                }}
              />

              {allowCustomerType?.value !== PACKAGE_ALLOWED.B2B ? (
                <>
                  <Controller
                    name={`header.description`}
                    control={control}
                    rules={{
                      validate: () => {
                        const validation = validationDescription(getValues(`header.description`));
                        return validation?.valid || validation?.errorMsg;
                      },
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Row className="mb-3">
                        <Label lg={3}>Description</Label>
                        <InputField
                          colWidth={9}
                          inputType={'textarea'}
                          disabled={disableForm || loadingSumbit}
                          value={value}
                          invalid={error?.message ? true : false}
                          errorMessage={error?.message ?? ''}
                          placeholder={'Description'}
                          onChange={(e) => {
                            const val =
                              e.target.value !== ' '
                                ? e.target.value.replace(/  +/g, ' ')
                                : getValues(`header.description`);
                            const validation = validationDescription(val);
                            setErrorMessage(`header.description`, 'manual', validation?.errorMsg);
                            setValue(`header.description`, val);
                            onChange(val);
                          }}
                        ></InputField>
                      </Row>
                    )}
                  />
                </>
              ) : (
                <></>
              )}

              {allowCustomerType?.value == PACKAGE_ALLOWED.B2C ? (
                <>
                  <Controller
                    control={control}
                    name={`header.b2c_price`}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Row className="mb-3">
                        <Label lg={3} className="label-required">
                          Price
                        </Label>
                        <InputField
                          colWidth={9}
                          inputType={'text'}
                          disabled={true}
                          value={`Rp. ${Helper.formatMoney(value)}`}
                          placeholder={'Price'}
                        ></InputField>
                      </Row>
                    )}
                  />
                </>
              ) : (
                <></>
              )}

              <Controller
                control={control}
                name={`header.customer_type`}
                render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                  return (
                    <Row className="mb-3">
                      <Label lg={3} className="required">
                        Customer Type
                      </Label>
                      <SelectField
                        {...rest}
                        colWidth={9}
                        placeholder={'B2B, B2C, All'}
                        disabled={disableForm || loadingSumbit}
                        closeMenuOnSelect={true}
                        options={optionsAllowCustomerType}
                        errorMessage={error?.message ?? ''}
                        titlePreview={'Customer Type'}
                        placeholderPreview={'B2B, B2C, All'}
                        labelDataPreview={'B2B, B2C, All'}
                        value={allowCustomerType}
                        onChange={(e) => {
                          if (allowCustomerType !== '') {
                            setTempAllowCustType(e);
                            setConfirmChangeModal(true);
                          } else {
                            setAllowCustomerType(e);
                          }
                        }}
                      />
                    </Row>
                  );
                }}
              />

              {allowCustomerType?.value === PACKAGE_ALLOWED.B2B || allowCustomerType?.value === PACKAGE_ALLOWED.BOTH ? (
                <>
                  <Controller
                    control={control}
                    name={`header.b2b_price`}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Row className="mb-3">
                        <Label lg={3} className="label-required">
                          Total B2B Price WI
                        </Label>
                        <InputField
                          colWidth={9}
                          inputType={'text'}
                          disabled={true}
                          value={`Rp. ${Helper.formatMoney(value)}`}
                          placeholder={'Total B2B Price WI'}
                        ></InputField>
                      </Row>
                    )}
                  />

                  <Row className="mb-3">
                    <Controller
                      name={`header.b2b_package_price`}
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                          <Tooltip id="b2b-package-info" style={{ width: '80%' }} />
                          <Label lg={3} className="required">
                            <img
                              src={InfoIcon}
                              alt=""
                              className="mr-1 cursor-pointer"
                              width={16}
                              data-tooltip-id="b2b-package-info"
                              data-tooltip-content="Harga custom pada Package Price akan menyesuaikan potongan harga pada sell price dari setiap item dan tidak mempengaruhi data WI."
                            />
                            B2B Package Price
                          </Label>
                          <InputField
                            colWidth={9}
                            inputType={'text'}
                            disabled={disableForm || loadingSumbit}
                            value={`Rp. ${Helper.formatMoney(value)}`}
                            onChange={(e) => {
                              const val = Helper.inputNumber(e.target.value, e.target.value ?? 0);
                              onChange(val);
                              handleChangePackagePrice(val, PACKAGE_ALLOWED.B2B);
                            }}
                          ></InputField>
                        </>
                      )}
                    />
                  </Row>
                </>
              ) : (
                <></>
              )}

              {allowCustomerType?.value === PACKAGE_ALLOWED.BOTH ? (
                <>
                  <Controller
                    control={control}
                    name={`header.b2c_price`}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Row className="mb-3">
                        <Label lg={3} className="label-required">
                          Total B2C Price
                        </Label>
                        <InputField
                          colWidth={9}
                          inputType={'text'}
                          disabled={true}
                          value={`Rp. ${Helper.formatMoney(value)}`}
                          placeholder={'Total B2C Price'}
                        ></InputField>
                      </Row>
                    )}
                  />

                  <Row className="mb-3">
                    <Controller
                      name={`header.b2c_package_price`}
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                          <Tooltip id="b2c-package-info" style={{ width: '80%' }} />
                          <Label lg={3} className="required">
                            <img
                              src={InfoIcon}
                              alt=""
                              className="mr-1 cursor-pointer"
                              width={16}
                              data-tooltip-id="b2c-package-info"
                              data-tooltip-content="Harga custom pada Package Price akan menyesuaikan potongan harga pada sell price dari setiap item dan tidak mempengaruhi data WI."
                            />
                            B2C Package Price
                          </Label>
                          <InputField
                            colWidth={9}
                            inputType={'text'}
                            disabled={disableForm || loadingSumbit}
                            value={`Rp. ${Helper.formatMoney(value)}`}
                            onChange={(e) => {
                              const val = Helper.inputNumber(e.target.value, e.target.value ?? 0);
                              onChange(val);
                              handleChangePackagePrice(val, PACKAGE_ALLOWED.B2C);
                            }}
                          ></InputField>
                        </>
                      )}
                    />
                  </Row>
                </>
              ) : (
                <></>
              )}
              {allowCustomerType?.value !== PACKAGE_ALLOWED.B2C ? (
                <>
                  <Controller
                    control={control}
                    name={`header.nett_price`}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Row className="mb-3">
                        <Label lg={3} className="label-required">
                          Total Nett Price
                        </Label>
                        <InputField
                          colWidth={9}
                          inputType={'text'}
                          disabled={true}
                          value={`Rp. ${Helper.formatMoney(value)}`}
                          placeholder={'Total Nett Price'}
                        ></InputField>
                      </Row>
                    )}
                  />
                </>
              ) : (
                <></>
              )}

              <Row>
                <Label lg={3}>Image</Label>
                <Col lg={6}>
                  {disableForm && !image && (
                    <div className="gallery-container-workshop mb-0">
                      <div className="box">
                        <img id="bannerPromoPreview" src={NoImage} height={150} />
                      </div>
                    </div>
                  )}

                  {allowEdit && !disableForm && !image && (
                    <Dropzone
                      onDrop={(file) => {
                        uploadImage(file);
                        setIsDragOver(false);
                      }}
                      onDragOver={() => setIsDragOver(true)}
                      onDragLeave={() => setIsDragOver(false)}
                      disabled={disabledUplaod || disableForm || loadingSumbit}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="gallery-container-workshop mb-3">
                          <section>
                            {!processUpload && (
                              <div>
                                {isDragOver && (
                                  <div {...getRootProps({ className: 'box-upload drag-over' })}>
                                    <img src={FolderIcon}></img>
                                    <div>
                                      <div className="title mt-3">Release File</div>
                                      <div className="detail mt-2">Maximum file size 3MB.</div>
                                      <div className="detail">jpeg, jpg, png</div>
                                    </div>
                                  </div>
                                )}
                                {!isDragOver && (
                                  <div {...getRootProps({ className: 'box-upload' })}>
                                    <img src={FolderIcon}></img>
                                    <div>
                                      <div className="title mt-3">Click or drag file to this area to upload</div>
                                      <div className="detail mt-2">Maximum file size 3MB.</div>
                                      <div className="detail">jpeg, jpg, png</div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {processUpload && (
                              <div {...getRootProps({ className: 'box-upload' })}>
                                <img src={FolderIcon}></img>
                                <div>
                                  <div className="title mt-3">Please wait...</div>
                                  <div className="detail mt-2">Maximum file size 3MB.</div>
                                  <div className="detail">jpeg, jpg, png</div>
                                </div>
                              </div>
                            )}
                          </section>
                          <div className="invalid-hour">{errorUpload}</div>
                        </div>
                      )}
                    </Dropzone>
                  )}

                  {image && (
                    <div className="gallery-container-workshop mb-0">
                      <div className="box">
                        {!disableForm && !loadingSumbit && (
                          <div className="icon-delete ml-0" src={TrashIcon} onClick={() => handleDeleteImage()}></div>
                        )}
                        <img id="bannerPromoPreview" src={image} height={150} onClick={previewImage} />
                      </div>
                    </div>
                  )}
                </Col>
              </Row>

              <Controller
                control={control}
                name={`header.is_active`}
                render={({ field: { ref, value, name, ...rest } }) => {
                  return (
                    <FormGroup row>
                      <Label lg={3}>Aktif</Label>
                      <Col className="p-2">
                        <ActionSwitch
                          {...rest}
                          inputName={name}
                          isChecked={value}
                          customClass="mb-0"
                          editableForm={disableForm || loadingSumbit}
                        />
                      </Col>
                    </FormGroup>
                  );
                }}
              />

              <Controller
                control={control}
                name={`header.payment_method_type`}
                render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                  return (
                    <Row className="mb-3">
                      <Tooltip id="method-type-info" style={{ width: '80%' }} />
                      <Label lg={3}>
                        <img
                          src={InfoIcon}
                          alt=""
                          className="mr-1 cursor-pointer"
                          width={16}
                          data-tooltip-id="method-type-info"
                          data-tooltip-content="Pengaturan untuk pilihan pembayaran di customer platform. Jika dipilih online, maka customer hanya mempunyai pilihan pembayaran melalui online"
                        />
                        Payment Methods
                      </Label>
                      <SelectField
                        {...rest}
                        colWidth={9}
                        placeholder="Payment Method"
                        disabled={disableForm || loadingSumbit}
                        options={PAYMENT_METHOD_TYPE}
                        errorMessage={error?.message ?? ''}
                        titlePreview="Payment Method"
                        placeholderPreview="Payment Method"
                        labelDataPreview="Payment Method"
                      />
                    </Row>
                  );
                }}
              />

              <Controller
                control={control}
                name={`header.booking_day`}
                render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                  return (
                    <Row className="mb-3">
                      <Tooltip id="day-of-booking-info" style={{ width: '80%' }} />
                      <Label lg={3}>
                        <img
                          src={InfoIcon}
                          alt=""
                          className="mr-1 cursor-pointer"
                          width={16}
                          data-tooltip-id="day-of-booking-info"
                          data-tooltip-content="Pengaturan minimal hari sebelum kedatangan. Jika diisi angka 0 maka customer dapat booking di hari yang sama saat servis dilakukan"
                        />
                        Day of Booking
                      </Label>
                      <SelectField
                        {...rest}
                        colWidth={9}
                        placeholder="Day of Booking"
                        disabled={disableForm || loadingSumbit}
                        options={PACKAGE_BOOKING_MAXIMUM_DAY}
                        errorMessage={error?.message ?? ''}
                        titlePreview="Day of Booking"
                        placeholderPreview="Day of Booking"
                        labelDataPreview="Day of Booking"
                      />
                    </Row>
                  );
                }}
              />

              <Controller
                control={control}
                name={`header.is_fbo`}
                render={({ field: { ref, value, name, ...rest } }) => {
                  return (
                    <FormGroup row>
                      <Label lg={3}>Is FBO</Label>
                      <Col className="p-2">
                        <ActionSwitch
                          {...rest}
                          inputName={name}
                          isChecked={value}
                          customClass="mb-0"
                          editableForm={disableForm || loadingSumbit}
                        />
                      </Col>
                    </FormGroup>
                  );
                }}
              />

              <Controller
                control={control}
                name={`header.product_group`}
                render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                  return (
                    <Row className="mb-3">
                      <Label lg={3} className="label-required">
                        Product Group
                      </Label>
                      <SelectField
                        {...rest}
                        colWidth={9}
                        placeholder={'Product Group'}
                        disabled={disableForm || loadingSumbit}
                        closeMenuOnSelect={true}
                        options={productGroupOptions}
                        errorMessage={error?.message ?? ''}
                      />
                    </Row>
                  );
                }}
              />

              {allowEdit && (
                <Row className="mt-3">
                  <Col sm={12} md={12} className="section-action">
                    <Button
                      className="button-action save"
                      disabled={loadingSumbit}
                      onClick={disableForm ? editForm : handleSubmit(submitForm, errorForm)}
                    >
                      {disableForm ? 'Edit' : 'Simpan'}
                    </Button>
                    {!disableForm && (
                      <Button className="button-action cancel" disabled={loadingSumbit} onClick={cancelEditForm}>
                        Batalkan
                      </Button>
                    )}
                  </Col>
                </Row>
              )}
            </CardBody>
            <ConfirmationModal
              modal={confirmModal}
              toggle={() => setConfirmModal(!confirmModal)}
              header={`Anda yakin ingin ${modalType === 'Simpan' ? 'menyimpan ' : 'membatalkan '} ?`}
              subHeader={`Aksi ini akan mengakibatkan hasil edit Package ${defaultValuesHeader?.name} ${
                modalType === 'Simpan' ? ' tersimpan' : ' tidak tersimpan'
              }`}
              onConfirm={() => (modalType === 'Simpan' ? updateHeaderPackage() : restoreFormPackageHeader())}
              toggleClose={() => setConfirmModal(!confirmModal)}
            ></ConfirmationModal>
            <ConfirmationModal
              modal={confirmChangeModal}
              toggle={() => setConfirmChangeModal(!confirmChangeModal)}
              header={`Anda yakin ingin merubah 'Customer Type' ?`}
              subHeader={`Aksi ini akan menghapus 'Package Detail'`}
              onConfirm={() => {
                setAllowCustomerType(tempAllowCustType);
                setConfirmChangeModal(false);
                handleUpdateAllowedCustomerType();
              }}
              toggleClose={() => setConfirmChangeModal(!confirmChangeModal)}
            ></ConfirmationModal>
          </Card>
        </>
      )}
    </React.Fragment>
  );
};

export default PackageDataHeader;
