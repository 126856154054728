import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Collapse, Col, Row, Spinner } from 'reactstrap';
import otobuddyIcon from '@assets/icons/otobuddy.svg';
import SideButton from '@components/chat-aida/SideButton';
import StreamlitLogin from '@components/chat-aida/StreamlitLogin';
import Cookies from 'universal-cookie';
import Iframe from 'react-iframe';
import config from '@utils/config';

const cookies = new Cookies();

const WrapperChat = (props) => {
  const { handleOpenChat } = props;
  const [collapse, setCollapse] = useState(false);
  const [connected, setConnected] = useState(true);
  const [inputHeight, setInputHeight] = useState(55);

  const scrollToBottom = () => {
    const element = document.getElementById('bottom-chat');
    if (element) {
      element.scrollIntoView({ behavior: 'instant' });
    }
  };

  const toggleCollapse = () => {
    setCollapse(!collapse);
    handleOpenChat(!collapse);
    scrollToBottom();
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 500);
    if (!collapse);
  }, [collapse]);

  return (
    <div className="pt-2 wrapper-chat-aida" style={{ width: collapse ? '35%' : '0%', transition: '0.5s' }}>
      <StreamlitLogin></StreamlitLogin>
      <Collapse isOpen={collapse} horizontal={true}>
        <Card className="card-aida">
          <CardHeader className="text-white d-flex">
            <img src={otobuddyIcon} height={23} width={23} alt="AIDA" className="mr-3" />
            Otopilot
            {connected ? <div className="aida-connected" /> : <div className="aida-disconnected" />}
          </CardHeader>
          <CardBody>
            <Row
              className="body-chat-aida"
              style={{ height: `calc(100% - ${inputHeight}px)`, maxHeight: '280px !important' }}
            >
              <Iframe
                url={config.otopilot_url}
                width="100%"
                height="100%"
                id=""
                className=""
                styles={{ borderRadius: '1.5rem' }}
              />
            </Row>
          </CardBody>
        </Card>
      </Collapse>
      <SideButton onClick={() => toggleCollapse()} isOpen={collapse} />
    </div>
  );
};

export default WrapperChat;
