import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Button, Badge } from 'reactstrap';
import CreatePointTransaction from '@components/modal/CreatePointTransaction';
import MainPagination from '@components/pagination/MainPagination';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import userPermission from '@utils/userPermission';

const HistoryPointTransaction = (props) => {
  const { transactionList, loyaltyPoints, detailUser, id, loadUserDetail } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');

  const [toggleUploadModal, setToggleUploadModal] = useState(false);
  const [historyList, setHistoryList] = useState({ isFetching: true });
  const [defaultRows, setDefaultRows] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    loadHistoryPoints();
  }, [detailUser]);

  const toggleCreatePointTransaction = () => setToggleUploadModal(!toggleUploadModal);

  async function loadHistoryPoints(currentPage) {
    const response = await api.get(
      `/v2/intools/loyalty/details/list?customer_id=${id}&page=${currentPage || 1}&limit=${defaultRows}`
    );

    if (response.data) {
      const { data, pagination } = response.data;
      setHistoryList({
        data,
        pagination,
        isFetching: false,
      });
    } else {
      setHistoryList({
        data: [],
        pagination: {},
        isFetching: false,
      });
    }
  }

  const handleChangePage = (val) => {
    const page = val.selected + 1;
    loadHistoryPoints(page);
  };

  const isCreateChanged = (val) => {
    loadHistoryPoints();
    loadUserDetail();
    toggleCreatePointTransaction();
  };

  return (
    <React.Fragment>
      <CreatePointTransaction
        id={id}
        modal={toggleUploadModal}
        isCreateChanged={isCreateChanged}
        toggle={() => toggleCreatePointTransaction()}
      />
      <Card>
        <CardHeader className="bg-primary text-white mt-0">
          <Row>
            <Col sm={12} md={7}>
              Riwayat Transaksi Otopoints
            </Col>
            <Col sm={12} md={5}>
              Saldo OtoPoints : {Helper.formatMoney(loyaltyPoints)}
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-0">
          {historyList.isFetching ? (
            <div className="points-card-body">
              <Container className="h-100 d-flex justify-content-center align-items-center">
                <h6 className="text-center">Loading...</h6>
              </Container>
            </div>
          ) : (
            <div className="points-card-body">
              {historyList.data.length > 0 ? (
                historyList?.data.map((item) => (
                  <Container key={Math.random()} className="list-points-wrapper my-4">
                    <Row className="px-2">
                      <Col xs={{ order: 2 }} md={{ size: 7, order: 1 }}>
                        <p className="date-time">
                          {item.date || '-'}, <span>pukul</span> {item.time || '-'}
                        </p>
                        <div className="description-wrapper">
                          <p className="label mb-0">Deskripsi:</p>
                          <h4 className="description mt-1">“{item.description || '-'}”</h4>
                        </div>
                      </Col>
                      <Col xs={{ order: 1 }} md={{ size: 5, order: 2 }}>
                        <div className="text-left text-md-right">
                          <h2 className="mt-0">
                            <Badge className="p-3 badge-status">{item.transaction_type || '-'}</Badge>
                          </h2>
                          <h4 className="my-3 point">
                            {!Helper.formatMoney(item.points_awarded)?.includes('-') && item.points_awarded !== 0
                              ? '+' + Helper.formatMoney(item.points_awarded)
                              : Helper.formatMoney(item.points_awarded)}{' '}
                            Otopoints
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                ))
              ) : (
                <Container className="h-100 d-flex justify-content-center align-items-center">
                  <h6 className="text-center">User Ini Belum Memiliki Riwayat Transaksi Otopoints</h6>
                </Container>
              )}
            </div>
          )}
        </CardBody>
        <CardFooter className="top-shadow">
          <div className="my-2">
            <MainPagination totalPage={historyList?.pagination?.total_page} handleChangePage={handleChangePage} />
            {generatePermission('customer', 'otopoints') && (
              <Button className="float-right" color="primary" onClick={toggleCreatePointTransaction}>
                Transaksi OtoPoints
              </Button>
            )}
          </div>
        </CardFooter>
      </Card>
    </React.Fragment>
  );
};

export default HistoryPointTransaction;
