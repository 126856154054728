import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import Skeleton from '@components/loading/Skeleton';
import map from 'lodash/map';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import CheckIcon from '@assets/icons/check.svg';
import AlertIcon from '@assets/icons/red-alert.svg';
import { isEmpty, over } from 'lodash';
import { Tooltip } from 'react-tooltip';
import BookingCreateDetailFleet from '@components/booking/BookingCreateDetailFleet';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { setIsDisableCreateBookingButton } from '@store/booking';
import { addCalculatePrice, getCalculatePrice } from '../../store/booking';

const BookingSummary = (props) => {
  const api = new API('v2');
  const {
    datas,
    source,
    setTotalAmount,
    setAmount,
    setPPN,
    setPPH,
    setDPP,
    setRounding,
    setGrandTotal,
    setUsedOtopoints,
    loadingCalculate,
    fleetProps,
    taxCalculation,
    isMultipleWorkshop,
    handleAddMultipleCalculateCartData,
    selectedWorkshops = [],
    setCalculatePricePayload,
    calculatePricePayload,
    b2bDraftDataPayload,
    overrideList,
  } = props;
  const { isFleetMode, detailFleet } = fleetProps;
  const dispatch = useDispatch();

  const [data, setData] = useState();
  const [users, setUsers] = useState('');
  const [loading, setLoading] = useState(false);

  const cars = datas?.cars;
  const spk = data?.spk;
  const workshops = data?.workshops;
  const is_b2b = data?.is_b2b;
  const otopoints = data?.otopoints;
  const promos = data?.discount;

  const packages = data?.items?.packages;
  const products = data?.items?.products;
  const services = data?.items?.services;
  const dpp = data?.items?.dpp;
  const ppn = data?.items?.ppn;
  const pph = data?.items?.pph;
  const amount = data?.items?.amount;

  const downloadFile = (value) => {
    const link = document.createElement('a');

    if (isFleetMode || source == 'revise') {
      link.setAttribute('href', value.url);
      link.setAttribute('download', value.filename);
      link.click();
    } else {
      const objurl = URL.createObjectURL(value);
      link.setAttribute('href', objurl);
      link.setAttribute('download', value?.name);
      link.click();
    }
  };

  const mappingPayloadToCalculatePrice = (items, booking_type) => {
    let mappedItems = [];
    const paket = items?.packages;
    const produk = items?.products;
    const jasa = items?.services;

    map(paket, (item) => {
      map(item?.details, (subitem) => {
        mappedItems.push({
          package_id: item?.id ?? null,
          package_details_id: subitem?.package_details_id ?? null,
          name: subitem?.name,
          item_type: subitem?.type,
          price: subitem?.unit_price,
          total_price: subitem?.price,
          discount_amount: booking_type === 'b2b' ? 0 : subitem?.discounted_price,
          qty: subitem?.type === 'work' ? 1 : subitem?.qty,
          markup_percentage: booking_type === 'b2b' ? 0 : subitem?.markup,
          discount_percentage: booking_type === 'b2b' ? 0 : subitem?.discount,
          promo_code: item?.promoData?.voucher_code ?? '',
          note: subitem?.note ?? '',
        });
      });
    });

    map(produk, (item) => {
      mappedItems.push({
        product_id: item?.id,
        name: item?.name ?? '',
        item_type: 'part',
        price: item?.unit_price,
        total_price: item?.price,
        discount_amount: item?.discounted_price,
        qty: item?.qty,
        markup_percentage: item?.markup,
        discount_percentage: item?.discount,
        promo_code: item?.promoData?.voucher_code ?? '',
      });
    });

    map(jasa, (item) => {
      mappedItems.push({
        product_id: item?.id,
        name: item?.name ?? '',
        item_type: 'work',
        price: item?.unit_price,
        total_price: item?.price,
        discount_amount: item?.discounted_price,
        qty: item?.qty,
        markup_percentage: item?.markup,
        discount_percentage: item?.discount,
        promo_code: item?.promoData?.voucher_code ?? '',
      });
    });

    return mappedItems;
  };
  const mappingPayloadToCalculatePriceSelectedWorkshop = (items, booking_type) => {
    let workshops = {};
    const paket = items?.packages;
    const produk = items?.productsSelectedWorkshop;
    const jasa = items?.servicesSelectedWorkshop;

    const addItemToWorkshop = (workshop, item) => {
      if (!workshops[workshop.id]) {
        workshops[workshop.id] = {
          id: workshop.id,
          name: workshop.name,
          slug: workshop.slug,
          items: [],
        };
      }
      workshops[workshop.id].items.push(item);
    };

    paket?.forEach((item) => {
      const workshop = item?.workshop;
      item?.details.forEach((subitem) => {
        const mappedItem = {
          package_id: item?.id ?? null,
          package_details_id: subitem?.package_details_id ?? null,
          name: subitem?.name,
          item_type: subitem?.type,
          price: subitem?.unit_price,
          total_price: subitem?.price,
          discount_amount: booking_type === 'b2b' ? 0 : subitem?.discounted_price,
          qty: subitem?.type === 'work' ? 1 : subitem?.qty,
          markup_percentage: booking_type === 'b2b' ? 0 : subitem?.markup,
          discount_percentage: booking_type === 'b2b' ? 0 : subitem?.discount,
          promo_code: item?.promoData?.voucher_code ?? '',
          note: subitem?.note ?? '',
        };
        addItemToWorkshop(workshop, mappedItem);
      });
    });

    produk?.forEach((item) => {
      const workshop = item?.workshop;
      const overrideObj = overrideList?.find(
        (ovrd) => ovrd?.workshop_id === workshop?.id && ovrd?.product_id === item?.id
      );

      let price = item?.unit_price ?? 0;
      let nett_price = item?.nett_price ?? 0;
      let isOverrideNett = item?.is_override_nett ?? false;
      let isOverrideSell = item?.is_override_sell ?? false;
      let isSplitedTax = item?.is_splited_tax ?? false;
      let nettPriceAfterTax = item?.nett_price_after_tax ?? 0

      if (overrideObj !== undefined && overrideObj !== null && overrideObj.length !== 0) {
        if (overrideObj?.isOverrideSell !== undefined && typeof item?.id === 'number') {
          price = overrideObj?.price ?? 0;
          isOverrideSell = overrideObj?.isOverrideSell;
        } else if(typeof item?.id === 'string') {
          price = overrideObj?.price ?? 0;
          isOverrideSell = false;
        } else {
          price = 0;
          isOverrideSell = false;
        }

        if (overrideObj?.isOverrideNett !== undefined) {
          nett_price = overrideObj?.nettPrice ?? 0;
          isOverrideNett = overrideObj?.isOverrideNett;
          isSplitedTax = overrideObj?.isSplitedTax ?? false;
          nettPriceAfterTax = overrideObj?.nettPriceAfterTax ?? 0
        } else if(typeof item?.id === 'string') {
          nett_price = overrideObj?.nettPrice ?? 0;
          isOverrideNett = false;
          isSplitedTax = overrideObj?.isSplitedTax ?? false;
          nettPriceAfterTax = overrideObj?.nettPriceAfterTax ?? 0
        } else {
          nett_price = 0;
          isOverrideNett = false;
          isSplitedTax = overrideObj?.isSplitedTax ?? false;
          nettPriceAfterTax = overrideObj?.nettPriceAfterTax ?? 0
        }
      } else if (isOverrideSell || isOverrideNett) {
        isOverrideNett = false;
        isOverrideSell = false;
      }

      const mappedItem = {
        product_id: item?.id ?? 'mock_item',
        name: item?.name ?? '',
        item_type: 'part',
        price: price,
        total_price: item?.price ?? 0,
        discount_amount: item?.discounted_price ?? 0,
        qty: item?.qty ?? 0,
        markup_percentage: item?.markup ?? 0,
        discount_percentage: item?.discount ?? 0,
        promo_code: item?.promoData?.voucher_code ?? '',
        nett_price: nett_price,
        is_override_nett: isOverrideNett,
        is_override_sell: isOverrideSell,
        is_splited_tax: isSplitedTax,
        note: item?.remarks ?? '',
        workshop_price_after_tax: nettPriceAfterTax
      };

      addItemToWorkshop(workshop, mappedItem);
    });

    jasa?.forEach((item) => {
      const workshop = item?.workshop;
      const overrideObj = overrideList?.find(
        (ovrd) => ovrd?.workshop_id === workshop?.id && ovrd?.product_id === item?.id
      );

      let price = item?.unit_price ?? 0;
      let nett_price = item?.nett_price ?? 0;
      let isOverrideNett = item?.is_override_nett ?? false;
      let isOverrideSell = item?.is_override_sell ?? false;
      let isSplitedTax = item?.is_splited_tax ?? false;
      let nettPriceAfterTax = item?.nett_price_after_tax ?? 0

      if (overrideObj !== undefined && overrideObj !== null && overrideObj.length !== 0) {
        if (overrideObj?.isOverrideSell !== undefined && overrideObj?.isOverrideSell && typeof item?.id === 'number') {
          price = overrideObj?.price ?? 0;
          isOverrideSell = overrideObj?.isOverrideSell;
        } else if(typeof item?.id === 'string') {
          price = overrideObj?.price ?? 0;
          isOverrideSell = false;
        } else {
          price = 0;
          isOverrideSell = false;
        }

        if (overrideObj?.isOverrideNett !== undefined && typeof item?.id === 'number') {
          nett_price = overrideObj?.nettPrice ?? 0;
          isOverrideNett = overrideObj?.isOverrideNett;
          isSplitedTax = overrideObj?.isSplitedTax ?? false;
          nettPriceAfterTax = overrideObj?.nettPriceAfterTax ?? 0
        } else if(typeof item?.id === 'string') {
          nett_price = overrideObj?.nettPrice ?? 0;
          isOverrideNett = false;
          isSplitedTax = overrideObj?.isSplitedTax ?? false;
          nettPriceAfterTax = overrideObj?.nettPriceAfterTax ?? 0
        } else {
          nett_price = 0;
          isOverrideNett = false;
          isSplitedTax = overrideObj?.isSplitedTax ?? false;
          nettPriceAfterTax = overrideObj?.nettPriceAfterTax ?? 0
        }
      } else if (isOverrideSell || isOverrideNett) {
        isOverrideNett = false;
        isOverrideSell = false;
      }

      const mappedItem = {
        product_id: item?.id ?? 'mock_item',
        name: item?.name ?? '',
        item_type: 'work',
        price: price,
        total_price: item?.price ?? 0,
        discount_amount: item?.discounted_price ?? 0,
        qty: item?.qty ?? 0,
        markup_percentage: item?.markup ?? 0,
        discount_percentage: item?.discount ?? 0,
        promo_code: item?.promoData?.voucher_code ?? '',
        nett_price: nett_price,
        is_override_nett: isOverrideNett,
        is_override_sell: isOverrideSell,
        is_splited_tax: isSplitedTax,
        note: item?.remarks ?? '',
        workshop_price_after_tax: nettPriceAfterTax
      };

      addItemToWorkshop(workshop, mappedItem);
    });

    // Convert the workshops object to an array
    return Object.values(workshops);
  };

  const mappingUpdatedCalculations = (datas, dt) => {
    let discountedItems = 0;
    let processedItems = new Map();
    map(datas?.items, (item) => {
      const uniqueId = `${item?.package_id || item?.product_id}-${item?.name}-${item?.price}-${item?.total_price}`;

      if (!processedItems.has(uniqueId)) {
        if (item?.package_id) {
          const packageGroups = dt?.items?.packages;
          const findIdx = packageGroups.findIndex((x) => x.id === item?.package_id);
          const groupToUpdate = packageGroups[findIdx];

          map(groupToUpdate?.details, (groupItem, index) => {
            if (
              (dt?.is_b2b && groupItem?.name === item?.name && groupItem?.unit_price === item?.price) ||
              groupItem?.name === item?.name
            ) {
              groupItem.updated_price = item?.total_price;
              groupItem.updated_unit_price = item?.price;
              groupItem.updated_discount = item?.discount_amount;
              groupItem.updated_voucher_discount = item?.voucher_amount;
              groupItem.total_voucher_amount = item?.total_voucher_amount;
              groupItem.promo_code = item?.promo_code;
              groupItem.voucher_percentage = item?.voucher_percentage;
              discountedItems += item?.discount_amount;

              if (index === 0) {
                groupToUpdate.total_price = item?.total_price;
                groupToUpdate.subtotal = item?.final_sell_price;
                groupToUpdate.subtotal_b2c = item?.total_price - (item?.voucher_amount ?? 0);
              } else {
                groupToUpdate.total_price += item?.total_price;
                groupToUpdate.subtotal += item?.final_sell_price;
                groupToUpdate.subtotal_b2c += item?.total_price - (item?.voucher_amount ?? 0);
              }
            }
          });
        }

        if (item?.product_id) {
          const itemType = item?.item_type?.toLowerCase() === 'part' ? 'products' : 'services';
          const itemGroups = dt?.items?.[itemType];

          map(itemGroups, (groupItem, index) => {
            if (groupItem?.name === item?.name && groupItem?.id === item?.product_id) {
              groupItem.updated_price = item?.total_price;
              groupItem.updated_unit_price = item?.price;
              groupItem.updated_discount = item?.discount_amount;
              groupItem.updated_voucher_discount = item?.voucher_amount;
              groupItem.total_voucher_amount = item?.total_voucher_amount;
              groupItem.promo_code = item?.promo_code;
              groupItem.voucher_percentage = item?.voucher_percentage;
              discountedItems += item?.discount_amount;

              if (index === 0) {
                dt.items[`subtotal_${itemType.slice(0, -1)}`] = item?.final_sell_price;
              } else {
                dt.items[`subtotal_${itemType.slice(0, -1)}`] += item?.final_sell_price;
              }
            }
          });
        }

        processedItems.set(uniqueId, true);
      }
    });

    dt.items.dpp = datas?.price_before_tax ?? 0;
    dt.items.ppn = datas?.tax ?? 0;
    dt.items.pph = datas?.service_tax ?? 0;
    dt.items.amount = datas?.total_amount ?? 0;
    dt.items.rounding = datas?.rounding ?? 0;
    dt.items.grand_total = datas?.grand_total ?? 0;
    dt.items.default_amount = datas?.price_after_tax ?? 0;
    dt.otopoints.used = datas?.otopoints ?? 0;
    dt.discount.amount = isNaN(datas?.discount) ? 0 : datas?.discount + (isNaN(discountedItems) || 0);

    var is_b2b = is_b2b ? is_b2b : dt?.is_b2b;
    if (typeof setTotalAmount === 'function')
      setTotalAmount(is_b2b ? datas?.price_before_tax : datas?.price_after_tax ?? 0);
    setAmount(datas?.total_amount ?? 0);
    setPPN(datas?.tax ?? 0);
    setPPH(datas?.service_tax ?? 0);
    setDPP(datas?.price_before_tax ?? 0);
    setRounding(datas?.rounding ?? 0);
    setGrandTotal(datas?.grand_total ?? 0);

    const availableOtopoints = dt?.otopoints?.checked
      ? datas?.otopoints
      : Helper.calculateTotalOtopoints(datas?.total_amount, dt?.otopoints?.balance);
    if (typeof setUsedOtopoints === 'function') setUsedOtopoints(availableOtopoints);

    setData({ ...dt, datas });
  };

  const handleDisableButton = async (value) => {
    await dispatch(setIsDisableCreateBookingButton(value));
  };

  const fetchCalculations = async (dt) => {
    const payload = {
      booking_type: dt?.is_b2b ? 'b2b' : 'b2c',
      otopoints: dt?.otopoints?.used ?? 0,
      discount: dt?.discount?.amount ?? 0,
      items: mappingPayloadToCalculatePrice(dt?.items, dt?.is_b2b ? 'b2b' : 'b2c'),
    };

    handleDisableButton(true);
    setLoading(true);
    if (source === 'revise' || !dt?.is_b2b) {
      await api
        .post('/v2/intools/bookings/tax_calculation/', payload)
        .then((res) => {
          dispatch(addCalculatePrice(res?.data));
          const data = res?.data?.data;
          if (data?.items?.length > 0) {
            handleDisableButton(false);
          } else {
            handleDisableButton(true);
          }
          if (source !== 'revise') {
            taxCalculation(data?.items);
          }
          mappingUpdatedCalculations(data, dt);
          setLoading(false);
        })
        .catch((err) => {
          console.log('FAILED -> ', err);
          setLoading(false);
        });
    } else {
      (payload.workshops = mappingPayloadToCalculatePriceSelectedWorkshop(dt?.items, dt?.is_b2b ? 'b2b' : 'b2c')),
        dispatch(getCalculatePrice(payload))
          .then((res) => {
            const data = res?.payload?.data;
            if (data?.length > 0) {
              handleDisableButton(false);
            } else {
              handleDisableButton(true);
            }
            if (source !== 'revise') {
              if (selectedWorkshops.length == 1) {
                taxCalculation(data[0]?.customer);
              } else {
                taxCalculation(data?.items);
              }
            }
            handleAddMultipleCalculateCartData(data);
            mappingUpdatedCalculations(data[0]?.customer, dt);
            setCalculatePricePayload(payload);
            setLoading(false);
          })
          .catch((err) => {
            console.log('FAILED -> ', err);
            setLoading(false);
          });
    }
  };

  const getPriceAndQty = (item, markUpProduct, discProduct) => {
    let price = 0;
    let markedUpPrice = 0;
    let discount = 0;
    const valueQty = Number(item?.qty) ?? 0;
    const qty = valueQty <= 0 ? 0 : valueQty > 999 ? 999 : valueQty;
    let valuePrice = 0;

    const is_custom = source == 'revise' ? item?.service_type == 'custom' : item?.formType == 'custom';

    if (!is_custom) {
      valuePrice = source == 'revise' ? item?.sell_price?.formatMoney : item?.sellPrice?.value;
      price = item?.is_override ? (valuePrice > 999999999 ? 999999999 : valuePrice ?? 0) : valuePrice ?? 0;
      markedUpPrice = Helper.countUpRate(markUpProduct, price, qty, true);
      discount = Helper.countDiscount(discProduct, markedUpPrice, 1, true);
    } else if (is_custom) {
      valuePrice = source == 'revise' ? item?.customer_price?.formatMoney : item?.name?.price?.formatMoney;
      price = valuePrice > 999999999 ? 999999999 : valuePrice ?? 0;
      markedUpPrice = Helper.countUpRate(markUpProduct, price, qty, true);
      discount = Helper.countDiscount(discProduct, markedUpPrice, 1, true);
    }

    return {
      qty,
      price,
      discount,
      markedUpPrice,
    };
  };

  const generateId = (type, id) => {
    let prefix = `custom_${type}_`;
    return `${prefix}${id ?? 0}`;
  };

  const parsingCalcItems = (item, itemType) => {
    let product_type = '';
    let markup = 0;
    let discount = 0;
    var users = users ? users : datas?.users;

    if (itemType === 'package') {
      let packagePrice = 0;

      if (source === 'revise') {
        return {
          name: item?.package?.label,
          price: packagePrice,
          id: item?.product_id ?? item?.id,
          details: map(item?.components, (subitem) => {
            product_type = subitem?.product?.product_type;
            markup = Helper.readMarkUp(users?.product_markup ?? 0, users?.service_markup ?? 0, product_type);
            discount = Helper.readDiscount(users?.product_discount ?? 0, users?.service_discount ?? 0, product_type);
            const name = subitem?.product?.label ?? '';
            const qty = +subitem?.product?.qty ?? 1;
            const unit_price = subitem?.product?.price ?? 0;
            const price = Helper.countUpRate(markup, unit_price, qty);
            const subtotal = Helper.calculateSubTotal(markup, discount, unit_price, qty, true);
            const discounted_price = Helper.countDiscount(discount, price, 1);
            const note = source == 'revise' ? item?.remarks : subitem?.note;

            packagePrice = subtotal;

            return {
              name,
              qty,
              unit_price,
              price,
              subtotal,
              discounted_price,
              markup,
              discount,
              type: product_type,
              note: note,
            };
          }),
        };
      }

      return {
        workshop: {
          id: item?.name?.workshop_id,
          name: item?.name?.workshop_name,
          slug: item?.name?.workshop_slug,
        },
        name: item?.name?.name,
        price: packagePrice,
        id: item?.name?.id,
        details: map(item?.package_details, (subitem) => {
          product_type = subitem?.value?.product_type;
          markup = 0;
          if (!users?.b2b_customer) {
            markup = Helper.readMarkUp(users?.product_markup ?? 0, users?.service_markup ?? 0, product_type);
          }
          discount = Helper.readDiscount(users?.product_discount ?? 0, users?.service_discount ?? 0, product_type);
          const package_details_id = subitem?.value?.package_details_id ?? null;
          const name = subitem?.value?.name ?? '';
          const qty = subitem?.value?.qty ?? 1;
          const unit_price = subitem?.value?.price ?? 0;
          const price = Helper.countUpRate(markup, unit_price, qty);
          const subtotal = Helper.calculateSubTotal(markup, discount, unit_price, qty, true);
          const discounted_price = Helper.countDiscount(discount, price, 1);
          const note = source == 'revise' ? item?.remarks : subitem?.note;
          const nett_price = 0;

          packagePrice = subtotal;

          return {
            package_details_id,
            name,
            qty,
            unit_price,
            price,
            subtotal,
            discounted_price,
            markup,
            discount,
            type: product_type,
            note: note,
            nett_price,
          };
        }),
      };
    }

    if (itemType === 'product' || itemType === 'service') {
      if (source == 'revise') {
        product_type = item?.service_type !== 'custom' ? item?.service_type : item?.type;
        markup = Helper.readMarkUp(users?.product_markup ?? 0, users?.service_markup ?? 0, product_type);
        discount = Helper.readDiscount(users?.product_discount ?? 0, users?.service_discount ?? 0, product_type);
        const id = item?.id ?? generateId(product_type, item?.booking_product_id);
        const priceQty = getPriceAndQty(item, markup, discount);
        const unit_price = priceQty?.price ?? 0;
        const price = priceQty?.markedUpPrice ?? 0;
        const qty = priceQty?.qty;
        const discounted_price = priceQty?.discount;
        const name = item?.item?.label ?? item?.name ?? '';
        const subtotal = Helper.calculateSubTotal(markup, discount, price, qty, true);
        const markedup_subtotal = Helper.countUpRate(markup, price, qty, true);
        const is_custom = !!item?.is_custom;
        const remarks = item?.remarks ?? '';
        const nett_price = item?.nettPrice;

        return {
          id,
          price,
          qty,
          name,
          unit_price,
          discounted_price,
          subtotal,
          markup,
          discount,
          type: product_type,
          is_custom,
          remarks,
          nett_price,
        };
      }

      product_type = item?.name?.product_type ?? item?.type;
      markup = Helper.readMarkUp(users?.product_markup ?? 0, users?.service_markup ?? 0, product_type);
      discount = Helper.readDiscount(users?.product_discount ?? 0, users?.service_discount ?? 0, product_type);
      const id = item?.name?.product_id ?? item?.name?.id;
      const priceQty = getPriceAndQty(item, markup, discount);
      const unit_price = priceQty?.price ?? 0;
      const price = priceQty?.markedUpPrice ?? 0;
      const qty = priceQty?.qty;
      const discounted_price = priceQty?.discount;
      const name = item?.name?.label ?? item?.name?.display_name ?? '';
      const subtotal = Helper.calculateSubTotal(markup, discount, price, qty, true);
      const markedup_subtotal = Helper.countUpRate(markup, price, qty, true);
      const is_custom = !!item?.formType;
      const remarks = item?.remarks ?? '';
      const nett_price = item?.nettPrice;
      const is_override_nett = item?.isOverrideNett || false;
      const is_override_sell = item?.isOverrideSell || false;

      return {
        id,
        price,
        qty,
        name,
        unit_price,
        discounted_price,
        subtotal,
        markup,
        discount,
        type: product_type,
        is_custom,
        remarks,
        nett_price,
        is_override_nett,
        is_override_sell,
      };
    }
  };

  const mapItems = (items) => {
    const paket = items?.package;
    const produk = items?.product;
    const jasa = items?.service;

    const newPackageItem = map(paket, (item) => {
      const parsedItem = parsingCalcItems(item, 'package');
      return {
        workshop: parsedItem?.workshop,
        name: parsedItem?.name,
        price: parsedItem?.price,
        id: parsedItem?.id,
        details: parsedItem?.details,
        promoData: item?.promoData,
        note: parsedItem?.note,
      };
    });

    const newProductItem = map(produk, (item) => {
      if (selectedWorkshops.length > 1) {
        selectedWorkshops.map((workshop) => {
          const parsedItem = parsingCalcItems(item, 'product');
          return {
            ...parsedItem,
            promoData: item?.promoData,
            workshop: {
              id: workshop.id,
              name: workshop.name,
              slug: workshop.slug,
            },
          };
        });
      }

      const parsedItem = parsingCalcItems(item, 'product');
      return { ...parsedItem, promoData: item?.promoData };
    });

    const newProductItemSelectedWorkshop = selectedWorkshops
      .map((workshop) => {
        // Check if produk is empty and create mock data if necessary
        if (produk.length === 0) {
          return {
            items: [],
            promoData: null,
            workshop: {
              id: workshop.id,
              name: workshop.name,
              slug: workshop.slug,
            },
          };
        } else {
          // Process each item in produk if it's not empty
          return produk.map((item) => {
            const parsedItem = parsingCalcItems(item, 'product');
            return {
              ...parsedItem,
              promoData: item?.promoData,
              workshop: {
                id: workshop.id,
                name: workshop.name,
                slug: workshop.slug,
              },
            };
          });
        }
      })
      .flat();

    const newServiceItemSelectedWorkshop = selectedWorkshops
      .map((workshop) => {
        // Check if jasa is empty and create mock data if necessary
        if (jasa.length === 0) {
          return {
            items: [],
            promoData: null,
            workshop: {
              id: workshop.id,
              name: workshop.name,
              slug: workshop.slug,
            },
          };
        } else {
          // Process each item in jasa if it's not empty
          return jasa.map((item) => {
            const parsedItem = parsingCalcItems(item, 'product');
            return {
              ...parsedItem,
              promoData: item?.promoData,
              workshop: {
                id: workshop.id,
                name: workshop.name,
                slug: workshop.slug,
              },
            };
          });
        }
      })
      .flat();

    const newServiceItem = map(jasa, (item) => {
      if (selectedWorkshops.length > 1) {
        selectedWorkshops.map((workshop) => {
          const parsedItem = parsingCalcItems(item, 'product');
          return {
            ...parsedItem,
            promoData: item?.promoData,
            workshop: {
              id: workshop.id,
              name: workshop.name,
              slug: workshop.slug,
            },
          };
        });
      }

      const parsedItem = parsingCalcItems(item, 'service');
      return { ...parsedItem, promoData: item?.promoData };
    });

    return {
      products: newProductItem,
      services: newServiceItem,
      packages: newPackageItem,
      productsSelectedWorkshop: newProductItemSelectedWorkshop,
      servicesSelectedWorkshop: newServiceItemSelectedWorkshop,
    };
  };

  const dataMapping = (dt) => {
    const checked = dt?.otopoints?.useOtopoints ?? false;
    let remapedData = {
      is_b2b: dt?.is_b2b,
      booking_date_time: dt?.booking_date_time,
      items: {
        dpp: data?.items?.dpp ?? 0,
        ppn: data?.items?.ppn ?? 0,
        pph: data?.items?.pph ?? 0,
        amount: data?.items?.amount ?? 0,
        rounding: data?.item?.rounding ?? 0,
        grand_total: data?.item?.grand_total ?? 0,
        ...mapItems(dt?.items),
      },
      users: dt?.users,
      cars: dt?.cars,
      otopoints: {
        balance: dt?.otopoints?.userOtopoints ?? 0,
        point_get: 0,
        point_spent: 0,
        used: !!checked ? Helper.calculateTotalOtopoints(data?.items?.amount, data?.otopoints?.balance) : 0,
        estimation: Helper.calculateTotalOtopoints(data?.items?.amount, data?.otopoints?.balance) ?? 0,
        checked: dt?.otopoints?.useOtopoints ?? false,
      },
      discount: {
        promo: dt?.promo?.promo ?? '',
        amount: dt?.promo?.amount ?? 0,
      },
      spk: dt?.spk,
      workshops: dt?.workshops,
    };
    fetchCalculations(remapedData);
  };

  const handleDebouncedEffect = debounce(() => {
    dataMapping(datas);
  }, 1000);

  useEffect(() => {
    if (isMultipleWorkshop?.length > 1) {
      handleDebouncedEffect();
      return () => {
        handleDebouncedEffect.cancel();
      };
    }
  }, [isMultipleWorkshop]);

  useEffect(() => {
    handleDebouncedEffect();
    return () => {
      handleDebouncedEffect.cancel();
    };
  }, [
    datas?.items,
    datas?.promo?.promo,
    datas?.promo?.amount,
    datas?.otopoints?.useOtopoints,
    datas?.users,
    isMultipleWorkshop,
  ]);

  useEffect(() => {
    if (source === 'revise') {
      if (users !== undefined && users !== '') {
        handleDebouncedEffect();
        return () => {
          handleDebouncedEffect.cancel();
        };
      }
    }
  }, [users, source, datas]);

  useEffect(() => {
    setUsers(data?.users);
  }, [data]);

  const hasItems = packages?.length > 0 || products?.length > 0 || services?.length > 0;
  // useEffect(() => {
  //   if (hasItems) {
  //     handleDisableButton(false);
  //   } else {
  //     handleDisableButton(true);
  //   }
  // }, [hasItems]);

  return (
    <Card>
      <CardHeader className="text-white mt-0">Rincian</CardHeader>
      <CardBody>
        <FormGroup row className="mb-4">
          <Col lg={2} sm={2} className="pr-4">
            <Row>
              <Col lg={12} sm={12} className="text-sm-right">
                <span className="label">Waktu</span>
              </Col>
            </Row>
          </Col>
          <Col lg={10} sm={10}>
            <Row>
              <Col lg={6} sm={6} className="col-summary">
                <span>{data?.booking_date_time ?? '-'}</span>
              </Col>
            </Row>
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Col lg={2} sm={2} className="pr-4">
            <Row>
              <Col lg={12} sm={12} className="text-sm-right">
                <span>User</span>
              </Col>
            </Row>
          </Col>
          <Col lg={10} sm={10}>
            <Row>
              <Col lg={6} sm={6} className="col-summary">
                <span className="font-weight-bold">No. Telp</span>
                <span>{users?.phone_no ?? '-'}</span>
              </Col>
              {is_b2b ? (
                <Col lg={6} sm={6} className="col-summary">
                  <span className="font-weight-bold">Nama PIC</span>
                  <span>{source === 'revise' ? users?.email : users?.pic_name ?? '-'}</span>
                </Col>
              ) : (
                <Col lg={6} sm={6} className="col-summary">
                  <span className="font-weight-bold">Email</span>
                  <span>{users?.email ?? '-'}</span>
                </Col>
              )}
            </Row>
            <Row>
              <Col lg={6} sm={6} className="col-summary">
                <span className="font-weight-bold">{`Nama${is_b2b ? ' Perusahaan' : ''}`}</span>
                {source === 'revise' ? (
                  <span>{users?.name ? `${users?.name}` : '-'}</span>
                ) : (
                  <span>{users?.username ? `${users?.username}` : '-'}</span>
                )}
              </Col>
              <Col lg={6} sm={6} className="col-summary">
                <span className="font-weight-bold">Flag</span>
                <div>
                  {users?.flag_status == 'suspect abuser' && <span className="flag">{'Suspect Abuser'}</span>}
                  {users?.flag_status !== undefined && (
                    <img src={users?.flag_status == 'suspect abuser' ? AlertIcon : CheckIcon} className="icon-flag" />
                  )}
                  {!users?.flag_status && <span>{'-'}</span>}
                </div>
              </Col>
              {is_b2b && (
                <Col lg={6} sm={6} className="col-summary">
                  <span className="font-weight-bold">Status</span>
                  <span>{users?.status ?? '-'}</span>
                </Col>
              )}
              {is_b2b && (
                <Col lg={6} sm={6} className="col-summary">
                  <span className="font-weight-bold">Internal Notes</span>
                  <span>{users?.internal_notes ?? '-'}</span>
                </Col>
              )}
            </Row>
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Col lg={2} sm={2} className="pr-4">
            <Row>
              <Col lg={12} sm={12} className="text-sm-right">
                <span>Mobil</span>
              </Col>
            </Row>
          </Col>
          <Col lg={10} sm={10}>
            <Row>
              <Col lg={6} sm={6} className="col-summary">
                <span className="font-weight-bold">Mobil</span>
                <span>
                  {cars?.car_details?.car_model?.brand?.name && cars?.car_details?.car_model?.model_name
                    ? cars?.car_details?.car_model?.brand?.name + ' ' + cars?.car_details?.car_model?.model_name
                    : '-'}
                </span>
              </Col>
              <Col lg={6} sm={6} className="col-summary">
                <span className="font-weight-bold">Plat Nomor</span>
                <span>{cars?.license_plate ?? '-'}</span>
              </Col>
            </Row>
            <Row>
              <Col lg={6} sm={6} className="col-summary">
                <span className="font-weight-bold">Transmisi</span>
                <span>{Helper.transmissionConverter(cars?.transmission) ?? '-'}</span>
              </Col>
              <Col lg={6} sm={6} className="col-summary">
                <span className="font-weight-bold">Tahun</span>
                <span>{cars?.year ?? '-'}</span>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={6} sm={6} className="col-summary">
                <Row className="align-items-center" style={{ marginLeft: '1px' }}>
                  <span className="font-weight-bold">Membership</span>
                  {cars?.membership_info?.is_expired && (
                    <span className="badge-custom badge-custom-membership" style={{ marginLeft: '10px' }}>
                      {'Expired'}
                    </span>
                  )}
                </Row>
                <span>{`${cars?.membership_info?.membership || '-'} (${
                  cars?.membership_info?.membership_expired_at || '-'
                })`}</span>
              </Col>
              <Col lg={6} sm={6} className="col-summary">
                <span className="font-weight-bold">Bahan Bakar</span>
                <span>{cars?.car_details?.fuel ? Helper.getFuelTypeName(cars?.car_details?.fuel) : '-'}</span>
              </Col>
            </Row>
          </Col>
        </FormGroup>

        {is_b2b && (
          <FormGroup row className="mb-4">
            <Col lg={2} sm={2} className="pr-4">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right">
                  <span className="label">SPK</span>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={10} className="pr-4">
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span>{spk?.number ?? '-'}</span>
                  <span
                    className={spk['file']?.name ? 'download-spk' : ''}
                    onClick={() => {
                      if (spk['file']?.name) {
                        downloadFile(source === 'revise' ? spk['file'] : spk['file']);
                      }
                    }}
                  >
                    {spk?.number || '-'}
                  </span>
                </Col>
              </Row>
            </Col>
          </FormGroup>
        )}

        {/* {selectedWorkshops.length <= 1 && (
          <FormGroup row className="mb-4">
            <Col lg={2} sm={2} className="pr-4">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right">
                  <span className="label">Bengkel</span>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={10} className="pr-4">
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  {!isEmpty(workshops) ? (
                    <>
                      <span className="font-weight-bold">
                        {workshops?.name}
                        {workshops?.tier?.name && <>&nbsp;[{workshops?.tier?.name}]</>}
                      </span>
                      <span>{workshops?.location?.street_address}</span>
                    </>
                  ) : (
                    <span>-</span>
                  )}
                </Col>
              </Row>
            </Col>
          </FormGroup>
        )} */}
        {!isFleetMode && selectedWorkshops.length <= 1 ? (
          <>
            <FormGroup row className="mb-0">
              <Col lg={2} sm={2} className="pr-4">
                <Row>
                  <Col lg={12} sm={12} className="text-sm-right">
                    <span className="label">Service</span>
                  </Col>
                </Row>
              </Col>
              <Col lg={10} sm={10}>
                {!hasItems ? <span>-</span> : null}
                {packages?.length > 0 ? (
                  <FormGroup row>
                    <Col lg={12} sm={12}>
                      <Row>
                        <Col lg={!is_b2b ? 5 : 3} sm={12} className="col-summary">
                          <span className="detail-group">Paket</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Mark Up'}</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{is_b2b ? 'Disc' : 'Kode Promo'}</span>
                        </Col>
                        {is_b2b && (
                          <Col lg={2} sm={12} className="col-summary">
                            <span className="detail-group price">{'Voucher'}</span>
                          </Col>
                        )}
                        <Col lg={3} sm={12} className="col-summary">
                          {''}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} sm={12}>
                      {map(packages, (item, index) => {
                        return (
                          <>
                            <Row key={index}>
                              <Col lg={!is_b2b ? 5 : 3} sm={12} className="col-summary">
                                <span className="detail-group">{item?.name ?? '-'}</span>
                              </Col>
                              <Col lg={2} sm={12} className="col-summary">
                                {''}
                              </Col>
                              <Col lg={2} sm={12} className="col-summary">
                                {''}
                              </Col>
                              {is_b2b && (
                                <Col lg={2} sm={12} className="col-summary">
                                  <span
                                    id={`promo-code-${item?.details?.[0]?.promo_code}-${item?.details?.[0]?.package_id}`}
                                    className="detail-group-item-name discount-otopoints price text-truncate"
                                  >
                                    {item?.details?.[0]?.promo_code && `${item?.details?.[0]?.promo_code}`}
                                  </span>
                                  <Tooltip
                                    anchorId={`promo-code-${item?.details?.[0]?.promo_code}-${item?.details?.[0]?.package_id}`}
                                    place="top"
                                    content={item?.details?.[0]?.promo_code}
                                  />
                                  <span className="detail-group-item-name discount-otopoints price">
                                    {item?.details?.[0]?.voucher_percentage &&
                                      `${item?.details?.[0]?.voucher_percentage} %`}
                                  </span>
                                </Col>
                              )}
                              <Col lg={3} sm={12} className="col-summary">
                                {loading ? (
                                  <div className="d-flex justify-content-end">
                                    <Skeleton width={100} height={14} />
                                  </div>
                                ) : (
                                  <>
                                    <span className="price detail-group">
                                      {'IDR ' + Helper.formatMoney(item?.total_price ?? 0)}
                                    </span>
                                    <span className="price detail-group discount-otopoints">
                                      {'- IDR ' + Helper.formatMoney(item?.details?.[0]?.total_voucher_amount ?? 0)}
                                    </span>
                                  </>
                                )}
                              </Col>
                            </Row>
                            {map(item?.details, (subitem, index) => {
                              const isLastItem = index === item.details.length - 1;
                              return (
                                <Row key={index}>
                                  <Col lg={!is_b2b ? 5 : 3} sm={4} className="col-summary">
                                    <>
                                      <span className="detail-group-item-name">
                                        {!subitem?.name ? '-' : subitem?.name}
                                      </span>
                                      {loading ? (
                                        <Skeleton width={100} height={14} />
                                      ) : (
                                        <span className="detail-group-item-qty">{`${
                                          subitem?.type === 'work' ? 1 : subitem?.qty
                                        } x IDR  ${Helper.formatMoney(subitem?.updated_unit_price ?? 0)}`}</span>
                                      )}
                                      {is_b2b && subitem?.note && isLastItem ? (
                                        <>
                                          <span className="remarks-item mb-0">{'Keterangan :'}</span>
                                          <span className="remarks-item mt-0">
                                            {!subitem?.note ? '-' : subitem?.note}
                                          </span>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  </Col>
                                  <Col lg={2} sm={12} className="col-summary">
                                    <span className="detail-group-item-name grand-total price">
                                      {is_b2b ? `0%` : `${subitem?.markup ?? 0}%`}
                                    </span>
                                  </Col>
                                  <Col lg={2} sm={12} className="col-summary">
                                    {is_b2b ? (
                                      <span className="detail-group-item-name discount-otopoints price">
                                        {is_b2b ? `0%` : `${subitem?.discount ?? 0}%`}
                                      </span>
                                    ) : (
                                      <span className="discount-otopoints price promo">
                                        {subitem?.promo_code ?? '-'}
                                      </span>
                                    )}
                                  </Col>
                                  {is_b2b && <Col lg={2} sm={12} className="col-summary" />}
                                  <Col lg={3} sm={12} className="col-summary">
                                    {loading ? (
                                      <>
                                        <div className="d-flex justify-content-end">
                                          <Skeleton width={100} height={14} />
                                        </div>
                                        <div className="d-flex justify-content-end">
                                          <Skeleton width={100} height={14} />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <span className="price detail-group-item-name">
                                          {'IDR ' +
                                            Helper.formatMoney(subitem?.updated_price ?? 0, 0, ',', '.', true, true)}
                                        </span>
                                        {subitem?.updated_discount > 0 && (
                                          <span className="price text-danger">
                                            {'- IDR ' +
                                              Helper.formatMoney(
                                                subitem?.updated_discount ?? 0,
                                                0,
                                                ',',
                                                '.',
                                                true,
                                                true
                                              )}
                                          </span>
                                        )}
                                        {!is_b2b && (
                                          <span className="price text-danger">
                                            {'- IDR' +
                                              Helper.formatMoney(
                                                subitem?.updated_voucher_discount ?? 0,
                                                0,
                                                ',',
                                                '.',
                                                true,
                                                true
                                              )}
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              );
                            })}
                            {item?.remarks && (
                              <Row>
                                <Col>
                                  <span className="remarks-item mb-0">{'Keterangan :'}</span>
                                  <span className="remarks-item mt-0">
                                    {!item?.remarks ? '-' : item?.remarks.replace(/-/g, '‑')}
                                  </span>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col lg={!is_b2b ? 5 : 3} sm={12} className="col-summary">
                                <span className="detail-group">Subtotal Paket</span>
                              </Col>
                              <Col lg={2} sm={12} className="col-summary">
                                {''}
                              </Col>
                              <Col lg={2} sm={12} className="col-summary">
                                {''}
                              </Col>
                              {is_b2b && <Col lg={2} sm={12} className="col-summary" />}
                              <Col lg={3} sm={12} className="col-summary">
                                {loading ? (
                                  <div className="d-flex justify-content-end">
                                    <Skeleton width={100} height={14} />
                                  </div>
                                ) : (
                                  <span className="price detail-group">
                                    {'IDR ' +
                                      Helper.formatMoney(is_b2b ? item?.subtotal ?? 0 : item?.subtotal_b2c ?? 0)}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                    </Col>
                  </FormGroup>
                ) : null}

                {products?.length > 0 ? (
                  <FormGroup row>
                    <Col lg={12} sm={12}>
                      <Row>
                        <Col lg={!is_b2b ? 5 : 3} sm={12} className="col-summary">
                          <span className="detail-group">Produk</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Mark Up'}</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Disc'}</span>
                        </Col>
                        {is_b2b && (
                          <Col lg={2} sm={12} className="col-summary">
                            <span className="detail-group price">{'Voucher'}</span>
                          </Col>
                        )}
                        <Col lg={3} sm={12} className="col-summary">
                          {''}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} sm={12}>
                      {map(products, (item, index) => {
                        return (
                          <Row key={index}>
                            <Col lg={!is_b2b ? 5 : 3} sm={5} className="col-summary" width={'100px'}>
                              <>
                                <span className="detail-group-item-name">{!item?.name ? '-' : item?.name}</span>
                                {loading ? (
                                  <Skeleton width={100} height={14} />
                                ) : (
                                  <span className="detail-group-item-qty">{`${item?.qty} x IDR  ${Helper.formatMoney(
                                    item?.updated_unit_price ?? 0
                                  )}`}</span>
                                )}
                                {item?.remarks && (
                                  <>
                                    <span className="remarks-item mb-0">{'Keterangan :'}</span>
                                    <span className="remarks-item mt-0">
                                      {!item?.remarks ? '-' : item?.remarks.replace(/-/g, '‑')}
                                    </span>
                                  </>
                                )}
                              </>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name grand-total price">{`${
                                item?.markup ?? 0
                              }%`}</span>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name discount-otopoints price min-h-16" />
                              <span className="detail-group-item-name discount-otopoints price">
                                {`${item?.discount ?? 0}%`}
                              </span>
                            </Col>
                            {is_b2b && (
                              <Col lg={2} sm={12} className="col-summary">
                                <span className="detail-group-item-name discount-otopoints price min-h-16" />
                                <span className="detail-group-item-name discount-otopoints price min-h-16" />
                                <span
                                  id={`promo-code-product-${item?.promo_code}-${item?.product_id}`}
                                  className="detail-group-item-name discount-otopoints price text-truncate"
                                >
                                  {item?.promo_code && `${item?.promo_code}`}
                                </span>
                                <Tooltip
                                  anchorId={`promo-code-product-${item?.promo_code}-${item?.product_id}`}
                                  place="top"
                                  content={item?.promo_code}
                                />
                                <span className="detail-group-item-name discount-otopoints price">
                                  {item?.voucher_percentage && `${item?.voucher_percentage} %`}
                                </span>
                              </Col>
                            )}
                            <Col lg={3} sm={12} className="col-summary">
                              {loading ? (
                                <>
                                  <div className="d-flex justify-content-end">
                                    <Skeleton width={100} height={14} />
                                  </div>
                                  <div className="d-flex justify-content-end">
                                    <Skeleton width={100} height={14} />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <span className="price detail-group-item-name">
                                    {'IDR ' + Helper.formatMoney(item?.updated_price ?? 0, 0, ',', '.', true, true)}
                                  </span>
                                  {is_b2b && (
                                    <>
                                      <span className="price text-danger">
                                        {'- IDR ' +
                                          Helper.formatMoney(item?.updated_discount ?? 0, 0, ',', '.', true, true)}
                                      </span>

                                      <span className="price detail-group discount-otopoints">
                                        {'- IDR ' + Helper.formatMoney(item?.updated_voucher_discount ?? 0)}
                                      </span>
                                    </>
                                  )}
                                </>
                              )}
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                    <Col lg={12} sm={12}>
                      <Row>
                        <Col lg={6} sm={12} className="col-summary">
                          <span className="detail-group">Subtotal Produk</span>
                        </Col>
                        <Col lg={6} sm={12} className="col-summary text-right font-weight-bold">
                          {loading ? (
                            <Skeleton width={100} height={14} />
                          ) : (
                            `IDR ${Helper.formatMoney(data?.items?.subtotal_product ?? 0)}`
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                ) : null}

                {services?.length > 0 ? (
                  <FormGroup row>
                    <Col lg={12} sm={12}>
                      <Row>
                        <Col lg={!is_b2b ? 5 : 3} sm={12} className="col-summary">
                          <span className="detail-group">Jasa</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Mark Up'}</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group price">{'Disc'}</span>
                        </Col>
                        {is_b2b && (
                          <Col lg={2} sm={12} className="col-summary">
                            <span className="detail-group price">{'Voucher'}</span>
                          </Col>
                        )}
                        <Col lg={3} sm={12} className="col-summary">
                          {''}
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} sm={12}>
                      {map(services, (item, index) => {
                        return (
                          <Row key={index}>
                            <Col lg={!is_b2b ? 5 : 3} sm={5} className="col-summary">
                              <>
                                <span className="detail-group-item-name">{!item?.name ? '-' : item?.name}</span>
                                {loading ? (
                                  <Skeleton width={100} height={14} />
                                ) : (
                                  <span className="detail-group-item-qty">{`${item?.qty} x IDR  ${Helper.formatMoney(
                                    item?.updated_unit_price ?? 0
                                  )}`}</span>
                                )}
                                {item?.remarks && (
                                  <>
                                    <span className="remarks-item mb-0">{'Keterangan :'}</span>
                                    <span className="remarks-item mt-0">
                                      {!item?.remarks ? '-' : item?.remarks.replace(/-/g, '‑')}
                                    </span>
                                  </>
                                )}
                              </>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name grand-total price">{`${
                                item?.markup ?? 0
                              }%`}</span>
                            </Col>
                            <Col lg={2} sm={12} className="col-summary">
                              <span className="detail-group-item-name discount-otopoints price min-h-16" />
                              <span className="detail-group-item-name discount-otopoints price">
                                {`${item?.discount ?? 0}%`}
                              </span>
                            </Col>
                            {is_b2b && (
                              <Col lg={2} sm={12} className="col-summary">
                                <span className="detail-group-item-name discount-otopoints price min-h-16" />
                                <span className="detail-group-item-name discount-otopoints price min-h-16" />
                                <span
                                  id={`promo-code-service-${item?.promo_code}-${item?.product_id}`}
                                  className="detail-group-item-name discount-otopoints price text-truncate"
                                >
                                  {item?.promo_code && `${item?.promo_code}`}
                                </span>
                                <Tooltip
                                  anchorId={`promo-code-service-${item?.promo_code}-${item?.product_id}`}
                                  place="top"
                                  content={item?.promo_code}
                                />
                                <span className="detail-group-item-name discount-otopoints price">
                                  {item?.voucher_percentage && `${item?.voucher_percentage} %`}
                                </span>
                              </Col>
                            )}
                            <Col lg={3} sm={12} className="col-summary">
                              {loading ? (
                                <>
                                  <div className="d-flex justify-content-end">
                                    <Skeleton width={100} height={14} />
                                  </div>
                                  <div className="d-flex justify-content-end">
                                    <Skeleton width={100} height={14} />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <span className="price detail-group-item-name">
                                    {'IDR ' + Helper.formatMoney(item?.updated_price ?? 0, 0, ',', '.', true, true)}
                                  </span>
                                  {is_b2b && (
                                    <>
                                      <span className="price text-danger">
                                        {'- IDR ' +
                                          Helper.formatMoney(item?.updated_discount ?? 0, 0, ',', '.', true, true)}
                                      </span>

                                      <span className="price detail-group discount-otopoints">
                                        {'- IDR ' + Helper.formatMoney(item?.updated_voucher_discount ?? 0)}
                                      </span>
                                    </>
                                  )}
                                </>
                              )}
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                    <Col lg={12} sm={12}>
                      <Row>
                        <Col lg={6} sm={12} className="col-summary">
                          <span className="detail-group">Subtotal Jasa</span>
                        </Col>
                        <Col lg={6} sm={12} className="col-summary text-right font-weight-bold">
                          {loading ? (
                            <Skeleton width={100} height={14} />
                          ) : (
                            `IDR ${Helper.formatMoney(data?.items?.subtotal_service ?? 0)}`
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                ) : null}
              </Col>
              <Col lg={12} sm={12} className="col-summary">
                <div className="divider"></div>
              </Col>
            </FormGroup>

            <FormGroup row className="mb-0">
              <Col lg={2} sm={2} className="pr-4">
                <Row>
                  <Col lg={12} sm={12} className="text-sm-right">
                    <dt>{is_b2b ? 'Total Diskon' : 'Promo Whole Cart'}</dt>
                  </Col>
                </Row>
              </Col>
              <Col lg={10} sm={10}>
                <Row>
                  <Col lg={6} sm={6} className="col-summary">
                    {loading && promos?.promo ? (
                      <Skeleton width={100} height={14} />
                    ) : (
                      <span className="discount-otopoints text-bold">{promos?.promo ?? ''}</span>
                    )}
                  </Col>
                  <Col lg={6} sm={6} className="col-summary">
                    {loading ? (
                      <div className="d-flex justify-content-end">
                        <Skeleton width={100} height={14} />
                      </div>
                    ) : (
                      <span className="price discount-otopoints">
                        {`- IDR ${Helper.formatMoney(Math.floor(promos?.amount))}`}
                      </span>
                    )}
                  </Col>
                </Row>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col lg={2} sm={2} className="pr-4">
                {!is_b2b && (
                  <Row>
                    <Col lg={12} sm={12} className="text-sm-right">
                      <dt>Otopoints</dt>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col lg={10} sm={10}>
                <Row>
                  <Col lg={6} sm={6} className="col-summary">
                    <span>{''}</span>
                  </Col>
                  {!is_b2b && (
                    <Col lg={6} sm={6} className="col-summary">
                      {loading ? (
                        <div className="d-flex justify-content-end">
                          <Skeleton width={100} height={14} />
                        </div>
                      ) : (
                        <span className="price discount-otopoints">
                          {'- IDR ' + Helper.formatMoney(otopoints?.used ?? 0, 0, ',', '.', true)}
                        </span>
                      )}
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <div className="divider"></div>
                  </Col>
                </Row>
              </Col>

              {!is_b2b && (
                <>
                  <Col lg={2} sm={2} className="pr-4">
                    <Row>
                      <Col lg={12} sm={12} className="col-summary text-right">
                        <div>Total</div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={10} sm={10}>
                    <Row>
                      <Col lg={6} sm={6} className="col-summary">
                        <span>{''}</span>
                      </Col>
                      <Col lg={6} sm={6} className="col-summary text-right">
                        {loading ? (
                          <div className="d-flex justify-content-end">
                            <Skeleton width={100} height={14} />
                          </div>
                        ) : (
                          <span className="">{'IDR ' + Helper.formatMoney(amount ?? 0, 0, ',', '.', true)}</span>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={12} sm={12}>
                    <Row>
                      <Col lg={12} sm={12} className="col-summary">
                        <div className="divider"></div>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}

              <Col lg={is_b2b ? '2' : '4'} sm={12} className="pr-3">
                <Row>
                  <Col
                    lg={12}
                    sm={12}
                    className={
                      is_b2b ? 'text-sm-right col-summary' : 'text-sm-left col-summary text-muted font-weight-bold'
                    }
                  >
                    <span>{is_b2b ? 'DPP' : 'Final DPP'}</span>
                  </Col>
                </Row>
              </Col>
              <Col lg={is_b2b ? '10' : '8'} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className={is_b2b ? 'col-summary' : 'col-summary text-muted font-weight-bold'}>
                    {loading ? (
                      <div className="d-flex justify-content-end">
                        <Skeleton width={100} height={14} />
                      </div>
                    ) : (
                      <span className="price">{'IDR ' + Helper.formatMoney(dpp ?? 0)}</span>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col lg={is_b2b ? '2' : '4'} sm={12} className="pr-3">
                <Row>
                  <Col
                    lg={12}
                    sm={12}
                    className={
                      is_b2b ? 'text-sm-right col-summary' : 'text-sm-left col-summary text-muted font-weight-bold'
                    }
                  >
                    <span>{is_b2b ? 'PPN 11%' : 'Final PPN'}</span>
                  </Col>
                </Row>
              </Col>
              <Col lg={is_b2b ? '10' : '8'} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className={is_b2b ? 'col-summary' : 'col-summary text-muted font-weight-bold'}>
                    {loading ? (
                      <div className="d-flex justify-content-end">
                        <Skeleton width={100} height={14} />
                      </div>
                    ) : (
                      <span className="price">{'IDR ' + Helper.formatMoney(ppn ?? 0)}</span>
                    )}
                  </Col>
                </Row>
              </Col>

              {is_b2b ? (
                <>
                  <Col lg={2} sm={12} className="pr-3">
                    <Row>
                      <Col
                        lg={12}
                        sm={12}
                        className={
                          is_b2b ? 'text-sm-right col-summary' : 'text-sm-left col-summary text-muted font-weight-bold'
                        }
                      >
                        <span>PPH 2%</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={10} sm={12}>
                    <Row>
                      <Col lg={12} sm={12} className="col-summary">
                        {loading ? (
                          <div className="d-flex justify-content-end">
                            <Skeleton width={100} height={14} />
                          </div>
                        ) : (
                          <span className="price discount-otopoints">{'- IDR ' + Helper.formatMoney(pph ?? 0)}</span>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </>
              ) : null}

              {is_b2b && (
                <>
                  <Col lg={2} sm={12} className="pr-3">
                    <Row>
                      <Col lg={12} sm={12} className="text-sm-right col-summary">
                        <span>Amount</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={10} sm={12}>
                    <Row>
                      <Col lg={12} sm={12} className="col-summary">
                        {loading ? (
                          <div className="d-flex justify-content-end">
                            <Skeleton width={100} height={14} />
                          </div>
                        ) : (
                          <span className="price grand-total">{'IDR ' + Helper.formatMoney(amount ?? 0)}</span>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </FormGroup>
          </>
        ) : null}

        {isFleetMode ? (
          <BookingCreateDetailFleet
            summaryBooking={data}
            detailFleet={detailFleet}
            hasProduct={hasItems}
            is_b2b={is_b2b}
            otopoints={otopoints}
            dataPromo={promos}
            loadingCalculate={loadingCalculate}
            selectedWorkshops={selectedWorkshops}
          ></BookingCreateDetailFleet>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default BookingSummary;
