import React, { useState } from 'react';
import ChevronLeftIcon from '@assets/icons/chevron-left-disable.svg';
import ChevronRightIcon from '@assets/icons/chevron-right-disable.svg';
import { Button, Tooltip } from 'reactstrap';

const SideButton = (props) => {
  const { onClick, isOpen } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  return (
    <>
      <Button
        color="primary"
        id="Tooltip-Aida"
        className={!isOpen ? `m-auto px-4 side-button-aida` : `m-auto px-4 side-button-aida-open`}
        size="lg"
        style={{ transition: '0.5s' }}
        onClick={() => onClick()}
      >
        <img
          src={!isOpen ? ChevronLeftIcon : ChevronRightIcon}
          className="mr-2"
          width={25}
          style={{ transition: '0.5s' }}
        />
      </Button>
      <Tooltip placement="top" isOpen={tooltipOpen} target="Tooltip-Aida" toggle={() => toggleTooltip()}>
        Chat Otopilot
      </Tooltip>
    </>
  );
};

export default SideButton;
