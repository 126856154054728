import React from 'react';
import { Row, Col, Card, CardBody, CardHeader, FormGroup, Label } from 'reactstrap';

function BookingB2BDraftData(props) {
  const { b2bDraftData, title } = props;

  const packages = b2bDraftData?.cart?.package ?? [];
  const products = b2bDraftData?.cart?.product ?? [];
  const services = b2bDraftData?.cart?.service ?? [];
  const workshops = b2bDraftData?.workshops ?? [];

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-primary text-white mt-0">{title}</CardHeader>
        <CardBody className="pb-0">
          <FormGroup row className="mb-3">
            {workshops.length > 0 && (
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group">Bengkel</span>
                    {workshops?.map((item, index) => (
                      <span className="detail-group-item-name" key={index}>
                        {item?.name}
                      </span>
                    ))}
                  </Col>
                </Row>
              </Col>
            )}
            {packages.length > 0 && (
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group-service">Paket</span>
                    {packages?.map((item, index) => (
                      <span className="detail-group-item-name" key={index}>
                        {item?.name?.name}
                        {item?.package_details?.map((pd, i) => {
                          return (
                            <span key={i}>
                              <br />- {pd?.name}
                            </span>
                          );
                        })}
                      </span>
                    ))}
                  </Col>
                </Row>
              </Col>
            )}
            {products.length > 0 && (
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group-service">Produk</span>
                    {products?.map((item, index) => (
                      <span className="detail-group-item-name" key={index}>
                        {item?.formType == 'custom' ? item?.name?.label : item?.name?.display_name}
                      </span>
                    ))}
                  </Col>
                </Row>
              </Col>
            )}
            {services.length > 0 && (
              <Col className="mt-2" lg={12} sm={12}>
                <Row>
                  <Col lg={12} sm={12} className="col-summary">
                    <span className="detail-group-service">Jasa</span>
                    {services?.map((item, index) => (
                      <span className="detail-group-item-name" key={index}>
                        {item?.formType == 'custom' ? item?.name?.label : item?.name?.display_name}
                      </span>
                    ))}
                  </Col>
                </Row>
              </Col>
            )}
          </FormGroup>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default BookingB2BDraftData;
