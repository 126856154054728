import React from 'react';
import { useFieldArray, useWatch, Controller } from 'react-hook-form';
import { Button, Col, Row, ListGroup, ListGroupItem } from 'reactstrap';
import ChevronUp from '@assets/icons/chevron-up.svg';
import ChevronDown from '@assets/icons/chevron-down.svg';
import CircleClose from '@assets/icons/circle-close.svg';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import EditableInputField from '@components/field/EditableInputField';
import ActionSwitch from '@components/field/ActionSwitch';
import DetailInformationProducts from './DetailInformationProducts';

const DetailInformationComponents = (props) => {
  const {
    packageProductOptions,
    allowEdit,
    disableForm,
    control,
    register,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'components',
  });

  const watchForm = (name) => {
    return watch(name, false);
  };

  const addComponent = () => {
    append({
      id_component: null,
      component_name: '',
      prompt_text: '',
      is_active: true, //need improvement BE data
      is_show: true,
      is_deleted: false,
      products: [],
    });
  };

  const showHideComponent = (index) => {
    const is_show = getValues(`components[${index}].is_show`);
    setValue(`components[${index}].is_show`, !is_show);
  };

  const deleteComponent = (index) => {
    // setValue(`components[${index}].is_deleted`, true);
    // remove(index);
  };

  return (
    <React.Fragment>
      {fields.map(({ id }, index) => {
        return (
          <ListGroup key={id}>
            {!watchForm(`components[${index}].is_deleted`) && (
              <ListGroupItem key={id} className="out-group-item">
                <div className="fly-title" onClick={() => showHideComponent(index)}>
                  Component: <span>{watchForm(`components[${index}].component_name`)}</span>
                  <img src={watchForm(`components[${index}].is_show`) ? ChevronUp : ChevronDown} alt="" />
                </div>
                <img src={CircleClose} className="fly-close" onClick={() => deleteComponent(index)} />

                {watchForm(`components[${index}].is_show`) && (
                  <div className="in-group-item">
                    <Controller
                      name={`components[${index}].component_name`}
                      control={control}
                      rules={{
                        required: 'Component Name harus diisi',
                      }}
                      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                        <EditableInputField
                          required
                          labelWidth={3}
                          colWidth={9}
                          title="Component Name"
                          disabled={disableForm}
                          invalid={error ? true : false}
                          errorMessage={error?.message ?? ''}
                          {...rest}
                        />
                      )}
                    />

                    <Controller
                      name={`components[${index}].prompt_text`}
                      control={control}
                      rules={{
                        required: 'Prompt Name harus diisi',
                      }}
                      render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                        return (
                          <EditableInputField
                            required
                            labelWidth={3}
                            colWidth={9}
                            title="Prompt"
                            disabled={disableForm}
                            invalid={error ? true : false}
                            errorMessage={error?.message ?? ''}
                            {...rest}
                          />
                        );
                      }}
                    />
                    <DetailInformationProducts
                      disableForm={disableForm}
                      componentIndex={index}
                      packageProductOptions={packageProductOptions}
                      {...{ control, register, getValues, setValue, setError, watch, formState: { errors } }}
                    />
                  </div>
                )}
              </ListGroupItem>
            )}
          </ListGroup>
        );
      })}
      <Row>
        <Col className="text-left">
          {!disableForm && (
            <Button color="primary" size="lg" className="btn-add-component-product" onClick={addComponent}>
              <img src={PlusFill} className="mr-2" />
              Add Component
            </Button>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DetailInformationComponents;
