import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Loading from '@components/loading/Loading';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import { Button } from 'reactstrap';

const CarHealth = (props) => {
  const { stnkID } = props;
  const history = useHistory();
  const api = new API('v2');
  const { generatePermission } = userPermission();
  const [totalRows, setTotalRows] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [allowDetail, setAllowDetail] = useState(false);
  const paginationOptions = { noRowsPerPage: true };
  const pageLimit = 10;
  const columns = useMemo(
    () => [
      {
        name: 'Tgl Inspeksi',
        selector: (row) =>
          originType(
            row?.origin,
            moment(row?.inspection_date).isValid() ? moment(row?.inspection_date).format('DD/MM/YYYY , HH:mm:ss') : '-'
          ),
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'Booking Code',
        selector: (row) => originType(row?.origin, row?.booking_code ?? '-'),
        wrap: true,
        minWidth: '100px',
      },
      {
        name: 'Input By',
        selector: (row) => originType(row?.origin, row?.submitted_by ?? '-'),
        minWidth: '200px',
        wrap: true,
      },
    ],
    []
  );

  const originType = (origin, value) => {
    if (origin == 'o2o') {
      return value;
    } else if (origin == 'workshop') {
      return <div className="list-inspection-origin-workshop">{value}</div>;
    } else {
      return value;
    }
  };

  async function loadCarHealth() {
    setLoading(true);

    const params = {
      page: page,
      limit: pageLimit,
    };

    try {
      const response = await api.get(`v2/intools/user-cars/${stnkID}/inspections/`, { params });
      setData(response?.data?.data?.inspections ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setLoading(false);
    } catch (error) {
      setData([]);
      setTotalRows(0);
      setLoading(false);
    }
  }

  const handlePageChange = (page) => {
    setPage(page);
  };

  const openHistoryCarHealth = (carHealth) => {
    if (allowDetail) {
      history.push(`/stnk/${stnkID}/car-health/inspections/detail/${carHealth?.id}`);
    }
  };

  const createCarHealth = () => {
    if (allowUpdate) {
      history.push(`/stnk/${stnkID}/car-health/inspections/`);
    }
  };

  const allowUpdateCarHealth = () => {
    if (
      generatePermission('customer', 'carinspectionsubmit') ||
      generatePermission('customer', 'carinspectionall') ||
      generatePermission('customer', 'carall')
    ) {
      setAllowUpdate(true);
    } else {
      setAllowUpdate(false);
    }
  };

  const allowDetailCarHealth = () => {
    if (
      generatePermission('customer', 'carinspectiondetail') ||
      generatePermission('customer', 'carinspectionall') ||
      generatePermission('customer', 'carall')
    ) {
      setAllowDetail(true);
    } else {
      setAllowDetail(false);
    }
  };

  useEffect(() => {
    if (page && stnkID) {
      loadCarHealth();
    }
  }, [page, stnkID]);

  useEffect(() => {
    allowUpdateCarHealth();
    allowDetailCarHealth();
  }, []);

  return (
    <div>
      {allowUpdate && (
        <Button className="btn-primary mb-3" onClick={createCarHealth}>
          {'Update'}
        </Button>
      )}
      <DataTable
        persistTableHead
        highlightOnHover
        pagination
        progressPending={loading}
        data={data}
        noHeader={true}
        fixedHeader
        fixedHeaderScrollHeight="239px"
        columns={
          generatePermission('customer', 'carinspectionlist') ||
          generatePermission('customer', 'carinspectionall') ||
          generatePermission('customer', 'carall')
            ? columns
            : []
        }
        className="table table-bordered dt-responsive table-stnk"
        paginationServer
        paginationTotalRows={totalRows}
        paginationComponentOptions={paginationOptions}
        onChangePage={handlePageChange}
        progressComponent={<Loading />}
        onRowClicked={(row) => openHistoryCarHealth(row)}
      />
    </div>
  );
};

export default CarHealth;
