import React, { useState } from 'react';
import get from 'lodash/get';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, CardTitle, CardSubtitle, Input, Table } from 'reactstrap';
import Helper from '@helpers/Helper';
import API from '@utils/API';

const changePriceBookingModal = (props) => {
  const { modal, toggle, priceData, workshopsData, arrayDetail, packageNames, totalPrice, onSuccess, onError } = props;

  const [totalNewPrice, setTotalNewPrice] = useState(0);
  const [newFinalPrice, setNewFinalPrice] = useState(0);
  const [newPrices, setNewPrices] = useState(arrayDetail);

  const forceUpdate = React.useReducer((bool) => !bool)[1];

  const handleNewPrice = () => {
    if (arrayDetail.length > 0) {
      let total = 0;
      arrayDetail.map((item) => {
        if (item.newPrice) {
          total += Number(item.newPrice);
        }
      });
      setTotalNewPrice(total);
      let newFinalPrice =
        Number(total) - Number(get(priceData, 'otopoints_used', 0)) - Number(get(priceData, 'promo_used', 0));
      setNewFinalPrice(newFinalPrice > 0 ? newFinalPrice : 0);
      setNewPrices(arrayDetail);
      return total;
    } else {
      setTotalNewPrice('');
      return '';
    }
  };

  const cancelEditPrice = () => {
    setTotalNewPrice(0);
    setNewFinalPrice(0);
    toggle();
  };

  const handleSubmitNewPrices = async () => {
    let changePrice = [];
    priceData?.packages[0].booking_details.map((item) => {
      let id = newPrices.findIndex((val) => val.data.name === item.name);
      if (id > -1) {
        if (item.level === 1 || item.level === 3) {
          changePrice.push({
            booking_details_id: Number(item.id),
            new_price: newPrices[id].newPrice === '' ? 0 : Number(newPrices[id].newPrice),
          });
        }
      }
    });
    const api = new API('v2');
    const payload = {
      change_price: changePrice,
    };
    const url = 'v2/intools/bookings/update-price/';
    try {
      await api.post(url, payload);
      onSuccess();
    } catch (error) {
      onError(error);
    }
  };

  return (
    <Modal className="modal-dialog-top" isOpen={modal}>
      <ModalHeader data>Booking Details</ModalHeader>
      <ModalBody>
        <Table className="border-none">
          <thead>
            <tr>
              <th>
                <CardTitle tag="h5" className="mb-3">
                  Detail
                </CardTitle>
              </th>
              <th>
                <CardTitle tag="h5" className="mb-3">
                  Old Price
                </CardTitle>
              </th>
              <th>
                <CardTitle tag="h5" className="mb-3">
                  New Price
                </CardTitle>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="3">
                <CardSubtitle tag="h6" className="mt-2">
                  {Helper.shortenByDash(packageNames[0])}
                </CardSubtitle>
              </td>
            </tr>
            {arrayDetail.map((item, index) => {
              return (
                <tr key={item.data.name} className="package-details">
                  <td>
                    <CardSubtitle tag="h6" className="text-muted mt-0" style={{ marginTop: 20 }}>
                      {item.data.name}
                    </CardSubtitle>
                  </td>
                  <td>
                    <CardSubtitle
                      key={item.data.name}
                      tag="h6"
                      className="text-muted text-nowrap mt-0"
                      style={{ marginTop: 20 }}
                    >
                      Rp {Helper.formatMoney(item.data.price)}
                    </CardSubtitle>
                  </td>
                  <td>
                    <Input
                      className="mt-0"
                      key={item.data.name}
                      type="text"
                      value={item.newPrice}
                      onChange={(e) => {
                        arrayDetail[index].newPrice = e.target.value.replace(/[^0-9]/g, '');
                        forceUpdate();
                      }}
                      onBlur={() => {
                        handleNewPrice();
                      }}
                    />
                  </td>
                </tr>
              );
            })}
            <tr className="border-0">
              <td>
                <CardSubtitle tag="h6" className="mt-1">
                  Total
                </CardSubtitle>
              </td>
              <td>
                <CardSubtitle tag="h6" className="mt-1">
                  Rp {Helper.formatMoney(totalPrice)}
                </CardSubtitle>
              </td>
              <td>
                <CardSubtitle tag="h6" className="mt-1 ">
                  Rp {Helper.formatMoney(totalNewPrice ? totalNewPrice : totalPrice)}
                </CardSubtitle>
              </td>
            </tr>
            <tr>
              <td>
                <CardSubtitle tag="h6" className="mt-4 text-muted">
                  OtoPoint Used
                </CardSubtitle>
              </td>
              <td>
                <CardSubtitle tag="h6" className="mt-4 text-muted">
                  - Rp {Helper.formatMoney(get(priceData, 'otopoints_used', 0))}
                </CardSubtitle>
              </td>
              <td>
                <CardSubtitle tag="h6" className="mt-4 text-muted">
                  - Rp {Helper.formatMoney(get(priceData, 'otopoints_used', 0))}
                </CardSubtitle>
              </td>
            </tr>
            <tr>
              <td>
                <CardSubtitle tag="h6" className="mt-4 text-muted">
                  Promo Used
                </CardSubtitle>
              </td>
              <td>
                <CardSubtitle tag="h6" className="mt-4 text-muted">
                  - Rp {Helper.formatMoney(get(priceData, 'promo_used', 0))}
                </CardSubtitle>
              </td>
              <td>
                <CardSubtitle tag="h6" className="mt-4 text-muted">
                  - Rp {Helper.formatMoney(get(priceData, 'promo_used', 0))}
                </CardSubtitle>
              </td>
            </tr>
            <tr>
              <td>
                <CardSubtitle tag="h6" className="mt-4 ">
                  Final Price
                </CardSubtitle>
              </td>
              <td>
                <CardSubtitle tag="h6" className="mt-4">
                  Rp {Helper.formatMoney(get(priceData, 'old_price', 0))}
                </CardSubtitle>
              </td>
              <td>
                <CardSubtitle tag="h6" className="mt-4">
                  Rp {Helper.formatMoney(newFinalPrice ? newFinalPrice : priceData.old_price)}
                </CardSubtitle>
              </td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="primary" onClick={() => cancelEditPrice()}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleSubmitNewPrices} value="2">
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default changePriceBookingModal;
