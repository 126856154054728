import React from 'react';
import AsyncSelect from 'react-select/async';
import { FormGroup, Label, Col } from 'reactstrap';

const EditableSelectAsyncField = (props) => {
  const {
    value,
    title,
    onChange,
    onInputChange,
    isDisabled,
    colWidth,
    labelWidth,
    required,
    placeholder,
    defaultOptions,
    getOptionLabel,
    getOptionValue,
    loadOptions,
    errorMessage,
    customClassName,
    components,
    isClearable,
  } = props;

  const generateClassName = () => {
    if (required) {
      return 'label-required';
    } else if (customClassName) {
      return customClassName;
    } else {
      return '';
    }
  };

  return (
    <FormGroup row>
      <Label lg={labelWidth} className={generateClassName()}>
        {title}
      </Label>
      <Col lg={colWidth}>
        <AsyncSelect
          cacheOptions
          isDisabled={isDisabled}
          defaultOptions={defaultOptions}
          placeholder={placeholder}
          value={value}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          loadOptions={loadOptions}
          onInputChange={onInputChange}
          onChange={onChange}
          components={components}
          isClearable={isClearable}
        />
        {errorMessage && <div className="pt-1 text-danger">{errorMessage}</div>}
      </Col>
    </FormGroup>
  );
};

export default EditableSelectAsyncField;
