import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { FormGroup, Button, Card, CardBody, CardHeader, Row, Col, Label } from 'reactstrap';
import API from '@utils/API';
import FsLightbox from 'fslightbox-react';
import userPermission from '@utils/userPermission';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import ActionSwitch from '@components/field/ActionSwitch';
import EditWorkshopModal from '@components/modal/EditWorkshopModal';
import EditableInputField from '@components/field/EditableInputField';
import EditableNumberField from '@components/field/EditableNumberField';
import EditableSelectField from '@components/field/EditableSelectField';

const HeaderInformation = (props) => {
  const { defaultValuesHeader, packageCategoryOptions, onUpdatePackageHeader, allowEdit } = props;
  const { generatePermission } = userPermission();
  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [images, setImages] = useState([]);
  const [imgPrev, setImgPrev] = useState('');
  const [modalType, setModalType] = useState('');
  const [payloadForm, setPayloadForm] = useState();
  const [disableForm, setDisableForm] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [showLightbox, setShowLightbox] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const api = new API('v2');

  const setDefaultForm = () => {
    reset({ header: defaultValuesHeader });
  };

  const setImagesPreview = (image_link) => {
    const imagePreview = image_link ?? '';
    const imagesPreview = image_link ? [image_link] : [];

    setImgPrev(imagePreview);
    setImages(imagesPreview);
  };

  const previewImage = () => {
    if (images?.length) {
      setShowLightbox(!showLightbox);
    }
  };

  const restoreFormPackageHeader = () => {
    setDefaultForm();
    setDisableForm(true);
    setConfirmModal(false);
  };

  const allowEditImage = (menu) => {
    return !!(
      generatePermission('package', menu) ||
      generatePermission('package', 'editall') ||
      generatePermission('package', 'editimageall')
    );
  };

  const editForm = () => {
    setDisableForm(false);
  };

  const cancelEditForm = () => {
    setModalType('Batalkan');
    setConfirmModal(true);
  };

  const submitForm = (data) => {
    setModalType('Simpan');
    setConfirmModal(true);
    setPayloadForm(data);
  };

  const updateHeaderPackage = async () => {
    const idProduct = payloadForm?.header?.id;
    const payload = {
      is_active: payloadForm?.header?.is_active ? 1 : 0,
    };

    try {
      const response = await api.put(`v2/intools/packages/edit-package?id=${idProduct}`, payload);
      showAlert('success', 'Berhasil update package header.');
      setDisableForm(true);
      setConfirmModal(false);
      onUpdatePackageHeader(response?.data?.data?.data?.id);
    } catch (e) {
      showAlert('danger', 'Gagal update package header.');
      setDisableForm(false);
      setConfirmModal(false);
    }
  };

  const showAlert = (color, message) => {
    setSubmitStatus({
      color: color,
      message: message,
    });

    setTimeout(() => {
      setSubmitStatus(null);
    }, 3000);
  };

  useEffect(() => {
    setDefaultForm();
    setImagesPreview(defaultValuesHeader?.image_link);
  }, [defaultValuesHeader]);

  return (
    <React.Fragment>
      <ToastContainer />

      <FsLightbox toggler={showLightbox} sources={images} type="image" />

      <Card>
        <CardHeader className="bg-primary text-white mt-0">Package Header</CardHeader>
        <CardBody>
          <Controller
            name={`header.workshop.name`}
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <EditableInputField required {...rest} colWidth={9} labelWidth={3} disabled={true} title="Workshop" />
            )}
          />

          <Controller
            name={`header.sku`}
            control={control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <EditableInputField
                {...rest}
                title="Part Number"
                colWidth={9}
                labelWidth={3}
                disabled={true}
                invalid={error ? true : false}
                errorMessage={error?.message ?? ''}
              />
            )}
          />

          <Controller
            name={`header.name`}
            control={control}
            // use for next sprint
            // rules={{
            //   required: 'Package Name harus diisi',
            // }}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <EditableInputField
                required
                {...rest}
                colWidth={9}
                labelWidth={3}
                title="Package Name"
                disabled={true}
                invalid={error ? true : false}
                errorMessage={error?.message ?? ''}
              />
            )}
          />

          <Controller
            control={control}
            name={`header.category`}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => {
              return (
                <EditableSelectField
                  {...rest}
                  colWidth={9}
                  labelWidth={3}
                  disabled={true}
                  title="Category"
                  placeholder="Pilih..."
                  closeMenuOnSelect={true}
                  options={packageCategoryOptions}
                  errorMessage={error?.message ?? ''}
                />
              );
            }}
          />

          <Controller
            control={control}
            name={`header.description`}
            render={({ field: { ref, ...rest } }) => (
              <EditableInputField
                {...rest}
                colWidth={9}
                labelWidth={3}
                title="Description"
                inputType="textarea"
                disabled={true}
              />
            )}
          />

          <Controller
            control={control}
            name={`header.original_price`}
            // use for next sprint
            // rules={{
            //   validate: () => {
            //     const valid =
            //       !getValues(`header.original_price`) || getValues(`header.original_price`) <= 0 ? false : true;
            //     return valid || 'Reguler Price harus diisi';
            //   },
            // }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <EditableNumberField
                required
                colWidth={9}
                value={value}
                labelWidth={3}
                title="Reguler Price"
                disabled={true}
                errorMessage={error?.message ?? ''}
                onChange={(e) => {
                  const discount_amount = getValues(`header.discount_amount`);
                  if (!e.value) {
                    onChange(0);
                  } else {
                    if (e.floatValue < discount_amount) {
                      setValue(`header.discount_amount`, 0);
                    } else {
                      const price = e.floatValue - discount_amount ?? 0;
                      setValue(`header.price`, price);
                    }

                    onChange(e.floatValue);
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name={`header.discount_amount`}
            render={({ field: { onChange, value } }) => {
              return (
                <EditableNumberField
                  colWidth={9}
                  value={value}
                  labelWidth={3}
                  disabled={true}
                  title="Discount Amount"
                  onChange={(e) => {
                    const reguler_price = getValues(`header.original_price`);
                    if (!e.value) {
                      onChange(0);
                    } else {
                      if (e.floatValue > reguler_price) {
                        onChange(0);
                      } else {
                        onChange(e.floatValue);
                        const price = reguler_price - e.floatValue ?? 0;
                        setValue(`header.price`, price);
                      }
                    }
                  }}
                />
              );
            }}
          />

          <Controller
            control={control}
            name={`header.price`}
            render={({ field: { onChange, value } }) => (
              <EditableNumberField
                colWidth={9}
                value={value}
                title="Price"
                labelWidth={3}
                disabled={true}
                onChange={(e) => {
                  onChange(e.floatValue);
                }}
              />
            )}
          />

          <Controller
            control={control}
            name={`header.is_active`}
            render={({ field: { ref, value, name, ...rest } }) => {
              return (
                <FormGroup row>
                  <Label lg={3}>Aktif</Label>
                  <Col className="p-2">
                    <ActionSwitch
                      {...rest}
                      inputName={name}
                      isChecked={value}
                      customClass="mb-0"
                      editableForm={disableForm}
                    />
                  </Col>
                </FormGroup>
              );
            }}
          />

          <Row>
            <Label lg={3}>Image</Label>
            <Col lg={6}>
              <div className="galeri-container">
                <div className="box" onClick={previewImage}>
                  <img src={imgPrev} />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            {allowEditImage('editimageupload') && !disableForm && (
              <Col sm={12}>
                <div className="d-flex h-100 justify-content-center">
                  <div className="justify-content-center align-self-center">
                    <Button color="primary" size="lg" className="btn-add-foto">
                      <img src={PlusFill} className="mr-2" />
                      Browse
                    </Button>
                  </div>
                </div>
              </Col>
            )}
          </Row>

          {allowEdit && (
            <Row className="mt-3">
              <Col className="text-right">
                {!disableForm && (
                  <Button color="danger" className="mr-2" onClick={cancelEditForm}>
                    Batalkan
                  </Button>
                )}
                <Button color="primary" className="mr-2" onClick={disableForm ? editForm : handleSubmit(submitForm)}>
                  {disableForm ? 'Edit' : 'Simpan'}
                </Button>
              </Col>
            </Row>
          )}

          <Row className="mt-3">
            <Col>
              {submitStatus && <div className={`alert alert-${submitStatus?.color}`}>{submitStatus?.message}</div>}
            </Col>
          </Row>
        </CardBody>
        <EditWorkshopModal
          modal={confirmModal}
          isCancel={modalType === 'Batalkan'}
          headerTitle={`${modalType} Hasil Edit?`}
          toggle={() => setConfirmModal(!confirmModal)}
          onConfirm={() => (modalType === 'Simpan' ? updateHeaderPackage() : restoreFormPackageHeader())}
        >
          {modalType} hasil edit Package <b>{defaultValuesHeader?.name}</b>?
        </EditWorkshopModal>
      </Card>
    </React.Fragment>
  );
};

export default HeaderInformation;
