import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Label, FormGroup, Tooltip } from 'reactstrap';
import DetailField from '@components/field/DetailField';
import InputField from '../field/InputField';
import IconInfo from '@assets/icons/info.svg';
// import EditableInputField from '../field/EditableInputField';

const CustomerLocation = (props) => {
  const { data, formValues, isB2B, origin, disableForm, handleChangeCompanyAddress, handleChangeNPWPAddress } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Lokasi</CardHeader>
      <CardBody>
        {!isB2B ? (
          <>
            <DetailField title="Alamat Utama" value={data?.main_address ?? '-'} />
            {data?.alt_address?.map((item, idx) => {
              const label = `Alamat ${idx + 1}`;

              return (
                <DetailField
                  key={label}
                  title={label}
                  value={
                    <div className="d-flex flex-column">
                      <span>{item?.label ?? '-'}</span>
                      <span>{item?.address1 ?? '-'}</span>
                      <span>{item?.address2 ?? '-'}</span>
                    </div>
                  }
                />
              );
            })}
          </>
        ) : (
          <>
            <FormGroup row>
              <Label lg={3}>Alamat Perusahaan</Label>
              <InputField
                colWidth={9}
                placeholder="Ex: Jl. Radio Dalam"
                inputName="company_address"
                value={formValues?.company_address}
                invalid={!!formValues?.error_company_address}
                errorMessage={formValues?.error_company_address}
                disabled={disableForm}
                onChange={handleChangeCompanyAddress}
              />
            </FormGroup>
            <FormGroup row>
              <Tooltip target="address_info" isOpen={tooltipOpen} toggle={toggle}>
                Alamat sesuai dengan NPWP
              </Tooltip>
              <Label lg={3}>
                {'Alamat Penagihan '}
                <img id="address_info" src={IconInfo} alt="" width={16} className="mr-1" />
              </Label>
              <InputField
                colWidth={9}
                placeholder="Ex: Jl. Radio Dalam"
                inputName="billing_address"
                value={formValues?.billing_address}
                invalid={!!formValues?.error_billing_address}
                errorMessage={formValues?.error_billing_address}
                disabled={disableForm}
                onChange={handleChangeNPWPAddress}
              />
            </FormGroup>
          </>
        )}

        {/* <EditableInputField
          labelWidth={3}
          colWidth={9}
          title="Alamat"
          placeholder="Ex: Jl. Radio Dalam"
          // value={data?.main_address}
          // invalid={!!data?.error_main_address}
          // errorMessage={data?.error_main_address}
          disabled={false}
          // onChange={handleChangeCustomerName}
        />
        <EditableInputField
          labelWidth={3}
          colWidth={9}
          title="Kecamatan"
          placeholder="Ex: "
          // value={data?.main_address}
          // invalid={!!data?.error_main_address}
          // errorMessage={data?.error_main_address}
          disabled={false}
          // onChange={handleChangeCustomerName}
        />
        <EditableInputField
          labelWidth={3}
          colWidth={9}
          title="Kabupaten/Kota"
          placeholder="Ex: Jakarta Selatan"
          // value={data?.main_address}
          // invalid={!!data?.error_main_address}
          // errorMessage={data?.error_main_address}
          disabled={false}
          // onChange={handleChangeCustomerName}
        />
        <EditableInputField
          labelWidth={3}
          colWidth={9}
          title="Provinsi"
          placeholder="Ex: DKI Jakarta"
          // value={data?.main_address}
          // invalid={!!data?.error_main_address}
          // errorMessage={data?.error_main_address}
          disabled={false}
          // onChange={handleChangeCustomerName}
        /> */}
      </CardBody>
    </Card>
  );
};

export default CustomerLocation;
