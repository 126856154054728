import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import userPermission from '@utils/userPermission';
import map from 'lodash/map';
import API from '@utils/API';
import moment from 'moment';
import Helper from '@helpers/Helper';
import Loading from '@components/loading/Loading';
import DataTable from 'react-data-table-component';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import PackageAdd from '@components/workshops/package/PackageAdd';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import CustomPagination from '@components/pagination/CustomPagination';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { assign, find } from 'lodash';

const PackageList = (props) => {
  const { id, onLoading, handleClickDetail, handleResultNotification } = props;
  const history = useHistory();
  const qp = qs.parse(history?.location?.search);
  const api = new API('v2');
  const { generatePermission } = userPermission();

  const [data, setData] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [disabledExport, setDisabledExport] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [hasModalAddPackage, setHasModalAddPackage] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();

  const customerTypeOptions = [
    { value: null, label: 'Semua' },
    { value: 'b2c', label: 'B2C' },
    { value: 'b2b', label: 'B2B' },
    { value: 'b2c;b2b', label: 'B2C;B2B' },
  ];

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'packageall'));
  };

  const allowList = allowPermission('packagelist');
  const allowAdd = allowPermission('packageadd');
  const allowDetail = allowPermission('packageshow');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        name: 'Nama',
        selector: (row) => Helper.shortenByDash(row?.name),
        wrap: true,
        sortable: true,
        minWidth: '275px',
      },
      {
        name: 'Kategori',
        selector: (row) => row?.category?.name ?? '-',
        width: '130px',
        wrap: true,
      },
      {
        name: 'Tipe Pelanggan',
        selector: (row) => row?.allowed_customer_type?.toUpperCase() ?? '-',
        width: '130px',
        wrap: true,
      },
      {
        name: 'Harga',
        selector: (row) => <span className="product-price">{`Rp. ${Helper.formatMoney(row?.original_price)}`}</span>,
        selecteor: 'original_price',
        wrap: true,
        minWidth: '120px',
      },
      {
        name: 'Status',
        minWidth: '120px',
        selector: (row) =>
          row.is_active === 0 ? (
            <span className="badge-custom badge-custom-danger">Not Active</span>
          ) : (
            <span className="badge-custom badge-custom-success">Active</span>
          ),
      },
      {
        name: 'Terakhir Diperbarui',
        selector: (row) => moment(row.updated_at).format('MMM D YYYY - HH:mm:ss'),
        wrap: true,
        minWidth: '180px',
      },
    ],
    []
  );

  const statusOptions = [
    { value: '', label: 'Any' },
    { value: '0', label: 'Non Active' },
    { value: '1', label: 'Active' },
  ];

  const dataFilter = [
    {
      id: 'filter_text_package_name',
      type: 'text',
      name: 'package_name',
      name_field: 'package_name',
      value: '',
      placeholder: 'Cari Nama Paket',
    },
    {
      id: 'filter_select_category',
      type: 'select',
      name: 'category',
      name_field: 'category',
      options: categoryOptions,
      value: '',
      placeholder: 'Kategori',
    },
    {
      id: 'filter_select_status',
      type: 'select',
      name: 'status',
      name_field: 'status',
      options: statusOptions,
      value: '',
      placeholder: 'Status',
    },
    {
      id: 'filter_select_customer_type',
      type: 'select',
      name: 'allowed_customer_type',
      name_field: 'allowed_customer_type',
      options: customerTypeOptions,
      value: '',
      placeholder: 'Tipe Pelanggan',
    },
  ];

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchListPackage() {
    setLoading(true);
    setDisabledExport(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/packages/${id}/list-package/`, { params });
      setData(response?.data?.data ?? []);
      handleDataExport(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      setPage(1);
      setData([]);
      handleDataExport([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
    }
  }

  async function fetchCategories() {
    let listCategory = [{ value: '', label: 'Any' }];

    try {
      const response = await api.get(`v2/intools/categories/list-categories-all/`);
      if (response?.data?.data) {
        let resultListCategory = map(response?.data?.data, (item) => {
          return { value: item?.slug, label: item?.name };
        });
        resultListCategory.splice(0, 0, { value: '', label: 'Any' });
        listCategory = resultListCategory;
      }

      setCategoryOptions(listCategory);
    } catch (error) {
      setCategoryOptions(listCategory);
    }
  }

  const getParamData = () => {
    const params = {
      package_name: filtered?.package_name ?? '',
      category_slug: filtered?.category?.value ?? '',
      is_active: filtered?.status?.value ?? '',
      allowed_customer_type: filtered?.allowed_customer_type?.value ?? '',
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const handleDataExport = (dataPackages) => {
    let arrDataExport = [];
    dataPackages.forEach((item) => {
      let status = '-';

      if (item?.is_active == 0) {
        status = 'Not Active';
      } else if (item?.is_active == 1) {
        status = 'Active';
      }

      const objDataExport = {
        name: Helper.shortenByDash(item?.name),
        category: item?.category?.name,
        workshop: item?.workshop?.name,
        estimated_price: Helper.formatRangePrice(item?.estimated_price),
        original_price: `Rp. ${Helper.formatMoney(item?.original_price)}`,
        discount: `Rp. ${Helper.formatMoney(item?.discount_amount)}`,
        price: `Rp. ${Helper.formatMoney(item?.price)}`,
        status: status,
        last_updated: moment(item.updated_at).format('MMM D YYYY - h:mm:ss'),
      };

      arrDataExport.push(objDataExport);
    });

    setDataExport(arrDataExport);
    setDisabledExport(false);
  };

  const handleApplyFilters = async (params) => {
    setFiltered(params);

    if (qp && filters) {
      let tempFilters = [];
      await map(filters, (item) => {
        const dataValue = () => {
          if (item?.id === 'filter_text_package_name') return params?.package_name;
          if (item?.id === 'filter_select_category') return params?.category;
          if (item?.id === 'filter_select_status') return params?.status;
          if (item?.id === 'filter_select_customer_type') return params?.allowed_customer_type;
        };
        const data = {
          ...item,
          value: dataValue(),
          is_filtered: true,
        };

        tempFilters.push(data);
      });
      if (tempFilters?.length > 0) setFilters(tempFilters);
    }
    const payload = {
      ...qp,
      category: params?.category?.value,
      package_name: params?.package_name,
      status: params?.status?.value,
      // allowed_customer_type: params?.allowed_customer_type?.value,
    };
    history.push({ search: qs.stringify(payload) });
  };

  const handleResetFilters = () => {
    setFilters(dataFilter);
    setFiltered({});
    delete qp.category;
    delete qp.package_name;
    delete qp.status;
    delete qp.allowed_customer_type;
    history.push({ search: qs.stringify(qp) });
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleChangeCategoryOptions = () => {
    if (filters.length >= 2) {
      filters[1].options = categoryOptions;
      setFilters([...filters]);
    }
  };

  const handleMethodFetchPackage = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchListPackage();
    }
  };

  const handleDetailPackage = (row) => {
    if (allowDetail) {
      handleClickDetail(row);
    }
  };

  const handleAddPackage = () => setHasModalAddPackage(true);
  const toogleAddPackage = () => setHasModalAddPackage(!hasModalAddPackage);

  const handleDownloadCSV = () => {
    const keys = [
      'name',
      'category',
      'workshop',
      'estimated_price',
      'original_price',
      'discount',
      'price',
      'status',
      'last_updated',
    ];
    const headers = [
      'Name',
      'Category',
      'Workshop Name',
      'Estimated Price',
      'Original Price',
      'Discount',
      'Price',
      'Status',
      'Last Updated',
    ];
    Helper.downloadCSV(dataExport, headers, keys);
  };

  const handleSubmitAddPackage = (status, error) => {
    const errorMsg = error ? 'Gagal Add Package, ' + error : 'Gagal Add Package';
    if (status) {
      handleMethodFetchPackage();
    }
    setHasModalAddPackage(false);
    handleResultNotification(true, status ? 'success' : 'fail', status ? 'Berhasil Add Package' : errorMsg);
  };

  const setDefaultFilters = () => {
    dataFilter[2].value = { value: '1', label: 'Active' };
    setFilters([...dataFilter]);
    setFiltered({
      status: { value: '1', label: 'Active' },
    });
  };

  useEffect(() => {
    if (page && pageLimit && !firstLoad) {
      fetchListPackage();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    handleChangeCategoryOptions();
  }, [categoryOptions]);

  useEffect(() => {
    if (!firstLoad) {
      handleMethodFetchPackage();
    }
    if (firstLoad && filtered) {
      fetchListPackage();
    }
  }, [filtered]);

  useEffect(() => {
    onLoading(firstLoad);
  }, [firstLoad]);

  useEffect(async () => {
    if (Object.keys(qp).length > 1 && categoryOptions?.length > 0) {
      const selectedStatusOptions = find(statusOptions, { value: qp?.status ?? '1' });
      const selectedCategoryOptions = find(categoryOptions, { value: qp?.category ?? '' });
      const selectedCustomerTypeOptions = find(customerTypeOptions, { value: qp?.allowed_customer_type ?? null });

      let params = {};
      if (qp?.package_name) await assign(params, { package_name: qp?.package_name ?? '' });
      if (qp?.category) await assign(params, { category: selectedCategoryOptions });
      if (qp?.status) await assign(params, { status: selectedStatusOptions });
      if (qp?.allowed_customer_type) await assign(params, { allowed_customer_type: selectedCustomerTypeOptions });
      handleApplyFilters(params);
    }
  }, [categoryOptions]);

  useEffect(() => {
    fetchCategories();
    setDefaultFilters();
  }, []);

  return (
    <React.Fragment>
      {firstLoad && <LoadingSpinner></LoadingSpinner>}
      {!firstLoad && (
        <>
          <Card className="card-custom">
            <CardBody className="p-0">
              <Row>
                <Col sm={12} md={12}>
                  <PackageAdd
                    modal={hasModalAddPackage}
                    idWorkshop={id}
                    toggle={toogleAddPackage}
                    onSubmit={handleSubmitAddPackage}
                  />
                  <Col sm={12} md={12} className="p-0 mt-0 section-action">
                    {allowAdd && (
                      <Button disabled={loading} className="button-action add w-170" onClick={handleAddPackage}>
                        {'Buat Paket Baru'}
                      </Button>
                    )}
                    {/* {allowList && (
                      <Button disabled={disabledExport} className="button-action primary" onClick={handleDownloadCSV}>
                        {'Export CSV'}
                      </Button>
                    )} */}
                  </Col>
                  {filters?.length !== 0 && allowList && (
                    <Col sm={12} md={12} className="p-0 mt-4">
                      <ActionFilterCustom
                        filters={filters}
                        loading={loading}
                        onApplyFilters={handleApplyFilters}
                        onResetFilters={handleResetFilters}
                      ></ActionFilterCustom>
                    </Col>
                  )}

                  <Col sm={12} md={12} className="p-0 mt-4">
                    <DataTable
                      persistTableHead
                      highlightOnHover
                      noHeader={true}
                      progressPending={loading}
                      data={data}
                      className="table dt-responsive table-custom"
                      noDataComponent={noDataComponent}
                      progressComponent={<Loading />}
                      customStyles={customStylesHeaderTable}
                      onRowClicked={(row) => handleDetailPackage(row)}
                      columns={allowList ? columns : []}
                    />
                  </Col>
                  <Col sm={12} md={12} className="p-0 mt-2">
                    {data?.length >= 1 && !loading && allowList && (
                      <CustomPagination
                        page={page}
                        pageLimit={pageLimit}
                        totalRows={data?.length ?? 0}
                        totalAllRows={totalRows}
                        totalPages={totalPages}
                        handleChangePage={handleChangePage}
                        handleChangeRowPerPage={handleChangeRowPerPage}
                      ></CustomPagination>
                    )}
                  </Col>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      )}
    </React.Fragment>
  );
};

export default PackageList;
