import React, { useState, useEffect, forwardRef } from 'react';
import { Row, Button, Label, Col } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import InputField from '@components/field/InputField';

const DateTimeField = (props) => {
  const {
    colWidth,
    date = null,
    startDate,
    endDate,
    dateFormat,
    withRange = false,
    withTime = true,
    withClear = true,
    backDate = true,
    futureDate = true,
    isGMTFormat = false,
    errorMessage,
    onChangeDate,
    onChangeStartDate,
    onChangeEndDate,
    placeholderDate = 'Date',
    placeholderStartDate = 'Start Date',
    placeholderEndDate = 'End Date',
    disabled,
  } = props;

  const [formatDate, setFormatDate] = useState();
  const [isOpenDate, setIsOpenDate] = useState(false);
  const [isOpenStartDate, setIsOpenStartDate] = useState(false);
  const [isOpenEndDate, setIsOpenEndDate] = useState(false);
  const [dummyDate, setDummyDate] = useState();
  const [dummyStartDate, setDummyStartDate] = useState();
  const [dummyEndDate, setDummyEndDate] = useState();
  const [time, setTime] = useState('00:00:00');
  const [startTime, setStartTime] = useState('00:00:00');
  const [endTime, setEndTime] = useState('00:00:00');

  const handleFormatDate = () => {
    setFormatDate(dateFormat ? dateFormat : withTime ? 'DD/MM/yyyy - HH:mm:ss' : 'DD/MM/yyyy');
  };

  const generateMask = (type, hour) => {
    return type == 'h' ? (hour && hour[0] == '2' ? [/[0-2]/, /[0-3]/] : [/[0-2]/, /[0-9]/]) : [/[0-5]/, /[0-9]/];
  };

  const generateClassName = (status) => {
    return status
      ? 'form-control input-field w-63 text-center is-invalid is-invalid'
      : 'form-control input-field w-63 text-center';
  };

  const CustomTimeInput = ({ type, onChangeTime, onApplyDateTime }) => {
    const [hh, mm, ss] = type == 'start' ? startTime.split(':') : type == 'end' ? endTime.split(':') : time.split(':');
    const [hours, setHours] = useState(!hh || hh == '' ? '00' : hh);
    const [minutes, setMinutes] = useState(!mm || mm == '' ? '00' : mm);
    const [seconds, setSeconds] = useState(!ss || ss == '' ? '00' : ss);
    const [errorHours, setErrorHours] = useState(false);
    const [errorMinutes, setErrorMinutes] = useState(false);
    const [errorSeconds, setErrorSeconds] = useState(false);
    const [errorInputTime, setErrorInputTime] = useState('');
    const [errorInputDate, setErrorInputDate] = useState('');

    const validateDate = () => {
      const isPassDate =
        type == 'start'
          ? dummyStartDate
            ? true
            : false
          : type == 'end'
          ? dummyEndDate
            ? true
            : false
          : dummyDate
          ? true
          : false;
      setErrorInputDate(isPassDate ? false : true);

      return isPassDate;
    };

    const validateInputTime = () => {
      const h = hours.split('_');
      const m = minutes.split('_');
      const s = seconds.split('_');
      setErrorHours(h[0]?.length == 2 ? false : true);
      setErrorMinutes(m[0]?.length == 2 ? false : true);
      setErrorSeconds(s[0]?.length == 2 ? false : true);
      const isPassTime = h[0]?.length == 2 && m[0]?.length == 2 && s[0]?.length == 2 ? true : false;

      return isPassTime;
    };

    const handleChangeTime = () => {
      const h = hours.split('_');
      const m = minutes.split('_');
      const s = seconds.split('_');
      const stringHours = h[0]?.length == 2 ? h : '00';
      const stringMinutes = m[0]?.length == 2 ? m : '00';
      const stringSeconds = s[0]?.length == 2 ? s : '00';
      const time = stringHours + ':' + stringMinutes + ':' + stringSeconds;
      if (validateInputTime()) {
        onChangeTime(time);
      }
    };

    const handleClickApply = () => {
      if (validateDate() && validateInputTime()) {
        onApplyDateTime();
      }
    };

    useEffect(() => {
      setErrorInputTime(
        errorInputDate
          ? 'Pilih tanggal terlebih dahulu'
          : errorHours || errorMinutes || errorSeconds
          ? 'Format jam salah'
          : ''
      );
    }, [errorHours, errorMinutes, errorSeconds, errorInputDate]);

    useEffect(() => {
      handleChangeTime();
    }, [hours, minutes, seconds]);

    return (
      <>
        <div className="divider-line mt-0" />
        <Row className="mb-2">
          <Col>
            <InputMask
              className={generateClassName(errorHours)}
              value={hours}
              aria-invalid={true}
              mask={generateMask('h', hours)}
              onChange={(e) => {
                if (validateDate()) {
                  setHours(e?.target?.value ?? '00');
                }
              }}
            />
          </Col>
          <Col className={'datetime-separator'}>
            <InputMask
              className={generateClassName(errorMinutes)}
              value={minutes}
              aria-invalid={true}
              mask={generateMask()}
              onChange={(e) => {
                if (validateDate()) {
                  setMinutes(e?.target?.value ?? '00');
                }
              }}
            />
          </Col>
          <Col className={'datetime-separator'}>
            <InputMask
              className={generateClassName(errorSeconds)}
              value={seconds}
              aria-invalid={true}
              mask={generateMask()}
              onChange={(e) => {
                if (validateDate()) {
                  setSeconds(e?.target?.value ?? '00');
                }
              }}
            />
          </Col>
        </Row>
        {errorInputTime && <div className="text-danger">{errorInputTime}</div>}
        <div className="section-action pt-2">
          <Button className="button-action export w-230 mb-1" onClick={() => handleClickApply()}>
            Pilih Tanggal dan Waktu
          </Button>
        </div>
      </>
    );
  };

  const InputDate = forwardRef(({ onClick }, ref) => (
    <InputField
      className={'input-field input-bg-calender cursor-pointer '}
      colWidth={12}
      inputType={'text'}
      value={date ? moment(date).format(formatDate) : ''}
      invalid={errorMessage ? true : false}
      placeholder={placeholderDate}
      onClick={onClick}
      disabled={disabled}
    />
  ));
  const InputStartDate = forwardRef(({ onClick }, ref) => (
    <InputField
      className={'input-field input-bg-calender cursor-pointer '}
      colWidth={12}
      inputType={'text'}
      value={startDate ? moment(startDate).format(formatDate) : ''}
      invalid={errorMessage ? true : false}
      placeholder={placeholderStartDate}
      onClick={onClick}
      disabled={disabled}
    />
  ));
  const InputEndDate = forwardRef(({ onClick }, ref) => (
    <InputField
      className={'input-field input-bg-calender cursor-pointer '}
      colWidth={12}
      inputType={'text'}
      value={endDate ? moment(endDate).format(formatDate) : ''}
      invalid={errorMessage ? true : false}
      placeholder={placeholderEndDate}
      onClick={onClick}
      disabled={disabled}
    />
  ));

  const handleDefaultDateTime = () => {
    if (withRange) {
      setDummyStartDate(startDate);
      setDummyEndDate(endDate);

      const timeStart =
        startDate instanceof Date && !isNaN(startDate)
          ? startDate.toLocaleTimeString('it-IT')
          : isGMTFormat && startDate
          ? moment(startDate).format('HH:mm:ss')
          : '';
      const timeEnd =
        endDate instanceof Date && !isNaN(endDate)
          ? endDate.toLocaleTimeString('it-IT')
          : isGMTFormat && endDate
          ? moment(endDate).format('HH:mm:ss')
          : '';
      if (timeStart !== '') {
        const [hh, mm, ss] = timeStart.split(':');
        setStartTime(hh + ':' + mm + ':' + ss);
      } else {
        setStartTime('00:00:00');
      }

      if (timeEnd !== '') {
        const [hh, mm, ss] = timeEnd.split(':');
        setEndTime(hh + ':' + mm + ':' + ss);
      } else {
        setEndTime('00:00:00');
      }
    } else {
      setDummyDate(date);

      const timeSingle = date instanceof Date && !isNaN(date) ? date.toLocaleTimeString('it-IT') : '';

      if (timeSingle !== '') {
        const [hh, mm, ss] = time.split(':');
        setTime(hh + ':' + mm + ':' + ss);
      } else {
        setTime('00:00:00');
      }
    }
  };

  const handleChangeDateRange = (e, type) => {
    if (withTime) {
      if (e == null) {
        if (type == 'start') setDummyStartDate();
        if (type == 'end') setDummyEndDate();
        handleClearRange(type);
      } else {
        if (type == 'start') setDummyStartDate(e);
        if (type == 'end') setDummyEndDate(e);
      }
    } else {
      setIsOpenStartDate(false);
      setIsOpenEndDate(false);
      if (type == 'start' && e == null) setDummyStartDate();
      if (type == 'end' && e == null) setDummyEndDate();
      if (type == 'start') onChangeStartDate(e);
      if (type == 'end') onChangeEndDate(e);
    }
  };

  const handleChangeDate = (e) => {
    if (withTime) {
      if (e == null) {
        setDummyDate();
        handleClear();
      } else {
        setDummyDate(e);
      }
    } else {
      setIsOpenDate(false);
      onChangeDate(e);
    }
  };

  const handleApplyDateTime = (type) => {
    if (type == 'start' || type == 'end') {
      setIsOpenStartDate(false);
      setIsOpenEndDate(false);

      const [hhStart, mmStart, ssStart] = startTime.split(':');
      const [hhEnd, mmEnd, ssEnd] = endTime.split(':');
      const targetStartDate = dummyStartDate instanceof Date && !isNaN(dummyStartDate) ? dummyStartDate : new Date();
      const targetEndDate = dummyEndDate instanceof Date && !isNaN(dummyEndDate) ? dummyEndDate : new Date();
      targetStartDate.setHours(Number(hhStart) || 0, Number(mmStart) || 0, Number(ssStart) || 0);
      targetEndDate.setHours(Number(hhEnd) || 0, Number(mmEnd) || 0, Number(ssEnd) || 0);
      const result = type == 'start' ? targetStartDate : targetEndDate;
      if (type == 'start') {
        onChangeStartDate(result);
      } else {
        onChangeEndDate(result);
      }
    } else {
      setIsOpenDate(false);

      const [hh, mm, ss] = time.split(':');
      const targetDate = dummyDate instanceof Date && !isNaN(dummyDate) ? dummyDate : new Date();
      targetDate.setHours(Number(hh) || 0, Number(mm) || 0, Number(ss) || 0);
      const result = targetDate;
      onChangeDate(result);
    }
  };

  const handleClearRange = (type) => {
    if (type == 'start') {
      onChangeStartDate(null);
    } else {
      onChangeEndDate(null);
    }
  };

  const handleClear = () => {
    onChangeDate(null);
  };

  useEffect(() => {
    handleDefaultDateTime();
  }, [isOpenStartDate, isOpenEndDate, isOpenDate]);

  useEffect(() => {
    handleFormatDate();
  }, []);

  return (
    <Col lg={colWidth}>
      <Row>
        {withRange && (
          <>
            <Col className="d-flex flex-column p-0">
              <div className="input-field-date">
                <DatePicker
                  className="date-field-input"
                  selected={dummyStartDate ? new Date(dummyStartDate) : ''}
                  onChange={(e) => {
                    handleChangeDateRange(e, 'start');
                  }}
                  placeholderText={placeholderStartDate}
                  selectsStart
                  showTimeInput={withTime}
                  startDate={dummyStartDate ? new Date(dummyStartDate) : new Date(undefined)}
                  endDate={new Date(endDate)}
                  minDate={backDate ? '' : new Date()}
                  maxDate={futureDate ? '' : new Date()}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode={'select'}
                  todayButton={withTime ? '' : 'Hari Ini'}
                  open={isOpenStartDate}
                  onInputClick={() => setIsOpenStartDate(true)}
                  onClickOutside={() => setIsOpenStartDate(false)}
                  customInput={<InputStartDate />}
                  customTimeInput={
                    <CustomTimeInput
                      type={'start'}
                      onChangeTime={(e) => setStartTime(e)}
                      onApplyDateTime={(e) => {
                        handleApplyDateTime('start');
                      }}
                    />
                  }
                  isClearable={startDate && withClear ? true : false}
                />
              </div>
            </Col>
            <>
              <Label className="text-center mt-2">{'-'}</Label>
              <Col className="d-flex flex-column p-0">
                <div className="input-field-date">
                  <DatePicker
                    className="date-field-input"
                    selected={dummyEndDate ? new Date(dummyEndDate) : ''}
                    onChange={(e) => {
                      handleChangeDateRange(e, 'end');
                    }}
                    placeholderText={placeholderEndDate}
                    selectsEnd
                    showTimeInput={withTime}
                    startDate={startDate ? new Date(startDate) : new Date(undefined)}
                    endDate={new Date(dummyEndDate)}
                    minDate={startDate ? new Date(startDate) : backDate ? '' : new Date()}
                    maxDate={futureDate ? '' : new Date()}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode={'select'}
                    todayButton={withTime ? '' : 'Hari Ini'}
                    open={isOpenEndDate}
                    onInputClick={() => setIsOpenEndDate(true)}
                    onClickOutside={() => setIsOpenEndDate(false)}
                    customInput={<InputEndDate />}
                    customTimeInput={
                      <CustomTimeInput
                        type={'end'}
                        onChangeTime={(e) => setEndTime(e)}
                        onApplyDateTime={() => {
                          handleApplyDateTime('end');
                        }}
                      />
                    }
                    isClearable={endDate && withClear ? true : false}
                  />
                </div>
              </Col>
            </>
          </>
        )}
        {!withRange && (
          <>
            <Col className="d-flex flex-column p-0" lg={12}>
              <div className="input-field-date">
                <DatePicker
                  className="date-field-input"
                  selected={dummyDate ? new Date(dummyDate) : ''}
                  onChange={(e) => {
                    handleChangeDate(e);
                  }}
                  showTimeInput={withTime}
                  startDate={dummyDate ? new Date(dummyDate) : new Date(undefined)}
                  endDate={dummyDate ? new Date(dummyDate) : new Date(undefined)}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode={'select'}
                  todayButton={withTime ? '' : 'Hari Ini'}
                  minDate={backDate ? '' : new Date()}
                  maxDate={futureDate ? '' : new Date()}
                  open={isOpenDate}
                  onInputClick={() => setIsOpenDate(true)}
                  onClickOutside={() => setIsOpenDate(false)}
                  customInput={<InputDate />}
                  customTimeInput={
                    <CustomTimeInput
                      type={'date'}
                      onChangeTime={(e) => setTime(e)}
                      onApplyDateTime={(e) => {
                        handleApplyDateTime('date');
                      }}
                    />
                  }
                  isClearable={date && withClear ? true : false}
                />
              </div>
            </Col>
          </>
        )}
      </Row>
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
    </Col>
  );
};

export default DateTimeField;
