import React, { useEffect, useState } from 'react';
import Wrapper from '@components/wrapper/Wrapper';
import EditableInputField from '@components/field/EditableInputField';
import EditActionButton from '@components/field/EditActionButton';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import API from '@utils/API';
import { Card, FormGroup, CardBody, CardHeader, Col, Form, Row, Button } from 'reactstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { get } from 'lodash';
import CarBrandName from '../../components/car/CarBrandName';
import CarModelName from '../../components/car/CarModelName';
import { regexOnlyAllowSomeSymbols } from '../../utils/Constants';

const CarsCreate = () => {
  const history = useHistory();
  const path = useLocation().pathname;
  const { id } = useParams();
  const api = new API('v2');

  const [data, setData] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCarModel, setSelectedCarModel] = useState(null);
  const [variant, setVariant] = useState('');
  const [tierByPrice, setTierByPrice] = useState('');
  const [tierBySize, setTierBySize] = useState('');
  const [tyre, setTyre] = useState('');
  const [rimSize, setRimSize] = useState('');
  const [fuel, setFuel] = useState('');
  const [oilType, setOilType] = useState('');
  const [oilQty, setOilQty] = useState('');
  const [oilQtyRound, setOilQtyRound] = useState('');
  const [hasInfo, setHasInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [infoColor, setInfoColor] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [brandNameError, setBrandNameError] = useState();
  const [carModelError, setCarModelError] = useState();
  const [variantError, setVariantError] = useState();
  const [tierByPriceError, setTierByPriceError] = useState();
  const [tierBySizeError, setTierBySizeError] = useState();
  const [tyreError, setTyreError] = useState();
  const [rimSizeError, setRimSizeError] = useState();
  const [fuelError, setFuelError] = useState();
  const [oilTypeError, setOilTypeError] = useState();
  const [oilQtyError, setOilQtyError] = useState();
  const [oilQtyRoundError, setOilQtyRoundError] = useState();

  const breadcrumb = [
    {
      id: 1,
      label: 'List Car',
      active: false,
      url: '/cars',
    },
    {
      id: 2,
      label: isEdit ? 'Detail Car' : 'Create Car',
      active: true,
      url: '',
    },
  ];

  useEffect(() => {
    const createPath = path === '/cars/create';

    setInfoColor('primary');
    setIsEdit(!createPath);

    if (!createPath) {
      fetchCar(id);
    }
  }, []);

  const validation = () => {
    let status = true;

    if (!selectedBrand) {
      setBrandNameError('Brand Name is mandatory!');
      status = false;
    }
    if (!selectedCarModel) {
      setCarModelError('Car Model is mandatory!');
      status = false;
    }
    if (!variant) {
      setVariantError('Variant is mandatory!');
      status = false;
    }
    if (!tierByPrice) {
      setTierByPriceError('Tier by Price is mandatory!');
      status = false;
    }
    if (!tierBySize) {
      setTierBySizeError('Tier by Size is mandatory!');
      status = false;
    }
    if (!tyre) {
      setTyreError('Tyre is mandatory!');
      status = false;
    }
    if (!rimSize) {
      setRimSizeError('Rim Size is mandatory!');
      status = false;
    }
    if (!fuel) {
      setFuelError('Fuel is mandatory!');
      status = false;
    }
    if (!oilType) {
      setOilTypeError('Oil Type is mandatory!');
      status = false;
    }
    if (!oilQty) {
      setOilQtyError('Oil Quantity is mandatory!');
      status = false;
    }
    if (!oilQtyRound) {
      setOilQtyRoundError('Oil Quantity Rounded is mandatory!');
      status = false;
    }

    return status;
  };

  const handleSubmit = async (e) => {
    setIsSubmit(true);
    e.preventDefault();

    if (!validation()) {
      setIsSubmit(false);
      return;
    }

    const params = {
      brand_name: selectedBrand?.name,
      car_model: selectedCarModel?.id,
      variant: variant,
      fuel,
      oil_type: oilType,
      oil_qty: oilQty,
      oil_qty_rounded: oilQtyRound,
      tyre_type: tyre,
      tier_by_price: tierByPrice,
      tier_by_size: tierBySize,
      rim_size: rimSize,
    };

    const url = isEdit ? `v2/intools/cars/${id}/` : 'v2/intools/cars/add/';

    try {
      isEdit ? await api.put(url, params) : await api.post(url, params);
      setHasInfo(true);
      setIsSubmit(false);
      setInfoColor('success');
      setInfoMessage('Workshop berhasil dibuat');
      history.push('/cars');
    } catch (error) {
      setHasInfo(true);
      setIsSubmit(false);
      setInfoColor('danger');
      setInfoMessage('Workshop gagal dibuat');
    }
  };

  const handleBrandChange = (value) => {
    setSelectedBrand(value);

    if (value) {
      setBrandNameError();
    }
  };

  const handleCarChange = (value) => {
    setSelectedCarModel(value);

    if (value) {
      setCarModelError();
    }
  };

  const fetchCar = async () => {
    setLoading(true);

    const response = await api.get(`v2/intools/user_car/get-details-car/?cardetails_id=${id}`);
    const datas = response.data.data[0];

    setData(datas);
    setSelectedBrand(datas?.car_model?.brand?.name);
    setSelectedCarModel(datas?.car_model?.model_name);
    setVariant(datas?.variant);
    setTierByPrice(datas?.tier_by_price);
    setTierBySize(datas?.tier_by_size);
    setTyre(datas?.tyre_type);
    setRimSize(datas?.rim_size);
    setFuel(datas?.fuel);
    setOilType(datas?.oil_type);
    setOilQty(datas?.oil_qty);
    setOilQtyRound(datas?.oil_qty_rounded);
    setLoading(false);
  };

  if (loading) {
    return <LoadingWrapper />;
  }

  return (
    <Wrapper title={isEdit ? 'Detail Car' : 'Create Car'} breadcrumbData={breadcrumb}>
      <Form>
        <Card>
          <CardHeader className="bg-primary text-white mt-0">{isEdit ? 'STNK Details' : 'Car Details'}</CardHeader>
          <CardBody>
            {isEdit && <img src={get(data, 'car_model.image_link', '')} className="user-car-detail" />}
            <Row>
              <Col xs={12} md={6} lg={6}>
                <CarBrandName
                  onChangeBrand={handleBrandChange}
                  initialValue={data?.car_model?.brand?.name}
                  invalid={brandNameError}
                />

                <CarModelName
                  brand={selectedBrand}
                  isDisabled={!selectedBrand}
                  onChangeName={handleCarChange}
                  initialValue={data?.car_model?.model_name}
                  invalid={carModelError}
                />

                <EditableInputField
                  labelWidth={3}
                  colWidth={9}
                  title="Variant"
                  inputName="variant"
                  value={variant}
                  placeholder="Car Variant"
                  onChange={(e) => {
                    e.target.validity.valid ? setVariant(e.target.value) : variant;

                    if (e.target.value.length > 0) {
                      setVariantError();
                    }
                  }}
                  invalid={variantError}
                  errorMessage={variantError}
                  inputPattern={regexOnlyAllowSomeSymbols}
                  required
                />

                <EditableInputField
                  labelWidth={3}
                  colWidth={9}
                  title="Tier by Price"
                  inputName="tierByPrice"
                  value={tierByPrice}
                  placeholder="Tier by Price"
                  onChange={(e) => {
                    e.target.validity.valid ? setTierByPrice(e.target.value) : tierByPrice;

                    if (e.target.value.length > 0) {
                      setTierByPriceError();
                    }
                  }}
                  invalid={tierByPriceError}
                  errorMessage={tierByPriceError}
                  inputPattern={regexOnlyAllowSomeSymbols}
                  required
                />

                <EditableInputField
                  labelWidth={3}
                  colWidth={9}
                  title="Tier by Size"
                  inputName="tierBySize"
                  value={tierBySize}
                  placeholder="Tier by Size"
                  onChange={(e) => {
                    e.target.validity.valid ? setTierBySize(e.target.value) : tierBySize;

                    if (e.target.value.length > 0) {
                      setTierBySizeError();
                    }
                  }}
                  invalid={tierBySizeError}
                  errorMessage={tierBySizeError}
                  inputPattern={regexOnlyAllowSomeSymbols}
                  required
                />

                <EditableInputField
                  labelWidth={3}
                  colWidth={9}
                  title="Tyre"
                  inputName="tyre"
                  value={tyre}
                  placeholder="Tyre"
                  onChange={(e) => {
                    e.target.validity.valid ? setTyre(e.target.value) : tyre;

                    if (e.target.value.length > 0) {
                      setTyreError();
                    }
                  }}
                  invalid={tyreError}
                  errorMessage={tyreError}
                  inputPattern={regexOnlyAllowSomeSymbols}
                  required
                />

                <EditableInputField
                  labelWidth={3}
                  colWidth={9}
                  title="Rim Size"
                  inputName="rimSize"
                  value={rimSize}
                  placeholder="Rim Size"
                  onChange={(e) => {
                    e.target.validity.valid ? setRimSize(e.target.value) : rimSize;

                    if (e.target.value.length > 0) {
                      setRimSizeError();
                    }
                  }}
                  invalid={rimSizeError}
                  errorMessage={rimSizeError}
                  inputPattern="[0-9]*"
                  required
                />

                <EditableInputField
                  labelWidth={3}
                  colWidth={9}
                  title="Fuel"
                  inputName="fuel"
                  value={fuel}
                  placeholder="Fuel"
                  onChange={(e) => {
                    e.target.validity.valid ? setFuel(e.target.value) : fuel;

                    if (e.target.value.length > 0) {
                      setFuelError();
                    }
                  }}
                  invalid={fuelError}
                  errorMessage={fuelError}
                  inputPattern={regexOnlyAllowSomeSymbols}
                  required
                />

                <EditableInputField
                  labelWidth={3}
                  colWidth={9}
                  title="Oil Type"
                  inputName="oilType"
                  value={oilType}
                  placeholder="Oil Type"
                  onChange={(e) => {
                    setOilType(e.target.value);

                    if (e.target.value.length > 0) {
                      setOilTypeError();
                    }
                  }}
                  invalid={oilTypeError}
                  errorMessage={oilTypeError}
                  required
                />

                <EditableInputField
                  labelWidth={3}
                  colWidth={9}
                  title="Oil Quantity"
                  inputName="oilQty"
                  value={oilQty}
                  placeholder="Oil Quantity"
                  onChange={(e) => {
                    e.target.validity.valid ? setOilQty(e.target.value) : oilQty;

                    if (e.target.value.length > 0) {
                      setOilQtyError();
                    }
                  }}
                  invalid={oilQtyError}
                  errorMessage={oilQtyError}
                  inputPattern="^[0-9,.]*"
                  required
                />

                <EditableInputField
                  labelWidth={3}
                  colWidth={9}
                  title="Oil Quantity Rounded"
                  inputName="oilQtyRound"
                  value={oilQtyRound}
                  placeholder="Oil Quantity Rounded"
                  onChange={(e) => {
                    e.target.validity.valid ? setOilQtyRound(e.target.value) : oilQtyRound;

                    if (e.target.value.length > 0) {
                      setOilQtyRoundError();
                    }
                  }}
                  invalid={oilQtyRoundError}
                  errorMessage={oilQtyRoundError}
                  inputPattern="[0-9]*"
                  required
                />

                {isSubmit ? (
                  <FormGroup row>
                    <Col lg={9} className="offset-lg-3">
                      <Row>
                        <Col lg={6} className="offset-lg-6">
                          <Button color="secondary" block disabled={isSubmit}>
                            Loading...
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                ) : (
                  <EditActionButton
                    labelWidth={3}
                    colWidth={9}
                    hasInfo={hasInfo}
                    hasCancel={false}
                    infoColor={infoColor}
                    infoMessage={infoMessage}
                    handleSubmit={handleSubmit}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </Wrapper>
  );
};

export default CarsCreate;
