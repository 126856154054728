import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import Loading from '@components/loading/Loading';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import { Col, Button, Card, CardBody, Badge } from 'reactstrap';

function PushNotifications() {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [dataExport, setDataExport] = useState([]);
  const [disabledExport, setDisabledExport] = useState(true);

  const allowPermission = (menu) => {
    return !!(generatePermission('pushnotification', menu) || generatePermission('pushnotification', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowShow = allowPermission('all') || allowPermission('detail');
  const allowCreate = allowPermission('all') || allowPermission('add');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  let dataFilter = [
    {
      id: 'filter_text_query',
      type: 'text',
      name: 'query',
      name_field: 'query',
      value: '',
      placeholder: 'Phone Number / Name / Email',
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'PN ID',
        selector: (row) => row.id,
        minWidth: '10%',
      },
      {
        name: 'Publish at',
        selector: (row) => moment(row.published_at).format('MMM DD YYYY, HH:mm:ss'),
        wrap: true,
        sortable: true,
        minWidth: '20%',
      },
      {
        name: 'Title Messages',
        selector: (row) => row?.title ?? '-',
        wrap: true,
        minWidth: '50%',
      },
      {
        name: 'Creator',
        selector: (row) => row?.creator ?? '-',
        wrap: true,
        width: '20%',
      },
    ],
    []
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchCustomers() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/fcm-notifications/blast/histories`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
    }
  }

  const getParamData = () => {
    let params = {
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const handleDataExport = (data) => {
    let arrDataExport = [];

    data.forEach((item) => {
      const objDataExport = {
        phone: item?.user?.username ?? '-',
        customer: item?.name ?? '-',
        email: item?.email,
      };

      arrDataExport.push(objDataExport);
    });

    setDataExport(arrDataExport);
    setDisabledExport(false);
  };

  const handleDownloadCSV = () => {
    const keys = ['phone', 'customer', 'email'];
    const headers = ['phone', 'customer name', 'email'];

    Helper.downloadCSV(dataExport, headers, keys);
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleResetFilters = () => {
    setFilters(dataFilter);
    setFiltered({});
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodfetchCustomers();
  };

  const handleMethodfetchCustomers = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchCustomers();
    }
  };

  const resetFilter = () => {
    setResetSorting(true);
    setSortingData({});
  };

  const createPushNotifications = () => {
    if (allowCreate) history.push(`/push-notifications/create`);
  };

  const openPushNotificationsDetail = (row) => {
    if (allowShow) {
      history.push(`/push-notifications/${row.id}`);
    }
  };

  useEffect(() => {
    if (page && pageLimit) {
      if (allowList) fetchCustomers();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    resetFilter();
  }, [filtered]);

  useEffect(() => {
    setFilters(dataFilter);
  }, []);

  return (
    <Wrapper title="List Push Notifications" className="wrapperContent">
      <Card className="p-3">
        <CardBody className="p-0">
          <Col sm={12} md={12} className="p-0 mt-0 section-action">
            {allowCreate && (
              <Button className="button-action add w-170" onClick={createPushNotifications}>
                {'+ Push Notifications'}
              </Button>
            )}
          </Col>
          <Col sm={12} md={12} className="p-0 mt-2">
            <DataTable
              persistTableHead
              highlightOnHover
              noHeader={true}
              progressPending={loading}
              data={data}
              columns={allowList ? columns : []}
              className="table dt-responsive table-custom"
              noDataComponent={noDataComponent}
              progressComponent={<Loading />}
              customStyles={customStylesHeaderTable}
              onRowClicked={(row) => openPushNotificationsDetail(row)}
            />
          </Col>
          <Col sm={12} md={12} className="p-0 mt-2">
            {data?.length >= 1 && !loading && (
              <CustomPagination
                page={page}
                pageLimit={pageLimit}
                totalRows={data?.length ?? 0}
                totalAllRows={totalRows}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                handleChangeRowPerPage={handleChangeRowPerPage}
              ></CustomPagination>
            )}
          </Col>
        </CardBody>
      </Card>
    </Wrapper>
  );
}

export default PushNotifications;
