import React, { useState, useEffect } from 'react';
import { Col, Label, Row, Button, Input } from 'reactstrap';
import InputField from '@components/field/InputField';
import { regexOnlyAllowNumber, regexOnlyAllowCharacter } from '@utils/Constants';
import Helper from '@helpers/Helper';
import API from '@utils/API';

const SetupVoucherForm = (props) => {
  const { dataDetail, dataCampaign, handleNext, handleBack, disableButton, disableForm, handleOnBlur } = props;

  const api = new API('v2');
  const [detailData, setDetailData] = useState({});

  const handleDefaultData = () => {
    const defaultData = {
      campaign_type: dataDetail?.campaign_type,
      error_campaign_type: dataDetail?.error_campaign_type,
      voucher_code: dataDetail?.voucher_code,
      error_voucher_code: dataDetail?.error_voucher_code,
      total_voucher: dataDetail?.total_voucher,
      error_total_voucher: dataDetail?.error_total_voucher,
      max_redeem_per_user:
        dataDetail?.customer_segment?.value == 'no-trx'
          ? Helper.formatMoneyOnField('1')
          : dataDetail?.max_redeem_per_user,
      error_max_redeem_per_user: dataDetail?.error_max_redeem_per_user,
      max_quota: dataDetail?.max_quota,
      error_max_quota: dataDetail?.error_max_quota,
    };
    setDetailData(defaultData);
  };

  const handleParentData = () => {
    dataDetail.campaign_type = detailData?.campaign_type;
    dataDetail.error_campaign_type = detailData?.error_campaign_type;
    dataDetail.voucher_code = detailData?.voucher_code;
    dataDetail.error_voucher_code = detailData?.error_voucher_code;
    dataDetail.total_voucher = detailData?.total_voucher;
    dataDetail.error_total_voucher = detailData?.error_total_voucher;
    dataDetail.max_redeem_per_user = detailData?.max_redeem_per_user;
    dataDetail.error_max_redeem_per_user = detailData?.error_max_redeem_per_user;
    dataDetail.max_quota = detailData?.max_quota;
    dataDetail.error_max_quota = detailData?.error_max_quota;

    return dataDetail;
  };

  const handleChangeCampaignType = (value) => {
    const valueChampaignType = value ?? null;
    validateCampaignType(valueChampaignType);
  };

  const handleChangeVoucherCode = (e) => {
    const valueVoucherCode =
      e.target.value !== ' ' ? e.target.value.replace(/ /g, '').toUpperCase() : detailData?.voucher_code ?? '';
    validateVoucherCode(valueVoucherCode);
  };

  const handleChangeTotalVoucher = (e) => {
    const valueTotalVoucher =
      e.target.value !== ' ' ? Helper.formatMoneyOnField(e.target.value) : detailData?.total_voucher ?? '';
    validateTotalVoucher(valueTotalVoucher);
  };

  const handleChangeMaxRedeem = (e) => {
    const valueMaxRedeem =
      e.target.value !== ' ' ? Helper.formatMoneyOnField(e.target.value) : detailData?.max_redeem_per_user ?? '';
    validateMaxRedeem(valueMaxRedeem);
  };

  const handleChangeMaxQuota = (e) => {
    const valueMaxQuota =
      e.target.value !== ' ' ? Helper.formatMoneyOnField(e.target.value) : detailData?.max_quota ?? '';
    validateMaxQuota(valueMaxQuota);
  };

  const validateCampaignType = (value) => {
    let passValidate = true;

    if (!value) {
      passValidate = false;
      detailData.error_campaign_type = 'Pilih salah satu terlebih dahulu';
    } else {
      detailData.error_campaign_type = '';
    }

    if (value == 'single') {
      detailData.total_voucher = Helper.formatMoneyOnField('1');
      detailData.error_total_voucher = '';
    }

    detailData.campaign_type = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateVoucherCode = (value) => {
    let passValidate = true;
    if (value?.length == 0 || !value) {
      passValidate = false;
      detailData.error_voucher_code = 'Field tidak boleh kosong';
    } else if (!disableForm && (value?.length < 3 || value?.length > 20)) {
      passValidate = false;
      detailData.error_voucher_code = 'Format minimal 3, maksimal 20 karakter';
    } else {
      detailData.error_voucher_code = '';
    }

    detailData.voucher_code = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateTotalVoucher = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      detailData.error_total_voucher = 'Field tidak boleh kosong';
    } else if (!disableForm && (value?.formatMoney < 1 || value?.formatMoney > 30000)) {
      passValidate = false;
      detailData.error_total_voucher = 'Minimal 1 voucher dan maximal 30.000 voucher';
    } else {
      detailData.error_total_voucher = '';
    }

    detailData.total_voucher = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateMaxRedeem = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      detailData.error_max_redeem_per_user = 'Field tidak boleh kosong';
    } else if (!disableForm && (value?.formatMoney < 1 || value?.formatMoney > 999)) {
      passValidate = false;
      detailData.error_max_redeem_per_user = 'Minimal 1 redeem dan maximal 999x per user';
    } else {
      detailData.error_max_redeem_per_user = '';
    }

    detailData.max_redeem_per_user = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateMaxQuota = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      detailData.error_max_quota = 'Field tidak boleh kosong';
    } else if (!disableForm && (value?.formatMoney < 1 || value?.formatMoney > 999)) {
      passValidate = false;
      detailData.error_max_quota = 'Minimal 1 dan maximal 999x';
    } else {
      detailData.error_max_quota = '';
    }

    detailData.max_quota = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const handleOnBlurVoucherCode = () => {
    if (
      detailData?.voucher_code &&
      detailData?.voucher_code?.length >= 3 &&
      detailData?.voucher_code?.length <= 20 &&
      detailData?.voucher_code != dataCampaign?.voucher_code
    ) {
      return api
        .get(`v2/intools/campaigns/check-voucher/${detailData?.voucher_code}/`)
        .then((response) => {
          detailData.error_voucher_code = '';
          setDetailData({ ...detailData });
        })
        .catch((error) => {
          detailData.error_voucher_code = 'Tidak dapat sama dengan kode voucher yang sedang aktif';
          setDetailData({ ...detailData });
        });
    }
  };

  const handleCheckVoucherCode = (value, params) => {
    if (value && value?.length >= 3 && value?.length <= 20 && value != dataCampaign?.voucher_code) {
      return api
        .get(`v2/intools/campaigns/check-voucher/${value}/`)
        .then((response) => {
          detailData.error_voucher_code = '';
          setDetailData({ ...detailData });
          handleNext(params);
        })
        .catch((error) => {
          detailData.error_voucher_code = 'Tidak dapat sama dengan kode voucher yang sedang aktif';
          setDetailData({ ...detailData });
          handleNext({ status: false, data: params?.data });
        });
    } else {
      handleNext({ status: false, data: params?.data });
    }
  };

  const handleActionNext = () => {
    let passValidateCampaigntype = true;
    let passValidateVoucherCode = true;
    let passValidateTotalVoucher = true;
    let passValidateMaxRedeem = true;
    let passValidateMaxQuota = true;

    if (!disableForm) {
      passValidateCampaigntype = validateCampaignType(detailData?.campaign_type);
      passValidateVoucherCode = validateVoucherCode(detailData?.voucher_code);
      passValidateTotalVoucher = validateTotalVoucher(detailData?.total_voucher);
      passValidateMaxRedeem = validateMaxRedeem(detailData?.max_redeem_per_user);
      passValidateMaxQuota = validateMaxQuota(detailData?.max_quota);
    }

    let params = {
      status:
        passValidateCampaigntype &&
        passValidateVoucherCode &&
        passValidateTotalVoucher &&
        passValidateMaxRedeem &&
        passValidateMaxQuota
          ? true
          : false,
      data: handleParentData(),
    };

    if (!disableForm) {
      handleCheckVoucherCode(detailData?.voucher_code, params);
    } else {
      handleNext({ status: true, data: params?.data });
    }
  };

  const handleActionBack = () => {
    handleBack();
  };

  useEffect(() => {
    handleDefaultData();
  }, [dataDetail]);

  return (
    <>
      <Row>
        <Col sm={12} md={12} className="mb-3">
          <Row>
            <Label sm={4} lg={2} className="text-left label-required">
              Tipe Voucher
            </Label>
            <Col sm={12} lg={10}>
              <Row>
                <Col sm={12} md={3}>
                  <Label className="ml-4 mt-2 mb-0">
                    <Input
                      type="radio"
                      checked={detailData?.campaign_type == 'single' ? true : false}
                      disabled={disableForm}
                      onClick={() => handleChangeCampaignType('single')}
                    />
                    Single Voucher
                  </Label>
                </Col>
                <Col sm={12} md={4}>
                  <Label className="ml-4 mt-2 mb-0">
                    <Input
                      type="radio"
                      checked={detailData?.campaign_type == 'bulk' ? true : false}
                      disabled={disableForm}
                      onClick={() => handleChangeCampaignType('bulk')}
                    />
                    Bulk Vouchers
                  </Label>
                </Col>
              </Row>
              <Row>
                {detailData?.error_campaign_type && (
                  <div className="text-danger ml-2">{detailData?.error_campaign_type}</div>
                )}
              </Row>
            </Col>
          </Row>
        </Col>

        <Col sm={12} md={6} className="mb-2">
          <Row>
            <Label lg={4} className="text-left label-required">
              Kode Voucher
            </Label>
            <InputField
              colWidth={8}
              inputType="text"
              disabled={disableForm}
              value={detailData?.voucher_code?.toUpperCase()}
              invalid={detailData?.error_voucher_code ? true : false}
              inputName={'voucher_code'}
              inputPattern={regexOnlyAllowCharacter}
              placeholder={'Masukkan kode voucher'}
              errorMessage={detailData?.error_voucher_code ?? ''}
              onChange={(e) => handleChangeVoucherCode(e)}
              needOnBlur={true}
              onBlur={handleOnBlurVoucherCode}
            />
          </Row>
        </Col>

        <Col sm={12} md={6} className="mb-2">
          <Row>
            <Label lg={4} className="text-left label-required">
              Jumlah Voucher
            </Label>
            <InputField
              colWidth={8}
              disabled={disableForm || detailData?.campaign_type == 'single' ? true : false}
              value={detailData?.total_voucher?.formatMoneyString}
              invalid={detailData?.error_total_voucher ? true : false}
              inputName={'total_voucher'}
              inputPattern={regexOnlyAllowNumber}
              placeholder={'Masukkan jumlah voucher'}
              errorMessage={detailData?.error_total_voucher ?? ''}
              onChange={(e) => handleChangeTotalVoucher(e)}
            />
          </Row>
        </Col>
        <Col sm={12} md={6}>
          <Row>
            <Label lg={4} className="text-left label-required">
              Max Redeem/User
            </Label>
            <InputField
              colWidth={8}
              min={0}
              value={detailData?.max_redeem_per_user?.formatMoneyString}
              invalid={detailData?.error_max_redeem_per_user ? true : false}
              inputName={'max_redeem_per_user'}
              placeholder={'Masukkan max redeem per user'}
              errorMessage={detailData?.error_max_redeem_per_user ?? ''}
              onChange={(e) => handleChangeMaxRedeem(e)}
            />
          </Row>
        </Col>

        <Col sm={12} md={6}>
          <Row>
            <Label lg={4} className="text-left label-required">
              Total Quota Voucher
            </Label>
            <InputField
              colWidth={8}
              min={0}
              value={detailData?.max_quota?.formatMoneyString}
              invalid={detailData?.error_max_quota ? true : false}
              inputName={'max_quota'}
              placeholder={'Masukkan total quota voucher'}
              errorMessage={detailData?.error_max_quota ?? ''}
              onChange={(e) => handleChangeMaxQuota(e)}
            />
          </Row>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={12} className="section-action mt-3">
          <Button disabled={disableButton} className="button-action save" onClick={() => handleActionNext()}>
            {'Selanjutnya'}
          </Button>
          <Button disabled={disableButton} className="button-action cancel" onClick={() => handleActionBack()}>
            {'Kembali'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SetupVoucherForm;
