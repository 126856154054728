import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Card, FormGroup, Button, Form, Label, Input, FormFeedback } from 'reactstrap';
import { authByUsername, getUserPermission, getSystemConfiguration } from '@actions/Auth';
import { setUserLoggedIn, setUserPermission, setSystemConfiguration } from '@reducers/Auth';
import { validate } from 'react-email-validator';
import Logo from '@assets/images/logo.svg';
import { useHistory } from 'react-router-dom';
import StreamlitLogin from '@components/chat-aida/StreamlitLogin';

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isDisabled, setIsDisabled] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailFormatInvalid, setIsEmailFormatInvalid] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);

  const handleEmailChange = (event) => {
    const email = event.target.value;
    let emailCheck = validate(email);
    if (emailCheck) {
      setEmailErrorMessage();
      setIsEmailFormatInvalid(false);
      setIsEmailInvalid(false);
    } else if (!email) {
      setEmailErrorMessage('Email tidak boleh kosong');
      setIsEmailFormatInvalid(false);
      setIsEmailInvalid(true);
    } else if (!emailCheck) {
      setEmailErrorMessage('Format Email salah');
      setIsEmailFormatInvalid(true);
      setIsEmailInvalid(true);
    }

    setEmail(email);
  };

  const handlePasswordChange = (event) => {
    const password = event.target.value;
    if (!password) {
      setPasswordErrorMessage('Password tidak boleh kosong');
      setIsPasswordInvalid(true);
    } else {
      setPasswordErrorMessage();
      setIsPasswordInvalid(false);
    }

    setPassword(password);
  };

  const validation = () => {
    let status = true;

    if (isEmailFormatInvalid) {
      setEmailErrorMessage('Format Email salah');
      setIsEmailInvalid(true);
      status = false;
    }
    if (!email) {
      setEmailErrorMessage('Email tidak boleh kosong');
      setIsEmailInvalid(true);
      status = false;
    }
    if (!password) {
      setPasswordErrorMessage('Password tidak boleh kosong');
      setIsPasswordInvalid(true);
      status = false;
    }

    return status;
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (!validation()) {
      return;
    }

    setIsDisabled(true);

    dispatch(authByUsername(email, password))
      .then((response) => {
        setIsDisabled(false);

        const payload = {
          loggedIn: true,
          token: response?.data,
          username: email,
        };

        dispatch(setUserLoggedIn(payload));
        dispatch(getUserPermission)
          .then((response) => {
            dispatch(setUserPermission(response.data));
          })
          .catch((error) => {
            setHasError(true);
            setIsDisabled(false);
            setErrorMessage(error?.response?.data);
          });
        dispatch(getSystemConfiguration)
          .then((response) => {
            dispatch(setSystemConfiguration(response.data));
          })
          .catch((error) => {
            setHasError(true);
            setIsDisabled(false);
            setErrorMessage(error?.response?.data);
          });
      })
      .catch((error) => {
        setHasError(true);
        setIsDisabled(false);
        setErrorMessage(error?.response?.data?.error?.message ?? '');
      });
  };

  return (
    <Container fluid={true}>
      <StreamlitLogin />
      <Row className="vh-100">
        <Col md={8} className="p-0 h-100vh d-flex justify-content-center auth-bg">
          <div className="accountbg d-flex align-items-center">
            <div className="account-title text-center">
              <h4 className="mt-3">{`Let's Rock`}</h4>
              <h1>
                Otoklix <span className="text-warning">Intools</span>{' '}
              </h1>
              <div className="border w-25 mx-auto border-warning" />
            </div>
          </div>
        </Col>
        <Col md={4} className="pr-0">
          <div className="auth-page">
            <Card body className="border-0 mb-0 h-100">
              <div className="mb-5 logo-rv">
                <a href="/" className="logo logo-admin">
                  <span>
                    <img src={Logo} height={40} alt="logo" className="my-3" />
                  </span>
                </a>
              </div>
              <h2 className="font-weight-semibold font-22 mb-2">
                Welcome to <span className="text-primary">InTools</span>.
              </h2>
              <Form className="form-horizontal auth-form my-4">
                <FormGroup>
                  <Label>Email</Label>
                  <Input placeholder="Email" invalid={isEmailInvalid} onChange={handleEmailChange} />
                  <FormFeedback>{emailErrorMessage}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    type="password"
                    placeholder="Password"
                    invalid={isPasswordInvalid}
                    onChange={handlePasswordChange}
                  />
                  <FormFeedback>{passwordErrorMessage}</FormFeedback>
                </FormGroup>

                {hasError && <div className="alert alert-danger">{errorMessage}</div>}
                <div className="text-primary mb-2 cursor-pointer" onClick={() => history.push('/forgot-password')}>
                  Forgot Password?
                </div>
                <FormGroup>
                  <Button block outline size="md" color="primary" disabled={isDisabled} onClick={(e) => handleLogin(e)}>
                    Sign in
                  </Button>
                </FormGroup>
              </Form>
            </Card>
          </div>
          <div className="footer-auth">
            <hr className="hr-dashed mb-0" />
            <div className="p-4">
              <span className="text-muted d-sm-inline-block float-right">
                Crafted with <i className="mdi mdi-heart text-danger" /> by Otoklix
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
