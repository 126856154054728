import React from 'react';

const SubscriptionBadge = ({ status }) => {
  switch (status) {
    case 'pending':
      return <span className="badge-custom badge-custom-primary">{'Menunggu Bayar'}</span>;
    case 'active':
      return <span className="badge-custom badge-custom-success">{'Aktif'}</span>;
    case 'expired':
      return <span className="badge-custom badge-custom-grey">{'Kadaluwarsa'}</span>;
    case 'cancel':
      return <span className="badge-custom badge-custom-danger">{'Batal'}</span>;
    case 'grace_period':
      return <span className="badge-custom badge-custom-yellow">{'Masa Tenggang'}</span>;
    case 'postpone':
      return <span className="badge-custom badge-custom-purple">{'Ditangguhkan'}</span>;
    default:
      return <span className="badge-custom badge-custom-grey">{'-'}</span>;
  }
};

export default SubscriptionBadge;
