import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Col, Label, Row, Input } from 'reactstrap';
import API from '@utils/API';
import map from 'lodash/map';
import Helper from '@helpers/Helper';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import ActionSwitch from '@components/field/ActionSwitch';

const GenerateSnapLink = (props) => {
  const { modal, toggle, payment, onSubmit } = props;

  const api = new API('v2');
  const [paymentNote, setPaymentNote] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [errorPaymentNote, setErrorPaymentNote] = useState('');
  const [errorPaymentMethod, setErrorPaymentMethod] = useState('');

  async function fetchPaymentOptions() {
    try {
      const response = await api.get(`v2/intools/workshops/banks/`);
      const options = map(response?.data?.data, (option) => {
        return { label: option.name, value: option.code };
      });
      setPaymentOptions(options);
    } catch (error) {
      setPaymentOptions([]);
    }
  }

  const handleChangePaymentMethod = (e) => {
    validatePaymentMethod(e?.value ? e : null);
  };

  const handleChangePaymentNote = (e) => {
    validatePaymentNote(e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : paymentNote);
  };

  const validatePaymentMethod = (value) => {
    setPaymentMethod(value);
    setErrorPaymentMethod(!value?.value ? 'Pilih metode pembayaran terlebih dahulu' : '');

    return !value?.value ? false : true;
  };

  const validatePaymentNote = (value) => {
    setPaymentNote(value);
    setErrorPaymentNote(value?.length > 500 ? 'Format catatan pembayaran maksimal 500 karakter' : '');

    return value?.length > 500 ? false : true;
  };

  const handleSubmit = () => {
    const validPaymentNote = validatePaymentNote(paymentNote);
    const validPaymentMethod = validatePaymentMethod(paymentMethod);
    if (validPaymentNote && validPaymentMethod) {
      submitForm();
    }
  };

  async function submitForm() {
    onSubmit({
      payment_method: paymentMethod,
      payment_note: paymentNote,
    });
    toggle();
  }

  const cancel = () => {
    toggle();
  };

  const resetData = () => {
    setPaymentNote();
    setPaymentMethod();
    setPaymentOptions([]);
    setErrorPaymentNote();
    setErrorPaymentMethod('');
  };

  useEffect(() => {
    resetData();
    if (modal) {
      setPaymentMethod(payment);
      fetchPaymentOptions();
    }
  }, [modal]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Metode Pembayaran</div>
        <div className="content">
          {paymentOptions && paymentOptions?.length !== 0 && (
            <Row className="mb-3">
              {paymentOptions.map((item) => {
                return (
                  <Col key={item?.id} md={12} lg={12} className="otopoints-checkbox mb-0">
                    <FormGroup check>
                      <Input
                        type="checkbox"
                        className="cursor-pointer"
                        checked={item?.value == paymentMethod?.value ? true : false}
                        onChange={() => handleChangePaymentMethod(item)}
                      />
                      <Label className="text-left ml-2">{item?.label}</Label>
                    </FormGroup>
                  </Col>
                );
              })}
              <Col sm={12} md={12}>
                <div className="invalid-document">{errorPaymentMethod}</div>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={12} md={12}>
              <Row>
                {/* <Label lg={4} className="text-left">
                  Catatan Pembayaran
                </Label> */}
                <InputField
                  colWidth={12}
                  inputType="textarea"
                  value={paymentNote}
                  invalid={errorPaymentNote ? true : false}
                  name={'payment_note'}
                  placeholder={'Catatan Pembayaran'}
                  errorMessage={errorPaymentNote ?? ''}
                  disabledFormText={false}
                  formText={(paymentNote?.length >= 0 ? paymentNote?.length : 0) + ' / 500 karakter'}
                  onChange={(e) => {
                    handleChangePaymentNote(e);
                  }}
                ></InputField>
              </Row>
            </Col>
          </Row>
        </div>

        <div className="action mb-3 mt-3">
          <Button
            className="button-cancel"
            onClick={() => {
              cancel();
            }}
          >
            Batal
          </Button>
          <Button
            className="button-action"
            onClick={() => {
              handleSubmit();
            }}
          >
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default GenerateSnapLink;
