import Helper from '@helpers/Helper';
import { map } from 'lodash';

export default class SPKText {
  static create(dataBooking, dataSummary) {
    const contenHeader = this.headerContent(dataBooking);
    const contentBody = this.bodyContent(dataBooking, dataSummary);
    const contentFooter = this.footerContent();

    return contenHeader + contentBody + contentFooter;
  }

  static headerContent(dataBooking) {
    const splitTime = dataBooking?.booking_time ? dataBooking?.booking_time.split('.') : [];
    const bookingTime = splitTime?.length == 2 ? `${splitTime[0]}:${splitTime[1]}` : '';
    let bookingSchedule = dataBooking?.booking_date ?? '';
    bookingSchedule += bookingTime ? ' - ' + bookingTime : '';
    let carType = dataBooking?.user_car?.car_details?.car_model?.brand?.name
      ? dataBooking?.user_car?.car_details?.car_model?.brand?.name + ' '
      : '';
    carType += dataBooking?.user_car?.car_details?.car_model?.model_name
      ? dataBooking?.user_car?.car_details?.car_model?.model_name + ' '
      : '';
    carType += dataBooking?.user_car?.car_details?.variant ? dataBooking?.user_car?.car_details?.variant : '';

    let headerText = '';
    headerText += `*Surat Perintah Kerja Bengkel*\n\n`;
    headerText += `*Kode Booking* : ${dataBooking?.booking_code ?? '-'}`;
    headerText += `\n*Jadwal Booking* : ${!bookingSchedule ? '-' : bookingSchedule}`;
    headerText += `\n*Bengkel* : ${dataBooking?.workshop?.name ?? '-'}`;
    headerText += `\n\n*Jenis Mobil* : ${!carType ? '-' : carType}`;
    headerText += `\n*Nomor Plat* : ${dataBooking?.user_car?.license_plate ?? '-'}`;

    return headerText;
  }

  static bodyContent(dataBooking, dataSummary) {
    const isPKP = dataBooking?.workshop?.is_pkp;
    const packages = dataSummary?.items?.packages;
    const products = dataSummary?.items?.products;
    const services = dataSummary?.items?.services;
    const isFlagship = dataBooking?.workshop?.tier?.value?.includes('flagship') ? true : false;

    let bodyText = '\n';

    if (dataBooking?.booking_type === 'b2c') {
      if (packages?.package_items?.length > 0) {
        bodyText += '\n\n*Paket*';
        map(packages?.package_items, (item) => {
          bodyText += `\n*${item?.name} ${item?.price}*`;
          map(item?.item_details, (subitem) => {
            bodyText += `\n${subitem?.name} Rp ${Helper.formatMoney(subitem?.price)}`;
            bodyText += `\n${subitem?.qty} x Rp ${Helper.formatMoney(subitem?.unit_price)}`;
          });
        });
        bodyText += `\n*Subtotal Paket Rp ${Helper.formatMoney(packages?.subtotal_package)}*`;
      }
    } else {
      if (packages?.package_items?.length > 0) {
        bodyText += '\n\n*Paket*';
        map(packages?.package_items, (item) => {
          bodyText += `\n*${item?.name} Rp ${Helper.formatMoney(item?.price)} ${
            item?.discount?.amount > 0 ? ` - Rp ${Helper.formatMoney(item?.discount?.amount)}` : ''
          }`;
          map(item?.item_details, (subitem) => {
            bodyText += `\n${subitem?.qty} x ${subitem?.name}`;
            bodyText += `\nRp ${Helper.formatMoney(subitem?.total_nett_price)}`;
          });
          bodyText += `${item?.item_details[0]?.note ? '\nKeterangan : ' + item?.item_details[0]?.note ?? '' : ''}`;
        });
        bodyText += `\n*Subtotal Paket Rp ${Helper.formatMoney(packages?.subtotal_package)}*`;
      }
    }

    if (products?.product_items?.length > 0) {
      bodyText += '\n\n*Produk*';
      map(products?.product_items, (item) => {
        bodyText += `\n${item?.name} Rp ${Helper.formatMoney(item?.total_workshop_price)}`;
        bodyText += `\n${item?.qty} x Rp ${Helper.formatMoney(item?.unit_price)}`;
        bodyText += `${item?.remark ? '\nKeterangan : ' + item?.remark ?? '' : ''}`;
      });
      bodyText += `\n*Subtotal Produk Rp ${Helper.formatMoney(products?.subtotal_product_pdf)}*`;
    }

    if (services?.service_items?.length > 0) {
      bodyText += '\n\n*Jasa*';
      map(services?.service_items, (item) => {
        bodyText += `\n${item?.name} Rp ${Helper.formatMoney(item?.total_workshop_price)}`;
        bodyText += `\n${item?.qty} x Rp ${Helper.formatMoney(item?.unit_price)}`;
        bodyText += `${item?.remark ? '\nKeterangan : ' + item?.remark ?? '' : ''}`;
      });
      bodyText += `\n*Subtotal Jasa Rp ${Helper.formatMoney(services?.subtotal_service_pdf)}*`;
    }

    bodyText += `\n\n\n*Sub Total Rp ${Helper.formatMoney(dataSummary?.dpp_spk ?? 0)}*`;
    if (isPKP) bodyText += `\n*PPN Rp ${Helper.formatMoney(dataSummary?.ppn_spk ?? 0)}*`;
    bodyText += `\n*Otoklix Take Rate Rp ${Helper.formatMoney(dataSummary?.take_rate_otoklix ?? 0)}*`;
    bodyText += `\n----------------------------`;
    if (isFlagship) {
      bodyText += `\n*Total Bayar Rp ${Helper.formatMoney(dataSummary?.amount_spk ?? 0)}*`;
      bodyText += `\n----------------------------`;
    } else {
      bodyText += `\n*Total Bayar Rp ${Helper.formatMoney(dataSummary?.amount ?? 0)}*`;
      bodyText += `\n----------------------------`;
    }

    return bodyText;
  }

  static footerContent() {
    let footerText = '';
    footerText += `\n\n\n*Bengkel Wajib Memberikan Foto Kedatangan, Odometer, Proses Pengerjaan & Sparepart Sebagai Bagian dari Dokumen Penagihan.*`;
    footerText += `\n\n\n*Panduan Bengkel Partner Otoklix terdapat di link berikut ini: https://link.otoklix.com/panduanbengkel*`;
    footerText += `\n\n*Syarat & Ketentuan:*`;
    footerText += `\n1. Otoklix tidak bertanggung jawab atas pengerjaan penggantian sparepart atau servis diluar permintaan pada Surat Perintah Kerja Otoklix.`;
    footerText += `\n2. Pembayaran kepada Bengkel akan dilakukan selambat-lambatnya 3 (tiga) hari kerja setelah Final Invoice Bengkel diterima oleh Otoklix.`;
    footerText += `\n3. Surat Perintah Kerja Otoklix bersifat rahasia dan Bengkel tidak diperbolehkan untuk menyebarkan dokumen tersebut kepada pihak manapun.`;
    footerText += `\n4. Nota Penagihan Bengkel hanya dapat diberikan kepada pihak Otoklix dan tidak diperkenankan untuk diberikan kepada pihak lain.`;
    footerText += `\n5. Surat Perintah Kerja ini sah dan diproses oleh komputer. Silakan hubungi Otoklix Customer Service apabila kamu membutuhkan bantuan.`;

    return footerText;
  }
}
