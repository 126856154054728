import React from 'react';
import Wrapper from './Wrapper';

function LoadingWrapper() {
  return (
    <Wrapper className="text-center">
      <div className="spinner-border thumb-sm text-primary"></div>
    </Wrapper>
  );
}

export default LoadingWrapper;
