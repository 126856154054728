import React, { useEffect, useState } from 'react';
import { map, find, filter, forEach } from 'lodash';
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Label, Button, Input, Spinner, Tooltip } from 'reactstrap';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import FsLightbox from 'fslightbox-react';
import userPermission from '@utils/userPermission';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import UploadImage from '@components/uploads/UploadImage';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import InputTagField from '@components/field/InputTagField';
import closeIcon from '@assets/icons/close-emoji.svg';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { useDropzone } from 'react-dropzone';
import FolderIcon from '@assets/icons/folder.svg';
import NoImage from '@assets/images/no-image.jpeg';
import Info from '@assets/icons/info.svg';

const ProductSectionDetail = (props) => {
  const { detailProduct, onResultNotification, onSubmit, productId } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const [imageKey, setImageKey] = useState();
  const [formData, setFormData] = useState({});
  const [modalType, setModalType] = useState('');
  const [errorImage, setErrorImage] = useState('');
  const [optionsCar, setOptionsCar] = useState([]);
  const [imagePreview, setImagePreview] = useState();
  const [optionsBrand, setOptionsBrand] = useState([]);
  const [disableForm, setDisableForm] = useState(true);
  const [showLightbox, setShowLightbox] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [disableBrand, setDisableBrand] = useState(false);
  const [loadingSumbit, setLoadingSubmit] = useState(false);
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [optionsSubCategory, setOptionsSubCategory] = useState([]);
  const [optionsFlagshipCategory, setOptionsFlagshipCategory] = useState([]);
  const [optionsComponent, setOptionsComponent] = useState([]);
  const [loadingFetchOptCar, setLoadingFetchOptCar] = useState(false);
  const [loadingUploadImage, setLoadingUploadImage] = useState(false);
  const [loadingFetchOptBrand, setLoadingFetchOptBrand] = useState(false);
  const [loadingFetchOptCategory, setLoadingFetchOptCategory] = useState(false);
  const [loadingFetchOptSubCategory, setLoadingFetchOptSubCategory] = useState(false);
  const [loadingFetchFlagshipCategory, setLoadingFetchFlagshipCategory] = useState(false);
  const [loadingFetchOptComponent, setLoadingFetchOptComponent] = useState(false);
  const [tags, setTags] = useState([]);
  const [input, setInput] = useState('');
  const [isKeyReleased, setIsKeyReleased] = useState('');
  const [listSegment, setListSegment] = useState([
    {
      compatibilty: [],
      qty: '',
    },
  ]);
  const [filteredArray, setFilteredArray] = useState([]);

  const [productPhoto, setProductPhoto] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [loadingPhotos, setLoadingPhotos] = useState(false);
  const [disableFormPhotos, setDisableFormPhotos] = useState(false);
  const [isEditPhotos, setIsEditPhotos] = useState(false);
  const [galleryLightbox, setGalleryLightbox] = useState([]);
  const [lightboxSlide, setLightboxSlide] = useState(0);
  const [isOpenOnMount, setIsOpenOnMount] = useState(false);
  const [isBig, setIsBig] = useState(false);
  const [infoTooltip, setInfoTooltip] = useState(false);
  const [disableQty, setDisableQty] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('master', menu) || generatePermission('master', 'all'));
  };

  const allowEdit =
    allowPermission('productall') || allowPermission('productdetailall') || allowPermission('productdetailedit');

  const optionsType = [
    {
      value: 'part',
      label: 'Produk',
    },
    {
      value: 'work',
      label: 'Jasa',
    },
  ];

  async function fetchBrandOptions() {
    setLoadingFetchOptBrand(true);
    try {
      const response = await api.get(`v2/intools/master/product-brands/all/?sort=name&order=asc`);
      const brandOptions = map(response?.data?.data, (item) => {
        return { value: item?.brand_id ?? null, label: item?.brand_name ?? '-' };
      });
      setOptionsBrand(brandOptions);
      setLoadingFetchOptBrand(false);
    } catch (error) {
      setOptionsBrand([]);
      setLoadingFetchOptBrand(false);
    }
  }

  async function fetchComponentOptions() {
    setLoadingFetchOptComponent(true);
    try {
      const response = await api.get(`v2/intools/packages/components`);
      const componentOptions = map(response?.data?.data?.components, (item) => {
        return { value: item?.id ?? null, label: item?.name ?? '-' };
      });
      setOptionsComponent(componentOptions);
      setLoadingFetchOptComponent(false);
    } catch (error) {
      setOptionsComponent([]);
      setLoadingFetchOptComponent(false);
    }
  }

  async function fetchCategoryOptions() {
    setLoadingFetchOptCategory(true);
    try {
      const response = await api.get(`v2/intools/products/categories/`);
      const categoryOptions = map(response?.data?.data?.categories, (item) => {
        return { value: item?.id, label: item?.name ?? '-' };
      });
      setOptionsCategory(categoryOptions);
      setLoadingFetchOptCategory(false);
    } catch (error) {
      setOptionsCategory([]);
      setLoadingFetchOptCategory(false);
    }
  }

  async function fetchSubCategoryOptions(categoryId) {
    setLoadingFetchOptSubCategory(true);
    try {
      const response = await api.get(`v2/intools/master/product-sub-categories/?product_category_id=${categoryId}`);
      const subCategoryOptions = map(response?.data?.data, (item) => {
        return { value: item?.id, label: item?.name ?? '-' };
      });
      setOptionsSubCategory(subCategoryOptions);
      setLoadingFetchOptSubCategory(false);
    } catch (error) {
      setOptionsSubCategory([]);
      setLoadingFetchOptSubCategory(false);
    }
  }

  async function fetchFlagshipCategoryOptions() {
    setLoadingFetchFlagshipCategory(true);
    try {
      const response = await api.get(`v2/intools/master/flagship-report-categories/`);
      const categoryOptions = map(response?.data?.data, (item) => {
        return { value: item?.id, label: item?.name ?? '-' };
      });
      setOptionsFlagshipCategory(categoryOptions);
      setLoadingFetchFlagshipCategory(false);
    } catch (error) {
      setOptionsFlagshipCategory([]);
      setLoadingFetchFlagshipCategory(false);
    }
  }

  async function fetchCarOptions() {
    setLoadingFetchOptCar(true);
    try {
      const response = await api.get(`v2/intools/master/cars/all/`);
      const carOptions = map(response?.data?.data, (item) => {
        const car_model = item?.car_model ?? '';
        const car_variant = item?.car_variant ?? '';
        const car_brand = item?.car_brand ?? '';
        let car_id = String.fromCharCode((item?.car_id ?? 1) + 20);
        return {
          value: car_brand + car_id,
          label: car_model + ' - ' + car_variant + ' ' + item?.car_fuel,
          id: item?.car_id,
        };
      });
      setOptionsCar(carOptions);
      setLoadingFetchOptCar(false);
    } catch (error) {
      setOptionsCar([]);
      setLoadingFetchOptCar(false);
    }
  }

  const handleAddPhotos = (keyFile, preview) => {
    const setOrderValue = filter(productPhoto, (item) => !item?.deleted);
    const photo = {
      id: null,
      order: setOrderValue?.length + 1,
      image_link: preview,
      key: keyFile,
      deleted: false,
    };

    setProductPhoto([...productPhoto, photo]);
  };

  const handleUploadPhotos = (file, preview) => {
    setDisableFormPhotos(true);
    handleLoadingUploadImage(true);

    if (file?.name) {
      const paramsImagedUpload = new FormData();
      paramsImagedUpload.append('namespace', 'master_product_image');
      paramsImagedUpload.append('identifier', productId);
      paramsImagedUpload.append('image', file);

      api
        .post(`v2/intools/image-upload/`, paramsImagedUpload)
        .then((response) => {
          const keyFile = response?.data?.data?.key ?? '';
          if (keyFile !== '') {
            handleAddPhotos(keyFile, preview);
            handleLoadingUploadImage(false);
            setDisableFormPhotos(false);
          } else {
            handleLoadingUploadImage(false);
            handleFailUploadPhotos('Gagal upload image');
          }
        })
        .catch((e) => {
          const message = `API Error : ${e?.response?.data?.error?.message}`;
          handleFailUploadPhotos(message);
          handleLoadingUploadImage(false);
        });
    } else {
      handleFailUploadPhotos('Gagal upload image, file tidak diketahui');
    }
  };

  const handleFilterOptionsCar = (array, filter) => {
    const filteredArray = array.filter((dataItem) => {
      return !filter.some((filterItem) => filterItem.value === dataItem.value);
    });
    return filteredArray;
  };

  const setDefaultData = () => {
    let defaultCars = [];
    let selectedCars = [];

    fetchSubCategoryOptions(detailProduct?.product_category_id);

    const selectedType =
      detailProduct?.product_type == 'part'
        ? { value: 'part', label: 'Produk' }
        : detailProduct?.product_type == 'work'
        ? { value: 'work', label: 'Jasa' }
        : null;
    const selectedBrand =
      detailProduct?.product_brand_id && detailProduct?.product_brand_name
        ? {
            value: Number(detailProduct?.product_brand_id)
              ? Number(detailProduct?.product_brand_id)
              : detailProduct?.product_brand_id,
            label: detailProduct?.product_brand_name,
          }
        : null;
    const selectedComponent =
      detailProduct?.product_component?.id && detailProduct?.product_component?.name
        ? {
            value: Number(detailProduct?.product_component?.id)
              ? Number(detailProduct?.product_component?.id)
              : detailProduct?.product_component?.id,
            label: detailProduct?.product_component?.name,
          }
        : null;
    const selectedCategory =
      detailProduct?.product_category_id && detailProduct?.product_category_name
        ? {
            value: Number(detailProduct?.product_category_id)
              ? Number(detailProduct?.product_category_id)
              : detailProduct?.product_category_id,
            label: detailProduct?.product_category_name,
          }
        : null;
    const selectedSubCategory =
      detailProduct?.product_subcategory_id && detailProduct?.product_subcategory
        ? {
            value: Number(detailProduct?.product_subcategory_id)
              ? Number(detailProduct?.product_subcategory_id)
              : detailProduct?.product_subcategory_id,
            label: detailProduct?.product_subcategory,
          }
        : null;
    const selectedFlagshipCategory =
      detailProduct?.flagship_report_category_id && detailProduct?.flagship_report_category
        ? {
            value: Number(detailProduct?.flagship_report_category_id)
              ? Number(detailProduct?.flagship_report_category_id)
              : detailProduct?.flagship_report_category_id,
            label: detailProduct?.flagship_report_category,
          }
        : null;

    detailProduct?.product_car_compatibility?.length &&
      detailProduct?.product_car_compatibility.forEach((item) => {
        const car_model = item?.car_model ?? '';
        const car_variant = item?.car_variant ?? '';
        const car_brand = item?.car_brand ?? '';
        const objDefaultCars = {
          value: car_brand + item?.car_id,
          label: car_model + ' - ' + car_variant,
          id: item?.car_id,
          compatibilty_id: item?.car_compatibility_id ?? null,
        };
        const objSelectedCars = {
          value: car_brand + item?.car_id,
          label: car_model + ' - ' + car_variant,
          id: item?.car_id,
        };

        defaultCars.push(objDefaultCars);
        selectedCars.push(objSelectedCars);
      });

    const dataForm = {
      id: detailProduct?.product_id ?? '',
      sku: detailProduct?.product_sku ?? '',
      default_cars: defaultCars,
      cars: selectedCars,
      type: selectedType,
      price: Helper.formatMoneyOnField('' + detailProduct?.product_price, 'Rp '),
      brand: selectedBrand,
      name: detailProduct?.product_name ?? '',
      display: detailProduct?.product_display_name ?? '',
      alias: detailProduct?.alias ?? '',
      desc:
        detailProduct?.product_description !== ''
          ? reConstructDescription(detailProduct?.product_description, 'get')
          : '',
      is_fbo: detailProduct?.is_fbo == 'active' ? true : false,
      category: selectedCategory,
      sub_category: selectedSubCategory,
      flagship_category: selectedFlagshipCategory,
      component: selectedComponent,
      error_price: '',
      error_brand: '',
      error_name: '',
      error_display: '',
      error_desc: '',
      error_category: '',
      error_sub_category: '',
      error_flagship_category: '',
      error_type: '',
      error_component: '',
    };

    setDisableBrand(detailProduct?.product_type == 'work' ? true : false);
    setFormData(dataForm);
    const tempSegmentList = detailProduct?.product_car_compatibility.map((item) => {
      if (item?.length) {
        const itemList = item?.map((itemChild) => {
          return {
            value: `${itemChild?.car_brand + itemChild?.car_id}`,
            label: `${itemChild?.car_model ? itemChild?.car_model + ' - ' : ''} ${itemChild?.car_variant} ${
              itemChild?.car_fuel
            }`,
            id: itemChild?.car_id,
            ...itemChild,
          };
        });
        return {
          compatibilty: itemList,
          qty: item?.[0]?.compatibility_qty,
          error: false,
          oil_qty: item?.[0]?.compatibility_qty_modifier === 'oil_qty',
        };
      }
    });
    setListSegment(tempSegmentList);
    setImageKey(null);
    setImagePreview(detailProduct?.product_image_link ?? '');
    setProductPhoto(detailProduct?.product_image_link);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (key === 'Enter' && trimmedInput.length && !formData?.alias.includes(trimmedInput)) {
      const tagsCopy = formData?.alias;
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setChangeValueForm('alias', [...tagsCopy, trimmedInput]);
      setInput('');
    }

    if (key === 'Backspace' && !input.length && formData?.alias?.length && isKeyReleased) {
      const tagsCopy = formData?.alias;
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setChangeValueForm('aslias', tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index) => {
    if (!disableForm) {
      const updateData = formData?.alias.filter((tag, i) => i !== index);
      setChangeValueForm('alias', updateData);
    }
  };

  const setChangeValueForm = (key, value, errorKey, errorMsg) => {
    formData[key] = value;
    if (errorKey) formData[errorKey] = errorMsg;
    setFormData({ ...formData });
  };

  const handleChangeSKU = (e) => {
    setChangeValueForm('sku', e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : formData?.sku);
  };

  const handleChangeBrand = (e) => {
    validateBrand(e?.value ? e : null);
  };

  const handleChangeProductName = (e) => {
    validateProductName(e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : formData?.name);
  };

  const handleChangeDisplayName = (e) => {
    validateDisplayName(e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : formData?.display);
  };

  const handleChangeAlias = (e) => {
    setChangeValueForm('alias', e);
  };

  const handleChangeComponent = (e) => {
    validateComponent(e?.value ? e : null);
  };

  const handleChangeCategory = (e) => {
    validateCategory(e?.value ? e : null);
  };

  const handleChangeSubCategory = (e) => {
    validateSubCategory(e?.value ? e : null);
  };

  const handleChangeFlagshipCategory = (e) => {
    validateFlagshipCategory(e?.value ? e : null);
  };

  const handleChangeType = (e) => {
    validateType(e?.value ? e : null);
  };

  const handleChangeProductPrice = (e) => {
    validateProductPrice(Helper.formatMoneyOnField(e?.target?.value, 'Rp '));
  };

  const handleChangeCars = (e) => {
    setChangeValueForm('cars', e);
  };

  const handleChangeDescription = (e) => {
    const { value } = e.target;
    const putValue = value !== ' ' ? value.replace(/  +/g, ' ') : formData?.desc;

    validateDescription(putValue?.length < 2 ? `\u2022 ${putValue}` : putValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      validateDescription(e.target.value !== ' ' ? `${e.target.value.replace(/  +/g, ' ')}\n\u2022 ` : formData?.desc);
    }
  };

  const handleChangeFBO = () => {
    setChangeValueForm('is_fbo', !formData?.is_fbo);
  };

  const validateBrand = (value) => {
    setChangeValueForm('brand', value, 'error_brand', !value?.value ? 'Pilih Brand terlebih dahulu' : '');
    return !value?.value ? false : true;
  };

  const validateProductName = (value) => {
    let errorMsg = '';
    if (value.length == 0) {
      errorMsg = 'Nama Produk tidak boleh kosong';
    } else if (value.length < 4 || value.length > 100) {
      errorMsg = 'Format Nama Produk minimal 4, maksimal 100 karakter';
    }
    setChangeValueForm('name', value, 'error_name', errorMsg);
    return errorMsg ? false : true;
  };

  const validateDisplayName = (value) => {
    let errorMsg = '';
    if (value.length == 0) {
      errorMsg = 'Display Name tidak boleh kosong';
    } else if (value.length < 4 || value.length > 100) {
      errorMsg = 'Format Display Name minimal 4, maksimal 100 karakter';
    }
    setChangeValueForm('display', value, 'error_display', errorMsg);
    return errorMsg ? false : true;
  };

  const validateComponent = (value) => {
    setChangeValueForm('component', value, 'error_component', !value?.value ? 'Pilih Komponen terlebih dahulu' : '');
    return !value?.value ? false : true;
  };

  const validateCategory = (value) => {
    setChangeValueForm('category', value, 'error_category', !value?.value ? 'Pilih Category terlebih dahulu' : '');
    return !value?.value ? false : true;
  };

  const validateSubCategory = (value) => {
    setChangeValueForm(
      'sub_category',
      value,
      'error_sub_category',
      !value?.value ? 'Pilih Sub Category terlebih dahulu' : ''
    );
    return !value?.value ? false : true;
  };

  const validateFlagshipCategory = (value) => {
    setChangeValueForm(
      'flagship_category',
      value,
      'error_flagship_category',
      !value?.value ? 'Pilih Flagship Category terlebih dahulu' : ''
    );
    return !value?.value ? false : true;
  };

  const validateType = (value) => {
    setChangeValueForm('type', value, 'error_type', !value?.value ? 'Pilih Tipe terlebih dahulu' : '');
    return !value?.value ? false : true;
  };

  const validateProductPrice = (value) => {
    setChangeValueForm(
      'price',
      value,
      'error_price',
      value?.formatMoney > 99999999 ? 'Harga Produk maksimal Rp 99.999.999' : ''
    );
    return value?.formatMoney > 99999999 ? false : true;
  };

  const validateDescription = (value) => {
    setChangeValueForm('desc', value, 'error_desc', value.length > 500 ? 'Format Deskripsi maksimal 500 karakter' : '');

    return value.length > 500 ? false : true;
  };

  const validateCarCompatibility = (value) => {
    let tempSegmentList;
    const removeEmptyData = value.filter((item) => +item?.compatibilty?.length > 0);
    tempSegmentList = removeEmptyData.map((item) => {
      return {
        ...item,
        error: +item?.qty === 0,
      };
    });
    setListSegment(tempSegmentList);
    const checkError = tempSegmentList.filter((item) => item?.error);
    if (+checkError?.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleLoadingUploadImage = (status) => {
    setLoadingUploadImage(status);
    setLoadingPhotos(status);
  };

  const handleErrorUploadImage = (msg) => {
    setErrorImage(msg);
  };

  const handlePreviewImage = () => {
    if (imagePreview) {
      setShowLightbox(!showLightbox);
    }
  };

  const handleDeleteImage = () => {
    setImageKey(null);
    setImagePreview('');
  };

  const handleUploadImage = (image) => {
    setImageKey(image.key);
    setImagePreview(image.preview);
  };

  const editForm = () => {
    setDisableForm(false);
    setDisableFormPhotos(false);
    setLoadingUploadImage(false);
    setIsEditPhotos(true);
  };

  const cancelEditForm = () => {
    setModalType('Batalkan');
    setConfirmModal(true);
  };

  const handleSubmit = () => {
    const passValidateType = validateType(formData?.type);
    const passValidateBrand = validateBrand(formData?.brand);
    const passValidateCategory = validateCategory(formData?.category);
    const passValidateSubCategory = validateSubCategory(formData?.sub_category);
    const passValidateComponent = validateComponent(formData?.component);
    const passValidateProductName = validateProductName(formData?.name);
    const passValidateDisplayName = validateDisplayName(formData?.display);
    const passValidateProductPrice = validateProductPrice(formData?.price);
    const passValidateDescription = validateDescription(formData?.desc);
    const passValidateCarCompatibilty = validateCarCompatibility(listSegment);
    const isValid =
      passValidateType &&
      passValidateBrand &&
      passValidateCategory &&
      passValidateSubCategory &&
      passValidateComponent &&
      passValidateProductName &&
      passValidateDisplayName &&
      passValidateProductPrice &&
      passValidateDescription &&
      passValidateCarCompatibilty;

    if (isValid) {
      setModalType('Simpan');
      setConfirmModal(true);
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
  };

  const handleResultNotification = (status, type, message) => {
    onResultNotification({ status: status, type: type, message: message });
  };

  const getPayloadSelectedCars = () => {
    let selectedCars = [];

    listSegment?.length &&
      listSegment.forEach((item) => {
        item?.compatibilty.forEach((itemChild) => {
          selectedCars.push({
            id: itemChild?.car_compatibility_id ?? null,
            car_details_id: itemChild?.id,
            qty: item?.qty,
            is_deleted: false,
            qty_modifier: detailProduct?.qty_modifier,
          });
        });
      });

    detailProduct?.product_car_compatibility?.length &&
      detailProduct?.product_car_compatibility.forEach((item) => {
        item.forEach((itemChild) => {
          const selected = find(selectedCars, (itemCar) => {
            return +itemChild?.car_compatibility_id === +itemCar?.id;
          });
          if (!selected) {
            selectedCars.push({
              id: itemChild?.car_compatibility_id ?? null,
              car_details_id: itemChild?.car_id,
              qty: itemChild?.compatibility_qty,
              is_deleted: true,
            });
          }
        });
      });

    return selectedCars;
  };

  const remapImageLinksPayload = (array) => {
    let arr = [];
    array.map((image, index) => {
      arr.push({
        id: image?.id,
        image_link: image?.key ?? Helper.removeDomain(image?.image_link),
        order: index + 1 ?? image?.order,
        is_deleted: image?.deleted ?? false,
      });
    });

    return arr;
  };

  const reConstructDescription = (text, method = 'put') => {
    if (method === 'get') {
      const bulletPoint = '• ';
      const changes = text
        .split('|')
        .map((str, index) => {
          if (index === 0) {
            return bulletPoint + str;
          } else {
            return '\n' + bulletPoint + str;
          }
        })
        .join('');

      return changes;
    }

    if (method === 'put') {
      let changes = text?.replace(/• /g, '')?.replace(/\n/g, '|');
      const checkLastIndex = changes.lastIndexOf('|');

      if (checkLastIndex === changes.length - 1) {
        changes = changes.substring(0, checkLastIndex);
      }

      return changes;
    }
  };

  const getPayload = () => {
    let payload = {
      product_sku: formData?.sku ?? null,
      product_brand_id: formData?.brand?.value ? `${formData?.brand?.value}` : null,
      product_brand_name: formData?.brand?.label ?? null,
      product_name: formData?.name ?? null,
      product_display_name: formData?.display ?? null,
      alias: formData?.alias ?? null,
      product_component_id: formData?.component?.value ? `${formData?.component?.value}` : null,
      product_component_name: formData?.component?.label ?? null,
      product_category_id: formData?.category?.value ? `${formData?.category?.value}` : null,
      product_subcategory_id: formData?.sub_category?.value ? `${formData?.sub_category?.value}` : null,
      flagship_report_category_id: formData?.flagship_category?.value ? `${formData?.flagship_category?.value}` : null,
      product_category_name: formData?.category?.label ?? null,
      product_type: formData?.type?.value ?? null,
      product_price: formData?.price?.formatMoney ?? 0,
      product_car_compatibility: getPayloadSelectedCars(),
      product_images: remapImageLinksPayload(productPhoto),
      is_fbo: formData?.is_fbo ? 'active' : 'inactive',
    };

    if (formData?.desc === '\u2022 ' || formData?.desc === '• ') {
      payload.product_description = '';
    } else {
      payload.product_description = reConstructDescription(formData?.desc);
    }

    return payload;
  };

  async function updateDetailProduct() {
    setConfirmModal(false);

    setLoadingSubmit(true);

    try {
      const response = await api.put(`v2/intools/master/products/${formData?.id}/`, getPayload());
      onSubmit(true);
      setDisableForm(true);
      setDisableFormPhotos(true);
      setLoadingSubmit(false);
    } catch (error) {
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoadingSubmit(false);
    }
  }

  const restoreDetailProduct = () => {
    setDefaultData();
    setDisableForm(true);
    setDisableFormPhotos(true);
    setConfirmModal(false);
    setLoadingUploadImage(false);
    setIsEditPhotos(false);
  };

  const handleDropFilePhotos = (data) => {
    const file = data;
    const maxSize = 5000;
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const allowTypes = ['jpeg', 'jpg', 'png'];
    const nameFileSplit = file?.name ? file?.name.split('.') : [];

    if (nameFileSplit.length == 0) {
      setErrorImage('Format file tidak diketahui');
    } else {
      const selected = find(allowTypes, (item) => {
        return nameFileSplit[nameFileSplit.length - 1].toLowerCase() === item;
      });
      if (!selected) {
        setErrorImage('File tidak sesuai dengan format');
      } else {
        if (fileSize == null) {
          setErrorImage('Ukuran file tidak diketahui');
        } else if (fileSize > maxSize) {
          setErrorImage('Ukuran file maksimal 5MB');
        } else {
          const previewPhoto = URL.createObjectURL(file);
          handleUploadPhotos(file, previewPhoto);
        }
      }
    }
  };

  const handleDeletePhoto = (index) => {
    productPhoto[index].deleted = true;
    setProductPhoto([...productPhoto]);
  };

  const handleSortProductPhotos = ({ oldIndex, newIndex }) => {
    if (isEditPhotos) {
      const productNewPosition = arrayMove(productPhoto, oldIndex, newIndex);
      setProductPhoto(productNewPosition);
    }
  };

  const handlePreviewPhoto = (index) => {
    if (!isEditPhotos) {
      setLightboxSlide(index + 1);
      setIsOpenOnMount(true);

      const previewProductPhoto = productPhoto?.map((image) => image.image_link);
      setGalleryLightbox(previewProductPhoto);
      setShowLightbox(!showLightbox);
    }
  };

  const handleDragOverPhotos = (status) => {
    setIsDragOver(status);
  };

  const handleFailUploadPhotos = (msg) => {
    setDisableFormPhotos(false);
    setErrorImage(msg);
    handleLoadingUploadImage(false);
  };

  const handleActionPhotos = (type) => {
    if (isEditPhotos) {
      if (type == 'cancel') {
        defaultDataPhotos();
        setIsEditPhotos(false);
        handleResultNotification(false);
        if (!isEditVideos) {
          onChangeAction(false, 'gallery');
        }
      } else {
        setDisableFormPhotos(true);
        defaultActionUploadPhotos();
        submitGallery('submit-photo');
      }
    } else {
      setIsEditPhotos(true);
      onChangeAction(true, 'gallery');
    }
  };

  const SortableItemPhoto = SortableElement(({ item, keyIndex }) => (
    <div className="box" onClick={() => handlePreviewPhoto(keyIndex)}>
      {isEditPhotos && <div className="icon-trash" onClick={() => handleDeletePhoto(keyIndex)}></div>}
      {keyIndex == 0 && <div className="icon-primary" onClick={() => handleDeletePhoto(keyIndex)}></div>}
      <img className={isEditPhotos ? 'all-scroll' : ''} src={item.image_link} />
    </div>
  ));

  const handleAddSegment = () => {
    const defaulSegment = {
      compatibilty: [],
      qty: 1,
    };
    if (+listSegment?.length === 0) {
      setListSegment([...listSegment, { ...defaulSegment, qty: '' }, defaulSegment]);
    } else {
      setListSegment([...listSegment, defaulSegment]);
    }
  };

  const handleRemoveSegment = (index) => {
    const list = [...listSegment];
    list.splice(index, 1);
    setListSegment(list);
  };

  const checkSameQty = (value) => {
    const result = listSegment.filter((obj) => +obj?.qty === +value);
    if (+result?.length > 0) return true;
    return false;
  };

  const handleChangeSegment = (value, index, type) => {
    const tempSegmentList = [...listSegment];
    let updatedData = { ...tempSegmentList[index] };

    if (type === 'compatibility') {
      updatedData = { ...listSegment[index], compatibilty: value, error: false };
    }
    if (type === 'qty') {
      const val = Helper.inputNumber(value, 999);
      if (!checkSameQty(val)) {
        if (+val === 0) {
          handleResultNotification(true, 'fail', 'Mohon masukan Jumlah Qty lebih dari 0');
          updatedData = { ...listSegment[index], qty: '', error: false };
        } else {
          updatedData = { ...listSegment[index], qty: val, error: false };
        }
      } else {
        handleResultNotification(true, 'fail', 'Mohon masukan Jumlah Qty yang berbeda');
      }
    }
    tempSegmentList[index] = updatedData;
    setListSegment([...tempSegmentList]);
  };

  const UploadPhotos = (props) => {
    const { lists, dragged, error, process } = props;
    const activePhotos = lists.filter((obj) => !obj.deleted);
    const { getRootProps } = useDropzone({
      multiple: false,
      disabled: disableFormPhotos,
      onDrop: (files) => {
        handleDragOverPhotos(false);

        handleDropFilePhotos(files[0]);
      },
      onDragOver: () => {
        setErrorImage('');
        handleDragOverPhotos(true);
      },
      onDragLeave: () => {
        handleDragOverPhotos(false);
      },
      onFileDialogOpen: () => {
        setErrorImage('');
      },
    });

    return (
      <div>
        {isEditPhotos && activePhotos.length < 10 && (
          <div>
            {!process && (
              <div>
                {dragged && (
                  <div {...getRootProps({ className: 'box-upload drag-over' })}>
                    <img src={FolderIcon}></img>
                    <div>
                      <div className="title mt-3">Release File</div>
                      <div className="detail mt-2">Maximum file size 5MB.</div>
                      <div className="detail">jpeg, jpg, png</div>
                    </div>
                  </div>
                )}
                {!dragged && (
                  <div {...getRootProps({ className: 'box-upload' })}>
                    <img src={FolderIcon}></img>
                    <div>
                      <div className="title mt-3">Click or drag file to this area to upload</div>
                      <div className="detail mt-2">Maximum file size 5MB.</div>
                      <div className="detail">jpeg, jpg, png</div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {process && (
              <div {...getRootProps({ className: 'box-upload' })}>
                <img src={FolderIcon}></img>
                <div>
                  <div className="title mt-3">Please wait...</div>
                  <div className="detail mt-2">Maximum file size 5MB.</div>
                  <div className="detail">jpeg, jpg, png</div>
                </div>
              </div>
            )}

            <div className="invalid-document">{error}</div>
          </div>
        )}
        {!isEditPhotos && activePhotos.length == 0 && (
          <div className="box">
            <img src={NoImage} />
          </div>
        )}
      </div>
    );
  };

  const SortableListPhotos = SortableContainer(({ lists }) => {
    return (
      <div className="gallery-container-workshop">
        {lists?.map((item, index) => {
          if (!item?.deleted) {
            return (
              <SortableItemPhoto
                disabled={!isEditPhotos || disableFormPhotos}
                key={Math.random()}
                keyIndex={index}
                index={index}
                item={item}
              />
            );
          }
        })}

        <UploadPhotos lists={lists} dragged={isDragOver} error={errorImage} process={loadingPhotos}></UploadPhotos>
      </div>
    );
  });

  const getWindowSize = () => {
    if (window) {
      if (window.innerWidth > 1279) setIsBig(true);
    }
  };

  useEffect(() => {
    if (+optionsCar.length > 0 && +listSegment.length > 0) {
      let filteredCar = [];
      +listSegment?.length > 0 &&
        listSegment?.forEach((item) => {
          item?.compatibilty?.forEach((itemChild) => {
            filteredCar.push({ ...itemChild });
          });
        });
      setFilteredArray(filteredCar);
    }
  }, [listSegment, optionsCar]);

  useEffect(() => {
    if (formData?.category?.value !== undefined) fetchSubCategoryOptions(formData?.category?.value);
  }, [formData?.category?.value]);

  useEffect(() => {
    setDefaultData();
    getWindowSize();
    if (detailProduct?.qty_modifier === 'oil_qty' || detailProduct?.product_type === 'work') setDisableQty(true);
  }, [detailProduct]);

  useEffect(() => {
    fetchCarOptions();
    fetchBrandOptions();
    fetchCategoryOptions();
    fetchFlagshipCategoryOptions();
    fetchComponentOptions();
  }, []);

  return (
    <React.Fragment>
      <FsLightbox
        toggler={showLightbox}
        sources={galleryLightbox}
        type="image"
        openOnMount={isOpenOnMount}
        slide={lightboxSlide}
      />
      <Card>
        <CardHeader className="bg-primary text-white mt-0">Product Detail</CardHeader>
        <CardBody>
          <Col lg={12}>
            <FormGroup row>
              <Label lg={3}>Product ID</Label>
              <InputField
                colWidth={9}
                inputType={'text'}
                disabled={true}
                placeholder="Product ID"
                value={formData?.id ?? ''}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Part Number</Label>
              <InputField
                colWidth={9}
                inputType={'text'}
                disabled={disableForm || loadingSumbit}
                placeholder="Part Number"
                value={formData?.sku ?? ''}
                onChange={handleChangeSKU}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Tipe
              </Label>
              <SelectField
                colWidth={9}
                name="type"
                placeholder="Pilih Tipe"
                closeMenuOnSelect={true}
                value={formData?.type}
                options={optionsType}
                disabled={true}
                errorMessage={formData?.error_type}
                onChange={handleChangeType}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Brand
              </Label>
              <SelectField
                colWidth={9}
                name="brand"
                placeholder="Pilih Brand"
                closeMenuOnSelect={true}
                value={formData?.brand}
                options={optionsBrand}
                disabled={disableForm || disableBrand || loadingSumbit}
                isLoading={loadingFetchOptBrand}
                errorMessage={formData?.error_brand}
                onChange={handleChangeBrand}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Nama Produk
              </Label>
              <InputField
                colWidth={9}
                inputType={'text'}
                disabled={disableForm || loadingSumbit}
                placeholder="Nama Produk"
                value={formData?.name ?? ''}
                invalid={formData?.error_name ? true : false}
                errorMessage={formData?.error_name ?? ''}
                onChange={handleChangeProductName}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Display Name
              </Label>
              <InputField
                colWidth={9}
                inputType={'text'}
                disabled={disableForm || loadingSumbit}
                placeholder="Display Name"
                value={formData?.display ?? ''}
                invalid={formData?.error_display ? true : false}
                errorMessage={formData?.error_display ?? ''}
                onChange={handleChangeDisplayName}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Nama Alternatif (Alias)</Label>
              <InputTagField
                colWidth={9}
                name="alias"
                placeholder="Masukkan kata kunci lalu klik `Enter`"
                value={formData?.alias ?? []}
                allowDuplication={true}
                disabled={disableForm || loadingSumbit}
                onChange={handleChangeAlias}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Komponen
              </Label>
              <SelectField
                colWidth={9}
                name="component"
                placeholder="Pilih Komponen"
                closeMenuOnSelect={true}
                value={formData?.component}
                options={optionsComponent}
                disabled={disableForm || loadingSumbit}
                isLoading={loadingFetchOptComponent}
                errorMessage={formData?.error_component}
                onChange={handleChangeComponent}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Kategori
              </Label>
              <SelectField
                colWidth={9}
                name="category"
                placeholder="Pilih Kategori"
                closeMenuOnSelect={true}
                value={formData?.category}
                options={optionsCategory}
                disabled={disableForm || loadingSumbit}
                isLoading={loadingFetchOptCategory}
                errorMessage={formData?.error_category}
                onChange={handleChangeCategory}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Sub Kategori
              </Label>
              <SelectField
                colWidth={9}
                name="category"
                placeholder="Pilih Sub Kategori"
                closeMenuOnSelect={true}
                value={formData?.sub_category}
                options={optionsSubCategory}
                disabled={disableForm || loadingSumbit}
                isLoading={loadingFetchOptSubCategory}
                errorMessage={formData?.error_sub_category}
                onChange={handleChangeSubCategory}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Flagship Report Category</Label>
              <SelectField
                colWidth={9}
                name="category"
                placeholder="Pilih Flagship Report Category"
                closeMenuOnSelect={true}
                value={formData?.flagship_category}
                options={optionsFlagshipCategory}
                disabled={disableForm || loadingSumbit}
                isLoading={loadingFetchFlagshipCategory}
                onChange={handleChangeFlagshipCategory}
                isClearable
              />
            </FormGroup>
            {!disableQty ? (
              <FormGroup row className={+listSegment?.length === 0 ? 'mb-0' : ''}>
                <Label lg={3}>
                  <div className="ml-3">
                    {disableQty ? (
                      <>
                        <img className="icon-quest" id="InfoTooltip" src={Info} width="14" />
                      </>
                    ) : (
                      <></>
                    )}
                    Car Compability
                  </div>
                </Label>
                <SelectField
                  colWidth={6}
                  isMulti={true}
                  isClearable
                  name="cars"
                  placeholder="Pilih Car Compability"
                  closeMenuOnSelect={false}
                  isSelectAll={true}
                  value={listSegment?.[0]?.compatibilty}
                  options={handleFilterOptionsCar(optionsCar, filteredArray)}
                  disabled={disableForm || loadingSumbit}
                  isLoading={loadingFetchOptCar}
                  onChange={(e) => handleChangeSegment(e, 0, 'compatibility')}
                  titlePreview={'Car Compability'}
                  placeholderPreview={'Tipe Mobil'}
                  labelDataPreview={'Tipe Mobil'}
                />
                <Label lg={1} className="px-0">
                  Qty
                </Label>
                <InputField
                  colWidth={2}
                  inputType={'text'}
                  disabled={disableForm || loadingSumbit || disableQty}
                  placeholder="Qty"
                  value={listSegment?.[0]?.qty ?? ''}
                  onChange={(e) => handleChangeSegment(e.target.value, 0, 'qty')}
                  invalid={listSegment?.[0]?.error}
                  errorMessage={listSegment?.[0]?.error ? 'Qty tidak boleh 0' : ''}
                />
              </FormGroup>
            ) : (
              <>
                <FormGroup row className={+listSegment?.length === 0 ? 'mb-0' : ''}>
                  <Label lg={3}>
                    <div className="div-icon-quest">
                      {disableQty ? (
                        <div id="InfoTooltip">
                          {detailProduct?.product_type === 'part' ? <img src={Info} width="14" /> : <></>}
                        </div>
                      ) : (
                        <></>
                      )}
                      Car Compability
                    </div>
                  </Label>
                  <SelectField
                    colWidth={9}
                    isMulti={true}
                    isClearable
                    name="cars"
                    placeholder="Pilih Car Compability"
                    closeMenuOnSelect={false}
                    isSelectAll={true}
                    value={listSegment?.[0]?.compatibilty}
                    options={handleFilterOptionsCar(optionsCar, filteredArray)}
                    disabled={disableForm || loadingSumbit}
                    isLoading={loadingFetchOptCar}
                    onChange={(e) => handleChangeSegment(e, 0, 'compatibility')}
                    titlePreview={'Car Compability'}
                    placeholderPreview={'Tipe Mobil'}
                    labelDataPreview={'Tipe Mobil'}
                  />
                </FormGroup>
              </>
            )}
            {!disableQty ? (
              <>
                {listSegment.slice(1, 19).map((item, index) => (
                  <FormGroup
                    row
                    key={index}
                    className={
                      +listSegment?.length - 1 === +index
                        ? 'input-segment position-relative mb-0'
                        : 'input-segment position-relative'
                    }
                  >
                    <Label lg={3} />
                    <SelectField
                      colWidth={6}
                      isMulti={true}
                      isClearable
                      name="cars"
                      placeholder="Pilih Car Compability"
                      closeMenuOnSelect={false}
                      isSelectAll={true}
                      value={item?.compatibilty}
                      options={handleFilterOptionsCar(optionsCar, filteredArray)}
                      disabled={disableForm || loadingSumbit}
                      isLoading={loadingFetchOptCar}
                      onChange={(e) => handleChangeSegment(e, +index + 1, 'compatibility')}
                      titlePreview={'Car Compability'}
                      placeholderPreview={'Tipe Mobil'}
                      labelDataPreview={'Tipe Mobil'}
                    />
                    <Label lg={1} className="px-0">
                      Qty
                    </Label>
                    <InputField
                      colWidth={2}
                      inputType={'text'}
                      disabled={disableForm || loadingSumbit || disableQty}
                      placeholder="Qty"
                      value={item?.qty ?? ''}
                      onChange={(e) => handleChangeSegment(e.target.value, +index + 1, 'qty')}
                      invalid={item?.error}
                      errorMessage={item?.error ? 'Qty tidak boleh 0' : ''}
                    />
                    {!disableForm ? (
                      <img
                        src={closeIcon}
                        className="cursor-pointer"
                        height={16}
                        alt=""
                        onClick={() => handleRemoveSegment(+index + 1)}
                      />
                    ) : (
                      <></>
                    )}
                  </FormGroup>
                ))}
              </>
            ) : (
              ''
            )}

            <FormGroup row>
              {!disableForm && +listSegment?.length < 19 && !loadingSumbit && !disableQty ? (
                <Label lg={12} className="m-0 p-0">
                  <div className="card-add-segment cursor-pointer p-1 m-2" onClick={() => handleAddSegment()}>
                    <span className="text-center p-2">+ Tambah Segment</span>
                  </div>
                </Label>
              ) : (
                <></>
              )}
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Deskripsi</Label>
              <InputField
                colWidth={9}
                inputType={'textarea'}
                disabled={disableForm || loadingSumbit}
                placeholder="Deskripsi"
                value={formData?.desc ?? ''}
                invalid={formData?.error_desc ? true : false}
                errorMessage={formData?.error_desc ?? ''}
                onChange={handleChangeDescription}
                onKeyDown={handleKeyDown}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Gambar Produk</Label>
              <Col lg={9}>
                <SortableListPhotos
                  transitionDuration={0}
                  lists={productPhoto}
                  onSortEnd={handleSortProductPhotos}
                  axis={isBig ? 'xy' : 'y'}
                  distance={1}
                  helperClass="nothing-image"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={3}>Is FBO?</Label>
              <Label lg={9} className="text-left text-bold">
                <span className="ml-1">
                  {(formData?.is_fbo === undefined || formData?.is_fbo === null) && '-'}
                  {formData?.is_fbo === true && 'Yes'}
                  {formData?.is_fbo === false && 'No'}
                </span>
              </Label>
            </FormGroup>
          </Col>
          <Row className="mt-3">
            {allowEdit && (
              <Col sm={12} md={12} className="section-action">
                <Button
                  className="button-action save"
                  type="button"
                  disabled={loadingSumbit || loadingUploadImage}
                  onClick={() => {
                    disableForm ? editForm() : handleSubmit();
                  }}
                >
                  {loadingSumbit ? (
                    <Spinner size="sm" color="dark">
                      Loading...
                    </Spinner>
                  ) : (
                    <> {disableForm ? 'Edit' : 'Simpan'}</>
                  )}
                </Button>
                {!disableForm && (
                  <Button
                    className="button-action cancel"
                    disabled={loadingSumbit || loadingUploadImage}
                    onClick={cancelEditForm}
                  >
                    Batalkan
                  </Button>
                )}
              </Col>
            )}
          </Row>
        </CardBody>
        <ConfirmationModal
          modal={confirmModal}
          toggle={() => setConfirmModal(!confirmModal)}
          header={`Anda yakin ingin ${modalType === 'Simpan' ? 'menyimpan ' : 'membatalkan '} ?`}
          subHeader={`Aksi ini akan mengakibatkan hasil edit master product ${detailProduct?.product_name} ${
            modalType === 'Simpan' ? ' tersimpan' : ' tidak tersimpan'
          }`}
          onConfirm={() => (modalType === 'Simpan' ? updateDetailProduct() : restoreDetailProduct())}
          toggleClose={() => setConfirmModal(!confirmModal)}
        ></ConfirmationModal>
      </Card>
      {disableQty ? (
        <Tooltip isOpen={infoTooltip} target="InfoTooltip" toggle={() => setInfoTooltip(!infoTooltip)}>
          <p>Quantity akan menyesuaikan berdasarkan data kendaraan di modul “Car”</p>
        </Tooltip>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default ProductSectionDetail;
