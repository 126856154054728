import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  FormGroup,
  Label,
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  CardFooter,
} from 'reactstrap';
import API from '@utils/API';
import SelectAsyncField from '@components/field/SelectAsyncField';
import SelectField from '@components/field/SelectField';
import InputField from '@components/field/InputField';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import DataTable from 'react-data-table-component';
import editIcon from '@assets/icons/edit.svg';

const DEFAULT_ERROR_FIELDS = {
  error_name: 'Nama tidak boleh kosong',
  error_phone: 'Nomor Telp tidak boleh kosong',
  error_role: 'Jabatan/Tugas harus dipilih',
};

const B2BCustomerContact = ({ id, handleResultNotification }) => {
  const api = new API('v2');
  const [onEdit, setOnEdit] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadContacts, setLoadContacts] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [hasModalAddContact, setHasModalAddContact] = useState(false);
  const [hasModalConfirmRemoveContact, setHasModalConfirmRemoveContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [roleOptions, setRoleOptions] = useState([]);
  const [allRegionOptions, setAllRegionOptions] = useState([]);

  useEffect(() => {
    loadCustomerContacts();
    loadRoleOptions();
    loadRegionOptions();
  }, [id]);

  const loadCustomerContacts = async () => {
    setLoadContacts(true);
    try {
      const response = await api.get(`v2/intools/customers/${id}/contacts/`);
      setContacts(response?.data?.data ?? []);
    } finally {
      setLoadContacts(false);
    }

    return Promise.resolve();
  };

  const loadRegionOptions = async (search = null) => {
    if (!search && !allRegionOptions.length) {
      try {
        const response = await api.get(`v2/intools/customers/${id}/contacts/areas`);
        setAllRegionOptions((response?.data?.data ?? []).map((item) => ({ id: null, region: item })));
      } finally {
      }
    }

    if (!search) {
      return allRegionOptions.filter((item) => {
        const exists = (selectedContact.responsibility_area ?? []).some(
          (area) => area.region.slug === item.region.slug
        );
        return !exists;
      });
    }

    return allRegionOptions
      .filter((item) => item.region.name.toLowerCase().includes(search.toLowerCase()))
      .filter((item) => {
        const exists = (selectedContact.responsibility_area ?? []).some(
          (area) => area.region.slug === item.region.slug
        );
        return !exists;
      });
  };

  const loadRoleOptions = async () => {
    if (roleOptions.length > 0) {
      return roleOptions;
    }
    const response = await api.get(`v2/intools/customers/${id}/contacts/roles/`);
    setRoleOptions(response?.data?.data ?? []);
  };

  const addContact = async (data) => {
    setLoadingSubmit(true);
    try {
      const response = await api.post(`v2/intools/customers/${id}/contacts/`, data);
      setContacts((prev) => [response?.data?.data, ...prev]);
      toggleModalAddContact();
      handleResultNotification({
        status: true,
        type: 'success',
        message: 'Kontak berhasil ditambahkan',
      });
    } catch (error) {
      const message = error?.response?.data?.error?.message ?? '';
      if (message.includes('Number is not')) {
        handleResultNotification({
          status: true,
          type: 'fail',
          message: 'Nomor Telp tidak valid',
        });
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  const removeContact = async (contactId) => {
    setLoadingSubmit(true);
    try {
      await api.delete(`v2/intools/customers/${id}/contacts/${contactId}/`);
      toggleModalAddContact();
      loadCustomerContacts();
      handleResultNotification({
        status: true,
        type: 'success',
        message: 'Kontak berhasil dihapus',
      });
    } catch (error) {
      const message = error?.response?.data?.error?.message ?? '';
      if (message.includes('Number is not')) {
        handleResultNotification({
          status: true,
          type: 'fail',
          message: message,
        });
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  const editContact = async (data) => {
    setLoadingSubmit(true);
    try {
      const response = await api.patch(`v2/intools/customers/${id}/contacts/${data.id}/`, data);
      if (response?.status === 200) {
        loadCustomerContacts();
        toggleModalAddContact();
        handleResultNotification({
          status: true,
          type: 'success',
          message: 'Kontak berhasil diubah',
        });
      }
    } catch (error) {
      const message = error?.response?.data?.error?.message ?? '';
      if (message.includes('Number is not')) {
        handleResultNotification({
          status: true,
          type: 'fail',
          message: 'Nomor Telp tidak valid',
        });
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleSubmit = useCallback(
    (e) => {
      if (selectedContact?.id && e?.delete) {
        return removeContact(selectedContact?.id);
      }

      if (hasErrorForm()) {
        handleResultNotification({
          status: true,
          type: 'fail',
          message: hasErrorForm(),
        });
        return;
      }

      if (selectedContact?.id) {
        return editContact(selectedContact);
      }

      addContact(selectedContact);
    },
    [selectedContact]
  );

  const hasErrorForm = useCallback(() => {
    if (!selectedContact) {
      return '';
    }
    return (
      Object.keys(selectedContact)
        .filter((key) => key.startsWith('error_') && selectedContact[key] !== '')
        .map((key) => selectedContact[key])[0] || ''
    );
  }, [selectedContact]);

  const handleChangeData = (field, value) => {
    setOnEdit(true);
    let err = '';

    if (field === 'name' && !value.trim()) {
      err = DEFAULT_ERROR_FIELDS.error_name;
    } else if (field === 'name' && value.length > 100) {
      err = 'Nama tidak boleh > 100 karakter';
    } else if (field === 'phone' && !value.trim()) {
      err = DEFAULT_ERROR_FIELDS.error_phone;
    } else if (field === 'phone' && value.length > 20) {
      err = 'No Telp tidak boleh > 20 karakter';
    } else if (field === 'role' && !value.value) {
      err = DEFAULT_ERROR_FIELDS.error_role;
    }

    setSelectedContact((prev) => ({ ...prev, [field]: value, [`error_${field}`]: err }));
  };

  const toggleModalConfirmRemove = useCallback(() => setHasModalConfirmRemoveContact((prev) => !prev), []);
  const toggleModalAddContact = (e) =>
    setHasModalAddContact((prev) => {
      if (prev) {
        setSelectedContact(null);
        setOnEdit(false);
      } else {
        setSelectedContact({
          ...(e.contact ?? {}),
          error_name: e.contact?.name ? '' : DEFAULT_ERROR_FIELDS.error_name,
          error_phone: e.contact?.phone ? '' : DEFAULT_ERROR_FIELDS.error_phone,
          error_role: e.contact?.role?.value ? '' : DEFAULT_ERROR_FIELDS.error_role,
        });
      }
      return !prev;
    });

  const columns = useMemo(
    () => [
      {
        name: '',
        cell: (row) => (
          <img src={editIcon} height={16} alt="" onClick={(e) => toggleModalAddContact({ ...e, contact: row })} />
        ),
        width: '50px',
        center: true,
      },
      {
        name: 'Nama',
        selector: (row) => row.name,
        wrap: true,
        allowOverflow: true
      },
      {
        name: 'Jabatan/Tugas',
        selector: (row) => row.role?.label,
        wrap: true,
        allowOverflow: true
      },
      {
        name: 'Nomor Telp',
        selector: (row) => row.phone,
        wrap: true,
        allowOverflow: true
      },
      {
        name: 'Area',
        selector: ({ responsibility_area = [] }) => responsibility_area.map(({ region }) => region.name).join(', '),
        wrap: true,
        allowOverflow: true
      },
    ],
    []
  );

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Kontak</CardHeader>
      <CardBody style={{ maxHeight: '350px', overflow: 'auto' }}>
        {loadContacts ? (
          <div className="text-center p-3">
            <Spinner color="primary" />
          </div>
        ) : (
          <Col sm={12} md={12} className="p-0 mt-1">
            <DataTable
              persistTableHead
              noHeader={true}
              columns={columns}
              data={contacts.filter((contact) => !contact.deleted)}
            />
          </Col>
        )}
      </CardBody>
      <CardFooter>
        {!loadContacts && (
          <Col className="section-action mb-2 mt-4">
            <Button color="purple" className="button-action" onClick={toggleModalAddContact} disabled={loadingSubmit}>
              + Kontak
            </Button>
          </Col>
        )}
      </CardFooter>

      <Modal isOpen={hasModalAddContact} centered className="modal-add-contact">
        <ModalHeader toggle={toggleModalAddContact}>
          {selectedContact?.id ? 'Ubah Kontak' : 'Tambah Kontak'}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup row>
                <Label lg={3} className="label-required">
                  Nama
                </Label>
                <InputField
                  value={selectedContact?.name}
                  onChange={(e) => handleChangeData('name', e.target.value)}
                  invalid={selectedContact?.error_name ?? '' !== ''}
                  errorMessage={selectedContact?.error_name ?? ''}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={3} className="label-required">
                  Nomor Telp
                </Label>
                <InputField
                  value={selectedContact?.phone}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, '');
                    handleChangeData('phone', numericValue);
                  }}
                  invalid={selectedContact?.error_phone ?? '' !== ''}
                  errorMessage={selectedContact?.error_phone ?? ''}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={3} className="label-required">
                  Jabatan/Tugas
                </Label>
                <SelectField
                  colWidth={9}
                  isDisabled={false}
                  value={selectedContact?.role ?? {}}
                  defaultOptions={roleOptions}
                  options={roleOptions}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  loadOptions={loadRoleOptions}
                  onChange={(v) => handleChangeData('role', v)}
                  errorMessage={selectedContact?.error_role ?? ''}
                  loading={false}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={3}>Area</Label>
                <SelectAsyncField
                  colWidth={9}
                  isDisabled={false}
                  value={selectedContact?.responsibility_area ?? []}
                  defaultOptions={[]}
                  getOptionLabel={(e) => e.region.name}
                  getOptionValue={(e) => e}
                  loadOptions={loadRegionOptions}
                  onChange={(v) => handleChangeData('responsibility_area', v)}
                  invalid={false}
                  errorMessage={''}
                  placeholder="Pilih Area..."
                  loading={false}
                  closeMenuOnSelect={false}
                  isMulti
                  isClearable
                  isSelectAll
                />
              </FormGroup>

              <div className="my-3 d-flex align-items-center">
                {selectedContact?.id && (
                  <Button color="danger" onClick={toggleModalConfirmRemove} className="mx-2">
                    Hapus Kontak
                  </Button>
                )}
                <div className="flex-grow-1 d-flex justify-content-center">
                  <Button
                    color="purple"
                    outline
                    onClick={toggleModalAddContact}
                    className="mx-2"
                    disabled={loadingSubmit}
                  >
                    Batal
                  </Button>
                  <Button
                    color="purple"
                    onClick={handleSubmit}
                    className="mx-2"
                    disabled={(!onEdit && hasErrorForm() !== '') || loadingSubmit}
                  >
                    {loadingSubmit ? <Spinner color="light" size="sm" /> : 'Simpan'}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <ConfirmationModal
        toggle={toggleModalConfirmRemove}
        toggleClose={toggleModalConfirmRemove}
        onConfirm={(e) => {
          toggleModalConfirmRemove();
          handleSubmit({ ...e, delete: true });
        }}
        modal={hasModalConfirmRemoveContact}
        header="Konfirmasi Hapus Kontak"
        subHeader={`Apakah Kamu yakin ingin menghapus kontak ${selectedContact?.name} ${
          selectedContact?.role?.value ? `(${selectedContact?.role?.value})` : ''
        }?`}
      />
    </Card>
  );
};

export default B2BCustomerContact;
