import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Fade,
  Form,
  FormText,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import map from 'lodash/map';
import moment from 'moment';
import Lottie from 'react-lottie';
import API from '@utils/API';
import FsLightbox from 'fslightbox-react';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import SuccessAnimation from '@assets/lottie/success.json';
import ErrorAnimation from '@assets/lottie/error.json';
import EditableInputField from '@components/field/EditableInputField';
import EditableSelectField from '@components/field/EditableSelectField';
import EditableSelectAsyncField from '@components/field/EditableSelectAsyncField';
import UploadFileCsv from '@components/modal/UploadFileCsv';
import CustomModal from '@components/modal/CustomModal';
import UploadImage from '@components/uploads/UploadImage';

function PushNotificationCreateForm(props) {
  const { viewOnly, dataDetail } = props;
  const apiv2 = new API('v2');

  const history = useHistory();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [urlLink, setUrlLink] = useState('');
  const [allUser, setAllUser] = useState(false);
  const [dataUsers, setDataUsers] = useState([]);

  const [selectedLink, setSelectedLink] = useState('');
  const [section, setSection] = useState('');
  const [subSection, setSubSection] = useState('');

  const [sectionOption, setSectionOption] = useState([]);
  const [subSectionProductOptions, setSubSectionProductOptions] = useState([]);

  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [fileError, setFileError] = useState('');
  const [hasModalUpload, setHasModalUpload] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);

  const [countDown, setCountDown] = useState(false);
  const [timeCountDown, setTimeCountDown] = useState(-1);

  const [success, setSuccess] = useState(false);
  const [actionError, setActionError] = useState(false);
  const [btnSubmitDisabled, setBtnSubmitDisabled] = useState(true);
  const [idSuccess, setIdSuccess] = useState('');
  const [hasErrorForm, setHasErrorForm] = useState(false);
  const [dataCSV, setDataCSV] = useState();
  const [imageKey, setImageKey] = useState();
  const [errorImage, setErrorImage] = useState('');
  const [imagePreview, setImagePreview] = useState();
  const [showLightbox, setShowLightbox] = useState(false);
  const [loadingUploadImage, setLoadingUploadImage] = useState(false);

  let intervalRef = useRef();

  const linkOptions = [
    {
      value: 'internal',
      label: 'Page',
    },
    {
      value: 'external',
      label: 'External Link',
    },
  ];

  const lottieOptions = {
    loop: true,
    autoPlay: true,
    animationData: actionError ? ErrorAnimation : SuccessAnimation,
  };

  const mapping = (data) => {
    return {
      value: data?.id,
      label: data?.name,
    };
  };

  const loadSectionOptions = async () => {
    const res = await apiv2.get(`v2/intools/fcm-notifications/sections`);
    const dataList = res?.data?.data;
    const dataSet = dataList.map((item) => mapping(item));
    setSectionOption(dataSet);
  };

  const loadSubSectionOptions = (key) => {
    if (key) {
      return apiv2
        .get(`v2/intools/campaigns/sections?keyword=${key}`)
        .then((res) => {
          return res?.data?.data;
        })
        .catch((error) => {});
    }
  };

  async function loadSubSectionProductOptions() {
    let listCategory = [];

    try {
      const response = await apiv2.get(`v2/intools/products/section`);
      if (response?.data?.data) {
        let resultListCategory = map(response?.data?.data, (item) => {
          return { slug: item?.slug, name: item?.name };
        });
        listCategory = resultListCategory;
      }
      setSubSectionProductOptions(listCategory);
    } catch (error) {
      setSubSectionProductOptions(listCategory);
    }
  }

  const validateForm = () => {
    if (
      (!allUser && dataUsers?.file === undefined) ||
      titleError !== '' ||
      descriptionError !== '' ||
      (!allUser && dataUsers?.file === undefined)
    ) {
      setHasErrorForm(true);
    } else {
      setHasErrorForm(false);
      setModalConfirm(true);
    }
    setTimeout(() => {
      setHasErrorForm(false);
    }, 3000);
  };

  const handleSubmit = () => {
    validateForm();
  };

  const handleModalConfirm = () => {
    setCountDown(true);
    setTimeCountDown(15);
  };

  const handleSubmitSuccess = () => {
    setSuccess(true);
  };

  const handleCancelSubmit = () => {
    setCountDown(false);
    setTimeCountDown(-1);
  };

  const decreaseCount = () => setTimeCountDown((count) => count - 1);

  const handleFile = (value) => {
    setDataUsers(value);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
    if (e.target.value.length > 40) {
      setTitleError('Title max 40 char');
    } else if (e.target.value.length < 5) {
      setTitleError('Title min 5 char');
    } else {
      setTitleError('');
    }
  };

  const handleChangeDescription = (e) => {
    let valDesc = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : description;

    setDescription(valDesc);
    if (valDesc.length > 100) {
      setDescriptionError('Description max 100 char');
    } else if (valDesc.length < 5) {
      setDescriptionError('Description min 5 char');
    } else {
      setDescriptionError('');
    }
  };

  const onChangeUpload = (value) => {
    const nameFile = value?.file?.name ?? 'undefined';
    const result = {
      file: value?.file ?? null,
      file_name: value?.file?.name,
      name: nameFile.length > 25 ? nameFile.substr(0, 11) + '...' + nameFile.substr(nameFile.length - 11) : nameFile,
      preview: value?.preview ?? '',
    };
    setHasModalUpload(!hasModalUpload);
    handleFile(result);
  };

  const downloadFile = (value) => {
    const link = document.createElement('a');
    link.setAttribute('href', value.preview);
    link.setAttribute('download', value.file.name);
    link.click();
  };

  const actionCreatePushNotif = () => {
    const dataForm = new FormData();
    dataForm.append('target_users', dataUsers?.file);
    dataForm.append('title', title || '');
    dataForm.append('description', description || '');
    dataForm.append('redirect_url', urlLink || '');
    dataForm.append('redirect_type', selectedLink?.value || '');
    dataForm.append('redirect_section_id', section?.value || '');
    dataForm.append('redirect_param', subSection?.slug || '');
    dataForm.append('redirect_param_name', subSection?.name || '');
    dataForm.append('is_all_customer', allUser);
    if (imageKey) {
      dataForm.append('image', imageKey);
    }

    apiv2
      .post('/v2/intools/fcm-notifications/blast/', dataForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        const data = response.data.data;
        if (+response?.status === 200) {
          const id = data?.id;
          setIdSuccess(id);
        } else {
          setIdSuccess('');
        }
      })
      .catch((error) => {
        setActionError(true);
      });
    handleSubmitSuccess();
  };

  const getFileCSV = () => {
    return apiv2
      .get(`v2/intools/fcm-notifications/blast/download/${dataDetail?.id}`)
      .then((res) => {
        setDataCSV(res?.data);
        return res;
      })
      .catch((error) => {});
  };

  const handleLoadingUploadImage = (status) => {
    setLoadingUploadImage(status);
  };

  const handleErrorUploadImage = (msg) => {
    setErrorImage(msg);
  };

  const handlePreviewImage = () => {
    if (imagePreview) {
      setShowLightbox(!showLightbox);
    }
  };

  const handleDeleteImage = () => {
    setImageKey(null);
    setImagePreview('');
  };

  const handleUploadImage = (image) => {
    setImageKey(image.key);
    setImagePreview(image.preview);
  };

  useEffect(() => {
    intervalRef.current = setInterval(decreaseCount, 1000);
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (+timeCountDown === 0) {
      actionCreatePushNotif();
    }
  }, [timeCountDown]);

  useEffect(() => {
    if (selectedLink?.value === 'external') {
      if (title !== '' && description !== '' && urlLink !== '' && selectedLink !== {}) {
        setBtnSubmitDisabled(false);
      } else {
        setBtnSubmitDisabled(true);
      }
    } else {
      if (
        title !== '' &&
        description !== '' &&
        section !== '' &&
        selectedLink !== {} &&
        ((section?.label.toLowerCase() != 'bengkel' && subSection !== '') ||
          (section?.label.toLowerCase() == 'bengkel' && subSection == ''))
      ) {
        setBtnSubmitDisabled(false);
      } else {
        setBtnSubmitDisabled(true);
      }
    }
  }, [title, description, urlLink, selectedLink, section, subSection]);

  useEffect(() => {
    if (!viewOnly) {
      setSubSection('');
    }
  }, [selectedLink, section]);

  useEffect(() => {
    if (viewOnly) {
      const redirectType = {
        value: dataDetail?.redirect_type,
        label: dataDetail?.redirect_type === 'external' ? 'External' : 'Page',
      };
      const redirectParam = {
        name: dataDetail?.redirect_param,
        slug: dataDetail?.redirect_param,
      };
      const redirectSection = {
        value: dataDetail?.redirect_section,
        label: dataDetail?.redirect_section,
      };
      setDescription(dataDetail?.description);
      setTitle(dataDetail?.title);
      setUrlLink(dataDetail?.redirect_url);
      setAllUser(dataDetail?.is_all_customer);
      setSelectedLink(redirectType);
      setSection(redirectSection);
      setSubSection(redirectParam);
      setImagePreview(dataDetail?.image);
    }
  }, [viewOnly]);

  useEffect(() => {
    if (!allUser && dataUsers?.file === undefined) {
      setFileError('Please choose an file to upload');
    } else {
      setFileError('');
    }
  }, [allUser, dataUsers]);

  useEffect(() => {
    if (dataDetail?.id !== undefined) {
      if (dataDetail?.is_all_customer !== true) {
        getFileCSV();
      }
    }
  }, [dataDetail]);

  useEffect(() => {
    loadSectionOptions();
    loadSubSectionProductOptions();
  }, []);

  return (
    <React.Fragment>
      <FsLightbox toggler={showLightbox} sources={[imagePreview]} type="image" />
      <Col sm={12} md={12}>
        <Card>
          <CardHeader className="text-white mt-0">{!viewOnly ? 'Create New' : 'Detail Notification'}</CardHeader>
          <CardBody>
            <Row>
              <Col xs={12} md={12} lg={12}>
                {/* <Form> */}
                <EditableInputField
                  textLeft
                  labelWidth={3}
                  colWidth={9}
                  title="Title"
                  inputName="title"
                  value={title}
                  inputMin={5}
                  placeholder="Title . . ."
                  onChange={handleChangeTitle}
                  invalid={titleError !== '' ? true : false}
                  errorMessage={titleError}
                  disabledFormText={viewOnly}
                  disabled={viewOnly}
                  formText={title.length + ' / 40 char'}
                  required={!viewOnly}
                  emoji={viewOnly ? false : true}
                />
                <EditableInputField
                  labelWidth={3}
                  colWidth={9}
                  textLeft
                  inputMin={5}
                  inputMax={100}
                  inputName="description"
                  placeholder="Description . . ."
                  title="Description"
                  inputType="textarea"
                  value={description}
                  disabled={viewOnly}
                  disabledFormText={viewOnly}
                  formText={description.length + ' / 100 char'}
                  invalid={descriptionError !== '' ? true : false}
                  errorMessage={descriptionError}
                  onChange={handleChangeDescription}
                  required={!viewOnly}
                  emoji={viewOnly ? false : true}
                />
                <FormGroup row>
                  <Label lg={3} className="text-left">
                    Gambar
                  </Label>
                  <Col lg={9}>
                    <UploadImage
                      isView={viewOnly}
                      image={imagePreview}
                      error={errorImage}
                      nameSpace={'push_notification'}
                      maxSize={1024}
                      maxSizeString={'1MB'}
                      allowTypesString={'jpeg, png'}
                      allowTypes={['jpeg', 'png']}
                      identifier={moment().format('HHmmss')}
                      onLoading={handleLoadingUploadImage}
                      onError={handleErrorUploadImage}
                      onDelete={handleDeleteImage}
                      onPreview={handlePreviewImage}
                      onUpload={handleUploadImage}
                    ></UploadImage>
                    {!viewOnly && (
                      <FormText className="muted text-left">{'Ukuran gambar setidaknya 360x180 pixel'}</FormText>
                    )}
                    <div className="invalid-document">{errorImage}</div>
                  </Col>
                </FormGroup>
                <Row>
                  <Col md={6}>
                    <EditableSelectField
                      required
                      textLeft
                      colWidth={6}
                      labelWidth={6}
                      disabled={viewOnly}
                      title="Publish to"
                      placeholder="Choose..."
                      closeMenuOnSelect={true}
                      value={selectedLink}
                      invalid={selectedLink !== {} ? true : false}
                      options={linkOptions}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      onChange={(e) => setSelectedLink(e)}
                    />
                  </Col>
                  {selectedLink !== '' ? (
                    <>
                      {selectedLink?.value === 'external' ? (
                        <Col md={6}>
                          <EditableInputField
                            colWidth={12}
                            disabled={viewOnly}
                            inputName="urlLink"
                            value={urlLink}
                            placeholder="Input URL link . . ."
                            onChange={(e) => {
                              setUrlLink(e.target.value);
                            }}
                          />
                        </Col>
                      ) : (
                        <>
                          <Col md={3}>
                            <EditableSelectField
                              colWidth={12}
                              defaultOptions={''}
                              placeholder="Choose Section . . ."
                              disabled={viewOnly}
                              value={section}
                              options={sectionOption}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              onChange={(e) => setSection(e)}
                            />
                          </Col>
                          {section?.label && section?.label.toLowerCase() == 'promo' && (
                            <Col md={3}>
                              <EditableSelectAsyncField
                                colWidth={12}
                                defaultOptions={''}
                                placeholder="Type Sub Section . . ."
                                isDisabled={viewOnly}
                                value={subSection}
                                getOptionLabel={(e) => e.name}
                                getOptionValue={(e) => e.slug}
                                loadOptions={loadSubSectionOptions}
                                onChange={(e) => setSubSection(e)}
                              />
                            </Col>
                          )}
                          {section?.label && section?.label.toLowerCase() == 'product' && (
                            <Col md={3}>
                              <EditableSelectField
                                colWidth={12}
                                defaultOptions={''}
                                placeholder="Choose Sub Section . . ."
                                disabled={viewOnly}
                                value={subSection}
                                options={subSectionProductOptions}
                                getOptionLabel={(e) => e.name}
                                getOptionValue={(e) => e.slug}
                                onChange={(e) => setSubSection(e)}
                              />
                            </Col>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </Row>
                <Row>
                  <Col md={3}>
                    <Label className="text-left">Target User</Label>
                  </Col>
                  <Col md={9}>
                    <Row>
                      <Col md={2}>
                        <Label className="ml-3">
                          <Input type="radio" checked={allUser} onClick={() => setAllUser(true)} disabled={viewOnly} />
                          All User
                        </Label>
                      </Col>
                      <Col md={2}>
                        <Label>
                          <Input
                            type="radio"
                            checked={!allUser}
                            onClick={() => setAllUser(false)}
                            disabled={viewOnly}
                          />
                          Selected User
                        </Label>
                      </Col>
                      <Col md={3}>
                        {!allUser && viewOnly && (
                          <a href={`data:text/csv;charset=utf-8,${dataCSV}`} download={dataDetail?.target_users_csv}>
                            <span className="text-link">{dataDetail?.target_users_csv}</span>
                          </a>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {!allUser && !viewOnly && (
                  <Row className="mt-3">
                    <Col md={3}></Col>
                    <Col md={2}>
                      <Button
                        color="primary"
                        size="lg"
                        className="btn-add-item-package"
                        style={{ marginBottom: '10px' }}
                        onClick={() => setHasModalUpload(true)}
                      >
                        <img src={PlusFill} className="mr-2" />
                        Browse
                      </Button>
                      <div style={{ display: 'block' }} className="invalid-feedback">
                        {fileError}
                      </div>
                    </Col>
                    <Col md={3}>
                      {dataUsers?.file_name && (
                        <span
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: '#6610f2',
                            paddingTop: 9,
                          }}
                          onClick={() => downloadFile(dataUsers)}
                        >
                          {dataUsers.file_name}
                        </span>
                      )}
                    </Col>
                  </Row>
                )}
                {!viewOnly && (
                  <Row>
                    <Col md={12} className="text-center">
                      <Button
                        color="primary"
                        size="lg"
                        className="my-2"
                        onClick={handleSubmit}
                        type="submit"
                        disabled={btnSubmitDisabled || loadingUploadImage}
                      >
                        Publish Now
                      </Button>
                    </Col>
                  </Row>
                )}
                {/* </Form> */}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      {!viewOnly && (
        <UploadFileCsv
          isValidation={true}
          sizeString={'5 MB'}
          sizeValidation={5048}
          modal={hasModalUpload}
          resultUploadFile={onChangeUpload}
          toggle={() => setHasModalUpload(!hasModalUpload)}
        />
      )}
      <CustomModal
        modal={modalConfirm}
        modalWidth="500"
        backdrop="static"
        toggle={() => setModalConfirm(!modalConfirm)}
        modalFooter={
          !countDown ? (
            <>
              <Button color="danger" outline size="lg" onClick={() => setModalConfirm(false)}>
                Cancel
              </Button>
              <Button color="primary" size="lg" onClick={() => handleModalConfirm()}>
                Submit
              </Button>
            </>
          ) : (
            <>
              {!success ? (
                <Button color="danger" outline size="lg" onClick={() => handleCancelSubmit()}>
                  Cancel
                </Button>
              ) : (
                <Button
                  color="success"
                  size="lg"
                  onClick={() => {
                    setModalConfirm(false);
                    history.push(`/push-notifications/${idSuccess}`);
                  }}
                >
                  Ok
                </Button>
              )}
            </>
          )
        }
      >
        <>
          {!countDown ? (
            <div>
              <p className="text-center">Are you sure to publish this notification to Customer ?</p>
            </div>
          ) : (
            <>
              {!success ? (
                <div className="text-center">
                  <p>click cancel if you change your mind</p>
                  <h1>{timeCountDown}</h1>
                </div>
              ) : (
                <div className="text-center">
                  <Lottie options={lottieOptions} height="auto" width={100} />
                  <p className="text-center">{actionError ? 'Error !' : 'Notification has been publish !'}</p>
                </div>
              )}
            </>
          )}
        </>
      </CustomModal>
      {hasErrorForm && (
        <div style={{ margin: '0 auto' }}>
          <Fade in>
            <Alert color="danger" fade isOpen>
              Please complete the form
            </Alert>
          </Fade>
        </div>
      )}
    </React.Fragment>
  );
}

PushNotificationCreateForm.defaultProps = {
  viewOnly: false,
  dataDetail: {},
};

PushNotificationCreateForm.propTypes = {
  viewOnly: PropTypes.bool,
  dataDetail: PropTypes.object,
};

export default PushNotificationCreateForm;
