import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Wrapper from '@components/wrapper/Wrapper';
import Loading from '@components/loading/Loading';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import { Col, Button, Card, CardBody, Badge } from 'reactstrap';

function Referral() {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [dataExport, setDataExport] = useState([]);
  const [disabledExport, setDisabledExport] = useState(true);

  const allowPermission = (menu) => {
    return !!(generatePermission('referral', menu) || generatePermission('referral', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowFilter = allowPermission('all') || allowPermission('search');
  const allowShow = allowPermission('all') || allowPermission('detail');
  const allowExport = allowPermission('all') || allowPermission('export');
  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);
  const keys = ['query'];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  let dataFilter = [
    {
      id: 'filter_text_query',
      type: 'text',
      name: 'query',
      name_field: 'query',
      value: tempFiltered?.query ?? '',
      placeholder: 'Name',
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: 'name',
        wrap: true,
        minWidth: '210px',
      },
      {
        name: 'Referral Code',
        selector: 'referral_code',
        wrap: true,
        minWidth: '150px',
      },
      {
        name: 'Used Count',
        selector: 'referral_used_count',
        wrap: true,
      },
    ],
    []
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchReferrals() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/referals/filter-referral/`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
      handleDataExport(response?.data?.data ?? []);
    } catch (error) {
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleDataExport([]);
    }
  }

  const getParamData = () => {
    let params = {
      name: filtered?.query ?? '',
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const handleDataExport = (data) => {
    let arrDataExport = [];

    data.forEach((item) => {
      const objDataExport = {
        customer: item?.name ?? '-',
        referral_code: item?.referral_code ?? '-',
        referral_used_count: item?.referral_used_count ?? 0,
      };

      arrDataExport.push(objDataExport);
    });

    setDataExport(arrDataExport);
    setDisabledExport(false);
  };

  const handleDownloadCSV = () => {
    const keys = ['customer', 'referral_code', 'referral_used_count'];
    const headers = ['name', 'referral code', 'referral count'];

    Helper.downloadCSV(dataExport, headers, keys);
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleResetFilters = () => {
    tempFiltered = {};
    resetFilter();
  };

  const resetFilter = () => {
    const updatedDataFilter = dataFilter.map((filter) => ({
      ...filter,
      value: typeof filter?.value !== '' ? '' : filter?.value,
    }));
    setFilters(updatedDataFilter);
    setFiltered({});
    tempFiltered = {};
  };

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
      query: e?.query ?? '',
    };
    console.log(`setfiltered`, e);
    setFiltered(tempVal);
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodfetchReferrals();
  };

  const handleMethodfetchReferrals = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchReferrals();
    }
  };

  const resetRefferal = () => {
    setResetSorting(true);
    setSortingData({});
  };

  const openReferralDetail = (row) => {
    if (allowShow) {
      history.push(`/referral/${row.id}`);
    }
  };

  useEffect(() => {
    setFiltered({ ...tempFiltered });
  }, Object.values(tempFiltered));

  useEffect(() => {
    if (filtered) {
      let tempSetFiltered = { ...filtered };
      tempSetFiltered = {
        ...filtered,
      };
      const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
      history.push(`/referral/?${urlParam}`);
      fetchReferrals();
    }
    resetRefferal();
  }, [filtered]);

  useEffect(() => {
    if (page && pageLimit) {
      if (allowList) fetchReferrals();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  // useEffect(() => {
  //   resetFilter();
  // }, [filtered]);

  useEffect(() => {
    setFilters(dataFilter);
  }, []);

  return (
    <Wrapper title="List Referral" className="wrapperContent">
      <Card className="p-3">
        <CardBody className="p-0">
          <Col sm={12} md={12} className="p-0 mt-0 section-action">
            {allowExport && allowList && (
              <Button
                disabled={disabledExport || loading}
                className="button-action primary"
                onClick={handleDownloadCSV}
              >
                {'Export CSV'}
              </Button>
            )}
          </Col>
          {filters?.length !== 0 && allowFilter && (
            <Col sm={12} md={12} className="p-0 mt-2">
              <ActionFilterCustom
                filters={filters}
                loading={loading}
                onApplyFilters={(e) => handleSetFiltered(e)}
                onResetFilters={handleResetFilters}
              ></ActionFilterCustom>
            </Col>
          )}
          <Col sm={12} md={12} className="p-0 mt-2">
            <DataTable
              persistTableHead
              highlightOnHover
              noHeader={true}
              progressPending={loading}
              data={data}
              columns={allowList ? columns : []}
              className="table dt-responsive table-custom"
              noDataComponent={noDataComponent}
              progressComponent={<Loading />}
              customStyles={customStylesHeaderTable}
              onRowClicked={(row) => openReferralDetail(row)}
            />
          </Col>
          <Col sm={12} md={12} className="p-0 mt-2">
            {data?.length >= 1 && !loading && (
              <CustomPagination
                page={page}
                pageLimit={pageLimit}
                totalRows={data?.length ?? 0}
                totalAllRows={totalRows}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                handleChangeRowPerPage={handleChangeRowPerPage}
              ></CustomPagination>
            )}
          </Col>
        </CardBody>
      </Card>
    </Wrapper>
  );
}

export default Referral;
