import React, { useEffect } from 'react';
import { Label, FormGroup, Button, Col, Input } from 'reactstrap';
import Divider from '@components/divider/Divider';
import { Tooltip } from 'react-tooltip';
import SelectField from '@components/field/SelectField';
import InputField from '@components/field/InputField';
import InfoIcon from '@assets/icons/info.svg';
import map from 'lodash/map';
import { CUSTOM_BOOKING_CATEGORY_OPTIONS } from '@utils/Constants';
import Helper from '@helpers/Helper';
import useSelectionRange from '@utils/useSelectionRange';

function BookingReviseFormCustom(props) {
  const {
    data,
    takeRateProduct,
    takeRateService,
    disabled,
    index,
    isFleetMode,
    categoryOptions,
    onChangeType,
    onChangeName,
    onChangeCategory,
    onChangeQty,
    onChangeWorkshopPrice,
    onChangeMarkUp,
    onChangeCustomerPrice,
    onChangeDiscount,
    onChangeSplitedTax,
    markUp,
    discount,
  } = props;

  const onTypeChange = (value) => {
    onChangeType(value, index);
  };

  const onNameChange = (value) => {
    onChangeName(value, index);
  };

  const onCategoryChange = (value) => {
    onChangeCategory(value, index);
  };

  const onQtyChange = (value) => {
    onChangeQty(value, index);
  };

  const onWorkshopPriceChange = (value) => {
    onChangeWorkshopPrice(value, index);
  };

  const onMarkUpChange = (value) => {
    onChangeMarkUp(value, index);
  };

  const onCustomerPriceChange = (value) => {
    onChangeCustomerPrice(value, index);
  };

  const onDiscountChange = (value) => {
    onChangeDiscount(value, index);
  };

  const onSplitedTaxChange = (value) => {
    const checked = value.target.checked;
    onChangeSplitedTax(checked, index);
  };

  useEffect(() => {
    onMarkUpChange({ value: markUp, label: `${markUp}%` });
    onDiscountChange({ value: discount, label: `${discount}%` });
  }, [markUp, discount]);

  return (
    <React.Fragment>
      <div className="service-tab">
        {isFleetMode && data?.is_approve !== undefined && data?.is_approve !== null && (
          <FormGroup row>
            <Label lg={3}>Status</Label>
            <Label
              lg={9}
              className={
                data?.is_approve ? 'text-left font-weight-bold color-green' : 'text-left font-weight-bold color-red'
              }
            >
              <div>{data?.is_approve ? 'APPROVED' : 'REJECTED'}</div>
            </Label>
          </FormGroup>
        )}
        {isFleetMode && data?.is_draft_change !== undefined && data?.is_draft_change !== null && data?.is_draft_change && (
          <FormGroup row>
            <Label lg={3}>Label</Label>
            <span className="ml-2 badge-custom badge-custom-draft">{'Draft'}</span>
          </FormGroup>
        )}
        <FormGroup className="d-flex">
          <Label lg={3} className="label-required">
            Tipe Flagging
          </Label>
          <Col lg={9} className="px-2">
            <div className="mb-1">
              {map(CUSTOM_BOOKING_CATEGORY_OPTIONS, (bookingOpt, index) => (
                <Button
                  style={data?.disable_type ? { cursor: 'unset' } : { cursor: 'pointer' }}
                  className={index > 0 ? 'ml-2' : ''}
                  color="purple"
                  outline={data?.type !== bookingOpt?.value}
                  disabled={data?.disable_type || disabled}
                  onClick={() => {
                    if (!data?.disable_type && !disabled) {
                      onTypeChange(bookingOpt?.value);
                    }
                  }}
                >
                  {bookingOpt?.label}
                </Button>
              ))}
            </div>
            <div className="invalid-document">{data?.error_type ?? ''}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="label-required">
            {data?.type == 'product' ? 'Product Name' : data?.type == 'service' ? 'Service Name' : 'Name'}
          </Label>
          <InputField
            colWidth={9}
            placeholder={
              data?.type == 'product'
                ? 'Masukkan Product Name'
                : data?.type == 'service'
                ? 'Masukkan Service Name'
                : 'Masukkan Name'
            }
            value={data?.name ?? ''}
            invalid={data?.error_name ? true : false}
            errorMessage={data?.error_name ?? ''}
            disabled={disabled}
            onChange={async (e) => {
              if (!disabled) {
                const { start, end, inputElm } = useSelectionRange(e);

                await onNameChange(e);
                inputElm.setSelectionRange(start, end);
              }
            }}
          />
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="label-required">
            Kategori
          </Label>
          <SelectField
            colWidth={9}
            title="Kategori"
            placeholder={'Pilih Kategori'}
            disabled={data?.disable_category || disabled}
            closeMenuOnSelect={true}
            options={categoryOptions}
            errorMessage={data?.error_category}
            value={data?.category}
            onChange={(e) => {
              if (!data?.disable_category && !disabled) {
                onCategoryChange(e);
              }
            }}
          />
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="label-required">
            Jumlah
          </Label>
          <InputField
            inputType="number"
            colWidth={9}
            placeholder={'Masukkan Jumlah'}
            value={data?.qty?.toString()}
            invalid={data?.error_qty ? true : false}
            errorMessage={data?.error_qty ?? ''}
            disabled={disabled}
            onChange={(e) => {
              if (!disabled) {
                onQtyChange(e);
              }
            }}
          />
        </FormGroup>
        <Divider />
        <FormGroup row>
          <Tooltip id="info-split-tax" />
          <Label lg={3}>
            <img
              src={InfoIcon}
              alt=""
              className="mr-1 cursor-pointer"
              width={16}
              data-tooltip-id="info-split-tax"
              data-tooltip-content="Jika diaktifkan, maka operator mengisi Harga Jual Bengkel termasuk PPN lalu sistem akan menghitung DPP"
            />
            Split Tax Pricing
          </Label>
          <Col lg={9} style={{ padding: '6px 12px', display: 'flex', alignItems: 'center' }}>
            <Input
              type="checkbox"
              onChange={onSplitedTaxChange}
              checked={data?.is_splited_tax}
              value={data?.is_splited_tax}
              disabled={disabled}
            />
          </Col>
        </FormGroup>
        {data?.is_splited_tax ? (
          <FormGroup row>
            <Label lg={3}>DPP Jual Bengkel</Label>
            <Col lg={9} sm={12} className="d-flex align-items-center">
              <span>{`IDR ${Helper.formatMoney(Helper.countDPP(data?.workshop_price?.formatMoney))}`}</span>
            </Col>
          </FormGroup>
        ) : null}
        <FormGroup row>
          <Label lg={3} className="label-required">
            {data?.is_splited_tax ? 'Harga Jual Bengkel Termasuk PPN' : 'DPP Jual Bengkel'}
          </Label>
          <InputField
            colWidth={9}
            placeholder={'DPP Jual Bengkel'}
            value={data?.workshop_price?.formatMoneyString}
            invalid={data?.error_workshop_price ? true : false}
            errorMessage={data?.error_workshop_price ?? ''}
            disabled={disabled}
            onChange={(e) => {
              if (!disabled) {
                onWorkshopPriceChange(e);
              }
            }}
          />
        </FormGroup>
        <FormGroup row>
          <Label lg={3}>Take Rate</Label>
          <Label lg={9} className="text-left text-bold">
            {data?.type == 'product'
              ? `${takeRateProduct ?? 0}%`
              : data?.type == 'service'
              ? `${takeRateService ?? 0}%`
              : `0%`}
          </Label>
        </FormGroup>
        <Divider />
        <FormGroup row>
          <Label lg={3} className="label-required">
            DPP Jual Customer
          </Label>
          <InputField
            colWidth={9}
            placeholder={'Harga Jual Customer'}
            value={data?.customer_price?.formatMoneyString}
            invalid={data?.error_customer_price ? true : false}
            errorMessage={data?.error_customer_price ?? ''}
            disabled={disabled}
            onChange={(e) => {
              if (!disabled) {
                onCustomerPriceChange(e);
              }
            }}
          />
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="label-required">
            Mark Up
          </Label>
          <Col lg={9} sm={12} className="d-flex align-items-center">
            <span>{data?.markup?.label}</span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="label-required">
            Diskon
          </Label>
          <Col lg={9} sm={12} className="d-flex align-items-center">
            <span>{data?.discount?.label}</span>
          </Col>
        </FormGroup>
        {isFleetMode && data?.note !== undefined && (
          <FormGroup row>
            <Label lg={3}>Catatan</Label>
            <Label lg={9} className="text-left">
              <div>{!data?.note ? '-' : data?.note}</div>
            </Label>
          </FormGroup>
        )}
      </div>
    </React.Fragment>
  );
}

export default BookingReviseFormCustom;
