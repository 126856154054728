import React, { useState } from 'react';
import { Col, FormGroup, Row, Label, Input, Tooltip } from 'reactstrap';
import Quest from '@assets/icons/question.svg';
import EditableSelectField from '@components/field/EditableSelectField';
import EditableInputField from '@components/field/EditableInputField';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';

const PromoType = (props) => {
  const {
    disableSwitch,
    disableGlobal,
    isPublic,
    isShowList,
    isBanner,
    isFeatured,
    data,
    onChangeSwitch,
    onChangeInput,
    labelChecked,
    labelUnchecked,
    bannerOptions,
    errorBanner,
    errorPromoLink,
    handleBannerTypeChange,
    packages,
  } = props;
  const [publicTooltip, setPublicTooltip] = useState(false);
  const [showlistTooltip, setShowlistTooltip] = useState(false);
  const [bannerTooltip, setBannerTooltip] = useState(false);
  const [featuredTooltip, setFeaturedTooltip] = useState(false);

  const handleSwitchChange = (type, value) => {
    onChangeSwitch(type, value);
  };

  const generatePromoLink = () => {
    return (
      <a href={data?.link_promo} className={'link'} target="_blank" rel="noreferrer">
        <div className="text-overflow">{data?.link_promo}</div>
      </a>
    );
  };

  return (
    <div>
      <Tooltip isOpen={publicTooltip} target="publicTooltip" toggle={() => setPublicTooltip(!publicTooltip)}>
        <p>1. Jika aktif, maka customer dapat klik pada banner untuk menggunakan code voucher dan dapat diketik juga</p>
        <p>2. Jika non-aktif, customer wajib mengetik promo code untuk mengaktifkan banner </p>
      </Tooltip>
      <Tooltip isOpen={showlistTooltip} target="showlistTooltip" toggle={() => setShowlistTooltip(!showlistTooltip)}>
        <p>Jika aktif, maka customer akan melihatnya di promo list di checkout page</p>
      </Tooltip>
      <Tooltip isOpen={bannerTooltip} target="bannerTooltip" toggle={() => setBannerTooltip(!bannerTooltip)}>
        <p>Jika aktif, maka di home page akan muncul banner yang akan direct ke suatu page/link</p>
      </Tooltip>
      <Tooltip isOpen={featuredTooltip} target="featuredTooltip" toggle={() => setFeaturedTooltip(!featuredTooltip)}>
        <p>Jika aktif, maka customer akan melihatnya di home page</p>
      </Tooltip>

      <Row className="ml-2">
        <Col sm={3} md={3}>
          <FormGroup row className="mb-0">
            <Label lg={3} className="text-left">
              <img className="icon-quest" id="publicTooltip" src={Quest} width="15" />
              Public
            </Label>
            <Col lg={9}>
              <div className="custom-control custom-switch switch-secondary mt-1">
                <Input
                  type="checkbox"
                  className="custom-control-input"
                  id="is_public"
                  name="is_public"
                  onChange={() => handleSwitchChange('is_public', isPublic ? false : true)}
                  checked={isPublic}
                  disabled={disableSwitch || disableGlobal}
                />
                <Label className="custom-control-label" htmlFor="is_public">
                  {isPublic ? labelChecked : labelUnchecked}
                </Label>
              </div>
            </Col>
          </FormGroup>
        </Col>
        <Col sm={3} md={3}>
          <FormGroup row className="mb-0">
            <Label lg={4} className="text-left">
              <img className="icon-quest" id="showlistTooltip" src={Quest} width="15" />
              Show List
            </Label>
            <Col lg={8}>
              <div className="custom-control custom-switch switch-secondary mt-1">
                <Input
                  type="checkbox"
                  className="custom-control-input"
                  id="is_show_list"
                  name="is_show_list"
                  onChange={() => handleSwitchChange('is_show_list', isShowList ? false : true)}
                  checked={isShowList}
                  disabled={disableSwitch || disableGlobal}
                />
                <Label className="custom-control-label" htmlFor="is_show_list">
                  {isShowList ? labelChecked : labelUnchecked}
                </Label>
              </div>
            </Col>
          </FormGroup>
        </Col>
        <Col sm={3} md={3}>
          <FormGroup row className="mb-0">
            <Label lg={3} className="text-left">
              <img className="icon-quest" id="bannerTooltip" src={Quest} width="15" />
              Banner
            </Label>
            <Col lg={9}>
              <div className="custom-control custom-switch switch-secondary mt-1">
                <Input
                  type="checkbox"
                  className="custom-control-input"
                  id="is_banner"
                  name="is_banner"
                  onChange={() => handleSwitchChange('is_banner', isBanner ? false : true)}
                  checked={isBanner}
                  disabled={disableSwitch || disableGlobal}
                />
                <Label className="custom-control-label" htmlFor="is_banner">
                  {isBanner ? labelChecked : labelUnchecked}
                </Label>
              </div>
            </Col>
          </FormGroup>
        </Col>
        <Col sm={3} md={3}>
          <FormGroup row className="mb-0">
            <Label lg={4} className="text-left">
              <img className="icon-quest" id="featuredTooltip" src={Quest} width="15" />
              Featured
            </Label>
            <Col lg={8}>
              <div className="custom-control custom-switch switch-secondary mt-1">
                <Input
                  type="checkbox"
                  className="custom-control-input"
                  id="is_featured"
                  name="is_featured"
                  onChange={() => handleSwitchChange('is_featured', isFeatured ? false : true)}
                  checked={isFeatured}
                  disabled={disableSwitch || disableGlobal}
                />
                <Label className="custom-control-label" htmlFor="is_featured">
                  {isFeatured ? labelChecked : labelUnchecked}
                </Label>
              </div>
            </Col>
          </FormGroup>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={6}>
          {!disableSwitch && isBanner == true && (
            <Row className="mb-3">
              <Label lg={4} className="text-left label-required">
                Entry Point
              </Label>
              <SelectField
                colWidth={8}
                title="Kelompok Promo"
                placeholder="select banner type"
                disabled={!isBanner || disableGlobal}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.slug}
                closeMenuOnSelect={true}
                options={bannerOptions}
                value={data?.banner_type}
                errorMessage={errorBanner ? 'tidak boleh kosong' : ''}
                onChange={handleBannerTypeChange}
              />
            </Row>
          )}
          {disableSwitch && isBanner == true && (
            <Row className="mb-3">
              <Label lg={4} className="text-left">
                Entry Point
              </Label>
              <Label lg={8} className="text-left text-bold">
                : <span className="ml-1">{data?.banner_type?.name ?? '-'}</span>
              </Label>
            </Row>
          )}
        </Col>

        <Col sm={6}>
          {!disableSwitch && isBanner == true && data?.banner_type?.slug == 'deeplink' && (
            <Row>
              <Label lg={4} className="text-left label-required">
                Link Promo
              </Label>
              <InputField
                colWidth={8}
                inputType="text"
                disabled={!isBanner || disableGlobal}
                value={data?.link_promo}
                invalid={(data?.link_promo?.length < 1 || errorPromoLink) && isBanner}
                inputName={'link_promo'}
                placeholder={'link'}
                errorMessage={'Field tidak boleh kosong'}
                onChange={onChangeInput}
              />
            </Row>
          )}
          {disableSwitch && isBanner == true && data?.banner_type?.slug == 'deeplink' && (
            <Row className="mb-3">
              <Label lg={4} className="text-left">
                Link Promo
              </Label>
              <Label lg={8} className="text-left text-bold d-flex">
                : <span className="ml-2">{data?.link_promo ? generatePromoLink() : '-'}</span>
              </Label>
            </Row>
          )}

          {!disableSwitch && isBanner == true && data?.banner_type?.slug == 'landing-page' && (
            <Row>
              <Label lg={4} className="text-left">
                Paket
              </Label>
              <SelectField
                colWidth={8}
                title="Kelompok Promo"
                placeholder="package list"
                disabled={!isBanner || disableGlobal}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.slug}
                closeMenuOnSelect={true}
                options={packages}
                value={{ name: `Paket(${packages ? packages?.length : '0'})`, slug: null }}
              />
            </Row>
          )}
          {disableSwitch && isBanner == true && data?.banner_type?.slug == 'landing-page' && (
            <Row className="mb-3">
              <Label lg={4} className="text-left">
                Paket
              </Label>
              <Label lg={8} className="text-left text-bold">
                : <span className="ml-1">{packages?.length ? `Paket (${packages?.length})` : 'Paket (0)'}</span>
              </Label>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PromoType;
