import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Label, Spinner, Button } from 'reactstrap';
import API from '@utils/API';
import UploadImage from '@components/uploads/UploadImage';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import Helper from '@helpers/Helper';

const B2BCustomerLegality = ({ id, allowEdit, handleResultNotification }) => {
  const api = new API('v2');
  const [disableForm, setDisableForm] = useState(true);
  const [onEdit, setOnEdit] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [loadingFetchDocuments, setLoadingFetchDocuments] = useState(false);
  const [hasModalDeleteDocumentConfirmation, setHasModalDeleteDocumentConfirmation] = useState(null);

  useEffect(() => {
    const loadDocuments = async () => {
      setLoadingFetchDocuments(true);
      try {
        const response = await api.get(`v2/intools/customers/${id}/legal-documents/`);
        setDocuments(response?.data?.data ?? []);
      } catch (error) {
        console.error('ERROR', error);
      } finally {
        setLoadingFetchDocuments(false);
      }
    };
    loadDocuments();
  }, [id]);

  const handleClickEdit = useCallback(() => {
    setDisableForm(false);
    setOnEdit(true);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setDisableForm(true);
    setOnEdit(false);
  }, []);

  const handleOnUploaded = async (i, result) => {
    setLoadingSubmit(true);
    try {
      const response = await api.put(`v2/intools/customers/${id}/legal-documents/`, { ...documents[i], file: result });
      if (response?.status === 200) {
        setDocuments((prev) => {
          const updated = [...prev];
          updated[i] = response.data.data;
          return updated;
        });
        handleResultNotification({
          status: true,
          type: 'success',
          message: `${documents[i]?.label ?? 'Dokumen'} berhasil diperbarui`,
        });
      }
    } catch (error) {
      handleResultNotification({
        status: true,
        type: 'fail',
        message: `Gagal memperbarui ${documents[i]?.label ?? 'dokumen'}`,
      });
      console.error(error);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleOnPreviewFile = (doc) => {
    if (doc.pdf_preview) {
      return () => {
        const contentType = 'application/pdf',
          blob = Helper.base64StringToBlob(doc.pdf_preview, contentType),
          dataUrl = URL.createObjectURL(blob),
          iframe = document.createElement('iframe');

        iframe.style.position = 'fixed';
        iframe.style.right = '0';
        iframe.style.bottom = '0';
        iframe.style.width = '0';
        iframe.style.height = '0';
        iframe.style.border = '0';
        iframe.src = dataUrl;
        document.body.appendChild(iframe);

        iframe.contentWindow.print();
      };
    }

    return null;
  };

  const handleDeleteDocument = async (doc, force = false) => {
    if (force) {
      setLoadingSubmit(true);
      toggleModalDeleteDocumentConfirmation();
      try {
        const response = await api.put(`v2/intools/customers/${id}/legal-documents/`, { ...doc, delete: true });
        if (response?.status === 200) {
          handleResultNotification({
            status: true,
            type: 'success',
            message: `${doc?.label ?? 'Dokumen'} berhasil dihapus`,
          });
          setDocuments((prevDocuments) =>
            prevDocuments.map((item) => {
              if (item.name === doc.name) {
                return {
                  ...item,
                  id: null,
                  value: null,
                  filename: null,
                  pdf_preview: null,
                };
              }
              return item;
            })
          );
        }
      } catch (error) {
        handleResultNotification({
          status: true,
          type: 'fail',
          message: `Gagal menghapus ${doc?.label ?? 'dokumen'}`,
        });
        console.error(error);
      } finally {
        setLoadingSubmit(false);
      }

      return;
    }

    toggleModalDeleteDocumentConfirmation(doc);
  };

  const toggleModalDeleteDocumentConfirmation = (val = {}) =>
    setHasModalDeleteDocumentConfirmation((prev) => (prev ? null : val));

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Legalitas</CardHeader>
      <CardBody>
        {loadingFetchDocuments ? (
          <div className="text-center p-3">
            <Spinner color="primary" />
          </div>
        ) : (
          <>
            {documents.map((doc, i) => (
              <FormGroup row key={doc.name}>
                <Label lg={4}>{doc.label}</Label>
                <Col lg={8} className="legal-docs">
                  {!disableForm || doc.value ? (
                    <UploadImage
                      uploadTitle=""
                      isView={false}
                      image=""
                      textMode
                      textPreview={doc.filename}
                      previewDownload
                      previewURL={doc.value}
                      error="errorImage"
                      nameSpace={`customer/${id}/legal_documents`}
                      identifier={doc.name}
                      forceOnPreview={handleOnPreviewFile(doc)}
                      maxSize={5120}
                      maxSizeString="5 MB"
                      allowTypesString="jpeg, jpg, png, pdf"
                      allowTypes={['jpeg', 'jpg', 'png', 'pdf']}
                      onLoading={() => {}}
                      onError={(e) => {
                        if (e) {
                          handleResultNotification({
                            status: true,
                            type: 'fail',
                            message: e,
                          });
                        }
                      }}
                      onDelete={() => handleDeleteDocument(doc)}
                      onUpload={(e) => handleOnUploaded(i, e)}
                      deletable={!disableForm}
                    />
                  ) : (
                    '-'
                  )}
                </Col>
              </FormGroup>
            ))}

            <Col className="section-action mb-2 mt-4">
              {allowEdit && onEdit ? (
                <Button className="button-action cancel" onClick={handleCancelEdit} disabled={loadingSubmit}>
                  {loadingSubmit ? <Spinner color="light" size="sm" /> : 'Selesai'}
                </Button>
              ) : (
                allowEdit && (
                  <Button color="purple" className="button-action" onClick={handleClickEdit} disabled={loadingSubmit}>
                    Edit
                  </Button>
                )
              )}
            </Col>
          </>
        )}
      </CardBody>

      <ConfirmationModal
        toggle={toggleModalDeleteDocumentConfirmation}
        toggleClose={toggleModalDeleteDocumentConfirmation}
        onConfirm={() => handleDeleteDocument(hasModalDeleteDocumentConfirmation, true)}
        modal={hasModalDeleteDocumentConfirmation}
        header="Hapus Dokumen Legalitas?"
        subHeader={`Apakah Anda yakin akan menghapus ${hasModalDeleteDocumentConfirmation?.label} pada Dokumen Legalitas?`}
      />
    </Card>
  );
};

export default B2BCustomerLegality;
