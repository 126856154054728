import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Alert,
  Col,
  Label,
  FormFeedback,
} from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { find } from 'lodash';
import DeleteIcon from '@assets/icons/delete.svg';
import API from '@utils/API';

const UploadFileConfig = (props) => {
  const {
    id,
    modal,
    toggle,
    maxSize = 10000,
    maxSizeString = '10MB',
    allowTypes = ['jpeg', 'jpg', 'png', 'pdf'],
    title = 'Tambah File',
    subTitle = '',
    disabled,
    onError,
    file,
    onConfirm,
  } = props;
  const api = new API('v2');
  const [uploadFile, setUploadFile] = useState(undefined);
  const [typeFile, setTypeFile] = useState(undefined);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState(null);
  const [hasMessage, setHasMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [spkNumber, setSpkNumber] = useState('');
  const [errorSpkNumber, setErrorSpkNumber] = useState('');
  const [errorFile, setErrorFile] = useState('');
  const [dataKey, setDataKey] = useState('');

  const { getRootProps } = useDropzone({
    multiple: false,
    disabled: loading || disabled,
    onDrop: (files) => {
      handleDropFileImage(files[0]);
      setDragOver(false);
    },
    onDragOver: () => {
      onError('');
      setDragOver(true);
    },
    onDragLeave: () => {
      setDragOver(false);
    },
    onFileDialogOpen: () => {
      onError('');
    },
  });

  const handleChangeSPKNumber = (e) => {
    let spkNumber = e?.target?.value;
    setSpkNumber(spkNumber);

    if (spkNumber?.length < 1) {
      setErrorSpkNumber('Nomor SPK tidak boleh kosong');
    } else {
      setErrorSpkNumber('');
    }
  };

  const generateDisplayName = (value) => {
    let displayName = value;

    if (value?.length > 23) {
      displayName = value?.slice(0, 10) + '...' + value?.slice(-10);
    }

    return displayName;
  };

  const handleDropFileImage = (file) => {
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const nameFileSplit = file?.name ? file?.name.split('.') : [];
    if (nameFileSplit.length == 0) {
      setErrorFile('Format file tidak diketahui');
    } else {
      const selected = find(allowTypes, (item) => {
        return nameFileSplit[nameFileSplit.length - 1].toLowerCase() === item;
      });
      if (!selected) {
        setErrorFile('File tidak sesuai dengan format');
      } else {
        if (fileSize == null) {
          setErrorFile('Ukuran file tidak diketahui');
        } else if (fileSize > maxSize) {
          setErrorFile('Ukuran file maksimal ' + maxSizeString);
        } else {
          const previewImage = URL.createObjectURL(file);
          handleUploadImage(file, previewImage);
          setErrorFile('');
        }
      }
    }
  };

  const handleUploadImage = (file, preview) => {
    if (file?.name) {
      setLoading(true);
      const paramsImagedUpload = new FormData();
      paramsImagedUpload.append('namespace', 'attachment_otoklix');
      paramsImagedUpload.append('identifier', id);
      paramsImagedUpload.append('image', file);

      api
        .post(`v2/intools/image-upload/`, paramsImagedUpload)
        .then((response) => {
          const keyFile = response?.data?.data?.key ?? '';
          if (keyFile !== '') {
            onError('');
            setDataKey(keyFile);
            setPreviewFile(preview);
            setIsShowPreview(true);
            setUploadFile(file);
          } else {
            onError('Gagal upload image');
          }
          setLoading(false);
        })
        .catch((e) => {
          const message = `API Error : ${e?.response?.data?.error?.message}`;
          onError(message);
          setLoading(false);
        });
    } else {
      onError('Gagal upload image, file tidak diketahui');
    }
  };

  const validation = () => {
    let isValid = true;

    if (file?.type_file == 'spk' && spkNumber?.length < 1) {
      setErrorSpkNumber('Nomor SPK tidak boleh kosong');
      isValid = false;
    }

    if (!uploadFile?.name) {
      setErrorFile('File tidak boleh kosong');
      isValid = false;
    }

    return isValid;
  };

  const submitForm = () => {
    let isValid = validation();

    if (isValid) {
      setDisable(true);
      toggleClose();
      onConfirm({ key: dataKey, fileName: uploadFile?.name, file: uploadFile, spkNumber: spkNumber });
    }
  };

  const toggleClose = () => {
    if (!disabled) {
      setUploadFile();
      setPreviewFile('');
      toggle();
    }
  };

  const onDeletePreview = () => {
    setUploadFile();
    setPreviewFile();
    setIsShowPreview(false);
  };

  const reset = () => {
    setUploadFile();
    setPreviewFile('');
    setDisable(false);
    setMessage(null);
    setHasMessage(false);
    setIsError(false);
    setIsShowPreview(false);
    setPreviewFile();
    setSpkNumber('');
    setErrorSpkNumber('');
    setErrorFile('');
  };

  useEffect(() => {
    if (file?.document?.name) {
      setUploadFile({ ...uploadFile, name: file?.document?.name });
      setPreviewFile(file?.document?.url);
      setSpkNumber(file?.spk_number ?? '');
      setIsShowPreview(true);
    }
  }, [modal]);

  return (
    <Modal
      className="modal-dialog-centered modal-custom w-500"
      isOpen={modal}
      // toggle={toggleClose}
      onClosed={() => reset()}
    >
      <ModalBody className="body modal-export-finance">
        <div className="header mb-5">{title}</div>
        <p className="text-center">{subTitle}</p>
        {file?.type_file == 'spk' && (
          <FormGroup row className="mb-1">
            <Label lg={4} className="label-required">
              No SPK
            </Label>
            <Col lg={7} className="ml-2">
              <Input
                name="file"
                disabled={disable || loading}
                placeholder="masukkan nomor SPK"
                value={spkNumber}
                invalid={!!errorSpkNumber}
                onChange={handleChangeSPKNumber}
              />
              <FormFeedback>{errorSpkNumber}</FormFeedback>
            </Col>
          </FormGroup>
        )}
        <FormGroup row className="">
          <Label lg={4} className="label-required">
            Dokumen {file?.label_file}
          </Label>
          <Col lg={7} className={`container-upload-file ${isShowPreview ? '' : 'mt-0'}`}>
            {!isShowPreview ? (
              <div {...getRootProps({ className: dragOver ? 'box-upload drag-over' : 'box-upload' })}>
                <div>
                  <div className="title">
                    {loading ? 'Please wait...' : dragOver ? 'Release File' : 'Drag and Drop or Choose File'}
                  </div>
                </div>
              </div>
            ) : (
              <div className="box-upload">
                <a
                  href={previewFile}
                  rel="noreferrer"
                  target="_blank"
                  className="text-bold text-purple-2 ml-2 text-decoration-underline"
                >
                  {uploadFile?.name ? generateDisplayName(uploadFile?.name) : '-'}
                </a>
                <img className="ml-3 cursor-pointer" src={DeleteIcon} height={15} onClick={onDeletePreview} />
              </div>
            )}
            {!isShowPreview && (
              <div className="ml-2">
                <div className="document-guide">Max upload 10mb</div>
                <div className="document-guide">pdf, jpeg, jpg, png</div>
              </div>
            )}
            <FormFeedback className="ml-2 mt-0">{errorFile}</FormFeedback>
          </Col>
        </FormGroup>
        {hasMessage && (
          <Alert className="mt-3 mb-0" color={isError ? 'danger' : 'primary'}>
            {message}
          </Alert>
        )}
        <div className="action mt-3">
          <Button className="button-action" disabled={disable || loading} onClick={() => toggleClose()}>
            Batal
          </Button>{' '}
          <Button className="button-action" disabled={disable || loading} onClick={() => submitForm()}>
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UploadFileConfig;
