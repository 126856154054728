import React, { useState, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import DataTable from 'react-data-table-component';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import DetailField from '@components/field/DetailField';
import ExportButton from '@components/export/ExportButton';
import Wrapper from '@components/wrapper/Wrapper';
import API from '@utils/API';
import userPermission from '@utils/userPermission';

function ReferralDetail() {
  const { id } = useParams();
  const history = useHistory();
  const api = new API('v2');
  const { generatePermission } = userPermission();

  const [dataUser, setDataUser] = useState(null);
  const [dataReferral, setDataReferral] = useState(null);
  const [loading, setLoading] = useState(false);

  const breadcrumb = [
    {
      id: 1,
      label: 'List Referral',
      active: false,
      url: '/referral',
    },
    {
      id: 2,
      label: 'Detail Referral',
      active: true,
      url: '',
    },
  ];

  useEffect(() => {
    laodReferralDetail(id);
  }, []);

  async function laodReferralDetail() {
    setLoading(true);

    const responseUser = await api.get(`v2/intools/customers/get-customer-by-id/?id=${id}`);
    const responseReferral = await api.get(`v2/intools/referals/get-detail-referral/?id=${id}`);
    setDataUser(responseUser.data.data.data[0]);
    setDataReferral(responseReferral?.data?.data ?? []);
    setLoading(false);
  }

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: 'name',
        wrap: true,
      },
      {
        name: 'Referral Code',
        selector: 'referral_code',
        wrap: true,
      },
    ],
    []
  );

  // render main content page
  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <React.Fragment>
        <Wrapper title="Detail Referral" breadcrumbData={breadcrumb}>
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-primary text-white mt-0">Customer Info</CardHeader>
                <CardBody>
                  <DetailField title="Name" value={get(dataUser, 'name')} />
                  <DetailField title="Phone Number" value={get(dataUser, 'user.username')} />
                  <DetailField title="Referral Code" value={get(dataUser, 'referral_code')} />
                  <DetailField title="Referral Count" value={dataReferral ? dataReferral.length : 0} />
                </CardBody>
              </Card>

              {dataReferral && (
                <DataTable
                  persistTableHead
                  highlightOnHover
                  pagination
                  data={dataReferral}
                  columns={columns}
                  className="table table-bordered dt-responsive"
                  actions={
                    <ExportButton
                      hasActionExport={generatePermission('referral', 'detailexport')}
                      data={dataReferral}
                      keys={['name', 'referral_code']}
                      header={['Name', 'Referral Code']}
                      title="Export CSV"
                    />
                  }
                  onRowClicked={(row) => history.push(`/users/${row.id}`)}
                />
              )}
            </Col>
          </Row>
        </Wrapper>
      </React.Fragment>
    );
  };

  return renderContent();
}

export default ReferralDetail;
