import React from 'react';
import { FormGroup, Col, Button, Row } from 'reactstrap';

const EditActionButton = (props) => {
  const {
    labelWidth,
    colWidth,
    hasInfo,
    infoColor,
    infoMessage,
    btnCancelDisable,
    handleCancelEdit,
    handleSubmit,
    hasCancel,
    isDisabled = false,
  } = props;

  return (
    <FormGroup row>
      <Col lg={labelWidth} />
      <Col lg={colWidth}>
        <Row>
          {hasInfo && (
            <Col lg={12}>
              <div className={`alert alert-${infoColor}`}>{infoMessage}</div>
            </Col>
          )}
          <Col>
            {hasCancel && (
              <Button color="danger" block disabled={btnCancelDisable} onClick={handleCancelEdit}>
                Cancel
              </Button>
            )}
          </Col>
          <Col>
            <Button color="secondary" block onClick={handleSubmit} disabled={isDisabled}>
              {`${btnCancelDisable ? 'Edit Data' : 'Submit'} `}
            </Button>
          </Col>
        </Row>
      </Col>
    </FormGroup>
  );
};

export default EditActionButton;
