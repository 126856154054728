import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader, CardFooter, Button, Col, Badge, Spinner } from 'reactstrap';
import { handleReschedule, handleChangeStatusBooking, handleChangeStatusIssue } from '@actions/Booking';
import map from 'lodash/map';
import API from '@utils/API';
import Popup from 'react-popup';
import moment from 'moment';
import userPermission from '@utils/userPermission';
import IconDownload from '@assets/icons/download.svg';
import DetailField from '@components/field/DetailField';
import RescheduleModal from '@components/modal/RescheduleModal';
import JiraTicketLinkModal from '@components/modal/JiraTicketLinkModal';
import GenerateSnapLink from '@components/modal/GenerateSnapLink';
import CancelBookingReasonModal from '@components/modal/CancelBookingReasonModal';
import CancelBookingInternalReasonModal from '@components/modal/CancelBookingInternalReasonModal';
import BookingConfirmationModal from '@components/modal/BookingConfirmationModal';
import BookingDetailDetailsDocument from '@components/booking/BookingDetailDetailsDocument';
import ChangePriceBookingModal from '@components/modal/ChangePriceBookingModal';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import RevertStatusModal from '@components/modal/RevertStatusModal';
import InputField from '@components/field/InputField';
import PenIcon from '@assets/icons/pen.svg';
import NewTabIcon from '@assets/icons/new-tab.svg';
import FreshdeskIcon from '@assets/icons/freshdesk.svg';
import JiraIcon from '@assets/icons/jira.svg';
import Select from 'react-select';
import InvoicePdf from '@helpers/pdf/InvoicePdf';
import CustomModal from '@components/modal/CustomModal';

function BookingDetailDetails(props) {
  const dispatch = useDispatch();
  const {
    id,
    isFleetMode,
    detailData,
    dataBillSettlement,
    workshops,
    editCar,
    editDetail,
    editDocument,
    disabledGLobal,
    bookingStatusOptions,
    issueStatusOptions,
    onFetchBookingDetail,
    onResultNotification,
    onEditModeDetail,
    onEditModeDocument,
    onProcessEditDetail,
    onProcessEditDocument,
    isSPK,
    isCustomDoc,
    isInvoice,
    isArrivalPhoto,
  } = props;
  const { generatePermission } = userPermission();
  const [changeStatus, setChangeStatus] = useState(null);
  const [priceData, setPriceData] = useState({});
  const [arrayDetail, setArrayDetail] = useState([]);
  const [totalPrice, setTotalPrice] = useState('');
  const [packageNames, setPackageNames] = useState('');
  const [bookingId, setBookingId] = useState('');
  const [bookingTime, setBookingTime] = useState(null);
  const [bookingDate, setBookingDate] = useState(Date.today);
  const [reason, setReason] = useState('');
  const [jiraUrl, setJiraUrl] = useState('');
  const [hasInvalidDate, setInvalidDate] = useState(false);
  const [messageInvalidDate, setMessageInvalidDate] = useState('');
  const [selectedReason, setSelectedReason] = useState(1);
  const [changeStatusButtonLoading, setChangeStatusButtonLoading] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showJiraTicketLink, setShowJiraTicketLink] = useState(false);
  const [cancelReasonModal, setCancelReasonModal] = useState(false);
  const [cancelInternalReasonModal, setCancelInternalReasonModal] = useState(false);
  const [revertStatusReasonModal, setRevertStatusReasonModal] = useState(false);
  const [reasonMsg, setReasonMsg] = useState('');
  const [internalReasonMsg, setInternalReasonMsg] = useState('');
  const [revertStatusReasonMsg, setRevertStatusReasonMsg] = useState('');
  const [reasonData, setReasonData] = useState(null);
  const [scheduleButtonLoading, setScheduleButtonLoading] = useState(false);
  const [priceBookingModal, setPriceBookingModal] = useState(false);
  const [bookingConfirmationModalTitle, setBookingConfirmationModalTitle] = useState('');
  const [bookingConfirmationModalStatus, setBookingConfirmationModalStatus] = useState('');
  const [bookingConfirmationModal, setBookingConfirmationModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedStatusIssue, setSelectedStatusIssue] = useState();
  const [hasCancelModalConfirmation, setHasCancelModalConfirmation] = useState(false);
  const [hasServiceDoneModalConfirmation, setHasServiceDoneModalConfirmation] = useState(false);
  const [serviceDoneModalType, setServiceDoneModalType] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [modalDisable, setModalDisable] = useState(false);
  const [statusOptions, setStatusOptions] = useState();
  const [statusIssueOptions, setStatusIssueOptions] = useState();
  const [noSPKModal, setNoSPKModal] = useState(false);

  const [formData, setFormData] = useState();
  const [editGenerateLink, setEditGenerateLink] = useState(false);
  const [editFreshdesk, setEditFreshdesk] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [editPaymentNote, setEditPaymentNote] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const [canDownloadInvoice, setCanDownloadInvoice] = useState(false);
  const [canReschedule, setCanReschedule] = useState(true);

  const [loadingInvoice, setLoadingInvoice] = useState(false);

  const serviceType = detailData?.service_type?.value;
  const customerType = detailData?.customer?.customer_type;
  const jiraTicket = {
    url: detailData?.jira_ticket?.url,
    status: detailData?.jira_ticket?.status,
    ticketKey: detailData?.jira_ticket?.ticket_key,
  };
  const payment = { value: '014' };

  const generateStatusColor = (status) => {
    const statusColorCode = {
      waiting: 'text-warning',
      confirm: 'text-secondary',
      finish: 'text-success',
      cancel: 'text-danger',
      payment: 'text-warning',
      review: 'text-info',
      'on-process': 'text-secondary',
      'service-done': 'text-success',
      complain: 'text-danger',
    };
    return statusColorCode[status];
  };

  const setDefaultForm = () => {
    const dataForm = {
      note: detailData?.booking_note ?? '',
      payment_note: detailData?.payment_note ?? '',
      freshdesk: detailData?.freshdesk_ticket_link ?? '',
      error_note: '',
      error_payment_note: '',
      error_freshdesk: '',
    };

    setFormData(dataForm);
    setEditGenerateLink(false);
    setEditFreshdesk(false);
    setEditNote(false);
    setEditPaymentNote(false);
  };

  const setChangeValueForm = (key, value, errorKey, errorMsg) => {
    formData[key] = value;
    if (errorKey) formData[errorKey] = errorMsg;
    setFormData({ ...formData });
  };

  const handleChangeFreshdesk = (e) => {
    validateFreshdesk(e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : formData?.freshdesk);
  };

  const handleChangeNote = (e) => {
    validateNote(e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : formData?.note);
  };
  const handleChangePaymentNote = (e) => {
    validatePaymentNote(e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : formData?.payment_note);
  };

  const validateFreshdesk = (value) => {
    const errorMsg =
      value?.length !== 0 && value?.length > 100 ? 'Format FreshDesk Ticket Link maksimal 100 karakter' : '';
    setChangeValueForm('freshdesk', value, 'error_freshdesk', errorMsg);
    return errorMsg ? false : true;
  };

  const validateNote = (value) => {
    const errorMsg = value?.length !== 0 && value?.length > 500 ? 'Format Note maksimal 500 karakter' : '';
    setChangeValueForm('note', value, 'error_note', errorMsg);
    return errorMsg ? false : true;
  };

  const validatePaymentNote = (value) => {
    const errorMsg = value?.length !== 0 && value?.length > 500 ? 'Format Payment Note maksimal 500 karakter' : '';
    setChangeValueForm('payment_note', value, 'error_payment_note', errorMsg);
    return errorMsg ? false : true;
  };

  const handleEditGenerateLink = () => {
    if (!disabled && !disabledGLobal) {
      setDefaultForm();
      setEditGenerateLink(!editGenerateLink);
    }
  };

  const handleEditFreshdesk = () => {
    if (!disabled && !disabledGLobal) {
      setDefaultForm();
      setEditFreshdesk(!editFreshdesk);
    }
  };

  const handleEditNote = () => {
    if (!disabled && !disabledGLobal) {
      setDefaultForm();
      setEditNote(!editNote);
    }
  };

  const handleEditPaymentNote = () => {
    if (!disabled && !disabledGLobal) {
      setDefaultForm();
      setEditPaymentNote(!editNote);
    }
  };

  const handleEditDocument = (isEdit) => {
    onEditModeDocument(isEdit);
  };

  const handleProcessEditDocument = (isProcessDocument) => {
    onProcessEditDocument(isProcessDocument);
  };

  const handleSelectStatus = (e) => {
    if (generatePermission('booking', 'updatestatus')) {
      setChangeStatus(e?.value);
      if (e.value === 'cancel') {
        cancelReasonModalToggle();
      } else if (e.value === 'reschedule' && generatePermission('booking', 'reschedule')) {
        setShowRescheduleModal(!showRescheduleModal);
      } else if (e.value === 'finish' && generatePermission('booking', 'done')) {
        if ((!isSPK || !isCustomDoc) && customerType == 'b2b') {
          setNoSPKModal(true);
        } else {
          setShowModal(true);
          setSelectedStatus(e.label);
        }
      } else if (e.value === 'confirm' && generatePermission('booking', 'confirm')) {
        setShowModal(true);
        setSelectedStatus(e.label);
      } else if (e.value === 'on-process') {
        setShowModal(true);
        setSelectedStatus(e.label);
      } else if (e.value === 'service-done') {
        if (detailData?.workshop?.is_gms && detailData?.custom_booking) {
          setHasServiceDoneModalConfirmation(true);
          setServiceDoneModalType('karena masih ada custom SKU.');
        } else if (!isArrivalPhoto) {
          setHasServiceDoneModalConfirmation(true);
          setServiceDoneModalType('karena Foto Report Kedatangan tidak ada.');
        } else if (!isInvoice && customerType == 'b2b') {
          setHasServiceDoneModalConfirmation(true);
          setServiceDoneModalType('karena Dokumen Invoice tidak ada.');
        } else {
          setShowModal(true);
          setSelectedStatus(e.label);
          setServiceDoneModalType('');
        }
      } else if (e.value === 'waiting') {
        setShowModal(true);
        setSelectedStatus(e.label);
      }
    }
  };

  const handleSelectIssueStatus = (e) => {
    if (generatePermission('booking', 'updatestatus')) {
      setChangeStatus(e?.value);
      if (e.value === 'cancel') {
        cancelReasonModalToggle();
      } else if (e.value === 'pending-workshop') {
        setShowModal(true);
        setSelectedStatusIssue(e.label);
      } else if (e.value === 'estimation') {
        setShowModal(true);
        setSelectedStatusIssue(e.label);
      } else if (e.value === 'approval') {
        setShowModal(true);
        setSelectedStatusIssue(e.label);
      } else if (e.value === 'awaiting-spk') {
        setShowModal(true);
        setSelectedStatusIssue(e.label);
      } else if (e.value === 'awaiting-revise') {
        setShowModal(true);
        setSelectedStatusIssue(e.label);
      } else if (e.value === 'confirm') {
        setShowModal(true);
        setSelectedStatusIssue(e.label);
      } else if (e.value === 'on-process') {
        setShowModal(true);
        setSelectedStatusIssue(e.label);
      } else if (e.value === 'pending-part') {
        setShowModal(true);
        setSelectedStatusIssue(e.label);
      } else if (e.value === 'pending-invoice') {
        setShowModal(true);
        setSelectedStatusIssue(e.label);
      } else if (e.value === 'revise-spk') {
        setShowModal(true);
        setSelectedStatusIssue(e.label);
      } else if (e.value === 'service-done') {
        if (detailData?.workshop?.is_gms && detailData?.custom_booking) {
          setHasServiceDoneModalConfirmation(true);
          setServiceDoneModalType('karena masih ada custom SKU.');
        } else if (!isArrivalPhoto) {
          setHasServiceDoneModalConfirmation(true);
          setServiceDoneModalType('karena Foto Report Kedatangan tidak ada.');
        } else if (!isInvoice && customerType == 'b2b') {
          setHasServiceDoneModalConfirmation(true);
          setServiceDoneModalType('karena Dokumen Invoice tidak ada.');
        } else {
          setShowModal(true);
          setSelectedStatusIssue(e.label);
          setServiceDoneModalType('');
        }
      } else if (e.value === 'post-service') {
        setShowModal(true);
        setSelectedStatusIssue(e.label);
      } else if (e.value === 'done' && generatePermission('booking', 'done')) {
        if ((!isSPK || !isCustomDoc) && customerType == 'b2b') {
          setNoSPKModal(true);
        } else {
          setShowModal(true);
          setSelectedStatusIssue(e.label);
        }
      }
    }
  };

  const onPriceChangedSuccess = () => {
    handleStatusChanged(true);
    togglePriceBookingModal();
  };

  const onPriceChangedError = (error) => {
    togglePriceBookingModal();
    if (error) {
      Popup.create({ title: 'Gagal', content: error.response.data?.error?.message });
      return;
    }
  };

  const rescheduleModalToggle = () => {
    setShowRescheduleModal(!showRescheduleModal);
  };

  const jiraticketLinkToggle = () => {
    setShowJiraTicketLink(!setShowJiraTicketLink);
  };

  const toggleBookingConfirmationModal = () => {
    setBookingConfirmationModal(!bookingConfirmationModal);
  };

  const togglePriceBookingModal = () => {
    setPriceBookingModal(!priceBookingModal);
  };

  const toggleGenerateLinkModal = () => {
    setDefaultForm();
  };

  const cancelReasonModalToggle = async () => {
    if (!cancelReasonModal) {
      const api = new API('v2');
      const response = await api.get(`v2/intools/bookings/cancel-reason-options/`);

      if (response.status === 200) {
        setReasonData(response.data.data);
        setInternalReasonMsg('');
        if (
          generatePermission('booking', 'cancelpostservice') &&
          (detailData?.booking_status?.slug == 'finish' || detailData?.booking_status?.slug == 'service-done')
        ) {
          setHasCancelModalConfirmation(true);
        } else if (
          (generatePermission('booking', 'cancelpreservice') || generatePermission('booking', 'cancelpreservice')) &&
          (detailData?.booking_status?.slug != 'finish' || detailData?.booking_status?.slug != 'service-done')
        ) {
          setCancelReasonModal(true);
        }
      }
    } else {
      setCancelReasonModal(false);
      setTimeout(() => {
        setReasonData(null);
        setInternalReasonMsg('');
      }, 200);
    }
  };

  const cancelInternalReasonModalToggle = () => {
    if (cancelInternalReasonModal) {
      setInternalReasonMsg('');
    }

    setCancelInternalReasonModal(!cancelInternalReasonModal);
  };

  const revertStatusReasonModalToggle = () => {
    if (!revertStatusReasonModal) {
      setRevertStatusReasonMsg('');
    }
    setRevertStatusReasonModal(!revertStatusReasonModal);
  };

  const handleSubmitReasonModal = () => {
    setCancelReasonModal(false);
    setCancelInternalReasonModal(true);
  };

  const handleSubmitInternalReasonModal = () => {
    changeStatusBooking(customerType == 'b2b');
  };

  const handleRevertStatusReasonModal = () => {
    if (generatePermission('booking', 'revert')) {
      changeStatusBooking(customerType == 'b2b');
    }
  };

  const handleChangeReason = (id, msg) => {
    setSelectedReason(id);
    if (id === 11) {
      setReasonMsg(msg);
    } else {
      let selectedMessage = '';

      reasonData?.length > 0 &&
        reasonData.map((item) => {
          item?.reason?.length > 0 &&
            item?.reason.map((value) => {
              if (value?.id == id) {
                selectedMessage = value?.description;
              }
            });
        });

      setReasonMsg(selectedMessage);
    }
  };

  const changeStatusBooking = async (isB2B = false) => {
    setModalDisable(true);
    if (isB2B) {
      dispatch(
        handleChangeStatusIssue(
          changeStatus,
          id,
          selectedReason,
          reasonMsg,
          internalReasonMsg,
          detailData?.booking_status?.slug,
          revertStatusReasonMsg,
          jiraTicket
        )
      )
        .then((response) => {
          const responseData = response.data;
          setBookingConfirmationModal(false);
          setCancelReasonModal(false);
          setCancelInternalReasonModal(false);

          if (responseData?.packages?.length > 0) {
            preparePriceData(responseData);
          } else {
            handleStatusChanged(true);
          }

          if (status === 'cancel') {
            handleChangeReason(1);
          }
          setModalDisable(false);
        })
        .catch((error) => {
          handleStatusChanged(false, error);
          setBookingConfirmationModal(false);
          setCancelReasonModal(false);
          setCancelInternalReasonModal(false);

          if (status === 'cancel') {
            handleChangeReason(1);
          }
          setModalDisable(false);
        });
    } else {
      dispatch(
        handleChangeStatusBooking(
          changeStatus,
          id,
          selectedReason,
          reasonMsg,
          internalReasonMsg,
          detailData?.booking_status?.slug,
          revertStatusReasonMsg
        )
      )
        .then((response) => {
          const responseData = response.data;
          setBookingConfirmationModal(false);
          setCancelReasonModal(false);
          setCancelInternalReasonModal(false);

          if (responseData?.packages?.length > 0) {
            preparePriceData(responseData);
          } else {
            handleStatusChanged(true);
          }

          if (status === 'cancel') {
            handleChangeReason(1);
          }
          setModalDisable(false);
        })
        .catch((error) => {
          handleStatusChanged(false, error);
          setBookingConfirmationModal(false);
          setCancelReasonModal(false);
          setCancelInternalReasonModal(false);

          if (status === 'cancel') {
            handleChangeReason(1);
          }
          setModalDisable(false);
        });
    }
  };

  const handleStatusChanged = async (isSuccess, error) => {
    setChangeStatusButtonLoading(true);
    if (error) {
      Popup.create({ title: 'Gagal', content: error.response.data?.error?.message });
      return;
    }
    await onFetchBookingDetail(id);
    setChangeStatusButtonLoading(false);
    Popup.alert('Booking telah berhasil di update!');
  };

  const handleConfirmationModal = () => {
    if (detailData?.booking_status?.slug === 'cancel') {
      revertStatusReasonModalToggle();
      setShowModal(false);
    } else {
      changeStatusBooking(customerType == 'b2b');
    }
  };

  const onReschedule = (e) => {
    setScheduleButtonLoading(true);
    e.preventDefault();

    if (bookingDate == null) {
      alert('Field : New Booking Date should be filled');
      setScheduleButtonLoading(false);
      return;
    }
    if (bookingTime == null) {
      alert('Field : New Booking Time should be filled');
      setScheduleButtonLoading(false);
      return;
    }
    if (!reason) {
      alert('Field : Reason should be filled');
      setScheduleButtonLoading(false);
      return;
    }

    dispatch(handleReschedule(id, bookingDate, bookingTime, reason))
      .then(() => {
        onFetchBookingDetail(id);
        setShowRescheduleModal(false);
        setInvalidDate(false);
        setScheduleButtonLoading(false);
      })
      .catch((e) => {
        setMessageInvalidDate(e.response?.data?.error?.message);
        setInvalidDate(true);
        setScheduleButtonLoading(false);
      });

    setScheduleButtonLoading(false);
  };

  const preparePriceData = (respData) => {
    if (respData?.packages?.length > 0) {
      let totalPrice = 0;
      let arrayDetail = [];
      let packageNames = [];
      respData?.packages.map((packageItem) => {
        packageNames.push(packageItem.name);
        packageItem.booking_details.map((item) => {
          arrayDetail.push({
            newPrice: item?.price || 0,
            data: item,
          });
          totalPrice += item.price;
        });
      });

      setPackageNames(packageNames);
      setTotalPrice(totalPrice);
      setArrayDetail(arrayDetail);
      setPriceData(respData);

      if (generatePermission('booking', 'updateprice')) {
        togglePriceBookingModal();
      }
    }
  };

  const downloadCustomerInvoice = () => {
    InvoicePdf.create(detailData, dataBillSettlement);
  };

  async function getCustomerInvoice() {
    const api = new API('v2');
    try {
      const response = await api.get(`v2/intools/bookings/${id}/invoice`, {
        responseType: 'blob',
      });
      if (response?.data) {
        var file = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        var fileURL = document.createElement('a');
        fileURL.href = file;
        fileURL.setAttribute('download', `invoice-${id}.pdf`);

        // Append to html link element page
        document.body.appendChild(fileURL);
        // Start download
        fileURL.click();
        // Clean up and remove the link
        fileURL.parentNode.removeChild(fileURL);
      }
      setLoadingInvoice(false);
    } catch (err) {
      handleResultNotification(true, 'fail', 'Gagal Download Invoice');
      setLoadingInvoice(false);
    }
  }

  const loadBookingStatusOptions = () => {
    if (
      (detailData?.booking_status?.slug === 'service-done' && !detailData?.workshop?.is_gms) ||
      detailData?.booking_status?.slug === 'cancel' ||
      detailData?.booking_status?.slug === 'finish'
    ) {
      let transformedOptions = map(bookingStatusOptions, (tier) => {
        return { value: tier.value, label: tier.name };
      });
      if (
        !generatePermission('booking', 'cancelpostservice') &&
        (detailData?.booking_status?.slug === 'finish' || detailData?.booking_status?.slug === 'service-done')
      ) {
        transformedOptions = transformedOptions.filter((data) => data?.value != 'cancel');
      }
      setCanReschedule(false);
      setStatusOptions(transformedOptions);
    } else {
      let transformedOptions = map(bookingStatusOptions, (tier) => {
        return { value: tier.value, label: tier.name };
      });
      if (
        !generatePermission('booking', 'cancelpostservice') &&
        (detailData?.booking_status?.slug == 'finish' || detailData?.booking_status?.slug === 'service-done')
      ) {
        transformedOptions = transformedOptions.filter((data) => data?.value != 'cancel');
      }
      setCanReschedule(true);
      setStatusOptions(transformedOptions);
    }
  };

  const loadIssueStatusOptions = () => {
    if (
      (detailData?.booking_status?.slug === 'service-done' && !detailData?.workshop?.is_gms) ||
      detailData?.booking_status?.slug === 'cancel' ||
      detailData?.booking_status?.slug === 'finish'
    ) {
      let transformedOptions = map(issueStatusOptions, (tier) => {
        return { value: tier.value, label: tier.name };
      });
      if (
        !generatePermission('booking', 'cancelpostservice') &&
        (detailData?.booking_status?.slug === 'finish' || detailData?.booking_status?.slug === 'service-done')
      ) {
        transformedOptions = transformedOptions.filter((data) => data?.value != 'cancel');
      }
      setCanReschedule(false);
      setStatusIssueOptions(transformedOptions);
    } else {
      let transformedOptions = map(issueStatusOptions, (tier) => {
        return { value: tier.value, label: tier.name };
      });
      if (
        !generatePermission('booking', 'cancelpostservice') &&
        (detailData?.booking_status?.slug == 'finish' || detailData?.booking_status?.slug === 'service-done')
      ) {
        transformedOptions = transformedOptions.filter((data) => data?.value != 'cancel');
      }
      setCanReschedule(true);
      setStatusIssueOptions(transformedOptions);
    }
  };

  const handleSubmitGenerateLink = async () => {
    if (!disabled && !disabledGLobal) {
      setIsProcess(true);
      const payload = getPayload();
      const api = new API('v2');
      api
        .put(`v2/intools/bookings/${id}/booking-revise/`, payload)
        .then(() => {
          handleResultNotification(true, 'success', 'Berhasil Update Snaplink ');
          setEditGenerateLink(false);
          setEditFreshdesk(false);
          setEditNote(false);
          setIsProcess(false);
        })
        .catch((e) => {
          const message = `API Error : ${e?.response?.data?.error?.message}`;
          setIsProcess(false);
          handleResultNotification(true, 'fail', 'Gagal Update Snaplink ' + message);
        });
    }
  };

  const handleSubmitJiraUrl = async () => {
    const api = new API('v2');
    let params = {
      ticket_url: jiraUrl,
    };
    if (jiraUrl === '') {
      handleResultNotification(true, 'fail', 'Jira URL Tidak Boleh Kosong');
      return;
    }
    api
      .put(`v2/intools/bookings/${detailData?.booking_code}/jira-ticket`, params)
      .then((result) => {
        jiraticketLinkToggle();
        handleResultNotification(true, 'success', 'Berhasil Update Jira Url ');
        location.reload();
      })
      .catch((err) => {
        handleResultNotification(true, 'fail', err?.response.data.error.message);
      });
  };

  const handleSubmit = async () => {
    if (!disabled && !disabledGLobal) {
      const validFreshdesk = editFreshdesk ? validateFreshdesk(formData?.freshdesk ?? '') : true;
      const validNote = editNote ? validateNote(formData?.note ?? '') : true;
      const validPaymentNote = editPaymentNote ? validatePaymentNote(formData?.payment_note ?? '') : true;
      const msgUpdateNote = isFleetMode ? 'Catatan Internal ' : 'Catatan ';
      const msgUpdate = editFreshdesk ? 'FreshDesk Ticket Link ' : editNote ? msgUpdateNote : '';
      if (validFreshdesk && validNote && validPaymentNote) {
        setIsProcess(true);
        const payload = getPayload();
        const api = new API('v2');
        api
          .put(`v2/intools/bookings/${id}/booking-revise/`, payload)
          .then(() => {
            handleResultNotification(true, 'success', 'Berhasil Update ' + msgUpdate);
            setEditGenerateLink(false);
            setEditFreshdesk(false);
            setEditNote(false);
            setIsProcess(false);
          })
          .catch((e) => {
            const message = `API Error : ${e?.response?.data?.error?.message}`;
            setIsProcess(false);
            handleResultNotification(true, 'fail', 'Gagal Update ' + msgUpdate + message);
          });
      }
    }
  };

  const getPayload = () => {
    let payload = {};

    if (editFreshdesk) {
      payload = {
        freshdesk_ticket_link: formData?.freshdesk ?? '',
      };
    } else if (editNote) {
      payload = {
        notes: formData?.note ?? '',
      };
    } else if (editPaymentNote) {
      payload = {
        payment_notes: formData?.payment_note ?? '',
      };
    }

    return payload;
  };

  const handleResultNotification = (status = false, type = '', message = '', without_reload = false) => {
    const notification = {
      status: status,
      type: type,
      message: message,
      without_reload: without_reload,
    };

    onResultNotification(notification);
  };

  const handleCanDownloadInvoice = () => {
    setCanDownloadInvoice(isFleetMode && detailData?.booking_status?.slug == 'review' ? false : true);
  };

  useEffect(() => {
    setDisabled(isProcess);
    onProcessEditDetail(isProcess);
  }, [isProcess]);

  useEffect(() => {
    handleChangeReason(1);
  }, [reasonData]);

  useEffect(() => {
    customerType !== 'b2b' && loadBookingStatusOptions();
  }, [bookingStatusOptions]);

  useEffect(() => {
    customerType == 'b2b' && loadIssueStatusOptions();
  }, [issueStatusOptions]);

  useEffect(() => {
    if (editNote || editFreshdesk || editGenerateLink) {
      onEditModeDetail(true);
    } else {
      onEditModeDetail(false);
    }
  }, [editNote, editFreshdesk, editGenerateLink]);

  useEffect(() => {
    if (editCar || editDocument) {
      setDefaultForm();
    }
  }, [editCar, editDocument]);

  useEffect(() => {
    handleCanDownloadInvoice();
  }, [detailData, isFleetMode]);

  useEffect(() => {
    const tempBookingDate = moment(detailData?.booking_date).format('YYYY-MM-DD');

    setDefaultForm();
    setBookingDate(tempBookingDate);
    setBookingId(detailData?.booking_id);
  }, [detailData]);

  return (
    <React.Fragment>
      <CustomModal modal={noSPKModal} toggle={() => setNoSPKModal(false)}>
        <div className="text-center">
          <p className="">
            Tidak dapat mengganti status menjadi <div className="text-bold">Order Selesai</div> tanpa melengkapi dokumen
            SPK atau Custom Documents. Harap lengkapi dokumen terlebih dahulu.
          </p>
          <Button color="primary" className="m-auto px-4 rounded-pill" size="lg" onClick={() => setNoSPKModal(false)}>
            Ok Mengerti
          </Button>
        </div>
      </CustomModal>
      <ConfirmationModal
        modal={showModal}
        isDisabled={modalDisable}
        toggle={() => setShowModal(!showModal)}
        header={`Yakin ingin mengubah status menjadi "${
          customerType !== 'b2b' ? selectedStatus : selectedStatusIssue
        }" ?`}
        onConfirm={() => handleConfirmationModal()}
        toggleClose={() => setShowModal(!showModal)}
      />
      <CustomModal modal={hasCancelModalConfirmation} toggle={() => setHasCancelModalConfirmation(false)}>
        <div className="text-center">
          <p className="">
            Booking ini sudah di status <b>{detailData?.booking_status?.name}</b>, jika ingin membatalkan dimohon untuk
            menginformasikan terlebih dahulu kepada Tim Finance.
          </p>
          <p>Terima kasih</p>
          <Button
            color="primary"
            className="m-auto px-4 rounded-pill"
            size="lg"
            onClick={() => {
              setCancelReasonModal(true);
              setHasCancelModalConfirmation(false);
            }}
          >
            Ok Mengerti
          </Button>
        </div>
      </CustomModal>
      <CustomModal modal={hasServiceDoneModalConfirmation} toggle={() => setHasServiceDoneModalConfirmation(false)}>
        <div className="text-center">
          <p className="">
            Anda tidak dapat mengubah status menjadi <b>Servis Selesai</b>, {serviceDoneModalType ?? ''}
          </p>
          <Button
            color="primary"
            className="m-auto px-4 rounded-pill"
            size="lg"
            onClick={() => setHasServiceDoneModalConfirmation(false)}
          >
            Ok Mengerti
          </Button>
        </div>
      </CustomModal>
      <BookingConfirmationModal
        title={bookingConfirmationModalTitle}
        status={bookingConfirmationModalStatus}
        modal={bookingConfirmationModal}
        toggle={toggleBookingConfirmationModal}
        handleOnClick={() => changeStatusBooking(customerType == 'b2b')}
      />
      <RescheduleModal
        modal={showRescheduleModal}
        bookingTime={bookingTime}
        bookingDate={bookingDate}
        reason={reason}
        toggle={rescheduleModalToggle}
        scheduleButtonLoading={scheduleButtonLoading}
        onChangeRescheduleTime={(e) => {
          setBookingTime(e.value);
        }}
        onChangeBookingDate={(e) => setBookingDate(e.target.value)}
        onChangeReason={(e) => setReason(e.target.value)}
        onReschedule={(e) => onReschedule(e)}
        hasInvalidDate={hasInvalidDate}
        messageInvalidDate={messageInvalidDate}
        bookingId={bookingId}
        priority={detailData?.queue_type?.slug}
      />
      <JiraTicketLinkModal
        modal={showJiraTicketLink}
        jiraUrl={jiraUrl}
        toggle={jiraticketLinkToggle}
        onChangeJiraUrl={(e) => setJiraUrl(e.target.value)}
        onSubmit={(e) => handleSubmitJiraUrl()}
        bookingId={detailData?.booking_code}
      />
      <CancelBookingReasonModal
        open={cancelReasonModal}
        toggle={cancelReasonModalToggle}
        dataList={reasonData}
        placeholder={'Jelaskan Alasan Lainnya'}
        selectedReason={selectedReason}
        selectReason={handleChangeReason}
        onSubmit={() => handleSubmitReasonModal()}
      />
      <CancelBookingInternalReasonModal
        open={cancelInternalReasonModal}
        disabled={modalDisable}
        toggle={cancelInternalReasonModalToggle}
        headerTitle={'Internal Notes'}
        placeholder={'Jelaskan Kronologi Pembatalan'}
        onChangeInternalNotes={(val) => setInternalReasonMsg(val)}
        onSubmit={() => handleSubmitInternalReasonModal()}
      />
      <ChangePriceBookingModal
        modal={priceBookingModal}
        toggle={togglePriceBookingModal}
        workshopsData={workshops}
        priceData={priceData}
        arrayDetail={arrayDetail}
        totalPrice={totalPrice}
        packageNames={packageNames}
        onSuccess={() => onPriceChangedSuccess()}
        onError={() => onPriceChangedError()}
      />
      <RevertStatusModal
        open={revertStatusReasonModal}
        toggle={revertStatusReasonModalToggle}
        headerTitle={'Alasan Perubahan Status'}
        placeholder={'Jelaskan Kronologi Perubahan Status'}
        onChangeRevertStatusNotes={(val) => setRevertStatusReasonMsg(val)}
        onSubmit={() => handleRevertStatusReasonModal()}
      />
      <GenerateSnapLink
        modal={editGenerateLink}
        payment={payment}
        toggle={toggleGenerateLinkModal}
        onSubmit={handleSubmitGenerateLink}
      ></GenerateSnapLink>
      <Card>
        <CardHeader className="bg-primary text-white mt-0">Detail</CardHeader>
        <CardBody className="pb-0">
          <DetailField title="Kode Booking" value={detailData?.booking_code ?? ''} />
          <dl className="row align-items-center">
            <div className="col-sm-3 text-sm-right">
              <dt>Booking Status</dt>
            </div>
            <>
              <dt
                className={`ml-2 text-sm-left ${generateStatusColor(
                  detailData?.booking_status?.slug ?? ''
                )} font-weight-bold`}
              >
                {detailData?.booking_status?.name ?? ''}
              </dt>
              <div className="text-sm-left font-weight-bold ml-1">
                {statusOptions?.length > 0 && detailData?.booking_status?.slug !== 'cancel' && customerType !== 'b2b' && (
                  <div className="ml-2 text-sm-left font-weight-bold">
                    <Select
                      name="status"
                      placeholder="Status Order"
                      isSearchable={true}
                      closeMenuOnSelect={true}
                      onChange={handleSelectStatus}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      options={statusOptions}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          width: state.selectProps.inputValue
                            ? `${state.selectProps.inputValue.length * 8}px`
                            : '200px',
                          minHeight: 'unset',
                        }),
                      }}
                    />
                  </div>
                )}
                {bookingStatusOptions.length > 0 &&
                  detailData?.booking_status?.slug == 'cancel' &&
                  generatePermission('booking', 'revert') &&
                  customerType !== 'b2b' && (
                    <Select
                      name="status"
                      placeholder="Status Order"
                      isSearchable={true}
                      closeMenuOnSelect={true}
                      onChange={handleSelectStatus}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      options={statusOptions}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          width: state.selectProps.inputValue
                            ? `${state.selectProps.inputValue.length * 8}px`
                            : '200px',
                          minHeight: 'unset',
                        }),
                      }}
                    />
                  )}
              </div>
            </>
          </dl>

          {customerType == 'b2b' && (
            <dl className="row align-items-center">
              <div className="col-sm-3 text-sm-right">
                <dt>Issue Status</dt>
              </div>
              <>
                <dt
                  className={`ml-2 text-sm-left ${generateStatusColor(
                    detailData?.booking_status?.slug ?? ''
                  )} font-weight-bold`}
                >
                  {detailData?.issue_status?.name ?? '-'}
                </dt>
                <div className="text-sm-left font-weight-bold ml-1">
                  {statusIssueOptions?.length > 0 && detailData?.booking_status?.slug !== 'cancel' && (
                    <div className="ml-2 text-sm-left font-weight-bold">
                      <Select
                        name="status"
                        placeholder="Issue Status"
                        isSearchable={true}
                        closeMenuOnSelect={true}
                        onChange={handleSelectIssueStatus}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        options={statusIssueOptions}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            width: state.selectProps.inputValue
                              ? `${state.selectProps.inputValue.length * 8}px`
                              : '200px',
                            minHeight: 'unset',
                          }),
                        }}
                      />
                    </div>
                  )}
                  {statusIssueOptions?.length > 0 &&
                    detailData?.booking_status?.slug == 'cancel' &&
                    generatePermission('booking', 'revert') && (
                      <Select
                        name="status"
                        placeholder="Issue Status"
                        isSearchable={true}
                        closeMenuOnSelect={true}
                        onChange={handleSelectIssueStatus}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        options={statusIssueOptions}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            width: state.selectProps.inputValue
                              ? `${state.selectProps.inputValue.length * 8}px`
                              : '200px',
                            minHeight: 'unset',
                          }),
                        }}
                      />
                    )}
                </div>
              </>
            </dl>
          )}

          {detailData?.booking_status?.slug == 'cancel' && (
            <>
              <DetailField title="Alasan Pembatalan" value={detailData?.cancel_reason?.description || '-'} />
              {detailData?.cancel_reason?.description == 'Lainnya' && (
                <DetailField title="Detail Lainnya" value={detailData?.cancel_reason?.cancel_note || '-'} />
              )}
              <DetailField title="Notes Batal" value={detailData?.cancel_reason?.internal_note || '-'} />
            </>
          )}
          <div style={{ position: 'relative' }}>
            <DetailField title="Waktu" value={`${detailData?.booking_date ?? ''}, ${detailData?.booking_time ?? ''}`} />
            <div>
              <>
                {canReschedule ? (
                  <img
                    className="button-pen"
                    src={PenIcon}
                    onClick={() => {
                      setShowRescheduleModal(!showRescheduleModal);
                    }}
                    style={{ position: 'absolute', top: 0, right: 0 }}
                  />
                ) : (
                  <></>
                )}
              </>
            </div>
          </div>
          <DetailField
            title="Tipe Servis"
            value={
              <>
                {serviceType === 'pickup_dropoff' ? detailData?.service_type?.name : 'Datang ke Bengkel'}
                {detailData?.queue_type?.slug === 'low-priority' ? (
                  <Badge className="ml-2 py-2 px-4 badge-info">Regular</Badge>
                ) : (
                  <Badge className="ml-2 py-2 px-4 badge-success">Prioritas</Badge>
                )}
              </>
            }
          />
          <DetailField
            title="Booking Origin"
            value={
              !detailData?.booking_origin
                ? '-'
                : detailData?.booking_origin?.length > 2
                ? detailData?.booking_origin.charAt(0).toUpperCase() + detailData?.booking_origin.slice(1)
                : detailData?.booking_origin
            }
          />

          {isFleetMode && <DetailField title="PIC Name" value={!detailData?.pic?.name ? '-' : detailData?.pic?.name} />}
          <DetailField
            title="Booking Source"
            value={`${detailData?.booking_source?.source_type === 'ecommerce' ? 'E-Commerce - ' : ''}${
              detailData?.booking_source?.name ?? '-'
            }`}
          />
          <DetailField
            title="Lokasi Mobil"
            value={
              <>
                {detailData?.service_type?.['customer-address']?.address1 || '-'}
                {detailData?.service_type?.['customer-address']?.label ||
                detailData?.service_type?.['customer-address']?.address2 ? (
                  <>
                    <br className="mt-1" />
                    {`(${
                      detailData?.service_type?.['customer-address']?.label ||
                      detailData?.service_type?.['customer-address']?.address2
                    })` ?? '-'}
                  </>
                ) : null}
                <br className="mt-1" />
                <a
                  href={detailData?.service_type?.['customer-address']?.gmaps_link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-underline document-workshop"
                >
                  {detailData?.service_type?.['customer-address']?.gmaps_link}
                </a>
              </>
            }
          />
          <div>
            <dl className="row align-items-center">
              <div className="col-sm-3 text-sm-right">
                <dt>
                  FD Ticket Link <img className="button-pen ml-2" src={FreshdeskIcon} />
                </dt>
              </div>
              {!editFreshdesk && (
                <>
                  <div className="col-sm-6 text-sm-left">
                    <dd className="mb-1">
                      {!formData?.freshdesk ? (
                        '-'
                      ) : (
                        <>
                          {' '}
                          {formData?.freshdesk}{' '}
                          <a href={formData?.freshdesk} target="_blank" rel="noreferrer">
                            <img className="button-pen ml-2" src={NewTabIcon} />
                          </a>
                        </>
                      )}
                    </dd>
                  </div>
                  <div className="col-sm-3 text-sm-right">
                    <img
                      className="button-pen"
                      src={PenIcon}
                      onClick={() => {
                        handleEditFreshdesk();
                      }}
                    />
                  </div>
                </>
              )}

              {editFreshdesk && (
                <>
                  <div className="col-sm-6 text-sm-left p-0">
                    <InputField
                      inputName="fresdesk_ticket_link"
                      placeholder={'Masukkan freshdesk ticket link'}
                      value={formData?.freshdesk}
                      disabled={disabled || disabledGLobal}
                      invalid={formData?.error_freshdesk ? true : false}
                      errorMessage={formData?.error_freshdesk ?? ''}
                      onChange={(e) => handleChangeFreshdesk(e)}
                    />
                  </div>
                  <div className="col-sm-3 text-sm-right section-action">
                    <Button
                      className="button-action no-width add"
                      disabled={disabled || disabledGLobal}
                      onClick={handleSubmit}
                    >
                      Simpan
                    </Button>
                    <Button
                      className="button-action no-width add"
                      disabled={disabled || disabledGLobal}
                      onClick={() => {
                        if (!disabled && !disabledGLobal) {
                          setDefaultForm();
                        }
                      }}
                    >
                      Batal
                    </Button>
                  </div>
                </>
              )}
            </dl>
          </div>
          {/* )} */}
          {detailData?.booking_type == 'b2b' && (
            <div>
              <dl className="row align-items-center">
                <div className="col-sm-3 text-sm-right">
                  <dt>
                    Jira Ticket Links <img className="button-pen ml-2" src={JiraIcon} />
                  </dt>
                </div>
                <>
                  <div className="col-sm-6 text-sm-left">
                    <dd className="mb-1">
                      {!jiraTicket.status ? (
                        '-'
                      ) : (
                        <>
                          {' '}
                          <span style={{ color: 'blue' }} className="font-weight-bold">
                            {jiraTicket.status}
                          </span>
                          <a href={jiraTicket.url} target="_blank" rel="noreferrer">
                            <img className="button-pen ml-2" src={NewTabIcon} />
                          </a>
                        </>
                      )}
                    </dd>
                    <dd className="mb-1">{!jiraTicket.status ? '-' : <> {jiraTicket.ticketKey}</>}</dd>
                  </div>
                  <div className="col-sm-3 text-sm-right">
                    <>
                      <img
                        className="button-pen"
                        src={PenIcon}
                        onClick={() => {
                          setJiraUrl('');
                          setShowJiraTicketLink(true);
                        }}
                      />
                    </>
                  </div>
                </>
              </dl>
            </div>
          )}
          <div>
            <dl className="row align-items-center">
              <div className="col-sm-3 text-sm-right">
                <dt>Catatan (Internal)</dt>
              </div>
              {!editNote && (
                <>
                  <div className="col-sm-6 text-sm-left">
                    <dd className="mb-1" style={{ whiteSpace: 'pre-line' }}>
                      {!formData?.note ? '-' : formData?.note}
                    </dd>
                  </div>
                  <div className="col-sm-3 text-sm-right">
                    <img
                      className="button-pen"
                      src={PenIcon}
                      onClick={() => {
                        handleEditNote();
                      }}
                    />
                  </div>
                </>
              )}

              {editNote && (
                <>
                  <div className="col-sm-6 text-sm-left p-0">
                    <InputField
                      inputName="notes"
                      inputType="textarea"
                      placeholder={isFleetMode ? 'Masukkan catatan internal' : 'Masukkan catatan'}
                      value={formData?.note}
                      disabled={disabled || disabledGLobal}
                      invalid={formData?.error_note ? true : false}
                      errorMessage={formData?.error_note ?? ''}
                      onChange={(e) => handleChangeNote(e)}
                    />
                  </div>
                  <div className="col-sm-3 text-sm-right section-action">
                    <Button
                      className="button-action no-width add"
                      disabled={disabled || disabledGLobal}
                      onClick={handleSubmit}
                    >
                      Simpan
                    </Button>
                    <Button
                      className="button-action no-width add"
                      disabled={disabled || disabledGLobal}
                      onClick={() => {
                        if (!disabled && !disabledGLobal) {
                          setDefaultForm();
                        }
                      }}
                    >
                      Batal
                    </Button>
                  </div>
                </>
              )}
            </dl>
          </div>

          <div>
            <dl className="row align-items-center">
              <div className="col-sm-3 text-sm-right">
                <dt>Catatan Pembayaran</dt>
              </div>
              {!editPaymentNote && (
                <>
                  <div className="col-sm-6 text-sm-left">
                    <dd className="mb-1" style={{ whiteSpace: 'pre-line' }}>
                      {!formData?.payment_note ? '-' : formData?.payment_note}
                    </dd>
                  </div>
                  <div className="col-sm-3 text-sm-right">
                    <img
                      className="button-pen"
                      src={PenIcon}
                      onClick={() => {
                        handleEditPaymentNote();
                      }}
                    />
                  </div>
                </>
              )}

              {editPaymentNote && (
                <>
                  <div className="col-sm-6 text-sm-left p-0">
                    <InputField
                      inputName="payment_notes"
                      inputType="textarea"
                      placeholder="Masukkan catatan pembayaran"
                      value={formData?.payment_note}
                      disabled={disabled || disabledGLobal}
                      invalid={formData?.error_payment_note ? true : false}
                      errorMessage={formData?.error_payment_note ?? ''}
                      onChange={(e) => handleChangePaymentNote(e)}
                    />
                  </div>
                  <div className="col-sm-3 text-sm-right section-action">
                    <Button
                      className="button-action no-width add"
                      disabled={disabled || disabledGLobal}
                      onClick={handleSubmit}
                    >
                      Simpan
                    </Button>
                    <Button
                      className="button-action no-width add"
                      disabled={disabled || disabledGLobal}
                      onClick={() => {
                        if (!disabled && !disabledGLobal) {
                          setDefaultForm();
                        }
                      }}
                    >
                      Batal
                    </Button>
                  </div>
                </>
              )}
            </dl>
          </div>

          {detailData?.booking_status?.slug == 'service-done' && detailData?.cancel_reason && (
            <DetailField
              title="Catatan Status"
              value={detailData?.status_notes && detailData?.status_notes !== '' ? detailData?.status_notes : '-'}
            />
          )}

          {false && (
            <BookingDetailDetailsDocument
              id={id}
              detailData={detailData}
              editDetail={editDetail}
              editCar={editCar}
              isFleetMode={isFleetMode}
              disabledGLobal={disabledGLobal}
              onResultNotification={onResultNotification}
              onEditModeDocument={handleEditDocument}
              onProcessEditDocument={handleProcessEditDocument}
            ></BookingDetailDetailsDocument>
          )}
        </CardBody>

        {generatePermission('booking', 'invoice') && customerType == 'b2c' && (
          <CardFooter className="p-3">
            <Button color="purple" block onClick={downloadCustomerInvoice} disabled={loadingInvoice}>
              <img src={IconDownload} className="mr-2 mb-1" />
              <span>Download Invoice </span>
              {loadingInvoice && (
                <span>
                  <Spinner color="light" size="sm">
                    Loading...
                  </Spinner>
                </span>
              )}
            </Button>
          </CardFooter>
        )}
      </Card>
    </React.Fragment>
  );
}

export default BookingDetailDetails;
