import API from '@utils/API';

class BookingService {
  async getCalculatePrice(payload) {
    try {
      const api = new API('v2');
      const response = await api.post('v3/intools/booking/calculate_price', payload);
      return response;
    } catch (error) {
      throw error.response || new Error('Unexpected error occurred');
    }
  }

  async createBookingB2BDraft(payload) {
    try {
      const api = new API('v2');
      const response = await api.post('v3/intools/booking/b2b-draft', payload);
      return response;
    } catch (error) {
      throw error.response || new Error('Unexpected error occurred');
    }
  }

  async updateBookingB2BDraft(payload) {
    try {
      const api = new API('v2');
      const response = await api.patch('v3/intools/booking/b2b-draft', payload);
      return response;
    } catch (error) {
      throw error.response || new Error('Unexpected error occurred');
    }
  }
}

export default new BookingService();
