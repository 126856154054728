import React from 'react';
import { Form, FormGroup, Input, Button } from 'reactstrap';

const ActionFilterService = ({ serviceName, sku, onChangeServiceName, onSearch, onChangeSku }) => (
  <Form inline>
    <FormGroup className="mb-2 mb-sm-0">
      <Input
        placeholder="Service Name"
        className="br-0 fixed-fc-height width-250"
        value={serviceName}
        onChange={onChangeServiceName}
      />
    </FormGroup>
    <FormGroup className="mb-2 mb-sm-0">
      <Input placeholder="Part Number" className="br-0 fixed-fc-height" value={sku} onChange={onChangeSku} />
    </FormGroup>
    <Button className="btn-info btn-square" onClick={onSearch}>
      Search
    </Button>
  </Form>
);

export default ActionFilterService;
