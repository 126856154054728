import React, { useState, useEffect, useMemo } from 'react';
import { Col, Button, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { assign, find, map, isEmpty } from 'lodash';
import moment from 'moment';
import API from '@utils/API';
import qs from 'query-string';
import Helper from '@helpers/Helper';
import Loading from '@components/loading/Loading';
import userPermission from '@utils/userPermission';
import DataTable from 'react-data-table-component';
import ProductAdd from '@components/masters/product/ProductAdd';
import ProductImport from '@components/masters/product/ProductImport';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import ItemsModal from '@components/modal/ItemsModal';
import CustomModal from '@components/modal/CustomModal';

const ProductList = (props) => {
  const { onResultNotification } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const history = useHistory();
  const qp = qs.parse(history?.location?.search);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [brandOptions, setBrandOptions] = useState([]);
  const [compatibilityOptions, setCompatibilityOptions] = useState([]);
  const [componentOptions, setComponentOptions] = useState([]);
  const [resetSorting, setResetSorting] = useState(false);
  const [disabledExport, setDisabledExport] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [hasModalAddProduct, setHasModalAddProduct] = useState(false);
  const [hasModalImportCSV, setHasModalImportCSV] = useState(false);
  const [showModalCompatibility, setShowModalCompatibility] = useState(false);
  const [compatibilityData, setCompatibilityData] = useState({});
  const [showModalAlias, setShowModalAlias] = useState(false);
  const [aliasData, setAliasData] = useState({});
  const [modalExpoprt, setModalExport] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('master', menu) || generatePermission('master', 'all'));
  };

  const allowList = allowPermission('productall') || allowPermission('productlist');
  const allowAdd = allowPermission('productall') || allowPermission('productcreate');
  const allowShow = allowPermission('productall') || allowPermission('productshow');
  const allowImport = allowPermission('productall') || allowPermission('productimport');
  const allowExport = allowPermission('productall') || allowPermission('productexport');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) =>
        (row?.car_compatibilities?.length > row?.alias?.length
          ? row?.car_compatibilities?.length
          : row?.alias?.length) >= 3,
      style: {
        minHeight: '100px',
      },
    },
    {
      when: (row) =>
        (row?.car_compatibilities?.length > row?.alias?.length
          ? row?.car_compatibilities?.length
          : row?.alias?.length) <= 2,
      style: {
        minHeight: '70px',
      },
    },
  ];

  const handleClickAlias = (data) => {
    setShowModalAlias(true);
    let datas = [];
    map(data, (item) => {
      datas.push({
        label: item,
        value: item,
      });
    });
    setAliasData(datas);
  };

  const handleClickCarCompatibility = (data) => {
    setShowModalCompatibility(true);
    let datas = [];
    map(data, (item) => {
      datas.push({
        label: item?.car_model + ' - ' + item?.car_variant,
        value: item?.car_compatibility_id + ' ' + item?.car_brand,
      });
    });
    setCompatibilityData(datas);
  };

  const columns = useMemo(
    () => [
      {
        name: 'Product ID',
        selector: (row) => row?.product_id ?? '-',
        minWidth: '130px',
        sortable: true,
        sortField: 'id',
      },
      {
        name: 'Brand',
        selector: (row) => row?.product_brand ?? '-',
        minWidth: '200px',
        sortable: true,
        sortField: 'brand',
      },
      {
        name: 'Product Name',
        minWidth: '350px',
        selector: (row) => row?.product_name ?? '-',
        sortable: true,
        sortField: 'name',
        wrap: true,
      },
      {
        name: 'Display Name',
        minWidth: '350px',
        selector: (row) => row?.product_display_name ?? '-',
        sortable: true,
        sortField: 'display_name',
        wrap: true,
      },
      {
        name: 'Alias',
        minWidth: '350px',
        selector: (row) => (row?.alias?.length > 0 ? row?.alias[0] : ''),
        cell: (row) => (
          <p className="my-1">
            {row?.alias?.length > 0 ? (
              <>
                {map(row?.alias, (item, index) => {
                  const itemLength = row?.alias.length - 1;
                  const itemToShow = 3;

                  return (
                    <>
                      <span>{index < itemToShow ? item : null}</span>
                      {itemLength !== index && index < itemToShow ? <br /> : null}
                    </>
                  );
                })}
                <a
                  onClick={() => {
                    handleClickAlias(row?.alias);
                  }}
                  style={{ color: '#0D6EFD', textDecoration: 'underline' }}
                >
                  {row?.alias?.length > 3 ? `+${row?.alias?.length - 3} Lainnya` : null}
                </a>
              </>
            ) : (
              '-'
            )}
          </p>
        ),
        sortable: true,
        sortField: 'alias',
        wrap: true,
      },
      {
        name: 'Component',
        minWidth: '350px',
        selector: (row) => row?.component?.name ?? '-',
        sortable: true,
        sortField: 'component',
        wrap: true,
      },
      {
        name: 'Car Compatibility',
        selector: (row) => (row?.car_compatibilities?.length > 0 ? row?.car_compatibilities[0] : ''),
        cell: (row) => (
          <p className="my-1">
            {row?.car_compatibilities?.length > 0 ? (
              <>
                {map(row?.car_compatibilities, (item, index) => {
                  const itemLength = row?.car_compatibilities.length - 1;
                  const itemToShow = 3;

                  return (
                    <>
                      <span>{index < itemToShow ? item?.car_model + ' - ' + item?.car_variant : null}</span>
                      {itemLength !== index && index < itemToShow ? <br /> : null}
                    </>
                  );
                })}
                <a
                  onClick={() => {
                    handleClickCarCompatibility(row?.car_compatibilities);
                  }}
                  style={{ color: '#0D6EFD', textDecoration: 'underline' }}
                >
                  {row?.car_compatibilities?.length > 3 ? `+${row?.car_compatibilities?.length - 3} Lainnya` : null}
                </a>
              </>
            ) : (
              '-'
            )}
          </p>
        ),
        minWidth: '300px',
        sortable: true,
        sortField: 'compatibility',
      },
      {
        name: 'Category',
        selector: (row) => row?.product_category ?? '-',
        minWidth: '200px',
        sortable: true,
        sortField: 'category',
      },
      {
        name: 'Status',
        selector: (row) =>
          row.product_status == 'Active' ? (
            <span className="badge-custom badge-custom-success">{row.product_status ?? '-'}</span>
          ) : (
            <span className="badge-custom badge-custom-danger">{row.product_status ?? '-'}</span>
          ),
        minWidth: '120px',
        center: true,
        sortable: true,
        sortField: 'status',
      },
    ],
    [resetSorting]
  );

  const statusOptions = [
    { value: '', label: 'Semua' },
    { value: 'active', label: 'Aktif' },
    { value: 'inactive', label: 'Tidak Aktif' },
  ];

  const dataFilter = [
    {
      id: 'filter_text_search',
      type: 'text',
      name: 'search',
      name_field: 'search',
      value: '',
      placeholder: 'Cari Nama Product',
    },
    {
      id: 'filter_text_display_search',
      type: 'text',
      name: 'display_search',
      name_field: 'display_search',
      value: '',
      placeholder: 'Cari Display Name Product',
    },
    {
      id: 'filter_alias_search',
      type: 'text',
      name: 'alias_search',
      name_field: 'alias_search',
      value: '',
      placeholder: 'Cari Alias',
    },
    {
      id: 'filter_select_compatibility',
      type: 'select',
      name: 'compatibility',
      name_field: 'compatibility',
      options: compatibilityOptions,
      value: '',
      placeholder: 'Compatibility',
    },
    {
      id: 'filter_select_component',
      type: 'select',
      name: 'component',
      name_field: 'component',
      options: componentOptions,
      value: '',
      placeholder: 'Component',
    },
    {
      id: 'filter_select_brand',
      type: 'select',
      name: 'brand',
      name_field: 'brand',
      options: brandOptions,
      value: '',
      placeholder: 'Brand',
    },
    {
      id: 'filter_select_category',
      type: 'select',
      name: 'category',
      name_field: 'category',
      options: categoryOptions,
      value: '',
      placeholder: 'Category',
    },
    {
      id: 'filter_select_status',
      type: 'select',
      name: 'status',
      name_field: 'status',
      options: statusOptions,
      value: '',
      placeholder: 'Status',
    },
  ];

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchProduct() {
    setLoading(true);
    setDisabledExport(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/master/products/`, { params });
      setData(response?.data?.data?.products ?? []);
      setTotalRows(response?.data?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      let msg = error?.response?.data?.error?.message ?? '';
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      setDisabledExport(false);
      handleResultNotification(true, 'fail', 'Gagal mendapatakan list master produk ' + msg);
    }
  }

  const validateExport = (data) => {
    delete data.page;
    delete data.limit;
    delete data.sort;
    delete data.order;

    let isValid = true;

    if (!Object.values(data)?.some((item) => item)) {
      isValid = false;
    }

    return isValid;
  };

  async function handleDownloadCSV() {
    const params = getParamData();
    const isValid = validateExport(params);
    const paramsUrl = `?search=${params?.search ?? ''}&display_search=${params?.display_search ?? ''}&sort=${
      params?.sort ?? ''
    }&order=${params?.order ?? ''}&brand=${params?.brand ?? ''}&category=${params?.category ?? ''}&status=${
      params?.status ?? ''
    }&compatibility=${params?.compatibility ?? ''}&alias=${params?.alias ?? ''}&components_ids=${
      params?.component_ids ?? ''
    }&type=file`;

    if (!isValid) {
      setModalExport(true);
    } else {
      setDisabledExport(true);
      setLoadingExport(true);
      try {
        const response = await api.post(`v2/intools/master/products/export` + paramsUrl, {
          responseType: 'blob',
        });
        if (response?.data) {
          const file = new Blob([response?.data], { type: 'text/csv' });
          const fileURL = URL.createObjectURL(file);
          let link = document.createElement('a');
          link.href = fileURL;
          link.download = `master_product_${moment().format('HHmmss')}.csv`;
          link.click();
        }
        setDisabledExport(false);
        setLoadingExport(false);
      } catch (error) {
        let msg = error?.response?.data?.error?.message ?? '';
        setDisabledExport(false);
        setLoadingExport(false);
        handleResultNotification(true, 'fail', 'CSV Gagal diexport' + msg);
      }
    }
  }

  async function fetchCompatibilityOptions() {
    let listCompability = [{ value: '', label: 'Any' }];

    try {
      const response = await api.get(`v2/intools/master/cars/all/`);
      if (response?.data?.data) {
        let result = map(response?.data?.data, (item) => {
          return {
            value: item?.car_brand + ' ' + item?.car_id,
            label: item?.car_model + ' - ' + item?.car_variant,
            id: item?.car_id,
          };
        });
        result.splice(0, 0, { value: '', label: 'Any' });
        listCompability = result;
      }

      setCompatibilityOptions(listCompability);
    } catch (error) {
      setCompatibilityOptions(listCompability);
    }
  }

  async function fetchComponentOptions() {
    let listComponents = [{ value: '', label: 'Any' }];

    try {
      const response = await api.get(`v2/intools/packages/components`);
      if (response?.data?.data?.components) {
        let result = map(response?.data?.data?.components, (item) => {
          return { value: item?.id, label: item?.name ?? '-' };
        });
        result.splice(0, 0, { value: '', label: 'Any' });
        listComponents = result;
      }

      setComponentOptions(listComponents);
    } catch (error) {
      setComponentOptions(listComponents);
    }
  }

  async function fetchBrandOptions() {
    let listBrand = [{ value: '', label: 'Any' }];

    try {
      const response = await api.get(`v2/intools/master/product-brands/all/?sort=name&order=asc`);
      if (response?.data?.data) {
        let resultListBrand = map(response?.data?.data, (item) => {
          return { value: item?.brand_id, label: item?.brand_name ?? '-' };
        });
        resultListBrand.splice(0, 0, { value: '', label: 'Any' });
        listBrand = resultListBrand;
      }

      setBrandOptions(listBrand);
    } catch (error) {
      setBrandOptions(listBrand);
    }
  }

  async function fetchCategories() {
    let listCategory = [{ value: '', label: 'Any' }];

    try {
      const response = await api.get(`v2/intools/products/categories/`);
      if (response?.data?.data?.categories) {
        let resultListCategory = map(response?.data?.data?.categories, (item) => {
          return { value: item?.id, label: item?.name ?? '-' };
        });
        resultListCategory.splice(0, 0, { value: '', label: 'Any' });
        listCategory = resultListCategory;
      }

      setCategoryOptions(listCategory);
    } catch (error) {
      setCategoryOptions(listCategory);
    }
  }

  const getParamData = () => {
    let params = {
      search: filtered?.search ?? '',
      display_search: filtered?.display_search ?? '',
      alias: filtered?.alias_search ?? '',
      compatibility: filtered?.compatibility?.id ?? '',
      component_ids: filtered?.component?.value ?? '',
      brand: filtered?.brand?.value ?? '',
      category: filtered?.category?.value ?? '',
      status: filtered?.status?.value ?? '',
      page: page,
      limit: pageLimit,
    };

    if (sortingData) {
      params.sort = sortingData?.sort;
      params.order = sortingData?.order;
    }

    return params;
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };
  const handleChangeSorting = (column, sortDirection) => {
    setSortingData({
      sort: column?.sortField ?? '',
      order: sortDirection ?? '',
    });
  };

  const handleApplyFilters = async (params) => {
    await setFiltered(params);
    if (!isEmpty(qp)) {
      const payload = {
        ...qp,
        search: params?.search,
        display_search: params?.display_search,
        alias_search: params?.alias_search,
        compatibility: params?.compatibility?.value,
        component_ids: params?.component?.value,
        status: params?.status?.value,
        brand: params?.brand?.value,
        category: params?.category?.value,
      };

      history.push({ search: qs.stringify(payload) });
    }
  };

  const handleResetFilters = () => {
    setFilters(dataFilter);
    setFiltered({});
    delete qp.search;
    delete qp.display_search;
    delete qp.alias_search;
    delete qp.compatibility;
    delete qp.component;
    delete qp.status;
    delete qp.brand;
    delete qp.category;
    history.push({ search: qs.stringify(qp) });
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.data?.pagination?.total_page) {
      setPage(response?.data?.data?.pagination?.total_page);
      setLoading(false);
      setDisabledExport(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setDisabledExport(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleAddProduct = () => {
    setHasModalAddProduct(true);
  };

  const handleImportCSV = () => {
    setHasModalImportCSV(true);
  };

  const handleDetailProduct = (row) => {
    if (allowShow) {
      history.push(`/masters/product/detail/${row?.product_id}`);
    }
  };

  const handleSubmitAddProduct = (status, error) => {
    const errorMsg = error ? 'Gagal Tambah Data Master Produk, ' + error : 'Gagal Tambah Data Master Produk';
    if (status) {
      handleMethodFetchProduct();
    }
    setHasModalAddProduct(false);
    handleResultNotification(
      true,
      status ? 'success' : 'fail',
      status ? 'Berhasil Tambah Data Master Produk' : errorMsg
    );
  };

  const handleSubmitImportCSV = (status, error) => {
    const errorMsg = error ? 'Gagal - CSV Gagal Diupload, ' + error : 'Gagal - CSV Gagal Diupload';
    if (status) {
      handleMethodFetchProduct();
    }
    setHasModalImportCSV(false);
    handleResultNotification(true, status ? 'success' : 'fail', status ? 'Berhasil - CSV Berhasil Diimport' : errorMsg);
  };

  const handleChangeFetchDropdown = () => {
    if (filters.length >= 7) {
      filters[3].options = compatibilityOptions;
      filters[4].options = componentOptions;
      filters[5].options = brandOptions;
      filters[6].options = categoryOptions;
      setFilters([...filters]);
    }

    setDefaultFilters();
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodFetchProduct();
  };

  const handleMethodFetchProduct = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchProduct();
    }
  };

  const resetProduct = () => {
    setResetSorting(true);
    setSortingData({});
  };

  async function setDefaultFilters() {
    const withParams =
      qp?.brand || qp?.category || qp?.search || qp?.display_search || qp?.brand == '' || qp?.category == ''
        ? true
        : false;
    const valueStatus =
      qp?.status == 'active'
        ? qp?.status
        : qp?.status == 'inactive'
        ? qp?.status
        : qp?.status == ''
        ? ''
        : withParams
        ? null
        : '';
    const valueCompatibility = qp?.compatibility
      ? qp?.compatibility == '-'
        ? qp?.compatibility
        : Number(qp?.compatibility) ?? undefined
      : qp?.compatibility == ''
      ? ''
      : undefined;
    const valueComponent = qp?.component
      ? qp?.component == '-'
        ? qp?.component
        : Number(qp?.component) ?? undefined
      : qp?.component == ''
      ? ''
      : undefined;
    const valueBrand = qp?.brand
      ? qp?.brand == '-'
        ? qp?.brand
        : Number(qp?.brand) ?? undefined
      : qp?.brand == ''
      ? ''
      : undefined;
    const valueCategory = qp?.category
      ? qp?.category == '-'
        ? qp?.category
        : Number(qp?.category) ?? undefined
      : qp?.category == ''
      ? ''
      : undefined;
    const filteredSearch = qp?.search ?? '';
    const filteredDisplaySearch = qp?.display_search ?? '';
    const filteredAliasSearch = qp?.alias_search ?? '';
    const filteredCompatibility = find(compatibilityOptions, { value: valueCompatibility });
    const filteredComponent = find(componentOptions, { value: valueComponent });
    const filteredStatus = find(statusOptions, { value: valueStatus });
    const filteredBrand = find(brandOptions, { value: valueBrand ?? {} });
    const filteredCategory = find(categoryOptions, { value: valueCategory ?? {} });

    let paramsFiltered = {};
    if (qp?.search) await assign(paramsFiltered, { search: filteredSearch });
    if (qp?.display_search) await assign(paramsFiltered, { display_search: filteredDisplaySearch });
    if (qp?.alias_search) await assign(paramsFiltered, { alias_search: filteredAliasSearch });
    if (qp?.status || filteredStatus?.value) await assign(paramsFiltered, { status: filteredStatus });
    if (qp?.compatibility) await assign(paramsFiltered, { brand: filteredCompatibility });
    if (qp?.component) await assign(paramsFiltered, { brand: filteredComponent });
    if (qp?.brand) await assign(paramsFiltered, { brand: filteredBrand });
    if (qp?.category) await assign(paramsFiltered, { category: filteredCategory });

    filters[0].value = filteredSearch;
    filters[1].value = filteredDisplaySearch;
    filters[2].value = filteredAliasSearch;
    filters[3].value = filteredCompatibility;
    filters[4].value = filteredComponent;
    filters[5].value = filteredBrand;
    filters[6].value = filteredCategory;
    filters[7].value = filteredStatus;

    setFilters([...filters]);
    setFiltered(paramsFiltered);
  }

  const toogleAddProduct = () => setHasModalAddProduct(!hasModalAddProduct);
  const toogleImportCSV = (success) => {
    if (success == true) {
      handleMethodFetchProduct();
    }
    setHasModalImportCSV(!hasModalImportCSV);
  };

  useEffect(() => {
    if (page && pageLimit && !firstLoad) {
      fetchProduct();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    if (firstLoad && filtered) {
      fetchProduct();
    } else {
      resetProduct();
    }
  }, [filtered]);

  useEffect(() => {
    if (brandOptions?.length && categoryOptions?.length && componentOptions?.length && compatibilityOptions?.length) {
      handleChangeFetchDropdown();
    }
  }, [brandOptions, categoryOptions, componentOptions, compatibilityOptions]);

  useEffect(() => {
    fetchCompatibilityOptions();
    fetchComponentOptions();
    fetchBrandOptions();
    fetchCategories();
    setFilters(dataFilter);
  }, []);

  return (
    <React.Fragment>
      <CustomModal modal={modalExpoprt} toggle={() => setModalExport(false)}>
        <div className="text-center">
          <p className="">Export data hanya dapat dilakukan jika anda sudah melakukan filtering data.</p>
          <Button color="primary" className="m-auto px-4 rounded-pill" size="lg" onClick={() => setModalExport(false)}>
            Ok Mengerti
          </Button>
        </div>
      </CustomModal>
      <ItemsModal
        data={compatibilityData}
        modal={showModalCompatibility}
        title={'List Car Compatibility'}
        placeholder={'Car Compatibility'}
        labelData={'Car Compatibility'}
        toggle={() => {
          setShowModalCompatibility(!showModalCompatibility);
        }}
      />
      <ItemsModal
        data={aliasData}
        modal={showModalAlias}
        title={'List Alias'}
        placeholder={'Alias'}
        labelData={'Alias'}
        toggle={() => {
          setShowModalAlias(!showModalAlias);
        }}
      />
      <ProductAdd modal={hasModalAddProduct} onSubmit={handleSubmitAddProduct} toggle={toogleAddProduct} />
      <ProductImport modal={hasModalImportCSV} onSubmit={handleSubmitImportCSV} toggle={toogleImportCSV} />
      <Col sm={12} md={12} className="p-0 mt-0 section-action">
        {allowAdd && (
          <Button disabled={loading} className="button-action add" onClick={handleAddProduct}>
            {'Tambah'}
          </Button>
        )}
        {allowExport && (
          <Button disabled={disabledExport} className="button-action primary" onClick={handleDownloadCSV}>
            {'Export CSV '}
            {loadingExport && (
              <>
                <Spinner color="light" size="sm">
                  Loading...
                </Spinner>{' '}
                &nbsp;
              </>
            )}
          </Button>
        )}
        {allowImport && (
          <Button disabled={disabledExport} className="button-action export" onClick={handleImportCSV}>
            {'Import CSV'}
          </Button>
        )}
      </Col>
      {filters?.length !== 0 && allowList && (
        <Col sm={12} md={12} className="p-0 mt-4">
          <ActionFilterCustom
            filters={filters}
            loading={loading}
            onApplyFilters={handleApplyFilters}
            onResetFilters={handleResetFilters}
          ></ActionFilterCustom>
        </Col>
      )}

      <Col sm={12} md={12} className="p-0 mt-4">
        <DataTable
          persistTableHead
          highlightOnHover
          sortServer
          noHeader={true}
          progressPending={loading}
          data={data}
          className="table dt-responsive table-custom"
          noDataComponent={noDataComponent}
          progressComponent={<Loading />}
          customStyles={customStylesHeaderTable}
          onSort={handleChangeSorting}
          onRowClicked={(row) => handleDetailProduct(row)}
          columns={allowList ? columns : []}
          conditionalRowStyles={conditionalRowStyles}
        />
      </Col>
      <Col sm={12} md={12} className="p-0 mt-2">
        {data?.length >= 1 && !loading && allowList && (
          <CustomPagination
            page={page}
            pageLimit={pageLimit}
            totalRows={data?.length ?? 0}
            totalAllRows={totalRows}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
            handleChangeRowPerPage={handleChangeRowPerPage}
          ></CustomPagination>
        )}
      </Col>
    </React.Fragment>
  );
};

export default ProductList;
