import React, { useState, useEffect } from 'react';
import { Col, Label, Row, Button, Input } from 'reactstrap';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import { regexOnlyAllowNumber } from '@utils/Constants';
import Helper from '@helpers/Helper';

const SetupVoucherForm = (props) => {
  const { dataDetail, handleNext, handleBack, discountForOptions, disableButton, disableForm } = props;
  const [detailData, setDetailData] = useState({});
  const isCampaignB2B =
    dataDetail?.customer_eligibility === 'all_customer_b2b' || dataDetail?.customer_segment?.value === 'customer-b2b';
  const handleDefaultData = () => {
    const defaultData = {
      discount_type: dataDetail?.discount_type,
      error_discount_type: dataDetail?.error_discount_type,
      discount_for: dataDetail?.discount_for,
      error_discount_for: dataDetail?.error_discount_for,
      discount_value: dataDetail?.discount_value,
      error_discount_value: dataDetail?.error_discount_value,
      max_discount: dataDetail?.max_discount,
      error_max_discount: dataDetail?.error_max_discount,
    };
    setDetailData(defaultData);
  };

  const handleParentData = () => {
    dataDetail.discount_type = detailData?.discount_type;
    dataDetail.error_discount_type = detailData?.error_discount_type;
    dataDetail.discount_for = detailData?.discount_for;
    dataDetail.error_discount_for = detailData?.error_discount_for;
    dataDetail.discount_value = detailData?.discount_value;
    dataDetail.error_discount_value = detailData?.error_discount_value;
    dataDetail.max_discount = detailData?.max_discount;
    dataDetail.error_max_discount = detailData?.error_max_discount;

    return dataDetail;
  };

  const handleChangeDiscountType = (value) => {
    const valueDiscountType = value ?? null;
    validateDiscountType(valueDiscountType);
  };

  const handleChangeDiscountFor = (e) => {
    const valueDiscountFor = e?.value ? e : null;
    validateDiscountFor(valueDiscountFor);
  };

  const handleChangeDiscountValue = (value) => {
    const valueDiscountValue = value !== ' ' ? Helper.formatMoneyOnField(value) : detailData?.discount_value ?? '';
    validateDiscountValue(valueDiscountValue);
  };

  const handleChangeMaxDiscount = (value) => {
    const valueMaxDiscount = value !== ' ' ? Helper.formatMoneyOnField(value) : detailData?.max_discount ?? '';
    validateMaxDiscount(valueMaxDiscount);
  };

  const validateDiscountType = (value) => {
    let passValidate = true;

    if (!value) {
      passValidate = false;
      detailData.error_discount_type = 'Pilih salah satu terlebih dahulu';
    } else {
      detailData.error_discount_type = '';
      detailData.error_discount_value = '';
      detailData.error_max_discount = '';
    }

    detailData.discount_type = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateDiscountFor = (value) => {
    let passValidate = true;
    if (!value?.value) {
      passValidate = false;
      detailData.error_discount_for = 'Pilih Discount For terlebih dahulu';
    } else {
      detailData.error_discount_for = '';
    }

    detailData.discount_for = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateDiscountValue = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      detailData.error_discount_value = 'Field tidak boleh kosong';
    } else if (
      detailData?.discount_type == 'amount' &&
      detailData?.discount_for?.value &&
      (value?.formatMoney < 0 || value?.formatMoney > 999999999)
    ) {
      passValidate = false;
      detailData.error_discount_value = 'Minimal Rp 0 dan maximal Rp 999.999.999';
    } else if (
      detailData?.discount_type == 'percent' &&
      detailData?.discount_for?.value &&
      (value?.formatMoney < 0 || value?.formatMoney > 100)
    ) {
      passValidate = false;
      detailData.error_discount_value = 'Minimal 0% dan maximal 100%';
    } else {
      detailData.error_discount_value = '';
    }

    detailData.discount_value = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateMaxDiscount = (value) => {
    let passValidate = true;
    if (!value && detailData?.discount_type == 'percent') {
      passValidate = false;
      detailData.error_max_discount = 'Field tidak boleh kosong';
    } else if (
      detailData?.discount_type == 'percent' &&
      detailData?.discount_for?.value &&
      (value?.formatMoney < 0 || value?.formatMoney > 999999999)
    ) {
      passValidate = false;
      detailData.error_max_discount = 'Minimal Rp 0 dan maximal Rp 999.999.999';
    } else {
      detailData.error_max_discount = '';
    }

    detailData.max_discount = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const handleActionNext = () => {
    const passValidateDiscountType = validateDiscountType(detailData?.discount_type);
    const passValidateDiscountFor = !isCampaignB2B ? validateDiscountFor(detailData?.discount_for) : true;
    const passValidateDiscountValue = validateDiscountValue(detailData?.discount_value);
    const passValidateMaxDiscount = validateMaxDiscount(detailData?.max_discount);
    let paramsPerItem = {
      status: passValidateDiscountType && passValidateDiscountFor && passValidateDiscountValue ? true : false,
      data: handleParentData(),
    };
    let params = {
      status:
        passValidateDiscountType && passValidateDiscountFor && passValidateDiscountValue && passValidateMaxDiscount
          ? true
          : false,
      data: handleParentData(),
    };
    handleNext(detailData?.discount_for?.value === 'per-item' ? paramsPerItem : params);
  };

  const handleActionBack = () => {
    handleBack();
  };

  useEffect(() => {
    handleDefaultData();
    if (isCampaignB2B) {
      const valueDiscFor = {
        value: 'selected-items',
        label: 'Selected Items',
      };
      handleChangeDiscountFor(valueDiscFor);
    }
    if (dataDetail?.discount_type) {
      handleChangeDiscountType(dataDetail?.discount_type);
    }
    if (dataDetail?.discount_value?.formatMoneyString) {
      handleChangeDiscountValue(dataDetail?.discount_value?.formatMoneyString);
    }
    if (dataDetail?.max_discount?.formatMoneyString) {
      handleChangeMaxDiscount(dataDetail?.max_discount?.formatMoneyString);
    }
  }, [dataDetail]);

  return (
    <>
      <Row>
        <Col sm={12} md={12} className="mb-3">
          <Row>
            <Label sm={4} lg={2} className="text-left label-required">
              Tipe Diskon
            </Label>
            <Col sm={12} lg={10}>
              <Row>
                <Col sm={12} md={2}>
                  <Label className="ml-4 mt-2 mb-0">
                    <Input
                      type="radio"
                      defaultChecked={detailData?.discount_type == 'amount' ? true : false}
                      checked={detailData?.discount_type == 'amount' ? true : false}
                      onClick={() => handleChangeDiscountType('amount')}
                    />
                    Amount Type
                  </Label>
                </Col>
                <Col sm={12} md={4}>
                  <Label className="ml-4 mt-2 mb-0">
                    <Input
                      type="radio"
                      defaultChecked={detailData?.discount_type == 'percent' ? true : false}
                      checked={detailData?.discount_type == 'percent' ? true : false}
                      onClick={() => handleChangeDiscountType('percent')}
                    />
                    Percentage Type
                  </Label>
                </Col>
              </Row>
              <Row>
                {detailData?.error_discount_type && (
                  <div className="text-danger ml-2">{detailData?.error_discount_type}</div>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        {!isCampaignB2B ? (
          <Col sm={12} md={6} className="mb-2">
            <Row>
              <Label lg={4} className="text-left label-required">
                Diskon Untuk
              </Label>
              <SelectField
                colWidth={8}
                title="Kelompok Promo"
                placeholder={'Pilih cara penggunaan diskon'}
                disabled={disableForm}
                closeMenuOnSelect={true}
                options={discountForOptions}
                errorMessage={detailData?.error_discount_for ?? ''}
                value={detailData?.discount_for}
                selectName={'discount_for'}
                onChange={(e) => handleChangeDiscountFor(e)}
              />
            </Row>
          </Col>
        ) : (
          <></>
        )}
        {detailData?.discount_for && detailData?.discount_type == 'amount' && (
          <Col sm={12} md={6} className="mb-2">
            <Row>
              <Label lg={4} className="text-left label-required">
                Besaran Diskon(Rp)
              </Label>
              <InputField
                colWidth={8}
                disabled={disableForm}
                value={detailData?.discount_value?.formatMoneyString}
                invalid={detailData?.error_discount_value ? true : false}
                inputName={'discount_value'}
                inputPattern={regexOnlyAllowNumber}
                placeholder={'Masukkan besaran diskon(Rp)'}
                errorMessage={detailData?.error_discount_value ?? ''}
                onChange={(e) => handleChangeDiscountValue(e?.target?.value)}
              />
            </Row>
          </Col>
        )}
        {detailData?.discount_for && detailData?.discount_type == 'percent' && (
          <>
            <Col sm={12} md={6} className="mb-2">
              <Row>
                <Label lg={4} className="text-left label-required">
                  Besaran Diskon(%)
                </Label>
                <InputField
                  colWidth={8}
                  disabled={disableForm}
                  value={detailData?.discount_value?.formatMoneyString}
                  invalid={detailData?.error_discount_value ? true : false}
                  inputName={'discount_value'}
                  inputPattern={regexOnlyAllowNumber}
                  placeholder={'Masukkan besaran diskon(%)'}
                  errorMessage={detailData?.error_discount_value ?? ''}
                  onChange={(e) => handleChangeDiscountValue(e?.target?.value)}
                />
              </Row>
            </Col>
            {detailData?.discount_for?.value !== 'per-item' && (
              <Col sm={12} md={6}>
                <Row>
                  <Label lg={4} className="text-left label-required">
                    Max Diskon(Rp)
                  </Label>
                  <InputField
                    colWidth={8}
                    min={0}
                    disabled={disableForm}
                    value={detailData?.max_discount?.formatMoneyString}
                    invalid={detailData?.error_max_discount ? true : false}
                    inputName={'max_discount'}
                    placeholder={'Masukkan max diskon(Rp)'}
                    errorMessage={detailData?.error_max_discount ?? ''}
                    onChange={(e) => handleChangeMaxDiscount(e?.target?.value)}
                  />
                </Row>
              </Col>
            )}
          </>
        )}
        {detailData?.discount_for?.value === 'per-item' && (
          <Col sm={12} md={6}>
            <div className="text-info">
              Jika Anda memilih Discount Per Baris maka tidak ada limit Voucher yang dapat digunakan
            </div>
          </Col>
        )}
      </Row>

      <Row>
        <Col sm={12} md={12} className="section-action mt-3">
          <Button disabled={disableButton} className="button-action save" onClick={() => handleActionNext()}>
            {'Selanjutnya'}
          </Button>
          <Button disabled={disableButton} className="button-action cancel" onClick={() => handleActionBack()}>
            {'Kembali'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SetupVoucherForm;
