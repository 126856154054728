import React, { useState, useRef, useEffect } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { FormGroup, Input, Label, Col, FormFeedback, FormText, InputGroupText, InputGroup } from 'reactstrap';

const EditableInputField = (props) => {
  const {
    value,
    title,
    invalid,
    onChange,
    disabled,
    colWidth,
    inputName,
    inputMin,
    inputMax,
    labelWidth,
    placeholder,
    errorMessage,
    inputType = 'text',
    inputPattern = '',
    disabledFormText = true,
    formText,
    formTextLeft,
    required,
    style,
    textLeft,
    customStyles,
    emoji = false,
    suffix,
  } = props;

  const wrapperRef = useRef();
  const [showEmoji, setShowEmoji] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);

  const generateClassName = () => {
    if (required && textLeft) {
      return 'text-left label-required';
    } else if (required) {
      return 'label-required';
    } else if (textLeft) {
      return 'text-left';
    } else {
      return '';
    }
  };

  const handleOnBlur = (e) => {
    setCursorPosition(e?.target?.selectionStart ?? 0);
  };

  const handleShowEmoji = () => {
    setShowEmoji(!showEmoji);
  };

  const onClickEmoji = (data) => {
    const valueWithEmoji = [value.slice(0, cursorPosition), data?.emoji ?? '', value.slice(cursorPosition)].join('');
    const val = {
      target: {
        value: valueWithEmoji,
      },
    };
    onChange(val);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowEmoji(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <FormGroup row style={style}>
      <Label lg={labelWidth} className={generateClassName()}>
        {title}
      </Label>
      <Col lg={colWidth}>
        {suffix ? (
          <InputGroup>
            <Input
              type={inputType}
              style={customStyles}
              pattern={inputPattern}
              value={value}
              name={inputName}
              min={inputMin}
              max={inputMax}
              invalid={invalid}
              disabled={disabled}
              onChange={onChange}
              onBlur={handleOnBlur}
              placeholder={placeholder}
              className={emoji ? 'input-emoji' : suffix ? ' with-suffix' : ''}
            />
            {emoji && (
              <>
                {!showEmoji && <div className="button-emoji show-emoji" onClick={handleShowEmoji}></div>}
                {showEmoji && (
                  <>
                    <div className="button-emoji hide-emoji" onClick={handleShowEmoji}></div>
                    <div className="popup-emoji" ref={wrapperRef}>
                      <EmojiPicker onEmojiClick={onClickEmoji} />
                    </div>
                  </>
                )}
              </>
            )}
            {suffix && <InputGroupText>{suffix}</InputGroupText>}
            <FormFeedback>{errorMessage}</FormFeedback>
            {!disabledFormText && <FormText className="muted text-right">{formText}</FormText>}
            <FormText className="muted text-left">{formTextLeft}</FormText>
          </InputGroup>
        ) : (
          <>
            <Input
              type={inputType}
              style={customStyles}
              pattern={inputPattern}
              value={value}
              name={inputName}
              min={inputMin}
              max={inputMax}
              invalid={invalid}
              disabled={disabled}
              onChange={onChange}
              onBlur={handleOnBlur}
              placeholder={placeholder}
              className={emoji ? 'input-emoji' : suffix ? ' with-suffix' : ''}
            />
            {emoji && (
              <>
                {!showEmoji && <div className="button-emoji show-emoji" onClick={handleShowEmoji}></div>}
                {showEmoji && (
                  <>
                    <div className="button-emoji hide-emoji" onClick={handleShowEmoji}></div>
                    <div className="popup-emoji" ref={wrapperRef}>
                      <EmojiPicker onEmojiClick={onClickEmoji} />
                    </div>
                  </>
                )}
              </>
            )}
            <FormFeedback>{errorMessage}</FormFeedback>
            {!disabledFormText && <FormText className="muted text-right">{formText}</FormText>}
            <FormText className="muted text-left">{formTextLeft}</FormText>
          </>
        )}
      </Col>
    </FormGroup>
  );
};

export default EditableInputField;
