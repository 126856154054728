import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import API from '@utils/API';
import AsyncSelect from 'react-select/async';
import DropdownCreate from '@components/field/DropdownCreate';
import CreateCarModelModal from '@components/modal/CreateCarModelModal';
import { find } from 'lodash';

function CarModelName(props) {
  const api = new API('v2');
  const { brand, isDisabled = false, onChangeName, initialValue, invalid } = props;

  const [modelSelectedValue, setModelSelectedValue] = useState(null);
  const [defaultModelOptions, setDefaultModelOptions] = useState([]);
  const [modalCreateModel, setModalCreateModel] = useState(false);

  useEffect(() => {
    if (typeof brand === 'object' && !isDisabled) {
      fetchModelOptions();
    }
  }, [brand, isDisabled]);

  useEffect(() => {
    if (initialValue && defaultModelOptions) {
      const select = find(defaultModelOptions, { model_name: initialValue });

      handleModelChange(select);
    }
  }, [initialValue, defaultModelOptions]);

  const loadModelSearch = (model) => {
    if (model) {
      loadCarModel(model).then((res) => {
        setDefaultModelOptions(res);
      });
    }
  };

  const fetchModelOptions = () => {
    loadCarModel().then((res) => {
      setDefaultModelOptions(res);
    });
  };

  const loadCarModel = (modelName = '') => {
    return api
      .get(`v2/intools/car-models?${brand ? `brand_id=${brand?.id}&` : ''}model_name=${modelName}`)
      .then((response) => {
        return response.data.data;
      });
  };

  const saveCurrentModel = (model) => {
    handleModelChange(model);
    setModalCreateModel(false);
    fetchModelOptions();
  };

  const handleModelChange = (value) => {
    setModelSelectedValue(value);
    onChangeName(value);
  };

  return (
    <>
      <FormGroup row>
        <Label lg={3} className="label-required">
          Car Model
        </Label>
        <Col lg={9}>
          <AsyncSelect
            cacheOptions
            isDisabled={isDisabled}
            value={modelSelectedValue}
            placeholder="Pilih..."
            loadOptions={loadModelSearch}
            defaultOptions={defaultModelOptions}
            onChange={handleModelChange}
            getOptionLabel={(e) => `${e.model_name}`}
            getOptionValue={(e) => e.id}
            components={{
              MenuList: (props) => (
                <DropdownCreate
                  onCreateClick={() => setModalCreateModel(!modalCreateModel)}
                  textLabel="Car Model"
                  {...props}
                />
              ),
            }}
            rules={{ required: true }}
          />

          {invalid && <span className="text-danger">Model is mandatory</span>}
        </Col>
      </FormGroup>

      <CreateCarModelModal
        carBrand={brand}
        modal={modalCreateModel}
        toggle={() => setModalCreateModel(!modalCreateModel)}
        onSuccess={saveCurrentModel}
      />
    </>
  );
}

export default CarModelName;
