import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Label } from 'reactstrap';
import API from '@utils/API';
import map from 'lodash/map';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import { regexOnlyAllowNumberCharacterSpace } from '@utils/Constants';

const PackageAdd = (props) => {
  const { modal, toggle, onSubmit } = props;

  const api = new API('v2');
  const [workshopOptions, setWorkshopOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [workshop, setWorkshop] = useState();
  const [category, setCategory] = useState();
  const [packageName, setPackageName] = useState('');
  const [errorWorkshop, setErrorWorkshop] = useState('');
  const [errorCategory, setErrorCategory] = useState('');
  const [errorPackage, setErrorPackage] = useState('');
  const [loadingFetchWorkshop, setLoadingFetchWorkshop] = useState(false);
  const [loadingFetchCategory, setLoadingFetchCategory] = useState(false);
  const [loading, setLoading] = useState(false);

  async function fetchMasterDataWorkshop() {
    setLoadingFetchWorkshop(true);
    try {
      const response = await api.get(`v2/intools/packages/list-workshop`);
      mappingMasterWorkshop(response?.data?.data ?? []);
      setLoadingFetchWorkshop(false);
    } catch (error) {
      mappingMasterWorkshop([]);
      setLoadingFetchWorkshop(false);
    }
  }

  async function fetchMasterDataCategory() {
    setLoadingFetchCategory(true);
    try {
      const response = await api.get(`v2/intools/categories/list-categories-all`);
      mappingMasterCategory(response?.data?.data ?? []);
      setLoadingFetchCategory(false);
    } catch (error) {
      mappingMasterCategory([]);
      setLoadingFetchCategory(false);
    }
  }

  const mappingMasterWorkshop = (data) => {
    let masterDataWorkshop = [];
    if (data.length !== 0) {
      map(data, (item) => {
        masterDataWorkshop.push({
          value: item?.workshop?.id,
          label: item?.workshop?.name,
        });
      });
    }

    setWorkshopOptions(masterDataWorkshop);
  };

  const mappingMasterCategory = (data) => {
    let masterDataCategory = [];
    if (data.length !== 0) {
      map(data, (item) => {
        masterDataCategory.push({
          value: item?.id,
          label: item?.name,
        });
      });
    }

    setCategoryOptions(masterDataCategory);
  };

  const handleChangeWorkshop = (e) => {
    const valueWorkshop = e?.value ? e : null;
    validateWorkshop(valueWorkshop);
  };

  const handleChangePackage = (e) => {
    const valuePackage = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : packageName;
    validatePackage(valuePackage);
  };

  const handleChangeCategory = (e) => {
    const valueCategory = e?.value ? e : null;
    validateCategory(valueCategory);
  };

  const validateWorkshop = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorWorkshop('Workshop harus di isi');
    } else {
      setErrorWorkshop('');
    }

    setWorkshop(value);
    return passValidate;
  };

  const validatePackage = (value) => {
    const regexNumbCharSpace = new RegExp(regexOnlyAllowNumberCharacterSpace);
    let passValidate = true;

    if (!regexNumbCharSpace.test(value)) {
      passValidate = false;
      setErrorPackage('Format Nama Package harus angka atau huruf');
    } else if (value.length == 0) {
      passValidate = false;
      setErrorPackage('Nama Package harus diisi');
    } else if (value.length < 5 || value.length > 100) {
      passValidate = false;
      setErrorPackage('Format Nama Package minimal 5, maksimal 100 karakter');
    } else {
      setErrorPackage('');
    }

    setPackageName(value);
    return passValidate;
  };

  const validateCategory = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorCategory('Category harus di isi');
    } else {
      setErrorCategory('');
    }

    setCategory(value);
    return passValidate;
  };

  const handleSubmit = () => {
    const passValidateWorkshop = validateWorkshop(workshop);
    const passValidatePackage = validatePackage(packageName);
    const passValidateCategory = validateCategory(category);

    if (passValidateWorkshop && passValidatePackage && passValidateCategory) {
      submitForm();
    }
  };

  async function submitForm() {
    toggle();
    setLoading(true);

    try {
      const payload = {
        workshop: workshop?.value ?? null,
        package_name: packageName ?? '',
        category: category?.label ?? null,
      };
      await api.post(`v2/intools/packages/create-package/`, payload);
      onSubmit(true);
      setLoading(false);
    } catch (error) {
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  const resetData = () => {
    setWorkshopOptions([]);
    setCategoryOptions([]);
    setWorkshop();
    setCategory();
    setPackageName('');
    setErrorWorkshop('');
    setErrorPackage('');
    setErrorCategory('');
  };

  const cancel = () => {
    toggle();
  };

  useEffect(() => {
    resetData();
    if (modal) {
      fetchMasterDataWorkshop();
      fetchMasterDataCategory();
    }
  }, [modal]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Add Package</div>
        <div className="content">
          <FormGroup row>
            <Label lg={4} className="label-required">
              Workshop
            </Label>
            <SelectField
              colWidth={8}
              name="workshop"
              placeholder="Workshop"
              closeMenuOnSelect={true}
              value={workshop}
              options={workshopOptions}
              disabled={loading}
              isLoading={loadingFetchWorkshop}
              errorMessage={errorWorkshop}
              onChange={handleChangeWorkshop}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4} className="label-required">
              Package Name
            </Label>
            <InputField
              colWidth={8}
              inputType={'text'}
              disabled={loading}
              placeholder="Package Name"
              value={packageName ?? ''}
              invalid={errorPackage ? true : false}
              errorMessage={errorPackage ?? ''}
              onChange={handleChangePackage}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4} className="label-required">
              Category
            </Label>
            <SelectField
              colWidth={8}
              name="category"
              placeholder="Category"
              closeMenuOnSelect={true}
              value={category}
              options={categoryOptions}
              disabled={loading}
              isLoading={loadingFetchCategory}
              errorMessage={errorCategory}
              onChange={handleChangeCategory}
            />
          </FormGroup>
        </div>

        <div className="action mb-3 mt-3">
          <Button className="button-cancel" onClick={cancel} disabled={loading}>
            Batal
          </Button>
          <Button className="button-action" onClick={handleSubmit} disabled={loading}>
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PackageAdd;
