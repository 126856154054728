import React, { useState, useRef, useEffect } from 'react';
import Select, { components } from 'react-select';
import { clone, findIndex } from 'lodash';
import { Col } from 'reactstrap';
import ItemsModal from '@components/modal/ItemsModal';
import DropdownCreate from '@components/field/DropdownCreate';

const SelectField = (props) => {
  const {
    value,
    onChange,
    onInputChange,
    onClickCreate,
    disabled,
    colWidth,
    selectName,
    className,
    placeholder,
    closeMenuOnSelect,
    errorMessage,
    options,
    getOptionLabel,
    getOptionValue,
    isClearable = false,
    isSearchable = true,
    isLoading,
    titlePreview,
    placeholderPreview,
    labelDataPreview,
    maxPreview = 5,
    isMulti = false,
    isSelectAll = false,
    needInputChange = false,
    customControlSelected = false,
    customControlKeySelected = '',
    customControlValueSelected = null,
    customControlColorLabelSelected = '#fff',
    customControlColorTextSelected = '#fff',
    customFull = false,
    id = '',
    isOptionDisabled = false,
    isCreate = false,
    textLabelCreate = '',
    isLowerCaseInputSearch = false,
    customeFilteredOption,
  } = props;

  const selectReff = useRef();
  const [searchInput, setSearchInput] = useState();
  const [showModalItems, setShowModalItems] = useState(false);
  const [withSelectAll, setWithSelectAll] = useState(isSelectAll);
  const [open, setOpen] = useState(false);

  const controlBorderStyle = (state) => {
    let style = '';
    if (state?.isFocused)
      style = customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? '1px solid ' + customControlColorTextSelected
          : '1px solid #6610F2'
        : '1px solid #6610F2';
    if (!state?.isFocused)
      style = customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? '1px solid ' + customControlColorTextSelected
          : '1px solid #CED4DA'
        : '1px solid #CED4DA';
    if (errorMessage) style = '1px solid red';
    if (state?.isDisabled) style = '1px solid #CED4DA';

    return style;
  };

  const dropdownIndicatorStyle = (state) => {
    let style = '';
    if (state?.isFocused)
      style = customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorTextSelected
          : '#6610F2'
        : '#6610F2';
    if (!state?.isFocused)
      style = customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorTextSelected
          : 'hsl(0,0%,80%)'
        : 'hsl(0,0%,80%)';
    if (errorMessage) style = 'red';
    if (state?.isDisabled) style = '';

    return style;
  };

  const clearIndicatorStyle = (state) => {
    let style = '';
    if (state?.isFocused) style = '#6610F2';
    if (!state?.isFocused) style = 'hsl(0,0%,80%)';

    return style;
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state?.isDisabled
        ? '#e6e6e6'
        : state?.isSelected
        ? '#6610F2'
        : state?.isFocused
        ? '#E0CFFC'
        : '#fff',
      color: state?.isDisabled ? '#272a21' : state?.isSelected ? '#fff' : state?.isFocused ? '#6610F2' : '#6C757D',
      cursor: state?.isFocused && !state?.isDisabled ? 'pointer' : '',
      '&:hover': {
        color: state?.isSelected ? '#fff' : state?.isDisabled ? '#272a21' : '#6610F2',
        backgroundColor: state?.isSelected ? '#6610F2' : state?.isDisabled ? '#e6e6e6' : '#E0CFFC',
        cursor: state?.isDisabled ? '' : 'pointer',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      border: controlBorderStyle(state),
      borderRadius: disabled && isMulti && value?.length > maxPreview ? '0.25rem 0.25rem 0rem 0.25rem' : '0.25rem',
      backgroundColor: customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorLabelSelected
          : state?.isDisabled
          ? '#DEE2E6'
          : '#fff'
        : state?.isDisabled
        ? '#DEE2E6'
        : '#fff',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorTextSelected
          : '#052C65'
        : '#052C65',
    }),
    input: (provided) => ({
      ...provided,
      color: customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorTextSelected
          : '#052C65'
        : '#052C65',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      borderRadius: isMulti && withSelectAll && isSelectAll ? '4px 4px 0px 0px' : 4,
      paddingBottom: 0,
      border: '0.5px solid #6610F2',
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        color: '#BDBDBD',
        fontWeight: '200',
      };
    },
    multiValue: (provided, state) => {
      return {
        ...provided,
        borderRadius: '0.25rem',
        backgroundColor: state?.isDisabled ? '#68717A' : '#6610F2',
      };
    },
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: '#FFFFFF',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: state?.isDisabled ? '#68717A' : '#FFFFFF',
      '&:hover': {
        color: '#6610F2',
        backgroundColor: '#E0CFFC',
        borderRadius: '0rem 0.25rem 0.25rem 0rem',
      },
    }),
    clearIndicator: (provided, state) => {
      return {
        ...provided,
        color: clearIndicatorStyle(state),
      };
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        color: dropdownIndicatorStyle(state),
      };
    },
  };

  const customStylesFull = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state?.isDisabled
        ? '#e6e6e6'
        : state?.isSelected
        ? '#6610F2'
        : state?.isFocused
        ? '#E0CFFC'
        : '#fff',
    }),
    control: (provided, state) => ({
      ...provided,
      border: controlBorderStyle(state),
      boderColor: '#660ff1',
      borderRadius: disabled && isMulti && value?.length > maxPreview ? '0.25rem 0.25rem 0rem 0.25rem' : '0.25rem',
      backgroundColor: '#e0cffc',
      color: '#660ff1',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorTextSelected
          : '#052C65'
        : '#052C65',
    }),
    input: (provided) => ({
      ...provided,
      borderColor: '#660ff1',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      borderRadius: isMulti && withSelectAll && isSelectAll ? '4px 4px 0px 0px' : 4,
      paddingBottom: 0,
      border: '0.5px solid #6610F2',
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        color: '#660ff1',
        fontWeight: '200',
      };
    },
    multiValue: (provided, state) => {
      return {
        ...provided,
        borderRadius: '0.25rem',
        backgroundColor: state?.isDisabled ? '#68717A' : '#6610F2',
      };
    },
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: '#FFFFFF',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: state?.isDisabled ? '#68717A' : '#FFFFFF',
      '&:hover': {
        color: '#6610F2',
        backgroundColor: '#E0CFFC',
        borderRadius: '0rem 0.25rem 0.25rem 0rem',
      },
    }),
    clearIndicator: (provided, state) => {
      return {
        ...provided,
        color: clearIndicatorStyle(state),
      };
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        color: 'transparent',
      };
    },
  };

  const menuHeaderStyle = {
    fontWeight: 'bold',
    cursor: 'pointer',
    padding: '8px 12px',
    bottom: '0px',
    border: '0.5px solid #6610F2',
    borderRadius: '0px 0px 4px 4px',
    color: '#FFFFFF',
    backgroundColor: '#6610F2',
  };

  const Menu = (props) => {
    return (
      <>
        <components.Menu {...props}>
          {props.children}
          {isMulti && withSelectAll && isSelectAll && options?.length !== 0 && (
            <div style={menuHeaderStyle} onClick={handleSelectAll}>
              Pilih Semua
            </div>
          )}
        </components.Menu>
      </>
    );
  };

  const handleCreate = (e) => {
    onClickCreate(e);
    setOpen(false);
    setSearchInput('');
  };

  const MenuList = (props) => {
    return (
      <>
        {!isCreate && (
          <components.MenuList {...props} className="p-0">
            {props.children}
          </components.MenuList>
        )}
        {isCreate && (
          <DropdownCreate onCreateClick={() => handleCreate(props)} textLabel={textLabelCreate} {...props} />
        )}
      </>
    );
  };

  const MultiValueContainer = (props) => {
    const position = findIndex(props?.selectProps?.value, (item) => {
      return item === props?.data;
    });
    return (
      <>
        {!disabled && <components.MultiValueContainer {...props} />}
        {disabled && position < maxPreview && <components.MultiValueContainer {...props} />}
        {disabled && value?.length > maxPreview && position == value?.length - 1 && (
          <div className="select-others">
            <div className="value-select">{'+' + (value?.length - maxPreview) + ' Lainnya'}</div>
          </div>
        )}
      </>
    );
  };

  const isIncludingSearch = (option) => {
    let result = false;

    if (
      !searchInput ||
      option?.label?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      option?.value?.toString().toLowerCase().includes(searchInput.toLowerCase())
    ) {
      result = true;
    }

    return result;
  };

  const isAvailableValue = (option) => {
    const data = value?.length ? value : [];
    const position = findIndex(data, (item) => {
      return item?.value == option?.value && item?.label == option?.label;
    });

    return position !== -1 ? true : false;
  };

  const handleInputChange = (e) => {
    setSearchInput(e);
    if (needInputChange) onInputChange(e);
  };

  const handleChange = (e) => {
    if (isMulti && !closeMenuOnSelect) setSearchInput(searchInput);
    onChange(e);
  };

  const handleSelectAll = () => {
    let newOptions = value?.length ? value : [];
    let filteredOptions = options?.length ? clone(options) : [];

    filteredOptions = filteredOptions.filter(
      (filteredOption) => isIncludingSearch(filteredOption) && !isAvailableValue(filteredOption)
    );

    newOptions = newOptions.concat(filteredOptions);

    setSearchInput('');
    selectReff?.current?.onMenuClose();
    onChange(newOptions);
  };

  const handleSelectMore = () => {
    setShowModalItems(true);
  };

  const calculateCountListOption = () => {
    let filteredOptions = options?.length ? clone(options) : [];

    filteredOptions = filteredOptions.filter(
      (filteredOption) => isIncludingSearch(filteredOption) && !isAvailableValue(filteredOption)
    );

    setWithSelectAll(filteredOptions?.length > 1 ? true : false);
  };

  useEffect(() => {
    if (searchInput !== undefined && isMulti) {
      calculateCountListOption();
    }
  }, [searchInput, value]);

  return (
    <>
      <Col lg={colWidth}>
        <Select
          inputId={id}
          ref={selectReff}
          components={{ IndicatorSeparator: () => null, MenuList, Menu, MultiValueContainer }}
          className={className}
          name={selectName}
          placeholder={placeholder}
          closeMenuOnSelect={closeMenuOnSelect}
          isSearchable={isSearchable}
          isMulti={isMulti}
          options={options}
          value={value}
          isDisabled={disabled}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          inputValue={isLowerCaseInputSearch ? searchInput?.toLowerCase() : searchInput}
          onInputChange={(e) => handleInputChange(e)}
          onChange={(e) => handleChange(e)}
          isLoading={isLoading}
          isClearable={isClearable}
          styles={customFull ? customStylesFull : customStyles}
          isOptionDisabled={isOptionDisabled}
          menuIsOpen={open}
          onMenuOpen={() => setOpen(true)}
          onMenuClose={() => setOpen(false)}
          filterOption={customeFilteredOption}
        />
        {disabled && isMulti && value?.length > maxPreview && (
          <div className="select-more" onClick={handleSelectMore}>
            <div className="content-select-more">{'Lihat Semua'}</div>
          </div>
        )}
        {errorMessage && <div className="pt-1 text-danger">{errorMessage}</div>}
      </Col>
      <ItemsModal
        data={value}
        modal={showModalItems}
        title={titlePreview}
        placeholder={placeholderPreview}
        labelData={labelDataPreview}
        toggle={() => {
          setShowModalItems(!showModalItems);
        }}
      />
    </>
  );
};

export default SelectField;
