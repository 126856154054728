import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Spinner } from 'reactstrap';
import API from '@utils/API';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import moment from 'moment';

function NotificationsDetail() {
  const [recipient, setRecipient] = useState();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const api = new API('v2');
  const history = useHistory();

  useEffect(() => {
    loadListNotifications();
  }, [recipient]);

  async function loadListNotifications() {
    setIsLoading(true);
    try {
      const response = await api.get(`v2/pub/notifications/${id}`);
      setData(response?.data?.data ?? {});
      setIsLoading(false);
    } catch (error) {
      setData({});
      setIsLoading(false);
    }
  }

  const handleGoToFirstPage = () => {
    history.push(`/pub/notifications`);
  };

  return (
    <Container fluid={true}>
      <React.Fragment>
        <Row className="ml-1 mt-5 mb-3">
          <Button onClick={() => handleGoToFirstPage()}>Back</Button>
        </Row>

        <Row>
          <Col md={12}>
            <Card>
              <CardHeader className="bg-primary text-white mt-0">{'Notification Detail'}</CardHeader>
              <div className="text-center">
                {isLoading && (
                  <Spinner size="lg" className="notif-loader">
                    Loading...
                  </Spinner>
                )}
              </div>
              {!isLoading && (
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <h4 className="text-bold">CREATED AT</h4>
                    </Col>
                    <Col className="mb-2" md={12}>
                      {moment(data?.created_at).isValid()
                        ? moment(data?.created_at).format('MMM-D-YYYY HH:mm:ss')
                        : '-'}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h4 className="text-bold">TEMPLATE</h4>
                    </Col>
                    <Col className="mb-2" md={12}>
                      {data?.content?.template ?? '-'}
                    </Col>
                  </Row>
                  {data?.content?.header && (
                    <Row>
                      <Col md={12}>
                        <h4 className="text-bold">HEADER</h4>
                      </Col>
                      <Col className="mb-2" md={12}>
                        {data?.content?.header ?? '-'}
                      </Col>
                    </Row>
                  )}
                  {data?.content?.title && (
                    <Row>
                      <Col md={12}>
                        <h4 className="text-bold">TITLE</h4>
                      </Col>
                      <Col className="mb-2" md={12}>
                        {data?.content?.title ?? '-'}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={12}>
                      <h4 className="text-bold">BODY</h4>
                    </Col>
                    <Col className="mb-2" style={{ whiteSpace: 'pre-line' }} md={12}>
                      {data?.content?.body ?? '-'}
                    </Col>
                  </Row>
                  {data?.content?.footer && (
                    <Row>
                      <Col md={12}>
                        <h4 className="text-bold">FOOTER</h4>
                      </Col>
                      <Col className="mb-2" md={12}>
                        {data?.content?.footer ?? '-'}
                      </Col>
                    </Row>
                  )}
                  {data?.content?.cta && (
                    <Row>
                      <Col md={12}>
                        <h4 className="text-bold">CTA</h4>
                      </Col>
                      <Col className="mb-2" md={12}>
                        <h6 className="text-bold">{'URL : ' + data?.content?.cta?.url ?? '-'}</h6>
                        <h6 className="text-bold">{'Label : ' + data?.content?.cta?.label ?? '-'}</h6>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </Container>
  );
}

export default NotificationsDetail;
