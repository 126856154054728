import React, { useState, forwardRef, useEffect } from 'react';
import { Col, Label, Row, Button, Tooltip } from 'reactstrap';
import SelectField from '@components/field/SelectField';
import ActionSwitch from '@components/field/ActionSwitch';
import InputField from '@components/field/InputField';
import DateTimeField from '@components/field/DateTimeField';
import { regexOnlyAllowCharacter } from '@utils/Constants';
import API from '@utils/API';
import Quest from '@assets/icons/question.svg';

const DetailCampaignForm = (props) => {
  const { dataDetail, dataCampaign, handleNext, groupOptions, disableButton, disableForm } = props;

  const api = new API('v2');
  const [detailData, setDetailData] = useState({});
  const [PICTooltip, setPICTooltip] = useState(false);

  const handleDefaultData = () => {
    const defaultData = {
      is_active: dataDetail?.is_active,
      category: dataDetail?.category,
      name: dataDetail?.name,
      start_date: dataDetail?.start_date,
      end_date: dataDetail?.end_date,
      error_category: dataDetail?.error_category,
      error_name: dataDetail?.error_name,
      error_date: dataDetail?.error_date,
      campaign_slug: dataDetail?.campaign_slug,
      error_campaign_slug: dataDetail?.error_campaign_slug,
      is_subscription: dataDetail?.is_subscription,
      pic_campaign: dataDetail?.pic_campaign,
      error_pic_campaign: dataDetail?.error_pic_campaign,
      deck_campaign: dataDetail?.deck_campaign,
      error_deck_campaign: dataDetail?.error_deck_campaign,
    };
    setDetailData(defaultData);
  };

  const handleParentData = () => {
    dataDetail.is_active = detailData?.is_active;
    dataDetail.category = detailData?.category;
    dataDetail.name = detailData?.name;
    dataDetail.start_date = detailData?.start_date;
    dataDetail.end_date = detailData?.end_date;
    dataDetail.error_category = detailData?.error_category;
    dataDetail.error_name = detailData?.error_name;
    dataDetail.error_date = detailData?.error_date;
    dataDetail.campaign_slug = detailData?.campaign_slug;
    dataDetail.error_campaign_slug = detailData?.error_campaign_slug;
    dataDetail.is_subscription = detailData?.is_subscription;
    dataDetail.pic_campaign = detailData?.pic_campaign;
    dataDetail.error_pic_campaign = detailData?.error_pic_campaign;
    dataDetail.deck_campaign = detailData?.deck_campaign;
    dataDetail.error_deck_campaign = detailData?.error_deck_campaign;

    return dataDetail;
  };

  const handleChangeStatus = () => {
    detailData.is_active = detailData?.is_active ? false : true;
    setDetailData({ ...detailData });
  };

  const handleChangeSubscription = () => {
    detailData.is_subscription = detailData?.is_subscription ? false : true;
    setDetailData({ ...detailData });
  };

  const handleChangeGroup = (e) => {
    const valueGroup = e?.value ? e : null;
    validateGroup(valueGroup);
  };

  const handleChangeName = (e) => {
    const valueName = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : detailData?.name ?? '';
    validateName(valueName);
  };

  const handleChangeDate = (type, e) => {
    const valueStartDate = type == 'start' ? e : detailData?.start_date;
    const valueEndDate = type == 'end' ? e : detailData?.end_date;
    validateDate(valueStartDate, valueEndDate);
  };

  const handleChangeCampaignSlug = (e) => {
    const valueCampaignSlug =
      e.target.value !== ' ' ? e.target.value.replace(/ /g, '').toLowerCase() : detailData?.campaign_slug ?? '';
    validateCampaignSlug(valueCampaignSlug);
  };

  const handleChangePIC = (e) => {
    const valuePIC = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : detailData?.pic_campaign ?? '';
    validatePIC(valuePIC);
  };

  const handleChangeDeck = (e) => {
    const valueDeck = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : detailData?.deck_campaign ?? '';
    validateDeck(valueDeck);
  };

  const validateGroup = (value) => {
    let passValidate = true;
    if (!value || !value?.value) {
      passValidate = false;
      detailData.error_category = 'Pilih Group terlebih dahulu';
    } else {
      detailData.error_category = '';
    }

    detailData.category = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateName = (value) => {
    let passValidate = true;
    if (value?.length == 0 || !value) {
      passValidate = false;
      detailData.error_name = 'Field tidak boleh kosong';
    } else if (value?.length < 2 || value?.length > 100) {
      passValidate = false;
      detailData.error_name = 'Format Nama  minimal 2, maksimal 100 karakter';
    } else {
      detailData.error_name = '';
    }

    detailData.name = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateDate = (startDate, endDate) => {
    let passValidate = true;
    if (!startDate || !endDate) {
      passValidate = false;
      detailData.error_date = 'Field tidak boleh kosong';
    } else if (startDate > endDate) {
      passValidate = false;
      detailData.error_date = 'Invalid date range';
    } else {
      detailData.error_date = '';
    }

    detailData.start_date = startDate;
    detailData.end_date = endDate;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateCampaignSlug = (value) => {
    let passValidate = true;
    if (value && (value?.length < 3 || value?.length > 20)) {
      passValidate = false;
      detailData.error_campaign_slug = 'Format minimal 3, maksimal 20 karakter';
    } else {
      detailData.error_campaign_slug = '';
    }

    detailData.campaign_slug = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validatePIC = (value) => {
    let passValidate = true;
    if (value?.length == 0 || !value) {
      passValidate = false;
      detailData.error_pic_campaign = 'Field tidak boleh kosong';
    } else if (value?.length < 1 || value?.length > 40) {
      passValidate = false;
      detailData.error_pic_campaign = 'Format minimal 1, maksimal 40 karakter';
    } else {
      detailData.error_pic_campaign = '';
    }

    detailData.pic_campaign = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateDeck = (value) => {
    let passValidate = true;
    if (value?.length > 100) {
      passValidate = false;
      detailData.error_deck_campaign = 'Format maksimal 100 karakter';
    } else {
      detailData.error_deck_campaign = '';
    }

    detailData.deck_campaign = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const handleOnBlurCampaignSlug = () => {
    if (
      detailData?.campaign_slug &&
      detailData?.campaign_slug?.length >= 3 &&
      detailData?.campaign_slug?.length <= 20 &&
      detailData?.campaign_slug != dataCampaign?.campaign_slug
    ) {
      return api
        .get(`v2/intools/campaigns/check-slug/${detailData?.campaign_slug}/`)
        .then((response) => {
          detailData.error_campaign_slug = '';
          setDetailData({ ...detailData });
        })
        .catch((error) => {
          detailData.error_campaign_slug = 'Tidak dapat sama dengan slug campaign yang sedang aktif';
          setDetailData({ ...detailData });
        });
    }
  };

  const handleCheckCampaignSlug = (value, params) => {
    if (value && value?.length >= 3 && value?.length <= 20 && value != dataCampaign?.campaign_slug) {
      return api
        .get(`v2/intools/campaigns/check-slug/${value}/`)
        .then((response) => {
          detailData.error_campaign_slug = '';
          setDetailData({ ...detailData });
          handleNext(params);
        })
        .catch((error) => {
          detailData.error_campaign_slug = 'Tidak dapat sama dengan slug campaign yang sedang aktif';
          setDetailData({ ...detailData });
          handleNext({ status: false, data: params?.data });
        });
    } else {
      handleNext(params);
    }
  };

  const handleActionNext = () => {
    const passValidateGroup = validateGroup(detailData?.category);
    const passValidateName = validateName(detailData?.name);
    const passValidateDate = validateDate(detailData?.start_date, detailData?.end_date);
    const passValidateCampaignSlug = validateCampaignSlug(detailData?.campaign_slug);
    const passValidatePIC = validatePIC(detailData?.pic_campaign);
    const passValidateDeck = validateDeck(detailData?.deck_campaign);

    let params = {
      status:
        passValidateGroup &&
        passValidateName &&
        passValidateDate &&
        passValidateCampaignSlug &&
        passValidatePIC &&
        passValidateDeck
          ? true
          : false,
      data: handleParentData(),
    };

    handleCheckCampaignSlug(detailData?.campaign_slug, params);
  };

  useEffect(() => {
    handleDefaultData();
  }, [dataDetail]);

  return (
    <>
      <Row>
        <Col sm={12} md={6} className="">
          <Row>
            <Col sm={12} md={12} className="">
              <ActionSwitch
                labelWidth={4}
                colWidth={8}
                title="Status"
                inputName="is_active"
                isChecked={detailData?.is_active}
                customClass="mt-0"
                customClassLabel="text-left"
                editableForm={disableForm}
                labelChecked="Aktif"
                labelUnchecked="Tidak Aktif"
                onChange={() => handleChangeStatus()}
                color={'secondary'}
              />
            </Col>
            <Col sm={12} md={12} className="mb-2">
              <Row>
                <Label lg={4} className="text-left label-required">
                  Nama Campaign
                </Label>
                <InputField
                  colWidth={8}
                  inputType="text"
                  disabled={disableForm}
                  value={detailData?.name}
                  inputName={'name'}
                  placeholder={'Masukkan nama Campaign'}
                  invalid={detailData?.error_name ? true : false}
                  errorMessage={detailData?.error_name ?? ''}
                  onChange={(e) => handleChangeName(e)}
                />
              </Row>
            </Col>
            <Col sm={12} md={12} className="my-2">
              <ActionSwitch
                labelWidth={4}
                colWidth={8}
                title="Subscription"
                inputName="is_subs"
                isChecked={detailData?.is_subscription}
                customClass="mt-0"
                customClassLabel="text-left"
                editableForm={disableForm}
                labelChecked="Ya"
                labelUnchecked="Tidak"
                onChange={() => handleChangeSubscription()}
                color={'secondary'}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={6} className="m-0">
          <Row>
            <Col sm={12} md={12} className="mb-3">
              <Row>
                <Label lg={4} className="text-left label-required">
                  Kelompok Campaign
                </Label>
                <SelectField
                  colWidth={8}
                  title="Kelompok Promo"
                  placeholder={'Pilih kelompok Campaign'}
                  disabled={disableForm}
                  closeMenuOnSelect={true}
                  options={groupOptions}
                  errorMessage={detailData?.error_category}
                  value={detailData?.category}
                  onChange={(e) => handleChangeGroup(e)}
                />
              </Row>
            </Col>
            <Col sm={12} md={12} className="mb-2">
              <Row>
                <Label lg={4} className="text-left label-required">
                  Periode Campaign
                </Label>
                <DateTimeField
                  colWidth={8}
                  startDate={detailData?.start_date}
                  endDate={detailData?.end_date}
                  placeholderStartDate={'dd/mm/yyyy - hh:mm:ss'}
                  placeholderEndDate={'dd/mm/yyyy - hh:mm:ss'}
                  withRange={true}
                  withClear={false}
                  onChangeStartDate={(e) => handleChangeDate('start', e)}
                  onChangeEndDate={(e) => handleChangeDate('end', e)}
                  errorMessage={detailData?.error_date}
                  isGMTFormat={true}
                  disabled={disableForm}
                ></DateTimeField>
              </Row>
            </Col>
            <Col sm={12} md={12} className="my-2">
              <Row>
                <Label lg={4} className="text-left">
                  Slug Campaign
                </Label>
                <InputField
                  colWidth={8}
                  inputType="text"
                  disabled={disableForm}
                  value={detailData?.campaign_slug}
                  invalid={detailData?.error_campaign_slug ? true : false}
                  inputName={'campaign_slug'}
                  inputPattern={regexOnlyAllowCharacter}
                  placeholder={'Masukkan slug Campaign'}
                  errorMessage={detailData?.error_campaign_slug ?? ''}
                  onChange={(e) => handleChangeCampaignSlug(e)}
                  needOnBlur={true}
                  onBlur={handleOnBlurCampaignSlug}
                />
              </Row>
            </Col>
            <Col sm={12} md={12} className="my-2">
              <Row>
                <Label lg={4} className="text-left">
                  <div className="ml-3 label-required">
                    <img className="icon-quest" id="PICTooltip" src={Quest} width="15" />
                    PIC Campaign
                  </div>
                </Label>
                <InputField
                  colWidth={8}
                  inputType="text"
                  disabled={disableForm}
                  value={detailData?.pic_campaign}
                  inputName={'pic_campaign'}
                  placeholder={'Masukkan PIC Campaign'}
                  invalid={detailData?.error_pic_campaign ? true : false}
                  errorMessage={detailData?.error_pic_campaign ?? ''}
                  onChange={(e) => handleChangePIC(e)}
                />
              </Row>
            </Col>
            <Col sm={12} md={12} className="my-2">
              <Row>
                <Label lg={4} className="text-left">
                  Deck Campaign
                </Label>
                <InputField
                  colWidth={8}
                  inputType="text"
                  disabled={disableForm}
                  value={detailData?.deck_campaign}
                  inputName={'deck_document'}
                  placeholder={'Masukkan Link Deck Campaign'}
                  invalid={detailData?.error_deck_campaign ? true : false}
                  errorMessage={detailData?.error_deck_campaign ?? ''}
                  onChange={(e) => handleChangeDeck(e)}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={12} className="section-action mt-3">
          <Button disabled={disableButton} className="button-action save" onClick={() => handleActionNext()}>
            {'Selanjutnya'}
          </Button>
        </Col>
      </Row>
      <Tooltip isOpen={PICTooltip} target="PICTooltip" toggle={() => setPICTooltip(!PICTooltip)}>
        <p>PIC yang akan menjawab setiap pertanyaan stakeholder terkait ketentuan Campaign yang sedang berjalan</p>
      </Tooltip>
    </>
  );
};

export default DetailCampaignForm;
