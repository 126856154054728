import React, { useState } from 'react';
import { Input, Col, InputGroup } from 'reactstrap';
import { map } from 'lodash';

const InputTagField = (props) => {
  const { name, value, onChange, disabled, colWidth, placeholder, allowDuplication = false } = props;

  const [input, setInput] = useState('');
  const [isKeyReleased, setIsKeyReleased] = useState('');
  const [isFocus, setIsFocus] = useState(false);

  const onChangeTags = (e) => {
    onChange(e);
  };

  const onChangeInput = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (key === 'Enter' && trimmedInput.length && !value.includes(allowDuplication ? '' : trimmedInput)) {
      const tagsCopy = value;
      e.preventDefault();
      onChangeTags([...tagsCopy, trimmedInput]);
      setInput('');
    }

    if (key === 'Backspace' && !input.length && value?.length && isKeyReleased) {
      const tagsCopy = value;
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      onChangeTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index) => {
    if (!disabled) {
      const updateData = value.filter((tag, i) => i !== index);
      onChangeTags(updateData);
    }
  };

  return (
    <Col lg={colWidth}>
      <InputGroup>
        <div className={`${disabled ? 'disabled container-tag' : 'container-tag'} ${isFocus ? 'focus' : ''}`}>
          {map(value, (tag, index) => (
            <div className={`${disabled ? 'disabled tag' : 'tag'}`} key={index}>
              <span className={`${disabled ? 'tag-text disabled' : 'tag-text'}`}>
                {tag?.length > 40 ? tag?.substr(0, 40) + '...' : tag}
              </span>
              <button className={`${disabled ? 'disabled' : ''}`} onClick={() => deleteTag(index)}>
                x
              </button>
            </div>
          ))}
          {(!disabled || (disabled && !value?.length)) && (
            <Input
              className="tag-input"
              name={name}
              value={input ?? ''}
              disabled={disabled}
              placeholder={placeholder}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onChange={onChangeInput}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
            />
          )}
          {/* {disabled && !value?.length && <span>{placeholder}</span>} */}
        </div>
      </InputGroup>
    </Col>
  );
};

export default InputTagField;
