import React, { useState } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Helmet } from 'react-helmet';
import NotificationAlert from '@components/notifications/NotificationAlert';
import WrapperChat from '@components/chat-aida/WrapperChat';

function Wrapper(props) {
  const {
    title,
    className,
    customContentHeader,
    customContentClassName,
    customTitleClassName,
    breadcrumbData,
    showNotification,
    notification,
    onCloseNotification,
    children,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChat = (e) => {
    setIsOpen(e);
  };

  return (
    <>
      <Helmet>
        <title>{`${title ? `${title} | ` : ''}Intools`}</title>
      </Helmet>
      <div className="page-wrapper">
        <div className={'page-content-tab ' + customContentClassName}>
          <Container fluid={true} style={{ width: isOpen ? '65%' : '100%', transition: '0.5s' }}>
            <Row>
              <Col sm={12}>
                <div className="page-title-box">
                  {breadcrumbData && (
                    <Breadcrumb>
                      {breadcrumbData.map((item) => (
                        <BreadcrumbItem key={item.id} active={item.active}>
                          <a href={item.url}>{item.label}</a>
                        </BreadcrumbItem>
                      ))}
                    </Breadcrumb>
                  )}
                  <h4 className={'page-title ' + customTitleClassName}>{title}</h4>
                  {customContentHeader}
                </div>
              </Col>
              <Col sm={12}>
                <div className={className}>{children}</div>
              </Col>
            </Row>
            <NotificationAlert
              isShow={showNotification}
              notification={notification}
              onClose={onCloseNotification}
            ></NotificationAlert>
          </Container>
          <WrapperChat handleOpenChat={(e) => handleOpenChat(e)} />
        </div>
      </div>
    </>
  );
}

export default Wrapper;
