import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import EditPackageModal from '@components/modal/EditPackageModal';
import DetailInformationComponents from '@components/packages/DetailInformationComponents';

const DetailInformation = (props) => {
  const { defaultValuesDetail, defaultValuesHeader, packageProductOptions, allowEdit } = props;
  const {
    control,
    reset,
    watch,
    register,
    setValue,
    setError,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [modalType, setModalType] = useState('');
  const [disableForm, setDisableForm] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);

  const setDefaultForm = () => {
    reset({ components: defaultValuesDetail });
  };

  const restoreFormPackageDetail = () => {
    setDefaultForm();
    setDisableForm(true);
    setConfirmModal(false);
  };

  const editForm = () => {
    if (disableForm) {
      setDisableForm(false);
    } else {
      setModalType('Simpan');
      setConfirmModal(true);
    }
  };

  const cancelEditForm = () => {
    setModalType('Batalkan');
    setConfirmModal(true);
  };

  const setErrorMessage = (nameFiled, type, message) => {
    setError(nameFiled, { type: type, message: message }, { shouldFocus: true });
  };

  const validateForm = (dataForm) => {
    let isValid = true;

    dataForm.components.map((component, idxComponent) => {
      let showComponent = false;
      let componentField = `components[${idxComponent}].`;
      if (!component.component_name && !component.is_deleted) {
        isValid = false;
        showComponent = true;
        setErrorMessage(componentField + `component_name`, 'manual', 'Component Name harus diisi');
      }
      if (!component.prompt_text && !component.is_deleted) {
        isValid = false;
        showComponent = true;
        setErrorMessage(componentField + `prompt_text`, 'manual', 'Prompt Name harus diisi');
      }
      if (showComponent) {
        setValue(`components[${idxComponent}].is_show`, true);
      }

      component.products.map((product, idxProduct) => {
        let showProduct = false;
        let productField = `components[${idxComponent}].products.[${idxProduct}].`;
        if (!product.product.value && !product.is_deleted) {
          isValid = false;
          showProduct = true;
          setErrorMessage(productField + `product`, 'manual', 'Product harus diisi');
        }
        if ((!product.reguler_price || product.reguler_price <= 0) && !product.is_deleted) {
          isValid = false;
          showProduct = true;
          setErrorMessage(productField + `reguler_price`, 'manual', 'Reguler Price harus diisi');
        }
        if (showProduct) {
          setValue(`components[${idxComponent}].is_show`, true);
          setValue(`components[${idxComponent}].products[${idxProduct}].is_show`, true);
        }
      });
    });

    return isValid;
  };

  const submitForm = (data) => {
    let isValid = validateForm(data);

    if (isValid) {
    }
  };

  useEffect(() => {
    setDefaultForm();
  }, [defaultValuesDetail]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-primary text-white mt-0">Package Detail</CardHeader>
        <CardBody>
          <Col lg={12}>
            <DetailInformationComponents
              disableForm={disableForm}
              packageProductOptions={packageProductOptions}
              {...{ control, register, getValues, setValue, setError, watch, formState: { errors } }}
            />

            <Row className="mt-3">
              {allowEdit && (
                <Col className="text-right">
                  {!disableForm && (
                    <div>
                      <Button color="danger" className="mr-2" onClick={cancelEditForm}>
                        Batalkan
                      </Button>
                      <Button color="primary" type="button" onClick={handleSubmit(submitForm)}>
                        Simpan
                      </Button>
                    </div>
                  )}
                  {!disableForm && (
                    <div>
                      <Button color="primary" className="mr-2" onClick={editForm}>
                        Edit
                      </Button>
                    </div>
                  )}
                </Col>
              )}
            </Row>
          </Col>
        </CardBody>
        <EditPackageModal
          modal={confirmModal}
          isCancel={modalType === 'Batalkan'}
          headerTitle={`${modalType} Hasil Edit?`}
          toggle={() => setConfirmModal(!confirmModal)}
          onConfirm={() => (modalType === 'Simpan' ? updateDetailPackage() : restoreFormPackageDetail())}
        >
          {modalType} hasil edit <b>Component and Product</b> Package <b>{defaultValuesHeader?.name}</b>?
        </EditPackageModal>
      </Card>
    </React.Fragment>
  );
};

export default DetailInformation;
