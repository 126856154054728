import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Helper from '@helpers/Helper';
import { map } from 'lodash';
import { BASE64_OTOKLIX_LOGO } from '@utils/Constants';
import moment from 'moment';

export default class SummaryPdf {
  static print(content, bookingCode) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'potrait',
      content: content,
      header: function (currentPage, pageCount) {
        return [
          {
            image: 'logo',
            width: 100,
            style: {
              alignment: 'left',
              fontSize: 8,
            },
            margin: [40, 30, 0, 0],
          },
        ];
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Halaman ' + currentPage.toString() + ' dari ' + pageCount,
            alignment: 'center',
            bold: true,
            color: '#d6d8e7',
            fontSize: 10,
          },
        ];
      },
      pageMargins: [40, 70, 40, 40],
      images: {
        logo: BASE64_OTOKLIX_LOGO,
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 30, 0, 0],
          alignment: 'center',
        },
        tableStyle: {
          border: '',
          fontSize: 10,
        },
        textCenter: {
          alignment: 'center',
        },
        textRight: {
          alignment: 'right',
        },
        fontSize10: {
          fontSize: 10,
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download(`Summary ${bookingCode ?? ''}`);
  }

  static create(dt) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const invoiceSummaryList = map(dt?.summary, (item, index) => {
      const sequent_number = index + 1;
      return [
        { text: sequent_number, style: 'textCenter' },
        item?.spk_number ?? '-',
        item?.booking_codes ?? '-',
        item?.receipt_number ?? '-',
        item?.license_plate ?? '-',
        item?.car_type ?? '-',
        { text: `Rp ${Helper.formatMoney(item?.subtotal ?? 0)}`, style: 'textRight' },
      ];
    });

    const invoiceSummary = [
      ['No.', 'No. SPK', 'Booking Code', 'No. Receipt', 'License Plate', 'Car Type', 'Subtotal'],
      ...invoiceSummaryList,
      [
        { text: '', border: [false] },
        { text: '', border: [false] },
        { text: '', border: [false] },
        { text: '', border: [false] },
        { text: '', border: [false] },
        { text: '', border: [false] },
        { text: '', border: [false] },
      ],
      [
        { text: '', border: [false] },
        { text: '', border: [false] },
        { text: '', border: [false] },
        { text: '', border: [false] },
        { text: 'Payment Amount', style: 'textRight', colSpan: 2 },
        { text: '', border: [false] },
        { text: `Rp ${Helper.formatMoney(dt?.payment_amount)}`, style: 'textRight' },
      ],
    ];

    const content = [
      {
        text: 'PT Oto Klix Indonesia',
        style: {
          alignment: 'center',
          fontSize: 12,
        },
        margin: [0, 0, 0, 4],
        bold: true,
      },
      {
        text:
          'Jl. Radio Dalam Raya No.22, RT.8/RW.4, Gandaria Utara, Kec. Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12140',
        style: {
          alignment: 'center',
          fontSize: 10,
        },
        bold: true,
      },
      {
        table: {
          body: [
            ['Bill to', ':', dt?.customer_name ?? '-'],
            ['', '', dt?.customer_address ?? '-'],
            ['Nomor Tanda Terima', ':', dt?.invoice_number ?? '-'],
            ['Date', ':', moment().format('DD MMMM YYYY')],
          ],
        },
        style: 'tableStyle',
        margin: [0, 16],
        layout: 'noBorders',
      },
      {
        table: {
          widths: [20, 110, 90, 60, 60, 50, '*'],
          body: invoiceSummary,
        },
        style: 'tableStyle',
        margin: [0, 16],
      },
      {
        table: {
          widths: ['auto', 200, 'auto'],
          heights: [80, 'auto', 'auto'],
          body: [
            [
              {
                text: 'Please settle your payment to BCA PT Oto Klix Indonesia : 0352-988-918',
                colSpan: 3,
                margin: [0, 80, 0, 8],
              },
              {},
              {},
            ],
            [
              { text: 'PT Oto Klix Indonesia', style: 'textCenter', margin: [0, 0, 0, 80] },
              '',
              { text: dt?.customer_name, style: 'textCenter' },
            ],
            [{ text: '', style: 'textCenter' }, '', { text: '_____________________', style: 'textCenter' }],
            [
              { text: '(Tim Finance)', style: 'textCenter' },
              '',
              { text: '(_____________________)', style: 'textCenter' },
            ],
          ],
        },
        style: 'tableStyle',
        margin: [0, 16],
        layout: 'noBorders',
        unbreakable: true,
      },
      {
        text: 'Catatan:',
        style: 'fontSize10',
      },
      {
        type: 'dashed',
        ul: [
          'Setelah ditandatangani, mohon agar dapat discan dan dikirim ke christa.natalia@otoklix.com dan finance-hq@otoklix.com. Apabila ada pertanyaan lebih lanjut, silakan menghubungi kami melalui email tersebut.',
          'Total Faktur di atas sudah dipotong Pph 23 sebesar 2% khusus yang berkaitan dengan layanan. Bukti potong mohon diberikan setiap bulan melalui email tax@otoklix.com.',
          'Mohon melakukan pembayaran sesuai dengan tagihan di atas.',
        ],
        style: 'fontSize10',
        // pageBreak: 'after',
      },
    ];

    return content;
  }
}
