import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import API from '@utils/API';
import { Card, CardBody, CardHeader, Button, Row, Col, FormGroup, Label } from 'reactstrap';
import EditableInputField from '@components/field/EditableInputField';
import EditModal from '@components/modal/EditModal';
import AddReasonEditModal from '@components/modal/AddReasonEditModal';
import StarRating from '@components/review-ratings/StarRating';
import Select from 'react-select';
import Helper from '@helpers/Helper';
import userPermission from '@utils/userPermission';

function ReviewRatingsDetails(props) {
  const { id } = useParams();
  const api = new API('v2');
  const history = useHistory();
  const { generatePermission } = userPermission();

  const { allowEdit, defaultValue, defaultStatus, defaultReviewChar } = props;
  const [formValues, setFormValues] = useState({});
  const [selectedStatus, setSelectedStatus] = useState();
  const [reason, setReason] = useState();
  const [reviewChar, setReviewChar] = useState();
  const [reasonChar, setReasonChar] = useState(0);
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [disabledRating, setDisabledRating] = useState(true);
  const [disabledReview, setDisabledReview] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addReasonModal, setAddReasonModal] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [modalType, setModalType] = useState('');
  const [selectStatusError, setSelectStatusError] = useState('');
  const [reviewError, setReviewError] = useState('');
  const [reviewInvalid, setReviewInvalid] = useState(false);
  const [reasonError, setReasonError] = useState('');
  const [reasonInvalid, setReasonInvalid] = useState(false);
  const [disableButtonEdit, setDisableButtonEdit] = useState(false);

  const StatusOption = [
    { id: 1, value: 'live', label: 'Live' },
    { id: 2, value: 'hide review', label: 'Hide Review' },
    { id: 3, value: 'hide review rating', label: 'Hide Review & Ratings' },
  ];

  useEffect(() => {
    setInitialValues(defaultValue);
  }, [defaultValue]);

  const setInitialValues = () => {
    setFormValues(defaultValue);
    const getStatus = StatusOption.find((item) => item.value === defaultStatus || '');
    setSelectedStatus(getStatus);
    setReviewChar(defaultReviewChar);
  };

  const handleChangeStatus = (e) => {
    if (e?.value) {
      setSelectedStatus(e);
      setSelectStatusError();
      if (e?.value == 'live') {
        setDisabledRating(false);
        setDisabledReview(false);
      }
      if (e?.value == 'hide review') {
        setDisabledRating(false);
        setDisabledReview(true);
        setFormValues({ ...formValues, review: defaultValue.review });
        setReviewChar(defaultReviewChar);
        setReviewInvalid(false);
      }
      if (e?.value == 'hide review rating') {
        setDisabledRating(true);
        setDisabledReview(true);
        setFormValues({ ...formValues, review: defaultValue.review });
        setReviewChar(defaultReviewChar);
        setReviewInvalid(false);
      }
    } else {
      setSelectedStatus({});
      setSelectStatusError('Status is required');
    }
  };

  const color = () => {
    if (selectedStatus?.value == 'live') {
      return 'forestgreen';
    } else if (selectedStatus?.value == 'hide review') {
      return 'orange';
    } else if (selectedStatus?.value == 'hide review rating') {
      return 'red';
    } else {
      return undefined;
    }
  };

  const customStylesSelectStatus = {
    control: (provided) => ({
      ...provided,
      backgroundColor: color(),
    }),
    input: (styles) => ({
      ...styles,
      Color: color(),
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: '#fff',
    }),
  };

  const validationReview = () => {
    let status = true;
    if (formValues.review) {
      if (formValues.review.length > 2000) {
        setReviewError('Format Review max 2000 characters');
        setReviewInvalid(true);
        status = false;
      }
    }
    return status;
  };

  const editForm = () => {
    if (disabledStatus) {
      if (selectedStatus?.value == 'live') {
        setDisabledRating(false);
        setDisabledReview(false);
      }
      if (selectedStatus?.value == 'hide review') {
        setDisabledRating(false);
        setDisabledReview(true);
      }
      if (selectedStatus?.values == 'hide review rating') {
        setDisabledRating(true);
        setDisabledReview(true);
      }
      setDisabledStatus(false);
      setSubmitStatus(null);
    } else {
      if (!validationReview()) {
        return;
      }

      setModalType('Simpan');
      setAddReasonModal(true);
    }
  };

  const cancelEditForm = () => {
    setModalType('Batalkan');
    setConfirmModal(true);
  };

  const restoreFormValues = () => {
    setDisabledStatus(true);
    setDisabledRating(true);
    setDisabledReview(true);
    setReviewError();
    setReviewInvalid(false);
    setFormValues(defaultValue);
    setInitialValues();
    setConfirmModal(false);
  };

  const validationReason = () => {
    let status = true;

    if (!reason) {
      setReasonError('Reason is required');
      setReasonInvalid(true);
      status = false;
    } else if (reason.length > 200) {
      setReasonError('Format Reason max 200 characters');
      setReasonInvalid(true);
      status = false;
    }
    return status;
  };

  const openReviewRatingList = () => {
    if (generatePermission('ratingreview', 'list')) {
      history.push(`/review-ratings`);
    }
  };

  const updateReviewRatingDetail = (e) => {
    e.preventDefault();

    if (!validationReason()) {
      return;
    }

    setDisabledStatus(true);
    setDisabledRating(true);
    setDisabledReview(true);
    setAddReasonModal(false);
    setReasonChar(0);
    setReason();
    setReasonInvalid(false);

    let payload = {};
    if (selectedStatus?.value == 'live') {
      payload = {
        status: selectedStatus?.value ?? '',
        review: formValues?.review ?? '',
        reason: reason ?? '',
      };
    } else {
      payload = {
        status: selectedStatus?.value ?? '',
        reason: reason ?? '',
      };
    }
    api
      .put(`v2/intools/rating-reviews/${id}/`, payload)
      .then((response) => {
        if (response?.data?.data?.id) {
          setDisableButtonEdit(true);
          showAlert('success', 'Berhasil Update Review & Rating Details.');
          setTimeout(() => {
            openReviewRatingList();
          }, 3000);
        } else {
          restoreFormValues();
          showAlert('danger', 'Gagal Update Review & Rating Details.');
        }
      })
      .catch((error) => {
        restoreFormValues();
        showAlert('danger', 'Gagal Update Review & Rating Details.');
      });
  };

  const showAlert = (color, message) => {
    setSubmitStatus({
      color: color,
      message: message,
    });

    setTimeout(() => {
      setSubmitStatus(null);
    }, 3000);
  };

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Review & Rating Detail</CardHeader>
      <CardBody>
        <Row>
          <Col sm={12} md={12}>
            <FormGroup row>
              <Label lg={4} className={'label-required'}>
                {'Status'}
              </Label>
              <Col lg={8}>
                <Select
                  placeholder="Pilih..."
                  closeMenuOnSelect={true}
                  isDisabled={disabledStatus}
                  options={StatusOption}
                  value={selectedStatus}
                  styles={customStylesSelectStatus}
                  onChange={handleChangeStatus}
                />
              </Col>
            </FormGroup>

            <EditableInputField
              labelWidth={4}
              colWidth={8}
              title="Timestamp"
              inputName="timestamp"
              value={formValues.timestamp}
              disabled={true}
            />
            <EditableInputField
              labelWidth={4}
              colWidth={8}
              title="Booking Code"
              inputName="bookingid"
              value={formValues.booking_code}
              disabled={true}
            />
            <EditableInputField
              labelWidth={4}
              colWidth={8}
              title="Nama Pelanggan"
              inputName="name"
              value={formValues.name}
              disabled={true}
            />
            <EditableInputField
              labelWidth={4}
              colWidth={8}
              title="Bengkel Name"
              inputName="bengkel"
              value={formValues.workshop}
              disabled={true}
            />
            <EditableInputField
              labelWidth={4}
              colWidth={8}
              title="Package"
              inputName="packages"
              value={Helper.shortenByDash(formValues.packages)}
              disabled={true}
            />
            <FormGroup row>
              <Label lg={4}>{'Rating'}</Label>
              <Col lg={8}>
                <StarRating rating={formValues.rating} disabled={disabledRating} />
              </Col>
            </FormGroup>
            <EditableInputField
              labelWidth={4}
              colWidth={8}
              placeholder="Review"
              title="Review"
              inputName="review"
              inputType="textarea"
              value={formValues.review}
              disabled={disabledReview}
              onChange={(e) => {
                setReviewChar(e.target.value.length);
                e.target.validity.valid ? setFormValues({ ...formValues, review: e.target.value }) : formValues.review;
                if (e.target.validity.valid && e.target.value.length > 2000) {
                  setReviewError('Format Review max 2000 characters');
                  setReviewInvalid(true);
                }
                if (e.target.validity.valid && e.target.value.length <= 2000) {
                  setReviewError();
                  setReviewInvalid(false);
                }
              }}
              disabledFormText={false}
              formText={reviewChar + ' / 2000 characters'}
              invalid={reviewInvalid}
              errorMessage={reviewError}
            />

            {allowEdit && (
              <Row>
                <Col className="text-right">
                  {!disabledStatus && (
                    <Button color="danger" className="mr-2" onClick={cancelEditForm} disabled={disableButton}>
                      Batalkan
                    </Button>
                  )}
                  <Button color="primary" onClick={editForm} disabled={disableButtonEdit}>
                    {disabledStatus ? 'Edit' : 'Simpan'}
                  </Button>
                </Col>
              </Row>
            )}

            <Row className="mt-3">
              <Col>
                {submitStatus && <div className={`alert alert-${submitStatus?.color}`}>{submitStatus?.message}</div>}
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>

      <AddReasonEditModal
        headerTitle="Alasan Edit ?"
        modal={addReasonModal}
        toggle={() => {
          setAddReasonModal(!addReasonModal);
          setReasonChar(0);
          setReason();
          setReasonInvalid(false);
        }}
        onConfirm={(e) => (modalType === 'Simpan' ? updateReviewRatingDetail(e) : restoreFormValues())}
      >
        <EditableInputField
          labelWidth={0}
          colWidth={12}
          placeholder="Alasan"
          inputName="reason"
          inputType="textarea"
          value={reason}
          onChange={(e) => {
            setReasonChar(e.target.value.length);
            e.target.validity.valid ? setReason(e.target.value) : reason;
            if (e.target.validity.valid && e.target.value.length > 200) {
              setReasonError('Format Reason max 200 characters');
              setReasonInvalid(true);
            }
            if (e.target.validity.valid && e.target.value.length <= 200) {
              setReasonError();
              setReasonInvalid(false);
            }
          }}
          disabledFormText={false}
          formText={reasonChar + ' / 200 characters'}
          invalid={reasonInvalid}
          errorMessage={reasonError}
        />
      </AddReasonEditModal>

      <EditModal
        headerTitle={`Batalkan Hasil Edit?`}
        modal={confirmModal}
        isCancel={'true'}
        toggle={() => setConfirmModal(!confirmModal)}
        onConfirm={() => restoreFormValues()}
      >
        {modalType} hasil edit <b>Review & Rating Detail</b> untuk <b>{formValues.workshop}</b>?
      </EditModal>
    </Card>
  );
}

export default ReviewRatingsDetails;
