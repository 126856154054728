import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Col,
  Button,
  Card,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from 'reactstrap';
import moment from 'moment';
import map from 'lodash/map';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import Wrapper from '@components/wrapper/Wrapper';
import Loading from '@components/loading/Loading';
import userPermission from '@utils/userPermission';
import DataTable from 'react-data-table-component';
import ChevronUp from '@assets/icons/chevron-up-white.svg';
import ChevronDown from '@assets/icons/chevron-down-white.svg';
import CustomModal from '@components/modal/CustomModal';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import WorkshopPriceImport from '@components/workshops/WorkshopPriceImport';
import WorkshopPackageImport from '@components/workshops/WorkshopPackageImport';

function Workshops() {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const api = new API('v2');

  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [dataExport, setDataExport] = useState([]);
  const [disabledExport, setDisabledExport] = useState(true);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [openExport, setOpenExport] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [hasModalImportCSV, setHasModalImportCSV] = useState(false);
  const [hasModalPackageImportCSV, setHasModalPackageImportCSV] = useState(false);
  const [hasModalExport, setHasModalExport] = useState(false);
  const [download, setDownload] = useState('');
  const [loadCsv, setLoadCsv] = useState(false);
  const [loadXlxs, setLoadXlxs] = useState(false);
  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);
  const keys = ['id', 'name', 'workshop_type', 'type', 'status', 'start_date', 'end_date'];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowAdd = allowPermission('all') || allowPermission('create');
  const allowExport = allowPermission('all') || allowPermission('export');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const statusOptions = [
    { value: '', label: 'Semua Status' },
    { value: 'activate', label: 'Active' },
    { value: 'deactivate', label: 'Deactive' },
  ];

  const workshopTypeOptions = [
    { value: 'semua', label: 'Semua' },
    { value: 'b2c', label: 'B2C' },
    { value: 'b2b', label: 'B2B' },
  ];

  const dataFilter = [
    {
      id: 'filter_text_id',
      width: 'w-170',
      type: 'text',
      name: 'id',
      name_field: 'id',
      value: tempFiltered?.id ?? '',
      placeholder: 'ID',
    },
    {
      id: 'filter_text_name',
      type: 'text',
      name: 'name',
      name_field: 'name',
      value: tempFiltered?.name ?? '',
      placeholder: 'Nama',
    },
    {
      id: 'filter_select_province',
      width: 'w-261',
      type: 'select',
      name: 'province',
      name_field: 'province',
      options: provinceOptions,
      value: '',
      placeholder: 'Provinsi',
    },
    {
      id: 'filter_select_city',
      width: 'w-261',
      type: 'select',
      name: 'city',
      name_field: 'city',
      options: cityOptions,
      value: '',
      placeholder: 'Kota/Kabupaten',
    },

    {
      id: 'filter_select_workshop_type',
      width: 'w-261',
      type: 'select',
      name: 'workshop_type',
      name_field: 'workshop_type',
      options: workshopTypeOptions,
      value: tempFiltered?.workshop_type
        ? workshopTypeOptions.filter((type) => type?.value === tempFiltered?.workshop_type)?.[0]
        : '',
      placeholder: 'Tipe',
    },
    {
      id: 'filter_select_status',
      width: 'w-261',
      type: 'select',
      name: 'status',
      name_field: 'status',
      options: statusOptions,
      value: tempFiltered?.status ? statusOptions.filter((type) => type?.value === tempFiltered?.status)?.[0] : '',
      placeholder: 'Status',
    },
    {
      id: 'filter_select_type',
      width: 'w-261',
      type: 'select',
      name: 'type',
      name_field: 'type',
      options: typeOptions,
      value: '',
      placeholder: 'Tipe Bengkel',
    },
    {
      id: 'filter_date_created_date',
      type: 'date',
      name: 'created_date',
      name_field: 'created_date',
      date_range: true,
      value:
        tempFiltered?.start_date && tempFiltered?.end_date
          ? { start_date: tempFiltered?.start_date, end_date: tempFiltered?.end_date }
          : '',
      placeholder: 'Create Date',
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'ID',
        selector: (row) => row?.id ?? '-',
        wrap: true,
        minWidth: '100px',
      },
      {
        name: 'Nama',
        selector: (row) => row?.name ?? '-',
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'Slug',
        selector: (row) => row?.slug ?? '-',
        width: '200px',
        wrap: true,
      },
      {
        name: 'Status',
        minWidth: '150px',
        cell: (row) =>
          row.ws_status === 'activate' ? (
            <span className="badge-custom badge-custom-primary">{'Activate'}</span>
          ) : (
            <span className="badge-custom badge-custom-yellow">{'Deactivate'}</span>
          ),
      },
      {
        name: 'Tipe Bengkel',
        selector: (row) => row?.tier_name ?? '-',
        width: '140px',
        wrap: true,
      },
      {
        name: 'Kota/Kabupaten',
        selector: (row) => row?.city_name ?? '-',
        width: '140px',
        wrap: true,
      },
      {
        name: 'Alamat',
        cell: (row) => <div className="py-2">{generateLabelAddress(row?.street_address ?? '')}</div>,
        wrap: true,
        minWidth: '400px',
      },
      {
        name: 'Create Date',
        selector: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('DD MMM, YYYY') : '-'}
            </span>
            <span className="time">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('HH:mm:ss') : '-'}
            </span>
          </div>
        ),
        width: '140px',
        wrap: true,
        left: true,
      },
      {
        name: 'Update At',
        selector: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.updated_at ?? null).isValid() ? moment(row?.updated_at).format('DD MMM, YYYY') : '-'}
            </span>
            <span className="time">
              {moment(row?.updated_at ?? null).isValid() ? moment(row?.updated_at).format('HH:mm:ss') : '-'}
            </span>
          </div>
        ),
        width: '140px',
        wrap: true,
        left: true,
      },
    ],
    []
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  const generateLabelAddress = (address) => {
    let labelAddress = address;

    if (address?.length > 130) {
      labelAddress = address.substring(0, 130);
      labelAddress += '...';
    }

    return labelAddress;
  };

  async function fetchWorkshops() {
    setLoading(true);
    setDisabledExport(true);
    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/workshops/`, { params });
      setData(response?.data?.data?.workshops ?? []);
      setTotalRows(response?.data?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
      handleDataExport(response?.data?.data?.workshops ?? []);
    } catch (error) {
      setPage(1);
      setData([]);
      handleDataExport([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
    }
  }

  async function fetchProvinceOptions() {
    let listProvince = [{ value: '', label: 'Semua Provinsi' }];
    try {
      const response = await api.get(`v2/md/location/province/`);
      let resultListProvince = map(response?.data?.data, (item) => {
        return { value: item?.id, label: item?.name };
      });
      resultListProvince.splice(0, 0, { value: '', label: 'Semua Provinsi' });
      listProvince = resultListProvince;
      setProvinceOptions(listProvince);
    } catch (error) {
      setProvinceOptions(listProvince);
    }
  }

  async function fetchCityOptions(provinceID) {
    let listCity = [];
    try {
      const response = await api.get(`v2/md/location/${provinceID}/`);
      listCity = map(response?.data?.data, (item) => {
        return { value: item?.name, label: item?.name };
      });
      setCityOptions(listCity);
    } catch (error) {
      setCityOptions(listCity);
    }
  }

  async function fetchTypeOptions() {
    let listType = [{ value: '', label: 'Semua Tipe Bengkel' }];

    try {
      const response = await api.get(`v2/intools/workshops/tier_options/`);
      let resultListType = map(response?.data?.data, (item) => {
        return { value: item?.name, label: item?.name };
      });
      resultListType.splice(0, 0, { value: '', label: 'Semua Tipe Bengkel' });
      listType = resultListType;

      setTypeOptions(listType);
    } catch (error) {
      setTypeOptions(listType);
    }
  }

  const getParamData = () => {
    let params = {
      id: filtered?.id ?? null,
      nama: filtered?.name ?? null,
      kota_kabupaten: filtered?.city?.value ?? null,
      province_name: filtered?.province?.label ?? null,
      status: filtered?.status ?? null,
      tipe_bengkel: filtered?.type?.value ?? null,
      workshop_type: filtered?.workshop_type ?? null,
      start_date: filtered?.created_date?.start_date
        ? moment(filtered?.created_date?.start_date).format('YYYY-MM-DD')
        : null,
      end_date: filtered?.created_date?.end_date ? moment(filtered?.created_date?.end_date).format('YYYY-MM-DD') : null,
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.data?.pagination?.total_page) {
      setPage(response?.data?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleDataExport = (dataWorkshop) => {
    let arrDataExport = [];
    dataWorkshop?.length &&
      dataWorkshop.forEach((item) => {
        const objDataExport = {
          name: item?.name ?? '-',
          phone: item?.phone ?? '-',
          address: item?.street_address ?? '-',
          status: item?.ws_status == 'activate' ? 'Activate' : 'Deactivate',
        };
        arrDataExport.push(objDataExport);
      });
    setDataExport(arrDataExport);
    setDisabledExport(false);
  };

  const handleCreateWorkshop = () => {
    if (allowAdd) {
      history.push('/workshops/create');
    }
  };

  const handleDownloadCSV = () => {
    if (allowExport && allowList) {
      const keys = ['name', 'phone', 'address', 'status'];
      const headers = ['Workhsop Name', 'Phone', 'Address', 'Status'];
      Helper.downloadCSV(dataExport, headers, keys);
    }
  };

  const handleCloseDownload = () => {
    setLoadCsv(false);
    setLoadXlxs(false);
    setHasModalExport(false);
    setLoadingExport(false);
  };

  const handleDownloadPrice = async (type) => {
    if (allowExport && allowList) {
      setLoadingExport(true);
      const params = {
        id: filtered?.id ?? '',
        nama: filtered?.name ?? '',
        province_name: filtered?.province_name ?? null,
        kota_kabupaten: filtered?.city?.value ?? '',
        status: filtered?.status?.value ?? '',
        tipe_bengkel: filtered?.type?.value ?? '',
        workshop_type: filtered?.workshop_type?.value ?? '',
        // start_date: filtered?.created_date?.start_date
        //   ? moment(filtered?.created_date?.start_date).format('YYYY-MM-DD')
        //   : null,
        // end_date: filtered?.created_date?.end_date
        //   ? moment(filtered?.created_date?.end_date).format('YYYY-MM-DD')
        //   : null,
        file_type: type,
      };
      const urlParam = Helper.jsonToUrlParam(params);
      try {
        const response = await api.get(`v2/intools/workshops/prices/download/?${urlParam}`, { responseType: 'blob' });
        if (+response?.status === 200) {
          handleCloseDownload();
          const blobType =
            type === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          const blob = new Blob([response?.data], { type: blobType });
          const url = window.URL.createObjectURL(blob);
          const fileName = `workshop_price.${type}`;
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }
      } catch (error) {
        handleCloseDownload();
        handleResultNotification({ status: true, type: 'fail', message: `Gagal - Export ${type}` });
      }
    }
  };

  const handleDownloadPackagePrice = async (type) => {
    if (allowExport && allowList) {
      setLoadingExport(true);
      let params = getParamData();
      params = {
        ...params,
        file_type: type,
      };
      try {
        const response = await api.get(`v2/intools/packages/pricing/export/`, {
          params,
          responseType: 'blob',
        });
        const filename = `package_price.${type}`;
        if (+response?.status === 200) {
          handleCloseDownload();
          const blobType =
            type === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          const blob = new Blob([response?.data], { type: blobType });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }
      } catch (error) {
        handleCloseDownload();
        handleResultNotification({ status: true, type: 'fail', message: `Gagal - Export ${type}` });
      }
    }
  };

  const handleSubmitImportCSV = (status, error) => {
    const errorMsg = error ? 'Gagal - CSV Gagal Diupload, ' + error : 'Gagal - CSV Gagal Diupload';
    setHasModalImportCSV(false);
    const notification = {
      status: true,
      type: status ? 'success' : 'fail',
      message: status ? 'Berhasil - CSV Berhasil Diimport' : errorMsg,
    };
    handleResultNotification(notification);
  };

  const handleSubmitPackageImportCSV = (status, error) => {
    const errorMsg = error ? 'Gagal - CSV Gagal Diupload, ' + error : 'Gagal - CSV Gagal Diupload';
    setHasModalPackageImportCSV(false);
    const notification = {
      status: true,
      type: status ? 'success' : 'fail',
      message: status ? 'Berhasil - CSV Berhasil Diimport' : errorMsg,
    };
    handleResultNotification(notification);
  };

  const handleChangeProvinceFilter = () => {
    if (filtered?.province?.value) {
      fetchCityOptions(filtered?.province?.value);
    } else {
      setCityOptions([]);
    }

    if (filtered?.province && filtered?.city?.value) {
      filtered.city = '';
      filters[3].value = '';
      setFiltered({ ...filtered });
      setFilters([...filters]);
    }
  };

  const handleChangeOptions = () => {
    if (filters.length) {
      filters[2].options = provinceOptions;
      filters[3].options = cityOptions;
      filters[6].options = typeOptions;
      setFilters([...filters]);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleResetFilters = () => {
    tempFiltered = {};
    resetFilter();
  };

  const resetFilter = () => {
    const updatedDataFilter = dataFilter.map((filter) => ({
      ...filter,
      value: typeof filter?.value !== '' ? '' : filter?.value,
    }));
    setFilters(updatedDataFilter);
    setFiltered({});
    tempFiltered = {};
  };

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
      workshop_type: e?.workshop_type?.value ?? '',
      province_name: e?.province?.label ?? '',
      status: e?.status?.value ?? '',
      start_date: e?.created_date?.start_date ? moment(e?.created_date?.start_date).format('YYYY-MM-DD 00:00:00') : '',
      end_date: e?.created_date?.end_date ? moment(e?.created_date?.end_date).format('YYYY-MM-DD 23:59:59') : '',
    };
    console.log(`setfiltered`, e);
    console.log(`tempVal`, tempVal);
    setFiltered(tempVal);
  };

  const handleMethodFetchWorkshops = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchWorkshops();
    }
  };

  const openWorkshopDetail = (row) => {
    history.push(`/workshops/${row.id}`);
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  useEffect(() => {
    setFiltered({ ...tempFiltered });
  }, Object.values(tempFiltered));

  useEffect(() => {
    if (filtered) {
      let { province, ...restFiltered } = filtered;

      let tempSetFiltered = { ...filtered };
      tempSetFiltered = {
        start_date: filtered?.start_date ? moment(filtered?.start_date).format('YYYY-MM-DD 00:00:00') : '',
        end_date: filtered?.end_date ? moment(filtered?.end_date).format('YYYY-MM-DD 23:59:59') : '',
        ...restFiltered,
      };
      console.log(tempSetFiltered, '<<<< kopsaopsap');
      const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
      history.push(`/workshops/?${urlParam}`);
      fetchWorkshops();
    }
    //resetBookings();
  }, [filtered?.province_name]);

  useEffect(() => {
    if (page && pageLimit && !firstLoad) {
      fetchWorkshops();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleMethodFetchWorkshops();
    }
  }, [filtered]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeProvinceFilter();
    }
  }, [filtered?.province, filtered?.province_name]);

  useEffect(() => {
    handleChangeOptions();
  }, [typeOptions, provinceOptions, cityOptions]);

  useEffect(() => {
    fetchWorkshops();
    fetchProvinceOptions();
    fetchTypeOptions();
    setFilters(dataFilter);
  }, []);

  return (
    <Wrapper
      title="List Workshop"
      className="wrapperContent"
      showNotification={hasNotif}
      notification={notification}
      onCloseNotification={handleCloseNotification}
    >
      <WorkshopPriceImport
        modal={hasModalImportCSV}
        onSubmit={handleSubmitImportCSV}
        toggle={() => setHasModalImportCSV(!hasModalImportCSV)}
      />
      <WorkshopPackageImport
        modal={hasModalPackageImportCSV}
        onSubmit={handleSubmitImportCSV}
        toggle={() => setHasModalPackageImportCSV(!hasModalPackageImportCSV)}
      />

      <Card className="p-3">
        <CardBody className="p-0">
          <Col sm={12} md={12} className="p-0 mt-0 section-action">
            {allowAdd && (
              <Button className="button-action add w-170" onClick={handleCreateWorkshop}>
                {'Create Workshop'}
              </Button>
            )}
            {allowExport && allowList && (
              <>
                <ButtonDropdown isOpen={openExport} toggle={() => setOpenExport(!openExport)}>
                  <DropdownToggle className="button-action primary" disabled={loadingExport} caret>
                    {loadingExport ? (
                      <Spinner size="sm" />
                    ) : (
                      <>
                        Export CSV
                        <img
                          src={openExport ? ChevronUp : ChevronDown}
                          alt=""
                          style={{ position: 'absolute', right: 10, top: 9 }}
                        />
                      </>
                    )}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <Button
                        disabled={disabledExport || loading}
                        className="button-action add"
                        onClick={handleDownloadCSV}
                      >
                        List Bengkel
                      </Button>
                    </DropdownItem>
                    <DropdownItem>
                      <Button
                        disabled={disabledExport || loading}
                        className="button-action add"
                        onClick={() => {
                          setHasModalExport(true);
                          setDownload('Inventory');
                        }}
                      >
                        Harga Inventory
                      </Button>
                    </DropdownItem>
                    <DropdownItem>
                      <Button
                        disabled={disabledExport || loading}
                        className="button-action add"
                        onClick={() => {
                          setHasModalExport(true);
                          setDownload('Packages');
                        }}
                      >
                        Harga Paket
                      </Button>
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
                <ButtonDropdown isOpen={openImport} toggle={() => setOpenImport(!openImport)}>
                  <DropdownToggle className="button-action add" disabled={loadingImport} caret>
                    {loadingImport ? (
                      <Spinner size="sm" />
                    ) : (
                      <>
                        Import CSV
                        <img
                          src={openImport ? ChevronUp : ChevronDown}
                          alt=""
                          style={{ position: 'absolute', right: 10, top: 9 }}
                        />
                      </>
                    )}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <Button
                        disabled={disabledExport || loading}
                        className="button-action primary"
                        onClick={() => setHasModalImportCSV(true)}
                      >
                        Harga Inventory
                      </Button>
                    </DropdownItem>
                    <DropdownItem>
                      <Button
                        disabled={disabledExport || loading}
                        className="button-action primary"
                        onClick={() => setHasModalPackageImportCSV(true)}
                      >
                        Harga Paket
                      </Button>
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </>
            )}
          </Col>
          {filters?.length !== 0 && (
            <Col sm={12} md={12} className="p-0 mt-2">
              <ActionFilterCustom
                filters={filters}
                loading={loading}
                onApplyFilters={(e) => handleSetFiltered(e)}
                onResetFilters={handleResetFilters}
              ></ActionFilterCustom>
            </Col>
          )}
          <Col sm={12} md={12} className="p-0 mt-2">
            <DataTable
              persistTableHead
              highlightOnHover
              noHeader={true}
              progressPending={loading}
              data={data}
              columns={allowList ? columns : []}
              className="table dt-responsive table-custom"
              noDataComponent={noDataComponent}
              progressComponent={<Loading />}
              customStyles={customStylesHeaderTable}
              onRowClicked={(row) => openWorkshopDetail(row)}
            />
          </Col>
          <Col sm={12} md={12} className="p-0 mt-2">
            {data?.length >= 1 && !loading && allowList && (
              <CustomPagination
                page={page}
                pageLimit={pageLimit}
                totalRows={data?.length ?? 0}
                totalAllRows={totalRows}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                handleChangeRowPerPage={handleChangeRowPerPage}
              ></CustomPagination>
            )}
          </Col>
        </CardBody>
      </Card>
      <CustomModal
        className="modal-confirm-inventory"
        modalWidth={'auto'}
        modal={hasModalExport}
        toggle={() => setHasModalExport(false)}
        modalHeader="Silahkan Pilih Format File"
        modalFooter={
          <>
            <Button
              color="secondary"
              size="md"
              disabled={loadCsv || loadXlxs}
              onClick={() => {
                setLoadCsv(true);
                if (download === 'Inventory') {
                  handleDownloadPrice('csv');
                } else {
                  handleDownloadPackagePrice('csv');
                }
              }}
            >
              {loadCsv ? <Spinner size="sm" /> : 'CSV'}
            </Button>
            <Button
              color="primary"
              size="md"
              disabled={loadCsv || loadXlxs}
              onClick={() => {
                setLoadXlxs(true);
                if (download === 'Inventory') {
                  handleDownloadPrice('xlsx');
                } else {
                  handleDownloadPackagePrice('xlsx');
                }
              }}
            >
              {loadXlxs ? <Spinner size="sm" /> : 'Excel'}
            </Button>
          </>
        }
      >
        Anda akan melakukan export {download} data dalam bentuk file CSV atau Excel ?
      </CustomModal>
    </Wrapper>
  );
}

export default Workshops;
