import React, { useState, useRef, useEffect } from 'react';
import { Col } from 'reactstrap';
import { components } from 'react-select';
import { findIndex } from 'lodash';
import AsyncSelect from 'react-select/async';
import ItemsModal from '@components/modal/ItemsModal';
import DropdownCreate from '@components/field/DropdownCreate';

const SelectAsyncField = (props) => {
  const {
    value,
    onChange,
    onInputChange,
    onClickCreate,
    isDisabled,
    colWidth,
    defaultOptions,
    options,
    isMulti = false,
    isSelectAll = false,
    isCreate = false,
    needInputChange = false,
    placeholder,
    getOptionLabel,
    getOptionValue,
    loadOptions,
    errorMessage,
    customClassName,
    selectName,
    closeMenuOnSelect = true,
    maxPreview = 5,
    titlePreview,
    placeholderPreview,
    labelDataPreview,
    textLabelCreate = '',
    customControlSelected = false,
    customControlKeySelected = '',
    customControlValueSelected = null,
    customControlColorLabelSelected = '#fff',
    customControlColorTextSelected = '#fff',
    cacheOptions = true,
    loading = false,
    isClearable = false,
    isOptionDisabled = false,
    id = '',
  } = props;

  const selectAsyncReff = useRef();
  const [searchInput, setSearchInput] = useState();
  const [showModalItems, setShowModalItems] = useState(false);
  const [optionsDefault, setOptionsDefault] = useState([]);
  const [withSelectAll, setWithSelectAll] = useState(isSelectAll);
  const [open, setOpen] = useState(false);

  const controlBorderStyle = (state) => {
    let style = '';
    if (state?.isFocused)
      style = customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? '1px solid ' + customControlColorTextSelected
          : '1px solid #6610F2'
        : '1px solid #6610F2';
    if (!state?.isFocused)
      style = customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? '1px solid ' + customControlColorTextSelected
          : '1px solid #CED4DA'
        : '1px solid #CED4DA';
    if (errorMessage) style = '1px solid red';
    if (state?.isDisabled) style = '1px solid #CED4DA';

    return style;
  };

  const dropdownIndicatorStyle = (state) => {
    let style = '';
    if (state?.isFocused)
      style = customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorTextSelected
          : '#6610F2'
        : '#6610F2';
    if (!state?.isFocused)
      style = customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorTextSelected
          : 'hsl(0,0%,80%)'
        : 'hsl(0,0%,80%)';
    if (errorMessage) style = 'red';
    if (state?.isDisabled) style = '';

    return style;
  };

  const clearIndicatorStyle = (state) => {
    let style = '';
    if (state?.isFocused) style = '#6610F2';
    if (!state?.isFocused) style = 'hsl(0,0%,80%)';

    return style;
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state?.isDisabled
        ? '#e6e6e6'
        : state?.isSelected
        ? '#6610F2'
        : state?.isFocused
        ? '#E0CFFC'
        : '#fff',
      color: state?.isDisabled ? '#272a21' : state?.isSelected ? '#fff' : state?.isFocused ? '#6610F2' : '#6C757D',
      cursor: state?.isFocused && !state?.isDisabled ? 'pointer' : '',
      '&:hover': {
        color: state?.isSelected ? '#fff' : state?.isDisabled ? '#272a21' : '#6610F2',
        backgroundColor: state?.isSelected ? '#6610F2' : state?.isDisabled ? '#e6e6e6' : '#E0CFFC',
        cursor: state?.isDisabled ? '' : 'pointer',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      border: controlBorderStyle(state),
      borderRadius: isDisabled && isMulti && value?.length > maxPreview ? '0.25rem 0.25rem 0rem 0.25rem' : '0.25rem',
      backgroundColor: customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorLabelSelected
          : state?.isDisabled
          ? '#DEE2E6'
          : '#fff'
        : state?.isDisabled
        ? '#DEE2E6'
        : '#fff',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorTextSelected
          : '#052C65'
        : '#052C65',
    }),
    input: (provided) => ({
      ...provided,
      color: customControlSelected
        ? customControlValueSelected == customControlKeySelected
          ? customControlColorTextSelected
          : '#052C65'
        : '#052C65',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      borderRadius: isMulti && withSelectAll && isSelectAll ? '4px 4px 0px 0px' : 4,
      paddingBottom: 0,
      border: '0.5px solid #6610F2',
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        color: '#BDBDBD',
        fontWeight: '200',
      };
    },
    multiValue: (provided, state) => {
      return {
        ...provided,
        borderRadius: '0.25rem',
        backgroundColor: state?.isDisabled ? '#68717A' : '#6610F2',
      };
    },
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: '#FFFFFF',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: state?.isDisabled ? '#68717A' : '#FFFFFF',
      '&:hover': {
        color: '#6610F2',
        backgroundColor: '#E0CFFC',
        borderRadius: '0rem 0.25rem 0.25rem 0rem',
      },
    }),
    clearIndicator: (provided, state) => {
      return {
        ...provided,
        color: clearIndicatorStyle(state),
      };
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        color: dropdownIndicatorStyle(state),
      };
    },
  };

  const menuHeaderStyle = {
    fontWeight: 'bold',
    cursor: 'pointer',
    padding: '8px 12px',
    bottom: '0px',
    border: '0.5px solid #6610F2',
    borderRadius: '0px 0px 4px 4px',
    color: '#FFFFFF',
    backgroundColor: '#6610F2',
  };

  const handleCreate = (e) => {
    onClickCreate(e);
    setOpen(false);
  };

  const MenuList = (props) => {
    return (
      <>
        {!isCreate && (
          <components.MenuList {...props} className="p-0">
            {props.children}
          </components.MenuList>
        )}
        {isCreate && (
          <DropdownCreate onCreateClick={() => handleCreate(props)} textLabel={textLabelCreate} {...props} />
        )}
      </>
    );
  };

  const Menu = (props) => {
    return (
      <>
        <components.Menu {...props}>
          {props.children}
          {isMulti && withSelectAll && isSelectAll && optionsDefault?.length !== 0 && (
            <div style={menuHeaderStyle} onClick={handleSelectAll}>
              Pilih Semua
            </div>
          )}
        </components.Menu>
      </>
    );
  };

  const MultiValueContainer = (props) => {
    const position = findIndex(props?.selectProps?.value, (item) => {
      return item === props?.data;
    });
    return (
      <>
        {!isDisabled && <components.MultiValueContainer {...props} />}
        {isDisabled && position < maxPreview && <components.MultiValueContainer {...props} />}
        {isDisabled && value?.length > maxPreview && position == value?.length - 1 && (
          <div className="select-others">
            <div className="value-select">{'+' + (value?.length - maxPreview) + ' Lainnya'}</div>
          </div>
        )}
      </>
    );
  };

  const handleInputChange = (e) => {
    setSearchInput(e);
    if (needInputChange) onInputChange(e);
  };

  const handleChange = (e) => {
    const result = isMulti && !e?.length ? [] : e;

    if (isMulti && !closeMenuOnSelect) setSearchInput(searchInput);
    onChange(result);
  };

  const handleSelectAll = () => {
    let newOptions = value?.length ? value : [];
    const filteredOptions = selectAsyncReff?.current?.optionsCache[searchInput]?.length
      ? selectAsyncReff?.current?.optionsCache[searchInput]
      : [];
    const filteredOptionsClear = filteredOptions.filter((filteredOption) => !isAvailableValue(filteredOption));
    newOptions = newOptions.concat(filteredOptionsClear);

    setSearchInput('');
    selectAsyncReff?.current?.select?.onMenuClose();
    onChange(newOptions);
  };

  const handleSelectMore = () => {
    setShowModalItems(true);
  };

  const isAvailableValue = (option) => {
    const data = value?.length ? value : [];
    const position = findIndex(data, (item) => {
      return item === option;
    });

    return position !== -1 ? true : false;
  };

  const calculateCountListOption = () => {
    setTimeout(() => {
      const filteredOptions = selectAsyncReff?.current?.optionsCache[searchInput]?.length
        ? selectAsyncReff?.current?.optionsCache[searchInput]
        : [];
      const filteredOptionsClear = filteredOptions.filter((filteredOption) => !isAvailableValue(filteredOption));

      setOptionsDefault(filteredOptions);
      setWithSelectAll(filteredOptionsClear?.length > 1 ? true : false);
    }, 10);
  };

  useEffect(() => {
    if (searchInput !== undefined && isMulti) {
      calculateCountListOption();
    }
  }, [searchInput, value, options]);

  useEffect(() => {
    if (!isMulti) {
      setOptionsDefault(defaultOptions);
    }
  }, [defaultOptions]);

  return (
    <>
      <Col lg={colWidth}>
        <AsyncSelect
          id={id}
          inputId={id}
          ref={selectAsyncReff}
          components={{ IndicatorSeparator: () => null, Menu, MenuList, MultiValueContainer }}
          cacheOptions={cacheOptions}
          className={customClassName}
          name={selectName}
          isDisabled={isDisabled}
          defaultOptions={optionsDefault}
          placeholder={placeholder}
          value={value}
          isMulti={isMulti}
          closeMenuOnSelect={closeMenuOnSelect}
          inputValue={searchInput}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          loadOptions={loadOptions}
          onInputChange={(e) => handleInputChange(e)}
          onChange={(e) => handleChange(e)}
          styles={customStyles}
          isLoading={loading}
          isClearable={isClearable}
          isOptionDisabled={isOptionDisabled}
          menuIsOpen={open}
          onMenuOpen={() => setOpen(true)}
          onMenuClose={() => setOpen(false)}
        />
        {isDisabled && isMulti && value?.length > maxPreview && (
          <div className="select-more" onClick={handleSelectMore}>
            <div className="content-select-more">{'Lihat Semua'}</div>
          </div>
        )}
        {errorMessage && <div className="pt-1 text-danger">{errorMessage}</div>}
      </Col>
      <ItemsModal
        data={value}
        modal={showModalItems}
        title={titlePreview}
        placeholder={placeholderPreview}
        labelData={labelDataPreview}
        toggle={() => {
          setShowModalItems(!showModalItems);
        }}
      />
    </>
  );
};

export default SelectAsyncField;
