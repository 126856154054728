import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import Select from 'react-select';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import Helper from '../../helpers/Helper';

const RescheduleModal = (props) => {
  const {
    modal,
    toggle,
    bookingDate,
    bookingTime,
    reason,
    onChangeReason,
    onChangeRescheduleTime,
    onChangeBookingDate,
    onReschedule,
    hasInvalidDate,
    scheduleButtonLoading,
    messageInvalidDate,
    bookingId,
    priority,
  } = props;

  const api = new API('v2');
  const { generatePermission } = userPermission();

  const [optionsTime, setOptionTime] = useState([]);
  const [valueBookingTime, setValueBookingTime] = useState(null);

  const getTime = () => {
    api
      .get(`v2/intools/bookings/schedule/${bookingId}/${bookingDate}/${priority}/time-slot/`)
      .then((result) => {
        const mappingData = result?.data?.data.map((item) => Helper.mappingOptionsTime(item));
        setOptionTime(mappingData);
      })
      .catch((err) => {
        console.log('error get schedule -> ', err?.response);
      });
  };

  useEffect(() => {
    if (bookingTime) {
      setValueBookingTime({ label: bookingTime, value: bookingTime });
    }
  }, [bookingTime]);

  useEffect(() => {
    if (bookingId && bookingDate) {
      if (generatePermission('booking', 'reschedule')) {
        getTime();
      }
      setValueBookingTime(null);
      onChangeRescheduleTime('');
    }
  }, [bookingId, bookingDate, priority]);
  return (
    <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Reschedule Booking Date</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label sm={4}>New Booking Date</Label>
            <Col sm={8}>
              <Input
                type="date"
                placeholder="New Booking Date"
                value={bookingDate}
                onChange={onChangeBookingDate}
                invalid={hasInvalidDate}
              />
              <FormFeedback>{messageInvalidDate}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>New Booking Time</Label>
            <Col sm={8}>
              <Select
                name="time"
                placeholder="Select Booking Time"
                closeMenuOnSelect={true}
                onChange={onChangeRescheduleTime}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                options={optionsTime}
                value={valueBookingTime}
                defaultValue={'09:00'}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Reason</Label>
            <Col sm={8}>
              <Input placeholder="Reason" onChange={onChangeReason} value={reason} />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Cancel
        </Button>{' '}
        <Button color="secondary" disabled={scheduleButtonLoading} onClick={onReschedule}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RescheduleModal;
