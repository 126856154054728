import React from 'react';
import get from 'lodash/get';
import { Button } from 'reactstrap';

const ExportButton = ({
  data,
  title,
  hasActionCreate,
  hasActionExport = true,
  hasDisableExport = false,
  createTitle,
  onClickCreate,
  keys,
  header,
  hasActionButton,
  showModalButton,
  buttonTitle,
  wrapperClassName,
}) => {
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';

    result = '';
    result += header.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += get(item, key);

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const ShowModal = (e) => {
    showModalButton(e);
  };

  const Export = ({ onExport, handleCreate, wrapperClassName = '' }) => (
    <div className={wrapperClassName}>
      {hasActionButton && (
        <Button className="btn-export-finance" onClick={() => ShowModal(true)}>
          {buttonTitle}
        </Button>
      )}

      {hasActionExport && (
        <Button className="btn-purple" disabled={hasDisableExport} onClick={(e) => onExport(e.target.value)}>
          {title}
        </Button>
      )}

      {hasActionCreate && (
        <Button className="btn-orange ml-2" onClick={handleCreate}>
          {createTitle}
        </Button>
      )}
    </div>
  );

  return <Export wrapperClassName={wrapperClassName} handleCreate={onClickCreate} onExport={() => downloadCSV(data)} />;
};

export default ExportButton;
