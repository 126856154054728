import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, FormGroup, FormFeedback, Input, Label, Row, Button } from 'reactstrap';
import API from '@utils/API';
import map from 'lodash/map';
import moment from 'moment';
import SelectField from '@components/field/SelectField';
import SubscribeChangeStatus from '@components/subscriptions/subscribe/SubscribeChangeStatus';

const SubscribeHeader = (props) => {
  const { subscribeData, onResultNotification } = props;
  const api = new API('v2');

  const [disableForm, setDisableForm] = useState(false);
  const [defaultData, setDefaultData] = useState(subscribeData);
  const [dataForm, setDataForm] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleChangeStatus = (e) => {
    dataForm.status = e;
    setDataForm({ ...dataForm });
    setShowModal(true);
    setDisableForm(true);
  };

  const defaultDataForm = () => {
    setDataForm({ ...defaultData });
    setDisableForm(false);
  };

  const handleCloseModal = () => {
    defaultDataForm();
    setShowModal(false);
    setDisableForm(false);
  };

  const handleConfirmModal = (params) => {
    setShowModal(false);
    if (params?.type == 1) {
      updateStatusWithReason(params);
    } else if (params?.type == 2) {
      updateStatusWithReason(params);
    } else {
      defaultDataForm();
    }
  };

  async function updateStatusWithReason(params) {
    const payload = {
      id: dataForm?.id ?? null,
      subscription_name: dataForm?.subscription_name ?? '',
      status_id: dataForm?.status?.id ?? null,
      status_value: dataForm?.status?.value ?? null,
    };
    if (params?.notes !== undefined) {
      payload.notes = params?.notes ?? '';
    }

    try {
      const response = await api.put(`v2/intools/customer-subscriptions/${defaultData?.id}/status-update/`, payload);
      handleResultNotification(true, 'success', 'Berhasil update status Paket Langganan');
      handleUpdatedData(response?.data?.data ?? {});
    } catch (error) {
      handleResultNotification(true, 'fail', 'Gagal update status Paket Langganan');
      defaultDataForm();
    }
  }

  const handleUpdatedData = (dataUpdated) => {
    defaultData.status = dataUpdated?.status ?? {};
    defaultData.status_options = dataUpdated?.status_options ?? [];
    setDefaultData({ ...defaultData });
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  useEffect(() => {
    defaultDataForm();
  }, [defaultData]);

  return (
    <React.Fragment>
      <SubscribeChangeStatus
        showModal={showModal}
        subscribeData={subscribeData}
        dataForm={dataForm}
        onClose={handleCloseModal}
        onConfirm={handleConfirmModal}
      ></SubscribeChangeStatus>
      <h5 className="mb-3">Detail Langganan</h5>
      <Row>
        <Col sm={12} md={6}>
          <Row className="mb-3">
            <Label lg={4} className="text-left">
              ID Langganan
            </Label>
            <Label lg={8} className="text-left text-bold">
              : <span className="ml-1">{dataForm?.id ?? '-'}</span>
            </Label>
          </Row>
          <Row className="mb-3">
            <Label lg={4} className="text-left">
              Paket Langganan
            </Label>
            <Label lg={8} className="text-left text-bold">
              : <span className="ml-1">{dataForm?.subscription_name ?? '-'}</span>
            </Label>
          </Row>
        </Col>
        <Col sm={12} md={6}>
          <Row className="mb-3">
            <Label lg={4} className="text-left">
              Status
            </Label>
            <SelectField
              colWidth={6}
              placeholder={'Pilih Status'}
              disabled={disableForm}
              closeMenuOnSelect={true}
              options={dataForm?.status_options ?? []}
              value={dataForm?.status}
              onChange={handleChangeStatus}
            />
          </Row>
          <Row className="mb-3">
            <Label lg={4} className="text-left">
              Tanggal
            </Label>
            <Label lg={8} className="text-left text-bold">
              : {defaultData?.status?.value == 'pending' && <span className="ml-1">{'-'}</span>}
              {defaultData?.status?.value !== 'pending' && (
                <span className="ml-1">
                  {moment(dataForm?.date?.start_date ?? null).isValid() &&
                  moment(dataForm?.date?.end_date ?? null).isValid()
                    ? moment(dataForm?.date?.start_date).format('DD MMM YYYY') +
                      ' - ' +
                      moment(dataForm?.date?.end_date).format('DD MMM YYYY')
                    : '-'}
                </span>
              )}
            </Label>
          </Row>
        </Col>
        {map(dataForm?.services, (item) => {
          return (
            <Col sm={12} md={6}>
              <Row className="mb-3">
                <Label lg={4} className="text-left">
                  {item?.type === 'credit'
                    ? item?.service?.name
                      ? 'Kredit ' + item?.service?.name
                      : 'Kredit'
                    : item?.service?.name
                    ? 'Kredit Rollover ' + item?.service?.name
                    : 'Kredit Rollover '}
                </Label>
                <Label lg={8} className="text-left text-bold">
                  :{' '}
                  <span className="ml-1">
                    {item?.type === 'credit' ? item?.service?.credit ?? '-' : item?.service?.rollovered_credit ?? '-'}
                  </span>
                </Label>
              </Row>
            </Col>
          );
        })}
        <Col sm={12} md={12}>
          <Row className="mb-3">
            <Label lg={2} className="text-left">
              Catatan Internal
            </Label>
            <Label lg={10} className="text-left">
              <div style={{ position: 'absolute', fontWeight: 'bold' }}>:</div>
              <div style={{ marginLeft: '0.7rem' }}>{dataForm?.internal_notes ?? '-'}</div>
            </Label>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SubscribeHeader;
