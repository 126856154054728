import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import map from 'lodash/map';
import moment from 'moment';
import API from '@utils/API';
import DataTable from 'react-data-table-component';
import Loading from '@components/loading/Loading';
import UploadImage from '@components/uploads/UploadImage';
import ConfirmationModal from '@components/modal/ConfirmationModal';

const BookingDetailPhoto = (props) => {
  const api = new API('v2');
  const { detailData, onResultNotification, setIsArrivalPhoto, loadLogHistory } = props;
  const [loading, setLoading] = useState(false);
  const [dataFile, setDataFile] = useState([]);
  const [activeFile, setActiveFile] = useState({});

  const [errorImage, setErrorImage] = useState('');
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [showModal, setShowModal] = useState(false);

  async function fetchFileOtoklix() {
    setLoading(true);

    try {
      const response = await api.get(`v2/intools/bookings/${detailData?.booking_code}/documents/?option=gms`);
      if (response?.data?.data) {
        let customDocCount = 0;
        let customDocFile = 0;

        const fileData = map(response?.data?.data, (item) => {
          const isRequired = false;
          const nameFile = item?.filename
            ? item.filename.length > 25
              ? item.filename.substr(0, 11) + '...' + item.filename.substr(item.filename.length - 11)
              : item.filename
            : '';
          if (item?.document_type == 'arrival_report' && !!item?.filename) {
            setIsArrivalPhoto(true);
          } else if (item?.document_type == 'arrival_report' && !item?.filename) {
            setIsArrivalPhoto(false);
          }

          return {
            id: item?.id,
            time: item?.created_at,
            type_file: item?.document_type,
            label_file: item?.label ?? '',
            required: isRequired,
            slug: item?.slug,
            doc_id: item?.master_document_config_id,
            document: {
              name: nameFile,
              url: item?.url ?? null,
            },
          };
        });
        setDataFile(fileData);
      } else {
        setDataFile([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setDataFile([]);
      setLoading(false);
      handleResultNotification(true, 'fail', 'Gagal mendapatkan lampiran Foto otoklix');
    }
  }

  const handleResultNotification = (status = false, type = '', message = '', without_reload = false) => {
    const notification = {
      status: status,
      type: type,
      message: message,
      without_reload: without_reload,
    };

    onResultNotification(notification);
  };

  async function handleSaveFile(keyFile, fileName, file, is_deleted = false) {
    setShowModal(false);
    setLoading(true);
    const payload = {
      document_type: file?.type_file,
      filename: fileName,
      key: keyFile,
      is_deleted,
      id: file?.id ?? null,
      slug: file?.slug,
      master_document_config_id: file?.doc_id,
    };

    // console.log(file);
    // return;

    api
      .post(`v2/intools/bookings/${detailData?.booking_code}/documents/`, payload)
      .then((response) => {
        handleResultNotification(
          true,
          'success',
          `Berhasil ${is_deleted ? 'menghapus' : 'menyimpan'}  file ` + file?.label_file,
          true
        );
        fetchFileOtoklix();
        loadLogHistory();
      })
      .catch((e) => {
        const message = `API Error : ${e?.response?.data?.error?.message}`;
        handleResultNotification(
          true,
          'fail',
          `Gagal ${is_deleted ? 'menghapus' : 'menyimpan'} file ` + file?.label_file + ' ' + message
        );
        setLoading(false);
      });
  }

  const handleUploadFile = (files, data) => {
    console.log(files);
    setActiveFile(data);
    handleSaveFile(files?.key, files?.file?.name, data);
  };

  const handleErrorUploadFile = (msg, data) => {
    setErrorImage(msg);
    if (msg) {
      handleResultNotification(true, 'fail', 'Gagal upload file ' + data?.label_file + ' ' + msg);
    }
  };

  const handleDeleteFile = (e, data) => {
    setShowModal(true);
    setActiveFile(data);
  };

  const handleLoadingUploadFile = (status) => {
    setLoadingUploadFile(status);
  };

  const columns = useMemo(
    () => [
      {
        name: 'Waktu',
        selector: (row) => row?.time,
        cell: (row) => (moment(row?.time).isValid() ? moment(row?.time).format('MMM DD YYYY , HH:mm:ss') : '-'),
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'File Name',
        selector: (row) => row?.label_file,
        cell: (row) => (
          <div
            className={
              row?.type_file == 'arrival_report' ? 'text-bold text-purple-2 label-required' : 'text-bold text-purple-2'
            }
          >
            {row?.label_file ?? '-'}
          </div>
        ),
        wrap: true,
        minWidth: '210px',
      },
      {
        name: 'File',
        selector: (row) => row?.document?.name,
        cell: (row) => (
          <UploadImage
            uploadTitle=""
            isView={loading}
            image={''}
            textMode={true}
            textPreview={row?.document?.name}
            previewDownload={true}
            previewURL={row?.document?.url}
            error={errorImage}
            nameSpace={'attachment_otoklix'}
            identifier={detailData?.booking_code}
            maxSize={5120}
            maxSizeString={'5 MB'}
            allowTypesString={'jpeg, jpg, png, xlsx, xls, pdf'}
            allowTypes={['jpeg', 'jpg', 'png', 'xlsx', 'xls', 'pdf']}
            onLoading={handleLoadingUploadFile}
            onError={(e) => handleErrorUploadFile(e, row)}
            onDelete={(e) => handleDeleteFile(e, row)}
            onUpload={(e) => handleUploadFile(e, row)}
          />
        ),
        wrap: true,
        minWidth: '300px',
      },
    ],
    []
  );

  useEffect(() => {
    fetchFileOtoklix();
  }, [detailData]);

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Lampiran Foto</CardHeader>
      <CardBody className="pb-0">
        <DataTable
          persistTableHead
          highlightOnHover
          data={dataFile}
          noHeader={true}
          fixedHeader
          progressPending={loading}
          progressComponent={<Loading />}
          fixedHeaderScrollHeight="340px"
          columns={columns}
          className="table table-bordered dt-responsive"
        />
      </CardBody>
      <ConfirmationModal
        modal={showModal}
        isDisabled={false}
        toggle={() => setShowModal(!showModal)}
        header={`Yakin ingin menghapus file "${activeFile?.label_file}" ?`}
        onConfirm={() => handleSaveFile(null, null, activeFile, true)}
        toggleClose={() => setShowModal(!showModal)}
      />
    </Card>
  );
};

export default BookingDetailPhoto;
