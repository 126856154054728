import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Label, Col, Row, Input } from 'reactstrap';
import API from '@utils/API';
import map from 'lodash/map';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import { validate } from 'react-email-validator';
import LoadingSpinner from '@components/loading/LoadingSpinner';

const AccountEdit = (props) => {
  const { idWorkshop, modal, toggle, onSubmit, idAccount, allowEdit } = props;
  const api = new API('v2');
  const [data, setData] = useState();
  const [roleOptions, setRoleOptions] = useState([]);
  const [role, setRole] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState();
  const [errorRole, setErrorRole] = useState('');
  const [errorFirstName, setErrorFirstName] = useState('');
  const [errorLastName, setErrorLastName] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [loadingFetchRole, setLoadingFetchRole] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editForm, setEditForm] = useState(false);

  async function fetchRoleOptions() {
    setLoadingFetchRole(true);
    try {
      const response = await api.get(`v2/intools/workshops/gms-roles/`);
      mappingRoleOptions(response?.data?.data ?? []);
      setLoadingFetchRole(false);
    } catch (error) {
      mappingRoleOptions([]);
      setLoadingFetchRole(false);
    }
  }

  async function fetchAccountDetail() {
    setLoading(true);

    try {
      const response = await api.get(`v2/intools/workshops/${idWorkshop}/gms-account/${idAccount}/`);
      defaultData(response?.data?.data ?? {});
      setData(response?.data?.data ?? {});
      setLoading(false);
    } catch (error) {
      defaultData({});
      setLoading(false);
    }
  }

  const mappingRoleOptions = (data) => {
    let dataRole = [];
    if (data.length !== 0) {
      map(data, (item) => {
        dataRole.push({
          value: item?.id,
          label: item?.name,
        });
      });
    }

    setRoleOptions(dataRole);
  };

  const handleChangeRole = (e) => {
    const valueRole = e?.value ? e : null;
    validateRole(valueRole);
  };

  const handleChangeFirstName = (e) => {
    const valueFirstName = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : firstName;
    validateFirstName(valueFirstName);
  };

  const handleChangeLastName = (e) => {
    const valueLastName = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : lastName;
    validateLastName(valueLastName);
  };

  const handleChangeEmail = (e) => {
    const valueEmail = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : email;
    validateEmail(valueEmail);
  };

  const handleChangeStatus = (e) => {
    setStatus(e);
  };

  const validateRole = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorRole('Role harus di isi');
    } else {
      setErrorRole('');
    }

    setRole(value);
    return passValidate;
  };

  const validateFirstName = (value) => {
    let passValidate = true;

    if (value?.length == 0) {
      passValidate = false;
      setErrorFirstName('Nama Depan harus diisi');
    } else if (value?.length > 20) {
      passValidate = false;
      setErrorFirstName('Maksimal format 20 karakter');
    } else {
      setErrorFirstName('');
    }

    setFirstName(value);
    return passValidate;
  };

  const validateLastName = (value) => {
    let passValidate = true;

    if (value?.length > 20) {
      passValidate = false;
      setErrorLastName('Maksimal format 20 karakter');
    } else {
      setErrorLastName('');
    }

    setLastName(value);
    return passValidate;
  };

  const validateEmail = (value) => {
    let emailCheck = validate(value);
    let passValidate = true;

    if (value.length == 0) {
      passValidate = false;
      setErrorEmail('Email harus diisi');
    } else if (!emailCheck) {
      passValidate = false;
      setErrorEmail('Format Email tidak sesuai');
    } else {
      setErrorEmail('');
    }

    setEmail(value);
    return passValidate;
  };

  const handleSubmit = () => {
    const passValidateFirstName = validateFirstName(firstName);
    const passValidateEmail = validateEmail(email);
    const passValidateRole = validateRole(role);

    if (passValidateRole && passValidateFirstName && passValidateEmail) {
      submitForm();
    }
  };

  async function submitForm() {
    toggle();
    setLoading(true);
    const payload = {
      role_id: role?.value ?? '',
      first_name: firstName ?? '',
      last_name: lastName ?? null,
      email: email ?? '',
      is_active: status,
    };

    try {
      await api.post(`v2/intools/workshops/${idWorkshop}/gms-account/${idAccount}/`, payload);
      onSubmit(true);
      setLoading(false);
    } catch (error) {
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  const defaultData = (data) => {
    setRole({ value: data?.role?.id, label: data?.role?.name });
    setFirstName(data?.first_name ?? '');
    setLastName(data?.last_name ?? '');
    setEmail(data?.email ?? '');
    setStatus(data?.status == 'active' ? true : false);
    setErrorRole('');
    setErrorFirstName('');
    setErrorLastName('');
    setErrorEmail('');
    setEditForm(false);
  };

  const edit = () => {
    setEditForm(true);
  };

  const cancel = () => {
    setEditForm(false);
    defaultData(data);
  };

  const close = () => {
    toggle();
    defaultData();
  };

  useEffect(() => {
    if (modal) {
      fetchRoleOptions();
      fetchAccountDetail();
    }
  }, [modal]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">{editForm ? 'Edit Account' : 'Detail Account'}</div>
        {loading && <LoadingSpinner />}
        {editForm && !loading && allowEdit && (
          <>
            <div className="content">
              <FormGroup row>
                <Label lg={4} className="label-required">
                  Pilih Role
                </Label>
                <SelectField
                  colWidth={8}
                  name="role"
                  placeholder="Pilih Role"
                  closeMenuOnSelect={true}
                  value={role}
                  options={roleOptions}
                  disabled={loading}
                  isLoading={loadingFetchRole}
                  errorMessage={errorRole}
                  onChange={handleChangeRole}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={4} className="label-required">
                  Nama Depan
                </Label>
                <InputField
                  colWidth={8}
                  inputType={'text'}
                  disabled={loading}
                  placeholder="First Name"
                  value={firstName ?? ''}
                  invalid={errorFirstName ? true : false}
                  errorMessage={errorFirstName ?? ''}
                  onChange={handleChangeFirstName}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={4} className="">
                  Nama Belakang
                </Label>
                <InputField
                  colWidth={8}
                  inputType={'text'}
                  disabled={loading}
                  placeholder="Last Name"
                  value={lastName ?? ''}
                  invalid={errorLastName ? true : false}
                  errorMessage={errorLastName ?? ''}
                  onChange={handleChangeLastName}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={4} className="label-required">
                  Email
                </Label>
                <InputField
                  colWidth={8}
                  inputType={'text'}
                  disabled={loading}
                  placeholder="Email"
                  value={email ?? ''}
                  invalid={errorEmail ? true : false}
                  errorMessage={errorEmail ?? ''}
                  onChange={handleChangeEmail}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>Status</Label>
                <Col sm={8} lg={8}>
                  <Row>
                    <Col sm={12} md={6}>
                      <Label className="ml-4 mt-2 mb-0">
                        <Input type="radio" checked={status ? true : false} onClick={() => handleChangeStatus(true)} />
                        Active
                      </Label>
                    </Col>
                    <Col sm={12} md={6}>
                      <Label className="ml-4 mt-2 mb-0">
                        <Input type="radio" checked={status ? false : true} onClick={() => handleChangeStatus(false)} />
                        Deactive
                      </Label>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            </div>

            <div className="action mb-3 mt-3">
              <Button className="button-cancel" onClick={cancel} disabled={loading}>
                batal
              </Button>
              <Button className="button-action" onClick={handleSubmit} disabled={loading}>
                Simpan
              </Button>
            </div>
          </>
        )}
        {!editForm && !loading && (
          <>
            <div className="content">
              <FormGroup row>
                <Label lg={4} className="label-required">
                  Pilih Role
                </Label>
                <Label lg={8} className="text-left">
                  : {role?.label ?? '-'}
                </Label>
              </FormGroup>
              <FormGroup row>
                <Label lg={4} className="label-required">
                  Nama Depan
                </Label>
                <Label lg={8} className="text-left">
                  : {firstName ?? '-'}
                </Label>
              </FormGroup>
              <FormGroup row>
                <Label lg={4} className="">
                  Nama Belakang
                </Label>
                <Label lg={8} className="text-left">
                  : {lastName ? lastName : '-'}
                </Label>
              </FormGroup>
              <FormGroup row>
                <Label lg={4} className="label-required">
                  Email
                </Label>
                <Label lg={8} className="text-left">
                  : {email ?? '-'}
                </Label>
              </FormGroup>
              <FormGroup row>
                <Label lg={4} className="label-required">
                  Status
                </Label>
                <Label lg={8} className="text-left">
                  {status ? (
                    <span className="badge-custom badge-custom-success">Active</span>
                  ) : (
                    <span className="badge-custom badge-custom-danger">Deactive</span>
                  )}
                </Label>
              </FormGroup>
            </div>

            <div className="action mb-3 mt-3">
              <Button className="button-cancel" onClick={close} disabled={loading}>
                kembali
              </Button>
              {allowEdit && (
                <Button className="button-action" onClick={edit} disabled={loading}>
                  Edit
                </Button>
              )}
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default AccountEdit;
