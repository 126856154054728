import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardBody, CardHeader, Label, FormGroup, Tooltip, Col, Button, Spinner, Row, Input } from 'reactstrap';
import InputField from '../field/InputField';
import IconInfo from '@assets/icons/info.svg';
import SelectField from '@components/field/SelectField';
import { flagTypeOption } from '@utils/Constants';
import CustomModal from '@components/modal/CustomModal';
import deleteIcon from '@assets/icons/trash.svg';
import Helper from '@helpers/Helper';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import API from '@utils/API';

const B2BCustomerBillingAndSettlement = ({ formValues, setFormValues, allowEdit, handleSubmitEdit }) => {
  const api = new API('v2');
  const [localFormValues, setLocalFormValues] = useState(formValues);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [disableForm, setDisableForm] = useState(true);
  const [onEdit, setOnEdit] = useState(false);
  const [hasModalSubmitConfirmation, setHasModalSubmitConfirmation] = useState(false);
  const [hasModalAddDoc, setHasModalAddDoc] = useState(false);
  const [openTooltipBillingAddress, setOpenTooltipBillingAddress] = useState(false);
  const [newDocument, setNewDocument] = useState({});

  const toggleTooltipBillingAddress = () => setOpenTooltipBillingAddress(!openTooltipBillingAddress);

  useEffect(() => {
    setLocalFormValues(formValues);
  }, [formValues]);

  const toggleEdit = useCallback(() => {
    setOnEdit((prev) => {
      if (prev) {
        resetValues();
      }
      setDisableForm(prev);
      return !prev;
    });
  }, []);

  const toggleModalSubmitConfirmation = useCallback(() => setHasModalSubmitConfirmation((prev) => !prev), []);
  const toggleModalAddDoc = useCallback(() => {
    setHasModalAddDoc((prev) => !prev);
    setNewDocument({ value: '', error: 'minimal 1 karakter' });
  }, []);

  const handleLocalSubmit = async () => {
    if (!hasModalSubmitConfirmation) {
      return toggleModalSubmitConfirmation();
    }
    toggleModalSubmitConfirmation();
    setLoadingSubmit(true);
    setDisableForm(true);

    handleSubmitEdit(
      localFormValues,
      (response) => {
        const result = response?.data?.data ?? {};
        if (result?.config_documents) {
          setFormValues((prev) => ({
            ...prev,
            billing_address: result.billing_address,
            config_documents: result.config_documents,
            flag: result.flag,
            virtual_account: result.virtual_account,
          }));
        }
        setLoadingSubmit(false);
        setOnEdit(false);
      },
      () => {
        setLoadingSubmit(false);
        setDisableForm(true);
      }
    );
  };

  const handleChange = (field) => (e) => {
    let errorMsg = '',
      value = field === 'flag' ? e : e.target.value;

    if (field == 'virtual_account') {
      value = value.replace(/\D+/g, '');

      if (value && value.length < 10) {
        errorMsg = 'Minimal 10 angka';
      } else if (value && value.length > 20) {
        errorMsg = 'Maksimal 20 angka';
      }
    }

    setLocalFormValues((prev) => ({ ...prev, [field]: value, [`error_${field}`]: errorMsg }));
  };

  const handleChangeDoc = (index) => {
    const docs = localFormValues?.config_documents;
    if (!Array.isArray(docs) || !docs[index]) {
      return;
    }

    const updatedDocs = docs.map((doc, i) => (i === index ? { ...doc, checked: !doc.checked } : doc));
    setLocalFormValues((prev) => ({ ...prev, config_documents: updatedDocs }));
  };

  const handleDeleteDoc = (index, data) => {
    setLocalFormValues((prev) => {
      const updatedDocs = data?.newData
        ? prev.config_documents.filter((_, i) => i !== index)
        : prev.config_documents.map((doc, i) => (i === index ? { ...doc, deleted: true } : doc));
      return { ...prev, config_documents: updatedDocs };
    });
  };

  const handleAddNewDocument = (e) => {
    const newDoc = e?.target?.value;
    const errorMessage = validationNewDocument(newDoc);
    setNewDocument({ value: newDoc, error: errorMessage });
  };

  const handleAddNewDoc = useCallback(() => {
    if (validationNewDocument(newDocument.value) === '') {
      const newDoc = {
        id: null,
        master_id: 6,
        slug: newDocument.value.toLowerCase().replace(/ /g, '-'),
        checked: true,
        label: newDocument.value,
        deleted: false,
        newData: true,
      };
      setLocalFormValues((prev) => ({ ...prev, config_documents: [...prev.config_documents, newDoc] }));
      toggleModalAddDoc();
    }
  }, [newDocument]);

  const resetValues = () => setLocalFormValues(formValues);

  const validationNewDocument = (value) => {
    if (value.length < 1) {
      return 'minimal 1 karakter';
    }
    if (value.length > 100) {
      return 'maksimal 100 karakter';
    }
    return '';
  };

  const hasChangeFormValue = useCallback(() => {
    const originalValues = Object.fromEntries(Object.entries(formValues).filter(([key]) => !key.startsWith('error_')));
    const localValues = Object.fromEntries(
      Object.entries(localFormValues).filter(([key]) => !key.startsWith('error_'))
    );
    return (
      !Helper.deepEqualObjects(originalValues, localValues) ||
      !Helper.deepEqualArrayObjects(originalValues?.config_documents ?? [], localValues?.config_documents ?? [])
    );
  }, [formValues, localFormValues]);

  const hasErrorField = useCallback(() => {
    return Object.entries(localFormValues).some(([key, value]) => key.startsWith('error_') && value !== '');
  }, [localFormValues]);

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Penagihan / Setelment</CardHeader>
      <CardBody>
        <>
          <FormGroup row>
            <Tooltip target="address_info" isOpen={openTooltipBillingAddress} toggle={toggleTooltipBillingAddress}>
              Alamat sesuai dengan NPWP
            </Tooltip>
            <Label lg={3}>
              {'Alamat Penagihan '}
              <img id="address_info" src={IconInfo} alt="" width={16} className="mr-1" />
            </Label>
            <InputField
              colWidth={9}
              placeholder="Ex: Jl. Radio Dalam"
              inputName="billing_address"
              value={localFormValues?.billing_address}
              invalid={!!localFormValues?.error_billing_address}
              errorMessage={localFormValues?.error_billing_address}
              disabled={disableForm}
              onChange={handleChange('billing_address')}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={3}>Flag</Label>
            <SelectField
              colWidth={9}
              disabled={disableForm}
              value={localFormValues?.flag}
              invalid={!!localFormValues?.error_flag}
              errorMessage={localFormValues?.error_flag}
              closeMenuOnSelect={true}
              options={flagTypeOption}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              onChange={handleChange('flag')}
            />
          </FormGroup>
          <Col sm={12} md={12} className="mb-3">
            <Row>
              <Label sm={6} lg={3} className="label-required">
                Dokumen Penagihan
              </Label>
              <Col sm={12} lg={9}>
                <Row className="document-checkbox-field">
                  {localFormValues?.config_documents?.map(
                    (item, index) =>
                      !item?.deleted && (
                        <Col sm={12} md={12} key={index} className={`checkbox-field checkbox-form`}>
                          <Label className="ml-4 mt-2 mb-0">
                            <Input
                              type="checkbox"
                              checked={item?.checked}
                              onClick={() => item?.slug !== 'receipt' && item?.slug !== 'spk' && handleChangeDoc(index)}
                              disabled={disableForm}
                            />
                            <div className="text-break">{item?.label}</div>
                          </Label>
                          {item?.master_id == 6 && !disableForm && (
                            <img
                              className={`button-delete-doc cursor-pointer`}
                              onClick={() =>
                                item?.slug !== 'receipt' && item?.slug !== 'spk' && handleDeleteDoc(index, item)
                              }
                              src={deleteIcon}
                            ></img>
                          )}
                        </Col>
                      )
                  )}
                  {localFormValues?.config_documents?.filter((obj) => !obj?.deleted)?.length < 25 && !disableForm && (
                    <div className="section-action">
                      <Button
                        disabled={disableForm}
                        onClick={toggleModalAddDoc}
                        className="button-add-document text-center mt-3 ml-3 button-action add"
                      >
                        + Tambah
                      </Button>
                    </div>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <FormGroup row>
            <Label lg={3}>VA</Label>
            <InputField
              colWidth={9}
              placeholder=""
              value={localFormValues?.virtual_account}
              invalid={!!localFormValues?.error_virtual_account}
              errorMessage={localFormValues?.error_virtual_account}
              disabled={disableForm}
              onChange={handleChange('virtual_account')}
            />
          </FormGroup>
        </>

        <Col className="section-action mb-2 mt-4">
          {allowEdit && onEdit ? (
            <>
              <Button
                className="button-action save"
                onClick={handleLocalSubmit}
                disabled={loadingSubmit || !hasChangeFormValue() || hasErrorField()}
              >
                {loadingSubmit ? <Spinner color="light" size="sm" /> : 'Simpan'}
              </Button>
              <Button className="button-action cancel" onClick={toggleEdit} disabled={loadingSubmit}>
                Batal
              </Button>
            </>
          ) : (
            allowEdit && (
              <Button color="purple" className="button-action" onClick={toggleEdit} disabled={loadingSubmit}>
                Edit
              </Button>
            )
          )}
        </Col>
      </CardBody>

      <CustomModal modal={hasModalAddDoc} modalWidth="500" toggle={toggleModalAddDoc}>
        <div className="modal-export-finance">
          <div className="text-center font-18 font-weight-bold mb-5">Tambah Dokumen Penagihan</div>
          <FormGroup row>
            <Label lg={4} className="label-required">
              {'Nama Dokumen'}
            </Label>
            <InputField
              colWidth={8}
              placeholder="Ex: BALAP"
              value={newDocument?.value}
              invalid={!!newDocument?.error}
              errorMessage={newDocument?.error}
              disabled={disableForm}
              onChange={handleAddNewDocument}
            />
          </FormGroup>
          <div className="action mt-0">
            <Button className="button-action" onClick={toggleModalAddDoc} disabled={disableForm}>
              Batal
            </Button>
            <Button className="button-action" onClick={handleAddNewDoc} disabled={!!newDocument?.error}>
              Simpan
            </Button>
          </div>
        </div>
      </CustomModal>

      <ConfirmationModal
        toggle={toggleModalSubmitConfirmation}
        toggleClose={toggleModalSubmitConfirmation}
        onConfirm={handleLocalSubmit}
        modal={hasModalSubmitConfirmation}
        header="Simpan Perubahan Penagihan/Setelmen?"
        subHeader={`Simpan Perubahan Penagihan/Setelmen atas nama ${formValues?.customer_name}?`}
      />
    </Card>
  );
};

export default B2BCustomerBillingAndSettlement;
