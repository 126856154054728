import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, FormGroup, FormFeedback, Input, Label, Row, Button } from 'reactstrap';
import API from '@utils/API';
import moment from 'moment';
import LoadingSpinner from '@components/loading/LoadingSpinner';

const Customer = (props) => {
  const { id, onLoading } = props;
  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});

  async function getDetailCustomer() {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/customer-subscriptions/${id}/customer/`);
      setDetailData(response?.data?.data ?? {});
      setLoading(false);
    } catch (error) {
      setDetailData({});
      setLoading(false);
    }
  }

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    getDetailCustomer();
  }, [id]);

  return (
    <React.Fragment>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <Card className="card-custom">
          <CardBody className="p-0">
            <h5 className="mb-3">ID Pelanggan</h5>
            <Row>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Nama
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <span className="ml-1">{detailData?.customer?.customer_name ?? '-'}</span>
                  </Label>
                </Row>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Nomor Tlp
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <span className="ml-1">{detailData?.customer?.phone ?? '-'}</span>
                  </Label>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Email
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <span className="ml-1">{detailData?.customer?.email ?? '-'}</span>
                  </Label>
                </Row>
              </Col>
            </Row>
            <h5 className="mb-3">Detail Mobil</h5>
            <Row>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Mobil
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <span className="ml-1">{detailData?.car?.car_model ?? '-'}</span>
                  </Label>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Plat
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <span className="ml-1">{detailData?.car?.license_plate ?? '-'}</span>
                  </Label>
                </Row>
              </Col>
            </Row>

            <h5 className="mb-3">Pembayaran</h5>
            <Row>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Status Pembayaran
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    :{' '}
                    <span className="ml-1">
                      {detailData?.payment?.status && detailData?.payment?.status === 'settlement' ? 'Berhasil' : '-'}
                    </span>
                  </Label>
                </Row>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Tipe
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    :{' '}
                    <span className="ml-1">
                      {detailData?.payment?.method && detailData?.payment?.status === 'settlement'
                        ? detailData?.payment?.method
                        : '-'}
                    </span>
                  </Label>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Tanggal Pengesahan
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    :{' '}
                    <span className="ml-1">
                      {moment(detailData?.payment?.settlement_date ?? null).isValid() &&
                      detailData?.payment?.status === 'settlement'
                        ? moment(detailData?.payment?.settlement_date).format('DD MMMM YYYY')
                        : '-'}
                    </span>
                  </Label>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default Customer;
