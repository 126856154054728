import React, { useState, useEffect } from 'react';
import { clone, findIndex } from 'lodash';
import { Modal, ModalBody, FormGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import InputField from '@components/field/InputField';

const ItemsModal = (props) => {
  const { modal, toggle, data, title = 'Data Terpilih', placeholder = '', labelData = 'Data' } = props;

  const [itemsWithPage, setItemsWithPage] = useState([]);
  const [itemsPreview, setItemsPreview] = useState([]);
  const [countItemsFiltered, setCountItemsFiltered] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [totalPages, setTotalPages] = useState(5);
  const [infoShowingData, setInfoShowingData] = useState('');

  const isIncludingSearch = (option) => {
    let result = false;

    if (
      !search ||
      option?.label?.toString().toLowerCase().includes(search.toLowerCase()) ||
      option?.value?.toString().toLowerCase().includes(search.toLowerCase())
    ) {
      result = true;
    }

    return result;
  };

  const processSearching = () => {
    const dummyData = data?.length ? clone(data) : [];
    let newItems = [];

    dummyData?.length &&
      dummyData.forEach((option) => {
        if (isIncludingSearch(option)) {
          newItems.push(option);
        }
      });

    setCountItemsFiltered(newItems?.length ?? 0);
    processPackagePaging(newItems);
  };

  const processPackagePaging = (newItems) => {
    let newItemsPerPage = [];
    let rowPage = 1;
    let rowPerPage = 10;
    newItems?.length &&
      newItems.forEach((item, index) => {
        const isIndex = findIndex(newItemsPerPage, (itemsPerPage) => {
          return itemsPerPage?.page === rowPage;
        });

        if (isIndex !== -1) {
          newItemsPerPage[isIndex].items.push(item);
        } else {
          newItemsPerPage.push({ items: [item], page: rowPage });
        }

        rowPage += index + 1 == rowPerPage ? 1 : 0;
        rowPerPage += index + 1 == rowPerPage ? 10 : 0;
      });

    setItemsWithPage(newItemsPerPage);
  };

  const handleKeyDown = (e) => {
    if (e?.key === 'Enter') {
      processSearching();
    }
  };

  const handleChangeItemsWithPage = (pageActive) => {
    setTotalPages(itemsWithPage?.length ?? 0);
    const isIndex = findIndex(itemsWithPage, (itemsPerPage) => {
      return itemsPerPage?.page === pageActive;
    });
    if (isIndex == -1 && itemsWithPage?.length !== 0) {
      setPage(1);
    } else {
      setItemsPreview(isIndex !== -1 ? itemsWithPage[isIndex] : []);
    }
  };

  const handleChangePage = (e) => {
    if (e?.selected >= 0) {
      setPage(e?.selected + 1);
    }
  };
  const handleLabelingInfoData = () => {
    let labelInfoData = '';
    if (search == '') {
      labelInfoData = 'Ada ' + data?.length + ' ' + labelData;
    } else {
      labelInfoData = 'Ada ' + countItemsFiltered + ' dari ' + data?.length + ' ' + labelData;
    }

    setInfoShowingData(labelInfoData);
  };

  const defaultValue = () => {
    if (modal) {
      processSearching();
    }
  };

  useEffect(() => {
    handleLabelingInfoData();
  }, [itemsPreview]);

  useEffect(() => {
    handleChangeItemsWithPage(page);
  }, [itemsWithPage, page]);

  // useEffect(() => {
  //   processSearching();
  // }, [search]);

  useEffect(() => {
    setSearch('');
    setPage(1);
    defaultValue();
  }, [modal]);

  return (
    <Modal className="modal-dialog-centered modal-items" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body">
        <div className="header">
          {title}
          <div className="button-close" onClick={() => toggle()}></div>
        </div>
        <div className="content">
          <FormGroup row className="mb-2">
            <InputField
              colWidth={12}
              inputType={'text'}
              placeholder={'Cari ' + placeholder ?? ''}
              value={search}
              className={search ? 'search-field filled' : 'search-field'}
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                let value = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : item?.value;
                setSearch(value);
              }}
            />
          </FormGroup>
          <div className="content-items" style={!itemsPreview?.items ? { textAlign: 'center' } : {}}>
            {itemsPreview?.items?.length > 0 &&
              itemsPreview?.items.map((item, index) => {
                const pageNo = itemsPreview?.page ?? 0;
                const counterNo = pageNo * 10 - 10 + (index + 1);
                return (
                  <>
                    <span key={'items' + index} style={{ lineHeight: '27px' }}>
                      {counterNo + '. ' + item?.label ?? ''}
                    </span>
                    <br />
                  </>
                );
              })}
            {!itemsPreview?.items && <span>{'Data tidak ditemukan'}</span>}
          </div>
        </div>
        <div className="footer">
          <div className="section-pagination">
            <div className="section-info-paging">{infoShowingData}</div>
            <div className="section-paging">
              <ReactPaginate
                forcePage={page - 1}
                breakLabel="..."
                nextLabel=""
                nextClassName="custom-pagination next-section"
                onPageChange={handleChangePage}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={totalPages}
                previousLabel=""
                previousClassName="custom-pagination prev-section"
                renderOnZeroPageCount={null}
                className="custom-pagination d-inline-block"
              />
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ItemsModal;
