import React from 'react';
import { useFieldArray, useWatch, Controller } from 'react-hook-form';
import { Button, ListGroup, ListGroupItem, Row, Col, Label, FormGroup, Input } from 'reactstrap';
import ChevronUp from '@assets/icons/chevron-up.svg';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import ChevronDown from '@assets/icons/chevron-down.svg';
import CircleClose from '@assets/icons/circle-close.svg';
import ActionSwitch from '@components/field/ActionSwitch';
import EditableInputField from '@components/field/EditableInputField';
import EditableNumberField from '@components/field/EditableNumberField';
import EditableSelectField from '@components/field/EditableSelectField';

const DetailInformationProducts = (props) => {
  const { watch, control, setValue, getValues, disableForm, componentIndex, packageProductOptions } = props;

  const { fields, append } = useFieldArray({
    control,
    name: `components[${componentIndex}].products`,
  });

  const watchForm = (name) => {
    return watch(name, false);
  };

  const addProduct = () => {
    append({
      price: 0,
      product: {
        value: null,
        label: '',
      },
      apps_name: '',
      is_show: true,
      is_active: true, //need improvement BE data
      product_name: '',
      id_product: null,
      reguler_price: 0,
      is_deleted: false,
      discount_amount: 0,
    });
  };

  const showHideProduct = (index) => {
    const is_show = getValues(`components[${componentIndex}].products[${index}].is_show`);
    setValue(`components[${componentIndex}].products[${index}].is_show`, !is_show);
  };

  const deleteProduct = (index) => {
    // setValue(`components[${componentIndex}].products[${index}].is_deleted`, true);
    // remove(index);
  };

  const ProductTitle = ({ control, index }) => {
    const product = useWatch({
      control,
      name: `components[${componentIndex}].products[${index}]`,
    });

    return <span>{product.product.label}</span>;
  };

  return (
    <React.Fragment>
      {fields.map(({ id }, index) => {
        return (
          <ListGroup key={id}>
            {!watchForm(`components[${componentIndex}].products[${index}].is_deleted`) && (
              <ListGroupItem key={id} className="out-group-item">
                <div className="fly-title" onClick={() => showHideProduct(index)}>
                  Product: <span>{watchForm(`components[${componentIndex}].products[${index}].product.label`)}</span>
                  <img
                    src={
                      watchForm(`components[${componentIndex}].products[${index}].is_show`) ? ChevronUp : ChevronDown
                    }
                    alt=""
                  />
                </div>
                <img src={CircleClose} className="fly-close" onClick={() => deleteProduct(index)} />

                {watchForm(`components[${componentIndex}].products[${index}].is_show`) && (
                  <div className="in-group-item">
                    <Controller
                      control={control}
                      name={`components[${componentIndex}].products[${index}].product`}
                      rules={{
                        validate: () => {
                          const valid = !getValues(`components[${componentIndex}].products[${index}].product.value`)
                            ? false
                            : true;
                          return valid || 'Product harus diisi';
                        },
                      }}
                      render={({ field: { ref, ...rest }, fieldState: { error } }) => {
                        return (
                          <EditableSelectField
                            required
                            {...rest}
                            colWidth={9}
                            labelWidth={3}
                            title="Product"
                            placeholder="Pilih..."
                            disabled={disableForm}
                            closeMenuOnSelect={true}
                            options={packageProductOptions}
                            errorMessage={error?.message ?? ''}
                          />
                        );
                      }}
                    />

                    <Controller
                      control={control}
                      name={`components[${componentIndex}].products[${index}].apps_name`}
                      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                        <EditableInputField
                          {...rest}
                          colWidth={9}
                          labelWidth={3}
                          disabled={disableForm}
                          title="Show in apps as"
                          invalid={error ? true : false}
                          errorMessage={error?.message ?? ''}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`components[${componentIndex}].products[${index}].reguler_price`}
                      rules={{
                        validate: () => {
                          const valid =
                            !getValues(`components[${componentIndex}].products[${index}].reguler_price`) ||
                            getValues(`components[${componentIndex}].products[${index}].reguler_price`) <= 0
                              ? false
                              : true;
                          return valid || 'Reguler Price harus diisi';
                        },
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <EditableNumberField
                          required
                          colWidth={9}
                          value={value}
                          labelWidth={3}
                          title="Reguler Price"
                          disabled={disableForm}
                          errorMessage={error?.message ?? ''}
                          onChange={(e) => {
                            const discount_amount = getValues(
                              `components[${componentIndex}].products[${index}].discount_amount`
                            );
                            if (!e.value) {
                              onChange(0);
                            } else {
                              if (e.floatValue < discount_amount) {
                                setValue(`components[${componentIndex}].products[${index}].discount_amount`, 0);
                              } else {
                                const price = e.floatValue - discount_amount ?? 0;
                                setValue(`components[${componentIndex}].products[${index}].price`, price);
                              }

                              onChange(e.floatValue);
                            }
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`components[${componentIndex}].products[${index}].discount_amount`}
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return (
                          <EditableNumberField
                            colWidth={9}
                            value={value}
                            labelWidth={3}
                            disabled={disableForm}
                            title="Discount Amount"
                            errorMessage={error?.message ?? ''}
                            onChange={(e) => {
                              const reguler_price = getValues(
                                `components[${componentIndex}].products[${index}].reguler_price`
                              );
                              if (!e.value) {
                                onChange(0);
                              } else {
                                if (e.floatValue > reguler_price) {
                                  onChange(0);
                                } else {
                                  onChange(e.floatValue);
                                  const price = reguler_price - e.floatValue ?? 0;
                                  setValue(`components[${componentIndex}].products[${index}].price`, price);
                                }
                              }
                            }}
                          />
                        );
                      }}
                    />

                    <Controller
                      control={control}
                      name={`components[${componentIndex}].products[${index}].price`}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <EditableNumberField
                          colWidth={9}
                          value={value}
                          title="Price"
                          labelWidth={3}
                          disabled={true}
                          errorMessage={error?.message ?? ''}
                          onChange={(e) => {
                            onChange(e.floatValue);
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`components[${componentIndex}].products[${index}].is_active`}
                      render={({ field: { ref, value, name, ...rest } }) => {
                        return (
                          <FormGroup row>
                            <Label lg={3}>Aktif</Label>
                            <Col className="p-2">
                              <ActionSwitch
                                {...rest}
                                inputName={name}
                                isChecked={value}
                                customClass="mb-0"
                                editableForm={disableForm}
                              />
                            </Col>
                          </FormGroup>
                        );
                      }}
                    />
                  </div>
                )}
              </ListGroupItem>
            )}
          </ListGroup>
        );
      })}
      <Row>
        <Col className="text-left">
          {!disableForm && (
            <Button color="primary" size="lg" className="btn-add-component-product" onClick={addProduct}>
              <img src={PlusFill} className="mr-2" />
              Add Product
            </Button>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DetailInformationProducts;
