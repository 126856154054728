import React from 'react';
import { components } from 'react-select';
import PlusFill from '@assets/icons/plus-fill.svg';

const DropdownCreate = (props) => {
  const { textLabel = 'Add New', onCreateClick } = props;
  return (
    <components.MenuList {...props} className="p-0">
      {props.children}
      <div onClick={onCreateClick} className="dropdown-create-item">
        <img src={PlusFill} />
        <div className="ml-3">{textLabel}</div>
      </div>
    </components.MenuList>
  );
};

export default DropdownCreate;
