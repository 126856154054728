import React, { useState, useEffect } from 'react';
import { Col, Label, Row, Button, Input } from 'reactstrap';
import SelectField from '@components/field/SelectField';
import SelectAsyncField from '@components/field/SelectAsyncField';
import API from '@utils/API';
import map from 'lodash/map';

const CustomerEligibilityForm = (props) => {
  const { dataDetail, handleNext, handleBack, disableButton, disableForm } = props;

  const isCampaignB2B =
    dataDetail?.customer_eligibility === 'all_customer_b2b' || dataDetail?.customer_segment?.value === 'customer-b2b';
  const disableEditCustomer = isCampaignB2B && dataDetail?.is_edited;

  const api = new API('v2');
  const [detailData, setDetailData] = useState({});
  const [segmentOptions, setSegmentOptions] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [plateOptions, setPlateOptions] = useState([]);

  const handleDefaultData = () => {
    const defaultData = {
      eligibility: dataDetail?.discount_for?.value === 'per-item' ? 'all' : dataDetail?.customer_eligibility,
      customer_b2b: dataDetail?.customer_b2b,
      error_customer_b2b: dataDetail?.error_customer_b2b,
      error_eligibility: dataDetail?.error_customer_eligibility,
      segments: dataDetail?.customer_segment,
      error_segments: dataDetail?.error_customer_segment,
      campaign: dataDetail?.campaign,
      error_campaign: dataDetail?.error_campaign,
      customer: dataDetail?.customer,
      error_customer: dataDetail?.error_customer,
      plate: dataDetail?.plate,
      error_plate: dataDetail?.error_plate,
      discount_for: dataDetail?.discount_for?.value,
    };
    setDetailData(defaultData);
  };

  const handleParentData = () => {
    dataDetail.customer_eligibility = detailData?.eligibility;
    dataDetail.customer_b2b = detailData?.customer_b2b;
    dataDetail.error_customer_eligibility = detailData?.error_eligibility;
    dataDetail.customer_segment = detailData?.segments;
    dataDetail.error_customer_segment = detailData?.error_segments;
    dataDetail.campaign = detailData?.campaign;
    dataDetail.error_campaign = detailData?.error_campaign;
    dataDetail.customer = detailData?.customer;
    dataDetail.error_customer = detailData?.error_customer;
    dataDetail.plate = detailData?.plate;
    dataDetail.error_plate = detailData?.error_plate;

    return dataDetail;
  };

  const handleChangeEligibility = (value) => {
    const valueEligibility = value ?? null;
    validateEligibility(valueEligibility);
  };

  const handleChangeSegments = (e) => {
    const valueSegments = e?.value ? e : null;
    validateSegments(valueSegments);
  };

  const handleChangeCampaign = (e) => {
    const valueCampaign = e?.value ? e : null;
    validateCampaign(valueCampaign);
  };

  const handleChangeCustomer = (e) => {
    const valueCustomer = e?.value ? e : null;
    validateCustomer(valueCustomer);
  };

  const handleChangeCustomerB2b = (e) => {
    const valueCustomerB2b = e ?? null;
    validateCustomerB2b(valueCustomerB2b);
  };

  const handleChangePlate = (e) => {
    const valuePlate = e?.id ? e : null;
    validatePlate(valuePlate);
  };

  const validateEligibility = (value) => {
    let passValidate = true;

    if (!value) {
      passValidate = false;
      detailData.error_eligibility = 'Pilih salah satu terlebih dahulu';
    } else if (detailData?.eligibility == 'all' || detailData?.eligibility == 'all_customer_b2b') {
      detailData.segments = '';
      detailData.customer_b2b = '';
      detailData.campaign = '';
      detailData.customer = '';
      detailData.plate = '';
      detailData.error_segments = '';
      detailData.error_campaign = '';
      detailData.error_customer = '';
      detailData.error_customer_b2b = '';
      detailData.error_plate = '';
      setCustomerOptions([]);
    } else {
      detailData.error_eligibility = '';
      detailData.error_segments = '';
    }

    detailData.eligibility = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateSegments = (value) => {
    let passValidate = true;
    if (!value?.value && detailData?.eligibility == 'customer_segment') {
      passValidate = false;
      detailData.error_segments = 'Pilih customer segments terlebih dahulu';
    } else if (detailData?.segments?.value == 'no-trx') {
      detailData.campaign = '';
      detailData.customer = '';
      detailData.plate = '';
      detailData.error_campaign = '';
      detailData.error_customer = '';
      detailData.error_customer_b2b = '';
      setCustomerOptions([]);
    } else {
      detailData.error_segments = '';
    }

    detailData.segments = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateCampaign = (value, actionNext) => {
    let passValidate = true;
    if (!value?.value && detailData?.segments?.value == 'membership') {
      passValidate = false;
      detailData.error_campaign = 'Pilih subscription campaign terlebih dahulu';
    } else {
      detailData.error_campaign = '';
    }

    if (!actionNext) {
      detailData.customer = [];
      detailData.plate = [];
    }

    detailData.campaign = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateCustomer = (value, actionNext) => {
    let passValidate = true;
    if (!value?.value && detailData?.segments?.value == 'membership') {
      passValidate = false;
      detailData.error_customer = 'Pilih customer terlebih dahulu';
    } else {
      detailData.error_customer = '';
    }

    if (!actionNext) {
      detailData.plate = [];
    }

    detailData.customer = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateCustomerB2b = (value, actionNext) => {
    let passValidate = true;
    if (+value?.length === 0) {
      passValidate = false;
      detailData.error_customer_b2b = 'Pilih customer B2B terlebih dahulu';
    } else {
      detailData.error_customer_b2b = '';
    }

    if (!actionNext) {
      detailData.customer_b2b = [];
    }

    detailData.customer_b2b = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validatePlate = (value) => {
    let passValidate = true;

    detailData.plate = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const loadSegmentOptions = () => {
    return api
      .get(`v2/intools/campaigns/options/customer-segment/`)
      .then((response) => {
        let valueOptions = [];
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.slug, label: item.name };
        });
        if (disableEditCustomer && +transformedOptions?.length > 0) {
          const filteredOptions = transformedOptions.filter((item) => item?.value === 'customer-b2b');
          valueOptions = filteredOptions;
        } else {
          valueOptions = transformedOptions;
        }
        setSegmentOptions(valueOptions);
      })
      .catch((error) => {
        setSegmentOptions([]);
      });
  };

  const loadCampaignOptions = () => {
    return api
      .get(`v2/intools/campaigns/search-membership/`)
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.id, label: item.campaign_name };
        });
        setCampaignOptions(transformedOptions);
      })
      .catch((error) => {
        setCampaignOptions([]);
      });
  };

  const loadCustomerOptions = () => {
    const params = {
      membership_campaign_id: detailData?.campaign?.value ?? '',
    };

    return api
      .get(`v2/intools/campaigns/customer-membership/`, { params })
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.customer_id, label: item.customer_name };
        });
        setCustomerOptions(transformedOptions);
      })
      .catch((error) => {
        setCustomerOptions([]);
      });
  };

  const loadPlateOptions = (query) => {
    const params = {
      customer_id: detailData?.customer?.value,
      limit: 5,
      query,
    };

    return api
      .get(`v2/intools/user_car/`, { params })
      .then((response) => {
        setPlateOptions(response.data.data.user_cars ?? []);
        return response.data.data.user_cars ?? [];
      })
      .catch(() => {});
  };

  const loadCustomerB2bOptions = async (query) => {
    const params = {
      query: query,
      is_enterprise: 'Y',
    };

    return await api.get(`v2/intools/customers/`, { params }).then((response) => {
      const transformedOptions = map(response?.data?.data?.customers, (item) => {
        return { value: item.id, label: item.name };
      });
      return transformedOptions ?? [];
    });
  };

  const handleActionNext = () => {
    const passEligibility = validateEligibility(detailData?.eligibility);
    const passSegments = validateSegments(detailData?.segments);
    const passCampaign = validateCampaign(detailData?.campaign, true);
    const passCustomer = validateCustomer(detailData?.customer, true);
    const passCustomerB2b =
      detailData?.segments?.value === 'customer-b2b' ? validateCustomerB2b(detailData?.customer_b2b ?? [], true) : true;
    const passPlate = validatePlate(detailData?.plate);

    let params = {
      status:
        passEligibility && passSegments && passCustomer && passCustomerB2b && passCampaign && passPlate ? true : false,
      data: handleParentData(),
    };

    handleNext(params);
  };

  const handleActionBack = () => {
    handleBack();
  };

  useEffect(() => {
    handleDefaultData();
  }, [dataDetail]);

  useEffect(() => {
    loadSegmentOptions();
  }, []);

  useEffect(() => {
    loadCampaignOptions();
  }, []);

  useEffect(() => {
    if (detailData?.campaign?.value) {
      loadCustomerOptions();
    }
  }, [detailData?.campaign]);

  useEffect(() => {
    if (detailData?.customer?.value) {
      loadPlateOptions('');
    } else {
      setPlateOptions([]);
    }
  }, [detailData?.customer]);

  return (
    <>
      <Row>
        <Col sm={12} md={12} className="mb-3">
          <Row>
            <Label sm={4} lg={2} className="text-left label-required">
              Customer Eligibility
            </Label>
            <Col sm={12} lg={10}>
              <Row>
                <Col sm={12} md={12}>
                  <Label className={`ml-4 my-3 ${disableEditCustomer && 'cursor-not-allowed'}`}>
                    <Input
                      type="radio"
                      disabled={disableEditCustomer}
                      checked={detailData?.eligibility == 'all' ? true : false}
                      onClick={() => handleChangeEligibility('all')}
                    />
                    All Customers B2C
                  </Label>
                </Col>
                <Col sm={12} md={12}>
                  <Row>
                    <Col sm={12} md={4}>
                      <Label className="ml-4 my-3">
                        <Input
                          type="radio"
                          checked={detailData?.eligibility == 'all_customer_b2b' ? true : false}
                          onClick={() => handleChangeEligibility('all_customer_b2b')}
                        />
                        All Customers B2B
                      </Label>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} md={12}>
                  <Row>
                    <Col sm={12} md={4}>
                      <Label className="ml-4 my-3">
                        <Input
                          type="radio"
                          checked={
                            detailData?.discount_for === 'per-item'
                              ? false
                              : detailData?.eligibility == 'customer_segment'
                              ? true
                              : false
                          }
                          disabled={detailData?.discount_for === 'per-item'}
                          onClick={() => handleChangeEligibility('customer_segment')}
                        />
                        Selected Customer Segments
                      </Label>
                    </Col>
                    {detailData?.eligibility == 'customer_segment' && (
                      <Col sm={12} md={6}>
                        <Row>
                          <SelectField
                            colWidth={12}
                            title="Segments"
                            placeholder={'Pilih customer segments'}
                            disabled={disableForm}
                            closeMenuOnSelect={true}
                            options={segmentOptions}
                            errorMessage={detailData?.error_segments ?? ''}
                            value={detailData?.segments}
                            selectName={'customer-segment'}
                            onChange={(e) => handleChangeSegments(e)}
                          />
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>
                {detailData?.discount_for === 'per-item' && (
                  <Col>
                    <Row>
                      <div className="text-info">Discount Per Baris hanya dapat digunakan semua customer</div>
                    </Row>
                  </Col>
                )}
                {detailData?.eligibility == 'customer_segment' && detailData?.segments?.value == 'membership' && (
                  <Col sm={12} md={12}>
                    <Row>
                      <Col sm={12} md={4}>
                        <Label className="ml-4 my-3">Selected Subscription Campaign</Label>
                      </Col>
                      <Col sm={12} md={6}>
                        <Row>
                          <SelectField
                            colWidth={12}
                            title="Segments"
                            placeholder={'Pilih subscription campaign'}
                            disabled={disableForm || !campaignOptions}
                            closeMenuOnSelect={true}
                            options={campaignOptions}
                            errorMessage={detailData?.error_campaign ?? ''}
                            value={detailData?.campaign}
                            selectName={'campaign'}
                            onChange={(e) => handleChangeCampaign(e)}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {detailData?.eligibility == 'customer_segment' && detailData?.segments?.value == 'membership' && (
                  <Col sm={12} md={12}>
                    <Row>
                      <Col sm={12} md={4}>
                        <Label className="ml-4 my-3">Selected Customer</Label>
                      </Col>
                      <Col sm={12} md={6}>
                        <Row>
                          <SelectField
                            colWidth={12}
                            title="Segments"
                            placeholder={'Pilih customer'}
                            disabled={disableForm}
                            closeMenuOnSelect={true}
                            options={customerOptions}
                            errorMessage={detailData?.error_customer ?? ''}
                            value={detailData?.customer}
                            selectName={'customer'}
                            onChange={(e) => handleChangeCustomer(e)}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {detailData?.eligibility == 'customer_segment' && detailData?.segments?.value == 'membership' && (
                  <Col sm={12} md={12}>
                    <Row>
                      <Col sm={12} md={4}>
                        <Label className="ml-4 my-3">Selected License Plate</Label>
                      </Col>
                      <Col sm={12} md={6}>
                        <Row>
                          <SelectAsyncField
                            colWidth={12}
                            isClearable
                            isDisabled={disableForm}
                            value={detailData?.plate || ''}
                            defaultOptions={plateOptions}
                            getOptionLabel={(e) => e?.license_plate || '-'}
                            getOptionValue={(e) => e?.id}
                            loadOptions={loadPlateOptions}
                            onChange={(e) => handleChangePlate(e)}
                            placeholder="Cari Mobil..."
                            invalid={!!detailData?.error_plate}
                            errorMessage={detailData?.error_plate ?? ''}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {detailData?.eligibility == 'customer_segment' && detailData?.segments?.value == 'customer-b2b' && (
                  <Col sm={12} md={12}>
                    <Row>
                      <Col sm={12} md={4}>
                        <Label className="ml-4 my-3 label-required">Selected Customer B2B</Label>
                      </Col>
                      <Col sm={12} md={6}>
                        <Row>
                          <SelectAsyncField
                            colWidth={12}
                            isMulti={true}
                            isDisabled={disableForm}
                            value={detailData?.customer_b2b || ''}
                            defaultOptions={[]}
                            getOptionLabel={(e) => e?.label || '-'}
                            getOptionValue={(e) => e?.value}
                            loadOptions={loadCustomerB2bOptions}
                            onChange={(e) => handleChangeCustomerB2b(e)}
                            placeholder="Pilih Customer B2B"
                            invalid={!!detailData?.error_customer_b2b}
                            errorMessage={detailData?.error_customer_b2b ?? ''}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              <Row>
                {detailData?.error_eligibility && (
                  <div className="text-danger mt-2 ml-3">{detailData?.error_eligibility}</div>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={12} className="section-action mt-3">
          <Button disabled={disableButton} className="button-action save" onClick={() => handleActionNext()}>
            {'Selanjutnya'}
          </Button>
          <Button disabled={disableButton} className="button-action cancel" onClick={() => handleActionBack()}>
            {'Kembali'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CustomerEligibilityForm;
