import React from 'react';
import NumberFormat from 'react-number-format';
import { FormGroup, Label, Col } from 'reactstrap';

const EditableNumberField = (props) => {
  const {
    value,
    title,
    onChange,
    disabled,
    colWidth,
    numberName,
    labelWidth,
    required,
    className = 'form-control',
    thousandSeparator = '.',
    decimalSeparator = false,
    fixedDecimalScale = true,
    allowNegative = false,
    errorMessage,
  } = props;

  return (
    <FormGroup row>
      <Label lg={labelWidth} className={required ? 'label-required' : ''}>
        {title}
      </Label>
      <Col lg={colWidth}>
        <NumberFormat
          name={numberName}
          className={className}
          value={value}
          thousandSeparator={thousandSeparator}
          decimalSeparator={decimalSeparator}
          fixedDecimalScale={fixedDecimalScale}
          allowNegative={allowNegative}
          disabled={disabled}
          onValueChange={onChange}
        />
        {errorMessage && <div className="pt-1 text-danger">{errorMessage}</div>}
      </Col>
    </FormGroup>
  );
};

export default EditableNumberField;
