import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import qs from 'query-string';
import userPermission from '@utils/userPermission';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import AgreementList from '@components/workshops/agreement/AgreementList';

const Agreement = (props) => {
  const { id, onResultNotification, onLoading } = props;
  const { generatePermission } = userPermission();
  const history = useHistory();
  const qp = qs.parse(history?.location?.search);

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'otoklixservicefeeall'));
  };

  const allowShow = allowPermission('otoklixservicefeeshow');
  const allowList = allowPermission('otoklixservicefeelist');
  const allowEditDetail =
    allowPermission('otoklixservicefeedetailall') || allowPermission('otoklixservicefeedetailedit');
  const allowShowDetail =
    allowPermission('otoklixservicefeedetailall') || allowPermission('otoklixservicefeedetailshow');

  const [loading, setLoading] = useState(false);

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  useEffect(() => {
    setLoading(onLoading);
  }, [onLoading]);

  useEffect(() => {
    if (!allowShow || (!allowShowDetail && !allowList)) {
      history.push('/404');
    }
  }, []);

  return (
    <React.Fragment>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <Card className="card-custom">
          <CardBody className="p-0">
            {allowList && (
              <Row className="mt-4">
                <Col sm={12} md={12}>
                  <AgreementList
                    id={id}
                    handleResultNotification={handleResultNotification}
                    allowEditDetail={allowEditDetail}
                    allowShowDetail={allowShowDetail}
                    qp={qp}
                  ></AgreementList>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default Agreement;
