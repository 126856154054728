import React, { useState, useEffect } from 'react';
import map from 'lodash/map';
import moment from 'moment';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import CheckIcon from '@assets/icons/check.svg';
import AlertIcon from '@assets/icons/red-alert.svg';
import PenIcon from '@assets/icons/pen.svg';
import InputField from '@components/field/InputField';

import { Button, Row, Col, Card, CardBody, CardHeader, FormGroup } from 'reactstrap';
import RescheduleDraftModal from '../modal/RescheduleDraftModal';

function BookingDraftSummary(props) {
  const { dataSummary, onSubmitData, onSummaryPrice } = props;

  const api = new API('v2');
  const [dataSummaryEdit, setDataSummaryEdit] = useState(dataSummary);
  const [customerID, setCustomerID] = useState();
  const [summaryBooking, setSummaryBooking] = useState();
  const [markUpProduct, setMarkUpProduct] = useState(0);
  const [markUpService, setMarkUpService] = useState(0);
  const [discProduct, setDiscProduct] = useState(0);
  const [discService, setDiscService] = useState(0);
  const [editFd, setEditFd] = useState(false);
  const [editSpkNo, setEditSpkNo] = useState(false);
  const [editInternalNote, setEditInternalNote] = useState(false);
  const [editKm, setEditKm] = useState(false);
  const [editBookingDate, setEditBookingDate] = useState(false);

  async function fetchCustomerConfig() {
    try {
      const response = await api.get(`v2/intools/customers/details/?customer_id=${customerID}`);
      setMarkUpProduct(response?.data?.data?.product_markup ?? 0);
      setMarkUpService(response?.data?.data?.service_markup ?? 0);
      setDiscProduct(response?.data?.data?.product_discount ?? 0);
      setDiscService(response?.data?.data?.service_discount ?? 0);
    } catch (error) {
      setMarkUpProduct(0);
      setMarkUpService(0);
      setDiscProduct(0);
      setDiscService(0);
    }
  }

  const calculateDataBooking = () => {
    const discount = !isNaN(+dataSummaryEdit?.discount_promo) ? dataSummaryEdit?.discount_promo : 0;
    const otopoints = !isNaN(+dataSummaryEdit?.total_point_spent) ? dataSummaryEdit?.total_point_spent : 0;

    let dataBooking = {
      groups: [
        {
          name: 'Paket',
          group_items: [],
        },
        {
          name: 'Produk',
          group_items: [],
        },
        {
          name: 'Jasa',
          group_items: [],
        },
      ],
      sub_total_amount: 0,
      discount: discount,
      otopoints: otopoints,
      total_amount: 0,
    };

    let subTotalAmount = 0;

    dataSummaryEdit?.data_form?.forEach((item) => {
      if (!item?.deleted) {
        if (item?.service_type == 'package') {
          const dataPackage = parsingDataPackage(item);
          const groupItemDetails = dataPackage?.group_items_details;
          const subTotal = dataPackage?.sub_total;
          subTotalAmount += subTotal;
          dataBooking.groups[0].group_items.push(
            parsingDataGroupItems('top', item?.package?.label ?? '', groupItemDetails, subTotal)
          );
        } else if (item?.service_type == 'product') {
          const dataProduct = parsingDataProduct(item);
          const groupItemDetails = dataProduct?.group_items_details;
          const subTotal = dataProduct?.sub_total;
          subTotalAmount += subTotal;
          if (dataBooking.groups[1].group_items?.length == 0) {
            dataBooking.groups[1].group_items.push(
              parsingDataGroupItems('bottom', 'Subtotal Produk', [groupItemDetails], subTotal)
            );
          } else {
            dataBooking.groups[1].group_items[0].sub_total += subTotal;
            dataBooking.groups[1].group_items[0].group_items_details.push(groupItemDetails);
          }
        } else if (item?.service_type == 'service') {
          const dataService = parsingDataService(item);
          const groupItemDetails = dataService?.group_items_details;
          const subTotal = dataService?.sub_total;
          subTotalAmount += subTotal;
          if (dataBooking.groups[2].group_items?.length == 0) {
            dataBooking.groups[2].group_items.push(
              parsingDataGroupItems('bottom', 'Subtotal Jasa', [groupItemDetails], subTotal)
            );
          } else {
            dataBooking.groups[2].group_items[0].sub_total += subTotal;
            dataBooking.groups[2].group_items[0].group_items_details.push(groupItemDetails);
          }
        } else if (item?.service_type == 'custom') {
          const dataCustom = parsingDataCustom(item);
          const groupItemDetails = dataCustom?.group_items_details;
          const subTotal = dataCustom?.sub_total;
          subTotalAmount += subTotal;

          if (item?.type == 'product') {
            if (dataBooking.groups[1].group_items?.length == 0) {
              dataBooking.groups[1].group_items.push(
                parsingDataGroupItems('bottom', 'Subtotal Produk', [groupItemDetails], subTotal)
              );
            } else {
              dataBooking.groups[1].group_items[0].sub_total += subTotal;
              dataBooking.groups[1].group_items[0].group_items_details.push(groupItemDetails);
            }
          } else if (item?.type == 'service') {
            if (dataBooking.groups[2].group_items?.length == 0) {
              dataBooking.groups[2].group_items.push(
                parsingDataGroupItems('bottom', 'Subtotal Jasa', [groupItemDetails], subTotal)
              );
            } else {
              dataBooking.groups[2].group_items[0].sub_total += subTotal;
              dataBooking.groups[2].group_items[0].group_items_details.push(groupItemDetails);
            }
          }
        }
      }
    });

    dataBooking.sub_total_amount = subTotalAmount;
    dataBooking.total_amount = subTotalAmount - discount - otopoints < 0 ? 0 : subTotalAmount - discount - otopoints;
    setSummaryBooking(dataBooking);
  };

  const parsingDataPackage = (item) => {
    let subTotalPackage = 0;
    let groupItemsDetail = [];
    item?.components.forEach((itemComponent) => {
      const price = itemComponent?.product?.price ?? 0;
      const qty = itemComponent?.product?.qty ?? 0;
      const name = itemComponent?.product?.label ?? '';
      const productType = itemComponent?.product?.product_type ?? '';
      const markup = Helper.readMarkUp(markUpProduct, markUpService, productType);
      const discount = Helper.readDiscount(discProduct, discService, productType);
      const discountNominal = Helper.calculateDiscountNominal(markup, discount, price, qty);
      const subTotal = Helper.calculateSubTotal(markup, discount, price, qty);
      const subTotalNoDiscount = Helper.calculateSubTotalWithoutDiscount(markup, price, qty);

      groupItemsDetail.push(
        parsingDataGroupItemsDetail(name, qty, markup, discount, discountNominal, price, subTotal, subTotalNoDiscount)
      );
      subTotalPackage += subTotal;
    });

    return {
      group_items_details: groupItemsDetail,
      sub_total: subTotalPackage,
    };
  };

  const parsingDataProduct = (item) => {
    const priceQty = getPriceAndQty(item);
    const price = priceQty?.price;
    const qty = priceQty?.qty;
    const name = item?.item?.label ?? '';
    const discountNominal = Helper.calculateDiscountNominal(markUpProduct, discProduct, price, qty);
    const subTotal = Helper.calculateSubTotal(markUpProduct, discProduct, price, qty);
    const subTotalNoDiscount = Helper.calculateSubTotalWithoutDiscount(markUpProduct, price, qty);
    const groupItemsDetail = parsingDataGroupItemsDetail(
      name,
      qty,
      markUpProduct,
      discProduct,
      discountNominal,
      price,
      subTotal,
      subTotalNoDiscount
    );

    return {
      group_items_details: groupItemsDetail,
      sub_total: subTotal,
    };
  };

  const parsingDataService = (item) => {
    const priceQty = getPriceAndQty(item);
    const price = priceQty?.price;
    const qty = priceQty?.qty;
    const name = item?.item?.label ?? '';
    const discountNominal = Helper.calculateDiscountNominal(markUpService, discService, price, qty);
    const subTotal = Helper.calculateSubTotal(markUpService, discService, price, qty);
    const subTotalNoDiscount = Helper.calculateSubTotalWithoutDiscount(markUpService, price, qty);
    const groupItemsDetail = parsingDataGroupItemsDetail(
      name,
      qty,
      markUpService,
      discService,
      discountNominal,
      price,
      subTotal,
      subTotalNoDiscount
    );

    return {
      group_items_details: groupItemsDetail,
      sub_total: subTotal,
    };
  };

  const parsingDataCustom = (item) => {
    const priceQty = getPriceAndQty(item);
    const price = priceQty?.price;
    const qty = priceQty?.qty;
    const name = item?.name ?? '';

    const discountNominal = Helper.calculateDiscountNominal(
      item?.markup?.value ?? 0,
      item?.discount?.value ?? 0,
      price,
      qty
    );
    const subTotal = Helper.calculateSubTotal(item?.markup?.value ?? 0, item?.discount?.value ?? 0, price, qty);
    const subTotalNoDiscount = Helper.calculateSubTotalWithoutDiscount(item?.markup?.value ?? 0, price, qty);
    const groupItemsDetail = parsingDataGroupItemsDetail(
      name,
      qty,
      item?.markup?.value ?? 0,
      item?.discount?.value ?? 0,
      discountNominal,
      price,
      subTotal,
      subTotalNoDiscount
    );

    return {
      group_items_details: groupItemsDetail,
      sub_total: subTotal,
    };
  };

  const getPriceAndQty = (item) => {
    let price = 0;
    const qty = item?.qty?.formatMoney <= 0 ? 0 : item?.qty?.formatMoney > 999 ? 999 : item?.qty?.formatMoney;
    if (item?.service_type == 'product' || item?.service_type == 'service') {
      price = item?.is_override
        ? item?.sell_price?.formatMoney > 999999999
          ? 999999999
          : item?.sell_price?.formatMoney ?? 0
        : item?.sell_price?.formatMoney ?? 0;
    } else if (item?.service_type == 'custom') {
      const workshopPrice =
        item?.workshop_price?.formatMoney > 999999999 ? 999999999 : item?.workshop_price?.formatMoney ?? 0;
      const customerPrice =
        item?.customer_price?.formatMoney > 999999999 ? 999999999 : item?.customer_price?.formatMoney ?? 0;

      price = item?.markup?.value > 0 ? workshopPrice : customerPrice;
    }

    return {
      qty: qty,
      price: price,
    };
  };

  const parsingDataGroupItems = (position, name, groupItemsDetail, subTotal) => {
    return {
      position: position,
      name: name,
      group_items_details: groupItemsDetail,
      sub_total: subTotal,
    };
  };

  const parsingDataGroupItemsDetail = (
    name,
    qty,
    markup,
    discount,
    discountNominal,
    price,
    subTotal,
    subTotalNoDiscount
  ) => {
    return {
      name: name,
      qty: qty,
      markup: markup,
      disc: discount,
      disc_nominal: discountNominal,
      price: price,
      sub_total: subTotal,
      sub_total_no_discount: subTotalNoDiscount,
    };
  };

  const handleEditFreshdesk = (e) => {
    const tempData = { ...dataSummaryEdit, freshdesk_ticket_link: e.target.value };
    setDataSummaryEdit(tempData);
  };

  const handleEditSpkNo = (e) => {
    const tempData = { ...dataSummaryEdit, spk_number: e.target.value };
    setDataSummaryEdit(tempData);
  };

  const handleEditInternalNote = (e) => {
    const tempData = { ...dataSummaryEdit, note: e.target.value };
    setDataSummaryEdit(tempData);
  };

  const handleEditKm = (e) => {
    const tempData = { ...dataSummaryEdit, mileage: e.target.value };
    setDataSummaryEdit(tempData);
  };

  useEffect(() => {
    // calculateDataBooking();
    const tempData = {
      ...dataSummary,
      schedule: dataSummary?.booking_datetime,
      mileage: dataSummary?.user_car?.mileage,
    };
    setDataSummaryEdit(tempData);
    setCustomerID(dataSummary?.customer?.id);
  }, [dataSummary]);

  useEffect(() => {
    onSubmitData(dataSummaryEdit);
    calculateDataBooking();
  }, [dataSummaryEdit]);

  useEffect(() => {
    calculateDataBooking();
  }, [markUpProduct, markUpService, discProduct, discService]);

  useEffect(() => {
    if (customerID) {
      fetchCustomerConfig();
    }
  }, [customerID]);

  useEffect(() => {
    if (+summaryBooking?.total_amount > 0) onSummaryPrice(summaryBooking?.total_amount);
  }, [summaryBooking]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="text-white mt-0">Rincian</CardHeader>
        <CardBody>
          <FormGroup row className="mb-4">
            <Col lg={2} sm={2} className="pr-4">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right">
                  <dt>{'Waktu'}</dt>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={10}>
              <Row>
                <Col lg={6} sm={6} className="col-summary">
                  <div className="d-flex justify-content-between">
                    <span>
                      {dataSummaryEdit?.booking_date
                        ? `${moment(dataSummaryEdit?.booking_date).format('DD MMM YYYY')}, ${
                            dataSummaryEdit?.booking_time ? dataSummaryEdit?.booking_time : ''
                          }`
                        : '-'}
                    </span>
                    {/* <img
                      className="button-pen"
                      src={PenIcon}
                      onClick={() => {
                        setEditBookingDate(!editBookingDate);
                      }}
                    /> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row className="mb-4">
            <Col lg={2} sm={2} className="pr-4">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right">
                  <dt>{'User'}</dt>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={10}>
              <Row>
                <Col lg={6} sm={6} className="col-summary">
                  <span className="detail-group">{'No. Telp'}</span>
                  <span>{!dataSummaryEdit?.customer?.phone_no ? '-' : dataSummaryEdit?.customer?.phone_no}</span>
                </Col>
                <Col lg={6} sm={6} className="col-summary">
                  <span className="detail-group">{dataSummaryEdit?.booking_type === 'b2b' ? 'Nama PIC' : 'Email'}</span>
                  <span>{!dataSummaryEdit?.customer?.email ? '-' : dataSummaryEdit?.customer?.email}</span>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={6} className="col-summary">
                  <span className="detail-group">
                    {dataSummaryEdit?.booking_type === 'b2b' ? 'Nama Perusahaan' : 'Nama'}
                  </span>
                  <span>{!dataSummaryEdit?.customer?.name ? '-' : dataSummaryEdit?.customer?.name}</span>
                </Col>
                <Col lg={6} sm={6} className="col-summary">
                  <span className="detail-group">{'Flag'}</span>
                  <div>
                    {dataSummaryEdit?.customer?.flag_status == 'suspect abuser' && (
                      <span className="flag">{'Suspect Abuser'}</span>
                    )}
                    {dataSummaryEdit?.customer?.flag_status !== undefined && (
                      <img
                        src={dataSummaryEdit?.user?.flag_status == 'suspect abuser' ? AlertIcon : CheckIcon}
                        className="icon-flag"
                      />
                    )}
                    {!dataSummaryEdit?.customer?.flag_status && <span>{'-'}</span>}
                  </div>
                </Col>
                <Col lg={6} sm={6} className="col-summary">
                  <span className="detail-group">{'Status'}</span>
                  <span>{!dataSummaryEdit?.customer?.status ? '-' : dataSummaryEdit?.customer?.status}</span>
                </Col>
                <Col lg={6} sm={6} className="col-summary">
                  <span className="detail-group">{'Internal Notes'}</span>
                  <span>
                    {!dataSummaryEdit?.customer?.internal_notes ? '-' : dataSummaryEdit?.customer?.internal_notes}
                  </span>
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row className="mb-4">
            <Col lg={2} sm={2} className="pr-4">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right">
                  <dt>{'Mobil'}</dt>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={10}>
              <Row>
                <Col lg={6} sm={6} className="col-summary">
                  <span className="detail-group">{'Mobil'}</span>
                  <span>
                    {dataSummaryEdit?.user_car?.car_details?.car_model?.brand?.name &&
                    dataSummaryEdit?.user_car?.car_details?.car_model?.model_name
                      ? dataSummaryEdit?.user_car?.car_details?.car_model?.brand?.name +
                        ' ' +
                        dataSummaryEdit?.user_car?.car_details?.car_model?.model_name
                      : '-'}
                  </span>
                </Col>
                <Col lg={6} sm={6} className="col-summary">
                  <span className="detail-group">{'Transmisi'}</span>
                  <span>
                    {dataSummaryEdit?.user_car?.transmission
                      ? Helper.getCommonLabel(dataSummaryEdit?.user_car?.transmission, '-')
                      : '-'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={6} className="col-summary">
                  <span className="detail-group">{'Plat Nomor'}</span>
                  <span>
                    {!dataSummaryEdit?.user_car?.license_plate ? '-' : dataSummaryEdit?.user_car?.license_plate}
                  </span>
                </Col>
                <Col lg={6} sm={6} className="col-summary">
                  <span className="detail-group">{'Tahun'}</span>
                  <span>{!dataSummaryEdit?.user_car?.year ? '-' : dataSummaryEdit?.user_car?.year}</span>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={6} className="col-summary">
                  <div className="col-summary">
                    <span className="detail-group">{'Kilometer'}</span>
                  </div>
                  {!editKm ? (
                    <div className="justify-content-between d-flex">
                      <span>{!dataSummaryEdit?.mileage ? '-' : dataSummaryEdit?.mileage}</span>
                      <img
                        className="button-pen"
                        src={PenIcon}
                        onClick={() => {
                          setEditKm(!editKm);
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="p-0">
                        <InputField
                          inputName="km"
                          placeholder={'Masukkan KM'}
                          value={dataSummaryEdit?.mileage}
                          invalid={false}
                          errorMessage={false}
                          onChange={(e) => handleEditKm(e)}
                        />
                      </div>
                      <div className="justify-content-between d-flex mt-1 section-action">
                        <Button className="button-action no-width add" onClick={() => setEditKm(!editKm)}>
                          Simpan
                        </Button>
                        <Button className="button-action no-width add" onClick={() => setEditKm(!editKm)}>
                          Batal
                        </Button>
                      </div>
                    </>
                  )}
                </Col>
                <Col lg={6} sm={6} className="col-summary">
                  <span className="detail-group">{'Bahan Bakar'}</span>
                  <span>
                    {dataSummaryEdit?.user_car?.car_details?.fuel
                      ? Helper.getCommonLabel(dataSummaryEdit?.user_car?.car_details?.fuel, '-')
                      : '-'}
                  </span>
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row className="mb-4">
            <Col lg={2} sm={2} className="pr-4">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right">
                  <dt>{'FD Ticket Link'}</dt>
                </Col>
              </Row>
            </Col>
            {!editFd ? (
              <Col lg={10} sm={10}>
                <Row>
                  <Col lg={10} sm={10} className="col-summary">
                    {dataSummaryEdit?.freshdesk_ticket_link && <span>{dataSummaryEdit?.freshdesk_ticket_link}</span>}
                    {!dataSummaryEdit?.freshdesk_ticket_link && <span>-</span>}
                  </Col>
                  <Col lg={2} sm={2} className="col-summary">
                    <img
                      className="button-pen"
                      src={PenIcon}
                      onClick={() => {
                        setEditFd(!editFd);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col lg={10} sm={10} className="d-flex">
                <>
                  <div className="col-sm-9 text-sm-left p-0">
                    <InputField
                      inputName="fresdesk_ticket_link"
                      placeholder={'Masukkan freshdesk ticket link'}
                      value={dataSummaryEdit?.freshdesk_ticket_link}
                      invalid={false}
                      errorMessage={false}
                      onChange={(e) => handleEditFreshdesk(e)}
                    />
                  </div>
                  <div className="col-sm-3 text-sm-right section-action">
                    <Button className="button-action no-width add" onClick={() => setEditFd(!editFd)}>
                      Simpan
                    </Button>
                    <Button
                      className="button-action no-width add"
                      onClick={() => {
                        const tempData = { ...dataSummaryEdit, freshdesk_ticket_link: '' };
                        setDataSummaryEdit(tempData);
                        setEditFd(!editFd);
                      }}
                    >
                      Batal
                    </Button>
                  </div>
                </>
              </Col>
            )}
          </FormGroup>

          <FormGroup row className="mb-4">
            <Col lg={2} sm={2} className="pr-4">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right">
                  <dt>{'No SPK'}</dt>
                </Col>
              </Row>
            </Col>
            {!editSpkNo ? (
              <Col lg={10} sm={10}>
                <Row>
                  <Col lg={10} sm={10} className="col-summary">
                    {dataSummaryEdit?.spk_number && <span>{dataSummaryEdit?.spk_number}</span>}
                    {!dataSummaryEdit?.spk_number && <span>-</span>}
                  </Col>
                  <Col lg={2} sm={2} className="col-summary">
                    <img
                      className="button-pen"
                      src={PenIcon}
                      onClick={() => {
                        setEditSpkNo(!editSpkNo);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col lg={10} sm={10} className="d-flex">
                <>
                  <div className="col-sm-9 text-sm-left p-0">
                    <InputField
                      inputName="fresdesk_ticket_link"
                      placeholder={'Masukkan No SPK'}
                      value={dataSummaryEdit?.spk_number}
                      invalid={false}
                      errorMessage={false}
                      onChange={(e) => handleEditSpkNo(e)}
                    />
                  </div>
                  <div className="col-sm-3 text-sm-right section-action">
                    <Button className="button-action no-width add" onClick={() => setEditSpkNo(!editSpkNo)}>
                      Simpan
                    </Button>
                    <Button
                      className="button-action no-width add"
                      onClick={() => {
                        const tempData = { ...dataSummaryEdit, spk_number: '' };
                        setDataSummaryEdit(tempData);
                        setEditSpkNo(!editSpkNo);
                      }}
                    >
                      Batal
                    </Button>
                  </div>
                </>
              </Col>
            )}
          </FormGroup>

          <FormGroup row className="mb-4">
            <Col lg={2} sm={2} className="pr-4">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right">
                  <dt>{'Catatan Internal'}</dt>
                </Col>
              </Row>
            </Col>
            {!editInternalNote ? (
              <Col lg={10} sm={10}>
                <Row>
                  <Col lg={10} sm={10} className="col-summary">
                    {dataSummaryEdit?.note && <span>{dataSummaryEdit?.note}</span>}
                    {!dataSummaryEdit?.note && <span>-</span>}
                  </Col>
                  <Col lg={2} sm={2} className="col-summary">
                    <img
                      className="button-pen"
                      src={PenIcon}
                      onClick={() => {
                        setEditInternalNote(!editInternalNote);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col lg={10} sm={10} className="d-flex">
                <>
                  <div className="col-sm-9 text-sm-left p-0">
                    <InputField
                      inputName="fresdesk_ticket_link"
                      placeholder={'Masukkan Catatan Internal'}
                      value={dataSummaryEdit?.note}
                      invalid={false}
                      errorMessage={false}
                      onChange={(e) => handleEditInternalNote(e)}
                    />
                  </div>
                  <div className="col-sm-3 text-sm-right section-action">
                    <Button
                      className="button-action no-width add"
                      onClick={() => setEditInternalNote(!editInternalNote)}
                    >
                      Simpan
                    </Button>
                    <Button
                      className="button-action no-width add"
                      onClick={() => {
                        const tempData = { ...dataSummaryEdit, note: '' };
                        setDataSummaryEdit(tempData);
                        setEditInternalNote(!editInternalNote);
                      }}
                    >
                      Batal
                    </Button>
                  </div>
                </>
              </Col>
            )}
          </FormGroup>

          <FormGroup row className="mb-4">
            <Col lg={2} sm={2} className="pr-4">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right">
                  <dt>{'Bengkel'}</dt>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={10}>
              <Row>
                <Col lg={6} sm={6} className="col-summary">
                  {dataSummaryEdit?.workshop?.name && (
                    <span>
                      {dataSummaryEdit?.workshop?.name}
                      <span className="text-bold">
                        {dataSummaryEdit?.workshop?.tier?.name ? ` [${dataSummaryEdit?.workshop?.tier?.name}]` : ''}
                      </span>
                    </span>
                  )}
                  {!dataSummaryEdit?.workshop?.name && <span>-</span>}
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row className="mb-0">
            <Col lg={2} sm={2} className="pr-4">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right">
                  <dt>{'Booking'}</dt>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={10}>
              {/* <Row> */}
              {map(summaryBooking?.groups, (group, indexGroup) => {
                if (summaryBooking?.groups[indexGroup]?.group_items?.length > 0) {
                  return (
                    <FormGroup row className="mb-0">
                      <Col lg={12} sm={12}>
                        <Row>
                          <Col lg={5} sm={12} className="col-summary">
                            <span className="detail-group">{!group?.name ? '-' : group?.name}</span>
                          </Col>
                          <Col lg={2} sm={12} className="col-summary">
                            <span className="detail-group price">{'Mark Up'}</span>
                          </Col>
                          <Col lg={2} sm={12} className="col-summary">
                            <span className="detail-group price">{'Disc'}</span>
                          </Col>
                          <Col lg={3} sm={12} className="col-summary">
                            {''}
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12} sm={12}>
                        {map(group.group_items, (item) => {
                          return (
                            <div
                              className={
                                indexGroup == summaryBooking.groups?.length - 1
                                  ? 'mb-0'
                                  : summaryBooking.groups[indexGroup + 1]?.group_items?.length > 0
                                  ? 'mb-3'
                                  : 'mb-0'
                              }
                            >
                              {item?.position == 'top' && (
                                <Row>
                                  <Col lg={9} sm={12} className="col-summary">
                                    <span className="detail-group">{!item?.name ? '-' : item?.name}</span>
                                  </Col>
                                  <Col lg={3} sm={12} className="col-summary">
                                    <span className="price detail-group">
                                      {'IDR ' + Helper.formatMoney(item?.sub_total ?? 0)}
                                    </span>
                                  </Col>
                                </Row>
                              )}
                              {map(item.group_items_details, (detail) => {
                                return (
                                  <>
                                    <Row>
                                      <Col lg={5} sm={5} className="col-summary">
                                        <>
                                          <span className="detail-group-item-name">
                                            {!detail?.name ? '-' : detail?.name}
                                          </span>
                                          <span className="detail-group-item-qty">{`${
                                            detail?.qty
                                          } x IDR  ${Helper.formatMoney(detail?.price ?? 0)}`}</span>
                                        </>
                                      </Col>
                                      <Col lg={2} sm={12} className="col-summary">
                                        <span className="detail-group-item-name grand-total price">
                                          {detail?.markup !== undefined
                                            ? detail?.markup >= 0
                                              ? detail?.markup + '%'
                                              : '0%'
                                            : ''}
                                        </span>
                                      </Col>
                                      <Col lg={2} sm={12} className="col-summary">
                                        <span className="detail-group-item-name discount-otopoints price">
                                          {detail?.disc !== undefined
                                            ? detail?.disc >= 0
                                              ? detail?.disc + '%'
                                              : '0%'
                                            : ''}
                                        </span>
                                      </Col>
                                      <Col lg={3} sm={12} className="col-summary">
                                        <span className="price detail-group-item-name">
                                          {'IDR ' + Helper.formatMoney(detail?.sub_total_no_discount ?? 0)}
                                        </span>
                                        <span className="price detail-group-item-name discount-otopoints">
                                          {'- IDR ' + Helper.formatMoney(detail?.disc_nominal ?? 0)}
                                        </span>
                                      </Col>
                                    </Row>
                                  </>
                                );
                              })}
                              {item?.position == 'bottom' && (
                                <Row>
                                  <Col lg={9} sm={12} className="col-summary">
                                    <span className="detail-group">{item?.name}</span>
                                  </Col>
                                  <Col lg={3} sm={12} className="col-summary">
                                    <span className="price detail-group">
                                      {'IDR ' + Helper.formatMoney(item?.sub_total ?? 0)}
                                    </span>
                                  </Col>
                                </Row>
                              )}
                            </div>
                          );
                        })}
                      </Col>
                    </FormGroup>
                  );
                }
              })}
              {/* </Row> */}
            </Col>
          </FormGroup>
          <FormGroup row className="mb-0">
            <Col lg={12} sm={12} className="col-summary">
              <div className="divider"></div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col lg={2} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right col-summary">
                  <span className="group">{'Harga'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span className="price detail-group">
                    {'IDR ' + Helper.formatMoney(summaryBooking?.sub_total_amount ?? 0)}
                  </span>
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col lg={2} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right col-summary">
                  <span className="group">{'Kode Promo'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span className="price discount-otopoints">
                    {'- IDR ' + Helper.formatMoney(summaryBooking?.discount ?? 0)}
                  </span>
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row className="mb-0">
            <Col lg={2} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right col-summary">
                  <span className="group">{'Otopoints'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span className="price discount-otopoints">
                    {'- IDR ' + Helper.formatMoney(summaryBooking?.otopoints ?? 0)}
                  </span>
                </Col>
              </Row>
            </Col>
          </FormGroup>
          <FormGroup row className="mb-0">
            <Col lg={12} sm={12} className="col-summary">
              <div className="divider"></div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col lg={2} sm={12} className="pr-3">
              <Row>
                <Col lg={12} sm={12} className="text-sm-right col-summary">
                  <span className="group">{'Total Harga'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={10} sm={12}>
              <Row>
                <Col lg={12} sm={12} className="col-summary">
                  <span className="price grand-total">
                    {'IDR ' + Helper.formatMoney(summaryBooking?.total_amount ?? 0)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col lg={12} sm={12} className="col-summary text-center">
              <span className="label note-footer">{'Ditagihkan ke Customer'}</span>
            </Col>
          </FormGroup>
        </CardBody>
      </Card>
      <RescheduleDraftModal
        modal={editBookingDate}
        // bookingTime={bookingTime}
        // bookingDate={bookingDate}
        toggle={() => setEditBookingDate(!editBookingDate)}
        // scheduleButtonLoading={scheduleButtonLoading}
        onChangeRescheduleTime={(e) => {
          onChangeRescheduleTime(e.target.value);
        }}
        onChangeBookingDate={(e) => {
          onChangeBookingDate(e.target.value);
        }}
        // onChangeReason={(e) => setReason(e.target.value)}
        onReschedule={(e) => console.log('on re', e.target.value)}
        // hasInvalidDate={hasInvalidDate}
        // messageInvalidDate={messageInvalidDate}
      />
    </React.Fragment>
  );
}

export default BookingDraftSummary;
