import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Label } from 'reactstrap';
import API from '@utils/API';
import map from 'lodash/map';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';

const PackageAdd = (props) => {
  const { idWorkshop, modal, toggle, onSubmit } = props;
  const history = useHistory();

  const api = new API('v2');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [customerTypeyOptions, setCustomerTypeOptions] = useState([]);
  const [category, setCategory] = useState();
  const [customerType, setCustomerType] = useState();
  const [packageName, setPackageName] = useState('');
  const [errorCategory, setErrorCategory] = useState('');
  const [errorCustomerType, setErrorCustomerType] = useState('');
  const [errorPackage, setErrorPackage] = useState('');
  const [loadingFetchCategory, setLoadingFetchCategory] = useState(false);
  const [loading, setLoading] = useState(false);

  async function fetchMasterDataCategory() {
    setLoadingFetchCategory(true);
    try {
      const response = await api.get(`v2/intools/categories/list-categories-all`);
      mappingMasterCategory(response?.data?.data ?? []);
      setLoadingFetchCategory(false);
    } catch (error) {
      mappingMasterCategory([]);
      setLoadingFetchCategory(false);
    }
  }

  const mappingMasterCategory = (data) => {
    let masterDataCategory = [];
    if (data.length !== 0) {
      map(data, (item) => {
        masterDataCategory.push({
          value: item?.id,
          label: item?.name,
        });
      });
    }

    const masterCustomerType = [
      {
        value: 'b2c;b2b',
        label: 'B2C/B2B',
      },
      {
        value: 'b2c',
        label: 'B2C',
      },
      {
        value: 'b2b',
        label: 'B2B',
      },
    ];

    setCategoryOptions(masterDataCategory);
    setCustomerTypeOptions(masterCustomerType);
  };

  const handleChangePackage = (e) => {
    const valuePackage = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : packageName;
    validatePackage(valuePackage);
  };

  const handleChangeCategory = (e) => {
    const valueCategory = e?.value ? e : null;
    validateCategory(valueCategory);
  };

  const handleChangeCustomerType = (e) => {
    const valueCustomerType = e?.value ? e : null;
    validateCustomerType(valueCustomerType);
  };

  const validatePackage = (value) => {
    // const regexNumbCharSpace = new RegExp(regexOnlyAllowNumberCharacterSpace);
    let passValidate = true;

    // if (!regexNumbCharSpace.test(value)) {
    //   passValidate = false;
    //   setErrorPackage('Format Nama Package harus angka atau huruf');
    if (value.length == 0) {
      passValidate = false;
      setErrorPackage('Nama Package harus diisi');
    } else if (value.length < 5 || value.length > 100) {
      passValidate = false;
      setErrorPackage('Format Nama Package minimal 5, maksimal 100 karakter');
    } else {
      setErrorPackage('');
    }

    setPackageName(value);
    return passValidate;
  };

  const validateCategory = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorCategory('Category harus di isi');
    } else {
      setErrorCategory('');
    }

    setCategory(value);
    return passValidate;
  };

  const validateCustomerType = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorCustomerType('Tipe Pelanggan harus di isi');
    } else {
      setErrorCustomerType('');
    }

    setCustomerType(value);
    return passValidate;
  };

  const handleSubmit = () => {
    const passValidatePackage = validatePackage(packageName);
    const passValidateCategory = validateCategory(category);
    const passValidateCustomerType = validateCustomerType(customerType);

    if (passValidatePackage && passValidateCategory && passValidateCustomerType) {
      submitForm();
    }
  };

  async function submitForm() {
    toggle();
    setLoading(true);

    try {
      const payload = {
        workshop: idWorkshop ?? null,
        package_name: packageName ?? '',
        category: category?.label ?? null,
        allowed_customer_type: customerType?.value ?? null,
      };
      const res = await api.post(`v2/intools/packages/create-package/`, payload);
      const data = res?.data?.data;
      const payloads = {
        activetab: 'package',
        productid: data?.id,
      };
      history.push({ search: qs.stringify(payloads) });
      onSubmit(true);
      setLoading(false);
    } catch (error) {
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  const resetData = () => {
    setCategoryOptions([]);
    setCustomerTypeOptions([]);
    setCategory();
    setCustomerType();
    setPackageName('');
    setErrorPackage('');
    setErrorCategory('');
    setErrorCustomerType('');
  };

  const cancel = () => {
    toggle();
  };

  useEffect(() => {
    resetData();
    if (modal) {
      fetchMasterDataCategory();
    }
  }, [modal]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Buat Paket</div>
        <div className="content">
          <FormGroup row>
            <Label lg={4} className="label-required">
              Nama Paket
            </Label>
            <InputField
              colWidth={8}
              inputType={'text'}
              disabled={loading}
              placeholder="Nama Paket"
              value={packageName ?? ''}
              invalid={errorPackage ? true : false}
              errorMessage={errorPackage ?? ''}
              onChange={handleChangePackage}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4} className="label-required">
              Kategori
            </Label>
            <SelectField
              colWidth={8}
              name="category"
              placeholder="Kategori"
              closeMenuOnSelect={true}
              value={category}
              options={categoryOptions}
              disabled={loading}
              isLoading={loadingFetchCategory}
              errorMessage={errorCategory}
              onChange={handleChangeCategory}
            />
          </FormGroup>
          <FormGroup row className="mb-0">
            <Label lg={4} className="label-required">
              Tipe Pelanggan
            </Label>
            <SelectField
              colWidth={8}
              name="customer_type"
              placeholder="Tipe Pelanggan"
              closeMenuOnSelect={true}
              value={customerType}
              options={customerTypeyOptions}
              errorMessage={errorCustomerType}
              onChange={handleChangeCustomerType}
            />
          </FormGroup>
        </div>

        <div className="action mb-3 mt-3">
          <Button className="button-cancel" onClick={cancel} disabled={loading}>
            Batal
          </Button>
          <Button className="button-action" onClick={handleSubmit} disabled={loading}>
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PackageAdd;
