import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Label, Row, Spinner, Input, Button } from 'reactstrap';
import EditableInputField from '../field/EditableInputField';
import SelectField from '../field/SelectField';
import SelectAsyncField from '../field/SelectAsyncField';
import RadioInput from '../field/RadioInput';
import InputField from '../field/InputField';
import Avatar from '@assets/images/avatar.png';
import { statusOptions, userTypeOption, customerTypeOptions, flagTypeOption } from '@utils/Constants';
import UploadImage from '../uploads/UploadImage';
import moment from 'moment';
import deleteIcon from '@assets/icons/trash.svg';
import CustomModal from '@components/modal/CustomModal';

const CustomerDetailForms = (props) => {
  const {
    id,
    isB2B,
    formValues,
    disableForm,
    customerType,
    handleChangeCustomerType,
    handleChangeCustomerName,
    handleChangeNickname,
    handleChangePICName,
    handleChangePhoneNumber,
    handleChangeEmail,
    handleChangeStatus,
    handleChangeUserType,
    handleChangeFlag,
    handleChangeBlacklistWorkshop,
    handleChangeInternalNotes,
    handleChangeImage,
    handleChangeDocument,
    handleErrorImage,
    handleChangePIC,
    origin,
    wsList,
    loadWorkshop,
    loadingLoadWorkshop,
    loadPIC,
    loadingLoadPIC,
    listPIC,
  } = props;

  const [uploadLoading, setUploadLoading] = useState(false);
  const [errorImage, setErrorImage] = useState('');
  const [showModalAddDoc, setShowModalAddDoc] = useState(false);
  const [newDocument, setNewDocument] = useState('');
  const [errorNewDocument, setErrorNewDocument] = useState('');

  const handleUploadImage = (image) => {
    handleChangeImage(image);
  };

  const handleLoadingImage = (loading) => {
    setUploadLoading(loading);
    if (errorImage) setErrorImage('');
  };

  const onImageError = (msg) => {
    if (msg) handleErrorImage(msg);
    setErrorImage(msg);
  };

  const disabledUpload = disableForm || uploadLoading;

  const handleChangeDoc = (index) => {
    let newStatus = !formValues.config_documents[index].checked;
    let docs = formValues?.config_documents;
    let tempDoc = { ...docs[index], checked: newStatus };
    docs[index] = tempDoc;
    handleChangeDocument(docs);
  };

  const handleDeleteDoc = (index, data) => {
    if (!disableForm) {
      let docs = formValues?.config_documents;
      if (data?.newData) {
        docs?.splice(index, 1);
      } else {
        let tempDoc = { ...docs[index], deleted: true };
        docs[index] = tempDoc;
      }
      handleChangeDocument(docs);
    }
  };

  const handleAddNewDocument = (e) => {
    let newDoc = e?.target?.value;
    setNewDocument(newDoc);

    validationNewDocument(newDoc);
  };

  const handleCloseModalAddDoc = () => {
    setNewDocument('');
    setErrorNewDocument('');
    setShowModalAddDoc(false);
  };

  const validationNewDocument = (value) => {
    let isValid = true;

    if (value?.length < 1) {
      isValid = false;
      setErrorNewDocument('minimal 1 karakter');
    } else if (value?.length > 100) {
      isValid = false;
      setErrorNewDocument('maksimal 100 karakter');
    } else {
      setErrorNewDocument('');
    }

    return isValid;
  };

  const generateSlug = (value) => {
    let slug = value?.toLowerCase()?.replace(/ /g, '-');
    return slug;
  };

  const handleAddNewDoc = () => {
    let isValid = validationNewDocument(newDocument);

    if (isValid) {
      let newDoc = {
        id: null,
        master_id: 6,
        slug: generateSlug(newDocument),
        checked: true,
        label: newDocument,
        deleted: false,
        newData: true,
      };
      let docs = formValues?.config_documents;
      docs.push(newDoc);
      handleChangeDocument(docs);
      setNewDocument('');
      setErrorNewDocument('');
      setShowModalAddDoc(false);
    }
  };

  useEffect(() => {
    onImageError('');
  }, [disabledUpload]);

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Detail</CardHeader>
      <CustomModal modal={showModalAddDoc} modalWidth="500" toggle={handleCloseModalAddDoc}>
        <div className="modal-export-finance">
          <div className="text-center font-18 font-weight-bold mb-5">Tambah Dokumen Penagihan</div>
          <FormGroup row>
            <Label lg={4} className="label-required">
              {'Nama Dokumen'}
            </Label>
            <InputField
              colWidth={8}
              placeholder="Ex: BALAP"
              value={newDocument}
              invalid={!!errorNewDocument}
              errorMessage={errorNewDocument}
              disabled={disableForm}
              onChange={handleAddNewDocument}
            />
          </FormGroup>
          <div className="action mt-0">
            <Button className="button-action" onClick={handleCloseModalAddDoc} disabled={disableForm}>
              Batal
            </Button>
            <Button className="button-action" onClick={() => handleAddNewDoc()} disabled={disableForm}>
              Simpan
            </Button>
          </div>
        </div>
      </CustomModal>
      <CardBody>
        <Row>
          <Col md={4}>
            <div className="profile-picture-wrapper mx-auto">
              <img src={formValues?.photo?.preview || formValues?.photo || Avatar} alt="" />
            </div>
            <UploadImage
              error={errorImage}
              nameSpace="profile_picture_image"
              identifier={id ?? moment().format('HHmmss')}
              onLoading={handleLoadingImage}
              onError={onImageError}
              onUpload={handleUploadImage}
              simplified
              maxSize={3000}
              maxSizeString="3MB"
              disabled={disabledUpload}
              uploadText={
                <>
                  <div className="my-2 d-flex justify-content-center align-items-center">
                    <i className="mdi mdi-border-color mr-1 align-self-center vertical-menu-icon"></i>
                    <span className={disabledUpload ? '' : 'cursor-pointer link'}>
                      {uploadLoading ? (
                        <div className="text-center">
                          <Spinner size="sm" />
                        </div>
                      ) : (
                        `${origin === 'create' ? 'Upload' : 'Edit'} Foto Profile`
                      )}
                    </span>
                  </div>
                  {errorImage ? <span className="text-danger">{errorImage}</span> : null}
                </>
              }
            ></UploadImage>
          </Col>
          <Col md={8}>
            <RadioInput
              required
              labelWidth={3}
              colWidth={9}
              label="Customer Type"
              options={customerTypeOptions}
              checked={customerType}
              onClick={handleChangeCustomerType}
              disabled={origin !== 'create'}
            />

            <FormGroup row>
              <Label lg={3} className="label-required">
                {isB2B ? 'Nama Perusahaan' : 'Nama'}
              </Label>
              <InputField
                colWidth={9}
                placeholder="Ex: Takaaki"
                value={formValues?.customer_name}
                invalid={!!formValues?.error_customer_name}
                errorMessage={formValues?.error_customer_name}
                disabled={disableForm}
                onChange={handleChangeCustomerName}
              />
            </FormGroup>

            {isB2B ? (
              <FormGroup row>
                <Label lg={3}>Nickname</Label>
                <InputField
                  colWidth={9}
                  placeholder="Ex: Nakagawa"
                  value={formValues?.nickname}
                  invalid={!!formValues?.error_nickname}
                  errorMessage={formValues?.error_nickname}
                  disabled={disableForm}
                  onChange={handleChangeNickname}
                />
              </FormGroup>
            ) : null}

            <FormGroup row>
              <Label lg={3} className={!isB2B ? 'label-required' : ''}>
                {isB2B ? 'Nomor Telepon Perusahaan' : 'Nomor Telepon'}
              </Label>
              <InputField
                colWidth={9}
                placeholder="Ex: 08123456789"
                inputName="phone_number"
                value={formValues?.phone_number}
                invalid={!!formValues?.error_phone_number}
                errorMessage={formValues?.error_phone_number}
                disabled={disableForm}
                onChange={handleChangePhoneNumber}
              />
            </FormGroup>

            <FormGroup row>
              <Label lg={3} className={isB2B ? 'label-required' : ''}>
                {isB2B ? 'Email Perusahaan' : 'Email'}
              </Label>
              <InputField
                colWidth={9}
                placeholder="Ex: people@otoklix.com"
                inputName="email"
                value={formValues?.email}
                invalid={!!formValues?.error_email}
                errorMessage={formValues?.error_email}
                disabled={disableForm}
                onChange={handleChangeEmail}
              />
            </FormGroup>

            {isB2B ? (
              <FormGroup row>
                <Label lg={3} className="label-required">
                  Nama PIC Perusahaan
                </Label>
                <InputField
                  colWidth={9}
                  placeholder="Ex: Takaaki"
                  value={formValues?.pic_name}
                  invalid={!!formValues?.error_pic_name}
                  errorMessage={formValues?.error_pic_name}
                  disabled={disableForm}
                  onChange={handleChangePICName}
                />
              </FormGroup>
            ) : null}

            {isB2B ? (
              <FormGroup row>
                <Label lg={3} className="label-required">
                  Nomor Telepon PIC
                </Label>
                <InputField
                  colWidth={9}
                  placeholder="Ex: 08123456789"
                  inputName="pic_phone"
                  value={formValues?.pic_phone}
                  invalid={!!formValues?.error_pic_phone}
                  errorMessage={formValues?.error_pic_phone}
                  disabled={disableForm}
                  onChange={handleChangePhoneNumber}
                />
              </FormGroup>
            ) : null}

            {isB2B ? (
              <FormGroup row>
                <Label lg={3} className="label-required">
                  B2B PIC
                </Label>
                <SelectAsyncField
                  colWidth={9}
                  isDisabled={disableForm}
                  value={formValues?.b2b_pic ?? {}}
                  defaultOptions={listPIC}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.id}
                  loadOptions={loadPIC}
                  onChange={handleChangePIC}
                  invalid={formValues.error_b2b_pic ? true : false}
                  errorMessage={formValues.error_b2b_pic}
                  // placeholder="Pilih PIC..."
                  loading={loadingLoadPIC}
                />
              </FormGroup>
            ) : null}

            {isB2B ? (
              <RadioInput
                required
                labelWidth={3}
                colWidth={9}
                label="Status"
                options={statusOptions}
                checked={formValues?.b2b_customer_status}
                onClick={handleChangeStatus}
                disabled={disableForm}
              />
            ) : null}

            {isB2B && (
              <Col sm={12} md={12} className="mb-3">
                <Row>
                  <Label sm={6} lg={3} className="label-required">
                    Dokumen Penagihan
                  </Label>
                  <Col sm={12} lg={9}>
                    <Row className="document-checkbox-field">
                      {formValues?.config_documents?.map((item, index) => (
                        <>
                          {!item?.deleted && (
                            <Col sm={12} md={12} key={index} className={`checkbox-field checkbox-form`}>
                              <Label className="ml-4 mt-2 mb-0">
                                <Input
                                  type="checkbox"
                                  checked={item?.checked}
                                  onClick={() => {
                                    item?.slug !== 'receipt' && item?.slug !== 'spk' && handleChangeDoc(index);
                                  }}
                                  disabled={disableForm}
                                />
                                <div className="text-break">{item?.label}</div>
                              </Label>
                              {item?.master_id == 6 && !disableForm && (
                                <img
                                  className={`button-delete-doc cursor-pointer`}
                                  onClick={() => {
                                    item?.slug !== 'receipt' && item?.slug !== 'spk' && handleDeleteDoc(index, item);
                                  }}
                                  src={deleteIcon}
                                ></img>
                              )}
                            </Col>
                          )}
                        </>
                      ))}
                      {formValues?.config_documents?.filter((obj) => obj?.deleted == false)?.length < 25 &&
                        !disableForm && (
                          <div className="section-action">
                            <Button
                              disabled={disableForm}
                              onClick={() => setShowModalAddDoc(true)}
                              className="button-add-document text-center mt-3 ml-3 button-action add"
                            >
                              + Tambah
                            </Button>
                          </div>
                        )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            )}

            <FormGroup row>
              <Label lg={3}>Tipe</Label>
              <SelectField
                colWidth={9}
                disabled={disableForm}
                value={formValues.user_type}
                invalid={formValues.error_user_type ? true : false}
                errorMessage={formValues.error_user_type}
                closeMenuOnSelect={true}
                options={userTypeOption}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                onChange={handleChangeUserType}
              />
            </FormGroup>

            <FormGroup row>
              <Label lg={3}>Flag</Label>
              <SelectField
                colWidth={9}
                disabled={disableForm}
                value={formValues.flag}
                invalid={formValues.error_flag ? true : false}
                errorMessage={formValues.error_flag}
                closeMenuOnSelect={true}
                options={flagTypeOption}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                onChange={handleChangeFlag}
              />
            </FormGroup>

            {isB2B ? (
              <FormGroup row>
                <Label lg={3}>Workshop Blacklist</Label>
                <SelectAsyncField
                  colWidth={9}
                  isDisabled={disableForm}
                  value={formValues?.workshop_blacklist || ''}
                  defaultOptions={[]}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.id}
                  loadOptions={loadWorkshop}
                  onChange={handleChangeBlacklistWorkshop}
                  invalid={formValues.error_workshop_blacklist ? true : false}
                  errorMessage={formValues.error_workshop_blacklist}
                  placeholder="Pilih Workshop..."
                  loading={loadingLoadWorkshop}
                  isMulti
                  closeMenuOnSelect={false}
                  isClearable
                  isSelectAll
                />
              </FormGroup>
            ) : null}

            {isB2B ? (
              <EditableInputField
                colWidth={9}
                labelWidth={3}
                inputType="textarea"
                title="Internal Notes"
                placeholder="Ex: notes"
                inputName="internal_notes"
                value={formValues?.internal_notes}
                invalid={!!formValues?.error_internal_notes}
                errorMessage={formValues?.error_internal_notes}
                disabled={disableForm}
                onChange={handleChangeInternalNotes}
              />
            ) : null}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CustomerDetailForms;
