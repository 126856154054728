import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const AddReasonEditModal = (props) => {
  const { modal, toggle, headerTitle, onConfirm, children } = props;

  return (
    <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggle}>
      <ModalHeader>{headerTitle}</ModalHeader>
      <ModalBody>
        <p>{children}</p>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" outline size="lg" onClick={() => toggle()}>
          Kembali
        </Button>
        <Button color="primary" size="lg" onClick={onConfirm}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddReasonEditModal;
