import React from 'react';
import ReactPaginate from 'react-paginate';

const MainPagination = (props) => {
  const { totalPage, handleChangePage } = props;

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel=">"
      onPageChange={handleChangePage}
      pageRangeDisplayed={1}
      marginPagesDisplayed={1}
      pageCount={totalPage}
      previousLabel="<"
      renderOnZeroPageCount={null}
      className="main-pagination d-inline-block"
    />
  );
};

export default MainPagination;
