import React, { useState, useEffect, useMemo } from 'react';
import { Col, Modal, ModalHeader, ModalBody, Tooltip } from 'reactstrap';
import moment from 'moment';
import userPermission from '@utils/userPermission';
import API from '@utils/API';
import Loading from '@components/loading/Loading';
import DataTable from 'react-data-table-component';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import CloseIcon from '@assets/icons/close.svg';

const LogInventoryModal = (props) => {
  const { isOpen, toggle, id, activeTab, warehouseId } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(15);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  const [filtered, setFiltered] = useState();

  const api = new API('v2');
  const { generatePermission } = userPermission();

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'inventoryall'));
  };

  const allowList = allowPermission('inventorylist');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const renderStatusPo = (status) => {
    switch (status) {
      case 'Waiting':
        return <span className="badge-custom badge-custom-primary">{status}</span>;
      case 'Failed':
        return <span className="badge-custom badge-custom-danger">{status}</span>;
      case 'Approved':
        return <span className="badge-custom badge-custom-success">{status}</span>;
      case 'Rejected':
        return <span className="badge-custom badge-custom-yellow">{status}</span>;
      default:
        return <span className="badge-custom badge-custom-grey">{status}</span>;
    }
  };

  const renderStatusJurnal = (status) => {
    switch (status) {
      case 'Open':
        return <span className="badge-custom badge-custom-primary">{status}</span>;
      case 'Closed':
        return <span className="badge-custom badge-custom-danger">{status}</span>;
      case 'Partially Sent':
        return <span className="badge-custom badge-custom-success">{status}</span>;
      default:
        return <span className="badge-custom badge-custom-grey">{status}</span>;
    }
  };

  const renderProductName = (id, products) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const doble = ['asd', 'qwe', 'asdzxc'];
    if (+products?.length > 0) {
      return (
        <div>
          <span id={`toolti-${id}`} className="text-truncate">
            {products.join(', ')}
          </span>
          <Tooltip placement="top" isOpen={tooltipOpen} target={`toolti-${id}`} toggle={toggle}>
            {products.join(', ')}
          </Tooltip>
        </div>
      );
    } else {
      return null;
    }
  };

  const columns = useMemo(
    () => [
      {
        name: 'No PO',
        selector: (row) => row?.po_number ?? '-',
        minWidth: '110px',
        sortField: 'no_po',
      },
      {
        name: 'Tanggal Transaksi',
        selector: (row) => (row?.transaction_date ? moment(row?.transaction_date).format('DD MMM YYYY') : '-'),
        minWidth: '110px',
        sortField: 'product_id',
      },
      {
        name: 'Nama Produk',
        minWidth: '200px',
        selector: (row) => (row?.products?.length ? renderProductName(row?.id, row?.products) : '-'),
        sortField: 'product_name',
        wrap: true,
      },
      {
        name: 'Memo',
        minWidth: '200px',
        selector: (row) => row?.memo ?? '-',
        sortField: 'memo',
        wrap: true,
      },
      {
        name: 'Status PO',
        selector: (row) => (row?.status_po ? renderStatusPo(row?.status_po) : '-'),
        center: true,
        sortField: 'status_po',
      },
      {
        name: 'Status Jurnal',
        selector: (row) => (row?.status_jurnal ? renderStatusJurnal(row?.status_jurnal) : '-'),
        center: true,
        sortField: 'status_jurnal',
      },
      {
        name: 'Updated At',
        minWidth: '110px',
        selector: (row) => (row?.updated_at ? moment(row?.updated_at).format('DD MMM YYYY') : '-'),
        sortField: 'updated_at',
        wrap: true,
      },
      {
        name: 'Notes',
        minWidth: '400px',
        selector: (row) => row?.notes ?? '-',
        sortField: 'notes',
        wrap: true,
      },
    ],
    [resetSorting]
  );

  const statusOptions = [
    { value: '', label: 'Semua' },
    { value: 'Waiting', label: 'Waiting' },
    { value: 'Failed', label: 'Failed' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Rejected', label: 'Rejected' },
  ];

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  const fetchInventory = async () => {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/workshops/${id}/inventories/logs/?warehouse_id=${warehouseId}`, {
        params,
      });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
    }
  };

  const getParamData = () => {
    let params = {
      start_date: filtered?.date?.start_date ? moment(filtered?.date?.start_date).format('YYYY-MM-DD') : '',
      end_date: filtered?.date?.end_date ? moment(filtered?.date?.end_date).format('YYYY-MM-DD') : '',
      search: filtered?.search ?? '',
      status_po: filtered?.status?.value ?? '',
      page: page,
      limit: pageLimit,
    };
    return params;
  };

  const handleChangeSorting = (column, sortDirection) => {
    setSortingData({
      sort: column?.sortField ?? '',
      order: sortDirection ?? '',
    });
  };

  const handleApplyFilters = (params) => {
    setFiltered(params);
  };

  const handleResetFilters = () => {
    setFiltered({});
    const defaultDataFilter = [
      {
        id: 'filter_text_search',
        type: 'text',
        name: 'search',
        name_field: 'search',
        value: '',
        placeholder: 'No PO/Nama Produk/Memo',
      },
      {
        id: 'filter_select_status',
        type: 'select',
        name: 'status',
        name_field: 'status',
        options: statusOptions,
        value: '',
        placeholder: 'Status',
      },
      {
        id: 'filter_date',
        type: 'date',
        name: 'date',
        name_field: 'date',
        date_range: true,
        value: '',
        placeholder: 'Transaction Date',
      },
    ];
    setDataFilter(defaultDataFilter);
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodFetchInventory();
  };

  const handleMethodFetchInventory = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchInventory();
    }
  };

  const resetInventory = () => {
    setResetSorting(true);
    setSortingData({});
  };

  useEffect(async () => {
    if (isOpen && page && pageLimit) {
      fetchInventory();
    }
  }, [isOpen, page, pageLimit]);

  useEffect(() => {
    if (activeTab?.id) {
      resetInventory();
    }
  }, [activeTab]);

  useEffect(() => {
    handleChangeSetSorting();
  }, [sortingData]);

  useEffect(async () => {
    if (isOpen && filtered) {
      fetchInventory();
    } else {
      resetInventory();
    }
  }, [isOpen, filtered]);

  useEffect(() => {
    const defaultDataFilter = [
      {
        id: 'filter_text_search',
        type: 'text',
        name: 'search',
        name_field: 'search',
        value: filtered?.search,
        placeholder: 'No PO/Nama Produk/Memo',
      },
      {
        id: 'filter_select_status',
        type: 'select',
        name: 'status',
        name_field: 'status',
        options: statusOptions,
        value: filtered?.status,
        placeholder: 'Status',
      },
      {
        id: 'filter_date',
        type: 'date',
        name: 'date',
        name_field: 'date',
        date_range: true,
        value: '',
        placeholder: 'Transaction Date',
      },
    ];
    setDataFilter(defaultDataFilter);
  }, []);

  return (
    <Modal className="modal-log-inventory" isOpen={isOpen} toggle={toggle} backdrop="static" fullscreen>
      <ModalHeader
        className="d-flex align-items-center cursor-pointer"
        close={<img src={CloseIcon} alt="close-icon" onClick={toggle} role="button" />}
      >
        Log PO
      </ModalHeader>
      <ModalBody>
        {dataFilter?.length !== 0 && (
          <Col sm={12} md={12} className="p-0 mt-4">
            <ActionFilterCustom
              filters={dataFilter}
              loading={loading}
              onApplyFilters={handleApplyFilters}
              onResetFilters={handleResetFilters}
            ></ActionFilterCustom>
          </Col>
        )}

        <Col sm={12} md={12} className="p-0 mt-4">
          <DataTable
            clearSelectedRows={loading}
            persistTableHead
            highlightOnHover
            sortServer
            noHeader={true}
            progressPending={loading}
            data={data}
            className="table dt-responsive table-custom"
            noDataComponent={noDataComponent}
            progressComponent={<Loading />}
            customStyles={customStylesHeaderTable}
            onSort={handleChangeSorting}
            columns={allowList ? columns : []}
          />
        </Col>
        <Col sm={12} md={12} className="p-0 mt-2">
          {data?.length >= 1 && !loading && allowList && (
            <CustomPagination
              page={page}
              pageLimit={pageLimit}
              totalRows={data?.length ?? 0}
              totalAllRows={totalRows}
              totalPages={totalPages}
              handleChangePage={handleChangePage}
              handleChangeRowPerPage={handleChangeRowPerPage}
            ></CustomPagination>
          )}
        </Col>
      </ModalBody>
    </Modal>
  );
};

export default LogInventoryModal;
