import React, { useState, useEffect, useMemo } from 'react';
import { Col, Button } from 'reactstrap';
import userPermission from '@utils/userPermission';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import Loading from '@components/loading/Loading';
import DataTable from 'react-data-table-component';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import InventoryAdd from '@components/workshops/inventory/InventoryAdd';
import InventoryEdit from '@components/workshops/inventory/InventoryEdit';
import CustomModal from '@components/modal/CustomModal';
import EditActiveInventoryModal from '@components/modal/EditActiveInventoryModal';
import EditInactiveInventoryModal from '@components/modal/EditInactiveInventoryModal';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { assign, find, isEmpty, map } from 'lodash';

const InventoryList = (props) => {
  const { id, activeTab, onLoading, dataWorkshop, handleResultNotification, handleSuccessAddInventory } = props;

  const api = new API('v2');
  const { generatePermission } = userPermission();
  const history = useHistory();
  const qp = qs.parse(history?.location?.search);
  const isFlagship = dataWorkshop?.tier?.value === 'flagship_plus' || dataWorkshop?.tier?.value === 'otoxpress';
  const isGMS = dataWorkshop?.is_gms;

  const [data, setData] = useState([]);
  const [idInventory, setIdInventory] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [hasModalAddInventory, setHasModalAddInventory] = useState(false);
  const [hasModalEditInventory, setHasModalEditInventory] = useState(false);
  const [hasModalActiveInventory, setHasModalActiveInventory] = useState(false);
  const [hasModalInactiveInventory, setHasModalInactiveInventory] = useState(false);
  const [hasModalChoose, setHasModalChoose] = useState(false);
  const [enableEditStatus, setEnableEditStatus] = useState(false);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [productTypeChoosen, setProductTypeChoosen] = useState('');

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'inventoryall'));
  };

  const allowPermissionCogs = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'inventorycogsall'));
  };

  const allowPermissionB2b = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'inventoryb2ball'));
  };

  const allowList = allowPermission('inventorylist');
  const allowAdd = allowPermission('inventoryadd');
  const allowEnable = allowPermission('inventoryenable');
  const allowDisable = allowPermission('inventorydisable');
  const allowEdit = allowPermission('inventoryedit');
  const allowCogs = allowPermissionCogs('inventorycogsshow');
  const allowB2b = allowPermissionB2b('inventoryb2bshow');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        name: 'Status',
        selector: (row) =>
          row.status ? (
            <span className="badge-custom badge-custom-success">Aktif</span>
          ) : (
            <span className="badge-custom badge-custom-danger">Tidak Aktif</span>
          ),
        minWidth: '90px',
        center: true,
        sortable: true,
        sortField: 'status',
      },
      {
        name: 'Product ID',
        selector: (row) => row?.product_id ?? '-',
        minWidth: '110px',
        sortable: true,
        sortField: 'product_id',
      },
      {
        name: 'Product Name',
        minWidth: '225px',
        selector: (row) => row?.product_name ?? '-',
        sortable: true,
        sortField: 'product_name',
        wrap: true,
      },
      {
        name: 'Display Name',
        minWidth: '225px',
        selector: (row) => row?.display_name ?? '-',
        sortable: true,
        sortField: 'product_name',
        wrap: true,
      },
      {
        name: 'Category',
        selector: (row) => row?.category ?? '-',
        minWidth: '120px',
        sortable: true,
        sortField: 'category',
      },
      {
        name: isGMS ? 'Quantity' : '',
        minWidth: isGMS ? '110px' : '0px',
        selector: (row) => row?.sellable_qty ?? '-',
        wrap: true,
        style: {
          display: isGMS ? '' : 'none',
        },
      },
      {
        name: allowCogs ? 'COGS' : '',
        selector: (row) => (
          <span className="product-price">{allowCogs ? `Rp. ${Helper.formatMoney(row?.cogs)}` : ''}</span>
        ),
        minWidth: allowCogs ? '150px' : '0px',
        sortable: true,
        sortField: 'cogs',
      },
      {
        name: allowB2b ? 'B2B Sell Price' : '',
        selector: (row) => (
          <span className="product-price">{allowB2b ? `Rp. ${Helper.formatMoney(row?.base_price)}` : ''}</span>
        ),
        minWidth: allowB2b ? '150px' : '0',
        sortable: true,
        sortField: 'base_price',
      },
      {
        name: 'B2C Sell Price',
        selector: (row) => <span className="product-price">{`Rp. ${Helper.formatMoney(row?.sell_price)}`}</span>,
        minWidth: '150px',
        sortable: true,
        sortField: 'sell_price',
      },
    ],
    [resetSorting]
  );

  const statusOptions = [
    { value: '', label: 'Semua' },
    { value: 'active', label: 'Aktif' },
    { value: 'inactive', label: 'Tidak Aktif' },
  ];

  const dataFilter = [
    {
      id: 'filter_text_search',
      type: 'text',
      name: 'search',
      name_field: 'search',
      value: '',
      placeholder: 'Cari ID/Product Name',
    },
    {
      id: 'filter_text_display_search',
      type: 'text',
      name: 'display_search',
      name_field: 'display_search',
      value: '',
      placeholder: 'Cari Display Name',
    },
    {
      id: 'filter_select_status',
      type: 'select',
      name: 'status',
      name_field: 'status',
      options: statusOptions,
      value: '',
      placeholder: 'Status',
    },
    {
      id: 'filter_range_price_sell',
      type: 'range_price',
      name_from: 'price_sell_from',
      name_to: 'price_sell_to',
      name_field: 'price',
      value: {},
      placeholder_main: 'Rp Price',
      placeholder_from: 'Rp harga dari',
      placeholder_to: 'Rp harga sampai',
    },
  ];

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchInventory() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/workshops/${id}/inventories/`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
    }
  }

  const getParamData = () => {
    let params = {
      id: id,
      category: activeTab?.name ? (activeTab?.name == 'Semua' ? '' : activeTab?.name) : '',
      search: filtered?.search ?? '',
      display_search: filtered?.display_search ?? '',
      status: filtered?.status?.value ?? '',
      page: page,
      limit: pageLimit,
    };

    if (filtered?.price?.price_from && filtered?.price?.price_to) {
      params.sell_price_from = filtered?.price?.price_from?.formatMoney;
      params.sell_price_to = filtered?.price?.price_to?.formatMoney;
    }

    if (sortingData) {
      params.sort = sortingData?.sort;
      params.order = sortingData?.order;
    }

    return params;
  };

  const handleChangeSorting = (column, sortDirection) => {
    setSortingData({
      sort: column?.sortField ?? '',
      order: sortDirection ?? '',
    });
  };

  const handleApplyFilters = async (params) => {
    await setFiltered(params);

    if (!isEmpty(qp)) {
      const payload = {
        ...qp,
        search: params?.search,
        display_search: params?.display_search,
        status: params?.status?.value,
        price_from: params?.price?.price_from?.formatMoney,
        price_to: params?.price?.price_to?.formatMoney,
      };

      history.push({ search: qs.stringify(payload) });
    }
  };

  const handleResetFilters = () => {
    setFilters(dataFilter);
    setFiltered({});

    let tempQP = qp;
    if (!isEmpty(qp)) {
      delete tempQP.search;
      delete tempQP.display_search;
      delete tempQP.status;
      delete tempQP.price_from;
      delete tempQP.price_to;
    }

    history.push({ search: qs.stringify(tempQP) });
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleChangeSelectedRow = (selected) => {
    setSelectedData(selected?.selectedRows ?? []);
  };

  const handleAddInventory = () => {
    isGMS ? setHasModalChoose(true) : setHasModalAddInventory(true);
  };

  const handleEditInventory = (row) => {
    if (allowEdit) {
      setIdInventory(row?.inventory_id);
      isGMS && row?.product_type === 'part'
        ? history.push(`/workshops/${id}/inventory/${row?.inventory_id}`)
        : setHasModalEditInventory(true);
    }
  };

  const handleActiveInventory = () => {
    setHasModalActiveInventory(true);
  };

  const handleInactiveInventory = () => {
    setHasModalInactiveInventory(true);
  };

  const handleSubmitAddInventory = (status, error) => {
    const errorMsg = error ? 'Gagal Add Inventory Workshop, ' + error : 'Gagal Add Inventory Workshop';
    if (status) {
      handleSuccessAddInventory();
    }
    setHasModalAddInventory(false);
    handleResultNotification(true, status ? 'success' : 'fail', status ? 'Berhasil Add Inventory Workshop' : errorMsg);
  };

  const handleSubmitEditInventory = (status, error) => {
    const errorMsg = error ? 'Gagal Edit Inventory Workshop, ' + error : 'Gagal Edit Inventory Workshop';
    if (status) {
      resetInventory();
    }
    setHasModalEditInventory(false);
    handleResultNotification(true, status ? 'success' : 'fail', status ? 'Berhasil Edit Inventory Workshop' : errorMsg);
  };

  const handleSubmitEditActiveInventory = (status) => {
    if (status) {
      resetInventory();
    }
    setHasModalActiveInventory(false);
    handleResultNotification(
      true,
      status ? 'success' : 'fail',
      status ? 'Berhasil Aktifkan Inventory Workshop' : 'Gagal Aktifkan Inventory Workshop'
    );
  };

  const handleSubmitEditInactiveInventory = (status) => {
    if (status) {
      resetInventory();
    }
    setHasModalInactiveInventory(false);
    handleResultNotification(
      true,
      status ? 'success' : 'fail',
      status ? 'Berhasil Matikan Inventory Workshop' : 'Gagal Matikan Inventory Workshop'
    );
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodFetchInventory();
  };

  const handleMethodFetchInventory = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchInventory();
    }
  };

  const resetInventory = () => {
    setResetSorting(true);
    setSortingData({});
  };

  const toogleAddInventory = () => setHasModalAddInventory(!hasModalAddInventory);
  const toogleEditInventory = () => setHasModalEditInventory(!hasModalEditInventory);
  const toogleActiveInventory = () => setHasModalActiveInventory(!hasModalActiveInventory);
  const toogleInactiveInventory = () => setHasModalInactiveInventory(!hasModalInactiveInventory);

  const setDataValue = (item) => {
    if (item?.id === 'filter_text_search') return qp?.search;
    if (item?.id === 'filter_text_display_search') return qp?.display_search;
    if (item?.id === 'filter_select_status') return find(statusOptions, { value: qp?.status ?? item?.status });
    if (item?.id === 'filter_range_price_sell') {
      let price = {};
      if (qp?.price_from)
        assign(price, {
          price_from: {
            formatIsMoney: true,
            formatMoney: parseInt(qp?.price_from),
            formatMoneyString: `Rp ${qp?.price_from ? Helper.numberWithCommas(qp?.price_from) : '-'}`,
          },
        });
      if (qp?.price_to)
        assign(price, {
          price_to: {
            formatIsMoney: true,
            formatMoney: parseInt(qp?.price_to),
            formatMoneyString: `Rp ${qp?.price_to ? Helper.numberWithCommas(qp?.price_to) : '-'}`,
          },
        });

      return price;
    }
  };

  const setDefaultFilters = async () => {
    dataFilter[2].value = { value: 'active', label: 'Aktif' };
    setFilters([...dataFilter]);

    if (Object.keys(qp).length > 2) {
      const filterPayload = {
        search: qp.search,
        display_search: qp.display_search,
        status: qp.status,
        price:
          qp.price_from || qp.price_to
            ? {
                price_from: {
                  formatIsMoney: true,
                  formatMoney: parseInt(qp?.price_from),
                  formatMoneyString: `Rp ${qp?.price_from ? Helper.numberWithCommas(qp?.price_from) : '-'}`,
                },
                price_to: {
                  formatIsMoney: true,
                  formatMoney: parseInt(qp?.price_to),
                  formatMoneyString: `Rp ${qp?.price_to ? Helper.numberWithCommas(qp?.price_to) : '-'}`,
                },
              }
            : {},
      };

      setFiltered(filterPayload);
      return;
    }

    setFiltered({
      status: { value: 'active', label: 'Aktif' },
    });
  };

  const setActiveFilters = async () => {
    const activeFilters = [];

    await map(filters, (item) => {
      const data = {
        ...item,
        value: setDataValue(item),
        is_filtered: true,
      };
      activeFilters.push(data);
    });

    await setFilters(activeFilters);
  };

  const handleAddWorkInventory = () => {
    setProductTypeChoosen('work');
    setHasModalAddInventory(true);
    setHasModalChoose(false);
  };

  useEffect(async () => {
    if (page && pageLimit && !firstLoad) {
      // if (Object.keys(qp).length < 3) await setDefaultFilters();
      fetchInventory();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (activeTab?.id && !firstLoad) {
      resetInventory();
      setDefaultFilters();
    }
  }, [activeTab]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    setEnableEditStatus(false);
  }, [selectedData]);

  useEffect(async () => {
    if (firstLoad && filtered) {
      if (Object.keys(qp).length > 2) await setActiveFilters();
      fetchInventory();
    } else {
      resetInventory();
    }
  }, [filtered]);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    setDefaultFilters();
  }, []);

  return (
    <React.Fragment>
      <InventoryAdd
        idWorkshop={id}
        isFlagship={isFlagship}
        modal={hasModalAddInventory}
        productType={productTypeChoosen}
        isGms={isGMS}
        onSubmit={handleSubmitAddInventory}
        toggle={toogleAddInventory}
      />
      <InventoryEdit
        idWorkshop={id}
        idInventory={idInventory}
        modal={hasModalEditInventory}
        onSubmit={handleSubmitEditInventory}
        toggle={toogleEditInventory}
      />
      <EditActiveInventoryModal
        data={selectedData}
        modal={hasModalActiveInventory}
        onSubmit={handleSubmitEditActiveInventory}
        toggle={toogleActiveInventory}
      ></EditActiveInventoryModal>
      <EditInactiveInventoryModal
        data={selectedData}
        modal={hasModalInactiveInventory}
        onSubmit={handleSubmitEditInactiveInventory}
        toggle={toogleInactiveInventory}
      ></EditInactiveInventoryModal>
      <CustomModal
        className="modal-confirm-inventory"
        modalWidth={'auto'}
        modal={hasModalChoose}
        toggle={() => setHasModalChoose(false)}
        modalHeader="Silahkan Pilih?"
        modalFooter={
          <>
            <Button color="secondary" size="md" outline onClick={() => handleAddWorkInventory()}>
              Tambah Jasa
            </Button>
            <Button color="primary" size="md" onClick={() => history.push(`/workshops/${id}/add/inventory`)}>
              Tambah Product
            </Button>
          </>
        }
      >
        Anda akan melakukan tambah product atau jasa ?
      </CustomModal>
      <Col sm={12} md={12} className="p-0 mt-0 section-action">
        {allowAdd && (
          <Button disabled={loading} className="button-action add" onClick={handleAddInventory}>
            {'Tambah'}
          </Button>
        )}

        {allowDisable && (
          <Button disabled={!enableEditStatus} className="button-action inactive" onClick={handleActiveInventory}>
            {'Matikan'}
          </Button>
        )}

        {allowEnable && (
          <Button disabled={!enableEditStatus} className="button-action activate" onClick={handleInactiveInventory}>
            {'Aktif'}
          </Button>
        )}
      </Col>
      {filters?.length !== 0 && (
        <Col sm={12} md={12} className="p-0 mt-4">
          <ActionFilterCustom
            filters={filters}
            loading={loading}
            onApplyFilters={handleApplyFilters}
            onResetFilters={handleResetFilters}
          ></ActionFilterCustom>
        </Col>
      )}

      <Col sm={12} md={12} className="p-0 mt-4">
        <DataTable
          selectableRows
          clearSelectedRows={loading}
          onSelectedRowsChange={handleChangeSelectedRow}
          persistTableHead
          highlightOnHover
          sortServer
          noHeader={true}
          progressPending={loading}
          data={data}
          className="table dt-responsive table-custom"
          noDataComponent={noDataComponent}
          progressComponent={<Loading />}
          customStyles={customStylesHeaderTable}
          onSort={handleChangeSorting}
          onRowClicked={(row) => handleEditInventory(row)}
          columns={allowList ? columns : []}
        />
      </Col>
      <Col sm={12} md={12} className="p-0 mt-2">
        {data?.length >= 1 && !loading && allowList && (
          <CustomPagination
            page={page}
            pageLimit={pageLimit}
            totalRows={data?.length ?? 0}
            totalAllRows={totalRows}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
            handleChangeRowPerPage={handleChangeRowPerPage}
          ></CustomPagination>
        )}
      </Col>
    </React.Fragment>
  );
};

export default InventoryList;
