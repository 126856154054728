import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Col,
  Row,
  Label,
  Alert,
} from 'reactstrap';
import API from '@utils/API';

const AddGalleryVideoModal = (props) => {
  const { modal, toggle, category, onSubmit } = props;
  const [videoTitle, setVideoTitle] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [message, setMessage] = useState(null);
  const [hasMessage, setHasMessage] = useState(false);
  const [isError, setIsError] = useState(false);

  const api = new API('v2');

  const reset = () => {
    setVideoTitle('');
    setVideoLink('');
    setMessage(null);
    setHasMessage(false);
    setIsError(false);
  };

  const submitForm = () => {
    let pattern = /(http:|https:)?(\/\/)?(www\.)?(youtube.com|youtu.be)\/(watch|embed)?(\?v=|\/)?(\S+)?/;

    if (videoLink.match(pattern)) {
      if (videoTitle) {
        const result = {
          title: videoTitle,
          link: videoLink,
          section: category,
        };
        reset();
        onSubmit(result);
      } else {
        setIsError(true);
        setMessage('Judul video tidak valid');
        setHasMessage(true);
      }
    } else {
      setIsError(true);
      setMessage('Video link tidak valid');
      setHasMessage(true);
    }
  };

  const cancel = () => {
    toggle();
    reset();
  };

  return (
    <Modal className="modal-dialog-centered modal-add-video-workshop" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body">
        <div className="header">
          Tambah Link Video
          <div className="button-close" onClick={() => cancel()}></div>
        </div>
        <div className="content">
          <div className="mb-3">Tambahkan link video</div>
          <Row>
            <Col>
              <FormGroup row>
                <Label lg={3} className="label-required">
                  Judul Video
                </Label>
                <Col lg={9}>
                  <Input
                    type="text"
                    placeholder="Judul Video"
                    onChange={(e) => setVideoTitle(e.target.value)}
                    value={videoTitle}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup row>
                <Label lg={3} className="label-required">
                  Link
                </Label>
                <Col lg={9}>
                  <Input
                    type="text"
                    placeholder="Link Video"
                    onChange={(e) => setVideoLink(e.target.value)}
                    value={videoLink}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          {hasMessage && (
            <Alert className="mb-3" color={isError ? 'danger' : 'primary'}>
              {message}
            </Alert>
          )}
        </div>

        <div className="action mb-3">
          <div className="button-cancel" onClick={() => cancel()}>
            Batal
          </div>
          <Button className="button-action" onClick={() => submitForm()}>
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddGalleryVideoModal;
