import React, { useState, useMemo, useEffect } from 'react';
import { Badge, Card, CardBody, Col, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import Loading from '@components/loading/Loading';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import userPermission from '@utils/userPermission';
import moment from 'moment';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import CustomPagination from '@components/pagination/CustomPagination';
import SubscriptionBadge from '@components/badge/SubscriptionBadge';

function Subscriptions() {
  const history = useHistory();
  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const statusOptions = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'Menunggu Bayar',
      value: 'pending',
    },
    {
      label: 'Aktif',
      value: 'active',
    },
    {
      label: 'Kadaluwarsa',
      value: 'expired',
    },
    {
      label: 'Batal',
      value: 'cancel',
    },
    {
      label: 'Masa Tenggang',
      value: 'grace_period',
    },
    {
      label: 'Ditangguhkan',
      value: 'postpone',
    },
  ];

  let dataFilter = [
    {
      id: 'filter_select_text_custom_search',
      type: 'select_text',
      name: 'custom_search',
      name_field: 'custom_search',
      placholder_select: 'Kategori',
      placeholder_default_text: 'Contoh: ID Subsriptions',
      width: 'w-425',
      width_select: 'w-p-35',
      width_text: 'w-p-65',
      value: {},
      options: [
        { value: 'license_plate', label: 'Plat Nomor', placeholder_text: 'Contoh: B 123 DKI' },
        { value: 'name', label: 'Nama Pelanggan', placeholder_text: 'Contoh: Andi Lesmana' },
        { value: 'subscription', label: 'Paket Langganan', placeholder_text: 'Contoh: Langganan Cuci' },
      ],
    },
    {
      id: 'filter_date_date',
      type: 'date',
      name: 'date',
      name_field: 'date',
      date_range: true,
      value: '',
      placeholder: 'Range Tanggal',
    },
    {
      id: 'filter_date_date_single',
      type: 'date',
      name: 'date_single',
      name_field: 'date_single',
      date_range: false,
      value: '',
      placeholder: 'Tanggal',
    },
    {
      id: 'filter_select_status',
      type: 'select',
      name: 'status',
      name_field: 'status',
      options: statusOptions,
      value: '',
      placeholder: 'Status',
    },
    {
      id: 'filter_range_price_sell',
      type: 'range_price',
      name_from: 'price_sell_from',
      name_to: 'price_sell_to',
      name_field: 'price',
      value: {},
      placeholder_main: 'Rp Price',
      placeholder_from: 'Rp harga dari',
      placeholder_to: 'Rp harga sampai',
    },
    {
      id: 'filter_text_subscription_name',
      type: 'text',
      name: 'subscription_name',
      name_field: 'subscription_name',
      value: '',
      placeholder: 'Cari Subscription Name',
    },
  ];

  const setDefaultFilters = () => {
    dataFilter[0].value = {
      searchBy: { value: 'license_plate', label: 'Plat Nomor', placeholder_text: 'Contoh: B 123 DKI' },
      search: 'B 123',
    };
    dataFilter[1].value = {
      start_date: '2022-10-01',
      end_date: '2022-10-10',
    };
    dataFilter[2].value = {
      selected_date: '2022-10-09',
    };
    dataFilter[3].value = {
      label: 'Menunggu Bayar',
      value: 'pending',
    };
    dataFilter[4].value = {
      price_from: Helper.formatMoneyOnField('' + 100000, 'Rp '),
      price_to: Helper.formatMoneyOnField('' + 1000000, 'Rp '),
    };
    dataFilter[5].value = 'Paket';
    setFilters([...dataFilter]);
    setFiltered({
      custom_search: {
        searchBy: { value: 'license_plate', label: 'Plat Nomor', placeholder_text: 'Contoh: B 123 DKI' },
        search: 'B 123',
      },
      date: {
        start_date: '2022-10-01',
        end_date: '2022-10-10',
      },
      date_single: {
        selected_date: '2022-10-09',
      },
      status: {
        label: 'Menunggu Bayar',
        value: 'pending',
      },
      price: {
        price_from: Helper.formatMoneyOnField('' + 100000, 'Rp '),
        price_to: Helper.formatMoneyOnField('' + 1000000, 'Rp '),
      },
      subscription_name: 'Paket',
    });
  };

  const getLabelLeftTime = (timeLeft) => {
    let labelTimeLeft = '-';
    if (timeLeft !== undefined) {
      labelTimeLeft = timeLeft === 0 ? timeLeft + ' hari' : labelTimeLeft;
      labelTimeLeft = timeLeft < 0 ? Math.abs(timeLeft) + ' hari terlewat' : labelTimeLeft;
      labelTimeLeft = timeLeft > 0 ? timeLeft + ' hari lagi' : labelTimeLeft;
    }

    return labelTimeLeft;
  };

  const columns = useMemo(
    () => [
      {
        name: 'Dibuat Pada',
        selector: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('DD MMM, YYYY') : '-'}
            </span>
            <span className="time">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('HH:MM:SS') : '-'}
            </span>
          </div>
        ),
        minWidth: '120px',
        left: true,
        sortable: true,
        sortField: 'created_at',
      },
      {
        name: 'Plat Nomor',
        selector: (row) => <span className="subscriptions_list_customer">{row?.license_plate ?? '-'}</span>,
        minWidth: '160px',
        sortable: false,
        sortField: 'license_plate',
      },
      {
        name: 'Nama Pelanggan',
        selector: (row) => <span className="subscriptions_list_customer">{row?.customer_name ?? '-'}</span>,
        minWidth: '180px',
        sortable: true,
        sortField: 'name',
      },
      {
        name: 'Paket Langganan',
        minWidth: '180px',
        selector: (row) => <span className="subscriptions_list_package">{row?.subscription_name ?? '-'}</span>,
        sortable: true,
        sortField: 'subscription',
        wrap: true,
      },
      {
        name: 'Sisa Waktu',
        selector: (row) => <span className="subscriptions_list_time_left">{getLabelLeftTime(row?.time_left)}</span>,
        minWidth: '120px',
        sortable: true,
        sortField: 'time_left',
      },
      {
        name: 'Status',
        selector: (row) => <SubscriptionBadge status={row?.status} />,
        minWidth: '170px',
        sortable: true,
        sortField: 'status',
      },
    ],
    [resetSorting]
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchSubscriptions() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/customer-subscriptions/`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
    }
  }

  const getParamData = () => {
    let params = {
      name: filtered?.custom_search?.searchBy?.value == 'name' ? filtered?.custom_search?.search ?? '' : '',
      license_plate:
        filtered?.custom_search?.searchBy?.value == 'license_plate' ? filtered?.custom_search?.search ?? '' : '',
      subscription:
        filtered?.custom_search?.searchBy?.value == 'subscription' ? filtered?.custom_search?.search ?? '' : '',
      status: filtered?.status?.value ?? '',
      search: filtered?.subscription_name ?? '',
      date: filtered?.date_single?.selected_date
        ? moment(filtered?.date_single?.selected_date).format('YYYY-MM-DD')
        : '',
      start_date: filtered?.date?.start_date ? moment(filtered?.date?.start_date).format('YYYY-MM-DD') : '',
      end_date: filtered?.date?.end_date ? moment(filtered?.date?.end_date).format('YYYY-MM-DD') : '',
      page: page,
      limit: pageLimit,
    };

    if (filtered?.price?.price_from && filtered?.price?.price_to) {
      params.sell_price_from = filtered?.price?.price_from?.formatMoney;
      params.sell_price_to = filtered?.price?.price_to?.formatMoney;
    }

    if (sortingData) {
      params.sort = sortingData?.sort;
      params.order = sortingData?.order;
    }

    return params;
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleChangeSorting = (column, sortDirection) => {
    setSortingData({
      sort: column?.sortField ?? '',
      order: sortDirection ?? '',
    });
  };

  const handleResetFilters = () => {
    setFilters(dataFilter);
    setFiltered({});
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodFetchSubscriptions();
  };

  const handleMethodFetchSubscriptions = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchSubscriptions();
    }
  };

  const resetSubsriptions = () => {
    setResetSorting(true);
    setSortingData({});
  };

  const createSubscription = () => {
    history.push(`/subscriptions/create`);
  };

  const openSubscriptionsDetail = (row) => {
    history.push(`/subscriptions/${row?.id}`);
  };

  useEffect(() => {
    if (page && pageLimit && !firstLoad) {
      fetchSubscriptions();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    if (firstLoad && filtered) {
      fetchSubscriptions();
    } else {
      resetSubsriptions();
    }
  }, [filtered]);

  useEffect(() => {
    setDefaultFilters();
  }, []);

  return (
    <Wrapper title="Langganan" className="wrapperContent">
      <Card className="p-3">
        <CardBody className="p-0">
          <Col sm={12} md={12} className="p-0 mt-0 section-action">
            <Button disabled={true} className="button-action add w-170" onClick={createSubscription}>
              {'Buat Langganan Baru'}
            </Button>
          </Col>
          {filters?.length !== 0 && (
            <Col sm={12} md={12} className="p-0 mt-2">
              <ActionFilterCustom
                filters={filters}
                loading={loading}
                onApplyFilters={(e) => setFiltered(e)}
                onResetFilters={handleResetFilters}
              ></ActionFilterCustom>
            </Col>
          )}
          <Col sm={12} md={12} className="p-0 mt-2">
            <DataTable
              persistTableHead
              highlightOnHover
              sortServer
              noHeader={true}
              progressPending={loading}
              data={data}
              columns={columns}
              className="table dt-responsive table-custom"
              noDataComponent={noDataComponent}
              progressComponent={<Loading />}
              customStyles={customStylesHeaderTable}
              onSort={handleChangeSorting}
              onRowClicked={(row) => openSubscriptionsDetail(row)}
            />
          </Col>
          <Col sm={12} md={12} className="p-0 mt-2">
            {data?.length >= 1 && !loading && (
              <CustomPagination
                page={page}
                pageLimit={pageLimit}
                totalRows={data?.length ?? 0}
                totalAllRows={totalRows}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                handleChangeRowPerPage={handleChangeRowPerPage}
              ></CustomPagination>
            )}
          </Col>
        </CardBody>
      </Card>
    </Wrapper>
  );
}

export default Subscriptions;
