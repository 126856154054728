import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleSearchWorkshop, handleChangeWorkshop } from '@actions/Booking';
import { Card, CardBody, CardHeader, ListGroup, CardFooter, Button } from 'reactstrap';
import Popup from 'react-popup';
import userPermission from '@utils/userPermission';
import ChangeWorkshopModal from '@components/modal/ChangeWorkshopModal';
import DetailField from '@components/field/DetailField';
import WhatsappIcon from '@assets/icons/whatsapp.svg';
import NewTabIcon from '@assets/icons/new-tab.svg';

function BookingDetailWorkshop(props) {
  const dispatch = useDispatch();
  const { id, detailData, canChangeWorkshop, onFetchBookingDetail } = props;
  const { generatePermission } = userPermission();
  const [dataWorkshop, setDataWorkshop] = useState(null);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);
  const [showChangeWorkshopModal, setShowChangeWorkshopModal] = useState(false);

  const changeWorkshopModalToggle = () => {
    setSelectedWorkshop();
    setShowChangeWorkshopModal(!showChangeWorkshopModal);
  };

  const searchWorkshop = (e) => {
    e.preventDefault();

    dispatch(handleSearchWorkshop(e.target.value)).then((resp) => {
      setDataWorkshop(e.target.value === '' ? null : resp.data.workshops);
    });
  };

  const changeWorkshop = (e) => {
    e.preventDefault();

    dispatch(handleChangeWorkshop(id, dataWorkshop)).then((resp) => {
      onFetchBookingDetail(id);
      setShowChangeWorkshopModal(false);

      Popup.alert('Workshop telah berhasil di update!');
    });
  };

  return (
    <React.Fragment>
      <ChangeWorkshopModal
        modal={showChangeWorkshopModal}
        toggle={changeWorkshopModalToggle}
        handleSearchWorkshop={searchWorkshop}
        handleChangeWorkshop={changeWorkshop}
        dataWorkshop={dataWorkshop}
      />
      <Card>
        <CardHeader className="bg-primary text-white mt-0">Bengkel</CardHeader>
        <CardBody className="pb-0">
          <ListGroup className="">
            <DetailField title="WS ID" value={detailData?.workshop?.id} />
            <DetailField
              title="Nama"
              value={
                <>
                  {detailData?.workshop?.name}
                  <a
                    href={`/workshops/${detailData?.workshop?.id}?activetab=information`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="button-pen ml-2" src={NewTabIcon} />
                  </a>
                </>
              }
            />
            <DetailField
              title="Alamat"
              value={
                <>
                  {detailData?.workshop?.street_address ? (
                    <>
                      {detailData?.workshop?.street_address}{' '}
                      <a href={detailData?.workshop?.gmaps_link} target="_blank" rel="noreferrer">
                        <img className="button-pen ml-2" src={NewTabIcon} />
                      </a>
                    </>
                  ) : (
                    '-'
                  )}
                </>
              }
            />
            <DetailField
              title={
                <>
                  WA Group
                  <img className="button-pen ml-2" src={WhatsappIcon} />
                </>
              }
              value={
                <>
                  {detailData?.workshop?.wa_group_link ? (
                    <>
                      {detailData?.workshop?.wa_group_link}{' '}
                      <a href={detailData?.workshop?.wa_group_link} target="_blank" rel="noreferrer">
                        <img className="button-pen ml-2" src={NewTabIcon} />
                      </a>
                    </>
                  ) : (
                    '-'
                  )}
                </>
              }
            />
            <DetailField
              title="PIC Otoklix"
              value={detailData?.workshop?.otoklix_pic_name ? detailData?.workshop?.otoklix_pic_name : '-'}
            />
            <DetailField
              title="WS Owner"
              value={
                +detailData?.workshop?.workshop_contact?.owner?.length > 0 &&
                detailData?.workshop?.workshop_contact?.owner.map((owner) => (
                  <>
                    {owner?.name} ({owner?.phone_number}) <br />
                  </>
                ))
              }
            />
            <DetailField
              title="PIC WS"
              value={
                +detailData?.workshop?.workshop_contact?.pic?.length > 0 &&
                detailData?.workshop?.workshop_contact?.pic.map((pic) => (
                  <>
                    {pic?.name} ({pic?.phone_number}) <br />
                  </>
                ))
              }
            />
          </ListGroup>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default BookingDetailWorkshop;
