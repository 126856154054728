import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const EditPackageModal = (props) => {
  const { modal, toggle, headerTitle, onConfirm, children, isCancel } = props;

  return (
    <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggle}>
      <ModalHeader>{headerTitle}</ModalHeader>
      <ModalBody>
        <p>{children}</p>
      </ModalBody>
      {!isCancel && (
        <ModalFooter>
          <Button color="danger" outline size="lg" onClick={() => toggle()}>
            Batalkan
          </Button>{' '}
          <Button color="primary" size="lg" onClick={onConfirm}>
            Ya, Simpan
          </Button>
        </ModalFooter>
      )}
      {isCancel && (
        <ModalFooter>
          <Button color="danger" outline size="lg" onClick={() => toggle()}>
            Kembali
          </Button>{' '}
          <Button color="danger" size="lg" onClick={onConfirm}>
            Ya, Batalkan
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default EditPackageModal;
