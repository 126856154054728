import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ActionSwitch from '@components/field/ActionSwitch';
import CloseIcon from '@assets/icons/close.svg';

const AddSystemConfigurationModal = (props) => {
  const { isOpen, toggle, onSubmit, initialValue, loading, onClosed } = props;

  const [forms, setForms] = useState({
    name: '',
    key: '',
    platform: '',
    is_apply: '',
  });
  const [formsError, setFormsError] = useState({
    nameError: false,
    keyError: false,
    platformError: false,
  });

  const handleChangeName = (e) => {
    setForms({ ...forms, name: e.target.value });
    if (e.target.value.length < 1) {
      setFormsError({ ...formsError, nameError: true });
    } else {
      setFormsError({ ...formsError, nameError: false });
    }
  };

  const handleChangeKey = (e) => {
    setForms({ ...forms, key: e.target.value });
    if (e.target.value.length < 1) {
      setFormsError({ ...formsError, keyError: true });
    } else {
      setFormsError({ ...formsError, keyError: false });
    }
  };

  const handleChangePlatform = (e) => {
    setForms({ ...forms, platform: e.target.value });
    if (e.target.value.length < 1) {
      setFormsError({ ...formsError, platformError: true });
    } else {
      setFormsError({ ...formsError, platformError: false });
    }
  };

  const handleChangeStatus = (e) => {
    setForms({ ...forms, is_apply: e.target.checked });
  };

  const handleOnCloseModal = () => {
    setForms({
      name: '',
      key: '',
      platform: '',
      is_apply: '',
    });

    onClosed();
  };

  useEffect(() => {
    setForms({
      name: initialValue?.name ?? '',
      key: initialValue?.key ?? '',
      platform: initialValue?.platform ?? '',
      is_apply: initialValue?.is_apply ?? '',
    });
  }, [initialValue]);

  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={() => toggle()} onClosed={handleOnCloseModal}>
      <ModalHeader
        className="d-flex align-items-center cursor-pointer"
        close={<img src={CloseIcon} alt="close-icon" onClick={() => handleOnCloseModal()} role="button" />}
      >
        {`${initialValue ? 'Edit' : 'Add New'} System Configuration`}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label sm={2} className="required text-left">
              Name
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                role="textbox"
                name="name"
                placeholder="Ex: Booking Revise"
                value={forms.name}
                onChange={handleChangeName}
                invalid={formsError?.nameError}
                required
              />
              {formsError?.nameError && <div className="pt-1 text-danger">Nama wajib diisi!</div>}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} className="required text-left">
              Key
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="key"
                placeholder="booking-revise"
                value={forms.key}
                onChange={handleChangeKey}
                invalid={formsError?.keyError}
              />
              {formsError?.keyError && <div className="pt-1 text-danger">Key wajib diisi!</div>}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} className="required text-left">
              Platform
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="platform"
                placeholder="Ex: Intools, CustApp, GMS"
                value={forms.platform}
                onChange={handleChangePlatform}
                invalid={formsError?.platformError}
              />
              {formsError?.platformError && <div className="pt-1 text-danger">Platform wajib diisi!</div>}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} className="text-left">
              Status
            </Label>
            <Col sm={10}>
              <ActionSwitch
                customClass="mb-0"
                color="secondary"
                inputName={`status`}
                isChecked={forms.is_apply ? true : false}
                onChange={handleChangeStatus}
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="m-auto px-4 rounded-pill"
          size="lg"
          onClick={() => onSubmit(forms)}
          disabled={!forms.name || loading}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddSystemConfigurationModal;
