import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';

export const options = [
  { value: '09.00 - 11.00', label: '09.00 - 11.00' },
  { value: '11.00 - 13.00', label: '11.00 - 13.00' },
  { value: '13.00 - 15.00', label: '13.00 - 15.00' },
  { value: '13.00 - 15.00', label: '13.00 - 15.00' },
];

const RescheduleDraftModal = (props) => {
  const {
    modal,
    toggle,
    bookingDate,
    bookingTime,
    onChangeRescheduleTime,
    onChangeBookingDate,
    onReschedule,
    hasInvalidDate,
    scheduleButtonLoading,
    messageInvalidDate,
  } = props;

  return (
    <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Reschedule Booking Date</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label sm={4}>New Booking Date</Label>
            <Col sm={8}>
              <Input
                type="date"
                placeholder="New Booking Date"
                value={bookingDate}
                onChange={onChangeBookingDate}
                invalid={hasInvalidDate}
              />
              <FormFeedback>{messageInvalidDate}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>New Booking Time</Label>
            <Col sm={8}>
              <Input type="time" value={bookingTime} onChange={onChangeRescheduleTime} />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Cancel
        </Button>{' '}
        <Button color="secondary" disabled={scheduleButtonLoading} onClick={onReschedule}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RescheduleDraftModal;
