import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import API from '@utils/API';
import { Col, Button, Form, FormGroup, Label, Input, Row, Card, CardBody, CardHeader } from 'reactstrap';
import Wrapper from '@components/wrapper/Wrapper';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import Select from 'react-select';
import { validate } from 'react-email-validator';
import { toast, ToastContainer } from 'react-toastify';
import { regexOnlyAllowCharacter } from '@utils/Constants';

function EditUser() {
  const history = useHistory();
  const api = new API('v2');
  const { id } = useParams();

  const [role, setRole] = useState(null);
  const [roleData, setRoleData] = useState([]);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');

  const [isFirstNameNotValid, setIsFirstNameNotValid] = useState(false);
  const [isLastNameNotValid, setIsLastNameNotValid] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPhoneNumberInvalid, setIsPhoneNumberInvalid] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    user_group: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  });

  const breadcrumb = [
    {
      id: 1,
      label: 'Access Control Levels',
      active: false,
      url: '/acl',
    },
    {
      id: 2,
      label: 'Edit User',
      active: true,
      url: '',
    },
  ];

  const handleEdit = () => {
    setIsEdit(true);
  };

  const resetValues = () => {
    setIsFirstNameNotValid(false);
    setIsLastNameNotValid(false);
    setIsEmailInvalid(false);
    setIsPhoneNumberInvalid(false);
  };

  async function fetchUserData() {
    setLoading(true);

    try {
      const response = await api.get(`v2/intools/users/${id}/`);
      if (response?.data?.data) {
        const { user_group, first_name, last_name, email, phone_number } = response?.data?.data;
        setInitialValues({
          user_group: user_group,
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone_number: phone_number,
        });

        const res = await api.get('v2/intools/roles/');
        if (res?.data?.data) {
          const response = res?.data?.data;
          setRoleData(res?.data?.data);
          setRole({ value: user_group, label: response.find((role) => role.id === user_group)?.name });
        }
        resetValues();
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const options = roleData?.map((role) => {
    return { value: role?.id, label: role?.name };
  });

  const handleRoleChange = (item) => {
    setRole(item);
    setInitialValues({ ...initialValues, user_group: item.value });
  };

  const handleNameChange = (e) => {
    const { name, value } = e.target;
    setInitialValues({ ...initialValues, [name]: value });

    if (name === 'first_name') {
      const isInputCorrect = regexOnlyAllowCharacter.test(value);
      if (isInputCorrect && value) {
        setIsFirstNameNotValid(false);
        setFirstNameErrorMessage('');
      } else {
        setIsFirstNameNotValid(true);
        setFirstNameErrorMessage('First Name is empty or invalid format');
      }
      setInitialValues({ ...initialValues, [name]: value });
    } else if (name === 'last_name') {
      const isInputCorrect = regexOnlyAllowCharacter.test(value);
      if (isInputCorrect && value) {
        setIsLastNameNotValid(false);
        setLastNameErrorMessage('');
      } else {
        setIsLastNameNotValid(true);
        setLastNameErrorMessage('Last Name is empty or invalid format');
      }
      setInitialValues({ ...initialValues, [name]: value });
    }
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    let emailCheck = validate(email);
    if (emailCheck) {
      setEmailErrorMessage();
      setIsEmailInvalid(false);
    } else if (!email) {
      setEmailErrorMessage('Email is empty');
      setIsEmailInvalid(true);
    } else if (!emailCheck) {
      setEmailErrorMessage('Email is invalid format');
      setIsEmailInvalid(true);
    }

    setInitialValues({ ...initialValues, email: email });
  };

  const validatePhoneNumber = (phoneNumber) => {
    let isValidated = true;
    if (phoneNumber.indexOf('+62') !== 0) {
      isValidated = false;
      setPhoneNumberErrorMessage('Format not following +62');
      setIsPhoneNumberInvalid(true);
    }
  };

  const handler = useCallback(debounce(validatePhoneNumber, 400), []);

  const changePhoneNumber = (e) => {
    let newValue = e.target.value.replace(/[^0-9+]/g, '');
    newValue = newValue.replace(/(?!^\+)\+/g, '');
    if (newValue !== initialValues.phone_number) {
      setInitialValues({ ...initialValues, phone_number: newValue });
      setPhoneNumberErrorMessage('');
      setIsPhoneNumberInvalid(false);
      handler(newValue);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    api
      .put(`v2/intools/users/${id}/`, initialValues)
      .then(() => {
        toast.success('User has successfully updated', { position: 'top-right', autoClose: 1800 });
        setTimeout(() => {
          history.push('/acl');
        }, 2000);
      })
      .catch((e) => {
        const message = e?.response?.data?.error?.message || 'Failed update new user';
        toast.warn(message, { position: 'top-right', autoClose: 4000 });
      });
  };

  const hasErrorForm = () => {
    let hasEmptyField = false;
    Object.entries(initialValues).forEach(([key, value]) => {
      if (value) {
        // pass
      } else {
        if (key !== 'last_name') {
          hasEmptyField = true;
        }
      }
    });

    return hasEmptyField;
  };

  useEffect(() => {
    if (!isEdit) fetchUserData();
  }, [!isEdit]);

  const isButtonDisabled = hasErrorForm() || isEmailInvalid || isFirstNameNotValid || isPhoneNumberInvalid;

  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <Wrapper title="Edit User" breadcrumbData={breadcrumb}>
          <Row>
            <Col sm={12} md={6}>
              <Card>
                <CardHeader className="text-white mt-0">Edit</CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup row>
                      <Label sm={4} className="required text-left">
                        Select a Role
                      </Label>
                      <Col sm={8}>
                        <Select
                          onChange={handleRoleChange}
                          value={role}
                          options={options}
                          menuPlacement="bottom"
                          isDisabled={!isEdit}
                          placeholder={!isEdit ? '' : 'Select...'}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={4} className="required text-left">
                        First Name
                      </Label>
                      <Col sm={8}>
                        <Input
                          type="text"
                          name="first_name"
                          placeholder={isEdit && 'Ex: John'}
                          value={initialValues.first_name}
                          onChange={handleNameChange}
                          invalid={isFirstNameNotValid}
                          required
                          disabled={!isEdit}
                        />
                        {isFirstNameNotValid && <div className="pt-1 text-danger">{firstNameErrorMessage}</div>}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={4} className="text-left">
                        Last Name
                      </Label>
                      <Col sm={8}>
                        <Input
                          type="text"
                          name="last_name"
                          placeholder={isEdit && 'Ex: Doe'}
                          value={initialValues.last_name}
                          onChange={handleNameChange}
                          invalid={isLastNameNotValid}
                          disabled={!isEdit}
                        />
                        {isLastNameNotValid && <div className="pt-1 text-danger">{lastNameErrorMessage}</div>}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={4} className="required text-left">
                        Email
                      </Label>
                      <Col sm={8}>
                        <Input
                          type="text"
                          name="email"
                          placeholder={isEdit && 'Ex: johndoe@otoklix.com'}
                          value={initialValues.email}
                          onChange={handleEmailChange}
                          invalid={isEmailInvalid}
                          autoComplete={false}
                          required
                          disabled={!isEdit}
                        />
                        {isEmailInvalid && <div className="pt-1 text-danger">{emailErrorMessage}</div>}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={4} className="required text-left">
                        Phone Number
                      </Label>
                      <Col sm={8}>
                        <Input
                          type="text"
                          name="phonenumber"
                          placeholder={isEdit && 'Ex: +628111222333'}
                          value={initialValues.phone_number}
                          onChange={changePhoneNumber}
                          invalid={isPhoneNumberInvalid}
                          required
                          disabled={!isEdit}
                        />
                        {isPhoneNumberInvalid && <div className="pt-1 text-danger">{phoneNumberErrorMessage}</div>}
                      </Col>
                    </FormGroup>
                    <div className="d-flex justify-content-center m-auto">
                      <Button
                        color="primary"
                        size="lg"
                        className="mt-3 px-4 rounded-pill"
                        disabled={isEdit ? isButtonDisabled : false}
                        onClick={isEdit ? handleSubmit : handleEdit}
                      >
                        {isEdit ? 'Save' : 'Edit'}
                      </Button>
                      {isEdit && (
                        <Button
                          color="danger"
                          size="lg"
                          className="mt-3 ml-4 rounded-pill"
                          onClick={() => setIsEdit(false)}
                        >
                          Cancel
                        </Button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Wrapper>
      </React.Fragment>
    );
  };

  return renderContent();
}

export default EditUser;
