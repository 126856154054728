import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Label } from 'reactstrap';
import API from '@utils/API';
import InputField from '@components/field/InputField';

const BrandAdd = (props) => {
  const { modal, toggle, onSubmit } = props;

  const api = new API('v2');
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [errorBrand, setErrorBrand] = useState('');

  const handleChangeBrand = (e) => {
    const valueBrand = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : brandName;
    validateBrandName(valueBrand);
  };

  const validateBrandName = (value) => {
    let passValidate = true;

    if (value.length == 0) {
      passValidate = false;
      setErrorBrand('Nama Brand tidak boleh kosong');
    } else if (value.length > 100) {
      passValidate = false;
      setErrorBrand('Format Nama Brand maksimal 100 karakter');
    } else {
      setErrorBrand('');
    }

    setBrandName(value);
    return passValidate;
  };

  const handleSubmit = () => {
    const passValidateBrandName = validateBrandName(brandName);

    if (passValidateBrandName) {
      submitForm();
    }
  };

  async function submitForm() {
    toggle();
    setLoading(true);

    try {
      const payload = {
        brand_name: brandName ?? '',
      };
      await api.post(`v2/intools/master/product-brands/`, payload);
      onSubmit(true);
      setLoading(false);
    } catch (error) {
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  const resetData = () => {
    setBrandName('');
    setErrorBrand('');
  };

  const cancel = () => {
    toggle();
  };

  useEffect(() => {
    resetData();
  }, [modal]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Tambah Data Brand</div>
        <div className="content">
          <FormGroup row className="mb-0">
            <Label lg={4} className="label-required">
              Nama Brand
            </Label>
            <InputField
              colWidth={8}
              inputType={'text'}
              disabled={loading}
              placeholder="Nama Brand"
              value={brandName ?? ''}
              invalid={errorBrand ? true : false}
              errorMessage={errorBrand ?? ''}
              onChange={handleChangeBrand}
            />
          </FormGroup>
        </div>

        <div className="action mb-3 mt-3">
          <Button className="button-cancel" onClick={cancel} disabled={loading}>
            Batal
          </Button>
          <Button className="button-action" onClick={handleSubmit} disabled={loading}>
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BrandAdd;
