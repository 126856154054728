import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Col } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import DropdownCreate from '@components/field/DropdownCreate';
import CreateCarBrandModal from '@components/modal/CreateCarBrandModal';
import API from '@utils/API';
import { find } from 'lodash';

const CarBrandName = (props) => {
  const apiv2 = new API('v2');
  const { onChangeBrand, initialValue, invalid } = props;

  const [brandSelectedValue, setBrandSelectedValue] = useState(null);
  const [defaultBrandOptions, setDefaultBrandOptions] = useState([]);
  const [modalCreateBrand, setModalCreateBrand] = useState(false);

  useEffect(() => {
    fetchBrandOptions();
  }, []);

  useEffect(() => {
    if (initialValue && defaultBrandOptions) {
      const select = find(defaultBrandOptions, { name: initialValue });

      handleBrandChange(select);
    }
  }, [initialValue, defaultBrandOptions]);

  const loadBrandSearch = (brand) => {
    if (brand) {
      return loadCarBrand(brand);
    }
  };

  const fetchBrandOptions = () => {
    loadCarBrand().then((response) => {
      setDefaultBrandOptions(response);
    });
  };

  const loadCarBrand = (brandName = '') => {
    return apiv2.get(`v2/intools/brands/?brand_name=${brandName}`).then((response) => {
      return response.data.data;
    });
  };

  const saveCurrentBrand = (brand) => {
    handleBrandChange(brand);
    setModalCreateBrand(false);
    fetchBrandOptions();
  };

  const handleBrandChange = (value) => {
    setBrandSelectedValue(value);
    onChangeBrand(value);
  };

  return (
    <>
      <FormGroup row>
        <Label lg={3} className="label-required">
          Brand Name
        </Label>
        <Col lg={9}>
          <AsyncSelect
            cacheOptions
            placeholder="Pilih..."
            value={brandSelectedValue}
            getOptionLabel={(e) => `${e.name}`}
            getOptionValue={(e) => e.id}
            loadOptions={loadBrandSearch}
            defaultOptions={defaultBrandOptions}
            onChange={handleBrandChange}
            components={{
              MenuList: (props) => (
                <DropdownCreate
                  onCreateClick={() => setModalCreateBrand(!modalCreateBrand)}
                  textLabel="Brand Baru"
                  {...props}
                />
              ),
            }}
            rules={{ required: true }}
          />

          {invalid && <span className="text-danger">Brand is mandatory</span>}
        </Col>
      </FormGroup>

      <CreateCarBrandModal
        modal={modalCreateBrand}
        toggle={() => setModalCreateBrand(!modalCreateBrand)}
        onSuccess={saveCurrentBrand}
      />
    </>
  );
};

export default CarBrandName;
