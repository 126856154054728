import axios from 'axios';
import Cookies from 'universal-cookie';
import { store, persistor } from '@store/configureStore';
import { resetAuth } from '@reducers/Auth';
import config from '@utils/config';

const source = axios.CancelToken.source();

const getClient = (apiVersion = 'v1') => {
  let options;
  if (apiVersion === 'search') {
    options = {
      baseURL: config.api_search,
    };
  } else {
    options = {
      baseURL: apiVersion === 'v2' ? config.api_url_v2 : config.api_url,
    };
  }

  const client = axios.create(options);

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.data?.error?.message === 'Unauthorized Access!') {
        window.location.href = '/404';
      }

      if (error.response.data.detail === 'Invalid token.') {
        const cookies = new Cookies();
        cookies.remove('intools_tok_c');
        cookies.remove('intools_jwt_token');

        persistor.purge();
        store.dispatch(resetAuth());
      }

      return Promise.reject(error);
    }
  );

  client.interceptors.request.use(
    async (config) => {
      const cookies = new Cookies();

      if (apiVersion === 'v1') {
        const tokenKey = cookies.get('intools_tok_c');

        if (tokenKey) {
          config.headers = {
            Authorization: `Token ${tokenKey}`,
          };
        }
      }

      if (apiVersion === 'v2' || apiVersion === 'search') {
        const tokenKey = cookies.get('intools_jwt_token');

        if (tokenKey) {
          config.headers = {
            Authorization: `Bearer ${tokenKey}`,
          };
        }
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  return client;
};

export default class API {
  constructor(apiVersion = 'v1') {
    this.client = getClient(apiVersion);
  }

  get(path, options = {}) {
    return this.client.get(path, options);
  }

  post(path, data = {}, options = {}) {
    return this.client.post(path, data, options);
  }

  put(path, data = {}, options = {}) {
    return this.client.put(path, data, options);
  }

  patch(path, data = {}, options = {}) {
    return this.client.patch(path, data, options);
  }

  delete(path, options = {}) {
    return this.client.delete(path, options);
  }

  cancelToken() {
    return source;
  }

  cancel() {
    source.cancel();
  }
}
