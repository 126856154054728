import React, { useState, useEffect, forwardRef } from 'react';
import { useParams, useHistory } from 'react-router';
import API from '@utils/API';
import moment from 'moment';
import { Button, FormFeedback, Row, Card, CardBody, Col, FormGroup, Input, Label } from 'reactstrap';

import Wrapper from '@components/wrapper/Wrapper';
import PromoBanner from '@components/voucherify/PromoBanner';
import PromoType from '@components/voucherify/PromoType';
import ActionSwitch from '@components/field/ActionSwitch';
import Divider from '@components/divider/Divider';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import userPermission from '@utils/userPermission';
import DatePicker from 'react-datepicker';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';

function PromoDetail() {
  const initForm = {
    is_active: true,
    is_public: true,
    is_show_list: true,
    is_banner: true,
    promo_name: 'Promo Oli Gratis',
    s_k: 'data',
    start_date: '2022-08-12T07:00:00+07:00',
    end_date: '2022-08-19T07:00:00+07:00',
    short_description: 'description description description',
    seo_title: 'Promo Oli Gratis Hanya Hari Ini',
    seo_description: 'Anda bisa ganti oli gratis Toyota dengan Program Sehat Toyotaku.',
    image_link:
      'https://st2.depositphotos.com/1003476/5929/i/950/depositphotos_59294607-stock-photo-hand-holding-christmas-voucher-isolated.jpg',
  };
  const history = useHistory();
  const api = new API('v2');
  const { generatePermission } = userPermission();

  const { promo_code } = useParams();
  const [formValues, setFormValues] = useState({});
  const [previousValues, setPreviousValues] = useState({});
  const [keyImage, setKeyImage] = useState();
  const [disableForm, setDisableForm] = useState(true);
  const [disableGlobal, setDisableGlobal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [packages, setPackages] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [date, setDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});
  const [errorUpload, setErrorUpload] = useState('');
  const [errorBanner, setErrorBanner] = useState(false);
  const [errorPromoLink, setErrorPromoLink] = useState(false);
  const [errorPromoName, setErrorPromoName] = useState(false);
  const [groupOptions, setGroupOptions] = useState({});
  const [bannerOptions, setBannerOptions] = useState({});
  const url = `v2/intools/promos/${promo_code}/`;

  const InputStart = forwardRef(({ value, onClick }, ref) => (
    <InputField
      colWidth={12}
      inputType={'text'}
      value={value}
      invalid={!formValues?.start_date || !formValues?.end_date || formValues?.start_date > formValues?.end_date}
      placeholder={'Start Date'}
      onClick={onClick}
      disabled={disableForm || disableGlobal}
    />
  ));
  const InputEnd = forwardRef(({ value, onClick }, ref) => (
    <InputField
      colWidth={12}
      inputType={'text'}
      value={value}
      invalid={!formValues?.start_date || !formValues?.end_date || formValues?.start_date > formValues?.end_date}
      placeholder={'End Date'}
      onClick={onClick}
      disabled={disableForm || disableGlobal}
    />
  ));

  useEffect(() => {
    fetchPromoDetail();
  }, []);

  useEffect(() => {
    loadGroupOptions();
  }, []);

  useEffect(() => {
    loadBannerOptions();
  }, []);

  useEffect(() => {
    loadPackages();
  }, []);

  const breadcrumb = [
    {
      id: 1,
      label: 'List Promo',
      active: false,
      url: '/promos',
    },
    {
      id: 2,
      label: 'Detail Promo',
      active: false,
      url: '',
    },
    {
      id: 2,
      label: promo_code,
      active: true,
      url: '',
    },
  ];

  async function fetchPromoDetail() {
    setLoading(true);

    try {
      const response = await api.get(url);
      if (response?.data?.data) {
        setFormValues(response?.data?.data);
        setPreviousValues(response?.data?.data);
      } else {
        setFormValues({});
        setPreviousValues({});
        setDisableForm(true);
        setDisableButton(true);
      }
      setLoading(false);
    } catch (error) {
      handleResultNotification(true, 'fail', error.message);
      setFormValues({});
      setPreviousValues({});
      setDisableForm(true);
      setDisableButton(true);
      setLoading(false);
    }
  }

  const loadGroupOptions = () => {
    return api
      .get(`v2/intools/promos/categories/master/`)
      .then((response) => {
        setGroupOptions(response.data.data ?? []);
      })
      .catch((error) => {
        setGroupOptions([]);
      });
  };

  const loadBannerOptions = () => {
    return api
      .get(`v2/intools/promos/entry-points/master/`)
      .then((response) => {
        setBannerOptions(response.data.data ?? []);
      })
      .catch((error) => {
        setBannerOptions([]);
      });
  };

  const loadPackages = () => {
    return api
      .get(`v2/intools/promos/${promo_code}/packages/`)
      .then((response) => {
        setPackages(response.data.data ?? []);
      })
      .catch((error) => {});
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });

    if (name == 'link_promo' && value?.length > 0) {
      setErrorPromoLink(false);
    }
  };

  const handleChangeImage = (data, key) => {
    setFormValues({ ...formValues, image_link: data[0] });
    setKeyImage(key);
  };

  const handleSelectChange = (name, event) => {
    setFormValues({ ...formValues, [name]: event });
  };

  const handleBannerTypeChange = (event) => {
    setFormValues({ ...formValues, banner_type: event });
    setErrorBanner(false);
  };

  const openPromoList = () => {
    history.push(`/promos`);
  };

  const submitEditPromo = () => {
    setDisableButton(true);
    setDisableGlobal(true);

    const startDateFormatted = moment(formValues?.start_date).format('YYYY-MM-DD');
    const endDateFormatted = moment(formValues?.end_date).format('YYYY-MM-DD');

    const payload = {
      promo_category: formValues?.group?.slug ?? null,
      public: formValues?.is_public,
      show_list: formValues?.is_show_list,
      banner: formValues?.is_banner,
      featured: formValues?.is_featured,
      banner_type: formValues?.banner_type?.slug ?? null,
      link_promo: formValues?.link_promo ?? null,
      description: formValues?.short_description ?? null,
      banner_photo: keyImage ?? formValues?.image_link,
      snk: formValues?.snk ?? null,
      seo_title: formValues?.seo_title ?? null,
      seo_description: formValues?.seo_description ?? null,
      start_date: startDateFormatted,
      end_date: endDateFormatted,
      promo_name: formValues?.promo_name,
      is_active: formValues?.is_active,
    };

    api
      .put(url, payload, {
        headers: {
          'Content-Type': 'application/JSON',
        },
      })
      .then((response) => {
        setFormValues(response?.data?.data);
        setPreviousValues(response?.data?.data);
        handleResultNotification(true, 'success', 'Berhasil update promo');
        setTimeout(() => {
          openPromoList();
          setDisableButton(false);
          setDisableGlobal(false);
        }, 3000);
      })
      .catch((error) => {
        setDisableButton(false);
        setDisableGlobal(false);
        handleResultNotification(true, 'fail', 'Gagal update promo');
      });
  };

  const handleAction = (type) => {
    if (isEdit) {
      if (type == 'cancel') {
        setFormValues(previousValues);
        setDisableForm(true);
        setIsEdit(false);
        setErrorUpload('');
        setErrorPromoName();
        // handleResultNotification(false);
      } else {
        handleSubmitForm();
      }
    } else {
      setIsEdit(true);
      setDisableForm(false);
    }
  };

  const handleSubmitForm = () => {
    const isValid = validateForm();
    if (isValid) {
      submitEditPromo();
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (formValues?.is_banner) {
      if (!formValues?.banner_type?.slug) {
        setErrorBanner(true);
        isValid = false;
      } else if (formValues?.banner_type?.slug == 'deeplink') {
        if (!formValues?.link_promo) {
          setErrorPromoLink(true);
          isValid = false;
        }
      }
    }
    if (!formValues?.promo_name) {
      setErrorPromoName(true);
      isValid = false;
    }
    if (!formValues?.start_date || !formValues?.end_date) {
      isValid = false;
    }
    if (formValues?.start_date && formValues?.end_date) {
      if (formValues?.start_date > formValues?.end_date) {
        isValid = false;
      }
    }

    return isValid;
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const onResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const color = () => {
    if (formValues?.is_active == 1) {
      return 'green';
    } else {
      return 'red';
    }
  };

  const customStyles = {
    color: color(),
  };

  const renderContent = () => {
    return (
      <React.Fragment>
        <Wrapper
          title="Detail Promo"
          customContentClassName="wrapper-content"
          customTitleClassName="wrapper-title"
          breadcrumbData={breadcrumb}
          showNotification={hasNotif}
          notification={notification}
          onCloseNotification={handleCloseNotification}
        >
          <Row className="mt-2">
            <Col sm={12}>
              {loading && <LoadingSpinner></LoadingSpinner>}
              {!loading && (
                <Card className="card-custom">
                  <CardBody className="p-0">
                    <Divider title="Detail Promo" />
                    <Row>
                      <Col sm={12} md={6}>
                        {disableForm && (
                          <Row className="mb-3">
                            <Label lg={4} className="text-left">
                              Status
                            </Label>
                            <Label lg={8} className="text-left text-bold">
                              :{' '}
                              <span className="ml-1" style={customStyles}>
                                {formValues?.is_active ? 'Aktif' : 'Tidak Aktif'}
                              </span>
                            </Label>
                          </Row>
                        )}
                        {!disableForm && (
                          <ActionSwitch
                            labelWidth={4}
                            colWidth={8}
                            title="Status"
                            inputName="is_active"
                            isChecked={formValues?.is_active}
                            customClass="mt-0"
                            customClassLabel="text-left"
                            editableForm={disableForm || disableGlobal}
                            labelChecked="Aktif"
                            labelUnchecked="Tidak Aktif"
                            onChange={() =>
                              setFormValues({ ...formValues, is_active: formValues?.is_active == 1 ? false : true })
                            }
                            color={'secondary'}
                          />
                        )}
                      </Col>
                      <Col sm={12} md={6}>
                        {disableForm && (
                          <Row className="mb-3">
                            <Label lg={4} className="text-left">
                              Nama Promo
                            </Label>
                            <Label lg={8} className="text-left text-bold">
                              : <span className="ml-1">{formValues?.promo_name ? formValues?.promo_name : '-'}</span>
                            </Label>
                          </Row>
                        )}
                        {!disableForm && (
                          <Row>
                            <Label lg={4} className="text-left label-required">
                              Nama Promo
                            </Label>
                            <InputField
                              colWidth={8}
                              inputType="text"
                              disabled={disableForm || disableGlobal}
                              value={formValues?.promo_name}
                              invalid={formValues?.promo_name ? false : true}
                              inputName={'promo_name'}
                              placeholder={'Description'}
                              errorMessage={'Field tidak boleh kosong'}
                              onChange={handleInputChange}
                            />
                          </Row>
                        )}
                      </Col>
                      <Col sm={12} md={6}>
                        {disableForm && (
                          <Row className="mb-3">
                            <Label lg={4} className="text-left">
                              Kelompok Promo
                            </Label>
                            <Label lg={8} className="text-left text-bold">
                              : <span className="ml-1">{formValues?.group ? formValues?.group?.name : '-'}</span>
                            </Label>
                          </Row>
                        )}
                        {!disableForm && (
                          <Row>
                            <Label lg={4} className="text-left">
                              Kelompok
                            </Label>
                            <SelectField
                              colWidth={8}
                              title="Kelompok Promo"
                              placeholder={'select group'}
                              disabled={disableForm || disableGlobal}
                              getOptionLabel={(e) => e.name}
                              getOptionValue={(e) => e.slug}
                              closeMenuOnSelect={true}
                              options={groupOptions}
                              value={formValues?.group}
                              onChange={(e) => handleSelectChange('group', e)}
                            />
                          </Row>
                        )}
                      </Col>
                      <Col sm={12} md={6}>
                        {disableForm && (
                          <Row className="mb-3">
                            <Label lg={4} className="text-left">
                              Periode Promo
                            </Label>
                            <Label lg={8} className="text-left text-bold">
                              :{' '}
                              <span className="ml-1">
                                {(formValues?.start_date
                                  ? moment(formValues?.start_date).format('DD MMMM YYYY')
                                  : '-') +
                                  ' s/d ' +
                                  (formValues?.end_date ? moment(formValues?.end_date).format('DD MMMM YYYY') : '-')}
                              </span>
                            </Label>
                          </Row>
                        )}
                        {!disableForm && (
                          <div>
                            <Row>
                              <Label lg={4} className="text-left label-required">
                                Periode Promo
                              </Label>
                              <Col lg={8}>
                                <Row className="input-date">
                                  <Col className="d-flex flex-column p-0">
                                    <DatePicker
                                      selected={formValues?.start_date ? new Date(formValues?.start_date) : ''}
                                      onChange={(e) => setFormValues({ ...formValues, start_date: e })}
                                      selectsStart
                                      startDate={new Date(formValues?.start_date)}
                                      endDate={new Date(formValues?.end_date)}
                                      dateFormat="dd MMMM yyyy"
                                      showYearDropdown
                                      customInput={<InputStart />}
                                    />
                                  </Col>
                                  <Label className="text-center mt-2">{'-'}</Label>
                                  <Col className="d-flex flex-column p-0">
                                    <DatePicker
                                      selected={formValues?.end_date ? new Date(formValues?.end_date) : ''}
                                      onChange={(e) => setFormValues({ ...formValues, end_date: e })}
                                      selectsEnd
                                      startDate={new Date(formValues?.start_date)}
                                      endDate={new Date(formValues?.end_date)}
                                      minDate={new Date(formValues?.start_date)}
                                      dateFormat="dd MMMM yyyy"
                                      showYearDropdown
                                      customInput={<InputEnd />}
                                    />
                                  </Col>
                                </Row>
                                {(!formValues?.start_date || !formValues?.end_date) && (
                                  <div className="invalid-hour">{'Field tidak boleh kosong'}</div>
                                )}
                                {formValues?.start_date > formValues?.end_date && (
                                  <div className="invalid-hour">{'Invalid date range'}</div>
                                )}
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Divider title="Customer App Interface" />
                    <Row>
                      <Col sm={12} md={12}>
                        <PromoType
                          disableSwitch={disableForm}
                          disableGlobal={disableGlobal}
                          isPublic={formValues?.is_public}
                          isShowList={formValues?.is_show_list}
                          isBanner={formValues?.is_banner}
                          isFeatured={formValues?.is_featured}
                          data={formValues}
                          packages={packages}
                          bannerOptions={bannerOptions}
                          handleBannerTypeChange={handleBannerTypeChange}
                          onChangeInput={handleInputChange}
                          onChangeSwitch={(name, value) => setFormValues({ ...formValues, [name]: value })}
                          errorBanner={errorBanner}
                          errorPromoLink={errorPromoLink}
                        />
                      </Col>
                      {!disableForm &&
                        formValues?.is_banner == true &&
                        formValues?.banner_type?.slug == 'landing-page' && (
                          <Col sm={12} md={12} className="mb-3">
                            <Row>
                              <Label lg={2} className="text-left">
                                Syarat dan Ketentuan
                              </Label>
                              <InputField
                                colWidth={10}
                                inputType="textarea"
                                disabled={disableForm || disableGlobal}
                                value={formValues?.snk}
                                name={'snk'}
                                onChange={handleInputChange}
                              />
                            </Row>
                          </Col>
                        )}
                      {disableForm && formValues?.is_banner == true && formValues?.banner_type?.slug == 'landing-page' && (
                        <Col sm={12} md={12}>
                          <Row className="mb-3">
                            <Label lg={2} className="text-left">
                              Syarat dan Ketentuan
                            </Label>
                            <Label lg={10} className="text-left d-flex">
                              :{' '}
                              <span className="ml-2" style={{ whiteSpace: 'pre-line' }}>
                                {formValues?.snk
                                  ? formValues?.snk
                                  : // .replace(/(<li>)/g, '')
                                    // .replace(/((<ul>|<ol>|<\/ul>|<\/ol>|<\/li>|<p>|<\/p>))/g, '')
                                    '-'}
                              </span>
                            </Label>
                          </Row>
                        </Col>
                      )}
                      {disableForm && (
                        <Col sm={12} md={12}>
                          <Row className="mb-3">
                            <Label lg={2} className="text-left">
                              Description
                            </Label>
                            <Label lg={10} className="text-left d-flex">
                              :
                              <span className="ml-2">
                                {formValues?.short_description ? formValues?.short_description : '-'}
                              </span>
                            </Label>
                          </Row>
                        </Col>
                      )}
                      {!disableForm && (
                        <Col sm={12} md={12}>
                          <Row>
                            <Label lg={2} className="text-left">
                              Description
                            </Label>
                            <InputField
                              colWidth={10}
                              inputType="textarea"
                              disabled={disableForm || disableGlobal}
                              value={formValues?.short_description}
                              name={'short_description'}
                              onChange={handleInputChange}
                            />
                          </Row>
                        </Col>
                      )}

                      <Col sm={12} md={12}>
                        <Row className="mb-3">
                          <Label lg={10} className="text-left text-bold">
                            <span className="ml-1">
                              <PromoBanner
                                image={formValues?.image_link}
                                promoCode={promo_code}
                                disableBrowse={disableForm}
                                disableGlobal={disableGlobal}
                                onUpload={handleChangeImage}
                                setErrorUpload={(data) => setErrorUpload(data)}
                                errorUpload={errorUpload}
                                onDelete={() => {
                                  setFormValues({ ...formValues, image_link: null });
                                  setKeyImage();
                                }}
                              />
                            </span>
                          </Label>
                        </Row>
                      </Col>
                    </Row>

                    {disableForm && formValues?.is_banner == true && formValues?.banner_type?.slug == 'landing-page' && (
                      <div>
                        <Divider title="SEO" />
                        <Row>
                          <Col sm={12} md={12}>
                            <Row className="mb-3">
                              <Label lg={2} className="text-left">
                                Meta Title
                              </Label>
                              <Label lg={10} className="text-left text-bold">
                                : <span className="ml-1">{formValues?.seo_title ? formValues?.seo_title : '-'}</span>
                              </Label>
                            </Row>
                          </Col>
                          <Col sm={12} md={12}>
                            <Row className="mb-3">
                              <Label lg={2} className="text-left">
                                Meta Description
                              </Label>
                              <Label lg={10} className="text-left d-flex">
                                :{' '}
                                <span className="ml-2">
                                  {formValues?.seo_description ? formValues?.seo_description : '-'}
                                </span>
                              </Label>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    )}
                    {!disableForm && formValues?.is_banner == true && formValues?.banner_type?.slug == 'landing-page' && (
                      <div>
                        <Divider title="SEO" />
                        <Row>
                          <Col sm={12} md={12}>
                            <Row className="mb-3">
                              <Label lg={2} className="text-left">
                                Meta Title
                              </Label>
                              <InputField
                                colWidth={10}
                                inputType="text"
                                disabled={disableForm || disableGlobal}
                                value={formValues?.seo_title}
                                name={'seo_title'}
                                onChange={handleInputChange}
                              />
                            </Row>
                            <Row>
                              <Label lg={2} className="text-left">
                                Meta Description
                              </Label>
                              <InputField
                                colWidth={10}
                                inputType="textarea"
                                disabled={disableForm || disableGlobal}
                                value={formValues?.seo_description}
                                name={'seo_description'}
                                onChange={handleInputChange}
                              />
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    )}
                    {generatePermission('promo', 'edit') && (
                      <Row>
                        <Col sm={12} md={12} className="section-action">
                          <Button
                            disabled={disableButton}
                            className="button-action save"
                            onClick={() => handleAction('save')}
                          >
                            {isEdit ? 'Simpan' : 'Edit Data'}
                          </Button>
                          {isEdit && (
                            <Button
                              disabled={disableButton}
                              className="button-action cancel"
                              onClick={() => handleAction('cancel')}
                            >
                              {'Batal'}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Wrapper>
      </React.Fragment>
    );
  };

  return renderContent();
}

export default PromoDetail;
