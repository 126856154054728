import React, { useState, useEffect, useMemo } from 'react';
import { Col, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { assign, find, isEmpty, map } from 'lodash';
import qs from 'query-string';
import userPermission from '@utils/userPermission';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import Loading from '@components/loading/Loading';
import DataTable from 'react-data-table-component';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import AgreementEdit from '@components/workshops/agreement/AgreementEdit';
import AgreementActive from '@components/workshops/agreement/AgreementActive';
import AgreementInactive from '@components/workshops/agreement/AgreementInactive';

const AgreementList = (props) => {
  const { id, allowShowDetail, allowEditDetail, handleResultNotification } = props;

  const api = new API('v2');
  const history = useHistory();
  const qp = qs.parse(history?.location?.search);

  const { generatePermission } = userPermission();
  const [data, setData] = useState([]);
  const [idAgreement, setIdAgreement] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [hasModalEditAgreement, setHasModalEditAgreement] = useState(false);
  const [hasModalActiveAgreement, setHasModalActiveAgreement] = useState(false);
  const [hasModalInactiveAgreement, setHasModalInactiveAgreement] = useState(false);
  const [enableEditStatus, setEnableEditStatus] = useState(false);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [componentOptions, setComponentOptions] = useState();

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        name: 'Status',
        selector: (row) =>
          row.is_active ? (
            <span className="badge-custom badge-custom-success">Aktif</span>
          ) : (
            <span className="badge-custom badge-custom-danger">Tidak Aktif</span>
          ),
        minWidth: '120px',
        center: true,
        sortable: true,
        sortField: 'status',
      },
      {
        name: 'ID',
        selector: (row) => row?.id ?? '-',
        minWidth: '120px',
        sortable: true,
        sortField: 'id',
      },
      {
        name: 'Component',
        selector: (row) => row?.service?.product_component?.name ?? '-',
        minWidth: '120px',
        sortable: true,
        sortField: 'component_name',
      },
      {
        name: 'Product/Service Name',
        minWidth: '455px',
        selector: (row) => row?.service?.service_name ?? '-',
        sortable: true,
        sortField: 'service_name',
        wrap: true,
      },
      {
        name: 'Display Name',
        minWidth: '455px',
        selector: (row) => row?.service?.display_name ?? '-',
        sortable: true,
        sortField: 'display_name',
        wrap: true,
      },
      {
        name: 'B2C Sell Price',
        minWidth: '150px',
        selector: (row) => `Rp. ${Helper.formatMoney(row?.sell_price)}`,
        sortable: true,
        sortField: 'sell_price',
        wrap: true,
      },
      {
        name: 'B2B Sell Price',
        minWidth: '150px',
        selector: (row) => `Rp. ${Helper.formatMoney(row?.base_price)}`,
        sortable: true,
        sortField: 'base_price',
        wrap: true,
      },
      {
        name: 'Service Fee',
        selector: (row) => `Rp. ${Helper.formatMoney(row?.service?.service_fee)}`,
        minWidth: '150px',
        sortable: true,
        sortField: 'service_fee',
      },
      {
        name: 'Agreed Service Fee',
        selector: (row) => (
          <>
            {' '}
            <span className="product-price">{`Rp. ${Helper.formatMoney(row?.agreed_service_fee)}`}</span>
            {Number(row?.service?.service_fee) && Number(row?.agreed_service_fee) ? (
              Number(row?.service?.service_fee) == Number(row?.agreed_service_fee) ? (
                <span className="ml-2 badge-custom badge-custom-primary">Default</span>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </>
        ),
        minWidth: '250px',
        sortable: true,
        sortField: 'agreed_service_fee',
        wrap: true,
      },
    ],
    [resetSorting]
  );

  const statusOptions = [
    { value: '', label: 'Semua' },
    { value: true, label: 'Aktif' },
    { value: false, label: 'Tidak Aktif' },
  ];

  const dataFilter = [
    {
      id: 'filter_text_search',
      type: 'text',
      name: 'search',
      name_field: 'search',
      value: '',
      placeholder: 'Cari Product Name',
    },
    {
      id: 'filter_text_display_search',
      type: 'text',
      name: 'display_search',
      name_field: 'display_search',
      value: '',
      placeholder: 'Cari Display Name',
    },
    {
      id: 'filter_select_component',
      type: 'select',
      name: 'component',
      name_field: 'component',
      options: componentOptions,
      value: '',
      placeholder: 'Component',
    },
    {
      id: 'filter_select_status',
      type: 'select',
      name: 'status',
      name_field: 'status',
      options: statusOptions,
      value: '',
      placeholder: 'Status',
    },
    {
      id: 'filter_range_price_service',
      type: 'range_price',
      name_from: 'price_service_from',
      name_to: 'price_service_to',
      name_field: 'price',
      value: {},
      placeholder_main: 'Rp Price',
      placeholder_from: 'Rp harga dari',
      placeholder_to: 'Rp harga sampai',
    },
  ];

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchAgreement() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/workshops/${id}/otoklix-service-fees`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      const msg = error?.response?.data?.error?.message ?? '';
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleResultNotification(true, 'fail', 'Gagal mendapatakan list Service Fee ' + msg);
    }
  }

  const getParamData = () => {
    let params = {
      search: filtered?.search ?? '',
      display_search: filtered?.display_search ?? '',
      active: filtered?.status?.value ?? '',
      component: filtered?.component?.value ?? '',
      page: page,
      limit: pageLimit,
    };

    if (filtered?.price?.price_from && filtered?.price?.price_to) {
      params.price_from = filtered?.price?.price_from?.formatMoney;
      params.price_to = filtered?.price?.price_to?.formatMoney;
    }

    if (sortingData) {
      params.sort = sortingData?.sort;
      params.order = sortingData?.order;
    }

    return params;
  };

  async function fetchComponentOptions() {
    let listComponent = [{ value: '', label: 'Semua' }];
    try {
      const response = await api.get(`v2/intools/packages/components`);
      if (response?.data?.data?.components) {
        let resultListComponent = map(response?.data?.data?.components, (item) => {
          return { value: item?.id, label: item?.name ?? '-' };
        });
        resultListComponent.splice(0, 0, { value: '', label: 'Semua' });
        listComponent = resultListComponent;
      }

      setComponentOptions(listComponent);
    } catch (error) {
      setComponentOptions(listComponent);
    }
  }

  const handleChangeSorting = (column, sortDirection) => {
    setSortingData({
      sort: column?.sortField ?? '',
      order: sortDirection ?? '',
    });
  };

  const handleApplyFilters = async (params) => {
    await setFiltered(params);
    if (!isEmpty(qp)) {
      const payload = {
        ...qp,
        search: params?.search,
        display_search: params?.display_search,
        status: params?.status?.value,
        component: params?.component?.value,
        price_from: params?.price?.price_from?.formatMoney,
        price_to: params?.price?.price_to?.formatMoney,
      };

      history.push({ search: qs.stringify(payload) });
    }
  };

  const handleResetFilters = () => {
    setFilters(dataFilter);
    setFiltered({});
    delete qp.search;
    delete qp.display_search;
    delete qp.status;
    delete qp.component;
    delete qp.price_from;
    delete qp.price_to;
    history.push({ search: qs.stringify(qp) });
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleChangeSelectedRow = (selected) => {
    setSelectedData(selected?.selectedRows ?? []);
  };

  const handleEditAgreement = (row) => {
    if (allowShowDetail) {
      setIdAgreement(row?.id);
      setHasModalEditAgreement(true);
    }
  };

  const handleActiveAgreement = () => {
    setHasModalActiveAgreement(true);
  };

  const handleInactiveAgreement = () => {
    setHasModalInactiveAgreement(true);
  };

  const handleSubmitEditAgreement = (status, error) => {
    const errorMsg = error ? 'Gagal Edit Service Fee, ' + error : 'Gagal Edit Service Fee';
    if (status) {
      resetAgreement();
    }
    setHasModalEditAgreement(false);
    handleResultNotification(true, status ? 'success' : 'fail', status ? 'Berhasil Edit Service Fee' : errorMsg);
  };

  const handleSubmitEditActiveAgreement = (status) => {
    if (status) {
      resetAgreement();
    }
    setHasModalActiveAgreement(false);
    handleResultNotification(
      true,
      status ? 'success' : 'fail',
      status ? 'Berhasil Aktifkan Service Fee' : 'Gagal Aktifkan Service Fee'
    );
  };

  const handleSubmitEditInactiveAgreement = (status) => {
    if (status) {
      resetAgreement();
    }
    setHasModalInactiveAgreement(false);
    handleResultNotification(
      true,
      status ? 'success' : 'fail',
      status ? 'Berhasil Matikan Service Fee' : 'Gagal Matikan Service Fee'
    );
  };

  const handleChangeComponentOptions = () => {
    if (filters.length >= 3) {
      filters[2].options = componentOptions;
      setFilters([...filters]);
    }
    setDefaultFilters();
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodFetchAgreement();
  };

  const handleMethodFetchAgreement = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchAgreement();
    }
  };

  const resetAgreement = () => {
    setResetSorting(true);
    setSortingData({});
  };

  const toogleEditAgreement = () => setHasModalEditAgreement(!hasModalEditAgreement);
  const toogleActiveAgreement = () => setHasModalActiveAgreement(!hasModalActiveAgreement);
  const toogleInactiveAgreement = () => setHasModalInactiveAgreement(!hasModalInactiveAgreement);

  async function setDefaultFilters() {
    const withParams =
      qp?.component || qp?.search || qp?.display_search || qp?.price_from || qp?.price_to || qp?.component == ''
        ? true
        : false;
    const valueStatus =
      qp?.status == 'true' ? true : qp?.status == 'false' ? false : qp?.status == '' ? '' : withParams ? null : true;
    const valueComponent = qp?.component ? Number(qp?.component) ?? undefined : qp?.component == '' ? '' : undefined;
    const filteredSearch = qp?.search ?? '';
    const filteredDisplaySearch = qp?.display_search ?? '';
    const filteredStatus = find(statusOptions, { value: valueStatus });
    const filteredComponent = find(componentOptions, { value: valueComponent ?? {} });
    const filteredPrice =
      Number(qp?.price_from) && Number(qp?.price_to)
        ? {
            price_from: {
              formatIsMoney: true,
              formatMoney: parseInt(qp?.price_from),
              formatMoneyString: `Rp ${qp?.price_from ? Helper.numberWithCommas(qp?.price_from) : '-'}`,
            },
            price_to: {
              formatIsMoney: true,
              formatMoney: parseInt(qp?.price_to),
              formatMoneyString: `Rp ${qp?.price_to ? Helper.numberWithCommas(qp?.price_to) : '-'}`,
            },
          }
        : {};

    let paramsFiltered = {};
    if (qp?.search) await assign(paramsFiltered, { search: filteredSearch });
    if (qp?.display_search) await assign(paramsFiltered, { display_search: filteredDisplaySearch });
    if (qp?.status || filteredStatus?.value) await assign(paramsFiltered, { status: filteredStatus });
    if (qp?.component) await assign(paramsFiltered, { component: filteredComponent });
    if (qp?.price_from && qp?.price_to) await assign(paramsFiltered, { price: filteredPrice });

    filters[0].value = filteredSearch;
    filters[1].value = filteredDisplaySearch;
    filters[2].value = filteredComponent;
    filters[3].value = filteredStatus;
    filters[4].value = filteredPrice;

    setFilters([...filters]);
    setFiltered(paramsFiltered);
  }

  useEffect(() => {
    if (page && pageLimit && !firstLoad) {
      fetchAgreement();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    setEnableEditStatus(selectedData?.length !== 0 ? true : false);
  }, [selectedData]);

  useEffect(() => {
    if (componentOptions?.length) {
      handleChangeComponentOptions();
    }
  }, [componentOptions]);

  useEffect(() => {
    if (firstLoad && filtered) {
      fetchAgreement();
    } else {
      resetAgreement();
    }
  }, [filtered]);

  useEffect(() => {
    fetchComponentOptions();
    setFilters(dataFilter);
  }, []);

  return (
    <React.Fragment>
      <AgreementEdit
        idWorkshop={id}
        idAgreement={idAgreement}
        modal={hasModalEditAgreement}
        onSubmit={handleSubmitEditAgreement}
        toggle={toogleEditAgreement}
        allowEditDetail={allowEditDetail}
      />
      <AgreementActive
        workshopId={id}
        data={selectedData}
        modal={hasModalActiveAgreement}
        onSubmit={handleSubmitEditActiveAgreement}
        toggle={toogleActiveAgreement}
      ></AgreementActive>
      <AgreementInactive
        workshopId={id}
        data={selectedData}
        modal={hasModalInactiveAgreement}
        onSubmit={handleSubmitEditInactiveAgreement}
        toggle={toogleInactiveAgreement}
      ></AgreementInactive>
      <Col sm={12} md={12} className="p-0 mt-0 section-action">
        {allowEditDetail && (
          <Button disabled={!enableEditStatus} className="button-action inactive" onClick={handleInactiveAgreement}>
            {'Matikan'}
          </Button>
        )}
        {allowEditDetail && (
          <Button disabled={!enableEditStatus} className="button-action activate" onClick={handleActiveAgreement}>
            {'Aktif'}
          </Button>
        )}
      </Col>
      {filters?.length !== 0 && (
        <Col sm={12} md={12} className="p-0 mt-4">
          <ActionFilterCustom
            filters={filters}
            loading={loading}
            onApplyFilters={handleApplyFilters}
            onResetFilters={handleResetFilters}
          ></ActionFilterCustom>
        </Col>
      )}

      <Col sm={12} md={12} className="p-0 mt-4">
        <DataTable
          selectableRows={allowEditDetail ? true : false}
          clearSelectedRows={loading}
          onSelectedRowsChange={handleChangeSelectedRow}
          persistTableHead
          highlightOnHover
          sortServer
          noHeader={true}
          progressPending={loading}
          data={data}
          className="table dt-responsive table-custom"
          noDataComponent={noDataComponent}
          progressComponent={<Loading />}
          customStyles={customStylesHeaderTable}
          onSort={handleChangeSorting}
          onRowClicked={(row) => handleEditAgreement(row)}
          columns={columns}
        />
      </Col>
      <Col sm={12} md={12} className="p-0 mt-2">
        {data?.length >= 1 && !loading && (
          <CustomPagination
            page={page}
            pageLimit={pageLimit}
            totalRows={data?.length ?? 0}
            totalAllRows={totalRows}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
            handleChangeRowPerPage={handleChangeRowPerPage}
          ></CustomPagination>
        )}
      </Col>
    </React.Fragment>
  );
};

export default AgreementList;
