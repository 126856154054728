import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CloseIcon from '@assets/icons/close.svg';

const AddPermissionModal = (props) => {
  const { isOpen, toggle, onSubmit, initialValue, loading, onClosed } = props;

  const [forms, setForms] = useState({
    name: '',
    method: '',
    path: '',
  });
  const [formsError, setFormsError] = useState({
    nameError: '',
  });

  const handleChangeRoleName = (e) => {
    setForms({ ...forms, name: e.target.value });

    if (e.target.value.length < 1) {
      setFormsError({ ...formsError, nameError: true });
    } else {
      setFormsError({ ...formsError, nameError: false });
    }
  };

  const handleChangeMethod = (e) => {
    setForms({ ...forms, method: e.target.value });
  };

  const handleChangePath = (e) => {
    setForms({ ...forms, path: e.target.value });
  };

  const handleOnCloseModal = () => {
    setForms({
      name: '',
      method: '',
      path: '',
    });

    onClosed();
  };

  useEffect(() => {
    setForms({
      name: initialValue?.name ?? '',
      method: initialValue?.method ?? '',
      path: initialValue?.path ?? '',
    });
  }, [initialValue]);

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => toggle('close')}
      onClosed={handleOnCloseModal}
    >
      <ModalHeader
        className="d-flex align-items-center cursor-pointer"
        close={<img src={CloseIcon} alt="close-icon" onClick={() => toggle('close')} role="button" />}
      >
        {`${initialValue ? 'Edit' : 'Add New'} Permission`}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label sm={2} className="required text-left">
              Name
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="role_name"
                placeholder="Ex: Manager"
                value={forms.name}
                onChange={handleChangeRoleName}
                invalid={formsError?.nameError}
                required
              />
              {formsError?.nameError && <div className="pt-1 text-danger">Nama Role harus diisi!</div>}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2} className="text-left">
              Method
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="method"
                placeholder="Ex: GET, POST, PUT, DELETE"
                value={forms.method}
                onChange={handleChangeMethod}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2} className="text-left">
              Path
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="path"
                placeholder="Ex: /v2/intools/acl"
                value={forms.path}
                onChange={handleChangePath}
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="m-auto px-4 rounded-pill"
          size="lg"
          onClick={() => onSubmit(forms)}
          disabled={!forms.name || loading}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddPermissionModal;
