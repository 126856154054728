import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  ListGroup,
  ListGroupItem,
  InputGroupText,
  InputGroupAddon,
  Card,
  CardBody,
  InputGroup,
} from 'reactstrap';

const ChangeWorkshopModal = (props) => {
  const { modal, toggle, handleChangeWorkshop, dataWorkshop, handleSearchWorkshop } = props;

  const [val, setVal] = useState('');
  const [hideWorkshop, setHidelWorkshop] = useState(false);

  return (
    <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggle}>
      <ModalHeader>Silahkan Ganti Workshop</ModalHeader>
      <Card className="m-3">
        <ModalBody>
          <InputGroup>
            <Input
              placeholder=" Workshop ... "
              className="br-0 fixed-fc-height"
              value={val}
              onChange={(e) => {
                setVal(e.target.value);
                handleSearchWorkshop(e);
              }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText color="primary">Search</InputGroupText>
            </InputGroupAddon>
          </InputGroup>

          {dataWorkshop && dataWorkshop.length > 0 ? (
            <div title="Bengkel" className="fw-bold">
              {dataWorkshop.map((workshop, index) => {
                return (
                  <ListGroup key={workshop.name} onClick={() => setHidelWorkshop(true)}>
                    <ListGroupItem
                      tag="button"
                      action
                      onChange={handleSearchWorkshop}
                      onClick={handleSearchWorkshop}
                      value={workshop.name}
                    >
                      {workshop.name}
                    </ListGroupItem>
                  </ListGroup>
                );
              })}
            </div>
          ) : null}
        </ModalBody>

        {hideWorkshop ? (
          <CardBody className="p-0" title="Detail Workshop">
            {dataWorkshop && dataWorkshop.length > 0 ? (
              <div className="fw-bold">
                {dataWorkshop.map((workshop, index) => {
                  return (
                    <ListGroup key={workshop.name}>
                      <ListGroupItem>
                        <h5>Detail Workshop</h5>
                        <div className="d-flex justify-content-beetwen">
                          <img className="mr-3 rounded" width="18%" src={workshop.image_link} alt="" />
                          <h5>{`${workshop.name} - ${workshop.kecamatan ? workshop.kecamatan : ''}`}</h5>
                        </div>
                      </ListGroupItem>
                    </ListGroup>
                  );
                })}
              </div>
            ) : null}
          </CardBody>
        ) : null}
      </Card>

      <ModalFooter>
        <Button color="primary" onClick={handleChangeWorkshop}>
          confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default ChangeWorkshopModal;
