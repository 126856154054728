import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Row } from 'reactstrap';
import InfoIcon from '@assets/icons/info.svg';

const WorkshopCard = (props) => {
  const {
    data,
    handleChangeWorkshop,
    isPUDO,
    showCheckBox,
    handleMultipleWorkshop,
    selectWorkshops = [],
    customerType = 'b2c',
  } = props;

  const hasErrorPUDO = isPUDO && !data?.pudo_tag;
  const hasErrorAllProductExist = !data?.all_product_exist;
  const hasErrorWorkshopClosed = data?.operational_time?.is_closed;
  const disableRow = hasErrorPUDO || data?.operational_time?.is_closed;

  const [isSelected, setIsSelected] = useState(selectWorkshops.some((workshop) => workshop.id === data.id));
  const isMaxSelectionReached = selectWorkshops.length >= 5;

  useEffect(() => {
    if (selectWorkshops.length > 0) {
      setIsSelected(selectWorkshops.some((workshop) => workshop.id === data.id));
    }
  }, [selectWorkshops, data.id]);

  const isRowDisabled = disableRow || (isMaxSelectionReached && !isSelected);

  const updateSelection = () => {
    const updatedWorkshops = isSelected
      ? selectWorkshops.filter((workshop) => workshop.id !== data.id)
      : [...selectWorkshops, data];

    if (typeof handleMultipleWorkshop === 'function') {
      handleMultipleWorkshop(updatedWorkshops);
    } else {
      console.error('handleMultipleWorkshop is not a function');
    }
  };

  const handleClickCard = () => {
    if (isRowDisabled) return;

    if (customerType === 'b2b') {
      updateSelection();
      setIsSelected(!isSelected);
    } else {
      if (handleChangeWorkshop) {
        handleChangeWorkshop(data);
      }
    }
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation(); // Prevent checkbox change from triggering row click

    if (isRowDisabled) return;

    if (customerType === 'b2b') {
      updateSelection();
      setIsSelected(!isSelected);
    } else {
      if (handleChangeWorkshop) {
        handleChangeWorkshop(data);
      }
    }
  };

  return (
    <Row
      className={`workshop_card px-1 py-1 mb-2 pointer-cursor${isRowDisabled ? ' disabled' : ''}`}
      onClick={handleClickCard}
    >
      {customerType === 'b2b' && showCheckBox !== false && (
        <Col className="col-1">
          <div className="form-check float-right">
            <input
              className="form-check-input"
              type="checkbox"
              checked={isSelected}
              onChange={handleCheckboxChange}
              id={`defaultCheck${data.id}`}
              disabled={isRowDisabled}
            />
          </div>
        </Col>
      )}

      <Col className="col-11">
        <Row className="workshop_card__header">
          <Col>
            <span>{data?.name}</span>
            {data?.is_fulfilled_by_otoklix && <span className="tag">FBO</span>}
            {data?.is_otoklix_go && <span className="tag">PUDO</span>}
          </Col>
        </Row>
        <Row>
          <Col>
            <span>{data?.location?.street_address}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <span>
              Waktu Operasional:{' '}
              <span className={data?.operational_time?.is_closed ? 'text-danger' : ''}>
                {data?.operational_time?.text}
              </span>
              {data?.location?.distance_text ? (
                <>
                  {' '}
                  | Jarak: <span className="text-uppercase">{data?.location?.distance_text}</span>
                </>
              ) : null}
            </span>
          </Col>
        </Row>
        <Row className="w-100 mr-0">
          <Col className="pr-0">
            {hasErrorPUDO && (
              <Alert color="primary" className="d-flex align-items-center mt-2 w-100">
                <img src={InfoIcon} width={15} className="mr-1" />
                Lokasi customer di luar area PUDO bengkel.
              </Alert>
            )}
            {hasErrorAllProductExist && (
              <Alert color="primary" className="d-flex align-items-center mt-2 w-100">
                <img src={InfoIcon} width={15} className="mr-1" />
                Satu atau lebih produk/jasa yang Anda pilih tidak tersedia pada bengkel ini.
              </Alert>
            )}
            {data?.operational_time?.is_closed && (
              <Alert color="primary" className="d-flex align-items-center mt-2 w-100">
                <img src={InfoIcon} width={15} className="mr-1" />
                Maaf bengkel tutup, silahkan pilih bengkel lainnya!
              </Alert>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

WorkshopCard.propTypes = {
  data: PropTypes.object.isRequired,
  handleChangeWorkshop: PropTypes.func,
  isPUDO: PropTypes.bool,
  showCheckBox: PropTypes.bool,
  handleMultipleWorkshop: PropTypes.func.isRequired,
  selectWorkshops: PropTypes.array.isRequired,
  customerType: PropTypes.string.isRequired,
};

export default WorkshopCard;
