import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Col, Label } from 'reactstrap';
import API from '@utils/API';
import map from 'lodash/map';
import Helper from '@helpers/Helper';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import ActionSwitch from '@components/field/ActionSwitch';
import CreatableSelect from 'react-select/creatable';
import PlusFill from '@assets/icons/plus-fill.svg';
import { optionsType } from '@utils/Constants';

const InventoryAdd = (props) => {
  const { modal, toggle, idWorkshop, isFlagship, onSubmit, isGms } = props;

  const api = new API('v2');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [componentOptions, setComponentOptions] = useState([]);
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState();
  const [component, setComponent] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [product, setProduct] = useState();
  const [productType, setProductType] = useState('');
  const [productPrice, setProductPrice] = useState();
  const [nettPrice, setNettPrice] = useState();
  const [sellPrice, setSellPrice] = useState();
  const [takeRate, setTakeRate] = useState();
  const [useTakeRate, setUseTakeRate] = useState(false);
  const [disableTakeRate, setDisableTakeRate] = useState(true);
  const [errorBrand, setErrorBrand] = useState('');
  const [errorComponent, setErrorComponent] = useState('');
  const [errorCategory, setErrorCategory] = useState('');
  const [errorDisplayName, setErrorDisplayName] = useState('');
  const [errorProduct, setErrorProduct] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingFetchProduct, setLoadingFetchProduct] = useState(false);
  const [productTakeRate, setProductTakeRate] = useState();
  const [serviceTakeRate, setServiceTakeRate] = useState();
  const [loadingFetchMasterTakeRate, setLoadingMasterTakeRate] = useState(false);
  const [existingProduct, setExistingProduct] = useState(false);

  async function fetchMasterTakeRate() {
    setLoadingMasterTakeRate(true);
    try {
      const response = await api.get(`v2/intools/workshops/${idWorkshop}/agreement/`);
      const takeRateProduct = response?.data?.data?.product_take_rate;
      const takeRateService = response?.data?.data?.service_take_rate;
      setProductTakeRate(takeRateProduct == null || takeRateProduct == undefined ? null : takeRateProduct);
      setServiceTakeRate(takeRateService == null || takeRateService == undefined ? null : takeRateService);
      setLoadingMasterTakeRate(false);
    } catch (error) {
      setProductTakeRate(null);
      setServiceTakeRate(null);
      setLoadingMasterTakeRate(false);
    }
  }

  async function fetchMasterDataCategory() {
    try {
      const response = await api.get(`v2/intools/products/categories`);
      mappingMasterCategory(response?.data?.data?.categories ?? []);
    } catch (error) {
      mappingMasterCategory([]);
    }
  }

  async function fetchBrandOptions() {
    try {
      const response = await api.get(`v2/intools/master/product-brands/all/?sort=name&order=asc`);
      const optionsBrand = map(response?.data?.data, (item) => {
        return { value: item?.brand_id, label: item?.brand_name ?? '-' };
      });
      setBrandOptions(optionsBrand);
    } catch (error) {
      setBrandOptions([]);
    }
  }

  async function fetchComponentOptions() {
    try {
      const response = await api.get(`v2/intools/packages/components`);
      const optionsComponent = map(response?.data?.data?.components, (item) => {
        return { value: item?.id, label: item?.name ?? '-' };
      });
      setComponentOptions(optionsComponent);
    } catch (error) {
      setComponentOptions([]);
    }
  }

  async function fetchMasterDataProduct() {
    const url = `v2/intools/products/all?type=${productType?.value}`;
    setLoadingFetchProduct(true);
    try {
      const response = await api.get(url);
      mappingMasterProductData(response?.data?.data?.products ?? []);
      setLoadingFetchProduct(false);
    } catch (error) {
      mappingMasterProductData([]);
      setLoadingFetchProduct(false);
    }
  }

  async function fetchDetailProduct(productId) {
    const url = `v2/intools/master/products/${productId}/`;
    await api
      .get(url)
      .then(async (res) => {
        const data = res?.data?.data;
        mappingDetailProduct(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const mappingDetailProduct = (data) => {
    setExistingProduct(true);
    const selectedCategory = { value: data?.product_category_id, label: data?.product_category_name };
    handleChangeCategory(selectedCategory);
    const selectedComponent = { value: data?.product_component?.id, label: data?.product_component?.name };
    handleChangeComponent(selectedComponent);
    handleChangeDisplayName(data?.product_display_name);
  };

  const mappingMasterCategory = (data) => {
    let masterDataCategory = [];
    if (data.length !== 0) {
      map(data, (item) => {
        masterDataCategory.push({
          value: item?.id,
          label: item?.name,
        });
      });
    }
    setCategoryOptions(masterDataCategory);
    fetchMasterDataProduct();
  };

  const mappingMasterProductData = (data) => {
    let masterDataProduct = [];
    if (data.length !== 0) {
      map(data, (item) => {
        masterDataProduct.push({
          value: item?.id,
          label: item?.name,
          regular_price: item?.regular_price ?? 0,
          type: item?.type,
        });
      });
    }

    setProductOptions(masterDataProduct);
  };

  const handleChangeCategory = (e) => {
    setCategory(e ?? null);
    handleChangeProduct();
    setProductOptions([]);
    fetchMasterDataProduct();
    const valueCategory = e?.value ? e : null;
    validateCategory(valueCategory);
  };

  const handleChangeProduct = (e) => {
    setErrorProduct('');
    let takeRateProduct;
    if (e) {
      setProduct(e);
      setProductPrice(e?.regular_price ?? 0);
      takeRateProduct = e?.type == 'part' ? productTakeRate : e?.type == 'work' ? serviceTakeRate : null;
      setTakeRate(takeRateProduct);
      fetchDetailProduct(e?.value);
    }
    setNettPrice(Helper.formatMoneyOnField('' + 0, 'Rp '));
    setUseTakeRate(false);
    setDisableTakeRate(
      takeRateProduct !== null && takeRateProduct !== undefined && Number(takeRateProduct) >= 0 ? false : true
    );
  };

  const handleChangeUseTakeRate = (e) => {
    if (!loading && !disableTakeRate) {
      setUseTakeRate(e?.target?.checked ? true : false);
    }
  };

  const calculateNettPrice = () => {
    if (useTakeRate && takeRate !== null && takeRate !== undefined && Number(takeRate) >= 0) {
      const percentage_take_rate = Number(100 - takeRate) / 100;
      const nett_price = Math.round(percentage_take_rate * Number(sellPrice?.formatMoney ?? 0));

      setNettPrice(Helper.formatMoneyOnField('' + nett_price, 'Rp '));
    } else {
      setNettPrice(Helper.formatMoneyOnField('' + 0, 'Rp '));
    }
  };

  const handleSubmit = () => {
    if (product?.label) {
      setErrorProduct('');
      submitForm();
    } else {
      setErrorProduct('Produk harus diisi');
    }
  };

  async function submitForm() {
    toggle();
    setLoading(true);

    try {
      const payload = {
        product_id: product?.value ?? null,
        product_name: product?.name ?? null,
        product_display_name: displayName ?? null,
        product_price: productPrice ?? 0,
        product_brand_id: brand?.value ? `${brand?.value}` : null,
        product_category_id: category?.value ? `${category?.value}` : null,
        product_component_id: component?.value ? `${component?.value}` : null,
        product_type: product?.type ?? null,
        product_sku: null,
        product_description: null,
        sell_price: sellPrice?.formatMoney ?? 0,
        nett_price: isFlagship ? sellPrice?.formatMoney ?? 0 : nettPrice?.formatMoney ?? 0,
        use_take_rate: useTakeRate ? true : false,
        take_rate: takeRate == undefined ? null : takeRate,
      };
      await api.post(`v2/intools/workshops/${idWorkshop}/inventories/`, payload);
      onSubmit(true);
      setLoading(false);
    } catch (error) {
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  const resetData = () => {
    setProductOptions([]);
    setCategoryOptions([]);
    setCategory(null);
    setProduct();
    setProductPrice();
    setNettPrice();
    setSellPrice();
    setTakeRate();
    setUseTakeRate(false);
    setDisableTakeRate(true);
    setErrorProduct('');
    setErrorCategory('');
    setErrorComponent('');
    setErrorDisplayName('');
    setDisplayName('');
    setComponent(null);
  };

  const cancel = () => {
    toggle();
  };

  const validateCategory = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorCategory('Pilih Kategori terlebih dahulu');
    } else {
      setErrorCategory('');
    }
    return passValidate;
  };

  const validateComponent = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorComponent('Pilih Komponen terlebih dahulu');
    } else {
      setErrorComponent('');
    }

    setComponent(value);
    return passValidate;
  };

  const validateBrand = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorBrand('Pilih Brand terlebih dahulu');
    } else {
      setErrorBrand('');
    }
    setBrand(value);
    return passValidate;
  };

  const validateDisplayName = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorDisplayName('Masukan Display Name terlebih dahulu');
    } else {
      setErrorDisplayName('');
    }
    setDisplayName(value);
    return passValidate;
  };

  const handleAddNewProduct = (e) => {
    setProduct({ value: null, label: e, type: 'work', name: e });
    setExistingProduct(false);
    handleChangeDisplayName(e);
    handleChangeCategory(null);
    handleChangeComponent(null);
  };

  const handleChangeBrand = (e) => {
    const valueBrand = e?.value ? e : null;
    validateBrand(valueBrand);
  };

  const handleChangeComponent = (e) => {
    const valueComponent = e?.value ? e : null;
    validateComponent(valueComponent);
  };

  const handleChangeDisplayName = (e) => {
    const valueDisplayName = e ?? null;
    validateDisplayName(valueDisplayName);
  };

  const handleChangeProductType = (e) => {
    setProductType(e);
  };

  useEffect(() => {
    if (useTakeRate !== undefined && sellPrice !== undefined) {
      calculateNettPrice();
    }
  }, [useTakeRate, sellPrice]);

  useEffect(() => {
    resetData();
    if (modal) {
      fetchMasterTakeRate();
      fetchBrandOptions();
      fetchMasterDataCategory();
      fetchComponentOptions();
      setNettPrice(Helper.formatMoneyOnField('' + 0, 'Rp '));
      setSellPrice(Helper.formatMoneyOnField('' + 0, 'Rp '));
      resetData();
    }
  }, [modal]);

  useEffect(() => {
    if (+brandOptions?.length > 0 && isGms) {
      setBrand({
        value: 62,
        label: 'Otoklix',
      });
    }
    if (isGms) setProductType({ value: 'work' });
  }, [brandOptions, isGms]);

  useEffect(() => {
    fetchMasterDataProduct();
  }, [productType]);

  return (
    <Modal
      className="modal-dialog-centered modal-form modal-custom w-auto"
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalBody className="body mt-3">
        <div className="header">Add New Inventory</div>
        <div className="content">
          {isGms ? (
            <FormGroup row>
              <Label lg={4}>Product Type</Label>
              <Label lg={8} className="text-left text-bold">
                {productType?.value === 'part' ? 'Produk' : productType?.value == 'work' ? 'Jasa' : '-'}
              </Label>
            </FormGroup>
          ) : (
            <FormGroup row>
              <Label lg={4} className="label-required">
                Product Type
              </Label>
              <SelectField
                colWidth={8}
                name="type"
                placeholder="Pilih Tipe"
                closeMenuOnSelect={true}
                value={productType}
                options={optionsType}
                disabled={loading || isGms}
                onChange={handleChangeProductType}
              />
            </FormGroup>
          )}
          <FormGroup row>
            <Label lg={4} className="label-required">
              Product Name
            </Label>
            {isGms ? (
              <Col lg={8}>
                <CreatableSelect
                  required
                  options={productOptions}
                  title="Product Name"
                  placeholder="Select Product"
                  isDisabled={loading}
                  value={product}
                  getOptionLabel={(e) => e?.label}
                  getOptionValue={(e) => e?.value}
                  onChange={handleChangeProduct}
                  onCreateOption={handleAddNewProduct}
                  formatCreateLabel={(e) => {
                    return (
                      <div className="dropdown-create-product">
                        <img src={PlusFill} />
                        <div className="ml-3">{`Add New '${e}'`}</div>
                      </div>
                    );
                  }}
                />
                {errorProduct && <div className="pt-1 text-danger">Product Name is Required</div>}
              </Col>
            ) : (
              <SelectField
                colWidth={8}
                name="product"
                placeholder="Product Name"
                closeMenuOnSelect={true}
                value={product}
                options={productOptions}
                disabled={loading}
                isLoading={loadingFetchProduct}
                errorMessage={errorProduct}
                onChange={handleChangeProduct}
              />
            )}
          </FormGroup>
          {isGms && (
            <>
              <FormGroup row>
                <Label lg={4} className="label-required">
                  Display Name
                </Label>
                <InputField
                  colWidth={8}
                  inputType={'text'}
                  disabled={loading}
                  placeholder="Display name"
                  value={displayName ?? ''}
                  invalid={errorDisplayName ? true : false}
                  errorMessage={errorDisplayName ?? ''}
                  onChange={(e) => handleChangeDisplayName(e?.target?.value)}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>Category</Label>
                <SelectField
                  colWidth={8}
                  name="category"
                  placeholder="Category"
                  closeMenuOnSelect={true}
                  value={category}
                  options={categoryOptions}
                  onChange={handleChangeCategory}
                  errorMessage={errorCategory}
                  disabled={loading || existingProduct}
                />
              </FormGroup>
              <FormGroup row>
                <Label lg={4}>Brand</Label>
                <InputField colWidth={8} inputType={'text'} disabled={true} placeholder="Brand" value={'Otoklix'} />
              </FormGroup>
              <FormGroup row>
                <Label lg={4} className="label-required">
                  Komponen
                </Label>
                <SelectField
                  colWidth={8}
                  name="component"
                  placeholder="Pilih Komponen"
                  closeMenuOnSelect={true}
                  value={component}
                  options={componentOptions}
                  disabled={loading || existingProduct}
                  errorMessage={errorComponent}
                  onChange={handleChangeComponent}
                />
              </FormGroup>
            </>
          )}

          <FormGroup row>
            <Label lg={4}>Product Price</Label>
            <InputField
              colWidth={8}
              inputType={'text'}
              disabled={true}
              placeholder="Product Price"
              value={`Rp. ${Helper.formatMoney(productPrice)}`}
            />
          </FormGroup>
          {!isFlagship && (
            <FormGroup row className="mb-0">
              <Label lg={4}>
                {takeRate !== null && takeRate !== undefined ? `Take Rate (${takeRate}%)` : `Take Rate`}
              </Label>
              <Col lg={8} className="p-2">
                <ActionSwitch
                  className="action-switch"
                  color="secondary"
                  inputName={`is_take_rate`}
                  isChecked={useTakeRate ? true : false}
                  editableForm={loading || disableTakeRate}
                  onChange={handleChangeUseTakeRate}
                />
              </Col>
            </FormGroup>
          )}
          <FormGroup row>
            <Label lg={4}>B2C Sell Price</Label>
            <InputField
              colWidth={8}
              inputType={'text'}
              disabled={loading}
              placeholder="B2C Sell Price"
              value={sellPrice?.formatMoneyString ?? ''}
              onChange={(e) => {
                let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                setSellPrice(convertMoney?.formatMoney > 99999999 ? sellPrice : convertMoney);
              }}
            />
          </FormGroup>
          {!isFlagship && (
            <FormGroup row>
              <Label lg={4}>Nett Price</Label>
              <InputField
                colWidth={8}
                inputType={'text'}
                disabled={loading || useTakeRate}
                placeholder="Nett Price"
                value={nettPrice?.formatMoneyString ?? ''}
                onChange={(e) => {
                  if (!loading && !useTakeRate) {
                    let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                    setNettPrice(convertMoney?.formatMoney > 99999999 ? nettPrice : convertMoney);
                  }
                }}
              />
            </FormGroup>
          )}
        </div>

        <div className="action mb-3 mt-3">
          <Button
            className="button-cancel"
            onClick={() => {
              if (!loading && !loadingFetchMasterTakeRate) {
                cancel();
              }
            }}
            disabled={loading || loadingFetchMasterTakeRate}
          >
            Batal
          </Button>
          <Button
            className="button-action"
            onClick={() => {
              if (!loading) {
                handleSubmit();
              }
            }}
            disabled={loading}
          >
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InventoryAdd;
