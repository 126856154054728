import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, Row, Col, Label, Input, Spinner } from 'reactstrap';
import API from '@utils/API';
import PDFMerger from 'pdf-merger-js/browser';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { BASE64_OTOKLIX_LOGO } from '@utils/Constants';
import SummaryPdf from '../../helpers/pdf/SummaryPdf';
import ReceiptPdf from '../../helpers/pdf/ReceiptPdf';
import BASTPdf from '../../helpers/pdf/BASTPdf';
import CollectionLetterPdf from '../../helpers/pdf/CollectionLetterPdf';
import PhotoServicesPdf from '../../helpers/pdf/PhotoServicesPdf';
import OfferingLetterPdf from '../../helpers/pdf/OfferingLetterPdf';
import { assign } from 'lodash';
import Helper from '@helpers/Helper';

const ConfigDocumentModal = (props) => {
  const {
    modal,
    toggle,
    backdrop,
    dataConfig,
    handleChangeDataConfig,
    onClosed,
    handleResultNotification,
    invoiceData,
  } = props;
  const api = new API('v2');

  const [isLoading, setLoading] = useState(false);
  const [resultBlob, setResultBlob] = useState([]);
  const [url, setURL] = useState('');
  const [totalData, setTotalData] = useState(0);

  const config = [
    {
      doc_name: 'summary',
      data: {
        customer_address: null,
        service_date: '23 Jan 2024',
        invoice_number: '021/PAR/B2B/2024',
        customer_name: 'PT Prima Armada Raya',
        summary: [
          {
            spk_number: '12121321',
            booking_code: '202434535092',
            receipt_number: 'Receipt 001-III/2024',
            license_plate: 'B 89798 JK',
            car_type: 'Toyota Avanza',
            subtotal: 345345,
          },
          {
            spk_number: '12121321',
            booking_code: '202434535092',
            receipt_number: 'Receipt 001-III/2024',
            license_plate: 'B 89798 JK',
            car_type: 'Toyota Avanza',
            subtotal: 345345,
          },
          {
            spk_number: '12121321',
            booking_code: '202434535092',
            receipt_number: 'Receipt 001-III/2024',
            license_plate: 'B 89798 JK',
            car_type: 'Toyota Avanza',
            subtotal: 345345,
          },
          {
            spk_number: '12121321',
            booking_code: '202434535092',
            receipt_number: 'Receipt 001-III/2024',
            license_plate: 'B 89798 JK',
            car_type: 'Toyota Avanza',
            subtotal: 345345,
          },
          {
            spk_number: '12121321',
            booking_code: '202434535092',
            receipt_number: 'Receipt 001-III/2024',
            license_plate: 'B 89798 JK',
            car_type: 'Toyota Avanza',
            subtotal: 345345,
          },
          {
            spk_number: '12121321',
            booking_code: '202434535092',
            receipt_number: 'Receipt 001-III/2024',
            license_plate: 'B 89798 JK',
            car_type: 'Toyota Avanza',
            subtotal: 345345,
          },
          {
            spk_number: '12121321',
            booking_code: '202434535092',
            receipt_number: 'Receipt 001-III/2024',
            license_plate: 'B 89798 JK',
            car_type: 'Toyota Avanza',
            subtotal: 345345,
          },
          {
            spk_number: '12121321',
            booking_code: '202434535092',
            receipt_number: 'Receipt 001-III/2024',
            license_plate: 'B 89798 JK',
            car_type: 'Toyota Avanza',
            subtotal: 345345,
          },
          {
            spk_number: '12121321',
            booking_code: '202434535092',
            receipt_number: 'Receipt 001-III/2024',
            license_plate: 'B 89798 JK',
            car_type: 'Toyota Avanza',
            subtotal: 345345,
          },
          {
            spk_number: '12121321',
            booking_code: '202434535092',
            receipt_number: 'Receipt 001-III/2024',
            license_plate: 'B 89798 JK',
            car_type: 'Toyota Avanza',
            subtotal: 345345,
          },
          {
            spk_number: '12121321',
            booking_code: '202434535092',
            receipt_number: 'Receipt 001-III/2024',
            license_plate: 'B 89798 JK',
            car_type: 'Toyota Avanza',
            subtotal: 345345,
          },
        ],
        payment_amount: 4343353,
      },
    },
    {
      doc_name: 'receipt',
      data: {
        receipt_number: 'Receipt 001-III/2024',
        booking_code: '202434535092, 202434535092',
        customer_name: 'PT Prima Armada Raya',
        car_type: 'Toyota Kijang Innova',
        license_plate: 'B 87987',
        customer_phone: '+628111266025',
        mileage: 10000,
        spk_number: '5454',
        payment_method: 'TOP',
        order_completed: '15 Jan 2024',
        payment_amount: 4354,
        subtotal: 35355,
        pph: 4534,
        ppn: 454354,
        summary: [
          {
            type: 'work',
            name: 'Detailing',
            remark: 'Ada keterangan nih',
            qty: 9,
            base_price: 45344,
            discount: 43545,
            subtotal: 4535345,
            tax: 4354,
          },
          {
            type: 'part',
            name: 'Astra GS Maintenance Free Calcium Battery (NS40ZL)',
            remark: 'Ada keterangan nih',
            qty: 9,
            base_price: 45344,
            discount: 43545,
            subtotal: 4535345,
            tax: 4354,
          },
          {
            type: 'work',
            name: 'Detailing',
            remark: '',
            qty: 9,
            base_price: 45344,
            discount: 43545,
            subtotal: 4535345,
            tax: 4354,
          },
        ],
      },
    },
    {
      doc_name: 'bast',
      data: {
        day: 'Rabu',
        date: 13,
        month: 'Januari',
        year: 2024,
        pic_otoklix: 'David',
        pic_customer: 'Wahyu',
        customer_name: 'PT Agung',
        customer_address: 'Jl. Autograph Jakarta Pusat',
        summary: [
          {
            customer_car: 'Toyota Fortuner B 1223 YU',
            detail_service: [
              {
                qty: 1,
                item: 'Jasa',
              },
              {
                qty: 1,
                item: 'AC',
              },
            ],
          },
          {
            customer_car: 'Kijang Inova 1223 YU',
            detail_service: [
              {
                qty: 2,
                item: 'Oli',
              },
              {
                qty: 1,
                item: 'Cuci',
              },
            ],
          },
          {
            customer_car: 'Kijang Inova 1223 YU',
            detail_service: [
              {
                qty: 3,
                item: 'single',
              },
            ],
          },
        ],
      },
    },
    {
      doc_name: 'service-photo',
      data: {
        car_type: 'Kijang Inova',
        license_plate: 'B 9089 YU',
        order_complete_date: '18-03-24',
        booking_code: '3532535, 5435345',
        document: [
          {
            label: 'Foto Report Kendaraan',
            photo: [null, null],
          },
          {
            label: 'Foto Report Sparepart',
            photo: [null],
          },
        ],
      },
    },
    {
      doc_name: 'offering-letter',
      data: {
        file: {
          user_car: {
            car_details: {
              car_model: {
                brand: {
                  name: 'Toyota',
                },
                model_name: 'Kijang Inova',
              },
            },
            license_plate: 'B 899 B',
          },
          offering_letter_file_number: '353/3535',
          booking_created_at: '4 Jan 2024',
          customer: {
            name: 'PT ojol',
            address: 'jalan',
            pic_phone: 455354,
            pic_email: 'jnsjkdn@mfnd.fkjdk',
          },
        },
        summary: {
          subtotal: 45345,
          pph: 453454,
          ppn: 435345,
          payment_amount: 56465,
          items: {
            products: {
              product_items: [
                {
                  product_type: 'part',
                  name: 'Denso Oil',
                  qty: 4,
                  unit_price: 45345,
                  voucher_amount: 4545,
                  price: 45454,
                  tax: 5646,
                },
                {
                  product_type: 'part',
                  name: 'Denso Oil',
                  qty: 4,
                  unit_price: 45345,
                  voucher_amount: 4545,
                  price: 45454,
                  tax: 5646,
                },
              ],
            },
            services: {
              service_items: [
                {
                  product_type: 'part',
                  name: 'Denso Oil',
                  qty: 4,
                  unit_price: 45345,
                  voucher_amount: 4545,
                  price: 45454,
                  tax: 5646,
                },
                {
                  product_type: 'part',
                  name: 'Denso Oil',
                  qty: 4,
                  unit_price: 45345,
                  voucher_amount: 4545,
                  price: 45454,
                  tax: 5646,
                },
              ],
            },
          },
        },
      },
    },
    {
      doc_name: 'custom',
      id: 6,
      data: [
        {
          label: 'balap',
          file: 'yahh',
        },
        {
          label: 'aligator',
          file: 'grrrr',
        },
      ],
    },
  ];

  const validation = () => {
    let isValid = true;

    if (dataConfig?.filter((obj) => obj.checked)?.length < 1) {
      isValid = false;
      handleResultNotification({
        status: true,
        type: 'fail',
        message: `pilih minimal 1 dokumen`,
      });
    }

    return isValid;
  };

  const handleGeneratePDF = (data) => {
    const isValid = validation();

    if (isValid) {
      getGeneratedData();
    }
  };

  const getGeneratedData = async () => {
    setLoading(true);
    let mappingConfig = dataConfig
      ?.filter((obj) => obj?.checked == true)
      ?.map((dt, index) => {
        return dt?.slug;
      });
    let uniqueConfig = [...new Set(mappingConfig)];
    let mappingCustomId = dataConfig
      ?.filter((obj) => obj?.checked == true && obj?.slug == 'custom')
      ?.map((dt, index) => {
        return dt?.id;
      });
    let params = {
      slug: uniqueConfig?.toString(),
    };

    if (dataConfig?.filter((obj) => obj?.checked == true && obj?.slug == 'custom')?.length > 0)
      assign(params, { custom_id: mappingCustomId?.toString() });

    await api
      .get(`v2/intools/invoices/${invoiceData?.id}/compile/`, { params })
      .then((res) => {
        const data = res?.data?.data ?? [];
        setTotalData(data?.document_count);
        loopWithAsyncActions(data?.config_doc);
        // generatePDF(data?.config_doc);
        // setLoading(false);
        // toggle();
      })
      .catch((err) => {
        handleResultNotification({
          status: true,
          type: 'fail',
          message: `Gagal mendapatkan data, reload halaman dalam beberapa saat lagi atau hubungi admin`,
        });
        setLoading(false);
        toggle();
      });
  };

  const makePdfHeader = (content) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'potrait',
      content: content,
      header: function (currentPage, pageCount) {
        return [
          {
            image: 'logo',
            width: 180,
            style: {
              alignment: 'center',
              fontSize: 8,
            },
            margin: [0, 30, 0, 0],
          },
          {
            margin: [40, 0, 40, 0],
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 10,
                x2: 515,
                y2: 10,
                lineWidth: 1.7,
                lineColor: '#cc4200',
              },
            ],
          },
          {
            text: 'PT. OTO KLIX INDONESIA',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 10, 40, 0],
            bold: true,
          },
          {
            text: 'Jl. Terusan Sinabung No.1',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 2, 40, 0],
          },
          {
            text: 'Grogol Selatan, Kebayoran Baru',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 2, 40, 0],
            bold: false,
          },
          {
            text: 'Kota Jakarta Selatan, DKI Jakarta 12220. Indonesia',
            style: {
              alignment: 'right',
              fontSize: 10,
            },
            margin: [40, 2, 40, 0],
            bold: false,
          },
        ];
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Halaman ' + currentPage.toString() + ' dari ' + pageCount,
            alignment: 'center',
            bold: true,
            color: '#d6d8e7',
            fontSize: 10,
          },
        ];
      },
      pageMargins: [40, 170, 40, 40],
      images: {
        logo: BASE64_OTOKLIX_LOGO,
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 30, 0, 0],
          alignment: 'center',
        },
        columnTop: {
          color: 'red',
        },
        paddingLeftRightSectionTop: {
          fillColor: '#FFFFFF',
          border: [false, false, false, false],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5], //left, top, right, bottom
        },
        tableContent: {
          margin: [0, 0, 0, 0],
        },
        tableDash: {
          margin: [-50, 5, 0, -50],
        },
        tableSpace: {
          margin: [0, -5, 0, 0],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
        tableStyle: {
          border: '',
          fontSize: 10,
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    return pdfDocGenerator;
  };

  const makePdfHeaderSimple = (content) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'potrait',
      content: content,
      header: function (currentPage, pageCount) {
        return [
          {
            image: 'logo',
            width: 100,
            style: {
              alignment: 'left',
              fontSize: 8,
            },
            margin: [40, 30, 0, 0],
          },
        ];
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Halaman ' + currentPage.toString() + ' dari ' + pageCount,
            alignment: 'center',
            bold: true,
            color: '#d6d8e7',
            fontSize: 10,
          },
        ];
      },
      pageMargins: [40, 70, 40, 40],
      images: {
        logo: BASE64_OTOKLIX_LOGO,
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 30, 0, 0],
          alignment: 'center',
        },
        tableStyle: {
          border: '',
          fontSize: 10,
        },
        textCenter: {
          alignment: 'center',
        },
        textRight: {
          alignment: 'right',
        },
        fontSize10: {
          fontSize: 10,
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    return pdfDocGenerator;
  };

  function asyncAction(item, index) {
    return new Promise((resolve, reject) => {
      if (item?.doc_name == 'summary') {
        setTimeout(() => {
          let summary = SummaryPdf.create(item?.data);
          makePdfHeaderSimple(summary).getBlob((blobSum) => {
            setResultBlob((prevState) => [...prevState, blobSum]);
          });

          resolve(); // Resolve the promise when the action is completed
        }, 100);
      }
      if (item?.doc_name == 'receipt') {
        setTimeout(() => {
          let receipt = ReceiptPdf.create(item?.data);
          makePdfHeaderSimple(receipt).getBlob((blob) => {
            setResultBlob((prevState) => [...prevState, blob]);
          });

          resolve(); // Resolve the promise when the action is completed
        }, 100);
      }
      if (item?.doc_name == 'spk') {
        setTimeout(() => {
          const contentType = 'application/pdf';
          const blob = Helper.base64StringToBlob(item?.data?.replace(/^[^,]+,/, ''), contentType);
          setResultBlob((prevState) => [...prevState, blob]);
          resolve(); // Resolve the promise when the action is completed
        }, 100);
      }
      if (item?.doc_name == 'bast') {
        setTimeout(() => {
          let bast = BASTPdf.create(item?.data);
          makePdfHeader(bast).getBlob((blob) => {
            setResultBlob((prevState) => [...prevState, blob]);
          });

          resolve(); // Resolve the promise when the action is completed
        }, 100);
      }
      if (item?.doc_name == 'service-photo') {
        setTimeout(() => {
          let photo = PhotoServicesPdf.create(item?.data);
          makePdfHeaderSimple(photo).getBlob((blob) => {
            setResultBlob((prevState) => [...prevState, blob]);
          });

          resolve(); // Resolve the promise when the action is completed
        }, 100);
      }
      if (item?.doc_name == 'offering-letter') {
        setTimeout(() => {
          let offering = OfferingLetterPdf.create(item?.data?.file, item?.data?.summary, 'invoice');
          makePdfHeader(offering).getBlob((blob) => {
            setResultBlob((prevState) => [...prevState, blob]);
          });

          resolve(); // Resolve the promise when the action is completed
        }, 100);
      }
      if (item?.doc_name == 'payment_request_letter') {
        setTimeout(() => {
          let collectionLetter = CollectionLetterPdf.create(item?.data);
          makePdfHeader(collectionLetter).getBlob((blob) => {
            setResultBlob((prevState) => [...prevState, blob]);
          });

          resolve(); // Resolve the promise when the action is completed
        }, 100);
      }
      if (item?.doc_name == 'custom') {
        let timeOut = item?.data?.custom_document?.length * 100;
        setTimeout(() => {
          item?.data?.custom_document?.map((dt, idx) => {
            const contentType = 'application/pdf';
            const blob = Helper.base64StringToBlob(dt?.file?.replace(/^[^,]+,/, ''), contentType);
            setResultBlob((prevState) => [...prevState, blob]);
          });
          resolve(); // Resolve the promise when the action is completed
        }, timeOut);
      }
    });
  }

  async function loopWithAsyncActions(dataCompile) {
    for (let i = 0; i < dataCompile?.length; i++) {
      const data = dataCompile[i];

      await asyncAction(data, i); // Execute the appropriate async action for each iteration
    }
  }

  const generatePDF = (data) => {
    setURL();
    data?.map((item, index) => {
      if (item?.doc_name == 'summary') {
        let summary = SummaryPdf.create(item?.data);
        makePdfHeaderSimple(summary).getBlob((blobSum) => {
          setResultBlob((prevState) => [...prevState, blobSum]);
        });
      }
      if (item?.doc_name == 'receipt') {
        let receipt = ReceiptPdf.create(item?.data);
        makePdfHeaderSimple(receipt).getBlob((blob) => {
          setResultBlob((prevState) => [...prevState, blob]);
        });
      }
      if (item?.doc_name == 'spk') {
        const contentType = 'application/pdf';
        const blob = Helper.base64StringToBlob(item?.data?.replace(/^[^,]+,/, ''), contentType);
        setTimeout(() => {
          setResultBlob((prevItems) => {
            // Create a copy of the previous state array
            const newArray = [...prevItems];
            // Insert the new item at the specified index
            newArray.splice(index, 0, blob);
            // Return the updated array
            return newArray;
          });
        }, 200);
      }
      if (item?.doc_name == 'bast') {
        let bast = BASTPdf.create(item?.data);
        makePdfHeader(bast).getBlob((blob) => {
          setResultBlob((prevState) => [...prevState, blob]);
        });
      }
      if (item?.doc_name == 'service-photo') {
        let photo = PhotoServicesPdf.create(item?.data);
        makePdfHeaderSimple(photo).getBlob((blob) => {
          setResultBlob((prevState) => [...prevState, blob]);
        });
      }
      if (item?.doc_name == 'offering-letter') {
        let offering = OfferingLetterPdf.create(item?.data?.file, item?.data?.summary, 'invoice');
        makePdfHeader(offering).getBlob((blob) => {
          setResultBlob((prevState) => [...prevState, blob]);
        });
      }
      if (item?.doc_name == 'custom') {
        item?.data?.custom_document?.map((dt, idx) => {
          const contentType = 'application/pdf';
          const blob = Helper.base64StringToBlob(dt?.file?.replace(/^[^,]+,/, ''), contentType);
          setTimeout(() => {
            setResultBlob((prevItems) => {
              // Create a copy of the previous state array
              const newArray = [...prevItems];
              // Insert the new item at the specified index
              newArray.splice(index + idx, 0, blob);
              // Return the updated array
              return newArray;
            });
          }, 300);
        });
      }
    });
  };

  const handleChangeDoc = (checked, index) => {
    dataConfig[index].checked = checked;
    handleChangeDataConfig([...dataConfig]);
  };

  useEffect(() => {
    console.log('start ...');
    if (resultBlob?.length > 0 && totalData > 0) {
      console.log('merging ...');
      const render = async () => {
        const merger = new PDFMerger();

        for (const file of resultBlob) {
          await merger.add(file);
        }

        const mergedPdf = await merger.saveAsBlob();
        const url = URL.createObjectURL(mergedPdf);

        resultBlob?.length == totalData && setURL(url);
        // setURL(url);
      };

      render().catch((err) => {
        throw err;
      });
    }
  }, [resultBlob, totalData]);

  useEffect(() => {
    if (url) {
      const fileName = `Penagihan_${invoiceData?.invoice_number}_${invoiceData?.customer?.name}.pdf`;
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setLoading(false);
      toggle();
    }
  }, [url]);

  useEffect(() => {
    if (modal) {
      setResultBlob([]);
      setURL();
    }
  }, [modal]);

  return (
    <Modal
      className={`modal-dialog-centered modal-custom w-374`}
      isOpen={modal}
      // toggle={toggle}
      backdrop={backdrop}
      onClosed={onClosed}
    >
      <ModalBody className="body">
        <div className="modal-export-finance">
          <div className="text-center font-18 font-weight-bold mb-2">Pilih Dokumen Penagihan</div>
          <Row className="document-checkbox-field mx-2 mb-5" style={{ height: 300, overflow: 'auto' }}>
            <Col sm={12} md={12}>
              {dataConfig?.map((item, index) => (
                <Col sm={12} md={12} key={index} className={`checkbox-${index} checkbox-form`}>
                  <Label className="ml-4 mt-2 mb-0">
                    <Input
                      type="checkbox"
                      checked={item?.checked}
                      onClick={() => handleChangeDoc(!item?.checked, index)}
                      disabled={isLoading}
                    />
                    <div className="text-break">{item?.label}</div>
                  </Label>
                </Col>
              ))}
            </Col>
          </Row>
          <div className="action mt-5">
            <Button className="button-action" onClick={toggle} disabled={isLoading}>
              Batal
            </Button>
            <Button className="button-action" disabled={isLoading} onClick={() => handleGeneratePDF()}>
              <span className="mr-2">Download</span>
              {isLoading && (
                <span>
                  <Spinner color="light" size="sm">
                    Loading...
                  </Spinner>
                </span>
              )}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfigDocumentModal;
