import React, { useState, useEffect } from 'react';
import { find, findIndex } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { validate } from 'react-email-validator';
import { regexOnlyAllowNumber } from '@utils/Constants';
import { Card, CardBody, Col, FormGroup, Input, Label, Row, Button } from 'reactstrap';
import map from 'lodash/map';
import API from '@utils/API';
import APIS3 from '@utils/APIS3';
import ACIcon from '@assets/icons/ac.svg';
import userPermission from '@utils/userPermission';
import ChargingIcon from '@assets/icons/charging.svg';
import DrinkIcon from '@assets/icons/drink.svg';
import FoodIcon from '@assets/icons/food.svg';
import MushollaIcon from '@assets/icons/musholla.svg';
import ParkirIcon from '@assets/icons/parkir.svg';
import ToiletIcon from '@assets/icons/toilet.svg';
import WifiIcon from '@assets/icons/wifi.svg';
import TrashIcon from '@assets/icons/trash.svg';
import AvailableIcon from '@assets/icons/available.svg';
import NotAvailableIcon from '@assets/icons/not-available.svg';
import StartRateIcon from '@assets/icons/star-rate-workshop.svg';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import ActionSwitch from '@components/field/ActionSwitch';

const Information = (props) => {
  const {
    id,
    onChangeAction,
    isValidationForm,
    onResultValidationForm,
    onResultNotification,
    onChangeWorkshopInfo,
    onLoading,
  } = props;
  const { generatePermission } = userPermission();
  const api = new API('v2');
  const apiS3 = new APIS3();
  const [dataWorkshop, setDataWorkshop] = useState({});
  const [optionsType, setOptionsType] = useState([]);
  const [optionsSpeciality, setOptionsSpeciality] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [disableForm, setDisableForm] = useState(true);
  const [isDragOver, setIsDragOver] = useState(false);

  const masterFacilitiesData = [
    {
      id: 1,
      icon: ACIcon,
    },
    {
      id: 2,
      icon: ToiletIcon,
    },
    {
      id: 3,
      icon: ParkirIcon,
    },
    {
      id: 4,
      icon: ACIcon,
    },
    {
      id: 5,
      icon: ChargingIcon,
    },
    {
      id: 6,
      icon: MushollaIcon,
    },
    {
      id: 7,
      icon: WifiIcon,
    },
    {
      id: 8,
      icon: DrinkIcon,
    },
    {
      id: 9,
      icon: FoodIcon,
    },
  ];

  const optionsMOU = [
    {
      value: 'not_approved',
      label: 'Tidak disetujui',
    },
    {
      value: 'on_process',
      label: 'Dalam Proses',
    },
    {
      value: 'approved',
      label: 'Disetujui',
    },
  ];

  const allowPermission = (menu) => {
    return !!(
      generatePermission('workshop', menu) ||
      generatePermission('workshop', 'all') ||
      generatePermission('workshop', 'galleryall')
    );
  };

  const allowEdit = allowPermission('generalinfoedit');

  const { getRootProps } = useDropzone({
    multiple: false,
    disabled: disableForm,
    onDrop: (files) => {
      handleDropFile(files[0]);
      setIsDragOver(false);
    },
    onDragOver: () => {
      removeErrorDocument();
      setIsDragOver(true);
    },
    onDragLeave: () => {
      setIsDragOver(false);
    },
    onFileDialogOpen: () => {
      removeErrorDocument();
    },
  });

  async function getGeneralInformation() {
    setLoading(true);
    setDisableForm(true);

    try {
      const response = await api.get(`v2/intools/workshops/${id}/general-info/`);
      if (response?.data?.data) {
        setDataWorkshop(response?.data?.data ?? {});
        setDisableForm(false);
      } else {
        setDataWorkshop({});
        setDisableForm(true);
      }
      setLoading(false);
    } catch (error) {
      setDataWorkshop({});
      setLoading(false);
      setDisableForm(true);
    }
  }

  const getTypeOptions = () => {
    api.get('v2/intools/workshops/tier_options/').then((response) => {
      const options = map(response.data.data, (tier) => {
        return { label: tier.name, value: tier.value };
      });

      setOptionsType(options);
    });
  };

  const getSpecialityOptions = () => {
    api.get('v2/intools/workshops/specialities/').then((response) => {
      const options = map(response.data.data, (item) => {
        return { label: item.name, value: item.id };
      });

      setOptionsSpeciality(options);
    });
  };

  const getIconFacilites = (item) => {
    const available = find(masterFacilitiesData, (value) => {
      return value.id === item?.id;
    });

    const facilityIcon = available?.icon ? available.icon : item?.available ? AvailableIcon : NotAvailableIcon;

    return facilityIcon;
  };

  const getFacilitiesData = () => {
    const dataFacilities = dataWorkshop?.facilities?.length ? dataWorkshop?.facilities : [];
    let facilities = dataFacilities.map((item) => {
      return { id: item?.id ?? null, name: item?.name ?? '', available: item?.is_available };
    });

    return facilities;
  };

  const getCapabilitiesData = () => {
    const dataCapabilities = dataWorkshop?.capabilities?.length ? dataWorkshop?.capabilities : [];
    let capabilities = dataCapabilities.map((item) => {
      return {
        id: item?.id ?? null,
        name: item?.name ?? '',
        available: item?.is_available,
        inventory: item?.is_inventory,
      };
    });

    return capabilities;
  };

  const defaultDataForm = () => {
    let phoneNumber = dataWorkshop?.phone ? dataWorkshop?.phone.replace(/[^0-9]/g, '') : '';
    phoneNumber.replace(/(?!^\+)\+/g, '');

    const blacklistedBy = dataWorkshop?.blacklisted_by?.length
      ? dataWorkshop?.blacklisted_by.map((item) => {
          return { value: item?.id ?? null, label: item?.name ?? '' };
        })
      : [];

    let dataForm = {
      id: dataWorkshop?.id,
      name: dataWorkshop?.name ?? '',
      phone: phoneNumber,
      email: dataWorkshop?.email ?? '',
      franchise: dataWorkshop?.is_franchise ?? null,
      is_b2b: dataWorkshop?.is_b2b_workshop ?? null,
      document_name: dataWorkshop?.document?.label ? 'Download' : '',
      document_url: dataWorkshop?.document?.value,
      document_file: '',
      document_key: dataWorkshop?.document?.key ?? null,
      status: dataWorkshop?.status_workshop ?? null,
      type: dataWorkshop?.tier ?? null,
      workshop_type: dataWorkshop?.workshop_type ?? '',
      is_otoklix_pickup: dataWorkshop?.is_otoklix_pickup ?? null,
      rating_workshop: dataWorkshop?.rating_workshop,
      count_rating_workshop: dataWorkshop?.count_rating_workshop,
      rating_google: dataWorkshop?.rating_google,
      count_rating_google: dataWorkshop?.count_rating_google,
      status_mou: dataWorkshop?.mou_status ?? null,
      pic: dataWorkshop?.otoklix_pic_name,
      facilities: getFacilitiesData(),
      description: dataWorkshop?.description,
      is_service_warranty: dataWorkshop?.service_warranty ?? null,
      is_fbo: dataWorkshop?.is_fbo ?? null,
      is_gms: dataWorkshop?.is_gms ?? null,
      blacklist_by: blacklistedBy,
      wa_group: dataWorkshop?.wa_group_link ?? '',
      specialities: dataWorkshop?.specialities ?? null,
      capabilities: getCapabilitiesData(),
      error_name: '',
      error_franchise: '',
      error_document: '',
      error_phone: '',
      error_email: '',
      error_pic: '',
      error_status: '',
      error_workshop_type: '',
      error_description: '',
      error_wa_group: '',
    };

    setFormData(dataForm);
  };

  const handleChangeRadio = (key, status) => {
    formData[key] = status;
    if (key == 'status') {
      formData.error_status = !status ? 'Field tidak boleh kosong' : '';
    }
    if (key == 'workshop_type') {
      formData.error_workshop_type = !status ? 'Field tidak boleh kosong' : '';
    }
    setFormData({ ...formData });
  };

  const handleChangeType = (e) => {
    formData.type = e;
    setFormData({ ...formData });
  };

  const handleChangeMoU = (e) => {
    formData.status_mou = e;
    setFormData({ ...formData });
  };

  const handleChangeSpecialities = (e) => {
    formData.specialities = e;
    setFormData({ ...formData });
  };

  const handleChangeActionSwitch = (key, e) => {
    formData[key] = e?.target?.checked ? true : false;
    setFormData({ ...formData });
  };

  const handleFacilityChange = (item) => {
    const facilityIndex = findIndex(formData.facilities, (value) => {
      return value?.id === item.id;
    });

    if (facilityIndex !== -1) {
      formData.facilities[facilityIndex].available = !item.available;
      setFormData({ ...formData });
    }
  };

  const handleCapabiltyChange = (item) => {
    if (!item?.inventory) {
      const capabilityIndex = findIndex(formData.capabilities, (value) => {
        return value?.id === item.id;
      });

      if (capabilityIndex !== -1) {
        formData.capabilities[capabilityIndex].available = !item.available;
        setFormData({ ...formData });
      }
    }
  };

  const removeErrorDocument = () => {
    if (formData.error_document !== '') {
      formData.error_document = '';
      setFormData({ ...formData });
    }
  };

  const handleDropFile = (data) => {
    const file = data;
    const maxSize = 10240;
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const allowTypes = ['jpeg', 'jpg', 'png', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx'];
    const nameFileSplit = file?.name ? file?.name.split('.') : [];

    if (nameFileSplit.length == 0) {
      formData.error_document = 'Format file tidak diketahui';
      setFormData({ ...formData });
    } else {
      const selected = find(allowTypes, (item) => {
        return nameFileSplit[nameFileSplit.length - 1].toLowerCase() === item;
      });
      if (!selected) {
        formData.error_document = 'File tidak sesuai dengan format';
        setFormData({ ...formData });
      } else {
        if (fileSize == null) {
          formData.error_document = 'Ukuran file tidak diketahui';
          setFormData({ ...formData });
        } else if (fileSize > maxSize) {
          formData.error_document = 'Ukuran file maksimal 10mb';
          setFormData({ ...formData });
        } else {
          const fileUrl = URL.createObjectURL(file);
          formData.error_document = '';
          formData.document_name = file?.name ?? '';
          formData.document_url = fileUrl ?? '';
          formData.document_file = file ?? '';
          setFormData({ ...formData });
        }
      }
    }
  };

  const handleDeleteDocument = () => {
    if (!disableForm) {
      formData.document_name = '';
      formData.document_url = '';
      formData.document_file = '';
      setFormData({ ...formData });
    }
  };

  const handleDownloadDocument = () => {
    if (!disableForm) {
      const link = document.createElement('a');
      link.setAttribute('href', formData.document_url);
      link.setAttribute('download', formData.document_name ?? '');
      link.click();
    }
  };

  const validateForm = () => {
    let isValid = true;
    const regexNumb = new RegExp(regexOnlyAllowNumber);
    const emailCheck = validate(formData?.email ?? '');

    if (allowEdit) {
      if (formData?.name == '') {
        isValid = false;
        formData.error_name = 'Field tidak boleh kosong';
      } else if (formData?.name.length > 50) {
        isValid = false;
        formData.error_name = 'Field tidak sesuai dengan format, format maksimal 50 karakter';
      }

      if (formData?.phone.length > 17) {
        isValid = false;
        formData.error_phone = 'Field tidak sesuai dengan format, format maksimal 17 digit';
      }

      if (!emailCheck && formData?.email) {
        isValid = false;
        formData.error_email = 'Format email tidak sesuai';
      }

      if (!formData?.status) {
        isValid = false;
        formData.error_status = 'Field tidak boleh kosong';
      }

      if (!formData?.workshop_type) {
        isValid = false;
        formData.error_workshop_type = 'Field tidak boleh kosong';
      }

      if (formData?.document_file) {
        const file = formData?.document_file;
        const maxSize = 10240;
        const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
        const allowTypes = ['jpeg', 'jpg', 'png', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx'];
        const nameFileSplit = file?.name ? file?.name.split('.') : [];

        if (nameFileSplit.length == 0) {
          isValid = false;
          formData.error_document = 'Format file tidak diketahui';
        } else {
          const selected = find(allowTypes, (item) => {
            return nameFileSplit[nameFileSplit.length - 1].toLowerCase() === item;
          });
          if (!selected) {
            isValid = false;
            formData.error_document = 'File tidak sesuai dengan format';
          } else {
            if (fileSize == null) {
              isValid = false;
              formData.error_document = 'Ukuran file tidak diketahui';
            } else if (fileSize > maxSize) {
              isValid = false;
              formData.error_document = 'Ukuran file maksimal 10mb';
            }
          }
        }
      }

      if (formData?.pic && formData?.pic.length > 21) {
        isValid = false;
        formData.error_pic = 'Field tidak sesuai dengan format, format maksimal 21 karakter';
      }

      if (formData?.wa_group && formData?.wa_group.length > 100) {
        isValid = false;
        formData.error_wa_group = 'Field tidak sesuai dengan format, format maksimal 100 karakter';
      }

      if (formData?.description && formData?.description.length > 2000) {
        isValid = false;
        formData.error_description = 'Field tidak sesuai dengan format, format maksimal 2000 karakter';
      }

      setFormData({ ...formData });
    }

    return isValid;
  };

  const handleAction = (type) => {
    if (isEdit) {
      if (type == 'cancel') {
        defaultDataForm();
        setIsEdit(false);
        onChangeAction(false, 'information');
        handleResultNotification(false);
      } else {
        handleSubmitForm('submit');
      }
    } else {
      setIsEdit(true);
      onChangeAction(true, 'information');
    }
  };

  const handleValidateForm = () => {
    const isValid = validateForm();
    if (isValid) {
      handleSubmitForm();
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
    onResultValidationForm(isValid);
  };

  const handleSubmitForm = (type) => {
    const isValid = validateForm();
    if (isValid) {
      setDisableForm(true);
      if (formData?.document_file && formData?.document_name) {
        handlePresignedUploadFile(type);
      } else {
        handleGetPayload(type);
      }
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
  };

  const handlePresignedUploadFile = (type) => {
    const paramsPresignedUpload = {
      namespace: 'workshop',
      identifier: formData?.id,
      file_name: formData?.document_name,
    };
    api
      .post(`v2/intools/presigned-upload/`, paramsPresignedUpload)
      .then((response) => {
        const urlUpload = response?.data?.data?.presigned_url ?? '';
        const keyFile = response?.data?.data?.key ?? '';
        if (urlUpload !== '') {
          handleUploadFile(type, urlUpload, keyFile);
        } else {
          setDisableForm(false);
          handleResultNotification(true, 'fail', 'Fail get url upload file');
        }
      })
      .catch((e) => {
        const message = `API Error : ${e?.response?.data?.error?.message}`;
        setDisableForm(false);
        handleResultNotification(true, 'fail', message);
      });
  };

  const handleUploadFile = (type, url, keyFile) => {
    apiS3
      .put(url, formData?.document_file)
      .then((response) => {
        handleGetPayload(type, keyFile);
      })
      .catch((e) => {
        setDisableForm(false);
        handleResultNotification(true, 'fail', 'Fail upload file');
      });
  };

  const handleGetPayload = (type, keyFile) => {
    let facilities_selected = [];
    let capabilities_selected = [];
    let specialites_selected = [];

    formData?.facilities.map((item) => {
      if (item?.available) {
        facilities_selected.push(item.id);
      }
    });

    formData?.capabilities.map((item) => {
      if (item?.available) {
        capabilities_selected.push(item.id);
      }
    });

    formData?.specialities.map((item) => {
      specialites_selected.push(item.value);
    });

    const params = {
      id: formData?.id,
      name: formData?.name,
      phone: formData?.phone,
      email: formData?.email,
      is_franchise: formData?.franchise ? true : false,
      status_workshop: formData?.status,
      tier: formData?.type?.value ?? null,
      workshop_type: formData?.workshop_type,
      is_otoklix_pickup: formData?.is_otoklix_pickup ? true : false,
      status_mou: formData?.status_mou?.value ?? null,
      facilities: facilities_selected,
      otoklix_pic_name: formData?.pic ?? '',
      description: formData?.description,
      service_warranty: formData?.is_service_warranty ? true : false,
      is_fbo: formData?.is_fbo ? true : false,
      wa_group_link: formData?.wa_group ?? '',
      specialities: specialites_selected,
      capabilities: capabilities_selected,
      is_gms: !!formData?.is_gms,
    };

    params.document_key = keyFile ? keyFile : formData?.document_key;
    if (dataWorkshop?.document?.value && !formData?.document_url) {
      params.document_key = null;
    }

    submitFormGeneralInformation(type, params);
  };

  const submitFormGeneralInformation = (type, params) => {
    api
      .put(`v2/intools/workshops/${formData?.id}/general-info/`, params)
      .then((response) => {
        handleResultNotification(true, 'success', 'Berhasil update informasi bengkel');
        onChangeWorkshopInfo(response?.data?.data ?? {});
        setIsEdit(false);
        setDisableForm(false);
        setDataWorkshop(response?.data?.data ?? {});
        if (type == 'submit') {
          onChangeAction(false, 'information');
        }
      })
      .catch((error) => {
        setDisableForm(false);
        handleResultNotification(true, 'fail', 'Gagal update informasi bengkel');
      });
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (isValidationForm) {
      handleValidateForm();
    }
  }, [isValidationForm]);

  useEffect(() => {
    if (dataWorkshop?.id) {
      defaultDataForm();
    }
  }, [dataWorkshop]);

  useEffect(() => {
    getGeneralInformation();
    getTypeOptions();
    getSpecialityOptions();
  }, []);

  return (
    <React.Fragment>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <Card className="card-custom">
          <CardBody className="p-0">
            <Row>
              <Col lg={12} md={12} sm={12}>
                <Row className="mb-4 font-weight-bold font-18">
                  <span>General</span>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Workshop ID
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <span className="ml-1">{formData?.id ?? '-'}</span>
                  </Label>
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="label-required text-left">
                    Nama Bengkel
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{formData?.name ?? '-'}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <InputField
                      colWidth={6}
                      inputType={'text'}
                      disabled={disableForm}
                      value={formData?.name}
                      invalid={formData?.error_name ? true : false}
                      errorMessage={formData?.error_name ?? ''}
                      placeholder={'Nama Bengkel'}
                      onChange={(e) => {
                        let value = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : formData?.name;
                        formData.name = value;

                        if (value.length > 50) {
                          formData.error_name = 'Field tidak sesuai dengan format, format maksimal 50 karakter';
                        } else if (value.length == 0) {
                          formData.error_name = 'Field tidak boleh kosong';
                        } else {
                          formData.error_name = '';
                        }
                        setFormData({ ...formData });
                      }}
                    ></InputField>
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    No Telp
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{formData?.phone ?? '-'}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <InputField
                      colWidth={6}
                      inputType={'text'}
                      value={formData?.phone}
                      disabled={disableForm}
                      invalid={formData?.error_phone ? true : false}
                      placeholder={'No Telp'}
                      errorMessage={formData?.error_phone ?? ''}
                      onChange={(e) => {
                        let value = e.target.value.replace(/[^0-9]/g, '');
                        value.replace(/(?!^\+)\+/g, '');
                        formData.phone = value;

                        if (value.length > 17) {
                          formData.error_phone = 'Field tidak sesuai dengan format, format maksimal 17 digit';
                        } else {
                          formData.error_phone = '';
                        }
                        setFormData({ ...formData });
                      }}
                    />
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Email
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{!formData?.email ? '-' : formData?.email}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <InputField
                      colWidth={6}
                      inputType={'text'}
                      value={formData?.email}
                      disabled={disableForm}
                      invalid={formData?.error_email ? true : false}
                      placeholder={'Email'}
                      errorMessage={formData?.error_email ?? ''}
                      onChange={(e) => {
                        let value = e.target.value !== '' ? e.target.value.replace(/ +/g, '') : e.target.value;
                        formData.email = value;
                        const emailCheck = validate(value);
                        if (!emailCheck && value) {
                          formData.error_email = 'Format email tidak sesuai';
                        } else {
                          formData.error_email = '';
                        }
                        setFormData({ ...formData });
                      }}
                    />
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="label-required text-left">
                    Bengkel Franchise
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      :{' '}
                      <span className="ml-1">
                        {(formData?.franchise === undefined || formData?.franchise === null) && '-'}
                        {formData?.franchise === true && 'Yes'}
                        {formData?.franchise === false && 'No'}
                      </span>
                    </Label>
                  )}
                  {isEdit && (
                    <Col lg={8} className="p-2">
                      <ActionSwitch
                        customClass="mb-0"
                        color="secondary"
                        inputName={`is_franchise`}
                        isChecked={formData?.franchise ? true : false}
                        editableForm={disableForm}
                        onChange={(e) => handleChangeActionSwitch('franchise', e)}
                      />
                    </Col>
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Otoklix Pickup
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      :{' '}
                      <span className="ml-1">
                        {(formData?.is_otoklix_pickup === undefined || formData?.is_otoklix_pickup === null) && '-'}
                        {formData?.is_otoklix_pickup === true && 'Yes'}
                        {formData?.is_otoklix_pickup === false && 'No'}
                      </span>
                    </Label>
                  )}
                  {isEdit && (
                    <Col lg={8} className="p-2">
                      <ActionSwitch
                        customClass="mb-0"
                        color="secondary"
                        inputName={`is_otoklix_pickup`}
                        isChecked={formData?.is_otoklix_pickup ? true : false}
                        editableForm={disableForm}
                        onChange={(e) => handleChangeActionSwitch('is_otoklix_pickup', e)}
                      />
                    </Col>
                  )}
                </Row>

                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Service Warranty
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      :{' '}
                      <span className="ml-1">
                        {(formData?.is_service_warranty === undefined || formData?.is_service_warranty === null) && '-'}
                        {formData?.is_service_warranty === true && 'Yes'}
                        {formData?.is_service_warranty === false && 'No'}
                      </span>
                    </Label>
                  )}
                  {isEdit && (
                    <Col lg={8} className="p-2">
                      <ActionSwitch
                        customClass="mb-0"
                        color="secondary"
                        inputName={`is_service_warranty`}
                        isChecked={formData?.is_service_warranty ? true : false}
                        editableForm={disableForm}
                        onChange={(e) => handleChangeActionSwitch('is_service_warranty', e)}
                      />
                    </Col>
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    FBO Workshop
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      :{' '}
                      <span className="ml-1">
                        {(formData?.is_fbo === undefined || formData?.is_fbo === null) && '-'}
                        {formData?.is_fbo === true && 'Yes'}
                        {formData?.is_fbo === false && 'No'}
                      </span>
                    </Label>
                  )}
                  {isEdit && (
                    <Col lg={8} className="p-2">
                      <ActionSwitch
                        customClass="mb-0"
                        color="secondary"
                        inputName={`is_fbo`}
                        isChecked={formData?.is_fbo ? true : false}
                        editableForm={disableForm}
                        onChange={(e) => handleChangeActionSwitch('is_fbo', e)}
                      />
                    </Col>
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    GMS Workshop
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      :{' '}
                      <span className="ml-1">
                        {(formData?.is_gms === undefined || formData?.is_gms === null) && '-'}
                        {formData?.is_gms === true && 'Yes'}
                        {formData?.is_gms === false && 'No'}
                      </span>
                    </Label>
                  )}
                  {isEdit && (
                    <Col lg={8} className="p-2">
                      <ActionSwitch
                        customClass="mb-0"
                        color="secondary"
                        inputName={`is_gms`}
                        isChecked={formData?.is_gms ? true : false}
                        editableForm={disableForm}
                        onChange={(e) => handleChangeActionSwitch('is_gms', e)}
                      />
                    </Col>
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Dokumen
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      :{' '}
                      {formData?.document_name && (
                        <span className="ml-1 document-workshop" onClick={handleDownloadDocument}>
                          {formData?.document_name}
                        </span>
                      )}
                      {!formData?.document_name && <span className="ml-1">-</span>}
                    </Label>
                  )}
                  {isEdit && (
                    <Col lg={6} className="text-left text-bold">
                      {formData?.document_name && (
                        <div className="document-box-workshop">
                          <span className="file" onClick={handleDownloadDocument}>
                            {formData?.document_name}
                          </span>
                          <img className="icon-trash-workshop" src={TrashIcon} onClick={handleDeleteDocument} />
                        </div>
                      )}
                      {!formData?.document_name && (
                        <div>
                          {isDragOver && (
                            <div {...getRootProps({ className: 'document-box-workshop drag-over cursor-pointer' })}>
                              <span className="choose-file">Release File</span>
                            </div>
                          )}
                          {!isDragOver && (
                            <div {...getRootProps({ className: 'document-box-workshop cursor-pointer' })}>
                              <span className="choose-file">Drag and Drop or Choose File</span>
                            </div>
                          )}
                        </div>
                      )}
                      <div className="invalid-document">{formData?.error_document}</div>
                      <div className="document-guide">Max upload 10mb</div>
                      <div className="document-guide">jpeg, jpg, png, xls, xlsx, doc, docx, ppt, pptx</div>
                    </Col>
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Specialities
                  </Label>
                  {!isEdit && (
                    <>
                      {!formData?.specialities?.length && (
                        <Label lg={8} className="text-left text-bold">
                          : <span className="ml-1">{'-'}</span>
                        </Label>
                      )}
                      {formData?.specialities?.length > 0 && (
                        <SelectField
                          colWidth={6}
                          name="specialities"
                          placeholder="Pilih Specialities"
                          closeMenuOnSelect={true}
                          value={formData?.specialities}
                          options={optionsSpeciality}
                          disabled={true}
                          isMulti={true}
                        />
                      )}
                    </>
                  )}
                  {isEdit && (
                    <SelectField
                      colWidth={6}
                      name="specialities"
                      placeholder="Pilih Specialities"
                      closeMenuOnSelect={true}
                      value={formData?.specialities}
                      options={optionsSpeciality}
                      disabled={disableForm}
                      isMulti={true}
                      onChange={handleChangeSpecialities}
                    />
                  )}
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Status Bengkel
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      :{' '}
                      <span className="ml-1">
                        {(formData?.status === undefined || formData?.status === null) && '-'}
                        {formData?.status === 'activate' && 'Aktif'}
                        {formData?.status === 'deactivate' && 'Non Aktif'}
                        {formData?.status === 'demo' && 'Demo'}
                      </span>
                    </Label>
                  )}
                  {isEdit && (
                    <Col lg={6} className="col-radio-workshop">
                      <Input
                        type="radio"
                        disabled={disableForm}
                        name="status_active"
                        id="status_active"
                        checked={formData?.status === 'activate' ? true : false}
                        className="radio-workshop cursor-pointer"
                        onChange={() => handleChangeRadio('status', 'activate')}
                      />
                      <span className="ml-2 mr-4 label-radio">{'Aktif'}</span>
                      <Input
                        type="radio"
                        disabled={disableForm}
                        name="status_inactive"
                        id="status_inactive"
                        checked={formData?.status === 'deactivate' ? true : false}
                        className="radio-workshop cursor-pointer"
                        onChange={() => handleChangeRadio('status', 'deactivate')}
                      />
                      <span className="ml-2 mr-4 label-radio">{'Non Aktif'}</span>
                      <Input
                        type="radio"
                        name="status_demo"
                        id="status_demo"
                        disabled={true}
                        checked={formData?.status === 'demo' ? true : false}
                        className="radio-workshop cursor-pointer"
                        onChange={() => handleChangeRadio('status', 'demo')}
                      />
                      <span className="ml-2 label-radio">{'Demo'}</span>
                      <div className="invalid-document">{formData?.error_status}</div>
                    </Col>
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Tipe Bengkel
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{formData?.type?.label ?? '-'}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <SelectField
                      colWidth={6}
                      placeholder={'Pilih Tipe Bengkel'}
                      disabled={disableForm}
                      closeMenuOnSelect={true}
                      options={optionsType}
                      value={formData?.type}
                      onChange={handleChangeType}
                    />
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="label-required text-left">
                    Jenis Bengkel
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      :{' '}
                      <span className="ml-1">
                        {!formData?.workshop_type && '-'}
                        {formData?.workshop_type === 'b2c' && 'B2C'}
                        {formData?.workshop_type === 'b2b' && 'B2B'}
                        {formData?.workshop_type === 'semua' && 'Semua'}
                      </span>
                    </Label>
                  )}
                  {isEdit && (
                    <Col lg={6} className="col-radio-workshop">
                      <Input
                        type="radio"
                        disabled={disableForm}
                        name="type_all"
                        id="type_all"
                        checked={formData?.workshop_type === 'semua' ? true : false}
                        className="radio-workshop cursor-pointer"
                        onChange={() => handleChangeRadio('workshop_type', 'semua')}
                      />
                      <span className="ml-2 mr-4 label-radio">{'Semua'}</span>
                      <Input
                        type="radio"
                        disabled={disableForm}
                        name="type_b2b"
                        id="type_b2b"
                        checked={formData?.workshop_type === 'b2b' ? true : false}
                        className="radio-workshop cursor-pointer"
                        onChange={() => handleChangeRadio('workshop_type', 'b2b')}
                      />
                      <span className="ml-2 mr-4 label-radio">{'B2B'}</span>
                      <Input
                        type="radio"
                        disabled={disableForm}
                        name="type_b2c"
                        id="type_b2c"
                        checked={formData?.workshop_type === 'b2c' ? true : false}
                        className="radio-workshop cursor-pointer"
                        onChange={() => handleChangeRadio('workshop_type', 'b2c')}
                      />
                      <span className="ml-2 label-radio">{'B2C'}</span>
                      <div className="invalid-document">{formData?.error_workshop_type}</div>
                    </Col>
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Rating Bengkel
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <img src={StartRateIcon} className="rating-icon" />{' '}
                    <span className="rating">{formData?.rating_workshop ?? 0}</span>
                    <span className="review ml-1">
                      {formData?.count_rating_workshop ? `(` + formData?.count_rating_workshop + `)` : `(` + 0 + `)`}
                    </span>
                  </Label>
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Rating Google
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <img src={StartRateIcon} className="rating-icon" />{' '}
                    <span className="rating">{formData?.rating_google ?? 0}</span>
                    <span className="review ml-1">
                      {formData?.count_rating_google ? `(` + formData?.count_rating_google + `)` : `(` + 0 + `)`}
                    </span>
                  </Label>
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Status MoU
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{formData?.status_mou?.label ?? '-'}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <SelectField
                      colWidth={6}
                      placeholder={'Pilih Status MoU'}
                      disabled={disableForm}
                      closeMenuOnSelect={true}
                      options={optionsMOU}
                      value={formData?.status_mou}
                      onChange={handleChangeMoU}
                    />
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    PIC Otoklix
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{!formData?.pic ? '-' : formData?.pic}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <InputField
                      colWidth={6}
                      inputType={'text'}
                      disabled={disableForm}
                      value={formData?.pic}
                      invalid={formData?.error_pic ? true : false}
                      errorMessage={formData?.error_pic ?? ''}
                      placeholder={'PIC Otoklix'}
                      onChange={(e) => {
                        let value = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : formData?.pic;
                        formData.pic = value;

                        if (value.length > 21) {
                          formData.error_pic = 'Field tidak sesuai dengan format, format maksimal 21 karakter';
                        } else {
                          formData.error_pic = '';
                        }
                        setFormData({ ...formData });
                      }}
                    />
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    WA Group Link
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{!formData?.wa_group ? '-' : formData?.wa_group}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <InputField
                      colWidth={6}
                      inputType={'text'}
                      disabled={disableForm}
                      value={formData?.wa_group}
                      invalid={formData?.error_wa_group ? true : false}
                      errorMessage={formData?.error_wa_group ?? ''}
                      placeholder={'WA Group Link'}
                      onChange={(e) => {
                        let value = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : formData?.wa_group;
                        formData.wa_group = value;

                        if (value.length > 100) {
                          formData.error_wa_group = 'Field tidak sesuai dengan format, format maksimal 100 karakter';
                        } else {
                          formData.error_wa_group = '';
                        }
                        setFormData({ ...formData });
                      }}
                    />
                  )}
                </Row>
                <Row className="mb-4">
                  <Label lg={4} className="text-left">
                    Blacklisted by
                  </Label>
                  <>
                    {formData?.blacklist_by?.length == 0 && (
                      <Label lg={8} className="text-left text-bold">
                        : <span className="ml-1">{'-'}</span>
                      </Label>
                    )}
                    {formData?.blacklist_by?.length !== 0 && (
                      <SelectField
                        colWidth={6}
                        name="blacklist_by"
                        placeholder="Pilih"
                        isMulti={true}
                        closeMenuOnSelect={false}
                        value={formData?.blacklist_by}
                        options={[]}
                        disabled={true}
                      />
                    )}
                  </>
                </Row>
              </Col>
              <Col sm={12} md={12}>
                <Row className="mb-4 mt-3">
                  <Label lg={2} className="text-left">
                    Fasilitas
                  </Label>
                  <Col lg={10} className="text-left">
                    {formData?.facilities && formData?.facilities?.length !== 0 && (
                      <Row>
                        {formData?.facilities.map((item) => {
                          return (
                            <Col key={item?.id} md={6} lg={3} className="otopoints-checkbox">
                              {isEdit && (
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    className="cursor-pointer"
                                    disabled={disableForm}
                                    checked={item?.available ? true : false}
                                    onChange={() => handleFacilityChange(item)}
                                  />
                                  <Label className="text-left ml-2">{item?.name}</Label>
                                </FormGroup>
                              )}
                              {!isEdit && (
                                <div>
                                  <img
                                    src={getIconFacilites(item)}
                                    className={!item.available ? 'facility-icon facility-disabled' : 'facility-icon'}
                                  />
                                  <Label
                                    className={
                                      !item.available
                                        ? 'text-left ml-2 facility-text facility-text-disabled'
                                        : 'text-left ml-2 facility-text text-bold'
                                    }
                                  >
                                    {item?.name}
                                  </Label>
                                </div>
                              )}
                            </Col>
                          );
                        })}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={12}>
                <Row className="mb-4 mt-3">
                  <Label lg={2} className="text-left">
                    Capabilities
                  </Label>
                  <Col lg={10} className="text-left">
                    {formData?.capabilities && formData?.capabilities?.length !== 0 && (
                      <Row>
                        {formData?.capabilities.map((item) => {
                          return (
                            <Col key={item?.id} md={6} lg={3} className="otopoints-checkbox">
                              {isEdit && (
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    className="cursor-pointer"
                                    disabled={disableForm || item?.inventory}
                                    checked={item?.available ? true : false}
                                    onChange={() => handleCapabiltyChange(item)}
                                  />
                                  <Label className="text-left ml-2">{item?.name}</Label>
                                </FormGroup>
                              )}
                              {!isEdit && (
                                <div>
                                  <img
                                    src={!item.available ? NotAvailableIcon : AvailableIcon}
                                    className={!item.available ? 'facility-icon facility-disabled' : 'facility-icon'}
                                  />
                                  <Label
                                    className={
                                      !item.available
                                        ? 'text-left ml-2 facility-text facility-text-disabled'
                                        : 'text-left ml-2 facility-text'
                                    }
                                  >
                                    {item?.name}
                                  </Label>
                                </div>
                              )}
                            </Col>
                          );
                        })}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={12}>
                <Row className="mb-4">
                  <Label lg={2} className="text-left">
                    Description
                  </Label>
                  {isEdit && (
                    <InputField
                      colWidth={10}
                      inputType="textarea"
                      disabled={disableForm}
                      value={formData?.description}
                      invalid={formData?.error_description ? true : false}
                      name={'description'}
                      placeholder={'Description'}
                      errorMessage={formData?.error_description ?? ''}
                      onChange={(e) => {
                        let value =
                          e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : formData?.description;
                        formData.description = value;

                        if (value.length > 2000) {
                          formData.error_description =
                            'Field tidak sesuai dengan format, format maksimal 2000 karakter';
                        } else {
                          formData.error_description = '';
                        }
                        setFormData({ ...formData });
                      }}
                    ></InputField>
                  )}

                  {!isEdit && (
                    <Label lg={10} className="text-left">
                      <div style={{ position: 'absolute', fontWeight: 'bold' }}>:</div>
                      <div style={{ marginLeft: '0.7rem', fontWeight: 'bold' }}>{formData?.description ?? '-'}</div>
                    </Label>
                  )}
                </Row>
              </Col>
              {allowEdit && (
                <Col sm={12} md={12} className="section-action">
                  <Button
                    disabled={disableForm}
                    className="button-action save"
                    onClick={() => {
                      if (!disableForm) {
                        handleAction('save');
                      }
                    }}
                  >
                    {isEdit ? 'Simpan' : 'Edit Data'}
                  </Button>
                  {isEdit && (
                    <Button
                      disabled={disableForm}
                      className="button-action cancel"
                      onClick={() => {
                        if (!disableForm) {
                          handleAction('cancel');
                        }
                      }}
                    >
                      {'Batal'}
                    </Button>
                  )}
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default Information;
