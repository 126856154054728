import API from '@utils/API';

class CustomerService {
  Api() {
    return new API('v2');
  }

  async getCustomerGroupOptions() {
    try {
      return await this.Api().get('/v2/intools/customer-group/');
    } catch (error) {
      throw error.response || new Error('Unexpected error occurred');
    }
  }
}

export default new CustomerService();
