import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Label } from 'reactstrap';
import SelectField from '@components/field/SelectField';

const PackageConfiguration = (props) => {
  const { modal, toggle, componentOptions, productTypeOptions, onSubmit } = props;

  const [component, setComponent] = useState();
  const [productType, setProductType] = useState();
  const [errorComponent, setErrorComponent] = useState('');
  const [errorProductType, setErrorProductType] = useState('');

  const handleChangeComponent = (e) => {
    setErrorComponent('');
    setComponent(e ?? null);
  };

  const handleChangeProductType = (e) => {
    setErrorProductType('');
    setProductType(e ?? null);
  };

  const handleSubmit = () => {
    let isValid = true;
    if (!component) {
      isValid = false;
      setErrorComponent('Component Name harus diisi');
    }
    if (!productType) {
      isValid = false;
      setErrorProductType('Product Type harus diisi');
    }

    if (isValid) {
      submitForm();
    }
  };

  async function submitForm() {
    const resultData = {
      component: component,
      product_type: productType,
    };
    onSubmit(resultData);
    toggle();
  }

  const resetData = () => {
    setComponent();
    setProductType();
    setErrorComponent('');
    setErrorProductType('');
  };

  const cancel = () => {
    toggle();
  };

  useEffect(() => {
    resetData();
  }, [modal]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Component Configuration</div>
        <div className="content">
          <FormGroup row>
            <Label lg={4} className="label-required">
              Component Name
            </Label>
            <SelectField
              colWidth={8}
              name="component"
              placeholder="Component Name"
              closeMenuOnSelect={true}
              value={component}
              options={componentOptions}
              errorMessage={errorComponent ?? ''}
              onChange={(e) => {
                handleChangeComponent(e);
              }}
            />
          </FormGroup>
          <FormGroup row className="mb-0">
            <Label lg={4} className="label-required">
              Product Type
            </Label>
            <SelectField
              colWidth={8}
              name="product_type"
              placeholder="Product Type"
              closeMenuOnSelect={true}
              value={productType}
              options={productTypeOptions}
              errorMessage={errorProductType ?? ''}
              onChange={(e) => {
                handleChangeProductType(e);
              }}
            />
          </FormGroup>
        </div>

        <div className="action mb-3 mt-3">
          <Button className="button-cancel" onClick={cancel}>
            Batal
          </Button>
          <Button className="button-action" onClick={handleSubmit}>
            Tambah
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PackageConfiguration;
