import React from 'react';
import { Form, FormGroup, Input } from 'reactstrap';

const ActionFilterACL = (props) => {
  const { value, handleChangeValue } = props;

  return (
    <div className="section-filters">
      <Form>
        <FormGroup>
          <Input
            className={`text-field-filter w-261 ${value?.length > 0 ? 'filled' : ''}`}
            placeholder="Search"
            value={value}
            onChange={(e) => handleChangeValue(e.target.value)}
          />
        </FormGroup>
      </Form>
    </div>
  );
};

export default ActionFilterACL;
