import React from 'react';
import StarIcon from '@assets/icons/star-rate.svg';

const StarRating = ({ rating, disabled, width }) => {
  if (rating > 0) {
    let ratings = [];
    for (let i = 0; i < rating; i++) {
      ratings.push(i);
    }
    return (
      <div>
        {ratings.map((item) => (
          <img
            key={item}
            src={StarIcon}
            className={disabled ? 'disabled-rating' : ''}
            style={width ? { width: `${width}px` } : {}}
          />
        ))}
      </div>
    );
  } else {
    return '-';
  }
};
export default StarRating;
