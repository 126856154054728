import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Col, Button, Form, Label, Input } from 'reactstrap';

const JiraTicketLinkModal = (props) => {
  const { modal, toggle, jiraUrl, onChangeJiraUrl, onSubmit } = props;

  return (
    <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggle}>
      <div className="mt-4 mb-4 text-center">
        <p className="text-bold" style={{ fontSize: '16px' }}>
          Integrasi Jira Ticket Links
        </p>
      </div>
      <ModalBody>
        <Form>
          <div className="row">
            <Label lg={3}>Jira Ticket Links</Label>
            <Col sm={8}>
              <Input placeholder="Masukan Jira Ticket Links" onChange={onChangeJiraUrl} value={jiraUrl} />
            </Col>
          </div>
        </Form>
        <Form>
          <div style={{ fontSize: '16px' }} className="mt-4">
            <Col className="col">
              <p className="text-decoration-underline info">Catatan:</p>
              <ul className="info" style={{ fontSize: '12px', margin: '0' }}>
                <li>
                  Pastikan kode booking ini sesuai dengan <i>Jira Ticket Links</i> yang anda isi
                </li>
                <li>
                  Ketika <i>Jira Ticket Links</i> sudah diisi, maka data booking akan mengisi fields yang ada di Jira
                </li>
              </ul>
            </Col>
          </div>
        </Form>
      </ModalBody>

      <div className="mt-5 mb-4 text-center">
        <Button color="primary" onClick={toggle}>
          Batalkan
        </Button>{' '}
        <Button className="ml-2" color="secondary" onClick={onSubmit}>
          Simpan & Integrasi
        </Button>
      </div>
    </Modal>
  );
};

export default JiraTicketLinkModal;
