class ParseItemCart {
  parsePackage(datas, workshopId) {
    const customerData = datas.find((item) => item.workshop_id === workshopId)?.customer;

    const packages = customerData?.items?.reduce((acc, item) => {
      const { package_id: packageId, package_name: packageName, final_sell_price: finalSellPrice } = item;

      if (packageId) {
        const existingPackage = acc.find((pkg) => pkg.package_id === packageId);

        if (existingPackage) {
          existingPackage.package_items.push(item);
          existingPackage.subtotal += finalSellPrice;
        } else {
          acc.push({
            package_id: packageId,
            name: packageName,
            subtotal: finalSellPrice,
            package_items: [item],
          });
        }
      }

      return acc;
    }, []);

    return packages;
  }

  parseProductService(datas, workshopId) {
    const customerData = datas.find((item) => item.workshop_id === workshopId)?.customer;

    let subtotalProduct = 0;
    let subtotalService = 0;

    const products = {
      subtotal: subtotalProduct,
      items: [],
    };

    const services = {
      subtotal: subtotalService,
      items: [],
    };

    customerData?.items?.forEach((item) => {
      const { item_type: itemType, final_sell_price: finalSellPrice } = item;
      const commonItemData = {
        name: item?.name || '',
        qty: item?.qty || 1,
        sell_price: item?.price,
        final_sell_price: item?.final_sell_price,
        discount_amount: item?.discount_amount,
        promo_code: item?.promo_code,
        voucher_amount: item?.voucher_amount,
        markup_price: item?.markup_price,
        remark: item?.note,
      };

      if (item?.product_id && item?.product_id !== 'mock_item') {
        if (itemType === 'part') {
          subtotalProduct += finalSellPrice;
          products.items.push(commonItemData);
        } else if (itemType === 'work') {
          subtotalService += finalSellPrice;
          services.items.push(commonItemData);
        }
      }
    });

    products.subtotal = subtotalProduct;
    services.subtotal = subtotalService;

    return {
      product: products,
      service: services,
    };
  }

  parsePriceDetail(datas, workshopId) {
    const customerData = datas.find((item) => item.workshop_id === workshopId)?.customer;

    return {
      tax: customerData?.tax,
      service_tax: customerData?.service_tax,
      price_before_tax: customerData?.price_before_tax,
      price_after_tax: customerData?.price_after_tax,
      total_amount: customerData?.total_amount,
      discount: customerData?.discount,
      otopoints: customerData?.otopoints,
    };
  }

  parseItems(datas, workshopId) {
    const noteTemplate = {
      ganti_aki:
        '\tMerk dan Tipe ?\n\tBasah/Kering/Hybrid ?\n\tGaransi ?\n\tTrade in atau tidak  ?\n\tNominal Trade in ?',
      ganti_ban: '\tMerk dan Tipe ?\n\tTanggal Produksi ?\n\t*Harga Sudah Termasuk Pentil, Nitrogen, Balancing',
      general: '\tMerk dan Tipe ?\n\tGaransi ?',
      custom: '\tMerk dan Tipe ?\n\tGaransi ?',
    };

    const workshopItems = datas.find((item) => item.workshop_id === workshopId)?.workshop;
    const allItems = [];
    const parseItems = workshopItems?.items?.reduce((acc, item) => {
      const { package_id: packageId, package_name: packageName, total_workshop_price: totalWorkshopPrice } = item;

      if (packageId) {
        const existingPackage = acc.find((pkg) => pkg.package_id === packageId);

        if (existingPackage) {
          existingPackage.items.push(item);
          existingPackage.subtotal += totalWorkshopPrice;
        } else {
          acc.push({
            package_id: packageId,
            name: packageName,
            qty: 1,
            subtotal: totalWorkshopPrice,
            items: [item],
          });
        }
      }

      return acc;
    }, []);

    parseItems.forEach((item) => {
      allItems.push(item);
    });

    workshopItems?.items?.forEach((item) => {
      const { total_workshop_price: workshopPrice } = item;
      if (item?.product_id && item?.product_id !== 'mock_item') {
        var note = '';
        if (item?.item_type?.toLowerCase() !== 'work') {
          if (item?.is_custom) {
            note = noteTemplate.custom;
          } else if (item?.component_name?.toLowerCase() === 'ganti aki') {
            note = noteTemplate.ganti_aki;
          } else if (item?.component_name?.toLowerCase() === 'ganti ban') {
            note = noteTemplate.ganti_ban;
          } else if (item?.name.split(' ')[0].toLowerCase() === 'general') {
            note = noteTemplate.general;
          }
        }

        allItems.push({
          product_id: item?.product_id,
          name: item?.name,
          qty: item?.qty,
          is_wi: item?.is_wi,
          is_custom: item?.is_custom,
          component_name: item?.component_name,
          subtotal: workshopPrice,
          note: note,
          is_override: item?.is_override_nett,
          items: [],
        });
      }
    });

    return allItems;
  }
}

export default new ParseItemCart();
