import React, { useState, useEffect } from 'react';
import { Col, Label, Row, Button } from 'reactstrap';
import InputField from '@components/field/InputField';
import CustomModal from '@components/modal/CustomModal';
import ConfirmationModal from '@components/modal/ConfirmationModal';

const SubscribeChangeStatus = (props) => {
  const { showModal, subscribeData, dataForm, onConfirm, onClose } = props;
  const [showModalWithoutReason, setShowModalWithoutReason] = useState(false);
  const [showModalWithReason, setShowModalWithReason] = useState(false);
  const [notes, setNotes] = useState('');
  const [errorNotes, setErrorNotes] = useState('');

  const handleTypeModal = (e) => {
    if (showModal) {
      let needReason = false;
      needReason =
        (subscribeData?.status?.value == 'pending' && dataForm?.status?.value == 'active') || needReason ? true : false;
      needReason =
        (subscribeData?.status?.value == 'pending' && dataForm?.status?.value == 'cancel') || needReason ? true : false;
      needReason =
        (subscribeData?.status?.value == 'active' && dataForm?.status?.value == 'cancel') || needReason ? true : false;
      needReason =
        (subscribeData?.status?.value == 'grace_period' && dataForm?.status?.value == 'expired') || needReason
          ? true
          : false;

      if (needReason) {
        setShowModalWithoutReason(false);
        setShowModalWithReason(true);
      } else {
        setShowModalWithReason(false);
        setShowModalWithoutReason(true);
      }
    } else {
      setShowModalWithReason(false);
      setShowModalWithoutReason(false);
    }
  };

  const defaultValueForm = () => {
    setNotes('');
    setErrorNotes('');
  };

  const handleCloseModal = () => {
    setShowModalWithReason(false);
    setShowModalWithoutReason(false);
    onClose();
  };

  const handleConfirmModal = () => {
    if (showModalWithReason) {
      const valid = validateFormReason();
      if (valid) {
        onConfirm({ type: 1, notes: notes });
      }
    } else {
      onConfirm({ type: 2 });
    }
  };

  const validateFormReason = () => {
    let valid = false;
    if (notes.length == 0) {
      setErrorNotes('Catatan harus diisi');
    } else if (notes.length > 2000) {
      setErrorNotes('Catatan tidak sesuai dengan format, format maksimal 2000 karakter');
    } else {
      setErrorNotes('');
      valid = true;
    }

    return valid;
  };

  useEffect(() => {
    handleTypeModal();
    defaultValueForm();
  }, [showModal]);

  return (
    <React.Fragment>
      {showModal && (
        <>
          <ConfirmationModal
            modal={showModalWithoutReason}
            toggle={handleCloseModal}
            header={'Yakin Ingin Mengubah Status Paket Langganan ini?'}
            onConfirm={handleConfirmModal}
            toggleClose={handleCloseModal}
          ></ConfirmationModal>
          <CustomModal modal={showModalWithReason} modalWidth="425" backdrop="static" toggle={handleCloseModal}>
            <div className="header">
              {'Yakin Ingin Mengubah Status'}
              <br />
              {' Paket Langganan ini?'}
            </div>
            <Row className="mt-4">
              <Col sm={12} md={12}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    ID Langganan
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    : <span className="ml-1">{subscribeData?.id ?? '-'}</span>
                  </Label>
                </Row>
              </Col>
              <Col sm={12} md={12}>
                <Row>
                  <Label lg={4} className="label-required text-left">
                    Catatan
                  </Label>
                  <InputField
                    className={'field-textarea-status'}
                    colWidth={8}
                    inputType="textarea"
                    name={'description'}
                    placeholder={'Masukkan alasan perubahan status...'}
                    value={notes}
                    invalid={errorNotes ? true : false}
                    errorMessage={errorNotes ?? ''}
                    onChange={(e) => {
                      let value = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : notes;
                      setNotes(value);

                      if (value.length > 2000) {
                        setErrorNotes('Catatan tidak sesuai dengan format, format maksimal 2000 karakter');
                      } else if (value.length == 0) {
                        setErrorNotes('Catatan harus diisi');
                      } else {
                        setErrorNotes('');
                      }
                    }}
                  ></InputField>
                </Row>
              </Col>
            </Row>
            <div className="action">
              <Button className="button-action-modal primary" onClick={handleCloseModal}>
                Tidak
              </Button>
              <Button className="button-action-modal primary" onClick={handleConfirmModal}>
                Ya
              </Button>
            </div>
          </CustomModal>
        </>
      )}
    </React.Fragment>
  );
};

export default SubscribeChangeStatus;
