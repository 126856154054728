import React, { useState, useEffect, useMemo } from 'react';
import { Col, Button, Row } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import API from '@utils/API';
import Loading from '@components/loading/Loading';
import userPermission from '@utils/userPermission';
import DataTable from 'react-data-table-component';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import Helper from '@helpers/Helper';
import deleteIcon from '@assets/icons/trash.svg';
import editIcon from '@assets/icons/edit.svg';
import GroupCampaignAdd from '@components/campaigns/groupCampaign/GroupCampaignAdd';

const GroupCampaignList = (props) => {
  const { onResultNotification } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [modalAddGroup, setModalAddGroup] = useState(false);
  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);
  const keys = ['search'];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });

  const allowPermission = (menu) => {
    return !!(generatePermission('campaign', menu) || generatePermission('campaign', 'all'));
  };

  const allowList = allowPermission('groupall') || allowPermission('grouplist');
  const allowSearch = allowPermission('groupall') || allowPermission('groupsearch');
  const allowCreate = allowPermission('groupall') || allowPermission('groupcreate');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        name: 'Nama Grup Campaign',
        selector: (row) => row?.name ?? '-',
        minWidth: '700px',
        sortable: true,
      },
      // {
      //   name: 'Aksi',
      //   minWidth: '100px',
      //   selector: (row) => (
      //     <Row>
      //       <Col md={6}>
      //         <img src={editIcon} height={16} />
      //       </Col>
      //       <Col md={6}>
      //         <img src={deleteIcon} height={16} />
      //       </Col>
      //     </Row>
      //   ),
      // },
    ],
    []
  );

  const dataFilter = [
    {
      id: 'filter_text_search',
      type: 'text',
      name: 'search',
      name_field: 'search',
      value: tempFiltered?.search ?? '',
      placeholder: 'Cari Nama Grup Campaign',
    },
  ];

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchCampaign() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/campaigns/groups/`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      let msg = error?.response?.data?.error?.message ?? '';
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleResultNotification(true, 'fail', 'Gagal mendapatakan list grup campaign ' + msg);
    }
  }

  const getParamData = () => {
    let params = {
      query: filtered?.search ?? '',
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };
  const handleChangeSorting = (column, sortDirection) => {
    setSortingData({
      sort: column?.sortField ?? '',
      order: sortDirection ?? '',
    });
  };

  const handleResetFilters = () => {
    tempFiltered = {};
    resetDataFilter();
  };

  const resetDataFilter = () => {
    const updatedDataFilter = dataFilter.map((filter) => ({
      ...filter,
      value: typeof filter?.value !== '' ? '' : filter?.value,
    }));
    setFilters(updatedDataFilter);
    setFiltered({});
    tempFiltered = {};
  };

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
    };
    console.log(`setfiltered`, e);
    setFiltered(tempVal);
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleAddCampaignGroup = () => {
    setModalAddGroup(true);
  };

  const handleSubmitAddGroupCampaign = (status, error) => {
    const errorMsg = error ? 'Gagal Tambah Grup Campaign, ' + error : 'Gagal Tambah Grup Campaign';
    if (status) {
      handleMethodFetchCampaign();
    }
    setModalAddGroup(false);
    handleResultNotification(true, status ? 'success' : 'fail', status ? 'Berhasil Tambah Grup Campaign' : errorMsg);
  };

  const handleDetailCampaign = (row) => {
    if (allowShow) {
      // history.push(`/promos/${row?.promo_code}`);
    }
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodFetchCampaign();
  };

  const handleMethodFetchCampaign = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchCampaign();
    }
  };

  const resetCampaign = () => {
    setResetSorting(true);
    setSortingData({});
  };

  useEffect(() => {
    setFiltered({ ...tempFiltered });
  }, Object.values(tempFiltered));

  useEffect(() => {
    if (filtered) {
      let tempSetFiltered = { ...filtered };
      tempSetFiltered = {
        ...filtered,
      };
      const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
      history.push(`/campaigns?activetab=group&${urlParam}`);
      fetchCampaign();
    }
    //resetBookings();
  }, [filtered]);

  useEffect(() => {
    if (page && pageLimit) {
      if (allowList) fetchCampaign();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    resetCampaign();
  }, [filtered]);

  useEffect(() => {
    setFilters(dataFilter);
  }, []);

  return (
    <React.Fragment>
      <GroupCampaignAdd
        modal={modalAddGroup}
        toggle={() => setModalAddGroup(!modalAddGroup)}
        onSubmit={handleSubmitAddGroupCampaign}
      />
      <Col sm={12} md={12} className="p-0 mt-0 section-action">
        {allowCreate && (
          <Button disabled={loading} className="button-action add w-170" onClick={handleAddCampaignGroup}>
            {'Create Campaign Group'}
          </Button>
        )}
      </Col>
      {filters?.length !== 0 && allowSearch && allowList && (
        <Col sm={12} md={12} className="p-0 mt-4">
          <ActionFilterCustom
            filters={filters}
            loading={loading}
            onApplyFilters={(e) => handleSetFiltered(e)}
            onResetFilters={handleResetFilters}
          ></ActionFilterCustom>
        </Col>
      )}

      <Col sm={12} md={12} className="p-0 mt-4">
        <DataTable
          persistTableHead
          highlightOnHover
          noHeader={true}
          progressPending={loading}
          data={data}
          className="table dt-responsive table-custom"
          noDataComponent={noDataComponent}
          progressComponent={<Loading />}
          customStyles={customStylesHeaderTable}
          // onRowClicked={(row) => handleDetailCampaign(row)}
          columns={allowList ? columns : []}
        />
      </Col>
      <Col sm={12} md={12} className="p-0 mt-2">
        {data?.length >= 1 && !loading && allowList && (
          <CustomPagination
            page={page}
            pageLimit={pageLimit}
            totalRows={data?.length ?? 0}
            totalAllRows={totalRows}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
            handleChangeRowPerPage={handleChangeRowPerPage}
          ></CustomPagination>
        )}
      </Col>
    </React.Fragment>
  );
};

export default GroupCampaignList;
