import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import SuccessIcon from '@assets/icons/success-workshop.svg';
import FailIcon from '@assets/icons/fail-workshop.svg';

const NotificationAlert = (props) => {
  const { isShow, notification, onClose } = props;

  const closeNotification = () => {
    onClose();
  };

  return (
    <React.Fragment>
      {isShow && (
        <Row>
          <Col sm={12} lg={4}></Col>
          <Col sm={12} lg={4} className="alert-notification">
            {notification?.type && (
              <Alert
                className={`${notification?.type === 'success' ? 'success' : 'fail'} d-flex align-items-start`}
                toggle={closeNotification}
              >
                <img src={notification?.type === 'success' ? SuccessIcon : FailIcon} className="icon" />
                <div className="text" dangerouslySetInnerHTML={{ __html: notification?.message ?? '' }}></div>
              </Alert>
            )}
          </Col>
          <Col sm={12} lg={4}></Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default NotificationAlert;
