import React, { useState, useEffect, useMemo } from 'react';
import { Col, Button } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import API from '@utils/API';
import Loading from '@components/loading/Loading';
import userPermission from '@utils/userPermission';
import DataTable from 'react-data-table-component';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import moment from 'moment';
import Helper from '@helpers/Helper';
import { map } from 'lodash';
import ItemsModal from '@components/modal/ItemsModal';

const CampaignList = (props) => {
  const { onResultNotification, activetab } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [showModalItems, setShowModalItems] = useState(false);
  const [modalData, setModalData] = useState({});
  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);
  const keys = ['search', 'voucher_code', 'pic'];

  let tempFiltered = {};
  let saveParams = 0;
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });

  const allowPermission = (menu) => {
    return !!(generatePermission('campaign', menu) || generatePermission('campaign', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowSearch = allowPermission('all') || allowPermission('search');
  const allowCreate = allowPermission('all') || allowPermission('create');
  const allowShow = allowPermission('all') || allowPermission('detailshow');

  const conditionalRowStyles = [
    {
      when: (row) => row?.vouchers?.length > 3,
      style: {
        minHeight: '100px',
      },
    },
    {
      when: (row) => row?.vouchers?.length <= 3 && row?.vouchers?.length >= 2,
      style: {
        minHeight: '70px',
      },
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'Nama Campaign',
        selector: (row) => row?.campaign_name ?? '-',
        minWidth: '300px',
        sortable: true,
      },
      {
        name: 'Voucher Code',
        selector: (row) => (row?.vouchers?.length > 0 ? row?.vouchers[0] : ''),
        cell: (row) => (
          <p className="my-1">
            {map(row?.vouchers, (item, index) => {
              const itemLength = row?.vouchers.length - 1;
              const itemToShow = 3;

              return (
                <>
                  <span>{index < itemToShow ? item : null}</span>
                  {itemLength !== index && index < itemToShow ? <br /> : null}
                </>
              );
            })}
            <a
              onClick={() => {
                handleClickCarModel(row?.vouchers);
              }}
              style={{ color: '#0D6EFD', textDecoration: 'underline' }}
            >
              {row?.vouchers?.length > 3 ? `+${row?.vouchers?.length - 3} Lainnya` : null}
            </a>
          </p>
        ),
        sortable: true,
        wrap: true,
        minWidth: '200px',
        sortField: 'car_model',
      },
      {
        name: 'PIC',
        selector: (row) => row?.pic_name ?? '-',
        minWidth: '200px',
        sortable: true,
      },
      {
        name: 'Tanggal Mulai',
        minWidth: '170px',
        selector: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.start_date ?? null).isValid() ? moment(row?.start_date).format('DD MMM, YYYY') : '-'}
            </span>
            <span className="time">
              {moment(row?.start_date ?? null).isValid() ? moment(row?.start_date).format('HH:mm:ss') : '-'}
            </span>
          </div>
        ),
      },
      {
        name: 'Tanggal Berakhir',
        minWidth: '170px',
        selector: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.end_date ?? null).isValid() ? moment(row?.end_date).format('DD MMM, YYYY') : '-'}
            </span>
            <span className="time">
              {moment(row?.end_date ?? null).isValid() ? moment(row?.end_date).format('HH:mm:ss') : '-'}
            </span>
          </div>
        ),
      },
      {
        name: 'Status',
        minWidth: '80px',
        selector: (row) =>
          row?.is_active ? (
            <span className="badge-custom badge-custom-success">Aktif</span>
          ) : (
            <span className="badge-custom badge-custom-danger">Tidak Aktif</span>
          ),
      },
    ],
    []
  );

  const statusOptions = [
    { value: '', label: 'Semua Status' },
    { value: 'active', label: 'Aktif' },
    { value: 'inactive', label: 'Tidak Aktif' },
  ];

  const dataFilter = [
    {
      id: 'filter_text_search',
      type: 'text',
      name: 'search',
      name_field: 'search',
      value: tempFiltered?.search ?? '',
      placeholder: 'Nama Campaign',
    },
    {
      id: 'filter_text_voucher_code',
      type: 'text',
      name: 'voucher_code',
      name_field: 'voucher_code',
      value: tempFiltered?.voucher_code ?? '',
      placeholder: 'Voucher Code',
    },
    {
      id: 'filter_text_pic',
      type: 'text',
      name: 'pic',
      name_field: 'pic',
      value: tempFiltered?.pic ?? '',
      placeholder: 'PIC',
    },
    {
      id: 'filter_date_date',
      type: 'date',
      name: 'date',
      name_field: 'date',
      date_range: true,
      value: '',
      placeholder: 'Active Campaign',
    },
    {
      id: 'filter_select_status',
      type: 'select',
      name: 'status',
      name_field: 'status',
      options: statusOptions,
      value: '',
      placeholder: 'Status',
    },
  ];

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchCampaign() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/campaigns/`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      let msg = error?.response?.data?.error?.message ?? '';
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleResultNotification(true, 'fail', 'Gagal mendapatakan list campaign ' + msg);
    }
  }

  const getParamData = () => {
    let params = {
      query: filtered?.search ?? '',
      voucher_code: filtered?.voucher_code ?? '',
      pic_name: filtered?.pic ?? '',
      start_date: filtered?.date?.start_date ? moment(filtered?.date?.start_date).format('YYYY-MM-DD') : null,
      end_date: filtered?.date?.end_date ? moment(filtered?.date?.end_date).format('YYYY-MM-DD') : null,
      status: filtered?.status ?? null,
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const handleClickCarModel = (data) => {
    setShowModalItems(true);
    let datas = [];
    map(data, (item) => {
      datas.push({ label: item });
    });
    setModalData(datas);
  };

  const handleChangeSorting = (column, sortDirection) => {
    setSortingData({
      sort: column?.sortField ?? '',
      order: sortDirection ?? '',
    });
  };

  const handleResetFilters = () => {
    tempFiltered = {};
    resetDataFilter();
  };

  const resetDataFilter = () => {
    const updatedDataFilter = dataFilter.map((filter) => ({
      ...filter,
      value: typeof filter?.value !== '' ? '' : filter?.value,
    }));
    setFilters(updatedDataFilter);
    setFiltered({});
    tempFiltered = {};
  };

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
    };
    console.log(`setfiltered`, e);
    setFiltered(tempVal);
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleAddCampaign = () => {
    history.push('/campaigns/campaign/create/');
  };

  const handleDetailCampaign = (row) => {
    if (allowShow) {
      history.push(`/campaigns/campaign/detail/${row?.id}`);
    }
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodFetchCampaign();
  };

  const handleMethodFetchCampaign = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchCampaign();
    }
  };

  const resetCampaign = () => {
    setResetSorting(true);
    setSortingData({});
  };

  useEffect(() => {
    setFiltered({ ...tempFiltered });
  }, Object.values(tempFiltered));

  useEffect(() => {
    if (filtered) {
      if (saveParams == 0) {
        saveParams = 1;
        let tempSetFiltered = { ...filtered };
        tempSetFiltered = {
          ...filtered,
        };
        const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
        history.push(`/campaigns?${urlParam}`);
      }
      //fetchCampaign();
    }
  }, [filtered]);

  useEffect(() => {
    if (page && pageLimit) {
      if (allowList) fetchCampaign();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    resetCampaign();
  }, [filtered]);

  useEffect(() => {
    setFilters(dataFilter);
  }, []);

  return (
    <React.Fragment>
      <Col sm={12} md={12} className="p-0 mt-0 section-action">
        {allowCreate && (
          <Button disabled={loading} className="button-action add w-170" onClick={handleAddCampaign}>
            {'Create New Campaign'}
          </Button>
        )}
      </Col>
      {filters?.length !== 0 && allowSearch & allowList && (
        <>
          <Col sm={12} md={12} className="p-0 mt-4">
            <ActionFilterCustom
              filters={filters}
              loading={loading}
              onApplyFilters={(e) => handleSetFiltered(e)}
              onResetFilters={handleResetFilters}
            ></ActionFilterCustom>
          </Col>
        </>
      )}

      <Col sm={12} md={12} className="p-0 mt-4">
        <DataTable
          persistTableHead
          highlightOnHover
          noHeader={true}
          progressPending={loading}
          data={data}
          className="table dt-responsive table-custom"
          noDataComponent={noDataComponent}
          conditionalRowStyles={conditionalRowStyles}
          progressComponent={<Loading />}
          onRowClicked={(row) => handleDetailCampaign(row)}
          columns={allowList ? columns : []}
        />
      </Col>
      <Col sm={12} md={12} className="p-0 mt-2">
        {data?.length >= 1 && !loading && allowList && (
          <CustomPagination
            page={page}
            pageLimit={pageLimit}
            totalRows={data?.length ?? 0}
            totalAllRows={totalRows}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
            handleChangeRowPerPage={handleChangeRowPerPage}
          ></CustomPagination>
        )}
      </Col>
      <ItemsModal
        data={modalData}
        modal={showModalItems}
        title={'List Voucher Code'}
        placeholder={'Voucher Code'}
        labelData={'Voucher Code'}
        toggle={() => {
          setShowModalItems(!showModalItems);
        }}
      />
    </React.Fragment>
  );
};

export default CampaignList;
