import React from 'react';
import map from 'lodash/map';
import TrashIcon from '@assets/icons/trash-v2.svg';
import EditableInputField from '@components/field/EditableInputField';
import { Row, Label, Col, Input, FormFeedback } from 'reactstrap';

function StakeholderAddAdmins(props) {
  const { dataItems, addAdminsDisable, handleAdminChange, handleAddAdmins, handleDelete } = props;

  const onChangeAdmin = (value, key, index) => {
    handleAdminChange(value, key, index);
  };

  const dataNotDeleted = () => {
    const data = dataItems
      .filter((data) => data.is_deleted == false)
      .map((item, index) => {
        return {
          name: item.owner_name,
        };
      });
    if (data.length >= 10) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {map(dataItems, (item, index) => {
        if (dataItems[index].is_deleted == false) {
          return (
            <Row key={`admin-edit-${index}`}>
              <Col sm={12} md={6}>
                <EditableInputField
                  textLeft
                  required
                  labelWidth={3}
                  colWidth={8}
                  title="Name"
                  placeholder={'Name'}
                  invalid={item.error_message_name ? true : false}
                  errorMessage={item.error_message_name}
                  value={item?.admin_name}
                  onChange={(e) => onChangeAdmin(e.target.value, 'admin_name', index)}
                />
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={3} className="text-left">
                    Phone No
                  </Label>
                  <Col lg={8} sm={11}>
                    <Input
                      type={'text'}
                      value={item?.admin_phone}
                      invalid={item.error_message_phone ? true : false}
                      placeholder={'Phone No'}
                      onChange={(e) => onChangeAdmin(e.target.value, 'admin_phone', index)}
                    />
                    <FormFeedback>{item.error_message_phone}</FormFeedback>
                  </Col>
                  {index !== 0 && (
                    <Col lg={1} sm={1}>
                      <img src={TrashIcon} className="delete-item" onClick={() => handleDelete('admin', item, index)} />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          );
        }
      })}
      <Row>
        <Col sm={12} md={12}>
          <div
            className="button-add-stakeholder mb-4 mt-3"
            onClick={handleAddAdmins}
            disabled={addAdminsDisable || dataNotDeleted()}
          >
            {'+ Tambah PIC'}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default StakeholderAddAdmins;
