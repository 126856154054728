import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import API from '@utils/API';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import BrandSectionDetail from '@components/masters/brand/BrandSectionDetail';
import BrandSectionHistory from '@components/masters/brand/BrandSectionHistory';

const BrandDetail = (props) => {
  const { idBrand, activeSection } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const history = useHistory();
  const [hasNotif, setHasNotif] = useState();
  const [loading, setLoading] = useState(false);
  const [fisrtLoad, setFirstLoad] = useState(true);
  const [notification, setNotification] = useState({});
  const [mainLoading, setMainLoading] = useState(true);
  const [dataDetailBrand, setDataDetailBrand] = useState();

  const allowPermission = (menu) => {
    return !!(generatePermission('master', menu) || generatePermission('master', 'all'));
  };

  const allowShow = allowPermission('productbrandall') || allowPermission('productbrandshow');
  const allowBrandDetail =
    allowPermission('productbrandall') ||
    allowPermission('productbranddetailall') ||
    allowPermission('productbranddetailshow');
  const allowBrandHistory = allowPermission('productbrandall') || allowPermission('productbrandlog');

  const breadcrumb = [
    {
      id: 1,
      label: 'List Master',
      active: false,
      url: activeSection?.url,
    },
    {
      id: 2,
      label: activeSection?.label ?? '',
      active: false,
      url: '',
    },
    {
      id: 3,
      label: dataDetailBrand?.brand_name ?? '',
      active: true,
      url: '',
    },
  ];

  async function fetchDetailBrand() {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/master/product-brands/${idBrand}/`);
      setDataDetailBrand(response?.data?.data ?? {});
      handleGetBrandDetail(response?.data?.data ?? {});
      setLoading(false);
      setFirstLoad(false);
    } catch (error) {
      const errorMsg = error?.response?.data?.error?.message ?? '';
      setDataDetailBrand({});
      handleResultNotification({
        status: true,
        type: 'fail',
        message: 'Gagal mendapatkan detail data master brand ' + errorMsg,
      });
      setLoading(false);
      setFirstLoad(false);
    }
  }

  const handleGetBrandDetail = (data) => {
    if (!data?.brand_id) {
      handleResultNotification({ status: true, type: 'fail', message: 'Detail data master brand tidak ditemukan' });
    }
  };

  const handleSubmitEditBrandDetail = (status, error) => {
    const errorMsg = error ? 'Gagal Edit Data Master Brand, ' + error : 'Gagal Edit Data Master Brand';
    if (status) {
      fetchDetailBrand();
    }
    handleResultNotification({
      status: true,
      type: status ? 'success' : 'fail',
      message: status ? 'Berhasil Edit Data Master Brand' : errorMsg,
    });
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  useEffect(() => {
    setMainLoading(loading && fisrtLoad ? true : false);
  }, [loading]);

  useEffect(() => {
    if (!allowShow || (!allowBrandDetail && !allowBrandHistory)) {
      history.push('/404');
    } else if (allowBrandDetail) {
      fetchDetailBrand();
    }
  }, []);

  return (
    <React.Fragment>
      {mainLoading && (
        <Wrapper className="wrapperContent">
          <LoadingSpinner></LoadingSpinner>
        </Wrapper>
      )}
      {!mainLoading && (
        <Wrapper
          title={activeSection?.label}
          className="wrapperContent"
          showNotification={hasNotif}
          notification={notification}
          breadcrumbData={breadcrumb}
          onCloseNotification={handleCloseNotification}
        >
          {loading && <LoadingSpinner></LoadingSpinner>}
          {!loading && (
            <>
              <Row>
                {dataDetailBrand?.brand_id && allowBrandDetail && (
                  <Col sm={12} md={6}>
                    <BrandSectionDetail
                      detailBrand={dataDetailBrand}
                      onResultNotification={handleResultNotification}
                      onSubmit={handleSubmitEditBrandDetail}
                    />
                  </Col>
                )}
                {allowBrandHistory && (
                  <Col sm={12} md={6}>
                    <BrandSectionHistory idBrand={idBrand} />
                  </Col>
                )}
              </Row>
            </>
          )}
        </Wrapper>
      )}
    </React.Fragment>
  );
};

export default BrandDetail;
