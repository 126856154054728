import React, { useEffect, useState, useRef } from 'react';
import Helper from '@helpers/Helper';
import { Input } from 'reactstrap';

const FilterRangePriceField = (props) => {
  const { data, disabled, onChange } = props;

  const wrapperRef = useRef();
  const [focusClass, setFocusClass] = useState('');
  const [dummyData, setDummyData] = useState(data);
  const [isShowRangePrice, setIsShowRangePrice] = useState(false);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsShowRangePrice(false);
    }
  };

  const showRangePrice = () => {
    if (!disabled && !isShowRangePrice) {
      dummyData.value = dummyData?.value ?? {};
      dummyData.price_from = dummyData?.value?.price_from ?? {};
      dummyData.price_to = dummyData?.value?.price_to ?? {};
      setDummyData({ ...dummyData });
      setIsShowRangePrice(true);
    }
  };

  const onChangePriceFrom = (value) => {
    dummyData.price_from = value;
    setDummyData({ ...dummyData });
  };

  const onChangePriceTo = (value) => {
    dummyData.price_to = value;
    setDummyData({ ...dummyData });
  };

  const onEnterRangePrice = (e) => {
    if (e?.key === 'Enter' && !disabled) {
      onClickApplyRangePrice();
    }
  };

  const onClickApplyRangePrice = () => {
    if (!disabled) {
      const defaultFormatMoney = Helper.formatMoneyOnField('0', 'Rp ');
      let priceFrom = dummyData?.price_from?.formatMoney ? dummyData?.price_from : defaultFormatMoney;
      let priceTo = dummyData?.price_to?.formatMoney ? dummyData?.price_to : defaultFormatMoney;
      priceFrom =
        priceTo?.formatMoney !== 0 ? (priceFrom?.formatMoney > priceTo?.formatMoney ? priceTo : priceFrom) : priceFrom;
      priceTo = priceTo?.formatMoney !== 0 ? priceTo : priceFrom;

      if (dummyData?.price_from?.formatIsMoney || dummyData?.price_to?.formatIsMoney) {
        dummyData.value = {
          price_from: priceFrom,
          price_to: priceTo,
        };
      } else {
        dummyData.value = {};
      }

      setDummyData({ ...dummyData });
      onChange(dummyData);
      setIsShowRangePrice(false);
    }
  };

  const getWidthClass = () => {
    const width = data?.width ?? 'w-250';
    return width;
  };

  const getFilledClass = () => {
    const filled = dummyData?.value?.price_from || dummyData?.value?.price_to ? 'filled' : '';
    return filled;
  };

  useEffect(() => {
    setFocusClass(isShowRangePrice ? 'focus' : '');
  }, [isShowRangePrice]);

  useEffect(() => {
    if (disabled) {
      setIsShowRangePrice(false);
    }
  }, [disabled]);

  useEffect(() => {
    setDummyData(data);
  }, [data]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <React.Fragment>
      <div
        className={'text-field-range-price-filter' + ' ' + getWidthClass() + ' ' + getFilledClass() + ' ' + focusClass}
        ref={wrapperRef}
        onClick={showRangePrice}
      >
        {dummyData?.value?.price_from && dummyData?.value?.price_to && (
          <div className="nominal">
            <span>{'Rp '}</span>
            <span>{Helper.formatMoney(dummyData?.value?.price_from?.formatMoney)}</span>
            <span>{' - Rp '}</span>
            <span>{Helper.formatMoney(dummyData?.value?.price_to?.formatMoney)}</span>
          </div>
        )}
        {!dummyData?.value?.price_from && !dummyData?.value?.price_to && <span>{dummyData?.placeholder_main}</span>}
        {isShowRangePrice && (
          <div className="content-range-price">
            <div className="wrapper-range-price">
              <Input
                disabled={disabled}
                name={dummyData?.name_from}
                placeholder={dummyData?.placeholder_from}
                className={dummyData?.price_from?.formatIsMoney ? 'input-range-price filled' : 'input-range-price'}
                autoComplete="off"
                value={dummyData?.price_from?.formatIsMoney ? dummyData?.price_from?.formatMoneyString : ''}
                onChange={(e) => {
                  let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                  onChangePriceFrom(convertMoney?.formatMoney > 9999999999 ? dummyData?.price_from : convertMoney);
                }}
                onKeyDown={onEnterRangePrice}
              />
              <div className="separator"></div>
              <Input
                disabled={disabled}
                name={dummyData?.name_to}
                placeholder={dummyData?.placeholder_to}
                className={dummyData?.price_to?.formatIsMoney ? 'input-range-price filled' : 'input-range-price'}
                autoComplete="off"
                value={dummyData?.price_to?.formatIsMoney ? dummyData?.price_to?.formatMoneyString : ''}
                onChange={(e) => {
                  let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                  onChangePriceTo(convertMoney?.formatMoney > 9999999999 ? dummyData?.price_to : convertMoney);
                }}
                onKeyDown={onEnterRangePrice}
              />
            </div>
            <div className="button-apply-range-price" onClick={onClickApplyRangePrice}>
              Terapkan
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default FilterRangePriceField;
