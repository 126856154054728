import axios from 'axios';

const getClient = () => {
  const client = axios.create();

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      return Promise.reject(error);
    }
  );

  client.interceptors.request.use(
    async (config) => {
      config.headers = {
        'Content-Type': 'application/octet-stream',
      };

      return config;
    },
    (error) => Promise.reject(error)
  );

  return client;
};

export default class APIS3 {
  constructor() {
    this.client = getClient();
  }

  put(url, data = {}, options = {}) {
    return this.client.put(url, data, options);
  }
}
