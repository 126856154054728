import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Alert } from 'reactstrap';

const UploadFile = (props) => {
  const {
    modal,
    toggle,
    resultUploadFile,
    isValidation,
    sizeValidation,
    sizeString,
    title = 'Tambah File',
    subTitle = 'Tambahkan file dari komputer anda',
    fileType = 'image/png, image/jpeg, image/jpg, .xlsx, .xls, .pdf',
  } = props;
  const [uploadFile, setUploadFile] = useState(undefined);
  const [typeFile, setTypeFile] = useState(undefined);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState(null);
  const [hasMessage, setHasMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState('');

  const submitForm = () => {
    setDisable(true);

    if (uploadFile) {
      const result = { file: uploadFile[0], preview: previewFile };
      resultUploadFile(result);
      setPreviewFile('');
      setDisable(true);
    } else {
      setIsError(true);
      setMessage('Please choose an file to upload');
      setHasMessage(true);
      setDisable(false);
    }
  };

  const toggleClose = () => {
    setUploadFile(undefined);
    setPreviewFile('');
    toggle();
  };

  const passValidation = (e) => {
    const fileUrl = URL.createObjectURL(e.target.files[0]);

    setUploadFile(e.target.files);
    setPreviewFile(fileUrl);
    setTypeFile(e.target.files[0].type);
    setIsError(false);
    setMessage(null);
    setHasMessage(false);
  };

  const handleAttachFile = (e) => {
    if (e?.target?.files[0]) {
      if (isValidation) {
        const sizeLabel = sizeString ? sizeString : sizeValidation + ' KB';
        const fileSize = Math.round(e.target.files[0].size / 1024);
        if (fileSize > sizeValidation) {
          e.target.value = '';
          setUploadFile(undefined);
          setPreviewFile('');
          setMessage('Please choose an file that the size is less than ' + sizeLabel);
          setIsError(true);
          setHasMessage(true);
          setDisable(false);
        } else {
          passValidation(e);
        }
      } else {
        passValidation(e);
      }
    } else {
      reset();
    }
  };

  const reset = () => {
    setUploadFile(undefined);
    setTypeFile(undefined);
    setPreviewFile('');
    setDisable(false);
    setMessage(null);
    setHasMessage(false);
    setIsError(false);
  };

  useEffect(() => {
    if ((typeFile == 'image/png' || typeFile == 'image/jpeg' || typeFile == 'image/jpg') && previewFile) {
      setIsShowPreview(true);
    } else {
      setIsShowPreview(false);
    }
  }, [typeFile]);

  return (
    <Modal
      className="modal-dialog-centered modal-custom w-500"
      isOpen={modal}
      toggle={toggleClose}
      onClosed={() => reset()}
    >
      <ModalBody className="body">
        <div className="header mb-2">{title}</div>
        <p className="text-center">{subTitle}</p>
        {isShowPreview && (
          <div className="my-3 text-center">
            <img src={previewFile} className="attached-profpict" alt="profile picture" />
          </div>
        )}
        <FormGroup className="mb-0 text-center">
          <label className="custom-file-upload">
            <Input
              type="file"
              name="file"
              className="choose-file-upload"
              accept={fileType}
              onChange={(e) => handleAttachFile(e)}
            />
          </label>
        </FormGroup>
        {hasMessage && (
          <Alert className="mt-3 mb-0" color={isError ? 'danger' : 'primary'}>
            {message}
          </Alert>
        )}
        <div className="action mt-3">
          <Button className="button-action-modal primary" onClick={() => toggleClose()}>
            Kembali
          </Button>{' '}
          <Button className="button-action-modal primary" disabled={disable} onClick={() => submitForm()}>
            Konfirmasi
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UploadFile;
