import React from 'react';
import { Card, CardHeader, CardBody, FormGroup, Label, Col, Row } from 'reactstrap';
import DropDownIndicatorInactive from '@assets/icons/chevron-down.svg';
import CloseIcon from '@assets/icons/close.svg';
import WorkshopCard from '../card/WorkshopCard';

const BookingWorkshop = (props) => {
  const {
    isECommerce,
    disableAll,
    selectedWorkshop,
    errorWorkshop,
    toggleHasModalChooseWorkshop,
    handleChangeWorkshop,
    setSelectedWorkshops,
    selectedWorkshops,
    handleAddCart,
    cart,
    multipleCalculateCartData,
  } = props;

  const handleDeleteOnMultipleWorkshop = (data) => {
    const updatedSelectedWorkshops = selectedWorkshops.filter((workshop) => workshop.id !== data);
    setSelectedWorkshops(updatedSelectedWorkshops);
    const updatedPackageCart = cart?.package?.filter((item) => item?.name?.workshop_id !== data);
    const updatedCart = {
      ...cart,
      package: updatedPackageCart,
    };

    if (updatedSelectedWorkshops.length == 1) {
      const calculateCartData = multipleCalculateCartData?.find(
        (item) => item?.workshop_id == updatedSelectedWorkshops[0]?.id
      );

      if (updatedCart.product && Array.isArray(updatedCart.product)) {
        updatedCart.product.forEach((product, index) => {
          const workshopProduct = calculateCartData?.customer?.items?.find(
            (item) => item.product_id === product.name?.product_id || item.product_id === product.name?.id
          );

          if (product.name.product_id !== 'mock_item' && workshopProduct && !workshopProduct.is_custom) {
            updatedCart.product[index] = {
              ...product,
              is_wi: workshopProduct.is_wi,
              price: workshopProduct.price,
              final_sell_price: workshopProduct.final_sell_price,
              total_price: workshopProduct.total_price,
              // nettPrice: workshopProduct.nett_price,
              sellPrice: {
                ...product.sellPrice,
                formatIsMoney: true,
                formatMoney: workshopProduct.price,
                isNoPriceAvailable: false,
                value: workshopProduct.price,
              },
              nettPrice: {
                ...product.nettPrice,
                formatIsMoney: true,
                formatMoney: workshopProduct.workshop_price,
                isNoPriceAvailable: false,
                value: workshopProduct.workshop_price,
              },
              name: {
                ...product.name,
                price: workshopProduct.price,
                nett_price: workshopProduct.nett_price,
                calculated_price: workshopProduct.total_price,
                attr: {
                  ...product.name.attr,
                  is_wi: workshopProduct.is_wi,
                },
              },
            };
          }
        });
      }

      if (updatedCart.service && Array.isArray(updatedCart.service)) {
        updatedCart.service.forEach((service, index) => {
          const workshopProduct = calculateCartData?.customer?.items?.find(
            (item) => item.product_id === service.name?.product_id || item.product_id === service.name?.id
          );

          if (service.name.product_id !== 'mock_item' && workshopProduct && !workshopProduct.is_custom) {
            updatedCart.service[index] = {
              ...service,
              is_wi: workshopProduct.is_wi,
              price: workshopProduct.price,
              final_sell_price: workshopProduct.final_sell_price,
              total_price: workshopProduct.total_price,
              // nettPrice: workshopProduct.nett_price,
              sellPrice: {
                ...service.sellPrice,
                formatIsMoney: true,
                formatMoney: workshopProduct.price,
                isNoPriceAvailable: false,
                value: workshopProduct.price,
              },
              nettPrice: {
                ...service.nettPrice,
                formatIsMoney: true,
                formatMoney: workshopProduct.workshop_price,
                isNoPriceAvailable: false,
                value: workshopProduct.workshop_price,
              },
              name: {
                ...service.name,
                price: workshopProduct.price,
                nett_price: workshopProduct.nett_price,
                calculated_price: workshopProduct.total_price,
                attr: {
                  ...service.name.attr,
                  is_wi: workshopProduct.is_wi,
                },
              },
            };
          }
        });
      }
    }

    handleAddCart(updatedCart);
  };

  return (
    <Card style={{ minHeight: 205 }}>
      <CardHeader className="text-white mt-0 d-flex justify-content-between">Pilih Bengkel</CardHeader>
      <CardBody>
        <FormGroup row>
          <Label lg={3} className={!isECommerce ? 'label-required' : ''}>
            Bengkel
          </Label>
          <Col lg={9}>
            <div
              id="workshop"
              className={`workshop-select${disableAll ? '-disabled' : ''}`}
              onClick={disableAll ? null : () => toggleHasModalChooseWorkshop()}
            >
              <div className="container mt-2">
                {selectedWorkshops.length > 0 ? (
                  selectedWorkshops.map((workshop, i) => (
                    <div className="d-flex justify-content-between align-items-center mb-2 p-2 border rounded" key={i}>
                      <WorkshopCard data={workshop} selectWorkshops={selectedWorkshops} showCheckBoxx={false} />
                      <img
                        src={CloseIcon}
                        className="cursor-pointer"
                        alt=""
                        onClick={(e) => {
                          e.stopPropagation();
                          handleChangeWorkshop(undefined, 'delete');
                          handleDeleteOnMultipleWorkshop(workshop?.id);
                        }}
                      />
                    </div>
                  ))
                ) : selectedWorkshop ? (
                  <div className="d-flex justify-content-between align-items-center mb-2 p-2 border rounded">
                    <WorkshopCard data={selectedWorkshop} />
                    <img
                      src={CloseIcon}
                      className="cursor-pointer"
                      alt=""
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChangeWorkshop(undefined, 'delete');
                      }}
                    />
                  </div>
                ) : (
                  <div className="text-muted">Pilih bengkel...</div>
                )}
              </div>

              <img src={DropDownIndicatorInactive} className="arrow cursor-pointer" all="" />
            </div>
            {errorWorkshop && <span className="text-danger">{errorWorkshop}</span>}
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );
};

export default BookingWorkshop;
