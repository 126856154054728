import React, { useState } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import { Button, FormGroup, Col, Row, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { PACKAGE_ALLOWED } from '@utils/Constants';
import Helper from '@helpers/Helper';
import ChevronUp from '@assets/icons/chevron-up.svg';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import ChevronDown from '@assets/icons/chevron-down.svg';
import CircleClose from '@assets/icons/circle-close.svg';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import ActionSwitch from '@components/field/ActionSwitch';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import PackageConfiguration from '@components/workshops/package/PackageConfiguration';
import PackageDataDetailProduct from '@components/workshops/package/PackageDataDetailProduct';
import filter from 'lodash/filter';

const PackageDataDetailComponent = (props) => {
  const {
    loadingSumbit,
    packageComponentOptions,
    packageProductTypeOptions,
    errorSectionForm,
    allowEdit,
    allowedCustomerType,
    percentageDiscountB2C,
    percentageDiscountB2B,
    disableForm,
    control,
    register,
    getValues,
    setValue,
    setError,
    watch,
    onNotifications,
    productGroup,
    formState: { errors },
  } = props;

  const [hasModalMasterComponent, setHasModalMasterComponent] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'components',
  });

  const watchForm = (name) => {
    return watch(name, false);
  };

  const handleAddComponent = (data) => {
    if (data?.component?.value !== undefined && data?.product_type?.value !== undefined) {
      addComponent(data);
    }
  };

  const addComponent = (data) => {
    append({
      is_single: data?.product_type?.value,
      package_details_id: null,
      component_id: data?.component?.value ?? null,
      component_label: data?.component?.label ?? '',
      component_placeholder: data?.component?.placeholder ?? '',
      component_prompt: data?.component?.prompt ?? '',
      component_name: data?.component?.label ?? '',
      qty: data?.component?.qty ?? null,
      description: '',
      first_load_product_options: true,
      loading_product_options: true,
      product_options: [],
      product: null,
      original_price: {
        formatIsMoney: true,
        formatMoney: 0,
        formatMoneyString: 'Rp 0',
      },
      original_price_b2b: {
        formatIsMoney: true,
        formatMoney: 0,
        formatMoneyString: 'Rp 0',
      },
      product_price: {
        formatIsMoney: true,
        formatMoney: 0,
        formatMoneyString: 'Rp 0',
      },
      product_price_b2b: {
        formatIsMoney: true,
        formatMoney: 0,
        formatMoneyString: 'Rp 0',
      },
      nett_price: {
        formatIsMoney: true,
        formatMoney: 0,
        formatMoneyString: 'Rp 0',
      },
      prompt_text: data?.component?.prompt ?? '',
      is_active: true,
      is_show: true,
      is_deleted: false,
      is_removable: false,
      products: data?.product_type?.value
        ? []
        : [
            {
              product: null,
              product_label: '',
              original_price: {
                formatIsMoney: true,
                formatMoney: 0,
                formatMoneyString: 'Rp 0',
              },
              original_price_b2b: {
                formatIsMoney: true,
                formatMoney: 0,
                formatMoneyString: 'Rp 0',
              },
              product_price: {
                formatIsMoney: true,
                formatMoney: 0,
                formatMoneyString: 'Rp 0',
              },
              product_price_b2b: {
                formatIsMoney: true,
                formatMoney: 0,
                formatMoneyString: 'Rp 0',
              },
              nett_price: {
                formatIsMoney: true,
                formatMoney: 0,
                formatMoneyString: 'Rp 0',
              },
              apps_name: '',
              is_show: true,
              is_active: true,
              is_deleted: false,
            },
          ],
    });
  };

  const setErrorMessage = (nameFiled, type, message) => {
    setError(nameFiled, { type: type, message: message }, { shouldFocus: true });
  };

  const validationComponentName = (value) => {
    let validation = {
      valid: value?.length == 0 || value?.length > 100 ? false : true,
      errorMsg:
        value?.length > 100
          ? 'Component Name max 100 karakter'
          : value?.length == 0
          ? 'Component Name harus diisi'
          : '',
    };
    return validation;
  };

  const validationPromptText = (value) => {
    let validation = {
      valid: value?.length == 0 || value?.length > 100 ? false : true,
      errorMsg: value?.length > 100 ? 'Prompt max 100 karakter' : value?.length == 0 ? 'Prompt harus diisi' : '',
    };
    return validation;
  };

  const validationPrice = (value, label) => {
    const msgError = label + ' max Rp 99.999.999';
    let validation = {
      valid: value?.formatMoney > 99999999 ? false : true,
      errorMsg: value?.formatMoney > 99999999 ? msgError : '',
    };
    return validation;
  };

  const convertPrice = (value) => {
    return Helper.formatMoneyOnField('' + value ?? 0, 'Rp ');
  };

  const getPriceAfterDiscount = (value, type) => {
    const price = value ?? 0;
    const percentage = type == PACKAGE_ALLOWED.B2C ? percentageDiscountB2C : percentageDiscountB2B;
    const price_disc = (price * percentage) / 100;
    const price_after_disc = price - price_disc;

    return Math.round(price_after_disc);
  };

  const showHideComponent = (index) => {
    const is_show = getValues(`components[${index}].is_show`);
    setValue(`components[${index}].is_show`, !is_show);
  };

  const deleteComponent = (index) => {
    setValue(`components[${index}].is_deleted`, true);
    setConfirmModal(false);
  };

  const checkActiveComponent = (index) => {
    let checkIdProduct = false;
    if (productGroup.id) {
      if (getValues(`components[${index}].is_single`)) {
        checkIdProduct = getValues(`components[${index}].product.value`) == productGroup?.id ? true : false;
      } else {
        let value = 0;
        getValues(`components[${index}].products`).map((item) => {
          if (item?.product?.value == productGroup?.id) {
            value++;
          }
        });
        checkIdProduct = value > 0 ? true : false;
      }
    }

    return checkIdProduct;
  };

  const handleDeleteComponent = (index) => {
    if (!loadingSumbit) {
      const components = filter(getValues(`components`), (value) => {
        return !value.is_deleted;
      });
      if (components.length == 1) {
        onNotifications({ status: false, msg: 'Component minimal 1' });
      } else if (checkActiveComponent(index)) {
        onNotifications({ status: false, msg: 'Product aktif sebagai Product Group' });
      } else {
        setSelectedIndex(index);
        setConfirmModal(true);
      }
    }
  };

  const getClassSectionError = (param) => {
    const errorComponent = errorSectionForm.filter((val) => val == param);
    if (errorComponent?.length !== 0) {
      return 'out-group-item-invalid';
    } else {
      return 'out-group-item';
    }
  };

  const handleNotifications = (e) => {
    onNotifications(e);
  };

  const handleShowMasterComponent = () => setHasModalMasterComponent(true);
  const toogleMasterComponent = () => setHasModalMasterComponent(!hasModalMasterComponent);

  return (
    <React.Fragment>
      <PackageConfiguration
        modal={hasModalMasterComponent}
        componentOptions={packageComponentOptions}
        productTypeOptions={packageProductTypeOptions}
        toggle={toogleMasterComponent}
        onSubmit={handleAddComponent}
      />
      {fields.map(({ id }, index) => {
        return (
          <ListGroup key={id}>
            {!watchForm(`components[${index}].is_deleted`) && (
              <ListGroupItem key={id} className={getClassSectionError(`components[${index}]`)}>
                <div className="fly-title" onClick={() => showHideComponent(index)}>
                  Component: <span>{watchForm(`components[${index}].component_label`)}</span>
                  <img src={watchForm(`components[${index}].is_show`) ? ChevronUp : ChevronDown} alt="" />
                </div>
                {!disableForm && (
                  <img src={CircleClose} className="fly-close" onClick={() => handleDeleteComponent(index)} />
                )}

                {watchForm(`components[${index}].is_show`) && (
                  <div className="in-group-item">
                    <Controller
                      name={`components[${index}].component_name`}
                      control={control}
                      rules={{
                        validate: () => {
                          const validation = validationComponentName(getValues(`components[${index}].component_name`));
                          return validation?.valid || validation?.errorMsg;
                        },
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Row className="mb-3">
                          <Label lg={3} className="label-required">
                            Component Name
                          </Label>
                          <InputField
                            colWidth={9}
                            inputType={'text'}
                            disabled={disableForm || loadingSumbit}
                            value={value}
                            invalid={error?.message ? true : false}
                            errorMessage={error?.message ?? ''}
                            placeholder={'Component Name'}
                            onChange={(e) => {
                              const val =
                                e.target.value !== ' '
                                  ? e.target.value.replace(/  +/g, ' ')
                                  : getValues(`components[${index}].component_name`);
                              const validation = validationComponentName(val);
                              setErrorMessage(`components[${index}].component_name`, 'manual', validation?.errorMsg);
                              setValue(`components[${index}].component_name`, val);
                              onChange(val);
                            }}
                          ></InputField>
                        </Row>
                      )}
                    />
                    {allowedCustomerType !== PACKAGE_ALLOWED.B2B ? (
                      <>
                        <Controller
                          name={`components[${index}].description`}
                          control={control}
                          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                            <Row className="mb-3">
                              <Label lg={3}>Description</Label>
                              <InputField
                                {...rest}
                                colWidth={9}
                                inputType={'text'}
                                disabled={disableForm || loadingSumbit}
                                placeholder={'Description'}
                              ></InputField>
                            </Row>
                          )}
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    {watchForm(`components[${index}].is_single`) == false && (
                      <>
                        <Controller
                          name={`components[${index}].prompt_text`}
                          control={control}
                          rules={{
                            validate: () => {
                              const validation = validationPromptText(getValues(`components[${index}].prompt_text`));
                              return validation?.valid || validation?.errorMsg;
                            },
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Row className="mb-3">
                              <Label lg={3} className="label-required">
                                Prompt
                              </Label>
                              <InputField
                                colWidth={9}
                                inputType={'text'}
                                disabled={disableForm || loadingSumbit}
                                value={value}
                                invalid={error?.message ? true : false}
                                errorMessage={error?.message ?? ''}
                                placeholder={'Prompt'}
                                onChange={(e) => {
                                  const val =
                                    e.target.value !== ' '
                                      ? e.target.value.replace(/  +/g, ' ')
                                      : getValues(`components[${index}].prompt_text`);
                                  const validation = validationPromptText(val);
                                  setErrorMessage(`components[${index}].prompt_text`, 'manual', validation?.errorMsg);
                                  setValue(`components[${index}].prompt_text`, val);
                                  onChange(val);
                                }}
                              ></InputField>
                            </Row>
                          )}
                        />
                        {allowedCustomerType !== PACKAGE_ALLOWED.B2B ? (
                          <Controller
                            control={control}
                            name={`components[${index}].is_removable`}
                            render={({ field: { ref, value, name, ...rest } }) => {
                              return (
                                <FormGroup row>
                                  <Label lg={3}>Is Add On</Label>
                                  <Col className="p-2">
                                    <ActionSwitch
                                      {...rest}
                                      inputName={name}
                                      isChecked={value}
                                      customClass="mb-0"
                                      editableForm={disableForm || loadingSumbit}
                                    />
                                  </Col>
                                </FormGroup>
                              );
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    {watchForm(`components[${index}].is_single`) == true && (
                      <>
                        <Controller
                          control={control}
                          name={`components[${index}].product`}
                          rules={{
                            validate: () => {
                              const valid = !getValues(`components[${index}].product.value`) ? false : true;
                              return valid || 'Product harus diisi';
                            },
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => {
                            return (
                              <Row className="mb-3">
                                <Label lg={3} className="label-required">
                                  Product
                                </Label>
                                <SelectField
                                  colWidth={9}
                                  placeholder={'Pilih Product'}
                                  value={value}
                                  disabled={disableForm || loadingSumbit}
                                  closeMenuOnSelect={true}
                                  options={getValues(`components[${index}].product_options`) ?? []}
                                  isLoading={getValues(`components[${index}].loading_product_options`) ?? false}
                                  errorMessage={error?.message ?? ''}
                                  onChange={(e) => {
                                    const originalPrice = convertPrice(e?.sell_price);
                                    const productPrice = convertPrice(
                                      getPriceAfterDiscount(e?.sell_price, PACKAGE_ALLOWED.B2C)
                                    );
                                    const originalPriceB2B = convertPrice(e?.base_price);
                                    const productPriceB2B = convertPrice(
                                      getPriceAfterDiscount(e?.base_price, PACKAGE_ALLOWED.B2B)
                                    );
                                    const nettPrice = convertPrice(e?.nett_price);
                                    setValue(`components[${index}].original_price`, originalPrice);
                                    setValue(`components[${index}].product_price`, productPrice);
                                    setValue(`components[${index}].original_price_b2b`, originalPriceB2B);
                                    setValue(`components[${index}].product_price_b2b`, productPriceB2B);
                                    setValue(`components[${index}].nett_price`, nettPrice);
                                    if (allowedCustomerType == PACKAGE_ALLOWED.B2C) {
                                      setErrorMessage(`components[${index}].original_price`, 'manual', '');
                                      setErrorMessage(`components[${index}].product_price`, 'manual', '');
                                    }
                                    if (allowedCustomerType == PACKAGE_ALLOWED.BOTH) {
                                      setErrorMessage(`components[${index}].product_price`, 'manual', '');
                                    }
                                    if (
                                      allowedCustomerType == PACKAGE_ALLOWED.BOTH ||
                                      allowedCustomerType == PACKAGE_ALLOWED.B2B
                                    ) {
                                      setErrorMessage(`components[${index}].product_price_b2b`, 'manual', '');
                                      setErrorMessage(`components[${index}].nett_price`, 'manual', '');
                                    }
                                    onChange(e);
                                  }}
                                />
                              </Row>
                            );
                          }}
                        />

                        {allowedCustomerType === PACKAGE_ALLOWED.B2C ? (
                          <>
                            <Controller
                              control={control}
                              name={`components[${index}].original_price`}
                              rules={{
                                validate: () => {
                                  const validation = validationPrice(
                                    getValues(`components[${index}].original_price`),
                                    'Original Price'
                                  );
                                  return validation?.valid || validation?.errorMsg;
                                },
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Row className="mb-3">
                                  <Label lg={3} className="label-required">
                                    Original Price
                                  </Label>
                                  <InputField
                                    colWidth={9}
                                    inputType={'text'}
                                    disabled={disableForm || loadingSumbit}
                                    value={value?.formatMoneyString ?? ''}
                                    invalid={error?.message ? true : false}
                                    errorMessage={error?.message ?? ''}
                                    placeholder={'Original Price'}
                                    onChange={(e) => {
                                      let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                                      const validation = validationPrice(convertMoney, 'Original Price');
                                      setErrorMessage(
                                        `components[${index}].original_price`,
                                        'manual',
                                        validation?.errorMsg
                                      );
                                      setValue(`components[${index}].original_price`, convertMoney);
                                      onChange(convertMoney);
                                    }}
                                  ></InputField>
                                </Row>
                              )}
                            />
                            <Controller
                              control={control}
                              name={`components[${index}].product_price`}
                              rules={{
                                validate: () => {
                                  const validation = validationPrice(
                                    getValues(`components[${index}].product_price`),
                                    'Price'
                                  );
                                  return validation?.valid || validation?.errorMsg;
                                },
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Row className="mb-3">
                                  <Label lg={3} className="label-required">
                                    Price
                                  </Label>
                                  <InputField
                                    colWidth={9}
                                    inputType={'text'}
                                    disabled={disableForm || loadingSumbit}
                                    value={value?.formatMoneyString ?? ''}
                                    invalid={error?.message ? true : false}
                                    errorMessage={error?.message ?? ''}
                                    placeholder={'Price'}
                                    onChange={(e) => {
                                      let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                                      const validation = validationPrice(convertMoney, 'Price');
                                      setErrorMessage(
                                        `components[${index}].product_price`,
                                        'manual',
                                        validation?.errorMsg
                                      );
                                      setValue(`components[${index}].product_price`, convertMoney);
                                      onChange(convertMoney);
                                    }}
                                  ></InputField>
                                </Row>
                              )}
                            />
                          </>
                        ) : (
                          <></>
                        )}

                        {allowedCustomerType === PACKAGE_ALLOWED.BOTH ? (
                          <>
                            <Controller
                              control={control}
                              name={`components[${index}].product_price`}
                              rules={{
                                validate: () => {
                                  const validation = validationPrice(
                                    getValues(`components[${index}].product_price`),
                                    'B2C Price'
                                  );
                                  return validation?.valid || validation?.errorMsg;
                                },
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Row className="mb-3">
                                  <Label lg={3} className="label-required">
                                    B2C Price
                                  </Label>
                                  <InputField
                                    colWidth={9}
                                    inputType={'text'}
                                    disabled
                                    value={value?.formatMoneyString ?? ''}
                                    invalid={error?.message ? true : false}
                                    errorMessage={error?.message ?? ''}
                                    placeholder={'Price'}
                                    onChange={(e) => {
                                      let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                                      const validation = validationPrice(convertMoney, 'B2C Price');
                                      setErrorMessage(
                                        `components[${index}].product_price`,
                                        'manual',
                                        validation?.errorMsg
                                      );
                                      setValue(`components[${index}].product_price`, convertMoney);
                                      onChange(convertMoney);
                                    }}
                                  ></InputField>
                                </Row>
                              )}
                            />
                          </>
                        ) : (
                          <></>
                        )}

                        {allowedCustomerType === PACKAGE_ALLOWED.B2B || allowedCustomerType === PACKAGE_ALLOWED.BOTH ? (
                          <>
                            <Controller
                              control={control}
                              name={`components[${index}].product_price_b2b`}
                              rules={{
                                validate: () => {
                                  const validation = validationPrice(
                                    getValues(`components[${index}].product_price_b2b`),
                                    'B2B Price'
                                  );
                                  return validation?.valid || validation?.errorMsg;
                                },
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Row className="mb-3">
                                  <Label lg={3} className="label-required">
                                    B2B Price
                                  </Label>
                                  <InputField
                                    colWidth={9}
                                    inputType={'text'}
                                    disabled
                                    value={value?.formatMoneyString ?? ''}
                                    invalid={error?.message ? true : false}
                                    errorMessage={error?.message ?? ''}
                                    placeholder={'Price'}
                                    onChange={(e) => {
                                      let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                                      const validation = validationPrice(convertMoney, 'B2B Price');
                                      setErrorMessage(
                                        `components[${index}].product_price_b2b`,
                                        'manual',
                                        validation?.errorMsg
                                      );
                                      setValue(`components[${index}].product_price_b2b`, convertMoney);
                                      onChange(convertMoney);
                                    }}
                                  ></InputField>
                                </Row>
                              )}
                            />
                            <Controller
                              control={control}
                              name={`components[${index}].nett_price`}
                              rules={{
                                validate: () => {
                                  const validation = validationPrice(
                                    getValues(`components[${index}].nett_price`),
                                    'Nett Price'
                                  );
                                  return validation?.valid || validation?.errorMsg;
                                },
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Row className="mb-3">
                                  <Label lg={3} className="label-required">
                                    Nett Price
                                  </Label>
                                  <InputField
                                    colWidth={9}
                                    inputType={'text'}
                                    disabled={disableForm || loadingSumbit}
                                    value={value?.formatMoneyString ?? ''}
                                    invalid={error?.message ? true : false}
                                    errorMessage={error?.message ?? ''}
                                    placeholder={'Price'}
                                    onChange={(e) => {
                                      let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                                      const validation = validationPrice(convertMoney, 'Nett Price');
                                      setErrorMessage(
                                        `components[${index}].nett_price`,
                                        'manual',
                                        validation?.errorMsg
                                      );
                                      setValue(`components[${index}].nett_price`, convertMoney);
                                      onChange(convertMoney);
                                    }}
                                  ></InputField>
                                </Row>
                              )}
                            />
                          </>
                        ) : (
                          <></>
                        )}

                        <Controller
                          control={control}
                          name={`components[${index}].is_active`}
                          render={({ field: { ref, value, name, ...rest } }) => {
                            return (
                              <FormGroup row>
                                <Label lg={3}>Aktif</Label>
                                <Col className="p-2">
                                  <ActionSwitch
                                    {...rest}
                                    inputName={name}
                                    isChecked={value}
                                    customClass="mb-0"
                                    editableForm={disableForm || loadingSumbit}
                                  />
                                </Col>
                              </FormGroup>
                            );
                          }}
                        />
                      </>
                    )}
                    {watchForm(`components[${index}].is_single`) == false && (
                      <PackageDataDetailProduct
                        loadingSumbit={loadingSumbit}
                        disableForm={disableForm}
                        componentIndex={index}
                        errorSectionForm={errorSectionForm}
                        productGroup={productGroup}
                        percentageDiscountB2C={percentageDiscountB2C}
                        percentageDiscountB2B={percentageDiscountB2B}
                        onNotifications={handleNotifications}
                        allowedCustomerType={allowedCustomerType}
                        {...{ control, register, getValues, setValue, setError, watch, formState: { errors } }}
                      />
                    )}
                  </div>
                )}
              </ListGroupItem>
            )}
          </ListGroup>
        );
      })}
      <Row>
        <Col className="text-left">
          {!disableForm && (
            <Button
              color="primary"
              size="lg"
              className="btn-add-component-product"
              disabled={loadingSumbit}
              onClick={handleShowMasterComponent}
            >
              <img src={PlusFill} className="mr-2" />
              Add Component
            </Button>
          )}
        </Col>
      </Row>

      <ConfirmationModal
        modal={confirmModal}
        toggle={() => setConfirmModal(!confirmModal)}
        header={`Anda yakin ingin menghapus data Component ?`}
        onConfirm={() => {
          deleteComponent(selectedIndex);
        }}
        toggleClose={() => setConfirmModal(!confirmModal)}
      ></ConfirmationModal>
    </React.Fragment>
  );
};

export default PackageDataDetailComponent;
