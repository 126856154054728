import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Label, Spinner, Button } from 'reactstrap';
import { DISCOUNT_OPTIONS } from '@utils/Constants';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import SelectField from '../field/SelectField';
import SelectAsyncField from '@components/field/SelectAsyncField';
import EditableInputField from '@components/field/EditableInputField';
import InputField from '@components/field/InputField';
import ConfirmationModal from '@components/modal/ConfirmationModal';

const B2BCustomerSpecialSettings = ({ formValues, allowEdit, handleSubmitEdit, setFormValues }) => {
  const api = new API('v2');
  const [data, setData] = useState(formValues);
  const [disableForm, setDisableForm] = useState(true);
  const [onEdit, setOnEdit] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [hasModalSubmitConfirmation, setHasModalSubmitConfirmation] = useState(false);
  const [hasModalAbortConfirmation, setHasModalAbortConfirmation] = useState(false);
  const [loadingFetchWorkshop, setLoadingFetchWorkshop] = useState(false);

  useEffect(() => {
    setData(formValues);
  }, [formValues]);

  const handleClickEdit = useCallback(() => {
    setDisableForm(false);
    setOnEdit(true);
  }, []);

  const handleCancelEdit = useCallback((e, force = false, reset = true) => {
    if (!force && formIsEdited()) {
      return toggleModalAbortConfirmation();
    }
    if (reset) {
      handleResetValues();
    }
    setDisableForm(true);
    setOnEdit(false);
    setHasModalAbortConfirmation(false);
  }, [formValues, data]);

  const handleSubmit = useCallback(() => {
    setLoadingSubmit(true);
    handleSubmitEdit(
      data,
      () => {
        setFormValues((prev) => ({ ...prev, ...data }));
        setTimeout(() => {
          handleCancelEdit(null, true, false);
          setLoadingSubmit(false);
        }, 500);
      },
      () => {
        handleClickEdit();
        setLoadingSubmit(false);
      }
    );
  }, [data, handleSubmitEdit, setFormValues, handleCancelEdit, handleClickEdit]);

  const handleResetValues = useCallback(() => {
    setData(formValues);
  }, [formValues]);

  const handleChange = (key, value) => {
    setData((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleChangeCustomerWarranty = (e) => {
    const val = parseInt(e.target.value, 10) || 0;
    const error = val >= 0 && val <= 1000 ? '' : 'Nilai harus diantara 0 - 1000';
    setData((prevData) => ({
      ...prevData,
      warranty_facility: {
        ...prevData.warranty_facility,
        name: prevData?.warranty_facility?.name ?? 'warranty',
        value: val.toString(),
        label_value: `${val} ${prevData?.warranty_facility?.unit_label}`,
      },
      error_warranty_facility: error,
    }));
  };

  const handleChangeTermsOfPayment = (e) => {
    const val = parseInt(e.target.value, 10) || 0;
    const error = val >= 0 && val <= 1000 ? '' : 'Nilai harus diantara 0 - 1000';
    setData((prevData) => ({
      ...prevData,
      terms_of_payment: {
        ...prevData.terms_of_payment,
        value: val.toString(),
        label_value: `${val} ${prevData?.terms_of_payment?.label_unit}`,
      },
      error_terms_of_payment: error,
    }));
  };

  const handleChangeInternalNotes = (e) => {
    const val = e.target.value || '';
    setData((prevData) => ({
      ...prevData,
      internal_notes: val,
      error_internal_notes: '',
    }));
  };

  const toggleModalSubmitConfirmation = useCallback(() => setHasModalSubmitConfirmation((prev) => !prev), []);
  const toggleModalAbortConfirmation = useCallback(() => setHasModalAbortConfirmation((prev) => !prev), []);

  const formIsEdited = () => {
    const keysToCompare = [
      'product_markup',
      'service_markup',
      'product_discount',
      'service_discount',
      'workshop_blacklist',
      'warranty_facility',
      'internal_notes',
      'terms_of_payment',
    ];
    return !keysToCompare.every((key) => formValues[key] === data[key]);
  };

  const customerWarrantyValue = () => {
    const warrantyFacility = data?.warranty_facility;
    return warrantyFacility ? (disableForm ? warrantyFacility.label_value : warrantyFacility.value) : '';
  };

  const termsOfPaymentValue = () => {
    const top = data?.terms_of_payment;
    return top ? (disableForm ? top.label_value : top.value) : '';
  };

  const fetchListWorkshops = async (search) => {
    setLoadingFetchWorkshop(true);
    if (search) {
      try {
        const result = await api.get(`v2/intools/customers/workshops/?limit=100&search=${search}`);
        setLoadingFetchWorkshop(false);
        return result.data.data;
      } catch {
        setLoadingFetchWorkshop(false);
      }
    }
  };

  const discountOptions = [{ value: 0, label: '0%' }, DISCOUNT_OPTIONS];

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Pengaturan Khusus</CardHeader>
      <CardBody>
        <FormGroup row>
          <Label lg={3}>Markup Produk</Label>
          <SelectField
            colWidth={9}
            inputName="markup-product"
            disabled={disableForm}
            textLeft
            value={data?.product_markup}
            closeMenuOnSelect={true}
            options={Helper.generatePercentage(0, 100)}
            getOptionLabel={(e) => e.label}
            getOptionValue={(e) => e.value}
            onChange={(value) => handleChange('product_markup', value)}
          />
        </FormGroup>
        <FormGroup row>
          <Label lg={3}>Markup Jasa</Label>
          <SelectField
            colWidth={9}
            inputName="markup-jasa"
            disabled={disableForm}
            textLeft
            value={data?.service_markup}
            closeMenuOnSelect={true}
            options={Helper.generatePercentage(0, 100)}
            getOptionLabel={(e) => e.label}
            getOptionValue={(e) => e.value}
            onChange={(value) => handleChange('service_markup', value)}
          />
        </FormGroup>
        <FormGroup row>
          <Label lg={3}>Diskon Produk</Label>
          <SelectField
            colWidth={9}
            inputName="discount-product"
            disabled={disableForm}
            textLeft
            value={data?.product_discount}
            closeMenuOnSelect={true}
            options={Helper.generatePercentage(0, 100)}
            getOptionLabel={(e) => e.label}
            getOptionValue={(e) => e.value}
            onChange={(value) => handleChange('product_discount', value)}
          />
        </FormGroup>
        <FormGroup row>
          <Label lg={3}>Diskon Jasa</Label>
          <SelectField
            colWidth={9}
            inputName="discount-jasa"
            disabled={disableForm}
            textLeft
            value={data?.service_discount}
            closeMenuOnSelect={true}
            options={Helper.generatePercentage(0, 100)}
            getOptionLabel={(e) => e.label}
            getOptionValue={(e) => e.value}
            onChange={(value) => handleChange('service_discount', value)}
          />
        </FormGroup>
        <FormGroup row>
          <Label lg={3}>Workshop Blacklist</Label>
          <SelectAsyncField
            colWidth={9}
            isDisabled={disableForm}
            value={data?.workshop_blacklist || ''}
            defaultOptions={[]}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.id}
            loadOptions={fetchListWorkshops}
            onChange={(value) => handleChange('workshop_blacklist', value)}
            invalid={!!data?.error_workshop_blacklist}
            errorMessage={data?.error_workshop_blacklist}
            placeholder="Pilih Workshop..."
            loading={loadingFetchWorkshop}
            isMulti
            closeMenuOnSelect={false}
            isClearable
            isSelectAll
          />
        </FormGroup>
        <FormGroup row>
          <Label lg={3}>Termin Pembayaran (TOP)</Label>
          <InputField
            colWidth={9}
            value={termsOfPaymentValue()}
            invalid={!!data?.error_terms_of_payment}
            errorMessage={data?.error_terms_of_payment}
            disabled={disableForm}
            onChange={handleChangeTermsOfPayment}
          />
        </FormGroup>
        <FormGroup row>
          <Label lg={3}>Fasilitas - Garansi</Label>
          <InputField
            colWidth={9}
            value={customerWarrantyValue()}
            invalid={!!data?.error_warranty_facility}
            errorMessage={data?.error_warranty_facility}
            disabled={disableForm}
            onChange={handleChangeCustomerWarranty}
          />
        </FormGroup>
        <EditableInputField
          colWidth={9}
          labelWidth={3}
          inputType="textarea"
          title="Internal Notes"
          placeholder="Ex: notes"
          inputName="internal_notes"
          value={data?.internal_notes}
          invalid={!!data?.error_internal_notes}
          errorMessage={data?.error_internal_notes}
          disabled={disableForm}
          onChange={handleChangeInternalNotes}
        />

        <Col className="section-action mb-2 mt-4">
          {allowEdit && onEdit ? (
            <>
              <Button className="button-action save" onClick={toggleModalSubmitConfirmation} disabled={loadingSubmit}>
                {loadingSubmit ? <Spinner color="light" size="sm" /> : 'Simpan'}
              </Button>
              <Button className="button-action cancel" onClick={handleCancelEdit} disabled={loadingSubmit}>
                Batal
              </Button>
            </>
          ) : (
            allowEdit && (
              <Button color="purple" className="button-action" onClick={handleClickEdit} disabled={loadingSubmit}>
                Edit
              </Button>
            )
          )}
        </Col>
      </CardBody>

      <ConfirmationModal
        toggle={toggleModalSubmitConfirmation}
        toggleClose={toggleModalSubmitConfirmation}
        onConfirm={() => {
          setDisableForm(true);
          toggleModalSubmitConfirmation();
          handleSubmit(handleCancelEdit, handleClickEdit);
        }}
        modal={hasModalSubmitConfirmation}
        header="Simpan Perubahan Pengaturan Khusus?"
        subHeader={`Simpan Perubahan Pengaturan Khusus atas nama ${data?.customer_name}?`}
      />

      <ConfirmationModal
        toggle={toggleModalAbortConfirmation}
        toggleClose={toggleModalAbortConfirmation}
        onConfirm={(e) => handleCancelEdit(e, true)}
        modal={hasModalAbortConfirmation}
        header="Konfirmasi Pembatalan Perubahan"
        subHeader="Apakah Anda yakin ingin membatalkan perubahan? Semua perubahan yang belum disimpan pada Pengaturan Khusus akan hilang."
      />
    </Card>
  );
};

export default B2BCustomerSpecialSettings;
