import logger from 'redux-logger';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import crossBrowserListener from '@utils/crossBrowserListener';
import appReducer from '@reducers/App';
import authReducer from '@reducers/Auth';
import booking from '@store/booking';
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  booking,
});

const persistConfig = {
  key: 'intools',
  storage: storage,
  whitelist: ['app', 'auth', 'booking'],
  stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [
  thunk,
  logger,
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
    warnAfter: 2000,
  }),
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middleware,
});

window.addEventListener('storage', crossBrowserListener(store, persistConfig));

export const persistor = persistStore(store);

export default store;
