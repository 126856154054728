import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';

const ApprovalNote = (props) => {
  const { modal, toggle, note } = props;

  const toggleClose = () => {
    toggle();
  };

  return (
    <Modal className="modal-dialog-centered modal-custom w-500" isOpen={modal} toggle={toggleClose}>
      <ModalBody className="body">
        <div className="header mb-2">{'Catatan Persetujuan'}</div>
        <p className="text-center mt-3">{note}</p>
        <div className="action mt-3">
          <Button className="button-action-modal primary" onClick={() => toggleClose()}>
            Tutup
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ApprovalNote;
