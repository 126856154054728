import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import API from '@utils/API';
import { Col, Button, Form, FormGroup, Label, Input, Row, Card, CardBody, CardHeader } from 'reactstrap';
import Wrapper from '@components/wrapper/Wrapper';
import Select from 'react-select';
import { validate } from 'react-email-validator';
import { toast, ToastContainer } from 'react-toastify';
import { regexOnlyAllowCharacter, regexWithMinimumNumberCharacter } from '@utils/Constants';

function AddUser() {
  const history = useHistory();
  const api = new API('v2');

  const [role, setRole] = useState(null);
  const [company, setCompany] = useState(null);
  const [roleData, setRoleData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState();
  const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] = useState();

  const [isFirstNameNotValid, setIsFirstNameNotValid] = useState(false);
  const [isLastNameNotValid, setIsLastNameNotValid] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPhoneNumberInvalid, setIsPhoneNumberInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isRepeatPasswordInvalid, setIsRepeatPasswordInvalid] = useState(false);
  const [isFleetRole, setIsFleetRole] = useState(false);

  const [forms, setForms] = useState({
    user_group: '',
    first_name: '',
    customer_id: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    repeat_password: '',
  });

  const breadcrumb = [
    {
      id: 1,
      label: 'Access Control Levels',
      active: false,
      url: '/acl',
    },
    {
      id: 2,
      label: 'Add User',
      active: true,
      url: '',
    },
  ];

  const fetchRoleData = async () => {
    api
      .get('v2/intools/roles/')
      .then((result) => {
        setRoleData(result?.data?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchCompanyData = async () => {
    api
      .get('v2/intools/customers/?query=&page=1&limit=9999&is_enterprise=y')
      .then((result) => {
        setCompanyData(result?.data?.data?.customers);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const options = roleData?.map((role) => {
    return { value: role?.id, label: role?.name, platform: role?.platform };
  });

  const optionsCompany = companyData?.map((dataCompany) => {
    return { value: dataCompany?.id, label: dataCompany?.name };
  });

  const handleRoleChange = (item) => {
    setRole(item);
    setCompany(null);
    setForms({ ...forms, user_group: item.value, customer_id: '' });
    setIsFleetRole(item?.platform == 'fleet' ? true : false);
  };

  const handleChangeCompany = (item) => {
    setCompany(item);
    setForms({ ...forms, customer_id: item?.value ?? '' });
  };

  const handleNameChange = (e) => {
    const { name, value } = e.target;
    setForms({ ...forms, [name]: value });

    if (name === 'first_name') {
      const isInputCorrect = regexOnlyAllowCharacter.test(value);
      if (isInputCorrect && value) {
        setIsFirstNameNotValid(false);
        setFirstNameErrorMessage('');
      } else {
        setIsFirstNameNotValid(true);
        setFirstNameErrorMessage('First Name is empty or invalid format');
      }
      setForms({ ...forms, [name]: value });
    } else if (name === 'last_name') {
      const isInputCorrect = regexOnlyAllowCharacter.test(value);
      if (isInputCorrect && value) {
        setIsLastNameNotValid(false);
        setLastNameErrorMessage('');
      } else {
        setIsLastNameNotValid(true);
        setLastNameErrorMessage('Last Name is empty or invalid format');
      }
      setForms({ ...forms, [name]: value });
    }
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    let emailCheck = validate(email);
    if (emailCheck) {
      setEmailErrorMessage();
      setIsEmailInvalid(false);
    } else if (!email) {
      setEmailErrorMessage('Email is empty');
      setIsEmailInvalid(true);
    } else if (!emailCheck) {
      setEmailErrorMessage('Email is invalid format');
      setIsEmailInvalid(true);
    }

    setForms({ ...forms, email: email });
  };

  const validatePhoneNumber = (phoneNumber) => {
    let isValidated = true;
    if (phoneNumber.indexOf('+62') !== 0) {
      isValidated = false;
      setPhoneNumberErrorMessage('Format not following +62');
      setIsPhoneNumberInvalid(true);
    }
  };

  const handler = useCallback(debounce(validatePhoneNumber, 400), []);

  const changePhoneNumber = (e) => {
    let newValue = e.target.value.replace(/[^0-9+]/g, '');
    newValue = newValue.replace(/(?!^\+)\+/g, '');
    if (newValue !== forms.phone_number) {
      setForms({ ...forms, phone_number: newValue });
      setPhoneNumberErrorMessage('');
      setIsPhoneNumberInvalid(false);
      handler(newValue);
    }
  };

  const handlePasswordChange = (event) => {
    let password = event.target.value;
    const isPasswordCorrect = regexWithMinimumNumberCharacter.test(password);
    if (isPasswordCorrect) {
      setPasswordErrorMessage();
      setIsPasswordInvalid(false);
    } else if (!password) {
      setPasswordErrorMessage('Password is empty or invalid format');
      setIsPasswordInvalid(true);
    } else if (!isPasswordCorrect) {
      setPasswordErrorMessage('Use 8 or more characters with a mix of letters and numbers');
      setIsPasswordInvalid(true);
    }

    setForms({ ...forms, password: password });
  };

  const handleRepeatPasswordChange = (event) => {
    let repeatPassword = event.target.value;
    const isRepeatPasswordCorrect = repeatPassword === forms.password;
    if (!isRepeatPasswordCorrect) {
      setRepeatPasswordErrorMessage('Passwords are not matching');
      setIsRepeatPasswordInvalid(true);
    } else {
      setRepeatPasswordErrorMessage();
      setIsRepeatPasswordInvalid(false);
    }

    setForms({ ...forms, repeat_password: repeatPassword });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = forms;

    api
      .post(`v2/intools/users/`, params)
      .then(() => {
        toast.success('User has successfully added', { position: 'top-right', autoClose: 1800 });
        setTimeout(() => {
          history.push('/acl');
        }, 2000);
      })
      .catch((e) => {
        const message = e?.response?.data?.error?.message || 'Failed add new user';
        toast.warn(message, { position: 'top-right', autoClose: 4000 });
      });
  };

  const hasErrorForm = () => {
    let hasEmptyField = false;
    Object.entries(forms).forEach(([key, value]) => {
      if (value) {
        // pass
      } else {
        if (key !== 'last_name' && key !== 'customer_id') {
          hasEmptyField = true;
        }
        if (key == 'customer_id' && isFleetRole) {
          hasEmptyField = true;
        }
      }
    });

    return hasEmptyField;
  };

  useEffect(() => {
    fetchRoleData();
    fetchCompanyData();
  }, []);

  const isButtonDisabled =
    hasErrorForm() || isEmailInvalid || isFirstNameNotValid || isPasswordInvalid || isRepeatPasswordInvalid;

  return (
    <React.Fragment>
      <ToastContainer />
      <Wrapper title="Add New User" breadcrumbData={breadcrumb}>
        <Row>
          <Col sm={12} md={6}>
            <Card>
              <CardHeader className="text-white mt-0">Add User</CardHeader>
              <CardBody>
                <Form>
                  <FormGroup row>
                    <Label sm={4} className="required text-left">
                      Select a Role
                    </Label>
                    <Col sm={8}>
                      <Select onChange={handleRoleChange} value={role} options={options} menuPlacement="bottom" />
                    </Col>
                  </FormGroup>
                  {isFleetRole && (
                    <FormGroup row>
                      <Label sm={4} className="required text-left">
                        Select a Company
                      </Label>
                      <Col sm={8}>
                        <Select
                          onChange={handleChangeCompany}
                          value={company}
                          options={optionsCompany}
                          menuPlacement="bottom"
                        />
                      </Col>
                    </FormGroup>
                  )}
                  <FormGroup row>
                    <Label sm={4} className="required text-left">
                      First Name
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="text"
                        name="first_name"
                        placeholder="Ex: John"
                        value={forms.first_name}
                        onChange={handleNameChange}
                        invalid={isFirstNameNotValid}
                        required
                      />
                      {isFirstNameNotValid && <div className="pt-1 text-danger">{firstNameErrorMessage}</div>}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={4} className="text-left">
                      Last Name
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="text"
                        name="last_name"
                        placeholder="Ex: Doe"
                        value={forms.last_name}
                        onChange={handleNameChange}
                        invalid={isLastNameNotValid}
                      />
                      {isLastNameNotValid && <div className="pt-1 text-danger">{lastNameErrorMessage}</div>}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={4} className="required text-left">
                      Email
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="text"
                        name="email"
                        placeholder="Ex: johndoe@otoklix.com"
                        value={forms.email}
                        onChange={handleEmailChange}
                        invalid={isEmailInvalid}
                        required
                      />
                      {isEmailInvalid && <div className="pt-1 text-danger">{emailErrorMessage}</div>}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={4} className="required text-left">
                      Phone Number
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="text"
                        name="phonenumber"
                        placeholder="Ex: +628111222333"
                        value={forms.phone_number}
                        onChange={changePhoneNumber}
                        invalid={isPhoneNumberInvalid}
                        required
                      />
                      {isPhoneNumberInvalid && <div className="pt-1 text-danger">{phoneNumberErrorMessage}</div>}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={4} className="required text-left">
                      Password
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="password"
                        name="password"
                        placeholder="********"
                        value={forms.password}
                        onChange={handlePasswordChange}
                        invalid={isPasswordInvalid}
                        autoComplete="new-password"
                      />
                      {isPasswordInvalid && <div className="pt-1 text-danger">{passwordErrorMessage}</div>}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={4} className="required text-left">
                      Repeat Password
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="password"
                        name="repeatPassword"
                        placeholder="********"
                        value={forms.repeat_password}
                        onChange={handleRepeatPasswordChange}
                        invalid={isRepeatPasswordInvalid}
                      />
                      {isRepeatPasswordInvalid && <div className="pt-1 text-danger">{repeatPasswordErrorMessage}</div>}
                    </Col>
                  </FormGroup>
                  <Button
                    color="primary"
                    size="lg"
                    className="w-50 d-flex justify-content-center m-auto mt-5 rounded-pill"
                    disabled={isButtonDisabled}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Wrapper>
    </React.Fragment>
  );
}

export default AddUser;
