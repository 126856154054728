import React, { useState, useEffect } from 'react';
import { Col, Label, Row, Button, Input, Tooltip, FormGroup } from 'reactstrap';
import Quest from '@assets/icons/question.svg';
import Divider from '@components/divider/Divider';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import UploadImage from '@components/uploads/UploadImage';
import FsLightbox from 'fslightbox-react';

const PublishmentScenarioForm = (props) => {
  const { dataDetail, id, handleNext, handleBack, bannerOptions, disableButton, disableForm } = props;

  const [publicTooltip, setPublicTooltip] = useState(false);
  const [showlistTooltip, setShowlistTooltip] = useState(false);
  const [featuredTooltip, setFeaturedTooltip] = useState(false);
  const [errorImage, setErrorImage] = useState('');
  const [imagePreview, setImagePreview] = useState();
  const [imageKey, setImageKey] = useState();
  const [errorImageDesktop, setErrorImageDesktop] = useState('');
  const [imageDesktopPreview, setImageDesktopPreview] = useState();
  const [imageDesktopKey, setImageDesktopKey] = useState();
  const [loadingUploadImage, setLoadingUploadImage] = useState(false);

  const [detailData, setDetailData] = useState({});

  const isCampaignB2B =
    dataDetail?.customer_eligibility === 'all_customer_b2b' || dataDetail?.customer_segment?.value === 'customer-b2b';

  const handleDefaultData = () => {
    const defaultData = {
      is_public: dataDetail?.is_public,
      is_show_list: dataDetail?.is_show_list,
      is_featured: dataDetail?.is_featured,
      banner_type: dataDetail?.banner_type,
      error_banner_type: dataDetail?.error_banner_type,
      banner_link: dataDetail?.banner_link,
      error_banner_link: dataDetail?.error_banner_link,
      terms_and_condition: dataDetail?.terms_and_condition,
      description: dataDetail?.description,
      image_link: dataDetail?.image_link,
      image_desktop_link: dataDetail?.image_desktop_link,
      seo_title: dataDetail?.seo_title,
      seo_description: dataDetail?.seo_description,
      image_key: null,
      image_desktop_key: null,
      discount_for: dataDetail?.discount_for?.value,
    };
    setDetailData(defaultData);
    setImagePreview(dataDetail?.image_link);
    setImageDesktopPreview(dataDetail?.image_desktop_link);
  };

  const handleParentData = () => {
    dataDetail.is_public = detailData?.is_public;
    dataDetail.is_show_list = detailData?.is_show_list;
    dataDetail.is_featured = detailData?.is_featured;
    dataDetail.banner_type = detailData?.banner_type;
    dataDetail.error_banner_type = detailData?.error_banner_type;
    dataDetail.banner_link = detailData?.banner_link;
    dataDetail.error_banner_link = detailData?.error_banner_link;
    dataDetail.terms_and_condition = detailData?.terms_and_condition;
    dataDetail.description = detailData?.description;
    dataDetail.image_link = detailData?.image_link;
    dataDetail.image_desktop_link = detailData?.image_desktop_link;
    dataDetail.seo_title = detailData?.seo_title;
    dataDetail.seo_description = detailData?.seo_description;
    dataDetail.image_key = detailData?.image_key;
    dataDetail.image_desktop_key = detailData?.image_desktop_key;

    return dataDetail;
  };

  const handleChangePublic = () => {
    detailData.is_public = detailData?.is_public ? false : true;
    setDetailData({ ...detailData });
  };

  const handleChangeShowList = () => {
    detailData.is_show_list = detailData?.is_show_list ? false : true;
    setDetailData({ ...detailData });
  };

  const handleChangeFeatured = () => {
    detailData.is_featured = detailData?.is_featured ? false : true;
    setDetailData({ ...detailData });
  };

  const handleChangeBannerType = (e) => {
    const valueBannerType = e?.value ? e : null;
    validateBannerType(valueBannerType);
  };

  const handleChangeBannerLink = (e) => {
    const valueBannerLink =
      e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : detailData?.banner_link ?? '';
    validateBannerLink(valueBannerLink);
  };

  const handleChangeTerm = (e) => {
    detailData.terms_and_condition =
      e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : detailData?.terms_and_condition ?? '';
    setDetailData({ ...detailData });
  };

  const handleChangeDescription = (e) => {
    detailData.description =
      e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : detailData?.description ?? '';
    setDetailData({ ...detailData });
  };

  const handleChangeSeoTitle = (e) => {
    detailData.seo_title = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : detailData?.seo_title ?? '';
    setDetailData({ ...detailData });
  };

  const handleChangeSeoDescription = (e) => {
    detailData.seo_description =
      e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : detailData?.seo_description ?? '';
    setDetailData({ ...detailData });
  };

  const validateBannerType = (value) => {
    let passValidate = true;
    if (!value?.value && detailData?.is_public) {
      passValidate = false;
      detailData.error_banner_type = 'Pilih Entry Point terlebih dahulu';
    } else {
      detailData.error_banner_type = '';
    }

    detailData.banner_type = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateBannerLink = (value) => {
    let passValidate = true;
    if ((value?.length == 0 || !value) && detailData?.banner_type?.value == 'deeplink') {
      passValidate = false;
      detailData.error_banner_link = 'Field tidak boleh kosong';
    } else {
      detailData.error_banner_link = '';
    }

    detailData.banner_link = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const handleActionNext = () => {
    const passValidateEntryPoint = validateBannerType(detailData?.banner_type);
    const passValidateBannerLink = validateBannerLink(detailData?.banner_link);
    let params = {
      status: passValidateEntryPoint && passValidateBannerLink ? true : false,
      data: handleParentData(),
    };

    handleNext(params);
  };

  const handleActionBack = () => {
    handleBack();
  };

  const handleLoadingUploadImage = (status) => {
    setLoadingUploadImage(status);
  };

  const handleErrorUploadImage = (msg) => {
    setErrorImage(msg);
  };

  const handleDeleteImage = () => {
    setImageKey(null);
    setImagePreview('');
    detailData.image_link = null;
    detailData.image_key = null;
    setDetailData({ ...detailData });
  };

  const handleUploadImage = (image) => {
    setImageKey(image.key);
    setImagePreview(image.preview);
    detailData.image_link = image.preview;
    detailData.image_key = image.key;
    setDetailData({ ...detailData });
  };

  const handleLoadingUploadImageDesktop = (status) => {
    setLoadingUploadImage(status);
  };

  const handleErrorUploadImageDesktop = (msg) => {
    setErrorImageDesktop(msg);
  };

  const handleDeleteImageDesktop = () => {
    setImageDesktopKey(null);
    setImageDesktopPreview('');
    detailData.image_desktop_link = null;
    detailData.image_desktop_key = null;
    setDetailData({ ...detailData });
  };

  const handleUploadImageDesktop = (image) => {
    setImageDesktopKey(image.key);
    setImageDesktopPreview(image.preview);
    detailData.image_desktop_link = image.preview;
    detailData.image_desktop_key = image.key;
    setDetailData({ ...detailData });
  };

  useEffect(() => {
    handleDefaultData();
  }, [dataDetail]);

  return (
    <>
      <Row>
        {dataDetail?.discount_for?.value !== 'per-item' && !isCampaignB2B && (
          <Col sm={12} md={12}>
            <div>
              <Tooltip isOpen={publicTooltip} target="publicTooltip" toggle={() => setPublicTooltip(!publicTooltip)}>
                <p>
                  1. Jika aktif, maka customer dapat klik pada banner untuk menggunakan code voucher dan dapat diketik
                  juga
                </p>
                <p>2. Jika non-aktif, customer wajib mengetik promo code untuk mengaktifkan banner </p>
              </Tooltip>
              <Tooltip
                isOpen={showlistTooltip}
                target="showlistTooltip"
                toggle={() => setShowlistTooltip(!showlistTooltip)}
              >
                <p>Jika aktif, maka customer akan melihatnya di promo list di checkout page</p>
              </Tooltip>
              <Tooltip
                isOpen={featuredTooltip}
                target="featuredTooltip"
                toggle={() => setFeaturedTooltip(!featuredTooltip)}
              >
                <p>Jika aktif, maka customer akan melihatnya di home page</p>
              </Tooltip>

              <Row className="ml-2">
                <Col sm={3} md={2}>
                  <FormGroup row className="mb-0">
                    <Label lg={6} className="text-left">
                      <img className="icon-quest" id="publicTooltip" src={Quest} width="15" />
                      Is Public
                    </Label>
                    <Col lg={6}>
                      <div className="custom-control custom-switch switch-secondary mt-1">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          id="is_public"
                          name="is_public"
                          checked={detailData?.is_public}
                          disabled={disableForm}
                          onChange={() => handleChangePublic()}
                        />
                        <Label className="custom-control-label" htmlFor="is_public">
                          {/* {dataCampaign?.is_public ? labelChecked : labelUnchecked} */}
                        </Label>
                      </div>
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={3} md={2}>
                  <FormGroup row className="mb-0">
                    <Label lg={7} className="text-left">
                      <img className="icon-quest" id="showlistTooltip" src={Quest} width="15" />
                      Is Show List
                    </Label>
                    <Col lg={5}>
                      <div className="custom-control custom-switch switch-secondary mt-1">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          id="is_show_list"
                          name="is_show_list"
                          checked={detailData?.is_show_list}
                          disabled={disableForm}
                          onChange={() => handleChangeShowList()}
                        />
                        <Label className="custom-control-label" htmlFor="is_show_list">
                          {/* {dataCampaign?.is_show_list ? labelChecked : labelUnchecked} */}
                        </Label>
                      </div>
                    </Col>
                  </FormGroup>
                </Col>

                <Col sm={3} md={2}>
                  <FormGroup row className="mb-0">
                    <Label lg={6} className="text-left">
                      <img className="icon-quest" id="featuredTooltip" src={Quest} width="15" />
                      Is Featured
                    </Label>
                    <Col lg={6}>
                      <div className="custom-control custom-switch switch-secondary mt-1">
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          id="is_featured"
                          name="is_featured"
                          checked={detailData?.is_featured}
                          disabled={disableForm}
                          onChange={() => handleChangeFeatured()}
                        />
                        <Label className="custom-control-label" htmlFor="is_featured">
                          {/* {dataCampaign?.is_featured ? labelChecked : labelUnchecked} */}
                        </Label>
                      </div>
                    </Col>
                  </FormGroup>
                </Col>

                <Col sm={3} md={6}>
                  {detailData?.is_public && (
                    <Row className="mb-0">
                      <SelectField
                        colWidth={8}
                        title="Kelompok Promo"
                        placeholder="Pilih Entrypoint"
                        disabled={disableForm}
                        closeMenuOnSelect={true}
                        options={bannerOptions}
                        value={detailData?.banner_type}
                        errorMessage={detailData?.error_banner_type ?? ''}
                        onChange={(e) => handleChangeBannerType(e)}
                      />
                    </Row>
                  )}
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={12}>
                  {detailData?.is_public && detailData?.banner_type?.value == 'deeplink' && (
                    <Row className="mb-3">
                      <Label lg={2} className="text-left label-required">
                        URL
                      </Label>
                      <InputField
                        colWidth={10}
                        inputType="text"
                        disabled={disableForm}
                        value={detailData?.banner_link}
                        invalid={detailData?.error_banner_link ?? false}
                        inputName={'banner_link'}
                        placeholder={'Masukkan URL'}
                        errorMessage={detailData?.error_banner_link ?? ''}
                        onChange={(e) => handleChangeBannerLink(e)}
                      />
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        )}

        <Col sm={12} md={12}>
          <Row className="mb-3">
            <Label lg={2} className="text-left">
              Terms and Condition
            </Label>
            <InputField
              colWidth={10}
              inputType="textarea"
              disabled={disableForm}
              value={detailData?.terms_and_condition}
              placeholder={'Masukkan terms and condition'}
              inputName={'terms_and_condition'}
              onChange={(e) => handleChangeTerm(e)}
            />
          </Row>
        </Col>

        <Col sm={12} md={12}>
          <Row className="mb-3">
            <Label lg={2} className="text-left">
              Deskripsi
            </Label>
            <InputField
              colWidth={10}
              inputType="textarea"
              disabled={disableForm}
              value={detailData?.description}
              placeholder={'Masukkan deskripsi'}
              inputName={'description'}
              onChange={(e) => handleChangeDescription(e)}
            />
          </Row>
        </Col>

        {dataDetail?.discount_for?.value !== 'per-item' && !isCampaignB2B && (
          <Col sm={12} md={12}>
            <Row className="mb-3">
              <Label lg={2} className="text-left">
                Campaign Banner
              </Label>
              <Col lg={3}>
                <UploadImage
                  uploadTitle="Mobile"
                  isView={disableForm}
                  image={imagePreview}
                  error={errorImage}
                  nameSpace={'campaign_banner'}
                  identifier={dataDetail?.voucher_code}
                  maxSize={3072}
                  maxSizeString={'3MB'}
                  onLoading={handleLoadingUploadImage}
                  onError={handleErrorUploadImage}
                  onDelete={handleDeleteImage}
                  onUpload={handleUploadImage}
                />
                <div className="invalid-document">{errorImage}</div>
              </Col>
              <Col lg={3}>
                <UploadImage
                  uploadTitle="Web"
                  isView={disableForm}
                  image={imageDesktopPreview}
                  error={errorImageDesktop}
                  nameSpace={'campaign_banner'}
                  identifier={dataDetail?.voucher_code}
                  maxSize={3072}
                  maxSizeString={'3MB'}
                  onLoading={handleLoadingUploadImageDesktop}
                  onError={handleErrorUploadImageDesktop}
                  onDelete={handleDeleteImageDesktop}
                  onUpload={handleUploadImageDesktop}
                />
                <div className="invalid-document">{errorImageDesktop}</div>
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      {detailData?.is_public && detailData?.banner_type?.value == 'landing-page' && (
        <div>
          <Divider title="SEO" />
          <Row>
            <Col sm={12} md={12}>
              <Row className="mb-3">
                <Label lg={2} className="text-left">
                  Meta Title
                </Label>
                <InputField
                  colWidth={10}
                  inputType="text"
                  disabled={disableForm}
                  value={detailData?.seo_title}
                  placeholder={'Masukkan meta title'}
                  inputName={'seo_title'}
                  onChange={(e) => handleChangeSeoTitle(e)}
                />
              </Row>
            </Col>
            <Col sm={12} md={12}>
              <Row className="mb-3">
                <Label lg={2} className="text-left">
                  Meta Deskripsi
                </Label>
                <InputField
                  colWidth={10}
                  inputType="textarea"
                  disabled={disableForm}
                  value={detailData?.seo_description}
                  placeholder={'Masukkan meta deskripsi'}
                  inputName={'seo_description'}
                  onChange={(e) => handleChangeSeoDescription(e)}
                />
              </Row>
            </Col>
          </Row>
        </div>
      )}

      <Row>
        <Col sm={12} md={12} className="section-action mt-2">
          <Button
            disabled={disableButton || loadingUploadImage}
            className="button-action save"
            onClick={() => handleActionNext('validation')}
          >
            {'Simpan'}
          </Button>
          <Button
            disabled={disableButton}
            className="button-action cancel"
            onClick={() => handleActionBack('publishment')}
          >
            {'Kembali'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PublishmentScenarioForm;
