import React, { useState, useEffect } from 'react';
import { Col, Label, Row, Button, Input } from 'reactstrap';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import { regexOnlyAllowNumber } from '@utils/Constants';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import map from 'lodash/map';
import SelectAsyncField from '@components/field/SelectAsyncField';

const SetupValidationRulesForm = (props) => {
  const { dataDetail, handleNext, handleBack, disableButton, disableForm } = props;

  const api = new API('v2');
  const [detailData, setDetailData] = useState({});
  const [serviceOptions, setServiceOptions] = useState({});
  const [brandOptions, setBrandOptions] = useState({});
  const [packageOptions, setPackageOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [serviceLength, setServiceLength] = useState(0);
  const isCampaignSelectedPackage = dataDetail?.discount_for?.value === 'selected-packages' ? true : false;
  const isCampaignB2B =
    dataDetail?.customer_eligibility === 'all_customer_b2b' || dataDetail?.customer_segment?.value === 'customer-b2b';

  const handleDefaultData = () => {
    const defaultData = {
      min_transaction: dataDetail?.min_transaction,
      services: dataDetail?.services,
      brands: dataDetail?.brands,
      products: isCampaignSelectedPackage ? [] : dataDetail?.products,
      workshops: dataDetail?.workshops,
      packages: dataDetail?.packages,
      booking_origin: dataDetail?.discount_for?.value === 'per-item' ? ['gms'] : dataDetail?.booking_origin ?? [],
      error_min_transaction: dataDetail?.error_min_transaction,
      error_workshop: dataDetail?.error_workshop,
      error_service: dataDetail?.error_service,
      error_booking_origin: dataDetail?.error_booking_origin,
      error_cannot_be_combined: dataDetail?.error_cannot_be_combined,
      select_all_workshop:
        dataDetail?.workshops?.length > 0 ? (dataDetail?.workshops[0]?.value == 'all' ? true : false) : false,
      discount_for: dataDetail?.discount_for?.value,
      exclusion: dataDetail?.exclusion,
      payment_type: dataDetail?.payment_type,
      cannotBeCombined: dataDetail?.cannotBeCombined,
    };
    setDetailData(defaultData);
  };

  const handleParentData = () => {
    dataDetail.min_transaction = detailData?.min_transaction;
    dataDetail.services = detailData?.services;
    dataDetail.brands = detailData?.brands;
    dataDetail.products = isCampaignSelectedPackage ? [] : detailData?.products;
    dataDetail.workshops = detailData?.select_all_workshop
      ? [{ value: 'all', name: 'Semua Worshop' }]
      : detailData?.workshops;
    dataDetail.packages = detailData?.packages;
    dataDetail.error_min_transaction = detailData?.error_min_transaction;
    dataDetail.error_workshop = detailData?.error_workshop;
    dataDetail.error_non_item_promo = detailData?.error_non_item_promo;
    dataDetail.error_service = detailData?.error_service;
    dataDetail.payment_type = detailData?.payment_type;
    dataDetail.cannotBeCombined = detailData?.cannotBeCombined;
    dataDetail.exclusion = detailData?.exclusion;
    return dataDetail;
  };

  const handleParentDataPerLine = () => {
    dataDetail.min_transaction = detailData?.min_transaction;
    dataDetail.workshops = detailData?.select_all_workshop
      ? [{ value: 'all', name: 'Semua Worshop' }]
      : detailData?.workshops;
    dataDetail.error_min_transaction = detailData?.error_min_transaction;
    dataDetail.error_workshop = detailData?.error_workshop;

    return dataDetail;
  };

  const handleChangeMinTransaction = (e) => {
    const valueMinTrans =
      e.target.value !== ' ' ? Helper.formatMoneyOnField(e.target.value) : detailData?.min_transaction ?? '';
    validateMinTransaction(valueMinTrans);
  };

  const handleClickBookingOrigin = (value, setDefault) => {
    const valueBookingOrigin = detailData?.booking_origin;
    const index = valueBookingOrigin.indexOf(value);
    if (isCampaignB2B && setDefault) {
      if (index < 0) valueBookingOrigin.push('intools');
    } else {
      if (index < 0) {
        valueBookingOrigin.push(value);
      } else {
        valueBookingOrigin.splice(index, 1);
      }
    }
    validateBookingOrigin(valueBookingOrigin);
  };

  const handleChangeServices = (e) => {
    const valueServices = e ?? null;
    validateServices(valueServices, false);
  };

  const handleChangeBrands = (e) => {
    const valueBrands = e ?? null;
    validateBrands(valueBrands);
  };

  const handleChangeProducts = (e) => {
    const valueProducts = e ?? null;
    if (isCampaignB2B) {
      validateItems(valueProducts);
    } else {
      validateProducts(valueProducts);
    }
  };

  const handleChangeWorkshops = (e) => {
    const valueWorkshops = e ?? null;
    validateWorkshops(valueWorkshops, false);
  };

  const handleChangeNonItemPromo = (e) => {
    const valueNonItemPromo = e ?? null;
    validateNonItemPromo(valueNonItemPromo);
  };

  const handleChangeCannotBeCombined = (e) => {
    const valueCannotBeCombined = e ?? null;
    validatCannotBeCombined(valueCannotBeCombined);
  };

  const handleChangePackages = (e) => {
    const valuePackages = e ?? null;
    validatePackages(valuePackages);
  };

  const handleChangePackagesB2b = (e) => {
    const valuePackages = e ?? null;
    validatePackagesB2b(valuePackages);
  };

  const handleChangeCheckBoxWorkshop = (value) => {
    const valueCheckbox = value ?? null;
    validateCheckboxWorkshop(valueCheckbox);
  };

  const handleChangePaymentType = (value) => {
    const valuePaymentType = value ?? null;
    validatePaymentType(valuePaymentType);
  };

  const validatePaymentType = (value) => {
    let passValidate = true;

    if (!value) {
      passValidate = false;
      detailData.error_payment_type = 'Pilih salah satu terlebih dahulu';
    } else {
      detailData.error_payment_type = '';
    }
    if (detailData?.discount_for !== 'whole-cart') {
      passValidate = true;
    }
    detailData.error_service = '';
    detailData.payment_type = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateMinTransaction = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      detailData.error_min_transaction = 'Field tidak boleh kosong';
    } else if (value?.formatMoney < 0 || value?.formatMoney > 999999999) {
      passValidate = false;
      detailData.error_min_transaction = 'Minimal Rp 0 dan maximal Rp 999.999.999';
    } else {
      detailData.error_min_transaction = '';
    }

    detailData.min_transaction = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateBookingOrigin = (value) => {
    let passValidate = true;

    if (value?.length < 1) {
      passValidate = false;
      detailData.error_booking_origin = 'Pilih salah satu';
    } else {
      detailData.error_booking_origin = '';
      detailData.error_service = '';
    }

    dataDetail.booking_origin = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateServices = (value, actionNext) => {
    let passValidate = true;
    if ((!value || value?.length < 1) && (detailData?.workshops?.length > 0 || detailData?.select_all_workshop)) {
      passValidate = false;
      detailData.error_service = 'Field tidak boleh kosong';
    } else {
      detailData.error_service = '';
    }

    detailData.services = value;
    if (!actionNext) {
      detailData.brands = [];
      detailData.packages = [];
      detailData.products = [];
    }

    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateBrands = (value) => {
    let passValidate = true;

    detailData.brands = value;
    detailData.packages = [];
    detailData.products = [];
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateProducts = (value) => {
    let passValidate = true;

    detailData.products = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateWorkshops = (value, actionNext) => {
    let passValidate = true;

    if (!value || (value?.length < 1 && !detailData?.select_all_workshop)) {
      passValidate = false;
      detailData.error_workshop = 'Field tidak boleh kosong';
    } else if (value?.length > 0 || detailData?.select_all_workshop) {
      detailData.error_workshop = '';
    }

    detailData.workshops = value;
    if (!actionNext) {
      detailData.services = [];
      detailData.error_service = '';
      detailData.brands = [];
      detailData.packages = [];
      detailData.products = [];
    }

    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateItems = (value, actionNext) => {
    let passValidate = true;
    if (value?.length < 1 && dataDetail?.packages?.length < 1) {
      passValidate = false;
      detailData.error_items = 'Field tidak boleh kosong';
    } else if (value?.length > 0) {
      detailData.error_items = '';
      passValidate = true;
    }

    detailData.products = value;

    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateNonItemPromo = (value, actionNext) => {
    let passValidate = true;
    if (value?.length >= 1) {
      detailData.exclusion = value;
      detailData.error_non_item_promo = '';
      passValidate = true;
    } else {
      detailData.exclusion = [];
      passValidate = false;
    }
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validatCannotBeCombined = (value, actionNext) => {
    let passValidate = true;

    detailData.error_cannot_be_combined = '';
    if (value?.length >= 1) {
      passValidate = true;
      detailData.cannotBeCombined = value;
    } else {
      passValidate = false;
      detailData.cannotBeCombined = [];
    }
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validatePackages = (value) => {
    let passValidate = true;

    detailData.packages = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validatePackagesB2b = (value) => {
    let passValidate = true;

    if (value?.length < 1 && dataDetail?.products?.length < 1) {
      passValidate = false;
      detailData.error_packages = 'Field tidak boleh kosong';
    } else if (value?.length > 0) {
      detailData.error_packages = '';
      passValidate = true;
    }

    detailData.packages = value;
    setDetailData({ ...detailData });
    return passValidate;
  };

  const validateCheckboxWorkshop = (value) => {
    let passValidate = true;

    detailData.select_all_workshop = value;
    detailData.error_workshop = '';
    detailData.workshops = [];
    detailData.services = [];
    detailData.brands = [];
    detailData.packages = [];
    detailData.products = [];
    detailData.error_service = '';
    setDetailData({ ...detailData });
    return passValidate;
  };

  const handleActionNext = () => {
    const passValidateMinTrans = validateMinTransaction(detailData?.min_transaction);
    const passValidateWorkshop = validateWorkshops(detailData?.workshops, true);
    const passValidateService =
      detailData?.discount_for !== 'whole-cart' ? validateServices(detailData?.services, true) : true;
    const passValidatePaymentMethod = validatePaymentType(detailData?.payment_type);
    const passValidateBookingOrigin = validateBookingOrigin(detailData?.booking_origin);
    const passValidatePackages = validatePackages(detailData?.packages);
    const passValidatePackagesB2b = validatePackagesB2b(detailData?.packages);
    const passValidateProducts = isCampaignB2B
      ? validateItems(detailData?.products)
      : validateProducts(detailData?.products);
    const params = {
      status:
        passValidatePaymentMethod &&
        passValidateMinTrans &&
        passValidateWorkshop &&
        passValidateService &&
        passValidateBookingOrigin &&
        passValidateProducts &&
        (isCampaignB2B ? passValidatePackagesB2b : passValidatePackages)
          ? true
          : false,
      data: handleParentData(),
    };
    const paramsPerLine = {
      status: passValidateWorkshop && passValidateBookingOrigin ? true : false,
      data: handleParentDataPerLine(),
    };
    handleNext(detailData?.discount_for !== 'per-item' ? params : paramsPerLine);
  };

  const handleActionBack = () => {
    handleBack();
  };

  const workshopLength = detailData?.workshops?.length;
  const brandLength = detailData?.brands?.length;
  const packageLength = detailData?.packages?.length;

  const generateParams = (data, key) => {
    const dataList = map(data, (item) => {
      return `${key}=${item?.value}`;
    }).join('&');

    return dataList;
  };

  const loadServiceOptions = (value) => {
    const workshopList = detailData?.select_all_workshop
      ? 'workshop=all'
      : generateParams(detailData?.workshops, 'workshop');

    let url = `v2/intools/campaigns/options/service/?keyword=${value ?? '' + '&' + workshopList}`;

    return api
      .get(url)
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.id, label: item.name };
        });

        setServiceOptions(transformedOptions);
        return transformedOptions;
      })
      .catch((error) => {
        setServiceOptions([]);
      });
  };

  const loadBrandOptions = (value) => {
    const workshopList = detailData?.select_all_workshop
      ? 'workshop=all'
      : generateParams(detailData?.workshops, 'workshop');
    let serviceList = generateParams(detailData?.services, 'service');

    let url = `v2/intools/campaigns/options/brand/?keyword=${value ?? ''}&${workshopList}&${serviceList}`;

    return api
      .get(url)
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.id, label: item.name ?? '-' };
        });

        setBrandOptions(transformedOptions);
        return transformedOptions;
      })
      .catch((error) => {
        setBrandOptions([]);
      });
  };

  const loadProductOptions = (value) => {
    const workshopList = detailData?.select_all_workshop
      ? 'workshop=all'
      : generateParams(detailData?.workshops, 'workshop');
    let serviceList = generateParams(detailData?.services, 'service');
    let fbo = `fbo=all`;
    let brandList = generateParams(detailData?.brands, 'brand');
    let packageList = generateParams(detailData?.packages, 'package');

    let url = `v2/intools/campaigns/options/product/?keyword=${
      value ?? ''
    }&${workshopList}&${serviceList}&${fbo}&${brandList}&${packageList}`;

    return api
      .get(url)
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.id, label: item.name };
        });

        setProductOptions(transformedOptions);
        return transformedOptions;
      })
      .catch((error) => {});
  };

  const loadProductOptionsB2B = (value) => {
    const workshopList = detailData?.select_all_workshop
      ? 'workshop=all'
      : generateParams(detailData?.workshops, 'workshop');
    let serviceList = generateParams(detailData?.services, 'service');
    let fbo = `fbo=all`;
    let brandList = generateParams(detailData?.brands, 'brand');
    let packageList = generateParams(detailData?.packages, 'package');

    let url = `v2/intools/campaigns/options/item/?keyword=${
      value ?? ''
    }&${workshopList}&${serviceList}&${fbo}&${brandList}&${packageList}&customer_type=b2b`;

    return api
      .get(url)
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.id, label: item.name };
        });
        return transformedOptions;
      })
      .catch((error) => {});
  };

  const loadWorkshopOptions = (value) => {
    let url = value
      ? `v2/intools/campaigns/options/workshop/?keyword=${value}`
      : `v2/intools/campaigns/options/workshop/`;

    return api
      .get(url)
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.id, label: item.name ?? '-' };
        });

        return transformedOptions;
      })
      .catch((error) => {});
  };

  const loadPromoOptions = (value) => {
    let url = `/v2/intools/promos/discount/whole-cart?query=${value}&limit=20&page=1`;

    return api
      .get(url)
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.id, label: item.slug ?? '-' };
        });

        return transformedOptions;
      })
      .catch((error) => {});
  };

  const loadCampaignOptions = (value) => {
    let url = `/v2/intools/master/inventories?query=${value}&workshops=&page=&limit=20`;

    return api
      .get(url)
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.id, reference: item.reference, label: item.name ?? '-' };
        });

        return transformedOptions;
      })
      .catch((error) => {});
  };

  const loadPackageOptions = (value) => {
    const workshopList = detailData?.select_all_workshop
      ? 'workshop=all'
      : generateParams(detailData?.workshops, 'workshop');
    let serviceList = generateParams(detailData?.services, 'service');
    let fbo = `fbo=all`;
    let brandList = generateParams(detailData?.brands, 'brand');

    let url = `v2/intools/campaigns/options/package/?keyword=${
      value ?? ''
    }&${workshopList}&${serviceList}&${fbo}&${brandList}`;

    return api
      .get(url)
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.id, label: item.name };
        });

        setPackageOptions(transformedOptions);
        return transformedOptions;
      })
      .catch((error) => {});
  };

  const loadPackageOptionsB2B = (value) => {
    const workshopList = detailData?.select_all_workshop
      ? 'workshop=all'
      : generateParams(detailData?.workshops, 'workshop');
    let serviceList = generateParams(detailData?.services, 'service');
    let fbo = `fbo=all`;
    let brandList = generateParams(detailData?.brands, 'brand');

    let url = `v2/intools/campaigns/options/package/?keyword=${
      value ?? ''
    }&${workshopList}&${serviceList}&${fbo}&${brandList}&customer_type=b2b`;

    return api
      .get(url)
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.id, label: item.name };
        });
        return transformedOptions;
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setServiceLength(detailData?.services?.length);
    loadBrandOptions();
    loadPackageOptions();
    loadPackageOptionsB2B();
  }, [detailData?.services?.length]);

  useEffect(() => {
    handleDefaultData();
  }, [dataDetail]);

  useEffect(() => {
    if (workshopLength > 0 || detailData?.select_all_workshop) {
      loadServiceOptions();
    }
  }, [workshopLength, detailData?.select_all_workshop]);

  useEffect(() => {
    if (+workshopLength > 0 && +serviceLength > 0) {
      loadBrandOptions();
      loadPackageOptions();
      loadPackageOptionsB2B();
    }
  }, [workshopLength, serviceLength]);

  useEffect(() => {
    if (packageLength > 0 && !isCampaignSelectedPackage) {
      loadProductOptions();
      loadProductOptionsB2B();
    }
  }, [workshopLength, serviceLength, brandLength, packageLength]);

  useEffect(() => {
    if (isCampaignB2B && detailData?.booking_origin) {
      handleClickBookingOrigin('intools', true);
    }
  }, [isCampaignB2B, detailData?.booking_origin]);

  return (
    <>
      <Row>
        {detailData?.discount_for !== 'per-item' && (
          <Col sm={12} md={12}>
            <Row className="mb-3">
              <Label lg={2} className="text-left label-required">
                Minimal Transaction(Rp)
              </Label>
              <InputField
                colWidth={10}
                inputName="min_transaction"
                placeholder={'Masukkan minimal transaction(Rp)'}
                value={detailData?.min_transaction?.formatMoneyString}
                disabled={disableForm}
                invalid={detailData?.error_min_transaction ? true : false}
                errorMessage={detailData?.error_min_transaction ?? ''}
                inputPattern={regexOnlyAllowNumber}
                onChange={(e) => handleChangeMinTransaction(e)}
              />
            </Row>
          </Col>
        )}

        <Col sm={12} md={12} className="mb-3">
          <Row>
            <Label sm={6} lg={2} className="text-left label-required">
              Booking Origin
            </Label>
            <Col sm={12} lg={10}>
              <Row>
                <Col sm={12} md={2}>
                  <Label className="ml-4 mt-2 mb-0">
                    <Input
                      type="checkbox"
                      checked={detailData?.booking_origin?.includes('intools')}
                      onClick={() => handleClickBookingOrigin('intools', false)}
                      disabled={disableForm || detailData?.discount_for === 'per-item' || isCampaignB2B}
                    />
                    Intools
                  </Label>
                </Col>
                {!isCampaignB2B && (
                  <>
                    <Col sm={12} md={2}>
                      <Label className="ml-4 mt-2 mb-0">
                        <Input
                          type="checkbox"
                          checked={detailData?.booking_origin?.includes('mobile')}
                          onClick={() => handleClickBookingOrigin('mobile', false)}
                          disabled={disableForm || detailData?.discount_for === 'per-item'}
                        />
                        Mobile App
                      </Label>
                    </Col>
                    <Col sm={12} md={2}>
                      <Label className="ml-4 mt-2 mb-0">
                        <Input
                          type="checkbox"
                          checked={detailData?.booking_origin?.includes('web', false)}
                          onClick={() => handleClickBookingOrigin('web')}
                          disabled={disableForm || detailData?.discount_for === 'per-item'}
                        />
                        Web App
                      </Label>
                    </Col>
                    <Col sm={12} md={2}>
                      <Label className="ml-4 mt-2 mb-0">
                        <Input
                          type="checkbox"
                          checked={
                            detailData?.booking_origin?.includes('gms') || detailData?.discount_for === 'per-item'
                          }
                          onClick={() => handleClickBookingOrigin('gms', false)}
                          disabled={disableForm}
                        />
                        GMS
                      </Label>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col>
                  {detailData?.discount_for === 'per-item' && (
                    <div className="text-info">Discount Per Baris hanya dapat digunakan untuk GMS</div>
                  )}
                  {detailData?.error_booking_origin && (
                    <div className="text-danger">{detailData?.error_booking_origin}</div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <Row className="mb-3">
            <Label lg={2} className="text-left label-required">
              Workshop
            </Label>
            <SelectAsyncField
              colWidth={8}
              isMulti={true}
              defaultOptions={[]}
              placeholder={'Cari workshop'}
              isDisabled={disableForm || detailData?.select_all_workshop ? true : false}
              value={detailData?.select_all_workshop ? [] : detailData?.workshops ?? []}
              errorMessage={detailData?.error_workshop ?? ''}
              loadOptions={loadWorkshopOptions}
              isSelectAll={true}
              closeMenuOnSelect={false}
              onChange={(e) => handleChangeWorkshops(e)}
            />
            <Col md={2}>
              <Label className="ml-4 mt-2 mb-0">
                <Input
                  type="checkbox"
                  checked={detailData?.select_all_workshop}
                  onClick={() => handleChangeCheckBoxWorkshop(!detailData?.select_all_workshop)}
                  invalid={true}
                  disabled={disableForm}
                />
                Semua Workshop
              </Label>
            </Col>
          </Row>
        </Col>
        {detailData?.discount_for !== 'per-item' && detailData?.discount_for !== 'whole-cart' && (
          <>
            <Col sm={12} md={6}>
              <Row className="mb-3">
                <Label lg={4} className="text-left label-required">
                  Servis
                </Label>
                <SelectField
                  colWidth={8}
                  isMulti={true}
                  SelectName="services"
                  placeholder={'Cari servis'}
                  value={detailData?.services ?? ''}
                  invalid={detailData?.error_service ? true : false}
                  errorMessage={detailData?.error_service ?? ''}
                  options={serviceOptions}
                  disabled={disableForm || workshopLength > 0 || detailData?.select_all_workshop ? false : true}
                  isSelectAll={true}
                  closeMenuOnSelect={false}
                  onChange={(e) => handleChangeServices(e)}
                />
              </Row>
            </Col>
            <Col sm={12} md={6}>
              <Row className="mb-3">
                <Label lg={4} className="text-left">
                  Brands
                </Label>
                <SelectField
                  colWidth={8}
                  isMulti={true}
                  SelectName="brands"
                  placeholder={'Cari brands'}
                  value={detailData?.brands ?? ''}
                  options={Array.isArray(brandOptions) ? brandOptions : []}
                  disabled={
                    disableForm || ((workshopLength > 0 || detailData?.select_all_workshop) && serviceLength > 0)
                      ? false
                      : true
                  }
                  isSelectAll={true}
                  onLoading={true}
                  closeMenuOnSelect={false}
                  onChange={(e) => handleChangeBrands(e)}
                />
              </Row>
            </Col>
            <Col sm={12} md={12}>
              <Row className="mb-3">
                <Label lg={2} className="text-left">
                  Packages
                </Label>
                {isCampaignB2B ? (
                  <>
                    <SelectAsyncField
                      colWidth={10}
                      isMulti={true}
                      SelectName="packages"
                      placeholder={'Cari packages'}
                      value={detailData?.packages ?? ''}
                      defaultOptions={[]}
                      loadOptions={loadPackageOptionsB2B}
                      disabled={
                        disableForm || ((workshopLength > 0 || detailData?.select_all_workshop) && serviceLength > 0)
                          ? false
                          : true
                      }
                      isSelectAll={true}
                      closeMenuOnSelect={false}
                      onChange={(e) => handleChangePackagesB2b(e)}
                      invalid={detailData?.error_packages ? true : false}
                      errorMessage={detailData?.error_packages ?? ''}
                    />
                  </>
                ) : (
                  <>
                    <SelectField
                      colWidth={10}
                      isMulti={true}
                      SelectName="packages"
                      placeholder={'Cari packages'}
                      value={detailData?.packages ?? ''}
                      options={Array.isArray(packageOptions) ? packageOptions : []}
                      disabled={
                        disableForm || ((workshopLength > 0 || detailData?.select_all_workshop) && serviceLength > 0)
                          ? false
                          : true
                      }
                      isSelectAll={true}
                      closeMenuOnSelect={false}
                      onChange={(e) => handleChangePackages(e)}
                    />
                  </>
                )}
              </Row>
            </Col>
            {isCampaignB2B && (
              <Col sm={12} md={12}>
                <Row className="mb-3">
                  <Label lg={2} className="text-left">
                    Item
                  </Label>
                  <SelectAsyncField
                    colWidth={10}
                    isMulti={true}
                    SelectName="product"
                    placeholder={'Cari Item'}
                    value={detailData?.products ?? ''}
                    defaultOptions={[]}
                    loadOptions={isCampaignB2B ? loadProductOptionsB2B : loadProductOptions}
                    disabled={
                      disableForm || ((workshopLength > 0 || detailData?.select_all_workshop) && serviceLength > 0)
                        ? false
                        : true
                    }
                    isSelectAll={true}
                    closeMenuOnSelect={false}
                    onChange={(e) => handleChangeProducts(e)}
                    invalid={detailData?.error_items ? true : false}
                    errorMessage={detailData?.error_items ?? ''}
                  />
                </Row>
              </Col>
            )}
            {!isCampaignSelectedPackage && !isCampaignB2B && (
              <Col sm={12} md={12}>
                <Row className="mb-3">
                  <Label lg={2} className="text-left">
                    Produk
                  </Label>
                  <SelectField
                    colWidth={10}
                    isMulti={true}
                    SelectName="product"
                    placeholder={'Cari produk'}
                    value={detailData?.products ?? ''}
                    options={Array.isArray(productOptions) ? productOptions : []}
                    disabled={
                      disableForm || ((workshopLength > 0 || detailData?.select_all_workshop) && serviceLength > 0)
                        ? false
                        : true
                    }
                    isSelectAll={true}
                    closeMenuOnSelect={false}
                    onChange={(e) => handleChangeProducts(e)}
                  />
                </Row>
              </Col>
            )}
          </>
        )}

        {detailData?.discount_for === 'whole-cart' && (
          <>
            <Col sm={12} md={12}>
              <Row className="mb-3">
                <Label lg={2} className="text-left">
                  Non Promo Item
                </Label>
                <SelectAsyncField
                  colWidth={8}
                  isMulti={true}
                  defaultOptions={[]}
                  placeholder={'Cari nama product/paket/jasa'}
                  value={detailData?.exclusion ?? []}
                  errorMessage={detailData?.error_non_item_promo ?? ''}
                  loadOptions={loadCampaignOptions}
                  closeMenuOnSelect={false}
                  onChange={(e) => handleChangeNonItemPromo(e)}
                />
              </Row>
              <Row>
                <Label sm={4} lg={2} className="text-left label-required">
                  Pembayaran
                </Label>
                <Col sm={12} lg={10}>
                  <Row>
                    <Col sm={12} md={2}>
                      <Label className="ml-4 mt-2 mb-0">
                        <Input
                          type="radio"
                          checked={detailData?.payment_type == 'all' ? true : false}
                          onClick={() => handleChangePaymentType('all')}
                        />
                        All
                      </Label>
                    </Col>
                    <Col sm={12} md={2}>
                      <Label className="ml-4 mt-2 mb-0">
                        <Input
                          type="radio"
                          checked={detailData?.payment_type == 'offline' ? true : false}
                          onClick={() => handleChangePaymentType('offline')}
                        />
                        Offline
                      </Label>
                    </Col>
                    <Col sm={12} md={4}>
                      <Label className="ml-4 mt-2 mb-0">
                        <Input
                          type="radio"
                          checked={detailData?.payment_type == 'online' ? true : false}
                          onClick={() => handleChangePaymentType('online')}
                        />
                        Online
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    {detailData?.error_payment_type && (
                      <div className="text-danger ml-2">{detailData?.error_payment_type}</div>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row className="mb-3 mt-3">
                <Label lg={2} className="text-left">
                  Tidak Dapat Digabung dengan
                </Label>
                <SelectAsyncField
                  colWidth={8}
                  isMulti={true}
                  defaultOptions={[]}
                  placeholder={'Cari slug campaign'}
                  value={detailData?.cannotBeCombined ?? []}
                  errorMessage={detailData?.error_cannot_be_combined ?? ''}
                  loadOptions={loadPromoOptions}
                  closeMenuOnSelect={false}
                  onChange={(e) => handleChangeCannotBeCombined(e)}
                />
              </Row>
            </Col>
          </>
        )}
      </Row>

      <Row>
        <Col sm={12} md={12} className="section-action mt-3">
          <Button disabled={disableButton} className="button-action save" onClick={() => handleActionNext()}>
            {'Selanjutnya'}
          </Button>
          <Button disabled={disableButton} className="button-action cancel" onClick={() => handleActionBack()}>
            {'Kembali'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SetupValidationRulesForm;
