import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import CustomerLocation from '@components/customer/CustomerLocation';
import CustomerPriceAgreement from './CustomerPriceAgreement';
import HistoryPointTransaction from '@components/customer/HistoryPointTransaction';
import { assign, map } from 'lodash';
import { parsePhoneNumber } from 'libphonenumber-js';
import CustomerDetailForms from './CustomerDetailForms';
import API from '@utils/API';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PRICE_AGREEMENT_VALUE } from '@utils/Constants';
import AddCarModal from '../modal/AddCarModal';
import CustomerCar from './CustomerCar';
import ConfirmationModal from '../modal/ConfirmationModal';
import { validate } from 'react-email-validator';
import userPermission from '@utils/userPermission';

const CustomerForms = (props) => {
  const {
    id,
    origin,
    handleResultNotification,
    activeCustomerType,
    handleEditForm,
    handleChangeOrigin,
    initialValues,
    loadUserDetail,
    userLocations,
    userCars,
    loadUserCar,
    wsList,
    loyaltyPoints,
    loadListWorkshop,
    loadingLoadWorkshop,
    loadListPIC,
    loadingLoadPIC,
    listPIC,
    configDoc,
  } = props;
  const api = new API('v2');
  const history = useHistory();
  const { generatePermission } = userPermission();

  const [formValues, setFormValues] = useState({});
  const [customerType, setCustomerType] = useState(activeCustomerType ?? 'b2c');
  const [submitStatus, setSubmitStatus] = useState(null);
  const [disableForm, setDisableForm] = useState(origin === 'create' ? false : true);
  const [toggleAddCarModal, setToggleAddCarModal] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [isGetDetailSuccess, setIsGetDetailSuccess] = useState(false);
  const [hasModalConfirmation, setHasModalConfirmation] = useState(false);
  const [confirmationType, setConfirmationType] = useState('Simpan');
  const [hasLocationDetail, setHasLocationDetail] = useState(false);

  const errorToDelete = [
    'error_customer_name',
    'error_phone_number',
    'error_email',
    'error_user_type',
    'error_flag',
    'error_nickname',
    'error_pic_name',
    'error_pic_phone',
    'error_pic_email',
    'error_b2b_customer_status',
    'error_product_markup',
    'error_service_markup',
    'error_product_discount',
    'error_service_discount',
    'error_internal_notes',
    'error_workshop_blacklist',
    'error_photo',
    'error_b2b_pic',
    'error_company_address',
    'error_billing_address',
    'error_config_document',
  ];

  const mappingForm = (type = 'b2c') => {
    let dataForm = {
      photo: initialValues?.photo ?? '',
      customer_name: initialValues?.customer_name ?? '',
      phone_number: initialValues?.phone_number ?? '',
      email: initialValues?.email ?? '',
      user_type: initialValues?.user_type ?? '',
      flag: initialValues?.flag ?? '',
      error_customer_name: '',
      error_phone_number: '',
      error_email: '',
      error_user_type: '',
      error_flag: '',
    };

    if (type === 'b2b') {
      const documents = [
        { id: 1, slug: 'receipt', checked: true, label: 'Receipt', deleted: false },
        { id: 2, slug: 'spk', checked: true, label: 'SPK Customer', deleted: false },
        { id: 3, slug: 'bast', checked: true, label: 'BAST Otoklix', deleted: false },
        { id: 4, slug: 'offering-letter', checked: true, label: 'Surat Penawaran', deleted: false },
        { id: 5, slug: 'service-photo', checked: true, label: 'Foto Servis', deleted: false },
      ];
      assign(dataForm, {
        nickname: initialValues?.nickname ?? '',
        pic_name: initialValues?.pic_name ?? '',
        pic_phone: initialValues?.pic_phone ?? '',
        b2b_pic: initialValues?.b2b_pic ?? '',
        b2b_customer_status: initialValues?.b2b_customer_status ?? 'trial',
        config_documents: initialValues?.config_documents ?? configDoc,
        product_markup: initialValues?.product_markup ?? DEFAULT_PRICE_AGREEMENT_VALUE,
        service_markup: initialValues?.service_markup ?? DEFAULT_PRICE_AGREEMENT_VALUE,
        product_discount: initialValues?.product_discount ?? DEFAULT_PRICE_AGREEMENT_VALUE,
        service_discount: initialValues?.service_discount ?? DEFAULT_PRICE_AGREEMENT_VALUE,
        internal_notes: initialValues?.internal_notes ?? '',
        company_address: initialValues?.company_address ?? '',
        billing_address: initialValues?.billing_address ?? '',
        error_nickname: '',
        error_pic_name: '',
        error_pic_phone: '',
        error_pic_email: '',
        error_b2b_customer_status: '',
        error_product_markup: '',
        error_service_markup: '',
        error_product_discount: '',
        error_service_discount: '',
        error_internal_notes: '',
        error_company_address: '',
        error_billing_address: '',
      });

      assign(dataForm, {
        workshop_blacklist: initialValues?.workshop_blacklist ?? [],
        error_workshop_blacklist: '',
      });
    } else {
      let toRemove = [
        'nickname',
        'pic_name',
        'pic_phone',
        'pic_email',
        'b2b_customer_status',
        'product_markup',
        'service_markup',
        'product_discount',
        'service_discount',
        'internal_notes',
        'error_nickname',
        'error_pic_name',
        'error_pic_phone',
        'error_pic_email',
        'error_b2b_customer_status',
        'error_product_markup',
        'error_service_markup',
        'error_product_discount',
        'error_service_discount',
        'error_internal_notes',
      ];

      if (origin !== 'create') toRemove.push(['workshop_blacklist', 'error_workshop_blacklist']);

      map(dataForm, (item, index) => {
        delete dataForm[toRemove[index]];
      });
    }

    setFormValues(dataForm);
  };

  const handleChangeImage = (image) => {
    handleChangeFormValues('photo', image, 'error_photo', '');
  };

  const handleErrorImage = (errMsg) => {
    let notification = {
      status: true,
      type: 'fail',
      message: errMsg ?? 'error!',
    };

    handleResultNotification(notification);
  };

  const handleChangeCustomerType = (e) => {
    setCustomerType(e.target.value);
  };

  const handleChangeFormValues = (key, value, errorKey, errorMsg) => {
    formValues[key] = value;
    if (errorKey) formValues[errorKey] = errorMsg;
    setFormValues({ ...formValues });
  };

  const handleChangeDocument = (e) => {
    handleChangeFormValues('config_documents', e, 'error_config_document', '');
  };

  const handleChangeCustomerName = (e) => {
    const valueCustomerName = isB2B ? e?.target?.value : e?.target?.value.replace(/[^a-zA-Z\s]+/g, '');
    let errorMsg = valueCustomerName.length < 1 ? 'Nama tidak boleh kosong' : '';

    handleChangeFormValues('customer_name', valueCustomerName, 'error_customer_name', errorMsg);
  };

  const handleChangeNickname = (e) => {
    const valueNickname = e.target.value;

    handleChangeFormValues('nickname', valueNickname, 'error_nickname', '');
  };

  const handleChangePICName = (e) => {
    const valuePICName = e.target.value;
    let errorMsg = valuePICName.length < 1 ? 'PIC Name tidak boleh kosong' : '';

    handleChangeFormValues('pic_name', valuePICName, 'error_pic_name', errorMsg);
  };

  const handleChangePhoneNumber = (e) => {
    const { name, value } = e.target;
    let phoneValue = value.replace(/[^0-9+()]/g, '');
    phoneValue.replace(/(?!^\+)\+/g, '');

    if (name == 'phone_number') validatePhoneNumber('phone_number', phoneValue);
    if (name == 'pic_phone') validatePhoneNumber('pic_phone', phoneValue);
  };

  const handleChangeEmail = (e) => {
    let emailValue = e?.target?.value;
    let inputName = e?.target?.name;
    validateEmail(emailValue, inputName);
  };

  const handleChangeUserType = (e) => {
    handleChangeFormValues('user_type', e, 'error_user_type', '');
  };

  const handleChangeFlag = (e) => {
    handleChangeFormValues('flag', e, 'error_flag', '');
  };

  const handleChangePIC = (e) => {
    handleChangeFormValues('b2b_pic', e, 'error_b2b_pic', '');
  };

  const handleChangeStatus = (e) => {
    handleChangeFormValues('b2b_customer_status', e.target.value, 'error_b2b_customer_status', '');
  };

  const handleChangeInternalNotes = (e) => {
    let errMsg = '';
    if (e.target.value.length > 500) {
      errMsg = 'Maksimal karakter adalah 500';
    }

    handleChangeFormValues('internal_notes', e.target.value, 'error_internal_notes', errMsg);
  };

  const handleChangeCompanyAddress = (e) => {
    const valueCompanyAddresss = e.target.value;
    let errMsg = '';
    if (valueCompanyAddresss.length > 500) {
      errMsg = 'Maksimal karakter adalah 500';
    }

    handleChangeFormValues('company_address', valueCompanyAddresss, 'error_company_address', errMsg);
  };

  const handleChangeNPWPAddress = (e) => {
    const valueNPWPAddresss = e.target.value;
    let errMsg = '';
    if (valueNPWPAddresss.length > 500) {
      errMsg = 'Maksimal karakter adalah 500';
    }

    handleChangeFormValues('billing_address', valueNPWPAddresss, 'error_billing_address', errMsg);
  };

  const handleChangePriceAgreement = (type, value) => {
    if (type === 'markup-product') handleChangeFormValues('product_markup', value, 'error_product_markup', '');
    if (type === 'markup-jasa') handleChangeFormValues('service_markup', value, 'error_service_markup', '');
    if (type === 'discount-product') handleChangeFormValues('product_discount', value, 'error_product_discount', '');
    if (type === 'discount-jasa') handleChangeFormValues('service_discount', value, 'error_service_discount', '');
  };

  const handleChangeBlacklistWorkshop = (value) => {
    handleChangeFormValues('workshop_blacklist', value, 'error_workshop_blacklist', '');
  };

  const validatePhoneNumber = (name, valuePhoneNumber) => {
    let errorMsg = '';
    const validateLength = valuePhoneNumber?.length == 0;
    const validateMinLength = valuePhoneNumber?.length > 0 && valuePhoneNumber?.length < 10;
    if (isB2B) {
      if (name === 'pic_phone') {
        if (validateLength) errorMsg = 'No. Telepon tidak boleh kosong';
        if (validateMinLength) errorMsg = 'No. Telepon kurang dari 9 digit';
      }
    }

    if (!isB2B) {
      if (name === 'phone_number') {
        if (validateLength) errorMsg = 'No. Telepon tidak boleh kosong';
        if (validateMinLength) errorMsg = 'No. Telepon kurang dari 9 digit';
      }
    }

    if (name == 'phone_number')
      handleChangeFormValues('phone_number', valuePhoneNumber, 'error_phone_number', errorMsg);
    if (name == 'pic_phone') handleChangeFormValues('pic_phone', valuePhoneNumber, 'error_pic_phone', errorMsg);
    return errorMsg ? false : true;
  };

  const validateEmail = (valueEmail, inputName) => {
    let errorMsg = '';
    if (valueEmail?.length < 1 && isB2B) {
      errorMsg = `Email${inputName === 'email' && isB2B ? ' Perusahaan' : ''} tidak boleh kosong`;
    }
    if (valueEmail?.length > 1 && isB2B && valueEmail.includes('@') && inputName === 'pic_email') {
      errorMsg = 'Email tidak valid';
    }

    if (!validate(valueEmail) && !isB2B && valueEmail?.length > 0) {
      errorMsg = 'Format Email salah';
    }

    handleChangeFormValues(inputName, valueEmail, `error_${inputName}`, errorMsg);
    return errorMsg ? false : true;
  };

  const toggleModalAddCar = () => setToggleAddCarModal(!toggleAddCarModal);

  const validateForm = () => {
    const { customer_name, phone_number, pic_name, pic_phone, pic_email, email, b2b_pic } = formValues;
    let status = true;

    if (!customer_name) {
      handleChangeFormValues('customer_name', customer_name, 'error_customer_name', 'Nama tidak boleh kosong');
      status = false;
    } else {
      handleChangeFormValues('customer_name', customer_name, 'error_customer_name', '');
    }
    if (!validateEmail(email, 'email')) status = false;
    if (customerType === 'b2c') {
      if (!validatePhoneNumber('phone_number', phone_number)) status = false;
    }
    if (customerType === 'b2b') {
      if (!validatePhoneNumber('pic_phone', pic_phone)) status = false;
      if (!pic_name) {
        handleChangeFormValues('pic_name', pic_name, 'error_pic_name', 'PIC Name tidak boleh kosong');
        status = false;
      } else {
        handleChangeFormValues('pic_name', pic_name, 'error_pic_name', '');
      }
      if (!b2b_pic?.id) {
        handleChangeFormValues('b2b_pic', b2b_pic, 'error_b2b_pic', 'B2B PIC Name tidak boleh kosong');
        status = false;
      } else {
        handleChangeFormValues('b2b_pic', b2b_pic, 'error_b2b_pic', '');
      }
      // if (!validateEmail(pic_email, 'pic_email')) status = false;
    }

    return status;
  };

  const handleSubmit = async () => {
    let payload = formValues;
    let notification = {
      status: true,
      type: '',
      message: '',
    };

    const generatePhoneNumber = formValues.phone_number ? { number: formValues?.phone_number } : { number: null };
    const generatePICPhoneNumber = formValues.pic_phone ? { number: formValues?.pic_phone } : { number: null };

    if (payload?.internal_notes?.length > 500) {
      notification = {
        ...notification,
        type: 'fail',
        message: 'Maksimal karakter internal notes adalah 500',
      };
      handleResultNotification(notification);
      return;
    }

    if (!validateForm()) {
      notification = {
        ...notification,
        type: 'fail',
        message: 'Gagal, field mandatory harus diisi!',
      };
      handleResultNotification(notification);
      return;
    }

    if (origin === 'edit') {
      assign(payload, { customer_id: id });
    }

    let blacklistws = [];
    if (isB2B) {
      map(payload?.workshop_blacklist, (item) => {
        blacklistws.push(item.id);
      });
    }

    map(errorToDelete, (item) => {
      delete payload[item];
    });

    const profilePicturePath = () => {
      let pict = payload?.photo?.key ?? initialValues?.photo?.replace(/(%20|%25)/g, ' ');
      if (pict?.includes('.net/')) {
        return pict.split('.net/')[1];
      }

      return pict;
    };

    payload = {
      ...payload,
      photo: profilePicturePath(),
      phone_number: generatePhoneNumber.number,
      customer_type: customerType,
      flag: payload.flag.value ?? 'default',
      user_type: payload.user_type.value,
      email: payload.email === '' ? null : payload.email,
    };

    if (isB2B) {
      assign(payload, {
        // pic_email: `${payload.pic_email}@otoklix.com`,
        pic_phone: generatePICPhoneNumber.number,
        b2b_pic: payload.b2b_pic.id,
        config_documents: payload.config_documents,
        product_discount: payload.product_discount.value,
        product_markup: payload.product_markup.value,
        service_discount: payload.service_discount.value,
        service_markup: payload.service_markup.value,
      });

      assign(payload, { blacklisted_workshops: blacklistws.toString() });
    }

    if (origin === 'create') {
      api
        .post('v2/intools/customers/', payload)
        .then((res) => {
          notification = {
            ...notification,
            type: 'success',
            message: 'Sukses',
          };
          handleResultNotification(notification).then(() => {
            setTimeout(() => {
              history.push(`/customer/${res.data.data.id}`);
            }, 1000);
          });
        })
        .catch((err) => {
          let errRes = err.response.data.error.message;

          notification = {
            ...notification,
            type: 'fail',
            message: `Gagal, ${errRes}`,
          };
          handleResultNotification(notification);
        });
    }

    if (origin === 'edit') {
      setSubmitStatus(false);

      api
        .post(`v2/intools/customers/edit/?=${id}`, payload)
        .then((res) => {
          setDisableForm(true);
          notification = {
            ...notification,
            type: 'success',
            message: 'Sukses',
          };
          handleResultNotification(notification, () => handleEdit());
          setSubmitStatus(true);
          loadUserDetail();
          handleChangeOrigin(origin === 'detail' ? 'edit' : 'detail');
          setTimeout(() => {
            setSubmitStatus(false);
          }, 3000);
        })
        .catch((err) => {
          let errRes = err.response.data.error.message || 'Gagal update detail user.';

          if (errRes?.toLowerCase().includes('duplicate entry')) {
            errRes = 'No telpon sudah digunakan';
          }

          notification = {
            ...notification,
            type: 'fail',
            message: `Gagal, ${errRes}`,
          };
          handleResultNotification(notification);
          setSubmitStatus(true);
        });
    }
  };

  const handleEdit = () => {
    if (submitStatus) {
      loadUserDetail();
    } else {
      if (origin == 'edit') loadUserDetail();
      handleEditForm(customerType);
    }
    handleChangeOrigin(origin === 'detail' ? 'edit' : 'detail');
    setDisableForm(!disableForm);
  };

  const handleCancel = () => {
    if (origin === 'create') {
      history.push('/customer');
    } else {
      handleEdit();
    }
  };

  const toggleModalConfirmation = (status) => {
    setConfirmationType(status);
    setHasModalConfirmation(!hasModalConfirmation);
  };

  const allowPermission = (menu, type = 'page') => {
    const allowAccess = !!(generatePermission('customer', menu) || generatePermission('customer', 'all'));
    if (!allowAccess && type !== 'element') return history.push('/404');
    if (menu === 'location' && allowAccess) setHasLocationDetail(!hasLocationDetail);
    return allowAccess;
  };

  useEffect(() => {
    if (customerType) mappingForm(customerType);
  }, [customerType]);

  useEffect(() => {
    allowPermission('location', 'element');
  }, []);

  const isB2B = customerType === 'b2b';
  const allowShowCar = allowPermission('usercarshow', 'element') || allowPermission('usercarall', 'element');
  const allowListCar = allowPermission('usercarlist', 'element') || allowPermission('usercarall', 'element');
  const allowAddCar = allowPermission('usercaradd', 'element') || allowPermission('usercarall', 'element');

  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <CustomerDetailForms
            isB2B={isB2B}
            formValues={formValues}
            disableForm={disableForm}
            customerType={customerType}
            handleChangeCustomerType={handleChangeCustomerType}
            handleChangeCustomerName={handleChangeCustomerName}
            handleChangeNickname={handleChangeNickname}
            handleChangePICName={handleChangePICName}
            handleChangePhoneNumber={handleChangePhoneNumber}
            handleChangeEmail={handleChangeEmail}
            handleChangeStatus={handleChangeStatus}
            handleChangeUserType={handleChangeUserType}
            handleChangeFlag={handleChangeFlag}
            handleChangeBlacklistWorkshop={handleChangeBlacklistWorkshop}
            handleChangeInternalNotes={handleChangeInternalNotes}
            handleChangeImage={handleChangeImage}
            handleErrorImage={handleErrorImage}
            handleChangeDocument={handleChangeDocument}
            handleChangePIC={handleChangePIC}
            wsList={wsList}
            origin={origin}
            loadWorkshop={loadListWorkshop}
            loadingLoadWorkshop={loadingLoadWorkshop}
            loadPIC={loadListPIC}
            loadingLoadPIC={loadingLoadPIC}
            listPIC={listPIC}
          />

          {allowShowCar && (
            <CustomerCar
              origin={origin}
              cars={userCars}
              toggleModalAddCar={toggleModalAddCar}
              allowListCar={allowListCar}
              allowAddCar={allowAddCar}
            />
          )}
        </Col>
        <Col sm={12} md={6}>
          {hasLocationDetail ? (
            <CustomerLocation
              isB2B={isB2B}
              formValues={formValues}
              data={userLocations?.customer_address}
              origin={origin}
              disableForm={disableForm}
              handleChangeCompanyAddress={handleChangeCompanyAddress}
              handleChangeNPWPAddress={handleChangeNPWPAddress}
            />
          ) : null}
          {isB2B ? (
            <CustomerPriceAgreement
              handleChangePriceAgreement={handleChangePriceAgreement}
              data={formValues}
              disableForm={disableForm}
            />
          ) : origin !== 'create' && allowPermission('otopoints', 'element') ? (
            <HistoryPointTransaction
              transactionList={transactionList}
              loyaltyPoints={loyaltyPoints}
              detailUser={isGetDetailSuccess}
              id={id}
              loadUserDetail={loadUserDetail}
            />
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col className="section-action mb-2">
          {origin === 'create' || origin === 'edit' ? (
            <>
              <Button
                className="button-action save"
                onClick={() => {
                  if (origin === 'create') handleSubmit();
                  if (origin === 'edit') toggleModalConfirmation('Simpan');
                }}
              >
                Konfirmasi
              </Button>
              <Button
                className="button-action cancel"
                onClick={() => {
                  if (origin === 'create') handleCancel();
                  if (origin === 'edit') toggleModalConfirmation('Batalkan');
                }}
              >
                Batal
              </Button>
            </>
          ) : null}
          {origin === 'detail' && allowPermission('edit', 'element') ? (
            <Button className="button-action save" onClick={handleEdit}>
              Ubah Data
            </Button>
          ) : null}
        </Col>
      </Row>

      <AddCarModal
        userId={id}
        modal={toggleAddCarModal}
        toggle={toggleModalAddCar}
        loadUserCar={loadUserCar}
        handleResultNotification={handleResultNotification}
        allowListCar={allowListCar}
      />

      <ConfirmationModal
        toggle={toggleModalConfirmation}
        toggleClose={toggleModalConfirmation}
        onConfirm={() => {
          if (confirmationType === 'Simpan') {
            handleSubmit();
          } else {
            handleEdit();
            mappingForm(customerType);
          }
          toggleModalConfirmation();
        }}
        modal={hasModalConfirmation}
        header={`${confirmationType} Hasil Edit Detail User?`}
        subHeader={`${confirmationType} hasil edit Detail User atas nama ${formValues?.customer_name}?`}
      />
    </>
  );
};

export default CustomerForms;
