import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import DataTable from 'react-data-table-component';
import Loading from '@components/loading/Loading';
import { Badge } from 'reactstrap';

function LogHistory(props) {
  const { id } = useParams();
  const api = new API('v2');

  const { generatePermission } = userPermission();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [page, setPage] = useState(1);
  const paginationOptions = { noRowsPerPage: true };
  const [pageLimit, setPageLimit] = useState(10);
  const columns = useMemo(
    () => [
      {
        name: 'Timestamp',
        selector: (row) =>
          moment(row?.timestamp).isValid() ? moment(row?.timestamp).format('MMM DD YYYY , HH.mm') : '-',
        wrap: true,
        minWidth: '160px',
      },
      {
        name: 'Agent',
        selector: (row) => row?.agent ?? '-',
        wrap: true,
        minWidth: '100px',
      },
      {
        name: 'Type Change',
        selector: (row) => {
          if (row?.type_change == 'live') {
            return <Badge className="p-2 badge-success">Live</Badge>;
          } else if (row?.type_change == 'hide review') {
            return <Badge className="p-2 badge-warning">Hide Review</Badge>;
          } else if (row?.type_change == 'hide review rating') {
            return <Badge className="p-2 badge-danger">Hide Review & Rating</Badge>;
          } else if (row?.type_change == 'edit review') {
            return <Badge className="p-2 badge-dark">Edit Review</Badge>;
          } else {
            return '-';
          }
        },
        minWidth: '150px',
        wrap: true,
      },
      {
        name: 'Reason',
        selector: (row) => row?.reason ?? '-',
        minWidth: '500px',
        wrap: true,
      },
    ],
    []
  );

  async function loadLogHistory() {
    setLoading(true);

    const params = {
      page: page,
      limit: pageLimit,
    };

    const response = await api.get(`v2/intools/activity-logs/booking-rating-review/${id}/`, { params });
    setData(response?.data?.data?.activity_logs ?? []);
    setTotalRows(response?.data?.pagination?.total_rows ?? 0);
    setLoading(false);
  }

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (page && pageLimit) {
      loadLogHistory();
    }
  }, [page, pageLimit]);

  const handleRowPerPageChange = (newPageLimit, page) => {
    setPage(page);
    setPageLimit(newPageLimit);
  };

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Log History</CardHeader>
      <CardBody>
        <DataTable
          persistTableHead
          highlightOnHover
          pagination
          progressPending={loading}
          data={data}
          noHeader={true}
          fixedHeader
          fixedHeaderScrollHeight="480px"
          columns={generatePermission('ratingreview', 'list') ? columns : []}
          className="table table-bordered dt-responsive table-stnk"
          paginationServer
          paginationTotalRows={totalRows}
          paginationComponentOptions={paginationOptions}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowPerPageChange}
          progressComponent={<Loading />}
        />
      </CardBody>
    </Card>
  );
}

export default LogHistory;
