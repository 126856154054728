import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, Col, FormGroup, FormFeedback, Input, Label, Row, Button } from 'reactstrap';
import Wrapper from '@components/wrapper/Wrapper';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import SelectField from '@components/field/SelectField';

function SubscriptionsHistory() {
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState({});

  const breadcrumb = [
    {
      id: 1,
      label: 'Langganan',
      active: false,
      url: '/subscriptions',
    },
    {
      id: 2,
      label: 'Detail Langganan',
      active: false,
      url: '/subscriptions/1',
    },
    {
      id: 3,
      label: 'Detail Riwayat Langganan',
      active: true,
      url: '',
    },
  ];

  const handleLoading = (status) => {
    setLoading(status);
  };

  return (
    <Wrapper
      title="Detail Riwayat Langganan"
      customContentClassName="wrapper-content"
      customTitleClassName="wrapper-title"
      breadcrumbData={breadcrumb}
    >
      <Row>
        <Col sm={12} md={12}>
          {loading && <LoadingSpinner></LoadingSpinner>}
          {!loading && (
            <Card className="card-custom">
              <CardBody className="p-0">
                <Row>
                  <Col sm={12} md={12}>
                    <h5 className="mb-3">ID Pelanggan</h5>
                    <Row>
                      <Col sm={12} md={6}>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Nama
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.id ?? '-'}</span>
                          </Label>
                        </Row>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Nomor Tlp
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.telp ?? '-'}</span>
                          </Label>
                        </Row>
                      </Col>
                      <Col sm={12} md={6}>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Email
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.email ?? '-'}</span>
                          </Label>
                        </Row>
                      </Col>
                    </Row>
                    <h5 className="mb-3">Detail Langganan</h5>
                    <Row>
                      <Col sm={12} md={6}>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            ID Langganan
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.id ?? '-'}</span>
                          </Label>
                        </Row>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Paket Langganan
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.telp ?? '-'}</span>
                          </Label>
                        </Row>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Kredit Cuci Mobil
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.telp ?? '-'}</span>
                          </Label>
                        </Row>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Paket Rollover
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.telp ?? '-'}</span>
                          </Label>
                        </Row>
                      </Col>
                      <Col sm={12} md={6}>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Status
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.email ?? '-'}</span>
                          </Label>
                        </Row>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Tanggal
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.email ?? '-'}</span>
                          </Label>
                        </Row>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Kredit Wax
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.email ?? '-'}</span>
                          </Label>
                        </Row>
                      </Col>
                    </Row>
                    <h5 className="mb-3">Pembayaran</h5>
                    <Row>
                      <Col sm={12} md={6}>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Status Pembayaran
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.id ?? '-'}</span>
                          </Label>
                        </Row>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Tipe
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.telp ?? '-'}</span>
                          </Label>
                        </Row>
                      </Col>
                      <Col sm={12} md={6}>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Tanggal Pengesahan
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.email ?? '-'}</span>
                          </Label>
                        </Row>
                        <Row className="mb-3">
                          <Label lg={4} className="text-left">
                            Nomor VA
                          </Label>
                          <Label lg={8} className="text-left text-bold">
                            : <span className="ml-1">{historyData?.email ?? '-'}</span>
                          </Label>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </Wrapper>
  );
}

export default SubscriptionsHistory;
