import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Input,
} from 'reactstrap';
import API from '@utils/API';
import moment from 'moment';
import { map } from 'lodash';
import { CAR_TRANSMISSION_OPTIONS } from '@utils/Constants';
import SelectAsyncField from '../field/SelectAsyncField';
import InputField from '../field/InputField';
import InputMask from 'react-input-mask';

const AddCarModal = (props) => {
  const { modal, toggle, userId, loadUserCar, handleResultNotification, allowListCar } = props;
  const api = new API('v2');

  const [carVariant, setCarVariant] = useState(null);
  const [carVariantError, setCarVariantError] = useState('');
  const [year, setYear] = useState('');
  const [yearError, setYearError] = useState('');
  const [carTransmission, setCarTransmission] = useState('');
  const [carTransmissionError, setCarTransmissionError] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [prefixPlate, setPrefixPlate] = useState('');
  const [numberPlate, setNumberPlate] = useState('');
  const [suffixPlate, setSuffixPlate] = useState('');
  const [errorPlateNumber, setErrorPlateNumber] = useState('');

  const fetchCarVariants = (userInputValue) => {
    if (userInputValue) {
      return api.get(`v2/intools/cars/search?q=${userInputValue}`).then((response) => {
        return response.data.data;
      });
    }
  };

  const resetPlateNumber = () => {
    setPrefixPlate('');
    setNumberPlate('');
    setSuffixPlate('');
    setErrorPlateNumber('');
  };

  const handleChangeCarVariantChange = (item) => {
    setCarVariant(item);
    carVariantValidation(item);

    setCarTransmission('');
    setYear('');
    setLicensePlate('');
    resetPlateNumber();
  };

  const handleChangeYear = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '');

    setYear(newValue);
    yearValidation(newValue);
  };

  const handleChangeTransmission = (value) => {
    setCarTransmission(value);
    carTransmissionValidation(value);
  };

  const carVariantValidation = (value) => {
    if (value?.id?.length < 1 || !value) {
      setCarVariantError('Mobil harus diisi');
      return false;
    }

    setCarVariantError('');

    return true;
  };

  const yearValidation = (value) => {
    const now = moment().format('YYYY');

    if (parseInt(value) > parseInt(now)) {
      setYearError('Tahun tidak boleh melebihi tahun sekarang');
      return false;
    }
    if (value?.length < 1 || !value) {
      setYearError('Tahun harus diisi');
      return false;
    }

    setYearError('');
    return true;
  };

  const carTransmissionValidation = (value) => {
    if (!value) {
      setCarTransmissionError('Transmisi harus diisi');
      return false;
    }

    setCarTransmissionError('');
    return true;
  };

  const plateNumberValidation = (prefix, number, suffix) => {
    const isPassValidatePlateNumber = (prefix || suffix) && !number ? false : true;
    setErrorPlateNumber(isPassValidatePlateNumber ? '' : 'Plat Nomor harus diisi');

    return isPassValidatePlateNumber;
  };

  const validate = (payload) => {
    let status = true;
    if (!carVariantValidation(payload?.variant_id)) {
      status = false;
    }

    if (!yearValidation(payload?.year)) {
      status = false;
    }

    if (!carTransmissionValidation(payload?.transmission)) {
      status = false;
    }

    if (!plateNumberValidation(prefixPlate, numberPlate, suffixPlate)) {
      status = false;
    }

    return status;
  };

  const handleOnSubmit = async () => {
    setIsLoading(true);

    let notification = {
      status: true,
      type: '',
      message: '',
    };
    const payload = {
      license_plate: licensePlate,
      variant_id: carVariant?.id,
      transmission: carTransmission,
      year,
    };

    if (!validate(payload)) {
      setIsLoading(false);
      return;
    }

    await api
      .post(`v2/intools/customers/customer-car/${userId}/`, payload)
      .then(() => {
        setIsLoading(false);
        toggle();
        if (allowListCar) loadUserCar();
        notification = {
          ...notification,
          type: 'success',
          message: 'Sukses',
        };
        handleResultNotification(notification);
      })
      .catch((err) => {
        setIsLoading(false);
        let errRes = err.response.data.error.message;

        notification = {
          ...notification,
          type: 'fail',
          message: `Gagal, ${errRes}`,
        };
        handleResultNotification(notification);
      });
  };

  const handleOnCancel = () => {
    toggle();
    handleResetAllField();
  };

  const handleResetAllField = () => {
    setCarVariant(null);
    setCarVariantError('');
    setCarTransmission(null);
    setCarTransmissionError('');
    setYear(null);
    setYearError('');
    setLicensePlate(null);
    resetPlateNumber();
  };

  useEffect(() => {
    let plateNumber = prefixPlate ?? '';
    plateNumber += numberPlate ? (plateNumber ? ' ' + numberPlate : numberPlate) : '';
    plateNumber += suffixPlate ? (plateNumber ? ' ' + suffixPlate : suffixPlate) : '';

    setLicensePlate(plateNumber);
  }, [prefixPlate, numberPlate, suffixPlate]);

  useEffect(() => {
    if (!modal) handleResetAllField();
  }, [modal]);

  return (
    <Modal isOpen={modal} toggle={toggle} centered className="modal-add-car">
      <ModalHeader toggle={toggle}>Tambah Mobil Baru</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Mobil
              </Label>
              <SelectAsyncField
                colWidth={9}
                cacheOptions
                defaultOptions={[]}
                value={carVariant}
                getOptionLabel={(e) => `${e.car_model?.model_name} - ${e.variant}`}
                getOptionValue={(e) => e.id}
                loadOptions={fetchCarVariants}
                onChange={handleChangeCarVariantChange}
                menuPlacement="top"
                placeholder="Cari..."
                disabled={isLoading}
                errorMessage={carVariantError}
                invalid={!!carVariantError}
              />
            </FormGroup>

            <FormGroup row>
              <Label lg={3} className="label-required">
                Year
              </Label>
              <InputField
                colWidth={9}
                title="Tahun"
                placeholder={`Ex: ${moment().format('YYYY')}`}
                onChange={handleChangeYear}
                value={year}
                disabled={isLoading}
                errorMessage={yearError}
                invalid={!!yearError}
              />
            </FormGroup>

            <FormGroup className="d-flex">
              <Label lg={3} className="label-required">
                Transmission
              </Label>
              <Col lg={9} className="px-2">
                <div className="mb-1">
                  {map(CAR_TRANSMISSION_OPTIONS, (transmission, index) => (
                    <Button
                      className={index > 0 ? 'ml-2' : ''}
                      color="purple"
                      outline={carTransmission === transmission.value}
                      onClick={() => handleChangeTransmission(transmission.value)}
                      disabled={isLoading}
                    >
                      {transmission.label}
                    </Button>
                  ))}
                </div>
                <span className="text-danger" style={{ fontSize: 11.28 }}>
                  {carTransmissionError}
                </span>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label lg={3}>Plat Nomor</Label>
              <Col sm={8} className="p-0">
                <Row className="mx-0">
                  <Col lg={4} xs={4}>
                    <InputMask
                      className={`mask-input valid`}
                      value={prefixPlate?.toUpperCase()}
                      mask={'**'}
                      maskPlaceholder={null}
                      onChange={(e) => {
                        setPrefixPlate(e.target.value.toUpperCase());
                        plateNumberValidation(e.target.value.toUpperCase(), numberPlate, suffixPlate);
                      }}
                    >
                      <Input placeholder={'AB'}></Input>
                    </InputMask>
                  </Col>
                  <Col lg={4} xs={4}>
                    <InputMask
                      className={`mask-input ${errorPlateNumber ? 'invalid' : 'valid'}`}
                      value={numberPlate?.toUpperCase()}
                      aria-invalid={errorPlateNumber}
                      mask={'9999'}
                      maskPlaceholder={null}
                      onChange={(e) => {
                        setNumberPlate(e.target.value.toUpperCase());
                        plateNumberValidation(prefixPlate, e.target.value.toUpperCase(), suffixPlate);
                      }}
                    >
                      <Input className="input-field" placeholder={'9999'} invalid={errorPlateNumber}></Input>
                    </InputMask>
                  </Col>
                  <Col lg={4} xs={4}>
                    <InputMask
                      className={`mask-input valid`}
                      value={suffixPlate?.toUpperCase()}
                      mask={'***'}
                      maskPlaceholder={null}
                      onChange={(e) => {
                        setSuffixPlate(e.target.value.toUpperCase());
                        plateNumberValidation(prefixPlate, numberPlate, e.target.value.toUpperCase());
                      }}
                    >
                      <Input placeholder={'XYZ'}></Input>
                    </InputMask>
                  </Col>
                  <Col>
                    <div className="invalid-hour">{errorPlateNumber}</div>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="purple" outline onClick={handleOnCancel} disabled={isLoading}>
          Tutup
        </Button>{' '}
        <Button color="purple" onClick={handleOnSubmit} disabled={isLoading}>
          {isLoading ? <Spinner color="light" size="sm" className="mr-2" /> : null}
          Tambah
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddCarModal;
