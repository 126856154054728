import React from 'react';
import Select from 'react-select';
import { FormGroup, Label, Col } from 'reactstrap';

const EditableSelectField = (props) => {
  const {
    value,
    title,
    onChange,
    onInputChange,
    disabled,
    colWidth,
    labelWidth,
    required,
    selectName,
    className,
    placeholder,
    closeMenuOnSelect,
    errorMessage,
    options,
    getOptionLabel,
    getOptionValue,
    style,
    textLeft,
    isClearable,
    isMulti,
    row = true,
    blurInputOnSelect = true,
  } = props;

  const generateClassName = () => {
    if (required && textLeft) {
      return 'text-left label-required';
    } else if (required) {
      return 'label-required';
    } else if (textLeft) {
      return 'text-left';
    } else {
      return '';
    }
  };

  return (
    <FormGroup row={row} style={style}>
      <Label lg={labelWidth} className={generateClassName()}>
        {title}
      </Label>
      <Col lg={colWidth}>
        <Select
          className={className}
          name={selectName}
          placeholder={placeholder}
          closeMenuOnSelect={closeMenuOnSelect}
          options={options}
          value={value}
          isDisabled={disabled}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          onInputChange={onInputChange}
          onChange={onChange}
          isClearable={isClearable}
          isMulti={isMulti}
          blurInputOnSelect={blurInputOnSelect}
        />
        {errorMessage && <div className="pt-1 text-danger">{errorMessage}</div>}
      </Col>
    </FormGroup>
  );
};

export default EditableSelectField;
