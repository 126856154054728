import React, { useState } from 'react';
import WarningIcon from '@assets/icons/warning.svg';
import { find } from 'lodash';
import { Tooltip } from 'reactstrap';
import { ProductTypeNameInit } from '@utils/Constants';

const PackageLineItems = (props) => {
  const { items } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const productType = find(ProductTypeNameInit, { value: items?.value?.product_type }) ?? items?.type;

  return (
    <>
      <img id="warning-icon" src={WarningIcon} width={15} className="ml-1" />
      <Tooltip target="warning-icon" isOpen={tooltipOpen} toggle={toggle}>
        {productType?.label ?? 'Paket / Produk / Jasa'} ini tidak direkomendasikan untuk mobil yang dipilih
      </Tooltip>
    </>
  );
};

export default PackageLineItems;
