import React from 'react';
import Wrapper from '@components/wrapper/Wrapper';
import ACLForm from '@components/access-control-level/ACLForm';

const EditAccessControlLevel = () => {
  const breadcrumb = [
    {
      id: 1,
      label: 'Access Control Level',
      active: true,
      url: '/acl',
    },
    {
      id: 2,
      label: 'Edit Access Control Level',
      active: false,
      url: '/acl/:id',
    },
  ];

  return (
    <Wrapper title="Edit Access Control Level" breadcrumbData={breadcrumb}>
      <ACLForm />
    </Wrapper>
  );
};

export default EditAccessControlLevel;
