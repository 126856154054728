import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from 'reactstrap';
import InputField from '@components/field/InputField';
import SelectAsyncField from '@components/field/SelectAsyncField';
import API from '@utils/API';
import { map } from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import Helper from '@helpers/Helper';
import userPermission from '@utils/userPermission';

const CarCompabilityForms = (props) => {
  const { origin, handleChangeOrigin, initialValues, getCarGroupDetails, handleResultNotification } = props;
  const api = new API('v2');
  const history = useHistory();
  const { generatePermission } = userPermission();

  const [formValues, setFormValues] = useState({});
  const [disableForm, setDisableForm] = useState(origin === 'create' ? false : true);

  const mappingForm = () => {
    const currentYear = moment().format('YYYY');
    const yearStartDefaultOptions = { label: currentYear - 1, value: currentYear - 1 };
    const yearFinishDefaultOptions = { label: currentYear, value: currentYear };
    const data = {
      car_group_id: initialValues?.car_group_id ?? '',
      car_group_name: initialValues?.car_group_name ?? '',
      car_model: initialValues?.car_model ?? [],
      year_start: initialValues?.year_start ?? yearStartDefaultOptions,
      year_end: initialValues?.year_end ?? yearFinishDefaultOptions,
      error_car_group_id: '',
      error_car_group_name: '',
      error_car_model: '',
      error_year: '',
    };

    setFormValues(data);
  };

  const allowPermission = (menu) => {
    const allowAccess = !!(generatePermission('car', menu) || generatePermission('car', 'all'));
    if (!allowAccess) return history.push('/404');
    return allowAccess;
  };

  const fetchCarVariants = async (userInputValue) => {
    if (userInputValue) {
      return await api
        .get(`v2/intools/cars/search?q=${userInputValue}`)
        .then((response) => {
          const data = response.data.data;
          let dataArray = [];
          map(data, (item) => {
            const car = item.car_model;
            dataArray.push({
              value: item.id,
              label: `${car.brand.name} ${car.model_name} ${item.variant}`,
            });
          });
          return dataArray;
        })
        .catch((err) => {
          return [];
        });
    }
  };

  const handleChangeFormValues = (key, value, errorKey, errorMsg) => {
    formValues[key] = value;
    if (errorKey) formValues[errorKey] = errorMsg;
    setFormValues({ ...formValues });
  };

  const handleChangeCarGroupName = (e) => {
    validateCarGroupName(e.target.value);
  };

  const handleChangeCarCompability = (value) => {
    validateCarCompability(value);
  };

  const handleChangeYear = (value, inputName) => {
    validateYear(value, inputName);
  };

  const validateCarGroupName = (value) => {
    let errMsg = '';
    if (value.length < 1) errMsg = 'Car Group Name tidak boleh kosong';
    if (value.length > 100) errMsg = 'Car Group Name tidak boleh lebih dari 100 karakter';

    handleChangeFormValues('car_group_name', value, 'error_car_group_name', errMsg);
    return errMsg ? false : true;
  };

  const validateCarCompability = (value) => {
    let errMsg = '';
    if (value.length < 1) errMsg = 'Car Model tidak boleh kosong';
    if (value.length < 2 && value.length > 0) errMsg = 'Pilih minimal 2 Car Model';

    handleChangeFormValues('car_model', value, 'error_car_model', errMsg);
    return errMsg ? false : true;
  };

  const validateYear = (year, duration) => {
    let errMsg = '';
    if (
      (duration === 'year_end' && year.value < formValues.year_start.value) ||
      (duration === 'year_start' && year.value > formValues.year_end.value) ||
      !year.value
    ) {
      errMsg = 'Tahun tidak valid';
    }
    handleChangeFormValues(duration, year, `error_year`, errMsg);
    return errMsg ? false : true;
  };

  const validate = () => {
    const { car_group_name, car_model, year_start, year_end } = formValues;
    let status = true;

    if (!validateCarGroupName(car_group_name)) status = false;
    if (!validateCarCompability(car_model)) status = false;
    // if (!validateYear(year_start, 'year_start')) status = false;
    // if (!validateYear(year_end, 'year_end')) status = false;

    return status;
  };

  const handleSubmit = async () => {
    let notification = {
      status: true,
      type: '',
      message: '',
    };
    if (!validate()) {
      notification = {
        ...notification,
        type: 'fail',
        message: 'Field mandatory harus diisi!',
      };

      handleResultNotification(notification);
      return;
    }

    let carModel = [];
    map(formValues?.car_model, (item) => {
      carModel.push(item?.value);
    });

    const payload = {
      group_name: formValues?.car_group_name,
      year_start: formValues?.year_start?.value,
      year_end: formValues?.year_end?.value,
      car_details_id: carModel,
    };

    if (origin === 'create') {
      api
        .post('v2/intools/user_car/compatibility_groups/', payload)
        .then((res) => {
          handleResultNotification({
            ...notification,
            type: 'success',
            message: 'Berhasil membuat car group compability',
          }).then(() => {
            setTimeout(() => {
              history.push(`/cars/compability/${res.data.data.car_group.car_group_id}`);
            }, 1000);
          });
        })
        .catch((err) => {
          let errMsg = err.response.data.error.message ?? '';

          handleResultNotification({
            ...notification,
            type: 'fail',
            message: `Gagal, ${errMsg}`,
          });
        });
    }

    if (origin === 'edit') {
      api
        .put(`v2/intools/user_car/compatibility_groups/${formValues?.car_group_id}/`, payload)
        .then((res) => {
          handleResultNotification({
            ...notification,
            type: 'success',
            message: 'Berhasil meng-edit car group compability',
          }).then(() => {
            setTimeout(() => {
              getCarGroupDetails();
              handleEdit();
            }, 1000);
          });
        })
        .catch((err) => {
          let errMsg = err.response.data.error.message ?? '';

          handleResultNotification({
            ...notification,
            type: 'fail',
            message: `Gagal, ${errMsg}`,
          });
        });
    }
  };

  const handleEdit = () => {
    handleChangeOrigin(origin === 'detail' ? 'edit' : 'detail');
    setDisableForm(!disableForm);
  };

  const handleCancel = () => {
    if (origin === 'create') {
      history.push({ pathname: '/cars', search: qs.stringify({ activetab: 'compability' }) });
    } else {
      handleEdit();
      mappingForm();
    }
  };

  useEffect(() => {
    mappingForm();
  }, [initialValues]);

  return (
    <Card className="form-car-compability">
      <CardHeader className="bg-primary text-white mt-0">Group Details</CardHeader>
      <CardBody>
        {origin !== 'create' && (
          <FormGroup row>
            <Label lg={3}>Car Group ID</Label>
            <Col lg={9}>
              <InputField disabled value={formValues?.car_group_id} placeholder="Car Group ID" />
            </Col>
          </FormGroup>
        )}

        <FormGroup row>
          <Label lg={3} className="label-required">
            Car Group Name
          </Label>
          <Col lg={9}>
            <InputField
              inputName="car_group_name"
              onChange={handleChangeCarGroupName}
              value={formValues?.car_group_name}
              invalid={!!formValues?.error_car_group_name}
              errorMessage={formValues?.error_car_group_name}
              disabled={disableForm}
              placeholder="Car Group Name"
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label lg={3} className="label-required">
            Car Model
          </Label>
          <Col lg={9}>
            <SelectAsyncField
              isDisabled={disableForm}
              defaultOptions={[]}
              isMulti
              closeMenuOnSelect={false}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              loadOptions={fetchCarVariants}
              placeholder="Pilih Mobil"
              onChange={handleChangeCarCompability}
              value={formValues?.car_model}
              invalid={!!formValues?.error_car_model}
              errorMessage={formValues?.error_car_model}
            />
          </Col>
        </FormGroup>

        {/* <FormGroup row>
          <Label lg={3} className="label-required">
            Year (Range)
          </Label>
          <Col lg={9}>
            <Row>
              <Col lg={7} className="d-flex">
                <SelectField
                  className="input-year"
                  onChange={(e) => handleChangeYear(e, 'year_start')}
                  options={Helper.generateYearOptions(1900, moment().format('YYYY'), true)}
                  value={formValues?.year_start}
                  disabled={disableForm}
                  invalid={!!formValues?.error_year}
                />
                <hr />
                <SelectField
                  className="input-year"
                  onChange={(e) => handleChangeYear(e, 'year_end')}
                  options={Helper.generateYearOptions(1900, moment().format('YYYY'), true)}
                  value={formValues?.year_end}
                  disabled={disableForm}
                  invalid={!!formValues?.error_year}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={7} className="px-3 d-flex flex-column align-items-center">
                <span className="text-danger">{formValues?.error_year}</span>
              </Col>
            </Row>
          </Col>
        </FormGroup> */}

        <div className="section-action">
          {origin === 'create' || origin === 'edit' ? (
            <>
              <Button className="button-action save" onClick={handleSubmit}>
                Simpan
              </Button>
              <Button className="button-action cancel" onClick={handleCancel}>
                Batalkan
              </Button>
            </>
          ) : null}
          {origin === 'detail' && allowPermission('groupedit') ? (
            <Button className="button-action save" onClick={handleEdit}>
              Edit
            </Button>
          ) : null}
        </div>
      </CardBody>
    </Card>
  );
};

export default CarCompabilityForms;
