import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'reactstrap';
import API from '@utils/API';
import Logo from '@assets/images/logo.svg';
import { useHistory, useLocation } from 'react-router-dom';

function ConfirmEmail() {
  const api = new API('v2');
  const history = useHistory();
  const queryParams = useLocation().search;
  const token = new URLSearchParams(queryParams).get('token');

  const [isSucceed, setIsSucceed] = useState(false);
  const [succeedMessage, setSucceedMessage] = useState('');

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleGetToken = () => {
    api
      .get(`v2/intools/users/confirm-email?token=${token}`)
      .then((res) => {
        setIsSucceed(true);
        setSucceedMessage(res?.data?.data);
      })
      .catch((e) => {
        setIsError(true);
        setErrorMessage(e.response?.data?.error?.message);
      });
  };

  useEffect(() => {
    handleGetToken();
  }, []);

  return (
    <Container fluid={true}>
      <Row className="vh-100">
        <Col md={8} className="p-0 h-100vh d-flex justify-content-center auth-bg">
          <div className="accountbg d-flex align-items-center">
            <div className="account-title text-center">
              <h4 className="mt-3">{`Let's Rock`}</h4>
              <h1>
                Otoklix <span className="text-warning">Intools</span>{' '}
              </h1>
              <div className="border w-25 mx-auto border-warning" />
            </div>
          </div>
        </Col>
        <Col md={4} className="pr-0">
          <div className="auth-page">
            <Card body className="border-0 mb-0 h-100">
              <div className="mb-5 logo-rv">
                <a href="/" className="logo logo-admin">
                  <span>
                    <img src={Logo} height={40} alt="logo" className="my-3" />
                  </span>
                </a>
              </div>
              <h2 className="font-weight-semibold font-22 mb-2">
                Welcome to <span className="text-primary">InTools</span>.
              </h2>

              {isSucceed && (
                <div>
                  <div className="my-3 alert alert-success">{succeedMessage}</div>
                  <Button block outline size="md" color="primary" onClick={() => history.push('/login')}>
                    Sign in
                  </Button>
                </div>
              )}
              {isError && <div className="mt-3 alert alert-warning">{errorMessage}</div>}
            </Card>
          </div>
          <div className="footer-auth">
            <hr className="hr-dashed mb-0" />
            <div className="p-4">
              <span className="text-muted d-sm-inline-block float-right">
                Crafted with <i className="mdi mdi-heart text-danger" /> by Otoklix
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ConfirmEmail;
