import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, FormGroup, Button, Form, Label, Input, FormFeedback, Alert } from 'reactstrap';
import Logo from '@assets/images/logo.svg';
import API from '@utils/API';
import { useHistory, useLocation } from 'react-router-dom';
import { regexWithMinimumNumberCharacter } from '@utils/Constants';
import Popup from 'react-popup';

function ResetPassword() {
  const api = new API('v2');
  const history = useHistory();
  const queryParams = useLocation().search;
  const token = new URLSearchParams(queryParams).get('token');

  const [isDisabled, setIsDisabled] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [password, setPassword] = useState('');
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState();
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isRepeatPasswordInvalid, setIsRepeatPasswordInvalid] = useState(false);
  const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] = useState();

  const handlePasswordChange = (event) => {
    let password = event.target.value;
    const isPasswordCorrect = regexWithMinimumNumberCharacter.test(password);
    if (isPasswordCorrect) {
      setPasswordErrorMessage();
      setIsPasswordInvalid(false);
    } else if (!password) {
      setPasswordErrorMessage('Password is empty or invalid format');
      setIsPasswordInvalid(true);
    } else if (!isPasswordCorrect) {
      setPasswordErrorMessage('Use 8 or more characters with a mix of letters and numbers');
      setIsPasswordInvalid(true);
    }

    setPassword(password);
  };

  const handleRepeatPasswordChange = (event) => {
    let repeatPassword = event.target.value;
    const isRepeatPasswordCorrect = repeatPassword === password;
    if (!isRepeatPasswordCorrect) {
      setRepeatPasswordErrorMessage('Passwords are not matching');
      setIsRepeatPasswordInvalid(true);
    } else {
      setRepeatPasswordErrorMessage();
      setIsRepeatPasswordInvalid(false);
    }

    setRepeatPassword(repeatPassword);
  };

  const handleGetToken = async () => {
    await api
      .get(`v2/intools/users/reset-password?token=${token}`)
      .then((res) => {
        console.log(res?.data?.data);
      })
      .catch((e) => {
        Popup.create({ title: 'Error', content: e.response?.data?.error?.message });
        setIsDisabled(true);
      });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    setIsSucceed(false);
    setIsDisabled(true);

    const params = {
      password: password,
      repeat_password: repeatPassword,
    };

    api
      .post(`v2/intools/users/reset-password?token=${token}`, params)
      .then((res) => {
        setAlertMessage(res.data?.data);
        setIsSucceed(true);
        setIsDisabled(false);
        setHasError(false);
        setPassword('');
        setRepeatPassword('');
        setTimeout(() => {
          history.push('/login');
        }, 3000);
      })
      .catch((e) => {
        setIsSucceed(false);
        setHasError(true);
        setIsDisabled(false);
        setErrorMessage(e.response.data?.error?.message ?? '');
      });
  };

  useEffect(() => {
    handleGetToken();
  }, []);

  return (
    <Container fluid={true}>
      <Row className="vh-100">
        <Col md={8} className="p-0 h-100vh d-flex justify-content-center auth-bg">
          <div className="accountbg d-flex align-items-center">
            <div className="account-title text-center">
              <h4 className="mt-3">{`Let's Rock`}</h4>
              <h1>
                Otoklix <span className="text-warning">Intools</span>{' '}
              </h1>
              <div className="border w-25 mx-auto border-warning" />
            </div>
          </div>
        </Col>
        <Col md={4} className="pr-0">
          <div className="auth-page">
            <Card body className="border-0 mb-0 h-100">
              <div className="mb-5 logo-rv">
                <a href="/" className="logo logo-admin">
                  <span>
                    <img src={Logo} height={40} alt="logo" className="my-3" />
                  </span>
                </a>
              </div>
              <h2 className="font-weight-semibold font-22 mb-2">
                Welcome to <span className="text-primary">InTools</span>.
              </h2>

              {isSucceed && <Alert color="success">{alertMessage}</Alert>}

              <Form className="form-horizontal auth-form my-4">
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    disabled={isDisabled}
                    invalid={isPasswordInvalid}
                    onChange={handlePasswordChange}
                  />
                  <FormFeedback>{passwordErrorMessage}</FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label>Re-enter New Password</Label>
                  <Input
                    type="password"
                    value={repeatPassword}
                    disabled={isDisabled}
                    placeholder="Repeat Password"
                    invalid={isRepeatPasswordInvalid}
                    onChange={handleRepeatPasswordChange}
                  />
                  <FormFeedback>{repeatPasswordErrorMessage}</FormFeedback>
                </FormGroup>

                {hasError && <div className="alert alert-danger">{errorMessage}</div>}
                <FormGroup>
                  <Button block outline size="md" color="primary" disabled={isDisabled} onClick={handleChangePassword}>
                    Change Password
                  </Button>
                </FormGroup>
              </Form>
            </Card>
          </div>
          <div className="footer-auth">
            <hr className="hr-dashed mb-0" />
            <div className="p-4">
              <span className="text-muted d-sm-inline-block float-right">
                Crafted with <i className="mdi mdi-heart text-danger" /> by Otoklix
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetPassword;
