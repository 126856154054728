import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { Button, Input, Col, Row } from 'reactstrap';
import userPermission from '@utils/userPermission';
import ActionFilterACL from '@components/filters/ActionFilterACL';
import Loading from '@components/loading/Loading';

const User = (props) => {
  const { data, loading, onChangeStatus, handleClickUserRow } = props;
  const history = useHistory();
  const { generatePermission } = userPermission();

  const [filterText, setFilterText] = useState('');

  const allowPermission = (menu) => {
    return !!(generatePermission('acl', menu) || generatePermission('acl', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowFilter = allowPermission('all') || allowPermission('search');
  const allowCreate = allowPermission('all') || allowPermission('add');

  const userData = () => {
    const list =
      data?.length > 0
        ? data?.map((parentData) => {
            parentData = {
              ...parentData,
              users: parentData?.users
                ?.filter(
                  (dt) =>
                    dt?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
                    dt?.email?.toLowerCase().includes(filterText.toLowerCase()) ||
                    dt?.phone_number?.toLowerCase().includes(filterText.toLowerCase()) ||
                    dt?.username?.toLowerCase().includes(filterText.toLowerCase())
                )
                .map((item) => {
                  item = {
                    ...item,
                  };

                  return item ?? [];
                }),
            };

            return parentData;
          })
        : [];

    return list;
  };

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row) => row?.users,
        cell: (row) => row?.name,
        minWidth: '200px',
        style: {
          fontWeight: 'bold',
        },
      },
      {
        name: 'Active',
        selector: 'is_active',
        wrap: true,
        width: '200px',
        cell: (row) =>
          row?.is_active !== undefined && (
            <Input
              type="checkbox"
              defaultChecked={row?.is_active}
              onClick={() => onChangeStatus(row)}
              className="ml-5"
            />
          ),
        left: true,
      },
      {
        name: 'Email',
        selector: 'email',
        minWidth: '200px',
      },
      {
        name: 'Phone Number',
        selector: 'phone_number',
        minWidth: '200px',
      },
    ],
    []
  );

  const customExpandableComponentStyles = {
    cells: {
      style: {
        fontWeight: '400 !important',
      },
    },
  };

  const createUser = () => {
    if (allowCreate) history.push(`/acl/add-user`);
  };

  const ExpanableComponent = ({ data, ...props }) => (
    <DataTable
      {...props}
      data={data?.users}
      noTableHead
      noHeader
      onRowClicked={(row) => handleClickUserRow(row?.id)}
      customStyles={customExpandableComponentStyles}
    />
  );

  const subHeaderComponentMemo = useMemo(() => {
    return <ActionFilterACL value={filterText} handleChangeValue={setFilterText} />;
  }, [filterText, setFilterText]);

  return (
    <React.Fragment>
      <Col sm={12} md={12} className="p-0 mt-0 section-action">
        {allowCreate && (
          <Button className="button-action add w-170" onClick={createUser}>
            {'Create New User'}
          </Button>
        )}
      </Col>
      {allowFilter && (
        <Col sm={12} md={12} className="p-0 mt-4 mb-0">
          <ActionFilterACL value={filterText} handleChangeValue={setFilterText} />
        </Col>
      )}
      <Col sm={12} md={12} className="p-0 mt-1">
        <DataTable
          persistTableHead
          highlightOnHover
          noHeader={true}
          progressPending={loading}
          data={userData()}
          fixedHeaderScrollHeight="239px"
          columns={allowList ? columns : []}
          expandableRows
          expandableRowExpanded={(row) => row.default_expanded}
          expandableRowsComponent={<ExpanableComponent columns={columns} />}
          expandableInheritConditionalStyles
          className="table dt-responsive table-custom"
          progressComponent={allowList ? <Loading /> : <></>}
        />
      </Col>
    </React.Fragment>
  );
};

export default User;
