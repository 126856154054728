import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Label } from 'reactstrap';
import API from '@utils/API';
import map from 'lodash/map';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { validate } from 'react-email-validator';

const AccountAdd = (props) => {
  const { idWorkshop, modal, toggle, onSubmit } = props;
  const history = useHistory();

  const api = new API('v2');
  const [roleOptions, setRoleOptions] = useState([]);
  const [role, setRole] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errorRole, setErrorRole] = useState('');
  const [errorFirstName, setErrorFirstName] = useState('');
  const [errorLastName, setErrorLastName] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [loadingFetchRole, setLoadingFetchRole] = useState(false);
  const [loading, setLoading] = useState(false);

  async function fetchRoleOptions() {
    setLoadingFetchRole(true);
    try {
      const response = await api.get(`v2/intools/workshops/gms-roles/`);
      mappingRoleOptions(response?.data?.data ?? []);
      setLoadingFetchRole(false);
    } catch (error) {
      mappingRoleOptions([]);
      setLoadingFetchRole(false);
    }
  }

  const mappingRoleOptions = (data) => {
    let dataRole = [];
    if (data.length !== 0) {
      map(data, (item) => {
        dataRole.push({
          value: item?.id,
          label: item?.name,
        });
      });
    }

    setRoleOptions(dataRole);
  };

  const handleChangeRole = (e) => {
    const valueRole = e?.value ? e : null;
    validateRole(valueRole);
  };

  const handleChangeFirstName = (e) => {
    const valueFirstName = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : firstName;
    validateFirstName(valueFirstName);
  };

  const handleChangeLastName = (e) => {
    const valueLastName = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : lastName;
    validateLastName(valueLastName);
  };

  const handleChangeEmail = (e) => {
    const valueEmail = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : email;
    validateEmail(valueEmail);
  };

  const validateRole = (value) => {
    let passValidate = true;
    if (!value) {
      passValidate = false;
      setErrorRole('Role harus di isi');
    } else {
      setErrorRole('');
    }

    setRole(value);
    return passValidate;
  };

  const validateFirstName = (value) => {
    let passValidate = true;

    if (value?.length == 0) {
      passValidate = false;
      setErrorFirstName('Nama Depan harus diisi');
    } else if (value?.length > 20) {
      passValidate = false;
      setErrorFirstName('Maksimal format 20 karakter');
    } else {
      setErrorFirstName('');
    }

    setFirstName(value);
    return passValidate;
  };

  const validateLastName = (value) => {
    let passValidate = true;

    if (value?.length > 20) {
      passValidate = false;
      setErrorLastName('Maksimal format 20 karakter');
    } else {
      setErrorLastName('');
    }

    setLastName(value);
    return passValidate;
  };

  const validateEmail = (value) => {
    let emailCheck = validate(value);
    let passValidate = true;

    if (value.length == 0) {
      passValidate = false;
      setErrorEmail('Email harus diisi');
    } else if (!emailCheck) {
      passValidate = false;
      setErrorEmail('Format Email tidak sesuai');
    } else {
      setErrorEmail('');
    }

    setEmail(value);
    return passValidate;
  };

  const handleSubmit = () => {
    const passValidateFirstName = validateFirstName(firstName);
    const passValidateEmail = validateEmail(email);
    const passValidateRole = validateRole(role);

    if (passValidateRole && passValidateFirstName && passValidateEmail) {
      submitForm();
    }
  };

  async function submitForm() {
    toggle();
    setLoading(true);
    const payload = {
      role_id: role?.value ?? '',
      first_name: firstName ?? '',
      last_name: lastName ?? null,
      email: email ?? '',
    };

    try {
      await api.post(`v2/intools/workshops/${idWorkshop}/gms-account/`, payload);
      onSubmit(true);
      setLoading(false);
    } catch (error) {
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  const resetData = () => {
    setRoleOptions([]);
    setRole();
    setFirstName('');
    setLastName('');
    setEmail('');
    setErrorRole('');
    setErrorFirstName('');
    setErrorLastName('');
    setErrorEmail('');
  };

  const cancel = () => {
    toggle();
  };

  useEffect(() => {
    resetData();
    if (modal) {
      fetchRoleOptions();
    }
  }, [modal]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Add Account</div>
        <div className="content">
          <FormGroup row>
            <Label lg={4} className="label-required">
              Pilih Role
            </Label>
            <SelectField
              colWidth={8}
              name="role"
              placeholder="Pilih Role"
              closeMenuOnSelect={true}
              value={role}
              options={roleOptions}
              disabled={loading}
              isLoading={loadingFetchRole}
              errorMessage={errorRole}
              onChange={handleChangeRole}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4} className="label-required">
              Nama Depan
            </Label>
            <InputField
              colWidth={8}
              inputType={'text'}
              disabled={loading}
              placeholder="First Name"
              value={firstName ?? ''}
              invalid={errorFirstName ? true : false}
              errorMessage={errorFirstName ?? ''}
              onChange={handleChangeFirstName}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4} className="">
              Nama Belakang
            </Label>
            <InputField
              colWidth={8}
              inputType={'text'}
              disabled={loading}
              placeholder="Last Name"
              value={lastName ?? ''}
              invalid={errorLastName ? true : false}
              errorMessage={errorLastName ?? ''}
              onChange={handleChangeLastName}
            />
          </FormGroup>
          <FormGroup row>
            <Label lg={4} className="label-required">
              Email
            </Label>
            <InputField
              colWidth={8}
              inputType={'text'}
              disabled={loading}
              placeholder="Email"
              value={email ?? ''}
              invalid={errorEmail ? true : false}
              errorMessage={errorEmail ?? ''}
              onChange={handleChangeEmail}
            />
          </FormGroup>
        </div>

        <div className="action mb-3 mt-3">
          <Button className="button-cancel" onClick={cancel} disabled={loading}>
            Batal
          </Button>
          <Button className="button-action" onClick={handleSubmit} disabled={loading}>
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AccountAdd;
