import React, { useState } from 'react';
import Wrapper from '@components/wrapper/Wrapper';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import API from '@utils/API';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { map } from 'lodash';
import { BOOKING_PAGE_TAB_OPTIONS } from '../../utils/Constants';
import BookingsB2b from '@containers/bookings/tabs/BookingsB2b';
import BookingsB2c from '@containers/bookings/tabs/BookingsB2c';

const Bookings = () => {
  const api = new API('v2');
  const history = useHistory();
  const queryParams = useLocation().search;
  const activetab = new URLSearchParams(queryParams).get('activetab') ?? 'b2b';

  const [activeTab, setActiveTab] = useState(activetab);
  const [notification, setNotification] = useState({});
  const [hasNotification, setHasNotification] = useState(false);

  const toggleActiveTab = (tab) => {
    const params = new URLSearchParams();

    setActiveTab(tab);
    params.append('activetab', tab);
    history.push({ search: params.toString() });
  };

  const handleResultNotification = async (notification) => {
    const { status, type, message } = notification;
    if (status) {
      setNotification({
        type: type,
        message: message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    setHasNotification(status);
    setTimeout(() => {
      setHasNotification(false);
      setNotification({});
      return status;
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotification(false);
    setNotification({});
  };

  return (
    <>
      <Wrapper
        showNotification={hasNotification}
        notification={notification}
        onCloseNotification={handleCloseNotification}
        title={`List Booking`}
      >
        <Nav tabs className="user-role-tabs">
          {map(BOOKING_PAGE_TAB_OPTIONS, (item) => (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === item?.value })}
                onClick={() => toggleActiveTab(item?.value)}
              >
                {item?.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="b2b">
            <BookingsB2b
              handleResultNotification={handleResultNotification}
              toggleActiveTab={toggleActiveTab}
              activetab={activetab}
            />
          </TabPane>
          <TabPane tabId="b2c">
            <BookingsB2c handleResultNotification={handleResultNotification} activetab={activetab} />
          </TabPane>
        </TabContent>
      </Wrapper>
    </>
  );
};

export default Bookings;
