import { map } from 'lodash';
import React from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';

const RadioInput = (props) => {
  const { label, required = false, options = [], checked, labelWidth, colWidth, disabled, ...rest } = props;

  return (
    <FormGroup row>
      <Label lg={labelWidth} className={required ? 'label-required' : ''}>
        {label}
      </Label>
      <Col lg={colWidth} className="d-flex">
        {map(options, (item, index) => (
          <FormGroup check className={`d-flex align-items-center${index > 0 ? ' ml-2' : ''}`}>
            <Input
              {...rest}
              type="radio"
              name={item?.label}
              id={item?.label}
              checked={checked === item?.value}
              value={item?.value}
              disabled={disabled}
            />
            <Label check>{item?.label}</Label>
          </FormGroup>
        ))}
      </Col>
    </FormGroup>
  );
};

export default RadioInput;
