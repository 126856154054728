import React from 'react';

function Divider(props) {
  const { title } = props;

  return (
    <div className="divider-label">
      <span>{title}</span>
    </div>
  );
}

export default Divider;
