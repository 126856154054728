import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Badge } from 'reactstrap';
import moment from 'moment';
import userPermission from '@utils/userPermission';
import DataTable from 'react-data-table-component';

const BookingDetailLog = (props) => {
  const { detailData } = props;
  const { generatePermission } = userPermission();
  const columns = useMemo(
    () => [
      {
        name: 'Timestamp',
        selector: (row) =>
          moment(row?.created_at).isValid() ? moment(row?.created_at).format('MMM DD YYYY , HH:mm:ss') : '-',
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'Creator',
        selector: (row) => (
          <>
            <span>
              <Badge className="p-2 badge-grey mr-3">{row?.origin ?? ''}</Badge>
            </span>
            <span>{row?.created_by ?? '-'}</span>
          </>
        ),
        // wrap: true,
        minWidth: '200px',
      },
      {
        name: 'Type Change',
        selector: (row) => {
          if (row?.operation == 'created') {
            return <Badge className="p-2 badge-dark">Booking dibuat</Badge>;
          } else if (row?.operation == 'workshop changed') {
            return <Badge className="p-2 badge-dark">Perubahan Bengkel</Badge>;
          } else if (row?.operation == 'reschedule') {
            return <Badge className="p-2 badge-dark">Perubahan Jadwal</Badge>;
          } else if (row?.operation == 'finish') {
            return <Badge className="p-2 badge-dark">Selesai</Badge>;
          } else if (row?.operation == 'cancel') {
            return <Badge className="p-2 badge-dark">Booking di Batalkan</Badge>;
          } else if (row?.operation == 'revise') {
            return <Badge className="p-2 badge-dark">Revise</Badge>;
          } else {
            return <Badge className="p-2 badge-dark">{row?.operation ?? '-'}</Badge>;
          }
        },
        minWidth: '200px',
        wrap: true,
      },
      {
        name: 'Note',
        selector: (row) => row?.note ?? '-',
        wrap: true,
        minWidth: '900px',
      },
    ],
    []
  );

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Log History</CardHeader>
      <CardBody className="pb-0">
        <DataTable
          persistTableHead
          highlightOnHover
          data={detailData}
          noHeader={true}
          fixedHeader
          fixedHeaderScrollHeight="239px"
          columns={generatePermission('booking', 'loghistory') ? columns : []}
          className="table table-bordered dt-responsive"
        />
      </CardBody>
    </Card>
  );
};

export default BookingDetailLog;
