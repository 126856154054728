import React, { useState, useEffect } from 'react';
import { map } from 'lodash';
import { GMAPS_API_KEY } from '@utils/Constants';
import { Card, CardBody, Col, Label, Row, Button } from 'reactstrap';
import API from '@utils/API';
import Geocode from 'react-geocode';
import Helper from '@helpers/Helper';
import GMap from '@components/maps/Gmap';
import ValidHttpUrl from 'is-valid-http-url';
import userPermission from '@utils/userPermission';
import ValidCoordinates from 'is-valid-coordinates';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import LoadingSpinner from '@components/loading/LoadingSpinner';

const Location = (props) => {
  const { id, onChangeAction, isValidationForm, onResultValidationForm, onResultNotification, onLoading } = props;

  const api = new API('v2');
  const { generatePermission } = userPermission();
  const [dataLocation, setDataLocation] = useState({});
  const [formData, setFormData] = useState({});
  const [pudoOptions, setPudoOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [allowEditLoaction, setAllowEditLocation] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'all'));
  };

  const allowEdit = allowPermission('locationedit');

  async function getLocation() {
    setLoading(true);

    try {
      const response = await api.get(`v2/intools/workshops/${id}/location/`);
      if (response?.data?.data) {
        setDataLocation(response?.data?.data ?? {});
      } else {
        setDataLocation({});
      }
      setLoading(false);
    } catch (error) {
      setDataLocation({});
      setLoading(false);
    }
  }

  const defaultDataForm = () => {
    const pudo_coverage = dataLocation?.pudo_coverage?.length
      ? dataLocation?.pudo_coverage.map((item) => {
          return { value: item?.id, label: item?.name };
        })
      : [];

    let dataForm = {
      province: dataLocation?.province?.id ? dataLocation?.province : null,
      city: dataLocation?.city?.id ? dataLocation?.city : null,
      district: dataLocation?.district?.id ? dataLocation?.district : null,
      sub_districts: dataLocation?.subdistricts ?? '',
      post_code: dataLocation?.postal_code ?? '',
      street_address: dataLocation?.street_address,
      gmaps_link: dataLocation?.gmaps_link,
      yourls: dataLocation?.yourls_gmaps_link,
      latitude: ValidCoordinates.latitude(Number(dataLocation?.latitude)) ? Number(dataLocation?.latitude) : -6.1753924,
      longitude: ValidCoordinates.longitude(Number(dataLocation?.longitude))
        ? Number(dataLocation?.longitude)
        : 106.8271528,
      pudo: pudo_coverage,
      error_province: '',
      error_city: '',
      error_district: '',
      error_sub_districts: '',
      error_post_code: '',
      error_street_address: '',
      error_gmaps_link: '',
    };

    setFormData(dataForm);
  };

  const defaultOptions = () => {
    loadProvinceOptions();
    loadPudoOptions();
    if (formData?.province?.id) loadCityOptions(formData?.province?.id);
    if (formData?.province?.id && formData?.city?.id) loadDistrictOptions(formData?.province?.id, formData?.city?.id);
  };

  async function loadProvinceOptions() {
    const response = await api.get(`v2/md/location/province/`);
    setProvinceOptions(response?.data?.data);
  }
  async function loadCityOptions(provinceId) {
    const response = await api.get(`v2/md/location/${provinceId}/`);

    setCityOptions(response?.data?.data);
  }
  async function loadDistrictOptions(provinceId, cityId) {
    const response = await api.get(`v2/md/location/${provinceId}/${cityId}/`);

    setDistrictOptions(response?.data?.data);
  }
  async function loadPudoOptions() {
    const response = await api.get(`v2/intools/workshops/districts/`);
    const optionsPudo = map(response?.data?.data, (item) => {
      return { value: item?.id ?? null, label: item?.name ?? '-' };
    });
    setPudoOptions(optionsPudo);
  }

  const setChangeValueForm = (key, value, errorKey, errorMsg) => {
    formData[key] = value;
    if (errorKey) formData[errorKey] = errorMsg;
    setFormData({ ...formData });
  };

  const handleChangeProvince = (e) => {
    formData.province = e;
    formData.city = null;
    formData.district = null;
    formData.error_city = '';
    formData.error_district = '';

    setFormData({ ...formData });
    setCityOptions([]);
    setDistrictOptions([]);
    loadCityOptions(e?.id);
    validateProvince(e);
  };

  const handleChangeCity = (e) => {
    formData.city = e;
    formData.district = null;
    formData.error_district = '';

    setFormData({ ...formData });
    setDistrictOptions([]);
    setPudoOptions([]);
    loadDistrictOptions(formData?.province?.id, e?.id);
    validateCity(e);
  };

  const handleChangeDistrict = (e) => {
    formData.district = e;
    setFormData({ ...formData });
    validateDistrict(e);
  };

  const handleChangeSubDistricts = (e) => {
    validateSubDistricts(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.sub_districts);
  };

  const handleChangePostCode = (e) => {
    let value = e?.target?.value.replace(/[^0-9]/g, '');
    value.replace(/(?!^\+)\+/g, '');
    validatePostCode(value);
  };

  const handleChangeStreetAddress = (e) => {
    validateStreetAddress(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.street_address);
  };

  const handleChangeGmapsLink = (e) => {
    validateGmapsLink(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.gmaps_link);
  };

  const handleChangePudo = (e) => {
    setChangeValueForm('pudo', e);
  };

  const handleAutoComplete = async (value) => {
    const response = await Geocode.fromAddress(value?.label);
    const newCoord = Helper.getCoordByAddress(response);

    Geocode.fromLatLng(newCoord.lat, newCoord.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        handleChangeCoordinate(newCoord, address);
      },
      (error) => {
        handleChangeCoordinate(newCoord, '');
      }
    );
  };

  const handleClickMap = (e) => {
    if (disableForm || !isEdit) return;
    const newCoord = {
      lat: e.lat,
      lng: e.lng,
    };

    Geocode.fromLatLng(e.lat, e.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        handleChangeCoordinate(newCoord, address);
      },
      (error) => {
        handleChangeCoordinate(newCoord, '');
      }
    );
  };

  const handleChangeCoordinate = (coord, streetAddress) => {
    formData.latitude = coord?.lat;
    formData.longitude = coord?.lng;
    formData.street_address = streetAddress;
    formData.error_street_address = '';

    setFormData({ ...formData });
  };

  const validateProvince = (value) => {
    setChangeValueForm('province', value, 'error_province', !value?.id ? 'Pilih Provinsi terlebih dahulu' : '');
    return !value?.id ? false : true;
  };

  const validateCity = (value) => {
    setChangeValueForm('city', value, 'error_city', !value?.id ? 'Pilih Kabupaten terlebih dahulu' : '');
    return !value?.id ? false : true;
  };

  const validateDistrict = (value) => {
    setChangeValueForm('district', value, 'error_district', !value?.id ? 'Pilih Kecamatan terlebih dahulu' : '');
    return !value?.id ? false : true;
  };

  const validateSubDistricts = (value) => {
    const errorMsg =
      value?.length == 0
        ? 'Kelurahan tidak boleh kosong'
        : value?.length > 50
        ? 'Format Kelurahan maksimal 50 karakter'
        : '';
    setChangeValueForm('sub_districts', value, 'error_sub_districts', errorMsg);
    return errorMsg ? false : true;
  };

  const validatePostCode = (value) => {
    const errorMsg =
      value?.length == 0
        ? 'Kode Pos tidak boleh kosong'
        : value?.length > 6
        ? 'Format Kode Pos maksimal 6 karakter'
        : '';
    setChangeValueForm('post_code', value, 'error_post_code', errorMsg);
    return errorMsg ? false : true;
  };

  const validateStreetAddress = (value) => {
    const errorMsg =
      value?.length == 0 ? 'Jalan tidak boleh kosong' : value?.length > 500 ? 'Format Jalan maksimal 500 karakter' : '';
    setChangeValueForm('street_address', value, 'error_street_address', errorMsg);
    return errorMsg ? false : true;
  };

  const validateGmapsLink = (value) => {
    const errorMsg =
      value?.length == 0
        ? 'Google Maps Link tidak boleh kosong'
        : value?.length > 100
        ? 'Format Google Maps Link maksimal 100 karakter'
        : '';
    setChangeValueForm('gmaps_link', value, 'error_gmaps_link', errorMsg);
    return errorMsg ? false : true;
  };

  const validateForm = () => {
    const isValidProvince = validateProvince(formData?.province ?? null);
    const isValidCity = validateCity(formData?.city ?? null);
    const isValidDistrict = validateDistrict(formData?.district ?? null);
    const isValidSubDistricts = validateSubDistricts(formData?.sub_districts ?? '');
    const isValidPostCode = validatePostCode(formData?.post_code ?? '');
    const isValidGmapsLink = validateGmapsLink(formData?.gmaps_link ?? '');
    const isValidAddress = validateStreetAddress(formData?.street_address ?? '');

    return isValidProvince &&
      isValidCity &&
      isValidDistrict &&
      isValidSubDistricts &&
      isValidPostCode &&
      isValidGmapsLink &&
      isValidAddress
      ? true
      : false;
  };

  const handleAction = (type) => {
    if (isEdit) {
      if (type == 'cancel') {
        defaultDataForm();
        setIsEdit(false);
        onChangeAction(false, 'location');
        handleResultNotification(false);
      } else {
        handleSubmitForm('submit');
      }
    } else {
      setIsEdit(true);
      onChangeAction(true, 'location');
    }
  };

  const handleValidateForm = () => {
    const isValid = validateForm();
    if (isValid) {
      handleSubmitForm();
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
    onResultValidationForm(isValid);
  };

  const handleSubmitForm = (type) => {
    const isValid = validateForm();
    if (isValid) {
      setDisableForm(true);
      handleGetPayload(type);
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
  };

  const goToLink = (link) => {
    if (ValidHttpUrl(link)) {
      window.open(`${link}`, '_blank');
    }
  };

  const handleGetPayload = (type) => {
    let pudo_selected = [];

    formData?.pudo.map((item) => {
      pudo_selected.push(item.value);
    });

    const payload = {
      province_id: formData?.province?.id ?? null,
      city_id: formData?.city?.id ?? null,
      district_id: formData?.district?.id ?? null,
      subdistricts: formData?.sub_districts ?? '',
      postal_code: formData?.post_code ?? '',
      street_address: formData?.street_address ?? '',
      gmaps_link: formData?.gmaps_link ?? '',
      latitude: formData?.latitude ? `${formData?.latitude}` : '',
      longitude: formData?.longitude ? `${formData?.longitude}` : '',
      pudo_coverage: pudo_selected,
    };

    submitFormLocation(type, payload);
  };

  const submitFormLocation = (type, payload) => {
    api
      .put(`v2/intools/workshops/${id}/location/`, payload)
      .then((response) => {
        setDataLocation(response?.data?.data ?? {});
        setIsEdit(false);
        setDisableForm(false);
        handleResultNotification(true, 'success', 'Berhasil update lokasi bengkel');
        if (type == 'submit') {
          onChangeAction(false, 'location');
        }
      })
      .catch((error) => {
        setDisableForm(false);
        handleResultNotification(true, 'fail', 'Gagal update lokasi bengkel');
      });
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  useEffect(() => {
    if (dataLocation) {
      defaultDataForm();
    }
  }, [dataLocation]);

  useEffect(() => {
    if (isEdit) {
      defaultOptions();
    }
  }, [isEdit]);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (isValidationForm) {
      handleValidateForm();
    }
  }, [isValidationForm]);

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    Geocode.setApiKey(GMAPS_API_KEY);
  }, []);

  return (
    <React.Fragment>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <Card className="card-custom">
          <CardBody className="p-0">
            <Row>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="label-required text-left">
                    Provinsi
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{formData?.province?.name ?? '-'}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <SelectField
                      colWidth={8}
                      name="province"
                      placeholder="Pilih Provinsi"
                      closeMenuOnSelect={true}
                      value={formData?.province}
                      options={provinceOptions}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.id}
                      disabled={disableForm}
                      errorMessage={formData?.error_province}
                      onChange={handleChangeProvince}
                    />
                  )}
                </Row>
                <Row className="mb-3">
                  <Label lg={4} className="label-required text-left">
                    Kabupaten / Kota
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{formData?.city?.name ?? '-'}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <SelectField
                      colWidth={8}
                      name="city"
                      placeholder="Pilih Kabupaten / Kota"
                      closeMenuOnSelect={true}
                      value={formData?.city}
                      options={cityOptions}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.id}
                      disabled={disableForm || cityOptions?.length == 0}
                      errorMessage={formData?.error_city}
                      onChange={handleChangeCity}
                    />
                  )}
                </Row>
                <Row className="mb-3">
                  <Label lg={4} className="label-required text-left">
                    Kecamatan
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{formData?.district?.name ?? '-'}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <SelectField
                      colWidth={8}
                      name="district"
                      placeholder="Pilih Kecamatan"
                      closeMenuOnSelect={true}
                      value={formData?.district}
                      options={districtOptions}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.id}
                      disabled={disableForm || districtOptions?.length == 0}
                      errorMessage={formData?.error_district}
                      onChange={handleChangeDistrict}
                    />
                  )}
                </Row>
                <Row className="mb-3">
                  <Label lg={4} className="label-required text-left">
                    Kelurahan
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{!formData?.sub_districts ? '-' : formData?.sub_districts}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <InputField
                      colWidth={8}
                      name="sub_districts"
                      inputType={'text'}
                      disabled={disableForm}
                      value={formData?.sub_districts}
                      invalid={formData?.error_sub_districts ? true : false}
                      errorMessage={formData?.error_sub_districts ?? ''}
                      placeholder={'Kelurahan'}
                      onChange={handleChangeSubDistricts}
                    ></InputField>
                  )}
                </Row>
                <Row className="mb-3">
                  <Label lg={4} className="label-required text-left">
                    Kode Pos
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{!formData?.post_code ? '-' : formData?.post_code}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <InputField
                      colWidth={8}
                      name="post_code"
                      inputType={'text'}
                      disabled={disableForm}
                      value={formData?.post_code}
                      invalid={formData?.error_post_code ? true : false}
                      errorMessage={formData?.error_post_code ?? ''}
                      placeholder={'Kode Pos'}
                      onChange={handleChangePostCode}
                    ></InputField>
                  )}
                </Row>
                <Row className="mb-3">
                  <Label lg={4} className="label-required text-left">
                    Jalan
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      <div>
                        <div style={{ position: 'absolute', fontWeight: 'bold' }}>:</div>
                        <div style={{ marginLeft: '0.7rem' }}>
                          {!formData?.street_address ? '-' : formData?.street_address}
                        </div>
                      </div>
                    </Label>
                  )}
                  {isEdit && (
                    <InputField
                      colWidth={8}
                      inputType="textarea"
                      disabled={disableForm}
                      value={formData?.street_address}
                      invalid={formData?.error_street_address ? true : false}
                      name={'address'}
                      placeholder={'Jalan'}
                      errorMessage={formData?.error_street_address ?? ''}
                      onChange={handleChangeStreetAddress}
                    ></InputField>
                  )}
                </Row>
                <Row className="mb-3">
                  <Label lg={4} className="label-required text-left">
                    Google Maps Link
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      :{' '}
                      <span
                        className={ValidHttpUrl(formData?.gmaps_link ?? '') ? 'ml-1 document-workshop' : 'ml-1'}
                        onClick={() => goToLink(formData?.gmaps_link ?? '')}
                      >
                        {!formData?.gmaps_link ? '-' : formData?.gmaps_link}
                      </span>
                    </Label>
                  )}
                  {isEdit && (
                    <InputField
                      colWidth={8}
                      inputType="text"
                      disabled={disableForm}
                      value={formData?.gmaps_link}
                      invalid={formData?.error_gmaps_link ? true : false}
                      name={'gmaps_link'}
                      placeholder={'Google Maps Link'}
                      errorMessage={formData?.error_gmaps_link ?? ''}
                      onChange={handleChangeGmapsLink}
                    ></InputField>
                  )}
                </Row>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Yourls
                  </Label>
                  <Label lg={8} className="text-left text-bold">
                    :{' '}
                    <span
                      className={ValidHttpUrl(formData?.yourls ?? '') ? 'ml-1 document-workshop' : 'ml-1'}
                      onClick={() => goToLink(formData?.yourls ?? '')}
                    >
                      {!formData?.yourls ? '-' : formData?.yourls}
                    </span>
                  </Label>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Pudo Coverage Area
                  </Label>
                  <SelectField
                    colWidth={8}
                    name="pudo"
                    placeholder="Pilih Pudo Coverage Area"
                    isMulti={true}
                    closeMenuOnSelect={false}
                    value={formData?.pudo}
                    options={pudoOptions}
                    disabled={disableForm || !isEdit}
                    onChange={handleChangePudo}
                  />
                </Row>
              </Col>
            </Row>
            <GMap
              disabled={!isEdit || disableForm}
              labelWidth={0}
              colWidth={12}
              latitude={formData?.latitude}
              longitude={formData?.longitude}
              handleAutoComplete={handleAutoComplete}
              handleClickMap={handleClickMap}
            />

            {allowEdit && (
              <Row>
                <Col sm={12} md={12} className="section-action">
                  <Button
                    disabled={disableForm}
                    className="button-action save"
                    onClick={() => {
                      if (!disableForm) {
                        handleAction('save');
                      }
                    }}
                  >
                    {isEdit ? 'Simpan' : 'Edit Data'}
                  </Button>
                  {isEdit && (
                    <Button
                      disabled={disableForm}
                      className="button-action cancel"
                      onClick={() => {
                        if (!disableForm) {
                          handleAction('cancel');
                        }
                      }}
                    >
                      {'Batal'}
                    </Button>
                  )}
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default Location;
