import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Wrapper from '@components/wrapper/Wrapper';
import Loading from '@components/loading/Loading';
import Helper from '@helpers/Helper';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import CustomPagination from '@components/pagination/CustomPagination';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import { Col, Button, Card, CardBody, Badge } from 'reactstrap';
import StarRating from '@components/review-ratings/StarRating';

function ReviewRatings() {
  const { generatePermission } = userPermission();
  const history = useHistory();
  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [sortingData, setSortingData] = useState({});
  const [resetSorting, setResetSorting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [dataExport, setDataExport] = useState([]);
  const [disabledExport, setDisabledExport] = useState(true);
  const queryParams = useLocation().search;
  const getParams = new URLSearchParams(queryParams);
  const keys = ['review', 'booking_id', 'workshop_name', 'customer_name', 'status', 'rating', 'created_date'];

  let tempFiltered = {};
  keys.forEach((key) => {
    tempFiltered[key] = getParams.get(key) ?? '';
  });

  const allowPermission = (menu) => {
    return !!(generatePermission('ratingreview', menu) || generatePermission('ratingreview', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowShow = allowPermission('all') || allowPermission('detail');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const ratingOptions = [
    { value: '', label: 'Semua Rating' },
    { value: '1', label: <StarRating rating={1} width={15} /> },
    { value: '2', label: <StarRating rating={2} width={15} /> },
    { value: '3', label: <StarRating rating={3} width={15} /> },
    { value: '4', label: <StarRating rating={4} width={15} /> },
    { value: '5', label: <StarRating rating={5} width={15} /> },
  ];

  const statusOptions = [
    { value: '', label: 'Semua Status' },
    { value: 'live', label: 'Live' },
    { value: 'hide review', label: 'Hide Review' },
    { value: 'hide review rating', label: 'Hide Review & Rating' },
  ];

  let dataFilter = [
    {
      id: 'filter_text_review',
      type: 'text',
      name: 'review',
      name_field: 'review',
      value: tempFiltered?.review ?? '',
      placeholder: 'Kata Ulasan',
    },
    {
      id: 'filter_date_created_date',
      type: 'date',
      name: 'created_date',
      name_field: 'created_date',
      date_range: false,
      value: tempFiltered?.created_date ?? '',
      placeholder: 'Created Date',
    },
    {
      id: 'filter_select_rating',
      type: 'select',
      name: 'rating',
      name_field: 'rating',
      options: ratingOptions,
      value: tempFiltered?.rating ? ratingOptions.filter((type) => type?.value === tempFiltered?.rating)?.[0] : '',
      placeholder: 'Rating',
    },
    {
      id: 'filter_text_booking_id',
      type: 'text',
      name: 'booking_id',
      name_field: 'booking_id',
      value: tempFiltered?.booking_id ?? '',
      placeholder: 'Booking ID',
    },
    {
      id: 'filter_text_workshop_name',
      type: 'text',
      name: 'workshop_name',
      name_field: 'workshop_name',
      value: tempFiltered?.workshop_name ?? '',
      placeholder: 'Bengkel Name',
    },
    {
      id: 'filter_text_customer_name',
      type: 'text',
      name: 'customer_name',
      name_field: 'customer_name',
      value: tempFiltered?.customer_name ?? '',
      placeholder: 'Customer Name',
    },
    {
      id: 'filter_select_status',
      type: 'select',
      name: 'status',
      name_field: 'status',
      options: statusOptions,
      value: tempFiltered?.status ? statusOptions.filter((type) => type?.value === tempFiltered?.status)?.[0] : '',
      placeholder: 'Status',
    },
  ];

  const columns = useMemo(
    () => [
      {
        name: 'Created At',
        selector: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('DD MMM, YYYY') : '-'}
            </span>
            <span className="time">
              {moment(row?.created_at ?? null).isValid() ? moment(row?.created_at).format('HH:mm:ss') : '-'}
            </span>
          </div>
        ),
        wrap: true,
        sortable: true,
        minWidth: '120px',
      },
      {
        name: 'Rating',
        selector: (row) => {
          return <StarRating rating={row?.rating} width={15} />;
        },
        width: '120px',
        wrap: true,
      },
      {
        name: 'Review',
        selector: (row) => row?.review ?? '-',
        cell: (row) => (
          <span className={`mr-3 ${row?.review?.length > 100 ? 'text-overflow' : ''}`}>{row?.review ?? ''}</span>
        ),
        wrap: true,
        minWidth: '350px',
      },
      {
        name: 'Booking ID',
        selector: (row) => row?.booking_code ?? '-',
        wrap: true,
        width: '160px',
      },
      {
        name: 'Bengkel Name',
        selector: (row) => row?.workshop_name ?? '-',
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'Customer Name',
        selector: (row) => row?.customer_name ?? '-',
        wrap: true,
        minWidth: '170px',
      },
      {
        name: 'Status',
        minWidth: '200px',
        selector: (row) => row?.status,
        cell: (row) => {
          if (row?.status == 'live') {
            return <span className="badge-custom badge-custom-success">Live</span>;
          } else if (row?.status == 'hide review') {
            return <span className="badge-custom badge-custom-yellow">Hide Review</span>;
          } else if (row?.status == 'hide review rating') {
            return <span className="badge-custom badge-custom-danger">Hide Review & Rating</span>;
          } else {
            return '-';
          }
        },
      },
    ],
    []
  );

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchReviewRatings() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/rating-reviews/`, { params });
      setData(response?.data?.data ?? []);
      setTotalRows(response?.data?.total_rows ?? 0);
      setTotalPages(response?.data?.total_page ?? 0);
      validateCountPage(response);
      handleDataExport(response?.data?.data ?? []);
    } catch (error) {
      setPage(1);
      setData([]);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
      handleDataExport([]);
    }
  }

  const getParamData = () => {
    let params = {
      review: filtered?.review ?? null,
      rating_created_at: filtered?.created_date ? moment(filtered?.created_date).format('YYYY-MM-DD') : null,
      rating: filtered?.rating ?? null,
      booking_code: filtered?.booking_id ?? null,
      workshop_name: filtered?.workshop_name ?? null,
      customer_name: filtered?.customer_name ?? null,
      status: filtered?.status ?? null,
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const handleDataExport = (data) => {
    let arrDataExport = [];

    data.forEach((item) => {
      const objDataExport = {
        review_date: moment(item.created_at).format('MMM DD YYYY HH:mm:ss'),
        rating: item?.rating ?? '-',
        review: item?.review.replace(/[\n]/g, ' ').replace(/,/g, ' ') ?? '-',
        booking: item?.booking_code ?? '-',
        workshop: item?.workshop_name ?? '-',
        customer: item?.customer_name ?? '-',
        status: item?.status,
      };

      arrDataExport.push(objDataExport);
    });

    setDataExport(arrDataExport);
    setDisabledExport(false);
  };

  const handleDownloadCSV = () => {
    const keys = ['review_date', 'rating', 'review', 'booking', 'workshop', 'customer', 'status'];
    const headers = ['Timestamp', 'Rating', 'Review', 'Booking ID', 'Bengkel Name', 'Customer Name', 'Status'];

    Helper.downloadCSV(dataExport, headers, keys);
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleResetFilters = () => {
    tempFiltered = {};
    resetDataFilter();
  };

  const resetDataFilter = () => {
    const updatedDataFilter = dataFilter.map((filter) => ({
      ...filter,
      value: typeof filter?.value !== '' ? '' : filter?.value,
    }));
    setFilters(updatedDataFilter);
    setFiltered({});
    tempFiltered = {};
  };

  const handleSetFiltered = (e) => {
    let tempVal = {
      ...e,
      created_date: e?.created_date?.selected_date
        ? moment(e?.created_date?.selected_date).format('YYYY-MM-DD 00:00:00')
        : '',
      status: e?.status.value ?? '',
      rating: e?.rating.value ?? '',
    };
    console.log('tempvall', e);
    setFiltered(tempVal);
  };

  const handleChangeSetSorting = () => {
    if (!sortingData?.order) {
      setResetSorting(false);
    }
    handleMethodfetchReviewRating();
  };

  const handleMethodfetchReviewRating = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchReviewRatings();
    }
  };

  const resetFilter = () => {
    setResetSorting(true);
    setSortingData({});
  };

  const openReviewRatingDetail = (row) => {
    if (allowShow) {
      history.push(`/review-ratings/${row.id}`);
    }
  };

  useEffect(() => {
    setFiltered({ ...tempFiltered });
    console.log('tempFiltered', tempFiltered);
  }, Object.values(tempFiltered));

  useEffect(() => {
    if (filtered) {
      if (page && pageLimit) {
        if (allowList) {
          let tempSetFiltered = { ...filtered };
          tempSetFiltered = {
            ...filtered,
          };
          const urlParam = Helper.jsonToUrlParam(tempSetFiltered);
          history.push(`/review-ratings/?${urlParam}`);
          //fetchReviewRatings();
        }
      }
    }
  }, [filtered]);

  useEffect(() => {
    if (page && pageLimit) {
      if (allowList) fetchReviewRatings();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleChangeSetSorting();
    }
  }, [sortingData]);

  useEffect(() => {
    resetFilter();
  }, [filtered]);

  useEffect(() => {
    setFilters(dataFilter);
  }, []);

  return (
    <Wrapper title="List Review & Rating" className="wrapperContent">
      <Card className="p-3">
        <CardBody className="p-0">
          <Col sm={12} md={12} className="p-0 mt-0 section-action">
            {allowList && (
              <Button
                disabled={disabledExport || loading}
                className="button-action primary"
                onClick={handleDownloadCSV}
              >
                {'Export CSV'}
              </Button>
            )}
          </Col>
          {filters?.length !== 0 && allowList && (
            <Col sm={12} md={12} className="p-0 mt-2">
              <ActionFilterCustom
                filters={filters}
                loading={loading}
                onApplyFilters={(e) => handleSetFiltered(e)}
                onResetFilters={handleResetFilters}
              ></ActionFilterCustom>
            </Col>
          )}
          <Col sm={12} md={12} className="p-0 mt-2">
            <DataTable
              persistTableHead
              highlightOnHover
              noHeader={true}
              progressPending={loading}
              data={data}
              columns={allowList ? columns : []}
              className="table dt-responsive table-custom"
              noDataComponent={noDataComponent}
              progressComponent={<Loading />}
              customStyles={customStylesHeaderTable}
              onRowClicked={(row) => openReviewRatingDetail(row)}
            />
          </Col>
          <Col sm={12} md={12} className="p-0 mt-2">
            {data?.length >= 1 && !loading && (
              <CustomPagination
                page={page}
                pageLimit={pageLimit}
                totalRows={data?.length ?? 0}
                totalAllRows={totalRows}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
                handleChangeRowPerPage={handleChangeRowPerPage}
              ></CustomPagination>
            )}
          </Col>
        </CardBody>
      </Card>
    </Wrapper>
  );
}

export default ReviewRatings;
