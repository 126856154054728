import { map } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

const CustomerCar = (props) => {
  const history = useHistory();
  const { origin, cars, toggleModalAddCar, allowAddCar, allowListCar } = props;

  const handleClickDetail = (carId) => {
    history.push(`/stnk/${carId}`);
  };

  return (
    <>
      <Card className="customer-car">
        <CardHeader className="bg-primary text-white mt-0">Car</CardHeader>
        <CardBody style={{ maxHeight: 250, overflow: 'auto', padding: '0 0.5rem' }}>
          <table className="w-100">
            <thead className="head">
              <th></th>
              <th>Mobil</th>
              <th className="text-center">Plat Nomor</th>
              <th className="text-center">Tahun</th>
              <th className="text-center">Transmisi</th>
              <th></th>
            </thead>
            <tbody>
              {allowListCar ? (
                cars?.length < 1 ? (
                  <tr>
                    <td colSpan={6} className="p-4 text-center">
                      Data mobil tidak ditemukan
                    </td>
                  </tr>
                ) : (
                  map(cars, (userCars, index) => {
                    const item = userCars?.user_car;
                    const isLastIndex = index + 1 === cars?.length;

                    return (
                      <tr style={{ minHeight: 100, height: 100, borderBottom: `${isLastIndex ? '0' : '1'}px solid` }}>
                        <td className="car-img">
                          <img src={item?.image} width={97} height={56} alt="" />
                        </td>
                        <td>{`${item?.brand} ${item?.model} ${item?.variant}`}</td>
                        <td className="text-center">{item?.license_plate ? item?.license_plate : '-'}</td>
                        <td className="text-center">{item?.year ?? '-'}</td>
                        <td className="text-center">{item?.transmission ?? '-'}</td>
                        <td className="text-right">
                          <Button
                            color="purple"
                            style={{
                              height: 'fit-content',
                              fontSize: 11,
                              whiteSpace: 'nowrap',
                              padding: '5.5px 4px',
                            }}
                            onClick={() => handleClickDetail(item?.variant_id)}
                          >
                            Lihat Detail
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                )
              ) : (
                <tr>
                  <td colSpan={6} className="p-4 text-center">
                    Tidak ada akses pada data mobil
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </CardBody>
      </Card>

      {origin !== 'create' && allowAddCar ? (
        <Button color="purple" className="w-100" onClick={toggleModalAddCar}>
          + Tambah Mobil
        </Button>
      ) : null}
    </>
  );
};

export default CustomerCar;
