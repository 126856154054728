import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Spinner } from 'reactstrap';
import { useParams, useHistory } from 'react-router';
import { findIndex } from 'lodash';
import map from 'lodash/map';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import BookingDetailCar from '@components/booking/BookingDetailCar';
import BookingDetailLog from '@components/booking/BookingDetailLog';
import BookingDetailJiraTodo from '@components/booking/BookingDetailJiraTodo';
import BookingDetailBill from '@components/booking/BookingDetailBill';
import BookingDetailUser from '@components/booking/BookingDetailUser';
import BookingDetailDetails from '@components/booking/BookingDetailDetails';
import BookingDetailWorkshop from '@components/booking/BookingDetailWorkshop';
import BookingDetailFleetService from '@components/booking/BookingDetailFleetService';
import BookingDetailFleetFileOtoklix from '@components/booking/BookingDetailFleetFileOtoklix';
import BookingDetailFleetFileUser from '@components/booking/BookingDetailFleetFileUser';
import BookingDetailLogPayment from '@components/booking/BookingDetailLogPayment';
import BookingDetailSettlementOtoklix from '@components/booking/BookingDetailSettlementOtoklix';
import BookingDetailSettlementWorkshop from '@components/booking/BookingDetailSettlementWorkshop';
import IconRefresh from '@assets/icons/refresh.svg';
import { dataBookingDetailDummy } from '@utils/Constants';
import BookingDetailFile from '@components/booking/BookingDetailFile';
import BookingDetailPhoto from '@components/booking/BookingDetailPhoto';
import BookingB2BDraftData from '../../components/booking/BookingB2BDraftData';

const BookingDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const { generatePermission } = userPermission();
  const api = new API('v2');

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [workshops, setWorkshops] = useState(null);
  const [bookingType, setBookingType] = useState(null);
  const [activityLogs, setActivityLogs] = useState([]);
  const [bookingTypeTitle, setBookingTypeTitle] = useState('');
  const [canReschedule, setCanReschedule] = useState(false);
  const [canReviseOrder, setCanReviseorder] = useState(false);
  const [canCreateEstimationOrder, setCanCreateEstimationOrder] = useState(false);
  const [dataBillSettlement, setDataBillSettlement] = useState({});
  const [dataDetailFleet, setDataDetailFleet] = useState({});
  const [canChangeWorkshop, setCanChangeWorkshop] = useState(false);
  const [bookingStatusOptions, setBookingStatusOptions] = useState([]);
  const [issueStatusOptions, setIssueStatusOptions] = useState([]);
  const [hasNotif, setHasNotif] = useState(true);
  const [notification, setNotification] = useState({});
  const [editDetail, setEditDetail] = useState(false);
  const [editDocument, setEditDocument] = useState(false);
  const [editCar, setEditCar] = useState(false);
  const [processEditDetail, setProcessEditDetail] = useState(false);
  const [processEditDocument, setProcessEditDocument] = useState(false);
  const [processEditCar, setProcessEditCar] = useState(false);
  const [disabledGLobal, setDisabledGLobal] = useState(false);
  const [isFleetMode, setIsFleetMode] = useState();
  const [showSection, setShowSection] = useState({});
  const [isSyncJira, setIsSyncJira] = useState(false);
  const [isSPK, setIsSPK] = useState(false);
  const [isCustomDoc, setIsCustomDoc] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [isArrivalPhoto, setIsArrivalPhoto] = useState(false);
  const [isB2BDraft, setIsB2BDraft] = useState(false);

  const allowPermissionRevise = generatePermission('booking', 'revise');

  const loadBookingDetail = async (bookingId) => {
    setLoading(true);
    await api
      .get(`v2/intools/bookings/${bookingId}/`)
      .then((res) => {
        const data = res.data.data;
        // const data = dataBookingDetailDummy.data;
        data.booking_type = data?.booking_type ? data?.booking_type.toLowerCase() : '';

        setData(data);
        setBookingType(data?.booking_type);
        loadLogHistory(data?.booking_id);
        loadBookingStatusOptions(data?.booking_id);
        if (data?.customer?.customer_type == 'b2b') {
          loadIssueStatusOptions(data?.booking_id);
        }

        if (
          data?.booking_status.slug === 'confirm' ||
          data?.booking_status.slug === 'on-process' ||
          data?.booking_status.slug === 'service-done' ||
          data?.booking_status.slug === 'finish' ||
          data?.booking_status.slug === 'cancel'
        ) {
          setCanReschedule(true);
          setCanChangeWorkshop(true);
        }

        setWorkshops(data);
        setData(data);
        setBookingType(data?.booking_type);
        setDataBillSettlement(data?.settlement_customer);
        setDataDetailFleet(data?.settlement_customer);
        // mappingDataFleet(data);
        // loadLogHistory(data?.booking_id);
        loadBookingStatusOptions(data?.booking_id);
        if (data?.customer?.customer_type == 'b2b') {
          loadIssueStatusOptions(data?.booking_id);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      });
  };

  const mappingDataFleet = (detailBooking) => {
    let dataBookingFleet = {
      groups: [
        {
          name: 'Paket',
          group_items: [],
        },
        {
          name: 'Produk',
          group_items: [],
        },
        {
          name: 'Jasa',
          group_items: [],
        },
        {
          name: 'Custom',
          group_items: [],
        },
      ],
      customer_note: detailBooking?.customer_note ?? '',
    };

    detailBooking?.packages?.items?.forEach((item) => {
      if (item?.package_details !== undefined) {
        let groupItemsDetail = [];
        item?.package_details?.forEach((detailItem) => {
          groupItemsDetail.push(parsingDetailItemService(detailItem));
        });
        dataBookingFleet.groups[0].group_items.push({
          name: item?.name ?? '',
          group_item_details: groupItemsDetail,
        });
      } else if (item?.products !== undefined) {
        item?.products?.forEach((detailItem) => {
          const isProduct = Helper.readIsProduct(detailItem?.product_type ?? '');
          const isService = Helper.readIsService(detailItem?.product_type ?? '');
          const index = isProduct ? 1 : isService ? 2 : null;
          if (index !== null) {
            if (dataBookingFleet?.groups[index]?.group_items?.length) {
              dataBookingFleet.groups[index].group_items[0].group_item_details.push(
                parsingDetailItemService(detailItem)
              );
            } else {
              dataBookingFleet.groups[index].group_items.push({
                group_item_details: [parsingDetailItemService(detailItem)],
              });
            }
          }
        });
      } else if (item?.custom_products !== undefined) {
        item?.custom_products?.forEach((detailItem) => {
          const isProduct = Helper.readIsProduct(detailItem?.product_type ?? '');
          const isService = Helper.readIsService(detailItem?.product_type ?? '');
          const categoryName = isProduct ? 'Produk' : isService ? 'Jasa' : null;
          if (categoryName !== null) {
            const index = findIndex(dataBookingFleet?.groups[3]?.group_items, (item) => {
              return item?.name === categoryName;
            });
            if (index >= 0) {
              dataBookingFleet.groups[3].group_items[index].group_item_details.push(
                parsingDetailItemService(detailItem)
              );
            } else {
              dataBookingFleet.groups[3].group_items.push({
                name: categoryName,
                group_item_details: [parsingDetailItemService(detailItem)],
              });
            }
          }
        });
      }
    });

    // setDataDetailFleet(dataBookingFleet);
  };

  const parsingDetailItemService = (detailItem) => {
    return {
      name: detailItem?.name ?? '',
      qty: detailItem?.qty ?? 0,
      is_approve: detailItem?.is_approve == false ? false : true,
      note: detailItem?.note ?? '',
    };
  };

  const mappingDataBillSettlement = (detailBooking) => {
    let dataBooking = {
      groups: [
        {
          name: 'Paket',
          group_items: [],
        },
        {
          name: 'Produk',
          group_items: [],
        },
        {
          name: 'Jasa',
          group_items: [],
        },
      ],
      otopoints: detailBooking?.total_point_spent >= 0 ? detailBooking?.total_point_spent : 0,
      discount: {
        code: detailBooking?.promo?.promo_code ?? '',
        nominal: detailBooking?.discount_promo ?? 0,
        name: detailBooking?.promo?.name ?? '' ?? 0,
        id: detailBooking?.promo?.id ?? '' ?? 0,
      },
      sub_total_bill: detailBooking?.packages?.total_price ?? 0,
      sub_total_otoklix: detailBooking?.packages?.total_settlement_otoklix ?? 0,
      sub_total_workshop: detailBooking?.packages?.total_settlement_workshop ?? 0,
      total_amount_bill: detailBooking?.price_after_tax ?? 0,
      dpp: detailBooking?.price_before_tax ?? 0,
      pph: detailBooking?.service_tax ?? 0,
      ppn: detailBooking?.tax ?? 0,
      total_amount_otoklix: detailBooking?.total_price_settlement_otoklix ?? 0,
      total_amount_workshop: detailBooking?.total_price_settlement_workshop ?? 0,
      total_take_rate_otoklix: detailBooking?.total_take_rate_otoklix ?? 0,
      customer_type: detailBooking?.customer?.customer_type ?? '',
      margin: detailBooking?.margin ?? 0,
      is_pkp: detailBooking?.workshops?.is_pkp,
      gross_profit: detailBooking?.gross_profit,
    };

    detailBooking?.packages?.items?.forEach((item) => {
      if (item?.package_details !== undefined) {
        const name = item?.name ?? '';
        const subTotalBill = item?.total_price ?? 0;
        const subTotalOtoklix = item?.package_total_settlement_otoklix ?? 0;
        const subTotalWorkshop = item?.package_total_settlement_workshop ?? 0;
        const subTotalMarginOtoklix = item?.package_total_margin_otoklix ?? 0;
        let groupItemsDetail = [];
        item?.package_details?.forEach((detailItem) => {
          groupItemsDetail.push(parsingDataGroupItemsDetail(detailItem));
        });
        dataBooking.groups[0].group_items.push(
          parsingDataGroupItems(
            'top',
            name,
            subTotalBill,
            subTotalOtoklix,
            subTotalWorkshop,
            subTotalMarginOtoklix,
            groupItemsDetail
          )
        );
      } else if (item?.products !== undefined || item?.custom_products !== undefined) {
        const listItem =
          item?.products !== undefined
            ? item?.products
            : item?.custom_products !== undefined
            ? item?.custom_products
            : [];

        listItem?.forEach((detailItem) => {
          const dataProductService = parsingDataProductService(
            detailBooking,
            detailItem,
            detailItem?.name ?? '',
            item?.custom_products !== undefined ? true : false,
            detailItem?.is_overide_sell_price,
            detailItem?.is_overide_nett_price
          );
          const name = dataProductService?.name;
          const subTotalBill = dataProductService?.sub_total_bill;
          const subTotalOtoklix = dataProductService?.sub_total_otoklix;
          const subTotalWorkshop = dataProductService?.sub_total_workshop;
          const subTotalMarginOtoklix = dataProductService?.sub_total_margin_otoklix;
          const groupItemsDetail = dataProductService?.group_item_details;
          if (dataProductService?.index !== undefined) {
            if (dataBooking.groups[dataProductService?.index].group_items?.length == 0) {
              dataBooking.groups[dataProductService?.index].group_items.push(
                parsingDataGroupItems(
                  'bottom',
                  name,
                  subTotalBill,
                  subTotalOtoklix,
                  subTotalWorkshop,
                  subTotalMarginOtoklix,
                  [groupItemsDetail]
                )
              );
            } else {
              dataBooking.groups[dataProductService?.index].group_items[0].group_items_details.push(groupItemsDetail);
              dataBooking.groups[dataProductService?.index].group_items[0].sub_total_bill += subTotalBill;
              dataBooking.groups[dataProductService?.index].group_items[0].sub_total_otoklix += subTotalOtoklix;
              dataBooking.groups[dataProductService?.index].group_items[0].sub_total_workshop += subTotalWorkshop;
              dataBooking.groups[
                dataProductService?.index
              ].group_items[0].sub_total_margin_otoklix += subTotalMarginOtoklix;
            }
          }
        });
      }
    });
    setDataBillSettlement(dataBooking);
  };

  const parsingDataProductService = (
    detailBooking,
    detailItem,
    nameItem,
    isCustom,
    isOverridePrice,
    isOverrideNettPrice
  ) => {
    let name = '';
    let subTotalBill = 0;
    let subTotalOtoklix = 0;
    let subTotalWorkshop = 0;
    let subTotalMarginOtoklix = 0;
    let groupItemDetails = parsingDataGroupItemsDetail(
      detailItem,
      nameItem,
      isCustom,
      isOverridePrice,
      isOverrideNettPrice
    );
    let index = undefined;

    if (
      detailItem?.product_type == 'part' ||
      detailItem?.product_type == 'product' ||
      detailItem?.product_type == 'Produk'
    ) {
      index = 1;
      name = 'Subtotal Produk';
      subTotalBill = detailItem?.final_sell_price ?? 0;
      subTotalMarginOtoklix = detailItem?.final_margin ?? 0;
      subTotalOtoklix = detailItem?.settlement_otoklix ?? 0;
      subTotalWorkshop = detailItem?.settlement_workshop ?? 0;
    } else if (
      detailItem?.product_type == 'work' ||
      detailItem?.product_type == 'service' ||
      detailItem?.product_type == 'Jasa'
    ) {
      index = 2;
      name = 'Subtotal Jasa';
      subTotalBill = detailItem?.final_sell_price ?? 0;
      subTotalMarginOtoklix = detailItem?.final_margin ?? 0;
      subTotalOtoklix = detailItem?.settlement_otoklix ?? 0;
      subTotalWorkshop = detailItem?.settlement_workshop ?? 0;
    }

    return {
      name: name,
      index: index,
      sub_total_bill: subTotalBill,
      sub_total_otoklix: subTotalOtoklix,
      sub_total_workshop: subTotalWorkshop,
      sub_total_margin_otoklix: subTotalMarginOtoklix,
      group_item_details: groupItemDetails,
    };
  };

  const parsingDataGroupItems = (
    position,
    name,
    sub_total_bill,
    sub_total_otoklix,
    sub_total_workshop,
    sub_total_margin_otoklix,
    groupItemsDetail
  ) => {
    return {
      position: position,
      name: name,
      sub_total_bill: sub_total_bill,
      sub_total_otoklix: sub_total_otoklix,
      sub_total_workshop: sub_total_workshop,
      sub_total_margin_otoklix: sub_total_margin_otoklix,
      group_items_details: groupItemsDetail,
    };
  };

  const parsingDataGroupItemsDetail = (
    detailItem,
    name = undefined,
    isCustom = false,
    isOverridePrice = false,
    isOverrideNettPrice = false
  ) => {
    return {
      name: name == undefined ? detailItem?.name ?? '' : name ?? '',
      qty: detailItem?.qty ?? 0,
      markup: detailItem?.markup_percentage ?? 0,
      disc: detailItem?.discount_percentage ?? 0,
      disc_nominal: detailItem?.discount_amount ?? 0,
      is_fbo: detailItem?.is_fbo ? true : false,
      customer_bill: detailItem?.final_sell_price ?? 0,
      workshop_bill: detailItem?.final_nett_price ?? 0,
      settlement_worskhop: detailItem?.settlement_workshop ?? 0,
      margin_otoklix: detailItem?.final_margin ?? 0,
      nett_price_otoklix: isCustom
        ? detailItem?.total_nett_price ?? 0
        : detailItem?.take_rate_otoklix == 100 || detailItem?.take_rate_otoklix == 0
        ? detailItem?.total_nett_price
        : detailItem?.total_sell_price,
      nett_price_workshop: isCustom
        ? detailItem?.total_workshop_price ?? 0
        : detailItem?.take_rate_workshop == 100 || detailItem?.take_rate_workshop == 0
        ? detailItem?.total_nett_price
        : detailItem?.total_sell_price,
      rate_otoklix: !isNaN(+detailItem?.take_rate_otoklix) ? detailItem?.take_rate_otoklix : null,
      rate_workshop: !isNaN(+detailItem?.take_rate_workshop) ? detailItem?.take_rate_workshop : null,
      price: detailItem?.sell_price ?? 0,
      price_otoklix: isCustom
        ? detailItem?.nett_price ?? 0
        : detailItem?.take_rate_otoklix == 100 || detailItem?.take_rate_otoklix == 0
        ? detailItem?.nett_price
        : detailItem?.sell_price,
      price_workshop: isCustom
        ? detailItem?.workshop_price ?? 0
        : detailItem?.take_rate_workshop == 100 ||
          detailItem?.take_rate_workshop == 0 ||
          !detailItem?.take_rate_workshop
        ? detailItem?.nett_price
        : detailItem?.sell_price,
      sub_total: detailItem?.final_sell_price ?? 0,
      sub_total_no_discount: detailItem?.marked_up_total_sell_price ?? 0,
      sub_total_otoklix: detailItem?.settlement_otoklix ?? 0,
      sub_total_workshop: detailItem?.settlement_workshop ?? 0,
      sell_price: detailItem?.sell_price ?? 0,
      workshop_price: detailItem?.workshop_price ?? 0,
      total_workshop_price: detailItem?.total_workshop_price ?? 0,
      markup_sell_price: detailItem?.marked_up_sell_price ?? 0,
      isCustom,
      isOverridePrice,
      isOverrideNettPrice,
    };
  };

  async function loadLogHistory(id) {
    const api = new API('v2');
    const url = `v2/intools/activity-logs/booking/${id}/`;
    const params = {
      params: {
        page: 1,
        limit: 100,
      },
    };
    const response = await api.get(url, params);
    setActivityLogs(response?.data?.data?.activity_logs ?? response?.data?.data ?? []);
  }

  const handleSyncJira = async () => {
    setIsSyncJira(true);
    const api = new API('v2');
    const notification = {
      status: true,
      type: '',
      message: '',
    };
    api
      .patch(`v2/intools/bookings/${id}/jira-ticket`)
      .then((result) => {
        setIsSyncJira(false);
        notification.type = 'success';
        notification.message = 'Sukses Sinkronisasi Jira';
        handleResultNotification(notification);
      })
      .catch((err) => {
        setIsSyncJira(false);
        notification.type = 'fail';
        notification.message = 'Gagal Sinkronisasi Jira';
        handleResultNotification(notification);
      });
  };

  const handleRefreshLogHistory = async () => {
    loadLogHistory(data?.booking_id);
  };

  async function loadBookingStatusOptions(id) {
    const api = new API('v2');
    const response = await api.get(`v2/intools/bookings/${id}/status-options/`);
    const bookingOptions = map(response.data.data, (option) => {
      return { name: option.label, value: option.slug };
    });

    bookingOptions && setBookingStatusOptions(bookingOptions);
  }

  async function loadIssueStatusOptions(id) {
    const api = new API('v2');
    const response = await api.get(`v2/intools/bookings/${id}/issue-status-options/`);
    const issueOptions = map(response.data.data, (option) => {
      return { name: option.label, value: option.slug };
    });

    issueOptions && setIssueStatusOptions(issueOptions);
  }

  const generateBreadcrumb = () => {
    return [
      {
        id: 1,
        label: 'Booking / Order',
        active: false,
        url: '/bookings',
      },
      {
        id: 2,
        label: `#${data?.booking_code || '-'}`,
        active: true,
        url: `/${data?.booking_code}`,
      },
    ];
  };

  const goToRevisePage = () => {
    history.push(`/bookings/${id}/revise`);
  };

  const goToEstimationPricePage = () => {
    history.push(`/bookings/${id}/create`);
  };

  const goToB2bDraftCreatePage = () => {
    history.push(`/bookings/${id}/b2b-draft/create`);
  };

  const handleCanReviseOrder = () => {
    const { customer_type } = data?.customer || {};
    const { slug } = data?.booking_status || {};
    let isB2BDraftMode = isB2BDraft !== undefined ? isB2BDraft : data?.b2b_draft?.is_draft;

    const isEligibleStatus = slug === 'confirm' || slug === 'on-process' || slug === 'complain';
    const isWaitingAndNotDraft = slug === 'waiting' && !isB2BDraftMode;

    if (customer_type === 'b2b') {
      if (!isFleetMode && (slug === 'payment' || isEligibleStatus || isWaitingAndNotDraft)) {
        setCanReviseorder(true);
      } else if (isFleetMode && (isEligibleStatus || isWaitingAndNotDraft)) {
        setCanReviseorder(true);
      } else if (isWaitingAndNotDraft) {
        setCanReviseorder(true);
      } else {
        setCanReviseorder(false);
      }
    } else {
      setCanReviseorder(false);
    }
  };

  const handleCanCreateEstimationOrder = () => {
    if (data?.customer?.customer_type == 'b2b' && isFleetMode && data?.booking_status?.slug == 'review') {
      setCanCreateEstimationOrder(true);
    } else {
      setCanCreateEstimationOrder(false);
    }
  };

  const handleFleetMode = () => {
    setIsFleetMode(data?.booking_source?.slug == 'fleet' ? true : false);
  };

  const handleShowSection = () => {
    setShowSection({
      workshop: isFleetMode ? (data?.workshop?.id ? true : false) : isB2BDraft ? false : true,
      fleet_service: isFleetMode ? true : false,
      fleet_attachment_otoklix: false,
      fleet_attachment_user: false,
      bill: isFleetMode ? (data?.booking_status?.slug == 'review' ? false : true) : isB2BDraft ? false : true,
      settlement_otoklix: isFleetMode
        ? data?.booking_status?.slug == 'review'
          ? false
          : true
        : isB2BDraft
        ? false
        : true,
      settlement_workshop: isFleetMode
        ? data?.booking_status?.slug == 'review'
          ? false
          : true
        : isB2BDraft
        ? false
        : true,
      file_attachment: true,
      draftDataSection: isB2BDraft ? true : false,
    });

    handleCanCreateEstimationOrder();
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      // window.scrollTo({
      //   top: 0,
      //   behavior: 'smooth',
      // });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);

    if (notification?.type == 'success' && !notification?.without_reload) {
      loadBookingDetail(id);
    }
  };

  const handleEditDetail = (isEdit) => {
    setEditDetail(isEdit);
  };

  const handleEditDocument = (isEdit) => {
    setEditDocument(isEdit);
  };

  const handleEditCar = (isEdit) => {
    setEditCar(isEdit);
  };

  const handleProcessEditDetail = (isProcess) => {
    setProcessEditDetail(isProcess);
  };

  const handleProcessEditDocument = (isProcess) => {
    setProcessEditDocument(isProcess);
  };

  const handleProcessEditCar = (isProcess) => {
    setProcessEditCar(isProcess);
  };

  const handleIsB2BDraftMode = () => {
    setIsB2BDraft(data?.b2b_draft?.is_draft);
  };

  useEffect(() => {
    loadBookingDetail(id);
  }, []);

  useEffect(() => {
    if (processEditDetail || processEditDocument || processEditCar) {
      setDisabledGLobal(true);
    } else {
      setDisabledGLobal(false);
    }
  }, [processEditDetail, processEditCar, processEditDocument]);

  useEffect(() => {
    handleShowSection();
  }, [isFleetMode, isB2BDraft]);

  useEffect(() => {
    handleCanReviseOrder();
  }, [data, isFleetMode]);

  useEffect(() => {
    handleFleetMode();
  }, [data]);

  useEffect(() => {
    handleIsB2BDraftMode();
  }, [data]);

  useEffect(() => {
    console.log(canReviseOrder, 'REVISE ORDER');
  }, [canReviseOrder]);

  if (loading) return <LoadingWrapper />;

  return (
    <>
      <Wrapper
        title={`Booking / Order #${data?.booking_code || '-'}`}
        breadcrumbData={generateBreadcrumb()}
        showNotification={hasNotif}
        notification={notification}
        onCloseNotification={handleCloseNotification}
      >
        <Row>
          <Col sm={12} md={12} className="text-right mb-2 section-action">
            {isB2BDraft && (
              <Button className="button-action add w-170" onClick={goToB2bDraftCreatePage}>
                Buat Estimasi
              </Button>
            )}
            {canCreateEstimationOrder && (
              <Button className="button-action add w-170" onClick={goToEstimationPricePage}>
                {'Buat Estimasi Harga'}
              </Button>
            )}
            {canReviseOrder && allowPermissionRevise && (
              <Button className="button-action primary" onClick={goToRevisePage}>
                {'Revise Order'}
              </Button>
            )}
            {data?.jira_ticket?.ticket_key ? (
              <>
                <Button color="purple" onClick={handleSyncJira}>
                  <>{isSyncJira ? <Spinner size="sm mr-1"></Spinner> : <img src={IconRefresh} />}</>
                  <span> Sinkronisasi Jira</span>
                </Button>
              </>
            ) : (
              <></>
            )}
          </Col>
          <Col sm={12} md={6}>
            <BookingDetailDetails
              id={id}
              isFleetMode={isFleetMode}
              detailData={data}
              dataBillSettlement={dataBillSettlement}
              workshops={workshops}
              canReschedule={canReschedule}
              bookingStatusOptions={bookingStatusOptions}
              issueStatusOptions={issueStatusOptions}
              editCar={editCar}
              editDetail={editDetail}
              editDocument={editDocument}
              disabledGLobal={disabledGLobal}
              onFetchBookingDetail={loadBookingDetail}
              onResultNotification={handleResultNotification}
              onEditModeDetail={handleEditDetail}
              onEditModeDocument={handleEditDocument}
              onProcessEditDetail={handleProcessEditDetail}
              onProcessEditDocument={handleProcessEditDocument}
              isSPK={isSPK}
              isCustomDoc={isCustomDoc}
              isArrivalPhoto={isArrivalPhoto}
              isInvoice={isInvoice}
            />
            {showSection?.draftDataSection && (
              <BookingB2BDraftData b2bDraftData={data?.b2b_draft} title={'Draft Data'} />
            )}
            {showSection?.workshop && (
              <BookingDetailWorkshop
                id={id}
                detailData={data}
                canChangeWorkshop={canChangeWorkshop}
                onFetchBookingDetail={loadBookingDetail}
              />
            )}
            {showSection?.fleet_service && (
              <BookingDetailFleetService
                detailData={data}
                title={'Permintaan Servis'}
                customer_note={data?.customer_note}
              ></BookingDetailFleetService>
            )}
            {showSection?.bill && (
              <BookingDetailBill
                id={id}
                data={data}
                detailData={data?.settlement_customer}
                dataOfferingLetter={data?.summary_offering_letter}
                bookingType={bookingType}
                title={'Tagihan Ke Customer'}
                onLoading={setLoading}
                onResultNotification={handleResultNotification}
              />
            )}
            {showSection?.settlement_workshop && (
              <BookingDetailSettlementWorkshop
                data={data}
                detailData={data?.settlement_workshop}
                takeRateOtx={data?.settlement_otoklix?.total_take_rate}
                bookingType={bookingType}
                title={`Settlement Workshop ` + bookingTypeTitle}
                id={id}
                onResultNotification={handleResultNotification}
              />
            )}
          </Col>
          <Col sm={12} md={6}>
            <BookingDetailUser detailData={data} />
            <BookingDetailCar
              id={id}
              isFleetMode={isFleetMode}
              detailData={data}
              editDetail={editDetail}
              editDocument={editDocument}
              disabledGLobal={disabledGLobal}
              onResultNotification={handleResultNotification}
              onEditModeCar={handleEditCar}
              onProcessEditCar={handleProcessEditCar}
            />
            <BookingDetailJiraTodo detailData={data?.jira_ticket} />
            <BookingDetailLog detailData={activityLogs} />
            {showSection?.file_attachment && (
              <BookingDetailFile
                detailData={data}
                isFleetMode={isFleetMode}
                onResultNotification={handleResultNotification}
                setIsSPK={setIsSPK}
                setIsCustomDoc={setIsCustomDoc}
                setIsInvoice={setIsInvoice}
                loadLogHistory={() => handleRefreshLogHistory()}
              />
            )}
            {showSection?.file_attachment && (
              <BookingDetailPhoto
                detailData={data}
                onResultNotification={handleResultNotification}
                setIsArrivalPhoto={setIsArrivalPhoto}
                loadLogHistory={() => handleRefreshLogHistory()}
              />
            )}
            {showSection?.fleet_attachment_otoklix && (
              <BookingDetailFleetFileOtoklix detailData={data} onResultNotification={handleResultNotification} />
            )}
            {showSection?.fleet_attachment_user && (
              <BookingDetailFleetFileUser detailData={data} onResultNotification={handleResultNotification} />
            )}
            {showSection?.settlement_otoklix && (
              <BookingDetailSettlementOtoklix
                data={data}
                detailData={data?.settlement_otoklix}
                title={`Settlement Otoklix ` + bookingTypeTitle}
              />
            )}
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

export default BookingDetail;
