import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import filter from 'lodash/filter';
import Wrapper from '@components/wrapper/Wrapper';
import BrandDetail from '@components/masters/brand/BrandDetail';
import ProductDetail from '@components/masters/product/ProductDetail';
import OtoklixInventoryDetail from '@components/masters/otoklix-inventory/OtoklixInventoryDetail';
import OtoklixServiceDetail from '@components/masters/otoklix-service/OtoklixServiceDetail';

const MasterDetail = () => {
  const { slug, id } = useParams();

  const [activeSection, setActiveSection] = useState({});

  const sectionData = [
    {
      key: 'brand',
      label: 'Detail Brand',
      url: '/masters?activetab=brand',
    },
    {
      key: 'product',
      label: 'Detail Product',
      url: '/masters?activetab=product',
    },
    {
      key: 'otoklix-inventory',
      label: 'Detail Product',
      url: '/masters?activetab=otoklix-inventory',
    },
    {
      key: 'otoklix-service',
      label: 'Detail Service',
      url: '/masters?activetab=otoklix-service',
    },
  ];

  const checkAvailableSection = () => {
    const availableSection =
      sectionData?.length &&
      filter(sectionData, (item) => {
        return item?.key == slug;
      });

    setActiveSection(availableSection?.length ? availableSection[0] : {});
  };

  useEffect(() => {
    checkAvailableSection();
  }, [slug]);

  return (
    <React.Fragment>
      {!activeSection?.key && <Wrapper className="wrapperContent"></Wrapper>}
      {activeSection?.key == 'brand' && <BrandDetail activeSection={activeSection} idBrand={id}></BrandDetail>}
      {activeSection?.key == 'product' && <ProductDetail activeSection={activeSection} idProduct={id}></ProductDetail>}
      {activeSection?.key == 'otoklix-inventory' && (
        <OtoklixInventoryDetail activeSection={activeSection} idInventory={id}></OtoklixInventoryDetail>
      )}
      {activeSection?.key == 'otoklix-service' && (
        <OtoklixServiceDetail activeSection={activeSection} idService={id}></OtoklixServiceDetail>
      )}
    </React.Fragment>
  );
};

export default MasterDetail;
