import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Wrapper from '@components/wrapper/Wrapper';
import DetailCarHealth from '@components/stnk/carhealth/DetailCarHealth';
import API from '@utils/API';
import { find, map } from 'lodash';
import moment from 'moment';
import Helper from '@helpers/Helper';
import { INSPECTION_SCORE_LIST } from '@utils/Constants';

function CarHealthDetail() {
  const { id, inspection_id, booking_code } = useParams();
  const api = new API('v2');
  const history = useHistory();
  const [isUpdate, setIsUpdate] = useState(false);
  const [label, setLabel] = useState('');
  const [disabledHeaderForm, setDisabledHeaderForm] = useState(true);
  const [inspectionList, setInspectionList] = useState([]);
  const [originOptions, setOriginOptions] = useState([]);
  const [defaultValueCarHealth, setDefaultValueCarHealth] = useState({});

  // const originOptions = [
  //   {
  //     label: 'O 2 O',
  //     value: 'o2o',
  //   },
  // ];

  const breadcrumb = [
    {
      id: 1,
      label: 'Detail Customer Car',
      active: false,
      url: `/stnk/${id}`,
    },
    {
      id: 2,
      label: label,
      active: true,
      url: '',
    },
  ];

  async function fetchInspectionList() {
    try {
      const response = await api.get(`v2/intools/master/inspection-points/`);
      mappingInspectionList(response?.data?.data?.inspection_points ?? []);
    } catch (error) {
      mappingInspectionList([]);
    }
  }

  async function fetchInspectionData() {
    const response = await api.get(`v2/intools/user-cars/${id}/inspections/${inspection_id}/`);
    return response?.data?.data ?? {};
  }

  const getWorkshopList = async () => {
    const response = await api.get('v2/intools/user-cars/workshops/');
    const data = response?.data?.data;

    if (data) {
      const workshopList = map(data, (item) => {
        return {
          label: item?.name,
          id: item?.id,
          value: item?.slug,
        };
      });

      return workshopList;
    } else {
      return [];
    }
  };

  const fetchOriginOptions = async () => {
    getWorkshopList()
      .then((data) => {
        setOriginOptions(data);
      })
      .catch(() => {
        setOriginOptions([]);
      });
  };

  const mappingInspectionList = (data) => {
    const inspections = map(data, (item) => {
      if (item?.name) {
        return {
          id: item.id ?? null,
          label: item?.label ?? '',
          name: item?.name,
          conditions: null,
          note: null,
        };
      }
    });

    const valueCarHealth = {
      inspections: inspections,
    };

    setInspectionList(inspections);
    setDefaultValueCarHealth(valueCarHealth);
  };

  async function fetchInspectionListById() {
    await getWorkshopList()
      .then((res) => {
        fetchInspectionData().then((response) => {
          mappingInspectionListByID(response ?? {}, res);
          setLabel('History Car Health Inspection');
          setIsUpdate(false);
          setDisabledHeaderForm(true);
        });
      })
      .catch(() => {
        setLabel(booking_code ? 'Update Car Health Inspection' : 'History Car Health Inspection');
        setIsUpdate(booking_code ? true : false);
        fetchInspectionList();
      });
  }

  const mappingInspectionListByID = (data, wsList) => {
    const booking = {
      id: data?.inspection?.booking_id ?? null,
      booking_code: data?.inspection?.booking_code ?? null,
      booking_datetime: data?.inspection?.inspection_date ?? null,
    };
    const inspection_location = data?.inspection?.inspection_location ?? null;
    const mile = data?.inspection?.mileage ?? 0;
    const inspection_date = data?.inspection?.inspection_date ?? null;
    const inspection_date_string = moment(inspection_date).isValid()
      ? moment(inspection_date).format('DD/MM/YYYY , HH:mm:ss')
      : '-';
    const getWs = find(wsList, { value: data?.inspection?.origin });
    const origin = {
      label: getWs?.label ?? 'Workshop',
      value: getWs?.value ?? 'workshop',
    };
    const inspections = map(data?.inspection?.inspection_points, (item) => {
      return {
        label: item?.label ?? '',
        name: item?.name,
        conditions: item?.value ?? null,
        note: item?.note ?? '',
      };
    });
    const summary = data?.inspection?.summary ?? '';
    const event_location = data?.inspection?.event_location ?? '-';
    const event_name = data?.inspection?.event_name ?? '-';
    const workshop_project = data?.inspection?.workshop_project ?? '-';
    const workshop_langganan = data?.inspection?.workshop_langganan ?? '-';

    const valueCarHealth = {
      booking: booking,
      origin: origin,
      mileage: {
        value: mile,
        string: Helper.numberWithCommas(mile),
      },
      inspection_date: inspection_date,
      inspection_date_string: inspection_date_string,
      inspection_location: inspection_location,
      inspections: inspections,
      summary: summary,
      event_location: event_location,
      event_name: event_name,
      workshop_project: workshop_project,
      workshop_langganan: workshop_langganan,
    };

    setDefaultValueCarHealth(valueCarHealth);
  };

  const handleUpdateCarHealth = () => {
    history.push(`/stnk/${id}`);
  };

  const initAction = async (inspection_id) => {
    await fetchOriginOptions();

    if (inspection_id) {
      fetchInspectionListById();
    } else {
      setLabel('Update Car Health Inspection');
      setIsUpdate(true);
      setDisabledHeaderForm(false);
      fetchInspectionList();
    }
  };

  useEffect(() => {
    initAction(inspection_id);
  }, [inspection_id]);

  return (
    <React.Fragment>
      <Wrapper title={label} breadcrumbData={breadcrumb}>
        <Card>
          <CardHeader className="bg-primary text-white mt-0">
            {isUpdate ? 'Pengisian Data Inspeksi' : 'Detail Data Inspeksi'}
          </CardHeader>
          <CardBody>
            <DetailCarHealth
              usercarID={id}
              bookingCode={booking_code}
              isUpdated={isUpdate}
              isDisabledHeaderForm={disabledHeaderForm}
              originOptions={originOptions}
              inspectionList={inspectionList}
              scoreList={INSPECTION_SCORE_LIST}
              defaultValueCarHealth={defaultValueCarHealth}
              onUpdateCarHealth={handleUpdateCarHealth}
            ></DetailCarHealth>
          </CardBody>
        </Card>
      </Wrapper>
    </React.Fragment>
  );
}

export default CarHealthDetail;
