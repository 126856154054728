import React, { useState, useEffect } from 'react';
import { Label, FormGroup, Input, Row, Col, Button, FormFeedback } from 'reactstrap';
import { Link } from 'react-router-dom';
import API from '@utils/API';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import SelectField from '@components/field/SelectField';
import InputField from '@components/field/InputField';
import SelectAsyncField from '@components/field/SelectAsyncField';
import OverridePriceIcon from '@assets/icons/override-price.svg';
import SoldOut from '@assets/images/sold-out.png';
import Divider from '@components/divider/Divider';
import InfoIcon from '@assets/icons/info.svg';
import { Tooltip } from 'react-tooltip';
import Helper from '@helpers/Helper';
import moment from 'moment';
import newTabIcon from '@assets/icons/new-tab.svg';

function BookingReviseFormItem(props) {
  const {
    headerData,
    data,
    disabled,
    index,
    isFleetMode,
    categoryOptions,
    onChangeCategory,
    onChangeItem,
    onChangeRemarks,
    onChangeQty,
    onChangeOverrideSellPrice,
    onChangeOverrideNettPrice,
    onChangeSplitedTax,
    onChangeSellPrice,
    onChangeNettPrice,
    onChangeListItem,
    onChangePromoCode,
    onResetItem,
    handleAddItem,
    calculatePromo,
  } = props;

  const [defaultNettPriceNewItem, setDefaultNettPriceNewItem] = useState(0);
  const [isOverrideNettPrice, setIsOverrideeNettPrice] = useState(0);
  const [product, setProduct] = useState({});
  const [promoCode, setPromoCode] = useState('');
  const [promoData, setPromoData] = useState({});

  const api = new API('v2');
  const apiSearch = new API('search');
  const onCategoryChange = (value) => {
    onChangeCategory(value, index);
  };

  const onItemChange = (value) => {
    onChangeItem(value, index);
    setProduct(value?.product_id + uniqueTime);
  };

  const onRemarksChange = (value) => {
    onChangeRemarks(value, index);
  };

  const onQtyChange = (value) => {
    onChangeQty(value, index);
  };

  const onOverrideSellPriceChange = () => {
    onChangeOverrideSellPrice(index);
  };

  const onOverrideNettPriceChange = () => {
    onChangeOverrideNettPrice(index);
    onChangeSplitedTax(false, index);
  };

  const onSellPriceChange = (value) => {
    onChangeSellPrice(value, index);
  };

  const onPromoCodeChange = (value) => {
    setPromoCode(value);
  };

  const onNettPriceChange = (value) => {
    onChangeNettPrice(value, index);
  };

  const onSplitedTaxChange = (value) => {
    const checked = value.target.checked;
    onChangeSplitedTax(checked, index);
  };

  async function fetchItem(query) {
    let listItem = [];
    let params = {
      category_id: data?.category?.value,
      customer_id: headerData?.customer?.id,
      is_ecommerce: headerData?.booking_source?.source_type == 'ecommerce' ? true : false,
      is_enterprise_customer: true,
      q: query,
    };

    if (headerData?.user_car?.car_details?.id) {
      params.variant_car_id = headerData?.user_car?.car_details?.id;
    }
    try {
      const url = headerData?.workshop?.slug
        ? `v2/intools/products/items/workshop/${headerData?.workshop?.slug}`
        : `v2/intools/products/items/master/`;
      const response = await apiSearch.get(url, { params });
      if (response?.data?.data?.length > 0) {
        listItem = map(response?.data?.data, (item, idx) => {
          return {
            value: item?.product_id ? `${item?.product_id}||${idx}` : null,
            label: item?.display_name,
            ...item,
          };
        });
      }
      onChangeListItem(listItem, index);
      return listItem;
    } catch (error) {
      onChangeListItem(listItem, index);
      return listItem;
    }
  }

  const _loadSuggestions = (query, callback) => {
    let listItem = [];

    let url = headerData?.workshop?.slug
      ? `v2/intools/products/items/workshop/${headerData?.workshop?.slug}`
      : 'v2/intools/products/items/master/';
    let params = {
      category_id: data?.category?.value,
      customer_id: headerData?.customer?.id,
      is_ecommerce: headerData?.booking_source?.source_type == 'ecommerce' ? true : false,
      is_enterprise_customer: true,
      q: query,
    };

    if (headerData?.user_car?.car_details?.id) {
      params.variant_car_id = headerData?.user_car?.car_details?.id;
    }

    return apiSearch
      .get(url, { params })
      .then((res) => {
        if (res?.data?.data?.length > 0) {
          listItem = map(res?.data?.data, (item, idx) => {
            return {
              value: item?.product_id ? `${item?.product_id}||${idx}` : null,
              label: item?.display_name,
              ...item,
            };
          });
        }
        callback(listItem, index);
        onChangeListItem(listItem, index);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const checkPromoCode = async () => {
    const price = +data?.sell_price?.formatMoney;
    const qty = +data?.qty;
    const markup = Helper.readMarkUp(
      headerData?.customer?.product_markup ?? 0,
      headerData?.customer?.service_markup ?? 0,
      data?.product_type
    );
    const discount = Helper.readDiscount(
      headerData?.customer?.product_discount ?? 0,
      headerData?.customer?.service_discount ?? 0,
      data?.product_type
    );
    const discountAmount = price * (+discount / 100);
    const totalPrice = qty * (price + price * (+markup / 100));

    const items = {
      index: index,
      promo_code: promoCode,
      product_id: data?.product_id,
      qty: qty,
      price: price,
      markup_percentage: markup,
      discount_percentage: discount,
      discount_amount: discountAmount,
      total_price: totalPrice,
      source: 'revise',
    };
    try {
      const checkPromo = await calculatePromo(items, 'item');
      if (checkPromo) onChangePromoCode(promoCode, index);
    } catch (error) {
      console.error(error);
    }

    getPromoDetail();
  };

  const getPromoDetail = () => {
    api
      .get(`v2/intools/campaigns/voucher-campaign/${promoCode}/`)
      .then((response) => {
        const data = response?.data?.data ?? {};
        const promo = {
          campaign_id: data?.campaign_id,
          campaign_name: data?.campaign_name,
          voucher_code: promoCode,
          status: 'success',
        };
        setPromoData(promo);
      })
      .catch((err) => {
        const errMessage = err?.response?.data?.error?.message ?? 'promo code may not be blank';
        const promo = {
          status: 'danger',
          message: errMessage,
        };
        setPromoData(promo);
      });
  };

  const disabledForm = () => {
    let isDisabled = false;
    if (
      headerData?.booking_type == 'b2b' &&
      (headerData?.booking_status?.slug == 'service-done' || headerData?.booking_status?.slug == 'finish')
    ) {
      isDisabled = true;
    }
    return isDisabled;
  };

  const uniqueTime = moment().hour() + moment().minute() + moment().second();

  useEffect(() => {
    setIsOverrideeNettPrice(
      data?.is_override_nett_price || (data?.nett_price?.formatMoney == 0 && data?.attr?.is_wi) || !data?.attr?.is_wi
    );
  }, []);

  useEffect(() => {
    if (data?.default_nett_price) {
      setIsOverrideeNettPrice((data?.nett_price?.formatMoney == 0 && data?.attr?.is_wi) || !data?.attr?.is_wi);
    }
  }, [data?.default_nett_price]);

  useEffect(() => {
    if (data?.id || product) {
      const element = document.getElementsByName(data?.id ? data?.id + uniqueTime : product)[0];
      element ? element.setAttribute('data-tooltip-content', '') : null;
    }
  }, [data, product]);

  useEffect(() => {
    if (data?.voucher_code) setPromoCode(data?.voucher_code);
  }, [data?.voucher_code]);

  useEffect(() => {
    if (data?.promo_data) setPromoData(data?.promo_data);
  }, [data?.promo_data]);

  useEffect(() => {
    if (promoData?.status === 'success') onChangePromoCode(promoCode, index);
    if (promoData?.status === 'danger') onChangePromoCode('', index);
  }, [promoData]);

  return (
    <React.Fragment>
      <div className="service-tab">
        {isFleetMode && data?.is_approve !== undefined && data?.is_approve !== null && (
          <FormGroup row>
            <Label lg={3}>Status</Label>
            <Label
              lg={9}
              className={
                data?.is_approve ? 'text-left font-weight-bold color-green' : 'text-left font-weight-bold color-red'
              }
            >
              <div>{data?.is_approve ? 'APPROVED' : 'REJECTED'}</div>
            </Label>
          </FormGroup>
        )}
        {isFleetMode && data?.is_draft_change !== undefined && data?.is_draft_change !== null && data?.is_draft_change && (
          <FormGroup row>
            <Label lg={3}>Label</Label>
            <span className="ml-2 badge-custom badge-custom-draft">{'Draft'}</span>
          </FormGroup>
        )}
        {data?.is_new_data && (
          <Row>
            <Col>
              <Label className="cursor-pointer float-right mb-3" onClick={() => onResetItem(index)}>
                <u>Reset</u>
              </Label>
            </Col>
          </Row>
        )}
        <FormGroup row>
          <Label lg={3}>Kategori</Label>
          <SelectField
            colWidth={9}
            title="Kategori"
            placeholder={'Pilih Kategori'}
            disabled={data?.disable_category || disabled}
            closeMenuOnSelect={true}
            options={categoryOptions}
            errorMessage={data?.error_category ?? ''}
            value={data?.category}
            onChange={(e) => {
              if (!data?.disable_category && !disabled) {
                onCategoryChange(e);
              }
            }}
          />
        </FormGroup>

        <FormGroup row>
          <Label lg={3}>Sub Kategori</Label>
          <InputField colWidth={9} disabled={true} value={!data?.sub_category ? '-' : data?.sub_category} />
        </FormGroup>

        <FormGroup row>
          <Label lg={3} className="label-required">
            Item
          </Label>
          <Tooltip
            id={`info-similarity`}
            place="right-center"
            offset={30}
            position-strategy="fixed"
            style={{ zIndex: '3' }}
          />
          <SelectAsyncField
            id="item-revise"
            colWidth={9}
            isDisabled={data?.disable_item || disabled}
            value={data?.item}
            defaultOptions={data?.item_options ?? []}
            getOptionLabel={(e) => (
              <span
                name={data?.id ? data?.id + uniqueTime : product}
                className={data?.id ? data?.id + uniqueTime : product}
                id={data?.id ? data?.id + uniqueTime : product}
                style={{ display: 'block', float: 'none' }}
                data-tooltip-id={`info-similarity`}
                data-tooltip-content={e?._sim?.content ?? ''}
              >
                {e?.attr?.is_fbo ? (
                  <span className="dropdown-tag">FBO</span>
                ) : e?.attr?.is_wi ? (
                  <span className="dropdown-tag__price-origin">WI</span>
                ) : (
                  <span className="dropdown-tag__price-origin no-wi">NO WI</span>
                )}
                {e.label}
                {!e?.warning_state?.is_sellable && headerData?.workshop?.is_gms && (
                  <img id="availability" src={SoldOut} width={15} className="ml-1 mt-0" />
                )}
                {/* {!e.is_compatible && <img id="warning-icon" src={WarningIcon} width={15} className="ml-1" />} */}
              </span>
            )}
            getOptionValue={(e) => e.value}
            loadOptions={debounce(_loadSuggestions, 600)}
            onChange={(e) => {
              if (!data?.disable_item && !disabled) {
                setDefaultNettPriceNewItem(e?.nett_price);
                onItemChange(e);
              }
            }}
            invalid={data?.error_item}
            errorMessage={data?.error_item ?? ''}
            placeholder="Pilih Item..."
            cacheOptions={false}
            isOptionDisabled={(e) => !e?.warning_state?.is_sellable}
            isCreate
            textLabelCreate="Custom Item"
            onClickCreate={handleAddItem}
          />
        </FormGroup>

        {headerData?.booking_type == 'b2b' && (
          <FormGroup row>
            <Label lg={3}>Keterangan</Label>
            <InputField
              id="remarks"
              colWidth={9}
              onChange={(e) => {
                if (!disabled) {
                  onRemarksChange(e);
                }
              }}
              disabled={disabled || disabledForm()}
              value={data?.remarks}
              placeholder={'Cth: Keterangan Tahun, Tukar Tambah, dll'}
              invalid={data?.error_remarks ? true : false}
              errorMessage={data?.error_remarks}
            />
          </FormGroup>
        )}

        <FormGroup row>
          <Label lg={3} className="label-required">
            Qty
          </Label>
          <InputField
            inputType="number"
            colWidth={9}
            placeholder={'Masukkan Qty'}
            value={data?.qty?.toString()}
            invalid={data?.error_qty ? true : false}
            errorMessage={data?.error_qty ?? ''}
            disabled={disabled || disabledForm()}
            onChange={(e) => {
              if (!disabled) {
                onQtyChange(e);
              }
            }}
          />
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="label-required">
            DPP Jual Customer
          </Label>
          {data?.loading_hit_default_price && (
            <Label lg={9} className=" text-left">
              Mohon menunggu...
            </Label>
          )}
          {!data?.loading_hit_default_price && (
            <Col lg={8} sm={11}>
              <Input
                className="input-field"
                placeholder={'Masukkan Sell Price'}
                disabled={data?.disable_sell_price || disabled || disabledForm()}
                value={data?.sell_price?.formatMoneyString}
                invalid={data?.error_sell_price ? true : false}
                onChange={(e) => {
                  if (!data?.disable_sell_price && !disabled && !disabledForm()) {
                    onSellPriceChange(e);
                  }
                }}
              />
              <FormFeedback>{data?.error_sell_price ?? ''}</FormFeedback>
            </Col>
          )}
          {!data?.loading_hit_default_price && (
            <Col lg={1} sm={1} className="d-flex justify-content-end">
              <Button
                color="primary"
                size="lg"
                className={`justify-self-rightjustify-self-right ${
                  data?.is_override_sell_price ? 'btn-override-price' : 'btn-no-override-price'
                }`}
                disabled={!data?.item || disabled}
                onClick={() => {
                  if (!disabled && !disabledForm()) {
                    onOverrideSellPriceChange();
                  }
                }}
              >
                <img src={OverridePriceIcon} height={15} className="override-icon" />
              </Button>
            </Col>
          )}
        </FormGroup>

        {headerData?.workshop?.is_pkp && data?.is_override_nett_price && (
          <>
            <Divider />
            <FormGroup row>
              <Tooltip id="info-split-tax" />
              <Label lg={3}>
                <img
                  src={InfoIcon}
                  alt=""
                  className="mr-1 cursor-pointer"
                  width={16}
                  data-tooltip-id="info-split-tax"
                  data-tooltip-content="Jika diaktifkan, maka operator mengisi Harga Jual Bengkel termasuk PPN lalu sistem akan menghitung DPP"
                />
                Split Tax Pricing
              </Label>
              <Col lg={9} style={{ padding: '6px 12px', display: 'flex', alignItems: 'center' }}>
                <Input
                  id="split-tax-check"
                  type="checkbox"
                  onChange={onSplitedTaxChange}
                  checked={data?.is_splited_tax}
                  disabled={disabled || !!!headerData?.workshop?.is_pkp}
                />
              </Col>
            </FormGroup>

            {data?.is_splited_tax && (
              <FormGroup row>
                <Label lg={3}>DPP Jual Bengkel</Label>
                <Col lg={9} sm={12} className="d-flex align-items-center">
                  <span>{`IDR ${Helper.formatMoney(Helper.countDPP(data?.nett_price?.formatMoney))}`}</span>
                </Col>
              </FormGroup>
            )}
          </>
        )}

        <FormGroup row>
          <Label lg={3} className="label-required">
            {data?.is_splited_tax ? 'DPP Jual Bengkel Termasuk PPN' : 'DPP Jual Bengkel'}
          </Label>
          {data?.loading_hit_default_nett_price && (
            <Label lg={9} className=" text-left">
              Mohon menunggu...
            </Label>
          )}
          {!data?.loading_hit_default_nett_price && (
            <Col
              lg={
                (data?.is_new_data && data?.item && (data?.attr?.is_fbo || defaultNettPriceNewItem > 0)) ||
                (!data?.is_new_data && (data?.attr?.is_fbo || !isOverrideNettPrice))
                  ? 9
                  : 8
              }
              sm={
                (data?.is_new_data && data?.item && (data?.attr?.is_fbo || defaultNettPriceNewItem > 0)) ||
                (!data?.is_new_data && (data?.attr?.is_fbo || !isOverrideNettPrice))
                  ? 12
                  : 11
              }
            >
              <Input
                className="input-field"
                placeholder={'Masukkan Nett Price'}
                disabled={data?.disable_nett_price || disabled || disabledForm()}
                value={data?.nett_price?.formatMoneyString}
                invalid={data?.error_nett_price ? true : false}
                onChange={(e) => {
                  if (!data?.disable_nett_price && !disabled && !disabledForm()) {
                    onNettPriceChange(e);
                  }
                }}
              />
              <FormFeedback>{data?.error_nett_price ?? ''}</FormFeedback>
            </Col>
          )}
          {!data?.loading_hit_default_nett_price && (
            <>
              {((data?.is_new_data && !data?.attr?.is_fbo && (!data?.item || defaultNettPriceNewItem <= 0)) ||
                (!data?.is_new_data && !data?.attr?.is_fbo && isOverrideNettPrice)) && (
                <Col lg={1} sm={1} className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    size="lg"
                    className={`justify-self-rightjustify-self-right ${
                      data?.is_override_nett_price ? 'btn-override-price' : 'btn-no-override-price'
                    }`}
                    disabled={!data?.item || disabled || data?.disabled_override_net_price}
                    onClick={() => {
                      if (!disabled && !disabledForm()) {
                        onOverrideNettPriceChange();
                      }
                    }}
                  >
                    <img src={OverridePriceIcon} height={15} className="override-icon" />
                  </Button>
                </Col>
              )}
            </>
          )}
        </FormGroup>
        <FormGroup row>
          <Label lg={3}>Kode Promo</Label>
          <InputField
            colWidth={6}
            placeholder={'Masukkan Kode Promo'}
            value={promoCode}
            valid={data?.promo_status?.color === 'success'}
            invalid={data?.promo_status?.color === 'danger' && data?.voucher_code !== '' ? true : false}
            errorMessage={
              data?.promo_status?.color === 'danger' && data?.voucher_code !== '' ? data?.promo_status?.message : ''
            }
            onChange={(e) => {
              if (!data?.disable_promo_code && !disabled) {
                setPromoData({ ...promoData, message: '' });
                onPromoCodeChange(e?.target?.value.toUpperCase());
              }
            }}
          />
          <Col lg={3}>
            <Button id="create-booking" color="purple" className="w-100" onClick={checkPromoCode}>
              Pakai
            </Button>
          </Col>
          <Col lg={3} />
          <Col lg={9} className="mt-2">
            {data?.promo_status?.message && (
              <>
                {data?.promo_status.color === 'success' && <span>{data?.promo_status?.message}. </span>}
                {promoData?.campaign_id && promoData?.campaign_name && (
                  <span>
                    Detail campaign <span className="promo-name">{promoData?.campaign_name ?? '-'}</span> dapat diakses
                    disini{' '}
                    <span>
                      <Link to={`/campaigns/campaign/detail/${promoData?.campaign_id ?? ''}`} target="_blank">
                        <img src={newTabIcon} height={18} className="icon-new-tab pb-1 ml-1" />
                      </Link>
                    </span>
                  </span>
                )}
              </>
            )}
          </Col>
        </FormGroup>
        {isFleetMode && data?.note !== undefined && (
          <FormGroup row>
            <Label lg={3}>Catatan</Label>
            <Label lg={9} className="text-left">
              <div>{!data?.note ? '-' : data?.note}</div>
            </Label>
          </FormGroup>
        )}
      </div>
    </React.Fragment>
  );
}

export default BookingReviseFormItem;
