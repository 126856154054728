import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Badge, Button, Col } from 'reactstrap';
import API from '@utils/API';
import { toast, ToastContainer } from 'react-toastify';
import deleteIcon from '@assets/icons/trash.svg';
import editIcon from '@assets/icons/edit.svg';
import userPermission from '@utils/userPermission';
import CustomModal from '@components/modal/CustomModal';
import ActionFilterACL from '@components/filters/ActionFilterACL';
import Loading from '@components/loading/Loading';
import AddSystemConfigurationModal from '../modal/AddSystemConfigurationModal';

const System = (props) => {
  const { data, loading, setPermissionData } = props;
  const api = new API('v2');
  const { generatePermission } = userPermission();

  const [hasCreateModal, setHasCreateModal] = useState(false);
  const [editedData, setEditedData] = useState();
  const [isAPIFetch, setIsAPIFetch] = useState(false);
  const [hasModalConfirmation, setHasModalConfirmation] = useState(false);
  const [filterText, setFilterText] = useState('');

  const allowPermission = (menu) => {
    return !!(generatePermission('acl', menu) || generatePermission('acl', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowFilter = allowPermission('all') || allowPermission('search');
  const allowCreate = allowPermission('all') || allowPermission('add');

  const modalToggle = () => setHasCreateModal(!hasCreateModal);

  const handleError = (msg) => {
    toast.error(msg, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  const handleClickPermissionRow = (id) => {
    setIsAPIFetch(true);

    api
      .get(`v2/intools/configs/${id}/`)
      .then((result) => {
        setEditedData(result?.data?.data);
        modalToggle();
        setIsAPIFetch(false);
      })
      .catch((err) => {
        console.log(err?.response);
        setIsAPIFetch(false);
        handleError(err?.response?.data?.error?.message);
      });
  };

  const handleDelete = () => {
    setIsAPIFetch(true);

    api
      .delete(`v2/intools/configs/${editedData?.key}/`)
      .then((result) => {
        setPermissionData(result?.data?.data);
        setHasModalConfirmation(false);
        setIsAPIFetch(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err?.response);
        setIsAPIFetch(false);
        handleError(err?.response?.data?.error?.message);
      });
  };

  const handleSubmit = async (params) => {
    setIsAPIFetch(true);

    if (editedData) {
      api
        .put(`v2/intools/configs/${editedData?.key}/`, params)
        .then((result) => {
          setPermissionData(result?.data?.data);
          modalToggle();
          setIsAPIFetch(false);
          setEditedData();
          window.location.reload();
        })
        .catch((err) => {
          console.log(err?.response);
          setIsAPIFetch(false);
          handleError(err?.response?.data?.error?.message);
        });

      return;
    }

    api
      .post('v2/intools/configs/', params)
      .then((result) => {
        setPermissionData(result?.data?.data);
        modalToggle();
        setIsAPIFetch(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err?.response);
        setIsAPIFetch(false);
        handleError(err?.response?.data?.error?.message);
      });
  };

  const permissionData = () => {
    const list =
      data?.length > 0
        ? data
            ?.filter(
              (dt) =>
                dt?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
                dt?.method?.toLowerCase().includes(filterText.toLowerCase()) ||
                dt?.path?.toLowerCase().includes(filterText.toLowerCase())
            )
            .map((item) => {
              item = {
                ...item,
              };
              return item;
            })
        : [];

    return list;
  };

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row) => row?.name,
        cell: (row) => row?.name,
        minWidth: '200px',
        width: '250px',
      },
      {
        name: 'Key',
        selector: (row) => row?.key,
        cell: (row) => row?.key ?? '-',
        minWidth: '100px',
      },
      {
        name: 'Platform',
        selector: (row) => row?.platform,
        cell: (row) => row?.platform ?? '-',
        minWidth: '200px',
      },
      {
        name: 'Status',
        selector: (row) => row?.is_apply,
        cell: (row) => {
          if (row?.is_apply) {
            return <Badge className="p-2 badge-success">Active</Badge>;
          } else {
            return <Badge className="p-2 badge-danger">Not Active</Badge>;
          }
        },
        width: '50px',
      },
      {
        name: 'Actions',
        cell: (row) => (
          <>
            <img
              src={editIcon}
              height={16}
              alt=""
              className="mr-3"
              onClick={() => handleClickPermissionRow(row?.key)}
            />
            <img
              src={deleteIcon}
              height={16}
              alt=""
              onClick={() => {
                setHasModalConfirmation(true);
                setEditedData(row);
              }}
            />
          </>
        ),
        right: true,
      },
    ],
    []
  );

  const createPermission = () => {
    if (allowCreate) setHasCreateModal(!hasCreateModal);
  };

  const subHeaderComponentMemo = useMemo(() => {
    return <ActionFilterACL value={filterText} handleChangeValue={setFilterText} />;
  }, [filterText, setFilterText]);

  return (
    <React.Fragment>
      <ToastContainer />
      <Col sm={12} md={12} className="p-0 mt-0 section-action">
        {allowCreate && (
          <Button className="button-action add w-170" onClick={createPermission}>
            {'Create New'}
          </Button>
        )}
      </Col>
      {allowFilter && (
        <Col sm={12} md={12} className="p-0 mt-4 mb-0">
          <ActionFilterACL value={filterText} handleChangeValue={setFilterText} />
        </Col>
      )}
      <Col sm={12} md={12} className="p-0 mt-1">
        <DataTable
          persistTableHead
          highlightOnHover
          noHeader={true}
          progressPending={loading}
          data={permissionData()}
          fixedHeaderScrollHeight="239px"
          columns={allowList ? columns : []}
          className="table dt-responsive table-custom"
          progressComponent={allowList ? <Loading /> : <></>}
        />
      </Col>

      <AddSystemConfigurationModal
        isOpen={hasCreateModal}
        toggle={modalToggle}
        onSubmit={handleSubmit}
        initialValue={editedData}
        onClosed={() => {
          setEditedData();
          setHasCreateModal(false);
        }}
        loading={isAPIFetch}
      />

      <CustomModal
        modal={hasModalConfirmation}
        toggle={() => setHasModalConfirmation(false)}
        modalHeader="Delete System Configuration"
        modalFooter={
          <>
            <Button color="danger" outline className="m-auto px-4 rounded-pill" size="lg" onClick={handleDelete}>
              Delete
            </Button>
            <Button
              color="primary"
              className="m-auto px-4 rounded-pill"
              size="lg"
              onClick={() => setHasModalConfirmation(false)}
            >
              Cancel
            </Button>
          </>
        }
      >
        Are you sure you want to delete this data?
      </CustomModal>
    </React.Fragment>
  );
};

export default System;
