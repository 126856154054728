import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import { useDropzone } from 'react-dropzone';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import CloudComputingIcon from '@assets/icons/cloud-computing.svg';

const UploadImagePo = (props) => {
  const {
    isButton = false,
    isEmpty,
    imageFile,
    maxSize = 10240,
    maxSizeString = '10MB',
    allowTypesString = 'jpeg, jpg, png',
    allowTypes = ['jpeg', 'jpg', 'png'],
    onDelete,
    onError,
    onSelectImage,
    simplified = false,
    disabled = false,
  } = props;

  const [file, setFile] = useState({});
  const [imagePreview, setImagePreview] = useState('');
  const [dragOver, setDragOver] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);

  const { getRootProps } = useDropzone({
    multiple: false,
    disabled: disabled,
    onDrop: (files) => {
      handleDropFileImage(files[0]);
      setDragOver(false);
    },
    onDragOver: () => {
      onError('');
      setDragOver(true);
    },
    onDragLeave: () => {
      setDragOver(false);
    },
    onFileDialogOpen: () => {
      onError('');
    },
  });
  const handlePreviewImage = () => {
    if (imagePreview) {
      setShowLightbox(!showLightbox);
    }
  };

  const clearFile = () => {
    setFile({});
    setImagePreview('');
  };

  const handleDropFileImage = (file) => {
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const nameFileSplit = file?.name ? file?.name.split('.') : [];

    if (nameFileSplit.length == 0) {
      onError('Format file tidak diketahui');
    } else {
      const selected = find(allowTypes, (item) => {
        return nameFileSplit[nameFileSplit.length - 1].toLowerCase() === item;
      });
      if (!selected) {
        onError('File tidak sesuai dengan format');
      } else {
        if (fileSize == null) {
          onError('Ukuran file tidak diketahui');
        } else if (fileSize > maxSize) {
          onError('Ukuran file maksimal ' + maxSizeString);
        } else {
          handleUploadImage(file);
        }
      }
    }
  };

  const handleUploadImage = (file) => {
    if (file?.name) {
      setFile(file);
      setImagePreview(URL.createObjectURL(file));
      onSelectImage(file);
    } else {
      onError('Gagal upload image, file tidak diketahui');
    }
  };

  const kbFormatted = (bytes) => {
    const kb = bytes / (1024 * 1024);
    return kb.toFixed(2) + ' MB';
  };

  useEffect(() => {
    if (isEmpty) {
      setFile({});
      setImagePreview('');
    }
  }, [isEmpty]);

  useEffect(() => {
    if (imageFile?.file) {
      setFile(imageFile?.file);
      setImagePreview(URL.createObjectURL(imageFile?.file));
    }
  }, [imageFile]);

  useEffect(() => {
    if (isButton) {
      if (file?.name !== undefined) clearFile();
    }
  }, [isButton, file]);

  return (
    <>
      {showLightbox && <Lightbox mainSrc={imagePreview} onCloseRequest={() => setShowLightbox(false)} />}
      <div className={`${simplified ? '' : 'gallery-container-workshop '}mb-0 mt-0`}>
        <>
          {imagePreview && (
            <div className="box-upload-po-preview">
              <div className="icon-trash" onClick={() => onDelete()}></div>
              <img src={imagePreview} height={71} width="auto" onClick={() => handlePreviewImage()} />
              <div className="preview">
                <div className="title">{file?.name}</div>
                <div className="detail mt-1">{kbFormatted(file?.size)}</div>
              </div>
            </div>
          )}
          {!imagePreview && (
            <div {...getRootProps({ className: dragOver ? 'box-upload-po drag-over' : 'box-upload-po' })}>
              <div>
                <div className="title">
                  <img height={20} src={CloudComputingIcon}></img>
                  Click or drag file to this area to upload
                </div>
                <div className="detail mt-1">
                  Maximum file size {maxSizeString}. {allowTypesString}
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default UploadImagePo;
