import React from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap'; // Sesuaikan dengan library yang Anda gunakan

const BookingDetailJiraTodo = (props) => {
  const { detailData } = props;

  // Tentukan jumlah maksimum tugas yang akan ditampilkan tanpa scroll
  const maxTasksToShowWithoutScroll = 5;

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Jira To Do List</CardHeader>
      <CardBody className="pb-0">
        {detailData && detailData.subtasks && detailData.subtasks.length > 0 ? (
          <div style={{ maxHeight: `${maxTasksToShowWithoutScroll * 85}px`, overflowY: 'auto' }}>
            {detailData.subtasks.map((todo, index) => (
              <Card key={todo.id}>
                <Row className="mb-2 ml-2 mt-2 mr-2">
                  <Col className="col-summary">
                    <span className="detail-group">{todo.summary}</span>
                    <span className="">{todo.assignee || '-'}</span>
                  </Col>
                  <Col className="col-summary d-flex justify-content-center">
                    <span className="text-sm-right detail-group">{todo.status}</span>
                  </Col>
                </Row>
              </Card>
            ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center mb-4">Tidak Ada Task</div>
        )}
      </CardBody>
    </Card>
  );
};

export default BookingDetailJiraTodo;
