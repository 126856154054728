import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import API from '@utils/API';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import SubscribeHeader from '@components/subscriptions/subscribe/SubscribeHeader';
import SubscribeList from '@components/subscriptions/subscribe/SubscribeList';

const Subscribe = (props) => {
  const { id, onLoading, onResultNotification } = props;
  const api = new API('v2');

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});

  async function getDetailSubscriptions() {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/customer-subscriptions/${id}`);
      mappingData(response?.data?.data ?? {});
      setLoading(false);
    } catch (error) {
      setDetailData({});
      setLoading(false);
    }
  }

  const mappingData = (data) => {
    let dummyData = data;
    if (data?.services?.length !== 0) {
      let dummyServices = [];
      data?.services.forEach((service, index) => {
        let itemCredit = { service };
        let itemRollover = { service };
        itemCredit.type = 'credit';
        itemRollover.type = service?.rollovered_credit > 0 ? 'rollover' : '';
        dummyServices.splice(index, 0, itemCredit);
        if (itemRollover.type == 'rollover') dummyServices.push(itemRollover);
      });
      dummyData.services = dummyServices;
    }

    setDetailData(dummyData);
  };

  const handleResultNotification = (e) => {
    onResultNotification(e);
  };

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    getDetailSubscriptions();
  }, [id]);

  return (
    <React.Fragment>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <Card className="card-custom">
          <CardBody className="p-0">
            <Row>
              <Col sm={12} md={12}>
                <SubscribeHeader
                  subscribeData={detailData}
                  onResultNotification={handleResultNotification}
                ></SubscribeHeader>
              </Col>
              {/* <Col sm={12} md={12}>
                <SubscribeList id={id}></SubscribeList>
              </Col> */}
            </Row>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default Subscribe;
