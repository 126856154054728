import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { assign, map } from 'lodash';
import API from '@utils/API';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PRICE_AGREEMENT_VALUE } from '@utils/Constants';
import AddCarModal from '../modal/AddCarModal';
import CustomerCar from './CustomerCar';
import ConfirmationModal from '../modal/ConfirmationModal';
import { validate } from 'react-email-validator';
import userPermission from '@utils/userPermission';
import B2BCustomerGeneralInformationForms from '@components/customer/B2BCustomerGeneralInformationForms';
import B2BCustomerSpecialSettings from '@components/customer/B2BCustomerSpecialSettings';
import B2BCustomerLegality from '@components/customer/B2BCustomerLegality';
import B2BCustomerContact from '@components/customer/B2BCustomerContact';
import B2BCustomerBillingAndSettlement from '@components/customer/B2BCustomerBillingAndSettlement';

const B2BCustomerForms = (props) => {
  const {
    id,
    origin,
    handleResultNotification,
    activeCustomerType,
    handleEditForm,
    handleChangeOrigin,
    initialValues,
    loadUserDetail,
    userCars,
    loadUserCar,
    wsList,
    loadListWorkshop,
    loadingLoadWorkshop,
    loadListPIC,
    loadingLoadPIC,
    listPIC,
    configDoc,
    listCustomerGroup,
    loadCustomerGroupOptions,
    loadingCustomerGroup,
  } = props;
  const api = new API('v2');
  const history = useHistory();
  const { generatePermission } = userPermission();

  const [formValues, setFormValues] = useState({});
  const [customerType, setCustomerType] = useState(activeCustomerType ?? 'b2c');
  const [submitStatus, setSubmitStatus] = useState(null);
  const [disableForm, setDisableForm] = useState(origin !== 'create');
  const [toggleAddCarModal, setToggleAddCarModal] = useState(false);
  const [hasModalConfirmation, setHasModalConfirmation] = useState(false);
  const [confirmationType, setConfirmationType] = useState('Simpan');
  const [hasLocationDetail, setHasLocationDetail] = useState(false);
  const [loadingSubmitGeneralInfo, setLoadingSubmitGeneralInfo] = useState(false);

  const errorToDelete = [
    'error_customer_name',
    'error_phone_number',
    'error_email',
    'error_user_type',
    'error_flag',
    'error_nickname',
    'error_pic_name',
    'error_pic_phone',
    'error_pic_email',
    'error_b2b_customer_status',
    'error_product_markup',
    'error_service_markup',
    'error_product_discount',
    'error_service_discount',
    'error_internal_notes',
    'error_workshop_blacklist',
    'error_photo',
    'error_b2b_pic',
    'error_company_address',
    'error_billing_address',
    'error_config_document',
  ];

  const mappingForm = (type = 'b2c') => {
    let dataForm = {
      photo: initialValues?.photo ?? '',
      customer_name: initialValues?.customer_name ?? '',
      phone_number: initialValues?.phone_number ?? '',
      email: initialValues?.email ?? '',
      user_type: initialValues?.user_type ?? '',
      flag: initialValues?.flag ?? '',
      error_customer_name: '',
      error_phone_number: '',
      error_email: '',
      error_user_type: '',
      error_flag: '',
    };

    if (type === 'b2b') {
      assign(dataForm, {
        nickname: initialValues?.nickname ?? '',
        pic_name: initialValues?.pic_name ?? '',
        pic_phone: initialValues?.pic_phone ?? '',
        b2b_pic: initialValues?.b2b_pic ?? '',
        b2b_customer_status: initialValues?.b2b_customer_status ?? 'trial',
        config_documents: initialValues?.config_documents ?? configDoc,
        product_markup: initialValues?.product_markup ?? DEFAULT_PRICE_AGREEMENT_VALUE,
        service_markup: initialValues?.service_markup ?? DEFAULT_PRICE_AGREEMENT_VALUE,
        product_discount: initialValues?.product_discount ?? DEFAULT_PRICE_AGREEMENT_VALUE,
        service_discount: initialValues?.service_discount ?? DEFAULT_PRICE_AGREEMENT_VALUE,
        internal_notes: initialValues?.internal_notes ?? '',
        company_address: initialValues?.company_address ?? '',
        billing_address: initialValues?.billing_address ?? '',
        customer_group: initialValues?.customer_group ?? {},
        warranty_facility: initialValues?.warranty_facility ?? [],
        terms_of_payment: initialValues?.terms_of_payment ?? {},
        virtual_account: initialValues?.virtual_account ?? '',
        error_nickname: '',
        error_pic_name: '',
        error_pic_phone: '',
        error_pic_email: '',
        error_b2b_customer_status: '',
        error_product_markup: '',
        error_service_markup: '',
        error_product_discount: '',
        error_service_discount: '',
        error_internal_notes: '',
        error_company_address: '',
        error_billing_address: '',
        error_customer_group: '',
        error_b2b_customer_status: '',
        error_warranty_facility: '',
        error_terms_of_payment: '',
        error_virtual_account: '',
        workshop_blacklist: initialValues?.workshop_blacklist ?? [],
        error_workshop_blacklist: '',
      });
    }

    setFormValues(dataForm);
  };

  const handleChangeFormValues = (key, value, errorKey, errorMsg) => {
    setFormValues((prevFormValues) => {
      const updatedFormValues = { ...prevFormValues, [key]: value };
      if (errorKey) {
        updatedFormValues[errorKey] = errorMsg;
      }
      return updatedFormValues;
    });
  };

  const handleChangeCustomerName = (e) => {
    const valueCustomerName = isB2B ? e?.target?.value : e?.target?.value.replace(/[^a-zA-Z\s]+/g, '');
    let errorMsg = valueCustomerName.length < 1 ? 'Nama tidak boleh kosong' : '';
    handleChangeFormValues('customer_name', valueCustomerName, 'error_customer_name', errorMsg);
  };

  const handleChangePhoneNumber = (e) => {
    const { name, value } = e.target;
    let phoneValue = value.replace(/[^0-9+()]/g, '').replace(/(?!^\+)\+/g, '');
    validatePhoneNumber(name, phoneValue);
  };

  const handleChangeEmail = (e) => {
    let emailValue = e?.target?.value;
    let inputName = e?.target?.name;
    validateEmail(emailValue, inputName);
  };

  const validatePhoneNumber = (name, valuePhoneNumber) => {
    let errorMsg = '';
    const validateLength = valuePhoneNumber?.length === 0;
    const validateMinLength = valuePhoneNumber?.length > 0 && valuePhoneNumber?.length < 10;
    if (isB2B && name === 'pic_phone') {
      errorMsg = validateLength ? 'No. Telepon tidak boleh kosong' : validateMinLength ? 'No. Telepon kurang dari 9 digit' : '';
    } else if (!isB2B && name === 'phone_number') {
      errorMsg = validateLength ? 'No. Telepon tidak boleh kosong' : validateMinLength ? 'No. Telepon kurang dari 9 digit' : '';
    }
    handleChangeFormValues(name, valuePhoneNumber, `error_${name}`, errorMsg);
    return !errorMsg;
  };

  const validateEmail = (valueEmail, inputName) => {
    let errorMsg = '';
    if (valueEmail?.length < 1 && isB2B) {
      errorMsg = `Email${inputName === 'email' && isB2B ? ' Perusahaan' : ''} tidak boleh kosong`;
    } else if (valueEmail?.length > 1 && isB2B && valueEmail.includes('@') && inputName === 'pic_email') {
      errorMsg = 'Email tidak valid';
    } else if (!validate(valueEmail) && !isB2B && valueEmail?.length > 0) {
      errorMsg = 'Format Email salah';
    }
    handleChangeFormValues(inputName, valueEmail, `error_${inputName}`, errorMsg);
    return !errorMsg;
  };

  const toggleModalAddCar = () => setToggleAddCarModal(!toggleAddCarModal);

  const validateForm = () => {
    const { customer_name, phone_number, pic_name, pic_phone, email, b2b_pic } = formValues;
    let status = true;

    if (!customer_name) {
      handleChangeFormValues('customer_name', customer_name, 'error_customer_name', 'Nama tidak boleh kosong');
      status = false;
    }
    if (!validateEmail(email, 'email')) status = false;
    if (customerType === 'b2c' && !validatePhoneNumber('phone_number', phone_number)) status = false;
    if (customerType === 'b2b') {
      if (!validatePhoneNumber('pic_phone', pic_phone)) status = false;
      if (!pic_name) {
        handleChangeFormValues('pic_name', pic_name, 'error_pic_name', 'PIC Name tidak boleh kosong');
        status = false;
      }
      if (!b2b_pic?.id) {
        handleChangeFormValues('b2b_pic', b2b_pic, 'error_b2b_pic', 'B2B PIC Name tidak boleh kosong');
        status = false;
      }
    }
    return status;
  };

  const handleSubmit = async () => {
    let payload = formValues;
    let notification = { status: true, type: '', message: '' };

    if (payload?.internal_notes?.length > 500) {
      notification = { ...notification, type: 'fail', message: 'Maksimal karakter internal notes adalah 500' };
      handleResultNotification(notification);
      return;
    }

    if (!validateForm()) {
      notification = { ...notification, type: 'fail', message: 'Gagal, field mandatory harus diisi!' };
      handleResultNotification(notification);
      return;
    }

    if (origin === 'edit') {
      assign(payload, { customer_id: id });
    }

    let blacklistws = [];
    if (isB2B) {
      map(payload?.workshop_blacklist, (item) => {
        blacklistws.push(item.id);
      });
    }

    map(errorToDelete, (item) => {
      delete payload[item];
    });

    const profilePicturePath = () => {
      let pict = payload?.photo?.key ?? initialValues?.photo?.replace(/(%20|%25)/g, ' ');
      if (pict?.includes('.net/')) {
        return pict.split('.net/')[1];
      }
      return pict;
    };

    payload = {
      ...payload,
      photo: profilePicturePath(),
      phone_number: payload.phone_number || null,
      customer_type: customerType,
      flag: payload.flag.value ?? 'default',
      user_type: payload.user_type.value,
      email: payload.email === '' ? null : payload.email,
    };

    if (isB2B) {
      assign(payload, {
        pic_phone: payload.pic_phone || null,
        b2b_pic: payload.b2b_pic.id,
        config_documents: payload.config_documents,
        product_discount: payload.product_discount.value,
        product_markup: payload.product_markup.value,
        service_discount: payload.service_discount.value,
        service_markup: payload.service_markup.value,
        blacklisted_workshops: blacklistws.toString(),
      });
    }

    const apiCall = origin === 'create' ? api.post('v2/intools/customers/', payload) : api.post(`v2/intools/customers/edit/?=${id}`, payload);

    apiCall
      .then((res) => {
        notification = { ...notification, type: 'success', message: 'Sukses' };
        handleResultNotification(notification).then(() => {
          setTimeout(() => {
            history.push(`/customer/${res.data.data.id}`);
          }, 1000);
        });
      })
      .catch((err) => {
        let errRes = err.response.data.error.message;
        notification = { ...notification, type: 'fail', message: `Gagal, ${errRes}` };
        handleResultNotification(notification);
      });
  };

  const handleEditGeneralInfo = async (editSuccess, editFailed) => {
    setLoadingSubmitGeneralInfo(true);
    let payload = formValues;
    let notification = { status: true, type: '', message: '' };

    if (!validateForm()) {
      notification = { ...notification, type: 'fail', message: 'Gagal, field mandatory harus diisi!' };
      handleResultNotification(notification);
      setLoadingSubmitGeneralInfo(false);
      editFailed();
      return;
    }

    if (origin === 'edit') {
      assign(payload, { customer_id: id });
    }

    map(errorToDelete, (item) => {
      delete payload[item];
    });

    const profilePicturePath = () => {
      let pict = payload?.photo?.key ?? initialValues?.photo?.replace(/(%20|%25)/g, ' ');
      if (pict?.includes('.net/')) {
        return pict.split('.net/')[1];
      }
      return pict;
    };

    payload = {
      ...payload,
      customer_id: id,
      photo: profilePicturePath(),
      phone_number: payload.phone_number || null,
      customer_type: customerType,
      flag: payload.flag.value ?? 'default',
      user_type: payload.user_type.value,
      email: payload.email === '' ? null : payload.email,
    };

    assign(payload, {
      pic_phone: payload.pic_phone || null,
      b2b_pic: payload.b2b_pic.id,
      config_documents: payload.config_documents,
      product_discount: payload.product_discount.value,
      product_markup: payload.product_markup.value,
      service_discount: payload.service_discount.value,
      service_markup: payload.service_markup.value,
    });

    api
      .post(`v2/intools/customers/edit/?=${id}`, payload)
      .then((res) => {
        setDisableForm(true);
        notification = { ...notification, type: 'success', message: 'Sukses' };
        handleResultNotification(notification, () => handleEdit());
        setSubmitStatus(true);
        setLoadingSubmitGeneralInfo(false);
        handleChangeOrigin(origin === 'detail' ? 'edit' : 'detail');
        editSuccess(res);
        setTimeout(() => {
          setSubmitStatus(false);
        }, 3000);
      })
      .catch((err) => {
        let errRes = err.response.data.error.message || 'Gagal update detail user.';
        if (errRes?.toLowerCase().includes('duplicate entry')) {
          errRes = 'No telpon sudah digunakan';
        }
        notification = { ...notification, type: 'fail', message: `Gagal, ${errRes}` };
        handleResultNotification(notification);
        setSubmitStatus(true);
        setLoadingSubmitGeneralInfo(false);
        editFailed();
      });
  };

  const handleEdit = () => {
    if (submitStatus) {
      loadUserDetail();
    } else {
      if (origin === 'edit') loadUserDetail();
      handleEditForm(customerType);
    }
    handleChangeOrigin(origin === 'detail' ? 'edit' : 'detail');
    setDisableForm(!disableForm);
  };

  const handleCancel = () => {
    if (origin === 'create') {
      history.push('/customer');
    } else {
      handleEdit();
    }
  };

  const toggleModalConfirmation = (status) => {
    setConfirmationType(status);
    setHasModalConfirmation(!hasModalConfirmation);
  };

  const allowPermission = (menu, type = 'page') => {
    const allowAccess = !!(generatePermission('customer', menu) || generatePermission('customer', 'all'));
    if (!allowAccess && type !== 'element') return history.push('/404');
    if (menu === 'location' && allowAccess) setHasLocationDetail(!hasLocationDetail);
    return allowAccess;
  };

  useEffect(() => {
    if (customerType) mappingForm(customerType);
  }, [customerType]);

  useEffect(() => {
    allowPermission('location', 'element');
  }, []);

  const isB2B = customerType === 'b2b';
  const allowShowCar = allowPermission('usercarshow', 'element') || allowPermission('usercarall', 'element');
  const allowListCar = allowPermission('usercarlist', 'element') || allowPermission('usercarall', 'element');
  const allowAddCar = allowPermission('usercaradd', 'element') || allowPermission('usercarall', 'element');

  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <B2BCustomerGeneralInformationForms
            isB2B={isB2B}
            formValues={formValues}
            customerType={customerType}
            handleChangeCustomerType={(e) => setCustomerType(e.target.value)}
            handleChangeCustomerName={handleChangeCustomerName}
            handleChangeNickname={(e) => handleChangeFormValues('nickname', e.target.value, 'error_nickname', '')}
            handleChangePICName={(e) => handleChangeFormValues('pic_name', e.target.value, 'error_pic_name', e.target.value.length < 1 ? 'PIC Name tidak boleh kosong' : '')}
            handleChangePhoneNumber={handleChangePhoneNumber}
            handleChangeEmail={handleChangeEmail}
            handleChangeStatus={(e) => handleChangeFormValues('b2b_customer_status', e.value, 'error_b2b_customer_status', '')}
            handleChangeUserType={(e) => handleChangeFormValues('user_type', e, 'error_user_type', '')}
            handleChangeFlag={(e) => handleChangeFormValues('flag', e, 'error_flag', '')}
            handleChangeBlacklistWorkshop={(value) => handleChangeFormValues('workshop_blacklist', value, 'error_workshop_blacklist', '')}
            handleChangeInternalNotes={(e) => handleChangeFormValues('internal_notes', e.target.value, 'error_internal_notes', e.target.value.length > 500 ? 'Maksimal karakter adalah 500' : '')}
            handleChangeImage={(image) => handleChangeFormValues('photo', image, 'error_photo', '')}
            handleErrorImage={(errMsg) => handleResultNotification({ status: true, type: 'fail', message: errMsg ?? 'error!' })}
            handleChangeDocument={(e) => handleChangeFormValues('config_documents', e, 'error_config_document', '')}
            handleChangePIC={(e) => handleChangeFormValues('b2b_pic', e, 'error_b2b_pic', '')}
            handleChangeCompanyAddress={(e) => handleChangeFormValues('company_address', e.target.value, 'error_company_address', e.target.value.length > 500 ? 'Maksimal karakter adalah 500' : '')}
            wsList={wsList}
            origin={origin}
            loadWorkshop={loadListWorkshop}
            loadingLoadWorkshop={loadingLoadWorkshop}
            loadPIC={loadListPIC}
            loadingLoadPIC={loadingLoadPIC}
            listPIC={listPIC}
            listCustomerGroup={listCustomerGroup}
            loadCustomerGroupOptions={loadCustomerGroupOptions}
            loadingCustomerGroup={loadingCustomerGroup}
            allowEdit={allowPermission('edit', 'element')}
            handleChangeCustomerGroup={(value) => handleChangeFormValues('customer_group', value, 'error_customer_group', '')}
            handleSubmit={handleEditGeneralInfo}
            loadingSubmit={loadingSubmitGeneralInfo}
          />

          <B2BCustomerSpecialSettings
            formValues={formValues}
            allowEdit={allowPermission('edit', 'element')}
            handleSubmitEdit={(data, onSuccess, onError) => {
              let notification = { status: true, type: '', message: '' };
              const customerPayload = {
                customer_id: id,
                ...Object.fromEntries(Object.entries(data).filter(([key]) => !key.startsWith('error_'))),
              };

              if (customerPayload?.internal_notes?.length > 500) {
                handleResultNotification({
                  status: true,
                  type: 'fail',
                  message: 'Maksimal karakter internal notes adalah 500',
                });
                return;
              }

              const blacklistedWorkshops = customerPayload.workshop_blacklist?.map((item) => item.id).toString() || '';

              const finalPayload = {
                ...customerPayload,
                phone_number: data.phone_number || null,
                pic_phone: data.pic_phone || null,
                customer_type: 'b2b',
                flag: customerPayload.flag?.value ?? 'default',
                user_type: customerPayload.user_type?.value,
                email: customerPayload.email === '' ? null : customerPayload.email,
                blacklisted_workshops: blacklistedWorkshops,
                b2b_pic: customerPayload.b2b_pic?.id,
                config_documents: customerPayload.config_documents,
                product_discount: customerPayload.product_discount?.value,
                product_markup: customerPayload.product_markup?.value,
                service_discount: customerPayload.service_discount?.value,
                service_markup: customerPayload.service_markup?.value,
              };

              api
                .post(`v2/intools/customers/edit/?=${id}`, finalPayload)
                .then((res) => {
                  onSuccess(res);
                  handleResultNotification({
                    ...notification,
                    type: 'success',
                    message: 'Sukses',
                  });
                })
                .catch((err) => {
                  let errRes = err.response.data.error.message || 'Gagal update detail user.';
                  onError(err);
                  handleResultNotification({
                    ...notification,
                    type: 'fail',
                    message: `Gagal, ${errRes}`,
                  });
                });
            }}
            setFormValues={setFormValues}
          />

          <B2BCustomerLegality
            id={id}
            allowEdit={allowPermission('edit', 'element')}
            handleResultNotification={handleResultNotification}
          />
        </Col>
        <Col sm={12} md={6}>
          <B2BCustomerContact
            id={id}
            allowEdit={allowPermission('edit', 'element')}
            handleResultNotification={handleResultNotification}
          />

          <B2BCustomerBillingAndSettlement
            id={id}
            formValues={formValues}
            setFormValues={setFormValues}
            disableForm={disableForm}
            allowEdit={allowPermission('edit', 'element')}
            handleSubmitEdit={(data, onSuccess, onError) => {
              let notification = { status: true, type: '', message: '' };
              const customerPayload = {
                customer_id: id,
                ...Object.fromEntries(Object.entries(data).filter(([key]) => !key.startsWith('error_'))),
              };

              if (customerPayload?.internal_notes?.length > 500) {
                handleResultNotification({
                  status: true,
                  type: 'fail',
                  message: 'Maksimal karakter internal notes adalah 500',
                });
                return;
              }

              const blacklistedWorkshops = customerPayload.workshop_blacklist?.map((item) => item.id).toString() || '';

              const finalPayload = {
                ...customerPayload,
                phone_number: data.phone_number || null,
                pic_phone: data.pic_phone || null,
                customer_type: 'b2b',
                flag: customerPayload.flag?.value ?? 'default',
                user_type: customerPayload.user_type?.value,
                email: customerPayload.email === '' ? null : customerPayload.email,
                blacklisted_workshops: blacklistedWorkshops,
                b2b_pic: customerPayload.b2b_pic?.id,
                config_documents: customerPayload.config_documents,
                product_discount: customerPayload.product_discount?.value,
                product_markup: customerPayload.product_markup?.value,
                service_discount: customerPayload.service_discount?.value,
                service_markup: customerPayload.service_markup?.value,
              };

              api
                .post(`v2/intools/customers/edit/?=${id}`, finalPayload)
                .then((res) => {
                  onSuccess(res);
                  handleResultNotification({
                    ...notification,
                    type: 'success',
                    message: 'Sukses',
                  });
                })
                .catch((err) => {
                  let errRes = err.response.data.error.message || 'Gagal update detail user.';
                  onError(err);
                  handleResultNotification({
                    ...notification,
                    type: 'fail',
                    message: `Gagal, ${errRes}`,
                  });
                });
            }}
          />

          {allowShowCar && (
            <div className="mb-4">
              <CustomerCar
                origin={origin}
                cars={userCars}
                toggleModalAddCar={toggleModalAddCar}
                allowListCar={allowListCar}
                allowAddCar={allowAddCar}
              />
            </div>
          )}
        </Col>
      </Row>

      <AddCarModal
        userId={id}
        modal={toggleAddCarModal}
        toggle={toggleModalAddCar}
        loadUserCar={loadUserCar}
        handleResultNotification={handleResultNotification}
        allowListCar={allowListCar}
      />

      <ConfirmationModal
        toggle={toggleModalConfirmation}
        toggleClose={toggleModalConfirmation}
        onConfirm={() => {
          if (confirmationType === 'Simpan') {
            handleSubmit();
          } else {
            handleEdit();
            mappingForm(customerType);
          }
          toggleModalConfirmation();
        }}
        modal={hasModalConfirmation}
        header={`${confirmationType} Hasil Edit Detail User?`}
        subHeader={`${confirmationType} hasil edit Detail User atas nama ${formValues?.customer_name}?`}
      />
    </>
  );
};

export default B2BCustomerForms;
