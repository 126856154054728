import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import API from '@utils/API';

const CreateCarModelModal = (props) => {
  const api = new API('v2');
  const { modal, toggle, carBrand, onSuccess } = props;

  const [uploadImage, setUploadImage] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorUploadImageMessage, setErrorUploadImageMessage] = useState('');

  const onUpload = (file) => {
    const image = file[0];

    if (image.size / 1024 / 1024 <= 2) {
      setUploadImage(image);
      setErrorUploadImageMessage('');
    } else {
      setUploadImage(null);
      setErrorUploadImageMessage('Ukuran file tidak boleh lebih dari 2MB');
    }
  };

  const handleSubmit = () => {
    setDisabledButton(true);
    const dataArray = new FormData();

    dataArray.append('name', formValues?.name);
    dataArray.append('brand_id', carBrand?.id);
    dataArray.append('image', uploadImage);

    api
      .post(`v2/intools/car-models/`, dataArray, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setErrorMessage('');
        setErrorUploadImageMessage('');
        setFormValues({});
        setUploadImage(null);
        setDisabledButton(false);
        onSuccess(response?.data?.data);
      })
      .catch((error) => {
        setErrorUploadImageMessage('');
        setErrorMessage('Gagal Menambahkan Car Model.');
        setDisabledButton(false);
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
      });
  };

  const handleChangeName = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const toggleCarModelModal = () => {
    setFormValues({});
    setUploadImage(null);
    toggle('close');
  };

  const isSubmitDisabled = disabledButton || !formValues?.name || !uploadImage;

  return (
    <Modal className="modal-dialog-centered" isOpen={modal} toggle={() => toggleCarModelModal()}>
      <ModalHeader>Tambah Model Baru</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label sm={4} className="required">
              Brand Name
            </Label>
            <Col sm={8}>
              <h6>{carBrand?.name || '-'}</h6>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} className="required">
              Car Model
            </Label>
            <Col sm={8}>
              <Input type="text" name="name" value={formValues?.name} onChange={(e) => handleChangeName(e)} required />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4} className="required">
              Image
            </Label>
            <Col sm={8} className="align-self-center">
              <Input
                type="file"
                name="Upload"
                accept="image/png, image/jpeg, image/jpg"
                disabled={disabledButton}
                onChange={(e) => onUpload(e.target.files)}
              />
              {errorUploadImageMessage && <div className="pt-1 text-danger">{errorUploadImageMessage}</div>}
            </Col>
          </FormGroup>

          {errorMessage && <div className={`alert alert-danger`}>{errorMessage}</div>}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" size="lg" disabled={disabledButton} onClick={() => toggleCarModelModal()}>
          Tutup
        </Button>{' '}
        <Button color="primary" size="lg" disabled={isSubmitDisabled} onClick={handleSubmit}>
          Tambah
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateCarModelModal;
