import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import filter from 'lodash/filter';
import Wrapper from '@components/wrapper/Wrapper';
import CampaignDetails from '@components/campaigns/campaign/CampaignDetail';

const CampaignDetail = () => {
  const { slug, id } = useParams();

  const [activeSection, setActiveSection] = useState({});

  const sectionData = [
    {
      key: 'campaign',
      label: 'Detail Campaign',
      url: '/campaign?activetab=campaign',
    },
  ];

  const checkAvailableSection = () => {
    const availableSection =
      sectionData?.length &&
      filter(sectionData, (item) => {
        return item?.key == slug;
      });

    setActiveSection(availableSection?.length ? availableSection[0] : {});
  };

  useEffect(() => {
    checkAvailableSection();
  }, [slug]);

  return (
    <React.Fragment>
      {!activeSection?.key && <Wrapper className="wrapperContent"></Wrapper>}
      {activeSection?.key == 'campaign' && (
        <CampaignDetails activeSection={activeSection} idCampaign={id}></CampaignDetails>
      )}
    </React.Fragment>
  );
};

export default CampaignDetail;
