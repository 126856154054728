import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import DownloadIcon from '@assets/icons/download.svg';

const PreviewDownload = (props) => {
  const { previewURL, previewName } = props;

  const [showLightbox, setShowLightbox] = useState(false);
  const [tooltip, setTooltip] = useState(false);

  const toggleTooltip = () => {
    setTooltip(!tooltip);
  };

  const handleDownload = () => {
    window.open(previewURL, '_blank');
  };

  return (
    <>
      {showLightbox && (
        <Lightbox
          toolbarButtons={[
            <>
              <div key={1} id="download-image" onClick={() => handleDownload()}>
                <img className="icon-download" src={DownloadIcon}></img>
              </div>
              <Tooltip placement={'bottom'} isOpen={tooltip} target={'download-image'} toggle={toggleTooltip}>
                Download Image
              </Tooltip>
            </>,
          ]}
          mainSrc={previewURL}
          onCloseRequest={() => setShowLightbox(false)}
        />
      )}
      <div className="text-bold text-purple-2" style={{ paddingTop: '10px' }} onClick={() => setShowLightbox(true)}>
        <span>{previewName}</span>
      </div>
    </>
  );
};

export default PreviewDownload;
