import React from 'react';
import { useState } from 'react';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { find } from 'lodash';
import TrashIcon from '@assets/icons/trash.svg';

const UploadCSVOtoprotectWarrantyModal = (props) => {
  const { toggle, isOpen, onSubmit, loading } = props;

  const [formData, setFormData] = useState('');
  const [isDragOver, setIsDragOver] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [resultMode, setResultMode] = useState(false);

  const { getRootProps } = useDropzone({
    multiple: false,
    disabled: loading,
    onDrop: (files) => {
      handleDropFile(files[0]);
      setIsDragOver(false);
    },
    onDragOver: () => {
      removeErrorDocument();
      setIsDragOver(true);
    },
    onDragLeave: () => {
      setIsDragOver(false);
    },
    onFileDialogOpen: () => {
      removeErrorDocument();
    },
  });

  const removeErrorDocument = () => {
    if (formData?.error_document !== '') {
      const tempData = { ...formData };
      tempData.error_document = '';
      setFormData({ ...tempData });
    }
  };

  const handleDropFile = (data) => {
    const formdt = { ...formData };
    const file = data;
    const maxSize = 5048;
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const allowTypes = ['csv'];
    const nameFileSplit = file?.name ? file?.name.split('.') : [];

    if (nameFileSplit.length == 0) {
      formdt.error_document = 'Format file tidak diketahui';
      setFormData({ ...formdt });
    } else {
      const selected = find(allowTypes, (item) => {
        return nameFileSplit[nameFileSplit.length - 1].toLowerCase().toString() === item;
      });

      if (!selected) {
        formdt.error_document = 'File tidak sesuai dengan format';
        setFormData({ ...formdt });
      } else {
        if (fileSize == null) {
          formdt.error_document = 'Ukuran file tidak diketahui';
          setFormData({ ...formdt });
        } else if (fileSize > maxSize) {
          formdt.error_document = 'Ukuran file maksimal 5MB';
          setFormData({ ...formdt });
        } else {
          const fileUrl = URL.createObjectURL(file);
          formdt.error_document = '';
          formdt.document_name = file?.name ?? '';
          formdt.document_url = fileUrl ?? '';
          formdt.document_file = file ?? '';
          setFormData({ ...formdt });
        }
      }
    }
  };

  const handleDeleteDocument = () => {
    if (!loading) {
      defaultDataForm();
    }
  };

  const handleSubmit = () => {
    if (typeof onSubmit == 'function') {
      onSubmit(formData);
    }
  };

  const defaultDataForm = () => {
    const dataForm = {
      document_name: '',
      document_url: '',
      document_file: '',
      document_key: null,
      error_document: '',
    };

    setFormData(dataForm);
  };

  const onExit = () => {
    defaultDataForm();
  };

  return (
    <Modal toggle={toggle} isOpen={isOpen} centered onClosed={onExit}>
      <ModalBody>
        <Row>
          <Col>
            <h3>Unggah File</h3>
          </Col>
        </Row>
        <Row className="p-2 pt-3">Upload Bulk CSV File untuk mendaftarkan customer Otoprotect Warranty di sini.</Row>
        <Row className="mb-3">
          <Col lg={12} className="text-left text-bold">
            {formData?.document_name && (
              <div className="document-box-workshop">
                <span className="file">{formData?.document_name}</span>
                <img className="icon-trash-workshop" src={TrashIcon} onClick={handleDeleteDocument} />
              </div>
            )}
            {!formData?.document_name && (
              <div>
                {isDragOver && (
                  <div {...getRootProps({ className: 'document-box-workshop drag-over cursor-pointer' })}>
                    <span className="choose-file">Release File</span>
                  </div>
                )}
                {!isDragOver && (
                  <div {...getRootProps({ className: 'document-box-workshop cursor-pointer' })}>
                    <span className="choose-file">Drag and Drop or Choose File</span>
                  </div>
                )}
              </div>
            )}
            <div className="invalid-document">{formData?.error_document}</div>
            <div className="document-guide">Hanya mendukung file CSV (max. 5 MB)</div>
          </Col>
        </Row>

        <Row className="p-2 pt-0 d-flex align-items-center">
          Download sample file{' '}
          <Button
            color="link"
            className="p-0 m-0 pl-1"
            onClick={() =>
              window.open(
                'https://docs.google.com/spreadsheets/d/1VjNv2eUSBMCHKP1tw5op-_oi1s-1mURL4g2bdfccZVQ/edit?usp=sharing',
                '_blank'
              )
            }
          >
            di sini
          </Button>
          .
        </Row>

        <Row>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Button color="danger" outline onClick={toggle} block disabled={loading}>
              Kembali
            </Button>
          </Col>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Button color="primary" onClick={handleSubmit} block disabled={loading}>
              Unggah
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default UploadCSVOtoprotectWarrantyModal;
