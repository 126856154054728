import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Label, Row, Button } from 'reactstrap';
import map from 'lodash/map';
import API from '@utils/API';
import ValidHttpUrl from 'is-valid-http-url';
import userPermission from '@utils/userPermission';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import LoadingSpinner from '@components/loading/LoadingSpinner';

const Bank = (props) => {
  const { id, onChangeAction, isValidationForm, onResultValidationForm, onResultNotification, onLoading } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const [dataBank, setDataBank] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [bankOptions, setBankOptions] = useState([]);
  const [disableForm, setDisableForm] = useState(true);
  const [formData, setFormData] = useState({});

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'all'));
  };
  const allowEdit = allowPermission('bankaccountedit');

  const paymentTermOptions = [
    {
      value: 1,
      label: 'Normal',
    },
    {
      value: 2,
      label: 'DP',
    },
    {
      value: 3,
      label: 'Same-day payment',
    },
  ];
  async function fetchBankOptions() {
    try {
      const response = await api.get(`v2/intools/workshops/banks/`);
      const options = map(response?.data?.data, (option) => {
        return { label: option.name, value: option.code };
      });
      setBankOptions(options);
    } catch (error) {
      setBankOptions([]);
    }
  }

  async function getBank() {
    setLoading(true);
    setDisableForm(true);
    try {
      const response = await api.get(`v2/intools/workshops/${id}/bank-account/`);
      setDataBank(response?.data?.data ?? {});
      setLoading(false);
      setDisableForm(false);
    } catch (error) {
      setDataBank([]);
      setLoading(false);
      setDisableForm(false);
    }
  }

  const defaultDataForm = () => {
    const dataForm = {
      id_bank: dataBank?.bank_accounts?.length > 0 ? dataBank.bank_accounts[0].id : null,
      bank: dataBank?.bank_accounts?.length > 0 ? dataBank.bank_accounts[0].bank : null,
      account_holder: dataBank?.bank_accounts?.length > 0 ? dataBank.bank_accounts[0].account_holder : null,
      account_number: dataBank?.bank_accounts?.length > 0 ? dataBank.bank_accounts[0].account_number : null,
      recap_payment: dataBank?.recap_payment_folder ? dataBank.recap_payment_folder : null,
      payment_term: dataBank?.payment_term ? dataBank.payment_term : null,
      error_bank: '',
      error_account_holder: '',
      error_account_number: '',
      error_recap_payment: '',
    };

    setFormData(dataForm);
  };

  const setChangeValueForm = (key, value, errorKey, errorMsg) => {
    formData[key] = value;
    if (errorKey) formData[errorKey] = errorMsg;
    setFormData({ ...formData });
  };

  const handleChangeBank = (e) => {
    validateBank(e?.value ? e : null);
  };

  const handleChangeAccountHolder = (e) => {
    validateAccountHolder(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.account_holder);
  };

  const handleChangeAccountNumber = (e) => {
    let value = e?.target?.value.replace(/[^0-9]/g, '');
    value.replace(/(?!^\+)\+/g, '');
    validateAcountNumber(value);
  };

  const handleChangeRecapPayment = (e) => {
    validateRecapPayment(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.recap_payment);
  };

  const handleChangePaymentTerm = (e) => {
    setChangeValueForm('payment_term', e);
  };

  const validateBank = (value) => {
    setChangeValueForm('bank', value, 'error_bank', !value?.value ? 'Pilih Bank terlebih dahulu' : '');
    return !value?.value ? false : true;
  };

  const validateAccountHolder = (value) => {
    const errorMsg =
      value?.length == 0
        ? 'Nama Pemilik Rekeking tidak boleh kosong'
        : value?.length > 50
        ? 'Format Nama Pemilik Rekening maksimal 50 karakter'
        : '';

    setChangeValueForm('account_holder', value, 'error_account_holder', errorMsg);
    return errorMsg ? false : true;
  };

  const validateAcountNumber = (value) => {
    const errorMsg =
      value?.length == 0
        ? 'Nomor Rekening tidak boleh kosong'
        : value?.length < 8 || value?.length > 20
        ? 'Format Nomor Rekening minimal 8, maksimal 20 karakter'
        : '';

    setChangeValueForm('account_number', value, 'error_account_number', errorMsg);
    return errorMsg ? false : true;
  };

  const validateRecapPayment = (value) => {
    const errorMsg = value?.length > 50 ? 'Format Nama Folder Rekap Payment maksimal 50 karakter' : '';

    setChangeValueForm('recap_payment', value, 'error_recap_payment', errorMsg);
    return errorMsg ? false : true;
  };

  const validateForm = () => {
    const isPassBank = validateBank(formData?.bank ?? null);
    const isPassAccountHolder = validateAccountHolder(formData?.account_holder ?? '');
    const isPassAccountNumber = validateAcountNumber(formData?.account_number ?? '');
    const isPassRecapPayment = validateRecapPayment(formData?.recap_payment ?? '');

    return isPassBank && isPassAccountHolder && isPassAccountNumber && isPassRecapPayment ? true : false;
  };

  const goToLink = (link) => {
    if (ValidHttpUrl(link)) {
      window.open(`${link}`, '_blank');
    }
  };

  const handleGetPayload = () => {
    const payload = {
      bank_account: [
        {
          id: formData?.id_bank ?? null,
          workshop_id: id,
          bank: formData?.bank?.value ?? null,
          account_name: formData?.account_holder ?? '',
          account_number: formData?.account_number ?? '',
        },
      ],
      recap_payment_folder: formData?.recap_payment ?? '',
      payment_term: formData?.payment_term?.value ?? null,
    };

    return payload;
  };

  const handleAction = (type) => {
    if (isEdit) {
      if (type == 'cancel') {
        defaultDataForm();
        setIsEdit(false);
        onChangeAction(false, 'bank');
        handleResultNotification(false);
      } else {
        handleSubmitForm('submit');
      }
    } else {
      setIsEdit(true);
      onChangeAction(true, 'bank');
    }
  };

  const handleSubmitForm = (type) => {
    const isValid = validateForm();
    if (isValid) {
      setDisableForm(true);
      submitFormBank(type);
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
  };

  const handleValidateForm = () => {
    const isValid = validateForm();
    if (isValid) {
      handleSubmitForm();
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
    onResultValidationForm(isValid);
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  async function submitFormBank(type) {
    const payload = handleGetPayload();
    try {
      const response = await api.put(`v2/intools/workshops/${id}/bank-account/`, payload);
      handleResultNotification(true, 'success', 'Berhasil update rekening bank');
      setIsEdit(false);
      setDisableForm(false);
      setDataBank(response?.data?.data ?? {});
      if (type == 'submit') {
        onChangeAction(false, 'bank');
      }
    } catch (error) {
      setDisableForm(false);
      handleResultNotification(true, 'fail', 'Gagal update rekening bank');
    }
  }

  useEffect(() => {
    defaultDataForm();
  }, [dataBank]);

  useEffect(() => {
    if (isValidationForm) {
      handleValidateForm();
    }
  }, [isValidationForm]);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    getBank();
    fetchBankOptions();
  }, []);

  return (
    <React.Fragment>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <Card className="card-custom">
          <CardBody className="p-0">
            <Row>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="label-required text-left">
                    Bank
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{formData?.bank?.label ?? '-'}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <SelectField
                      colWidth={6}
                      name="bank"
                      placeholder={'Pilih Bank'}
                      disabled={disableForm}
                      closeMenuOnSelect={true}
                      options={bankOptions}
                      value={formData?.bank}
                      errorMessage={formData?.error_bank}
                      onChange={handleChangeBank}
                    />
                  )}
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="label-required text-left">
                    Nama Pemilik Rekening
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{formData?.account_holder ?? '-'}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <InputField
                      colWidth={6}
                      inputType={'text'}
                      disabled={disableForm}
                      value={formData?.account_holder}
                      invalid={formData?.error_account_holder ? true : false}
                      errorMessage={formData?.error_account_holder ?? ''}
                      placeholder={'Nama Pemilik Rekening'}
                      onChange={handleChangeAccountHolder}
                    ></InputField>
                  )}
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="label-required text-left">
                    Nomor Rekening
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      : <span className="ml-1">{formData?.account_number ?? '-'}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <InputField
                      colWidth={6}
                      inputType={'text'}
                      disabled={disableForm}
                      value={formData?.account_number}
                      invalid={formData?.error_account_number ? true : false}
                      errorMessage={formData?.error_account_number ?? ''}
                      placeholder={'Nomor Rekening'}
                      onChange={handleChangeAccountNumber}
                    ></InputField>
                  )}
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Folder Rekap Payment
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      :{' '}
                      <span
                        className={ValidHttpUrl(formData?.recap_payment ?? '') ? 'ml-1 document-workshop' : 'ml-1'}
                        onClick={() => goToLink(formData?.recap_payment ?? '')}
                      >
                        {!formData?.recap_payment ? '-' : formData?.recap_payment}
                      </span>
                    </Label>
                  )}
                  {isEdit && (
                    <InputField
                      colWidth={6}
                      inputType={'text'}
                      disabled={disableForm}
                      value={formData?.recap_payment}
                      invalid={formData?.error_recap_payment ? true : false}
                      errorMessage={formData?.error_recap_payment ?? ''}
                      placeholder={'Folder Rekap Payment'}
                      onChange={handleChangeRecapPayment}
                    ></InputField>
                  )}
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Row className="mb-3">
                  <Label lg={4} className="text-left">
                    Payment Term
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      :{' '}
                      <span className="ml-1">
                        {!formData?.payment_term?.label ? '-' : formData?.payment_term?.label}
                      </span>
                    </Label>
                  )}
                  {isEdit && (
                    <SelectField
                      colWidth={6}
                      name="payment_term"
                      placeholder={'Pilih Payment term'}
                      disabled={disableForm}
                      closeMenuOnSelect={true}
                      options={paymentTermOptions}
                      value={formData?.payment_term}
                      onChange={handleChangePaymentTerm}
                    />
                  )}
                </Row>
              </Col>
              {allowEdit && (
                <Col sm={12} md={12} className="section-action">
                  <Button
                    disabled={disableForm}
                    className="button-action save"
                    onClick={() => {
                      if (!disableForm) {
                        handleAction('save');
                      }
                    }}
                  >
                    {isEdit ? 'Simpan' : 'Edit Data'}
                  </Button>
                  {isEdit && (
                    <Button
                      disabled={disableForm}
                      className="button-action cancel"
                      onClick={() => {
                        if (!disableForm) {
                          handleAction('cancel');
                        }
                      }}
                    >
                      {'Batal'}
                    </Button>
                  )}
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default Bank;
