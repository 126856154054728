import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { validate } from 'react-email-validator';
import { Card, CardBody, Col, Label, Row, Button } from 'reactstrap';
import userPermission from '@utils/userPermission';
import DividerNoLabel from '@components/divider/DividerNoLabel';
import API from '@utils/API';
import map from 'lodash/map';
import StakeholderAddAdmins from '@components/workshops/StakeholderAddAdmins';
import StakeholderAddOwners from '@components/workshops/StakeholderAddOwners';
import LoadingSpinner from '@components/loading/LoadingSpinner';

const Stakeholder = (props) => {
  const { id, isValidationForm, onResultValidationForm, onLoading, onChangeAction, onResultNotification } = props;
  const api = new API('v2');
  const { generatePermission } = userPermission();
  const [isEdit, setIsEdit] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [originalValues, setOriginalValues] = useState({});
  const [owners, setOwners] = useState();
  const [admins, setAdmins] = useState();
  const [loading, setLoading] = useState(false);
  const [addAdminsDisable, setAddAdminsDisable] = useState(false);
  const [addOwnersDisable, setAddOwnersDisable] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'all'));
  };

  const allowEdit = allowPermission('stakeholderedit');

  async function getStakeholders() {
    setLoading(true);

    try {
      const response = await api.get(`v2/intools/workshops/${id}/stakeholder/`);
      if (response?.data?.data) {
        setInitialValues(response?.data?.data ?? {});
      } else {
        setInitialValues({});
        setDisableButton(true);
      }
      setLoading(false);
    } catch (error) {
      handleResultNotification(true, 'fail', error.message);
      setInitialValues({});
      setDisableButton(true);
      setLoading(false);
    }
  }

  const setInitialValues = (value) => {
    setOriginalValues(value);
  };

  const defaultData = () => {
    if (originalValues?.admins?.length > 0) {
      const dataAdmin = map(originalValues?.admins, (item) => {
        let phoneValue = item?.mobile_no?.replace(/[^0-9]/g, '');
        phoneValue?.replace(/(?!^\+)\+/g, '');
        return {
          id: item.id,
          admin_name: item.name,
          admin_phone: phoneValue,
          is_deleted: false,
          error_message_name: '',
          error_message_phone: '',
        };
      });
      setAdmins(dataAdmin);
      checkDataAdmin(dataAdmin);
      setAddAdminsDisable(false);
    } else {
      const dataAdmin = [
        { admin_name: '', admin_phone: '', is_deleted: false, error_message_name: '', error_message_phone: '' },
      ];
      setAdmins(dataAdmin);
      setAddAdminsDisable(true);
      checkDataAdmin(dataAdmin);
    }

    if (originalValues?.owners?.length > 0) {
      const dataOwner = map(originalValues?.owners, (item) => {
        let phoneValue = item?.mobile_no?.replace(/[^0-9]/g, '');
        phoneValue?.replace(/(?!^\+)\+/g, '');
        return {
          id: item.id,
          owner_name: item.name,
          owner_phone: phoneValue,
          owner_email: item.email,
          is_deleted: false,
          error_message_name: '',
          error_message_phone: '',
          error_message_email: '',
        };
      });
      setOwners(dataOwner);
      checkDataOwner(dataOwner);
      setAddOwnersDisable(false);
    } else {
      const dataOwner = [
        {
          owner_name: '',
          owner_phone: '',
          owner_email: '',
          is_deleted: false,
          error_message_name: '',
          error_message_phone: '',
          error_message_email: '',
        },
      ];
      setOwners(dataOwner);
      setAddOwnersDisable(true);
      checkDataOwner(dataOwner);
    }
  };

  const handleAction = (type) => {
    if (isEdit && type == 'cancel') {
      defaultData();
      setIsEdit(!isEdit);
      handleResultNotification(false);
      onChangeAction(false, 'stakeholder');
    } else if (isEdit && type == 'save') {
      handleSubmitForm('submit');
      checkDataAdmin(admins);
      checkDataOwner(owners);
    } else {
      setIsEdit(!isEdit);
      onChangeAction(true, 'stakeholder');
    }
  };

  const formatOwners = (data) => {
    return map(data, (item) => {
      if (!item.owner_phone) {
        return {
          id: item.id,
          name: item.owner_name,
          mobile_no: '',
          email: item.owner_email,
          is_deleted: item.is_deleted,
        };
      } else {
        return {
          id: item.id,
          name: item.owner_name,
          mobile_no: item.owner_phone,
          email: item.owner_email,
          is_deleted: item.is_deleted,
        };
      }
    });
  };

  const formatAdmins = (data) => {
    return map(data, (item) => {
      if (!item.admin_phone) {
        return {
          id: item.id,
          name: item.admin_name,
          mobile_no: '',
          is_deleted: item.is_deleted,
        };
      } else {
        return {
          id: item.id,
          name: item.admin_name,
          mobile_no: item.admin_phone,
          is_deleted: item.is_deleted,
        };
      }
    });
  };

  const handleSubmitForm = (type) => {
    const isValid = validateForm();
    if (isValid) {
      updateStakeholder(type, formatOwners(owners), formatAdmins(admins));
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
  };

  const validateForm = () => {
    let isValid = true;
    map(admins, (item) => {
      if (item?.error_message_name !== '') {
        isValid = false;
      } else if (item?.error_message_phone !== '') {
        isValid = false;
      }
    });
    map(owners, (item) => {
      if (item?.error_message_name !== '') {
        isValid = false;
      } else if (item?.error_message_phone !== '') {
        isValid = false;
      } else if (item?.error_message_email !== '') {
        isValid = false;
      }
    });

    return isValid;
  };

  const updateStakeholder = (type, dataOwner, dataAdmin) => {
    setDisableButton(true);
    const payload = {
      owners: dataOwner,
      admins: dataAdmin,
    };

    api
      .put(`v2/intools/workshops/${id}/stakeholder/`, payload)
      .then((response) => {
        setInitialValues(response?.data?.data ?? {});
        setIsEdit(!isEdit);
        handleResultNotification(true, 'success', 'Berhasil update stakeholder');
        if (type == 'submit') {
          onChangeAction(false, 'stakeholder');
        }
        setDisableButton(false);
      })
      .catch((error) => {
        setIsEdit(isEdit);
        handleResultNotification(true, 'fail', 'Gagal update stakeholder');
        setDisableButton(false);
      });
  };

  const handleAdminChange = (value, key, index) => {
    setAddAdminsDisable(false);
    const newAdmins = [...admins];
    if (key === 'admin_phone') {
      let phoneValue = value.replace(/[^0-9]/g, '');
      phoneValue.replace(/(?!^\+)\+/g, '');
      newAdmins[index][key] = phoneValue;
    } else {
      newAdmins[index][key] = value;
    }
    checkDataAdmin(newAdmins);
  };

  const handleOwnerChange = (value, key, index) => {
    setAddOwnersDisable(false);
    const newOwner = [...owners];
    if (key === 'owner_phone') {
      let phoneValue = value.replace(/[^0-9]/g, '');
      phoneValue.replace(/(?!^\+)\+/g, '');
      newOwner[index][key] = phoneValue;
    } else if (key === 'owner_email') {
      const val = value !== '' ? value.replace(/ +/g, '') : value;
      newOwner[index][key] = val;
    } else {
      newOwner[index][key] = value;
    }
    checkDataOwner(newOwner);
  };

  const checkDataOwner = (values) => {
    const validatedData = map(values, (item) => {
      return {
        id: item.id,
        owner_name: item.owner_name,
        owner_phone: item.owner_phone,
        owner_email: item.owner_email,
        is_deleted: item.is_deleted,
        error_message_name: generateError(item.owner_name, 'name'),
        error_message_phone: generateError(item.owner_phone, 'phone'),
        error_message_email: generateError(item.owner_email, 'email'),
      };
    });
    setOwners(validatedData);
    checkIfErrorOwner(validatedData);
  };

  const checkDataAdmin = (values) => {
    const validatedData = map(values, (item) => {
      return {
        id: item.id,
        admin_name: item.admin_name,
        admin_phone: item.admin_phone,
        is_deleted: item.is_deleted,
        error_message_name: generateError(item.admin_name, 'name'),
        error_message_phone: generateError(item.admin_phone, 'phone'),
      };
    });
    setAdmins(validatedData);
    checkIfErrorAdmin(validatedData);
  };

  const generateError = (data, key) => {
    if (key == 'name') {
      if (data) {
        return data?.length > 25 ? 'maximal 25 characters' : '';
      } else {
        return 'field tidak boleh kosong';
      }
    } else if (key == 'phone') {
      if (data) {
        return data?.length > 17 ? 'maximal 17 characters' : '';
      } else {
        return '';
      }
    } else if (key == 'email') {
      if (data) {
        return validate(data ?? '') ? '' : 'Format Email tidak sesuai';
      } else {
        return '';
      }
    }
  };

  const checkIfErrorAdmin = (data) => {
    map(data, (item) => {
      if (item?.error_message_name !== '') {
        setAddAdminsDisable(true);
      }
    });
  };

  const checkIfErrorOwner = (data) => {
    map(data, (item) => {
      if (item?.error_message_name !== '') {
        setAddOwnersDisable(true);
      }
    });
  };

  const handleAddAdmins = () => {
    let newAdmin = {
      admin_name: null,
      admin_phone: null,
      is_deleted: false,
    };
    setAdmins([...admins, newAdmin]);
    setAddAdminsDisable(true);
  };

  const handleAddOwners = () => {
    let newOwner = {
      owner_name: null,
      owner_phone: null,
      is_deleted: false,
    };
    setOwners([...owners, newOwner]);
    setAddOwnersDisable(true);
  };

  const handleDelete = (key, item, index) => {
    if (key == 'admin') {
      if (item?.id) {
        admins[index].is_deleted = true;
        setAdmins([...admins]);
      } else {
        admins.splice(index, 1);
        setAdmins([...admins]);
      }
      setAddAdminsDisable(false);
    } else if (key == 'owner') {
      if (item?.id) {
        owners[index].is_deleted = true;
        setOwners([...owners]);
      } else {
        owners.splice(index, 1);
        setOwners([...owners]);
      }
      setAddOwnersDisable(false);
    }
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const handleValidateForm = () => {
    checkDataAdmin(admins);
    checkDataOwner(owners);
    const isValid = validateForm();
    if (isValid) {
      handleSubmitForm();
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
    onResultValidationForm(isValid);
  };

  useEffect(() => {
    if (isValidationForm) {
      handleValidateForm();
    }
  }, [isValidationForm]);

  useEffect(() => {
    if (originalValues) {
      defaultData();
    }
  }, [originalValues]);

  useEffect(() => {
    getStakeholders();
  }, []);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  return (
    <React.Fragment>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <Card className="card-custom">
          <CardBody className="p-0">
            <h3 className="mb-4">Owner</h3>
            {!isEdit && (
              <>
                {map(owners, (person, index) => {
                  if (owners[index].is_deleted == false) {
                    return (
                      <Row>
                        <Col sm={12} md={6}>
                          <Row className="mb-3">
                            <Label lg={3} className="text-left">
                              Name
                            </Label>
                            <Label lg={9} className="text-left text-bold">
                              : <span className="ml-1">{person?.owner_name ? person?.owner_name : '-'}</span>
                            </Label>
                          </Row>
                        </Col>
                        <Col sm={12} md={6}>
                          <Row className="mb-3">
                            <Label lg={3} className="text-left">
                              Phone No
                            </Label>
                            <Label lg={9} className="text-left text-bold">
                              : <span className="ml-1">{person?.owner_phone ? person?.owner_phone : '-'}</span>
                            </Label>
                          </Row>
                        </Col>
                        <Col sm={12} md={6}>
                          <Row className="mb-3">
                            <Label lg={3} className="text-left">
                              Email
                            </Label>
                            <Label lg={9} className="text-left text-bold">
                              : <span className="ml-1">{person?.owner_email ? person?.owner_email : '-'}</span>
                            </Label>
                          </Row>
                        </Col>
                      </Row>
                    );
                  }
                })}
              </>
            )}
            {isEdit && (
              <StakeholderAddOwners
                dataItems={owners}
                addOwnersDisable={addOwnersDisable}
                handleAddOwners={handleAddOwners}
                handleDelete={handleDelete}
                handleOwnerChange={handleOwnerChange}
              ></StakeholderAddOwners>
            )}
            {!isEdit ? <DividerNoLabel /> : ''}
            <h3 className="mb-4">PIC Workshop</h3>
            {!isEdit && (
              <>
                {map(admins, (person, index) => {
                  if (admins[index].is_deleted == false) {
                    return (
                      <Row key={`admin-${index}`}>
                        <Col sm={12} md={6}>
                          <Row className="mb-3">
                            <Label lg={3} className="text-left">
                              Name
                            </Label>
                            <Label lg={9} className="text-left text-bold">
                              : <span className="ml-1">{person?.admin_name ? person?.admin_name : '-'}</span>
                            </Label>
                          </Row>
                        </Col>
                        <Col sm={12} md={6}>
                          <Row className="mb-3">
                            <Label lg={3} className="text-left">
                              Phone No
                            </Label>
                            <Label lg={9} className="text-left text-bold">
                              : <span className="ml-1">{person?.admin_phone ? person?.admin_phone : '-'}</span>
                            </Label>
                          </Row>
                        </Col>
                      </Row>
                    );
                  }
                })}
              </>
            )}
            {isEdit && (
              <StakeholderAddAdmins
                dataItems={admins}
                addAdminsDisable={addAdminsDisable}
                handleAddAdmins={handleAddAdmins}
                handleDelete={handleDelete}
                handleAdminChange={handleAdminChange}
              ></StakeholderAddAdmins>
            )}

            {allowEdit && (
              <Row>
                <Col sm={12} md={12} className="section-action">
                  <Button className="button-action save" disabled={disableButton} onClick={() => handleAction('save')}>
                    {isEdit ? 'Simpan' : 'Edit Data'}
                  </Button>
                  {isEdit && (
                    <Button className="button-action cancel" onClick={() => handleAction('cancel')}>
                      {'Batal'}
                    </Button>
                  )}
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default Stakeholder;
