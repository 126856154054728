import React, { useState, useEffect } from 'react';
import { map } from 'lodash';
import { Row, Col, Label, Card, CardHeader, CardBody, Input } from 'reactstrap';
import API from '@utils/API';
import ValidCoordinates from 'is-valid-coordinates';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import ActionSwitch from '@components/field/ActionSwitch';

const CreateBasic = (props) => {
  const { isSave, disableForm, onResultValidate } = props;
  const api = new API('v2');
  const [formData, setFormData] = useState();
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [districtsOptions, setDistrictsOptions] = useState([]);
  const [loadingFetchProvince, setLoadingFetchProvince] = useState(false);
  const [loadingFetchCity, setLoadingFetchCity] = useState(false);
  const [loadingFetchDistricts, setLoadingFetchDistricts] = useState(false);

  const handleDefaultData = () => {
    const defaultForm = {
      name: '',
      telp: '',
      owner_name: '',
      owner_phone: '',
      province: null,
      city: null,
      districts: null,
      sub_districts: '',
      post_code: '',
      gmaps_link: '',
      latitude: '',
      longitude: '',
      address: '',
      type: 'semua',
      is_otoklix_pickup: false,
      error_name: '',
      error_telp: '',
      error_owner_name: '',
      error_owner_phone: '',
      error_province: '',
      error_city: '',
      error_districts: '',
      error_sub_districts: '',
      error_post_code: '',
      error_gmaps_link: '',
      error_latitude: '',
      error_longitude: '',
      error_type: '',
      error_address: '',
    };

    setFormData(defaultForm);
  };

  async function fetchProvince() {
    setLoadingFetchProvince(true);
    try {
      const response = await api.get(`v2/intools/workshops/locations/province/`);
      const optionsProvince = map(response?.data?.data, (item) => {
        return { value: item?.id ?? null, label: item?.name ?? '-' };
      });
      setProvinceOptions(optionsProvince);
      setLoadingFetchProvince(false);
    } catch (error) {
      setProvinceOptions([]);
      setLoadingFetchProvince(false);
    }
  }

  async function fetchCity(provinceID) {
    setLoadingFetchCity(true);
    try {
      const response = await api.get(`v2/intools/workshops/locations/${provinceID}/`);
      const optionsCity = map(response?.data?.data, (item) => {
        return { value: item?.id ?? null, label: item?.name ?? '-' };
      });
      setCityOptions(optionsCity);
      setLoadingFetchCity(false);
    } catch (error) {
      setCityOptions([]);
      setLoadingFetchCity(false);
    }
  }

  async function fetchDistricts(provinceID, cityID) {
    setLoadingFetchDistricts(true);
    try {
      const response = await api.get(`v2/intools/workshops/locations/${provinceID}/${cityID}/`);
      const optionsDistricts = map(response?.data?.data, (item) => {
        return { value: item?.id ?? null, label: item?.name ?? '-' };
      });
      setDistrictsOptions(optionsDistricts);
      setLoadingFetchDistricts(false);
    } catch (error) {
      setDistrictsOptions([]);
      setLoadingFetchDistricts(false);
    }
  }

  const setChangeValueForm = (key, value, errorKey, errorMsg) => {
    formData[key] = value;
    if (errorKey) formData[errorKey] = errorMsg;
    setFormData({ ...formData });
  };

  const handleChangeName = (e) => {
    validateName(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.name);
  };

  const handleChangePhone = (e) => {
    let value = e?.target?.value.replace(/[^0-9]/g, '');
    value.replace(/(?!^\+)\+/g, '');
    validatePhone(value);
  };

  const handleChangeOwnerName = (e) => {
    validateOwnerName(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.owner_name);
  };

  const handleChangeOwnerPhone = (e) => {
    let value = e?.target?.value.replace(/[^0-9]/g, '');
    value.replace(/(?!^\+)\+/g, '');
    validateOwnerPhone(value);
  };

  const handleChangeProvince = (e) => {
    validateProvince(e?.value ? e : null);
  };

  const handleChangeCity = (e) => {
    validateCity(e?.value ? e : null);
  };

  const handleChangeDistricts = (e) => {
    validateDistricts(e?.value ? e : null);
  };

  const handleChangeSubDistricts = (e) => {
    validateSubDistricts(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.sub_districts);
  };

  const handleChangePostCode = (e) => {
    let value = e?.target?.value.replace(/[^0-9]/g, '');
    value.replace(/(?!^\+)\+/g, '');
    validatePostCode(value);
  };

  const handleChangeGmapsLink = (e) => {
    validateGmapsLink(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.gmaps_link);
  };

  const handleChangeLatitude = (e) => {
    validateLatitude(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.latitude);
  };

  const handleChangeLongitude = (e) => {
    validateLongitude(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.longitude);
  };

  const handleChangeAddress = (e) => {
    validateAddress(e?.target?.value !== ' ' ? e?.target?.value.replace(/  +/g, ' ') : formData?.address);
  };

  const handleChangeType = (e) => {
    validateType(e);
  };

  const handleChangeOtoPickup = (e) => {
    setChangeValueForm('is_otoklix_pickup', e?.target?.checked ? true : false);
  };

  const validateName = (value) => {
    const errorMsg =
      value?.length == 0
        ? 'Nama Bengkel tidak boleh kosong'
        : value?.length > 50
        ? 'Format Nama Bengkel maksimal 50 karakter'
        : '';
    setChangeValueForm('name', value, 'error_name', errorMsg);
    return errorMsg ? false : true;
  };

  const validatePhone = (value) => {
    const errorMsg =
      value?.length == 0
        ? 'No. Telp Bengkel tidak boleh kosong'
        : value?.length > 17
        ? 'Format No. Telp Bengkel maksimal 17 karakter'
        : '';
    setChangeValueForm('telp', value, 'error_telp', errorMsg);
    return errorMsg ? false : true;
  };

  const validateOwnerName = (value) => {
    const errorMsg = value?.length > 50 ? 'Format Nama Owner maksimal 50 karakter' : '';
    setChangeValueForm('owner_name', value, 'error_owner_name', errorMsg);
    return errorMsg ? false : true;
  };

  const validateOwnerPhone = (value) => {
    const errorMsg = value?.length > 17 ? 'Format No. Telp Owner maksimal 17 karakter' : '';
    setChangeValueForm('owner_phone', value, 'error_owner_phone', errorMsg);
    return errorMsg ? false : true;
  };

  const validateProvince = (value) => {
    setChangeValueForm('province', value, 'error_province', !value?.value ? 'Pilih Provinsi terlebih dahulu' : '');
    return !value?.value ? false : true;
  };

  const validateCity = (value) => {
    setChangeValueForm('city', value, 'error_city', !value?.value ? 'Pilih Kabupaten terlebih dahulu' : '');
    return !value?.value ? false : true;
  };

  const validateDistricts = (value) => {
    setChangeValueForm('districts', value, 'error_districts', !value?.value ? 'Pilih Kecamatan terlebih dahulu' : '');
    return !value?.value ? false : true;
  };

  const validateSubDistricts = (value) => {
    const errorMsg =
      value?.length == 0
        ? 'Kelurahan tidak boleh kosong'
        : value?.length > 50
        ? 'Format Kelurahan maksimal 50 karakter'
        : '';
    setChangeValueForm('sub_districts', value, 'error_sub_districts', errorMsg);
    return errorMsg ? false : true;
  };

  const validatePostCode = (value) => {
    const errorMsg =
      value?.length == 0
        ? 'Kode Pos tidak boleh kosong'
        : value?.length > 6
        ? 'Format Kode Pos maksimal 6 karakter'
        : '';
    setChangeValueForm('post_code', value, 'error_post_code', errorMsg);
    return errorMsg ? false : true;
  };

  const validateGmapsLink = (value) => {
    const errorMsg =
      value?.length == 0
        ? 'Google Maps Link tidak boleh kosong'
        : value?.length > 100
        ? 'Format Google Maps Link maksimal 100 karakter'
        : '';
    setChangeValueForm('gmaps_link', value, 'error_gmaps_link', errorMsg);
    return errorMsg ? false : true;
  };

  const validateLatitude = (value) => {
    const isValidLatitude = ValidCoordinates.latitude(Number(value)) ? true : false;
    const errorMsg =
      value?.length == 0
        ? 'Latitude tidak boleh kosong'
        : value?.length > 20
        ? 'Format Latitude maksimal 20 karakter'
        : !isValidLatitude
        ? 'Format Latitude tidak sesuai'
        : '';
    setChangeValueForm('latitude', value, 'error_latitude', errorMsg);
    return errorMsg ? false : true;
  };

  const validateLongitude = (value) => {
    const isValidLongitude = ValidCoordinates.longitude(Number(value)) ? true : false;
    const errorMsg =
      value?.length == 0
        ? 'Longitude tidak boleh kosong'
        : value?.length > 20
        ? 'Format Longitude maksimal 20 karakter'
        : !isValidLongitude
        ? 'Format Longitude tidak sesuai'
        : '';
    setChangeValueForm('longitude', value, 'error_longitude', errorMsg);
    return errorMsg ? false : true;
  };

  const validateType = (value) => {
    const errorMsg = value?.length == 0 ? 'Jenis bengkel tidak boleh kosong' : '';
    setChangeValueForm('type', value, 'error_type', errorMsg);
    return errorMsg ? false : true;
  };

  const validateAddress = (value) => {
    const errorMsg =
      value?.length == 0 ? 'Jalan tidak boleh kosong' : value?.length > 500 ? 'Format Jalan maksimal 500 karakter' : '';
    setChangeValueForm('address', value, 'error_address', errorMsg);
    return errorMsg ? false : true;
  };

  const handleOptionsNestedProvince = () => {
    if (formData?.province) {
      formData.city = null;
      formData.districts = null;
      formData.error_city = '';
      formData.error_districts = '';
      setCityOptions([]);
      setDistrictsOptions([]);
      setFormData({ ...formData });

      fetchCity(formData?.province?.value);
    }
  };

  const handleOptionsNestedCity = () => {
    if (formData?.city) {
      formData.districts = null;
      formData.error_districts = '';
      setDistrictsOptions([]);
      setFormData({ ...formData });

      fetchDistricts(formData?.province?.value, formData?.city?.value);
    }
  };

  const validateFormBasic = () => {
    const isValidName = validateName(formData?.name ?? '');
    const isValidPhone = validatePhone(formData?.telp ?? '');
    const isValidOwnerName = validateOwnerName(formData?.owner_name ?? '');
    const isValidOwnerPhone = validateOwnerPhone(formData?.owner_phone ?? '');
    const isValidProvince = validateProvince(formData?.province ?? null);
    const isValidCity = validateCity(formData?.city ?? null);
    const isValidDistricts = validateDistricts(formData?.districts ?? null);
    const isValidSubDistricts = validateSubDistricts(formData?.sub_districts ?? '');
    const isValidPostCode = validatePostCode(formData?.post_code ?? '');
    const isValidGmapsLink = validateGmapsLink(formData?.gmaps_link ?? '');
    const isValidLatitude = validateLatitude(formData?.latitude ?? '');
    const isValidLongitude = validateLongitude(formData?.longitude ?? '');
    const isValidType = validateType(formData?.type ?? '');
    const isValidAddress = validateAddress(formData?.address ?? '');

    if (
      isValidName &&
      isValidPhone &&
      isValidOwnerName &&
      isValidOwnerPhone &&
      isValidProvince &&
      isValidCity &&
      isValidDistricts &&
      isValidSubDistricts &&
      isValidPostCode &&
      isValidGmapsLink &&
      isValidLatitude &&
      isValidLongitude &&
      isValidType &&
      isValidAddress
    ) {
      handleValidate(true);
    } else {
      handleValidate(false);
    }
  };

  const handleValidate = (pass) => {
    onResultValidate({
      is_pass: pass,
      data_form: formData,
    });
  };

  useEffect(() => {
    handleOptionsNestedProvince();
  }, [formData?.province]);

  useEffect(() => {
    handleOptionsNestedCity();
  }, [formData?.city]);

  useEffect(() => {
    if (isSave) {
      validateFormBasic();
    }
  }, [isSave]);

  useEffect(() => {
    handleDefaultData();
    fetchProvince();
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="text-white mt-0">Basic</CardHeader>
        <CardBody className="card-custom">
          <Row>
            <Col sm={12} md={6}>
              <Row className="mb-4">
                <Label lg={4} className="label-required text-left">
                  Nama Bengkel
                </Label>
                <InputField
                  colWidth={8}
                  name="name"
                  inputType={'text'}
                  disabled={disableForm}
                  value={formData?.name}
                  invalid={formData?.error_name ? true : false}
                  errorMessage={formData?.error_name ?? ''}
                  placeholder={'Nama Bengkel'}
                  onChange={handleChangeName}
                ></InputField>
              </Row>
              <Row className="mb-4">
                <Label lg={4} className="text-left">
                  Owner
                </Label>
                <InputField
                  colWidth={8}
                  name="owner_name"
                  inputType={'text'}
                  disabled={disableForm}
                  value={formData?.owner_name}
                  invalid={formData?.error_owner_name ? true : false}
                  errorMessage={formData?.error_owner_name ?? ''}
                  placeholder={'Nama Owner'}
                  onChange={handleChangeOwnerName}
                ></InputField>
              </Row>
              <Row className="mb-4">
                <Label lg={4} className="label-required text-left">
                  No. Telp Bengkel
                </Label>
                <InputField
                  colWidth={8}
                  name="telp"
                  inputType={'text'}
                  disabled={disableForm}
                  value={formData?.telp}
                  invalid={formData?.error_telp ? true : false}
                  errorMessage={formData?.error_telp ?? ''}
                  placeholder={'No. Telp Bengkel'}
                  onChange={handleChangePhone}
                ></InputField>
              </Row>
              <Row className="mb-4">
                <Label lg={4} className="text-left">
                  No. Telp Owner
                </Label>
                <InputField
                  colWidth={8}
                  name="owner_phone"
                  inputType={'text'}
                  disabled={disableForm}
                  value={formData?.owner_phone}
                  invalid={formData?.error_owner_phone ? true : false}
                  errorMessage={formData?.error_owner_phone ?? ''}
                  placeholder={'No. Telp Owner'}
                  onChange={handleChangeOwnerPhone}
                ></InputField>
              </Row>
              <Row className="mb-4">
                <Label lg={4} className="label-required text-left">
                  Jenis Bengkel
                </Label>
                <Col lg={8} className="col-radio-workshop">
                  <Input
                    type="radio"
                    disabled={disableForm}
                    name="type_all"
                    id="type_all"
                    checked={formData?.type === 'semua' ? true : false}
                    className="radio-workshop cursor-pointer"
                    onChange={() => handleChangeType('semua')}
                  />
                  <span className="ml-2 mr-4 label-radio">{'Semua'}</span>
                  <Input
                    type="radio"
                    disabled={disableForm}
                    name="type_b2b"
                    id="type_b2b"
                    checked={formData?.type === 'b2b' ? true : false}
                    className="radio-workshop cursor-pointer"
                    onChange={() => handleChangeType('b2b')}
                  />
                  <span className="ml-2 mr-4 label-radio">{'B2B'}</span>
                  <Input
                    type="radio"
                    disabled={disableForm}
                    name="type_b2c"
                    id="type_b2c"
                    checked={formData?.type === 'b2c' ? true : false}
                    className="radio-workshop cursor-pointer"
                    onChange={() => handleChangeType('b2c')}
                  />
                  <span className="ml-2 label-radio">{'B2C'}</span>
                  <div className="invalid-document">{formData?.error_type}</div>
                </Col>
              </Row>
              <Row>
                <Label lg={4} className="text-left">
                  Otoklix Pickup
                </Label>
                <Col lg={8} className="p-2">
                  <ActionSwitch
                    customClass="mb-4"
                    color="secondary"
                    inputName={`is_otoklix_pickup`}
                    isChecked={formData?.is_otoklix_pickup ? true : false}
                    editableForm={disableForm}
                    onChange={handleChangeOtoPickup}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={6}>
              <Row className="mb-4">
                <Label lg={4} className="label-required text-left">
                  Provinsi
                </Label>
                <SelectField
                  colWidth={8}
                  name="province"
                  placeholder="Pilih Provinsi"
                  closeMenuOnSelect={true}
                  value={formData?.province}
                  options={provinceOptions}
                  disabled={disableForm}
                  isLoading={loadingFetchProvince}
                  errorMessage={formData?.error_province}
                  onChange={handleChangeProvince}
                />
              </Row>
              <Row className="mb-4">
                <Label lg={4} className="label-required text-left">
                  Kabupaten
                </Label>
                <SelectField
                  colWidth={8}
                  name="city"
                  placeholder="Pilih Kabupaten"
                  closeMenuOnSelect={true}
                  value={formData?.city}
                  options={cityOptions}
                  disabled={disableForm || cityOptions?.length == 0}
                  isLoading={loadingFetchCity}
                  errorMessage={formData?.error_city}
                  onChange={handleChangeCity}
                />
              </Row>
              <Row className="mb-4">
                <Label lg={4} className="label-required text-left">
                  Kecamatan
                </Label>
                <SelectField
                  colWidth={8}
                  name="districts"
                  placeholder="Pilih Kecamatan"
                  closeMenuOnSelect={true}
                  value={formData?.districts}
                  options={districtsOptions}
                  disabled={disableForm || districtsOptions?.length == 0}
                  isLoading={loadingFetchDistricts}
                  errorMessage={formData?.error_districts}
                  onChange={handleChangeDistricts}
                />
              </Row>
              <Row className="mb-4">
                <Label lg={4} className="label-required text-left">
                  Kelurahan
                </Label>
                <InputField
                  colWidth={8}
                  name="sub_districts"
                  inputType={'text'}
                  disabled={disableForm}
                  value={formData?.sub_districts}
                  invalid={formData?.error_sub_districts ? true : false}
                  errorMessage={formData?.error_sub_districts ?? ''}
                  placeholder={'Kelurahan'}
                  onChange={handleChangeSubDistricts}
                ></InputField>
              </Row>
              <Row className="mb-4">
                <Label lg={4} className="label-required text-left">
                  Kode Pos
                </Label>
                <InputField
                  colWidth={8}
                  name="post_code"
                  inputType={'text'}
                  disabled={disableForm}
                  value={formData?.post_code}
                  invalid={formData?.error_post_code ? true : false}
                  errorMessage={formData?.error_post_code ?? ''}
                  placeholder={'Kode Pos'}
                  onChange={handleChangePostCode}
                ></InputField>
              </Row>
              <Row className="mb-4">
                <Label lg={4} className="label-required text-left">
                  Jalan
                </Label>
                <InputField
                  colWidth={8}
                  name="latitude"
                  inputType={'textarea'}
                  disabled={disableForm}
                  value={formData?.address}
                  invalid={formData?.error_address ? true : false}
                  errorMessage={formData?.error_address ?? ''}
                  placeholder={'Jalan'}
                  onChange={handleChangeAddress}
                ></InputField>
              </Row>
              <Row className="mb-4">
                <Label lg={4} className="label-required text-left">
                  Google Maps Link
                </Label>
                <InputField
                  colWidth={8}
                  name="gmpas_link"
                  inputType={'text'}
                  disabled={disableForm}
                  value={formData?.gmaps_link}
                  invalid={formData?.error_gmaps_link ? true : false}
                  errorMessage={formData?.error_gmaps_link ?? ''}
                  placeholder={'Google Maps Link'}
                  onChange={handleChangeGmapsLink}
                ></InputField>
              </Row>
              <Row className="mb-4">
                <Label lg={4} className="label-required text-left">
                  Latitude
                </Label>
                <InputField
                  colWidth={8}
                  name="latitude"
                  inputType={'text'}
                  disabled={disableForm}
                  value={formData?.latitude}
                  invalid={formData?.error_latitude ? true : false}
                  errorMessage={formData?.error_latitude ?? ''}
                  placeholder={'Latitude'}
                  onChange={handleChangeLatitude}
                ></InputField>
              </Row>
              <Row className="mb-4">
                <Label lg={4} className="label-required text-left">
                  Longitude
                </Label>
                <InputField
                  colWidth={8}
                  name="longitude"
                  inputType={'text'}
                  disabled={disableForm}
                  value={formData?.longitude}
                  invalid={formData?.error_longitude ? true : false}
                  errorMessage={formData?.error_longitude ?? ''}
                  placeholder={'Longitude'}
                  onChange={handleChangeLongitude}
                ></InputField>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CreateBasic;
