import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import SelectField from '@components/field/SelectField';
import API from '@utils/API';
import { assign, find, map, toNumber } from 'lodash';
import Filter from '@assets/icons/filter.svg';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import WorkshopCard from '../card/WorkshopCard';
import InputField from '../field/InputField';
import { monasLocation, workshopSortingOptions } from '@utils/Constants';
import Helper from '@helpers/Helper';
import { useSelector, useDispatch } from 'react-redux';
import { addMultipleWorkshop } from '@store/booking';

const ModalChooseWorkshop = (props) => {
  const {
    isOpen,
    toggle,
    bookingDatetime,
    handleChangeWorkshop,
    cart,
    selectedUser,
    isPUDO,
    mapValue,
    customerType,
    selectedWorkshops,
    setSelectedWorkshops,
    handleAddCart,
    multipleCalculateCartData,
  } = props;
  const api = new API('v2');
  const hasLocation = mapValue?.latitude !== monasLocation.lat && mapValue?.longitude !== monasLocation.lng;

  const [selectWorkshops, setSelectWorkshops] = useState(selectedWorkshops);

  const handleMultipleWorkshop = (workshops) => {
    setSelectWorkshops(workshops);
  };

  const [workshops, setWorkshops] = useState([]);
  const [hasMoreWorkshop, setHasMoreWorkshop] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [capabilitiesFilter, setCapabilitiesFilter] = useState([]);
  const [selectedCapabilities, setSelectedCapabilities] = useState([]);
  const [specialitiesFilter, setSpecialitiesFilter] = useState();
  const [selectedSpecialities, setSelectedSpecialities] = useState();
  const [fboFilter, setFBOFilter] = useState([
    { value: '0', label: 'All' },
    { value: '1', label: 'FBO' },
    { value: '2', label: 'NON FBO' },
  ]);
  const [selectedFBO, setSelectedFBO] = useState({ value: '0', label: 'All' });
  const [pudoFilter, setPUDOFilter] = useState([
    { value: '0', label: 'All' },
    { value: '1', label: 'PUDO' },
    { value: '2', label: 'NON PUDO' },
  ]);
  const [selectedPUDO, setSelectedPUDO] = useState({ value: '0', label: 'All' });
  const [sort, setSort] = useState('recommended');
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [hasErrorAPI, setHasErrorAPI] = useState('');

  const handleUnselectedWorkshopCart = () => {
    const updatedPackageCart = cart?.package?.filter((item) => {
      return selectWorkshops.some((workshop) => workshop.id === item?.name?.workshop_id);
    });

    const updatedCart = {
      ...cart,
      package: updatedPackageCart,
    };

    if (selectWorkshops.length == 1) {
      const calculateCartData = multipleCalculateCartData?.find((item) => item?.workshop_id == selectWorkshops[0]?.id);

      if (updatedCart.product && Array.isArray(updatedCart.product)) {
        updatedCart.product.forEach((product, index) => {
          const workshopProduct = calculateCartData?.customer?.items?.find(
            (item) => item.product_id === product.name?.product_id || item.product_id === product.name?.id
          );

          if (product.name.product_id !== 'mock_item' && workshopProduct && !workshopProduct.is_custom) {
            updatedCart.product[index] = {
              ...product,
              is_wi: workshopProduct.is_wi,
              price: workshopProduct.price,
              final_sell_price: workshopProduct.final_sell_price,
              total_price: workshopProduct.total_price,
              // nettPrice: workshopProduct.nett_price,
              nettPrice: {
                ...product.nettPrice,
                formatIsMoney: true,
                formatMoney: workshopProduct.workshop_price,
                isNoPriceAvailable: false,
                value: workshopProduct.workshop_price,
              },
              sellPrice: {
                ...product.sellPrice,
                formatIsMoney: true,
                formatMoney: workshopProduct.price,
                isNoPriceAvailable: false,
                value: workshopProduct.price,
              },
              name: {
                ...product.name,
                price: workshopProduct.price,
                nett_price: workshopProduct.nett_price,
                calculated_price: workshopProduct.total_price,
                attr: {
                  ...product.name.attr,
                  is_wi: workshopProduct.is_wi,
                },
              },
            };
          }
        });
      }

      if (updatedCart.service && Array.isArray(updatedCart.service)) {
        updatedCart.service.forEach((service, index) => {
          const workshopProduct = calculateCartData?.customer?.items?.find(
            (item) => item.product_id === service.name?.product_id || item.product_id === service.name?.id
          );

          if (service.name.product_id !== 'mock_item' && workshopProduct && !workshopProduct.is_custom) {
            updatedCart.service[index] = {
              ...service,
              is_wi: workshopProduct.is_wi,
              price: workshopProduct.price,
              final_sell_price: workshopProduct.final_sell_price,
              total_price: workshopProduct.total_price,
              // nettPrice: workshopProduct.nett_price,
              nettPrice: {
                ...service.nettPrice,
                formatIsMoney: true,
                formatMoney: workshopProduct.workshop_price,
                isNoPriceAvailable: false,
                value: workshopProduct.workshop_price,
              },
              sellPrice: {
                ...service.sellPrice,
                formatIsMoney: true,
                formatMoney: workshopProduct.price,
                isNoPriceAvailable: false,
                value: workshopProduct.price,
              },
              name: {
                ...service.name,
                price: workshopProduct.price,
                nett_price: workshopProduct.nett_price,
                calculated_price: workshopProduct.total_price,
                attr: {
                  ...service.name.attr,
                  is_wi: workshopProduct.is_wi,
                },
              },
            };
          }
        });
      }
    }

    handleAddCart(updatedCart);
  };

  const getFilterCapabilities = async () => {
    await api
      .get('v2/intools/workshops/capabilities')
      .then((res) => {
        const transformedData = map(res.data.data, (item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setCapabilitiesFilter(transformedData);
      })
      .catch((err) => console.log(err));
  };

  const getFilterSpecialities = async () => {
    await api
      .get('v2/intools/workshops/specialities')
      .then((res) => {
        let transformedData = map(res.data.data, (item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        transformedData.unshift({ label: 'All', value: 0 });
        setSpecialitiesFilter(transformedData);
      })
      .catch((err) => console.log(err));
  };

  const generateExistingProductId = () => {
    let filteredProducts = cart?.product.filter((x) => x?.formType !== 'custom');
    let filteredServices = cart?.service.filter((x) => x?.formType !== 'custom');
    const productId = map(filteredProducts, (item) => {
      return {
        product_id: item?.name?.id ?? item?.name?.product_id,
        is_fbo: item?.name?.is_fbo ? item?.name?.is_fbo : false,
      };
    });
    const serviceId = map(filteredServices, (item) => {
      return {
        product_id: item?.name?.id ?? item?.name?.product_id,
        is_fbo: item?.name?.is_fbo ? item?.name?.is_fbo : false,
      };
    });

    return [...productId.filter((x) => x), ...serviceId.filter((x) => x)] ?? [];
  };

  const getWorkshopList = async (
    page = 1,
    search = query,
    restart,
    PUDO = selectedPUDO,
    FBO = selectedFBO,
    speciality = selectedSpecialities,
    capability = selectedCapabilities,
    sorting = sort,
    user = selectedUser
  ) => {
    const capabilities = map(capability, (item) => {
      return item?.value;
    });
    const datetime =
      bookingDatetime?.date || bookingDatetime?.time
        ? `${
            bookingDatetime?.date ? moment(bookingDatetime?.date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
          }${bookingDatetime?.time ? ` ${bookingDatetime?.time}:00` : ' 00:00:00'}`
        : moment().format('YYYY-MM-DD HH:mm:ss');
    let params = {
      products: selectWorkshops.length > 0 ? [] : generateExistingProductId(),
      capabilities_id: capabilities,
      speciality: Number(speciality?.value) ?? null,
      query: search,
      latitude: hasLocation ? mapValue?.latitude : '',
      longitude: hasLocation ? mapValue?.longitude : '',
      booking_datetime: datetime,
      pudo: Number(PUDO?.value ?? 0),
      fbo: Number(FBO?.value ?? 0),
      page,
      limit: 10,
      sort_by: sorting,
    };

    if (user.isB2B) params = { ...params, customer_id: user.id };

    setHasErrorAPI('');
    if (restart) setLoading(true);

    await api
      .post(`v2/intools/workshops/inventory-related/`, params)
      .then((res) => {
        const data = res.data.data;
        if (data?.length < 1) setHasMoreWorkshop(false);
        if (restart) {
          setWorkshops(res.data.data);
          setHasMoreWorkshop(true);
          setLoading(false);
        } else {
          setWorkshops([...workshops, ...data]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setHasMoreWorkshop(false);
        setHasErrorAPI(`Gagal! - ${err?.response?.data?.error?.message}`);
        setLoading(false);
      });

    if (restart) {
      setCurrentPage(1);
    } else {
      setCurrentPage(page);
    }
  };

  const handleSorting = (value) => {
    setSort(value.value);
    getWorkshopList(1, query, true, selectedPUDO, selectedFBO, selectedSpecialities, selectedCapabilities, value.value);
  };

  const handleChangeCapabilities = (value) => {
    setSelectedCapabilities(value);
    getWorkshopList(1, '', true, selectedPUDO, selectedFBO, selectedSpecialities, value, sort);
  };

  const handleChangeSpeciabilities = (value) => {
    setSelectedSpecialities(value);
    getWorkshopList(1, '', true, selectedPUDO, selectedFBO, value, selectedCapabilities, sort);
  };

  const handleChangePUDO = (value) => {
    setSelectedPUDO(value);
    getWorkshopList(1, '', true, value, selectedFBO, selectedSpecialities, selectedCapabilities, sort);
  };

  const handleChangeFBO = (value) => {
    setSelectedFBO(value);
    getWorkshopList(1, '', true, selectedPUDO, value, selectedSpecialities, selectedCapabilities, sort);
  };

  const handleInputQuery = (e) => {
    setQuery(e.target.value);
    getWorkshopList(
      1,
      e.target.value,
      true,
      selectedPUDO,
      selectedFBO,
      selectedSpecialities,
      selectedCapabilities,
      sort,
      selectedUser
    );
  };

  // const handleDeleteCartWorkshop = () => {

  // }

  const handleChangeQuery = Helper.debounce(handleInputQuery, 500);

  const handleDismissAlert = () => setHasErrorAPI('');

  const handleOpenedModal = () => {
    let pudo = selectedPUDO;
    let ispudo = {};
    if (isPUDO) {
      ispudo = find(pudoFilter, { value: '1' });
      setSelectedPUDO(ispudo);
      pudo = ispudo;
    } else {
      ispudo = find(pudoFilter, { value: '0' });
      setSelectedPUDO(ispudo);
      pudo = ispudo;
    }
    getWorkshopList(1, '', true, pudo);
  };

  const handleClosedModal = () => {
    setSelectedCapabilities([]);
    setSelectedSpecialities();
    setSelectedFBO({ value: '0', label: 'All' });
    setSelectedPUDO({ value: '0', label: 'All' });
    setQuery('');
    setSelectWorkshops([]);
  };

  const handleCancelSelectWorkshop = () => {
    if (selectedWorkshops.length == 0) {
      handleMultipleWorkshop([]);
    }
  };

  useEffect(() => {
    getFilterCapabilities();
    getFilterSpecialities();
    setSelectWorkshops(selectedWorkshops);
  }, [selectedWorkshops]);

  const sortValue = find(workshopSortingOptions, { value: sort });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      size="lg"
      onOpened={handleOpenedModal}
      onExit={handleClosedModal}
      backdrop={handleClosedModal}
    >
      <ModalBody>
        <Row className="justify-content-center">
          <span style={{ fontWeight: 700, fontSize: 24 }}>Cari Bengkel</span>
        </Row>

        <Row
          style={{
            border: '1px solid #6610F2',
            boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.05)',
            borderRadius: 4,
            padding: 8,
            margin: '0px 32px',
          }}
        >
          <Row style={{ width: '100%' }}>
            <SelectField
              options={capabilitiesFilter}
              isMulti
              onChange={handleChangeCapabilities}
              value={selectedCapabilities}
              placeholder="Select Capabilities"
              isSelectAll
            />
            <SelectField
              options={pudoFilter}
              onChange={handleChangePUDO}
              value={selectedPUDO}
              placeholder="Select PUDO"
            />
            <SelectField
              options={specialitiesFilter}
              onChange={handleChangeSpeciabilities}
              value={selectedSpecialities}
              placeholder="Select Specialities"
            />
            <SelectField options={fboFilter} onChange={handleChangeFBO} value={selectedFBO} placeholder="Select FBO" />
          </Row>

          <Row className="w-100 mt-2">
            <Col className="d-flex p-0 align-items-center">
              <InputField placeholder="Cari Bengkel" onChange={handleChangeQuery} colWidth={9} />
              <SelectField
                options={workshopSortingOptions}
                onChange={handleSorting}
                value={sortValue}
                placeholder="Urutkan"
                colWidth={3}
              />
            </Col>
          </Row>

          <Row className="w-100">
            <Alert color="danger" className="w-100 m-2" isOpen={!!hasErrorAPI} toggle={handleDismissAlert}>
              <span dangerouslySetInnerHTML={{ __html: hasErrorAPI }}></span>
            </Alert>
            <hr />
          </Row>

          <Row className="w-100">
            <Col style={{ height: 300, overflow: 'auto' }}>
              <InfiniteScroll
                pageStart={1}
                loadMore={getWorkshopList}
                hasMore={hasMoreWorkshop}
                loader={
                  <div className="loader d-flex justify-content-center my-2" key={0}>
                    <Spinner size="sm" color="primary" />
                  </div>
                }
                useWindow={false}
              >
                {map(workshops, (item, index) => {
                  return (
                    <WorkshopCard
                      customerType={customerType}
                      key={index}
                      data={item}
                      handleChangeWorkshop={handleChangeWorkshop}
                      isPUDO={isPUDO}
                      handleMultipleWorkshop={handleMultipleWorkshop}
                      selectWorkshops={selectWorkshops}
                    />
                  );
                })}

                {workshops?.length < 1 && !isLoading ? (
                  <Row className="justify-content-center text-center pt-5 w-100">
                    <div className="d-flex flex-column justify-content-center">
                      <span style={{ fontSize: 24 }}>🙂</span>
                      <span>
                        Tidak ditemukan bengkel yang sesuai <br /> Cari lagi dengan kata kunci atau filter lain yaa!
                      </span>
                    </div>
                  </Row>
                ) : null}
              </InfiniteScroll>
            </Col>
          </Row>
        </Row>

        <Row className="justify-content-center mt-2">
          <Col xs="auto" className="section-action d-flex justify-content-center">
            <Button
              className="button-action cancel"
              onClick={() => {
                handleCancelSelectWorkshop();
                toggle();
              }}
            >
              Batal
            </Button>
          </Col>
          {customerType == 'b2b' && (
            <Col xs="auto" className="section-action d-flex justify-content-center">
              {selectWorkshops.length > 0 || selectedWorkshops.length > 0 ? (
                <Button
                  className="button-action save"
                  onClick={() => {
                    setSelectedWorkshops(selectWorkshops);
                    handleUnselectedWorkshopCart();
                    toggle();
                  }}
                >
                  Simpan
                </Button>
              ) : (
                <Button className="button-action save" onClick={toggle} disabled>
                  Simpan
                </Button>
              )}
            </Col>
          )}
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ModalChooseWorkshop;
