import API from '@utils/API';

class SearchItemService {
  async getPackages(payload) {
    try {
      const api = new API('v2');
      const response = await api.post('v3/intools/search/item/package', payload);
      return response;
    } catch (error) {
      throw error.response || new Error('Unexpected error occurred');
    }
  }

  async getProducts(payload) {
    try {
      const api = new API('v2');
      const response = await api.post('v3/intools/search/item/product', payload);
      return response;
    } catch (error) {
      throw error.response || new Error('Unexpected error occurred');
    }
  }
}

export default new SearchItemService();
