import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import EditableInputField from '@components/field/EditableInputField';

const CancelBookingInternalReasonModal = (props) => {
  const { open, toggle, headerTitle, placeholder, onChangeInternalNotes, onSubmit, disabled } = props;

  const [internalNotes, setInternalNotes] = useState('');
  const [errorInternalNotes, setErrorInternalNotes] = useState('');

  const handleSubmit = () => {
    if (internalNotes?.length < 2) {
      setErrorInternalNotes('Format Internal Notes minimal 2 karakter');
    } else if (internalNotes?.length > 2000) {
      setErrorInternalNotes('Format Internal Notes maksimal 2000 karakter');
    } else {
      setErrorInternalNotes('');
      onSubmit();
    }
  };

  useEffect(() => {
    onChangeInternalNotes(internalNotes);
  }, [internalNotes]);

  useEffect(() => {
    setInternalNotes('');
    setErrorInternalNotes('');
  }, [open]);

  return (
    <Modal className="modal-dialog-centered" isOpen={open} toggle={toggle}>
      <ModalHeader>{headerTitle}</ModalHeader>
      <ModalBody>
        <EditableInputField
          labelWidth={0}
          colWidth={12}
          placeholder={placeholder ?? ''}
          inputName="reason"
          inputType="textarea"
          value={internalNotes}
          onChange={(e) => {
            let msg = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : internalNotes;

            setInternalNotes(msg);
            if (msg.length < 2) {
              setErrorInternalNotes('Format Internal Notes minimal 2 karakter');
            } else if (msg.length > 2000) {
              setErrorInternalNotes('Format Internal Notes maksimal 2000 karakter');
            } else {
              setErrorInternalNotes('');
            }
          }}
          disabledFormText={false}
          formText={internalNotes.length + ' / 2000 characters'}
          invalid={errorInternalNotes !== '' ? true : false}
          errorMessage={errorInternalNotes}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" outline size="lg" onClick={() => toggle()}>
          Kembali
        </Button>
        <Button disabled={disabled} color="primary" size="lg" onClick={() => handleSubmit()}>
          Simpan
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CancelBookingInternalReasonModal;
