import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Wrapper from '@components/wrapper/Wrapper';
import { Row } from 'reactstrap';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import PushNotificationCreateForm from '@components/push-notifications/PushNotificationCreateForm';
import API from '@utils/API';
import userPermission from '@utils/userPermission';

function PushNotificationDetail() {
  const { id } = useParams();
  const api = new API('v2');
  const history = useHistory();
  const { generatePermission } = userPermission();

  if (!generatePermission('pushnotification', 'show')) {
    history.push('/push-notifications');
  }

  const [dataDetail, setDataDetail] = useState({});
  const [loading, setLoading] = useState(false);

  const breadcrumb = [
    {
      id: 1,
      label: 'Push Notifications List',
      active: false,
      url: '/push-notifications',
    },
    {
      id: 2,
      label: id,
      active: true,
      url: '',
    },
  ];

  const getDataDetail = async () => {
    setLoading(true);
    const res = await api.get(`v2/intools/fcm-notifications/blast/${id}`);
    setDataDetail(res?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getDataDetail();
  }, []);

  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <React.Fragment>
        <Wrapper title="Detail Push Notifications" breadcrumbData={breadcrumb}>
          <Row>
            <PushNotificationCreateForm viewOnly dataDetail={dataDetail} />
          </Row>
        </Wrapper>
      </React.Fragment>
    );
  };

  return renderContent();
}

export default PushNotificationDetail;
