import React, { useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import map from 'lodash/map';
import API from '@utils/API';

const EditInactiveInventoryModal = (props) => {
  const { modal, toggle, data, onSubmit } = props;

  const api = new API('v2');
  const [loading, setLoading] = useState(false);

  const goToPackage = (item) => {
    if (!loading) {
      window.open(`/packages/${item?.id}`, '_blank');
    }
  };

  async function submitForm() {
    setLoading(true);

    try {
      const selectedID = map(data, (item) => {
        return item?.id;
      });
      const payload = {
        id: selectedID,
      };

      const response = await api.get(`v2/intools/categories/list-categories-all/`);
      onSubmit(true);
      setLoading(false);
    } catch (error) {
      onSubmit(false);
      setLoading(false);
    }
  }

  const cancel = () => {
    toggle();
  };

  return (
    <Modal
      className="modal-dialog-centered modal-active-inactive-inventory"
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalBody className="body">
        <div className="header">Yakin ingin Matikan</div>
        <div className="content">
          <div className="notification">ini akan mengakibatkan Produk didalam Paket menghilang</div>
          <div className="list-inventory">
            <ul>
              {data.length > 0 &&
                map(data, (item) => {
                  return (
                    <li className="item" onClick={() => goToPackage(item)}>
                      {item?.product_name}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="action">
          <Button className="button-action inactive" disabled={loading} onClick={cancel}>
            Tidak
          </Button>
          <Button className="button-action inactive" disabled={loading} onClick={submitForm}>
            Ya
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EditInactiveInventoryModal;
