import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Col } from 'reactstrap';
import AddNewRoleModal from '@components/modal/AddNewRoleModal';
import RemoveRoleModal from '@components/modal/RemoveRoleModal';
import deleteIcon from '@assets/icons/trash.svg';
import editIcon from '@assets/icons/edit.svg';
import API from '@utils/API';
import Popup from 'react-popup';
import { useHistory } from 'react-router-dom';
import userPermission from '@utils/userPermission';
import ActionFilterACL from '@components/filters/ActionFilterACL';
import Loading from '@components/loading/Loading';

const Role = (props) => {
  const { data, loading, setRoleData } = props;
  const api = new API('v2');
  const history = useHistory();
  const { generatePermission } = userPermission();

  const [addNewRoleModal, setAddNewRoleModal] = useState(false);
  const [removeRoleModal, setRemoveRoleModal] = useState(false);
  const [roleName, setRoleName] = useState('');
  const [id, setId] = useState('');
  const [role, setRole] = useState('');
  const [errorName, setErrorName] = useState('');
  const [filterText, setFilterText] = useState('');

  const allowPermission = (menu) => {
    return !!(generatePermission('acl', menu) || generatePermission('acl', 'all'));
  };

  const allowList = allowPermission('all') || allowPermission('list');
  const allowFilter = allowPermission('all') || allowPermission('search');
  const allowCreate = allowPermission('all') || allowPermission('add');

  const handleRoleName = (e) => {
    setRoleName(e.target.value);
    setErrorName(!e.target.value ? 'Role Name cannot be empty!' : '');
  };

  const handleRemoveRoleModal = (id, name) => {
    setId(id);
    setRole(name);
    setRemoveRoleModal(!removeRoleModal);
  };

  const addNewRole = async () => {
    const params = {
      name: roleName,
    };

    try {
      const response = await api.post('v2/intools/roles/', params);
      setAddNewRoleModal(!addNewRoleModal);
      setRoleName('');
      setRoleData(response?.data?.data);
    } catch (e) {
      setErrorName(e.response?.data?.error?.message);
    }
  };

  const removeRole = async (id) => {
    api
      .delete(`v2/intools/roles/${id}/`)
      .then((response) => {
        setRemoveRoleModal(false);
        setRoleData(response?.data?.data);
      })
      .catch((e) => {
        setRemoveRoleModal(false);
        Popup.create({ title: 'Error', content: e.response?.data?.error?.message });
      });
  };

  const handleClickRow = (row) => {
    history.push(`/acl/edit/${row?.id}/`);
  };

  const roleData = () => {
    const list =
      data?.length > 0
        ? data
            ?.filter((dt) => dt?.name?.toLowerCase().includes(filterText.toLowerCase()))
            .map((item) => {
              item = {
                ...item,
              };
              return item;
            })
        : [];

    return list;
  };

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row) => row?.users,
        cell: (row) => row?.name,
        minWidth: '200px',
      },
      {
        name: 'Actions',
        cell: (row) => (
          <>
            <img src={editIcon} height={16} alt="" className="mr-3" onClick={() => handleClickRow(row)} />
            <img src={deleteIcon} height={16} alt="" onClick={() => handleRemoveRoleModal(row?.id, row?.name)} />
          </>
        ),
        right: true,
      },
    ],
    []
  );

  const createRole = () => {
    if (allowCreate) setAddNewRoleModal(!addNewRoleModal);
  };

  const subHeaderComponentMemo = useMemo(() => {
    return <ActionFilterACL value={filterText} handleChangeValue={setFilterText} />;
  }, [filterText, setFilterText]);

  return (
    <React.Fragment>
      <Col sm={12} md={12} className="p-0 mt-0 section-action">
        {allowCreate && (
          <Button className="button-action add w-170" onClick={createRole}>
            {'Create New Role'}
          </Button>
        )}
      </Col>
      {allowFilter && (
        <Col sm={12} md={12} className="p-0 mt-4 mb-0">
          <ActionFilterACL value={filterText} handleChangeValue={setFilterText} />
        </Col>
      )}
      <Col sm={12} md={12} className="p-0 mt-1">
        <DataTable
          noTableHead
          persistTableHead
          highlightOnHover
          noHeader={true}
          progressPending={loading}
          data={roleData()}
          fixedHeaderScrollHeight="239px"
          columns={allowList ? columns : []}
          className="table dt-responsive table-custom"
          progressComponent={allowList ? <Loading /> : <></>}
        />
      </Col>

      <AddNewRoleModal
        modal={addNewRoleModal}
        toggle={() => {
          setAddNewRoleModal(!addNewRoleModal);
          setRoleName('');
          setErrorName('');
        }}
        error={errorName}
        value={roleName}
        onChange={handleRoleName}
        onConfirm={addNewRole}
      />

      <RemoveRoleModal
        modal={removeRoleModal}
        toggle={() => {
          setRemoveRoleModal(!removeRoleModal);
        }}
        removeRole={() => removeRole(id)}
        role={role}
      />
    </React.Fragment>
  );
};

export default Role;
