import React from 'react';
import { Form, FormGroup, Input, Button } from 'reactstrap';

const ActionFilterByText = ({ placeholder, classWidth, searchText, onChangeText, onSearch }) => (
  <Form inline>
    <FormGroup className="mb-2 mb-sm-0">
      <Input
        placeholder={placeholder}
        className={`br-0 fixed-fc-height ${classWidth ? classWidth : ''}`}
        value={searchText}
        onChange={onChangeText}
      />
    </FormGroup>
    <Button className="btn-info btn-square" onClick={onSearch}>
      Search
    </Button>
  </Form>
);

export default ActionFilterByText;
