import React from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from 'reactstrap';
import { DISCOUNT_OPTIONS } from '@utils/Constants';
import EditableSelectField from '../field/EditableSelectField';
import Helper from '@helpers/Helper';
import SelectField from '../field/SelectField';

const CustomerPriceAgreement = (props) => {
  const { data, handleChangePriceAgreement, disableForm } = props;

  let discountOptions = [{ value: 0, label: '0%' }];
  discountOptions.push(DISCOUNT_OPTIONS);

  return (
    <Card>
      <CardHeader className="bg-primary text-white mt-0">Perjanjian Harga</CardHeader>
      <CardBody>
        <Row>
          <Col lg={3} className="p-0">
            <FormGroup>
              <Label lg={12} className="text-left">
                Fixed Markup Product
              </Label>
              <SelectField
                colWidth={12}
                inputName="markup-product"
                disabled={disableForm}
                textLeft
                value={data.product_markup}
                closeMenuOnSelect={true}
                options={Helper.generatePercentage(0, 100)}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                onChange={(value) => handleChangePriceAgreement('markup-product', value)}
              />
            </FormGroup>
          </Col>
          <Col lg={3} className="p-0">
            <FormGroup>
              <Label lg={12} className="text-left">
                Fixed Markup Jasa
              </Label>
              <SelectField
                colWidth={12}
                inputName="markup-jasa"
                disabled={disableForm}
                textLeft
                value={data.service_markup}
                closeMenuOnSelect={true}
                options={Helper.generatePercentage(0, 100)}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                onChange={(value) => handleChangePriceAgreement('markup-jasa', value)}
              />
            </FormGroup>
          </Col>
          <Col lg={3} className="p-0">
            <FormGroup>
              <Label lg={12} className="text-left">
                Fixed Disc. Product
              </Label>
              <SelectField
                colWidth={12}
                inputName="discount-product"
                disabled={disableForm}
                textLeft
                value={data.product_discount}
                closeMenuOnSelect={true}
                options={discountOptions}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                onChange={(value) => handleChangePriceAgreement('discount-product', value)}
              />
            </FormGroup>
          </Col>
          <Col lg={3} className="p-0">
            <FormGroup>
              <Label lg={12} className="text-left">
                Fixed Disc. Jasa
              </Label>
              <SelectField
                colWidth={12}
                inputName="discount-jasa"
                disabled={disableForm}
                textLeft
                value={data.service_discount}
                closeMenuOnSelect={true}
                options={discountOptions}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                onChange={(value) => handleChangePriceAgreement('discount-jasa', value)}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CustomerPriceAgreement;
