import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Card, CardBody, Spinner, Row } from 'reactstrap';
import moment from 'moment';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import DataTable from 'react-data-table-component';
import Loading from '@components/loading/Loading';
import LoadingSpinner from '@components/loading/LoadingSpinner';

const History = (props) => {
  const { id, onLoading } = props;
  const api = new API('v2');

  const [idWorkshop, setIdWorkshop] = useState();
  const { generatePermission } = userPermission();
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [page, setPage] = useState(1);
  const paginationOptions = { noRowsPerPage: true };
  const [pageLimit, setPageLimit] = useState(10);
  const columns = useMemo(
    () => [
      {
        name: 'Time Stamp',
        selector: (row) =>
          moment(row?.created_at).isValid() ? moment(row?.created_at).format('MMM DD YYYY , HH:mm') : '-',
        wrap: true,
        minWidth: '160px',
      },
      {
        name: 'Agent',
        selector: (row) => row?.created_by ?? '-',
        wrap: true,
        minWidth: '100px',
      },
      {
        name: 'Type Change',
        selector: (row) => row?.operation ?? '-',
        minWidth: '150px',
        wrap: true,
      },
    ],
    []
  );

  async function loadLogHistory() {
    setLoadingTable(true);
    const params = {
      page: page,
      limit: pageLimit,
    };

    try {
      const response = await api.get(`v2/intools/activity-logs/workshop/${id}/`, { params });
      setData(response?.data?.data?.activity_logs ?? response?.data?.data ?? []);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setLoadingTable(false);
    } catch (error) {
      setData([]);
      setTotalRows(0);
      setLoadingTable(false);
    }
  }

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleRowPerPageChange = (newPageLimit, page) => {
    setPage(page);
    setPageLimit(newPageLimit);
  };

  useEffect(() => {
    if (page && pageLimit) {
      loadLogHistory();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  return (
    <React.Fragment>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <Card className="card-custom">
          <CardBody className="p-0">
            <DataTable
              persistTableHead
              highlightOnHover
              pagination
              progressPending={loadingTable}
              data={data}
              noHeader={true}
              fixedHeader
              fixedHeaderScrollHeight="480px"
              columns={generatePermission('workshop', 'loghistory') ? columns : []}
              className="table table-bordered dt-responsive table-stnk"
              paginationServer
              paginationTotalRows={totalRows}
              paginationComponentOptions={paginationOptions}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowPerPageChange}
              progressComponent={<Loading />}
            />
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default History;
