import React, { useState, useMemo, useEffect } from 'react';
import { Badge } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Wrapper from '@components/wrapper/Wrapper';
import ExportButton from '@components/export/ExportButton';
import ActionFilterByText from '@components/filters/ActionFilterByText';
import Loading from '@components/loading/Loading';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import moment from 'moment';

function Promos() {
  const history = useHistory();
  const api = new API('v2');
  const { generatePermission } = userPermission();

  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPromoList(1);
  }, []);

  async function getPromoList(page) {
    setLoading(true);
    const searchValue = searchText.trim(); //remove space before and after value
    const response = await api.get(`v2/intools/promos/?page=${page}&limit=${perPage}&query=${searchValue}`);

    if (response.data.data) {
      setData(response.data.data);
      setTotalRows(response.data.pagination.total_rows);
    }

    setLoading(false);
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const searchValue = searchText.trim(); //remove space before and after value
    const response = await api.get(`v2/intools/promos/?page=${page}&limit=${newPerPage}&query=${searchValue}`);

    if (response.data.data) {
      setData(response.data.data);
      setPerPage(newPerPage);
    }

    setLoading(false);
  };

  const handlePageChange = (page) => {
    getPromoList(page);
  };

  const openPromoDetail = (row) => {
    if (generatePermission('promo', 'show')) {
      history.push(`/promos/${row.promo_code}`);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: 'promo_name',
        wrap: true,
        minWidth: '250px',
      },
      {
        name: 'Promo Code',
        selector: 'promo_code',
        wrap: true,
        minWidth: '150px',
      },
      {
        name: 'Start Date',
        minWidth: '200px',
        sortable: true,
        cell: (row) => (row?.start_date ? moment(row?.start_date).format('MMM D YYYY - h:mm:ss') : '-'),
      },
      {
        name: 'End Date',
        minWidth: '200px',
        sortable: true,
        cell: (row) => (row?.end_date ? moment(row?.end_date).format('MMM D YYYY - h:mm:ss') : '-'),
      },
      {
        name: 'Is Active',
        wrap: true,
        minWidth: '100px',
        cell: (row) =>
          row.is_active === true ? (
            <Badge className="p-2 badge-success">True</Badge>
          ) : (
            <Badge className="p-2 badge-warning">False</Badge>
          ),
      },
    ],
    []
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleSearch = () => {
      getPromoList(1);
    };

    return (
      <ActionFilterByText
        searchText={searchText}
        onChangeText={(e) => setSearchText(e.target.value)}
        onSearch={handleSearch}
        placeholder="Name or Code"
      />
    );
  }, [searchText]);

  return (
    <Wrapper title="List Promo" className="wrapperContent">
      <DataTable
        persistTableHead
        highlightOnHover
        pagination
        subHeader
        progressPending={loading}
        data={data}
        columns={generatePermission('promo', 'list') ? columns : []}
        className="table table-bordered dt-responsive"
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        progressComponent={generatePermission('promo', 'list') ? <Loading /> : <></>}
        subHeaderComponent={generatePermission('promo', 'search') ? subHeaderComponentMemo : ''}
        onRowClicked={(row) => openPromoDetail(row)}
      />
    </Wrapper>
  );
}

export default Promos;
