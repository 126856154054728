import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import EditableInputField from '@components/field/EditableInputField';

const RevertStatusModal = (props) => {
  const { open, toggle, headerTitle, placeholder, onChangeRevertStatusNotes, onSubmit } = props;

  const [revertStatusNotes, setrevertStatusNotes] = useState('');
  const [errorRevertStatusNotes, seterrorRevertStatusNotes] = useState('');

  const handleSubmit = () => {
    if (revertStatusNotes?.length > 2000) {
      seterrorRevertStatusNotes('Format Revert Notes maksimal 2000 karakter');
    } else {
      seterrorRevertStatusNotes('');
      onSubmit();
    }
  };

  useEffect(() => {
    onChangeRevertStatusNotes(revertStatusNotes);
  }, [revertStatusNotes]);

  useEffect(() => {
    setrevertStatusNotes('');
  }, [open]);

  return (
    <Modal className="modal-dialog-centered" isOpen={open} toggle={toggle}>
      <ModalHeader>{headerTitle}</ModalHeader>
      <ModalBody>
        <EditableInputField
          labelWidth={0}
          colWidth={12}
          placeholder={placeholder ?? ''}
          inputName="reason"
          inputType="textarea"
          value={revertStatusNotes}
          onChange={(e) => {
            let msg = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : revertStatusNotes;

            setrevertStatusNotes(msg);
            if (msg.length > 2000) {
              seterrorRevertStatusNotes('Format Revert Notes maksimal 2000 karakter');
            } else {
              seterrorRevertStatusNotes('');
            }
          }}
          disabledFormText={false}
          formText={revertStatusNotes.length + ' / 2000 characters'}
          invalid={errorRevertStatusNotes !== '' ? true : false}
          errorMessage={errorRevertStatusNotes}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" outline size="lg" onClick={() => toggle()}>
          Kembali
        </Button>
        <Button color="primary" size="lg" onClick={() => handleSubmit()}>
          Simpan
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RevertStatusModal;
