import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav, NavItem, NavLink, Col, Card, CardBody, Row } from 'reactstrap';
import API from '@utils/API';
import qs from 'query-string';
import filter from 'lodash/filter';
import classnames from 'classnames';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import BrandList from '@components/masters/brand/BrandList';
import ProductList from '@components/masters/product/ProductList';
import OtoklixServiceList from '@components/masters/otoklix-service/OtoklixServiceList';
import OtoklixInventoryList from '@components/masters/otoklix-inventory/OtoklixInventoryList';

const Masters = () => {
  const api = new API('v2');
  const history = useHistory();
  const queryParams = useLocation().search;
  const activeTabParam = new URLSearchParams(queryParams).get('activetab');
  const { generatePermission } = userPermission();

  const [hasNotif, setHasNotif] = useState();
  const [activeTab, setActiveTab] = useState('');
  const [notification, setNotification] = useState({});

  const allowPermission = (menu) => {
    return !!(generatePermission('master', menu) || generatePermission('master', 'all'));
  };

  const allowBrand =
    allowPermission('productbrandall') || allowPermission('productbrandlist') || allowPermission('productbrandcreate');
  const allowProduct =
    allowPermission('productall') || allowPermission('productlist') || allowPermission('productcreate');
  const allowOtoklixInventory =
    allowPermission('otoklixinventoryall') ||
    allowPermission('otoklixinventorylist') ||
    allowPermission('otoklixinventorycreate');
  const allowOtoklixService =
    allowPermission('otoklixservicefeeall') ||
    allowPermission('otoklixservicefeelist') ||
    allowPermission('otoklixservicefeecreate');

  const tabsData = [
    {
      key: 'brand',
      label: 'Brand',
      allow_permission: allowBrand,
    },
    {
      key: 'product',
      label: 'Product',
      allow_permission: allowProduct,
    },
    {
      key: 'otoklix-inventory',
      label: 'Otoklix Inventory',
      allow_permission: allowOtoklixInventory,
    },
    {
      key: 'otoklix-service',
      label: 'Otoklix Service',
      allow_permission: allowOtoklixService,
    },
  ];

  const toggleActiveTab = (tab) => {
    let params = qs.parse(history?.location?.search);
    params.activetab = tab;
    delete params.search;
    delete params.display_search;
    delete params.brand;
    delete params.category;
    delete params.status;
    api.cancel();

    setActiveTab(tab);
    history.push({ search: qs.stringify(params) });
  };

  const checkTabs = () => {
    const availableTabs =
      tabsData?.length &&
      filter(tabsData, (item) => {
        return item?.key == activeTabParam && item?.allow_permission;
      });

    const allowTabs =
      tabsData?.length &&
      filter(tabsData, (item) => {
        return item?.allow_permission;
      });

    const tabActive = !availableTabs?.length ? (!activeTabParam ? allowTabs[0]?.key ?? '' : '') : activeTabParam;
    setActiveTab(tabActive);

    let params = qs.parse(history?.location?.search);
    params.activetab = tabActive;
    history.push({ search: qs.stringify(params) });
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  useEffect(() => {
    checkTabs();
  }, [activeTabParam]);

  useEffect(() => {
    if (!allowBrand && !allowProduct && !allowOtoklixInventory && !allowOtoklixService) {
      history.push('/404');
    }
  }, []);

  return (
    <Wrapper
      title="List Master"
      className="wrapperContent"
      showNotification={hasNotif}
      notification={notification}
      onCloseNotification={handleCloseNotification}
    >
      <Nav tabs className="user-role-tabs">
        {tabsData?.length !== 0 &&
          tabsData.map((item) => {
            if (item?.allow_permission) {
              return (
                <NavItem key={item?.key}>
                  <NavLink
                    className={classnames({ active: activeTab === item?.key })}
                    onClick={() => toggleActiveTab(item?.key)}
                  >
                    {item?.label}
                  </NavLink>
                </NavItem>
              );
            }
          })}
      </Nav>

      {activeTab !== '' && (
        <Card className="card-custom none-border-radius-top card-custom-p-15-15">
          <CardBody className="p-0">
            <Row>
              {activeTab == 'brand' && allowBrand && (
                <Col sm={12} md={12}>
                  <BrandList onResultNotification={handleResultNotification}></BrandList>
                </Col>
              )}

              {activeTab == 'product' && allowProduct && (
                <Col sm={12} md={12}>
                  <ProductList onResultNotification={handleResultNotification}></ProductList>
                </Col>
              )}

              {activeTab == 'otoklix-inventory' && allowOtoklixInventory && (
                <Col sm={12} md={12}>
                  <OtoklixInventoryList onResultNotification={handleResultNotification}></OtoklixInventoryList>
                </Col>
              )}

              {activeTab == 'otoklix-service' && allowOtoklixService && (
                <Col sm={12} md={12}>
                  <OtoklixServiceList onResultNotification={handleResultNotification}></OtoklixServiceList>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      )}
    </Wrapper>
  );
};

export default Masters;
