import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardBody, Row, Col, Input, Label } from 'reactstrap';
import userPermission from '@utils/userPermission';
import API from '@utils/API';
import moment from 'moment';
import Loading from '@components/loading/Loading';
import DataTable from 'react-data-table-component';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import ActionFilterCustom from '@components/filters/ActionFilterCustom';
import CustomPagination from '@components/pagination/CustomPagination';

const VoucherTab = (props) => {
  const { id, onLoading, onResultNotification } = props;

  const api = new API('v2');
  const { generatePermission } = userPermission();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState();
  const [disableButton, setDisableButton] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('campaign', menu) || generatePermission('campaign', 'all'));
  };

  const allowList = allowPermission('detailvoucherall') || allowPermission('detailvoucherlist');
  const allowSearch = allowPermission('detailvoucherall') || allowPermission('detailvouchersearch');
  const allowEdit = allowPermission('detailvoucherall') || allowPermission('detailvoucheredit');

  const customStylesHeaderTable = {
    headCells: {
      style: {
        bacgroundColor: '#F9F9F9',
      },
    },
  };

  const getIndex = (code) => {
    const index = data.findIndex(function (obj) {
      return obj.code == code;
    });

    return index;
  };

  const columns = useMemo(
    () => [
      {
        name: 'Voucher Code',
        selector: (row) => row?.code,
        wrap: true,
        sortable: true,
        minWidth: '400px',
      },
      {
        name: 'Create Date',
        selector: (row) => (
          <div className="subscriptions_list_created_at">
            <span className="date">
              {moment(row?.created_date ?? null).isValid() ? moment(row?.created_date).format('DD MMM, YYYY') : '-'}
            </span>
            <span className="time">
              {moment(row?.created_date ?? null).isValid() ? moment(row?.created_date).format('HH:mm:ss') : '-'}
            </span>
          </div>
        ),
        width: '130px',
        wrap: true,
      },
      {
        name: 'Redeemtion Counts',
        selector: (row) => (row?.quota_limit ? row?.quota_used + '/' + row?.quota_limit : '-/-'),
        wrap: true,
        minWidth: '130px',
      },
      {
        name: 'Active/Deactive',
        selector: (row) => (
          <div className="custom-control custom-switch switch-secondary mt-1">
            <Input
              type="checkbox"
              className="custom-control-input"
              id={'is_active_' + row?.code}
              name={'is_active_' + row?.code}
              onChange={() => handleSwitchChange(row?.code, row?.is_active ? false : true)}
              checked={row?.is_active}
              disabled={disableButton}
            />
            <Label className="custom-control-label" htmlFor={'is_active_' + row?.code}>
              {/* {dataCampaign?.is_show_list ? labelChecked : labelUnchecked} */}
            </Label>
          </div>
        ),
        wrap: true,
        minWidth: '130px',
      },
    ],
    []
  );

  const handleSwitchChange = (code, value) => {
    if (allowEdit) {
      setDisableButton(true);
      handleChangeStatusVoucher(code, value);
      setDisableButton(false);
    }
  };

  const handleChangeStatusVoucher = async (code, value) => {
    const payload = {
      is_active: value,
    };

    try {
      const response = await api.post(`v2/intools/campaigns/vouchers/${code}/status/`, payload);
      if (response?.data?.data) {
        fetchListVouchers();
        handleResultNotification(true, 'success', 'Berhasil update voucher');
      } else {
        handleResultNotification(true, 'fail', 'Gagal update voucher');
      }
    } catch (e) {
      const message = `API Error : ${e?.response?.data?.error?.message}`;
      handleResultNotification(true, 'fail', message);
    }
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const dataFilter = [
    {
      id: 'filter_text_voucher_code',
      type: 'text',
      name: 'voucher_code',
      name_field: 'voucher_code',
      value: '',
      placeholder: 'Cari Voucher Code',
    },
  ];

  const noDataComponent = useMemo(() => {
    return <div className="no-record p-4">{'Tidak Ada Hasil'}</div>;
  }, []);

  async function fetchListVouchers() {
    setLoading(true);

    const params = getParamData();
    try {
      const response = await api.get(`v2/intools/campaigns/${id}/vouchers/`, { params });
      setData(response?.data?.data ?? []);
      handleGetVoucherList(response?.data?.data);
      setTotalRows(response?.data?.pagination?.total_rows ?? 0);
      setTotalPages(response?.data?.pagination?.total_page ?? 0);
      validateCountPage(response);
    } catch (error) {
      const errorMsg = error?.response?.data?.error?.message ?? '';
      setPage(1);
      setData([]);
      handleResultNotification(true, 'fail', 'Voucher List tidak ditemukan' + errorMsg);
      setTotalRows(0);
      setTotalPages(0);
      setLoading(false);
      setFirstLoad(false);
    }
  }

  const handleGetVoucherList = (data) => {
    if (!data) {
      handleResultNotification(true, 'fail', 'Voucher List tidak ditemukan');
    }
  };

  const getParamData = () => {
    const params = {
      query: filtered?.voucher_code ?? '',
      page: page,
      limit: pageLimit,
    };

    return params;
  };

  const handleResetFilters = () => {
    setFilters(dataFilter);
    setFiltered({});
  };

  const validateCountPage = (response) => {
    if (page > response?.data?.pagination?.total_page) {
      setPage(response?.data?.pagination?.total_page);
      setLoading(false);
      setFirstLoad(false);
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleChangeRowPerPage = (e) => {
    setPageLimit(e);
  };

  const handleMethodFetchVouchers = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchListVouchers();
    }
  };

  useEffect(() => {
    if (page && pageLimit) {
      fetchListVouchers();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    if (!firstLoad) {
      handleMethodFetchVouchers();
    }
    if (firstLoad && filtered) {
      fetchListVouchers();
    }
  }, [filtered]);

  useEffect(() => {
    onLoading(firstLoad);
  }, [firstLoad]);

  useEffect(() => {
    setFilters(dataFilter);
  }, []);

  return (
    <React.Fragment>
      {firstLoad && <LoadingSpinner></LoadingSpinner>}
      {!firstLoad && (
        <>
          <Card className="card-custom">
            <CardBody className="p-0">
              <Row>
                <Col sm={12} md={12}>
                  {filters?.length !== 0 && allowSearch && (
                    <Col sm={12} md={12} className="p-0 mt-4">
                      <ActionFilterCustom
                        filters={filters}
                        loading={loading}
                        onApplyFilters={(e) => setFiltered(e)}
                        onResetFilters={handleResetFilters}
                      ></ActionFilterCustom>
                    </Col>
                  )}

                  <Col sm={12} md={12} className="p-0 mt-4">
                    <DataTable
                      persistTableHead
                      highlightOnHover
                      noHeader={true}
                      progressPending={loading}
                      data={data}
                      className="table dt-responsive table-custom"
                      noDataComponent={noDataComponent}
                      progressComponent={<Loading />}
                      customStyles={customStylesHeaderTable}
                      columns={allowList ? columns : []}
                    />
                  </Col>
                  <Col sm={12} md={12} className="p-0 mt-2">
                    {data?.length >= 1 && !loading && allowList && (
                      <CustomPagination
                        page={page}
                        pageLimit={pageLimit}
                        totalRows={data?.length ?? 0}
                        totalAllRows={totalRows}
                        totalPages={totalPages}
                        handleChangePage={handleChangePage}
                        handleChangeRowPerPage={handleChangeRowPerPage}
                      ></CustomPagination>
                    )}
                  </Col>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      )}
    </React.Fragment>
  );
};

export default VoucherTab;
