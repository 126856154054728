import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';

const ConfirmAddInventoryModal = (props) => {
  const { isOpen, toggle, onProccess, isLoading } = props;

  return (
    <Modal className="modal-confirm-inventory modal-dialog-centered" isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Konfirmasi Tambah Stok Inventory</ModalHeader>
      <ModalBody>
        <p>
          Jika kamu memproses ini, maka secara otomatis di Jurnal akan auto create PO. Apakah kamu akan tetap memproses
          ini?
        </p>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="danger" size="md" outline onClick={() => toggle()} disabled={isLoading}>
          Kembali
        </Button>
        <Button color="primary" size="md" onClick={() => onProccess()} disabled={isLoading}>
          {!isLoading ? (
            'Ya, Proses'
          ) : (
            <Spinner size="sm" color="dark">
              Loading...
            </Spinner>
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmAddInventoryModal;
