import API from '@utils/API';
import { assign, reject } from 'lodash';
import jiraService from '../services/jira.service';

const api = new API('v2');

export const handleReschedule = (bookingCode, bookingDate, bookingTime) => () => {
  const formData = new FormData();

  formData.append('booking_code', bookingCode);
  formData.append('booking_datetime', `${bookingDate} ${bookingTime}:00`);

  const callApi = new Promise((resolve, reject) => {
    api
      .put('v2/intools/bookings/reschedule/', formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

  return callApi;
};

export const handleChangeStatusBooking = (
  bookingStatus,
  bookingCode,
  cancelReasonId,
  cancelNotes,
  internalNotes,
  previousStatus,
  revertNotes
) => () => {
  let params = {
    booking_status: bookingStatus,
    booking_code: bookingCode,
  };

  if (bookingStatus === 'cancel') {
    assign(params, {
      cancel_reason_id: cancelReasonId,
      cancel_notes: cancelNotes,
      note: internalNotes,
    });
  }

  if (previousStatus === 'cancel') {
    assign(params, {
      revert_notes: revertNotes,
    });
  }

  const callApi = new Promise((resolve, reject) => {
    api
      .put(`v2/intools/bookings/booking-status-update/`, params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

  return callApi;
};

export const handleChangeStatusIssue = (
  bookingStatus,
  bookingCode,
  cancelReasonId,
  cancelNotes,
  internalNotes,
  previousStatus,
  revertNotes,
  jiraTicket
) => () => {
  let params = {
    issue_status: bookingStatus,
    booking_code: bookingCode,
  };

  if (bookingStatus === 'cancel') {
    assign(params, {
      cancel_reason_id: cancelReasonId,
      cancel_notes: cancelNotes,
      note: internalNotes,
    });
  }

  if (previousStatus === 'cancel') {
    assign(params, {
      revert_notes: revertNotes,
    });
  }

  const callApi = new Promise((resolve, reject) => {
    api
      .put(`v2/intools/bookings/booking-issue-status-update/`, params)
      .then((response) => {
        resolve(response.data);

        // Call service sync jira
        if (jiraTicket?.ticketKey) {
          const bookingCode = response?.data?.data?.booking_code;
          jiraService.syncJira(bookingCode);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });

  return callApi;
};

export const handleSearchWorkshop = (val, index) => () => {
  const callApi = new Promise((resolve, reject) => {
    api
      .get(`v2/search/?q=${val}&filter_search=workshop`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

  return callApi;
};

export const handleChangeWorkshop = (bookingCode, dataWorkshop) => () => {
  let workshopSlug = '';
  if (dataWorkshop) {
    workshopSlug = dataWorkshop[0].slug;
  }
  const formData = new FormData();

  formData.append('booking_code', bookingCode);
  formData.append('workshop_slug', workshopSlug);

  const callApi = new Promise((resolve, reject) => {
    api
      .put('v2/intools/bookings/change-workshop/', formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

  return callApi;
};
