import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const RemoveRoleModal = (props) => {
  const { modal, toggle, removeRole, role } = props;

  return (
    <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggle}>
      <ModalHeader>Delete Role</ModalHeader>
      <ModalBody>
        <p>
          Do you really want to delete this <b>{role}</b> role?
        </p>
      </ModalBody>
      <ModalFooter>
        <div className="m-auto d-flex justify-content-around w-50">
          <Button color="danger" size="lg" className="rounded-pill px-4" onClick={removeRole}>
            Confirm
          </Button>
          <Button color="danger" outline className="rounded-pill px-4" size="lg" onClick={() => toggle()}>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveRoleModal;
