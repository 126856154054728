import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Button, Spinner } from 'reactstrap';
import TabsScroll from '@components/tabs/TabsScroll';
import InventoryList from '@components/workshops/inventory/InventoryList';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import LogInventoryModal from '@components/modal/LogInventoryModal';
import API from '@utils/API';
import map from 'lodash/map';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { find } from 'lodash';
import RefreshDataModal from '../modal/RefreshDataModal';

const Inventory = (props) => {
  const { id, onLoading, dataWorkshop, onResultNotification } = props;
  const api = new API('v2');
  const history = useHistory();
  let qp = qs.parse(history?.location?.search);

  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [scrollTabsData, setScrollTabsData] = useState([]);
  const [activeTab, setActiveTab] = useState({});
  const [showLog, setShowLog] = useState(false);
  const [showDataRefresh, setShowDataRefresh] = useState(false);
  const [warehouseId, setWarehouseId] = useState('');

  const isFlagship = dataWorkshop?.tier?.value === 'flagship_plus' || dataWorkshop?.tier?.value === 'otoxpress';

  async function loadScrollTabs() {
    setMainLoading(true);
    let listTab = [{ id: 'inventory_' + 0, name: 'Semua', active: true }];

    try {
      const response = await api.get(`v2/intools/workshops/${id}/categories/`);
      let responseData = response?.data?.data ?? [];
      responseData = Array.isArray(responseData) ? responseData : [];
      let resultListTab = map(responseData, (item, index) => {
        return {
          id: `inventory_${index + 1}`,
          name: item,
          active: false,
        };
      });
      resultListTab.splice(0, 0, listTab[0]);
      listTab = resultListTab;

      setScrollTabsData(listTab);
      setMainLoading(false);
    } catch (error) {
      setScrollTabsData(listTab);
      setMainLoading(false);
    }
  }

  const handleLoading = (e) => {
    setLoading(e);
  };

  const handleChangeActiveTab = (tab, restart) => {
    const payload = { ...qp, activecategory: tab?.id };
    setActiveTab(tab);
    if (restart) {
      delete payload.search;
      delete payload.display_search;
      delete payload.status;
      delete payload.price_from;
      delete payload.price_to;
    }

    history.push({ search: qs.stringify(payload) });
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const handleSuccessAddInventory = () => {
    setActiveTab();
    loadScrollTabs();
  };

  const fetchWarehouseList = async () => {
    await api
      .get('v2/intools/warehouses')
      .then(async (res) => {
        let dt = res?.data?.data;
        const warehouseId = dt.filter((item) => item?.slug === dataWorkshop?.slug);
        setWarehouseId(warehouseId?.[0]?.id);
      })
      .catch((err) => {
        console.error(err);
        handleResultNotification(true, 'fail', `Error: ${' '}${err?.message}`);
      });
  };

  useEffect(() => {
    fetchWarehouseList();
    loadScrollTabs();
  }, []);

  useEffect(() => {
    if (qp.activecategory && scrollTabsData?.length > 0) {
      const selectedCategory = find(scrollTabsData, { id: qp.activecategory });

      handleChangeActiveTab(selectedCategory);
    }
  }, [qp.activecategory, scrollTabsData]);

  useEffect(() => {
    onLoading(mainLoading);
  }, [mainLoading]);

  return (
    <React.Fragment>
      {mainLoading && <LoadingSpinner></LoadingSpinner>}
      {!mainLoading && scrollTabsData?.length !== 0 && (
        <>
          <Row className="mb-2">
            <Col sm={12} md={12} className="d-flex justify-content-end">
              {dataWorkshop?.is_gms ? (
                <Button className="btn btn-purple mr-2" onClick={() => setShowLog(!showLog)}>
                  Log PO
                </Button>
              ) : (
                ''
              )}
              {/* {isFlagship ? (
                <Button className="btn btn-purple" onClick={() => setShowDataRefresh(true)}>
                  Refresh Data
                </Button>
              ) : (
                ''
              )} */}
            </Col>
          </Row>
          <Card className="card-custom">
            <CardBody className="p-0">
              <Row>
                <Col sm={12} md={12}>
                  <TabsScroll
                    loading={loading}
                    tabs={scrollTabsData}
                    onChangeActiveTab={handleChangeActiveTab}
                    initialActiveTabs={activeTab}
                  ></TabsScroll>
                </Col>
                <Col sm={12} md={12}>
                  <InventoryList
                    id={id}
                    activeTab={activeTab}
                    onLoading={handleLoading}
                    dataWorkshop={dataWorkshop}
                    handleResultNotification={handleResultNotification}
                    handleSuccessAddInventory={handleSuccessAddInventory}
                    qp={qp}
                  ></InventoryList>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      )}
      <LogInventoryModal
        isOpen={showLog}
        toggle={() => setShowLog(!showLog)}
        id={id}
        activeTab={activeTab}
        warehouseId={warehouseId}
      />
      <RefreshDataModal
        isOpen={showDataRefresh}
        toggle={() => setShowDataRefresh(!showDataRefresh)}
        handleResultNotification={handleResultNotification}
      />
    </React.Fragment>
  );
};

export default Inventory;
