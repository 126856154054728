import React, { useState, useEffect } from 'react';
import { findIndex } from 'lodash';
import map from 'lodash/map';
import ConfirmationModal from '@components/modal/ConfirmationModal';

function Tabs(props) {
  const {
    tabs,
    control,
    resultValidationForm,
    onShowModal,
    onChangeActiveTab,
    onChangeValidationForm,
    loading,
    initialActiveTabs,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [tabsData, setTabsData] = useState([]);
  const [activeTab, setActiveTab] = useState({});
  const [tempTab, setTempTab] = useState({});
  const [isRestart, setIsRestart] = useState(false);

  const initialTabs = async () => {
    const initActiveTab = initialActiveTabs ? initialActiveTabs : tabs[0];

    await setTabsData(tabs);
    setActiveTab(initActiveTab);
  };

  const handleActionMode = (isEdit, slug) => {
    const tabIndex = findIndex(tabsData, (value) => {
      return value?.slug === slug;
    });

    if (tabIndex !== -1) {
      tabsData[tabIndex].edit_mode = isEdit;
      setTabsData([...tabsData]);
      setActiveTab(tabsData[tabIndex]);
    }
  };

  const handleClick = (tab) => {
    if (!loading) {
      if (tab?.slug !== activeTab.slug) {
        if (activeTab?.edit_mode) {
          setTempTab(tab);
          onShowModal();
          setShowModal(true);
        } else {
          handleChangeTabs(tab);
        }
        setIsRestart(true);
      }
    }
  };

  const handleChangeTabs = (tab) => {
    const newTabsData = map(tabsData, (item) => {
      return {
        title: item.title,
        slug: item.slug,
        active: item?.slug === tab?.slug ? true : false,
        edit_mode: false,
        allow_permission: item.allow_permission,
      };
    });

    setTabsData(newTabsData);
    setActiveTab(tab);
  };

  const handleResultValidation = () => {
    handleChangeTabs(tempTab);
  };

  const handleConfirmModal = () => {
    onChangeValidationForm();
    setShowModal(!showModal);
  };

  const classNameTabItem = (item) => {
    let className = 'tab-item';
    className = item?.slug === activeTab?.slug ? className + ' active' : className;
    className = loading ? className + ' disabled' : className;

    return className;
  };

  useEffect(() => {
    handleActionMode(control?.action, control?.slug);
  }, [control]);

  useEffect(() => {
    onChangeActiveTab(activeTab, isRestart);
  }, [activeTab]);

  useEffect(() => {
    if (resultValidationForm) {
      handleResultValidation();
    }
  }, [resultValidationForm]);

  useEffect(() => {
    initialTabs();
  }, []);

  return (
    <React.Fragment>
      <div className="tabs">
        {tabsData?.length !== 0 &&
          tabsData.map((item) => (
            <>
              {item?.allow_permission ? (
                <div
                  className={classNameTabItem(item)}
                  key={item.slug}
                  onClick={() => {
                    handleClick(item);
                  }}
                >
                  {item.title}
                </div>
              ) : (
                <></>
              )}
            </>
          ))}
      </div>
      <ConfirmationModal
        modal={showModal}
        toggle={() => setShowModal(!showModal)}
        header={'Anda yakin pindah halaman sebelum menyimpannya?'}
        subHeader={'Aksi ini akan mengakibatkan data yang Anda masukkan tersimpan'}
        onConfirm={handleConfirmModal}
        toggleClose={() => setShowModal(!showModal)}
      ></ConfirmationModal>
    </React.Fragment>
  );
}

export default Tabs;
