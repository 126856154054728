import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Label, Button } from 'reactstrap';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import InputField from '@components/field/InputField';
import ConfirmationModal from '@components/modal/ConfirmationModal';

const BrandSectionDetail = (props) => {
  const { detailBrand, onResultNotification, onSubmit } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const [formData, setFormData] = useState({});
  const [modalType, setModalType] = useState('');
  const [disableForm, setDisableForm] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [loadingSumbit, setLoadingSubmit] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('master', menu) || generatePermission('master', 'all'));
  };

  const allowEdit =
    allowPermission('productbrandall') ||
    allowPermission('productbranddetailall') ||
    allowPermission('productbranddetailedit');

  const setDefaultData = () => {
    const dataForm = {
      id: detailBrand?.brand_id ?? '',
      name: detailBrand?.brand_name ?? '',
      error_name: '',
    };

    setFormData(dataForm);
  };

  const setChangeValueForm = (key, value, errorKey, errorMsg) => {
    formData[key] = value;
    if (errorKey) formData[errorKey] = errorMsg;
    setFormData({ ...formData });
  };

  const handleChangeBrandName = (e) => {
    validateBrandName(e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : formData?.name);
  };

  const validateBrandName = (value) => {
    let errorMsg = '';
    if (value.length == 0) {
      errorMsg = 'Nama Brand tidak boleh kosong';
    } else if (value.length > 100) {
      errorMsg = 'Format Nama Brand maksimal 100 karakter';
    }
    setChangeValueForm('name', value, 'error_name', errorMsg);
    return errorMsg ? false : true;
  };

  const editForm = () => {
    setDisableForm(false);
  };

  const cancelEditForm = () => {
    setModalType('Batalkan');
    setConfirmModal(true);
  };

  const handleSubmit = () => {
    const isValid = validateBrandName(formData?.name);

    if (isValid) {
      setModalType('Simpan');
      setConfirmModal(true);
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
  };

  const handleResultNotification = (status, type, message) => {
    onResultNotification({ status: status, type: type, message: message });
  };

  async function updateDetailBrand() {
    setConfirmModal(false);
    setLoadingSubmit(true);

    const payload = {
      brand_name: formData?.name ?? null,
    };

    try {
      const response = await api.put(`v2/intools/master/product-brands/${formData?.id}/`, payload);
      onSubmit(true);
      setDisableForm(true);
      setLoadingSubmit(false);
    } catch (error) {
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoadingSubmit(false);
    }
  }

  const restoreDetailBrand = () => {
    setDefaultData();
    setDisableForm(true);
    setConfirmModal(false);
  };

  useEffect(() => {
    setDefaultData();
  }, [detailBrand]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="bg-primary text-white mt-0">Brand Detail</CardHeader>
        <CardBody>
          <Col lg={12}>
            <FormGroup row>
              <Label lg={3}>Brand ID</Label>
              <InputField
                colWidth={9}
                inputType={'text'}
                disabled={true}
                placeholder="Brand ID"
                value={formData?.id ?? ''}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={3} className="label-required">
                Nama Brand
              </Label>
              <InputField
                colWidth={9}
                inputType={'text'}
                disabled={disableForm}
                placeholder="Nama Brand"
                value={formData?.name ?? ''}
                invalid={formData?.error_name ? true : false}
                errorMessage={formData?.error_name ?? ''}
                onChange={handleChangeBrandName}
              />
            </FormGroup>
          </Col>
          <Row className="mt-3">
            {allowEdit && (
              <Col sm={12} md={12} className="section-action">
                <Button
                  className="button-action save"
                  type="button"
                  disabled={loadingSumbit}
                  onClick={() => {
                    disableForm ? editForm() : handleSubmit();
                  }}
                >
                  {disableForm ? 'Edit' : 'Simpan'}
                </Button>
                {!disableForm && (
                  <Button className="button-action cancel" disabled={loadingSumbit} onClick={cancelEditForm}>
                    Batalkan
                  </Button>
                )}
              </Col>
            )}
          </Row>
        </CardBody>
        <ConfirmationModal
          modal={confirmModal}
          toggle={() => setConfirmModal(!confirmModal)}
          header={`Anda yakin ingin ${modalType === 'Simpan' ? 'menyimpan ' : 'membatalkan '} ?`}
          subHeader={`Aksi ini akan mengakibatkan hasil edit master brand ${detailBrand?.brand_name} ${
            modalType === 'Simpan' ? ' tersimpan' : ' tidak tersimpan'
          }`}
          onConfirm={() => (modalType === 'Simpan' ? updateDetailBrand() : restoreDetailBrand())}
          toggleClose={() => setConfirmModal(!confirmModal)}
        ></ConfirmationModal>
      </Card>
    </React.Fragment>
  );
};

export default BrandSectionDetail;
