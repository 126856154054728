import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import API from '@utils/API';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import OtoklixInventorySectionDetail from '@components/masters/otoklix-inventory/OtoklixInventorySectionDetail';
import OtoklixInventorySectionHistory from '@components/masters/otoklix-inventory/OtoklixInventorySectionHistory';

const OtoklixInventoryDetail = (props) => {
  const { idInventory, activeSection } = props;
  const { generatePermission } = userPermission();

  const api = new API('v2');
  const history = useHistory();
  const [hasNotif, setHasNotif] = useState();
  const [loading, setLoading] = useState(false);
  const [fisrtLoad, setFirstLoad] = useState(true);
  const [notification, setNotification] = useState({});
  const [mainLoading, setMainLoading] = useState(true);
  const [dataDetailInventory, setDataDetailInventory] = useState();

  const allowPermission = (menu) => {
    return !!(generatePermission('master', menu) || generatePermission('master', 'all'));
  };

  const allowShow = allowPermission('otoklixinventoryall') || allowPermission('otoklixinventoryshow');
  const allowProductDetail =
    allowPermission('otoklixinventoryall') ||
    allowPermission('otoklixinventorydetailall') ||
    allowPermission('otoklixinventorydetailshow');
  const allowProductHistory = allowPermission('otoklixinventoryall') || allowPermission('otoklixinventorylog');

  const breadcrumb = [
    {
      id: 1,
      label: 'List Master',
      active: false,
      url: activeSection?.url,
    },
    {
      id: 2,
      label: activeSection?.label ?? '',
      active: false,
      url: '',
    },
    {
      id: 3,
      label: dataDetailInventory?.product?.name ?? '',
      active: true,
      url: '',
    },
  ];

  async function fetchDetailInventory() {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/master/otoklix-inventories/${idInventory}`);
      setDataDetailInventory(response?.data?.data ?? {});
      handleGetInventoryDetail(response?.data?.data ?? {});
      setLoading(false);
      setFirstLoad(false);
    } catch (error) {
      const errorMsg = error?.response?.data?.error?.message ?? '';
      setDataDetailInventory({});
      handleResultNotification({
        status: true,
        type: 'fail',
        message: 'Gagal mendapatkan detail data master otoklix inventory ' + errorMsg,
      });
      setLoading(false);
      setFirstLoad(false);
    }
  }

  const handleGetInventoryDetail = (data) => {
    if (!data?.id) {
      handleResultNotification({
        status: true,
        type: 'fail',
        message: 'Detail data master otoklix inventory tidak ditemukan',
      });
    }
  };

  const handleSubmitEditInventoryDetail = (status, error) => {
    const errorMsg = error
      ? 'Gagal - Inventory Otoklix Gagal Diubah, ' + error
      : 'Gagal - Inventory Otoklix Gagal Diubah';
    if (status) {
      fetchDetailInventory();
    }
    handleResultNotification({
      status: true,
      type: status ? 'success' : 'fail',
      message: status ? 'Berhasil - Inventory Otoklix Berhasil Diubah' : errorMsg,
    });
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  useEffect(() => {
    setMainLoading(loading && fisrtLoad ? true : false);
  }, [loading]);

  useEffect(() => {
    if (!allowShow || (!allowProductDetail && !allowProductHistory)) {
      history.push('/404');
    } else if (allowProductDetail) {
      fetchDetailInventory();
    }
  }, []);

  return (
    <React.Fragment>
      {mainLoading && (
        <Wrapper className="wrapperContent">
          <LoadingSpinner></LoadingSpinner>
        </Wrapper>
      )}
      {!mainLoading && (
        <Wrapper
          title={activeSection?.label}
          className="wrapperContent"
          showNotification={hasNotif}
          notification={notification}
          breadcrumbData={breadcrumb}
          onCloseNotification={handleCloseNotification}
        >
          {loading && <LoadingSpinner></LoadingSpinner>}
          {!loading && (
            <>
              <Row>
                {dataDetailInventory?.id && allowProductDetail && (
                  <Col sm={12} md={6}>
                    <OtoklixInventorySectionDetail
                      detailInventory={dataDetailInventory}
                      onResultNotification={handleResultNotification}
                      onSubmit={handleSubmitEditInventoryDetail}
                    />
                  </Col>
                )}
                {allowProductHistory && (
                  <Col sm={12} md={6}>
                    <OtoklixInventorySectionHistory idInventory={idInventory} />
                  </Col>
                )}
              </Row>
            </>
          )}
        </Wrapper>
      )}
    </React.Fragment>
  );
};

export default OtoklixInventoryDetail;
