import React, { useCallback, useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  FormFeedback,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import Select from 'react-select';
import EditableInputField from '@components/field/EditableInputField';
import EditWorkshopModal from '@components/modal/EditWorkshopModal';
import API from '@utils/API';
import NumberFormat from 'react-number-format';

const CreatePointTransaction = (props) => {
  const { modal, toggle, id, isCreateChanged } = props;

  const apiv2 = new API('v2');

  const [selectedType, setSelectedType] = useState(undefined);
  const [formValues, setFormValues] = useState({});
  const [isPointsError, setIsPointsError] = useState({ status: false });
  const [submitStatus, setSubmitStatus] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isHideBatal, setIsHideBatal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const transactionType = [
    { id: 1, value: 'PENERIMAAN', label: 'Refund' },
    { id: 2, value: 'PENGGUNAAN', label: 'Pembayaran' },
    { id: 3, value: 'SUKSES_REFER', label: 'Referrer' },
    { id: 4, value: 'SELESAI_BOOKING_PERTAMA_REFERAL', label: 'Referal' },
    { id: 5, value: 'PENDAFTARAN', label: 'Register' },
    { id: 6, value: 'CASHBACK', label: 'Cashback' },
  ];

  const handleCloseModal = () => {
    toggle();
  };

  const onChangePoints = (val) => {
    const points = val.floatValue;
    setFormValues({ ...formValues, otopoints: points });
    if (points === undefined) {
      setIsPointsError({
        status: true,
        message: 'Point tidak boleh kosong',
      });
    } else {
      setIsPointsError({
        status: false,
        message: '',
      });
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const onChangeType = (e) => {
    setSelectedType(e);
  };

  const showAlert = (color, message) => {
    setSubmitStatus({
      color: color,
      message: message,
    });
  };

  const onSubmit = (values) => {
    setIsSubmit(true);
    setIsHideBatal(true);

    const payload = {
      ...formValues,
      transaction_type: selectedType.value,
      customer_id: id,
    };

    apiv2
      .post(`v2/intools/loyalty/details/add`, payload)
      .then((response) => {
        if (response?.status === 200) {
          setIsHideBatal(true);
          setIsSubmit(false);
          showAlert('success', 'Berhasil menambahkan transaksi point.');
          setFormValues({});
          setSelectedType(undefined);
          setTimeout(() => {
            setSubmitStatus(null);
            setIsHideBatal(false);
            isCreateChanged(true);
          }, 3000);
        }
      })
      .catch((error) => {
        const message = error?.response?.data?.error?.message || 'Gagal menambahkan transaksi point.';
        showAlert('danger', message);
        setIsSubmit(false);
      });
  };

  const restoreFormValues = () => {
    setFormValues({});
    setSelectedType(undefined);
    isCreateChanged(true);
    setConfirmModal(false);
  };

  const cancleCreateForm = () => {
    setConfirmModal(true);
  };

  const isButtonDisabled = !selectedType || !formValues.description || !formValues.otopoints || isSubmit;

  return (
    <React.Fragment>
      <EditWorkshopModal
        headerTitle={`Batal Menambahkan Transaksi Otopoints?`}
        modal={confirmModal}
        isCancel={true}
        toggle={() => setConfirmModal(!confirmModal)}
        onConfirm={() => restoreFormValues()}
      >
        Apakah Anda Yakin Batal Menambahkan Transaksi Otopoints?
      </EditWorkshopModal>
      <Modal className="modal-dialog-centered" isOpen={modal} toggle={handleCloseModal} backdrop="static">
        <ModalHeader>Tambah Transaksi Otopoints</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label lg={4}>Tipe Transaksi</Label>
              <Col lg={8}>
                <Select placeholder="Pilih..." options={transactionType} value={selectedType} onChange={onChangeType} />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label lg={4}>Jumlah Otopoints</Label>
              <Col lg={8}>
                <NumberFormat
                  name="otopoints"
                  className="form-control"
                  value={formValues.otopoints}
                  thousandSeparator={'.'}
                  decimalSeparator={false}
                  fixedDecimalScale={true}
                  allowNegative={false}
                  onValueChange={onChangePoints}
                />
                {isPointsError.status && (
                  <div className="custom-error">
                    <FormFeedback>{isPointsError?.message}</FormFeedback>
                  </div>
                )}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label lg={4}>Deskripsi Singkat</Label>
              <Col lg={8}>
                <Input
                  type="textarea"
                  name="description"
                  value={formValues.description}
                  invalid={formValues.description === ''}
                  onChange={onChange}
                />
                {formValues.description === '' && <FormFeedback>Description tidak boleh kosong</FormFeedback>}
              </Col>
            </FormGroup>
          </Form>
          {submitStatus && <div className={`alert alert-${submitStatus?.color}`}>{submitStatus?.message}</div>}
        </ModalBody>
        <ModalFooter className="d-block">
          <Row>
            <Col xs={6}>
              {!isHideBatal && (
                <Button color="danger" outline className="w-100" size="lg" onClick={cancleCreateForm}>
                  Batal
                </Button>
              )}
            </Col>
            <Col xs={6}>
              <Button
                color="primary"
                className="w-100"
                size="lg"
                disabled={isButtonDisabled}
                onClick={() => onSubmit(formValues)}
              >
                Konfirmasi
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default CreatePointTransaction;
