import React, { useEffect, useState, useContext } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

function Arrow({ disabled, position, onClick }) {
  if (position === 'left') {
    const resultLeft = disabled ? (
      <></>
    ) : (
      <div className="button-slide-inventory button-left-slide" onClick={onClick}></div>
    );
    return resultLeft;
  }
  if (position === 'right') {
    const resultRight = disabled ? (
      <></>
    ) : (
      <div className="button-slide-inventory button-right-slide" onClick={onClick}></div>
    );
    return resultRight;
  }
}

export function LeftArrow() {
  const { isFirstItemVisible, scrollPrev, visibleItemsWithoutSeparators, initComplete } = useContext(VisibilityContext);
  const [disabled, setDisabled] = useState(!initComplete || (initComplete && isFirstItemVisible));

  useEffect(() => {
    setDisabled(true);
  }, []);

  useEffect(() => {
    if (visibleItemsWithoutSeparators?.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return <Arrow disabled={disabled} position={'left'} onClick={() => scrollPrev()}></Arrow>;
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } = useContext(VisibilityContext);
  const [disabled, setDisabled] = useState(!visibleItemsWithoutSeparators.length && isLastItemVisible);

  useEffect(() => {
    setDisabled(true);
  }, []);

  useEffect(() => {
    if (visibleItemsWithoutSeparators?.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return <Arrow disabled={disabled} position={'right'} onClick={() => scrollNext()}></Arrow>;
}
