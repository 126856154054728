import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import API from '@utils/API';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import CampaignDetailCreate from '@components/campaigns/campaign/CampaignCreate';
import map from 'lodash/map';
import Helper from '@helpers/Helper';

const CampaignCreate = (props) => {
  const { id } = useParams();
  const { generatePermission } = userPermission();
  const api = new API('v2');
  const [hasNotif, setHasNotif] = useState();
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({});
  const [noData, setNoData] = useState(false);
  const [dataDetailCampaign, setDataDetailCampaign] = useState({});
  const [groupOptions, setGroupOptions] = useState({});
  const [bannerTypeOptions, setBannerTypeOptions] = useState({});

  const allowPermission = (menu) => {
    return !!(generatePermission('campaign', menu) || generatePermission('campaign', 'all'));
  };

  const allowCreate = allowPermission('all') || allowPermission('create');
  const allowEdit = allowPermission('detailall') || allowPermission('detailedit');

  const breadcrumb = [
    {
      id: 1,
      label: 'List Campaign',
      active: false,
      url: '/campaigns',
    },
    {
      id: 2,
      label: 'Edit Campaign',
      active: false,
      url: '',
    },
    {
      id: 3,
      label: dataDetailCampaign?.name,
      active: true,
      url: '',
    },
  ];

  const breadcrumbCreate = [
    {
      id: 1,
      label: 'List Campaign',
      active: false,
      url: '/campaigns',
    },
    {
      id: 2,
      label: 'Create Campaign',
      active: false,
      url: '',
    },
  ];

  async function fetchDetailCampaign() {
    setLoading(true);
    setNoData(true);
    try {
      const response = await api.get(`v2/intools/campaigns/${id}/`);
      mappingDataCampaign(response?.data?.data ?? {});
      handleGetDetailCampaign(response?.data?.data ?? {});
      setLoading(false);
    } catch (error) {
      const errorMsg = error?.response?.data?.error?.message ?? '';
      setDataDetailCampaign({});
      handleResultNotification({
        status: true,
        type: 'fail',
        message: 'Gagal mendapatkan detail data campaign ' + errorMsg,
      });
      setLoading(false);
    }
  }

  const loadGroupOptions = () => {
    return api
      .get(`v2/intools/campaigns/options/campaign-group/`)
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.slug, label: item.name };
        });
        setGroupOptions(transformedOptions);
      })
      .catch((error) => {
        setGroupOptions([]);
      });
  };

  const loadBannerTypeOptions = () => {
    return api
      .get(`v2/intools/campaigns/options/entry-point/`)
      .then((response) => {
        const transformedOptions = map(response?.data?.data, (item) => {
          return { value: item.slug, label: item.name };
        });
        setBannerTypeOptions(transformedOptions);
      })
      .catch((error) => {
        setBannerTypeOptions([]);
      });
  };

  const mappingDataCampaign = (data) => {
    const generateValidationRules = (data) => {
      const transformedData = map(data, (item) => {
        return { value: item.id, label: item.name };
      });

      return data ? transformedData : [];
    };

    const generateValidationRulesNonItemPromo = (data) => {
      const transformedData = map(data, (item) => {
        return { value: item.id, reference: item?.reference, label: item.name };
      });

      return data ? transformedData : [];
    };

    const defaultValue = {
      is_active: data?.is_active,
      is_subscription: data?.is_membership,
      category: {
        value: data?.category?.slug,
        label: data?.category?.name,
      },
      error_category: '',
      name: data?.name,
      error_name: '',
      start_date: data?.start_date,
      end_date: data?.end_date,
      error_date: '',
      campaign_slug: data?.slug,
      pic_campaign: data?.pic_name,
      error_pic_campaign: '',
      deck_campaign: data?.deck_link,
      error_deck_campaign: '',
      customer_eligibility: data?.rules?.customer_eligibility,
      error_customer_eligibility: '',
      customer_segment: {
        value: data?.rules?.customer_segment?.id,
        label: data?.rules?.customer_segment?.name,
      },
      error_customer_segment: '',
      campaign: {
        value: data?.membership_campaign?.id,
        label: data?.membership_campaign?.campaign_name,
      },
      error_campaign: '',
      customer: {
        value: data?.customer_membership?.id,
        label: data?.customer_membership?.name ?? data?.customer_membership?.phone_number ?? '-',
      },
      error_customer: '',
      plate: data?.user_car ?? [],
      error_plate: '',
      campaign_type: data?.campaign_type,
      error_campaign_type: '',
      voucher_code: data?.voucher_code,
      error_voucher_code: '',
      total_voucher: {
        formatMoney: data?.total_voucher ?? 0,
        formatMoneyString: Helper.numberWithCommas(data?.total_voucher ?? 0),
      },
      error_total_voucher: '',
      max_redeem_per_user: {
        formatMoney: data?.max_redeem_per_user ?? 0,
        formatMoneyString: Helper.numberWithCommas(data?.max_redeem_per_user ?? 0),
      },
      error_max_redeem_per_user: '',
      max_quota: {
        formatMoney: data?.max_quota ?? 0,
        formatMoneyString: Helper.numberWithCommas(data?.max_quota ?? 0),
      },
      error_max_quota: '',
      discount_type: data?.discount_type,
      error_discount_type: '',
      discount_for: {
        value: data?.discount_for?.slug,
        label: data?.discount_for?.name,
      },
      error_discount_for: '',
      discount_value: {
        formatMoney: data?.discount_value ?? 0,
        formatMoneyString: Helper.numberWithCommas(data?.discount_value ?? 0),
      },
      error_discount_value: '',
      max_discount: {
        formatMoney: data?.max_discount ?? 0,
        formatMoneyString: Helper.numberWithCommas(data?.max_discount ?? 0),
      },
      error_max_discount: '',
      min_transaction: {
        formatMoney: data?.min_transaction ?? 0,
        formatMoneyString: Helper.numberWithCommas(data?.min_transaction ?? 0),
      },
      error_min_transaction: '',
      booking_origin: data?.rules?.booking_origin,
      error_booking_origin: '',
      services: generateValidationRules(data?.rules?.service),
      error_service: '',
      brands: generateValidationRules(data?.rules?.brand),
      products: generateValidationRules(data?.rules?.product),
      workshops: generateValidationRules(data?.rules?.workshop),
      error_workshop: '',
      packages: generateValidationRules(data?.rules?.package),
      is_fbo: data?.is_fbo,
      error_is_fbo: '',
      is_public: data?.is_public,
      is_show_list: data?.is_show_list,
      is_featured: data?.is_featured,
      banner_type: {
        value: data?.banner_type?.slug,
        label: data?.banner_type?.name,
      },
      error_banner_type: '',
      banner_link: data?.banner_link,
      error_banner_link: '',
      terms_and_condition: data?.terms_and_condition,
      description: data?.description,
      image_link: data?.image_link,
      image_desktop_link: data?.image_desktop_link,
      seo_title: data?.seo_title,
      seo_description: data?.seo_description,
      exclusion: generateValidationRulesNonItemPromo(data?.rules?.exclusion),
      payment_type: data?.rules?.payment_method,
      cannotBeCombined: generateValidationRules(data?.rules?.prohibition_combine),
      customer_b2b: generateValidationRules(data?.rules?.customer),
      is_edited: id ? true : false,
    };

    setDataDetailCampaign(defaultValue);
  };

  const handleGetDetailCampaign = (data) => {
    if (!data?.id) {
      setNoData(true);
      handleResultNotification({ status: true, type: 'fail', message: 'Detail data campaign tidak ditemukan yuuu' });
    } else if (data?.id) {
      setNoData(false);
    }
  };

  const handleSubmitEditCampaignDetail = (status, error) => {
    const errorMsg = error ? 'Gagal Edit Data Campaign, ' + error : 'Gagal Edit Data Campaign';
    if (status) {
      fetchDetailCampaign();
    }
    handleResultNotification({
      status: true,
      type: status ? 'success' : 'fail',
      message: status ? 'Berhasil Edit Data Campaign' : errorMsg,
    });
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleLoading = (status) => {
    setLoading(status);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const handleWrapper = () => {
    if (id) {
      return !allowEdit;
    } else if (!id) {
      return !allowCreate;
    }
  };

  useEffect(() => {
    if (allowEdit && id) {
      fetchDetailCampaign();
    }
  }, []);

  useEffect(() => {
    loadGroupOptions();
    loadBannerTypeOptions();
  }, []);

  return (
    <React.Fragment>
      {handleWrapper() && <Wrapper className="wrapperContent"></Wrapper>}
      {(allowCreate || allowEdit) && (
        <>
          {loading && (
            <Wrapper className="wrapperContent">
              <LoadingSpinner></LoadingSpinner>
            </Wrapper>
          )}
          {!loading && (
            <Wrapper
              title={id ? 'Edit Campaign' : 'Create Campaign'}
              customContentClassName="wrapper-content"
              customTitleClassName="wrapper-title"
              showNotification={hasNotif}
              notification={notification}
              breadcrumbData={id ? breadcrumb : breadcrumbCreate}
              onCloseNotification={handleCloseNotification}
            >
              {!noData && (
                <CampaignDetailCreate
                  id={id}
                  onResultNotification={handleResultNotification}
                  onLoading={handleLoading}
                  dataCampaign={dataDetailCampaign}
                  groupOptions={groupOptions}
                  bannerTypeOptions={bannerTypeOptions}
                  allowEdit={allowEdit}
                  allowCreate={allowCreate}
                ></CampaignDetailCreate>
              )}
            </Wrapper>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default CampaignCreate;
