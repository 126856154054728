import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button, Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import map from 'lodash/map';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import userPermission from '@utils/userPermission';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import PackageDataDetailComponent from '@components/workshops/package/PackageDataDetailComponent';
import { PACKAGE_ALLOWED } from '@utils/Constants';

const PackageDataDetail = (props) => {
  const {
    isEditHeader,
    workshopId,
    packageId,
    defaultValuesDetail,
    defaultValuesHeader,
    packageComponentOptions,
    packageProductTypeOptions,
    loadingPackageDetail,
    percentageDiscountB2C,
    percentageDiscountB2B,
    packagePriceB2C,
    packagePriceB2B,
    isUpdateAllowedCustomerType,
    onChangeDetailPrice,
    onSubmit,
    onEditDetail,
  } = props;
  const {
    control,
    reset,
    watch,
    register,
    setValue,
    setError,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const api = new API('v2');
  const { generatePermission } = userPermission();
  const [modalType, setModalType] = useState('');
  const [loadingSumbit, setLoadingSubmit] = useState(false);
  const [payloadForm, setPayloadForm] = useState();
  const [detailPrice, setDetailPrice] = useState();
  const [disableForm, setDisableForm] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errorSectionForm, setErrorSectionForm] = useState([]);
  const [isEditDetail, setIsEditDetail] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'packageall'));
  };

  const allowEdit = allowPermission('packagedetailedit') || allowPermission('packagedetailall');

  async function fetchProductByComponent(component_id, index) {
    let listProduct = [];
    setValue(`components[${index}].first_load_product_options`, false);

    try {
      const response = await api.get(`v2/intools/workshops/${workshopId}/inventories/all?component_id=${component_id}`);
      if (response?.data?.data) {
        let resultListProduct = map(response?.data?.data, (item) => {
          return {
            value: item?.product_id,
            label: item?.product_name,
            inventory_id: item?.inventory_id,
            ...item,
            // category_id: item?.category_id,
          };
        });
        listProduct = resultListProduct;
      }
      let componentLength = getValues(`components`);
      componentLength = componentLength?.length ?? 0;
      if (componentLength > 0) {
        setValue(`components[${index}].loading_product_options`, false);
        setValue(`components[${index}].product_options`, listProduct);
      }
    } catch (error) {
      let componentLength = getValues(`components`);
      componentLength = componentLength?.length ?? 0;
      if (componentLength > 0) {
        setValue(`components[${index}].loading_product_options`, false);
        setValue(`components[${index}].product_options`, listProduct);
      }
    }
  }

  const setDefaultForm = () => {
    reset({ components: defaultValuesDetail });
  };

  const componentsForm = useWatch({
    control,
    name: `components`,
  });

  const restoreFormPackageDetail = () => {
    setDefaultForm();
    setDisableForm(true);
    setConfirmModal(false);
    setIsEditDetail(false);
  };

  const editForm = () => {
    if (isEditHeader) {
      onSubmit(false, `Mohon selesaikan edit package header`);
    } else {
      if (disableForm) {
        setDisableForm(false);
      } else {
        setModalType('Simpan');
        setConfirmModal(true);
      }
      setIsEditDetail(true);
    }
  };

  const cancelEditForm = () => {
    setModalType('Batalkan');
    setConfirmModal(true);
  };

  const setErrorMessage = (nameField, type, message) => {
    setError(nameField, { type: type, message: message }, { shouldFocus: true });
  };

  const validationComponentName = (value) => {
    let validation = {
      valid: value?.length == 0 || value?.length > 100 ? false : true,
      errorMsg:
        value?.length > 100
          ? 'Component Name max 100 karakter'
          : value?.length == 0
          ? 'Component Name harus diisi'
          : '',
    };
    return validation;
  };

  const validationPromptText = (value) => {
    let validation = {
      valid: value?.length == 0 || value?.length > 100 ? false : true,
      errorMsg: value?.length > 100 ? 'Prompt max 100 karakter' : value?.length == 0 ? 'Prompt harus diisi' : '',
    };
    return validation;
  };

  const validationProduct = (value) => {
    let validation = {
      valid: !value?.value ? false : true,
      errorMsg: !value?.value ? 'Product harus diisi' : '',
    };
    return validation;
  };

  const validationAppsName = (value) => {
    let validation = {
      valid: value?.length == 0 || value?.length > 100 ? false : true,
      errorMsg:
        value?.length > 100
          ? 'Show in apps as max 100 karakter'
          : value?.length == 0
          ? 'Show in apps as harus diisi'
          : '',
    };
    return validation;
  };

  const validationPrice = (value, label) => {
    const msgError = label + ' max Rp 99.999.999';
    let validation = {
      valid: value?.formatMoney > 99999999 ? false : true,
      errorMsg: value?.formatMoney > 99999999 ? msgError : '',
    };
    return validation;
  };

  const defaultPassFieldValidation = (value) => {
    return {
      valid: true,
      errorMsg: '',
    };
  };

  const calculatePrice = () => {
    let estimated_price = 0;
    let estimated_price_b2b = 0;
    let estimated_package_price = 0;
    let estimated_package_price_b2b = 0;
    let estimated_nett_price = 0;

    componentsForm.map((component) => {
      if (component.is_single && !component.is_deleted && component.is_active) {
        if (defaultValuesHeader?.allowed_customer_type == PACKAGE_ALLOWED.B2C) {
          estimated_price += component?.product_price?.formatMoney ?? 0;
        } else {
          estimated_price += component?.original_price?.formatMoney ?? 0;
          estimated_price_b2b += component?.original_price_b2b?.formatMoney ?? 0;
          estimated_package_price += component?.product_price?.formatMoney ?? 0;
          estimated_package_price_b2b += component?.product_price_b2b?.formatMoney ?? 0;
        }
        estimated_nett_price += component?.nett_price?.formatMoney ?? 0;
      }
      if (!component.is_single && !component.is_deleted) {
        let price = null;
        let priceB2B = null;
        let packagePrice = null;
        let packagePriceB2B = null;
        let nettPrice = null;
        if (component?.products?.length) {
          component.products.map((product) => {
            if (!product.is_deleted && product.is_active) {
              if (defaultValuesHeader?.allowed_customer_type == PACKAGE_ALLOWED.B2C) {
                price = price == null ? product.product_price?.formatMoney : price;
                price = price < product?.product_price?.formatMoney ? price : product?.product_price?.formatMoney;
              } else {
                price += product.original_price?.formatMoney ?? 0;
                priceB2B += product.original_price_b2b?.formatMoney ?? 0;
                packagePrice += product.product_price?.formatMoney ?? 0;
                packagePriceB2B += product.product_price_b2b?.formatMoney ?? 0;
              }
              nettPrice += product?.nett_price?.formatMoney ?? 0;
            }
          });
        }
        estimated_price += price ?? 0;
        estimated_price_b2b += priceB2B ?? 0;
        estimated_package_price += packagePrice ?? 0;
        estimated_package_price_b2b += packagePriceB2B ?? 0;
        estimated_nett_price += nettPrice ?? 0;
      }
    });

    setDetailPrice({
      price: Helper.formatMoneyOnField('' + estimated_price ?? 0, 'Rp '),
      price_b2b: Helper.formatMoneyOnField('' + estimated_price_b2b ?? 0, 'Rp '),
      package_price: Helper.formatMoneyOnField('' + estimated_package_price ?? 0, 'Rp '),
      package_price_b2b: Helper.formatMoneyOnField('' + estimated_package_price_b2b ?? 0, 'Rp '),
      nett_price: Helper.formatMoneyOnField('' + estimated_nett_price ?? 0, 'Rp '),
    });
  };

  const convertPrice = (value) => {
    return Helper.formatMoneyOnField('' + value ?? 0, 'Rp ');
  };

  const getPriceAfterDiscount = (value, type) => {
    const price = value ?? 0;
    const percentage = type == PACKAGE_ALLOWED.B2C ? percentageDiscountB2C ?? 0 : percentageDiscountB2B ?? 0;
    const price_disc = (price * percentage) / 100;
    const price_after_disc = price - price_disc;

    return Math.round(price_after_disc);
  };

  const updatePriceFromChangeDiscountHeader = (type) => {
    if (componentsForm?.length > 0 && defaultValuesHeader?.allowed_customer_type !== PACKAGE_ALLOWED.B2C) {
      const packagePrice = type == PACKAGE_ALLOWED.B2C ? packagePriceB2C : packagePriceB2B;
      const fieldOriginalPrice = type == PACKAGE_ALLOWED.B2C ? 'original_price' : 'original_price_b2b';
      const fieldProductPrice = type == PACKAGE_ALLOWED.B2C ? 'product_price' : 'product_price_b2b';
      let sum_package_price = 0;

      componentsForm.map((component, idxComponent) => {
        if (!component.is_deleted) {
          if (component?.is_single) {
            let productPrice = getValues(`components[${idxComponent}].` + fieldOriginalPrice);
            productPrice = convertPrice(getPriceAfterDiscount(productPrice?.formatMoney ?? 0, type));
            if (componentsForm.length - 1 == idxComponent) {
              productPrice = convertPrice(packagePrice - sum_package_price > 0 ? packagePrice - sum_package_price : 0);
            }
            sum_package_price += productPrice?.formatMoney ?? 0;
            setValue(`components[${idxComponent}].` + fieldProductPrice, productPrice);
          } else {
            component.products.map((product, idxProduct) => {
              if (!product.is_deleted) {
                let productPrice = getValues(
                  `components[${idxComponent}].products[${idxProduct}].` + fieldOriginalPrice
                );
                productPrice = convertPrice(getPriceAfterDiscount(productPrice?.formatMoney ?? 0, type));
                if (componentsForm.length - 1 == idxComponent && component.products.length - 1 == idxProduct) {
                  productPrice = convertPrice(
                    packagePrice - sum_package_price > 0 ? packagePrice - sum_package_price : 0
                  );
                }
                sum_package_price += productPrice?.formatMoney ?? 0;
                setValue(`components[${idxComponent}].products[${idxProduct}].` + fieldProductPrice, productPrice);
              }
            });
          }
        }
      });
    }
  };

  const validateComponentProductForm = (field, value, isSingle) => {
    let validate = {
      validateAppsName: defaultPassFieldValidation(),
      validateOriginalPrice: defaultPassFieldValidation(),
      validateB2CPrice: defaultPassFieldValidation(),
      validateB2BPrice: defaultPassFieldValidation(),
      validateNettPrice: defaultPassFieldValidation(),
    };

    if (defaultValuesHeader?.allowed_customer_type == PACKAGE_ALLOWED.B2C) {
      if (!isSingle) {
        validate.validateAppsName = validationAppsName(value?.apps_name ?? '');
        setErrorMessage(field + `apps_name`, 'manual', validate.validateAppsName?.errorMsg ?? '');
      }
      validate.validateOriginalPrice = validationPrice(value?.original_price, 'Original Price');
      validate.validateB2CPrice = validationPrice(value?.product_price, 'Price');
      setErrorMessage(field + `original_price`, 'manual', validate.validateOriginalPrice?.errorMsg ?? '');
      setErrorMessage(field + `product_price`, 'manual', validate.validateB2CPrice?.errorMsg ?? '');
    }
    if (defaultValuesHeader?.allowed_customer_type == PACKAGE_ALLOWED.BOTH) {
      if (!isSingle) {
        validate.validateAppsName = validationAppsName(value?.apps_name ?? '');
        setErrorMessage(field + `apps_name`, 'manual', validate.validateAppsName?.errorMsg ?? '');
      }
      validate.validateB2CPrice = validationPrice(value?.product_price, 'B2C Price');
      setErrorMessage(field + `product_price`, 'manual', validate.validateB2CPrice?.errorMsg ?? '');
    }
    if (
      defaultValuesHeader?.allowed_customer_type == PACKAGE_ALLOWED.BOTH ||
      defaultValuesHeader?.allowed_customer_type == PACKAGE_ALLOWED.B2B
    ) {
      validate.validateB2BPrice = validationPrice(value?.product_price_b2b, 'B2B Price');
      validate.validateNettPrice = validationPrice(value?.nett_price, 'Nett Price');
      setErrorMessage(field + `product_price_b2b`, 'manual', validate.validateB2BPrice?.errorMsg ?? '');
      setErrorMessage(field + `nett_price`, 'manual', validate.validateNettPrice?.errorMsg ?? '');
    }

    return validate;
  };

  const isValidSingleComponent = (defaultValid, validComponentName, validProduct, validField) => {
    const isValid = !defaultValid
      ? defaultValid
      : validComponentName?.valid &&
        validProduct?.valid &&
        validField?.validateOriginalPrice?.valid &&
        validField?.validateB2CPrice?.valid &&
        validField?.validateB2BPrice?.valid &&
        validField?.validateNettPrice?.valid;
    return isValid;
  };

  const isValidMultipleComponent = (defaultValid, validProduct, validField) => {
    const isValid = !defaultValid
      ? defaultValid
      : validProduct?.valid &&
        validField?.validateAppsName?.valid &&
        validField?.validateOriginalPrice?.valid &&
        validField?.validateB2CPrice?.valid &&
        validField?.validateB2BPrice?.valid &&
        validField?.validateNettPrice?.valid;
    return isValid;
  };

  const getShowComponentError = (validComponentName, validProduct, validField) => {
    const showComponent =
      !validComponentName?.valid ||
      !validProduct?.valid ||
      !validField?.validateOriginalPrice?.valid ||
      !validField?.validateB2CPrice?.valid ||
      !validField?.validateB2BPrice?.valid ||
      !validField?.validateNettPrice?.valid
        ? true
        : false;

    return showComponent;
  };

  const getShowProductError = (validProduct, validField) => {
    const showProduct =
      !validProduct?.valid ||
      !validField?.validateAppsName?.valid ||
      !validField?.validateOriginalPrice?.valid ||
      !validField?.validateB2CPrice?.valid ||
      !validField?.validateB2BPrice?.valid ||
      !validField?.validateNettPrice?.valid
        ? true
        : false;
    return showProduct;
  };

  const validateForm = (dataForm) => {
    let isValid = true;
    if (dataForm.components.length > 0) {
      dataForm.components.map((component, idxComponent) => {
        let showComponent = false;
        let componentField = `components[${idxComponent}].`;

        if (!component.is_deleted) {
          const validateComponentName = validationComponentName(component?.component_name ?? '');
          setErrorMessage(componentField + `component_name`, 'manual', validateComponentName?.errorMsg ?? '');

          if (component.is_single) {
            const validateSingleProduct = validationProduct(component?.product ?? {});
            setErrorMessage(componentField + `product`, 'manual', validateSingleProduct?.errorMsg ?? '');

            const validateField = validateComponentProductForm(componentField, component, true);
            isValid = isValidSingleComponent(isValid, validateComponentName, validateSingleProduct, validateField);
            showComponent = getShowComponentError(validateComponentName, validateSingleProduct, validateField);

            if (showComponent) {
              setValue(`components[${idxComponent}].is_show`, true);
            }
          }
          if (!component.is_single) {
            const validatePrompt = validationPromptText(component?.prompt_text ?? '');
            setErrorMessage(componentField + `prompt_text`, 'manual', validatePrompt?.errorMsg ?? '');

            isValid = !isValid ? isValid : validateComponentName?.valid && validatePrompt?.valid;
            showComponent = !validateComponentName?.valid || !validatePrompt?.valid ? true : false;

            component.products.map((product, idxProduct) => {
              let showProduct = false;
              let productField = `components[${idxComponent}].products.[${idxProduct}].`;

              if (!product.is_deleted) {
                const validateMultipleProduct = validationProduct(product?.product ?? {});
                setErrorMessage(productField + `product`, 'manual', validateMultipleProduct?.errorMsg ?? '');

                const validateField = validateComponentProductForm(productField, product, false);
                isValid = isValidMultipleComponent(isValid, validateMultipleProduct, validateField);
                showProduct = getShowProductError(validateMultipleProduct, validateField);
              }

              if (showComponent) {
                setValue(`components[${idxComponent}].is_show`, true);
              }
              if (showProduct) {
                setValue(`components[${idxComponent}].is_show`, true);
                setValue(`components[${idxComponent}].products[${idxProduct}].is_show`, true);
              }
            });
          }
        }
      });
    } else if (dataForm.components.length == 0) {
      isValid = false;
    }

    return isValid;
  };

  const getPayloadProductComponent = (component, product, isSingle) => {
    const isSingleComponent = isSingle;
    const baseField = isSingleComponent ? component : product;
    let productObj = {
      workshop_inventory_id: baseField?.product?.inventory_id ?? null,
      product_id: baseField?.product?.value ?? null,
      qty_modifier: component?.component_id == 1 ? 'oil_qty' : null,
      is_active: baseField?.is_active ?? false,
    };

    if (isSingleComponent) productObj.show_in_app_as = null;

    if (defaultValuesHeader?.allowed_customer_type == PACKAGE_ALLOWED.B2C) {
      if (!isSingleComponent) productObj.show_in_app_as = baseField?.apps_name ?? null;
      productObj.original_price = baseField?.original_price?.formatMoney ?? 0;
      productObj.price = baseField?.product_price?.formatMoney ?? 0;
    }
    if (defaultValuesHeader?.allowed_customer_type == PACKAGE_ALLOWED.B2B) {
      if (!isSingleComponent) productObj.show_in_app_as = baseField?.apps_name ?? null;
      productObj.original_price_b2b = baseField?.original_price_b2b?.formatMoney ?? 0;
      productObj.price_b2b = baseField?.product_price_b2b?.formatMoney ?? 0;
      productObj.nett_price = baseField?.nett_price?.formatMoney ?? 0;
    }
    if (defaultValuesHeader?.allowed_customer_type == PACKAGE_ALLOWED.BOTH) {
      if (!isSingleComponent) productObj.show_in_app_as = baseField?.apps_name ?? null;
      productObj.original_price = baseField?.original_price?.formatMoney ?? 0;
      productObj.price = baseField?.product_price?.formatMoney ?? 0;
      productObj.original_price_b2b = baseField?.original_price_b2b?.formatMoney ?? 0;
      productObj.price_b2b = baseField?.product_price_b2b?.formatMoney ?? 0;
      productObj.nett_price = baseField?.nett_price?.formatMoney ?? 0;
    }

    return productObj;
  };

  const getPayloadData = (dataForm) => {
    let components = [];
    dataForm.components.map((component) => {
      if (!component.is_deleted) {
        let componentObj = {
          name: component?.component_name ?? '',
          description: component?.description ?? '',
          name_placeholder: component?.is_single ? null : component?.component_placeholder ?? null,
          prompt: component?.is_single ? null : component?.prompt_text ?? null,
          component_id: component?.component_id ?? null,
          is_single: component?.is_single ?? false,
          qty: component?.qty ?? null,
          products: [],
        };
        if (component.is_single) {
          const productObj = getPayloadProductComponent(component, null, true);
          componentObj.products.push(productObj);
        } else {
          componentObj.is_removable = component?.is_removable ?? false;
          component.products.map((product) => {
            if (!product.is_deleted) {
              const productObj = getPayloadProductComponent(component, product, false);
              componentObj.products.push(productObj);
            }
          });
        }
        components.push(componentObj);
      }
    });

    let payloadData = {
      category_id: defaultValuesHeader?.category?.id ?? null,
      components: components,
    };

    if (defaultValuesHeader?.allowed_customer_type == PACKAGE_ALLOWED.B2C)
      payloadData.total_price = detailPrice?.price?.formatMoney ?? 0;

    return payloadData;
  };

  const submitForm = (data) => {
    let isValid = validateForm(data);
    if (isValid) {
      setModalType('Simpan');
      setConfirmModal(true);
      setPayloadForm(data);
    } else {
      if (data.components.length < 1) {
        onSubmit(false, 'Component minimal 1');
      } else {
        onSubmit(false, 'Mohon lengkapi form');
      }
    }
  };

  const handleUIErrorForm = () => {
    let errorSectionForm = [];

    errors?.components &&
      errors?.components?.map((component) => {
        if (component) {
          Object.entries(component).forEach(([key]) => {
            if (component[key]?.message) {
              let sectionSplitComponent = component[key]?.ref?.name.split('.');
              if (sectionSplitComponent.length > 0) {
                errorSectionForm.push(sectionSplitComponent[0]);
              }
            }
          });
        }
        component?.products &&
          component?.products?.map((product) => {
            if (product) {
              Object.entries(product).forEach(([key]) => {
                if (product[key]?.message) {
                  let sectionSplitProduct = product[key]?.ref?.name.split('.');
                  if (sectionSplitProduct.length > 1) {
                    errorSectionForm.push(sectionSplitProduct[0]);
                    errorSectionForm.push(sectionSplitProduct[0] + sectionSplitProduct[1]);
                  }
                }
              });
            }
          });
      });

    errorSectionForm = [...new Set(errorSectionForm)];
    setErrorSectionForm(errorSectionForm);
  };

  const handleGetProducts = () => {
    componentsForm &&
      componentsForm?.map((component, index) => {
        if (component?.component_id && component?.first_load_product_options) {
          fetchProductByComponent(component?.component_id, index);
        }
      });
  };

  const errorForm = (e) => {
    onSubmit(false, `Mohon lengkapi form`);
    handleUIErrorForm();
  };

  const updateDetailPackage = async () => {
    setConfirmModal(false);
    setLoadingSubmit(true);
    const payload = getPayloadData(payloadForm);
    try {
      const response = await api.post(`v2/intools/packages/${packageId}/detail/`, payload);
      setTimeout(() => {
        setLoadingSubmit(false);
        setDisableForm(true);
        onSubmit(true);
        setIsEditDetail(false);
      }, 200);
    } catch (e) {
      let defaultMsg = 'Gagal Update Package Detail';
      let errMsg = e?.response?.data?.error?.message
        ? defaultMsg + ', ' + e?.response?.data?.error?.message
        : defaultMsg;
      setTimeout(() => {
        setLoadingSubmit(false);
        onSubmit(false, errMsg);
      }, 1500);
    }
  };

  const handleNotifications = (e) => {
    onSubmit(e?.status, e?.msg);
  };

  useEffect(() => {
    if (isUpdateAllowedCustomerType) {
      reset({ components: [] });
    }
  }, [isUpdateAllowedCustomerType]);

  useEffect(() => {
    if (isEditHeader) {
      updatePriceFromChangeDiscountHeader(PACKAGE_ALLOWED.B2C);
    }
  }, [percentageDiscountB2C]);

  useEffect(() => {
    if (isEditHeader) {
      updatePriceFromChangeDiscountHeader(PACKAGE_ALLOWED.B2B);
    }
  }, [percentageDiscountB2B]);

  useEffect(() => {
    onEditDetail(isEditDetail);
  }, [isEditDetail]);

  useEffect(() => {
    if (!isEditHeader) setDefaultForm();
  }, [isEditHeader]);

  useEffect(() => {
    setDefaultForm();
  }, [defaultValuesDetail]);

  useEffect(() => {
    if (defaultValuesHeader?.is_active) {
      setDefaultForm();
      setDisableForm(true);
    }
  }, [defaultValuesHeader]);

  useEffect(() => {
    if (componentsForm?.length) {
      calculatePrice();
    }
    handleUIErrorForm();
    handleGetProducts();
  }, [componentsForm]);

  useEffect(() => {
    onChangeDetailPrice(detailPrice);
  }, [detailPrice]);

  return (
    <React.Fragment>
      {loadingPackageDetail && <LoadingSpinner></LoadingSpinner>}
      {!loadingPackageDetail && (
        <Card>
          <CardHeader className="bg-primary text-white mt-0">Package Detail</CardHeader>
          <CardBody>
            <Col lg={12}>
              <PackageDataDetailComponent
                loadingSumbit={loadingSumbit}
                disableForm={disableForm}
                packageComponentOptions={packageComponentOptions}
                packageProductTypeOptions={packageProductTypeOptions}
                errorSectionForm={errorSectionForm}
                productGroup={defaultValuesHeader?.product_group}
                onNotifications={handleNotifications}
                percentageDiscountB2C={percentageDiscountB2C}
                percentageDiscountB2B={percentageDiscountB2B}
                allowedCustomerType={defaultValuesHeader?.allowed_customer_type}
                {...{ control, register, getValues, setValue, setError, watch, formState: { errors } }}
              />

              {!defaultValuesHeader?.is_active && (
                <Row className="mt-3">
                  {allowEdit && (
                    <Col sm={12} md={12} className="section-action">
                      {!disableForm && (
                        <>
                          <Button
                            className="button-action save"
                            type="button"
                            disabled={loadingSumbit}
                            onClick={handleSubmit(submitForm, errorForm)}
                          >
                            Simpan
                          </Button>
                          <Button className="button-action cancel" disabled={loadingSumbit} onClick={cancelEditForm}>
                            Batalkan
                          </Button>
                        </>
                      )}
                      {disableForm && (
                        <div>
                          <Button className="button-action save" onClick={editForm}>
                            Edit
                          </Button>
                        </div>
                      )}
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          </CardBody>
          <ConfirmationModal
            modal={confirmModal}
            toggle={() => setConfirmModal(!confirmModal)}
            header={`Anda yakin ingin ${modalType === 'Simpan' ? 'menyimpan ' : 'membatalkan '} ?`}
            subHeader={`Aksi ini akan mengakibatkan hasil edit Component & Product ${defaultValuesHeader?.name} ${
              modalType === 'Simpan' ? ' tersimpan' : ' tidak tersimpan'
            }`}
            onConfirm={() => (modalType === 'Simpan' ? updateDetailPackage() : restoreFormPackageDetail())}
            toggleClose={() => setConfirmModal(!confirmModal)}
          ></ConfirmationModal>
        </Card>
      )}
    </React.Fragment>
  );
};

export default PackageDataDetail;
