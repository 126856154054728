import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router';
import Wrapper from '@components/wrapper/Wrapper';
import Tabs from '@components/tabs/Tabs';
import Subscribe from '@components/subscriptions/Subscribe';
import Customer from '@components/subscriptions/Customer';
import History from '@components/subscriptions/History';
import CreditHistory from '@components/subscriptions/CreditHistory';

function SubscriptionsDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState({});
  const [hasNotif, setHasNotif] = useState();
  const [notification, setNotification] = useState({});

  const breadcrumb = [
    {
      id: 1,
      label: 'Langganan',
      active: false,
      url: '/subscriptions',
    },
    {
      id: 2,
      label: 'Detail Langganan',
      active: true,
      url: '',
    },
  ];

  const tabsData = [
    {
      title: 'Langganan',
      slug: 'subscribe',
      active: true,
      edit_mode: false,
    },
    {
      title: 'Pelanggan',
      slug: 'customer',
      active: false,
      edit_mode: false,
    },
    {
      title: 'Riwayat Penukaran Kredit',
      slug: 'credit_history',
      active: false,
      edit_mode: false,
    },
    {
      title: 'Log History',
      slug: 'history',
      active: false,
      edit_mode: false,
    },
  ];

  const handleChangeActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleLoading = (status) => {
    setLoading(status);
  };

  return (
    <Wrapper
      title="Detail Langganan"
      customContentClassName="wrapper-content"
      customTitleClassName="wrapper-title"
      breadcrumbData={breadcrumb}
      showNotification={hasNotif}
      notification={notification}
      onCloseNotification={handleCloseNotification}
    >
      <Tabs loading={loading} tabs={tabsData} onChangeActiveTab={handleChangeActiveTab}></Tabs>
      <Row className="mt-4">
        <Col sm={12}>
          {activeTab.slug == 'subscribe' && (
            <Subscribe id={id} onLoading={handleLoading} onResultNotification={handleResultNotification}></Subscribe>
          )}
        </Col>
        <Col sm={12}>{activeTab.slug == 'customer' && <Customer id={id} onLoading={handleLoading}></Customer>}</Col>
        <Col sm={12}>
          {activeTab.slug == 'credit_history' && <CreditHistory id={id} onLoading={handleLoading}></CreditHistory>}
        </Col>
        <Col sm={12}>{activeTab.slug == 'history' && <History></History>}</Col>
      </Row>
    </Wrapper>
  );
}

export default SubscriptionsDetail;
