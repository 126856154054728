import React, { useState, useEffect } from 'react';
import PackageList from '@components/workshops/package/PackageList';
import PackageDetail from '@components/workshops/package/PackageDetail';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';

function Package(props) {
  const { id, onResultNotification, onLoading, activeproductid } = props;
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [packageId, setPackageId] = useState();
  const [showDetail, setShowDetail] = useState(false);

  const handleLoading = (e) => {
    setLoading(e);
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const handleClickList = () => {
    setShowDetail(false);
    setPackageId();

    if (activeproductid) {
      let params = qs.parse(history?.location?.search);
      delete params.productid;
      history.push({ search: qs.stringify(params) });
    }
  };

  const handleClickDetail = (data) => {
    setShowDetail(true);
    setPackageId(data?.id);

    let params = qs.parse(history?.location?.search);
    params.productid = data?.id;
    history.push({ search: qs.stringify(params) });
  };

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (activeproductid) {
      handleClickDetail({ id: activeproductid });
    }
  }, [activeproductid]);

  return (
    <React.Fragment>
      {!showDetail && (
        <PackageList
          id={id}
          onLoading={handleLoading}
          handleClickDetail={handleClickDetail}
          handleResultNotification={handleResultNotification}
        ></PackageList>
      )}

      {showDetail && (
        <PackageDetail
          id={id}
          packageId={packageId}
          onLoading={handleLoading}
          handleClickList={handleClickList}
          handleResultNotification={handleResultNotification}
        ></PackageDetail>
      )}
    </React.Fragment>
  );
}

export default Package;
