import { debounce, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

const CancelBookingReasonModal = ({ open, toggle, dataList, placeholder, selectedReason, selectReason, onSubmit }) => {
  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const setMessage = (value) => {
    setMsg(value);
    handleSelectReason(11, value);
  };

  const handleSelectReason = (idReason, messageReason) => {
    if (idReason !== 11) {
      setMsg('');
      setErrorMsg('');
    }
    selectReason(idReason, messageReason);
  };

  const handleSubmit = () => {
    let isValid = true;

    if (selectedReason == 11) {
      if (msg == '') {
        isValid = false;
        setErrorMsg('Alasan Lainnya harus diisi');
      } else if (msg.length > 200) {
        isValid = false;
        setErrorMsg('Format Alasan Lainnya maksimal 200 karakter');
      } else {
        setErrorMsg('');
      }
    }

    if (isValid) {
      onSubmit('cancel');
    }
  };

  useEffect(() => {
    setMsg('');
    setErrorMsg('');
  }, [open]);

  return (
    <Modal className="modal-dialog-centered" isOpen={open} toggle={toggle} scrollable={true}>
      <ModalHeader>Batalkan Booking / Order</ModalHeader>
      <ModalBody>
        <div className="mb-2">
          <span>Alasan pembatalan:</span>
        </div>
        <div className="px-4">
          <Form>
            {dataList?.length > 0 &&
              map(dataList, (value) => {
                return (
                  <div>
                    <div className="mt-3 mb-2 font-weight-bold">{value?.group_name ?? ''}</div>
                    {value?.reason?.length > 0 &&
                      map(value.reason, (options) => {
                        return (
                          <FormGroup check>
                            <Label className="my-2 w-100" for={options.id}>
                              <Input
                                type="radio"
                                name={options.id}
                                id={options.id}
                                checked={selectedReason === options.id}
                                onClick={() => handleSelectReason(options.id, '')}
                              />
                              {options.description}
                            </Label>
                            {options.id === 11 && (
                              <Form>
                                <Input
                                  disabled={selectedReason !== 11}
                                  type="textarea"
                                  className="mt-2"
                                  value={msg}
                                  invalid={errorMsg ? true : false}
                                  placeholder={placeholder ?? ''}
                                  onChange={(e) => {
                                    let reasonMsg = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : msg;

                                    setMessage(reasonMsg);
                                    if (reasonMsg.length > 200) {
                                      setErrorMsg('Format Alasan Lainnya maksimal 200 karakter');
                                    } else if (reasonMsg.length == 0) {
                                      setErrorMsg('Alasan Lainnya harus diisi');
                                    } else {
                                      setErrorMsg('');
                                    }
                                  }}
                                />
                                <FormFeedback>{errorMsg}</FormFeedback>
                              </Form>
                            )}
                          </FormGroup>
                        );
                      })}
                  </div>
                );
              })}
          </Form>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between flex-row flex-nowrap">
        <Button className="w-50" outline color="danger" onClick={toggle}>
          Kembali
        </Button>
        <Button
          className="w-50"
          color="primary"
          disabled={selectedReason ? false : true}
          onClick={() => handleSubmit()}
        >
          Konfirmasi
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CancelBookingReasonModal;
