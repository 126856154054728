import React from 'react';
import { Badge } from 'reactstrap';

const BookingBadges = ({ status, isDraft }) => {
  switch (status?.slug) {
    case 'waiting':
      return (
        <>
          <span className="badge-custom badge-custom-yellow">{status?.name}</span>
          {isDraft && <span className="ml-2 badge-custom badge-custom-draft">{'Draft'}</span>}
        </>
      );
    case 'confirm':
      return (
        <>
          <span className="badge-custom badge-custom-primary">{status?.name}</span>
          {isDraft && <span className="ml-2 badge-custom badge-custom-draft">{'Draft'}</span>}
        </>
      );
    case 'finish':
      return <span className="badge-custom badge-custom-success">{status?.name}</span>;
    case 'cancel':
      return <span className="badge-custom badge-custom-danger">{status?.name}</span>;
    case 'payment':
      return <span className="badge-custom badge-custom-yellow">{status?.name}</span>;
    case 'review':
      return <span className="badge-custom badge-custom-purple">{status?.name}</span>;
    case 'on-process':
      return (
        <>
          <span className="badge-custom badge-custom-primary">{status?.name}</span>
          {isDraft && <span className="ml-2 badge-custom badge-custom-draft">{'Draft'}</span>}
        </>
      );
    case 'service-done':
      return <span className="badge-custom badge-custom-success">{status?.name}</span>;
    case 'complain':
      return (
        <>
          <span className="badge-custom badge-custom-danger">{status?.name}</span>
          {isDraft && <span className="ml-2 badge-custom badge-custom-draft">{'Draft'}</span>}
        </>
      );
    default:
      return <span className="badge-custom badge-custom-grey">{status?.name}</span>;
  }
};

export default BookingBadges;
