import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav, NavItem, NavLink, Col, Card, CardBody, Row } from 'reactstrap';
import filter from 'lodash/filter';
import classnames from 'classnames';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import CampaignList from '@components/campaigns/campaign/CampaignList';
import GroupCampaignList from '@components/campaigns/groupCampaign/GroupCampaignList';

const Campaigns = () => {
  const history = useHistory();
  const queryParams = useLocation().search;
  const activeTabParam = new URLSearchParams(queryParams).get('activetab');
  const { generatePermission } = userPermission();

  const [hasNotif, setHasNotif] = useState();
  const [activeTab, setActiveTab] = useState('');
  const [notification, setNotification] = useState({});

  const allowPermission = (menu) => {
    return !!(generatePermission('campaign', menu) || generatePermission('campaign', 'all'));
  };

  const allowCampaign = allowPermission('all') || allowPermission('list') || allowPermission('create');
  const allowCampaignGroup =
    allowPermission('groupall') || allowPermission('grouplist') || allowPermission('groupcreate');

  const tabsData = [
    {
      key: 'campaign',
      label: 'Campaign',
      allow_permission: allowCampaign,
    },
    {
      key: 'group',
      label: 'Grup Campaign',
      allow_permission: allowCampaignGroup,
    },
  ];

  const toggleActiveTab = (tab) => {
    const params = new URLSearchParams();

    setActiveTab(tab);
    params.append('activetab', tab);
    history.push({ search: params.toString() });
  };

  const checkTabs = () => {
    const availableTabs =
      tabsData?.length &&
      filter(tabsData, (item) => {
        return item?.key == activeTabParam && item?.allow_permission;
      });

    const allowTabs =
      tabsData?.length &&
      filter(tabsData, (item) => {
        return item?.allow_permission;
      });

    setActiveTab(!availableTabs?.length ? (!activeTabParam ? allowTabs[0]?.key ?? '' : '') : activeTabParam);
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  useEffect(() => {
    checkTabs();
  }, [activeTabParam]);

  return (
    <Wrapper
      title="List Campaign"
      className="wrapperContent"
      showNotification={hasNotif}
      notification={notification}
      onCloseNotification={handleCloseNotification}
    >
      <Nav tabs className="user-role-tabs">
        {tabsData?.length !== 0 &&
          tabsData.map((item) => {
            if (item?.allow_permission) {
              return (
                <NavItem key={item?.key}>
                  <NavLink
                    className={classnames({ active: activeTab === item?.key })}
                    onClick={() => toggleActiveTab(item?.key)}
                  >
                    {item?.label}
                  </NavLink>
                </NavItem>
              );
            }
          })}
      </Nav>

      {activeTab !== '' && (
        <Card className="card-custom none-border-radius-top card-custom-p-15-15">
          <CardBody className="p-0">
            <Row>
              {activeTab == 'campaign' && (
                <Col sm={12} md={12}>
                  <CampaignList onResultNotification={handleResultNotification} activetab={activeTab}></CampaignList>
                </Col>
              )}

              {activeTab == 'group' && (
                <Col sm={12} md={12}>
                  <GroupCampaignList
                    onResultNotification={handleResultNotification}
                    activetab={activeTab}
                  ></GroupCampaignList>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      )}
    </Wrapper>
  );
};

export default Campaigns;
