import React, { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import EditableInputField from '@components/field/EditableInputField';

const ChangePasswordForm = (props) => {
  const {
    currentPassword,
    newPassword,
    ConfirmNewPassword,
    errorMessageCurrent,
    errorMessageNew,
    errorMessageConfirm,
    disabledForm,
    onChangeCurrentPassword,
    onChangeNewPassword,
    onChangeConfirmNewPassword,
    toggleClose,
    onConfirm,
    submitStatus,
  } = props;

  const handleCurrentPassword = (e) => {
    onChangeCurrentPassword(e);
  };

  const handleNewPassword = (e) => {
    onChangeNewPassword(e);
  };

  const handleConfirmNewPassword = (e) => {
    onChangeConfirmNewPassword(e);
  };

  return (
    <>
      <div className="header mb-5">Change Password</div>
      <div>
        <EditableInputField
          value={currentPassword}
          title="Current Password"
          disabled={disabledForm}
          onChange={(e) => handleCurrentPassword(e)}
          colWidth={12}
          labelWidth={12}
          required
          placeholder="Current Password"
          invalid={errorMessageCurrent}
          errorMessage={errorMessageCurrent}
          inputType="password"
          textLeft
        />
        <EditableInputField
          value={newPassword}
          title="New Password"
          disabled={disabledForm}
          onChange={(e) => handleNewPassword(e)}
          colWidth={12}
          labelWidth={12}
          required
          placeholder="New Password"
          invalid={errorMessageNew}
          errorMessage={errorMessageNew}
          inputType="password"
          textLeft
        />
        <EditableInputField
          value={ConfirmNewPassword}
          title="Confirm New Password"
          disabled={disabledForm}
          onChange={(e) => handleConfirmNewPassword(e)}
          colWidth={12}
          labelWidth={12}
          required
          placeholder=" Confirm New Password"
          invalid={errorMessageConfirm}
          errorMessage={errorMessageConfirm}
          inputType="password"
          textLeft
        />
      </div>
      <div>{submitStatus && <div className={`alert alert-${submitStatus?.color}`}>{submitStatus?.message}</div>}</div>
      <div className="action">
        <Button className="button-action-modal danger" onClick={toggleClose} disabled={disabledForm}>
          Batal
        </Button>
        <Button className="button-action-modal primary" onClick={onConfirm} disabled={disabledForm}>
          Simpan
        </Button>
      </div>
    </>
  );
};

export default ChangePasswordForm;
