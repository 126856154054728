import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import map from 'lodash/map';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import Wrapper from '@components/wrapper/Wrapper';
import userPermission from '@utils/userPermission';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import HeaderInformation from '@components/packages/HeaderInformation';
import DetailInformation from '@components/packages/DetailInformation';

function PackageDetail() {
  const api = new API('v2');
  const history = useHistory();
  const { id } = useParams();
  const { generatePermission } = userPermission();
  const [loading, setLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [packageHeader, setPackageHeader] = useState({});
  const [packageDetail, setPackageDetail] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  const breadcrumb = [
    {
      id: 1,
      label: 'List Package',
      active: false,
      url: '/packages',
    },
    {
      id: 2,
      label: 'Detail Package',
      active: false,
      url: '',
    },
    {
      id: 3,
      label: packageHeader?.name ?? '',
      active: true,
      url: '',
    },
  ];

  const allowEdit = (menu) => {
    return !!(generatePermission('package', menu) || generatePermission('package', 'editall'));
  };

  async function fetchCategories() {
    let listCategory = [];

    try {
      const response = await api.get(`v2/intools/categories/list-categories-all/`);
      if (response?.data?.data) {
        let resultListCategory = map(response?.data?.data, (item) => {
          return { value: item?.slug, label: item?.name };
        });
        listCategory = resultListCategory;
      }

      setCategoryOptions(listCategory);
    } catch (error) {
      setCategoryOptions(listCategory);
    }
  }

  async function fetchPackageHeader() {
    setLoading(true);

    try {
      const response = await api.get(`v2/intools/packages/details-header?package_id=${id}`);
      if (response?.data?.data?.length && response?.data?.data[0]) {
        const headerData = mappingPackageHeader(response?.data?.data[0]);

        setPackageHeader(headerData);
        fetchPackageDetail(headerData?.id, headerData?.workshop?.slug, headerData?.category?.value);
        setLoading(false);
      } else {
        setPackageHeader({});
        setLoading(false);
      }
    } catch (error) {
      setPackageHeader({});
      setLoading(false);
    }
  }

  const mappingPackageHeader = (packageHeader) => {
    let transformedData = packageHeader;
    transformedData.category.value = transformedData?.category?.slug;
    transformedData.category.label = transformedData?.category?.name;
    delete transformedData?.category?.slug;
    delete transformedData?.category?.name;
    delete transformedData?.category?.id;
    transformedData.sku = transformedData.sku ?? '';
    transformedData.discount_amount = transformedData.discount_amount ?? 0;
    transformedData.is_active = transformedData.is_active == 1 ? true : false;
    transformedData.name = transformedData?.name ? Helper.shortenByDash(transformedData?.name) : '';

    return transformedData;
  };

  async function fetchPackageDetail(id, workshop_slug, service_category) {
    const params = {
      package_id: id,
      workshop_slug: workshop_slug,
      service_category: service_category,
    };

    try {
      const response = await api.get(`v2/intools/packages/details/`, { params });
      const detailData = mappingPackageDetail(response?.data?.data?.package_details?.main);
      setPackageDetail(detailData);
      setLoading(false);
    } catch (error) {
      setPackageDetail([]);
      setLoading(false);
    }
  }

  const mappingPackageDetail = (packageDetails) => {
    let components = [];
    packageDetails?.length &&
      packageDetails.map((componentItem, idxComponent) => {
        let products = [];
        componentItem?.line_item?.items?.length &&
          componentItem?.line_item?.items.map((productItem, idxProduct) => {
            products.push({
              product: {
                value: productItem?.id,
                label: productItem?.name ?? '',
              },
              is_active: true, //need improvement BE data
              is_deleted: false,
              id_product: productItem?.id,
              price: productItem?.price ?? 0,
              apps_name: productItem?.name ?? '',
              product_name: productItem?.name ?? '',
              is_show: idxComponent == 0 && idxProduct == 0 ? true : false,
              reguler_price: productItem?.original_price ?? 0,
              discount_amount: productItem?.discount_amount ?? 0,
            });
          });
        components.push({
          is_active: true, //need improvement BE data
          is_deleted: false,
          products: products,
          id_component: componentItem?.id,
          is_show: idxComponent == 0 ? true : false,
          component_name: componentItem?.name ?? '',
          description: componentItem?.description ?? '',
          prompt_text: componentItem?.line_item?.description ?? '',
        });
      });

    return components;
  };

  const handleUpdatePackage = (id) => {
    history.push(`/packages/${id}`);
  };

  useEffect(() => {
    fetchCategories();
    fetchPackageHeader();
  }, []);

  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }
    return (
      <React.Fragment>
        <Wrapper title="Detail Package" breadcrumbData={breadcrumb}>
          <Row>
            <Col sm={12} md={6}>
              {packageHeader?.id && (
                <HeaderInformation
                  defaultValuesHeader={packageHeader}
                  packageCategoryOptions={categoryOptions}
                  allowEdit={allowEdit('editheaderinfo')}
                  onUpdatePackageHeader={handleUpdatePackage}
                />
              )}
            </Col>
            <Col sm={12} md={6}>
              {packageDetail?.length > 0 && (
                <DetailInformation
                  defaultValuesDetail={packageDetail}
                  defaultValuesHeader={packageHeader}
                  packageProductOptions={productOptions}
                  allowEdit={allowEdit('editdetaiinfo')}
                  onUpdatePackageDetail={handleUpdatePackage}
                />
              )}
            </Col>
          </Row>
        </Wrapper>
      </React.Fragment>
    );
  };

  return renderContent();
}

export default PackageDetail;
