import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { Input } from 'reactstrap';

const FilterSelectTextField = (props) => {
  const { data, onSearch, onChange, disabled, direction } = props;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: '500',
      backgroundColor: state?.isSelected ? '#6610F2' : state?.isFocused ? '#E0CFFC' : '#fff',
      color: state?.isSelected ? '#fff' : state?.isFocused ? '#6610F2' : '#6C757D',
      cursor: state?.isFocused ? 'pointer' : '',
      '&:hover': {
        color: state?.isSelected ? '#fff' : '#6610F2',
        backgroundColor: state?.isSelected ? '#6610F2' : '#E0CFFC',
        cursor: 'pointer',
      },
    }),
    control: (provided) => ({
      ...provided,
      height: 38,
      border: 'unset',
      borderRadius: direction === 'reverse' ? '0px 8px 8px 0px' : '8px 0px 0px 8px',
      backgroundColor: '#fff',
      boxShadow: 'none',
      fontWeight: '600',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#6610F2!important',
    }),
    input: (provided) => ({
      ...provided,
      color: '#6610F2',
      '[type="text"]': {
        fontWeight: '600',
      },
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      borderRadius: 4,
      paddingBottom: 0,
      border: '0.5px solid #6610F2',
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        color: '#BDBDBD',
        fontWeight: '200',
      };
    },
  };

  let [dummyData, setDummyData] = useState(data);
  const [filedClass, setFilledClass] = useState('');

  const getWidthClass = () => {
    const width = data?.width ?? 'w-400';
    return width;
  };

  const getWidthClassSelect = () => {
    const widthSelect = data?.width_select ?? '';
    return widthSelect;
  };

  const getWidthClassText = () => {
    const widthText = data?.width_text ?? '';
    return widthText;
  };

  const onChangeSelect = (e) => {
    if (e?.value !== dummyData?.dummy_value_select?.value) {
      dummyData.dummy_value_select = e;
      dummyData.dummy_value_text = '';
      setDummyData({ ...dummyData });
      onChange(dummyData, 'Change');
    }
  };

  const onChangeText = (e) => {
    dummyData.dummy_value_text = e?.target?.value ?? '';
    setDummyData({ ...dummyData });
    onChange(dummyData, 'Change');
  };

  const onBlurSelect = () => {
    setTimeout(() => {
      if (!dummyData.temp_value_select) {
        dummyData.dummy_value_select = dummyData?.dummy_value_text !== '' ? dummyData.dummy_value_select : '';
        setFilledClass(
          dummyData?.value_text === dummyData?.dummy_value_text &&
            dummyData?.dummy_value_text !== '' &&
            dummyData?.dummy_value_select
            ? 'filled'
            : ''
        );
        setDummyData({ ...dummyData });
      }
    }, 10);
  };

  const onFocusText = () => {
    dummyData.temp_value_select = dummyData?.dummy_value_select ?? '';
    setDummyData({ ...dummyData });
    setFilledClass(dummyData?.value_select ? 'filled' : '');
  };

  const onBlurText = () => {
    dummyData.temp_value_select = '';
    dummyData.dummy_value_select = dummyData?.dummy_value_text ? dummyData?.dummy_value_select : '';
    setFilledClass(
      dummyData?.value_text === dummyData?.dummy_value_text && dummyData?.dummy_value_select ? 'filled' : ''
    );
    setDummyData({ ...dummyData });
  };

  const onFocusSection = () => {
    setFilledClass('filled');
  };

  const onKeyDownText = (e) => {
    if (e?.key === 'Enter' && !disabled) {
      dummyData.dummy_value_select = dummyData?.dummy_value_text ? dummyData?.dummy_value_select : '';
      dummyData.value_text = dummyData?.dummy_value_text ?? '';
      dummyData.value_select = dummyData?.dummy_value_text ? dummyData?.dummy_value_select : '';
      dummyData.temp_value_select = '';
      dummyData.value = {
        searchBy: dummyData?.dummy_value_select ?? '',
        search: dummyData.dummy_value_text ?? '',
      };

      setFilledClass(dummyData?.value_text ? 'filled' : '');
      setDummyData({ ...dummyData });
      onChange(dummyData, 'Enter');
    }
  };

  const resetOnSearch = () => {
    if (onSearch && dummyData) {
      dummyData.value_text = dummyData?.dummy_value_text ?? '';
      dummyData.value_select = dummyData?.dummy_value_text ? dummyData?.dummy_value_select : '';
      setFilledClass(
        dummyData?.value_text === dummyData?.dummy_value_text && dummyData?.dummy_value_select ? 'filled' : ''
      );
      setDummyData({ ...dummyData });
    }
  };

  const DropdownIndicator = (props) => {
    const isActive = props?.isFocused || props?.hasValue ? true : false;

    return (
      <components.DropdownIndicator {...props}>
        {isActive && <div className="arrow-inactive-select">{''}</div>}
        {!isActive && <div className="arrow-active-select">{''}</div>}
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    setDummyData(data);
    setFilledClass(data?.dummy_value_text || data?.value_select?.value ? 'filled' : '');
  }, [data]);

  useEffect(() => {
    resetOnSearch();
  }, [onSearch]);

  const classes = direction === 'reverse' ? 'text-select' : 'select-text';

  return (
    <div className={`${classes}-field-filter` + ' ' + filedClass + ' ' + getWidthClass()} onFocus={onFocusSection}>
      <Select
        className={`select-${classes}-field-filter` + ' ' + getWidthClassSelect()}
        components={{ IndicatorSeparator: () => null, DropdownIndicator }}
        name={dummyData?.name + '_text'}
        placeholder={dummyData?.placholder_select ?? ''}
        isSearchable={dummyData?.searchable ?? true}
        styles={customStyles}
        value={dummyData?.dummy_value_select ?? ''}
        options={dummyData?.options ?? []}
        isDisabled={disabled}
        onBlur={onBlurSelect}
        onChange={(e) => onChangeSelect(e)}
      />
      <Input
        className={`input-${classes}-field-filter` + ' ' + filedClass + ' ' + getWidthClassText()}
        type={'text'}
        autoComplete="off"
        value={dummyData?.dummy_value_text ?? ''}
        name={data?.name + '_select'}
        disabled={disabled || !dummyData?.dummy_value_select}
        placeholder={
          dummyData?.dummy_value_select
            ? dummyData?.dummy_value_select?.placeholder_text
              ? dummyData?.dummy_value_select?.placeholder_text
              : ''
            : dummyData?.placeholder_default_text ?? ''
        }
        onFocus={() => onFocusText()}
        onBlur={() => onBlurText()}
        onChange={(e) => onChangeText(e)}
        onKeyDown={(e) => onKeyDownText(e)}
      />
    </div>
  );
};

export default FilterSelectTextField;
