import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Card, CardBody, Col, FormGroup, FormFeedback, Input, Label, Row, Button } from 'reactstrap';
import API from '@utils/API';
import map from 'lodash/map';
import userPermission from '@utils/userPermission';
import InputMask from 'react-input-mask';
import ActionSwitch from '@components/field/ActionSwitch';
import DividerNoLabel from '@components/divider/DividerNoLabel';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { forwardRef } from 'react';
import every from 'lodash/every';
import 'moment/min/locales.min';
moment.locale('id');

const Operational = (props) => {
  const { id, isValidationForm, onResultValidationForm, onLoading, onChangeAction, onResultNotification } = props;
  const api = new API('v2');
  const { generatePermission } = userPermission();
  const [originalValuesOperational, setOriginalValuesOperational] = useState([]);
  const [originalValuesClosedDate, setOriginalValuesClosedDate] = useState({});
  const [closeFrom, setCloseFrom] = useState();
  const [closeTo, setCloseTo] = useState();
  const [closingDaySwitch, setClosingDaySwitch] = useState();
  const [schedules, setSchedules] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [errorClosedDay, setErrorClosedDay] = useState('');
  const [isErrorClosedDay, setIsErrorClosedDay] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'all'));
  };

  const allowEdit = allowPermission('operatinghouredit');

  const InputStart = forwardRef(({ value, onClick }, ref) => (
    <>
      <Input type={'text'} value={value} invalid={isErrorClosedDay} placeholder={'Start Date'} onClick={onClick} />
      <FormFeedback>{errorClosedDay ? errorClosedDay : ''}</FormFeedback>
    </>
  ));
  const InputEnd = forwardRef(({ value, onClick }, ref) => (
    <Input type={'text'} value={value} invalid={isErrorClosedDay} placeholder={'End Date'} onClick={onClick} />
  ));

  const InputDate = forwardRef(({ dates, value, onClick }, ref) => (
    // let date = value.split('-');
    <>
      <Row>
        <Col lg={5} sm={4}>
          <Input
            type={'text'}
            value={value.split(' - ')[0] ? moment(value.split(' - ')[0]).format('DD MMMM YYYY') : ''}
            invalid={isErrorClosedDay}
            placeholder={'Start Date'}
            onClick={onClick}
          />
        </Col>
        <Label className="text-left mt-2"> s/d </Label>
        <Col lg={5} sm={4}>
          <Input
            type={'text'}
            value={value.split(' - ')[1] ? moment(value.split(' - ')[1]).format('DD MMMM YYYY') : ''}
            invalid={isErrorClosedDay}
            placeholder={'End Date'}
            onClick={onClick}
          />
        </Col>
        <Col lg={1} sm={1} className="is-open-switch">
          <ActionSwitch
            className="action-switch"
            color="secondary"
            inputName={`closing_day`}
            isChecked={closingDaySwitch}
            onChange={handleSwitchClosingDayChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span className="invalid-hour">{errorClosedDay}</span>
        </Col>
      </Row>
    </>
  ));

  async function getOperational() {
    setLoading(true);

    try {
      const response = await api.get(`v2/intools/workshops/${id}/operating-hour/`);
      if (response?.data?.data) {
        setInitialValues(response?.data?.data?.operating_hours ?? []);
        setInitialClosingDay(response?.data?.data?.closed_date);
      } else {
        setOriginalValuesOperational({});
        setDisableButton(true);
      }
      setLoading(false);
    } catch (error) {
      handleResultNotification(true, 'fail', error.message);
      setOriginalValuesOperational({});
      setDisableButton(true);
      setLoading(false);
    }
  }

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const handleAction = (type) => {
    if (isEdit && type == 'cancel') {
      defaultData();
      setClosedDate(originalValuesClosedDate);
      setIsEdit(!isEdit);
      setIsErrorClosedDay(false);
      setErrorClosedDay(false);
      handleResultNotification(false);
      onChangeAction(false, 'operational');
    } else if (isEdit && type == 'save') {
      handleSubmitForm('submit');
    } else {
      setIsEdit(!isEdit);
      onChangeAction(true, 'operational');
    }
  };

  const handleSubmitForm = (type) => {
    const isValid = validateForm();
    if (isValid) {
      updateOperationalHour(type);
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
  };

  const validateForm = () => {
    let isValid = true;

    map(schedules, (data) => {
      if (data?.error_message !== '') {
        isValid = false;
      }
    });

    if (!startDate || !endDate) {
      isValid = false;
      setIsErrorClosedDay(true);
      setErrorClosedDay('Field tidak boleh kosong');
    }
    return isValid;
  };

  const updateOperationalHour = (type) => {
    setDisableButton(true);
    const payload = {
      closed_date: formatClosedDates(),
      operating_hours: formatSchedules(schedules),
    };
    api
      .put(`v2/intools/workshops/${id}/operating-hour/`, payload)
      .then((response) => {
        setInitialValues(response?.data?.data?.operating_hours ?? []);
        setInitialClosingDay(response?.data?.data?.closed_date);
        setIsEdit(!isEdit);
        handleResultNotification(true, 'success', 'Berhasil update jam operasional');
        if (type == 'submit') {
          onChangeAction(false, 'operational');
        }
        setDisableButton(false);
      })
      .catch((error) => {
        setIsEdit(isEdit);
        handleResultNotification(true, 'fail', 'Gagal update jam operasional');
        setDisableButton(false);
      });
  };

  const defaultData = () => {
    const dataSchedule = map(originalValuesOperational, (data) => {
      return {
        day: data.day,
        opening_hour: data.opening_hour,
        closing_hour: data.closing_hour,
        is_open: data.is_open,
        error_message: '',
      };
    });

    setSchedules(dataSchedule);
  };

  const setInitialValues = (value) => {
    setOriginalValuesOperational(value);
  };

  const setInitialClosingDay = (value) => {
    setOriginalValuesClosedDate(value);

    setClosedDate(value);
  };

  const setClosedDate = (value) => {
    if (value.close_from && value.close_to) {
      const from = moment(value?.close_from).format('DD MMMM YYYY');
      const to = moment(value?.close_to).format('DD MMMM YYYY');
      setCloseFrom(from);
      setCloseTo(to);
      const start = new Date(value?.close_from);
      const end = new Date(value?.close_to);
      setStartDate(start);
      setEndDate(end);
    } else {
      setCloseFrom('-');
      setCloseTo('-');
      setStartDate();
      setEndDate();
    }
    setClosingDaySwitch(value?.is_active ?? false);
  };

  const formatSchedules = (schedules) => {
    return map(schedules, (data) => {
      return {
        day: data.day,
        opening_hour: data.opening_hour,
        closing_hour: data.closing_hour,
        is_open: data.is_open,
      };
    });
  };

  const formatClosedDates = () => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    const payload = {
      close_from: start,
      close_to: end,
      is_active: closingDaySwitch,
    };

    return payload;
  };

  const generateMask = (hour) => {
    let firstNumber = '';
    if (hour) {
      firstNumber = hour[0] === '2';
    }
    return [/[0-2]/, firstNumber ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/];
  };

  const handleInputOpenDateChange = (value) => {
    if (value || endDate) {
      setStartDate(value);
      setIsErrorClosedDay(false);
      setDisableButton(false);
    } else {
      setIsErrorClosedDay(true);
      setErrorClosedDay('Field tidak boleh kosong');
    }
  };

  const handleInputCloseDateChange = (value) => {
    if (value) {
      setEndDate(value);
      setIsErrorClosedDay(false);
      setDisableButton(false);
    } else {
      setIsErrorClosedDay(true);
      setErrorClosedDay('Field tidak boleh kosong');
    }
  };

  const handleInputFromChange = (value, index) => {
    const newSchedules = [...schedules];
    newSchedules[index].opening_hour = value;
    checkValidSchedules(newSchedules);
  };

  const handleInputCloseChange = (value, index) => {
    const newSchedules = [...schedules];
    newSchedules[index].closing_hour = value;
    checkValidSchedules(newSchedules);
  };

  const checkValidSchedules = (values) => {
    const validatedSchedules = map(values, (schedule) => {
      return {
        day: schedule.day,
        opening_hour: schedule.opening_hour,
        closing_hour: schedule.closing_hour,
        is_open: schedule.is_open,
        error_message: generateErrorHour(schedule.opening_hour, schedule.closing_hour),
      };
    });
    setSchedules(validatedSchedules);
  };

  const checkIfError = () => {
    const errors = map(schedules, (schedule) => {
      return schedule.error_message ? false : true;
    });

    return every(errors, (error) => {
      return error === true;
    });
  };

  const generateErrorHour = (open, close) => {
    const regex = new RegExp('^[0-9]+$');
    const openingHour = open.slice(0, 2);
    const openingMin = open.slice(3, 5);
    const closingHour = close.slice(0, 2);
    const closingMin = close.slice(3, 5);
    if (regex.test(openingHour) && regex.test(openingMin) && regex.test(closingHour) && regex.test(closingMin)) {
      return closingHour <= openingHour ? 'Periksa kembali format jam ini' : '';
    } else {
      return 'Field tidak boleh kosong';
    }
  };

  const handleSwitchClosingDayChange = (e) => {
    setClosingDaySwitch(e.target.checked);
  };

  const handleSwitchChange = (index) => {
    const isOpen = schedules[index].is_open;
    const newSchedules = [...schedules];
    newSchedules[index].is_open = !isOpen;
    newSchedules[index].error_message = '';
    if (isOpen) {
      newSchedules[index].opening_hour = '09:00';
      newSchedules[index].closing_hour = '18:00';
    }
    setSchedules(newSchedules);
  };

  const generateClassName = (error) => {
    return error ? 'form-control input-hours is-invalid' : 'form-control input-hours';
  };

  const generateInActiveHour = (status) => {
    return status ? 'text-left text-bold' : 'text-muted text-left text-bold';
  };

  const showAlert = (color, message) => {
    setSubmitStatus({
      color: color,
      message: message,
    });

    setTimeout(() => {
      setSubmitStatus(null);
    }, 3000);
  };

  const handleValidateForm = () => {
    const isValid = validateForm();
    if (isValid) {
      handleSubmitForm();
    } else {
      handleResultNotification(true, 'fail', 'Mohon lengkapi form');
    }
    onResultValidationForm(isValid);
  };

  useEffect(() => {
    if (originalValuesOperational) {
      defaultData();
    }
  }, [originalValuesOperational]);

  useEffect(() => {
    if (isValidationForm) {
      handleValidateForm();
    }
  }, [isValidationForm]);

  useEffect(() => {
    getOperational();
  }, []);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  const onChange = (dates) => {
    const [start, end] = dates;
    if (start || end) {
      setStartDate(start);
      setEndDate(end);
      setIsErrorClosedDay(false);
      setDisableButton(false);
      setErrorClosedDay(false);
    } else {
      setIsErrorClosedDay(true);
      setErrorClosedDay('Field tidak boleh kosong');
    }
  };

  return (
    <React.Fragment>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <Card className="card-custom">
          <CardBody className="p-0">
            <Row>
              <Col sm={12} md={12}>
                <Row className="mb-3">
                  <Label lg={2} sm={2} className="text-left">
                    Libur Bengkel
                  </Label>
                  {!isEdit && (
                    <Label lg={8} className="text-left text-bold">
                      <span className="ml-1">{closingDaySwitch ? closeFrom + ' s/d ' + closeTo : ' - s/d -'}</span>
                    </Label>
                  )}
                  {isEdit && (
                    <>
                      {/* <Col lg={2} sm={4}>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => handleInputOpenDateChange(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          minDate={new Date()}
                          placeholderText={'Start Date'}
                          customInput={<InputStart />}
                          dateFormat="dd MMMM yyyy"
                        />
                        <FormFeedback>{errorClosedDay ? errorClosedDay : ''}</FormFeedback>
                      </Col> */}
                      {/* <Label className="text-left text-bold"> s/d </Label>
                      <Col lg={2} sm={4}>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => handleInputCloseDateChange(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          placeholderText={'End Date'}
                          // customInput={<InputEnd />}
                          dateFormat="dd MMMM yyyy"
                        /> */}
                      {/* <FormFeedback>{formData?.error_name ?? ''}</FormFeedback> */}
                      {/* </Col> */}
                      <Col lg={5} sm={4}>
                        <DatePicker
                          selected={startDate}
                          onChange={onChange}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={new Date()}
                          dateFormat="dd MMMM yyyy"
                          customInput={<InputDate />}
                          selectsRange
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
            <DividerNoLabel />
            <Row>
              <Col md={6} sm={12}>
                {map(schedules, (schedule, index) => {
                  return (
                    <>
                      {!isEdit && index < 4 && (
                        <FormGroup row key={`schedule-${schedule.day}-${index}`}>
                          <Label sm={2} className="text-left">
                            {schedule.day}
                          </Label>
                          <Label lg={10} className={generateInActiveHour(schedule?.is_open)}>
                            <span className="ml-1 muted">{schedule.opening_hour + ' - ' + schedule.closing_hour}</span>
                          </Label>
                        </FormGroup>
                      )}

                      {isEdit && index < 4 && (
                        <>
                          <FormGroup row key={`schedule-${schedule.day}-${index}`}>
                            <Label sm={2} className="text-left">
                              {schedule.day}
                            </Label>
                            <Col sm={3}>
                              <InputMask
                                className={generateClassName(schedule?.error_message)}
                                mask={generateMask(schedule.opening_hour)}
                                value={schedule.opening_hour}
                                onChange={(e) => handleInputFromChange(e.target.value, index)}
                                aria-invalid={schedule.error_message}
                              />
                              {/* <span className="invalid-feedback">{schedule.error_message}</span> */}
                            </Col>
                            <Col sm={3}>
                              <InputMask
                                className={generateClassName(schedule.error_message)}
                                mask={generateMask(schedule.closing_hour)}
                                value={schedule.closing_hour}
                                onChange={(e) => handleInputCloseChange(e.target.value, index)}
                                aria-invalid={schedule.error_message}
                              />
                              {/* <span className="invalid-feedback">{schedule.error_message}</span> */}
                            </Col>
                            <Col sm={4} className="is-open-switch">
                              <ActionSwitch
                                className="action-switch"
                                color="secondary"
                                inputName={`is_open_${index}`}
                                isChecked={schedule.is_open}
                                onChange={() => handleSwitchChange(index)}
                              />
                            </Col>
                            <Col sm={2}></Col>
                            <Col sm={8}>
                              <div className="invalid-hour">{schedule.error_message}</div>
                            </Col>
                            <Col sm={2}></Col>
                          </FormGroup>
                        </>
                      )}
                    </>
                  );
                })}
              </Col>

              <Col md={6} sm={12}>
                {map(schedules, (schedule, index) => {
                  return (
                    <>
                      {!isEdit && index > 3 && (
                        <FormGroup row key={`schedule-${schedule.day}-${index}`}>
                          <Label sm={2} className="text-left">
                            {schedule.day}
                          </Label>
                          <Label lg={8} className={generateInActiveHour(schedule?.is_open)}>
                            <span className="ml-1">{schedule.opening_hour + ' - ' + schedule.closing_hour}</span>
                          </Label>
                        </FormGroup>
                      )}

                      {isEdit && index > 3 && (
                        <FormGroup row key={`schedule-${schedule.day}-${index}`}>
                          <Label sm={2} className="text-left">
                            {schedule.day}
                          </Label>
                          {
                            <>
                              <Col sm={3}>
                                <InputMask
                                  className={generateClassName(schedule.error_message)}
                                  mask={generateMask(schedule.opening_hour)}
                                  value={schedule.opening_hour}
                                  onChange={(e) => handleInputFromChange(e.target.value, index)}
                                  aria-invalid={schedule.error_message}
                                />
                                {/* <span className="invalid-feedback">{schedule.error_message}</span> */}
                              </Col>
                              <Col sm={3}>
                                <InputMask
                                  className={generateClassName(schedule.error_message)}
                                  mask={generateMask(schedule.closing_hour)}
                                  value={schedule.closing_hour}
                                  onChange={(e) => handleInputCloseChange(e.target.value, index)}
                                  aria-invalid={schedule.error_message}
                                />
                                {/* <span className="invalid-feedback">{schedule.error_message}</span> */}
                              </Col>
                            </>
                          }
                          <Col sm={4} className="is-open-switch">
                            <ActionSwitch
                              className="action-switch"
                              color="secondary"
                              inputName={`is_open_${index}`}
                              isChecked={schedule.is_open}
                              onChange={() => handleSwitchChange(index)}
                            />
                          </Col>
                          <Col sm={2}></Col>
                          <Col sm={8}>
                            <div className="invalid-hour">{schedule.error_message}</div>
                          </Col>
                          <Col sm={2}></Col>
                        </FormGroup>
                      )}
                    </>
                  );
                })}
              </Col>
              {allowEdit && (
                <Col sm={12} md={12} className="section-action">
                  <Button className="button-action save" disabled={disableButton} onClick={() => handleAction('save')}>
                    {isEdit ? 'Simpan' : 'Edit Data'}
                  </Button>
                  {isEdit && (
                    <Button className="button-action cancel" onClick={() => handleAction('cancel')}>
                      {'Batal'}
                    </Button>
                  )}
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default Operational;
