import React, { useState, useEffect } from 'react';
import API from '@utils/API';
import { useParams, useHistory } from 'react-router-dom';
import userPermission from '@utils/userPermission';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import LoadingWrapper from '@components/wrapper/LoadingWrapper';
import Wrapper from '@components/wrapper/Wrapper';
import ReviewRatingsDetails from '@components/review-ratings/ReviewRatingDetails';
import LogHistory from '@components/review-ratings/LogHistory';

function ReviewRatingsDetail() {
  const { id } = useParams();
  const api = new API('v2');
  const history = useHistory();
  const { generatePermission } = userPermission();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [status, setsStatus] = useState('');
  const [initialReviewChar, setInitialReviewChar] = useState(0);

  const breadcrumb = [
    {
      id: 1,
      label: 'List Review & Rating',
      active: false,
      url: '/review-ratings',
    },
    {
      id: 2,
      label: 'Detail Review & Rating',
      active: true,
      url: '',
    },
  ];

  const allowEdit = (menu) => {
    return !!(generatePermission('ratingreview', menu) || generatePermission('ratingreview', 'editall'));
  };

  useEffect(() => {
    loadReviewRatingDetail(id);
  }, []);

  async function loadReviewRatingDetail() {
    setLoading(true);

    const response = await api.get(`v2/intools/rating-reviews/${id}/`);

    if (response?.data?.data) {
      const get = response?.data?.data;
      const details = {
        status: get?.status || '',
        timestamp: moment(get?.created_at).isValid() ? moment(get?.created_at).format('MMM DD YYYY , HH.mm') : '',
        booking_code: get?.booking?.booking_code || '',
        name: get?.booking?.customer?.name || '',
        workshop: get?.workshop?.name || '',
        packages: get?.booking?.package[0]?.name || '',
        rating: get?.rating || '',
        review: get?.review || '',
      };
      setInitialValues(details || '');
      setsStatus(details?.status || '');
      setInitialReviewChar(details?.review?.length || 0);
    }
    setLoading(false);
  }
  // render main content page
  const renderContent = () => {
    if (loading) {
      return <LoadingWrapper />;
    }

    return (
      <React.Fragment>
        <Wrapper title="Detail Review & Rating" breadcrumbData={breadcrumb}>
          <Row>
            <Col md={5} sm={12}>
              <ReviewRatingsDetails
                allowEdit={allowEdit('edit')}
                defaultValue={initialValues}
                defaultStatus={status}
                defaultReviewChar={initialReviewChar}
              />
            </Col>
            <Col md={7} sm={12}>
              <LogHistory />
            </Col>
          </Row>
        </Wrapper>
      </React.Fragment>
    );
  };
  return renderContent();
}

export default ReviewRatingsDetail;
