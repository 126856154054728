import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import API from '@utils/API';
import Wrapper from '@components/wrapper/Wrapper';
import Tabs from '@components/tabs/Tabs';
import userPermission from '@utils/userPermission';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import DetailCampaignTab from '@components/campaigns/campaign/DetailCampaignTab';
import VoucherTab from '@components/campaigns/campaign/VoucherTab';

const CampaignDetail = (props) => {
  const { idCampaign, activeSection } = props;
  const { generatePermission } = userPermission();
  const [control, setControl] = useState();
  const api = new API('v2');
  const [hasNotif, setHasNotif] = useState();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState({});
  const [resultValidationForm, setResultValidationForm] = useState();
  const [fisrtLoad, setFirstLoad] = useState(true);
  const [notification, setNotification] = useState({});
  const [mainLoading, setMainLoading] = useState(true);
  const [dataDetailCampaign, setDataDetailCampaign] = useState();

  const allowPermission = (menu) => {
    return !!(generatePermission('campaign', menu) || generatePermission('campaign', 'all'));
  };

  const allowShowDetail = allowPermission('detailall') || allowPermission('detailshow');
  const allowShowVoucher = allowPermission('detailvoucherall') || allowPermission('detailvoucherlist');

  const breadcrumb = [
    {
      id: 1,
      label: 'List Campaign',
      active: false,
      //   url: activeSection?.url,
      url: '/campaigns',
    },
    {
      id: 2,
      label: 'Detail Campaign',
      active: false,
      url: '',
    },
    {
      id: 3,
      label: dataDetailCampaign?.name,
      active: true,
      url: '',
    },
  ];

  async function fetchDetailCampaign() {
    setLoading(true);
    try {
      const response = await api.get(`v2/intools/campaigns/${idCampaign}/`);
      setDataDetailCampaign(response?.data?.data ?? {});
      handleGetCampaignDetail(response?.data?.data ?? {});
      setLoading(false);
      setFirstLoad(false);
    } catch (error) {
      const errorMsg = error?.response?.data?.error?.message ?? '';
      setDataDetailCampaign({});
      handleResultNotification({
        status: true,
        type: 'fail',
        message: 'Gagal mendapatkan detail campaign, ' + errorMsg,
      });
      setLoading(false);
      setFirstLoad(false);
    }
  }

  const tabsData = [
    {
      title: 'Detail Campaign',
      slug: 'detail',
      active: false,
      edit_mode: false,
      allow_permission: allowShowDetail,
    },
    {
      title: 'Voucher',
      slug: 'voucher',
      active: false,
      edit_mode: false,
      allow_permission: allowShowVoucher,
    },
  ];

  const handleGetCampaignDetail = (data) => {
    if (!data?.id) {
      handleResultNotification({ status: true, type: 'fail', message: 'Detail data campaign tidak ditemukan' });
    }
  };

  const handleResultNotification = (notification) => {
    if (notification.status) {
      setNotification({
        type: notification.type,
        message: notification.message,
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setHasNotif(notification.status);
    setTimeout(() => {
      setHasNotif(false);
      setNotification({});
    }, 3000);
  };

  const handleLoading = (status) => {
    setLoading(status);
  };

  const handleChangeActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const handleCloseNotification = () => {
    setHasNotif(false);
    setNotification({});
  };

  const handleChangeAction = (action, slug) => {
    let control = {
      action: action,
      slug: slug,
    };
    setControl(control);
  };

  useEffect(() => {
    setMainLoading(loading && fisrtLoad ? true : false);
  }, [loading]);

  useEffect(() => {
    if (allowShowDetail) {
      fetchDetailCampaign();
    }
  }, []);

  return (
    <React.Fragment>
      {!allowShowDetail && <Wrapper className="wrapperContent"></Wrapper>}
      {allowShowDetail && (
        <>
          {mainLoading && (
            <Wrapper className="wrapperContent">
              <LoadingSpinner></LoadingSpinner>
            </Wrapper>
          )}
          {!mainLoading && (
            <Wrapper
              title={'Detail Campaign'}
              customContentClassName="wrapper-content"
              customTitleClassName="wrapper-title"
              showNotification={hasNotif}
              notification={notification}
              breadcrumbData={breadcrumb}
              onCloseNotification={handleCloseNotification}
            >
              {dataDetailCampaign?.id && (
                <>
                  <Tabs
                    control={control}
                    loading={loading}
                    tabs={tabsData}
                    onShowModal={() => setResultValidationForm(false)}
                    resultValidationForm={resultValidationForm}
                    onChangeActiveTab={handleChangeActiveTab}
                  ></Tabs>
                  <Row className="mt-4">
                    <Col sm={12}>
                      {activeTab.slug == 'detail' && (
                        <DetailCampaignTab
                          id={idCampaign}
                          onChangeAction={handleChangeAction}
                          onResultNotification={handleResultNotification}
                          onLoading={handleLoading}
                        ></DetailCampaignTab>
                      )}
                    </Col>
                    <Col sm={12}>
                      {activeTab.slug == 'voucher' && (
                        <VoucherTab
                          id={idCampaign}
                          onChangeAction={handleChangeAction}
                          onResultNotification={handleResultNotification}
                          onLoading={handleLoading}
                        ></VoucherTab>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Wrapper>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default CampaignDetail;
